import { useEffect, useRef, useState } from "react";
import { logger } from "../../../loggers";
import { getSuretyBond } from "../../services";

export default function useGetBond(bondNumber) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const cache = useRef({});
  const error = useRef(false);

  const handleError = () => {
    cache.current = {};
    error.current = true;
    setData(null);
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (!bondNumber || isLoading || error.current) return;

    if (cache?.current?.[bondNumber]) {
      setData(cache.current[bondNumber]);
      return;
    }

    setIsLoading(true);

    getSuretyBond(bondNumber)
      .then(res => {
        if (res.status === 200 && res.data) {
          cache.current[bondNumber] = res.data;
          setData(res.data);
        } else {
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsLoading(false));
  }, [bondNumber, isLoading]);

  return [data, isLoading, error.current];
}
