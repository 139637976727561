import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../loggers";

const fetchSuretyQuoteDataStarted = () => ({
  type: types.FETCH_SURETY_QUOTE_DATA_STARTED
});

const fetchSuretyQuoteDataSucceeded = data => ({
  type: types.FETCH_SURETY_QUOTE_DATA_SUCCEEDED,
  payload: data
});

const fetchSuretyQuoteDataFailed = error => ({
  type: types.FETCH_SURETY_QUOTE_DATA_FAILED,
  payload: error
});

const updateSuretyQuoteDataStarted = () => ({
  type: types.UPDATE_SURETY_QUOTE_DATA_STARTED
});

const updateSuretyQuoteDataSucceeded = data => ({
  type: types.UPDATE_SURETY_QUOTE_DATA_SUCCEEDED,
  payload: data
});

const updateSuretyQuoteDataFailed = error => ({
  type: types.UPDATE_SURETY_QUOTE_DATA_FAILED,
  payload: error
});

const clearSuretyQuoteFailed = error => ({
  type: types.DELETE_SURETY_QUOTE_FAILED,
  payload: error
});

export const clearSuretyQuoteData = () => ({
  type: types.CLEAR_SURETY_QUOTE_DATA
});

export const fetchSuretyQuoteData = identifier => dispatch => {
  dispatch(fetchSuretyQuoteDataStarted());
  return quickQuotingServices.getSuretyQuote(identifier).then(
    response => {
      dispatch(fetchSuretyQuoteDataSucceeded(response.data.data));
    },
    error => {
      dispatch(fetchSuretyQuoteDataFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const updateSuretyQuoteData = (identifier, data) => dispatch => {
  dispatch(updateSuretyQuoteDataStarted());
  return quickQuotingServices
    .postSuretyQuote(identifier, {
      origin: window.location.href,
      store_name: identifier,
      data
    })
    .then(
      response => {
        dispatch(updateSuretyQuoteDataSucceeded(response.data.data));
      },
      error => {
        dispatch(updateSuretyQuoteDataFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const clearSuretyQuote = identifier => dispatch => {
  return quickQuotingServices
    .deleteSuretyQuote(identifier)
    .then(response => {
      if (response.status === 204) {
        dispatch(clearSuretyQuoteData());
      }
    })
    .catch(error => {
      dispatch(clearSuretyQuoteFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    });
};
