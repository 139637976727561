import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../translations";
import { Button, Table } from "@ufginsurance/ui-kit";
import ConfirmDeletePopover from "./ConfirmDeletePopover";

const AttachmentsTable = ({
  attachmentsList,
  canModify,
  fetchBlobAttachFile,
  removeAttachment
}) => {
  const columns = [
    {
      key: "name",
      label: Translations.commercial_lines.view_attachments.name,
      sortable: true,
      element: row => (
        <Button
          id="name"
          isLink
          onClick={() => {
            fetchBlobAttachFile(row.id);
          }}
        >
          {row.name}
        </Button>
      )
    },
    {
      key: "added_by_role",
      label: Translations.commercial_lines.view_attachments.added_by
    }
  ];

  const remove = {
    key: "remove",
    label: Translations.commercial_lines.view_attachments.remove,
    align: "center",
    element: attachment => {
      if (!attachment.can_delete) {
        return null;
      }
      return (
        <ConfirmDeletePopover
          attachment={attachment}
          removeAttachment={removeAttachment}
        />
      );
    }
  };

  if (canModify) {
    columns.push(remove);
  }

  return (
    <Table
      rowKey="id"
      className="attachments-table"
      data={attachmentsList}
      columns={columns}
      initialSort="client_name"
      initialDirection="desc"
      itemsPerPage={20}
      noResultsMessage={Translations.commercial_lines.view_attachments.error}
    />
  );
};

AttachmentsTable.propTypes = {
  attachmentsList: PropTypes.array,
  fetchBlobAttachFile: PropTypes.func.isRequired,
  canModify: PropTypes.bool,
  removeAttachment: PropTypes.func
};

export default AttachmentsTable;
