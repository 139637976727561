import API from "../../api";

export const fetchClaims = (number, selectedAgencyCode, closeAfterDate) => {
  return API.agent().get(
    `/claims/details?number=${number}&agency_number=${selectedAgencyCode}&closed_after=${closeAfterDate}`
  );
};

export const fetchClaimsPayments = (claimNumber, selectedAgencyCode) => {
  return API.agent().get(
    `/claims/${claimNumber}/payments?agency_number=${selectedAgencyCode}`
  );
};

export const fetchClaimsPaymentDetails = (
  claimNumber,
  paymentNumber,
  selectedAgencyCode
) => {
  return API.agent().get(
    `/claims/${claimNumber}/payments/${paymentNumber}?agency_number=${selectedAgencyCode}`
  );
};

export const fetchClaimsNotes = claimNumber => {
  return API.agent().get(`/claims/${claimNumber}/files`);
};

export const fetchClaimsClaimants = claimNumber => {
  return API.agent().get(`/claims/${claimNumber}/claimants`);
};

export const fetchClaimNoteDocument = (
  claimNumber,
  documentId,
  isAdjusterNote
) => {
  return API.agent().get(
    `/claims/${claimNumber}/files/documents?document_id=${documentId}&is_adjuster_note=${isAdjusterNote}`,
    { responseType: "blob" }
  );
};

//Claim Information
export const fetchClaim = (claimNumber, selectedAgencyCode) => {
  return API.agent().get(
    `/claims/${claimNumber}/details?agency_number=${selectedAgencyCode}`
  );
};

export const printDocuments = claim_number => {
  return API.agent().get(`/claims/${claim_number}/agent-acknowledgement`, {
    responseType: "blob"
  });
};