import React, { useEffect, useState } from "react";
import { useNewBusinessContext } from "../context";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import CMSContent from "../../cms-content/CMSContent";

const Content = () => {
  const { tableOfContentId, fetchContent, newBusinessData } =
    useNewBusinessContext();

  const [content, setContent] = useState(null);

  useEffect(() => {
    if (tableOfContentId) {
      setContent(null);
      fetchContent(tableOfContentId);
    }
  }, [tableOfContentId, fetchContent]);

  useEffect(() => {
    if (newBusinessData?.content?.data?.display_text) {
      setContent(newBusinessData?.content?.data?.display_text);
    }
  }, [newBusinessData.content.data, content]);

  return (
    <>
      {!content && <LoadingIndicator />}

      {content && (
        <CMSContent
          id="cms-content-new-business"
          classNames="cms-content cms-content--new-business"
          content={content}
        />
      )}
    </>
  );
};

export default Content;
