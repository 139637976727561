import { combineReducers } from "redux";
import activityData from "./activityReducer";
import { reducer as accountData } from "./accountDataReducer";
import billingDetails from "./billingReducer";
import policiesData from "./policiesReducer";
import commercialLines from "./commercialLinesReducer";
import installmentDetails from "./installmentReducer";
import { reducer as currentAgency } from "./currentAgencyReducer";
import homeCommercial from "./homeCommercialReducer";
import homeBilling from "./homeBillingReducer";
import homeClaims from "./homeClaimsReducer";
import homePersonal from "./homePersonalReducer";
import moratorium from "./moratoriumReducer";
import umailData from "../umail/umailReducer";
import helpDesk from "../help-desk/helpDeskReducer";
import bidRequestList from "../components/home/surety/reducers/bidRequestListReducer";
import consentBondsList from "../components/home/surety/reducers/consentBondsListReducer";
import billingCopiesList from "../components/home/surety/reducers/billingCopiesListReducer";
import suretyBondFormList from "../../src/quick-program/quick-quoting/reducers/suretyBondFormListReducer";
import agencyProducersList from "../../src/quick-program/quick-quoting/reducers/agencyProducersListReducer";
import agencyAccountsList from "../components/home/surety/reducers/agencyAccountsListReducer";
import quickAgencyAccountsList from "../quick-program/quick-quoting/reducers/agencyAccountsListReducer";
import quickVerifyAddress from "../quick-program/quick-quoting/reducers/quickVerifyAddressReducer";
import quickOwnersList from "../quick-program/quick-quoting/reducers/quickOwnersListReducer";
import quickEligibilityList from "../quick-program/quick-quoting/reducers/quickEligibilityListReducer";
import quickBondObligeeList from "../quick-program/quick-quoting/reducers/quickBondObligeeListReducer";
import quickSubmission from "../quick-program/quick-quoting/reducers/quickSubmissionReducer";
import quickUnderWriterDecision from "../underwriter-review/reducers/uwReviewDecisionReducer";
import quickUnderWriterReviewAccountInfo from "../underwriter-review/reducers/uwReviewAccountInfoReducer";
import quickUnderWriterReviewBond from "../underwriter-review/reducers/uwReviewBondInfoReducer";
import quickUnderWriterWarningMessages from "../underwriter-review/reducers/uwReviewWarningMessagesReducer";
import commercialQuotesList from "../components/home/surety/reducers/commercialQuotesListReducer";
import uploadHistoryList from "../components/home/surety/reducers/uploadHistoryListReducer";
import executedBondsList from "../components/home/surety/reducers/executedBondsListReducer";
import { reducer as oidcReducer } from "redux-oidc";
import { ssoSession } from "@ufginsurance/sso-oidc-client-react";
import classAppetiteGuide from "../class-appetite-guide/reducers/classAppetiteGuideReducer";
import { reducer as billingActivityReducer } from "./billingActivityReducer";
import directBillPayNowEnrollment from "./directBillPayNowEnrollmentReducer";
import paymentDetails from "../top-action-cards/topActionCardReducer";
import policyChange from "../account-change-requests/redux/policyChangesReducer";
import claims from "../claims/reducers/claimsReducer";
import { reducer as claimNotes } from "../claims/reducers/notesReducer";
import claimsInquiry from "../claims/reducers/claimsInquiryReducer";
import policyActivity from "../policy-activity/policyActivityReducer";
import newsReducer from "../news/newsReducer";
import currentPolicyReducer from "../current-policy/currentPolicyReducer";
import producerEmailList from "../reports/reducers/agencyLossRunReducer";
import administrator from "../reports/reducers/administratorReducer";
import serviceCenterSummary from "../reports/reducers/serviceCenterReducer";
import underwritingProducerEmailList from "../reports/reducers/underwritingReducer";
import appQuoteStatusList from "../reports/reducers/appQuoteStatusReducer";
import yearToDateLossListReport from "../reports/reducers/yearToDateLossReducer";
import weeklyPaymentListReport from "../reports/reducers/weeklyPaymentReducer";
import agencyBillPay from "../reports/reducers/agencyBillEpayReducer";
import auditBreakdown from "../audit-breakdown/reducers/auditBreakdownReducer";
import reportClaimReducer from "../top-action-cards/report-claim/reducers/reportClaimReducer";
import newQuote from "../top-action-cards/new-quote/reducers";
import websiteLogo from "../components/website-logo-usage/WebsiteLogoReducers";
import agencyStatementList from "../reports/reducers/agencyStatementListReducer";
import homeServiceCenter from "./homeServiceCenterReducer";
import yourUfgTeamReducer from "../your-ufg-team/reducers/yourUfgTeamReducer";
import suretyQuote from "../quick-program/quick-quoting/reducers/suretyQuoteReducer";
import suretyQuoteClearData from "../quick-program/quick-quoting/reducers/suretyQuoteClearReducer";
import suretyQuoteAgencyData from "../quick-program/quick-quoting/reducers/suretyQuoteAgencyDataReducer";
import suretyZip from "../quick-program/quick-quoting/reducers/suretyZipReducer";
import suretyQuickUserAttachments from "../quick-program/quick-quoting/reducers/quickDocumentUploadReducer";
import suretyQuickDocumentsPacket from "../quick-program/quick-quoting/reducers/documentsPacketReducer";
import agencySweepAccountBillingActivity from "../agency-sweep/complete-payment/agencySweepAccountBillingActivityReducer";
import agencySweepOasisInvoiceDetail from "../agency-sweep/complete-payment/agencySweepOasisInvoiceDetailReducer";
import agencySweepPaymentRequestDetails from "../agency-sweep/complete-payment/agencySweepPaymentRequestDetailsReducer";
import agencySweepPaymentConfirmation from "../agency-sweep/payment-confirmation/agencySweepPaymentConfirmationReducer";
import portalViewState from "../portalview/changePortalViewStateReducer";
import agentManualsReducer from "../agent-manuals/reducers/agentManualReducer";
import otherChangesModalData from "../account-change-requests/other-changes/otherChangesModalReducer";
import { quotingProductSelection } from "../commercial/shared/ProductSelection/ProductSelectionModal";
import agencyStatementOptionsReducer from "../reports/slices/guidewireAgencyStatementOptions";
import agencyProfile from "../agency-profile/AgencyProfileSlice";
import agencyInfoData from "../shared/slices/agencyInfoSlice";
import proquote from "../mfe/ProQuoteSlice";

const rootReducer = combineReducers({
  accountData,
  agencyProfile,
  agencySweepAccountBillingActivity,
  agencySweepOasisInvoiceDetail,
  agencySweepPaymentRequestDetails,
  agencySweepPaymentConfirmation,
  agentManuals: agentManualsReducer,
  activityData,
  administrator,
  suretyBondFormList,
  agencyProducersList,
  agencyAccountsList,
  quickEligibilityList,
  quickBondObligeeList,
  quickSubmission,
  quickUnderWriterDecision,
  quickUnderWriterReviewAccountInfo,
  quickUnderWriterReviewBond,
  quickUnderWriterWarningMessages,
  agencyBillPay,
  agencyStatementList,
  appQuoteStatusListData: appQuoteStatusList,
  auditBreakdown,
  bidRequestList,
  billingActivity: billingActivityReducer,
  billingCopiesList,
  billingDetails,
  policyChange,
  claimNotes,
  claims,
  claimsInquiry,
  classAppetiteGuide,
  commercialLines,
  commercialQuotesList,
  consentBondsList,
  currentAgency,
  currentPolicyData: currentPolicyReducer,
  directBillPayNowEnrollment,
  executedBondsList,
  helpDesk,
  homeBilling,
  homeClaims,
  homeCommercial,
  homePersonal,
  homeServiceCenter,
  installmentDetails,
  moratorium,
  newQuote,
  news: newsReducer,
  oidc: oidcReducer,
  paymentDetails,
  policiesData,
  policyActivityData: policyActivity,
  portalViewState,
  producerEmailList,
  reportClaimReducer,
  serviceCenterSummary,
  ssoSession,
  quickAgencyAccountsList,
  quickOwnersList,
  quickVerifyAddress,
  umailData,
  underwritingProducerEmailList,
  uploadHistoryList,
  websiteLogo,
  weeklyPaymentListReportData: weeklyPaymentListReport,
  yearToDateLossListReportData: yearToDateLossListReport,
  yourUfgTeamReducer,
  suretyQuote,
  suretyQuoteClearData,
  suretyQuoteAgencyData,
  suretyZip,
  suretyQuickUserAttachments,
  suretyQuickDocumentsPacket,
  otherChangesModalData,
  quotingProductSelection,
  agencyStatementOptionsReducer,
  agencyInfoData,
  proquote
});

export default rootReducer;
