import { connect } from "react-redux";
import {
  cancelQuote,
  saveLineOfBusinessStep
} from "../actions/newQuoteActions";
import LineOfBusiness from "./LineOfBusiness";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  initialFormValues: state.newQuote.steps.lineOfBusiness.form,
  isCancelledAgent: sessionSelector.isCancelledAgent(state)
});

const mapDispatchToProps = {
  onClose: cancelQuote,
  onContinue: saveLineOfBusinessStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineOfBusiness);
