import React from "react";
import LobCoverages from "./LobCoverages";
import { PanelGrid } from "@ufginsurance/ui-kit";
import Employees from "./Employees";
import StateSpecific from "./StateSpecific";

const WorkCompSummary = ({ coverageExclusions }) => {
  return (
    <PanelGrid
      sizes={{ sm: 1, md: 2, lg: 2, xl: 2 }}
      className="oq__quote-summary__tab"
    >
      <div className="oq__quote-summary__column">
        <LobCoverages
          lobName={"Workers' Compensation"}
          lobKey="wcmWorkersComp"
          coverageExclusions={coverageExclusions}
        />
        <StateSpecific
          lobKey={"wcmWorkersComp"}
          coverageExclusions={coverageExclusions || []}
        />
      </div>
      <div className="oq__quote-summary__column">
        <Employees coverageExclusions={coverageExclusions} />
      </div>
    </PanelGrid>
  );
};

export default WorkCompSummary;
