export const agentManualTypes = {
  tableOfContents:{
      header:"Header",
      subHeader1: "Sub-Header1",
      subHeader2: "Sub-Header2",
  }
};

export const INVALID_COMPANY_BRANCH = "invalidCompanyBranch";

export const isHeaderType = (subHeader) => {
  return subHeader.type === agentManualTypes.tableOfContents.header;
};

export const isSubHeader1Type = (subHeader) => {
  return subHeader.type === agentManualTypes.tableOfContents.subHeader1;
};

export const isSubHeader2Type = (subHeader) => {
  return subHeader.type === agentManualTypes.tableOfContents.subHeader2;
};
