import React, { useContext, useState } from "react";
import * as api from "../../services/onlineQuotingService";
import * as routes from "../../constants/routes";

import {
  Button,
  FlexRow,
  Form,
  FormGroup,
  TextArea,
  useForm
} from "@ufginsurance/ui-kit";
import { connect } from "react-redux";
import { cancelQuote } from "../../top-action-cards/new-quote/actions/newQuoteActions";

import OnlineQuotingContext from "../OnlineQuotingContext";

const PricingReviewForm = ({ onCancelQuote, onCancel, onShowConfirmation }) => {
  //use OnlineQuoting Context
  const { quoteData, showUpdatingToast, closeUpdatingToast, clearLocalQuote } =
    useContext(OnlineQuotingContext);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const sendUWIssue = () => {
    const message = "Sending pricing review request...";
    showUpdatingToast({ message });
    api
      .sendForReview(quoteData.quoteID, {
        message: values.comments
      })
      .then(() => {
        onShowConfirmation();
        setShowConfirmation(true);
        closeUpdatingToast({ message });
      });
  };

  const initialValues = {
    comments: ""
  };
  const form = useForm({ values: initialValues, onSubmit: sendUWIssue });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const isInModal = !!onCancel;

  return (
    <>
      {showConfirmation ? (
        <>
          <p>
            Your pricing review was submitted. Your UFG underwriter will contact
            you shortly.
          </p>
          <FlexRow align="right">
            <Button
              variant="primary"
              onClick={() => {
                onCancelQuote().then(() => {
                  clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
                });
              }}
            >
              Exit Quote
            </Button>
          </FlexRow>
        </>
      ) : (
        <Form context={form}>
          <p>Credit available based off of risk characteristics.</p>
          <p>Your UFG underwriter will contact you. </p>
          <FormGroup>
            <TextArea
              id="comments"
              name="comments"
              label="Pricing Comments / Remarks"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.comments}
              maxLength={65000}
            />
          </FormGroup>

          <FormGroup align="right">
            {onCancel && (
              <Button isLink onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="primary"
              disabled={isInModal && !values.comments}
            >
              Request Pricing Review
            </Button>
          </FormGroup>
        </Form>
      )}
    </>
  );
};

export default connect(null, { onCancelQuote: cancelQuote })(PricingReviewForm);
