import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import qs from "query-string";
import { Translations } from "../../components/translations";
import NameSearchResults from "./NameSearchResults";
import * as api from "../../services/accountholderService";
import { CLAIM_INFORMATION } from "../../constants/routes";
import {
  useForm,
  FormGroup,
  Form,
  Button,
  LoadingIndicator,
  Input
} from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const getRedirectUrl = queryString => `${CLAIM_INFORMATION}?${queryString}`;

const reducer = (state, action) => {
  switch (action.type) {
    case "SEARCH_BY_CLAIM_NUMBER_STARTED": {
      return {
        ...state,
        policies: [],
        loading: true,
        isInvalidSearch: false
      };
    }
    case "SEARCH_BY_CLAIM_NUMBER_SUCCEEDED": {
      const queryString = qs.stringify({ claim_number: action.claimNumber });
      return {
        ...state,
        loading: false,
        redirectTo: getRedirectUrl(queryString)
      };
    }
    case "SEARCH_BY_CLAIM_NUMBER_FAILED": {
      return {
        ...state,
        loading: false,
        isInvalidSearch: true
      };
    }

    case "SEARCH_BY_POLICY_NUMBER_STARTED": {
      return {
        ...state,
        policies: [],
        loading: true,
        isInvalidSearch: false
      };
    }
    case "SEARCH_BY_POLICY_NUMBER_SUCCEEDED": {
      const queryString = qs.stringify({ policy_number: action.policyNumber });
      return {
        ...state,
        loading: false,
        redirectTo: getRedirectUrl(queryString)
      };
    }
    case "SEARCH_BY_POLICY_NUMBER_FAILED": {
      const { error, policyNumber } = action;
      const noResults = error && error.status === 404;
      const queryString = qs.stringify({ policy_number: policyNumber });
      return {
        ...state,
        loading: false,
        isInvalidSearch: !noResults,
        redirectTo: noResults ? getRedirectUrl(queryString) : ""
      };
    }

    case "SEARCH_BY_NAME_STARTED": {
      return {
        ...state,
        policies: [],
        loading: true,
        isInvalidSearch: false
      };
    }
    case "SEARCH_BY_NAME_SUCCEEDED": {
      const { policies } = action;
      const policyNumbers = policies.map(policy => policy.policy_number);
      const uniquePolicies = policies.filter(
        (policy, index) => policyNumbers.indexOf(policy.policy_number) === index
      );
      const newState = {
        ...state,
        loading: false,
        isInvalidSearch: false,
        hasNameResults: uniquePolicies && uniquePolicies.length > 0,
        policies: uniquePolicies
      };

      if (uniquePolicies.length === 1) {
        const queryString = qs.stringify({
          policy_number: uniquePolicies[0].policy_number
        });
        return { ...newState, redirectTo: getRedirectUrl(queryString) };
      }

      return newState;
    }
    case "SEARCH_BY_NAME_FAILED": {
      return {
        ...state,
        policies: [],
        loading: false,
        hasNameResults: false
      };
    }

    case "INVALID_SEARCH": {
      return { ...state, isInvalidSearch: true };
    }

    default:
      throw new Error(`Unrecognized action: ${JSON.stringify(action)}`);
  }
};

const ClaimsInquiryForm = ({
  claimNumber,
  dismissModal,
  history,
  fetchClaim,
  employeeUserName,
  activeAgency,
  fetchClaims
}) => {
  const [state, dispatch] = useReducer(reducer, {
    claimNumber: claimNumber || "",
    nameOrPolicy: "",
    policies: [],
    loading: false,
    // This is a combination of:
    // - whether the claim or policy number values are all digits
    // - or there were no results for the claim or policy number
    isInvalidSearch: false,

    // User has submit a name search and policies should be api result
    hasNameResults: true,
    redirectTo: null
  });

  const { redirectTo } = state;
  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
      dismissModal();
    }
  }, [dismissModal, history, redirectTo]);

  const onSubmit = formData => {
    const values = {
      ...formData.values
    };
    if (values.claimNumber) {
      searchByClaimNumber(values.claimNumber.toUpperCase());
    } else if (values.nameOrPolicy) {
      if (isNaN(values.nameOrPolicy)) {
        searchByName(values.nameOrPolicy);
      } else {
        searchByPolicyNumber(values.nameOrPolicy);
      }
    } else {
      dispatch({ type: "INVALID_SEARCH" });
    }
  };

  const searchByClaimNumber = claimNumber => {
    dispatch({ type: "SEARCH_BY_CLAIM_NUMBER_STARTED" });
    fetchClaim(claimNumber, activeAgency.agencyCode, employeeUserName).then(
      () => {
        dispatch({ type: "SEARCH_BY_CLAIM_NUMBER_SUCCEEDED", claimNumber });
      },
      error => {
        dispatch({ type: "SEARCH_BY_CLAIM_NUMBER_FAILED", error });
      }
    );
  };

  const searchByPolicyNumber = policyNumber => {
    const closedAfterDate = moment().subtract(1, "year").format("YYYY-MM-DD");

    dispatch({ type: "SEARCH_BY_POLICY_NUMBER_STARTED" });
    fetchClaims(
      policyNumber,
      activeAgency.agencyCode,
      closedAfterDate,
      employeeUserName
    ).then(
      () => {
        dispatch({ type: "SEARCH_BY_POLICY_NUMBER_SUCCEEDED", policyNumber });
      },
      error => {
        dispatch({
          type: "SEARCH_BY_POLICY_NUMBER_FAILED",
          error,
          policyNumber
        });
      }
    );
  };

  const searchByName = name => {
    const policyType = "A";

    dispatch({ type: "SEARCH_BY_NAME_STARTED" });
    api.searchPolicies(activeAgency.agencyCode, name, policyType).then(
      ({ data: { policies } }) => {
        dispatch({ type: "SEARCH_BY_NAME_SUCCEEDED", policies });
      },
      () => {
        dispatch({ type: "SEARCH_BY_NAME_FAILED" });
      }
    );
  };

  const form = useForm({
    values: {
      claimNumber: claimNumber || "",
      nameOrPolicy: ""
    },
    onSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <>
      <Form context={form} id="claims-inquiry-form">
        <FormGroup groupErrors wrap={false}>
          <Input
            id="claimNumber"
            name="claimNumber"
            label={Translations.Claims_Inquiry.claim_number}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.claimNumber || ""}
            maxLength={18}
            disabled={!!values.nameOrPolicy}
            required={false}
          />
          <div>or</div>
          <Input
            id="nameOrPolicy"
            name="nameOrPolicy"
            label={Translations.Claims_Inquiry.name_or_policy.label}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.nameOrPolicy || ""}
            maxLength={30}
            disabled={!!values.claimNumber}
            required={false}
          />
          <div className="claims-inquiry-submit-container">
            <Button
              type="submit"
              variant="primary"
              id="claims-inquiry-submit"
              disabled={!values.claimNumber && !values.nameOrPolicy}
            >
              {Translations.Claims_Inquiry.submit}
            </Button>
          </div>
        </FormGroup>
      </Form>
      {state.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <NameSearchResults
            policies={state.policies}
            onPolicySelected={() => {
              dismissModal();
            }}
          />
          {(state.isInvalidSearch || !state.hasNameResults) && (
            <div className="invalid-search-terms">
              <HelpDeskMessage
                display="questionsOrAssistance"
                before="Unfortunately, the information you entered could not be found. Please ensure the number was entered correctly."
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

ClaimsInquiryForm.propTypes = {
  dismissModal: PropTypes.func.isRequired,
  fetchClaim: PropTypes.func.isRequired,
  fetchClaims: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  activeAgency: PropTypes.shape({
    agencyCode: PropTypes.string
  }).isRequired,
  employeeUserName: PropTypes.string,
  claimNumber: PropTypes.string
};

export default ClaimsInquiryForm;
