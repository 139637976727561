import _set from "lodash/set";
import React from "react";
import { ToolTip, Button } from "@ufginsurance/ui-kit";

// OOQ-3351 Tooltips on questions
// if the question has a tooltip... then here's the JSX for it
const QuestionToolTip = ({ tooltip, code }) => {
  // make the tooltip content support lists, text, or both
  const tooltipList = !!tooltip?.list && (
    <ul>
      {tooltip?.list.map(s => (
        <li key={s}>{s}</li>
      ))}
    </ul>
  );
  const tooltipText = !!tooltip?.text && <div>{tooltip?.text}</div>;
  return (
    <ToolTip
      id={"oq__eligibility-questions__tooltip__" + code}
      type="hover"
      position="right"
      nowrap={false}
      trigger={
        <Button
          isLink
          variant="secondary"
          className="oq__eligibility-question__tooltip-trigger"
          icon="fasQuestionCircle"
        ></Button>
      }
      variant="white"
      content={
        <div>
          {!!tooltip?.title && <h4>{tooltip?.title}</h4>}
          {tooltipText}
          {tooltipList}
        </div>
      }
      width={500}
    />
  );
};

export default QuestionToolTip;
