import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  ContentHeader,
  Panel,
  Button,
  FlexRow,
  Alert,
  Form,
  FormGroup,
  Checkbox,
  useForm
} from "@ufginsurance/ui-kit";
import {
  SuretyConstructionSpecialty,
  suretyLegalEntities
} from "../constants/suretyDictionary";
import * as routes from "../../../constants/routes";
import { toTitleCase } from "../../../components/Factory";
import {
  formatAddress,
  formatZip,
  formatPercent,
  getIsNewAccountOrObligee
} from "./../shared/util";
import { Translations } from "../../../components/translations";
import SubmissionError from "./SubmissionError";
import BondInfoSummary from "./BondInfoSummary";
import BottomNav from "../shared/BottomNav";
import { clearSuretyQuote } from "../actions/suretyQuoteActions";
import { getCacheId } from "../../selectors/cacheId";
import {
  postSuretyQuickQuote,
  updateQuickSubmissionError
} from "../actions/quickSubmissionAction";
import SubmissionProcess from "./SubmissionProcess";
import "./Summary.scss";

const Summary = ({
  agencyCode,
  producerCode,
  suretyQuote,
  history,
  quickSubmission,
  postSuretyQuickQuote,
  clearSuretyQuote,
  cacheId,
  updateQuickSubmissionError
}) => {
  const [submissionError, setSubmissionError] = useState("");
  const [readyToContinue, setReadyToContinue] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const form = useForm({
    values: {
      credit_auth_acknowledge: false
    },
    onSubmit: () => {}
  });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields
  } = form;

  const hasOrderCredit = suretyQuote?.ownerData?.some(
    g => g.order_credit === true
  );
  const isQuoteStatusQuickNew =
    suretyQuote?.status === "W" &&
    getIsNewAccountOrObligee(suretyQuote?.account_number);
  const hasModifiedOwners = suretyQuote?.ownerData?.some(
    g =>
      (!g.isNew && g.delete) ||
      (!!g.isNew && !g.delete) ||
      (!!g.origTitle && g.origTitle !== g.title) ||
      (!!g.origSsn && g.origSsn !== g.ssn) ||
      (!!g.origPct && g.origPct !== g.ownership_percent)
  );

  useEffect(() => {
    setReadyToContinue(
      hasOrderCredit ||
        hasModifiedOwners ||
        isQuoteStatusQuickNew ||
        values.credit_auth_acknowledge
    );
  }, [values, hasOrderCredit, hasModifiedOwners, isQuoteStatusQuickNew]);

  const constructionSpecialty = SuretyConstructionSpecialty.filter(
    a => a.value === suretyQuote?.construction_specialty
  ).map(s => s.label);

  const legalEntity = suretyLegalEntities
    .filter(a => a.value === suretyQuote?.business_type)
    .map(s => s.label);

  const submitToConfirmation = async () => {
    if (!quickSubmission.isLoading) {
      await postSuretyQuickQuote({
        ...suretyQuote,
        agency_code: agencyCode,
        sub_producer: producerCode,
        is_new_account: getIsNewAccountOrObligee(suretyQuote.account_number)
      });
      await clearSuretyQuote(cacheId);
      await history.push(routes.setStepUrl(6));
    }
  };

  return (
    <>
      <ContentHeader className="summary-panel">
        Let&apos;s verify the information
      </ContentHeader>
      {submissionError && (
        <Alert
          type="error"
          className="qq__step6__error"
          onDismiss={() => {
            updateQuickSubmissionError(null);
            setSubmissionError(null);
          }}
        >
          <SubmissionError
            error={submissionError}
            history={history}
            updateQuickSubmissionError={updateQuickSubmissionError}
          />
        </Alert>
      )}
      <Panel
        className="summary-panel"
        rounded
        bgcolor="grey"
        title="Review submission information"
        titlebar
      >
        <FlexRow>
          <FlexRow className="summaryColumn">
            <Panel className="section_items">
              <div className="summary_header">Customer information</div>

              <div className="qq__step6__summary__item">
                <div>{toTitleCase(suretyQuote?.account_name)}</div>
                <div>{formatAddress(suretyQuote?.address_line)}</div>
                <div className="flexRow wrap">
                  <span className="qq__step6__summary__city">
                    {toTitleCase(suretyQuote?.city)}
                  </span>
                  ,
                  <span className="qq__step6__summary__state">
                    {suretyQuote?.state}
                  </span>
                  <span className="qq__step6__summary__postalCode">
                    {formatZip(suretyQuote?.zip)}
                  </span>
                </div>
              </div>

              {suretyQuote?.pobox && suretyQuote?.address_line && (
                <div className="qq__step6__summary__item">
                  <b>Mailing Address</b>
                  <div className="qq__step6__summary__item-lines">
                    P.O. Box {suretyQuote?.pobox}
                  </div>
                  <div className="flexRow wrap">
                    <span className="qq__step6__summary__city">
                      {toTitleCase(suretyQuote?.city)}
                    </span>
                    ,
                    <span className="qq__step6__summary__state">
                      {suretyQuote?.state}
                    </span>
                    <span className="qq__step6__summary__postalCode">
                      {formatZip(suretyQuote?.zip)}
                    </span>
                  </div>
                </div>
              )}

              <div className="qq__step6__summary__item">
                <b>Legal Business Name</b>
                <div className="qq__step6__summary__item-lines">
                  {toTitleCase(suretyQuote?.legal_business_name)}
                </div>
              </div>
              {!isQuoteStatusQuickNew && (
                <div className="qq__step6__summary__item">
                  <b>Account</b>
                  <div className="qq__step6__summary__item-lines">
                    {suretyQuote?.account_number}
                  </div>
                </div>
              )}
              <div className="qq__step6__summary__item">
                <b>Legal Entity</b>
                <div className="qq__step6__summary__item-lines">
                  {legalEntity}
                </div>
              </div>
              <div className="qq__step6__summary__item">
                <b>Construction Specialty</b>
                <div className="qq__step6__summary__item-lines">
                  {constructionSpecialty}
                </div>
              </div>
              <div>
                <Button
                  isLink
                  onClick={() => history.push(routes.setStepUrl(1))}
                  disabled={quickSubmission.isLoading}
                  inline
                >
                  Edit Customer Information
                </Button>
              </div>
              <div>
                <Button
                  isLink
                  onClick={() => history.push(routes.setStepUrl(3))}
                  disabled={quickSubmission.isLoading}
                  inline
                >
                  Edit Eligibility
                </Button>
              </div>
            </Panel>
            <Panel>
              <div className="summary_header">Owner information</div>

              <div>
                {suretyQuote?.ownerData
                  ?.filter(o => o.delete === false)
                  .map(item => (
                    <div key={item.id}>
                      <div className="flexRow wrap">
                        <div className="qq__step6__summary__item">
                          <b>
                            {toTitleCase(
                              item.first_name + " " + item.last_name
                            )}
                            <span>
                              {!!item.title ? "," : ""}
                              &nbsp;{toTitleCase(item.title)}
                            </span>
                          </b>
                          <div>{formatAddress(item.address_line)}</div>
                          <div>
                            {toTitleCase(item.city)}, {item.state}{" "}
                            {formatZip(item.zip)}
                          </div>
                        </div>
                      </div>

                      {item?.pobox && item?.address_line && (
                        <div className="qq__step6__summary__item">
                          <b>Mailing Address</b>
                          <div className="qq__step6__summary__item-lines">
                            P.O. Box {item.pobox}
                          </div>
                          <div className="flexRow wrap">
                            <span className="qq__step6__summary__city">
                              {toTitleCase(item.city)}
                            </span>
                            ,
                            <span className="qq__step6__summary__state">
                              {item.state}
                            </span>
                            <span className="qq__step6__summary__postalCode">
                              {formatZip(item.zip)}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="qq__step6__summary__item">
                        <b>Ownership</b>
                        <div className="qq__step6__summary__item-lines">
                          {formatPercent(item.ownership_percent)}
                        </div>
                        <hr />
                      </div>
                    </div>
                  ))}
              </div>

              <div className="qq__step6__summary__item">
                <Button
                  isLink
                  disabled={quickSubmission.isLoading}
                  inline
                  onClick={() => history.push(routes.setStepUrl(2))}
                >
                  Edit Owner Information
                </Button>
              </div>
            </Panel>
          </FlexRow>

          <FlexRow className="summaryColumn">
            <Panel>
              <div className="summary_header">Bond information</div>
              {!!suretyQuote && !!suretyQuote.bond_information ? (
                <BondInfoSummary
                  suretyQuote={suretyQuote}
                  history={history}
                  quickSubmission={quickSubmission}
                />
              ) : (
                <div className="qq__step6__summary__item">
                  <FlexRow>
                    <span>No Bond Information</span>
                  </FlexRow>
                  <FlexRow>
                    <Button
                      isLink
                      disabled={quickSubmission.isLoading}
                      onClick={() => history.push(routes.setStepUrl(4))}
                      inline
                    >
                      + Add Bond Information
                    </Button>
                  </FlexRow>
                </div>
              )}
            </Panel>
          </FlexRow>
        </FlexRow>
      </Panel>

      <SubmissionProcess
        showModal={showModal}
        setShowModal={setShowModal}
        quickSubmission={quickSubmission}
      />

      <BottomNav>
        <FlexRow className="summary-margin">
          <div className="centered-div">
            {Translations.Surety.creditAuthTerms}
          </div>
        </FlexRow>
        <FlexRow>
          <div className="align-right summary-row">
            <Form className="credit-auth-form" context={form}>
              <div className="checkbox-div">
                <FormGroup>
                  <Checkbox
                    id="credit_auth_acknowledge"
                    name="credit_auth_acknowledge"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.credit_auth_acknowledge}
                    label={Translations.Surety.creditAuthAcknowledegment}
                    noLabel
                    className="checkbox__credit-auth"
                    required
                    requiredError="Required"
                  />
                </FormGroup>
              </div>
            </Form>
            <Button
              variant="primary"
              spinner={quickSubmission.isLoading}
              onClick={submitToConfirmation}
              disabled={
                !readyToContinue ||
                !!invalidFields.length ||
                submissionError ||
                quickSubmission.isLoading
              }
            >
              Submit
            </Button>
          </div>
        </FlexRow>
      </BottomNav>
    </>
  );
};

Summary.propTypes = {
  quickSubmission: PropTypes.object.isRequired,
  agencyCode: PropTypes.string.isRequired,
  producerCode: PropTypes.string.isRequired,
  history: PropTypes.any,
  suretyQuote: PropTypes.object,
  clearSuretyQuote: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  updateQuickSubmissionError: PropTypes.func.isRequired,
  postSuretyQuickQuote: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    quickSubmission: state.quickSubmission,
    agencyCode: sessionSelector.getActiveAgencyCode(state),
    producerCode: sessionSelector.getAgentProducerCode(state),
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state)
  };
};

const mapDispatchToProps = {
  clearSuretyQuote,
  updateQuickSubmissionError,
  postSuretyQuickQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
