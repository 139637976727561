import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, FlexRow, Modal } from "@ufginsurance/ui-kit";
import { BUILDERS_RISK, HOME } from "../../constants/routes";
import { closeProductSelectionModal } from "./ProductSelection/ProductSelectionModal";
import "./CommercialHeader.scss";
import { useFlags } from "launchdarkly-react-client-sdk";

function CommercialHeader({ history, location, closeProductSelectionModal }) {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { buildersRiskEnabled } = useFlags();

  const exitQuote = async () => {
    if (buildersRiskEnabled && location.pathname.includes(BUILDERS_RISK)) {
      closeProductSelectionModal();
    }
    setShowMessageModal(false);
    history.replace(HOME);
  };

  return (
    <div className="commercial-header">
      <div className="secondary-nav">
        <Button
          isLink
          className="exit-link"
          icon="farAngleLeft"
          onClick={() => setShowMessageModal(true)}
        >
          EXIT QUOTE
        </Button>
      </div>

      <Modal
        title="Exit Confirmation"
        className="commercial-header-modal"
        // TODO: if local storage for BR, text needs to change.
        body={
          "Data entered will NOT be saved if you exit at this time. Are you sure you want to Exit?"
        }
        footer={
          <FlexRow align="right">
            <Button
              wrapperClassName="modal-cancel"
              isLink
              inline
              onClick={() => setShowMessageModal(false)}
            >
              Cancel
            </Button>
            <Button
              wrapperClassName="modal-exit"
              variant="primary"
              onClick={exitQuote}
            >
              Exit
            </Button>
          </FlexRow>
        }
        show={showMessageModal}
        onHide={() => setShowMessageModal(false)}
        altCloseMethod={false}
        size="sm"
      />
    </div>
  );
}

export default connect(null, { closeProductSelectionModal })(CommercialHeader);
