import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Table,
  formatDate,
  Currency,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchPendingTransactions,
  withdrawPolicyDraft
} from "./redux/policyChangesService";
import { POLICY_TRANSACTION_TYPES } from "../constants/dictionary";
import HelpDeskMessage from "../help-desk/helpDeskMessage";
import { toast } from "react-toastify";
import {
  POLICY_TRANSACTION_STATUS_TYPES,
  mapPolicyChangeUrl,
  POLICY_WIZARD_STEPS
} from "./constants_helpers";
import { useFlags } from "launchdarkly-react-client-sdk";

const { ISSUED, QUOTED, WITHDRAWN, QUOTING } = POLICY_TRANSACTION_STATUS_TYPES;

const PolicyTransactionsTable = ({
  policyNumber,
  fetchRequestEligibility,
  fetchPolicyDraft,
  agencyCode,
  disableActionButtons
}) => {
  const { driverChangesPhase2 } = useFlags();

  const history = useHistory();

  const [policyTransactions, setPolicyTransactions] = useState([]);
  const [loadingPolicies, setLoadingPolicies] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const UNACTIONABLE_POLICY_STATUS_TYPES = useMemo(() => {
    const statuses = [ISSUED, WITHDRAWN, QUOTING];

    if (!driverChangesPhase2) {
      statuses.push(QUOTED);
    }

    return statuses;
  }, [driverChangesPhase2]);

  const displayToastError = () => {
    const options = {
      position: "top-center",
      className: "error",
      autoClose: false
    };

    toast(<HelpDeskMessage display="anErrorOccurred" />, options);
  };

  const onEditPolicyClick = (job_number, status) => {
    setDisableButton(true);

    fetchPolicyDraft(policyNumber, job_number).then(
      response => {
        if (!response) {
          displayToastError();
          setDisableButton(false);
        } else {
          const policyWizardStep =
            status === QUOTED
              ? POLICY_WIZARD_STEPS.TWO
              : POLICY_WIZARD_STEPS.ONE;

          const policyChangeUrl = mapPolicyChangeUrl(
            policyNumber,
            job_number,
            policyWizardStep
          );
          history.push(policyChangeUrl);
        }
      },
      () => {
        displayToastError();
        setDisableButton(false);
      }
    );
  };

  const onRemovePolicyClick = job_number => {
    setDisableButton(true);

    setLoadingPolicies([...loadingPolicies, job_number]);
    withdrawPolicyDraft(policyNumber, job_number).then(
      response => {
        if (response.status !== 200) {
          setLoadingPolicies(loadingPolicies.filter(id => id !== job_number));
          displayToastError();
        } else {
          fetchRequestEligibility(policyNumber, agencyCode);
        }

        setDisableButton(false);
      },
      () => {
        setLoadingPolicies(loadingPolicies.filter(id => id !== job_number));
        displayToastError();
        setDisableButton(false);
      }
    );
  };

  const columns = [
    {
      key: "effective_date",
      label: "Effective Date",
      sortable: true,
      className: !disableActionButtons ? "effective-date-col" : "",
      element: row => formatDate(row.effective_date, "MM/DD/YYYY")
    },
    {
      key: "description",
      label: "Type of Change",
      sortable: false,
      className: !disableActionButtons ? "type-of-change-col" : "",
      element: row => row.description
    },
    {
      key: "created_by",
      label: "Created By",
      sortable: false,
      className: !disableActionButtons ? "created-by-col" : "",
      element: row => row.created_by
    },

    {
      key: "adjusted_premium",
      label: "Premium Change",
      sortable: false,
      className: !disableActionButtons ? "premium-change-col" : "",
      element: row => (
        <div className="row">
          {!!row.adjusted_premium && row.adjusted_premium >= 0 && (
            <span>+ </span>
          )}{" "}
          <Currency includeDecimals amount={row.adjusted_premium} />
        </div>
      )
    },
    {
      key: "status",
      label: "Status",
      sortable: true,
      className: !disableActionButtons ? "status-col" : "",
      element: row => {
        const color = row.status === ISSUED ? "#2A8200" : "#DE8800";
        return (
          <>
            <span
              className="policy-changes-status-text"
              data-job-number={`${row.job_number}`}
            >
              <FontAwesomeIcon icon={faCheckCircle} color={color} />
              &nbsp;&nbsp;
              {row.status}
            </span>
          </>
        );
      }
    },
    {
      key: "actions",
      label: "",
      className: `pending-changes-actions ${
        !disableActionButtons
          ? "pending-changes-actions-fixed-width"
          : "pending-changes-actions-no-width"
      }`,
      element: row => {
        const isLoading = loadingPolicies.length > 0;

        return (
          <>
            {!disableActionButtons &&
              !UNACTIONABLE_POLICY_STATUS_TYPES.includes(row.status) &&
              row.is_agency_created &&
              row.uw_locked && (
                <div className="pending-changes-actions__buttons">
                  Under Review
                </div>
              )}
            {!disableActionButtons &&
              !UNACTIONABLE_POLICY_STATUS_TYPES.includes(row.status) &&
              row.is_agency_created &&
              !row.uw_locked && (
                <div className="pending-changes-actions__buttons">
                  <Button
                    isLink
                    inline
                    disabled={disableButton}
                    icon="fasEdit"
                    className={`policy-change-edit-icon`}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      onEditPolicyClick(row.job_number, row.status);
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    variant="tertiary"
                    isLink
                    inline
                    disabled={disableButton}
                    icon="farTrashAlt"
                    className={`policy-change-trash-icon`}
                    onClick={() => {
                      if (isLoading) {
                        return;
                      }

                      onRemovePolicyClick(row.job_number);
                    }}
                  >
                    Remove
                  </Button>

                  {isLoading && (
                    <LoadingIndicator
                      type="spinner"
                      className={"policy-changes-loading-spinner"}
                      message={""}
                    />
                  )}
                </div>
              )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    fetchPendingTransactions(
      policyNumber,
      POLICY_TRANSACTION_TYPES.POLICY_CHANGE
    ).then(res => {
      if (!!res.data) {
        setPolicyTransactions(res.data);
      }
    });
  }, [policyNumber]);

  return (
    <div id="pendingChanges">
      <div>
        <h3>
          Pending and Recent Changes: <br />
          <span className="change-request-header-span">
            (Includes issued changes created in the last 60 days.)
          </span>
        </h3>
      </div>
      {policyTransactions.length === 0 ? (
        <p id="noPendingChangesMessage">No changes.</p>
      ) : (
        <Table
          id="pendingChangesTable"
          data={policyTransactions}
          columns={columns}
          showPagination
          rowKey="job_number"
          itemsPerPage={25}
          initialSort="effective_date"
          initialDirection="desc"
        />
      )}
    </div>
  );
};

export default PolicyTransactionsTable;
