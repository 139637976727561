import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./../../components/translations";
import { logoMini, logoSmall, logoLarge } from "../../constants/images";
import { Button, Modal } from "@ufginsurance/ui-kit";

const LogoUsage = ({ openModal }) => {
  const renderLogoUsageTerms = () => (
    <ul>
      <li>
        {Translations.formatString(
          Translations.website_logo.logo_usage_term,
          <a href="mailto:corpcomm@unitedfiregroup.com">
            {Translations.website_logo.logo_usage_term_email}
          </a>
        )}
      </li>
      <li>{Translations.website_logo.logo_usage_term_two}</li>
      <li>
        {Translations.website_logo.logo_usage_term_three}{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.ufginsurance.com"
        >
          {Translations.website_logo.website_link}
        </a>
      </li>
      <li>{Translations.website_logo.logo_usage_term_four}</li>
      <li>
        {Translations.website_logo.logo_usage_term_five}
        <span
          className="agreement-link"
          role="presentation"
          onClick={openModal("agency_linking")}
        >
          {Translations.website_logo.logo_usage_term_five_link}
        </span>
      </li>
    </ul>
  );

  return (
    <div className="agency-linking-container">
      <Modal
        size="lg"
        title={Translations.website_logo.header.logo_usage_header}
        show
        onHide={openModal(null)}
        body={
          <div className="agency-linking-modal__body">
            <p>{Translations.website_logo.logo_usage_data}</p>
            {renderLogoUsageTerms()}
            <p>{Translations.website_logo.logo_usage_declaration}</p>
            <div />
            <div className="row logo-usage">
              <div>
                <img
                  alt="UFG Insurance"
                  data-displaymode="Original"
                  className="ufg-mini-icon"
                  src={logoMini}
                  title="logo 100 pixels"
                />
              </div>
              <div>
                <img
                  alt="UFG Insurance"
                  data-displaymode="Original"
                  className="ufg-small-icon"
                  src={logoSmall}
                  title="logo 125 pixels"
                />
              </div>
              <div>
                <img
                  alt="UFG Insurance"
                  data-displaymode="Original"
                  className="ufg-large-icon"
                  src={logoLarge}
                  title="logo 150 pixels"
                />
              </div>
            </div>
            <div className="col-sm-offset-9">
              <Button
                type="reset"
                variant="secondary"
                onClick={openModal(null)}
              >
                {Translations.general.cancel}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

LogoUsage.propTypes = {
  openModal: PropTypes.func.isRequired
};

export default LogoUsage;
