import * as WebsiteLogoService from "./WebsiteLogoService";
import * as types from "./WebsiteLogoActionTypes";

const postAgencyLinkingFormStarted = () => ({
  type: types.POST_AGENCY_LINKING_FORM_STARTED
});

const postAgencyLinkingFormSucceeded = data => ({
  type: types.POST_AGENCY_LINKING_FORM_SUCCEEDED,
  payload: data
});

const postAgencyLinkingFormFailed = error => ({
  type: types.POST_AGENCY_LINKING_FORM_FAILED,
  payload: error
});

export const resetWebsiteLogoState = () => ({
  type: types.RESET_WEBSITE_LOGO
});

export const updateWebsiteLogoState = payload => ({
  type: types.RESET_WEBSITE_LOGO,
  payload
});

export const postAgencyLinkingDetails = body => dispatch => {
  dispatch(postAgencyLinkingFormStarted());
  WebsiteLogoService.postAgencyLinkingDetails(body).then(
    res => {
      dispatch(postAgencyLinkingFormSucceeded(res.data));
    },
    error => dispatch(postAgencyLinkingFormFailed(!!error.response))
  );
};
