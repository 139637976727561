import { Translations } from "../../components/translations";
import {
  BILLING_RESOURCES,
  CLAIMS_RESOURCES,
  AGENCY_INTERFACE
} from "../../constants/routes";

export const resourcesLinks = [
  {
    to: BILLING_RESOURCES,
    activeClassName: "nav-menu--active",
    text: Translations.Resources.nav.billing
  },
  {
    to: CLAIMS_RESOURCES,
    activeClassName: "nav-menu--active",
    text: Translations.Resources.nav.claims
  },
  {
    to: AGENCY_INTERFACE,
    activeClassName: "nav-menu--active",
    text: Translations.Resources.nav.agency_interface
  }
];
