import { useEffect, useRef, useState } from "react";
import { logger } from "../../../loggers";
import { getCustomerInfoMetaData } from "../../../services/onlineQuotingService";

export default function useGetContactDetailsMeta(locationState) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (data || isLoading || error.current) return;

    setIsLoading(true);

    if (locationState?.meta && !data) {
      setData(locationState.meta);
      setIsLoading(false);
    } else {
      getCustomerInfoMetaData()
        .then(res => {
          if (res.status === 200 && res.data) {
            setData(res.data?.formData);
          } else {
            handleError();
          }
        })
        .catch(err => {
          handleError();
          logger.error({
            description: err.toString(),
            fatal: false
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [data, isLoading, locationState]);

  return [data, isLoading];
}
