import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  massageMetadata,
  transformAnswersToBoolForForm,
  filterOutAutoIfHNO,
  removeDuplicateQuestions
} from "../../shared/util";
import * as api from "../../../services/onlineQuotingService";
import { LoadingIndicator, FlexRow, Button } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import { productKeys } from "../../shared/constants";
import getQuestions from "../../step3/getQuestions";
import FormSetup from "../../shared/FormSetup";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import EligibilityCheckbox from "../../step3/EligibilityCheckbox";

const EligibilityQuestions = ({ onNextStep, onPrevStep, onCancel }) => {
  const { quoteData, supportingData, updateSupportingDataPromise, toastErrr } =
    useContext(OnlineQuotingContext);

  //Note current step is 0 indexed...i.e 0,1,-- numberProducts - 1
  const [currStep, setElgStep] = useState(0);
  const [metadata, setMetadata] = useState();
  const [panelGroups, setPanelGroups] = useState([]);
  const [totalSteps, setTotalSteps] = useState(1);
  const [checkboxValidation, setCheckboxValidation] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [cancelSpinner, setCancelSpinner] = useState(false);

  // questionValues is the collection of all of the current questions
  // ... and is populated from the FormSetup
  const [questionValues, setQuestionValues] = useState();
  const [formIsInvalid, setFormIsInvalid] = useState();

  useEffect(() => {
    if (!metadata) {
      api
        .getUnAnsweredEligibilityQuestions(quoteData?.quoteID)
        .then(results => {
          const newMetadata = results.data;
          setMetadata(newMetadata);

          //OOQ-4177  combine all the rows into one panel.
          const panelGroups = [];
          const products = filterOutAutoIfHNO(quoteData, supportingData);

          products.forEach(product => {
            let panels = [];
            //common sections seperated out and should be put at the top of bp7BusinessOnwers
            if (product === "bp7BusinessOwners") {
              const commonPanel = getQuestions(newMetadata, "common");
              const bp7Panel = getQuestions(newMetadata, "bp7BusinessOwners");

              //  if we have common questions, combine them with BOP and remove duplicates
              // if no commons, then just use the BOP questions
              panels = commonPanel?.length
                ? removeDuplicateQuestions([...commonPanel, ...bp7Panel])
                : bp7Panel;
            } else {
              panels = newMetadata ? getQuestions(newMetadata, product) : [];
            }

            if (panels.length) {
              // insert the panel title to the questions
              panels[0].title = `Questions for ${productKeys[product].label}`;

              panelGroups.push({
                product,
                panels,
                eligibilityChecked:
                  !!supportingData[product]?.dateTermsCheckCaptured
              });
            }
          });

          // if no questions are avail, then continue
          if (!panelGroups.length) {
            setSpinner(true);

            const dataToMergeAndSave = {
              tempAdditionalClassCodes: null
            };
            updateSupportingDataPromise({ dataToMergeAndSave }).then(() => {
              setSpinner(false);
              toast("Class codes have been updated", {
                position: "top-center",
                className: "success"
              });
              onNextStep();
            });
          } else {
            // otherwise, update the panelgroups
            setPanelGroups(panelGroups);
            setTotalSteps(panelGroups.length - 1);
          }
        })
        .catch(error => {
          toastErrr({
            action: "getUnAnsweredEligibilityQuestions",
            description: "unable to get questions",
            error
          });
        });
    }
    // don't run again when loadingData is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, quoteData, supportingData]);

  const handleFormSubmit = useCallback(() => {
    if (formIsInvalid) {
      return false;
    }

    toast.dismiss();

    const product = panelGroups[currStep].product;
    const formValues = { ...questionValues?.values };

    //use null for items not filled in.
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === true) formValues[key] = "true";
      if (formValues[key] === false) formValues[key] = "false";
    });

    const payload = {
      sessionUUID: quoteData.sessionUUID,
      answers: { values: formValues }
    };
    setSpinner(true);
    api
      .updateEligibilityQuestions(quoteData.quoteID, payload)
      .then(results => {
        // save checkbox timestamp and the answers in supporting data
        let dataToMergeAndSave = {
          ...results.data.supportingData,
          [product]: {
            ...results.data.supportingData[product],
            dateTermsCheckCaptured: new Date().toLocaleDateString()
          }
        };

        // update the checkbox confirmation value

        if (currStep + 1 <= totalSteps) {
          const st = currStep + 1;
          updateSupportingDataPromise({ dataToMergeAndSave }).then(() => {
            setSpinner(false);
            setElgStep(st);
          });
        } else if (currStep + 1 > totalSteps) {
          dataToMergeAndSave = {
            ...dataToMergeAndSave,
            tempAdditionalClassCodes: null
          };
          updateSupportingDataPromise({ dataToMergeAndSave }).then(() => {
            setSpinner(false);
            toast("Class codes have been updated", {
              position: "top-center",
              className: "success"
            });
            onNextStep();
          });
        }
      })
      .catch(error => {
        toastErrr({
          action: "updateEligibilityQuestions",
          description: "unable to save questions",
          error,
          payload
        });
      });
  }, [
    formIsInvalid,
    panelGroups,
    currStep,
    questionValues,
    quoteData,
    totalSteps,
    updateSupportingDataPromise,
    onNextStep,
    toastErrr
  ]);

  return (
    <>
      {!metadata ? (
        <LoadingIndicator />
      ) : (
        !!panelGroups.length && (
          <div className="oq__classes__in-modal__form">
            <FormSetup
              key={productKeys[panelGroups[currStep].product].label}
              sectionHeader=""
              panels={massageMetadata(
                panelGroups[currStep].panels,
                transformAnswersToBoolForForm(
                  supportingData?.answered_questions?.values
                )
              )}
              setFormIsInvalid={setFormIsInvalid}
              onValuesUpdate={setQuestionValues}
              hideSubmitBtn
            />
            <FlexRow>
              <EligibilityCheckbox
                key={productKeys[panelGroups[currStep].product].label}
                checkboxIsChecked={false}
                setCheckboxValidation={setCheckboxValidation}
                products={[panelGroups[currStep].product]}
              />
            </FlexRow>
            <FlexRow>
              <Button
                isLink
                onClick={onPrevStep}
                disabled={spinner || cancelSpinner}
              >
                Back to Classes
              </Button>

              <Button
                isLink
                wrapperClassName="push-right"
                onClick={() => {
                  setCancelSpinner(true);
                  onCancel(() => {
                    setCancelSpinner(false);
                  });
                }}
                spinner={cancelSpinner}
                disabled={spinner || cancelSpinner}
                variant="plain"
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                onClick={handleFormSubmit}
                disabled={
                  formIsInvalid ||
                  !(supportingData?.tempAdditionalClassCodes || [])?.length ||
                  !checkboxValidation ||
                  spinner ||
                  cancelSpinner
                }
                spinner={spinner}
                wrapperClassName=""
              >
                {currStep + 1 > totalSteps ? "Update" : "Continue"}
              </Button>
            </FlexRow>
          </div>
        )
      )}
    </>
  );
};
export default EligibilityQuestions;
