import React from "react";
import PropTypes from "prop-types";
import { Table, ExternalLink, formatDate } from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../../components/Factory";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

const CommercialQuotesTable = ({ commercialQuotesList }) => {
  const getColumns = [
    {
      key: "effective_date",
      label: "Effective Date",
      element: ({ effective_date }) => {
        return <span>{formatDate(effective_date, "MM/DD/YYYY")}</span>;
      }
    },
    {
      key: "account_name",
      label: "Account",
      element: row => <span>{toTitleCase(row.account_name)}</span>
    },
    {
      key: "bond_number",
      label: "Action",
      element: row => {
        return (
          <div className={"surety-purchase-commercial-quote__buttons"}>
            <ExternalLink
              to={`${baseLink}/AgentsOnly/ufgBonds/PurchaseBond_Portal.aspx?bondNumber=${row.bond_number}&type=D`}
            >
              Decline
            </ExternalLink>
            &nbsp;
            <ExternalLink
              to={`${baseLink}/AgentsOnly/ufgBonds/PurchaseBond_Portal.aspx?bondNumber=${row.bond_number}&type=P`}
            >
              Purchase
            </ExternalLink>
          </div>
        );
      }
    }
  ];
  return (
    <div className="">
      <Table
        rowKey="bond_number"
        data={commercialQuotesList}
        columns={getColumns}
        itemsPerPage={5}
        showPagination
      />
    </div>
  );
};

CommercialQuotesTable.propTypes = {
  commercialQuotesList: PropTypes.array.isRequired
};

export default CommercialQuotesTable;
