import { useEffect, useState } from "react";
import { getCommercialQuotes } from "../../../components/home/surety/suretyTabService";
import { logger } from "../../../loggers";

export default function useFetchCommercialQuotes(agencyCode) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!agencyCode) return;

    getCommercialQuotes(agencyCode)
      .then(res => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch(err => {
        console.error(err);
        logger.error({
          description: err.toString(),
          fatal: false
        });
        setError(err);
      })
      .finally(() => setIsLoading(false));
  }, [agencyCode]);

  return [data, isLoading, error];
}
