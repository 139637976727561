import React, { useState } from "react";
import { PanelGrid, Button, FlexRow, Panel, Modal } from "@ufginsurance/ui-kit";
import "./PreQuoteModal.scss";
import * as routes from "../../constants/routes";
import { useFlags } from "launchdarkly-react-client-sdk";

const PreQuoteModal = ({
  history,
  onContinue,
  showPreQuoteModal,
  setShowPreQuoteModal
}) => {
  const { proQuoteMonolinePolicy } = useFlags();
  const [showText, setShowText] = useState(false);

  if (!showPreQuoteModal) return null;

  return (
    <Modal
      title="Start a Quote"
      show
      className="oq__prequote__modal"
      altCloseMethod={false}
      body={
        <div>
          <Panel title="Underwriting Guidelines" rounded>
            <PanelGrid
              className="oq__prequote__panel-container"
              sizes={{ sm: 4, md: 4, lg: 4, xl: 4 }}
            >
              <Panel className="oq__prequote__custom-panel" bgcolor="grey">
                <b>Max Sales</b>
                <h3>$10M</h3>
                <b>Most industries, per location</b>
                <p>
                  * Restaurants & Contractors $6M
                  <br />* Lessors&apos; Risk $5M
                </p>
              </Panel>
              <Panel className="oq__prequote__custom-panel" bgcolor="grey">
                <b>Max Property (TIV)</b>
                <h3>$7M</h3>
                <b>Most industries, per location</b>
                <p>* Contractors $3M</p>
              </Panel>
              <Panel className="oq__prequote__custom-panel" bgcolor="grey">
                <b>Building</b>
                <h3>36-49 Years</h3>
                <b>with updates</b>
                <p>* 50+ years with full rehab</p>
              </Panel>
              <Panel className="oq__prequote__custom-panel" bgcolor="grey">
                <b>Max Payroll (Contractors)</b>
                <h3>$1M</h3>
                <b>&nbsp;</b>
                <p>* Sub-costs less than 25% of total sales</p>
              </Panel>
            </PanelGrid>
          </Panel>

          <PanelGrid
            className="oq__prequote__panel-container"
            sizes={{ sm: 3, md: 3, lg: 3, xl: 3 }}
          >
            <Panel
              className="oq__prequote__panel oq__prequote__panel__wide"
              rounded
              title="To best support straight-through processing, follow these
                guidelines:"
            >
              <ul>
                <li>
                  Buildings built within the last 35 years or have had updates
                  within the last 15 years.
                </li>
                <li>Up to $2M Commercial Umbrella (up to $5M available).</li>
              </ul>
              <p>
                <b>Note:</b> &nbsp;If your account consists of more than 10
                locations or auto units, complete the quote on ProQuote
                including the first 10 locations and/or first 10 auto
                units.&nbsp;
                {showText && (
                  <span>
                    Please inform the underwriter upon referral that additional
                    locations and/or auto units need to be added. The
                    underwriter will be in contact to obtain the Acord
                    application from you directly to then enter the remaining
                    locations and/or auto units for you.
                  </span>
                )}
                <span className="oq__prequote__panel__block-span">
                  <Button onClick={() => setShowText(!showText)} inline isLink>
                    {showText ? "View Less" : "View More"}
                  </Button>
                </span>
              </p>
            </Panel>
            <Panel
              rounded
              title="Other Actions"
              className="oq__prequote__panel"
            >
              <p>
                <Button
                  isLink
                  inline
                  onClick={() => {
                    setShowPreQuoteModal(false);
                    history.push(routes.COMMERCIAL_LINES);
                  }}
                >
                  Looking for an existing quote?
                </Button>
              </p>
              <p>
                <Button
                  isLink
                  inline
                  onClick={() => {
                    setShowPreQuoteModal(false);
                    history.push("/appetite");
                  }}
                >
                  Visit our interactive appetite quite
                </Button>
              </p>
            </Panel>
          </PanelGrid>

          <FlexRow className="oq__prequote__buttons" align="right">
            {proQuoteMonolinePolicy && (
              <Button onClick={() => history.push("/proquote")}>
                Use new ProQuote
              </Button>
            )}

            <Button
              onClick={() => {
                setShowPreQuoteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setShowPreQuoteModal(false);
                onContinue();
              }}
            >
              Continue
            </Button>
          </FlexRow>
        </div>
      }
      onHide={() => {
        setShowPreQuoteModal(false);
      }}
      size="xl"
    />
  );
};
export default PreQuoteModal;
