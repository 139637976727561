import React from "react";
import { locationDisplayName } from "../../shared/util";
import { Button, Icon } from "@ufginsurance/ui-kit";

const BuildingCardTitle = ({ building, onClickHandler }) => {
  if (!building.isMissingInfo || !building.isPrefill)
    return (
      <div className="oq__coverable__card__initial-content__container">
        <span className="oq__coverable__card__initial-content">
          <span className="oq__coverable__card__initial-content__title">
            {building?.description}
          </span>
          <span className="oq__coverable__card__initial-content__secondary">
            {(building?.classifications || [])
              .map(c => c.classDescription)
              .join(", ")}
          </span>
          <span className="oq__coverable__card__initial-content__extended">
            {locationDisplayName(building.location)}
          </span>
        </span>
      </div>
    );

  // buliding is a BopPrefill building, ask the user to finish it
  return (
    <div className="oq__coverable__card__missing__add oq__coverable__card__missing__add__building">
      <span className="oq__coverable__card__warning__content">
        {
          <span className="oq__coverable__card__initial-content">
            <span className="oq__coverable__card__initial-content__secondary">
              <Icon
                className="oq__coverable__card__warning__icon"
                icon="fasExclamationTriangle"
              />{" "}
              We found a building associated with:
            </span>
            <span className="oq__coverable__card__initial-content__extended">
              {locationDisplayName(building.location)}
            </span>
          </span>
        }
      </span>
      <Button
        variant="info"
        wrapperClassName="oq__coverable__add-button-wrapper__missing oq__coverable__add-button-wrapper__prefill-missing"
        className="oq__coverable__card__add__button"
        onMouseDown={onClickHandler} // using onMouseDown here to get around the issue of the collapsing panel OOQ-11135
        onClick={() => {}}
        size="sm"
      >
        Finish Building
      </Button>
    </div>
  );
};

export default BuildingCardTitle;
