import { routes } from "@ufginsurance/sso-oidc-client-react";
import { Log } from "oidc-client";

const ssoBaseUrlAPI = window.env.REACT_APP_SSO_BASEURL;
const ufgAgentClientId = window.env.REACT_APP_SSO_CLIENT_ID;

Log.logger = console;
Log.level = Log.ERROR;

export const config = {
  client_id: ufgAgentClientId,
  redirect_uri: `${window.location.origin}${routes.OIDC_SSO_CALLBACK}`,
  response_type: "id_token token",
  scope: "openid profile agent",
  authority: `${ssoBaseUrlAPI}identity`,
  post_logout_redirect_uri: `${window.location.origin}${routes.HOME}`,
  filterProtocolClaims: true,
  loadUserInfo: false,
  clockSkew: 900
};
