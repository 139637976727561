import React, { useState } from "react";
import { connect } from "react-redux";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import {
  Anchor,
  ContentHeader,
  Icon,
  LoadingIndicator,
  Panel,
  Popover
} from "@ufginsurance/ui-kit";
import useGetAccount from "./hooks/useGetAccount";
import "./AccountOverview.scss";
import { createAddressZone, formatCurrencyUSD } from "../shared/utils";
import DefaultErrorResponse from "../../shared/error/DefaultErrorResponse";
import AccountServices from "./components/AccountServices";
import RequestNameChangeModal from "./components/RequestNameChangeModal";
import RequestAddressChangeModal from "./components/RequestAddressChangeModal";
import AccountUwModal from "./components/AcountUwModal";
import { DateFormat, toTitleCase } from "../../components/Factory";

function AccountOverview({ agencyCode, match: { params } }) {
  const [showAddressChangeModal, setShowAddressChangeModal] = useState(false);
  const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showUwModal, setShowUwModal] = useState(false);
  const [account, isLoading, error] = useGetAccount(
    agencyCode,
    params.account_number
  );

  const addressZone = createAddressZone(account);

  if (isLoading) return <LoadingIndicator color="blue" />;
  if (error || (!isLoading && !account)) return <DefaultErrorResponse />;

  const underwriter = account?.surety_underwriter || {};

  return (
    <div className="account-overview">
      <ContentHeader>Account Overview</ContentHeader>

      <div className="account-overview__account-details">
        <Panel bgcolor="grey" noBorder>
          <Icon icon="farAddressCard" className="icon" />

          <div className="details">
            <div className="account-name">
              {account?.account_name}
              <br />
              <Anchor onClick={() => setShowNameChangeModal(true)}>
                Request Name Change
              </Anchor>
            </div>
            <div className="account-number mt-1">
              <strong>Account #: </strong>
              <span>{params?.account_number}</span>
            </div>

            <div className="street-address mt-1">
              <strong>Address:</strong>
              <div>{account?.account_street_address}</div>
              <div>{addressZone}</div>
              <Anchor
                className="mt-sm"
                onClick={() => setShowAddressChangeModal(true)}
              >
                Request Address Change
              </Anchor>
            </div>
          </div>
        </Panel>

        <Panel bgcolor="grey" noBorder>
          <Icon icon="farFileAlt" className="icon" />

          <div className="account-status-details">
            <div>
              <strong>Underwriter: </strong>
              <span>
                {!!underwriter?.email ? (
                  <Anchor onClick={() => setShowUwModal(true)}>
                    {underwriter.first_name} {underwriter.last_name}
                  </Anchor>
                ) : (
                  <>Unavailable</>
                )}
              </span>
            </div>
            <div>
              <strong>Account Credit Line: </strong>
              <span>
                {account?.cr_job ? formatCurrencyUSD(account.cr_job) : "-"} /{" "}
                {account?.cr_aggr ? formatCurrencyUSD(account.cr_aggr) : "-"}{" "}
                <Popover
                  id="singleAggregate"
                  trigger="hover"
                  triggerContent={<Icon icon="fasInfoCircle" />}
                  popoverContent={<span>Single/Aggregate</span>}
                />
              </span>
            </div>
            <div>
              <strong>Expires: </strong>
              <span>
                {account?.line_expiration && account?.line_expiration !== "0"
                  ? DateFormat(account.line_expiration, "MM/DD/YYYY")
                  : "-"}
              </span>
            </div>
            <div>
              <strong>Rate Type: </strong>
              <span>{toTitleCase(account?.rate_type) || "-"}</span>
            </div>
            <div>
              <strong>File Status: </strong>
              <span>{toTitleCase(account?.account_status) || "-"}</span>
            </div>
            <div>
              <strong>RMF: </strong>
              <span>
                {account?.rmf && account?.rmf !== "0"
                  ? toTitleCase(account.rmf)
                  : "-"}
              </span>
            </div>
            <div>
              <strong>Default Class: </strong>
              <span>{toTitleCase(account?.def_class) || "-"}</span>
            </div>
            <div>
              <strong>Subtype: </strong>
              <span>{toTitleCase(account?.subtype) || "-"}</span>
            </div>
          </div>
        </Panel>
      </div>

      <AccountServices account={account} agencyCode={agencyCode} />

      <RequestNameChangeModal
        show={showNameChangeModal}
        address={{ addressLine: account?.account_street_address, addressZone }}
        account={account}
        setShowModal={setShowNameChangeModal}
      />

      <RequestAddressChangeModal
        show={showAddressChangeModal}
        address={{ addressLine: account?.account_street_address, addressZone }}
        account={account}
        setShowModal={setShowAddressChangeModal}
      />

      <AccountUwModal
        showUwModal={showUwModal}
        setShowUwModal={setShowUwModal}
        underwriter={underwriter}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  agencyCode: getSelectedAgencyCode(state)
});

export default connect(mapStateToProps, {})(AccountOverview);
