/* 
pull all of the coverages from the quote
example coverage path object: {
    productName: "bp7BusinessOwners",
    coveragesPath: "lobData.bp7BusinessOwners.coverages.clausesGroups.coverages",
    addlCoveragesPath: "lobData.bp7BusinessOwners.coverages.clausesGroups.addlCoverages",
    defaultCoveragesPath: "lobData.bp7BusinessOwners.coverages.clausesGroups.defaults"
}
as we gather up the coverages from each of these groups,
add a "coveragesPath" property so we can know which group the coverage came from
*/

import { getCoverageGroupPath } from "../shared/constants";
import _set from "lodash/set";
import { getCoverageDetailsForLogging } from "../shared/coveragePanels";

const getAllCoverages = ({ coveragePaths, getQuotePart }) => {
  const standardCoveragess = getQuotePart(
    coveragePaths.coveragesPath,
    true
  ).map(f => {
    f.coveragesPath = coveragePaths.coveragesPath;
    return f;
  });

  const additionalCoveragess = getQuotePart(
    coveragePaths.addlCoveragesPath,
    true
  ).map(f => {
    f.coveragesPath = coveragePaths.addlCoveragesPath;
    return f;
  });

  const defaultCoverages = getQuotePart(
    coveragePaths.defaultCoveragesPath,
    true
  ).map(f => {
    f.coveragesPath = coveragePaths.defaultCoveragesPath;
    return f;
  });

  const addlInsdCoverages = getQuotePart(
    coveragePaths.addlInsdCoveragesPath,
    true
  ).map(f => {
    f.coveragesPath = coveragePaths.addlInsdCoveragesPath;
    return f;
  });

  const contractorBus = getQuotePart(
    coveragePaths.contractorBusCoveragesPath,
    true
  ).map(f => {
    f.coveragesPath = coveragePaths.contractorBusCoveragesPath;
    return f;
  });

  return [
    ...standardCoveragess,
    ...additionalCoveragess,
    ...defaultCoverages,
    ...addlInsdCoverages,
    ...contractorBus
  ];
};

export default getAllCoverages;

const getProductName = path => {
  const items = String(path).split(".");
  if (items && items.length > 1) {
    return `${items[1]}`;
  }
  return null;
};

export const saveLineLevelCoverages = ({
  showUpdatingToast,
  closeUpdatingToast,
  updateCoveragesPromise,
  updateSupportingDataPromise,
  coverage,
  termToUpdate,
  action = "added"
}) => {
  const productName = getProductName(coverage.coveragesPath);
  if (!productName) return;

  coverage.updated = true;
  coverage.selected = action === "added";

  const coveragePath = `${productName}.${getCoverageGroupPath(
    coverage.coverageCategoryCode
  )}`;
  const coveragesToSave = {};
  _set(coveragesToSave, coveragePath, [coverage]);

  // Saving the coverage:`Damage To Premises Rented To You`
  // should only update the supporting data, then Mule reads the
  // value from supporting data and applies it to the building(s) when buildings are saved.
  if (coverage.publicID === "BP7StructureTenantsFireLiab") {
    if (updateSupportingDataPromise) {
      const toastId = Math.random();
      showUpdatingToast({
        message: "Updating Damage To Premises Rented To You",
        toastId
      });
      updateSupportingDataPromise({
        dataToMergeAndSave: {
          policyCoverages: {
            damageToPremisesRentedToYouCoverage: Number(
              termToUpdate.directValue
            )
          }
        }
      }).then(() => closeUpdatingToast({ toastId }));
    }
    return; // stop here... don't send anythin gto PC
  }

  updateCoveragesPromise({
    coveragesToSave,
    productName,
    coverageDetails: getCoverageDetailsForLogging({
      coverage,
      termToUpdate,
      action
    })
  }).catch(({ error }) => console.error({ error }));
};
