import React from "react";

/**
 * BP7BusinessIncome - show custom text at bottom of coverage panel
 * https://ufginsurance.atlassian.net/browse/OOQ-14337
 */
export const BP7BusinessIncome = {
  jsxAtTop: () => (
    <div className="oq__BP7BusinessIncome__after-jsx">
      Actual Loss Sustained - 12 Months Included
    </div>
  )
};
