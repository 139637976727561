import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import CustomerInfo from "./CustomerInfo";
import {
  fetchAgencyProducers,
  fetchAgencyAccounts,
  fetchOwners,
  clearOwners
} from "../actions/CustomerInfoAction";
import {
  updateSuretyQuoteData,
  clearSuretyQuote
} from "../actions/suretyQuoteActions";
import { verifyAddress } from "../actions/verifyAddressAction";
import { fetchSuretyQuoteAgencyData } from "../actions/suretyQuoteAgencyDataAction";
import { fetchSuretyZip } from "../actions/suretyZipAction";
import { getCacheId } from "../../selectors/cacheId";
import {
  validateOwnersSsn,
  validateOwnershipPercentage,
  validateHasWholeNumberOwnershipPercents
} from "../../selectors/ownersData";

const stateSelector = state =>
  state.suretyQuoteAgencyData?.data?.licensed_states;

const ownersSelector = state =>
  //if nothing in form state, use account data
  state?.suretyQuote?.data?.ownerData || state?.quickOwnersList?.data

const mapStateToProps = (state, ownProps) => {
  return {
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    quoteData: ownProps.quoteData,
    updateQuote: ownProps.updateQuote,
    agencyProducersListData: state.agencyProducersList,
    agencyAccountsListData: state.quickAgencyAccountsList,
    ownersListData: state.quickOwnersList,
    verifyAddressData: state.quickVerifyAddress,
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state),
    licensedStates: stateSelector(state),
    suretyZip: state.suretyZip, //should this be called address data?
    isOwnerSsnValid: validateOwnersSsn(state.quickOwnersList.data),
    isOwnershipPercentageValid: validateOwnershipPercentage(
      ownersSelector(state)
    ),
    hasWholeNumberOwnershipPercents: validateHasWholeNumberOwnershipPercents(
      ownersSelector(state)
    )
  };
};

const mapDispatchToProps = {
  fetchAgencyProducers,
  fetchAgencyAccounts,
  fetchOwners,
  clearOwners,
  verifyAddress,
  updateSuretyQuoteData,
  fetchSuretyQuoteAgencyData,
  fetchSuretyZip,
  clearSuretyQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
