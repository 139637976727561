import React, { useEffect, useState } from "react";
import {
  Button,
  FlexRow,
  LoadingIndicator,
  Icon,
  formatDate
} from "@ufginsurance/ui-kit";
import * as qqService from "../../quickQuotingServices";
import { toTitleCase } from "../../../../components/Factory.js";
import CheckboxButton from "./CheckboxButton";
import {
  normalizeUnverifiedAddress,
  normalizeVerifiedAddress,
  getValidUnitType
} from "../util";
import "./VerifyAddresses.scss";

const VerifyAddress = ({
  updateAddressFormFields,
  addressToVerify,
  handleContinue,
  setShowVerifyModal,
  setShowAddModal,
  setShowEditModal
}) => {
  const [activeSelection, setActiveSelection] = useState(); // false is original | true is processed
  const [processedAddress, setProcessedAddress] = useState();

  // on component load, check the addresses using the address service and set up the data for the UI
  useEffect(() => {
    const processed = { exactMatch: true };

    // only run if the api service hasn't been run yet
    if (!processedAddress) {
      // set a value so if the component re-renders before the api is finished,
      // we don't try to run it multiple times
      setProcessedAddress({});

      qqService
        .verifyAddress(
          addressToVerify.address_line.replace(" ", "+"),
          addressToVerify.city.replace(" ", "+"),
          addressToVerify.state,
          addressToVerify.zip
        )
        .then(response => {
          const data = response.data;
          response.data.verify_date = formatDate(new Date(), "YYYY-MM-DD");
          const confidence = !!data?.confidence ? Number(data.confidence) : 0;

          response.data.unit_number = response.data.unit_number || "";
          response.data.unit_type = getValidUnitType(response.data);
          // let addressLine = data.street_name;

          //save verified data to the address object
          if (confidence <= 50) {
            processed.isNotVerified = true;
          } else {
            processed.verified = normalizeVerifiedAddress(
              data,
              addressToVerify?.pobox
            );
          }

          processed.original = normalizeUnverifiedAddress(addressToVerify);

          //set if the address is exact match - used to see if all addresses are exact match
          if (confidence < 100) processed.exactMatch = false;
        })
        .catch(error => {
          console.error(error);
          processed.isNotVerified = true;
        })
        .finally(() => {
          processed.processed = true;
          // save processed data to state
          setProcessedAddress(processed);
          setActiveSelection(processed.isNotVerified ? "original" : "verified");

          // if there's an exact match, just continue on
          if (processed.exactMatch) {
            //This must be done if we want to skip the user choice when confidence is 100.
            //Calling updateForm is not fast enough to update form state before calling goToStep2 like in other cases.
            if (handleContinue) handleContinue(processed.verified);
          }
        });
    }
  }, [addressToVerify, processedAddress, handleContinue]);

  useEffect(() => {
    if (processedAddress && activeSelection) {
      updateAddressFormFields({
        address: processedAddress[activeSelection]
      });
    }
    // prevents infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedAddress, activeSelection]);

  const handleCancelClick = () => {
    updateAddressFormFields({
      address: addressToVerify,
      isCancel: true
    });
    // remove address from processing
    setProcessedAddress({});

    // run cancel func from parent
    setShowVerifyModal(false);

    if (!!setShowAddModal) setShowAddModal(false);

    if (!!setShowEditModal) setShowEditModal(false);
  };

  const handleOnClick = () => {
    handleContinue(processedAddress[activeSelection]);
  };

  const handleVerifiedOnClick = () => {
    setActiveSelection("verified");
  };

  const handleOriginalOnClick = () => {
    setActiveSelection("original");
  };

  return (
    <>
      <div className="qq__verify-address__container">
        <div className="qq__verify-address">
          {!processedAddress?.processed && (
            <LoadingIndicator message="Verifying addresses..." />
          )}
          {processedAddress?.processed && !processedAddress?.exactMatch && (
            <>
              <p className="qq__verify-address__title">
                Please verify your addresses.
              </p>

              <FlexRow className="qq__verify-address_row">
                <div className="qq__verify-address__button-header">
                  Original Address
                </div>

                <div className="qq__verify-address__button-header qq__verify-address__button-header__suggested">
                  Suggested Address
                </div>
              </FlexRow>

              <div className="oq__verify-address__row-header">&nbsp;</div>

              <FlexRow className="qq__verify-address__row">
                <CheckboxButton
                  selected={activeSelection === "original"}
                  onClick={handleOriginalOnClick}
                >
                  <div>
                    {toTitleCase(processedAddress.original.address_line)}
                  </div>
                  <div>
                    {toTitleCase(processedAddress.original.city)},{" "}
                    {processedAddress.original.state}{" "}
                    {processedAddress.original.zip}
                  </div>
                </CheckboxButton>

                <div className="qq__verify-address__carret">
                  <Icon icon="fasAngleRight" size="2x" />
                </div>

                <CheckboxButton
                  selected={activeSelection === "verified"}
                  invalid={processedAddress?.isNotVerified}
                  disabled={processedAddress?.isNotVerified}
                  onClick={handleVerifiedOnClick}
                >
                  {!!processedAddress?.verified && (
                    <>
                      <div>
                        {toTitleCase(
                          //should we be title casing this? talk to Shiryl.
                          processedAddress.verified.address_line
                        )}
                      </div>
                      <div>
                        {toTitleCase(processedAddress.verified.city)},{" "}
                        {processedAddress.verified.state}{" "}
                        {processedAddress.verified.zip}
                      </div>
                    </>
                  )}
                  {processedAddress?.isNotVerified && (
                    <em>Unable to verify address</em>
                  )}
                </CheckboxButton>
              </FlexRow>
            </>
          )}
        </div>
      </div>

      {processedAddress && (
        <FlexRow align="right">
          <Button type="button" onClick={handleCancelClick}>
            Cancel
          </Button>

          <Button variant="primary" type="button" onClick={handleOnClick}>
            Continue
          </Button>
        </FlexRow>
      )}
    </>
  );
};

export default VerifyAddress;
