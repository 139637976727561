import * as types from "../constants/actionTypes";
import { getCertificateHistory } from "../services/homeServiceCenterService";
import { logger } from "../loggers";

const fetchCertificateHistoryStarted = () => ({
  type: types.FETCH_CERTIFICATE_HISTORY_STARTED
});

const fetchCertificateHistorySucceeded = data => ({
  type: types.FETCH_CERTIFICATE_HISTORY_SUCCEEDED,
  payload: data
});

const fetchCertificateHistoryFailed = error => ({
  type: types.FETCH_CERTIFICATE_HISTORY_FAILED,
  payload: error
});

export const fetchCertificateHistory = (
  agency_code,
  search_text,
  selectType
) => dispatch => {
  dispatch(fetchCertificateHistoryStarted());
  return getCertificateHistory(agency_code, search_text, selectType).then(
    response => {
      dispatch(fetchCertificateHistorySucceeded(response.data));
    },
    error => {
      dispatch(fetchCertificateHistoryFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
