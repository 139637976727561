import React, { useRef } from "react";
import { Switch, Route } from "react-router-dom";
import * as routes from "../constants/routes";
import { withRouter } from "react-router";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as keys from "../constants/localStorageKeys";

import MoratoriumAlert from "../containers/moratorium/MoratoriumAlertContainer";
import ScrollToTop from "../components/menus/ScrollToTop";
import Step1Profile from "./Step1Profile";
import Step2Classify from "./Step2Classify";
import Step3Eligibility from "./Step3Eligibility";
import Step4PolicyInfo from "./Step4PolicyInfo";
import Step5RiskInfo from "./Step5RiskInfo";
import Step6QuoteSummary from "./Step6QuoteSummary";
import FooterContainer from "../containers/FooterContainer";
import HeaderContainer from "../containers/HeaderContainer";
import OQSideBar from "./OQSideBar";
import QuoteErrorReturnToStep1 from "./shared/QuoteErrorReturnToStep1";
import QuoteLoader from "./QuoteLoader";
import VendorQuoteLoader from "./VendorQuoteLoader";
import OnlineQuotingContext from "./OnlineQuotingContext";
import OqTimeoutPopup from "./OqTimeoutPopup";
import UpdateOverlayTransparent from "./shared/UpdateOverlayTransparent";

import { useQuote } from "./shared/useQuote";
import { ToastContainer, toast, Slide } from "react-toastify";
import { cancelQuote } from "../top-action-cards/new-quote/actions/newQuoteActions";

import "./OnlineQuotingLayout.scss";
import "./formGroup.scss";

const OnlineQuotingHome = props => {
  const { history, location, sessionData } = props;

  const OqContextValue = useQuote({ sessionData });

  const { clearLocalQuote, step, quoteIsUpdating, quoteData } = OqContextValue;

  // TODO: ADD FEATURE SWITCH FOR THIS
  if (window.env.REACT_APP_IS_PROD !== "true") {
    if (props?.activeAgencyCode === "109015" && !toast.isActive(109015))
      toast(
        `Warning: Current agency is 109015. You will need to impersonate in order to create or make changes to a quote.`,
        {
          toastId: 109015,
          position: "top-center",
          className: "warning",
          autoClose: false
        }
      );
  }

  // evaluate the browser path, if the user is on a step that requires some data that is missing, show an error page
  const navStep = location.pathname.match(/online_quoting\/step(\d)/);
  const currentStep = parseInt(navStep?.[1] || 0);
  const quoteInProgress = window.localStorage.getItem(keys.CURRENT_QUOTE_DATA);
  const preQuoteInProgress = window.localStorage.getItem(
    keys.QUOTE_SUPPORT_DATA
  );

  const quoteIsInvalid =
    quoteData &&
    (!quoteData?.lobData ||
      (quoteData?.lobData.constructor === Object &&
        Object.keys(quoteData?.lobData).length === 0));

  const badQuote =
    // user is on step2 and there is no supporting data or is quoteData
    (currentStep === 2 &&
      (preQuoteInProgress === "null" || quoteInProgress !== "null")) ||
    // user is on step3,4,5,6 and there is no quoteData
    (currentStep > 2 && (!quoteData || quoteIsInvalid));

  // if user is on step1 and there is qutoeData, reset quote
  if (currentStep === 1 && !!quoteInProgress && quoteInProgress !== "null") {
    clearLocalQuote({ goto: null });
  }

  // this ref is used in the OqTimeoutPopup component to attach an event handler to the container
  const OQ_container_ref = useRef();

  return (
    <div id="OQ" ref={OQ_container_ref}>
      <OnlineQuotingContext.Provider value={OqContextValue}>
        <ToastContainer
          className="oq__toast__container ufg__toast"
          enableMultiContainer
          closeOnClick={false}
          containerId="OQ"
          closeButton
          position={toast.POSITION.BOTTOM_CENTER}
          draggable={false}
          transition={Slide}
        />
        <HeaderContainer />

        {badQuote && <QuoteErrorReturnToStep1 {...props} />}

        {!badQuote && (
          <>
            <div className="oq__below-nav">
              <Route path={routes.ONLINE_QUOTING} component={MoratoriumAlert} />
            </div>
            <section id="content" className="noborder">
              {quoteIsUpdating && <UpdateOverlayTransparent />}
              <div id="body" className="container">
                <Route
                  path={routes.ONLINE_QUOTING_LOADER}
                  render={props => <QuoteLoader {...props} />}
                />
                <Route
                  path={routes.VENDOR_QUOTING_LOADER}
                  render={props => <VendorQuoteLoader {...props} />}
                />
                <div className="oq__layout__left-sidebar__container">
                  {!!step && (
                    <div className="oq__layout__left-sidebar">
                      <OQSideBar />
                    </div>
                  )}
                  <div className="oq__content-body">
                    <Switch>
                      <ScrollToTop>
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP1}
                          render={props => <Step1Profile {...props} />}
                        />
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP2}
                          render={props => <Step2Classify {...props} />}
                        />
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP3}
                          render={props => <Step3Eligibility {...props} />}
                        />
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP4}
                          render={props => <Step4PolicyInfo {...props} />}
                        />
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP5}
                          render={props => <Step5RiskInfo {...props} />}
                        />
                        <Route
                          exact
                          path={routes.ONLINE_QUOTING_STEP6}
                          render={props => <Step6QuoteSummary {...props} />}
                        />
                      </ScrollToTop>
                    </Switch>
                  </div>
                </div>
              </div>
            </section>
            <OqTimeoutPopup
              history={history}
              OQ_container_ref={OQ_container_ref}
            />
          </>
        )}
      </OnlineQuotingContext.Provider>
      <FooterContainer />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  isPilotAgent: sessionSelector.isPilotAgent(state),
  sessionData: {
    userInfo: {
      employeeUsername: state?.oidc?.user?.profile?.employeeUsername,
      userName: sessionSelector.getFirstAndLastName(state),
      userEmail: sessionSelector.getEmail(state),
      userAgentCode: sessionSelector.getAgentCode(state)
    }
  }
});

export default compose(
  withRouter,
  connect(mapStateToProps, { onCancelQuote: cancelQuote })
)(OnlineQuotingHome);
