import {
  initialize as GAinitialize,
  pageview as GApageview,
  modalview as GAmodalview,
  event as GAevent,
  exception as GAexception,
  timing as GAtiming
} from "react-ga";

let diagnosticsEnabled = false;

/**
 * Initializes the logger(s). Call at application startup
 * before calling any other logger function.
 * @param {string} ga_tracking_id - Google Analytics tracking id
 * @param {boolean} ga_debug - Google Analytics debug setting
 * @param {boolean} enable_diagnostics - true for developers
 * @returns {void}
 */
export const initialize = (ga_tracking_id, ga_debug, enable_diagnostics) => {
  GAinitialize(ga_tracking_id, {
    debug: parseBoolean(ga_debug)
  });
  diagnosticsEnabled = parseBoolean(enable_diagnostics);

  window.onerror = function(message, source, lineno, colno, err) {
    const description = { message, source, lineno, colno, err };
    error({ description: JSON.stringify(description) });
  };
};

const parseBoolean = string => {
  switch (string.toLowerCase()) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      throw new Error(`Unable to parse ${string} to boolean.`);
  }
};

/**
 * Logs a message if diagnostics are enabled.
 * @param {string} message - The message
 * @returns {void}
 */
export const log = message => {
  if (diagnosticsEnabled) {
    console.log(message);
  }
};

/**
 * Logs a pageview.
 * @param {string} path - A relative URL path
 * @returns {void}
 */
export const pageview = path => {
  GApageview(path);
};

/**
 * Logs a modal view (like a pageview but without a change in URL).
 * @param {string} modalName - A name representing the modal view
 * @returns {void}
 */
export const modalview = modalName => {
  GAmodalview(modalName);
};

/**
 * @typedef {Object} Event
 * @property {string} category - A top level category for these events
 * @property {string} action - A description of the behaviour
 * @property {string=} label - Optional; more precise labelling of the related action
 * @property {number=} value - Optional integer; a means of recording a numerical value against an event
 * @property {boolean=} nonInteraction - Optional; true if event is triggered by code (e.g. page load) rather than by user interaction
 * @property {string=} transport - Optional; transport mechanism ("beacon", "xhr", or "image")
 */

/**
 * Logs an in-page event (a user interaction that does not trigger a
 * change in URL).
 * @param {Event} args - An object describing the event
 * @returns {void}
 */
export const event = args => {
  GAevent(args);
};

/**
 * @typedef {Object} Exception
 * @property {string=} description - Optional; error message or description
 * @property {boolean=} fatal - Optional; true if exception was fatal
 */

/**
 * Logs an error/exception.
 * @param {Exception} args - An object describing the exception
 * @returns {void}
 */
export const error = args => {
  GAexception(args);
  log(args.description);
};

/**
 * Record GA API call times.
 * @param {Date} startTime - api start time
 * @returns {void}
 */
export const apiEndTime = startTime => {
  if (startTime) {
    const requestTime = Date.now() - startTime;
    GAtiming({
      category: "API Call",
      //variable: key,
      value: requestTime // in milliseconds
    });
  }
};
