import React from "react";
import { useHistory } from "react-router";
import { useCMSContext } from "./context";
import { PanelGrid, Panel, Anchor } from "@ufginsurance/ui-kit";
import { CHECKLIST_ID } from "../resources/agency-interface/util";

const TableOfContents = ({ baseRoute }) => {
  const { cmsData, tableOfContentId } = useCMSContext();

  const history = useHistory();

  return (
    <div className="cms-layout__table-of-contents">
      <PanelGrid sizes={{ sm: 2, md: 3, lg: 3, xl: 3 }}>
        {cmsData.tableOfContents.data.map(header => {
          return (
            <Panel title={header.description} key={header.sequence}>
              {header.subheaders.map(subHeader => {
                const isCurrent = subHeader.id === tableOfContentId;
                const subHeaderClass = isCurrent
                  ? "table-of-contents__link--disabled"
                  : "table-of-contents__link";
                return (
                  <Anchor
                    className={`${subHeaderClass}`}
                    id={
                      subHeader.description === "Checklist"
                        ? CHECKLIST_ID
                        : subHeader.id
                    }
                    key={subHeader.sequence}
                    onClick={e => {
                      if (subHeader.description === "Checklist") {
                        e.preventDefault();
                        return;
                      }
                      if (
                        subHeader.navigation_link.length > 0 &&
                        subHeader.open_new_window === false
                      ) {
                        history.push(`${subHeader.navigation_link}`);
                      }
                      else if (
                        subHeader.navigation_link.length > 0 &&
                        subHeader.open_new_window === true
                      ) {
                        window.open(`${subHeader.navigation_link}`, "_blank");
                      } else if (!isCurrent) {
                        history.push(
                          `${baseRoute}/header/${header.id}/subheader/${subHeader.id}`
                        );
                      }
                    }}
                  >
                    {subHeader.description}
                  </Anchor>
                );
              })}
            </Panel>
          );
        })}
      </PanelGrid>
    </div>
  );
};

export default TableOfContents;
