import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import UfgCore from "./UfgCore";
import Oasis from "./Oasis";

const getUfgCoreReports = report => getReportsBySource("ufg_core", report);
const getOasisReports = report => getReportsBySource("oasis", report);

const getReportsBySource = (source, report) => {
  return report
    .filter(
      statement =>
        statement.source === source &&
        statement.items.length > 0 &&
        statement.items.filter(_ => _.documents.length > 0).length > 0
    )
    .map(statement => ({
      ...statement,
      billing_type:
        statement.items.length > 0 ? statement.items[0].billing_type : ""
    }))
    .sort((a, b) => {
      const textA = a.billing_type.toUpperCase();
      const textB = b.billing_type.toUpperCase();
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
};

const getDirectBillItems = listItem =>
  getItemsByBillingType("direct", listItem.items);

const getAgencyBillItems = listItem =>
  getItemsByBillingType("agency", listItem.items);

const getItemsByBillingType = (billingType, items) =>
  items.filter(i => i.billing_type === billingType);

const sortByBillingTypeAsc = (a, b) => a.billing_type < b.billing_type;

const Viewer = ({
  title,
  statementList,
  fetchAccountingStatementViewPDF,
  report_group,
  agency_code
}) => {
  const ufgCoreList = getUfgCoreReports(statementList.report);
  const oasisList = getOasisReports(statementList.report);
  const maxStatementLength =
    ufgCoreList.length > oasisList.length
      ? ufgCoreList.length
      : oasisList.length;

  const hasUfgCoreItems = ufgCoreList.length > 0;
  const oasisClasses = cn("oasis-container col-xs-12", {
    "col-md-6": hasUfgCoreItems,
    "col-md-12": !hasUfgCoreItems
  });

  return (
    <div className="row">
      <div className="accounting-heading">{title}</div>
      {Array(maxStatementLength)
        .fill(null)
        .map((val, index) => {
          const ufgCoreListItem = ufgCoreList[index];
          const oasisListItem = oasisList[index];
          const key = ufgCoreListItem ? ufgCoreListItem.id : oasisListItem.id;

          // Because this layout and component hiearchy is operating by 'row' (based on bill type) it
          // forces us into this awkward iteration over both ufgcore and oasis at the same time (based on max length)
          // in order to process direct bill first and then the next row of agency bill.
          // Potential refactoring could be:
          // 1. Leveraging flexbox column layout could allow us to separate ufgcore and oasis
          //    into their own halves.
          // 2. Separate the data more cleanly from api (in redux or via selectors).
          //    Break it into the 4 types: ufgcore direct, ufgcore agency, oasis direct, and oasis agency.
          //    Top level component then lays out the 4 table components instead of relying on this iteration style.
          return (
            <div key={key} className="row">
              {oasisListItem && (
                <div className={oasisClasses}>
                  <Oasis
                    directList={getDirectBillItems(oasisListItem).sort(
                      sortByBillingTypeAsc
                    )}
                    agencyList={getAgencyBillItems(oasisListItem).sort(
                      sortByBillingTypeAsc
                    )}
                    fetchAccountingStatementViewPDF={
                      fetchAccountingStatementViewPDF
                    }
                    report_group={report_group}
                    agency_code={agency_code}
                    source={oasisListItem.source}
                  />
                </div>
              )}
              {ufgCoreListItem && (
                <div className={"ufg-core-container col-xs-12 col-md-6"}>
                  <UfgCore
                    directList={getDirectBillItems(ufgCoreListItem)}
                    agencyList={getAgencyBillItems(ufgCoreListItem)}
                    fetchAccountingStatementViewPDF={
                      fetchAccountingStatementViewPDF
                    }
                    report_group={report_group}
                    agency_code={agency_code}
                    source={ufgCoreListItem.source}
                  />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

Viewer.propTypes = {
  title: PropTypes.string.isRequired,
  statementList: PropTypes.object.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  report_group: PropTypes.string,
  agency_code: PropTypes.string
};

export default Viewer;
