import {
  CURRENT_POLICY_TERM
} from "./policyTermSummary";

export const getCurrentSummarySelector = state =>
  state.currentPolicyData.currentSummary;

export const getCurrentSummaryErrorSelector = state  =>
  state.currentPolicyData.currentSummaryError;

export const getCurrentSummaryIsLoadingSelector = state =>
  state.currentPolicyData.currentSummaryIsloading;

export const getOasisDocumentSummarySelector = (state, term) =>
  term === CURRENT_POLICY_TERM ?
    state.currentPolicyData.oasisDocumentSummaryCurrent :
    state.currentPolicyData.oasisDocumentSummaryRenewal;

export const getOasisDocumentSummaryIsLoadingSelector = (state, term) =>
  term === CURRENT_POLICY_TERM ?
    state.currentPolicyData.oasisDocumentSummaryIsLoadingCurrent :
    state.currentPolicyData.oasisDocumentSummaryIsLoadingRenewal;

export const getOasisDocumentSummaryErrorSelector = (state, term) =>
  term === CURRENT_POLICY_TERM ?
    state.currentPolicyData.oasisDocumentSummaryErrorCurrent :
    state.currentPolicyData.oasisDocumentSummaryErrorRenewal;

//CURRENT POLICY DOCUMENT SELECTION MANAGEMENT SELECTORS
export const createLineOfBusinessDocumentSelections = (
  currentSelectedPolicyDocuments,
  selections,
  currentPolicyData,
  declarationCode,
  policyTerm
) => {
  const data = { ...currentSelectedPolicyDocuments };
  const indexedSelections = selections.map(doc => {
    const lineOfBusinessDocuments =
      currentPolicyData.ufgDocuments[declarationCode + policyTerm];
    const docs = lineOfBusinessDocuments?.documents || [];
    return {
      ...doc,
      originalIndex: docs.findIndex(d => d.id === doc.id) || -1
    };
  });
  data[declarationCode + policyTerm] = indexedSelections.sort(
    (a, b) => a.originalIndex - b.originalIndex
  );
  return data;
};

export const createDecAndTypedFormsDocumentSelections = (
  currentSelectedPolicyDocuments,
  currentSummary,
  policyTerm,
  currentPolicyData,
  selectAllDecAndTypedForms
) => {
  const currentSummaryTerm = currentSummary.find(
    lob => lob.term === policyTerm
  );
  const data = { ...currentSelectedPolicyDocuments };
  if (currentSummaryTerm) {
    for (const lob of currentSummaryTerm.lines_of_business) {
      const lineOfBusinessDocuments =
        currentPolicyData.ufgDocuments[lob.declaration_code + policyTerm];
      const decAndTypedForms =
        lineOfBusinessDocuments?.documents?.filter(doc => doc.process_date) ||
        [];
      const decAndTypedFormsWithoutFilings =
        decAndTypedForms?.filter(doc => doc.reference_number) || [];
      data[lob.declaration_code + policyTerm] = selectAllDecAndTypedForms
        ? decAndTypedFormsWithoutFilings
        : [];
    }
  }
  return data;
};

export const createAllDocumentSelections = (
  currentSelectedPolicyDocuments,
  currentSummary,
  policyTerm,
  currentPolicyData,
  selectAllDocuments
) => {
  const currentSummaryTerm = currentSummary.find(
    lob => lob.term === policyTerm
  );
  const data = { ...currentSelectedPolicyDocuments };
  if (currentSummaryTerm) {
    for (const lob of currentSummaryTerm.lines_of_business) {
      const lineOfBusinessDocuments =
        currentPolicyData.ufgDocuments[lob.declaration_code + policyTerm];
      if (lineOfBusinessDocuments) {
        data[lob.declaration_code + policyTerm] = selectAllDocuments
          ? lineOfBusinessDocuments.documents
          : [];
      }
    }
  }
  return data;
};

export const atleastOneDocumentSelected = (
  policyTerm,
  selectedPolicyDocuments
) => {
  const lobAndPolicyTerms = Object.keys(selectedPolicyDocuments);
  for (const lobAndPolicyTerm of lobAndPolicyTerms) {
    if (
      lobAndPolicyTerm.includes(policyTerm) &&
      selectedPolicyDocuments[lobAndPolicyTerm].length > 0
    ) {
      return true;
    }
  }
};

export const getSelectedDocuments = (policyTerm, selectedPolicyDocuments) => {
  const selectedDocumentsLobAndPolicyTerms = Object.keys(
    selectedPolicyDocuments
  );
  let selectedDocuments = [];
  for (const lobAndPolicyTerm of selectedDocumentsLobAndPolicyTerms) {
    if (
      lobAndPolicyTerm.includes(policyTerm) &&
      selectedPolicyDocuments[lobAndPolicyTerm].length > 0
    ) {
      selectedDocuments = [
        ...selectedDocuments,
        ...selectedPolicyDocuments[lobAndPolicyTerm]
      ];
    }
  }
  return selectedDocuments;
};
//CURRENT POLICY DOCUMENT SELECTION MANAGEMENT SELECTORS
