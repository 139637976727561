import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from "react";
import * as api from "../../../services/onlineQuotingService";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import cn from "classnames";
import _isEqual from "lodash/isEqual";
import OQstrings from "../../shared/strings";
import {
  proQuoteProductsAvail,
  bopProIsAvail
} from "../../shared/productMatrix";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  guid,
  addUpdateArrayItem,
  zipCodeValidate,
  addressLineValidate,
  zipCodePattern,
  hasHNO,
  validatePoBox
} from "../../shared/util";
import {
  Button,
  Input,
  Select,
  Form,
  FormGroup,
  useForm,
  Switch,
  LoadingIndicator,
  FlexRow
} from "@ufginsurance/ui-kit";

import { productKeys } from "../../shared/constants";
import { toTitleCase } from "../../../components/Factory.js";
import getAllLocations from "./getAllLocations";
import VerifyAddress from "../../../shared/components/AddressValidate/VerifyAddressSingle";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { mapExtraDataFromAddressService } from "../../shared/supportingDataHelpers";

import "./LocationForms.scss";

let abortController;

const LocationFormStep1 = ({
  itemData,
  onNextStep,
  onCancel,
  locationfixedId,
  activeAgencyCode
}) => {
  const {
    updateCoverablesPromise,
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    toastErrr,
    quoteIsUpdating
  } = useContext(OnlineQuotingContext);

  const { proQuoteUseAwsProductMatrix } = useFlags();

  const [agencyStates, setAgencyStates] = useState([]);
  const [availableStates, setAvailableStates] = useState();
  const [availableCities, setAvailableCities] = useState([]);
  const [lobSelected, setLobSelected] = useState(false);
  const [intialValuesBeforeUpdate, setIntialValuesBeforeUpdate] = useState();
  const [zipSearching, setZipSearching] = useState();
  const [locations] = useState(getAllLocations({ quoteData, supportingData }));
  const [addressToVerify, setAddressToVerify] = useState();

  const [isDupAddressMessage, setDupAddressMessage] = useState(false);

  const combinedLobs = [];
  locations.forEach(l => {
    l.linesOfBusiness.forEach(b => {
      combinedLobs.push(b);
    });
  });
  const [lobsList, setLobsList] = useState(combinedLobs);

  const lineOfBusiness = useMemo(() => {
    //The only products that can have a location.
    let lobLocationTypes = [
      "bp7BusinessOwners",
      "ca7CommAuto",
      "wcmWorkersComp"
    ];

    //OOQ-4428 hide autoLine indicator if any of HNO Auto are selected
    if (hasHNO(supportingData)) {
      lobLocationTypes = lobLocationTypes.filter(l => l !== "ca7CommAuto");
    }

    return (
      Object.keys(quoteData?.lobData).filter(x =>
        lobLocationTypes.includes(x)
      ) || []
    );
  }, [quoteData?.lobData, supportingData]);

  const [availableLOBS, setAvailableLOBS] = useState(lineOfBusiness);

  const initialValues = {
    addressLine1: "",
    postalCode: "",
    county: "",
    countyCode: "",
    city: "",
    state: "",
    isPrimary: false,
    verified: "verified" // this default is set for if address verify is an exact match
  };

  if (lineOfBusiness.includes("bp7BusinessOwners"))
    initialValues.lobs_bp7BusinessOwners = false;

  if (lineOfBusiness.includes("ca7CommAuto"))
    initialValues.lobs_ca7CommAuto = false;

  if (lineOfBusiness.includes("wcmWorkersComp"))
    initialValues.lobs_wcmWorkersComp = false;

  //If only one LOB indicator exists select it.
  const lobsItems = Object.keys(initialValues).filter(x =>
    x.startsWith("lobs_")
  );
  if (lobsItems.length === 1) {
    initialValues[lobsItems[0]] = true;
  }

  //if ca7CommAuto look in supporting data for this format.
  /**
   * supportingData: ....,
   *   autoLocationFixedIDs: [1234,2341,4353]
   */
  const getLobFields = useCallback(
    loc => {
      lineOfBusiness.forEach(x => {
        let foundLob = null;
        if (x === "ca7CommAuto") {
          foundLob = (supportingData?.autoLocationFixedIDs || []).find(
            y => Number(y) === Number(loc.fixedID)
          );
        } else {
          //Otherwise Reading FROM location.linesOfBusiness = ["bp7BusinessOwners","ca7CommAuto"]
          foundLob = (loc?.linesOfBusiness || []).find(y => x === y);
        }
        loc = { ...loc, ["lobs_" + x]: !!foundLob };
      });

      return loc;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supportingData, quoteData]
  );

  // covert TO: location.lobs_bp7BusinessOwners = true,location.lobs_ca7CommAuto = true
  const convertLocationJsonDTOToForm = useCallback(
    loc => {
      loc = getLobFields(loc);
      return {
        ...loc.address,
        ...loc
      };
    },
    [getLobFields]
  );

  const convertLocationToJsonDTO = loc => {
    const location = { ...loc };

    //Move address-line fields to address object.
    location.address = {
      displayName: loc?.address?.displayName,
      addressLine1: loc.addressLine1,
      postalCode: loc.postalCode,
      city: loc.city,
      state: loc.state,
      county: loc.county,
      countyCode: loc.countyCode,
      country: "US",
      addressVerificationStatus_UFG: loc?.verified,
      ...loc?.extraValuesFromService
    };

    //delete inline address fields which have been added above.
    delete location.displayName;
    delete location.addressLine1;
    delete location.postalCode;
    delete location.city;
    delete location.state;
    delete location.county;
    delete location.countyCode;
    delete location.country;
    delete location.verified;
    delete location.extraValuesFromService;

    location.linesOfBusiness = [];
    Object.keys(loc).forEach(x => {
      if (x.includes("lobs_") && loc[x] === true) {
        location.linesOfBusiness.push(x.replace("lobs_", ""));
      }
    });

    //delete all the lobs_...fields... not required.
    Object.keys(location).forEach(x => {
      if (x.includes("lobs_")) {
        delete location[x];
      }
    });
    return location;
  };

  const saveCAlocationToSupportingData = useCallback(
    ({ location, locationfixedId }) => {
      let savedCA7locationFixedIds = supportingData?.autoLocationFixedIDs || [];

      if (location.linesOfBusiness.includes("ca7CommAuto")) {
        if (!savedCA7locationFixedIds.length) {
          savedCA7locationFixedIds = [locationfixedId];
        } else if (
          !savedCA7locationFixedIds.find(
            x => Number(x) === Number(locationfixedId)
          )
        ) {
          savedCA7locationFixedIds.push(locationfixedId);
        }
        // saving ca7CommAuto locations to supporting data
        updateSupportingDataPromise({
          dataToMergeAndSave: { autoLocationFixedIDs: savedCA7locationFixedIds }
        });

        //if you already saved a ca7CommAuto for this fixedID remove it.
      } else if (
        !location.linesOfBusiness.includes("ca7CommAuto") &&
        !!savedCA7locationFixedIds.find(
          x => Number(x) === Number(locationfixedId)
        )
      ) {
        //_remove id not work now doing the filter instead
        savedCA7locationFixedIds = savedCA7locationFixedIds.filter(
          b => b !== locationfixedId
        );
        updateSupportingDataPromise({
          dataToMergeAndSave: { autoLocationFixedIDs: savedCA7locationFixedIds }
        });
      }
    },
    [supportingData.autoLocationFixedIDs, updateSupportingDataPromise]
  );

  const didFormValueChange = (a, b) => {
    if (
      a?.addressLine1 === b?.addressLine1 &&
      a?.postalCode === b?.postalCode &&
      a?.city === b?.city &&
      a?.state === b?.state &&
      a?.lobs_bp7BusinessOwners === b?.lobs_bp7BusinessOwners &&
      a?.lobs_ca7CommAuto === b?.lobs_ca7CommAuto &&
      a?.lobs_wcmWorkersComp === b?.lobs_wcmWorkersComp
    )
      return false;

    return true;
  };

  const isEditWithNoChanges = () => {
    if (!locationfixedId) return false;

    const location = convertLocationToJsonDTO({ ...values });

    // if we are editing a location and no values have not changed...
    // just go to step 2 without submitting an update
    return (
      !!location?.fixedID &&
      !didFormValueChange(intialValuesBeforeUpdate?.values, values)
    );
  };

  const handleFormSubmit = address => {
    const addressValues = { ...values, ...address };
    const location = convertLocationToJsonDTO({ ...addressValues });

    const isNew = !location.id;

    if (!!location?.fixedID && _isEqual(initialValues, values)) {
      onNextStep({ item: itemData, newQuoteData: quoteData });
      return false;
    }

    setAddressToVerify(null);
    if (isDupAddressMessage) {
      return false;
    }
    location.id = itemData && itemData.id ? itemData.id : guid();

    const _locations = addUpdateArrayItem(
      (locations || []).map(b => {
        return {
          ...b,
          id: b.fixedID || guid()
        };
      }),
      { ...location },
      location?.fixedID ? "fixedID" : "id"
    );

    //store location IDs before saving
    const oldIDs = locations.map(l => l.fixedID);

    updateCoverablesPromise({
      coverableType: "location",
      coverables: { locations: _locations },
      action: isNew ? "Adding" : "Updating"
    })
      .then(({ data }) => {
        //if new location get that location
        const newCoverableId = getNewLocationfixedId(data, oldIDs);
        const locationfixedId = location.fixedID || newCoverableId;

        //after location is saved in PC, save ca7CommAuto location
        saveCAlocationToSupportingData({ location, locationfixedId });

        // get updated bp7LocationFixedID from the update
        const newBpBuilding =
          data?.lobData?.bp7BusinessOwners?.coverables?.locations?.find(
            l => l.fixedID === locationfixedId
          );

        const newBpFixId = newBpBuilding?.bp7LocationFixedID;

        const updatedLocation =
          data.lobData.bp7BusinessOwners.coverables.locations.find(
            l => l.fixedID === locationfixedId
          ) || {};

        // update the product lines on the location item
        const newItemData = {
          ...updatedLocation,
          ...itemData,
          ...location,
          bp7LocationFixedID: newBpFixId,
          fixedID: locationfixedId
        };

        onNextStep({
          newQuoteData: data,
          item: newItemData
        });
      })
      .catch(({ error }) =>
        toastErrr({
          action: "updateCoverablesPromise",
          description: `Failed to ${isNew ? "add" : "update"} location.`,
          error,
          locations: _locations,
          displayMessage: `Failed to ${isNew ? "add" : "update"} location.`
        })
      );
  };

  //try to figure out what the new fixedID is...
  const getNewLocationfixedId = useCallback(
    (data, oldIds) => {
      let foundID = null;
      const newLocations = getAllLocations({ quoteData: data, supportingData });
      newLocations.forEach(l => {
        if (!oldIds.includes(l.fixedID)) {
          foundID = l.fixedID;
        }
      });
      return foundID;
    },
    [supportingData]
  );

  const form = useForm({
    values: initialValues,
    onSubmit: handleFormSubmit
  });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm,
    errors
  } = form;

  const getProductMatrix = useCallback(
    async (valuesState, callback) => {
      const _values = {};
      //show the product indicators based on productMatrix

      api
        .getProductMatrixForState({
          stateAbr: valuesState,
          useAWS: proQuoteUseAwsProductMatrix
        })
        .then(productMatrixData => {
          const productMatrix = productMatrixData?.data?.products || [];

          const avilailableProducts = proQuoteProductsAvail({
            productMatrix,
            effectiveDate: supportingData.effectiveDate
          });

          if (callback) callback(bopProIsAvail);

          const linesAvailableForEffectiveDate = (lineOfBusiness || []).filter(
            l => avilailableProducts[l]?.productAvailable
          );

          setAvailableLOBS(linesAvailableForEffectiveDate);
        });
    },
    [lineOfBusiness, proQuoteUseAwsProductMatrix, supportingData.effectiveDate]
  );

  useEffect(() => {
    // if we are editing a location, update the initial values for the form
    if ((itemData || locationfixedId) && !intialValuesBeforeUpdate) {
      const _locationfixedId = itemData?.fixedId || locationfixedId;
      let _location = null;

      if (_locationfixedId)
        _location = locations.find(l => l.fixedID === _locationfixedId);

      const _values = convertLocationJsonDTOToForm(
        _location ? _location : itemData
      );
      if (_location?.address?.state) getProductMatrix(_location.address.state);

      setIntialValuesBeforeUpdate({ values: _values });
      updateForm({ values: _values });

      setAvailableStates([
        {
          label: _values.state,
          value: _values.state
        }
      ]);

      const city = toTitleCase(_values.city);
      setAvailableCities([
        {
          label: city,
          value: _values.city
        }
      ]);
    }
  }, [
    convertLocationJsonDTOToForm,
    getProductMatrix,
    intialValuesBeforeUpdate,
    itemData,
    locationfixedId,
    locations,
    updateForm
  ]);

  const atLeastOneLOBSelected = (lineOfBusiness, values) => {
    let i = 0;
    while (i < lineOfBusiness.length) {
      if (values["lobs_" + lineOfBusiness[i]] === true) {
        return true;
      }
      i++;
    }
    return false;
  };

  useEffect(() => {
    //Make sure at least one type of location is selected.
    if (atLeastOneLOBSelected(availableLOBS, values)) {
      setLobSelected(true);
    } else {
      setLobSelected(false);
    }
  }, [availableLOBS, values]);

  useEffect(() => {
    if (activeAgencyCode) {
      abortController = new AbortController();

      api
        .getAgencyData(activeAgencyCode, {
          signal: abortController?.signal
        })
        .then(agencyData => {
          if (!!agencyData?.data?.licensed_states?.length) {
            setAgencyStates(
              (agencyData?.data?.licensed_states || []).map(s => {
                return {
                  label: s,
                  value: s
                };
              })
            );
          }
          // if component is still on screen, then update the state
          else {
            console.error("Agency State List Error");
            /*
            TODO: Show-stopping error - agency data doesn't load
            */
          }
        })
        .catch(() => {
          console.error("Agency Error");
        });
    }
    return () => {
      if (abortController) abortController.abort();
    };
  }, [activeAgencyCode]);

  const _searchLocaleByZipCodeV2 = value => {
    setZipSearching(true);

    api
      .searchLocaleByZipCodeV2(value)
      .then(result => {
        const zipCodeErrors = [];
        if (result && result.data && result.data.length) {
          // first check to see if zip is in allowed state list

          if (
            agencyStates.length &&
            !agencyStates.map(a => a.value).includes(result.data[0].state)
          ) {
            zipCodeErrors.push(OQstrings.error.zip_code_not_in_agent_state);
          }

          //Show this error if state is not R2 state
          getProductMatrix(result.data[0].state, bopProEnabledForState => {
            if (!bopProEnabledForState) {
              zipCodeErrors.push(
                "The state you have selected is not yet available for the Pro-Quote system. Please contact your underwriter with questions."
              );
            }

            setAvailableCities(
              (result?.data || []).map(d => {
                const city = toTitleCase(d.city);
                return { value: city, label: city };
              })
            );

            setAvailableStates(
              (result?.data || []).map(d => {
                return {
                  label: d.state,
                  value: d.state
                };
              })
            );

            // set city & state values
            const formData = {
              values: {
                postalCode: value,
                city: toTitleCase(result.data[0].city),
                county: result?.data[0]?.county,
                countyCode: result.data[0].county_number,
                state: result.data[0].state
              },
              errors: {
                postalCode: zipCodeErrors,
                county: "",
                countyCode: "",
                city: [],
                state: []
              }
            };
            form.updateForm(formData);
          });
        }
      })
      .finally(() => setZipSearching(false));
  };

  const resetCityState = zip => {
    const formData = {
      values: {
        postalCode: zip,
        county: "",
        countyCode: "",
        city: "",
        state: ""
      }
    };
    form.updateForm(formData);
  };

  const handleZipOnChange = ({ field, value }) => {
    handleOnChange({ field, value });
    resetCityState(value);
    if (zipCodePattern.test(value)) {
      _searchLocaleByZipCodeV2(value);
    }
  };

  //OOQ-6230 ignore the change if removing last lob on location.
  const handleLOBOnChange = ({ field, value }) => {
    if (field.startsWith("lobs_")) {
      const lobName = field.replace("lobs_", "");
      const countLOB = lobsList.filter(l => l === lobName).length;

      if (countLOB === 1 && value === false) {
        const productLineName = productKeys[lobName].label;
        toastErrr({
          actions: "handleLOBOnChange",
          description: "failed to remove product line from location",
          misc: {
            field,
            value,
            lob: lobName,
            lobsList
          },
          displayMessage: `Unable to remove ${productLineName} from this location. At least one location must have the ${productLineName} product line.`
        });

        return;
      }
      //This section makes sure the lobsList updates before user saves.
      //To make sure the original count is still relevant.
      if (value === true) {
        //add lobName to lobsList
        lobsList.push(lobName);
        setLobsList([...lobsList]);
      } else {
        //remove lobName to lobsList
        const index = lobsList.findIndex(l => l === lobName);
        lobsList.splice(index, 1);
        setLobsList([...lobsList]);
      }
    }
    handleOnChange({ field, value });
  };

  //Check for duplicates if any address fields change.
  useEffect(() => {
    const foundLoc = locations.find(
      l =>
        !!l.fixedID &&
        !values.fixedID &&
        String(l.address.addressLine1).toLocaleUpperCase() ===
          String(values.addressLine1).toLocaleUpperCase() &&
        String(l.address.city).toLocaleUpperCase() ===
          String(values.city).toLocaleUpperCase() &&
        l.address.state === values.state &&
        l.address.postalCode.startsWith(values.postalCode)
    );
    if (foundLoc) setDupAddressMessage(true);
    else setDupAddressMessage(false);
  }, [
    locations,
    values.addressLine1,
    values.city,
    values.fixedID,
    values.postalCode,
    values.state
  ]);

  const handleZipValidate = field => {
    const fieldErrors = handleOnValidate(field);

    const zipErrorCheck = zipCodeValidate(field.value);
    if (zipErrorCheck) fieldErrors.push(zipErrorCheck);

    return fieldErrors;
  };

  const handleStreetValidate = field => {
    const fieldErrors = handleOnValidate(field);

    const addressLineCheck = addressLineValidate(field.value);
    if (addressLineCheck) fieldErrors.push(addressLineCheck);

    const poBoxCheck = validatePoBox(field.value);
    if (poBoxCheck) {
      fieldErrors.push(poBoxCheck);
    }

    return fieldErrors;
  };

  // function triggered to start the address validation process
  // setting the state of the address kicks it off
  const validateAddress = () => {
    setAddressToVerify({
      addressLine1: values.addressLine1,
      city: values.city,
      state: values.state,
      zip: values.postalCode,
      county: values.county,
      countyCode: values.countyCode,
      verified: "unverified" // this "unverfied" default is used if the user selects an unverified address
    });
  };

  // function used by address validation...
  // to update the address fields to the options chosen by the address validation
  const updateFormAndSubmit = ({ addressData }) => {
    const { addressFields: address } = addressData;

    if (!address)
      toastErrr({
        actions: "validateAddress",
        description: "problems with address validation",
        misc: { lobsList },
        displayMessage: `We rean into a problem using the address selected.`
      });

    const city = toTitleCase(address?.city);

    if (!availableCities.includes(city)) {
      setAvailableCities([...availableCities, { value: city, label: city }]);
    }

    handleFormSubmit({
      addressLine1: toTitleCase(address?.address1),
      city,
      state: address?.state,
      postalCode: address?.zip,
      county: address?.county,
      countyCode: address?.county_code,
      verified: address?.verified,
      extraValuesFromService: mapExtraDataFromAddressService(
        addressData?.rawVerificationData
      )
    });
  };

  const keyErrors = Object.keys(errors);

  return (
    <div>
      <Form className="oq__form__location_step1 oq-forms" context={form}>
        <FormGroup wrap={false} align="justify" hideErrors>
          <Input
            id="addressLine1"
            name="addressLine1"
            label="Address"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleStreetValidate}
            value={values.addressLine1}
            required
            disabled={values.isPrimary || !!addressToVerify}
            size="lg"
          />
          <Input
            id="postalCode"
            name="postalCode"
            label="Zip"
            labelElement={
              zipSearching ? (
                <LoadingIndicator
                  className="oq__label-spinner"
                  type="spinner"
                  message="Searching"
                />
              ) : null
            }
            onChange={handleZipOnChange}
            onBlur={handleOnBlur}
            onValidate={handleZipValidate}
            value={values.postalCode}
            required
            className="zip"
            disabled={values.isPrimary || !!addressToVerify}
          />
          <Select
            id="city"
            name="city"
            label="City"
            placeholder=""
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.city}
            options={availableCities || []}
            required
            size="md"
            disabled={
              values.isPrimary ||
              availableCities.length <= 1 ||
              !!addressToVerify
            }
          />
          <Select
            id="state"
            name="state"
            label="State"
            placeholder=""
            className="state"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.state}
            options={availableStates || []}
            required
            disabled={values.isPrimary || true}
          />
        </FormGroup>
        {keyErrors.filter(x => !x.includes("lobs_")).length > 0 && (
          <FormGroup
            className={cn("uikit__form-group__error-group", {
              "oq__error-group__multiple": keyErrors.length > 1
            })}
          >
            <ul>
              {keyErrors.map(k => {
                return <li key={k}>{errors[k]}</li>;
              })}
            </ul>
          </FormGroup>
        )}
        {isDupAddressMessage && (
          <FormGroup className="uikit__form-group__error-group">
            <span>The location you have entered has already been added.</span>
          </FormGroup>
        )}
        {availableLOBS.length > 1 && (
          <div className="oq__location__products">
            <FormGroup>
              <p className="oq__location__select-lob">
                Please select the product lines which are applicable to this
                location
              </p>
            </FormGroup>
            {availableLOBS.map(x => {
              return (
                <FormGroup hideErrors key={x}>
                  <Switch
                    key={x}
                    size="lg"
                    id={"lobs_" + x}
                    name={"lobs_" + x}
                    label={productKeys[x].label}
                    onChange={handleLOBOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values["lobs_" + x] || false}
                    className="grow"
                    noLabel
                  />
                </FormGroup>
              );
            })}
          </div>
        )}
      </Form>

      {addressToVerify ? (
        <VerifyAddress
          addressToVerify={{
            addressLine1: values.addressLine1,
            city: values.city,
            state: values.state,
            zip: values.postalCode
          }}
          onContinue={(addressData = {}) => {
            updateFormAndSubmit({ addressData });
          }}
          onCancel={onCancel}
        />
      ) : (
        <FlexRow align="right">
          <Button
            variant="plain"
            onClick={onCancel}
            disabled={quoteIsUpdating}
            className="cancel"
          >
            {isEditWithNoChanges() ? "Close" : "Cancel"}
          </Button>

          <Button
            variant="primary"
            className="continue"
            onClick={() => {
              if (isEditWithNoChanges()) {
                // we're editing the location and nothing has changed,
                // just continue to next step
                onNextStep({ item: itemData, newQuoteData: quoteData });
              } else {
                // kick off address validation
                validateAddress();
              }
            }}
            disabled={
              !!invalidFields.length ||
              !!Object.keys(errors).length ||
              !lobSelected ||
              isDupAddressMessage ||
              quoteIsUpdating
            }
          >
            Continue
          </Button>
        </FlexRow>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

export default connect(mapStateToProps, {})(LocationFormStep1);
