import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, useForm } from "@ufginsurance/ui-kit";
import isEqual from "lodash/isEqual";
import {
  BUILDERS_RISK,
  ONLINE_QUOTING_STEP1,
  ONLINE_QUOTING_STEP1_EXISTING
} from "../../../constants/routes";
import NewCustomerInfo from "./components/NewCustomerInfo";
import AccountSearchTable from "./components/AccountSearchTable";
import ProductSelectionDetails from "./components/ProductSelectionDetails";
import "./ProductSelectionModal.scss";
import useGetContactDetailsMeta from "../../builders-risk/hooks/useGetContactDetailsMeta";
import VerifyAddress from "../../../quick-program/quick-quoting/shared/AddressVerification/VerifyAddress";
import {
  initialImbrCustomerValues,
  emptyImbrCustomerErrors
} from "../lib/form-utils";
import {
  createRiskIndicationPayload,
  getPrefilledBuildersRisk
} from "../../builders-risk/lib/utils";
import { postRiskIndicationRecord } from "../services/index";
import ToastError from "../../builders-risk/components/ToastError";
import useGetAgencyInfo from "../../../shared/hooks/useGetAgencyInfo";

const ProductSelectionModalNew = ({
  isOpen,
  toggleProductSelectionModal,
  agencyCode
}) => {
  const history = useHistory();
  const [meta, isLoading] = useGetContactDetailsMeta();
  const [view, setView] = useState("main");
  const [existingCustomer, setExistingCustomer] = useState(null);
  const [addressToVerify, setAddressToVerify] = useState(null);
  const [showVerifyAddress, setShowVerifyAddress] = useState(false);
  const lastVerifiedAddress = useRef(null);
  const [inlandMarineUderwriter, setInlandMarineUderwriter] = useState(null);

  const form = useForm({
    values: initialImbrCustomerValues,
    onSubmit: () => {}
  });

  const { values, updateForm } = form;

  useGetAgencyInfo(agencyCode, async agencyData => {
    const underwriter = agencyData?.ufgteam?.inland_marine_underwriter;
    if (
      !underwriter?.email ||
      inlandMarineUderwriter?.email === underwriter?.email
    )
      return;
    setInlandMarineUderwriter(underwriter);
  });

  useEffect(() => {
    if (!inlandMarineUderwriter?.email) return;
    if (inlandMarineUderwriter?.email === values?.underwriter?.email) return;
    const { first_name, last_name, email, phone } = inlandMarineUderwriter;
    updateForm({
      values: {
        ...values,
        underwriter: { first_name, last_name, email, phone }
      }
    });
  }, [inlandMarineUderwriter, updateForm, values]);

  const saveAndContinue = () => {
    const payload = createRiskIndicationPayload(
      getPrefilledBuildersRisk(values, agencyCode, 2)
    );
    postRiskIndicationRecord(payload)
      .then(_ => {
        if (_.status === 201) {
          history.push({
            pathname: BUILDERS_RISK,
            search: `?id=${_?.data?.risk_indication_id}`
          });
        } else {
          ToastError(_);
        }
      })
      .catch(_ => ToastError(_));
  };

  const handleProQuoteOnClick = () => history.push(ONLINE_QUOTING_STEP1);
  const handleProQuoteExistingOnClick = () =>
    history.push(ONLINE_QUOTING_STEP1_EXISTING);

  const handleBuildersRiskOnClick = () => setView("existingCustomer");

  const handleImbrNewCustomerOnClick = () => {
    setView("newCustomer");
  };

  useEffect(() => {
    if (!isOpen) setView("main");
  }, [isOpen]);

  const getCurrentAddress = () => ({
    address_line: values.address_line,
    city: values.city,
    state: values.state,
    zip: values.zip
  });

  const handleNewCustomerContinue = () => {
    const currentAddress = getCurrentAddress();

    if (
      !isEqual(currentAddress, lastVerifiedAddress?.current) &&
      !existingCustomer
    ) {
      setAddressToVerify(currentAddress);
      setShowVerifyAddress(true);
    } else {
      verifiedAddressContinue();
    }
  };

  const updateAddressValues = ({ address, isCancel }) => {
    if (!isCancel) {
      updateForm({
        values: {
          ...values,
          ...address
        }
      });
    }
  };

  const verifiedAddressContinue = () => {
    lastVerifiedAddress.current = getCurrentAddress();
    setShowVerifyAddress(false);
    setAddressToVerify(null);
    saveAndContinue();
  };

  const modalTitleDict = {
    main: "Product Selection",
    newCustomer: "Start a Quote - Inland Marine Builders' Risk",
    existingCustomer: "Start a Quote - Inland Marine Builders' Risk"
  };

  const handleProductSelectionModalClose = () => {
    updateForm({
      values: initialImbrCustomerValues,
      errors: emptyImbrCustomerErrors
    });
    setExistingCustomer(null);
    setAddressToVerify(null);
    setShowVerifyAddress(false);
    toggleProductSelectionModal();
  };

  return (
    <>
      <Modal
        title={modalTitleDict[view]}
        containerClassName="product-selection"
        show={isOpen}
        onHide={handleProductSelectionModalClose}
        size="lg"
        body={
          {
            main: (
              <ProductSelectionDetails
                {...{
                  handleProQuoteOnClick,
                  handleProQuoteExistingOnClick,
                  handleBuildersRiskOnClick,
                  handleNewCustomerOnClick: handleImbrNewCustomerOnClick,
                  isMetaLoading: isLoading
                }}
              />
            ),
            newCustomer: (
              <>
                <NewCustomerInfo
                  {...{
                    form,
                    licensedStates: meta?.licensed_states || [],
                    onContinue: handleNewCustomerContinue,
                    disableFields: !!existingCustomer,
                    showVerifyAddress,
                    setView,
                    setExistingCustomer
                  }}
                />
                {showVerifyAddress && (
                  <VerifyAddress
                    updateAddressFormFields={updateAddressValues}
                    addressToVerify={addressToVerify}
                    setAddressToVerify={setAddressToVerify}
                    handleContinue={verifiedAddressContinue}
                    setShowVerifyModal={setShowVerifyAddress}
                  />
                )}
              </>
            ),
            existingCustomer: (
              <AccountSearchTable
                {...{
                  form,
                  existingCustomer,
                  setExistingCustomer,
                  setView
                }}
              />
            )
          }[view]
        }
      />
    </>
  );
};

export default ProductSelectionModalNew;
