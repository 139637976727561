import { connect } from "react-redux";
import {
  cancelQuote,
  dismissCustomerAndAddressValidationError,
  goToPreviousStep,
  saveAddressValidationStep
} from "../actions/newQuoteActions";

import AddressValidation from "./AddressValidation";

const mapStateToProps = state => ({
  enteredAddress: state.newQuote.steps.addressValidation.enteredAddress,
  suggestedAddress: state.newQuote.steps.addressValidation.suggestedAddress,
  error:
    state.newQuote.steps.addressValidation.error ||
    state.newQuote.steps.customer.error,
  isAdverseRiskCustomer: state.newQuote.steps.customer.isAdverseRisk
});

const mapDispatchToProps = {
  onClose: cancelQuote,
  onBackClick: goToPreviousStep,
  onDismissError: dismissCustomerAndAddressValidationError,
  onSubmit: saveAddressValidationStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressValidation);
