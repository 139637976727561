import React from "react";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const Error = () => {
  return (
    <div>
      <h1>Unfortunately, something has gone wrong.</h1>
      <p>
        <HelpDeskMessage display="anErrorOccurred" />
      </p>
    </div>
  );
};

export default Error;
