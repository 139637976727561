import React, { useState } from "react";
import * as routes from "../../constants/routes";
import * as keys from "../../constants/localStorageKeys";
import { connect } from "react-redux";

import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

import { toast } from "react-toastify";
import { logger } from "../../loggers";
import { clearLocalQuoteManually } from "./util";
import { cancelQuote } from "../../top-action-cards/new-quote/actions/newQuoteActions";

import ExitQuoteConfirmationModal from "./ExitQuoteConfirmationModal";

const ExitQuoteModal = ({ history, onExit, setShowExitQuoteModal }) => {
  const [showExitQuoteConfirmationModal, setShowExitQuoteConfirmationModal] =
    useState(false);

  toast.dismiss();

  const step = Number(window.localStorage.getItem(keys.CURRENT_STEP));
  const supportingData = window.localStorage.getItem(keys.QUOTE_SUPPORT_DATA);

  const showDeleteBtn =
    step > 2 || (!!supportingData && supportingData !== "null");

  const saveAndExit = () => {
    setShowExitQuoteModal(false);
    onExit().then(() => {
      clearLocalQuoteManually();
    });

    // send tracking to GA
    logger.event({
      category: "OQ_quote",
      action: "saved",
      value: step
    });
    history.push(routes.COMMERCIAL_LINES);
  };

  return (
    <>
      {showExitQuoteConfirmationModal && (
        <ExitQuoteConfirmationModal
          history={history}
          setShowExitQuoteModal={setShowExitQuoteModal}
          setShowExitQuoteConfirmationModal={setShowExitQuoteConfirmationModal}
        />
      )}
      {!showExitQuoteConfirmationModal && (
        <Modal
          title="Exit Confirmation"
          body="Are you sure you want to exit the quote?"
          closeIcon={false}
          footer={
            <FlexRow>
              {showDeleteBtn && (
                <Button
                  isLink
                  variant="tertiary"
                  onClick={() => {
                    setShowExitQuoteConfirmationModal(true);
                  }}
                >
                  Delete Quote and Exit
                </Button>
              )}
              <Button
                variant="plain"
                onClick={() => {
                  setShowExitQuoteModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  saveAndExit();
                }}
              >
                {!!supportingData && supportingData !== "null" && "Save and "}{" "}
                Exit Quote
              </Button>
            </FlexRow>
          }
          show
          onHide={() => {
            setShowExitQuoteModal(false);
          }}
          altCloseMethod={false}
          size="md"
        />
      )}
    </>
  );
};

export default connect(null, { onExit: cancelQuote })(ExitQuoteModal);
