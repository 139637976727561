import { connect } from "react-redux";
import CompletePaymentLegacy from "./CompletePaymentLegacy";
import { copyAgencySweepPaymentRequestDetails } from "../complete-payment/agencySweepPaymentRequestDetailsAction";
import * as completePaymentLegacySelector from "./completePaymentLegacySelector";
import * as completePaymentSelector from "./completePaymentSelector";

const mapStateToProps = state => ({
  accountBilledRows: completePaymentLegacySelector.getAccountBilledCompletePaymentRows(
    state
  ),
  policyBilledRows: completePaymentLegacySelector.getPolicyBilledCompletePaymentRows(
    state
  ),
  hasAccountAndBillingInformation: completePaymentSelector.hasAccountAndBillingInformation(
    state
  ),
  agencySweepAccountNumber: completePaymentSelector.getAgencySweepAccountNumber(
    state
  ),
  hasDirectBillPayNowEnrollment: completePaymentSelector.hasDirectBillPayNowEnrollment(
    state
  )
});

const mapDispatchToProps = {
  copyAgencySweepPaymentRequestDetails
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePaymentLegacy);
