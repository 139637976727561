import * as types from "./actionTypes";
import * as reportService from "../services/accountingReportService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";
import { toast } from "react-toastify";

const fetchAgencyStatementListStarted = () => ({
  type: types.FETCH_AGENCY_STATEMENT_LIST_STARTED
});

const fetchAgencyStatementListSucceeded = data => ({
  type: types.FETCH_AGENCY_STATEMENT_LIST_SUCCEEDED,
  payload: data
});

const fetchAgencyStatementListFailed = error => ({
  type: types.FETCH_AGENCY_STATEMENT_LIST_FAILED,
  payload: error
});

const fetchPurchasedAgencyCodesStarted = () => ({
  type: types.FETCH_PURCHASED_AGENCY_CODES_STARTED
});

const fetchPurchasedAgencyCodesSucceeded = data => ({
  type: types.FETCH_PURCHASED_AGENCY_CODES_SUCCEEDED,
  payload: data
});

const fetchPurchasedAgencyCodesFailed = error => ({
  type: types.FETCH_PURCHASED_AGENCY_CODES_FAILED,
  payload: error
});

const fetchSponsoredAgencyCodesStarted = () => ({
  type: types.FETCH_SPONSORED_AGENCY_CODES_STARTED
});

const fetchSponsoredAgencyCodesSucceeded = data => ({
  type: types.FETCH_SPONSORED_AGENCY_CODES_SUCCEEDED,
  payload: data
});

const fetchSponsoredAgencyCodesFailed = error => ({
  type: types.FETCH_SPONSORED_AGENCY_CODES_FAILED,
  payload: error
});

export const fetchPurchasedAgencyCodes = agency_code => {
  return dispatch => {
    dispatch(fetchPurchasedAgencyCodesStarted());

    return reportService.getPurchasedAgencyCodes(agency_code).then(
      response => {
        dispatch(fetchPurchasedAgencyCodesSucceeded(response.data));
      },
      error => {
        dispatch(fetchPurchasedAgencyCodesFailed(error.response));
      }
    );
  };
};

export const fetchSponsoredAgencyCodes = agency_code => {
  return dispatch => {
    dispatch(fetchSponsoredAgencyCodesStarted());

    return reportService.getSponsoredAgencyCodes(agency_code).then(
      response => {
        dispatch(fetchSponsoredAgencyCodesSucceeded(response.data));
      },
      error => {
        dispatch(fetchSponsoredAgencyCodesFailed(error.response));
      }
    );
  };
};

export const fetchAgencyStatementListDetails = (
  agency_code,
  purchased_sponsored_agency
) => {
  return dispatch => {
    dispatch(fetchAgencyStatementListStarted());

    return reportService
      .getAgencyStatementList(agency_code, purchased_sponsored_agency)
      .then(
        response => {
          dispatch(fetchAgencyStatementListSucceeded(response.data));
        },
        error => {
          dispatch(fetchAgencyStatementListFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

const displayToastError = () => {
  toast(
    "We’re sorry, but an error occurred while processing your request.  To obtain a copy of this report, please contact Direct Bill customer service at 800-637-6309.",
    {
      position: "top-center",
      className: "error",
      autoClose: false
    }
  );
};

export const fetchAccountingStatementViewPDF =
  ({
    agency_code,
    billing_type,
    date,
    document_id,
    report_group,
    report_type,
    source
  }) =>
  () => {
    const documentService = new DocumentService();
    return reportService
      .getAgencyStatementDocuments({
        agency_code,
        billing_type,
        date,
        document_id,
        report_group,
        report_type,
        source
      })
      .then(
        response => {
          documentService.initializeRenderer("/loading-document");
          documentService.saveOrOpenDocument(
            response.data,
            `Accounting-Statement.pdf`
          );
        },
        error => {
          documentService.clearRenderer();
          displayToastError();
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
