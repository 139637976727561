import React, { useState, useContext } from "react";
import { quotePath } from "../shared/constants";
import { toTitleCase } from "../../components/Factory";
import { Button } from "@ufginsurance/ui-kit";
import EditCustomerModal from "./EditCustomerModal";
import OnlineQuotingContext from "../OnlineQuotingContext";

const CustomerInfo = () => {
  const { getQuotePart, quoteData, step, supportingData } =
    useContext(OnlineQuotingContext);

  const [showModal, setShowModal] = useState(false);

  const emailAddress1 =
    step === 2
      ? supportingData?.customerInformation?.accountHolder?.emailAddress1 || ""
      : quoteData?.baseData?.accountHolder?.emailAddress1 || "";

  //contact constants
  const subtype =
    quoteData || supportingData
      ? step === 2
        ? supportingData?.customerInformation?.accountHolder?.subType
        : quoteData?.baseData?.accountHolder?.subtype
      : "";

  const companyName =
    quoteData || supportingData
      ? step === 2
        ? supportingData?.customerInformation?.accountHolder?.contactName
        : quoteData?.baseData?.accountHolder?.contactName
      : "";

  const firstName =
    quoteData || supportingData
      ? step === 2
        ? supportingData?.customerInformation?.accountHolder?.firstName
        : quoteData?.baseData?.accountHolder?.firstName
      : "";

  const lastName =
    quoteData || supportingData
      ? step === 2
        ? supportingData?.customerInformation?.accountHolder?.lastName
        : quoteData?.baseData?.accountHolder?.lastName
      : "";

  return (
    <>
      <div className="oq__sidebar__summary__item">
        {step === 2 && !!supportingData && (
          <>
            <b>Business Information:</b>
            <div>
              {supportingData?.customerInformation?.accountHolder
                ?.contactName ||
                `${supportingData?.customerInformation?.accountHolder?.firstName} ${supportingData?.customerInformation?.accountHolder?.lastName}`}
            </div>
            <div>
              {toTitleCase(
                supportingData?.customerInformation?.accountHolder
                  ?.primaryAddress?.addressLine1
              )}
            </div>
            <div className="flexRow wrap">
              <span className="oq__sidebar__summary__city">
                {toTitleCase(
                  supportingData?.customerInformation?.accountHolder
                    ?.primaryAddress?.city
                )}
              </span>
              ,
              <span className="oq__sidebar__summary__state">
                {supportingData?.customerInformation?.accountHolder?.primaryAddress?.state.toUpperCase()}
              </span>
              <span className="oq__sidebar__summary__postalCode">
                {
                  supportingData?.customerInformation?.accountHolder
                    ?.primaryAddress?.postalCode
                }
              </span>
              <span className="oq__sidebar__summary__addressType">
                (Primary)
              </span>
            </div>
            <div>{emailAddress1}</div>
          </>
        )}
        {step > 2 && (
          <>
            <b>Business Information:</b>
            <div>{getQuotePart(quotePath.contactName)}</div>
            <div>
              {toTitleCase(getQuotePart(quotePath.primaryAddress.address))}
            </div>
            <div className="flexRow wrap">
              <span className="oq__sidebar__summary__city">
                {toTitleCase(getQuotePart(quotePath.primaryAddress.city))}
              </span>
              ,
              <span className="oq__sidebar__summary__state">
                {getQuotePart(quotePath.primaryAddress.state) ||
                  "".toUpperCase()}
              </span>
              <span className="oq__sidebar__summary__postalCode">
                {getQuotePart(quotePath.primaryAddress.zip)}
              </span>
              <span className="oq__sidebar__summary__addressType">
                (Primary)
              </span>
            </div>
            <div>{emailAddress1}</div>
          </>
        )}
        {!supportingData?.customerInformation?.isInForce && (
          <>
            <Button isLink inline onClick={() => setShowModal(true)}>
              Edit Customer
            </Button>
          </>
        )}
      </div>
      {showModal && (
        <EditCustomerModal
          onHide={() => setShowModal(false)}
          quoteId={quoteData?.quoteID}
          initialValues={{
            companyName,
            firstName,
            lastName,
            subtype,
            emailAddress1
          }}
        />
      )}
    </>
  );
};

export default CustomerInfo;
