import { connect } from "react-redux";
import ServiceCenterSummary from "../components/agency/service-center-summary/ServiceCenterSummary";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import {
  fetchCommercialDateListDetails,
  fetchPersonalDateListDetails,
  fetchServiceCenterSummary
} from "../actions/serviceCenterSummaryAction";
import {
  getCommercialDateList,
  getPersonalDateList
} from "../selectors/serviceCenterSummarySelector";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  commercialDateList: getCommercialDateList(state),
  personalDateList: getPersonalDateList(state),
  producerHasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state)
});

const mapDispatchToProps = {
  fetchCommercialDateListDetails,
  fetchPersonalDateListDetails,
  fetchServiceCenterSummary
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCenterSummary);
