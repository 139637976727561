import { connect } from "react-redux";
import OwnerInfo from "./OwnerInfo";
import { verifyAddress, verifyAddresses } from "../actions/verifyAddressAction";
import { updateSuretyQuoteData } from "../actions/suretyQuoteActions";
import { fetchSuretyZip } from "../actions/suretyZipAction";
import { getCacheId } from "../../selectors/cacheId";
import {
  validateOwnersSsn,
  validateHasWholeNumberOwnershipPercents
} from "../../selectors/ownersData";

const stateSelector = state =>
  state.suretyQuoteAgencyData?.data?.licensed_states;


const ownersSelector = state =>
  //if nothing in form state, use account data
  state?.suretyQuote?.data?.ownerData || state?.quickOwnersList?.data

const mapStateToProps = (state, ownProps) => {
  return {
    quoteData: ownProps.quoteData,
    updateQuote: ownProps.updateQuote,
    verifyAddressData: state.quickVerifyAddress,
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state),
    licensedStates: stateSelector(state),
    suretyZip: state.suretyZip,
    isOwnerSsnValid: validateOwnersSsn(
      ownersSelector(state)
    ),
    hasWholeNumberOwnershipPercents: validateHasWholeNumberOwnershipPercents(
      ownersSelector(state)
    )
  };
};

const mapDispatchToProps = {
  verifyAddress,
  verifyAddresses,
  updateSuretyQuoteData,
  fetchSuretyZip
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInfo);