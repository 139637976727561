import qs from "query-string";
import API from "../api";
import { DateFormat } from "../components/Factory";

export const getBillingDetails = (
  policy_account_number,
  agency_code,
  activity_date_from,
  activity_date_to
) => {
  let queryString = qs.stringify({
    policy_account_number,
    agency_code
  });

  if (activity_date_from && activity_date_to) {
    queryString = qs.stringify({
      policy_account_number,
      agency_code,
      activity_date_from: DateFormat(activity_date_from, "YYYY-MM-DD"),
      activity_date_to: DateFormat(activity_date_to, "YYYY-MM-DD")
    });
  }

  return API.agent().get(`/billing/history?${queryString}`);
};

export const getBillingActivity = (policy_account_number, agency_code) => {
  const queryString = qs.stringify({
    policy_account_number,
    agency_code
  });
  return API.agent().get(`/billing/accounts?${queryString}`);
};

export const fetchBillingPastDue = agency_code => {
  const queryString = qs.stringify({ agency_code, number_days: 15 });
  return API.agent().get(`/billing/agency/PastDue?${queryString}`);
};

export const fetchBillingDueSoon = agency_code => {
  const queryString = qs.stringify({ agency_code, number_days: 15 });
  return API.agent().get(`/billing/agency/DueSoon?${queryString}`);
};

export const getInstallmentDetails = (policy_account_number, agency_code) => {
  const queryString = qs.stringify({
    account: policy_account_number,
    agency_code
  });
  return API.agent().get(`/billing/installment-schedule?${queryString}`);
};

export const getDirectBillPayNowEnrollment = agency_code => {
  return API.agent().get(`/agencies/${agency_code}/payment-method-token/DB`);
};

export const getInstallmentPrintReport = data => {
  return API.agent().post(`/billing/installment-schedule-report`, data, {
    responseType: "blob"
  });
};

export const getHistoryPrintReport = (data, show_balance) => {
  const queryString = qs.stringify({ show_balance });
  return API.agent().post(`/billing/history-report?${queryString}`, data, {
    responseType: "blob"
  });
};

export const getViewGWStatementList = (policy_number, account_number) => {
  const queryString = qs.stringify({
    policy_number,
    account_number
  });
  return API.documents().get(`/billing/statements-list?${queryString}`);
};

export const getBillingGWDocuments = (
  bill_type,
  policy_number,
  document_id
) => {
  const queryString = qs.stringify({
    bill_type,
    number: policy_number,
    document_id
  });
  return API.documents().get(
    `/billing/guidewire-statements-document?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const getLegacyDocuments = (
  bill_type,
  bill_date,
  policy_number,
  category
) => {
  const queryString = qs.stringify({
    bill_type,
    number: policy_number,
    date: DateFormat(bill_date, "YYYY-MM-DD"),
    category
  });
  return API.documents().get(
    `/billing/legacy-statements-document?${queryString}`,
    {
      responseType: "blob"
    }
  );
};
