import * as types from "./WebsiteLogoActionTypes";
import initialState from "../../reducers/initialState";

const WebsiteLogoReducer = (state = initialState.websiteLogoUsage, action) => {
  switch (action.type) {
    case types.RESET_WEBSITE_LOGO:
      return initialState.websiteLogoUsage;
    case types.UPDATE_WEBSITE_LOGO_STATE:
      return {
        ...state,
        ...action.payload
      };
    case types.POST_AGENCY_LINKING_FORM_STARTED:
      return {
        ...state,
        agencyLinkingLoading: true,
        agencyLinkingError: false
      };
    case types.POST_AGENCY_LINKING_FORM_SUCCEEDED:
      return {
        ...state,
        agencyLinking: action.payload,
        agencyLinkingLoading: false,
        agencyLinkingError: false
      };
    case types.POST_AGENCY_LINKING_FORM_FAILED:
      return {
        ...state,
        agencyLinkingLoading: false,
        agencyLinkingError: action.payload,
        agencyLinking: null
      };
    default:
      return state;
  }
};
export default WebsiteLogoReducer;
