import * as types from "../../constants/actionTypes";

export const copyAgencySweepPaymentRequestDetailsSucceeded = data => ({
  type: types.COPY_AGENCY_SWEEP_PAYMENT_REQUEST_DETAILS_SUCCEEDED,
  payload: data
});

export const copyAgencySweepPaymentRequestDetails = data => dispatch => {
  dispatch(copyAgencySweepPaymentRequestDetailsSucceeded(data));
};
