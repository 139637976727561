import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const ConfirmationModal = ({
  show,
  message,
  handleClose,
  handleConfirm,
  title = "",
  subTitle = "",
  className = "",
  id = "",
  altCloseMethod = false,
  closeIcon = false,
  footer,
  cancelText,
  confirmText,
  confirmSpinner = false,
  confirmDisabled = false,
  cancelDisabled = false
}) => {
  const defaultFooter = (
    <FlexRow align="right">
      <Button
        disabled={cancelDisabled}
        variant="secondary"
        onClick={handleClose}
      >
        {cancelText ? cancelText : "Cancel"}
      </Button>
      <Button
        disabled={confirmDisabled}
        spinner={confirmSpinner}
        variant="primary"
        onClick={handleConfirm}
      >
        {confirmText ? confirmText : "OK"}
      </Button>
    </FlexRow>
  );

  return (
    <>
      {message && (
        <Modal
          className={`${className} confirmation-modal`}
          id={id}
          title={title}
          subTitle={subTitle}
          show={show}
          onHide={handleClose}
          altCloseMethod={altCloseMethod}
          closeIcon={closeIcon}
          body={message}
          footer={footer ? footer : defaultFooter}
        />
      )}
    </>
  );
};

const propTypes = {
  show: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  altCloseMethod: PropTypes.bool,
  closeIcon: PropTypes.bool,
  footer: PropTypes.element,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  confirmSpinner: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool
};

ConfirmationModal.propTypes = propTypes;

export default ConfirmationModal;
