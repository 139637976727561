import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Translations } from "./translations";
import { Alert, Icon } from "@ufginsurance/ui-kit";

const AgencyNumberTextbox = ({
  handleAgentNumberUpdate,
  iconEnable,
  buttonText,
  isSpecialtyPortalView
}) => {
  const [agencyNumber, setAgencyNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isValidAgencyCode, setIsValidAgencyCode] = useState(true);

  const btnClassName = iconEnable ? " hidden" : "";
  const agentCodeSubmittedClass = classnames("", {
    "agent-code-submitted": submitted && isValidAgencyCode
  });

  const validateAgencyCodeFormat = agencyCode => {
    const agencyCodeRegex = /^\d{0,6}$/i;
    if (!!agencyCode && !agencyCodeRegex.test(agencyCode)) {
      return true;
    }
    return null;
  };

  const handleChange = e => {
    if (validateAgencyCodeFormat(e.target.value)) {
      e.preventDefault();
      return;
    }

    setAgencyNumber(e.target.value);
    setSubmitted(false);
  };

  const handlePaste = e => {
    if (validateAgencyCodeFormat(e.clipboardData.getData("Text").trim())) {
      e.preventDefault();
    }
  };

  const handleKeyDown = e => {
    const keyInput = e.key && e.key.length === 1 ? e.key : "";
    const hasSelection = window.getSelection().toString() === e.target.value;
    const newValue = hasSelection ? keyInput : `${e.target.value}${keyInput}`;
    if (!e.ctrlKey && validateAgencyCodeFormat(newValue)) {
      e.preventDefault();
    }
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    if (agencyNumber.length === 6) {
      handleAgentNumberUpdate(agencyNumber);
      setIsValidAgencyCode(true);
    } else {
      setIsValidAgencyCode(false);
    }
  };

  const searchBarText = () => {
    return isSpecialtyPortalView
      ? Translations.search.broker_header_placeholder
      : Translations.search.agent_header_placeholder;
  };

  const invalidCodeLengthError = () => {
    return isSpecialtyPortalView
      ? Translations.search.invalid_broker_code
      : Translations.search.invalid_agent_code;
  };

  return (
    <div>
      {!isValidAgencyCode && submitted && (
        <Alert type="error" dismissible={false}>
          {invalidCodeLengthError()}
        </Alert>
      )}
      <div className="form-group has-feedback">
        <form onSubmit={handleSubmit}>
          <div className="sr-only agency-number-textbox-search-label">Search</div>
          <div className={!iconEnable ? "" : ""}>
            <input
              type="text"
              className={`${agentCodeSubmittedClass} form-control`}
              onPaste={handlePaste}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={searchBarText()}
            />
            <button
              aria-labelledby="agency-number-textbox-search-label"
              type="submit"
              className="search-button"
              onClick={handleSubmit}
            >
              {iconEnable && <Icon icon="fasSearch" />}
            </button>
          </div>
          <div className={btnClassName}>
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-success submit"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

AgencyNumberTextbox.propTypes = {
  handleAgentNumberUpdate: PropTypes.func,
  iconEnable: PropTypes.bool,
  buttonText: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool
};

AgencyNumberTextbox.defaultProps = { iconEnable: true };

export default AgencyNumberTextbox;
