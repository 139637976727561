import React from "react";

import ProductLines from "./ProductLines";
import { Panel, FormGroup, LoadingIndicator } from "@ufginsurance/ui-kit";
import ProductsPanelCustomMessage from "./ProductsPanelCustomMessage";

const ProductsPanels = ({
  form,
  isBranch11Agent,
  rawMetadata,
  selectedState,
  productMatrix
}) => {
  return (
    <Panel
      className="oq__step1__products"
      rounded
      bgcolor="grey"
      title="Select Product(s)"
      titlebar
    >
      <ProductsPanelCustomMessage
        effectiveDate={form?.values?.effective_date}
        selectedState={selectedState}
      />
      {!productMatrix ? (
        <LoadingIndicator />
      ) : (
        <FormGroup>
          {isBranch11Agent &&
          !(rawMetadata?.proQuoteEnabledStates || []).includes(
            selectedState
          ) ? (
            <div>No products available for online quoting.</div>
          ) : (
            <>
              <ProductLines
                productMatrix={productMatrix}
                form={form}
                isBranch11Agent={isBranch11Agent}
              />
            </>
          )}
        </FormGroup>
      )}
    </Panel>
  );
};

export default ProductsPanels;
