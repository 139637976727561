import { parseLocation } from "parse-address";
import { formatDate } from "@ufginsurance/ui-kit";
import { MAX_ATTACHMENTS_SIZE } from "../../quick-program/quick-quoting/constants/suretyDictionary";
import { StateLongNames } from "../../constants/dictionary";
import { toTitleCase } from "../../components/Factory";
import { formatZip } from "../../quick-program/quick-quoting/shared/util";

export const openPopupWindow = path => {
  const newWindow = window.open(
    path,
    "popUpWindow",
    "width=" +
      (window.screen.availWidth - 260) +
      ",height=" +
      (window.screen.availHeight - 250) +
      ",toolbar=0,location=0,menubar=1,status=1,resizable=1,scrollbars=1,align=center"
  );
  if (newWindow) newWindow.opener = null;
};

export const exceedsMaxSize = files => {
  const totalSize = files.reduce((tot, file) => (tot += file.size), 0);
  return Boolean(totalSize > MAX_ATTACHMENTS_SIZE);
};

export const convertBytesToMB = b =>
  parseFloat(b / Math.pow(1024, 2)).toFixed(2);

export const formatCurrencyUSD = (amount = 0, decimal = 0, options = {}) => {
  if (amount?.length && amount.charAt(amount?.length - 1) === "-") {
    amount = `-${amount.slice(0, -1)}`;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    ...options
  }).format(amount);
};

export const formatPercent = (number, convertDecimal = false, decimals = 0) => {
  if (!number) return;
  if (convertDecimal) number = parseFloat(number);
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(number);
};

export const formatAccountsDropdownOptions = accounts =>
  accounts.map(a => ({
    value: a.account_number,
    label: `${a.account_name} (${toTitleCase(a.city)}, ${a.state})`,
    account_number: a.account_number,
    account_name: a.account_name,
    state: StateLongNames[a.state]
  }));

export const formatBondDropdownOption = (bond, bond_number) => {
  const blankSpace = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";
  return bond.map(b => ({
    value: bond_number,
    label: `Bond No: ${bond_number} ${blankSpace} ${
      b.account_name
    } (${toTitleCase(b.account_city)}, ${b.account_state})`,
    account_number: b.account_number,
    account_name: b.account_name,
    state: StateLongNames[b.account_state]
  }));
};

export const createAddressZone = address => {
  if (!address) return;
  return `${toTitleCase(address?.account_city || "")}, ${
    address?.account_state
  } ${formatZip(address?.account_zip || "")}`;
};

export const createSuretyAccountAddressChangePayload = ({
  account,
  agency,
  agent,
  values
}) => ({
  account_number: account.account_number,
  agency_name: agency.name,
  agent_code: agent.code,
  agent_email: agent.email,
  agent_name: agent.name,
  comments_remarks: values.comments,
  current_account_name: account.account_name,
  effective_date: values.effective_date,
  current_address: {
    address_line: account.account_street_address,
    city: account.account_city,
    state: account.account_state,
    zip: account.account_zip
  },
  new_address: {
    address_line: values.address_line,
    city: values.city,
    state: values.state,
    zip: values.zip
  }
});

export const createSuretyAccountNameChangePayload = ({
  account,
  values,
  agent,
  agency
}) => {
  return {
    agent_code: agent.code,
    agent_name: agent.name,
    agency_name: agency.name,
    agent_email: agent.email,
    effective_date: values.effective_date,
    current_account_name: account.account_name,
    current_address: {
      address_line: account.account_street_address,
      city: account.account_city,
      state: account.account_state,
      zip: account.account_zip
    },
    new_account_name: values.new_account_name,
    comments_remarks: values.comments
  };
};

export const createBondEditPayload = ({ bond, agency, producer, values }) => {
  const parsedCSZ = parseLocation(
    Object.values(bond?.account_address).join(" ")
  );

  return {
    agency_code: agency?.agencyCode,
    agency_name: agency?.name,
    producer_code: producer.code,
    producer_name: producer.name,
    producer_email: producer.email,
    change_effective_date: values.change_effective_date,
    bond_number: bond.bond_number,
    account_name: bond.account_name,
    account_address: {
      address_line: bond.account_address.street,
      city: parsedCSZ.city,
      state: parsedCSZ.state,
      zip: parsedCSZ.zip
    },
    ...values
  };
};

export const getIsBondOverviewLinkValid = activityType => {
  if (!activityType) return;
  const invalidStatuses = ["bid request", "new contract bond"];
  if (invalidStatuses.includes(activityType.toLowerCase())) return false;
  return true;
};

export const createConsentOfSuretyPayload = (values, consentOfSuretyTypes) => {
  if (!values) return;

  const form = consentOfSuretyTypes.find(
    ({ value }) => value === values?.consent_type
  );

  const payload = {
    agency_code: values?.agency?.agencyCode,
    account: {
      account_number: values?.account_number,
      account_name: values?.account_name,
      account_address: values?.address_line,
      account_city: values?.city,
      account_state: values?.state,
      account_zip: values.zip.includes("-")
        ? values?.zip?.split("-")[0]
        : values.zip
    },
    obligee: {
      obligee_name: values?.obligee_name,
      obligee_address: values?.obligee_address_line,
      obligee_city: values?.obligee_city,
      obligee_state: values?.obligee_state,
      obligee_zip: values?.obligee_zip
    },
    bond_number: values?.accountBondSearch,
    consent_type: values?.consent_type,
    contract_description: values?.project_description,
    effective_date: formatDate(values?.bond?.effective_date, "YYYY-MM-DD"),
    edition_date: "12005", // Static.
    form_description: form?.label?.toUpperCase(), // (Final Payment || Reduction Retainage)
    form_number: form?.form, // (CONT0311~012005 || CONT0312~012005).
    requestor_number: parseInt(values?.contact_person),
    signing_date: formatDate(values?.sign_seal_date, "YYYY-MM-DD")
  };

  // Consent Type Fields.
  if (values?.retainage_change_request)
    payload.reduction_retainage = values.retainage_change_request;
  if (values?.final_contract_price)
    payload.contract_amount = parseInt(values.final_contract_price);

  // Optional Fields.
  if (values?.total_claim_value)
    payload.amount_of_claims = parseInt(values.total_claim_value);
  if (values?.architect_project_num)
    payload.architect_number = values.architect_project_num;
  if (values?.all_bills_paid) payload.bills_paid = values.all_bills_paid;
  if (values?.completion_date)
    payload.completion_date = formatDate(values.completion_date, "YYYY-MM-DD");
  if (values?.contract_for) payload.contract_for = values.contract_for;
  if (values?.claims_against_contractor)
    payload.number_of_claims = parseInt(values.claims_against_contractor);
  if (values?.contract_dated)
    payload.contract_date = formatDate(values.contract_dated, "YYYY-MM-DD");

  return payload;
};

/**
 * Checks if the date passed in is less than a year old.
 * @param {String} date A date string.
 * @returns Boolean value of 'true' if the date is less than a year old or 'false' if it's not.
 */
export const checkIfDateLessThanYearOld = date => {
  let yearAgo = new Date();
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);
  yearAgo = formatDate(yearAgo, "YYYY-MM-DD");
  return formatDate(date, "YYYY-MM-DD") >= yearAgo;
};
