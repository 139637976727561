import React, { useCallback, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import {
  Button,
  FlexRow,
  Form,
  FormGroup,
  useForm,
  Input,
  Table
} from "@ufginsurance/ui-kit";
import UpdateOverlay from "../../shared/UpdateOverlay";

import "./PriorLosses.scss";

const PriorLossesTable = ({
  lob,
  fieldList,
  fieldValues,
  onClose,
  showInputs
}) => {
  const { updatePriorLosses, updatingPriorLosses } =
    useContext(OnlineQuotingContext);

  const [formIsVaild, setFormIsValid] = useState(false);

  const onSubmit = useCallback(
    ({ values }) => {
      const payload = [
        ...fieldList.map(row => {
          return {
            claimInfoUnknown: false,
            claimType: row.claimType,
            lob,
            priorYears: row.fields.reduce(
              (acc, field) => ({
                ...acc,
                [field.id]: Number(values[`${row.claimType}.${field.id}`])
              }),
              {}
            )
          };
        })
      ];

      updatePriorLosses({
        lob,
        payload
      }).then(onClose);
    },
    [fieldList, lob, onClose, updatePriorLosses]
  );

  const tableData = fieldList.map(row => {
    const obj = { rowLabel: row.rowLabel };
    row.fields.forEach(
      (f, i) => (obj[`field${i}`] = `${row.claimType}.${f.id}`)
    );
    return obj;
  });

  const columnArray = [
    {
      key: "rowLabel",
      className: "rowHeader"
    },
    ...fieldList[0].fields.map((f, i) => ({
      key: f.label,
      label: f.label,
      sortable: false,
      className: "oq__loss-table__input-cell",
      element: row => {
        const fieldId = row[`field${i}`];

        // if showInputs, then render the Input
        if (showInputs)
          return (
            <Input
              id={fieldId}
              name={fieldId}
              className={classnames("oq__loss-table__input", {
                "field-has-error": !!errors[fieldId]
              })}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values[fieldId]}
              noLabel
              numbersOnly
              maxLength={2}
              required
              size="full"
            />
          );
        // else render just the text
        return <span>{fieldValues[fieldId]}</span>;
      }
    }))
  ];

  const form = useForm({ values: fieldValues, onSubmit });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    errors,
    invalidFields
  } = form;

  useEffect(() => {
    if (showInputs) {
      let isValid = false;

      tableData.forEach(row => {
        // get fields from row
        const fieldsInRow = Object.keys(row).filter(f => f.includes("field"));
        // create array of values from fields in row
        const fieldsInRowValues = fieldsInRow.map(f => values[row[f]]);
        // sum of row values
        const rowTotal = fieldsInRowValues.reduce(
          (a, b) => (Number(a) || 0) + (Number(b) || 0),
          0
        );
        // if all fields in row are 0, then total = 0 and form is not valid
        if (rowTotal > 0) isValid = true;
      });
      setFormIsValid(isValid);
    }
  }, [values, showInputs, tableData]);

  return (
    <>
      {updatingPriorLosses === lob && <UpdateOverlay />}
      {showInputs ? (
        <div>
          <Form context={form}>
            <FormGroup>
              <Table
                className="oq__loss-table"
                rowKey="rowLabel"
                columns={columnArray}
                data={tableData}
              />
            </FormGroup>

            {!formIsVaild && (
              <div className="oq__loss-table__error">
                At least one term must be greater than 0.
              </div>
            )}
            <FlexRow align="right">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!formIsVaild || !!invalidFields.length}
              >
                Continue
              </Button>
            </FlexRow>
          </Form>
        </div>
      ) : (
        <Table
          className="oq__loss-table"
          rowKey="rowLabel"
          columns={columnArray}
          data={tableData}
        />
      )}
    </>
  );
};

export default PriorLossesTable;
