import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../components/Factory";
import { Translations } from "../components/translations";
import { LoadingIndicator, Table } from "@ufginsurance/ui-kit";

const CancellationTable = props => {
  const {
    cancellationList,
    isCancellationLoading,
    errorCancellationList,
    fetchCancellationDocument,
    policy_number
  } = props;

  const handleDocumentClick = (date, document_id) => () => {
    fetchCancellationDocument(policy_number, date, document_id);
  };

  const createColumns = () => [
    {
      key: "date",
      label: "Process Date",
      element: row => <span>{DateFormat(row.date, "MM/DD/YYYY")}</span>
    },
    {
      key: "policy_number",
      label: "Policy Number",
      element: () => <span>{policy_number}</span>
    },
    {
      key: "id",
      label: "Document Type",
      element: row => (
        <button
          className="btn-as-link"
          onClick={handleDocumentClick(row.date, row.id)}
        >
          Cancellation Memo
        </button>
      )
    }
  ];

  return (
    <div className="cancellation-table">
      {isCancellationLoading ? (
        <LoadingIndicator />
      ) : (
        <div>
          {errorCancellationList ? (
            <span>{Translations.general.error}</span>
          ) : (
            <Table
              rowKey="id"
              data={cancellationList}
              columns={createColumns(cancellationList)}
              itemsPerPage={20}
              condensed={false}
              initialSort="date"
              initialDirection="desc"
              showPagination
            />
          )}
        </div>
      )}
    </div>
  );
};

CancellationTable.propTypes = {
  cancellationList: PropTypes.array.isRequired,
  policy_number: PropTypes.string.isRequired,
  fetchCancellationDocument: PropTypes.func,
  isCancellationLoading: PropTypes.bool.isRequired,
  errorCancellationList: PropTypes.any
};

export default CancellationTable;
