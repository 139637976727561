import { connect } from "react-redux";
import ReportsHome from "../components/ReportsHome";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getServiceCenterAccess } from "../selectors/underwritingSelector";

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  hasAgencyAccountingReportsAccess:
    sessionSelector.hasAgencyAccountingReportsAccess(state),
  hasAgencyProductionReportsAccess:
    sessionSelector.hasAgencyProductionReportsAccess(state),
  hasAgencyClaimReportsAccess:
    sessionSelector.hasAgencyClaimReportsAccess(state),
  has_personal_lines_contract:
    sessionSelector.agencyHasPersonalLinesContract(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  isPilotAgent: sessionSelector.isPilotAgent(state),
  hasServiceCenterAccess: getServiceCenterAccess(state),
  isAgencyAdministrator: sessionSelector.isAgencyAdministrator(state)
});

export default connect(mapStateToProps)(ReportsHome);
