import * as types from "../actions/reportClaimActionTypes";
import initialState from "../../../reducers/initialState";

const reportClaimReducer = (state = initialState.reportClaim, action) => {
  switch (action.type) {
    case types.START_REPORT_A_CLAIM:
      return {
        ...state,
        startReportAClaim: true,
        policyNumber: action.policyNumber || null
      };
    case types.CANCEL_REPORT_A_CLAIM:
      return {
        ...state,
        startReportAClaim: false
      };
    default:
      return state;
  }
};

export default reportClaimReducer;
