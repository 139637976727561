import axios from "axios";
import {
  secureRequestInterceptor,
  secureResponseInterceptor
} from "@ufginsurance/sso-oidc-client-react";

import { authorizationForbiddenResponseInterceptor } from "./services/interceptor/authorizationForbiddenResponseInterceptor";
import { clientIDRequestInterceptor } from "./services/interceptor/clientIDRequestInterceptor";
import { getStoreState } from "./store";
import { logger } from "./loggers";

const logError = (message, error) => {
  logger.error({
    description: `${message}: ${JSON.stringify(error)}`,
    fatal: true
  });
};

const apiFactory = (baseURL, client_id, client_secret, noCache) => {
  const config = {
    baseURL,
    headers: {}
  };

  if (noCache) {
    config.headers["Cache-Control"] = "no-cache, no-store";
    config.headers.cache = "no-cache";
  }
  if ((baseURL || "").includes("amazon"))
    config.headers["Referrer-Policy"] = "origin";

  const service = axios.create(config);

  secureRequestInterceptor(
    service.interceptors.request,
    getStoreState,
    logError
  );

  secureResponseInterceptor(
    service.interceptors.response,
    getStoreState,
    logError
  );

  if (client_id && client_secret)
    clientIDRequestInterceptor(
      service.interceptors.request,
      client_id,
      client_secret
    );

  authorizationForbiddenResponseInterceptor(service.interceptors.response);

  return service;
};

// Docs: https://ufginsurance.atlassian.net/wiki/spaces/SYS/pages/3354788064/API+Consumption+in+Agent+Portal.
export default {
  address: () =>
    apiFactory(
      window.env.REACT_APP_M4_ADDRESS_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  agency: () =>
    apiFactory(
      window.env.REACT_APP_M4_AGENCY_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  agent: () =>
    apiFactory(
      window.env.REACT_APP_M4_AGENT_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  aws: aws => {
    const resources = {
      default: window.env.REACT_APP_AWS_RISK_INTEGRATION_BASEURL,
      adminProQuoteProductMatrix:
        window.env.REACT_APP_AWS_ADMIN_PROQUOTE_PROD_MATRIX,
      "risk-indication-submission":
        window.env.REACT_APP_AWS_RISK_INTEGRATION_BASEURL
    };

    return apiFactory(resources?.[aws] || resources.default);
  },
  base: (secret = "", path = "") => {
    const baseURL = window.env.REACT_APP_API_BASEURL + path;
    const secrets = {
      quoting: {
        client_id: window.env.REACT_APP_ONLINE_QUOTING_CLIENT_ID,
        client_secret: window.env.REACT_APP_ONLINE_QUOTING_CLIENT_SECRET_ID
      },
      default: {
        client_id: window.env.REACT_APP_CLIENT_ID,
        client_secret: window.env.REACT_APP_CLIENT_SECRET_ID
      }
    };
    const use = secrets?.[secret] || secrets.default;
    const { client_id, client_secret } = use;

    return apiFactory(baseURL, client_id, client_secret);
  },
  commercial: () =>
    apiFactory(
      window.env.REACT_APP_M4_COMMERCIAL_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  documents: () =>
    apiFactory(
      window.env.REACT_APP_M4_DOCUMENTS_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  surety: () =>
    apiFactory(
      window.env.REACT_APP_M4_SURETY_BASEURL,
      window.env.REACT_APP_CLIENT_ID,
      window.env.REACT_APP_CLIENT_SECRET_ID
    ),
  onlineQuoting: (noCache = false) =>
    apiFactory(
      window.env.REACT_APP_API_ONLINE__QUOTING_BASEURL,
      window.env.REACT_APP_ONLINE_QUOTING_CLIENT_ID,
      window.env.REACT_APP_ONLINE_QUOTING_CLIENT_SECRET_ID,
      noCache
    ),
  url: url => apiFactory(url)
};
