import React, { StrictMode } from "react";
import {
  ssoClient,
  SSOValidationContainer,
  configuration
} from "@ufginsurance/sso-oidc-client-react";
import App from "./App";
import { withLDConsumer, useFlags } from "launchdarkly-react-client-sdk";

const REACT_APP_ENABLE_STRICTMODE =
  window.env.REACT_APP_ENABLE_STRICTMODE === "true";

const LaunchDarklyContainer = ({ store }) => {
  const { specialtyEnabled } = useFlags();
  return (
    <SSOValidationContainer
      specialtyEnabled={specialtyEnabled}
      store={store}
      userManager={ssoClient.userManager}
      clientType={configuration.CLIENT_TYPE_AGENT}
    >
      {REACT_APP_ENABLE_STRICTMODE ? (
        <StrictMode>
          <App />
        </StrictMode>
      ) : (
        <App />
      )}
    </SSOValidationContainer>
  );
};
export default withLDConsumer()(LaunchDarklyContainer);
