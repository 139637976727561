import React, { useState, useEffect } from "react";
import { Alert, Button, Icon, Table } from "@ufginsurance/ui-kit";
import DragAndDropAttachments from "../../../components/commercial/commercial-lines/DragAndDropAttachments";
import { convertBytesToMB, exceedsMaxSize } from "../../../surety/shared/utils";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";
import { ALLOWED_FILE_TYPES } from "../constants/suretyDictionary";

export default function DocumentUpload({
  setIsUploading,
  quickSubmissionData,
  agencyCode,
  postQuickDocuments,
  suretyQuickUserAttachments
}) {
  const [attachments, setAttachments] = useState([]);
  const [showAlert, setShowAlert] = useState({});

  useEffect(() => {
    if (suretyQuickUserAttachments.error) {
      setShowAlert({ type: "error" });
    } else {
      setShowAlert({});
    }
  }, [suretyQuickUserAttachments]);

  const fileSizeErrMsg = "The combined filesize is too large";

  const handleUploadDocsSubmit = () => {
    if (!quickSubmissionData) {
      setShowAlert({ type: "error" });
      return;
    }

    postQuickDocuments({
      quickSubmissionData,
      agencyCode,
      files: attachments
    });
  };

  const handleSelectFiles = selectedAttachments => {
    const allAttachments = attachments.length
      ? [...selectedAttachments, ...attachments]
      : selectedAttachments;
    const uniqueAttachments = Array.from(
      new Map(allAttachments.map(att => [att.name, att])).values()
    );

    if (!exceedsMaxSize(uniqueAttachments)) {
      setShowAlert({});
      setAttachments(uniqueAttachments);
    } else {
      setShowAlert({
        msg: fileSizeErrMsg,
        type: "error"
      });
    }
  };

  const handleRemoveAttachment = file => {
    const filteredAttachments = attachments.filter(o => o.name !== file.name);

    if (!exceedsMaxSize(filteredAttachments)) {
      setShowAlert({});
      setAttachments(filteredAttachments);
    } else {
      setShowAlert({
        msg: fileSizeErrMsg,
        type: "error"
      });
    }
  };

  const handleDismissAlert = () => {
    setShowAlert({});
  };

  if (suretyQuickUserAttachments.isSuccess) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Icon
            size="2x"
            icon="fasCheck"
            style={{ color: "green", marginRight: "2rem" }}
          />
          <span>Success! We got your documents!</span>
        </div>

        <Button onClick={() => setIsUploading(false)}>Exit</Button>
      </>
    );
  }

  return (
    <>
      {!!showAlert?.type && (
        <Alert type={showAlert.type} onDismiss={handleDismissAlert}>
          {showAlert.msg || <DefaultErrorResponse />}
        </Alert>
      )}

      <DragAndDropAttachments
        id="document-upload-file-input"
        allowMultiple
        onSelectFiles={handleSelectFiles}
        allowedFileTypes={ALLOWED_FILE_TYPES}
      />

      <div className="upload-filesize">
        <i>
          We only accept the following file types{" "}
          {ALLOWED_FILE_TYPES.join(", ")}
        </i>
        <br />
        <i>
          <b>Note* </b>
          <span>Total size of all attachments cannot exceed 10mb.</span>
        </i>
      </div>

      <hr />

      <p className="lead">Your Attachments</p>

      <Table
        data={attachments}
        rowKey="name"
        itemsPerPage={20}
        showPagination
        columns={[
          {
            key: "name",
            label: "Name",
            sortable: true
          },
          {
            label: "Size",
            className: "size",
            key: "fileSize",
            align: "center",
            element: row => (
              <span role="presentation">{convertBytesToMB(row.size)}</span>
            )
          },
          {
            label: "Remove",
            className: "remove",
            key: "removeFile",
            align: "center",
            element: row => {
              return (
                <div
                  className="remove-icon"
                  role="presentation"
                  onClick={() => handleRemoveAttachment(row)}
                >
                  <Icon icon="fasTimesCircle" />
                </div>
              );
            }
          }
        ]}
      />

      <div className="modal-form-buttons">
        <Button onClick={() => setIsUploading(false)}>Cancel</Button>
        <Button
          onClick={handleUploadDocsSubmit}
          spinner={suretyQuickUserAttachments.isLoading}
          spinnerPos="right"
          variant="primary"
          disabled={!!showAlert?.type || !attachments.length}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
