import * as types from "./actionTypes";
import * as reportService from "../services/agencyReportService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

const fetchCommercialDateListStarted = () => ({
  type: types.FETCH_COMMERCIAL_LIST_DATES_STARTED
});

const fetchCommercialDateListSucceeded = data => ({
  type: types.FETCH_COMMERCIAL_LIST_DATES_SUCCEEDED,
  payload: data
});

const fetchCommercialDateListFailed = error => ({
  type: types.FETCH_COMMERCIAL_LIST_DATES_FAILED,
  payload: error
});

const fetchPersonalDateListStarted = () => ({
  type: types.FETCH_PERSONAL_LIST_DATES_STARTED
});

const fetchPersonalDateListSucceeded = data => ({
  type: types.FETCH_PERSONAL_LIST_DATES_SUCCEEDED,
  payload: data
});

const fetchPersonalDateListFailed = error => ({
  type: types.FETCH_PERSONAL_LIST_DATES_FAILED,
  payload: error
});

export const fetchCommercialDateListDetails = agency_code => (
  dispatch
) => {
  dispatch(fetchCommercialDateListStarted());
  return reportService.getServiceCenterCommercialDateList(agency_code).then(
    res => dispatch(fetchCommercialDateListSucceeded(res.data)),
    error => {
      dispatch(fetchCommercialDateListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchPersonalDateListDetails = agency_code => (
  dispatch
) => {
  dispatch(fetchPersonalDateListStarted());
  reportService.getServiceCenterPersonalDateList(agency_code).then(
    res => dispatch(fetchPersonalDateListSucceeded(res.data)),
    error => {
      dispatch(fetchPersonalDateListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchServiceCenterSummary = (
  lineOfBusiness,
  agencyCode,
  reportDate
) => () => {
  let summaryPromise;
  if (lineOfBusiness === "commercial")
    summaryPromise = reportService.getCommercialServiceCenterSummary(
      agencyCode,
      reportDate
    );
  else if (lineOfBusiness === "personal")
    summaryPromise = reportService.getPersonalServiceCenterSummary(
      agencyCode,
      reportDate
    );
  else {
    throw new Error(`Invalid line of business: ${lineOfBusiness}`);
  }

  const documentService = new DocumentService();
  documentService.initializeRenderer("/loading-document");
  return summaryPromise
    .then(response => {
      documentService.saveOrOpenDocument(response.data, "service_center.pdf");
      return response;
    })
    .catch(err => {
      documentService.clearRenderer();
      throw err;
    });
};
