import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Translations } from "../../components/translations";
import ClaimsDetail from "./ClaimsDetail";
import { getPolicyLossRunsUrl } from "../../reports/services/claimsReportsService";
import { LoadingIndicator, Button } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const CLOSED_AFTER_DATE = moment().subtract(1, "year").format("YYYY-MM-DD");

const Claims = ({
  fetchClaims,
  claims,
  selectedAgencyCode,
  policyNumber,
  employeeUserName,
  claimsLoading,
  claimsError,
  startReportAClaimWithPolicy
}) => {
  const [previousPolicyNumber, setPreviousPolicyNumber] = useState(null);
  const [previousSelectedAgencyCode, setPreviousSelectedAgencyCode] =
    useState(null);

  useEffect(() => {
    if (
      previousPolicyNumber !== policyNumber ||
      previousSelectedAgencyCode !== selectedAgencyCode
    ) {
      setPreviousPolicyNumber(policyNumber);
      setPreviousSelectedAgencyCode(selectedAgencyCode);
      fetchClaims(
        policyNumber,
        selectedAgencyCode,
        CLOSED_AFTER_DATE,
        employeeUserName
      ).then(
        () => {},
        () => {
          const options = {
            position: "top-center",
            className: "error",
            autoClose: false
          };
          toast(<HelpDeskMessage display="anErrorOccurred" />, options);
        }
      );
    }
  }, [
    previousPolicyNumber,
    previousSelectedAgencyCode,
    policyNumber,
    selectedAgencyCode,
    employeeUserName,
    fetchClaims
  ]);

  const handleReportClaimButtonClick = () => {
    startReportAClaimWithPolicy(policyNumber);
  };

  const handleLossRunsButtonClick = () => {
    window.open(getPolicyLossRunsUrl(policyNumber));
  };

  return (
    <div className=" tabs-active-content claims-container">
      <div className="col-xs-12 col-md-7 claims-detail-blurb">
        {Translations.Claims.claims_detail.claims_detail_blurb}
      </div>
      <div className="row claims-buttons claims-button-row">
        <div className="col-md-5 col-md-offset-7 button-row">
          <div className="row view-loss-run-button">
            <div className="col-xs-12 col-sm-6 claim-detail-button">
              <Button onClick={handleLossRunsButtonClick} variant="primary">
                {Translations.Claims.claims_detail.view_loss_runs}
              </Button>
            </div>
            <div className="col-xs-12 col-sm-6 claim-detail-button">
              <Button onClick={handleReportClaimButtonClick} variant="primary">
                {Translations.Claims.claims_detail.report_a_claim}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {claimsLoading ? (
        <LoadingIndicator />
      ) : claimsError ? (
        <div>
          <HelpDeskMessage display="anErrorOccurred" />
        </div>
      ) : claims && claims.length ? (
        <ClaimsDetail claims={claims} policyNumber={policyNumber} />
      ) : (
        <b>{Translations.Claims.claims_detail.no_claims_error_message}</b>
      )}
    </div>
  );
};

Claims.propTypes = {
  fetchClaims: PropTypes.func,
  claims: PropTypes.array,
  selectedAgencyCode: PropTypes.string.isRequired,
  policyNumber: PropTypes.string,
  employeeUserName: PropTypes.string,
  claimsLoading: PropTypes.bool,
  claimsError: PropTypes.any,
  startReportAClaimWithPolicy: PropTypes.func
};

export default Claims;
