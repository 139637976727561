export const validateOwnersSsn = state => {
  // regex for ssn same sequence number (111111111,22222222,etc....) is not valid
  const ssnRegex = /^(\d)(?!\1+$)\d{8}$/;
  const ssnRegexValidation = new RegExp(ssnRegex);
  const isSSNValidAcrossAllOwners = state
    ?.map(g => ssnRegexValidation.test(g.ssn))
    .every(t => t === true);

  // to check ssn has empty string or "123456789"
  const isExistingSsnValid = state?.some(
    g => g.ssn === "" || g.ssn === "123456789"
  );
  const hasOwners = (state?.length || 0) > 0;

  return isSSNValidAcrossAllOwners && !isExistingSsnValid && hasOwners;
};

export const validateOwnershipPercentage = owners => {
  const totalOwnershipPercent = (owners || [])?.reduce((totalPercent, key) => {
    if (!!key.ownership_percent) {
      totalPercent += Number(key.ownership_percent);
    }
    return totalPercent;
  }, 0);

  return totalOwnershipPercent >= 90 && totalOwnershipPercent <= 100;
};

export const validateDuplicateOwnersSSN = state => {
  //checking SSN as duplicate values
  const duplicatingSsnOwnerData = new Set(state?.map(v => v.ssn));
  return duplicatingSsnOwnerData.size < state?.length;
};

export const validateHasWholeNumberOwnershipPercents = owners => {
  const isIntegerRgx = /^\d+$/;
  const foundNonIntegerOwnershipPercent = !!(owners || [])?.find(
    _ => !!_?.ownership_percent && !isIntegerRgx.test(_?.ownership_percent)
  );
  return !foundNonIntegerOwnershipPercent;
};
