import React, { useEffect } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { LoadingIndicator, Anchor } from "@ufginsurance/ui-kit";
import AgencyBillEpayForm from "./AgencyBillEpayForm";
import { Translations } from "../../../components/translations";
import { useSelector } from "react-redux";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const scheduled_saved_payment = "S";
const pending_payment = "P";
const confirmed_payment = "C";
const rejected_payment = "R";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
const billPayBaseLink = `${baseLink}/agentsonly/ufgagent/bill/abepay`;

const AgencyEnrollErrorMessage = ({ isSpecialtyPortalView }) => {
  const queryString = qs.stringify({
    BillType: "AB",
    RedirectUrl: window.location.href
  });
  return (
    <div>
      <div className="reports-above-form-message">
        {isSpecialtyPortalView
          ? Translations.agency_bill_epay.broker_error_message
          : Translations.agency_bill_epay.error_message}
      </div>
      <p>
        {Translations.formatString(
          Translations.agency_bill_epay.online_link_message,
          <Anchor
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseLink}/AgentsOnly/ufgAgent/AgencySweepEnrollment/default.aspx?${queryString}`}
          >
            {Translations.agency_bill_epay.enrollment_link_text}
          </Anchor>
        )}
      </p>
    </div>
  );
};

const AgencyBillEpay = ({
  billEnrollLoading,
  is109015Agent,
  getAgencyBillPayNowEnrollment,
  agencyCode,
  billEnrollError,
  billPayDetails,
  billPayLoading,
  isSpecialtyPortalView
}) => {
  const getGuidewireAgencyStatementOptionsLoading = useSelector(state => {
    return state.agencyStatementOptionsReducer
      .getGuidewireAgencyStatementOptionsLoading;
  });

  useEffect(() => {
    if (!is109015Agent) {
      getAgencyBillPayNowEnrollment(agencyCode);
    }
  }, [is109015Agent, getAgencyBillPayNowEnrollment, agencyCode]);

  const handleSubmit = values => {
    const { sort_by, action, statementLegacy, statementGuidewire } = values;
    const queryString = qs.stringify({
      sortby: sort_by,
      stmtdt: statementLegacy || statementGuidewire,
      goto: action,
      origin: statementLegacy ? "ufg_core" : "oasis"
    });
    window.location = `${baseLink}/agentsonly/ufgagent/Bill/ABEpay/ABWait.aspx?${queryString}`;
  };

  const hideForm = () => {
    const guidewire_payment =
      billPayDetails && billPayDetails.guidewire_payment;
    const legacy_payment = billPayDetails && billPayDetails.legacy_payment;
    const hasInProcessGuidewirePayment =
      (guidewire_payment &&
        (guidewire_payment.payment_status === confirmed_payment ||
          guidewire_payment.payment_status === scheduled_saved_payment ||
          guidewire_payment.payment_status === rejected_payment ||
          guidewire_payment.payment_status === pending_payment)) ||
      false;
    const hasInProcessLegacyPayment =
      (legacy_payment &&
        (legacy_payment.payment_status === confirmed_payment ||
          legacy_payment.payment_status === scheduled_saved_payment ||
          legacy_payment.payment_status === rejected_payment ||
          legacy_payment.payment_status === pending_payment)) ||
      false;
    return hasInProcessLegacyPayment || hasInProcessGuidewirePayment;
  };

  const getDisableDropdowns = () => {
    return Object.keys(billPayDetails || {}).filter(
      o => billPayDetails[o].payment_status === confirmed_payment
    );
  };

  const getErrorMessage = payment_type => {
    return billPayDetails && billPayDetails[payment_type] ? (
      [pending_payment, rejected_payment].includes(
        billPayDetails[payment_type].payment_status
      ) ? (
        <div>
          <strong>{Translations.agency_bill_epay.an_error_has_occured}</strong>
          <br />
          {Translations.agency_bill_epay.rejected_payment}
          <br />
          {Translations.formatString(
            Translations.agency_bill_epay.rejected_payment_contact,
            billPayDetails[payment_type]
              ? billPayDetails[payment_type].payment_id
              : ""
          )}
          <br />
          <HelpDeskMessage display="questionsOrAssistance" />
        </div>
      ) : billPayDetails[payment_type].payment_status === confirmed_payment ? (
        Translations.agency_bill_epay.confirmed_payment
      ) : (
        ""
      )
    ) : (
      ""
    );
  };

  const getLinks = ({ payment_id }) => {
    const queryString = qs.stringify({
      payment: payment_id
    });
    return (
      <div className="links-saved-work">
        <Anchor href={`${billPayBaseLink}/ABModify.aspx?${queryString}`}>
          {Translations.agency_bill_epay.view_saved_payment}
        </Anchor>
        &nbsp; or &nbsp;
        <Anchor
          href={`${billPayBaseLink}/ABDeleteSave.aspx?delete=${payment_id}`}
        >
          {Translations.agency_bill_epay.delete_saved_payment}
        </Anchor>
      </div>
    );
  };

  const getPaymentLinks = () => {
    return Object.keys(billPayDetails || {}).reduce((acc, key) => {
      acc[key] =
        billPayDetails[key].payment_status === scheduled_saved_payment
          ? getLinks(billPayDetails[key], key)
          : "";
      return acc;
    }, {});
  };

  const disabledDropdowns = getDisableDropdowns();
  const shouldHideForm = hideForm();
  const legacyPaymentError = getErrorMessage("legacy_payment");
  const guidewirePaymentError = getErrorMessage("guidewire_payment");
  const paymentLinks = getPaymentLinks();

  return billEnrollLoading ||
    billPayLoading ||
    getGuidewireAgencyStatementOptionsLoading ? (
    <LoadingIndicator />
  ) : billEnrollError || is109015Agent ? (
    <AgencyEnrollErrorMessage isSpecialtyPortalView={isSpecialtyPortalView} />
  ) : (
    <div className="agency-bill-pay">
      <p>{Translations.agency_bill_epay.text_message}</p>
      <AgencyBillEpayForm
        disableFields={disabledDropdowns}
        hideForm={shouldHideForm}
        legacyPaymentError={legacyPaymentError}
        guidewirePaymentError={guidewirePaymentError}
        paymentLinks={paymentLinks}
        handleSubmit={handleSubmit}
        agencyCode={agencyCode}
      />
    </div>
  );
};

AgencyBillEpay.propTypes = {
  billEnrollLoading: PropTypes.any,
  is109015Agent: PropTypes.bool,
  getAgencyBillPayNowEnrollment: PropTypes.func.isRequired,
  agencyCode: PropTypes.string.isRequired,
  billEnrollError: PropTypes.bool,
  billPayDetails: PropTypes.object,
  billPayLoading: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool
};

export default AgencyBillEpay;
