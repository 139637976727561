import React from "react";
import PropTypes from "prop-types";
import { Table, Currency } from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";

const PaymentDetailsTable = ({ lineItems }) => {
  const { VerifyPayment } = Translations;
  const createColumns = () => [
    {
      key: "accountPolicy",
      label: VerifyPayment.account_policy_column_label
    },
    {
      key: "insured",
      label: VerifyPayment.insured_column_label
    },
    {
      key: "payment_amount",
      label: VerifyPayment.amount_column_label,
      element: row => <Currency amount={row.payment_amount} />
    }
  ];
  const columns = createColumns();
  return (
    <div>
      <Table rowKey="accountPolicy" data={lineItems} columns={columns} />
    </div>
  );
};

export default PaymentDetailsTable;

PaymentDetailsTable.propTypes = {
  lineItems: PropTypes.array.isRequired
};
