import React from "react";
import PropTypes from "prop-types";
import { Table, Icon } from "@ufginsurance/ui-kit";

const AttachmentsTable = ({ attachments, removeAttachment }) => {
  const columns = [
    {
      key: "name",
      label: "Name",
      sortable: true
    },
    {
      label: "Remove",
      className: "remove",
      key: "dummyDataField",
      align: "center",
      element: row => {
        return (
          <div
            className="remove-icon"
            role="presentation"
            onClick={removeAttachment(row)}
          >
            <Icon icon="fasTimesCircle" />
          </div>
        );
      }
    }
  ];

  return (
    <div className="umail-attachments-table">
      <Table
        rowKey="name"
        data={attachments}
        columns={columns}
        itemsPerPage={20}
        showPagination
      />
    </div>
  );
};

export default AttachmentsTable;

AttachmentsTable.defaultProps = {
  attachments: []
};

AttachmentsTable.propTypes = {
  attachments: PropTypes.array,
  removeAttachment: PropTypes.func
};
