import * as types from "./actionTypes";
import * as reportService from "../services/underwritingReportsService";
import { logger } from "../../loggers";

const fetchUnderwritingEmailListStarted = () => ({
  type: types.FETCH_UNDERWRITING_EMAIL_LIST_STARTED
});

const fetchUnderwritingEmailListSucceeded = data => ({
  type: types.FETCH_UNDERWRITING_EMAIL_LIST_SUCCEEDED,
  payload: data
});

const fetchUnderwritingEmailListFailed = error => ({
  type: types.FETCH_UNDERWRITING_EMAIL_LIST_FAILED,
  payload: error
});

export const fetchUnderwritingEmailListDetails = agency_code => {
  return dispatch => {
    dispatch(fetchUnderwritingEmailListStarted());

    return reportService.getUnderwritingEmailList(agency_code).then(
      res => dispatch(fetchUnderwritingEmailListSucceeded(res.data)),
      error => {
        dispatch(fetchUnderwritingEmailListFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};
