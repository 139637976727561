import * as types from "./actionTypes";
import * as reportService from "../services/underwritingReportsService";
import { DocumentService } from "../../services/documentService";
import { logger } from "../../loggers";

const fetchAppQuoteStatusListStarted = () => ({
  type: types.FETCH_APP_QUOTE_STATUS_LIST_STARTED
});

const fetchAppQuoteStatusListSucceeded = data => ({
  type: types.FETCH_APP_QUOTE_STATUS_LIST_SUCCEEDED,
  payload: data
});

const fetchAppQuoteStatusListFailed = error => ({
  type: types.FETCH_APP_QUOTE_STATUS_LIST_FAILED,
  payload: error
});

export const fetchAppQuoteStatusList = (
  agencyCode,
  numberOfDays,
  status,
  type
) => {
  return dispatch => {
    dispatch(fetchAppQuoteStatusListStarted());
    return reportService
      .getAppQuoteStatusList(agencyCode, numberOfDays, status, type)
      .then(
        res => dispatch(fetchAppQuoteStatusListSucceeded(res.data)),
        error => {
          dispatch(fetchAppQuoteStatusListFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

export const fetchAppQuoteStatusReport = request => () => {
  const documentService = new DocumentService();
  return reportService.getAppQuoteStatusReport(request).then(
    response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        "app-quote-status-report.pdf"
      );
    },
    error => {
      documentService.clearRenderer();
      logger.error({
        description: error.toString(),
        fatal: false
      });
    }
  );
};
