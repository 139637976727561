import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { logger } from "../../../loggers";
import { getAccountBondList } from "../../services/index";

export default function useGetAccountBondList(agencyCode, account) {
  const [data, setData] = useState();
  const [isAccountBondListLoading, setIsAccountBondListLoading] = useState(false);
  const cache = useRef({});
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (
      !agencyCode || !account?.account_number || isAccountBondListLoading || error.current
    ) return;

    if (cache.current[account?.account_number]) {
      setData(cache.current[account?.account_number]);
      return;
    }

    setIsAccountBondListLoading(true);

    getAccountBondList(agencyCode, account?.account_number)
      .then(res => {
        if (res.status === 200 && res.data.length) {
          const d = (res?.data ?? []).map(_ => ({
            ..._,
            id: v4()
          }));
          cache.current[account?.account_number] = d;
          setData(d);
        } else {
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsAccountBondListLoading(false));
  }, [agencyCode, account, isAccountBondListLoading]);

  return [data, isAccountBondListLoading, error.current];
}
