import React, { useState, useContext } from "react";
import { FormatDate, Button } from "@ufginsurance/ui-kit";
import EffectiveDateModal from "../shared/effectiveDate/EffectiveDateModal";
import OnlineQuotingContext from "../OnlineQuotingContext";

const EffectiveDate = () => {
  const { quoteData, step, supportingData } = useContext(OnlineQuotingContext);

  const [showModal, setShowModal] = useState(false);

  const effectiveDate =
    quoteData || supportingData
      ? step === 2
        ? supportingData?.effectiveDate
        : quoteData?.baseData?.periodStartDate
      : "";

  return (
    <div className="oq__effective-date__wrapper">
      {
        <div className="oq__sidebar__summary__item">
          <b>Effective Date:</b>
          {effectiveDate && (
            <FormatDate
              className="oq__sidebar__summary__effective-date"
              date={effectiveDate}
              format="M/D/YYYY"
            />
          )}
          <Button isLink inline onClick={() => setShowModal(true)}>
            Edit Effective Date
          </Button>
        </div>
      }
      {showModal && (
        <EffectiveDateModal
          initialValues={{
            effectiveDate
          }}
          onHide={() => setShowModal(false)}
          show={showModal}
          quoteId={quoteData?.quoteID}
        />
      )}
    </div>
  );
};

export default EffectiveDate;
