import { formatDate } from "@ufginsurance/ui-kit";
import { getImbrExpirationDate } from "../../builders-risk/lib/utils";

/**
 * Get an IMBR/ProQuote account's initial values in the Product Selection Modal.
 * @param {Object} account The account returned from onlineQuotingService.js/getAccountSearch.
 * @returns The account's initial values for the new Product Selection Modal.
 */
export const existingImbrCustomerInitialValues = ({
  accountHolder,
  account_name = ""
}) => {
  const { primaryAddress } = accountHolder;
  const today = formatDate(new Date(), "YYYY-MM-DD");

  return {
    account_name,
    account_type: "api",
    address_line: primaryAddress?.addressLine1 || "",
    city: primaryAddress?.city || "",
    contractor_name: "",
    effective_date: today,
    expiration_date: getImbrExpirationDate(today),
    state: primaryAddress?.state || "",
    zip: primaryAddress?.postalCode || ""
  };
};

export const initialImbrCustomerValues = {
  account_name: "",
  account_type: "business",
  address_line: "",
  city: "",
  contractor_name: "",
  effective_date: "",
  expiration_date: "",
  search: "",
  state: "",
  zip: ""
};

export const emptyImbrCustomerErrors = {
  account_name: [],
  address_line: [],
  city: [],
  contractor_name: [],
  effective_date: [],
  expiration_date: [],
  search: [],
  state: [],
  zip: []
};
