import { connect } from "react-redux";
import ClaimInformation from "../components/ClaimInformation";
import { fetchClaim, fetchClaims } from "../actions/claimsActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getInsuredName } from "../../selectors/claimsSelectors";
import { startReportAClaimWithPolicy } from "../../top-action-cards/report-claim/actions/reportClaimActions";

const mapStateToProps = state => ({
  claims: state.claims.claims,
  claimsError: state.claims.claimsError,
  claimsLoading: state.claims.claimsLoading,
  employeeUserName: sessionSelector.getEmployeeUserName(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  insuredName: getInsuredName(state)
});

const mapDispatchToProps = {
  fetchClaim,
  fetchClaims,
  startReportAClaimWithPolicy
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimInformation);
