import * as types from "../constants/actionTypes";
import initialState from "../reducers/initialState";

const newsReducer = (state = initialState.news, action) => {
  switch (action.type) {
    case types.FETCH_NEWS_CURRENT_STARTED:
      return {
        ...state,
        isLoading: true,
        newsCurrentError: false
      };
    case types.FETCH_NEWS_CURRENT_SUCCEEDED:
      return {
        ...state,
        newsCurrent: action.payload,
        isLoading: false,
        newsCurrentError: false
      };
    case types.FETCH_NEWS_CURRENT_FAILED:
      return {
        ...state,
        isLoading: false,
        newsCurrentError: action.payload,
        newsCurrent: []
      };
    case types.FETCH_NEWS_ARCHIVE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: false
      };
    case types.FETCH_NEWS_ARCHIVE_SUCCEEDED:
      return {
        ...state,
        newsArchive: action.payload,
        isLoading: false,
        newsArchiveError: false
      };
    case types.FETCH_NEWS_ARCHIVE_FAILED:
      return {
        ...state,
        isLoading: false,
        newsArchiveError: action.payload,
        newsArchive: []
      };
    default:
      return state;
  }
};

export default newsReducer;
