import React, { useState } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavCardContainer, NavCard } from "@ufginsurance/ui-kit";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { Translations } from "../translations";
import * as routes from "../../constants/routes";
import { toggleProductSelectionModal } from "../../commercial/shared/ProductSelection/ProductSelectionModal";
import { clearLocalQuoteManually } from "../../online-quoting/shared/util";
import { useFlags } from "launchdarkly-react-client-sdk";
import PreQuoteModal from "./../../online-quoting/step1/PreQuoteModal";

const Navigation = ({
  activateUmailCard,
  activateHelpDeskCard,
  activateMakeAPayment,
  history,
  location,
  startReportAClaim,
  activateClaimsInquiryCard,
  isSpecialtyPortalView,
  acceptsCommercialLine,
  toggleProductSelectionModal
}) => {
  const { buildersRiskEnabled } = useFlags();
  const [showPreQuoteModal, setShowPreQuoteModal] = useState(false);

  const isSurety = location.pathname.includes("surety");

  const filterAlwaysVisible = linkArray =>
    linkArray.filter(l => l.alwaysVisible);

  const filterSpecialtyItems = linkArray =>
    linkArray.filter(
      l =>
        l.text !== Translations.side_bar.make_a_payment &&
        l.text !== Translations.side_bar.new_quote &&
        l.text !== Translations.side_bar.recent_quote_submissions
    );

  const getUfgMailIndex = linkArray =>
    (linkArray || []).findIndex(i => i.text === Translations.side_bar.umail);

  const getLinks = () => {
    let linkArray = [
      {
        text: Translations.side_bar.recent_quote_submissions,
        icon: "farClock",
        alwaysVisible: true,
        onClickHandler: () => {
          history.push(routes.COMMERCIAL_LINES);
        },
        className: "recent-quote-submissions"
      },
      {
        text: Translations.side_bar.umail,
        icon: "farEnvelope",
        alwaysVisible: true,
        onClickHandler: () => {
          activateUmailCard();
        }
      },
      {
        text: "Policy Loss Runs",
        icon: "farFileAlt",
        alwaysVisible: true,
        onClickHandler: () => {
          history.push(
            `${routes.REPORTS}/${routes.REPORTS_CLAIMS_ACTIVE_TAB}/${routes.REPORTS_POLICY_LOSS}`
          );
        }
      },
      {
        text: Translations.side_bar.report_a_claim,
        icon: "farPaperPlane",
        alwaysVisible: true,
        onClickHandler: () => {
          startReportAClaim();
        }
      },
      {
        text: Translations.side_bar.help_desk,
        icon: "fasHeadset",
        alwaysVisible: true,
        onClickHandler: () => {
          activateHelpDeskCard();
        }
      },
      {
        text: Translations.side_bar.your_ufg_team,
        icon: "fasUsers",
        alwaysVisible: true,
        onClickHandler: () => {
          history.push(routes.VIEW_UNDERWRITING_TEAM_UFG_ONLINE);
        }
      },
      {
        text: Translations.side_bar.make_a_payment,
        icon: "farCreditCard",
        alwaysVisible: false,
        onClickHandler: () => {
          activateMakeAPayment();
        }
      },
      {
        text: Translations.side_bar.claims_inquiry,
        icon: "farFolderOpen",
        className: "claims-inquiry-action-card",
        alwaysVisible: true,
        onClickHandler: () => {
          activateClaimsInquiryCard();
        }
      }
    ];

    if (isSurety) {
      linkArray = [
        {
          text: Translations.side_bar.umail,
          icon: "farEnvelope",
          alwaysVisible: true,
          onClickHandler: () => {
            activateUmailCard();
          }
        },
        {
          text: "Policy Loss Runs",
          icon: "farFileAlt",
          alwaysVisible: true,
          onClickHandler: () => {
            history.push(
              `${routes.REPORTS}/${routes.REPORTS_CLAIMS_ACTIVE_TAB}/${routes.REPORTS_POLICY_LOSS}`
            );
          }
        },
        {
          text: Translations.side_bar.help_desk,
          icon: "fasHeadset",
          alwaysVisible: true,
          onClickHandler: () => {
            activateHelpDeskCard();
          }
        },
        {
          text: Translations.side_bar.your_ufg_team,
          icon: "fasUsers",
          alwaysVisible: true,
          onClickHandler: () => {
            history.push(routes.YOUR_UFG_TEAM_ROOT);
          }
        },
        {
          text: Translations.side_bar.make_a_payment,
          icon: "farCreditCard",
          alwaysVisible: false,
          onClickHandler: () => {
            activateMakeAPayment();
          }
        }
      ];
    }

    if (acceptsCommercialLine) {
      // for commercial-line-enabled agentst...
      // always insert the New Quote after ufgMail
      linkArray.splice(getUfgMailIndex(linkArray) + 1, 0, {
        text: "New Quote",
        icon: "farPlusCircle",
        alwaysVisible: false,
        onClickHandler: setShowPreQuoteModal
      });
    }

    return location.pathname === "/" && isSpecialtyPortalView
      ? filterSpecialtyItems(filterAlwaysVisible(linkArray))
      : location.pathname === "/"
      ? filterAlwaysVisible(linkArray)
      : isSpecialtyPortalView
      ? filterSpecialtyItems(linkArray)
      : linkArray;
  };

  const links = getLinks();

  return (
    <>
      {showPreQuoteModal && (
        <PreQuoteModal
          history={history}
          onContinue={() => {
            if (buildersRiskEnabled) {
              toggleProductSelectionModal();
            } else {
              clearLocalQuoteManually();
              history.push(routes.ONLINE_QUOTING_STEP1);
            }
          }}
          showPreQuoteModal={showPreQuoteModal}
          setShowPreQuoteModal={setShowPreQuoteModal}
        />
      )}
      <NavCardContainer className="side-bar__nav-cards">
        {links.map(link => (
          <NavCard
            key={link.text}
            onClick={link.onClickHandler}
            icon={link.icon}
            title={link.text}
            className={link.className}
          />
        ))}
      </NavCardContainer>
    </>
  );
};

Navigation.propTypes = {
  activateUmailCard: PropTypes.func.isRequired,
  activateHelpDeskCard: PropTypes.func.isRequired,
  activateMakeAPayment: PropTypes.func.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  startReportAClaim: PropTypes.func.isRequired,
  activateClaimsInquiryCard: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  acceptsCommercialLine: PropTypes.bool,
  toggleProductSelectionModal: PropTypes.func
};

// export default withRouter(Navigation);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  acceptsCommercialLine:
    sessionSelector.agencyAcceptsCommercialLinesNewBusiness(state)
});

export default connect(mapStateToProps, { toggleProductSelectionModal })(
  withRouter(Navigation)
);
