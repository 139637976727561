import _get from "lodash/get";
import { quotePath } from "../../shared/constants";
import { guid, addUpdateArrayItem } from "../../shared/util";

export const getAllVehicles = ({ quoteData }) => {
  return _get(quoteData, quotePath.ca7Vehicle, []).map(b => ({
    ...b,
    id: b.fixedId || guid()
  }));
};

export const addUpdateVehicles = ({ quoteData, vehicle }) => {
  return addUpdateArrayItem(getAllVehicles({ quoteData }), vehicle);
};

export const getVehicle = ({ quoteData, fixedId }) => {
  return _get(quoteData, quotePath.ca7Vehicle, []).find(
    v => v.fixedId === fixedId
  );
};

export const IsAtleastOneVINMissing = ({ quoteData, supportingData }) => {
  return getAllVehicles({ quoteData })
    .filter(x => !(supportingData?.trailerIDs || []).includes(x.fixedId))
    .some(v => !v?.vin);
};
