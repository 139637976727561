import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const propTypes = {
  grade: PropTypes.string
};

const colorStar = (starNumber, grade) => {
  const classValue = starNumber <= grade ? "gold-star" : "grey-star";
  return classValue;
};

const StarRatings = ({ grade }) => {
  return (
    <div>
      <div className="star-rating">
        <FontAwesomeIcon className={colorStar(1, grade)} icon={faStar} />
        <FontAwesomeIcon className={colorStar(2, grade)} icon={faStar} />
        <FontAwesomeIcon className={colorStar(3, grade)} icon={faStar} />
        <FontAwesomeIcon className={colorStar(4, grade)} icon={faStar} />
        <FontAwesomeIcon className={colorStar(5, grade)} icon={faStar} />
      </div>
    </div>
  );
};

StarRatings.propTypes = propTypes;
export default StarRatings;
