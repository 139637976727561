import { useCallback, useMemo } from "react";
import { convertStatesAndAbbreviations } from "../../../src/components/Factory";
import { useSelector } from "react-redux";

export const useGetDriveLicenseStateDropdownList = () => {
  const driverLicenseValidationList = useSelector(
    state => state.policyChange?.driverLicenseValidationList?.data
  );

  const dropdownList = useMemo(() => {
    if (!driverLicenseValidationList) return [];

    return driverLicenseValidationList.map(jurisdiction => {
      return {
        label: convertStatesAndAbbreviations(
          jurisdiction.stateAbbreviation,
          "name"
        ),
        value: jurisdiction.stateAbbreviation
      };
    });
  }, [driverLicenseValidationList]);

  return dropdownList;
};

export const useValidateStateDriverLicenseNumberRules = () => {
  const driverLicenseValidationList = useSelector(
    state => state.policyChange?.driverLicenseValidationList?.data
  );

  const driverLicenseValidationRegex = useMemo(() => {
    let data = {};

    if (driverLicenseValidationList) {
      driverLicenseValidationList.forEach(jurisdiction => {
        const { stateAbbreviation, regexFormat, regexDescription } =
          jurisdiction;

        data = {
          ...data,
          [stateAbbreviation]: {
            regex: new RegExp(regexFormat),
            description: regexDescription
          }
        };
      });
    }

    return data;
  }, [driverLicenseValidationList]);

  const validateStateDriverLicenseNumberRules = useCallback(
    (licenseNumber, licenseState) => {
      if (!licenseState) {
        return {
          valid: false,
          errorString: "License State is empty. "
        };
      }
      const licenseRule = driverLicenseValidationRegex[licenseState] || {};

      let valid = false;
      let errorString = "";

      if (licenseRule.regex.test(licenseNumber) === true) {
        valid = true;
      } else {
        errorString =
          errorString.length === 0
            ? errorString.concat(licenseRule.description)
            : errorString.concat(" or ").concat(licenseRule.description);
      }

      return {
        valid,
        errorString
      };
    },
    [driverLicenseValidationRegex]
  );

  return validateStateDriverLicenseNumberRules;
};
