import React from "react";
import { FormGroup, DatePicker } from "@ufginsurance/ui-kit";
import { getImbrExpirationDate } from "../lib/utils";

export default function EffectiveExpirationDate({
  form: {
    errors,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    updateForm,
    values
  }
}) {
  const handleEffectiveDateOnChange = ({ value }) => {
    updateForm({
      values: {
        ...values,
        effective_date: value,
        expiration_date: getImbrExpirationDate(value)
      },
      errors: { ...errors, effective_date: [] }
    });
  };

  const getMaxEffectiveDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 90);
    return maxDate;
  };

  return (
    <FormGroup>
      <DatePicker
        id="effective_date"
        name="effective_date"
        label="Effective Date"
        onChange={handleEffectiveDateOnChange}
        onBlur={handleOnBlur}
        onValidate={handleOnValidate}
        value={values.effective_date}
        format="YYYY-MM-DD"
        required
        minDate={new Date()}
        maxDate={getMaxEffectiveDate()}
      />
      <DatePicker
        id="expiration_date"
        name="expiration_date"
        label="Expiration Date"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onValidate={handleOnValidate}
        value={values.expiration_date}
        format="YYYY-MM-DD"
        required
        disabled
      />
    </FormGroup>
  );
}
