import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  FlexRow,
  LoadingIndicator,
  Icon,
  Modal,
  formatDate
} from "@ufginsurance/ui-kit";
import * as qqService from "../../quickQuotingServices";
import CheckboxButton from "./CheckboxButton";
import { normalizeUnverifiedAddress, normalizeVerifiedAddress } from "../util";
import "./VerifyAddresses.scss";

const VerifyAddresses = ({
  cacheId,
  suretyQuote,
  updateSuretyQuoteData,
  setUpdatedOwnerData,
  ownerData,
  setShowAddModal
}) => {
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [processedAddresses, setProcessedAddresses] = useState();
  const [allAddressesExact, setAllAddressesExact] = useState(null);

  // on component load, check the addresses using the address service and set up the data for the UI

  useEffect(() => {
    setShowAddModal(false);
    // run the address validation for all addresses in the quote data
    //const types = Object.keys(addresses);
    const addressesToCheck = [];

    const processed = {};
    const defaultValues = {};

    const selectedDefault = {};

    let exactMatch = true;
    const { addresses } = suretyQuote.verifyModalData;

    addresses.forEach(address => {
      processed[address.type] = {
        id: address.id,
        original: {
          ...normalizeUnverifiedAddress(address)
        }
      };

      addressesToCheck.push(
        qqService
          .verifyAddress(
            address.address_line.replace(" ", "+"),
            address.city.replace(" ", "+"),
            address.state,
            address.zip
          )
          .then(response => {
            const confidence = !!response?.data?.confidence
              ? Number(response.data.confidence)
              : 0;

            response.data.verify_date = formatDate(new Date(), "YYYY-MM-DD");

            //save verified data to the address object
            if (confidence <= 50) {
              processed[address.type].isNotVerified = true;
              selectedDefault[address.type] = false;
            } else {
              const verifiedAddress = normalizeVerifiedAddress(
                response.data,
                address.pobox
              );

              processed[address.type].verified = verifiedAddress;
              selectedDefault[address.type] = true;
            }

            //set if the address is exact match - used to see if all addresses are exact match
            if (confidence < 100) exactMatch = false;

            defaultValues[address.type] = `${
              processed[address.type].verified.street_name
            }, ${processed[address.type].verified.city}, ${
              processed[address.type].verified.state
            }, ${processed[address.type].verified.zip}`;

            return { type: address.type, result: response.data };
          })
          .catch(error => {
            console.error(error);
            processed[address.type].isNotVerified = true;
            selectedDefault[address.type] = false;
          })
      );
    });

    Promise.all(addressesToCheck).then(() => {
      setProcessedAddresses(processed);
      setSelectedAddresses(selectedDefault);
      setAllAddressesExact(exactMatch);
    });
  }, [setShowAddModal, suretyQuote]);

  const handleContinue = useCallback(() => {
    let tmpOwner = ownerData;

    // this function does not work for setting multiple addresses.
    Object.keys(processedAddresses).forEach(type => {
      tmpOwner = tmpOwner.map(owner => {
        if (owner.id === processedAddresses[type].id) {
          if (
            selectedAddresses[type] === true &&
            processedAddresses[type].verified
          ) {
            owner = {
              ...owner,
              ...normalizeVerifiedAddress(processedAddresses[type].verified)
            };
          } else {
            owner = {
              ...owner,
              ...normalizeUnverifiedAddress(processedAddresses[type].original)
            };
          }
        }

        return owner;
      });
    });

    const addSpouseUpdateQuoteValues = {
      ...suretyQuote,
      ownerData: tmpOwner,
      verifyModalData: {
        show: false
      }
    };

    updateSuretyQuoteData(cacheId, addSpouseUpdateQuoteValues);
    setUpdatedOwnerData(tmpOwner);
  }, [
    processedAddresses,
    selectedAddresses,
    ownerData,
    suretyQuote,
    cacheId,
    updateSuretyQuoteData,
    setUpdatedOwnerData
  ]);
  // Automatically continue if addresses are all valid...
  // check the allAddressesExact state in a useEffect because the handleContinue funciton is reading
  // data from the processedAddresses state which is null until after the Promise in the loading useEffect
  useEffect(() => {
    //if all address is exact match, move to next step- Bop prefill
    if (allAddressesExact) {
      setTimeout(() => {
        handleContinue();
      }, 1500);
    }
    // only run when allAddressesExact is updated... including handleContinue causes this to retrigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAddressesExact]);

  const updateSelected = (type, state) => {
    setSelectedAddresses({
      ...selectedAddresses,
      [type]: state
    });
  };

  const handleCancelButton = () => {
    const processedIds = Object.values(processedAddresses).map(o => o.id);
    const tmpOwner = ownerData.filter(item => !processedIds.includes(item.id));
    //removes values from quote data
    Object.keys(processedAddresses).forEach(type => {
      for (let k = ownerData.length - 1; k >= 0; k--) {
        if (
          parseInt(ownerData[k].reference_number) ===
          parseInt(processedAddresses[type].original.reference_number)
        ) {
          tmpOwner.splice(k, 1);
        }
      }
    });
    const addSpouseUpdateQuoteValues = {
      ...suretyQuote,
      ...{ ownerData: tmpOwner },
      verifyModalData: {
        show: false
      }
    };
    updateSuretyQuoteData(cacheId, addSpouseUpdateQuoteValues);
    setUpdatedOwnerData(tmpOwner);
  };

  return (
    <Modal
      title="Verify Addresses"
      className="qq__verify-address__modal"
      size="lg"
      closeIcon={false}
      body={
        <div className="qq__verify-address__container">
          <div className="qq__verify-address">
            {(allAddressesExact === null || allAddressesExact === true) && (
              <LoadingIndicator message="Verifying addresses..." />
            )}
            {processedAddresses && !allAddressesExact && (
              <>
                <p className="qq__verify-address__title">
                  Please verify your addresses.
                </p>
                {Object.keys(processedAddresses).map(type => {
                  return (
                    <React.Fragment key={type}>
                      {type !== "owner" && (
                        <FlexRow className="qq__verify-address_row">
                          <div className="qq__verify-address__row-header">
                            Spouse Or Domestic Partner Address
                          </div>
                        </FlexRow>
                      )}
                      <FlexRow className="qq__verify-address_row">
                        <div className="qq__verify-address__button-header">
                          Original Address
                        </div>

                        <div className="qq__verify-address__button-header oq__verify-address__button-header__suggested">
                          Suggested Address
                        </div>
                      </FlexRow>
                      <div className="oq__verify-address__row-header">
                        &nbsp;
                      </div>
                      <FlexRow className="qq__verify-address__row" key={type}>
                        <CheckboxButton
                          selected={selectedAddresses?.[type] === false}
                          onClick={() => {
                            updateSelected(type, false);
                          }}
                        >
                          <div>
                            {processedAddresses?.[type]?.original.address_line}
                          </div>
                          <div>
                            {processedAddresses?.[type]?.original.city},{" "}
                            {processedAddresses?.[type]?.original.state}{" "}
                            {processedAddresses?.[type]?.original.zip}
                          </div>
                        </CheckboxButton>
                        <div className="qq__verify-address__carret">
                          <Icon icon="fasAngleRight" size="2x" />
                        </div>

                        <CheckboxButton
                          selected={selectedAddresses?.[type] === true}
                          invalid={processedAddresses[type]?.isNotVerified}
                          disabled={processedAddresses[type]?.isNotVerified}
                          onClick={() => {
                            if (!processedAddresses[type]?.isNotVerified)
                              updateSelected(type, true);
                          }}
                        >
                          {processedAddresses[type]?.verified &&
                            !processedAddresses[type]?.isNotVerified && (
                              <>
                                <div>
                                  {
                                    processedAddresses?.[type]?.verified
                                      .address_line
                                  }
                                </div>
                                <div>
                                  {processedAddresses?.[type]?.verified.city},{" "}
                                  {processedAddresses?.[type]?.verified.state}{" "}
                                  {processedAddresses?.[type]?.verified.zip}
                                </div>
                              </>
                            )}

                          {processedAddresses[type]?.isNotVerified && (
                            <em>Unable to verify address</em>
                          )}
                        </CheckboxButton>
                      </FlexRow>
                    </React.Fragment>
                  );
                })}

                <FlexRow align="right">
                  <Button onClick={handleCancelButton}>Cancel</Button>

                  <Button variant="primary" onClick={handleContinue}>
                    Continue
                  </Button>
                </FlexRow>
              </>
            )}
          </div>
        </div>
      }
      show
      altCloseMethod={false}
      onHide={handleCancelButton}
    />
  );
};

export default VerifyAddresses;
