import { formatDate } from "@ufginsurance/ui-kit";
import { trimAllObjectValues } from "./util";
import { productKeys } from "./constants";

// helper function to create object for managing the extra data from the address service
export const mapExtraDataFromAddressService = a => {
  if (!a) return {};

  const floodDistance = a?.flood_risk.distance_100_year_flood || null;
  const fireBehavior = a?.wild_fire.predicted_fire_behavior || null;
  const valueCalculation = a?.property_attributes.value_calculation || null;
  const landValue = a?.property_attributes.land_value || null;
  const improvementValue = a?.property_attributes.improvement_value || null;

  return {
    buildingQuality_UFG: a?.property_attributes?.quality || null,
    improveValueCalculation_UFG:
      improvementValue && !isNaN(improvementValue)
        ? parseFloat(improvementValue)
        : null, // int
    landValueCalculation_UFG:
      landValue && !isNaN(landValue) ? parseFloat(landValue) : null, // int
    totalValueCalculation_UFG:
      valueCalculation && !isNaN(valueCalculation)
        ? parseFloat(valueCalculation)
        : null, // int
    hailRiskLevel_UFG: a?.weather_risk?.hail_risk_level || null,
    windRiskLevel_UFG: a?.weather_risk?.wind_risk_level || null,
    dist100YearFlood_UFG:
      floodDistance && !isNaN(floodDistance) ? parseFloat(floodDistance) : null, // int
    riskDescription_UFG: a.wild_fire.risk_description || null,
    predFireBehavior_UFG:
      fireBehavior && !isNaN(fireBehavior) ? parseFloat(fireBehavior) : null, // int
    urbanicity_UFG: a?.ext_attributes?.urbanicity || null
  };
};

// filtering out umbrella because it's not being treated like a typical product
const LOBs = Object.keys(productKeys).filter(l => l !== "cuCommercialUmbrella");

export const makeSupportingDataObj = (
  { values, extraAddressValues },
  classCodes
) => {
  // console.log("formValues to create", formValues);

  const addressTypes = ["mailing_ufg", "other"].map(type => {
    const addressValidationValues = extraAddressValues?.[type] || {};
    return {
      state: values[type + "_state"], // [AK,AL,AR,AZ…,WY],
      addressLine1: values[type + "_address1"],
      addressType: type, // [physical_ufg, billing, mailing_ufg, other]
      postalCode: values[type + "_zip"],
      city: values[type + "_city"],
      county: values[type + "_county"],
      countyCode: values[type + "_county_code"],
      displayName: "Physical Address",
      isAutoFilled: values[type + "_is_autofilled"], // true if address scrubbed
      publicID: values[type + "_publicID"] || "",
      verified: values[type + "_verified_status"] || "unverified",
      ...addressValidationValues // the extra values from the address validation
    };
  });

  const dataToSave = {
    currentPage: values?.currentPage || String(3), // should be a step # 3, 4, 5, or 6
    // quoteNumber: "string", // only after step 2
    policyLines: LOBs.filter(p => values[p] === true), // code values of the selected LOBs
    effectiveDate: formatDate(values.effective_date, "OBJECT"),
    effectiveDateOriginal: formatDate(values.effective_date, "OBJECT"),
    businessStartYear: values.business_start_year,
    dbaNames: !!values.DBA_name ? [values.DBA_name] : [],
    garageStates: !!values.garaged_states ? values.garaged_states : [], // [AK,AL,AR,AZ…,WY]
    classCode: classCodes.find(c => c.primary === true) || {},
    additionalClassCodes: classCodes?.length
      ? classCodes.filter(c => !c.primary)
      : [],
    customerInformation: {
      // only step 1 or 2
      // publicID: "string", // Policy Center - only include for an existing account
      accountNumber: isNaN(values.account_number)
        ? ""
        : values.account_number || "", // generated by PC - only include for an existing account
      accountOrgType_UFG: values.account_org_type_UFG,
      producerCodes: [
        // the producer id selected from customer info page
        {
          code: values?.producer_code ? String(values.producer_code) : ""
        }
      ],
      isInForce: values.is_inforce,
      accountHolder: {
        publicID: values.account_publicID || "", // string: Policy center ID
        contactName: values?.account_name, //  string:for Company type customers only
        firstName: values?.first_name, //  string:for Person type customers only
        lastName: values?.last_name, // string: for Person type customers only
        subType: values?.account_name ? "Company" : "Person",
        emailAddress1: values.policyholder_email,
        primaryAddress: {
          // AddressDTO
          state: values.physical_state, // [AK,AL,AR,AZ…,WY],
          addressLine1: values.physical_address1,
          addressType: "physical_ufg", // [physical_ufg, billing, mailing_ufg, other]
          postalCode: values.physical_zip,
          city: values.physical_city,
          county: values.physical_county,
          countyCode: values.physical_county_code,
          displayName: "Physical Address",
          isAutoFilled: values.physical_is_autofilled, // true if address scrubbed
          publicID: values.physical_publicID || "", // Policy center ID - only include for an existing account
          verified: values.physical_verified_status || "unverified",
          ...extraAddressValues?.physical // the extra values from the address validation
        },
        additionalAddresses: addressTypes.filter(a => !!a.addressLine1) // array of address DTOs
      }
    },
    // OOQ-11247 - umbrella is only compatible with BOP...
    // ... and we're caching this addWhenRating to be used when we go to step 6
    packages: {
      umbrella: {
        addWhenRating: values?.bp7BusinessOwners && values?.cuCommercialUmbrella
      }
    }
  };

  return trimAllObjectValues(dataToSave);
};

export const supportingDataToFormData = ({ supportingData }) => {
  const data = supportingData;

  const newValues = {
    business_start_year: data?.businessStartYear || "",
    effective_date: data?.effectiveDate || "",
    account_org_type_UFG: data?.customerInformation?.accountOrgType_UFG || "",
    account_number: data?.customerInformation?.accountNumber || "",
    account_publicID: data?.customerInformation?.accountHolder?.publicID || "",
    account_name: data?.customerInformation?.accountHolder?.contactName || "",
    last_name: data?.customerInformation?.accountHolder?.lastName || "",
    first_name: data?.customerInformation?.accountHolder?.firstName || "",
    policyholder_email:
      data?.customerInformation?.accountHolder?.emailAddress1 || "",
    has_DBA: (!!data?.dbaNames && data?.dbaNames.length > 0) || false,
    DBA_name: data?.dbaNames?.[0] || "",
    garaged_autos:
      (!!data?.garageStates && data?.garageStates.length > 0) || false,
    garaged_states: data?.garageStates || [],
    producer_code: data?.customerInformation?.producerCodes?.[0]?.code || "",
    physical_address1:
      data?.customerInformation?.accountHolder?.primaryAddress?.addressLine1 ||
      "",
    physical_zip:
      data?.customerInformation?.accountHolder?.primaryAddress?.postalCode ||
      "",
    physical_city:
      data?.customerInformation?.accountHolder?.primaryAddress?.city || "",
    physical_county:
      data?.customerInformation?.accountHolder?.primaryAddress?.county || "",
    physical_county_code:
      data?.customerInformation?.accountHolder?.primaryAddress?.countyCode ||
      "",
    physical_state:
      data?.customerInformation?.accountHolder?.primaryAddress?.state || "",
    physical_is_autofilled:
      data?.customerInformation?.accountHolder?.primaryAddress?.isAutoFilled ||
      false,
    is_inforce: data?.customerInformation?.isInForce || false
  };
  if (
    !!data?.customerInformation?.accountHolder?.additionalAddresses &&
    !!data?.customerInformation?.accountHolder?.additionalAddresses.length
  ) {
    data.customerInformation.accountHolder.additionalAddresses.forEach(a => {
      newValues[a.addressType + "_address1"] = a?.addressLine1;
      newValues[a.addressType + "_zip"] = a?.postalCode;
      newValues[a.addressType + "_city"] = a?.city;
      newValues[a.addressType + "_county"] = a?.county;
      newValues[a.addressType + "_county_code"] = a?.countyCode;
      newValues[a.addressType + "_state"] = a?.state;
      newValues[a.addressType + "_is_autofilled"] = a.isAutoFilled;
      newValues[a.addressType + "_publicID"] = a.publicID;
    });
  }
  Object.keys(productKeys).forEach(
    p => (newValues[p] = !!data?.policyLines && data?.policyLines.includes(p))
  );

  // OOQ-11247 - set umbrella to true if umbrella is on (it's not a normal product)
  if (data?.packages?.umbrella?.addWhenRating)
    newValues.cuCommercialUmbrella = true;

  return newValues;
};
