import qs from "query-string";
import API from "../api";

export const getCertificateHistory = (agency_code, search_text, selectType) => {
  const queryString = qs.stringify({
    agency_code,
    search_text,
    option: selectType
  });
  return API.agent().get(`/service-center/certificates?${queryString}`);
};
