import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LoadingIndicator } from "@ufginsurance/ui-kit";

import { getCacheId } from "../selectors/cacheId";
import { fetchSuretyQuoteData } from "./actions/suretyQuoteActions";
import * as routes from "../../constants/routes";
import ScrollToTop from "../../components/menus/ScrollToTop";
import HeaderContainer from "../../containers/HeaderContainer";
import FooterContainer from "../../containers/FooterContainer";
import QQSideBarContainer from "./sidebar/QQSideBarContainer";
import QuotingSteps from "./QuotingSteps";
import "./QuickQuotingLayout.scss";
import "./formGroup.scss";
import { useQuote } from "./shared/useQuote";
import CustomerInfoContainer from "./customer-info/CustomerInfoContainer";
import OwnerInfoContainer from "./owner-info/OwnerInfoContainer";
import EligibilityContainer from "./eligibility/EligibilityContainer";
import BondInformationContainer from "./bond-info/BondInformationContainer";
import Summary from "./summary/Summary";
import ConfirmationContainer from "./confirmation/ConfirmationContainer";
import { GlobalContext } from "../../shared/globalContext";

const QuickQuoteHome = ({
  quickSubmissionResponse,
  quickSubmission,
  ...props
}) => {
  const { suretyDevToolsEnabled } = useFlags();
  const { step, setStep } = useQuote();
  const { content } = useContext(GlobalContext);

  //check cache for unfinished quote
  useEffect(() => {
    props.fetchSuretyQuoteData(props.cacheId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const stepFromPath = parseInt(props.location.pathname.split("/").pop(), 10);

    if (props.suretyQuote?.isLoading || quickSubmission?.isLoading) return;

    if (
      !suretyDevToolsEnabled &&
      quickSubmission?.error == null && // null or undefined.
      !!(
        (!props.suretyQuote?.data && stepFromPath > 1 && stepFromPath <= 5) ||
        (stepFromPath === 6 && !quickSubmissionResponse?.submission_status) ||
        isNaN(stepFromPath)
      )
    ) {
      setStep(1);
      props.history.replace({
        pathname: routes.setStepUrl(1)
      });
    } else {
      setStep(stepFromPath);
    }

    // Skipped Dependencies: (props.history, props.suretyQuote?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.location.pathname,
    props.suretyQuote?.isLoading,
    quickSubmissionResponse,
    setStep,
    suretyDevToolsEnabled,
    quickSubmission
  ]);

  return (
    <div id="QQ">
      <HeaderContainer />
      <div className="qqt__below-nav">
        <QuotingSteps step={step} />
      </div>
      <section id="content" className="noborder">
        <div id="body" className="container">
          <div className="qq__layout__left-sidebar__container">
            {![5, 6].some(s => s === step) && (
              <div className="qq__layout__left-sidebar">
                <QQSideBarContainer step={step} history={props.history} />
              </div>
            )}
            <div className="qq__content-body" ref={content}>
              {props.suretyQuote.isLoading ? (
                <LoadingIndicator />
              ) : (
                <Switch>
                  <ScrollToTop>
                    <Route
                      exact
                      path={routes.setStepUrl(1)}
                      render={() => <CustomerInfoContainer {...props} />}
                    />
                    <Route
                      exact
                      path={routes.setStepUrl(2)}
                      render={props => <OwnerInfoContainer {...props} />}
                    />
                    <Route
                      exact
                      path={routes.setStepUrl(3)}
                      render={props => <EligibilityContainer {...props} />}
                    />
                    <Route
                      exact
                      path={routes.setStepUrl(4)}
                      render={props => <BondInformationContainer {...props} />}
                    />
                    <Route
                      exact
                      path={routes.setStepUrl(5)}
                      render={props => <Summary {...props} />}
                    />
                    <Route
                      exact
                      path={routes.setStepUrl(6)}
                      render={props => <ConfirmationContainer {...props} />}
                    />
                  </ScrollToTop>
                </Switch>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterContainer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cacheId: getCacheId(state),
    suretyQuote: state.suretyQuote,
    quickSubmissionResponse: state.quickSubmission.submissionData,
    quickSubmission: state.quickSubmission
  };
};

export default withRouter(
  connect(mapStateToProps, { fetchSuretyQuoteData })(QuickQuoteHome)
);
