import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CompanyWarning from "../components/company-warning/CompanyWarning";
import { Translations } from "../components/translations";
import NewsArchivesContainer from "./newsArchivesContainer";
import NewsMessage from "./newsMessage";

const NewsCurrent = ({
  fetchNewsCurrent,
  newsCurrent,
  agentState,
  activeSpecialtyCompanyBranch
}) => {
  const [showArchiveModel, setShowArchiveModel] = useState(false);
  const [showCurrent, setShowCurrent] = useState(true);

  useEffect(() => {
    fetchNewsCurrent(agentState, activeSpecialtyCompanyBranch);
  }, [agentState, fetchNewsCurrent, activeSpecialtyCompanyBranch]);

  const removeCurrent = () => {
    setShowCurrent(false);
    setShowArchiveModel(false);
  };

  const showArchive = () => {
    setShowArchiveModel(true);
  };

  const closeArchive = () => {
    setShowArchiveModel(false);
  };

  const hasCurrentNews = news => news && news.length > 0;

  const renderCurrentNewsItems = () => {
    if (!hasCurrentNews(newsCurrent)) {
      return [<div key="no_news">{Translations.warning.no_news}</div>];
    }
    return newsCurrent.slice(0, 5).map(news => (
      <div key={news.entry_id_number}>
        <NewsMessage news={news} />
      </div>
    ));
  };

  return (
    <div>
      {showArchiveModel && <NewsArchivesContainer onClose={closeArchive} />}

      {showCurrent && (
        <CompanyWarning
          action={Translations.warning.action}
          message={renderCurrentNewsItems()}
          onClose={removeCurrent}
          onActionClick={showArchive}
        />
      )}
    </div>
  );
};

NewsCurrent.propTypes = {
  fetchNewsCurrent: PropTypes.func.isRequired,
  newsCurrent: PropTypes.array.isRequired,
  agentState: PropTypes.string.isRequired,
  activeSpecialtyCompanyBranch: PropTypes.string
};

export default NewsCurrent;
