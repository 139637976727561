import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const documentsPacketReducer = (
  state = initialState.suretyQuickDocumentsPacket,
  action
) => {
  switch (action.type) {
    case types.FETCH_DOCUMENTS_PACKET_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.CLEAR_DOCUMENTS_PACKET_ERROR:
    case types.FETCH_DOCUMENTS_PACKET_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.FETCH_DOCUMENTS_PACKET_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default documentsPacketReducer;
