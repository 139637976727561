import React, { useState, useEffect, useCallback } from "react";
import FormatSsn from "../constants/FormatSsn";
import {
  Panel,
  Button,
  useForm,
  Form,
  FormGroup,
  Table,
  ContentHeader,
  FlexRow,
  Alert,
  Checkbox,
  Input,
  Modal
} from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../../components/Factory";
import "./OwnerInfo.scss";
import * as routes from "../../../constants/routes";
import DeleteModal from "./DeleteModal";
import { Translations } from "../../../components/translations";
import PropTypes from "prop-types";
import { formatZip, percentRemoveZero } from "./../shared/util";
import VerifyAddresses from "../shared/AddressVerification/VerifyAddresses";
import EditOwner from "./EditOwner";
import AddOwner from "./AddOwner";
import { validateDuplicateOwnersSSN } from "../../selectors/ownersData";
import { suretyBusinessTitles } from "../constants/suretyDictionary";
import BottomNav from "../shared/BottomNav";

const OwnerInfo = ({
  history,
  suretyQuote,
  updateSuretyQuoteData,
  cacheId,
  verifyAddressData,
  verifyAddress,
  licensedStates,
  suretyZip,
  fetchSuretyZip,
  isOwnerSsnValid,
  hasWholeNumberOwnershipPercents
}) => {
  const initialValues = {
    owner_verified: suretyQuote?.owner_verified || false,
    //map owners to form context
    ...(!!suretyQuote?.ownerData?.length &&
      suretyQuote.ownerData.reduce((obj, owner) => {
        // save whole object in the form context
        obj["ownership_percent_" + owner.id] = percentRemoveZero(
          owner.ownership_percent
        );

        return obj;
      }, {}))
  };

  const form = useForm({ values: initialValues });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm
  } = form;

  const orderCredit = suretyQuote?.ownerData?.some(
    g => g.order_credit === true
  );
  const quoteStatusW = suretyQuote?.status !== "W";
  const customerHasNoOwner = suretyQuote?.ownerData?.length === 0;
  const isNewOwner = suretyQuote?.ownerData?.some(g => g.isNew === true);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [row, setRow] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updatedOwnerData, setUpdatedOwnerData] = useState([]);
  const [showPercentErrorMessage, setshowPercentErrorMessage] = useState("");
  const [showSsnInvalidErrors, setshowSsnInvalidErrors] = useState();
  const [hasDuplicateOwnersSSN, setHasDuplicateOwnersSSN] = useState(false);
  const [ownerTitleAlertMsg, setOwnerTitleAlertMsg] = useState(null);
  const [validTitles, setValidTitles] = useState(true);
  const [showOrderCreditFields, setShowOrderCreditFields] =
    useState(orderCredit);

  const readyToContinueOwner =
    orderCredit || quoteStatusW || customerHasNoOwner || isNewOwner;
  const [readyToContinue, setReadyToContinue] = useState(readyToContinueOwner);

  const mapPercentagesToOwners = () =>
    updatedOwnerData?.map(owner => ({
      ...owner,
      ownership_percent: values["ownership_percent_" + owner.id]
    }));

  const handleContinue = () => {
    //get all owner percent values
    const inputKeys = Object.keys(values);

    const percentCal = inputKeys.reduce((totalPercent, key) => {
      if (key.includes("ownership_percent_")) {
        const tmpKey = key.replace("ownership_percent_", "");

        const tmpOwner = suretyQuote.ownerData.find(j => j.id === tmpKey);

        if (!!tmpOwner && !tmpOwner.delete) {
          totalPercent += Number(values[key]);
        }
      }
      return totalPercent;
    }, 0);

    if (percentCal >= 90 && percentCal <= 100 && !hasDuplicateOwnersSSN) {
      const updatedValue = {
        ...suretyQuote,
        owner_verified: values.owner_verified,
        ///this is not ideal but input values cannot be directly handled by the form context
        ownerData: mapPercentagesToOwners()
      };
      updateSuretyQuoteData(cacheId, updatedValue);
      history.push(routes.setStepUrl(3));
    } else if (!!hasDuplicateOwnersSSN) {
      setshowSsnInvalidErrors("Social Security Number entered is a duplicate.");
    } else {
      setshowPercentErrorMessage(
        "90% - 100% of Ownership required to proceed."
      );
    }
  };

  const backToCustomerLink = () => {
    const updatedValue = {
      ...suretyQuote,
      ownerData: mapPercentagesToOwners()
    };

    updateSuretyQuoteData(cacheId, updatedValue);
    history.push(routes.setStepUrl(1));
  };

  const getIsValidOwnerTitleAndPercent = useCallback(() => {
    const currentBusinessType = suretyBusinessTitles.find(
      j => j.type === suretyQuote?.business_type
    );

    setOwnerTitleAlertMsg(
      `${suretyQuote?.business_type
        ?.split(/(?=[A-Z])/)
        .join(" ")}s require at least one primary owner with the title '${
        currentBusinessType.title
      }'. Primary owners must have at least 1% ownership.`
    );

    const primaryOwners = updatedOwnerData.filter(
      _ => _.title?.toLowerCase() === currentBusinessType.title?.toLowerCase()
    );

    return !!primaryOwners
      ? primaryOwners.length > 0 &&
          primaryOwners.every(_ => _.ownership_percent > 0)
      : false;
  }, [updatedOwnerData, suretyQuote]);

  useEffect(() => {
    const validOwners = updatedOwnerData?.filter(owner => !owner.delete);
    setHasDuplicateOwnersSSN(validateDuplicateOwnersSSN(validOwners));
  }, [updatedOwnerData]);

  useEffect(() => {
    if (!!updatedOwnerData && !!updatedOwnerData.length) {
      const isValidTitles = getIsValidOwnerTitleAndPercent();
      setValidTitles(isValidTitles);
      setReadyToContinue(isValidTitles);
    }
  }, [suretyQuote, updatedOwnerData, getIsValidOwnerTitleAndPercent]);

  useEffect(() => {
    setShowOrderCreditFields(
      !!updatedOwnerData?.some(g => g.order_credit === true)
    );
  }, [updatedOwnerData, setShowOrderCreditFields]);

  //show add modal if new customer
  useEffect(() => {
    if (!!suretyQuote?.newCustomer && !suretyQuote?.ownerData?.length) {
      setShowAddModal(true);
    }
  }, [suretyQuote?.newCustomer, suretyQuote?.ownerData]);

  useEffect(() => {
    const mapOwnerData = suretyQuote?.ownerData?.map(owner => {
      if (!owner.hasOwnProperty("delete")) {
        owner.delete = false;
      }

      return owner;
    });

    setUpdatedOwnerData(mapOwnerData);
  }, [suretyQuote]);

  useEffect(() => {
    if (!isOwnerSsnValid && suretyQuote.ownerData.length > 0) {
      setshowSsnInvalidErrors(Translations.Surety.invalidSsn);
    } else {
      setshowSsnInvalidErrors(false);
    }
  }, [suretyQuote, isOwnerSsnValid]);

  const editOwner = row => {
    row.origTitle = row.title;
    row.origSsn = row.ssn;
    row.origPct = row.ownership_percent;
    setShowEditModal(true);
    setRow(row);
    setshowPercentErrorMessage(false);
  };
  const openDeleteConfirmationModal = row => {
    setShowDeleteModal(true);
    setRow(row);
    setUpdatedOwnerData(updatedOwnerData);
  };

  const updateOwners = (ownerData, editRow) => {
    const newOwners = ownerData?.map(owner => {
      if (owner.id === editRow.id) {
        return {
          ...owner,
          ...editRow
        };
      }

      return owner;
    });

    setUpdatedOwnerData(newOwners);
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      ownerData: newOwners
    });
    setShowEditModal(false);
  };

  const registerPercentageValues = idList => {
    updateForm({
      values: idList.reduce((acc, id) => {
        acc["ownership_percent_" + id] = "0";
        return acc;
      }, {})
    });
  };

  const handleOwnerPercentBlur = field => {
    const updatedValue = {
      ...suretyQuote,
      owner_verified: values.owner_verified,
      ///this is not ideal but input values cannot be directly handled by the form context
      ownerData: mapPercentagesToOwners()
    };
    updateSuretyQuoteData(cacheId, updatedValue);
    handleOnBlur(field, values);
  };

  const handleOwnershipPercentValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const isIntegerRgx = /^\d+$/;
    if (!isIntegerRgx.test(value)) fieldErrors.push(" Whole number required");
    return fieldErrors;
  };

  const getOwnerTitle = ({ title, title_other }) => {
    if (!title) return;
    if (title_other?.length) title = `${title}: ${title_other}`;
    return toTitleCase(title);
  };

  const isContinueButtonEnabled =
    readyToContinue && !invalidFields.length && hasWholeNumberOwnershipPercents;

  const tableColumns = [
    {
      key: "first_name",
      label: "Individual/Indemnitor",
      element: row =>
        toTitleCase(
          row.first_name + " " + row.middle_name + " " + row.last_name
        ),
      sortable: true,
      nowrap: true
    },
    {
      key: "title",
      label: "Title",
      sortable: true,
      element: row => getOwnerTitle(row)
    },
    {
      key: "address_line",
      label: "Address",
      element: row => {
        return (
          <div>
            <div>{row.address_line}</div>
            <div>
              {toTitleCase(row.city)}, {row.state} {formatZip(row.zip)}
            </div>
          </div>
        );
      }
    },
    {
      key: "ssn",
      label: "Social Security Number",
      element: row => <FormatSsn data={row.ssn} />,
      sortable: true,
      className: "fullstory-mask"
    },
    {
      key: "ownership_percent",
      label: "Percent of Ownership",
      element: row => (
        <FormGroup>
          <Input
            id={"ownership_percent_" + row.id}
            name={"ownership_percent_" + row.id}
            onChange={handleOnChange}
            onBlur={handleOwnerPercentBlur}
            onValidate={handleOwnershipPercentValidate}
            noLabel
            value={values["ownership_percent_" + row.id]}
            size="auto"
            numbersOnly
            label={"ownership_percent_" + row.id}
          />
        </FormGroup>
      )
    },
    {
      key: "actions",
      label: "Actions",
      element: row => (
        <FlexRow align="right" className="mx-0">
          <Button
            className="m-0 p-0"
            wrapperClassName="m-0 p-0"
            icon="fasPencil"
            isLink
            variant="secondary"
            onClick={() => editOwner(row)}
          >
            <span className="uikit-sr-only">Edit Owner</span>
          </Button>
          <Button
            className="m-0 p-0"
            wrapperClassName="m-0 p-0"
            icon="fasTimesCircle"
            onClick={() => openDeleteConfirmationModal(row)}
            isLink
            variant="secondary"
          >
            <span className="uikit-sr-only">Remove Owner</span>
          </Button>
        </FlexRow>
      ),
      className: "no-spacing"
    }
  ];

  const header = (
    <div className="flexRow wrap">
      Owner/indemnitor information
      <div className="subtitle">
        All owners and their spouse/domestic partner must be listed as
        individual indemnitors.
      </div>
      <div className="rowSpacer" />
    </div>
  );

  const getTableData = data =>
    data?.filter(owner => owner.delete === false) || [];

  return (
    <div>
      <ContentHeader>Who are the owners/indemnitors?</ContentHeader>
      <div>
        {showOrderCreditFields && (
          <Alert label="Note:" dismissible={false} className="qq__alert">
            <span>
              Credit Authorization will be done on all individuals, please
              review & update information.
            </span>
          </Alert>
        )}
        {showPercentErrorMessage && (
          <Alert
            className="qq__alert warning_msg"
            dismissible={false}
            type="warning"
          >
            <span>{showPercentErrorMessage}</span>
          </Alert>
        )}
        {!validTitles && (
          <Alert
            className="qq__alert warning_msg"
            dismissible={false}
            type="warning"
          >
            <span>{ownerTitleAlertMsg}</span>
          </Alert>
        )}
        {!!showSsnInvalidErrors && (
          <Alert
            className="qq__alert warning_msg"
            dismissible={false}
            type="warning"
          >
            <span>{showSsnInvalidErrors}</span>
          </Alert>
        )}
      </div>
      <Form className="qq__ownerinfo-form" context={form}>
        <Panel title={header} titlebar rounded bgcolor="grey" id="add-owner">
          <FormGroup align="left">
            <Button
              className="qqAddOwner"
              icon="fasPlus"
              variant="primary"
              onClick={() => setShowAddModal(true)}
            >
              Add Individual
            </Button>
          </FormGroup>
          <div>
            <Table
              rowKey="id"
              columns={tableColumns}
              data={getTableData(updatedOwnerData)}
              showPagination
              itemsPerPage={5}
            />
          </div>
        </Panel>
        <BottomNav leftNav>
          <FlexRow align="right">
            <Button isLink inline onClick={backToCustomerLink}>
              Previous
            </Button>
            <FormGroup className="align-right">
              {showOrderCreditFields && (
                <Checkbox
                  id="owner_verified"
                  name="owner_verified"
                  label="I have verified that the owner information provided is correct"
                  noLabel
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values?.owner_verified || false}
                  required
                  className="checkbox__owner_checkbox"
                  size="lg"
                  requiredError="Required"
                />
              )}
              <Button
                variant="primary"
                onClick={handleContinue}
                disabled={!isContinueButtonEnabled}
              >
                Continue to Eligibility
              </Button>
            </FormGroup>
          </FlexRow>
        </BottomNav>
      </Form>
      <Modal
        title="Add Owner/Indemnitors"
        size="lg"
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        className="qq__modal"
        body={
          <AddOwner
            setShowAddModal={setShowAddModal}
            history={history}
            setUpdatedOwnerData={setUpdatedOwnerData}
            ownerData={updatedOwnerData}
            setShowEditModal={setShowEditModal}
            updateSuretyQuoteData={updateSuretyQuoteData}
            suretyQuote={suretyQuote}
            cacheId={cacheId}
            licensedStates={licensedStates}
            suretyZip={suretyZip}
            fetchSuretyZip={fetchSuretyZip}
            registerPercentageValues={registerPercentageValues}
          />
        }
        altCloseMethod={false}
      />
      <Modal
        title={
          <>
            Edit&nbsp;{toTitleCase(row?.first_name || "")}&nbsp;
            {toTitleCase(row?.last_name || "")}
          </>
        }
        className="qq__modal"
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        altCloseMethod={false}
        body={
          <EditOwner
            setShowEditModal={setShowEditModal}
            editRow={row}
            setRow={setRow}
            setUpdateOwnerData={setUpdatedOwnerData}
            ownerData={updatedOwnerData}
            setShowAddModal={setShowAddModal}
            history={history}
            verifyAddress={verifyAddress}
            verifyAddressData={verifyAddressData}
            licensedStates={licensedStates}
            suretyZip={suretyZip}
            fetchSuretyZip={fetchSuretyZip}
            updateSuretyQuoteData={updateSuretyQuoteData}
            cacheId={cacheId}
            suretyQuote={suretyQuote}
            updateOwners={updateOwners}
          />
        }
      />
      {showDeleteModal && (
        <DeleteModal
          title="Delete Owner"
          warningMessage={
            <>
              Are you sure you want to delete{" "}
              {row.first_name + " " + row.last_name}?
            </>
          }
          show={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setUpdateOwnerData={setUpdatedOwnerData}
          ownerData={updatedOwnerData}
          setRow={setRow}
          deleteRow={row}
        />
      )}

      {!!suretyQuote?.verifyModalData && suretyQuote?.verifyModalData?.show && (
        <VerifyAddresses
          cacheId={cacheId}
          suretyQuote={suretyQuote}
          updateSuretyQuoteData={updateSuretyQuoteData}
          setUpdatedOwnerData={setUpdatedOwnerData}
          ownerData={updatedOwnerData}
          setShowAddModal={setShowAddModal}
        />
      )}
    </div>
  );
};
export default OwnerInfo;

OwnerInfo.propTypes = {
  history: PropTypes.any,
  suretyQuote: PropTypes.object,
  updateSuretyQuoteData: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  verifyAddressData: PropTypes.object,
  verifyAddress: PropTypes.func.isRequired,
  licensedStates: PropTypes.array,
  suretyZip: PropTypes.object,
  fetchSuretyZip: PropTypes.func.isRequired,
  isOwnerSsnValid: PropTypes.bool,
  hasWholeNumberOwnershipPercents: PropTypes.bool
};
