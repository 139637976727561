import moment from "moment";
import qs from "query-string";
import API from "../api";

export const getActivityList = ({
  policy_number,
  policy_type,
  begin_date,
  end_date
}) => {
  const queryString = qs.stringify({
    policy_type,
    begin_date: moment(begin_date).format("YYYY-MM-DD"),
    end_date: moment(end_date).format("YYYY-MM-DD")
  });
  return API.agent().get(`policies/${policy_number}/activity?${queryString}`, {
    timeout: 300000
  });
};

export const getActivityDocument = (
  document_id,
  policy_number,
  policy_type
) => {
  const queryString = qs.stringify({ policy_number, policy_type });
  return API.documents().get(`/documents/${document_id}?${queryString}`, {
    responseType: "blob"
  });
};

export const getCancellationList = policy_number => {
  const queryString = qs.stringify({ policy_number });
  return API.documents().get(`/cancellation-list?${queryString}`);
};

export const getCancellationDocument = (policy_number, date, document_id) => {
  const queryString = qs.stringify({
    policy_number,
    report_date: date,
    document_id
  });
  return API.documents().get(`cancellation-list/document?${queryString}`, {
    responseType: "blob"
  });
};
