import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import StarsAndAppetite from "./StarsAndAppetite";
import FormLinks from "./FormLinks";
import { convertStatesAndAbbreviations } from "../../components/Factory";

const PREMIER_PRO_PRODUCT_CODE = "BP2";

const ClassAppetiteGuideAppetiteDetail = props => {
  const displayDate = moment().format("MM/DD/YYYY");
  const { selectedState, selectedProduct, classItem, productCode } = props;

  const forms = classItem.forms;

  const showSicCode = productCode => {
    return productCode === PREMIER_PRO_PRODUCT_CODE;
  };

  return (
    classItem && (
      <div className="classifications-detail">
        <div className="class-name-description">
          {classItem.code} {classItem.description}
        </div>
        <div className="row selected-values">
          {/* <div className="col-md-6 selected-product">
              {selectedProduct.product_name}
            </div> */}
          <div className="col-md-6 selected-state">
            {convertStatesAndAbbreviations(selectedState, "name").toUpperCase()}{" "}
            - {displayDate}
          </div>
        </div>
        <StarsAndAppetite
          grade={classItem.grade}
          productCode={selectedProduct.product_code}
        />
        <hr />
        {showSicCode(productCode) ? (
          <div className="sic-code">
            <h3 className="blackText">SIC Code</h3>
            {classItem.sic_code}
          </div>
        ) : (
          ""
        )}
        {forms && forms.length > 0 && <FormLinks forms={classItem.forms} />}
      </div>
    )
  );
};

ClassAppetiteGuideAppetiteDetail.propTypes = {
  selectedState: PropTypes.string,
  selectedProduct: PropTypes.string,
  classItem: PropTypes.object,
  productCode: PropTypes.string
};

export default ClassAppetiteGuideAppetiteDetail;
