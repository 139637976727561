import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import * as routes from "../../constants/routes";
import { Icon, Anchor } from "@ufginsurance/ui-kit";
import { useNewBusinessContext } from "../context";
import { useCurrentRouteMatch, useOnlyBaseRoute } from "../hooks/hooks";
import _equal from "lodash/isEqual";

const Breadcrumbs = () => {
  const { newBusinessData, updateTableOfContentId } = useNewBusinessContext();

  const history = useHistory();
  const currentMatch = useCurrentRouteMatch();
  const onlyBaseRouteMatch = useOnlyBaseRoute();

  const defaultHeaderText = "New Business Procedures";

  const [currentHeader, setCurrentHeader] = useState(null);
  const [currentSubheader, setCurrentSubheader] = useState(null);

  // set current header and subheader
  useEffect(() => {
    const { headerId, subheaderId } = currentMatch.params;

    const header = !headerId
      ? newBusinessData.tableOfContents.data.find(h => h.type === "Header")
      : newBusinessData.tableOfContents.data.find(content => {
          return content.id === headerId;
        });

    const subheader = !subheaderId
      ? newBusinessData.tableOfContents.data.find(
          s => s.type === "Sub-Header1" && s.sequence > header.sequence
        )
      : newBusinessData.tableOfContents.data.find(content => {
          return content.id === subheaderId;
        });

    if (!_equal(currentSubheader, subheader)) {
      if (subheader) {
        updateTableOfContentId(subheader?.id);
      }
      setCurrentHeader(header);
      setCurrentSubheader(subheader);
    }
  }, [
    newBusinessData.tableOfContents.data,
    currentMatch.params,
    currentHeader,
    currentSubheader,
    updateTableOfContentId
  ]);

  // redirect base route to first table of content route
  useEffect(() => {
    if (newBusinessData?.tableOfContents?.data) {
      const defaultSubHeader = newBusinessData?.tableOfContents?.data.find(
        s => s.type !== "Header"
      );

      const defaultHeader = !defaultSubHeader
        ? newBusinessData?.tableOfContents?.data[0]
        : newBusinessData?.tableOfContents?.data.find(
            s => s.type === "Header" && s.sequence < defaultSubHeader?.sequence
          );

      if (onlyBaseRouteMatch && defaultHeader && defaultSubHeader) {
        history.replace(
          `${routes.NEW_BUSINESS}/header/${defaultHeader.id}/subheader/${defaultSubHeader.id}`
        );
      } else if (onlyBaseRouteMatch && defaultHeader) {
        history.replace(`${routes.NEW_BUSINESS}/header/${defaultHeader.id}`);
      }
    }
  }, [newBusinessData.tableOfContents.data, history, onlyBaseRouteMatch]);

  const onBaseRouteClick = () => {
    history.push(routes.NEW_BUSINESS);
  };

  const onHeaderClick = () => {
    const tmpSubHeader = newBusinessData?.tableOfContents?.data.find(
      s => s.sequence > currentHeader.sequence
    );

    history.push(
      `${routes.NEW_BUSINESS}/header/${currentHeader.id}/subheader/${tmpSubHeader.id}`
    );
  };

  return (
    <div id="new-business__breadcrumbs">
      <Switch>
        {/* base route */}
        <Route exact path={routes.NEW_BUSINESS}>
          <div className={`new-business__breadcrumb`}>
            <Anchor
              className="breadcrumb__link breadcrumb__link--disabled"
              onClick={() => {}}
            >
              {defaultHeaderText}
            </Anchor>
          </div>
        </Route>

        {/* header route */}
        <Route exact path={routes.NEW_BUSINESS_HEADER}>
          <div className={`new-business__breadcrumb`}>
            <Anchor className={`breadcrumb__link`} onClick={onBaseRouteClick}>
              {defaultHeaderText}
              {<Icon size="lg" icon="farAngleRight" />}
            </Anchor>
          </div>

          {currentHeader && (
            <div className={`new-business__breadcrumb`}>
              <Anchor
                className="breadcrumb__link breadcrumb__link--disabled"
                onClick={() => {}}
              >
                {currentHeader.description}
              </Anchor>
            </div>
          )}
        </Route>

        {/* subheader route */}
        <Route exact path={routes.NEW_BUSINESS_SUB_HEADER}>
          <div className={`new-business__breadcrumb`}>
            <Anchor className={`breadcrumb__link`} onClick={onBaseRouteClick}>
              {defaultHeaderText}
              {<Icon size="lg" icon="farAngleRight" />}
            </Anchor>
          </div>

          {currentHeader && (
            <div className={`new-business__breadcrumb`}>
              <Anchor className={`breadcrumb__link`} onClick={onHeaderClick}>
                {currentHeader.description}
                {<Icon size="lg" icon="farAngleRight" />}
              </Anchor>

              <Anchor
                className="breadcrumb__link breadcrumb__link--disabled"
                onClick={() => {}}
              >
                {currentSubheader && currentSubheader.description}
              </Anchor>
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(Breadcrumbs);
