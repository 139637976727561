/*
IMPORTANT NOTE: THIS FILE IS TEMPORARY AND ONLY EXISTS FOR THE PURPOSE OF ISOLATING CHANGES
THIS WILL BE DELETED AS REFACTORING PROGRESSES

- Dustin
*/
import React, { useEffect, useState } from "react";
import { Input, Dropdown, FieldErrors, FormGroup } from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../../components/Factory";
import { searchLocaleByZipCodeV2 } from "../quickQuotingServices";
import {
  ADDRESS_MAX_LENGTH,
  POBOX_MAX_LENGTH,
  NON_SURETY_STATES,
  statesList,
  streetPattern,
  zipPattern
} from "../constants/suretyDictionary";
import { getAddressFieldIDs, getInvalidStatesErrorMsg } from "./util";

const Address = ({
  form: {
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    values,
    updateForm,
    errors
  },
  addressLineFieldLabel,
  agencyStates = statesList,
  allowPoBox,
  checkIsAgencyState,
  checkIsNonSuretyState,
  customZipChangeHandler,
  disableFields,
  disableProjectCity,
  disableProjectState,
  disableValidation,
  prefix,
  renderAddressLine,
  replaceAddressWithPoBox,
  showBondLabelAddress,
  showDefaultAddressField,
  zipCodeLabel,
  zipPlusFour
}) => {
  const isPoBox = prefix === "pobox";
  const {
    addressLineFieldId,
    zipFieldId,
    cityFieldId,
    stateFieldId,
    poboxFieldId
  } = getAddressFieldIDs(prefix);

  const [localeData, setLocaleData] = useState([]);
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(true);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (prefix === "obligee") {
      setStateOptions(statesList);
    } else {
      setStateOptions(agencyStates);
    }
  }, [agencyStates, prefix]);

  const handleZipOnChange = ({ field, value }) => {
    handleOnChange({ field, value });
    if (value && zipPattern.test(value)) {
      searchLocaleByZipCodeV2(value).then(response => {
        setLocaleData(response.data);

        if (response.data.length) {
          const { city, state } = response.data[0];
          const isNonSuretyState =
            checkIsNonSuretyState && NON_SURETY_STATES.includes(state);
          const isAgencyState = agencyStates.map(s => s.value).includes(state);

          if (checkIsAgencyState && !isAgencyState) {
            setStateOptions([...agencyStates, { value: state, label: state }]);
          }

          updateForm({
            values: {
              [zipFieldId]: value,
              [cityFieldId]: city,
              [stateFieldId]: state
            },
            errors: {
              [zipFieldId]:
                checkIsNonSuretyState && isNonSuretyState
                  ? [getInvalidStatesErrorMsg()]
                  : [],
              [cityFieldId]: [],
              [stateFieldId]:
                checkIsAgencyState && !isAgencyState
                  ? [
                      `You are attempting to submit business in a state in which
                    we do not have a license on file. Please contact your UFG
                    marketing representative for licensing procedures.`
                    ]
                  : []
            }
          });

          if (!!disableProjectCity || !!disableProjectState) {
            const uniqueCities = [...new Set(response.data.map(x => x.city))];
            const uniqueStates = [...new Set(response.data.map(x => x.state))];

            setDisableCity(uniqueCities.length < 2);
            setDisableState(uniqueStates.length < 2);
          }
        } else if (!response.data.length) {
          setDisableCity(true);
          setDisableState(true);
          updateForm({
            values: {
              [zipFieldId]: value,
              [cityFieldId]: "",
              [stateFieldId]: ""
            },
            errors: {
              [zipFieldId]: ["Invalid Zipcode"],
              [cityFieldId]: [],
              [stateFieldId]: []
            }
          });
        }
        if (customZipChangeHandler)
          customZipChangeHandler({ field, value }, response.data);
      });
    }

    // Reset.
    if (!value) {
      setDisableCity(true);
      setDisableState(true);
      setStateOptions(prefix === "obligee" ? statesList : agencyStates);
      updateForm({
        values: {
          [zipFieldId]: "",
          [cityFieldId]: "",
          [stateFieldId]: ""
        },
        errors: {
          [zipFieldId]: [],
          [cityFieldId]: [],
          [stateFieldId]: []
        }
      });
    }
  };

  const handleZipValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!zipPattern.test(value))
      fieldErrors.push(
        "Zip Code must be a number with format of 55555 or 55555-1234"
      );
    return fieldErrors;
  };

  const handleStreetValidate = field => {
    const fieldErrors = handleOnValidate(field);
    if (
      !!field.value &&
      !streetPattern.test(field.value) &&
      !disableValidation
    ) {
      fieldErrors.push(
        `Not a valid address. A street address should look like '123 Main St' ${
          allowPoBox ? "and a PO Box like 'PO Box 123'" : ""
        }`
      );
    }

    return fieldErrors;
  };

  const handleStateOnValidate = field => {
    const fieldErrors = handleOnValidate(field);
    const { value } = field;
    const errMsg = getInvalidStatesErrorMsg();

    if (
      value &&
      checkIsNonSuretyState &&
      !errors[zipFieldId]?.includes(errMsg) &&
      NON_SURETY_STATES.includes(value)
    ) {
      fieldErrors.push(errMsg);
    }

    return fieldErrors;
  };

  const getFormattedCities = locales => {
    const localeMatch = locales.find(
      locale => values[cityFieldId] === locale.city
    );

    // default to form values if no locale data
    if (!localeMatch || disableFields) {
      return [
        { value: values[cityFieldId], label: toTitleCase(values[cityFieldId]) }
      ];
    }

    return locales.map(locale => ({
      value: locale.city,
      label: toTitleCase(locale.city)
    }));
  };

  return (
    <div>
      <FormGroup hideErrors className="Address">
        {renderAddressLine}
        {showDefaultAddressField &&
          !renderAddressLine &&
          !replaceAddressWithPoBox && (
            <Input
              id={addressLineFieldId}
              name={addressLineFieldId}
              label={addressLineFieldLabel || "Address"}
              placeholder="123 Main Street"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleStreetValidate}
              value={values[addressLineFieldId] || ""}
              required
              className="grow address"
              size="fill"
              maxLength={ADDRESS_MAX_LENGTH}
              disabled={disableFields}
            />
          )}
        {!renderAddressLine && replaceAddressWithPoBox && (
          <Input
            id={poboxFieldId}
            name={poboxFieldId}
            label={addressLineFieldLabel || "Address"}
            placeholder="1234"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values[poboxFieldId]?.trim()}
            required
            className="grow address"
            disabled={disableFields}
            maxLength={POBOX_MAX_LENGTH}
            numbersOnly
          />
        )}
        <Input
          id={zipFieldId}
          name={zipFieldId}
          label={
            zipCodeLabel
              ? zipCodeLabel
              : !!showBondLabelAddress
              ? "Project Zip Code"
              : "Zip Code"
          }
          className="projectZip"
          onChange={handleZipOnChange}
          onBlur={handleOnBlur}
          onValidate={handleZipValidate}
          value={values[zipFieldId] || ""}
          required
          maxLength={
            zipPlusFour
              ? 10
              : !showDefaultAddressField || !renderAddressLine
              ? 5
              : 10
          }
          disabled={disableFields || isPoBox}
        />
        <Dropdown
          id={cityFieldId}
          name={cityFieldId}
          label={!!showBondLabelAddress ? "Project City" : "City"}
          placeholder=""
          className="city"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values[cityFieldId]}
          options={getFormattedCities(localeData)}
          required
          isClearable={false}
          disabled={
            disableFields || isPoBox || (disableProjectCity && disableCity)
          }
        />
        <Dropdown
          id={stateFieldId}
          name={stateFieldId}
          label={!!showBondLabelAddress ? "Project State" : "State"}
          className="state"
          placeholder=""
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleStateOnValidate}
          value={values[stateFieldId]}
          isClearable={false}
          options={stateOptions}
          disabled={
            disableFields || isPoBox || (disableProjectState && disableState)
          }
          required
        />
      </FormGroup>

      <FieldErrors
        fields={
          isPoBox
            ? ["pobox"]
            : [addressLineFieldId, zipFieldId, cityFieldId, stateFieldId]
        }
      />
    </div>
  );
};

export default Address;
