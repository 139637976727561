import React, { useEffect, useState } from "react";
import { Currency } from "@ufginsurance/ui-kit";
import { formatCurrencyUSD } from "../../../surety/shared/utils";
import { DEDUCTIBLES } from "../lib/constants";

export default function useGetDeductibleOptions({
  job_sites,
  deductible,
  minimum_premium,
  coverage_amount
}) {
  const [deductibleOptions, setDeductibleOptions] = useState([]);
  useEffect(() => {
    const options = DEDUCTIBLES.map(ded => {
      const modifiedPremium = Math.round(
        job_sites.reduce((accum, a) => accum + a.premium, 0) * ded.factor
      );
      let diff = 0;
      if (modifiedPremium > minimum_premium) {
        const comparablePremium =
          minimum_premium >= coverage_amount
            ? minimum_premium
            : coverage_amount;
        diff = Math.round(comparablePremium) - modifiedPremium;
      }

      return {
        ...ded,
        modifiedPremium,
        value: String(ded.amount),
        label: (
          <div className="deductible-dropdown-option">
            <Currency amount={ded.amount} includeDecimals={false} />
            {diff !== 0 && (
              <em>
                {formatCurrencyUSD(-diff, 0, { signDisplay: "always" })} (New
                Premium{" "}
                {formatCurrencyUSD(
                  modifiedPremium >= minimum_premium
                    ? modifiedPremium
                    : minimum_premium,
                  0
                )}
                )
              </em>
            )}
          </div>
        )
      };
    });
    setDeductibleOptions(options);
  }, [deductible, job_sites, coverage_amount, minimum_premium]);

  return [deductibleOptions, setDeductibleOptions];
}
