import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { combineZipAndAreaCodes } from "../actions/newQuoteActions";
import { validateZip } from "../formValidation";
import { Translations } from "../../../components/translations";

const ZipCodeInput = ({ disabled, field, form, onValidZipCodeChange }) => {
  const [zipCode, setZipCode] = useState("");
  const [areaCode, setAreaCode] = useState("");

  useEffect(() => {
    const [zip, area] = field.value.split("-");
    setZipCode(zip || "");
    setAreaCode(area || "");
  }, [field]);

  const handleOnBlur = () => {
    form.setFieldTouched(field.name);
  };

  const handleOnChange = e => {
    const zip = e.target.name === "zipCode" ? e.target.value : zipCode;
    const area = e.target.name === "areaCode" ? e.target.value : areaCode;

    const combinedZip = combineZipAndAreaCodes(zip, area);
    form.setFieldValue(field.name, combinedZip);

    // Notify parent so that it can execute redux action if necessary
    if (!validateZip(combinedZip) && combinedZip !== field.value) {
      onValidZipCodeChange(zip);
    }
  };

  return (
    <>
      <input
        id="zipCode"
        name="zipCode"
        value={zipCode}
        placeholder={Translations.start_new_quote.customer_modal.enter_zip_code}
        maxLength="5"
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        className="form-control zipCodeNumber"
      />
      <span> - </span>
      <input
        id="areaCode"
        name="areaCode"
        value={areaCode}
        placeholder={Translations.start_new_quote.customer_modal.code}
        maxLength="4"
        pattern="[0-9]{4}"
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        className="form-control zipAreaCode"
      />
    </>
  );
};

ZipCodeInput.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onValidZipCodeChange: PropTypes.func.isRequired
};

export default ZipCodeInput;
