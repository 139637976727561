import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "@ufginsurance/ui-kit";
import { DateFormat } from "../../components/Factory";
import { fetchAuditDocument } from "../actions/auditBreakdownActions";
import { Translations } from "../../components/translations";

const AuditBreakdownList = ({ audits, searchNumber }) => {
  const { review_audit } = Translations;
  const columns = [
    {
      key: "process_date",
      label: review_audit.process_date,
      sortable: true,
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "document_description",
      label: review_audit.document_description,
      element: row => {
        return (
          <Button
            isLink
            variant="secondary"
            className="capitalize"
            onClick={fetchAuditDocument(searchNumber, row.id_number)}
          >
            {row.document_description
              ? row.document_description
              : "No document description available"}
          </Button>
        );
      }
    },
    {
      key: "effective_date",
      label: review_audit.effective_date,
      element: row => (
        <span>{DateFormat(row.effective_date, "MM/DD/YYYY")}</span>
      )
    }
  ];
  return (
    <Table
      data={audits}
      rowKey="document_id"
      columns={columns}
      itemsPerPage={20}
      initialDirection="desc"
      initialSort="process_date"
    />
  );
};

AuditBreakdownList.propTypes = {
  audits: PropTypes.array,
  searchNumber: PropTypes.string.isRequired
};

export default AuditBreakdownList;
