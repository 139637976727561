import React from "react";
import PropTypes from "prop-types";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Panel,
  LoadingIndicator,
  Form,
  FormGroup,
  Dropdown,
  Input,
  Table,
  Anchor
} from "@ufginsurance/ui-kit";
import { openPopupWindow, formatCurrencyUSD } from "../../shared/utils";
import { DateFormat, toTitleCase } from "../../../components/Factory";
import { Eportal } from "../../shared/eportal";
import { useSuretyContext } from "../../surety-context/SuretyContext";

export default function AccountOverviewBondListPanel({
  formContext,
  bondTypeOptions,
  panelValues,
  isLoading,
  error,
  list,
  onChange,
  onBlur,
  onValidate
}) {
  const { suretyBondOverviewEnabled } = useFlags();
  const { updateBondOverview } = useSuretyContext();
  const columnsBondList = [
    {
      key: "bond_effective_date",
      label: "Effective Date",
      sortable: true,
      element: _ => <>{DateFormat(_.bond_effective_date)}</>,
      dataType: "date"
    },
    {
      key: "bond_number",
      label: "Bond No",
      sortable: true,
      element: ({ bond_number }) => (
        <Anchor
          target="_blank"
          className="nowrap"
          onClick={() => {
            if (suretyBondOverviewEnabled) {
              updateBondOverview({ bond_number, showModal: true });
            } else {
              openPopupWindow(Eportal.getBondInfoLink(bond_number));
            }
          }}
        >
          {bond_number}
        </Anchor>
      )
    },
    {
      key: "obligee_name",
      label: "Obligee",
      sortable: true,
      element: _ => (
        <>
          {toTitleCase(_.obligee_name)
            .replace("Of ", "of ")
            .replace("And ", "and ")}
        </>
      )
    },
    {
      key: "bond_amount",
      label: "Bond Amount",
      sortable: true,
      element: _ => <>{formatCurrencyUSD(_.bond_amount)}</>,
      align: "right",
      dataType: "number"
    },
    {
      key: "bond_type",
      label: "Form",
      sortable: true,
      nowrap: true,
      element: _ => <>{toTitleCase(_.bond_type).replace("And ", "& ")}</>
    },
    {
      key: "bond_status",
      label: "Status",
      sortable: true,
      nowrap: true,
      element: _ => <>{toTitleCase(_.bond_status)}</>
    }
  ];

  return (
    <Panel
      className="account-overview__bond-list"
      bgcolor="grey"
      titlebar
      columns="Bond List"
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Form context={formContext}>
            <FormGroup>
              <Dropdown
                id="currentBondStatusFilter"
                name="currentBondStatusFilter"
                className="mb-1"
                options={bondTypeOptions || []}
                value={panelValues.currentBondStatusFilter}
                size="25p"
                label="Bond Status"
                onChange={onChange}
                onBlur={onBlur}
                onValidate={onValidate}
                isClearable={false}
              />
              <Input
                id="currentSearchNumber"
                name="currentSearchNumber"
                onChange={onChange}
                onBlur={onBlur}
                onValidate={onValidate}
                value={panelValues.currentSearchNumber}
                label="Search"
                placeholder="Search by bond number or keyword"
                size="fill"
              />
            </FormGroup>
          </Form>
          <Table
            rowKey="id"
            id="accountOverviewBondListTable"
            columns={columnsBondList}
            data={
              error || !list
                ? []
                : list.filter(
                    bond =>
                      bond.bond_status ===
                        panelValues.currentBondStatusFilter ||
                      panelValues.currentBondStatusFilter === "ALL"
                  )
            }
            showPagination
            itemsPerPage={10}
            searchQuery={panelValues.currentSearchNumber}
          />
        </>
      )}
    </Panel>
  );
}

AccountOverviewBondListPanel.propTypes = {
  formContext: PropTypes.object.isRequired,
  bondTypeOptions: PropTypes.array,
  panelValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  list: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
};
