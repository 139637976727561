import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  FlexRow,
  Form,
  FormGroup,
  DatePicker,
  useForm,
  LoadingIndicator,
  formatDate
} from "@ufginsurance/ui-kit";
import { useFlags, withLDConsumer } from "launchdarkly-react-client-sdk";

import LegacyPolicyChangesForm from "./LegacyPolicyChangesForm";
import OtherChanges from "./other-changes/OtherChanges";
import HelpDeskMessage from "../help-desk/helpDeskMessage";
import { CHANGE_REQUEST_TYPES } from "../constants/dictionary";
import { Translations } from "./../components/translations";
import { mapPolicyChangeUrl, POLICY_WIZARD_STEPS } from "./constants_helpers";

const POLICY_LOADING_TYPE = {
  NOT_LOADING: 1,
  LOADING: 2,
  COMPLETE: 3,
  ERROR: 4
};

const PolicyChangesForm = ({
  policyInfo,
  commercial_underwriter,
  agentCode,
  selectedAgencyCode,
  processChange,
  activateOtherChangesModal,
  requestChangeData,
  pristine,
  submitting,
  invalid,
  createPolicyDraft,
  is109015Agent,
  isCurrentPolicyInForce
}) => {
  const { origin = "" } = policyInfo;
  const { driverChangesPhase2 } = useFlags();

  const { changeRequests } = Translations.account_overview;

  const history = useHistory();

  const initialValues = {
    effectiveDate: "",
    policyChangeInformation: ""
  };

  const form = useForm({ values: initialValues });
  const { values, errors, handleOnBlur, handleOnValidate, handleOnChange } =
    form;

  const handleEffectiveDateOnChange = ({ field, value, event }) => {
    setChangeTypesLoading(POLICY_LOADING_TYPE.NOT_LOADING);
    handleOnChange({ field, value, event });
  };

  const [changeTypesLoading, setChangeTypesLoading] = useState(
    POLICY_LOADING_TYPE.NOT_LOADING
  );
  const [selectedChangeType, setSelectedChangeType] = useState();

  const disableChangeCard =
    !values.effectiveDate ||
    Object.keys(errors).length > 0 ||
    changeTypesLoading.length > 0;

  const changeTypes = requestChangeData?.allowed_change_types ?? [];

  const isGuidewirePolicy = origin === "oasis";

  const convertToDate = date => {
    const { year, month, day } = formatDate(date, "OBJECT");

    return new Date(year, month, day, 0, 0, 0, 0);
  };

  const { maxDate, minDate } = useMemo(() => {
    let maxDate = new Date();
    let minDate = new Date();

    if (requestChangeData?.effective_date_range?.end_date) {
      maxDate = convertToDate(requestChangeData.effective_date_range.end_date);
    }

    if (requestChangeData?.effective_date_range?.start_date) {
      minDate = convertToDate(
        requestChangeData.effective_date_range.start_date
      );
    }

    return {
      maxDate,
      minDate
    };
  }, [requestChangeData?.effective_date_range]);

  const driverChangeCardClick = () => {
    const changeTypeKey = CHANGE_REQUEST_TYPES["driver-change"].key;

    setChangeTypesLoading(POLICY_LOADING_TYPE.LOADING);
    const effective_date = formatDate(values.effectiveDate, "YYYY-MM-DD");

    createPolicyDraft(
      policyInfo.policyNumber,
      changeTypeKey,
      effective_date
    ).then(
      response => {
        if (!response) {
          setChangeTypesLoading(POLICY_LOADING_TYPE.ERROR);
        } else {
          setChangeTypesLoading(POLICY_LOADING_TYPE.COMPLETE);
          const policyChangeUrl = mapPolicyChangeUrl(
            policyInfo.policyNumber,
            response.job_id,
            POLICY_WIZARD_STEPS.ONE
          );
          history.push(policyChangeUrl);
        }
      },
      () => {
        setChangeTypesLoading(POLICY_LOADING_TYPE.ERROR);
      }
    );
  };

  const handleChangeTypeClick = e => {
    const id = e.currentTarget.getAttribute("id");
    const prefix = "change-type-";

    switch (id) {
      case `${prefix}${CHANGE_REQUEST_TYPES.other.key}`:
        activateOtherChangesModal();
        break;
      case `${prefix}${CHANGE_REQUEST_TYPES["driver-change"].key}`:
        driverChangeCardClick();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {!isGuidewirePolicy && (
        <LegacyPolicyChangesForm
          policyInfo={policyInfo}
          requestChangeData={requestChangeData}
          commercial_underwriter={commercial_underwriter}
          agentCode={agentCode}
          activeAgencyCode={selectedAgencyCode}
          processChange={processChange}
          activateOtherChangesModal={activateOtherChangesModal}
          maxDate={maxDate}
          minDate={minDate}
          pristine={pristine}
          submitting={submitting}
          invalid={invalid}
        />
      )}

      {isGuidewirePolicy && (
        <>
          {!is109015Agent && isCurrentPolicyInForce && changeTypes.length > 0 && (
            <>
              <div>
                <h3>Start a Change Request:</h3>
              </div>

              {!driverChangesPhase2 && (
                <Alert
                  className="driver-changes-alert"
                  type="warning"
                  dismissible={false}
                >
                  <b>Note:</b> If your Driver Change includes drivers younger
                  than 21 or older than 70, please submit via Other Change for
                  underwriter review.
                </Alert>
              )}

              <Form className="" context={form}>
                <FormGroup>
                  <DatePicker
                    id="effectiveDate"
                    name="effectiveDate"
                    label={changeRequests.effective_date}
                    onChange={handleEffectiveDateOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.effectiveDate}
                    format="MM/DD/YYYY"
                    required
                    className="effective-date"
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </FormGroup>
              </Form>

              <FlexRow align="left">
                <div>
                  <label htmlFor="selectTypeOfChange" className="uikit__label">
                    Select Type of Change
                  </label>
                  <span className="uikit__required-asterisk__container">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="asterisk"
                      className="svg-inline--fa fa-asterisk fa-1x uikit__required-asterisk"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M479.31 357.216L303.999 256l175.31-101.215c5.74-3.314 7.706-10.653 4.392-16.392l-12-20.785c-3.314-5.74-10.653-7.706-16.392-4.392L280 214.431V12c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v202.431L56.69 113.215c-5.74-3.314-13.079-1.347-16.392 4.392l-12 20.785c-3.314 5.74-1.347 13.079 4.392 16.392L208 256 32.69 357.216c-5.74 3.314-7.706 10.653-4.392 16.392l12 20.784c3.314 5.739 10.653 7.706 16.392 4.392L232 297.569V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V297.569l175.31 101.215c5.74 3.314 13.078 1.347 16.392-4.392l12-20.784c3.314-5.739 1.347-13.079-4.392-16.392z"
                      />
                    </svg>
                    <span className="uikit-sr-only">required</span>
                  </span>
                </div>
              </FlexRow>

              <FlexRow id="change-request-buttons" align="left">
                {changeTypes.map(type => {
                  const changeType = CHANGE_REQUEST_TYPES[type];

                  return (
                    <Button
                      className="card-button change-type-button"
                      disabled={disableChangeCard}
                      key={type}
                      id={`change-type-${type}`}
                      onClick={e => {
                        handleChangeTypeClick(e);
                        setSelectedChangeType(type);
                      }}
                    >
                      <span className="card-button__title">
                        {changeType?.title ?? ""}
                      </span>{" "}
                      <span className="card-button__description">
                        {changeType?.description ?? ""}
                      </span>
                    </Button>
                  );
                })}
              </FlexRow>
            </>
          )}

          {changeTypesLoading === POLICY_LOADING_TYPE.LOADING && (
            <LoadingIndicator />
          )}
          {changeTypesLoading === POLICY_LOADING_TYPE.ERROR && (
            <FlexRow>
              <span id="policyChangeLoadingError">
                <HelpDeskMessage display="anErrorOccurred" />
              </span>
            </FlexRow>
          )}
        </>
      )}
      {selectedChangeType === "other" && (
        <OtherChanges
          policyInfo={policyInfo}
          effectiveDate={values.effectiveDate}
          minDate={minDate}
          maxDate={maxDate}
          updateParentEffectDate={handleEffectiveDateOnChange}
        />
      )}
    </>
  );
};

PolicyChangesForm.propTypes = {
  requestChangeData: PropTypes.object,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  policyInfo: PropTypes.object.isRequired,
  processChange: PropTypes.func.isRequired,
  commercial_underwriter: PropTypes.string,
  agentCode: PropTypes.string,
  selectedAgencyCode: PropTypes.string.isRequired,
  activateOtherChangesModal: PropTypes.func.isRequired,
  createPolicyDraft: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool.isRequired,
  isCurrentPolicyInForce: PropTypes.bool.isRequired
};

export default withLDConsumer()(PolicyChangesForm);
