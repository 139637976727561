import React, { createContext, useReducer } from "react";
import * as types from "./actionTypes";

const initialState = {
  navigation: {
    loading: false,
    data: [],
    error: undefined
  },
  content: {
    loading: false,
    data: undefined,
    error: undefined
  }
};

const SuretyAgentManualsStore = createContext(initialState);

const SuretyAgentManualsProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state = initialState, action) => {
    switch (action.type) {

      //NAVIGATION ACTIONS

      case types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_STARTED: {
        return {
          ...state,
          navigation: {
            ...initialState.navigation,
            loading: true
          }
        };
      }
      case types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_SUCCEEDED: {
        return {
          ...state,
          navigation: {
            ...initialState.navigation,
            data: action?.payload
          }
        };
      }
      case types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_FAILED: {
        return {
          ...state,
          navigation: {
            ...initialState.content,
            error: action.payload?.response?.data
          }
        };
      }

      //CONTENT ACTIONS

      case types.FETCH_SURETY_AGENT_MANUALS_CONTENT_STARTED: {
        return {
          ...state,
          content: {
            ...initialState.content,
            loading: true
          }
        };
      }
      case types.FETCH_SURETY_AGENT_MANUALS_CONTENT_SUCCEEDED: {
        return {
          ...state,
          content: {
            ...initialState.content,
            data: action.payload
          }
        };
      }
      case types.FETCH_SURETY_AGENT_MANUALS_CONTENT_FAILED: {
        return {
          ...state,
          content: {
            ...initialState.content,
              error: action.payload?.response?.data
          }
        };
      }
      default:
        return initialState;
    }
  }, initialState);
  return <SuretyAgentManualsStore.Provider value={{ state, dispatch }}>
    {children}
  </SuretyAgentManualsStore.Provider>
};

export { SuretyAgentManualsStore, SuretyAgentManualsProvider };
