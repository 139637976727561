import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import { Translations } from "../../../../components/translations";
import WeeklyPaymentList from "./WeeklyPaymentList";

const WeeklyPayment = ({
  error,
  fetchWeeklyPaymentListDetails,
  fetchWeeklyPaymentViewReport,
  isLoading,
  selectedAgencyCode,
  weeklyPaymentList
}) => {
  useEffect(() => {
    fetchWeeklyPaymentListDetails(selectedAgencyCode);
  }, [fetchWeeklyPaymentListDetails, selectedAgencyCode]);

  return (
    <div className="claims-year-weekly-container">
      {error == null || error ? (
        Translations.reports.tabClaims.weeklyPaymentNoReport
      ) : (
        <div>
          <div>{Translations.reports.tabClaims.weeklyReportSummary}</div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <WeeklyPaymentList
              weeklyPaymentList={weeklyPaymentList}
              selectedAgencyCode={selectedAgencyCode}
              fetchWeeklyPaymentViewReport={fetchWeeklyPaymentViewReport}
            />
          )}
        </div>
      )}
    </div>
  );
};

WeeklyPayment.propTypes = {
  error: PropTypes.any,
  fetchWeeklyPaymentListDetails: PropTypes.func.isRequired,
  fetchWeeklyPaymentViewReport: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  weeklyPaymentList: PropTypes.array
};

export default WeeklyPayment;
