import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dropdown,
  FlexRow,
  Form,
  FormGroup,
  Icon,
  Input,
  Popover,
  TextArea,
  useForm
} from "@ufginsurance/ui-kit";
import Address from "../../quick-program/quick-quoting/shared/Address";
import "./JobSiteInfoForm.scss";
import {
  CONSTRUCTION_TYPES,
  RATE_RULES,
  PROJECT_LIMITS
} from "./lib/constants";
import { getProjectLimitError, getProtectionClasses } from "./lib/utils";
import useGetLocationRatingData from "./hooks/useGetLocationRatingData";

export default function JobSiteInfoForm({
  jobSite,
  jobSites,
  jobsiteIndex,
  saveJobSite,
  setShowJobSiteModal,
  licensedStates
}) {
  const isFirstJobSite = !jobSites?.length || jobsiteIndex === 0;

  const form = useForm({
    values: {
      ...jobSite,
      ...(!isFirstJobSite && { deductible: jobSites[0].deductible })
    },
    onSubmit: () => {}
  });
  const {
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    values,
    errors,
    updateForm,
    invalidFields
  } = form;

  const [constructionTypeOptions, setConstructionTypeOptions] = useState(
    CONSTRUCTION_TYPES.map(t => ({
      value: t,
      label: t
    }))
  );

  const [protectionClasses, setProtectionClasses] = useState(
    getProtectionClasses(values?.protection_class)
  );

  const [address, setAddress] = useState({});

  useEffect(() => {
    if (values.state) {
      setConstructionTypeOptions(getConstructionTypes(values.state));
    }
  }, [values.state]);

  const addressChangeTimeoutId = useRef(null);

  useEffect(() => {
    clearTimeout(addressChangeTimeoutId.current);
    addressChangeTimeoutId.current = setTimeout(() => {
      setAddress({
        street_address: values?.street_address_or_description || "",
        zip: values?.zip || "",
        city: values?.city || "",
        state: values?.state || ""
      });
    }, 1000);
  }, [
    values.street_address_or_description,
    values.zip,
    values.city,
    values.state,
    setAddress
  ]);

  const [locationRatingData, locationRatingDataIsLoading] =
    useGetLocationRatingData(address);

  useEffect(() => {
    const protectionClass = locationRatingData?.suggested_protection_class ?? 0;
    if (values.protection_class === protectionClass) return;
    updateForm({
      values: {
        ...values,
        protection_class: protectionClass
      },
      errors: {
        ...errors,
        //we're programmatically setting the value, so clear out any errors
        protection_class: []
      }
    });
    // eslint-disable-next-line
  }, [locationRatingData]);

  useEffect(() => {
    const protectionClassErrors = (errors?.protection_class || []).filter(
      _ => !_.includes("Protection class of")
    );
    if (
      (values.protection_class || "").startsWith("9") ||
      (values.protection_class || "").startsWith("10")
    )
      protectionClassErrors.push(
        `Protection class of ${values.protection_class} is not allowed online. Please refer this risk to your underwriter.`
      );
    setProtectionClasses(getProtectionClasses(values.protection_class));
    updateForm({
      errors: {
        ...errors,
        protection_class: [...protectionClassErrors]
      }
    });
    // eslint-disable-next-line
  }, [values.protection_class]);

  const handleOnContinue = () => {
    setShowJobSiteModal(false);
    saveJobSite(values);
  };

  const getConstructionTypes = state => {
    const options = [];
    if (!!state) {
      for (const constructionType of CONSTRUCTION_TYPES) {
        if (RATE_RULES.constructionType[constructionType][state])
          options.push({ value: constructionType, label: constructionType });
      }
    }
    return options;
  };

  // TODO: move this to utils so i can use it on InsuredInfoForm.js
  const handleZipChange = (event, localeData) => {
    const locale = Array.isArray(localeData) ? localeData[0] : localeData;

    const constructionTypes = getConstructionTypes(locale?.state);
    setConstructionTypeOptions(constructionTypes);

    const zipErrors = [];
    if (!!locale?.state) {
      if (
        constructionTypes.length === 0 ||
        RATE_RULES.blockedStates.includes(locale.state)
      )
        zipErrors.push(
          "The state you have selected is not yet available for the Builders' Risk portal. Please contact your underwriter with questions."
        );
      else if (!licensedStates.some(_ => _ === locale?.state))
        zipErrors.push(
          "You are attempting to quote business in a state in which we do not have a license on file for. Please contact your UFG marketing representative for licensing procedures."
        );
    }

    updateForm({
      values: {
        ...values,
        zip: event.value,
        city: locale?.city,
        state: locale?.state,
        construction_type: "",
        county: locale?.county || ""
      },
      errors: {
        ...errors,
        zip: [...zipErrors]
      }
    });
  };

  const handleBuildingLimitOnValidate = field => {
    const errors = handleOnValidate(field);
    const maxLimit = PROJECT_LIMITS[values.construction_type];
    const val = Number(field.value.replace(/\$/g, "")); // Strip mask.

    if (val > maxLimit) {
      errors.push(getProjectLimitError(maxLimit, values.construction_type));
    }

    return errors;
  };

  const handleConstructionTypeOnChange = ({ field, value }) => {
    const constructionTypeLimit = PROJECT_LIMITS[value];
    const isOverProjectLimit = values?.building_limit > constructionTypeLimit;

    updateForm({
      values: {
        ...values,
        [field]: value
      },
      errors: {
        ...errors,
        [field]: [],
        building_limit: isOverProjectLimit
          ? [getProjectLimitError(constructionTypeLimit, value)]
          : []
      }
    });
  };

  return (
    <div>
      <Form context={form}>
        <FormGroup>
          <Input
            id="street_address_or_description"
            name="street_address_or_description"
            label="Street Address or Description"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.street_address_or_description}
            minLength={3}
            maxLength={55}
            size="fill"
            required
          />
        </FormGroup>
        <Address
          form={form}
          disableProjectCity
          disableProjectState
          size="fill"
          customZipChangeHandler={handleZipChange}
        />
        <FormGroup>
          <TextArea
            id="project_description"
            name="project_description"
            label="Tell us about the project and intended occupancy"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.project_description}
            requiredError="This is a required field."
            required
          />
        </FormGroup>
        <FormGroup>
          {/* TODO: Should be prefilled after being returned from webservice upon entry of address. For now, allowing user to select*/}
          <Dropdown
            id="protection_class"
            name="protection_class"
            label="Protection Class"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            options={protectionClasses}
            value={values.protection_class}
            size="50p"
            required
            isClearable={false}
            disabled={
              !!locationRatingData?.suggested_protection_class ||
              !!locationRatingDataIsLoading
            }
            isLoading={!!locationRatingDataIsLoading}
          />
          <Dropdown
            id="project_type"
            name="project_type"
            label="Project Type"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            options={[
              { value: "Commercial", label: "Commercial" },
              { value: "Residential", label: "Residential" }
            ]}
            value={values.project_type}
            size="fill"
            required
            isClearable={false}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            id="construction_type"
            name="construction_type"
            label="Construction Type"
            onChange={handleConstructionTypeOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            options={constructionTypeOptions}
            value={values.construction_type}
            size="50p"
            required
            isClearable={false}
            disabled={!constructionTypeOptions?.length}
          />
          <Input
            id="number_of_stories"
            name="number_of_stories"
            label="Number of Stories"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.number_of_stories}
            size="50p"
            numbersOnly
            required
            mask="numberCommas"
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="building_limit"
            name="building_limit"
            label="Building Limit"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleBuildingLimitOnValidate}
            value={values.building_limit}
            size="50p"
            numbersOnly
            required
            mask="currency"
          />
          <Dropdown
            id="deductible"
            name="deductible"
            label={
              isFirstJobSite ? (
                "Deductible"
              ) : (
                <>
                  <span>Deductible </span>
                  <Popover
                    id="job-site-deductible-locked"
                    trigger="hover"
                    triggerContent={<Icon icon="fasInfoCircle" />}
                    popoverContent="Deductible is set at the policy level. You can manage the deductible on the next screen."
                  />
                </>
              )
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            options={[
              { value: "1000", label: "$1,000" },
              { value: "2500", label: "$2,500" },
              { value: "5000", label: "$5,000" },
              { value: "10000", label: "$10,000" }
            ]}
            value={String(values.deductible)}
            size="50p"
            isClearable={false}
            required={isFirstJobSite}
            disabled={!isFirstJobSite}
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="soft_cost_limit"
            name="soft_cost_limit"
            label="Soft Cost Limit"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.soft_cost_limit}
            size="50p"
            numbersOnly
            mask="currency"
          />
        </FormGroup>
        <FlexRow align="right">
          <Button onClick={() => setShowJobSiteModal(false)}>Cancel</Button>
          <Button
            onClick={() => handleOnContinue()}
            variant="primary"
            disabled={!!Object.keys(errors)?.length || !!invalidFields?.length}
          >
            Continue
          </Button>
        </FlexRow>
      </Form>
    </div>
  );
}
