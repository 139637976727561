import { connect } from "react-redux";
import ClaimsClaimants from "../components/ClaimsClaimants";
import { fetchClaimsClaimants } from "../actions/claimsActions";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = (state, props) => ({
  claimants: state.claims.claimants[props.claimNumber] || [],
  selectedAgencyCode: getSelectedAgencyCode(state),
  employeeUserName: sessionSelector.getEmployeeUserName(state)
});

const mapDispatchToProps = {
  fetchClaimsClaimants
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsClaimants);
