import React, { Suspense } from "react";
import { FlexRow, LoadingIndicator } from "@ufginsurance/ui-kit";
import useDynamicScript from "./useDynamicScript";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

function loadComponent(scope, module) {
  return async () => {
    // eslint-disable-next-line no-undef
    await __webpack_init_sharing__("default");

    const container = window[scope];
    // eslint-disable-next-line no-undef
    await container.init(__webpack_share_scopes__.default);
    const newModule =
      module === "." || module.startsWith("./") ? module : `./${module}`;
    const factory = await window[scope].get(newModule);
    const Module = factory();
    return Module;
  };
}

const ReactAdapterConsumer = props => {
  const { scope, module, url } = props;

  const { ready, failed } = useDynamicScript({ url });

  const Component = React.lazy(loadComponent(scope, module));

  return (
    <Suspense
      fallback={<LoadingIndicator message="Loading" type="bar" color="grey" />}
    >
      {failed && (
        <FlexRow>
          <div className="no-results-info">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </FlexRow>
      )}

      {ready && <Component {...props} />}
    </Suspense>
  );
};

export default ReactAdapterConsumer;
