import { connect } from "react-redux";
import TopActionCard from "./topActionCard";
import { activateMakeAPayment } from "./topActionCardAction";
import { startReportAClaim } from "./report-claim/actions/reportClaimActions";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

const mapDispatchToProps = {
  activateMakeAPayment,
  startReportAClaim
};

export default connect(mapStateToProps, mapDispatchToProps)(TopActionCard);
