import qs from "query-string";
import API from "../api";

export const getCurrentSummary = (
  policyNumber,
  agencyCode,
  effectiveDate,
  companyBranch
) => {
  const queryString = qs.stringify({
    agency_code: agencyCode,
    effective_date: effectiveDate.split("T")[0],
    company_branch:
      companyBranch.length > 2 ? companyBranch : "01" + companyBranch
  });
  return API.agent().get(
    `/policies/${policyNumber}/current-summary?${queryString}`
  );
};

export const getOasisDocumentSummary = (policyNumber, policyTerm) => {
  const queryString = qs.stringify({ policy_term: policyTerm.toLowerCase() });
  return API.agent().get(
    `/policies/${policyNumber}/current-summary-documents?${queryString}`
  );
};

export const getUFGDocumentSummary = (
  agencyCode,
  policyType,
  declarationCode,
  policy,
  policyTerm
) => {
  const queryString = qs.stringify({
    declaration_code: declarationCode,
    policy_type: policyType,
    agency_code: agencyCode,
    policy_term: policyTerm.toLocaleLowerCase(),
    effective_date: policy.effective_date.split("T")[0],
    company_branch: policy.company_branch
  });
  return API.agent().get(
    `/policies/${policy.policy_number}/current-line-of-business-documents?${queryString}`
  );
};

export const selectDocumentsToView = data => {
  return API.documents().post(`documents`, data, {
    responseType: "blob",
    timeout: 300000
  });
};

export const selectGWDocumentsToView = (
  document_id,
  policy_number,
  policy_type
) => {
  const queryString = qs.stringify({ policy_number, policy_type });
  return API.documents().get(`documents/${document_id}?${queryString}`, {
    responseType: "blob",
    timeout: 300000
  });
};
