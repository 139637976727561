import React, { useState, useCallback } from "react";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import BuildingFormStep1 from "./BuildingFormStep1.js";
import BuildingFormCoveragesStep2 from "./BuildingFormCoveragesStep2.js";
import OqModal from "../../shared/OqModal.js";
import cn from "classnames";
import BadBuildingModal from "./BadBuildingModal.js";

const BuildingModalWizardTitles = [
  { step: 1, title: " Building Details" },
  { step: 2, title: " Building Coverages" }
];

const BuildingModalWizard = ({
  show,
  onHide,
  itemData,
  locations,
  coverageExclusions,
  mode,
  defaultLocation
}) => {
  const setTitle = useCallback(step => {
    const item = BuildingModalWizardTitles.find(d => d.step === step);
    if (item) {
      return setCurrentTitle(item.title);
    }
    return setCurrentTitle("");
  }, []);

  const [currentStep, setStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState(
    BuildingModalWizardTitles[0].title
  );
  const [scheduleItemOpenInCoverable, setScheduleItemOpenInCoverable] =
    useState();

  const [thisItem, setThisItem] = useState(itemData);

  const totalSteps = 2;

  const onNextStep = useCallback(
    building => {
      if (currentStep + 1 <= totalSteps) {
        const st = currentStep + 1;
        setTitle(st);
        setStep(st);
      } else if (currentStep + 1 > totalSteps) {
        onHide();
      }
      if (building) setThisItem(building);
    },
    [currentStep, onHide, setTitle]
  );

  const onPrevStep = useCallback(() => {
    if (currentStep - 1 > 0) {
      const st = currentStep - 1;
      setTitle(st);
      setStep(st);
    }
  }, [currentStep, setTitle]);

  const renderStepForm = useCallback(
    currentStep => {
      switch (currentStep) {
        case 1:
          return (
            <BuildingFormStep1
              itemData={thisItem}
              onNextStep={onNextStep}
              locations={locations}
              onCancel={onHide}
              onPrevStep={onPrevStep}
              mode={mode}
              defaultLocation={defaultLocation}
            />
          );

        case 2:
          if (thisItem?.fixedId) {
            return (
              <BuildingFormCoveragesStep2
                key={currentStep}
                building={thisItem}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
                onCancel={onHide}
                coverageExclusions={coverageExclusions}
                setScheduleItemOpenInCoverable={setScheduleItemOpenInCoverable}
                locations={locations}
              />
            );
          }
          return <LoadingIndicator />;
        default:
          onHide(); //if you are here closes the dialog.
      }
    },
    [
      thisItem,
      onNextStep,
      locations,
      onHide,
      onPrevStep,
      mode,
      defaultLocation,
      coverageExclusions
    ]
  );

  return (
    <OqModal
      className={cn("oq__modal__coverable oq__modal__coverable__building", {
        scheduleItemOpenInCoverable
      })}
      title={`Step ${currentStep} of ${totalSteps}: ${currentTitle}`}
      show={show}
      onHide={onHide}
      body={
        <div>
          {renderStepForm(currentStep)}
          <BadBuildingModal building={thisItem} onCancel={onHide} />
        </div>
      }
      closeIcon={false}
      overlayHidden={scheduleItemOpenInCoverable}
    />
  );
};

export default BuildingModalWizard;
