import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import {
  ContentHeader,
  Tabs,
  Tab,
  Panel,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import Accounting from "../components/accounting/Accounting";
import Underwriting from "./underwriting/Underwriting";
import Agency from "../components/agency/Agency";
import Claims from "../components/claims/Claims";
import * as routes from "./../../constants/routes";
import useGetAgencyInfo from "../../shared/hooks/useGetAgencyInfo";

const ReportsHome = ({
  is109015Agent,
  hasAgencyAccountingReportsAccess,
  hasAgencyProductionReportsAccess,
  hasAgencyClaimReportsAccess,
  hasServiceCenterAccess,
  selectedAgencyCode,
  has_personal_lines_contract,
  match,
  params,
  history,
  isAgencyAdministrator
}) => {
  const [agencyAdmin, setAgencyAdmin] = useState();

  const [isLoadingAgencyData] = useGetAgencyInfo(
    selectedAgencyCode,
    async agencyData => {
      setAgencyAdmin(agencyData?.agency_contacts?.administrator);
    }
  );

  const getActiveTabIndex = () => {
    const { params } = match;
    return (
      (params.active_tab &&
        Object.keys(routes.REPORTS_ACTIVE_TABS).findIndex(
          o => o === params.active_tab
        )) ||
      0
    );
  };

  const onTabClick = tabIndex => {
    const reportsBasePath = `${routes.REPORTS}`;
    const current_tab = Object.keys(routes.REPORTS_ACTIVE_TABS)[tabIndex];
    const child_tab_route = routes.REPORTS_ACTIVE_TABS[current_tab][0];
    history.push(`${reportsBasePath}/${current_tab}/${child_tab_route}`);
  };

  const activeTabIndex = getActiveTabIndex();
  return (
    <div className="reports-container">
      <ContentHeader>{Translations.navigation.report}</ContentHeader>
      <p>{Translations.reports.user_note}</p>
      <p>{is109015Agent && Translations.reports.internal_user}</p>
      {!isAgencyAdministrator && (
        <Panel noBorder bgcolor="grey">
          {isLoadingAgencyData ? (
            <LoadingIndicator />
          ) : (
            <>
              <p>
                <b>
                  Please contact your agency administrator if you do not have
                  access to a report.
                </b>
              </p>
              <p>
                <b>Agency Administrator:</b> {agencyAdmin?.name || ""}
              </p>
              <p style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                <b>Email:</b>{" "}
                <a href={`mailto:${agencyAdmin?.email}`}>
                  {agencyAdmin?.email || ""}
                </a>
              </p>
            </>
          )}
        </Panel>
      )}
      <div>
        <Tabs activeTab={activeTabIndex}>
          <Tab title={Translations.reports.Accounting} onClick={onTabClick}>
            <Accounting
              is109015Agent={is109015Agent}
              hasAgencyAccountingReportsAccess={
                hasAgencyAccountingReportsAccess
              }
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
              hasAgencyClaimReportsAccess={hasAgencyClaimReportsAccess}
              hasServiceCenterAccess={hasServiceCenterAccess}
              selectedAgencyCode={selectedAgencyCode}
              has_personal_lines_contract={has_personal_lines_contract}
              match={match}
              params={params}
              history={history}
            />
          </Tab>
          <Tab title={Translations.reports.Agency} onClick={onTabClick}>
            <Agency
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
              hasServiceCenterAccess={hasServiceCenterAccess}
              is109015Agent={is109015Agent}
            />
          </Tab>
          <Tab title={Translations.reports.Claims} onClick={onTabClick}>
            <Claims
              is109015Agent={is109015Agent}
              hasAgencyAccountingReportsAccess={
                hasAgencyAccountingReportsAccess
              }
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
              hasAgencyClaimReportsAccess={hasAgencyClaimReportsAccess}
              hasServiceCenterAccess={hasServiceCenterAccess}
              selectedAgencyCode={selectedAgencyCode}
              has_personal_lines_contract={has_personal_lines_contract}
              match={match}
              params={params}
              history={history}
            />
          </Tab>
          <Tab title={Translations.reports.Underwriting} onClick={onTabClick}>
            <Underwriting
              is109015Agent={is109015Agent}
              hasAgencyAccountingReportsAccess={
                hasAgencyAccountingReportsAccess
              }
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
              hasAgencyClaimReportsAccess={hasAgencyClaimReportsAccess}
              hasServiceCenterAccess={hasServiceCenterAccess}
              selectedAgencyCode={selectedAgencyCode}
              has_personal_lines_contract={has_personal_lines_contract}
              match={match}
              params={params}
              history={history}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

ReportsHome.propTypes = {
  is109015Agent: PropTypes.bool.isRequired,
  hasAgencyAccountingReportsAccess: PropTypes.bool.isRequired,
  hasAgencyProductionReportsAccess: PropTypes.bool.isRequired,
  hasAgencyClaimReportsAccess: PropTypes.bool.isRequired,
  hasServiceCenterAccess: PropTypes.bool.isRequired,
  selectedAgencyCode: PropTypes.string.isRequired,
  has_personal_lines_contract: PropTypes.bool.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  history: PropTypes.object,
  isAgencyAdministrator: PropTypes.bool.isRequired
};

export default ReportsHome;
