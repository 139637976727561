import React from "react";
import OqModal from "../shared/OqModal";
import { Button } from "@ufginsurance/ui-kit";

const QUOTE_ERRORS = {
  "AOR error": (
    <div>
      <p>We’re sorry but this account is reserved by another agency. </p>
      <p>
        If you feel this is an error please reach out to your underwriter with a
        signed AOR.
      </p>
    </div>
  )
};

const CreateQuoteErrorModal = ({ error, onHide }) => {
  if (!QUOTE_ERRORS[error]) return null;

  return (
    <OqModal
      className="oq__modal__aor"
      title="Unable to continue quote"
      show
      closeIcon={false}
      onHide={onHide}
      body={QUOTE_ERRORS[error]}
      footer={<Button onClick={onHide}>Close</Button>}
    />
  );
};

export default CreateQuoteErrorModal;
