import * as constants from "./constants";

export const getSectionByHierarchyKey = (section, hierarchyKey) => {
  if (Number(section?.hierarchy_key) === Number(hierarchyKey))
    return section;
  for (const child of (section?.children || [])) {
    const result = getSectionByHierarchyKey(child, hierarchyKey);
    if (result != null)
      return result;
  }
  return null;
};

export const getContractSection = (root) =>
  getSectionByHierarchyKey(root, constants.CMS.CONTRACT_NAVIGATION_ID);

export const getCommercialSection = (root) =>
  getSectionByHierarchyKey(root, constants.CMS.COMMERCIAL_NAVIGATION_ID);

export const isCommercialSubsection = (section, root) =>
  (getCommercialSection(root)?.children || []).includes(section);

export const isContractSubsection = (section, root) =>
  (getContractSection(root)?.children || []).includes(section);

export const getSubsectionTitle = (section, root) =>
  isCommercialSubsection(section, root) ? "Commercial" : "Contract";

export const getParentSectionFor = (section, root) =>
  isContractSubsection(section, root)
    ? getContractSection(root)
    : isCommercialSubsection(section, root)
      ? getCommercialSection(root)
      : null;

export const isLandingPage = (contentId) =>
  constants.CMS.DEFAULT_CONTENT_ID === Number(contentId);

export const isPanel = (section) =>
  section?.hierarchy_type?.toLowerCase() === "header"
  && section?.web_description?.toLowerCase() !== "introduction";

export const isHeader = (section) =>
  section?.hierarchy_type.toLowerCase() === "header";

export const isLink = (section) =>
  section?.text_flag === true;

export const getSectionLinksFor = (items, section, contentId) => {
  let activeItems = [];
  if (section) {
    let insideActiveItems = false;
    for (const item of (items || [])) {
      if (insideActiveItems && item?.hierarchy_type?.toLowerCase() === "header")
        insideActiveItems = false;
      if (item?.hierarchy_key === section?.hierarchy_key)
        insideActiveItems = true;
      if (!insideActiveItems)
        continue;
      if (item?.text_flag)
        activeItems.push(item);
    }
  } else
    activeItems = items;
  return (activeItems || [])
    .filter(_ => _?.web_description?.toLowerCase() !== "introduction")
    .map(_ => ({ ..._, enabled: _?.hierarchy_key !== contentId }))
};
