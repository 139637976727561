import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Currency,
  Table,
  useForm,
  Form,
  FormGroup,
  FormatDate,
  Input,
  Alert
} from "@ufginsurance/ui-kit";
import { withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";
import { Translations } from "../../components/translations";
import HelpText from "./../../components/account/billing-details/InfoIconPopover";
import * as completePaymentLegacySelector from "./completePaymentLegacySelector";

const calculateTotalPaymentAmount = formValues => {
  const notAllowed = ["totalAmount"];
  const filteredFormValues = Object.keys(formValues)
    .filter(key => !notAllowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = formValues[key];
      return obj;
    }, {});
  return Object.keys(filteredFormValues)
    .reduce((total, key) => {
      const value =
        Number(filteredFormValues[key]) &&
        parseFloat(Number(filteredFormValues[key]).toFixed(2));
      if (value) {
        total += value;
      } else {
        total += 0;
      }
      return total;
    }, 0)
    .toString();
};

const CompletePaymentLegacyForm = ({
  accountNumber,
  accountBilledTableData,
  policyBilledTableData,
  history,
  copyAgencySweepPaymentRequestDetails
}) => {
  const {
    Legacy_Payment_Form
  } = Translations.Billing_Details.Account_Billing_Activity.Agency_Sweep;
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [errors, setErrors] = useState(null);
  const getPolicyBilledFormValues = () => ({});
  const initialValues = {
    [accountNumber]: "",
    ...getPolicyBilledFormValues()
  };
  const form = useForm({
    values: {
      ...initialValues,
      totalAmount: "0.00"
    },
    errors: []
  });
  const { handleOnChange, handleOnValidate, updateForm } = form;

  const onSubmit = e => {
    e.preventDefault();
  };

  const handleContinue = () => {
    const paymentData = completePaymentLegacySelector.createVerifyPaymentDetails(
      form.values,
      accountBilledTableData,
      policyBilledTableData
    );
    copyAgencySweepPaymentRequestDetails(paymentData);
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}${routes.VERIFY_AGENCY_SWEEP}`
    );
  };

  const handleAccountOverview = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    );
  };

  const handleValidatePaymentAmount = (min, max) => ({
    field,
    value,
    validation
  }) => {
    value = value.replace("$", "");
    if (value === "") return [];
    const fieldErrors = handleOnValidate({ field, value, validation });
    const currencyNumberRegex = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    const isCurrencyFormat = currencyNumberRegex.test(value);
    min = Number(min) && parseFloat(Number(min).toFixed(2));
    max = Number(max) && parseFloat(Number(max).toFixed(2));
    value = Number(value) && parseFloat(Number(value).toFixed(2));

    if (!isCurrencyFormat || (value !== 0 && !value)) {
      fieldErrors.push(Legacy_Payment_Form.Payment_Invalid);
      setContinueButtonDisabled(true);
    } else if (value < min) {
      fieldErrors.push(Legacy_Payment_Form.Payment_Minimum);
      setContinueButtonDisabled(true);
    } else if (value > max) {
      fieldErrors.push(Legacy_Payment_Form.Payment_Maximum);
      setContinueButtonDisabled(true);
    } else {
      setContinueButtonDisabled(false);
    }
    setErrors(fieldErrors);
    form.values.totalAmount = calculateTotalPaymentAmount(form.values);
    return fieldErrors;
  };

  const handleOnBlur = () => {
    form.values.totalAmount = calculateTotalPaymentAmount(form.values);
    updateForm(form);
  };

  const handleChangeValue = ({ event, field, value }) => {
    event.target.value = value = value.replace("$", "");
    if (form.values && field) {
      form.values[field] = event.target.value;
    }
    form.values.totalAmount = calculateTotalPaymentAmount(form.values);
    handleOnChange({ event, field, value });
  };

  const accountTableColumns = [
    {
      key: "account_number",
      label: Legacy_Payment_Form.Account_Policy_Column_Label,
      element: row =>
        row.rowType === "account" ? row.account_number : row.policy_number
    },
    {
      key: "insured",
      label: Legacy_Payment_Form.Insured_Column_Label,
      className: "agency-sweep-insured-column capitalize",
      element: row =>
        row.rowType === "account"
          ? row.insured
          : row.policy_type && row.policy_type.toLowerCase()
    },
    {
      key: "expiration_date",
      label: Legacy_Payment_Form.Expiration_Date_Column_Label,
      element: row =>
        row.expiration_date && <FormatDate date={row.expiration_date} />
    },
    {
      key: "min_due",
      label: Legacy_Payment_Form.Minimum_Due_Column_Label,
      className: "agency-sweep-min-due-column",
      element: row => {
        const firstRow =
          accountBilledTableData &&
          accountBilledTableData.length > 0 &&
          accountBilledTableData[0];
        return (
          <span>
            <Currency amount={row.min_due} />{" "}
            {firstRow && firstRow.rowId === row.rowId && (
              <HelpText
                content={Legacy_Payment_Form.Minimum_Due_Service_Charge_Tooltip}
              />
            )}
          </span>
        );
      }
    },
    {
      key: "balance",
      label: Legacy_Payment_Form.Balance_Column_Label,
      element: row => <Currency amount={row.balance} />
    },
    {
      key: "input",
      label: Legacy_Payment_Form.Payment_Amount_Column_Label,
      className: "agency-sweep-pay-amount-column",
      element: row =>
        row.balance &&
        !row.expiration_date && (
          <Input
            noLabel
            id={row.account_number}
            name={row.account_number}
            label={row.account_number}
            className="payment-amount-input-legacy"
            onChange={handleChangeValue}
            onBlur={handleOnBlur}
            onValidate={handleValidatePaymentAmount(row.min_due, row.balance)}
            value={
              form.values[row.account_number]
                ? `$${form.values[row.account_number]}`
                : form.values[row.account_number]
            }
          />
        )
    }
  ];

  const policyTableColumns = [
    {
      key: "policy_number",
      label: Legacy_Payment_Form.Account_Policy_Column_Label
    },
    {
      key: "insured_name",
      label: Legacy_Payment_Form.Insured_Column_Label,
      className: "agency-sweep-insured-column"
    },
    {
      key: "expiration_date",
      label: Legacy_Payment_Form.Expiration_Date_Column_Label,
      element: row =>
        row.expiration_date && <FormatDate date={row.expiration_date} />
    },
    {
      key: "min_due",
      label: Legacy_Payment_Form.Minimum_Due_Column_Label,
      element: row => <Currency amount={row.min_due} />
    },
    {
      key: "balance",
      label: Legacy_Payment_Form.Balance_Column_Label,
      element: row => <Currency amount={row.balance} />
    },
    {
      key: "input",
      label: Legacy_Payment_Form.Payment_Amount_Column_Label,
      className: "agency-sweep-pay-amount-column",
      element: row =>
        row.balance && (
          <Input
            noLabel
            id={row.policy_number}
            name={row.policy_number}
            label={row.policy_number}
            onChange={handleChangeValue}
            className="payment-amount-input-legacy"
            onBlur={handleOnBlur}
            onValidate={handleValidatePaymentAmount(row.min_due, row.balance)}
            value={
              form.values && row.policy_number && form.values[row.policy_number]
                ? `$${form.values[row.policy_number]}`
                : ""
            }
          />
        )
    }
  ];

  const hasAccountBilledPolicies =
    accountBilledTableData &&
    accountBilledTableData.length > 0 &&
    accountBilledTableData.filter(row => row.rowType === "policy").length > 0;
  const hasPolicyBilledPolicies =
    policyBilledTableData && policyBilledTableData.length > 0;
  return (
    <Form onSubmit={onSubmit} context={form}>
      <FormGroup wrap={false} className="form-group">
        {errors && errors.length > 0 && (
          <div>
            <Alert type="error">
              {errors.map(error => (
                <div key={error}>{error}</div>
              ))}
            </Alert>
          </div>
        )}
        <div className="row agency-sweep-emphasis">
          {Legacy_Payment_Form.Enter_Payments}
        </div>
        <div className="row">
          {hasAccountBilledPolicies && (
            <div>
              <Table
                className="agency-sweep-account-billed-table"
                data={accountBilledTableData || []}
                columns={accountTableColumns}
                itemsPerPage={20}
                rowKey={"rowId"}
              />
            </div>
          )}
          {hasPolicyBilledPolicies && (
            <div>
              <Table
                className="agency-sweep-policy-billed-table"
                data={policyBilledTableData || []}
                columns={policyTableColumns}
                itemsPerPage={20}
                rowKey={"rowId"}
              />
            </div>
          )}
        </div>
        <div className="row">
          <span className="agency-sweep-emphasis-payment-amount">
            {Legacy_Payment_Form.Total_Payment_Amount}{" "}
            <Currency amount={form.values.totalAmount} />
          </span>
          <div className="row">
            {Legacy_Payment_Form.Total_Payment_Amount_Footer}
          </div>
        </div>
        <Button
          onClick={handleContinue}
          variant="primary"
          disabled={continueButtonDisabled || !Number(form.values.totalAmount)}
        >
          {Legacy_Payment_Form.Continue_Button_Label}
        </Button>
        <Button variant="secondary" onClick={handleAccountOverview}>
          {Legacy_Payment_Form.Back_Button_Label}
        </Button>
      </FormGroup>
    </Form>
  );
};

CompletePaymentLegacyForm.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  accountBilledTableData: PropTypes.array,
  policyBilledTableData: PropTypes.array,
  history: PropTypes.object,
  copyAgencySweepPaymentRequestDetails: PropTypes.func.isRequired
};

export default withRouter(CompletePaymentLegacyForm);
