import * as types from "../uwReviewActionTypes";
import * as UWReviewServices from "../uwReviewServices";
import { logger } from "../../loggers";

/*--------UWREVIEW DECISION INFORMATION SECTION---------- */
const uwReviewDecisionServiceStarted = () => ({
  type: types.UW_REVIEW_DECISION_STARTED
});

const uwReviewDecisionServiceSucceeded = data => ({
  type: types.UW_REVIEW_DECISION_SUCCEEDED,
  payload: data
});

const uwReviewDecisionServiceFailed = error => ({
  type: types.UW_REVIEW_DECISION_FAILED,
  payload: error
});
export const uwReviewDecision = data => dispatch => {
  dispatch(uwReviewDecisionServiceStarted());
  return UWReviewServices.postUWDecision(data).then(
    response => {
      dispatch(uwReviewDecisionServiceSucceeded(response.data));
    },
    error => {
      dispatch(uwReviewDecisionServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
