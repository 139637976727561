import React from "react";
import { Panel, PhoneLink, Anchor } from "@ufginsurance/ui-kit";

const UnderwritingTeam = ({ underwriter }) => {
  if (!underwriter) return null;

  return (
    <Panel className="panel__uwteam" highlight="blue" title="Underwriting Team">
      <ul>
        <li>
          <b>
            {underwriter?.first_name} {underwriter?.last_name}
          </b>
        </li>
        <li>
          Phone:{" "}
          <PhoneLink phoneNumber={underwriter?.phone}>
            {underwriter?.phone}
          </PhoneLink>
        </li>
        <li>
          <Anchor
            href={`mailto:${underwriter?.email}`}
            className="noOutline"
            onClick={() => {}}
          >
            {underwriter?.email}
          </Anchor>
        </li>
      </ul>
    </Panel>
  );
};

export default UnderwritingTeam;
