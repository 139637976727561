import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import { toTitleCase } from "../../components/Factory";
import moment from "moment";
import ClaimsPaymentsContainer from "../containers/ClaimsPaymentsContainer";
import ClaimsNotesContainer from "../containers/ClaimsNotesContainer";
import ClaimsClaimantsContainer from "../containers/ClaimsClaimantsContainer";
import { CLOSED_STATUS, sortClaims } from "../services/claimsUtil";
import { ClaimsActivityColumnHeader } from "./ClaimsActivityColumnHeader";
import { formatPhoneNumber } from "../../shared/util";

import { Accordion, Anchor, Currency, PhoneLink } from "@ufginsurance/ui-kit";
const WORK_COMP = "WORK COMP";
const DATE_FORMAT = "MM/DD/YYYY";

const ClaimsDetail = props => {
  const { claims, policyNumber } = props;

  const [sortField, setSortField] = useState("claim_number");

  const sortedClaims = sortClaims(claims, sortField);

  const isWorkCompClaim = coverage => coverage === WORK_COMP;

  const handleSortClick = event => {
    setSortField(event.target.value);
  };

  return (
    <Fragment>
      <div>
        <div className="row claims-results-header">
          <div className="col-md-2 col-xs-12 claim-number">
            <ClaimsActivityColumnHeader
              value="claim_number"
              onClick={handleSortClick}
              selectedSortField={sortField}
            >
              {Translations.Claims.claims_detail.claim_number}
            </ClaimsActivityColumnHeader>
          </div>

          <div className="col-md-2 col-xs-12 claim-loss-date">
            <ClaimsActivityColumnHeader
              value="loss_date"
              onClick={handleSortClick}
              selectedSortField={sortField}
            >
              {Translations.Claims.claims_detail.loss_date}
            </ClaimsActivityColumnHeader>
          </div>

          <div className="col-md-2 col-xs-12 claim-reported-date">
            <ClaimsActivityColumnHeader
              value="reported_date"
              onClick={handleSortClick}
              selectedSortField={sortField}
            >
              {Translations.Claims.claims_detail.reported_date}
            </ClaimsActivityColumnHeader>
          </div>

          <div className="col-md-2 col-xs-12">
            <ClaimsActivityColumnHeader
              value="coverage"
              onClick={handleSortClick}
              selectedSortField={sortField}
            >
              {Translations.Claims.claims_detail.coverage}
            </ClaimsActivityColumnHeader>
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="row">
              <div className="col-md-4 col-xs-12 claim-status">
                <ClaimsActivityColumnHeader
                  value="status"
                  onClick={handleSortClick}
                  selectedSortField={sortField}
                >
                  {Translations.Claims.claims_detail.status}
                </ClaimsActivityColumnHeader>
              </div>
              <div className="col-md-8 col-xs-12 claim-incurred">
                <ClaimsActivityColumnHeader
                  value="incurred_amount"
                  onClick={handleSortClick}
                  selectedSortField={sortField}
                >
                  {Translations.Claims.claims_detail.loss_total}
                </ClaimsActivityColumnHeader>
              </div>
            </div>
          </div>
        </div>
        <div className="claims-results">
          {sortedClaims.map(claim => (
            <div key={claim.claim_number} className="claim-detail ">
              <div className="row claims-detail-header">
                <div className="col-md-2 col-xs-12 claim-number">
                  {claim.claim_number}
                </div>
                <div className="col-md-2 col-xs-12 claim-loss-date">
                  {moment(claim.loss_date).format(DATE_FORMAT)}
                </div>
                <div className="col-md-2 col-xs-12 claim-reported-date">
                  {moment(claim.reported_date).format(DATE_FORMAT)}
                </div>
                <div className="col-md-2 col-xs-12">
                  {toTitleCase(claim.coverage)}
                </div>

                <div className="col-md-4 col-xs-12">
                  <div className="row ">
                    <div className="col-md-4 col-xs-12 claim-status">
                      {claim.is_secured ? "Secure" : toTitleCase(claim.status)}
                      {claim.status.toLowerCase() ===
                        CLOSED_STATUS.toLowerCase() && (
                        <div>
                          {moment(claim.closed_date).format(DATE_FORMAT)}
                        </div>
                      )}
                    </div>
                    <div className="col-md-8 col-xs-12 claim-incurred">
                      {Translations.Claims.claims_detail.incurred}:{" "}
                      <Currency amount={claim.incurred_amount} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-xs-12 claims-detail-column-1">
                  <div className="row claims-descriptions">
                    <div className="col-xs-4 claim-label ">
                      {Translations.Claims.claims_detail.description}:{" "}
                    </div>
                    <div
                      id="claim-description"
                      className="col-xs-8 claim-descriptor"
                    >
                      {claim.description}
                    </div>
                  </div>

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-injury-detail">
                      <div className="col-xs-4 claim-label ">
                        {Translations.Claims.claims_detail.injury_detail}:{" "}
                      </div>
                      <div className="col-xs-8 claim-descriptor">
                        {claim.injury_detail &&
                          claim.injury_detail.length > 0 &&
                          claim.injury_detail[0]}
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-xs-4 claim-label">
                      {Translations.Claims.claims_detail.location_of_loss}:
                    </div>
                    <div
                      id="location-of-loss"
                      className="col-xs-8 claim-descriptor"
                    >
                      {claim.location_of_loss}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-4 claim-label">
                      {Translations.Claims.claims_detail.adjuster}:{" "}
                    </div>
                    <div
                      id="claim-adjuster-name"
                      className="col-xs-8 claim-descriptor"
                    >
                      {claim.adjuster.name}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 claim-label"></div>
                    <div
                      id="claim-adjuster-email"
                      className="col-xs-8 claim-descriptor"
                    >
                      {`Email: `}
                      <a href={`mailto:${claim.adjuster.email}`}>
                        {claim.adjuster.email}
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 claim-label"></div>
                    <div
                      id="claim-adjuster-phone"
                      className="col-xs-8 claim-descriptor"
                    >
                      {`Phone: `}
                      <PhoneLink phoneNumber={claim.adjuster.phone}>
                        {`${formatPhoneNumber(claim.adjuster.phone)}`}
                      </PhoneLink>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 claim-label">
                      {Translations.Claims.claims_detail.supervisor}:{" "}
                    </div>
                    <div
                      id="claim-supervisor-name"
                      className="col-xs-8 claim-descriptor claim-supervisor"
                    >
                      {claim.supervisor.name}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 claim-label"></div>
                    <div
                      id="claim-supervisor-email"
                      className="col-xs-8 claim-descriptor"
                    >
                      {`Email: `}
                      <Anchor
                        href={`mailto:${claim.supervisor.email}`}
                        onClick={() => {}}
                      >
                        {claim.supervisor.email}
                      </Anchor>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-4 claim-label"></div>
                    <div
                      id="claim-supervisor-phone"
                      className="col-xs-8 claim-descriptor"
                    >
                      {`Phone: `}
                      <PhoneLink phoneNumber={claim.supervisor.phone}>
                        {`${formatPhoneNumber(claim.supervisor.phone)}`}
                      </PhoneLink>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-xs-12 claims-amounts-container">
                  <div className="row claims-amounts dark ">
                    <div className="col-xs-8 claims-amounts-label ">
                      {Translations.Claims.claims_detail.paid}
                    </div>
                    <div className="col-xs-4 claim-amounts-dollars">
                      <Currency amount={claim.paid_amount} />
                    </div>
                  </div>

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-amounts ">
                      <div className="col-xs-5 col-sm-offset-1 claims-amounts-label work-comp">
                        {Translations.Claims.claims_detail.medical}
                      </div>
                      <div className="col-xs-6 claim-amounts-dollars">
                        <Currency amount={claim.paid_medical_amount} />
                      </div>
                    </div>
                  )}

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-amounts dark ">
                      <div className="col-xs-5 col-sm-offset-1 claims-amounts-label work-comp">
                        {Translations.Claims.claims_detail.indemnity}
                      </div>
                      <div className="col-xs-6 claim-amounts-dollars">
                        <Currency amount={claim.paid_indemnity_amount} />
                      </div>
                    </div>
                  )}

                  <div className="row claims-amounts ">
                    <div className="col-xs-8 claims-amounts-label ">
                      {Translations.Claims.claims_detail.recoveries}
                    </div>
                    <div className="col-xs-4 claim-amounts-dollars">
                      <Currency amount={claim.recoveries_amount} />
                    </div>
                  </div>
                  <div className="row claims-amounts dark ">
                    <div className="col-xs-8 claims-amounts-label ">
                      {Translations.Claims.claims_detail.expenses}
                    </div>
                    <div className="col-xs-4 claim-amounts-dollars">
                      <Currency amount={claim.expenses_amount} />
                    </div>
                  </div>
                  <div className="row claims-amounts ">
                    <div className="col-xs-8 claims-amounts-label">
                      {Translations.Claims.claims_detail.outstanding_reserves}
                    </div>
                    <div className="col-xs-4 claim-amounts-dollars">
                      <Currency amount={claim.outstanding_reserves} />
                    </div>
                  </div>

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-amounts dark ">
                      <div className="col-xs-5 col-sm-offset-1 claims-amounts-label work-comp">
                        {Translations.Claims.claims_detail.medical}
                      </div>
                      <div className="col-xs-6 claim-amounts-dollars">
                        <Currency amount={claim.medical_reserves_amount} />
                      </div>
                    </div>
                  )}

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-amounts ">
                      <div className="col-xs-5 col-sm-offset-1 claims-amounts-label work-comp">
                        {Translations.Claims.claims_detail.indemnity}
                      </div>
                      <div className="col-xs-6 claim-amounts-dollars">
                        <Currency amount={claim.indemnity_reserves_amount} />
                      </div>
                    </div>
                  )}

                  {isWorkCompClaim(claim.coverage) && (
                    <div className="row claims-amounts dark ">
                      <div className="col-xs-8 claims-amounts-label">
                        {
                          Translations.Claims.claims_detail
                            .work_comp_ded_reimbursement
                        }
                      </div>
                      <div className="col-xs-4 claim-amounts-dollars">
                        <Currency amount={claim.work_comp_deductible} />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="notes-information-container">
                <Accordion
                  accordionIndex={0}
                  textMain={
                    Translations.Claims.claims_detail
                      .accordion_header_claim_notes
                  }
                >
                  <ClaimsNotesContainer claimNumber={claim.claim_number} />
                </Accordion>
              </div>
              <div className="claimants-information-container">
                <Accordion
                  accordionIndex={1}
                  textMain={
                    Translations.Claims.claims_detail
                      .accordion_header_claim_claimants
                  }
                >
                  {claim.is_secured ? (
                    <div>
                      {Translations.Claims.claims_detail.secure_claim_text}
                    </div>
                  ) : (
                    <ClaimsClaimantsContainer
                      claimNumber={claim.claim_number}
                    />
                  )}
                </Accordion>
              </div>
              <div className="payment-information-container">
                <Accordion
                  accordionIndex={2}
                  textMain={
                    Translations.Claims.claims_detail
                      .accordion_header_payment_information
                  }
                >
                  {claim.is_secured ? (
                    <div>
                      {Translations.Claims.claims_detail.secure_claim_text}
                    </div>
                  ) : (
                    <ClaimsPaymentsContainer
                      claimNumber={claim.claim_number}
                      policyNumber={policyNumber}
                    />
                  )}
                </Accordion>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

ClaimsDetail.propTypes = {
  claims: PropTypes.array,
  policyNumber: PropTypes.string
};

export default ClaimsDetail;
