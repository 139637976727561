import React, { useContext } from "react";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import getAllLocations from "../step5/location/getAllLocations";
import OqModal from "./OqModal";
import _remove from "lodash/remove";
import { locationDisplayName } from "./util.js";
//import * as api from "../../services/onlineQuotingService";
//import { logger } from "../../loggers";
//import { toast } from "react-toastify";

const AdvantageModal = ({ Location, onCancel, exitQuote }) => {
  const {
    quoteData,
    quoteIsUpdating,
    supportingData,
    updateCoverablesPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const onDeleteLocation = Location => {
    const fixedID = Location.fixedID;
    const newItemsArray = [...getAllLocations({ quoteData, supportingData })];
    _remove(newItemsArray, b => b.fixedID === fixedID);
    updateCoverablesPromise({
      coverableType: "location",
      coverables: { locations: newItemsArray },
      action: "Deleting"
    })
      .catch(({ error }) => {
        toastErrr({
          displayMessage: "Failed to remove location.",
          action: "onDeleteLocation",
          description: "unable to delete location",
          error,
          misc: { Location, newItemsArray }
        });
      })
      .finally(() => {
        onCancel();
      });
  };

  //Future story.
  /*
  const changeQuoteStatusToUWDeclined = quoteId => {
    api
      .changeQuoteStatus(quoteId, params)
      .then(() => {
        toast(`You have successfully change status of quote.`, {
          position: "top-center",
          className: "success",
          autoClose: true
        });

        // send event to GA
        logger.event({
          category: "OQ_quote",
          action: "changeQuoteStatus",
          value: supportingData?.currentPage
        });
      })
      .catch(error => {
        toastErrr({
          displayMessage:"There was an error attempting to change status of quote",
          action: "changeQuoteStatus",
          description:"unable to change quote",
          error
          
        });
      });
  };
*/
  const _exitQuote = quoteId => {
    if (quoteId) {
      //changeQuoteStatusToUWDeclined = quoteId;
      onCancel();
    }
  };

  return (
    <OqModal
      title={
        <span>
          Location: <b>{locationDisplayName(Location)}</b> is not eligible
        </span>
      }
      className="oq__Advantage_point__modal"
      show
      closeIcon={false}
      body={
        <div>
          <p>
            We’re sorry but due to the location and/or risk information you
            entered the account is not eligible for coverage. Please contact
            your underwriter if you have any questions. Thank you!
          </p>
          <FlexRow>
            {exitQuote ? (
              <Button
                wrapperClassName="push-right"
                variant="info"
                onClick={() => {
                  _exitQuote(quoteData.quoteID);
                }}
                disabled={quoteIsUpdating}
              >
                Exit Quote
              </Button>
            ) : (
              <Button
                wrapperClassName="push-right"
                variant="tertiary"
                onClick={() => {
                  onDeleteLocation(Location);
                }}
                disabled={quoteIsUpdating}
              >
                Remove Location
              </Button>
            )}
          </FlexRow>
        </div>
      }
    />
  );
};

export default AdvantageModal;
