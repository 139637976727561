import React, { useEffect, useState } from "react";
import { Dropdown, Form, useForm, Input, FormGroup } from "@ufginsurance/ui-kit";
import {
  sdfCommercialFormTypes,
  sdfObligeeTypeOptions,
  sdfContractFormTypes
} from "../../../quick-program/quick-quoting/constants/suretyDictionary";
import { useSuretyDocsFormsContext } from "../context";
import { toTitleCase } from "../../../components/Factory";

export default function FilterForm() {
  const {
    currentSection,
    formValues,
    initialFormValues,
    updateFormValues,
    validAgencyStates,
    validAgencyStatesIsLoading
  } = useSuretyDocsFormsContext();
  const [bondTypeOptions, setBondTypeOptions] = useState([]);

  useEffect(() => {
    setBondTypeOptions(
      currentSection === "bondsCommercial"
        ? sdfCommercialFormTypes
        : sdfContractFormTypes
    );
  }, [currentSection]);

  const form = useForm({
    values: initialFormValues,
    onSubmit: () => {}
  });

  const { handleOnChange, handleOnBlur, handleOnValidate } = form;

  const handleFilterOnChange = ({ field, value, event }) => {
    handleOnChange({ field, value, event });
    updateFormValues({ [field]: value });
  };

  if (!currentSection.includes("bond")) {
    return null;
  }

  return (
    <Form context={form} className="sdfFilterForm">
      <FormGroup>
        <Dropdown
          id="state"
          name="state"
          label="Select State"
          required
          onChange={handleFilterOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={formValues.state}
          isClearable={false}
          options={(validAgencyStates ?? []).map(state => ({
            label: toTitleCase(state.name),
            value: String(state.name)
          }))}
          size="25p"
          isLoading={validAgencyStatesIsLoading}
        />

        <Dropdown
          id="bondType"
          name="bondType"
          label="Bond Type"
          onChange={handleFilterOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={formValues.bondType}
          isClearable={false}
          options={bondTypeOptions}
          size="33p"
        />

        <Dropdown
          id="obligeeType"
          name="obligeeType"
          label="Obligee Type"
          onChange={handleFilterOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={formValues.obligeeType}
          isClearable={false}
          options={sdfObligeeTypeOptions}
          size="33p"
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="keyword"
          name="keyword"
          label="Search Keyword or Form Name"
          onChange={handleFilterOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={formValues.keyword}
          size="50p"
        />
      </FormGroup>
    </Form>
  );
}
