import { useEffect } from "react";
import { useRootRouteMatch } from "../hooks";

export const useDepartmentsFetch = ({
  activeSpecialtyCompanyBranch,
  previousCompanyBranch,
  agencyCode,
  previousAgencyCode,
  fetchUfgTeamDepartmentData,
  areDepartmentsLoading
}) => {
  const yourUfgTeamRouteMatch = useRootRouteMatch();

  useEffect(() => {
    if (
      yourUfgTeamRouteMatch && !areDepartmentsLoading &&
      (
        activeSpecialtyCompanyBranch !== previousCompanyBranch ||
        agencyCode !== previousAgencyCode
      )
    ) {
      fetchUfgTeamDepartmentData(agencyCode, activeSpecialtyCompanyBranch);
    }
  }, [
    agencyCode,
    activeSpecialtyCompanyBranch,
    fetchUfgTeamDepartmentData,
    previousAgencyCode,
    previousCompanyBranch,
    yourUfgTeamRouteMatch,
    areDepartmentsLoading
  ]);

  return null;
};
