import {
  hardCodedErrorsToIgnore_stateSpecific,
  stateSpecificFields,
  stateOptions,
  getClauseGroupsOrder
} from "../../shared/constants";
import { convertStatesAndAbbreviations } from "../../../components/Factory";
import { applyExclusions } from "../../shared/coveragePanels";
import { getDynamicJurisdictionalCoverageControls } from "../../shared/dynamicCoverageControls/coverageControls";

import {
  addAutoHiredNonOwned,
  addBureauIdField,
  addNcciidFields,
  addWorkCompFields,
  addCA7JurisdictionCoverable
} from "./lobAdditionalFields";

import { _set, hasHNO, sortCoverages } from "../../shared/util";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";

const lobsWithStateSpecific = [
  "bp7BusinessOwners",
  "ca7CommAuto",
  "wcmWorkersComp"
];

/**
 * exemptionsForState
 * merges the mule riskInfo coverage controls with the hard-coded exemptions
 */
export const coverageExclusionsForState = (
  coverableName,
  productLine,
  step5Exclusions,
  supportingData,
  quoteData
) => {
  /**
   * `step5Exclusions` comes from AWS & Mule metadata calls
   * ... mix this with the hard-coded controls below
   */

  const stateSpecificExclusion = {
    formData: {
      coverageControl: [
        // specific state rules
        // NOTE: This MUST be first, so that individual state rules can overide the *.* rules
        // - example: Tort Limitation for Kentucky: https://ufginsurance.atlassian.net/browse/OOQ-14420
        ...(step5Exclusions?.formData?.stateCoverageControl?.[coverableName] ||
          []),
        // all states
        ...(step5Exclusions?.formData?.stateCoverageControl?.[".*"] || [])
      ]
    }
  };

  /**
   * add the dynamic coverage controls handled in the UI
   *  - because these controls are dependent on values in the quote
   *    ... we handle them directly in the UI since they need to be dyanmic
   */
  const dynamicCoverageControls = getDynamicJurisdictionalCoverageControls({
    coverableName,
    productLine,
    quoteData,
    supportingData
  });

  return mergeExclusions([stateSpecificExclusion, dynamicCoverageControls]);
};

/**
 * amendCoverages
 * - add some properties to the coverages to support the form
 * - injects the custom coverages we manually add to a specific group of coverages
 * ... associating them to a specific entity (or clausesGroup)
 */
const amendCoverages = ({
  coverableName,
  productLine,
  coverableFixedId,
  path,
  clausesGroupCoverages,
  entity,
  coverageExclusionsForState,
  get,
  quoteData,
  supportingData
}) => {
  const abbrv = convertStatesAndAbbreviations(coverableName, "abbr");
  const stateConfig = stateSpecificFields(abbrv);

  // clean up and sort the existing coverages
  const exclusions = coverageExclusionsForState(coverableName, productLine);
  const coveragesWithExclusions = applyExclusions(
    clausesGroupCoverages,
    exclusions
  );
  const allCoverages = sortCoverages(coveragesWithExclusions);

  // add custom auto coverages
  const autoFields = [];
  if (entity === "entity.CA7Jurisdiction" && !hasHNO(supportingData)) {
    autoFields.unshift(
      ...addAutoHiredNonOwned({
        fixedId: coverableFixedId,
        quoteData,
        get,
        usState: abbrv
      })
    );
  }

  if (entity === "entity.CA7Jurisdiction") {
    autoFields.unshift(
      ...addCA7JurisdictionCoverable({
        fixedId: coverableFixedId,
        quoteData
      })
    );
  }

  // add custom work comp coverages
  const workCompFields = [];

  if (stateConfig && entity === "entity.WCMJurisdiction") {
    // NCCII fields
    const ncciiFieldData = (stateConfig?.fields || []).filter(f =>
      f.name.includes("nccii")
    );
    if (ncciiFieldData.length > 0) {
      const newFieldPanel = addNcciidFields({
        fixedId: coverableFixedId,
        quoteData,
        usState: stateConfig.stateName,
        usStateAbr: stateConfig.stateCode,
        terms: ncciiFieldData
      });
      workCompFields.push(newFieldPanel);
    }

    // non-NCCII fields
    const nonNcciFieldData = (stateConfig?.fields || []).filter(
      f => !f.name.includes("nccii")
    );
    if (nonNcciFieldData.length > 0) {
      const newFieldPanel = addBureauIdField({
        fixedId: coverableFixedId,
        quoteData,
        usState: stateConfig.stateName,
        terms: nonNcciFieldData
      });

      workCompFields.push(newFieldPanel);
    }
  }

  if (entity === "entity.WCMJurisdiction") {
    workCompFields.push(
      ...addWorkCompFields({
        fixedId: coverableFixedId,
        quoteData
      })
    );
  }

  // return combined coverage with some added data to help support the form
  return [...allCoverages, ...autoFields, ...workCompFields].map(f => ({
    ...f,
    coverableFixedId,
    coverableType: "jurisdiction",
    productLine,
    coveragesPath: path,
    coverableName
  }));
};

/**
 *  dynamically organize state data based on "coverableName" in the dto
 *  is combines and sorts the coverages for all jurisdictional-related clauses groups
 */
export const processStateData = ({ get, quoteData, supportingData }) => {
  // a temp object to help organize the data by state/coverableName
  const tempStatesData = {};

  // array of stateNames
  const statesArray = Object.values(stateOptions);

  // dynamically organize state data based on "coverableName" in the dto
  lobsWithStateSpecific.forEach(productLine => {
    const lobCoverages = quoteData?.lobData?.[productLine]?.coverages || {};
    const clausesGroupKeysInLob = Object.keys(lobCoverages);

    clausesGroupKeysInLob.forEach(key => {
      /**
       * examples of some keys:
       * - jurisdictionClausesGroups
       * - hiredAutoClausesGroups
       * - coveredEmployeeClausesGroups
       */

      /**
       * we check for arrays becuase the "clausesGroups" key is an object with coverages for the lob
       * ... and state-specifc clauses groups MUST be an array of objects
       */
      if (Array.isArray(lobCoverages[key]))
        // Loop through all of the clauses groups and start to organize the data based on the state/coverableName
        lobCoverages[key].forEach(clausesGroup => {
          const coverableName = clausesGroup.coverableName || "unknown";

          // check if coverableName is a state -- some coverableNames are addresses for location clauses groups
          if (statesArray.includes(coverableName)) {
            // set the path used on coverages for supporting the form
            const path = `lobData.${productLine}.coverages.${key}`;

            // a merge of all of the coverages in in this group
            const clausesGroupCoverages = Object.keys(
              clausesGroup.clausesGroups
            ).reduce((acc, ckey) => {
              // save the clause group name so that it can be referenced/used in the saveCoverages func
              const covgArr = clausesGroup.clausesGroups[ckey].map(d => ({
                ...d,
                clausesGroupName: ckey
              }));

              return [...acc, ...covgArr];
            }, []);

            // update the coverages to
            const coverages = amendCoverages({
              coverableName,
              productLine,
              path,
              coverableFixedId: clausesGroup.coverableFixedId,
              clausesGroupCoverages,
              entity: clausesGroup.entityName,
              coverageExclusionsForState,
              get,
              quoteData,
              supportingData
            });

            // add this group to the datas array that holds all of the specific clauses
            // ... groups for this state/coverableName
            // ... and sort it by lob and sort order
            const mergedDatas = [
              ...(tempStatesData?.[coverableName]?.data || []),
              {
                ...clausesGroup,
                productLine,
                path,
                clausesGroupName: key,
                sortOrder: getClauseGroupsOrder(key),
                clausesGroups: {
                  coverages
                }
              }
            ].sort((a, b) =>
              a.productLine > b.productLine
                ? 1
                : a.productLine === b.productLine
                ? a.sortOrder > b.sortOrder
                  ? 1
                  : -1
                : -1
            );

            // the coverable Ids used for associated errors in the dto with this state
            const coverableIds = (mergedDatas || []).map(
              c => c.coverableFixedId
            );

            // assign this clause group to the temporary state object
            tempStatesData[coverableName] = {
              // start with data already associated to this state
              ...(tempStatesData?.[coverableName] || {}),

              // add the new data for the current clauseGroup
              coverableName,
              coverageCount: coverages.length,
              data: mergedDatas,
              className: (
                quoteData?.errorsAndWarnings?.validationIssues?.issues || []
              )
                .filter(
                  e =>
                    !hardCodedErrorsToIgnore_stateSpecific.some(i =>
                      i.test(e.reason)
                    )
                )
                .some(i => coverableIds.includes(i?.relatedEntity?.fixedId))
                ? "oq__coverable__tableRowWithError"
                : ""
            };
          }
        });
    });
  });

  // pull values from the temporary collection
  const newStatesData = Object.values(tempStatesData);

  // save to the local state
  return newStatesData;
};
