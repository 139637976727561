import React, { useCallback, useState, useContext, useEffect } from "react";
import * as api from "../../../services/onlineQuotingService";
import {
  Button,
  Form,
  useForm,
  FormGroup,
  DatePicker,
  formatDate,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import OqModal from "../OqModal";
import { toast } from "react-toastify";
import { quotePatch } from "../quotePatching";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { getMinMax } from "./getMinMax";
import { useFlags } from "launchdarkly-react-client-sdk";

/*
this modal is used in the quoting system and in the submission list on the /commercial pages
*/

const EffectiveDateModal = ({
  initialValues,
  onHide,
  show,
  quoteId,
  setNewEffectiveDate
}) => {
  const {
    step,
    updateSupportingDataPromise,
    patchQuotePromise,
    toastErrr,
    supportingData,
    effectiveDateOverride
  } = useContext(OnlineQuotingContext) || {}; // use {} because this modal is consumed by the Commercial Submission list which is outside the context provider

  const { proquoteMaxEffectiveDateDays } = useFlags();

  const [spinner, setSpinner] = useState();
  const [suppData, setSuppData] = useState(supportingData);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [hasAuto, setHasAuto] = useState();
  const [originalEffectiveDate, setOriginalEffectiveDate] = useState();

  const onSubmit = useCallback(
    ({ values }) => {
      if (
        spinner ||
        !values?.effectiveDate ||
        formatDate(values?.effectiveDate, "MM/DD/YYYY") ===
          initialValues.effectiveDate
      )
        return;

      // actions to take after the update is completed
      const doAfterUpdate = ({ success, results, error }) => {
        setSpinner(false);
        // only need to do this if you're on the NSL
        if (!step && success) {
          // update the effective Date on the NSL (if we're on the NSL)
          if (results && setNewEffectiveDate) setNewEffectiveDate(results.data);
          toast("The policy effective date has been updated", {
            position: "top-center",
            className: "success"
          });
          onHide();
        } else if (step && success) {
          onHide();
        } else if (error) {
          toastErrr({
            error,
            action: "EffectiveDateModal > onSubmit",
            description: "unable to save effective date",
            misc: { values }
          });
        }
      };

      setSpinner(true);

      /**
       * Here, we're in the quoting system pages
       */
      if (step) {
        if (step < 3) {
          // if we're on step 2, just update the supporting data
          updateSupportingDataPromise({
            dataToMergeAndSave: {
              effectiveDate: values.effectiveDate,
              effectiveDateOriginal: values.effectiveDate
            }
          }).then(doAfterUpdate);
        } else {
          // on steps 3 - 6, use the useQuote patchQuote
          patchQuotePromise({
            newData: {
              effectiveDate: formatDate(values.effectiveDate, "OBJECT")
            },
            quoteId,
            updateMessage: "Updating Effective Date"
          })
            .then(onHide)
            .catch(doAfterUpdate);
        }
      } else {
        /***
         * if we're not in the quoting system pages (New Submission List)
         * update using the static quotePatch function
         */
        quotePatch({
          newData: {
            effectiveDate: formatDate(values.effectiveDate, "OBJECT")
          },
          quoteId,
          callback: doAfterUpdate
        });
      }
    },
    [
      spinner,
      initialValues?.effectiveDate,
      step,
      setNewEffectiveDate,
      onHide,
      toastErrr,
      updateSupportingDataPromise,
      patchQuotePromise,
      quoteId
    ]
  );

  // initialize the hook
  const form = useForm({
    values: { effectiveDate: suppData?.effectiveDate || "" },
    onSubmit
  });
  const {
    values,
    handleOnChange,
    handleOnValidate,
    handleOnBlur,
    invalidFields,
    updateForm
  } = form;

  /**
   * When this modal is used on the New Submission List,
   * it needs to load the SupportingData for validation rules
   */
  useEffect(() => {
    // should happen only on the NSL
    if (!suppData) {
      api
        .getSupportingDataByQuoteId(quoteId)
        .then(response => setSuppData(response.data))
        .catch(e => {
          console.error(e);

          // if we fail to get the supportingData, then fallback to just use the initialvalue
          // this should only be possible by opening from the NSL...
          // ... and only if there's a real problem
          setSuppData({ ...initialValues });
          updateForm({ values: { ...initialValues } });
        });
    }
  }, [initialValues, quoteId, suppData, updateForm]);

  /**
   * After SupportingData is setup,
   * then get set up all of validation for the date ranges
   */
  useEffect(() => {
    /***
     * Notes about `originalEffectiveDate`
     * this field was added to help track changes for the AU update in 2023 in PC
     * it allows us to tell what date the quote was set to when it was created...
     * it's no longer needed for the AU update (5/1/2023), but we'll leave it in
     * just in case it's useful in the future
     */

    /**
     * Only needs to happen once, so once minDate is set, don't try again (else it will loop)
     * Override Note: when override is on, the minDate is set to `null`...
     * ... so confirming the minDate is undefined is better than `falsy`
     */
    if (!effectiveDateOverride && suppData && !minDate) {
      const _hasAuto = (suppData?.policyLines || []).some(
        l => l === "CA7CommAutoLine" || l === "ca7CommAuto"
      );
      if (hasAuto === undefined) setHasAuto(_hasAuto);

      // get OG effective date, if possible (only avail in SupportingData after Feb)
      // if not avail, then fall back to the effective date values sent to the modal
      const ogEffectiveDate =
        suppData?.effectiveDateOriginal || suppData?.effectiveDate;
      const _originalEffectiveDate = new Date(
        ogEffectiveDate.year,
        ogEffectiveDate.month,
        ogEffectiveDate.day
      );
      setOriginalEffectiveDate(_originalEffectiveDate);

      /**
       * SET THE MIN AND MAX VALUES
       */
      const { minDate, maxDate } = getMinMax({
        effectiveDateOverride,
        proquoteMaxEffectiveDateDays,
        hasAuto: _hasAuto,
        supportingData: suppData,
        originalEffectiveDate: _originalEffectiveDate,
        step
      });
      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [
    hasAuto,
    initialValues,
    effectiveDateOverride,
    proquoteMaxEffectiveDateDays,
    step,
    suppData,
    supportingData,
    updateForm,
    minDate,
    originalEffectiveDate,
    values.effectiveDate
  ]);

  return (
    <OqModal
      size="sm"
      title="Edit Effective Date"
      closeIcon={false}
      className="oq__effective-date__modal"
      body={
        <Form context={form}>
          {!suppData && <LoadingIndicator />}
          {suppData && (
            <>
              <FormGroup>
                <DatePicker
                  id="effectiveDate"
                  name="effectiveDate"
                  label="Effective Date"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.effectiveDate}
                  format="MM/DD/YYYY"
                  required
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </FormGroup>
              <FormGroup align="right">
                <Button variant="plain" onClick={onHide} disabled={spinner}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    spinner ||
                    !!invalidFields.length ||
                    formatDate(values?.effectiveDate, "MM/DD/YYYY") ===
                      formatDate(suppData?.effectiveDate, "MM/DD/YYYY")
                  }
                  spinner={spinner}
                >
                  Update
                </Button>
              </FormGroup>
            </>
          )}
        </Form>
      }
      show={show}
      onHide={onHide}
      altCloseMethod={false}
    />
  );
};

export default EffectiveDateModal;
