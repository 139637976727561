import React from "react";
import { Button } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { toTitleCase } from "../../../components/Factory";

const OwnerData = ({ quoteData, history }) => {
  const ownerDataDisplay = quoteData?.ownerData
    ?.filter(d => d.delete === false)
    .map(item => {
      return (
        <div key={item.id}>
          {toTitleCase(item.first_name + " " + item.last_name)}
        </div>
      );
    });

  return (
    <>
      <div className="qq__sidebar__summary__item">
        <b>Owners</b>
        <div>{ownerDataDisplay}</div>
        <Button
          isLink
          inline
          onClick={() => history.push(routes.setStepUrl(2))}
        >
          Edit Owners
        </Button>
      </div>
    </>
  );
};

export default OwnerData;
