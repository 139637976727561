import * as types from "../constants/actionTypes";
import * as service from "../services/commercialService";
import { DocumentService } from "../services/documentService";
import { logger } from "../loggers";

const fetchQuoteProposalStarted = (
  agencyCode,
  currentFilter,
  specialtyCompanyBranch
) => ({
  type: types.FETCH_QUOTE_PROPOSAL_START,
  agencyCode,
  currentFilter,
  specialtyCompanyBranch
});

const fetchQuoteProposalSucceeded = (
  data,
  agencyCode,
  currentFilter,
  specialtyCompanyBranch
) => ({
  type: types.FETCH_QUOTE_PROPOSAL_SUCCEEDED,
  payload: data,
  agencyCode,
  currentFilter,
  specialtyCompanyBranch
});

const fetchQuoteProposalFailed = (
  error,
  currentFilter,
  agencyCode,
  specialtyCompanyBranch
) => ({
  type: types.FETCH_QUOTE_PROPOSAL_FAILED,
  payload: error,
  error: true,
  currentFilter,
  agencyCode,
  specialtyCompanyBranch
});

const updateQuoteProposalFilter = data => ({
  type: types.UPDATE_QUOTE_PROPOSAL_FILTER,
  payload: data
});

export const updateQuoteProposal =
  (agencyCode, currentFilter, company_branch) => dispatch => {
    dispatch(updateQuoteProposalFilter(agencyCode, currentFilter));
    dispatch(
      fetchQuoteProposalStarted(agencyCode, currentFilter, company_branch)
    );
    return service
      .getQuoteProposalList(agencyCode, currentFilter, company_branch)
      .then(
        response => {
          dispatch(
            fetchQuoteProposalSucceeded(
              response.data,
              agencyCode,
              currentFilter,
              company_branch
            )
          );
        },
        error => {
          dispatch(
            fetchQuoteProposalFailed(
              error,
              currentFilter,
              agencyCode,
              company_branch
            )
          );
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

const fetchSubmissionListStarted = () => ({
  type: types.FETCH_SUBMISSION_LIST_START
});

const fetchSubmissionListSucceeded = data => ({
  type: types.FETCH_SUBMISSION_LIST_SUCCEEDED,
  payload: data
});

const fetchSubmissionListFailed = error => ({
  type: types.FETCH_SUBMISSION_LIST_FAILED,
  payload: error,
  error: true
});

//messages
const fetchMessagesStarted = () => ({
  type: types.FETCH_MESSAGES_STARTED
});

const fetchMessagesSucceeded = data => ({
  type: types.FETCH_MESSAGES_SUCCEEDED,
  payload: data
});

const markConversationAsRead = conversationId => ({
  type: types.MARK_CONVERSATION_READ,
  payload: conversationId
});

const fetchMessagesFailed = error => ({
  type: types.FETCH_MESSAGES_FAILED,
  payload: error,
  error: true
});

const startConversationStarted = () => ({
  type: types.START_CONVERSATIONS_STARTED
});

const startConversationSucceeded = () => ({
  type: types.START_CONVERSATIONS_SUCCEEDED
});

const fetchConversationFailed = error => ({
  type: types.CONVERSATIONS_FAILED,
  payload: error,
  error: true
});

const replyMessageStarted = () => ({
  type: types.REPLY_MESSAGE_STARTED
});

const replyMessageSucceeded = () => ({
  type: types.REPLY_MESSAGE_SUCCEEDED
});

const replyFailed = error => ({
  type: types.REPLY_FAILED,
  payload: error,
  error: true
});

const fetchAttchmentsList = data => ({
  type: types.FETCH_ATTACHMENT_LIST,
  payload: data
});

const uploadStarted = () => ({
  type: types.UPLOAD_ATTACHMENT_PROGRESS,
  payload: {
    uploading: true,
    uploadSuccess: false,
    uploadFailed: false
  }
});

const uploadSuccess = () => ({
  type: types.UPLOAD_ATTACHMENT_PROGRESS,
  payload: {
    uploading: false,
    uploadSuccess: true,
    uploadFailed: false
  }
});

const uploadFailed = () => ({
  type: types.UPLOAD_ATTACHMENT_PROGRESS,
  payload: {
    uploading: false,
    uploadSuccess: false,
    uploadFailed: true
  }
});

const fetchProposalDocumentStarted = id => ({
  type: types.FETCH_PROPOSAL_DOCUMENT_STARTED,
  id
});

const fetchProposalDocumentSuccess = id => ({
  type: types.FETCH_PROPOSAL_DOCUMENT_SUCCESS,
  id
});

const fetchProposalDocumentFail = id => ({
  type: types.FETCH_PROPOSAL_DOCUMENT_FAIL,
  id
});

export const fetchBlobFile = id => dispatch => {
  const documentService = new DocumentService();
  dispatch(fetchProposalDocumentStarted(id));
  return service.getQuotePdfData(id).then(
    response => {
      dispatch(fetchProposalDocumentSuccess(id));
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(response.data, "proposal.pdf");
    },
    () => {
      dispatch(fetchProposalDocumentFail(id));
      documentService.clearRenderer();
    }
  );
};

export const fetchBlobAttachFile = (attachment, submissionId) => () => {
  const documentService = new DocumentService();
  return service.getAttachment(attachment.id, submissionId).then(
    response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        attachment.name,
        attachment.mime_type
      );
    },
    () => {
      documentService.clearRenderer();
    }
  );
};

export const fetchSubmissionList = agencyCode => dispatch => {
  dispatch(fetchSubmissionListStarted());
  return service.getSubmissions(agencyCode).then(
    response => {
      dispatch(fetchSubmissionListSucceeded(response.data));
    },
    error => {
      dispatch(fetchSubmissionListFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const deleteQuote = (submissionId, agencyCode) => dispatch => {
  return service.deleteQuote(submissionId).then(
    response => {
      if (response.status === 204) {
        dispatch(fetchSubmissionList(agencyCode));
      }
    },
    error => {
      dispatch(fetchSubmissionListFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const copyQuote = submissionId => dispatch => {
  return service.copyQuote(submissionId).then(
    response => {
      if (response.status === 200) {
        window.location = response.data.edit_quote_url;
      }
    },
    error => {
      dispatch(fetchSubmissionListFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const editQuote = submissionId => dispatch => {
  return service.editQuote(submissionId).then(
    () => {},
    error => {
      dispatch(fetchSubmissionListFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchMessages = (submissionId, user_role) => dispatch => {
  dispatch(fetchMessagesStarted());
  return service.getMessages(submissionId, user_role).then(
    response => {
      dispatch(fetchMessagesSucceeded(response.data));
    },
    error => {
      dispatch(fetchMessagesFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const resetMessages = () => ({
  type: types.RESET_MESSAGES
});

export const startConversation =
  (submissionId, user_role, body) => dispatch => {
    dispatch(startConversationStarted());
    return service.startConversation(submissionId, user_role, body).then(
      () => {
        dispatch(startConversationSucceeded());
        return dispatch(fetchMessages(submissionId, user_role));
      },
      error => {
        dispatch(fetchConversationFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };

export const replyMessage =
  (submissionId, conversationId, user_role, body) => dispatch => {
    dispatch(replyMessageStarted());
    return service
      .replyMessage(submissionId, conversationId, user_role, body)
      .then(
        () => {
          dispatch(replyMessageSucceeded());
          return dispatch(fetchMessages(submissionId, user_role));
        },
        error => {
          dispatch(replyFailed(error));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const markAsRead =
  (submissionId, conversationId, user_role) => dispatch => {
    return service.markAsRead(submissionId, conversationId, user_role).then(
      () => {
        dispatch(markConversationAsRead(conversationId));
      },
      error => {
        dispatch(replyFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };

export const editApp = submissionId => dispatch => {
  return service.editApp(submissionId).then(
    () => {},
    error => {
      dispatch(fetchSubmissionListFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const viewAttachments = submissionId => dispatch => {
  dispatch(resetAttachments());
  return service.viewAttachments(submissionId).then(
    response => {
      if (response.status === 200) {
        dispatch(fetchAttchmentsList(response.data));
      }
    },
    error => {
      if (error.response.status === 404) dispatch(fetchAttchmentsList([]));
    }
  );
};

export const resetAttachments = () => ({
  type: types.RESET_ATTACHMENTS
});

export const removeAttachment = (id, submissionId) => dispatch => {
  return service.removeAttachment(id, submissionId).then(() => {
    dispatch(viewAttachments(submissionId));
  });
};

export const updateAttachment = (data, submissionId) => dispatch => {
  dispatch(uploadStarted());
  return service.updateAttachment(data, submissionId).then(
    () => {
      dispatch(viewAttachments(submissionId));
      dispatch(uploadSuccess());
    },
    () => {
      dispatch(uploadFailed());
    }
  );
};
