import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@ufginsurance/ui-kit";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";
import "./ApiResponseModalBody.scss";

const ApiResponseModalBody = ({ isSuccess, isError, message, handleClose }) => (
  <div className="api-response-modal-body">
    <div className="api-response-modal-body__content">
      <Icon
        size="2x"
        icon={isSuccess ? "fasCheck" : "fasTimesCircle"}
        className={`api-response-modal-body__icon${isError ? "--error" : ""}`}
      />

      <span className="api-response-modal-body__message">
        {message && isSuccess ? message : <DefaultErrorResponse />}
      </span>
    </div>

    {handleClose && (
      <Button
        variant="plain"
        wrapperClassName="api-response-modal-body__button"
        onClick={handleClose}
      >
        Close
      </Button>
    )}
  </div>
);

ApiResponseModalBody.propTypes = {
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  handleClose: PropTypes.func
};

export default ApiResponseModalBody;
