import React from "react";
import { Panel } from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../components/Factory";
import PropTypes from "prop-types";

const UnderwritingTeamInformation = props => {
  const { policyInfo } = props;

  return (
    <Panel
      className="pol__sidebar__summary"
      highlight="blue"
      title="Underwriting Team"
    >
      <div className="pol__underwriter-name__wrapper">
        {
          <div className="pol__sidebar__summary__item" id="underwriterName">
            <b className="pol__sidebar__summary__underwriterName">
              {toTitleCase(policyInfo?.underwriter_contact_first_name ?? "") +
                " " +
                toTitleCase(policyInfo?.underwriter_contact_last_name ?? "")}
            </b>
          </div>
        }
      </div>
      <div className="pol__sidebar__summary__item">
        <div id="underwriterPhone">
          Phone: {policyInfo?.underwriter_contact_primary_phone ?? ""}
        </div>
      </div>
      <div id="underwriterEmailAddress" className="pol__sidebar__summary__item">
        <a
          href={"mailto:" + policyInfo?.underwriter_contact_email_address ?? ""}
        >
          {policyInfo?.underwriter_contact_email_address ?? ""}
        </a>{" "}
      </div>
    </Panel>
  );
};

UnderwritingTeamInformation.propTypes = {
  policyInfo: PropTypes.object
};

export default UnderwritingTeamInformation;
