import { connect } from "react-redux";
import PolicyActivity from "./policyActivity";
import {
  fetchActivityDocument,
  fetchPolicyActivityCancellationList,
  fetchCancellationDocument
} from "./policyActivityActions";
import {
  getActivityListSelector,
  getCancellationListSelector,
  getIsActivityLoadingSelector,
  getErrorActivityListSelector,
  getCancellationListLoadingSelector,
  getErrorCancellationListSelector
} from "./policyActivitySelector";

const mapStateToProps = state => ({
  activityList: getActivityListSelector(state),
  cancellationList: getCancellationListSelector(state),
  isActivityLoading: getIsActivityLoadingSelector(state),
  errorActivityList: getErrorActivityListSelector(state),
  isCancellationLoading: getCancellationListLoadingSelector(state),
  errorCancellationList: getErrorCancellationListSelector(state)
});

const mapDispatchToProps = {
  fetchActivityDocument,
  fetchPolicyActivityCancellationList,
  fetchCancellationDocument
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyActivity);
