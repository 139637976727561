import React, { useReducer } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DatePicker,
  FlexRow,
  Form,
  FormGroup,
  TextArea,
  useForm
} from "@ufginsurance/ui-kit";
import { createBondEditPayload } from "../shared/utils";
import { logger } from "../../loggers";
import { putBondProjectDescription } from "../services";
import ApiResponseModalBody from "../shared/ApiResponseModalBody/ApiResponseModalBody";
import { useSuretyContext } from "../surety-context/SuretyContext";

function ChangeProjectDescription({ bond, setModalBody }) {
  const { agency, producer } = useSuretyContext();
  const [submission, updateSubmission] = useReducer(
    (b, u) => ({ ...b, ...u }),
    { success: false, error: false, isLoading: false }
  );

  const currentProjectDescription = bond?.bond_description || "";

  const initialValues = {
    change_effective_date: "",
    new_project_description: "",
    comments_remarks: ""
  };

  const handleSubmit = async ({ values }) => {
    const payloadValues = {
      ...values,
      current_project_description: currentProjectDescription
    };
    try {
      updateSubmission({ isLoading: true });
      const response = await putBondProjectDescription(
        createBondEditPayload({ bond, agency, producer, values: payloadValues })
      );
      if (response?.status === 200) {
        updateSubmission({ success: true, isLoading: false });
      }
    } catch (error) {
      updateSubmission({ error: true, isLoading: false });
      logger.error({
        description: error.toString(),
        fatal: false
      });
    }
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleSubmit
  });

  const {
    errors,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    invalidFields,
    updateForm,
    values
  } = form;

  const handleCloseModal = () => {
    updateForm({
      values: initialValues,
      errors: {
        change_effective_date: [],
        comments_remarks: [],
        new_project_description: []
      }
    });
    setModalBody("bondOverview");
  };

  if (submission?.success || submission?.error) {
    return (
      <>
        <ApiResponseModalBody
          isSuccess={submission?.success}
          isError={submission?.error}
          message="Thank you! Your change request has been sent to the surety department. An email confirmation of this change has been sent to you."
        />
        <FlexRow align="right" className="edit-modal-buttons mt-2">
          <Button onClick={handleCloseModal}>Back to Bond Overview</Button>
        </FlexRow>
      </>
    );
  }

  return (
    <Form context={form} id="change-bond-amount">
      <FormGroup>
        <DatePicker
          id="change_effective_date"
          name="change_effective_date"
          label="Effective Date of Change"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.change_effective_date}
          format="YYYY-MM-DD"
          minDate={new Date()}
          size="md"
          required
        />
      </FormGroup>

      <strong className="mt-2">Current Project Description</strong>
      <p className="mt-1">
        {currentProjectDescription?.length ? currentProjectDescription : "-"}
      </p>

      <FormGroup>
        <strong>New Project Description</strong>
        <TextArea
          id="new_project_description"
          name="new_project_description"
          label="Project Description"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.new_project_description}
          required
        />
      </FormGroup>
      <FormGroup>
        <TextArea
          id="comments_remarks"
          name="comments_remarks"
          label="Comments/Remarks"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.comments_remarks}
        />
      </FormGroup>
      <FlexRow align="right" className="edit-modal-buttons">
        <Button variant="plain" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          spinner={submission.isLoading}
          disabled={!!Object.keys(errors).length || !!invalidFields.length}
        >
          Submit Change
        </Button>
      </FlexRow>
    </Form>
  );
}

ChangeProjectDescription.propTypes = {
  bond: PropTypes.object.isRequired,
  setModalBody: PropTypes.func.isRequired
};

export default ChangeProjectDescription;
