import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../loggers";

/*--------AGENCY ACCOUNTS SECTION----------*/
const fetchSuretyZipStarted = () => ({
  type: types.FETCH_SURETY_ZIP_STARTED
});

const fetchSuretyZipSucceeded = data => ({
  type: types.FETCH_SURETY_ZIP_SUCCEEDED,
  payload: data
});

const fetchSuretyZipFailed = error => ({
  type: types.FETCH_SURETY_ZIP_FAILED,
  payload: error
});

export const fetchSuretyZip = activeAgencyCode => dispatch => {
  dispatch(fetchSuretyZipStarted());
  return quickQuotingServices.searchLocaleByZipCodeV2(activeAgencyCode).then(
    response => {
      dispatch(fetchSuretyZipSucceeded(response.data));
    },
    error => {
      dispatch(fetchSuretyZipFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
