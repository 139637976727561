import React, { useState } from "react";
import PropTypes from "prop-types";
import { Anchor, LoadingIndicator, Modal, Table } from "@ufginsurance/ui-kit";
import { DateFormat } from "../../../components/Factory";
import { v4 } from "uuid";
import * as dictionary from "../../../constants/dictionary";
import * as routes from "../../../constants/routes";
import { printPdf } from "../../../shared/documents/helpers";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const CertificateHistoryTable = props => {
  const { certificateHistoryList } = props;

  const certificateHistoryListWithIds =
    (certificateHistoryList &&
      certificateHistoryList.map(certificate => ({
        ...certificate,
        id: v4()
      }))) ||
    [];

  const [documentsLoading, setDocumentsLoading] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPrintStart = certificate_holder_key => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      certificate_holder_key
    )
      ? [...documentsLoading, certificate_holder_key]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = certificate_holder_key => {
    setDocumentsLoading(
      documentsLoading.filter(x => x !== certificate_holder_key)
    );
  };

  const onPrintError = () => {
    setShowModal(true);
  };

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const getColumns = () => [
    {
      key: "certificate_holder",
      label: "Certificate Holder"
    },
    {
      key: "policy_number",
      label: "Policy Number"
    },
    {
      key: "account_number",
      label: "Account Number"
    },
    {
      key: "process_date",
      label: "Process Date",
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "type_description",
      label: "",
      element: row => {
        if (row.certificate_holder_key) {
          const docId = `${row.certificate_holder_key}_${row.process_date}`;
          const requestInProgress = documentsLoading.includes(docId);

          return (
            <div className="certificate-table-type__wrapper">
              {requestInProgress && (
                <span className="certificate-table-spinner">
                  <LoadingIndicator message="" type="spinner" />
                </span>
              )}
              <Anchor
                href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${
                  dictionary.DOCUMENT_FORM_TYPES.CERTIFICATE
                }/${row.certificate_holder_key ?? ""}`}
                onClick={e => {
                  e.preventDefault();

                  printPdf({
                    certificate: {
                      account_number: row.account_number,
                      type: row.type_description,
                      effective_date: row.effective_date,
                      process_date: row.process_date,
                      amend_id: row.certificate_holder_key
                    },
                    form_type: dictionary.DOCUMENT_FORM_TYPES.CERTIFICATE,
                    onStart: () => {
                      onPrintStart(docId);
                    },
                    onComplete: () => {
                      onPrintComplete(docId);
                    },
                    onError: onPrintError
                  });
                }}
              >
                Certificate
              </Anchor>
            </div>
          );
        }

        return <span>{row.type_description || ""}</span>;
      },
      sortable: true
    }
  ];
  return (
    <div className="certificate-history-table">
      <Table
        rowKey="id"
        data={certificateHistoryListWithIds}
        columns={getColumns()}
        itemsPerPage={20}
        showPagination
      />

      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </div>
  );
};

CertificateHistoryTable.propTypes = {
  certificateHistoryList: PropTypes.array
};
export default CertificateHistoryTable;
