import moment from "moment";
import API from "../../api";

const todaysDate = moment().format("YYYY-MM-DD");

export const fetchStatesList = () =>
  API.commercial().get(`/classifications/states`);

export const fetchProductsList = stateAbbreviation =>
  API.commercial().get(
    `/classifications/products?state=${stateAbbreviation}&effective_date=${todaysDate}`
  );

export const fetchClassesList = (stateAbbreviation, productCode) =>
  API.commercial().get(
    `/classifications?dec_code=${productCode}&effective_date=${todaysDate}&state=${stateAbbreviation}`
  );
