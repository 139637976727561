import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../../../components/Factory";
import { Translations } from "../../../../components/translations";
import { Table } from "@ufginsurance/ui-kit";

const AppQuoteStatusList = ({ submissions }) => {
  const headers = Translations.reports.tabUnderwriting.appQuoteStatus.headers;

  const columns = [
    {
      label: headers.customer,
      key: "customer"
    },
    {
      label: headers.quoteNumber,
      key: "quote_number"
    },
    {
      label: headers.received,
      key: "received_date",
      element: row => DateFormat(row.received_date, "MM/DD/YYYY")
    },
    {
      label: headers.status,
      key: "status"
    },
    {
      label: headers.statusDate,
      key: "",
      element: row => (
        <>{row.status_date && DateFormat(row.status_date, "MM/DD/YYYY")}</>
      )
    },
    {
      label: headers.policyNumber,
      key: "policy_number"
    }
  ];
  return (
    <div className="app-quote-status-container app-quote-status-report">
      {submissions.length > 0 && (
        <Table
          rowKey="id"
          columns={columns}
          data={submissions}
          showPagination
          itemsPerPage={20}
          className="app-quote-status-table"
        />
      )}
    </div>
  );
};

AppQuoteStatusList.propTypes = {
  submissions: PropTypes.array
};

export default AppQuoteStatusList;
