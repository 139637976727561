import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../../../components/Factory";
import { Table } from "@ufginsurance/ui-kit";
import { Translations } from "../../../../components/translations";

const WeeklyPaymentList = props => {
  const {
    fetchWeeklyPaymentViewReport,
    selectedAgencyCode,
    weeklyPaymentList
  } = props;

  const showReport = row => () => {
    fetchWeeklyPaymentViewReport(selectedAgencyCode, row.date);
  };

  const createColumns = () => [
    {
      key: "date",
      label: Translations.reports.tabClaims.weeklyReportsHeading,
      element: row => <span>{DateFormat(row.date, "MM/DD/YYYY")}</span>,
      className: "date"
    },
    {
      key: "",
      label: "",
      element: row => (
        <div>
          <button className="btn-as-link" onClick={showReport(row)}>
            {Translations.reports.tabClaims.viewReport}
          </button>
        </div>
      )
    }
  ];

  return (
    <div className="table">
      <Table
        rowKey="id"
        data={weeklyPaymentList}
        columns={createColumns()}
        itemsPerPage={20}
        showPagination
      />
    </div>
  );
};

WeeklyPaymentList.propTypes = {
  weeklyPaymentList: PropTypes.array.isRequired,
  fetchWeeklyPaymentViewReport: PropTypes.func.isRequired,
  selectedAgencyCode: PropTypes.string
};

export default WeeklyPaymentList;
