export const OTHER_CHANGES_MODAL_ACTIVATED = "OTHER_CHANGES_MODAL_ACTIVATED";
export const OTHER_CHANGES_MODAL_DISMISS = "OTHER_CHANGES_MODAL_DISMISS";

export const activateOtherChangesModal = () => ({
  type: OTHER_CHANGES_MODAL_ACTIVATED
});

export const dismissOtherChanges = dispatch => {
  dispatch({
    type: OTHER_CHANGES_MODAL_DISMISS
  });
};
