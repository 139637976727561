import React, { useState, useEffect, useContext } from "react";
import * as api from "../../../services/onlineQuotingService";
import _isEqual from "lodash/isEqual";
import {
  Button,
  Input,
  Select,
  Form,
  FormGroup,
  useForm,
  LoadingIndicator,
  DatePicker,
  formatDate
} from "@ufginsurance/ui-kit";
import { addUpdateArrayItem, guid, sortByProperty } from "../../shared/util";
import getAllLocations from "../location/getAllLocations";
import { DriverLicenseValidationRegex } from "../../shared/DriverLicenseValidationRegex";
import OqModal from "../../shared/OqModal";
import OnlineQuotingContext from "../../OnlineQuotingContext";

import "./DriverForm.scss";

const sortByPriority = arr => {
  return (arr || []).sort(sortByProperty("code", true)).map(c => {
    return { value: c.code, label: c.displayKey };
  });
};

const maxDate = new Date();

const DriverFormStep = ({ itemData, drivers, show, onHide }) => {
  const {
    updateCoverablesPromise,
    quoteIsUpdating,
    toastErrr,
    quoteData,
    supportingData
  } = useContext(OnlineQuotingContext);

  const [formMetadata, setFormMetadata] = useState();
  // const [spinnerSave, setSpinnerSave] = useState(false);
  // const [spinnerAdd, setSpinnerAdd] = useState(false);
  const primarylocation = (
    getAllLocations({ quoteData, supportingData }) || []
  ).find(l => l.isPrimary);
  const [driver, setDriver] = useState(itemData);
  const [_drivers, setDrivers] = useState(drivers);

  const convertDriverJsonDTOToForm = d => {
    const driver = {
      ...d,
      firstName: d.firstName,
      lastName: d.lastName,
      licenseState: d.licenseState,
      licenseNumber: d.licenseNumber,
      gender_UFG: d.gender_UFG,
      dateOfBirth: d.dateOfBirth
    };

    if (d.fixedId) {
      driver.fixedId = d.fixedId;
    }

    return driver;
  };

  const convertDriverToJsonDTO = d => {
    const driver = {
      ...d,
      firstName: d.firstName,
      lastName: d.lastName,
      licenseState: d.licenseState,
      licenseNumber: d.licenseNumber,
      gender_UFG: d.gender_UFG,
      dateOfBirth: formatDate(d.dateOfBirth, "OBJECT"),
      //non form fields
      driverStatus_UFG: "OK",
      activeDriver_UFG: true
    };

    if (d.fixedId) {
      driver.fixedId = d.fixedId;
    }

    return driver;
  };

  const clearAddNew = () => {
    setDriver(null);
    updateForm({
      values: {
        ...clearedValues,
        // the UI-kit datepicker is acting weird and needs this format to reset the input
        // TODO: fix the UI-kit
        dateOfBirth: { month: "0", day: "0", year: "0" }
      },
      errors: {}
    });
  };

  const handleFormSubmit = ({ addNew = false }) => {
    const driver = convertDriverToJsonDTO(values);
    const tempId = guid();
    driver.id = driver && driver.id ? driver.id : tempId;

    //if we are editing a driver and no values changed
    //do not submit an update
    if (!!driver?.fixedId && _isEqual(initialValues, values)) {
      if (!addNew) {
        onHide();
      } else {
        clearAddNew();
      }
      return false;
    }

    const ds = addUpdateArrayItem(
      (_drivers || []).map(d => {
        return {
          ...d,
          id: d.fixedId || tempId
        };
      }),
      { ...driver }
    );

    const isNew = !driver?.fixedId;

    updateCoverablesPromise({
      coverableType: "driver",
      coverables: { drivers: ds },
      action: isNew ? "Adding" : "Updating"
    })
      .then(({ data, error }) => {
        if (data && !error) {
          if (!addNew) {
            onHide();
          } else {
            const retDrivers =
              data?.lobData?.ca7CommAuto?.coverables?.commAutoDrivers || [];
            setDrivers(retDrivers);
            clearAddNew();
          }
        }
      })
      .catch(({ error }) =>
        toastErrr({
          action: "driver form step 1 > handleFormSubmit",
          misc: { drivers: _drivers },
          description: `Failed to ${isNew ? "Add" : "Update"} Driver`,
          error,
          displayMessage: `Failed to ${isNew ? "Add" : "Update"} Driver`
        })
      );
  };

  const clearedValues = {
    id: null,
    fixedId: null,
    firstName: "",
    lastName: "",
    licenseState: primarylocation?.address?.state,
    licenseNumber: "",
    gender_UFG: "",
    dateOfBirth: ""
  };

  const initialValues = !!driver
    ? convertDriverJsonDTOToForm(driver)
    : clearedValues;

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  useEffect(() => {
    if (!formMetadata) {
      api
        .getDriverFormMetadata("string")
        .then(result => {
          const data = result?.data?.formData;

          const formData = {
            licenseStateOptions: sortByPriority(data.states),
            genderOptions: sortByPriority(data.gender)
          };

          setFormMetadata(formData);

          // after updating the formMetadata, trigger an update on the form values to get the invalidFields
          updateForm({ values: {} });

          if (!data)
            toastErrr({
              action: "getDriverFormMetadata",
              description: "failed to load driver metadata"
            });
        })
        .catch(error => {
          toastErrr({
            action: "getDriverFormMetadata",
            description: "failed to load driver metadata",
            error
          });

          console.error(error);
        });
    }
  }, [formMetadata, updateForm, toastErrr]);

  const handleDriverLicenseValidate = ({ value }) => {
    const fieldErrors = [];
    if (!value) fieldErrors.push("This field is required.");
    if (value) {
      let regexObjectArry = [];
      let valid = false;
      const licenseState = formMetadata.licenseStateOptions.find(
        key => key.value === values.licenseState
      );
      let errorString = "";
      regexObjectArry = DriverLicenseValidationRegex[values?.licenseState];
      (regexObjectArry || []).forEach(r => {
        if (r.regex.test(value) === true) {
          valid = true;
        } else {
          errorString =
            errorString.length === 0
              ? errorString.concat(r.description)
              : errorString.concat(" or ").concat(r.description);
        }
      });
      if (!valid) {
        fieldErrors.push(
          licenseState?.label + " license formatting must match " + errorString
        );
      }
    }
    return fieldErrors;
  };

  const handleLicenseStateChange = ({ value }) => {
    //update form to empty out the license number and clear the errors
    //because each state has its own format
    updateForm({
      values: {
        licenseState: value,
        licenseNumber: ""
      },
      errors: { licenseState: [], licenseNumber: [] }
    });
  };

  // updating the license number should force alpha characters to uppercase
  // to match regex validation
  const handleLicenceNumberChange = ({ value, field }) => {
    updateForm({
      values: { [field]: (value || "").toUpperCase() },
      errors: { [field]: [] }
    });
  };

  return (
    <div>
      {show && (
        <OqModal
          className="oq__modal__coverable__driver"
          title="Driver Details"
          show={show}
          onHide={onHide}
          body={
            <>
              {!formMetadata ? (
                <LoadingIndicator />
              ) : (
                <Form
                  className="oq__form__driver__step1 oq__form"
                  context={form}
                >
                  <FormGroup groupErrors>
                    <Input
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.firstName}
                      required
                      size="25p"
                    />
                    <Input
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.lastName}
                      required
                      size="25p"
                    />
                    <DatePicker
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label="Date of Birth"
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.dateOfBirth}
                      format="MM/DD/YYYY"
                      required
                      size="25p"
                      maxDate={maxDate}
                    />
                    <Select
                      id="gender_UFG"
                      name="gender_UFG"
                      label="Gender"
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.gender_UFG}
                      options={formMetadata.genderOptions || []}
                      required
                      size="25p"
                    />
                  </FormGroup>

                  <FormGroup groupErrors>
                    <Select
                      id="licenseState"
                      name="licenseState"
                      label="License State"
                      onChange={handleLicenseStateChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.licenseState}
                      options={formMetadata.licenseStateOptions || []}
                      required
                      size="25p"
                    />
                    <Input
                      id="licenseNumber"
                      name="licenseNumber"
                      label="License Number"
                      onChange={handleLicenceNumberChange}
                      onBlur={handleOnBlur}
                      onValidate={handleDriverLicenseValidate}
                      value={values.licenseNumber}
                      required
                      disabled={!values.licenseState}
                      size="25p"
                    />
                  </FormGroup>

                  <FormGroup align="right">
                    <Button
                      variant="plain"
                      className="cancel"
                      onClick={() => {
                        onHide();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outline"
                      className="add-another"
                      disabled={invalidFields.length > 0 || quoteIsUpdating}
                      onClick={() => handleFormSubmit({ addNew: true })}
                    >
                      Save and Add New Driver
                    </Button>
                    <Button
                      variant="primary"
                      className="continue"
                      disabled={invalidFields.length > 0 || quoteIsUpdating}
                      onClick={() => handleFormSubmit({ addNew: false })}
                    >
                      Save
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </>
          }
        />
      )}
    </div>
  );
};

export default DriverFormStep;
