import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateQuoteProposal,
  fetchSubmissionList
} from "../../../actions/commercialLinesAction";
import { ContentHeader } from "@ufginsurance/ui-kit";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import QuoteProposals from "./QuoteProposals";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import {
  getisSpecialtyPortalView,
  getActiveSpecialtyCompanyBranch
} from "../../../portalview/changePortalViewStateSelectors";
import {
  getPastFetchedQuoteProposalsCode,
  getPastQuoteProposalsFilter,
  getPastFetchedQuoteProposalsSpecialtyCompanyBranch,
  areQuoteProposalsLoading
} from "../../home/commercial/CommercialTabSelectors";

const QuoteProposalContainer = ({
  quoteProposals,
  updateQuoteProposal,
  agencyCode,
  pastQuoteProposalsFilter,
  pastFetchedQuoteProposalsCode,
  areQuoteProposalsLoading,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch,
  activeSpecialtyCompanyBranch,
  loadingProposalDocumentIds
}) => {
  return (
    <div className="commercial">
      <ContentHeader>Quote Proposals</ContentHeader>
      <div>
        <QuoteProposals
          agencyCode={agencyCode}
          quoteProposals={quoteProposals}
          updateQuoteProposal={updateQuoteProposal}
          daysFilterTabs={[30, 60, 90]}
          sortableColumns={["client_name", "product", "created"]}
          pastQuoteProposalsFilter={pastQuoteProposalsFilter}
          pastFetchedQuoteProposalsCode={pastFetchedQuoteProposalsCode}
          pastFetchedQuoteProposalsSpecialtyCompanyBranch={
            pastFetchedQuoteProposalsSpecialtyCompanyBranch
          }
          areQuoteProposalsLoading={areQuoteProposalsLoading}
          activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
          loadingProposalDocumentIds={loadingProposalDocumentIds}
        />
      </div>
    </div>
  );
};

QuoteProposalContainer.propTypes = {
  quoteProposals: PropTypes.object.isRequired,
  updateQuoteProposal: PropTypes.func,
  agencyCode: PropTypes.string.isRequired,
  pastQuoteProposalsFilter: PropTypes.number,
  pastFetchedQuoteProposalsCode: PropTypes.string,
  areQuoteProposalsLoading: PropTypes.bool.isRequired,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch: PropTypes.string,
  activeSpecialtyCompanyBranch: PropTypes.string,
  loadingProposalDocumentIds: PropTypes.array
};

const mapStateToProps = state => {
  return {
    ...state.commercialLines,
    agencyCode: getSelectedAgencyCode(state),
    hasCommercialContract:
      sessionSelector.agencyHasCommercialLinesContract(state),
    isCancelledAgent: sessionSelector.isCancelledAgent(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    pastFetchedQuoteProposalsCode: getPastFetchedQuoteProposalsCode(state),
    pastQuoteProposalsFilter: getPastQuoteProposalsFilter(state),
    areQuoteProposalsLoading: areQuoteProposalsLoading(state),
    pastFetchedQuoteProposalsSpecialtyCompanyBranch:
      getPastFetchedQuoteProposalsSpecialtyCompanyBranch(state),
    activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
  };
};

const mapDispatchToProps = {
  updateQuoteProposal,
  fetchSubmissionList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteProposalContainer);
