import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { Dropdown } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import { getActiveSpecialtyCompanyBranch } from "../../../portalview/changePortalViewStateSelectors";
import { StateLongNames } from "../../../constants/dictionary";
import { findStateByName } from "../../../util/selectors";
import { getStates } from "../../services/documentsService";

const StatesDropdown = ({
  form,
  activeSpecialtyCompanyBranch,
  defaultAgentState
}) => {
  const [areStatesLoading, setAreStatesLoading] = useState();
  const [statesList, setStatesList] = useState([]);

  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  // Reset statesList on activeSpecialtyCompanyBranch change
  useEffect(() => {
    setStatesList([]);
  }, [activeSpecialtyCompanyBranch]);

  // Load statesList if statesList is empty
  useEffect(() => {
    if (statesList.length === 0 && !areStatesLoading) {
      const defaultAgentStateLongName = defaultAgentState
        ? StateLongNames[defaultAgentState]
        : null;

      setAreStatesLoading(true);
      getStates(activeSpecialtyCompanyBranch).then(
        res => {
          const data = res.data.map(d => ({
            label: d.state,
            value: d.id,
            state_abbreviation: d.state_abbreviation
          }));

          const defaultStateSelection =
            findStateByName(data, defaultAgentStateLongName) || data[0];

          setStatesList(data);
          updateForm({
            values: { selectedState: defaultStateSelection || "" },
            selectedProduct: "",
            ...defaultStateSelection
          });
          setAreStatesLoading(false);
        },
        error => {
          toast(
            <HelpDeskMessage
              display="anErrorOccurred"
              after={`(Error loading states): ${error.message}`}
            />,
            {
              position: "top-center",
              className: "error",
              autoClose: false,
              toastId: "statesError"
            }
          );
        }
      );
    }
  }, [
    activeSpecialtyCompanyBranch,
    areStatesLoading,
    defaultAgentState,
    statesList,
    updateForm
  ]);

  const handleStateChangeValue = ({ event, field, value }) => {
    handleOnChange({ event, field, value });
    updateForm({
      values: {
        selectedState: event.newValue,
        selectedProduct: "",
        keyword: ""
      },
      ...values
    });
  };

  return (
    <Dropdown
      label="Select State"
      id="selectedState"
      name="selectedState"
      placeholder="Loading data..."
      onChange={handleStateChangeValue}
      onBlur={handleOnBlur}
      onValidate={handleOnValidate}
      options={statesList}
      value={values.selectedState}
      isClearable={false}
      isSearchable
      isLoading={areStatesLoading}
      required
      size="25p"
    />
  );
};

StatesDropdown.propTypes = {
  form: PropTypes.object.isRequired,
  activeSpecialtyCompanyBranch: PropTypes.string,
  defaultAgentState: PropTypes.string
};

const mapStateToProps = state => ({
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state),
  defaultAgentState: sessionSelector.getActiveAgencyState(state)
});

export default connect(mapStateToProps)(StatesDropdown);
