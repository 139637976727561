import React, { useContext, useState, useEffect, useCallback } from "react";

import { locationDisplayName, sortByProperty } from "../../shared/util";
import { Panel, Button } from "@ufginsurance/ui-kit";
import { vehicleTypes, quotePath } from "../../shared/constants";
import vehicleCoverages from "../../step5/vehicle/vehicleCoverages";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import CoveragesInCards, {
  displayedCoverages
} from "../../step5/CoveragesInCards";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import "../../Step5RiskInfo.scss";

const Vehicles = ({ coverageExclusions }) => {
  const {
    quoteData,
    supportingData,
    getQuotePart,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);

  const history = useHistory();

  const [vehicles, setVehicles] = useState([]);
  const coverageFields = vehicle => {
    const vehicleTypeObj = vehicleTypes.find(
      t => t.key === (vehicle?.entityName || "").replace("entity.", "")
    );
    return (
      vehicleCoverages(quoteData, vehicle.fixedId, vehicleTypeObj) || []
    ).filter(c => c.selected === true);
  };

  const getCoveragebleFromStore = useCallback(
    path => {
      const obj = getQuotePart(path, true);
      return obj.map(l => ({
        ...l,
        id: l?.id || l?.fixedId || l?.fixedID
      }));
    },
    [getQuotePart]
  );

  useEffect(() => {
    const inVehicles =
      getCoveragebleFromStore(quotePath.vehicles).map(v => ({
        ...v,
        locationId: v.location
          ? v.location.id || v.location.fixedId || v.location.fixedID
          : null
      })) || [];

    setVehicles(inVehicles.sort(sortByProperty("fixedId")));
  }, [
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    getCoveragebleFromStore
  ]);

  return (
    <div>
      <Panel
        id="oq__coverable__container__vehicle"
        noBorder
        titlebar
        bgcolor="grey"
        className="oq__coverable__container oq__coverable__container__vehicle"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Vehicles ({vehicles.length})
            </span>{" "}
            <Button
              variant="outline"
              wrapperClassName="align-right"
              isLink
              inline
              className="link-not-bolded"
              onClick={() => {
                history.push({
                  pathname: routes.ONLINE_QUOTING_STEP5,
                  hash: "Vehicles"
                });
              }}
            >
              Back to Vehicles
            </Button>
          </div>
        }
      >
        <>
          {vehicles.sort(sortByProperty("displayName")).map(vehicle => {
            const coverages = displayedCoverages({
              coverages: coverageFields(vehicle),
              coverageExclusions
            });
            return (
              <Panel
                key={`container__vehicle__${vehicle?.fixedId}`}
                id={`container__vehicle__${vehicle?.fixedId}`}
                isOpen={false}
                rounded
                className="oq__coverable__card oq__coverable__card__vehicle"
                title={
                  <div>
                    <div className="oq__coverable__card__initial-content__container">
                      <span className="oq__coverable__card__initial-content">
                        <span className="oq__coverable__card__initial-content__title">
                          {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                        </span>
                        <span className="oq__coverable__card__initial-content__extended">
                          {locationDisplayName(vehicle.location)}
                        </span>
                      </span>
                    </div>
                  </div>
                }
              >
                <div className="oq__coverable__vehicle-wrapper">
                  {coverageFields(vehicle) && (
                    <div className="oq__coverable__card__content__coverages">
                      <CoveragesInCards
                        quoteData={quoteData}
                        coverageFields={coverages}
                        coverageExclusions={coverageExclusions}
                        id={`coverages__vehicle__${vehicle.id}`}
                        alwaysDisplay={false}
                        editScheduledItems={false}
                      />
                    </div>
                  )}
                </div>
              </Panel>
            );
          })}
        </>
      </Panel>
    </div>
  );
};

export default Vehicles;
