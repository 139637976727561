import React from "react";
import PropTypes from "prop-types";
import { Currency, Table } from "@ufginsurance/ui-kit";
import { Translations } from "../../translations";
import { toTitleCase, DateFormat } from "../../Factory";
import { v4 } from "uuid";

const activityDateSortCompare = (a, b) => {
  const dateA = new Date(a.activity_date);
  const dateB = new Date(b.activity_date);
  return a.activity_date === b.activity_date
    ? b.index - a.index
    : dateA.valueOf() - dateB.valueOf();
};

const getColumns = showBalance => {
  const columns = [
    {
      key: "policy_number",
      label: Translations.account_overview.billingDetailsTab.policy
    },
    {
      className: "activity_date",
      label: Translations.account_overview.billingDetailsTab.activity_date,
      element: row => {
        return <span>{DateFormat(row.activity_date)}</span>;
      },
      sortable: true,
      sortCompare: activityDateSortCompare
    },
    {
      key: "activity",
      className: "activity",
      label: Translations.account_overview.billingDetailsTab.activity,
      element: row => {
        return <span>{toTitleCase(row.activity)}</span>;
      },
      nowrap: true
    },
    {
      key: "effective_due_date",
      className: "effective_due_date",
      label: Translations.account_overview.billingDetailsTab.effective_date,
      element: row => {
        return <span>{DateFormat(row.effective_due_date)}</span>;
      }
    },
    {
      key: "amount",
      className: "amount",
      label: Translations.account_overview.billingDetailsTab.amount,
      element: row => {
        return <Currency amount={row.amount} />;
      },
      align: "right"
    }
  ];

  if (showBalance) {
    columns.push({
      key: "balance",
      className: "balance",
      label: Translations.account_overview.billingDetailsTab.balance,
      element: row => {
        return <Currency amount={row.balance} />;
      },
      align: "right"
    });
  }
  return columns;
};

const BillingDetails = ({ activities, showBalance }) => {
  const activitesWithUniqueId =
    activities?.map(activity => {
      return {
        ...activity,
        id: v4()
      };
    }) || [];
  return (
    <div>
      <Table
        rowKey="id"
        data={activitesWithUniqueId}
        columns={getColumns(showBalance)}
        itemsPerPage={20}
        initialDirection="desc"
        initialSortCompare={activityDateSortCompare}
        showPagination
      />
    </div>
  );
};

BillingDetails.propTypes = {
  activities: PropTypes.array.isRequired,
  showBalance: PropTypes.bool.isRequired
};

export default BillingDetails;
