import * as types from "./actionTypes";
import * as agencyBillEpayService from "../services/agencyBillEpayService";

const agencyBillPayStarted = () => ({
  type: types.FETCH_AGENCY_BILL_PAY_STARTED
});

const agencyBillPaySucceeded = data => ({
  type: types.FETCH_AGENCY_BILL_PAY_SUCCEEDED,
  payload: data
});

const agencyBillPayFailed = () => ({
  type: types.FETCH_AGENCY_BILL_PAY_FAILED
});

const billPayEnrollmentStarted = () => ({
  type: types.FETCH_BILL_ENROLLMENT_STARTED
});

const billPayEnrollmentSucceeded = data => ({
  type: types.FETCH_BILL_ENROLLMENT_SUCCEEDED,
  payload: data
});

const billPayEnrollmentFailed = () => ({
  type: types.FETCH_BILL_ENROLLMENT_FAILED
});

export const getAgencyBillPayNow = agency_code => dispatch => {
  dispatch(agencyBillPayStarted());
  return agencyBillEpayService.getAgencyBillPayNow(agency_code).then(
    res => {
      dispatch(agencyBillPaySucceeded(res.data));
    },
    () => {
      dispatch(agencyBillPayFailed());
    }
  );
};

export const getAgencyBillPayNowEnrollment = agency_code => dispatch => {
  dispatch(billPayEnrollmentStarted());
  return agencyBillEpayService.getAgencyBillPayNowEnrollment(agency_code).then(
    res => {
      if (res.data.token) {
        dispatch(billPayEnrollmentSucceeded(res.data));
        dispatch(getAgencyBillPayNow(agency_code));
      } else dispatch(billPayEnrollmentFailed());
    },
    () => {
      dispatch(billPayEnrollmentFailed());
    }
  );
};
