import { connect } from "react-redux";
import HelpDesk from "./helpDesk";
import {
  dismissHelpDesk,
  getIsActivatedSelector,
  fetchHelpDeskService,
  getisLoadingSelector,
  getHelpDeskErrorSelector
} from "./helpDeskAction";

const mapStateToProps = state => ({
  isActivated: getIsActivatedSelector(state),
  isLoading: getisLoadingSelector(state),
  helpDeskError: getHelpDeskErrorSelector(state)
});

const mapDispatchToProps = {
  dismissHelpDesk,
  fetchHelpDeskService
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDesk);
