export const POLICY_CENTER_POLICY_LENGTH = 11;
export const POLICY_CENTER_PREFIX_LENGTH = 3;

export const isOasisPolicyNumber = policyNumber =>
  !!policyNumber && policyNumber.length === POLICY_CENTER_POLICY_LENGTH;

export const getPrefix = policyNumber =>
  isOasisPolicyNumber(policyNumber)
    ? policyNumber.substring(0, POLICY_CENTER_PREFIX_LENGTH)
    : undefined;

export const stripPrefix = policyNumber =>
  isOasisPolicyNumber(policyNumber)
    ? policyNumber.substring(POLICY_CENTER_PREFIX_LENGTH)
    : policyNumber;
