import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import { getPolicyLossRunsUrl } from "../../../services/claimsReportsService";
import { Translations } from "../../../../components/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import * as accountholderService from "../../../../services/accountholderService";
import { calculateSearchType } from "../../../../actions/policies";
import { toast } from "react-toastify";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";

const accountOrPolicyNumberRegex = /^[A-Za-z0-9]{0,11}$/i;

export const isValidAccountOrPolicyNumberFormat = number =>
  accountOrPolicyNumberRegex.test(number);

const PolicyLossRunForm = props => {
  const { searchKeywordUpdatedReportClaims, iconEnable, activeAgencyCode } =
    props;

  const [values, setValues] = useState({
    name: "",
    number: ""
  });
  const [isLoadingPolicyTerms, setIsLoadingPolicyTerms] = useState(false);

  const { name, number } = values;

  const handleChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const handleNumberChange = e => {
    if (!isValidAccountOrPolicyNumberFormat(e.target.value)) {
      return;
    }

    handleChange(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (number !== "") {
      const searchType = calculateSearchType(number);
      if (searchType === "policy") {
        setIsLoadingPolicyTerms(true);
        accountholderService
          .searchPolicyTermsHistory(activeAgencyCode, number)
          .then(
            response => {
              setIsLoadingPolicyTerms(false);
              const policyTermsHistory = response.data ?? [];
              if (policyTermsHistory.length > 0) {
                window.open(getPolicyLossRunsUrl(number));
              } else {
                const options = {
                  position: "top-center",
                  className: "error",
                  autoClose: false
                };
                toast(
                  <HelpDeskMessage
                    before={
                      <>
                        Unfortunately, the policy number you entered could not
                        be found. Please ensure the number was entered
                        correctly.
                        <br />
                      </>
                    }
                    display="questionsOrAssistance"
                  />,
                  options
                );
              }
            },
            () => {
              setIsLoadingPolicyTerms(false);
              const options = {
                position: "top-center",
                className: "error",
                autoClose: false
              };
              toast(
                <HelpDeskMessage
                  before={
                    <>
                      Unfortunately, the policy number you entered could not be
                      found. Please ensure the number was entered correctly.
                      <br />
                    </>
                  }
                  display="questionsOrAssistance"
                />,
                options
              );
            }
          );
      } else {
        window.open(getPolicyLossRunsUrl(number));
      }
    } else {
      searchKeywordUpdatedReportClaims(name);
    }
  };

  return (
    <div className="reports-form__container">
      <div className="form-group has-feedback">
        <form onSubmit={handleSubmit} className="policy-loss-run-form">
          <div className="reports-form__note">
            {Translations.reports.tabClaims.createPolicyLossRun}
          </div>
          <FlexRow>
            <div>
              <label
                className="reports-form__search-label"
                htmlFor="numberInput"
              >
                {Translations.reports.tabClaims.AccountPolicySearchLabel}
              </label>
              <div className="reports-form__search-input">
                <input
                  data-testid="numberInput"
                  id="numberInput"
                  name="number"
                  type="text"
                  className="form-control"
                  maxLength="30"
                  onChange={handleNumberChange}
                  value={number}
                  placeholder={Translations.general.search_text}
                  disabled={name}
                />
                <button
                  type="button"
                  disabled={!number}
                  className="search-button"
                  onClick={handleSubmit}
                >
                  {iconEnable && <FontAwesomeIcon icon={faSearch} />}
                </button>
              </div>
            </div>
            <div className="orSpacer">or</div>
            <div>
              <label htmlFor="name" className="reports-form__search-label">
                {Translations.reports.tabClaims.NameSearchLabel}
              </label>
              <div className="reports-form__search-input">
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  maxLength="30"
                  onChange={handleChange}
                  value={name}
                  placeholder={Translations.general.search_text}
                  disabled={number}
                />
                <button
                  type="button"
                  disabled={!name}
                  className="search-button"
                  onClick={handleSubmit}
                >
                  {iconEnable && <FontAwesomeIcon icon={faSearch} />}
                </button>
              </div>
            </div>
            <Button
              type="submit"
              variant="primary"
              labelSpace
              disabled={(!number && !name) || isLoadingPolicyTerms}
              spinner={isLoadingPolicyTerms}
              spinnerPos="right"
            >
              {Translations.reports.tabClaims.runReport}
            </Button>
          </FlexRow>
        </form>
      </div>
    </div>
  );
};

PolicyLossRunForm.propTypes = {
  iconEnable: PropTypes.bool,
  searchKeywordUpdatedReportClaims: PropTypes.func.isRequired,
  activeAgencyCode: PropTypes.string.isRequired
};

PolicyLossRunForm.defaultProps = { iconEnable: true };

export default PolicyLossRunForm;
