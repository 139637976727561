import React from "react";
import ClaimStatusTable from "./ClaimStatusTable";
import ClaimsOpenedTable from "./ClaimsOpenedTable";
import ClaimPaymentsTable from "./ClaimPaymentsTable";
import PropTypes from "prop-types";

const ClaimsActivity = ({
  agencyCode,
  employeeUserName,
  claimsOpenedList,
  removeClaimOpened,
  claimsOpenedError,
  claimOpenedDeleteError,
  is109015Agent,
  claimsOpenedLoading,
  claimPaymentsList,
  removeClaimPayment,
  claimPaymentsError,
  claimPaymentDeleteError,
  claimsStatusesList,
  removeClaimStatus,
  claimsStatusDeleteError,
  claimsStatusesError,
  claimsStatusesLoading,
  claimPaymentsLoading
}) => {
  return (
    <div>
      <ClaimsOpenedTable
        agencyCode={agencyCode}
        employeeUserName={employeeUserName}
        claimsOpenedList={claimsOpenedList}
        removeClaimOpened={removeClaimOpened}
        claimsOpenedError={claimsOpenedError}
        claimOpenedDeleteError={claimOpenedDeleteError}
        is109015Agent={is109015Agent}
        claimsOpenedLoading={claimsOpenedLoading}
      />
      <br />
      <ClaimPaymentsTable
        agencyCode={agencyCode}
        is109015Agent={is109015Agent}
        claimPaymentsList={claimPaymentsList}
        removeClaimPayment={removeClaimPayment}
        claimPaymentsError={claimPaymentsError}
        claimPaymentDeleteError={claimPaymentDeleteError}
        claimPaymentsLoading={claimPaymentsLoading}
      />
      <br />
      <ClaimStatusTable
        agencyCode={agencyCode}
        claimsStatusesList={claimsStatusesList}
        removeClaimStatus={removeClaimStatus}
        claimsStatusDeleteError={claimsStatusDeleteError}
        claimsStatusesError={claimsStatusesError}
        is109015Agent={is109015Agent}
        claimsStatusesLoading={claimsStatusesLoading}
      />
    </div>
  );
};

ClaimsActivity.propTypes = {
  claimsStatusesList: PropTypes.array,
  removeClaimStatus: PropTypes.func.isRequired,
  agencyCode: PropTypes.string.isRequired,
  employeeUserName: PropTypes.string,
  claimsStatusesError: PropTypes.string,
  claimsStatusDeleteError: PropTypes.string,
  claimsStatusesLoading: PropTypes.bool,
  claimsOpenedList: PropTypes.array,
  removeClaimOpened: PropTypes.func.isRequired,
  claimsOpenedError: PropTypes.string,
  claimOpenedDeleteError: PropTypes.string,
  is109015Agent: PropTypes.bool,
  claimPaymentsList: PropTypes.array,
  removeClaimPayment: PropTypes.func.isRequired,
  claimPaymentsError: PropTypes.string,
  claimPaymentDeleteError: PropTypes.string,
  claimsOpenedLoading: PropTypes.bool.isRequired,
  claimPaymentsLoading: PropTypes.bool.isRequired
};

export default ClaimsActivity;
