import React, { useState, useContext, useEffect } from "react";
import LobCoverages from "./LobCoverages";
import { PanelGrid } from "@ufginsurance/ui-kit";
import LocationCards from "../../step5/location/LocationCards";
import BuildingCards from "../../step5/building/BuildingCards";
import getAllLocations from "../../step5/location/getAllLocations";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import StateSpecific from "./StateSpecific";

const BopSummary = ({ coverageExclusions, hasCyber, hasUmbrella }) => {
  const { quoteData, supportingData } = useContext(OnlineQuotingContext);

  const [locations, setlocations] = useState([]);

  useEffect(() => {
    if (quoteData && locations.length === 0) {
      const inlocations = getAllLocations({ quoteData, supportingData });
      setlocations(inlocations);
    }
  }, [locations, quoteData, supportingData]);

  return (
    <>
      <PanelGrid
        sizes={{ sm: 1, md: 2, lg: 2, xl: 2 }}
        className="oq__quote-summary__tab"
      >
        <div className="oq__quote-summary__column">
          <LobCoverages
            lobName={"BOP-Pro"}
            lobKey="bp7BusinessOwners"
            coverageExclusions={coverageExclusions}
          />
          {hasCyber && (
            <LobCoverages
              lobName={"Cyber"}
              lobKey="internetSecurity"
              coverageExclusions={coverageExclusions}
            />
          )}
          {hasUmbrella && (
            <LobCoverages
              lobName={"Umbrella"}
              lobKey="cuCommercialUmbrella"
              coverageExclusions={coverageExclusions}
            />
          )}
          <StateSpecific
            lobKey={"bp7BusinessOwners"}
            coverageExclusions={coverageExclusions}
          />
        </div>
        <div className="oq__quote-summary__column">
          <LocationCards
            locations={[...locations]}
            isQuoteSummary
            coverageExclusions={coverageExclusions}
          />
          <BuildingCards
            isOpen={false}
            locations={locations}
            isQuoteSummary
            coverageExclusions={coverageExclusions}
          />
        </div>
      </PanelGrid>
    </>
  );
};

export default BopSummary;
