import "./BuildingForms.scss";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Select,
  FlexRow,
  Form,
  FormGroup,
  Icon,
  Input,
  RadioList,
  LoadingIndicator,
  Popover,
  Checkbox,
  useForm,
  Alert
} from "@ufginsurance/ui-kit";
import _isEqual from "lodash/isEqual";
import { useFlags } from "launchdarkly-react-client-sdk";
import { convertStatesAndAbbreviations } from "../../../components/Factory";
import * as api from "../../../services/onlineQuotingService";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { contractorLiabilityExposureMinimums } from "../../shared/constants";
import { isKentuckyMineSubsidence } from "./kentuckyDetails";
import { floridaSelectedLocationWindHailIsNotApplicable } from "./floridaDetails";
import {
  boolToYN,
  guid,
  locationDisplayName,
  removeDuplicates,
  sortByProperty,
  YNToBool,
  stringToCurrency
} from "../../shared/util";

const sortByName = arr => {
  return (arr || []).sort(sortByProperty("name")).map(c => {
    return { value: c.code, label: c.name };
  });
};

const currentYear = new Date().getFullYear();

const BuildingFormStep1 = ({
  itemData,
  onNextStep,
  locations,
  onCancel,
  defaultLocation
}) => {
  const {
    quoteData,
    updateCoverablesPromise,
    supportingData,
    updateSupportingDataPromise,
    toastErrr,
    quoteIsUpdating
  } = useContext(OnlineQuotingContext);

  const [formMetadata, setFormMetadata] = useState();
  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [structureTypeOptions, setStructureTypeOptions] = useState([]);

  //below states control the visibility of some fields
  const [condSqrFtDisplay, setCondSqrFtDisplay] = useState(false);
  const [structureTypeDisplay, setStructureTypeDisplay] = useState(false);
  const [liabExpDisplay, setLiabExpDisplay] = useState({});
  const [condoByLawDiaplay, setCondoByLawDisplay] = useState({});
  const [townhByLawDiaplay, setTownhByLawDisplay] = useState({});
  const [mineSubsidenceDisplay, setMineSubsidenceDisplay] = useState();
  const [prefillValidated, setPreFillValidated] = useState();

  const { proQuoteFloridaWindHailOnBuilding } = useFlags();

  const getLocationByBp7 = bp7LocationFixedID =>
    locations?.find(
      l => Number(l.bp7LocationFixedID) === Number(bp7LocationFixedID)
    ) || {};

  const convertBuildingJsonDTOToForm = b => {
    // b => building

    // when form is saved, empty years are saved as Number("") which becomes 0
    // so when we load from data, make sure not to make the values for years 0
    const numValueNotZeroAndIsString = val => {
      if (!!val) return String(val);
      return "";
    };

    let building = {
      ...b,
      locationId: String(b.locationId),
      numberOfStories: b.numberOfStories || "", //Number
      description: b.description || "",
      constructionType: b?.constructionType || "",
      roofType: b?.roofType || "",
      yearBuilt: numValueNotZeroAndIsString(b.yearBuilt), //Number
      yearRoofingReplaced: numValueNotZeroAndIsString(b.yearRoofingReplaced), //Number
      yearPlumbingReplaced: numValueNotZeroAndIsString(b.yearPlumbingReplaced), //Number
      yearHeatingReplaced: numValueNotZeroAndIsString(b.yearHeatingReplaced), //Number
      yearWiringReplaced: numValueNotZeroAndIsString(b.yearWiringReplaced), //Number
      structureType: b?.structureType || "",
      totalCondominiumBuildingSquareFootage: numValueNotZeroAndIsString(
        b.totalCondominiumBuildingSquareFootage
      ),
      basementPresent: YNToBool(b.basementPresent), //String "Yes"/"No"
      automaticSprinklerSystemPresent: YNToBool(
        b.automaticSprinklerSystemPresent
      ), //String "Yes"/"No"
      mineSubsidenceApplies: YNToBool(b.mineSubsidenceApplies), //String "Yes"/"No"
      // https://ufginsurance.atlassian.net/browse/OOQ-10349
      // ContentsOnlyIndicator is evaulated backwards because we changed the label to "Add building coverage"
      // ... when it's checked then ContentsOnlyIndicator needs to be 'false'
      ContentsOnlyIndicator:
        supportingData?.buildings?.[b.fixedId]?.ContentsOnlyIndicator !==
        undefined
          ? !supportingData?.buildings?.[b.fixedId]?.ContentsOnlyIndicator
          : true,
      // FLORIDA WINDHAIL field
      floridaWindHail:
        supportingData?.locations?.[getLocationByBp7(b?.locationId)?.fixedID]
          ?.floridaWindHail
    };

    if (b.fixedId) {
      building.fixedId = b.fixedId;
    }

    // TODO: HOW TO HANDLE GROSS SALES WITH THE CLASS FIELDS
    const classCodes = (b.classifications || []).map(c => {
      return `${c.classCode}|${
        c.northAmericanIndustryClassificationSystemNAICSCode
      }|${
        c.standardIndustrialClassificationSICCode
      }|${c?.classDescription?.replace(/\s/g, "")}`;
    });

    if (b.classifications && b.classifications.length) {
      building = {
        ...building,
        classCodes
      };
    }

    // populate class Specific data
    const classFields = {};

    (b.classifications || []).forEach(cls => {
      const identifier = `${cls.classCode}|${
        cls.northAmericanIndustryClassificationSystemNAICSCode
      }|${
        cls.standardIndustrialClassificationSICCode
      }|${cls?.classDescription?.replace(/\s/g, "")}`;

      classFields[`${identifier}~classificationSquareFootage`] =
        cls?.classificationSquareFootage
          ? String(cls.classificationSquareFootage)
          : "";

      classFields[`${identifier}~exposure`] = cls?.exposure
        ? String(cls.exposure)
        : "";
      classFields[`${identifier}~grossSales_UFG`] = cls?.grossSales_UFG
        ? String(cls.grossSales_UFG || "")
        : "";

      classFields[`${identifier}~condoBylaws`] = YNToBool(cls.CondoBylaws);
      classFields[`${identifier}~condoResidence`] = YNToBool(
        cls.condoResidence
      );
      classFields[`${identifier}~townhouseBylaw`] = YNToBool(
        cls.townhouseBylaw
      );
      classFields[`${identifier}~townhouseAssoc`] = YNToBool(
        cls.townhouseAssoc
      );
    });

    // remove the classifications array - it's not needed
    delete building.classifications;

    if (Object.keys(classFields).length)
      building = {
        ...building,
        ...classFields
      };

    return building;
  };

  // b=> building
  const convertBuildingToJsonDTO = b => {
    // if we're not setting the year values, make them null
    const yearsNullIfZero = val => {
      if (Number(val) === 0) return null;
      return Number(val);
    };

    const building = {
      ...b,
      description: b.description,
      yearBuilt: Number(b.yearBuilt),
      bldgCodeEffGrade: "Ungraded",
      bldgCodeEffGradeClass: "Not Graded",
      raboptypeLiabilityLessors: "Smoothed",
      yearRoofingReplaced: yearsNullIfZero(b.yearRoofingReplaced),
      yearPlumbingReplaced: yearsNullIfZero(b.yearPlumbingReplaced),
      yearHeatingReplaced: yearsNullIfZero(b.yearHeatingReplaced),
      yearWiringReplaced: yearsNullIfZero(b.yearWiringReplaced),
      numberOfStories: Number(b.numberOfStories),
      mineSubsidenceApplies: boolToYN(b.mineSubsidenceApplies),
      constructionType: b.constructionType,
      roofType: b.roofType,
      locationId: b.locationId,
      rabopwanted: "Yes",
      structureType: b.structureType,
      totalCondominiumBuildingSquareFootage:
        b.totalCondominiumBuildingSquareFootage,
      automaticSprinklerSystemPresent: boolToYN(
        b.automaticSprinklerSystemPresent
      ),
      basementPresent: boolToYN(b.basementPresent), //String "Yes"/"No"
      constructedInOrAfter1978: boolToYN(Number(b.yearBuilt) >= 1978),
      /*
      FLORIDA WINDHAIL
      ContentsOnlyIndicator is not allowed (===true) when Florida WindHail question is "No"
      So, when floridaWindHail === "No", then ignore the ContentsOnlyIndicator checkbox value and set to true
      if floridaWindHail === "Yes" or "" (because it's hidden), then obey the ContentsOnlyIndicator checkbox
      */
      ContentsOnlyIndicator: !!b.floridaWindHail
        ? b.floridaWindHail === "No"
          ? true
          : !b.ContentsOnlyIndicator
        : !b.ContentsOnlyIndicator // OOQ-10349 - see notes in 'convertBuildingJsonDTOToForm'
    };

    // TODO: HOW TO HANDLE GROSS SALES WITH THE CLASS FIELDS
    building.classifications = (b?.classCodes || []).map(c => {
      // if the class exists on the building already, then use the existing class as the base object
      const existingClassOnBuilding = (itemData?.classifications || []).find(
        f => f.classCode === c.split("|")[0]
      );
      // use the class data from metadata
      const classDataFromMetadata = (formMetadata?.class_codes || []).find(
        f => c === f.identifier
      );
      const classObj = existingClassOnBuilding || classDataFromMetadata;

      if (values[`${c}~classificationSquareFootage`])
        classObj.classificationSquareFootage = Number(
          values[`${c}~classificationSquareFootage`]
        );
      if (values[`${c}~exposure`])
        classObj.exposure = Number(values[`${c}~exposure`]);
      if (values[`${c}~grossSales_UFG`])
        classObj.grossSales_UFG = Number(values[`${c}~grossSales_UFG`]);
      if (values[`${c}~condoBylaws`])
        classObj.condoBylaws = Number(values[`${c}~condoBylaws`]);
      if (values[`${c}~condoResidence`])
        classObj.condoResidence = Number(values[`${c}~condoResidence`]);
      if (values[`${c}~townhouseBylaw`])
        classObj.townhouseBylaw = Number(values[`${c}~townhouseBylaw`]);
      if (values[`${c}~townhouseAssoc`])
        classObj.townhouseAssoc = Number(values[`${c}~townhouseAssoc`]);

      return classObj;
    });

    return building;
  };

  const handleFormSubmit = () => {
    const building = convertBuildingToJsonDTO(values);
    const tempId = guid();
    building.id = !!itemData?.id ? itemData.id : itemData?.fixedId || tempId;
    building.locationId = Number(building.locationId);

    const isNew = !itemData?.fixedId;

    if (!!itemData?.fixedId && _isEqual(initialValues, values)) {
      // no changes made, continue to next step without sending changes to PC
      onNextStep(building);
    } else {
      updateCoverablesPromise({
        coverableType: "building",
        coverables: { building },
        action: isNew ? "Adding" : "Updating"
      })
        .then(({ data, error }) => {
          if (data && !error) {
            //if new building get that building
            const coverableId =
              itemData?.fixedId ||
              data.newCoverableIds.sort((a, b) => b - a)[0]; //OOQ-4042 sort list desc and use the first item.

            const selectedLocationId = getLocationByBp7(
              building.locationId
            )?.fixedID;

            // find building in fresh data
            const locationInDto =
              data.lobData.bp7BusinessOwners.coverables.locations.find(
                l => Number(l.fixedID) === Number(selectedLocationId)
              );
            const updatedBuildingItem = locationInDto.buildings.find(
              b => Number(b.fixedId) === Number(coverableId)
            );

            /**
             * FLORIDA WINDHAIL - save value in supportindData
             * in Florida we have to save the special windhail radio value...
             * once it's set, we don't need to save it again
             */
            if (
              selectedLocationState === "FL" &&
              selectedLocationId &&
              supportingData?.locations?.[selectedLocationId]
                ?.floridaWindHail === undefined
            ) {
              updateSupportingDataPromise({
                dataToMergeAndSave: {
                  locations: {
                    ...(supportingData?.locations || {}),
                    [selectedLocation.fixedID]: {
                      floridaWindHail: values?.floridaWindHail,
                      address: locationInDto?.address?.addressLine1
                    }
                  }
                }
              }).finally(() => onNextStep(updatedBuildingItem));
            } else {
              onNextStep(updatedBuildingItem);
            }
          }
        })
        .catch(({ error }) =>
          toastErrr({
            action: "save building on step 1",
            description: "unable to add/update building",
            error,
            misc: { building },
            displayMessage: `Failed to ${isNew ? "add" : "update"} building.`
          })
        );
    }
  };

  // FLORIDA WINDHAIL value saved on location in Supporting Data

  // default form values
  const initialValues = !!itemData
    ? convertBuildingJsonDTOToForm(itemData)
    : {
        classCodes: [],
        locationId: defaultLocation ? String(defaultLocation) : "",
        numberOfStories: "",
        description: "",
        constructionType: "",
        roofType: "",
        yearBuilt: "",
        yearRoofingReplaced: "",
        yearPlumbingReplaced: "",
        yearHeatingReplaced: "",
        yearWiringReplaced: "",
        structureType: "",
        totalCondominiumBuildingSquareFootage: "",
        basementPresent: false, //String "Yes"/"No"
        automaticSprinklerSystemPresent: false, //String "Yes"/"No"
        condoBylaws: false, //String "Yes"/"No"
        townhouseBylaw: false, //String "Yes"/"No"
        mineSubsidenceApplies: false, //String "Yes"/"No"
        ContentsOnlyIndicator: true, // OOQ-10349 - see notes in 'convertBuildingJsonDTOToForm'
        floridaWindHail:
          supportingData?.locations?.[
            getLocationByBp7(defaultLocation)?.fixedID
          ]?.floridaWindHail || "" // FLORIDA WINDHAIL
      };

  // setup location dropdowns. make sure only BP7 locations are used.
  const locationOptions = (locations || [])
    .filter(loc => !!loc?.bp7LocationFixedID)
    .map(loc => {
      return {
        value: String(loc.bp7LocationFixedID),
        label: locationDisplayName(loc)
      };
    });

  // if there is only one location select it by default.
  if (locationOptions.length === 1) {
    const onlyLocationId = locationOptions[0].value.toString();
    initialValues.locationId = onlyLocationId;
    // FLORIDA WINDHAIL - setting default value when only 1 location option
    initialValues.floridaWindHail =
      supportingData?.locations?.[getLocationByBp7(onlyLocationId)?.fixedID]
        ?.floridaWindHail || "";
  }

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    errors,
    invalidFields,
    validateFields
  } = form;

  // get the current location selected
  const selectedLocation = getLocationByBp7(values?.locationId);

  const locationState = selectedLocation?.address?.state
    ? convertStatesAndAbbreviations(selectedLocation?.address?.state, "name")
    : "";

  const isKentucyMineSub = isKentuckyMineSubsidence(selectedLocation);

  const handleClassExposureValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      // field looks like: "50381|422930|5193|Florists-Distributors~condoBylaws"
      const classCode = field.split("~")[0];
      const minExp = getContractorExposureMinimum({
        classCode,
        locationId: values.locationId
      });
      const numberValue = Number((value || "").replace(/\$|,/, ""));
      if (minExp > 0 && numberValue < minExp)
        fieldErrors.push(
          `This field must be at least ${stringToCurrency(minExp, 0)}.`
        );
      if (minExp === 0 && numberValue <= 0)
        fieldErrors.push(`This field must be greater than $0.`);
    }

    return fieldErrors;
  };

  const handleClassSpeficValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      if (Number(value) <= 0)
        fieldErrors.push(`This field must be greater than 0.`);
    }
    return fieldErrors;
  };

  const selectedLocationState = locations?.find(
    l => Number(l.bp7LocationFixedID) === Number(values.locationId)
  )?.address?.state;

  const handleConstructionTypeValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    //OOQ-14643 FL risk building before 2000 with joisted Masonry or Fragment
    if (
      selectedLocationState === "FL" &&
      (value === "Frame Construction" || value === "Joisted Masonry")
    ) {
      fieldErrors.push(
        "The construction types 'Frame Construction' and 'Joisted Masonry' are ineligible in Florida."
      );
    }
    return fieldErrors;
  };

  const handleYearValidate = ({ field, value, validation }, label) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      const y = !isNaN(value) ? Number(value) : null;
      if (!y || y < 1000) fieldErrors.push(`${label} is not a valid year.`);

      // OOQ-14643 FL risk building before 2001 with joisted Masonry or Fragment
      if (selectedLocationState === "FL" && Number(value) < 2001)
        fieldErrors.push(
          "Buildings built before 2001 are ineligible in Florida."
        );
      if (y && y > currentYear) {
        fieldErrors.push(`${label} cannot be a year in the future.`);
      }
    }

    return fieldErrors;
  };

  const handleYearUpdatesValidate = ({ field, value, validation }, label) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      const y = !isNaN(value) ? Number(value) : null;
      if (!y || y < 1000) fieldErrors.push(`${label} must be a valid value.`);
      else if (y && y > currentYear) {
        fieldErrors.push(`${label} cannot be a year in the future.`);
      }

      // Year restrictions : https://ufginsurance.atlassian.net/browse/OOQ-14708
      // new Year restrictions : https://ufginsurance.atlassian.net/browse/OOQ-14831
      else if (
        label === "Year Heating Replaced" ||
        label === "Year Plumbing Replaced"
      ) {
        if (Number(value) < Number(values?.yearBuilt)) {
          fieldErrors.push(`${label} must be a valid value.`);
        }
        return fieldErrors;
      } else if (
        /** else if - field is one of:
         * Year Roofing Replaced
         * Year Wiring Replaced
         */ currentYear - Number(values?.yearBuilt) >= 20 && // 20 years or older
        currentYear - Number(value) > 20
      )
        fieldErrors.push(
          `Buildings over 20 years old must have updates to ${label} within the last 20 years.`
        );
    }
    return fieldErrors;
  };

  const handleOnChangeYearBuilt = field => {
    if (currentYear - Number(field.value) < 20 && Number(field.value) > 1900) {
      updateForm({
        values: {
          yearBuilt: field.value,
          yearHeatingReplaced: "",
          yearPlumbingReplaced: "",
          yearRoofingReplaced: "",
          yearWiringReplaced: ""
        },
        errors: {
          yearHeatingReplaced: [],
          yearPlumbingReplaced: [],
          yearRoofingReplaced: [],
          yearWiringReplaced: []
        }
      });
    } else if (
      // entered a valid date... and if any of the updates fields have a value
      // this prevents triggering the validation when creating a new building
      Number(field.value) > 1900 &&
      (!!values?.yearHeatingReplaced ||
        !!values?.yearPlumbingReplaced ||
        !!values?.yearRoofingReplaced ||
        !!values?.yearWiringReplaced)
    ) {
      handleOnChange(field);
      setTimeout(() => {
        validateFields([
          "yearHeatingReplaced",
          "yearPlumbingReplaced",
          "yearRoofingReplaced",
          "yearWiringReplaced"
        ]);
      }, 500);
      // this delay is required because the validation won't work if the fields are invisible
      // this helps make sure that the validation triggers after the fields are displayed after a valid year is inputted
    } else {
      handleOnChange(field);
    }
  };

  const getContractorExposureMinimum = useCallback(
    ({ classCode, locationId, formData }) => {
      const usedMetadata = formMetadata || formData;

      // OOQ-7014 setting defaults for liability exposure
      // if it's a contractor class, set a min, fall back to a min of 0 for all other class types
      const contractorClass = (usedMetadata?.class_codes || []).find(
        x => x.identifier === classCode && x.classPropertyType === "Contractor"
      );
      const selectedLocation = locations.find(
        l => Number(l.bp7LocationFixedID) === Number(locationId)
      );

      const exposureMinimum = contractorClass
        ? contractorLiabilityExposureMinimums[selectedLocation?.address?.state]
            ?.minimum
        : 0;

      return exposureMinimum;
    },
    [formMetadata, locations]
  );

  // a function to add the class code to the building
  // ... used when:
  //  1. the a new building form is loaded and there is only 1 class code
  //  2. when the user changes the classes or locations
  const updateClassCodeFields = useCallback(
    ({ classCode, locationId, formData }) => {
      // OOQ-7014 Contractor classes have a custom min for exposure
      const minExp = String(
        getContractorExposureMinimum({
          classCode,
          locationId,
          formData
        })
      );

      // add values to form for class-specific fields
      // ...but only if they don't already exist in the form
      const classFields = {};

      if (!values[`${classCode}~classificationSquareFootage`])
        classFields[`${classCode}~classificationSquareFootage`] = "";

      if (!values[`${classCode}~exposure`])
        classFields[`${classCode}~exposure`] = minExp !== "0" ? minExp : "";

      if (!values[`${classCode}~grossSales_UFG`])
        classFields[`${classCode}~grossSales_UFG`] =
          minExp !== "0" ? minExp : "";

      if (!values[`${classCode}~condoBylaws`])
        classFields[`${classCode}~condoBylaws`] = false;

      if (!values[`${classCode}~condoResidence`])
        classFields[`${classCode}~condoResidence`] = false;

      if (!values[`${classCode}~townhouseBylaw`])
        classFields[`${classCode}~townhouseBylaw`] = false;

      if (!values[`${classCode}~townhouseAssoc`])
        classFields[`${classCode}~townhouseAssoc`] = false;

      return classFields;
    },
    [getContractorExposureMinimum, values]
  );

  // when the clas or location changes, add and update class-related fields
  const handleClassOrLocationChange = ({ field, value }) => {
    // if we have at least one class and one location selected in the form
    // then we update the changed field and update the class-specific fields
    const allClassCodes =
      field === "classCodes" ? value : values.classCodes || [];
    const locationId = field === "locationId" ? value : values.locationId || "";

    if (allClassCodes.length && !!locationId) {
      let newValues = {};

      // check if new class is added if so initialize the class specific object
      (allClassCodes || []).forEach(classCode => {
        newValues = {
          ...newValues, // important for when there are multiple classes on a location
          ...updateClassCodeFields({ classCode, locationId })
        };
      });

      updateForm({
        values: {
          [field]: value,
          ...newValues
        },
        // clear the errors for these fields
        // .. because they'll automatically come back if the user removes a class
        errors: { classCodes: [], locationId: [] }
      });
    } else {
      // just let the class or location field update without doing anything special
      handleOnChange({ field, value });
    }
  };

  /**
   * FLORIDA WINDHAIL
   * when the selected location value is changed,
   * update the value of the special Florida input: floridaWindHail
   * based on the supportingData value (if it's in SupportingData)
   */
  useEffect(() => {
    if (
      selectedLocationState === "FL" &&
      selectedLocation?.fixedID &&
      values.floridaWindHail !==
        (supportingData?.locations?.[selectedLocation?.fixedID]
          ?.floridaWindHail || "")
    ) {
      setTimeout(() =>
        updateForm({
          values: {
            floridaWindHail:
              supportingData?.locations?.[selectedLocation?.fixedID]
                ?.floridaWindHail || ""
          }
        })
      );
    }
    // useEffect should only observe the location selection
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationState, values?.locationId]);

  /**
   * if building is a BOP Prefill and has invalid fields, then display the errors
   */
  useEffect(() => {
    if (
      !prefillValidated &&
      itemData?.sourceOfService === "prefill" &&
      formMetadata
    ) {
      //validate fields with values
      validateFields(Object.keys(values).filter(f => values[f]));
      setPreFillValidated(true);
    }
  }, [
    formMetadata,
    prefillValidated,
    setPreFillValidated,
    itemData?.sourceOfService,
    validateFields,
    values
  ]);

  // get the formMetadata from the API and save in the formMetadata state
  useEffect(() => {
    if (!formMetadata && !loadingMetadata) {
      setLoadingMetadata(true);
      api
        .getBuildingFormMetadata(quoteData.quoteID)
        .then(result => {
          const data = result?.data?.formData;

          /*  construct data for the class code options  */
          const classCodeOptionPrimary = data.class_codes
            .filter(c => c.classCode === supportingData?.classCode?.code)
            .map(c => ({
              ...c, //keep all the info for letter search
              value: c.identifier,
              label: c.classDescription
            }));
          const classCodeOptionAdditional = data.class_codes
            .filter(c => c.classCode !== supportingData?.classCode?.code)
            .map(c => ({
              ...c,
              value: c.identifier,
              label: c.classDescription
            }))
            .sort((a, b) =>
              a?.classDescription < b?.classDescription ? -1 : 0
            );
          const groupedOptions = [
            {
              label: "Primary Class Code",
              options: classCodeOptionPrimary
            },
            {
              label: "Additional Class Codes",
              options: classCodeOptionAdditional
            }
          ];
          /* end class code options */

          const formData = {
            ...data,
            constructionTypeOptions: sortByName(data.constructionType),
            roofTypeOptions: sortByName(data.roofType),
            structureType: data.structureType,
            classCodesOptions: (data.class_codes || []).map(c => ({
              ...c, //keep all the info for letter search
              value: c.identifier,
              label: c.classDescription
            })),
            classCodesOptionsByGroup: groupedOptions
          };

          setFormMetadata(formData);

          let newValues = {};
          const classCode = data?.class_codes[0]?.identifier;

          // if there's only one class, then select it
          if (data.class_codes.length === 1) newValues.classCodes = [classCode];

          // if there's only one class and one location...  then update the class-specific fields
          if (
            locations.length === 1 &&
            data.class_codes.length === 1 &&
            values.classCodes.length === 0
          ) {
            newValues = {
              ...newValues,
              ...updateClassCodeFields({
                classCode,
                locationId: locations[0].bp7LocationFixedID,
                formData
              })
            };
          }

          // after updating the formMetadata, trigger an update on the form values to get the invalidFields
          if (Object.keys(newValues).length) updateForm({ values: newValues });

          if (!data)
            toastErrr({
              action: "building step1 > useEffect",
              description: "building metadata issue"
            });
        })
        .catch(error => {
          toastErrr({
            action: "building step1 > useEffect",
            description: "building metadata issue",
            error
          });

          //alert("API failure: Unable to load building metadata.");
          console.error(error);
          setFormMetadata({}); // set metadata to empty object to prevent infinite looping of trying to load data
          onCancel(); // close the modal if metadata fails because the user can't do anything
        })
        .finally(() => setLoadingMetadata(false));
    }
  }, [
    onCancel,
    updateClassCodeFields,
    formMetadata,
    quoteData,
    supportingData,
    updateForm,
    values,
    toastErrr,
    loadingMetadata,
    locations
  ]);

  // set the class-specific fields visibile based on class selected
  useEffect(() => {
    if (
      formMetadata &&
      values?.locationId &&
      (values?.classCodes || []).length
    ) {
      let officeConPropType = false;
      let otherPropType = false;

      const newFormValues = { values: {}, errors: {} };

      //initialize class specific visibility state, to make sure state is
      //redone each time useeffect run for class code changes
      const newLiabExpDisplay = {};
      const newCondoByLawDisplay = {};
      const newTownhByLawDisplay = {};
      const newClassSqFtPrefilled = {};
      let newStructureTypeOptions = [];
      const propertyTypesBySelectedClass = {};
      let newMinSubDisplay = false;

      // find the mineSubsidence value
      const jurisdictionCoverages = (
        quoteData?.lobData?.bp7BusinessOwners?.coverages
          ?.jurisdictionClausesGroups || []
      ).find(
        c =>
          c.entityName === "entity.BP7Jurisdiction" &&
          c.coverableName === locationState
      );

      const mineSubsidenceCoverageSelected = (
        jurisdictionCoverages?.clausesGroups?.coverages || []
      ).find(c => c.name.includes("Mine Subsidence") && c.selected);

      const mineSubsidenceValue = mineSubsidenceCoverageSelected?.terms?.find(
        c => c.publicID === "BP71"
      )?.chosenTermValue;

      (values?.classCodes || []).forEach(classCode => {
        //set bop prefill indicator
        const classCd = classCode.split("|")[0];
        const classDataDto = itemData?.classifications?.find(
          key => key.classCode === classCd
        );
        if (classDataDto && classDataDto.classificationSquareFootageOrig) {
          newClassSqFtPrefilled[classCode] = true;
        }

        //set other visibility indicators
        const classData = formMetadata?.class_codes?.find(data => {
          return data.identifier === classCode;
        });

        if (classData?.classPropertyType === "Office Condominium") {
          officeConPropType = true;
        } else {
          otherPropType = true;
        }

        //set exposure visibility
        if (
          classData?.liabilityExposureBase === "Annual Gross Sales" ||
          classData?.liabilityExposureBase === "Annual Payroll"
        ) {
          newLiabExpDisplay[classCode] = {
            displayField: true,
            displayIcon: classData?.classPropertyType === "Contractor"
          };
        }

        //set Condo by law visibility
        if (
          locationState &&
          locationState === "Pennsylvania" &&
          classData?.classDescription ===
            "Condominiums - Residential Condominium (Association risk only)"
        ) {
          newCondoByLawDisplay[classCode] = true;
        }

        //set townhouse by law visibility
        if (
          locationState &&
          locationState === "Minnesota" &&
          (classData?.classDescription ===
            "Townhouses or Similar Associations - Over 4 families with mercantile or office occupancy" ||
            classData?.classDescription ===
              "Townhouses or Similar Associations - Over 4 families with no mercantile or office occupancy" ||
            classData?.classDescription ===
              "Townhouses or Similar - 4 families or less, w/mercantile or office occupancy - lessor's risk only")
        ) {
          newTownhByLawDisplay[classCode] = true;
        }

        // hide mine Subsidence Applies unless location is...
        newMinSubDisplay =
          locationState === "Illinois" ||
          locationState === "Ohio" ||
          locationState === "Indiana";

        // set StructureType Options
        if (mineSubsidenceValue) {
          (formMetadata?.structureType || []).forEach(s => {
            // keep track of the classes property types so that we can tell if there are multiple classes selected
            propertyTypesBySelectedClass[classData?.classPropertyType] = true;

            // compoaring property types from the selected classes with the data in formData
            if (
              (s?.[mineSubsidenceValue]?.propertyTypes || []).includes(
                classData?.classPropertyType
              )
            )
              newStructureTypeOptions.push(s.value);
          });

          // if the property type is Mixed, then hard-code the options
          if (Object.keys(propertyTypesBySelectedClass).length > 1)
            newStructureTypeOptions = ["Dwelling", "Non-Dwelling"];
        }
        /**
         * if the mine subsidence coverage is on the quote but has no terms (Kentucky)
         * then we couldn't set the structure options... so default them
         */
        if (isKentucyMineSub)
          newStructureTypeOptions = ["Not Applicable", "Non-Dwelling"];
      }); // end forEach ClassCode

      // update states
      //if mine subsidance is selected, then set to true
      setStructureTypeDisplay(!!mineSubsidenceCoverageSelected);
      setMineSubsidenceDisplay(newMinSubDisplay);
      setLiabExpDisplay(newLiabExpDisplay);
      setCondoByLawDisplay(newCondoByLawDisplay);
      setTownhByLawDisplay(newTownhByLawDisplay);
      setStructureTypeOptions(
        removeDuplicates(newStructureTypeOptions)
          .sort()
          .map(i => ({ value: i, label: i }))
      );

      // set the value of structureType if there's only one option (it'll be disabled too)
      if (newStructureTypeOptions.length === 1) {
        newFormValues.values.structureType = newStructureTypeOptions[0];
        newFormValues.errors.structureType = [];
      }

      if (newMinSubDisplay) {
        newFormValues.values.mineSubsidenceApplies =
          isKentucyMineSub || initialValues.mineSubsidenceApplies;
        newFormValues.errors.mineSubsidenceApplies = [];
      }

      // only trigger the update if there are values being updated
      if (Object.keys(newFormValues.values).length)
        updateForm({
          values: newFormValues.values,
          errors: newFormValues.errors
        });

      //set to true if all the class codes selected have property type office condominium
      if (
        officeConPropType &&
        !otherPropType &&
        // is lessor's risk (toggled "on" on step 2 of the quote)
        supportingData.lessorsRiskIndicator
      ) {
        setCondSqrFtDisplay(true);
      } else {
        setCondSqrFtDisplay(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.classCodes, values.locationId, formMetadata]);

  const classCodeOptionsByGroup = (
    formMetadata?.classCodesOptionsByGroup || []
  ).reduce((acc, curr) => {
    const opts = curr?.options || [];
    return [
      ...acc,
      ...opts.map(o => ({
        groupHeader: curr.label,
        ...o
      }))
    ];
  }, []);

  // extra year fields only display if yearBuilt is valid and the year is greater than 25 years ago
  const yearFieldsDisplay =
    !invalidFields.some(f => f.name === "yearBuilt") &&
    currentYear - Number(values.yearBuilt) > 20;

  // OOQ-14708 Florida building requirements
  const hasFloridaIssues = [
    ...(errors?.yearBuilt || []),
    ...(errors?.constructionType || [])
  ]?.some(e => e.includes("Florida"));

  const yearUpdatesIssues = [
    ...(errors?.yearHeatingReplaced || []),
    ...(errors?.yearPlumbingReplaced || []),
    ...(errors?.yearRoofingReplaced || []),
    ...(errors?.yearWiringReplaced || [])
  ]?.some(e => e.includes("years old must have updates"));

  return (
    <div>
      {!formMetadata ? (
        <LoadingIndicator />
      ) : (
        <Form className="oq__form__building__step1 oq__form" context={form}>
          <FormGroup>
            <Input
              id="description"
              name="description"
              label="Building Description"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.description}
              required
              size="full"
            />
          </FormGroup>
          <FormGroup wrap={false} align="justify">
            <Select
              multi
              id="classCodes"
              name="classCodes"
              label="Select Class(es)"
              className="w50p"
              onChange={handleClassOrLocationChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.classCodes || []}
              options={classCodeOptionsByGroup || []}
              showSearch={false}
              required
            />
            <Select
              id="locationId"
              name="locationId"
              label="Location"
              className="w50p"
              onChange={handleClassOrLocationChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.locationId}
              options={locationOptions || []}
              disabled={!!itemData?.locationId}
              isClearable={false}
              showSearch={false}
              required
            />
          </FormGroup>
          <FormGroup>
            <Select
              id="constructionType"
              name="constructionType"
              label="Construction Type"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleConstructionTypeValidate}
              value={values.constructionType}
              options={formMetadata?.constructionTypeOptions || []}
              className="w50p oq__form__construction-type"
              showSearch={false}
              required
              disabled={
                itemData?.sourceOfService === "prefill" &&
                !!itemData?.constructionTypeOrig
              }
            />
            <Select
              id="roofType"
              name="roofType"
              label="Roof Type"
              className="w50p"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.roofType}
              options={formMetadata?.roofTypeOptions || []}
              showSearch={false}
              required
              disabled={
                itemData?.sourceOfService === "prefill" &&
                !!itemData?.roofTypOrig
              }
            />
          </FormGroup>
          <FormGroup wrap={false}>
            <Input
              id="numberOfStories"
              name="numberOfStories"
              label="Number of Stories"
              className="w50p"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.numberOfStories}
              numbersOnly
              required
              disabled={
                itemData?.sourceOfService === "prefill" &&
                !!itemData?.numberOfStoriesOrig
              }
            />
            <Input
              id="yearBuilt"
              name="yearBuilt"
              label="Year Built"
              className="w50p"
              onChange={handleOnChangeYearBuilt}
              onBlur={handleOnBlur}
              onValidate={field => handleYearValidate(field, "Year Built")}
              value={values.yearBuilt}
              numbersOnly
              required
              maxLength={4}
              disabled={
                itemData?.sourceOfService === "prefill" &&
                !!itemData?.yearBuiltOrig
              }
            />
          </FormGroup>

          {yearFieldsDisplay && (
            <FormGroup groupErrors>
              <Input
                id="yearHeatingReplaced"
                name="yearHeatingReplaced"
                label="Year Heating Replaced"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={field =>
                  handleYearUpdatesValidate(field, "Year Heating Replaced")
                }
                numbersOnly
                value={values.yearHeatingReplaced}
                required
                maxLength={4}
              />
              <Input
                id="yearPlumbingReplaced"
                name="yearPlumbingReplaced"
                label="Year Plumbing Replaced"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={field =>
                  handleYearUpdatesValidate(field, "Year Plumbing Replaced")
                }
                numbersOnly
                value={values.yearPlumbingReplaced}
                required
                maxLength={4}
              />
              <Input
                id="yearRoofingReplaced"
                name="yearRoofingReplaced"
                label="Year Roofing Replaced"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={field =>
                  handleYearUpdatesValidate(field, "Year Roofing Replaced")
                }
                numbersOnly
                value={values.yearRoofingReplaced}
                required
                maxLength={4}
              />
              <Input
                id="yearWiringReplaced"
                name="yearWiringReplaced"
                label="Year Wiring Replaced"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={field =>
                  handleYearUpdatesValidate(field, "Year Wiring Replaced")
                }
                numbersOnly
                value={values.yearWiringReplaced}
                required
                maxLength={4}
              />
            </FormGroup>
          )}
          <FormGroup>
            {condSqrFtDisplay && (
              <Input
                id="totalCondominiumBuildingSquareFootage"
                name="totalCondominiumBuildingSquareFootage"
                label="Total Condominium Building Square Footage"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.totalCondominiumBuildingSquareFootage}
                numbersOnly
                required
                mask="numberCommas"
                stripMaskFromValue
              />
            )}
            {(structureTypeDisplay || isKentucyMineSub) && (
              <Select
                id="structureType"
                name="structureType"
                label="Structure Type"
                className="w50p"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.structureType}
                options={structureTypeOptions || []}
                required
                disabled={
                  !!values.structureType && structureTypeOptions?.length === 1
                }
                isClearable={false}
              />
            )}
          </FormGroup>

          {(values?.classCodes || []).map(classCode => {
            return (
              <React.Fragment key={classCode}>
                <FlexRow>
                  <div className="oq__form__building__class-code__header">
                    {
                      (formMetadata?.classCodesOptions || []).find(
                        c => c.identifier === classCode
                      )?.classDescription
                    }
                  </div>
                </FlexRow>
                <FormGroup>
                  <Input
                    id={`${classCode}~classificationSquareFootage`}
                    name={`${classCode}~classificationSquareFootage`}
                    label="Classification Sq Ft"
                    className="w50p"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleClassSpeficValidate}
                    numbersOnly
                    value={
                      values[`${classCode}~classificationSquareFootage`] || ""
                    }
                    required
                    mask="numberCommas"
                    stripMaskFromValue
                  />
                  {
                    /**
                     * show exposure (Annual Sales)
                     * ... or the grossSales_UFG fields
                     */

                    liabExpDisplay[classCode]?.displayField ? (
                      <Input
                        id={`${classCode}~exposure`}
                        name={`${classCode}~exposure`}
                        label={`${
                          (formMetadata?.classCodesOptions || []).find(
                            c => c.identifier === classCode
                          )?.liabilityExposureBase
                        }`}
                        labelElement={
                          !!liabExpDisplay[classCode]?.displayIcon ? (
                            <Popover
                              id="annualTooltip"
                              tooltip
                              trigger="hover"
                              triggerContent={
                                <span>
                                  <Icon
                                    id="annualTooltipIcon"
                                    icon="fasInfoCircle"
                                    size="1x"
                                  />
                                </span>
                              }
                              popoverContent="Annual Payroll should reflect all payroll including owner."
                            />
                          ) : null
                        }
                        className="w50p"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleClassExposureValidate}
                        numbersOnly
                        value={values[`${classCode}~exposure`] || ""}
                        required
                        mask="currency"
                        stripMaskFromValue
                      />
                    ) : (
                      /**
                       * if no class is requiring the exposure field to be inputted,
                       * then display the Gross Sales field
                       */
                      <Input
                        id={`${classCode}~grossSales_UFG`}
                        name={`${classCode}~grossSales_UFG`}
                        label="Annual Gross Sales"
                        className="w50p"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleClassSpeficValidate}
                        numbersOnly
                        value={values[`${classCode}~grossSales_UFG`] || ""}
                        required
                        mask="numberCommas"
                        stripMaskFromValue
                      />
                    )
                  }
                </FormGroup>

                <div className="oq__form__building__step1__checkbox-group">
                  {condoByLawDiaplay[classCode] && (
                    <>
                      <FormGroup>
                        <Checkbox
                          className="oq__form__building__step1__checkbox"
                          id={`${classCode}~condoBylaws`}
                          name={`${classCode}~condoBylaws`}
                          label="Condo By Laws"
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[`${classCode}~condoBylaws`]}
                          noLabel
                          size="auto"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Checkbox
                          className="oq__form__building__step1__checkbox"
                          id={`${classCode}~condoResidence`}
                          name={`${classCode}~condoResidence`}
                          label="Condo Residence"
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[`${classCode}~condoResidence`]}
                          noLabel
                          size="auto"
                        />
                      </FormGroup>
                    </>
                  )}
                  {townhByLawDiaplay[classCode] && (
                    <>
                      <FormGroup>
                        <Checkbox
                          className="oq__form__building__step1__checkbox"
                          id={`${classCode}~townhouseBylaw`}
                          name={`${classCode}~townhouseBylaw`}
                          label="Townhouse By Law"
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[`${classCode}~townhouseBylaw`]}
                          noLabel
                          size="auto"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Checkbox
                          className="oq__form__building__step1__checkbox"
                          id={`${classCode}~townhouseAssoc`}
                          name={`${classCode}~townhouseAssoc`}
                          label="Townhouse Assoc "
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[`${classCode}~townhouseAssoc`]}
                          noLabel
                          size="auto"
                        />
                      </FormGroup>
                    </>
                  )}
                </div>
              </React.Fragment>
            );
          })}
          {selectedLocationState === "FL" &&
            proQuoteFloridaWindHailOnBuilding &&
            floridaSelectedLocationWindHailIsNotApplicable({
              quoteData,
              location: selectedLocation
            }) && (
              <div>
                <br />
                <FormGroup>
                  <RadioList
                    id="floridaWindHail"
                    name="floridaWindHail"
                    label="Does the insured have a separate wind/hail policy?"
                    // labelElement={
                    //   <ToolTip
                    //     className="oq__fl__windhail__tooltip"
                    //     type="click"
                    //     width={200}
                    //     nowrap={false}
                    //     variant="white"
                    //     position="top"
                    //     trigger={<Icon icon="fasExclamationCircle" />}
                    //     content={
                    //       <span>
                    //         This question appears because the selected location
                    //         does not have any WindHail coverage.
                    //       </span>
                    //     }
                    //   />
                    // }
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.floridaWindHail}
                    options={[
                      { value: "Yes", label: "Yes" },
                      {
                        value: "No",
                        label: "No"
                      }
                    ]}
                    disabled={
                      !!supportingData?.locations?.[selectedLocation?.fixedID]
                        ?.floridaWindHail
                    }
                    required
                  />
                </FormGroup>
                <div>
                  <small className="oq__fl__windhail__label">
                    If &quot;No&quot;, then Building coverage will not be
                    available
                  </small>
                </div>
                <br />
              </div>
            )}
          {!supportingData?.lessorsRiskIndicator &&
            values?.floridaWindHail !== "No" && (
              <FormGroup>
                <Checkbox
                  className="oq__form__building__step1__checkbox oq__form__contents-only-checkbox"
                  id="ContentsOnlyIndicator"
                  name="ContentsOnlyIndicator"
                  label="Include Building Coverage"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.ContentsOnlyIndicator}
                  noLabel
                  size="full"
                />
              </FormGroup>
            )}
          <FormGroup>
            <Checkbox
              className="oq__form__building__step1__checkbox"
              id="basementPresent"
              name="basementPresent"
              label="Basement Present"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={!!values.basementPresent}
              noLabel
              size="auto"
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              className="oq__form__building__step1__checkbox"
              id="automaticSprinklerSystemPresent"
              name="automaticSprinklerSystemPresent"
              label="Automatic Sprinkler System"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={!!values.automaticSprinklerSystemPresent}
              size="auto"
              noLabel
              disabled={
                itemData?.sourceOfService === "prefill" &&
                !!itemData?.automaticSprinklerSystemPresentOrig
              }
            />
          </FormGroup>
          {mineSubsidenceDisplay && (
            <FormGroup>
              <Checkbox
                className="oq__form__building__step1__checkbox"
                id="mineSubsidenceApplies"
                name="mineSubsidenceApplies"
                label="Mine Subsidence Applies"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.mineSubsidenceApplies}
                size="auto"
                noLabel
              />
            </FormGroup>
          )}
          {hasFloridaIssues && (
            <Alert
              block
              className="oq__fl_building__risk-alert"
              type="error"
              label="Risk Ineligible"
              dismissible={false}
              dataAttrib={[
                { name: "fullstory", value: "FL_building__risk-alert" }
              ]}
            >
              <div>
                Due to one or more characteristics of the building (age and/or
                construction type), the risk is ineligible.
              </div>
            </Alert>
          )}
          {yearUpdatesIssues && (
            <Alert
              block
              className="oq__fl_building__risk-alert"
              type="error"
              label="Risk Ineligible"
              dismissible={false}
              dataAttrib={[
                { name: "fullstory", value: "FL_building__risk-alert" }
              ]}
            >
              <div>
                Due to one or more characteristics of the building (age and
                updates age), the risk is ineligible.
              </div>
            </Alert>
          )}

          <FormGroup align="right">
            <Button
              variant="plain"
              onClick={() => onCancel()}
              disabled={quoteIsUpdating}
              className="cancel"
            >
              {!!itemData?.fixedId && _isEqual(initialValues, values)
                ? "Close"
                : "Cancel"}
            </Button>

            <Button
              variant="primary"
              className="continue"
              disabled={invalidFields.length > 0 || quoteIsUpdating}
              onClick={() => handleFormSubmit()}
            >
              Continue
            </Button>
          </FormGroup>
        </Form>
      )}
    </div>
  );
};

export default BuildingFormStep1;
