import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../components/Factory";
import { LoadingIndicator, Table } from "@ufginsurance/ui-kit";

const PolicyActivityTable = props => {
  const { activityList, fetchActivityDocument, policy_type } = props;

  const handleDocumentClick = (documentId, policyNumber) => () => {
    document.getElementsByClassName(documentId)[0].style.display = "";
    fetchActivityDocument(documentId, policyNumber, policy_type).then(() => {
      document.getElementsByClassName(documentId)[0].style.display = "none";
    });
  };

  const createColumns = () => [
    {
      key: "process_date",
      label: "Process Date",
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "policy_number",
      label: "Policy Number"
    },
    {
      key: "document_type_description",
      label: "Document Type",
      element: row => (
        <div>
          <button
            className="btn-as-link"
            onClick={handleDocumentClick(row.document_id, row.policy_number)}
          >
            {row.document_type_description}
          </button>
          <div className={row.document_id} style={{ display: "none" }}>
            <LoadingIndicator />
          </div>
        </div>
      )
    },
    {
      key: "dec_type",
      label: "Dec Type"
    },
    {
      key: "effective_date",
      label: "Effective Date",
      element: row => (
        <span>{DateFormat(row.effective_date, "MM/DD/YYYY")}</span>
      )
    }
  ];

  return (
    <div className="policy-activity-table">
      <Table
        rowKey="document_id"
        data={activityList}
        columns={createColumns(activityList)}
        itemsPerPage={20}
        initialDirection="desc"
        initialSort="process_date"
        showPagination
      />
    </div>
  );
};

PolicyActivityTable.propTypes = {
  activityList: PropTypes.array.isRequired,
  fetchActivityDocument: PropTypes.func,
  policy_type: PropTypes.string.isRequired
};

export default PolicyActivityTable;
