import React, { useContext } from "react";
import { Panel } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";

const UnderwriterDisplay = () => {
  const { step, quoteData } = useContext(OnlineQuotingContext);

  const uw = quoteData?.baseData?.underwriter_UFG;

  const uwDisplay = step > 2;

  return uwDisplay && uw ? (
    <Panel className="underwriter" highlight="blue" title="Underwriting Team">
      <div>
        <ul className="oq__underwriter__container">
          <li className="oq__underwriter">
            <b className="oq__underwriter__name">{uw?.displayName}</b>
            <ul className="oq__underwriter__phone">
              {uw?.phoneNumber && (
                <li>
                  <span className="oq__underwriter__phone-label">Phone:</span>
                  <span className="oq__underwriter__phone-number">
                    {uw?.phoneNumber}
                  </span>
                </li>
              )}
            </ul>
            {uw?.email && (
              <a
                className="oq__underwriter__email"
                href={`mailto:${uw?.email}`}
              >
                {uw?.email}
              </a>
            )}
          </li>
        </ul>
      </div>
    </Panel>
  ) : null;
};

export default UnderwriterDisplay;
