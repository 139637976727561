import React from "react";
import { Button, FlexRow, Panel } from "@ufginsurance/ui-kit";

function ProductSelectionDetails({
  handleProQuoteOnClick,
  handleProQuoteExistingOnClick,
  handleBuildersRiskOnClick,
  handleNewCustomerOnClick,
  isMetaLoading
}) {
  return (
    <div>
      <Panel rounded title="Commercial Small Business Quoting">
        <FlexRow align="right">
          <Button variant="outline" onClick={handleProQuoteExistingOnClick}>
            Existing Customer
          </Button>
          <Button variant="primary" onClick={handleProQuoteOnClick}>
            New Customer
          </Button>
        </FlexRow>
      </Panel>
      <Panel rounded title="Inland Marine Builders' Risk">
        <div>
          <p className="text-lead">Online Program Eligibility</p>
          <p className="text-bold">
            Please consult with your underwriter before submitting online if
            your Builders&apos; Risk project involves any of the following:
          </p>
          <ul className="criteria">
            <li>Frame projects greater than $1,500,000</li>
            <li>New commercial projects greater than $3,500,000</li>
            <li>Projects already started</li>
            <li>All renovation projects</li>
            <li>Projects located in PC 9-10</li>
            <li>Projects located in the State of Florida</li>
          </ul>
        </div>
        <FlexRow align="right">
          <Button variant="outline" onClick={handleBuildersRiskOnClick}>
            Existing Customer
          </Button>
          <Button
            variant="primary"
            onClick={handleNewCustomerOnClick}
            disabled={isMetaLoading}
            spinner={isMetaLoading}
          >
            New Customer
          </Button>
        </FlexRow>
      </Panel>
    </div>
  );
}

export default ProductSelectionDetails;
