import React, { useState } from "react";

import { Button, Panel } from "@ufginsurance/ui-kit";

import { toTitleCase } from "../../components/Factory.js";
import OqModal from "../shared/OqModal";
import BillingContactForm from "./BillingContactForm";

import "./BillingContact.scss";

export const pcContactToForm = contact => ({
  /**
   * when we get the ID from metadata, it's "accountContactPublicID"
   * but when we save the contact using the updateContact API, the id in the response is "publicID"
   */
  id: contact?.accountContactPublicID || contact?.publicID || "",
  contactName: contact?.contactName || "",
  firstName: contact?.firstName || "",
  lastName: contact?.lastName || "",
  subtype: contact?.subtype || "",
  addressLine1: contact?.primaryAddress?.addressLine1 || "",
  postalCode: contact?.primaryAddress?.postalCode || "",
  city: contact?.primaryAddress?.city || "",
  county: contact?.primaryAddress?.county || "",
  countyCode: contact?.primaryAddress?.countyCode_UFG || "",
  state: contact?.primaryAddress?.state || "",
  isAccountHolder: !!contact?.accountHolder
});

const BillingContactPanel = ({ contactsFromMetadata }) => {
  // const { getQuotePart, updateSupportingData, supportingData, loggingData } =
  //   useContext(OnlineQuotingContext);

  //address verification related data
  const [show, setShow] = useState();

  // the billing contact
  const pcContactFromMetadata = contactsFromMetadata?.find(contact =>
    contact?.accountContactRoles?.some(c => c.subtype === "BillingContact")
  );

  const [contact, setContact] = useState(
    pcContactToForm(pcContactFromMetadata)
  );

  return (
    <>
      <Panel
        title="Billing Address"
        noBorder
        className="oq__billing-address__panel"
      >
        {/**
         * In theory, this should never occur because Mule always creates
         * a billing contact when the quote is created... but just in case
         */}
        {!contact && <div>No billing contact found.</div>}

        {contact && (
          <div>
            <div className="oq__billing-address__contact-name">
              {contact?.firstName
                ? `${contact?.firstName} ${contact?.lastName}`
                : contact?.contactName}
            </div>
            <div className="oq__billing-address__address">
              {toTitleCase(contact?.addressLine1)}
            </div>
            <div className="oq__billing-address__city-state-zip">
              {toTitleCase(contact?.city)}, {contact?.state}{" "}
              {contact?.postalCode} (primary)
            </div>
          </div>
        )}

        <div className="oq__billing-address__edit">
          <Button isLink inline onClick={() => setShow(true)}>
            Edit Billing Information
          </Button>
        </div>
      </Panel>

      {show && (
        <OqModal
          title="Edit Billing Information"
          className="oq__billing-address__modal"
          show={show}
          closeIcon={false}
          onHide={() => {}}
          body={
            <>
              <BillingContactForm
                contact={contact}
                setContact={setContact}
                setShow={setShow}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default BillingContactPanel;
