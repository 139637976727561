// TODO: this isn't a component, so let's move this file
export const INTERFACE_CODES = {
  agencyNote: "agency",
  claims: "claims",
  commission: "commission",
  docs: "docs"
};

const { agencyNote, claims, commission, docs } = INTERFACE_CODES;

export const MANAGEMENT_SYSTEM = [
  { value: "AFW", label: "AFW", code: [agencyNote, claims, commission, docs] },
  { value: "AGENCYPRO", label: "Agency Pro", code: [docs] },
  {
    value: "AGENCYSOLUTION",
    label: "Agency Solution",
    code: []
  },
  {
    value: "AMS",
    label: "AMS 360",
    code: [agencyNote, claims, commission, docs]
  },
  {
    value: "CATALYST",
    label: "Catalyst",
    code: []
  },
  {
    value: "CMS",
    label: "CMS",
    code: [docs]
  },
  {
    value: "DORIS",
    label: "Doris",
    code: [commission]
  },
  {
    value: "EBIXASP",
    label: "ebixASP",
    code: []
  },
  {
    value: "ECLIPSE",
    label: "Eclipse",
    code: [claims, commission, docs]
  },
  {
    value: "EPIC",
    label: "Epic",
    code: [claims, commission,docs]
  },
  { value: "EXLYNX", label: "EXLYNX",code: [] },
  { value: "FSC", label: "FSC Manager", code: [commission] },
  { value: "IMS4", label: "IMS4" , code: [docs]},
  { value: "INSTAR", label: "Instar", code: [agencyNote, claims] },
  { value: "IRS", label: "IRS-AIMS", code: [agencyNote, claims, commission] },
  { value: "NEWTON", label: "Newton", code: [commission] },
  { value: "NEXSURE", label: "Nexsure",code: [] },
  { value: "PARTNER", label: "Partner XE",code: [] },
  { value: "PRIME", label: "Prime", code: [agencyNote, claims] },
  { value: "QQ", label: "QQ Evolution",code: [] },
  { value: "QUICKFILE", label: "Quick File" ,code: []},
  {
    value: "SAGITTA",
    label: "Sagitta",
    code: [agencyNote, claims, commission,docs]
  },
  { value: "TAM", label: "TAM", code: [claims,docs,commission] },
  { value: "AMR", label: "Veruna AMR", code: [commission] },
  { value: "VISION", label: "Vision", code: [commission] }
];
