import React from "react";
import {
  Button,
  Dropdown,
  FormGroup,
  Input,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import useFetchAccounts from "./hooks/useFetchAccounts";
import { toTitleCase } from "../../../components/Factory";
import {
  ACCOUNT_NAME_MAX_LENGTH,
  NAME_FIELDS_MAX_LENGTH
} from "../lib/constants";

function AccountOrNameSelector({
  form,
  licensedStates,
  resetForm,
  updateLastVerifiedAddress
}) {
  const {
    errors,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    updateForm,
    values
  } = form;
  const accountSearchValue = values?.account_search_input;
  const [accounts, accountsIsLoading] = useFetchAccounts(accountSearchValue);

  // Input/typing action.
  const handleAccountSearch = (value, { action }) => {
    // Persist the value for blur/focus events.
    if (action === "input-change") {
      if (value && !/^[a-zA-Z0-9_].*/.test(value)) {
        updateForm({
          values: {
            ...values,
            account_search_input: value
          },
          errors: {
            ...errors,
            account_search: [
              "Search Insured Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
            ]
          }
        });
      } else {
        updateForm({
          values: {
            ...values,
            account_search_input: value
          },
          errors: {
            ...errors,
            account_search: []
          }
        });
      }
    }
  };

  // Dropdown selection, sets the account name value or splits to first/last names.
  const handleAccountSearchSelect = ({ value }) => {
    // Get the selected account object.
    const selectedOption = (accounts || [])?.find(a => a.value === value);

    // Handle the option based on type (api, business, individual).
    // Business | Individual = new/manual entry.
    if (selectedOption?.type === "api") {
      // Get the corresponding account data.
      const account = selectedOption.account;
      const accountHolder = account?.accountHolder;
      const newValues = {
        account_type: selectedOption.type,
        account_search_input: "",
        account_search: value,
        account_number: account?.accountNumber || null,
        account_publicID: accountHolder?.publicID || "",
        account_org_type_UFG: account?.accountOrgType_UFG || ""
      };
      // Account Holder data fills the address.
      if (!!accountHolder) {
        newValues.address_line =
          toTitleCase(
            accountHolder?.primaryAddress?.addressLine1.slice(
              0,
              ACCOUNT_NAME_MAX_LENGTH
            )
          ) || "";
        newValues.city = toTitleCase(accountHolder?.primaryAddress?.city) || "";
        newValues.state = accountHolder?.primaryAddress?.state || "";
        newValues.zip = accountHolder?.primaryAddress?.postalCode;
        if (updateLastVerifiedAddress) updateLastVerifiedAddress(newValues);
      }

      // If it's a company, then update the account_name, otherwise it's an individual.
      if (accountHolder?.subtype === "Company" || !accountHolder?.lastName) {
        newValues.account_name =
          accountHolder?.contactName.slice(0, ACCOUNT_NAME_MAX_LENGTH) ||
          accountHolder?.displayName.slice(0, ACCOUNT_NAME_MAX_LENGTH) ||
          "";
      } else {
        newValues.last_name =
          accountHolder?.lastName.slice(0, NAME_FIELDS_MAX_LENGTH) || "";
        newValues.first_name =
          accountHolder?.firstName.slice(0, NAME_FIELDS_MAX_LENGTH) || "";
      }

      // Enforce selected state is an agency's licensed state.
      const zipCodeError =
        !!accountHolder?.primaryAddress?.state &&
        !licensedStates.includes(accountHolder?.primaryAddress?.state)
          ? [
              "You are attempting to quote business in a state in which we do not have a license on file for. Please contact your UFG marketing representative for licensing procedures."
            ]
          : [];
      updateForm({
        values: {
          ...values,
          ...newValues
        },
        errors: {
          account_name: [],
          account_search: [],
          account_number: [],
          address_line: [],
          zip: [...zipCodeError],
          city: [],
          state: []
        }
      });
    } else if (
      selectedOption?.type === "business" ||
      selectedOption?.type === "individual"
    ) {
      // Start a new biz or individual flow.
      updateForm({
        values: {
          ...values,
          account_type: selectedOption.type,
          account_search_input: "",
          account_search: value,
          account_name:
            selectedOption.type === "business"
              ? value.split("|")[0].slice(0, ACCOUNT_NAME_MAX_LENGTH)
              : "",
          last_name:
            selectedOption.type === "individual"
              ? value.split("|")[0].slice(0, NAME_FIELDS_MAX_LENGTH)
              : ""
        },
        errors: {
          account_type: [],
          account_search_input: [],
          account_search: [],
          account_name: [],
          last_name: []
        }
      });
    } else {
      return null;
    }
  };

  const handleAccountSearchValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (
      values.account_search_input &&
      !/^[a-zA-Z0-9_].*/.test(values.account_search_input)
    )
      fieldErrors.push(
        "Search Insured Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
      );
    return fieldErrors;
  };

  const handleAccountNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(
        "Business Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
      );
    return fieldErrors;
  };

  const handleLastNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(
        "Last Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
      );
    return fieldErrors;
  };

  const handleFirstNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(
        "First Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
      );
    return fieldErrors;
  };

  const accountIsSelected = !!values?.account_name || !!values?.last_name;

  return (
    <FormGroup className="search-account-names">
      {!values.account_name && !values.last_name && (
        <Dropdown
          id="account_search"
          name="account_search"
          label="Search Insured Name"
          placeholder="Search Insured Name"
          size="fill"
          onChange={handleAccountSearchSelect}
          onBlur={handleOnBlur}
          onValidate={handleAccountSearchValidate}
          onInputChange={handleAccountSearch}
          value={values.account_search}
          options={accounts || []}
          isSearchable
          hideIndicatorUntilSearch
          required={!accountIsSelected}
          isLoading={accountsIsLoading}
          loadingMessage={() => "Searching for account..."}
          inputValue={accountSearchValue}
          noOptionsMessage={() => {
            // Prevents the menu from appearing until the api runs or data appears.
            return null;
          }}
          labelElement={
            accountsIsLoading ? (
              <LoadingIndicator
                className="oq__label-spinner"
                type="spinner"
                message="Searching"
              />
            ) : null
          }
        />
      )}

      {!!values.account_name && (
        <Input
          id="account_name"
          name="account_name"
          label="Business Name"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleAccountNameValidate}
          value={values.account_name}
          required
          disabled={!!values?.account_name && values?.account_type === "api"}
          size="fill"
          maxLength={ACCOUNT_NAME_MAX_LENGTH}
        />
      )}
      {!!values.last_name && (
        <>
          <Input
            id="last_name"
            name="last_name"
            label="Last Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleLastNameValidate}
            value={values.last_name}
            required
            size="fill"
            maxLength={NAME_FIELDS_MAX_LENGTH}
          />
          <Input
            id="first_name"
            name="first_name"
            label="First Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleFirstNameValidate}
            value={values.first_name}
            required
            size="fill"
            maxLength={NAME_FIELDS_MAX_LENGTH}
          />
        </>
      )}
      {accountIsSelected && (
        <Button labelSpace isLink onClick={resetForm}>
          Reset
        </Button>
      )}
    </FormGroup>
  );
}

export default AccountOrNameSelector;
