import React from "react";
import PropTypes from "prop-types";
import MakeAPayment from "./makeAPayment";

const makeAPaymentFormHolder = ({
  paymentDetails,
  handleClose,
  accountData,
  checkPaymentEligibility,
  agency_number
}) => {
  return (
    <div>
      {paymentDetails.isActivated && (
        <MakeAPayment
          paymentDetails={paymentDetails}
          handleClose={handleClose}
          accountData={accountData}
          checkPaymentEligibility={checkPaymentEligibility}
          agency_number={agency_number}
        />
      )}
    </div>
  );
};

makeAPaymentFormHolder.propTypes = {
  paymentDetails: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  accountData: PropTypes.object,
  checkPaymentEligibility: PropTypes.func.isRequired,
  agency_number: PropTypes.string
};

export default makeAPaymentFormHolder;
