import React, { useContext } from "react";
import { _get, hasHNO } from "../../shared/util";
import { Currency } from "@ufginsurance/ui-kit";
import { productKeys, modifierPath } from "../../shared/constants";
import LobAdd from "./LobAdd";
import LobRemove from "./LobRemove";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import UmbrellaForm from "../UmbrellaForm";
import classNames from "classnames";

const LobItem = ({ lob, product }) => {
  const { quoteData, supportingData } = useContext(OnlineQuotingContext);

  const isBopWithHnoAuto = lob === "ca7CommAuto" && hasHNO(supportingData);

  const creditDebitModifier = _get(quoteData, modifierPath(lob), {});
  const totalModifier = creditDebitModifier?.totalModifier || 0;

  const creditDebitAmount =
    lob === "wcmWorkersComp" ? Math.round(totalModifier * 100) : totalModifier;

  const creditDebitLabel = creditDebitAmount < 0 ? "Credit" : "Debit";

  const quotedLob = (
    quoteData?.quoteData?.offeredQuotes[0].linePremiumDTO_UFG || []
  ).find(l => l.lob === productKeys[lob].line);

  // special handline for umbrella
  if (lob === "cuCommercialUmbrella") return <UmbrellaForm />;

  if (lob === "internetSecurity" && !quoteData?.baseData?.cyberEligible_UFG)
    return null;

  // if product not on quote, then show Add Lob
  if (!quotedLob && !isBopWithHnoAuto) {
    return (
      <tr className="oq__premium__summary__lob__content__item">
        <td colSpan="3">
          <LobAdd lob={lob} product={product} />
        </td>
      </tr>
    );
  }

  // else show line details with Remove Lob
  return (
    <tr className="oq__premium__summary__lob__content__item">
      <td className="oq__premium__summary__lob__content__item-label">
        {product.shortDescription || product.label}{" "}
        {isBopWithHnoAuto && "(HNO)"}
      </td>
      <td className="oq__premium__summary__lob__content__credit-debit">
        {!!creditDebitAmount && (
          <span
            className={classNames({
              "oq__lob__credit-amount": creditDebitAmount < 0,
              "oq__lob__debit-amount": creditDebitAmount > 0
            })}
          >
            {Math.abs(creditDebitAmount)}% {creditDebitLabel} Applied
          </span>
        )}
      </td>
      <td className="oq__premium__summary__lob__content__item-amount">
        <b>
          <Currency amount={quotedLob?.total?.amount} includeDecimals={false} />
        </b>
      </td>
      <td className="oq__premium__summary__lob__content__item-action">
        <LobRemove
          lob={lob}
          product={product}
          isBopWithHnoAuto={isBopWithHnoAuto}
        />
      </td>
    </tr>
  );
};
export default LobItem;
