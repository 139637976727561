import React, { useEffect } from "react";
import { useForm, Form, FormGroup, Checkbox } from "@ufginsurance/ui-kit";

import "./EligibilityCheckbox.scss";
import { productKeys } from "../shared/constants";

const EligibilityCheckbox = ({
  setCheckboxValidation,
  checkboxIsChecked,
  products
}) => {
  const initialValues = { requireTermsCheckbox: checkboxIsChecked || false };

  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  const prodlabels = products.map(p => <b key={p}>{productKeys[p].label}</b>);

  useEffect(() => {
    if (checkboxIsChecked !== values.requireTermsCheckbox)
      updateForm({ values: { requireTermsCheckbox: checkboxIsChecked } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxIsChecked]);

  useEffect(() => {
    setCheckboxValidation(invalidFields.length === 0);
  }, [setCheckboxValidation, invalidFields]);

  return (
    <Form context={form} className="oq__eligibility-form">
      <FormGroup>
        <Checkbox
          id="requireTermsCheckbox"
          name="requireTermsCheckbox"
          className="oq__checkbox__require-terms"
          labelElement={
            <span>
              I have reviewed and accurately answered the{" "}
              {prodlabels.reduce((acc, p, i, array) => (
                <>
                  {acc}
                  {i < array.length - 1 ? ", " : " and "}
                  {p}
                </>
              ))}{" "}
              eligibility questions above.
            </span>
          }
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.requireTermsCheckbox}
          required
          noLabel
        />
      </FormGroup>
    </Form>
  );
};

export default EligibilityCheckbox;
