const classificationsyCode = filterText => c => c.code.includes(filterText);
const classificationsByDescription = filterText => c =>
  c.description?.toUpperCase()?.includes(filterText?.toUpperCase() ?? "") ?? "";

export const filterClassifications = (classifications, filterText) => {
  if (!filterText) {
    return classifications;
  }
  return [
    ...classifications.filter(classificationsyCode(filterText)),
    ...classifications.filter(classificationsByDescription(filterText))
  ].filter((v, i, a) => a.indexOf(v) === i);
};
