import { searchLocaleByZipCodeV2 } from "../../services/onlineQuotingService";
import { toTitleCase } from "../../components/Factory";
import { arrayToOptions, zipCodePattern } from "../shared/util";
import OQstrings from "../shared/strings";

export const search = ({
  field,
  value,
  form,
  type,
  addresses,
  setAddresses,
  setSelectedState,
  agencyStates,
  toastErrr,
  callbackAfterSearch
}) => {
  // function to reset the Address fields
  const resetAddressFields = (zipCodeErrors = [], zipcodeFail = false) => {
    // reset the values if they haven't been reset already
    form.updateForm({
      values: {
        [field]: value,
        [type + "_city"]: "",
        [type + "_county"]: "",
        [type + "_county_code"]: "",
        [type + "_state"]: ""
      },
      errors: {
        [type + "_zip"]: zipCodeErrors || [],
        [type + "_city"]: [],
        [type + "_county"]: [],
        [type + "_county_code"]: [],
        [type + "_state"]: []
      }
    });

    setAddresses({
      ...addresses,
      [type]: {
        ...addresses[type],
        options: {
          city: [],
          state: arrayToOptions([...new Set(agencyStates)])
        },
        disabled: { city: !zipcodeFail, state: !zipcodeFail },
        zipcodeFail
      }
    });
    if (type === "physical") setSelectedState(null);
  };

  // if zip code is a valid format, then look it up
  if (zipCodePattern.test(value)) {
    setAddresses({
      ...addresses,
      [type]: {
        ...addresses[type],
        isLoading: { city: true, state: true }
      }
    });

    // it's a valid zip code... reset the errors -- fixes an issue with copy/pasting of zip code

    const zipCodeErrors = [];

    searchLocaleByZipCodeV2(value)
      .then(result => {
        if (!!result?.data.length) {
          //OOQ-8172 skip state validation for mailing addess
          // first check to see if zip is in allowed state list
          if (
            !agencyStates.includes(result.data[0].state) &&
            type !== "mailing_ufg"
          ) {
            zipCodeErrors.push(OQstrings.error.zip_code_not_in_agent_state);
          }
          // set city & state values
          const formData = {
            values: {
              [field]: value,
              [type + "_city"]: toTitleCase(result.data[0].city),
              [type + "_county"]: toTitleCase(result.data[0].county),
              [type + "_county_code"]: result.data[0].county_number,
              [type + "_state"]: result.data[0].state
            },
            errors: {
              ...form.errors,
              [type + "_zip"]: zipCodeErrors,
              [type + "_city"]: [],
              [type + "_county"]: [],
              [type + "_county_code"]: [],
              [type + "_state"]: []
            }
          };

          form.updateForm(formData);

          const uniqueCities = [...new Set(result.data.map(o => o.city))];
          const uniqueStates = [...new Set(result.data.map(o => o.state))];

          // prepare the options for city and state
          const newCityOptions = uniqueCities.map(city => {
            return {
              label: toTitleCase(city),
              value: toTitleCase(city)
            };
          });
          const newStateOptions = uniqueStates.map(state => {
            return {
              label: state,
              value: state
            };
          });

          // update the address field options and field states
          setAddresses({
            ...addresses,
            [type]: {
              ...addresses[type],
              options: { city: newCityOptions, state: newStateOptions },
              disabled: {
                city: newCityOptions.length < 2,
                state: newStateOptions.length < 2
              },
              zipcodeFail: false
            }
          });

          // update the selectedState so the products get updated
          if (type === "physical") setSelectedState(result.data[0].state);
        } else {
          // no data returned, empty them out like it was an error
          // 01234 is an example

          // clear selection options
          resetAddressFields([], true);
        }
      })
      .catch(error => {
        toastErrr({
          action: "searchLocaleByZipCodeV2",
          description: "zip code search failure",
          error,
          payload: value
        });
      })
      .finally(() => {
        if (callbackAfterSearch) callbackAfterSearch();
      });
  } else {
    // zip code is not a valid format
    // clear city and state options
    resetAddressFields([]);
    if (callbackAfterSearch) callbackAfterSearch();
  }
};
