import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import qs from "query-string";
import moment from "moment";
import ClaimsDetail from "./ClaimsDetail";
import { ContentHeader, LoadingIndicator, Button } from "@ufginsurance/ui-kit";
import { getPolicyLossRunsUrl } from "../../reports/services/claimsReportsService";
import { toast } from "react-toastify";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const CLAIM_LOADING_TYPE = {
  NOT_LOADING: 1,
  LOADING: 2,
  COMPLETE: 3
};

// TODO: There's still duplicate api calls when using things like Claims Inquiry modal.
// In Claims Inquiry it makes the call by claim number or policy number to see if there
// are results and then when this component loads it will remake that same api call.
// It would take some significant redux refactoring to track last api call and timestamp
// in order to determine if api call should occur and I'm not sure if there would be
// unintended consequences in other parts of the app.
const ClaimInformation = ({
  claims,
  claimsError,
  employeeUserName,
  fetchClaim,
  fetchClaims,
  history,
  selectedAgencyCode,
  insuredName,
  startReportAClaimWithPolicy
}) => {
  // Tracking loading locally due to how impactful refactoring the redux loading
  // indicator would be. It is shared by several api calls like notes, payments,
  // and by claim number.
  const [loadingType, setLoadingType] = useState(
    CLAIM_LOADING_TYPE.NOT_LOADING
  );
  const [currentClaimOrPolicyNumber, setCurrentClaimOrPolicyNumber] =
    useState("");

  // Memoizing to prevent useEffect from thinking query string object has changed each time
  const queryString = useMemo(
    () => qs.parse(history.location.search),
    [history.location.search]
  );

  // Don't use this for the useEffect because a claim number page hit will
  // cause this value to change with first claim's policy number and trigger
  // another api call.
  const policyNumber = queryString.policy_number
    ? queryString.policy_number
    : claims.length > 0
    ? claims[0].policy_number
    : "";

  useEffect(() => {
    if (
      !!queryString.claim_number &&
      queryString.claim_number !== currentClaimOrPolicyNumber
    ) {
      setCurrentClaimOrPolicyNumber(queryString.claim_number);
      setLoadingType(CLAIM_LOADING_TYPE.LOADING);

      fetchClaim(queryString.claim_number, selectedAgencyCode, employeeUserName)
        .then(
          () => {},
          () => {
            const options = {
              position: "top-center",
              className: "error",
              autoClose: false
            };
            toast(<HelpDeskMessage display="anErrorOccurred" />, options);
          }
        )
        .finally(() => setLoadingType(CLAIM_LOADING_TYPE.COMPLETE));
    } else if (
      !!queryString.policy_number &&
      queryString.policy_number !== currentClaimOrPolicyNumber
    ) {
      setCurrentClaimOrPolicyNumber(queryString.policy_number);
      const closedAfterDate = moment().subtract(1, "year").format("YYYY-MM-DD");

      setLoadingType(CLAIM_LOADING_TYPE.LOADING);

      fetchClaims(
        queryString.policy_number,
        selectedAgencyCode,
        closedAfterDate,
        employeeUserName
      )
        .then(
          () => {},
          () => {
            const options = {
              position: "top-center",
              className: "error",
              autoClose: false
            };
            toast(<HelpDeskMessage display="anErrorOccurred" />, options);
          }
        )
        .finally(() => setLoadingType(CLAIM_LOADING_TYPE.COMPLETE));
    }
  }, [
    currentClaimOrPolicyNumber,
    selectedAgencyCode,
    employeeUserName,
    fetchClaim,
    fetchClaims,
    queryString
  ]);

  const handleReportClaimButtonClick = () => {
    startReportAClaimWithPolicy(policyNumber);
  };

  const handleLossRunsButtonClick = () => {
    window.open(getPolicyLossRunsUrl(policyNumber));
  };
  return (
    <div className="claim-information-container">
      <ContentHeader>
        {Translations.Claims.claim_information.claim_information}
      </ContentHeader>
      <div className="tabs-active-content claims-container claim-information">
        <div className="row claims-buttons">
          <div className="col-md-5 col-md-offset-7 button-row">
            <div className="row view-loss-run-button">
              <div className="col-xs-12 col-sm-6 claim-detail-button">
                <Button onClick={handleLossRunsButtonClick} variant="primary">
                  {Translations.Claims.claims_detail.view_loss_runs}
                </Button>
              </div>
              <div className="col-xs-12 col-sm-6 claim-detail-button">
                <Button
                  onClick={handleReportClaimButtonClick}
                  variant="primary"
                >
                  {Translations.Claims.claims_detail.report_a_claim}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {insuredName && (
            <p>
              <b>{Translations.Claims.claim_information.insured_name_label}</b>{" "}
              {insuredName}
            </p>
          )}
        </div>
        <div className="row claims-information__policy-number">
          {policyNumber && (
            <p>
              <b>{Translations.Claims.claim_information.policy_number_label}</b>{" "}
              {policyNumber}
            </p>
          )}
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12 claim-information-blurb">
            {Translations.Claims.claims_detail.claims_detail_blurb}
          </div>
        </div>
        {loadingType !== CLAIM_LOADING_TYPE.COMPLETE ? (
          <LoadingIndicator />
        ) : claimsError ? (
          <HelpDeskMessage display="anErrorOccurred" />
        ) : claims && claims.length ? (
          <ClaimsDetail claims={claims} policyNumber={policyNumber} />
        ) : (
          <b>{Translations.Claims.claims_detail.no_claims_error_message}</b>
        )}
      </div>
    </div>
  );
};

ClaimInformation.propTypes = {
  claims: PropTypes.array,
  claimsError: PropTypes.bool,
  employeeUserName: PropTypes.string,
  fetchClaim: PropTypes.func,
  fetchClaims: PropTypes.func,
  history: PropTypes.any,
  selectedAgencyCode: PropTypes.string.isRequired,
  insuredName: PropTypes.string,
  startReportAClaimWithPolicy: PropTypes.func
};

export default ClaimInformation;
