import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./../components/translations";

const propTypes = {
  moratorium: PropTypes.array.isRequired,
  statename: PropTypes.string.isRequired
};

const PolicyChangesMoratorium = props => {
  const { statename, moratorium } = props;

  return (
    <div>
      {moratorium &&
        moratorium.map(moraObj => {
          let result = "";

          if (moraObj === "EQ") {
            result = (
              <div key={moraObj}>
                <span className="moratorium">
                  <b>
                    {Translations.account_overview.changeRequests.moratorium.EQ}
                    <div>
                      {
                        Translations.account_overview.changeRequests
                          .moratorium_guidelines
                      }
                    </div>
                  </b>
                </span>
              </div>
            );
          }
          if (moraObj === "WF") {
            result = (
              <div key={moraObj}>
                <span className="moratorium">
                  <b>
                    {Translations.account_overview.changeRequests.moratorium.WF}
                    <div>
                      {
                        Translations.account_overview.changeRequests.moratorium
                          .guidelines_text
                      }
                    </div>
                  </b>
                </span>
              </div>
            );
          }
          if (moraObj === "HU") {
            result = (
              <div key={moraObj}>
                <span className="moratorium">
                  <b>
                    {Translations.account_overview.changeRequests.moratorium.HU}
                    {statename}
                    {
                      Translations.account_overview.changeRequests.moratorium
                        .HU_continuation
                    }
                    <div>
                      {
                        Translations.account_overview.changeRequests
                          .moratorium_guidelines
                      }
                    </div>
                  </b>
                </span>
              </div>
            );
          }
          return result;
        })}
    </div>
  );
};

PolicyChangesMoratorium.propTypes = propTypes;

export default PolicyChangesMoratorium;
