import { connect } from "react-redux";
import { activateUmailCard } from "./../umail/umailAction";
import Footer from "../components/Footer";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => {
  return {
    isSpecialtyPortalView: getisSpecialtyPortalView(state)
  };
};

const mapDispatchToProps = {
  activateUmailCard
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
