import React from "react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { Anchor, Panel } from "@ufginsurance/ui-kit";
import { isHeaderType, isSubHeader1Type, isSubHeader2Type } from "../constants";
import * as routes from "../../constants/routes";

const TableOfContentPanel = ({
  header,
  subHeaders,
  onHeaderClick,
  onSubHeaderClick,
  onSubHeader2Click,
  contentElement
}) => {
  const tableOfContentSubHeaderRouteMatch = useRouteMatch(
    routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER
  );
  const tableOfContentSubHeader2RouteMatch = useRouteMatch(
    routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER_SUBHEADER2
  );

  const currentTableOfContentId =
    tableOfContentSubHeader2RouteMatch?.params?.subheader2Id ??
    tableOfContentSubHeaderRouteMatch?.params?.subheaderId;

  const isCurrentTableOfContentHeader =
    tableOfContentSubHeaderRouteMatch?.isExact
      ? currentTableOfContentId === header.id
      : false;
  const currentTableOfContentHeaderClass = isCurrentTableOfContentHeader
    ? " current"
    : "";

  const handleClick = (subHeader, index) => () => {
    const isCurrentTableOfContent = currentTableOfContentId === subHeader.id;

    if (isCurrentTableOfContent) {
      return;
    }

    if (isSubHeader2Type(subHeader)) {
      const parent = subHeaders
        .slice(0, index)
        .reverse()
        .find(x => {
          return isSubHeader1Type(x);
        });
      onSubHeader2Click(parent, subHeader);
    } else if (isSubHeader1Type(subHeader)) {
      onSubHeaderClick(subHeader);
    } else {
      onHeaderClick(subHeader);
    }
  };

  return (
    <Panel
      title={header.description}
      key={header?.id}
      className="table-of-contents__panel"
    >
      <>
        {isHeaderType(header) &&
          (header.navigation_link || header.contains_text) && (
            <div
              key={`div_${header.description.replace(" ", "_")}_${header?.id}`}
              className="row"
            >
              <Anchor
                className={`table-of-contents__link${currentTableOfContentHeaderClass}`}
                key={`btn${header.description.replace(" ", "_")}_${header?.id}`}
                disabled={isCurrentTableOfContentHeader}
                onClick={handleClick(header)}
                aria-controls={`toc-panel-contents-${header.id}`}
                aria-expanded={isCurrentTableOfContentHeader}
              >
                {header.description}
              </Anchor>
              <div className="uikit-sr-only" id={`toc-panel-contents-${header.id}`}>
                {isCurrentTableOfContentHeader && contentElement}
              </div>
            </div>
          )}
        {subHeaders.map((subHeader, index) => {
          const subHeaderClass = isSubHeader2Type(subHeader)
            ? " table-of-contents__link--indented"
            : "";

          const isCurrentTableOfContentSubHeader =
            currentTableOfContentId === subHeader?.id;
          const currentTableOfContentSubHeaderClass =
            currentTableOfContentId === subHeader?.id ? " current" : "";

          return (
            <div
              key={`div_${subHeader.description.replace(" ", "_")}_${
                subHeader?.id
              }`}
              className="row"
            >
              <Anchor
                className={`table-of-contents__link${subHeaderClass}${currentTableOfContentSubHeaderClass}`}
                key={`btn${subHeader.description.replace(" ", "_")}_${
                  subHeader?.id
                }`}
                disabled={isCurrentTableOfContentSubHeader}
                onClick={handleClick(subHeader, index + 1)}
                aria-controls={`toc-panel-contents-${subHeader.id}`}
                aria-expanded={isCurrentTableOfContentSubHeader}
              >
                {subHeader.description}
              </Anchor>
              <div className="uikit-sr-only" id={`toc-panel-contents-${subHeader.id}`}>
                {isCurrentTableOfContentSubHeader && contentElement}
              </div>
            </div>
          );
        })}
      </>
    </Panel>
  );
};

TableOfContentPanel.propTypes = {
  header: PropTypes.object,
  subHeaders: PropTypes.array,
  onHeaderClick: PropTypes.func.isRequired,
  onSubHeaderClick: PropTypes.func.isRequired,
  onSubHeader2Click: PropTypes.func.isRequired,
  contentElement: PropTypes.element
};

export default TableOfContentPanel;
