import React, { useContext, useState } from "react";
import * as api from "../../services/onlineQuotingService";
import {
  Button,
  Form,
  useForm,
  FormGroup,
  Input,
  InputEmail
} from "@ufginsurance/ui-kit";
import OqModal from "../shared/OqModal";
import OnlineQuotingContext from "../OnlineQuotingContext";

import "./ServiceCenterContactInfo.scss";

const ServiceCenterContactInfo = ({
  onHide,
  show,
  accountContacts,
  mode,
  hasServiceCenter
}) => {
  const {
    getQuotePart,
    patchQuotePromise,
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    showUpdatingToast,
    closeUpdatingToast,
    quoteIsUpdating,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const accountId = getQuotePart("baseData.accountNumber");

  const contactFromSupData =
    supportingData?.ServiceCenterAccountData?.accountContactDTO;
  const constactFromMetadata = accountContacts.find(x =>
    x.accountContactRoles.some(y => y?.subtype === "SvcCenter_UFG")
  );

  const contactInfo = contactFromSupData || constactFromMetadata;

  const initialValues = {
    firstName: contactInfo?.firstName || "",
    lastName: contactInfo?.lastName || "",
    emailAddress1: contactInfo?.emailAddress1 || "",
    emailAddress2: contactInfo?.emailAddress2 || "",
    workNumber: contactInfo?.workNumber || "",
    homeNumber: contactInfo?.homeNumber || "",
    cellNumber: contactInfo?.cellNumber || "",
    faxNumber: contactInfo?.faxNumber || ""
  };

  const [showSecondaryEmail, setShowSecondaryEmail] = useState(
    !!contactInfo?.emailAddress2
  );
  const [showHomeNumber, setShowHomeNumber] = useState(
    !!contactInfo?.homeNumber
  );
  const [showCellNumber, setShowCellNumber] = useState(
    !!contactInfo?.cellNumber
  );
  const [showFaxNumber, setShowFaxNumber] = useState(!!contactInfo?.faxNumber);

  const onSubmit = () => {
    const payload = {
      accountNumber: accountId,
      accountContactDTO: {
        subtype: "Person",
        primaryPhoneType: "work",
        firstName: values.firstName,
        lastName: values.lastName,
        accountHolder: false,
        additionalAddresses: [],
        emailAddress1: values.emailAddress1 || "",
        emailAddress2: values.emailAddress2 || "",
        workNumber: values.workNumber || "",
        homeNumber: values.homeNumber || "",
        cellNumber: values.cellNumber || "",
        faxNumber: values.faxNumber || "",
        // set the default address to be the mailing address
        primaryAddress:
          supportingData.customerInformation.accountHolder.additionalAddresses.find(
            a => a.addressType === "mailing_ufg"
          )
      },
      inServiceCenter: true
    };

    const message = "Updating Service Center Contact Details";
    showUpdatingToast({ message });
    // we're editing an existing contact
    api
      .updateServiceCenterContact({ accountId, payload })
      .then(response => {
        updateSupportingDataPromise({
          dataToMergeAndSave: {
            ServiceCenterAccountData: response.data
          }
        }).then(() => {
          closeUpdatingToast({ message });
          if (mode === "edit" && hasServiceCenter) {
            // is 'edit' mode, just close the modal
            onHide({ serviceCenterValue: undefined });
          } else {
            patchQuotePromise({
              newData: {
                sessionUUID: quoteData.sessionUUID,
                quoteId: quoteData.quoteID,
                serviceCenterIndicator: true
              },
              quoteId: quoteData.quoteID,
              updateMessage: "Updating Service Center Account Enrollment"
            })
              .then(({ success }) => {
                closeUpdatingToast({ message });
                if (success) {
                  onHide({ serviceCenterValue: true });
                }
              })
              .catch(({ error }) =>
                toastErrr({
                  misc: { accountId },
                  action: "enrollServiceCenter",
                  description: "Unable to enroll service center",
                  error
                })
              );
          }
        });
      })
      .catch(error => {
        closeUpdatingToast({ message });
        toastErrr({
          misc: { accountId },
          action: "updateServiceCenterContact",
          description: "failed to set service center contact",
          error
        });
      });
  };

  // initialize the hook
  const form = useForm({ values: initialValues, onSubmit });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm
  } = form;

  return (
    <OqModal
      size="md"
      title="Service Center Contact Details"
      className="oq__modal__service-center-contact"
      body={
        <div>
          <Form context={form}>
            <FormGroup>
              <Input
                id="firstName"
                name="firstName"
                label="First Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.firstName}
                required
                className="grow"
              />
              <Input
                id="lastName"
                name="lastName"
                label="Last Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.lastName}
                required
                className="grow"
              />
            </FormGroup>
            <FormGroup>
              <InputEmail
                id="emailAddress1"
                name="emailAddress1"
                label="Primary Email Address"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.emailAddress1}
                size="lg"
                required
              />
            </FormGroup>
            <FormGroup className="oq__service-center__dynamic-field">
              {showSecondaryEmail && (
                <>
                  <InputEmail
                    id="emailAddress2"
                    name="emailAddress2"
                    label="Secondary Email Address"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.emailAddress2}
                    size="lg"
                  />
                  <Button
                    onClick={() => {
                      setShowSecondaryEmail(false);
                      updateForm({ values: { ...values, emailAddress2: "" } });
                    }}
                    variant="tertiary"
                    isLink
                    className="oq__service-center__dynamic-field__remove"
                  >
                    <span>Remove</span>
                  </Button>
                </>
              )}
              {!showSecondaryEmail && !values?.emailAddress2 && (
                <Button
                  onClick={() => {
                    setShowSecondaryEmail(true);
                  }}
                  isLink
                >
                  + Add Secondary Email Address
                </Button>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                id="workNumber"
                name="workNumber"
                label="Work Phone Number"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.workNumber}
                required
                mask="phone"
                stripMaskFromValue={false}
              />
            </FormGroup>
            <FormGroup className="oq__service-center__dynamic-field">
              {showHomeNumber && (
                <>
                  <Input
                    id="homeNumber"
                    name="homeNumber"
                    label="Home Phone Number"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.homeNumber}
                    mask="phone"
                    stripMaskFromValue={false}
                  />
                  <Button
                    onClick={() => {
                      setShowHomeNumber(false);
                      updateForm({ values: { ...values, homeNumber: "" } });
                    }}
                    isLink
                    variant="tertiary"
                    className="oq__service-center__dynamic-field__remove"
                  >
                    <span>Remove</span>
                  </Button>
                </>
              )}
              {!showHomeNumber && !values?.homeNumber && (
                <Button
                  onClick={() => {
                    setShowHomeNumber(true);
                  }}
                  isLink
                >
                  + Add Home Phone Number
                </Button>
              )}
            </FormGroup>
            <FormGroup className="oq__service-center__dynamic-field">
              {showCellNumber && (
                <>
                  <Input
                    id="cellNumber"
                    name="cellNumber"
                    label="Mobile Phone Number"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.cellNumber}
                    mask="phone"
                    stripMaskFromValue={false}
                  />
                  <Button
                    onClick={() => {
                      setShowCellNumber(false);
                      updateForm({
                        values: { ...values, cellNumber: "" }
                      });
                    }}
                    variant="tertiary"
                    isLink
                    className="oq__service-center__dynamic-field__remove"
                  >
                    <span>Remove</span>
                  </Button>
                </>
              )}
              {!showCellNumber && !values?.cellNumber && (
                <Button
                  onClick={() => {
                    setShowCellNumber(true);
                  }}
                  isLink
                >
                  + Add Mobile Phone Number
                </Button>
              )}
            </FormGroup>
            <FormGroup className="oq__service-center__dynamic-field">
              {showFaxNumber && (
                <>
                  <Input
                    id="faxNumber"
                    name="faxNumber"
                    label="Fax Number"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.faxNumber}
                    mask="phone"
                    stripMaskFromValue={false}
                  />
                  <Button
                    onClick={() => {
                      setShowFaxNumber(false);
                      updateForm({ values: { ...values, faxNumber: "" } });
                    }}
                    variant="tertiary"
                    isLink
                    className="oq__service-center__dynamic-field__remove"
                  >
                    <span>Remove</span>
                  </Button>
                </>
              )}
              {!showFaxNumber && !values?.faxNumber && (
                <Button
                  onClick={() => {
                    setShowFaxNumber(true);
                  }}
                  isLink
                >
                  + Add Fax Phone Number
                </Button>
              )}
            </FormGroup>

            <FormGroup align="right">
              <Button
                isLink
                onClick={() => {
                  onHide({ serviceCenterValue: mode === "edit" });
                }}
                disabled={quoteIsUpdating}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={quoteIsUpdating || !!invalidFields.length}
              >
                {!hasServiceCenter
                  ? "Update Contact and Enroll"
                  : "Update Contact"}
              </Button>
            </FormGroup>
          </Form>
        </div>
      }
      show={show}
      onHide={onHide}
      altCloseMethod={false}
      closeIcon={false}
    />
  );
};

export default ServiceCenterContactInfo;
