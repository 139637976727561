import React from "react";
import { toTitleCase } from "../../../components/Factory";
import { formatAddress } from "./../shared/util";

const CustomerInfo = ({ quoteData, step }) => {
  return (
    <>
      {quoteData && step > 1 && (
        <div className="qq__sidebar__summary__item">
          <b>Customer Information:</b>
          <div>{toTitleCase(quoteData?.account_name)}</div>
          <div>{formatAddress(quoteData?.address_line)}</div>
          <div className="flexRow wrap">
            <span className="qq__sidebar__summary__city">
              {toTitleCase(quoteData?.city)}
            </span>
            ,
            <span className="qq__sidebar__summary__state">
              {quoteData?.state}
            </span>
            <span className="qq__sidebar__summary__postalCode">
              {quoteData?.zip}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerInfo;
