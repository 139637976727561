import API from "../api";

export const getUWReviewBondInfo = bond_number =>
  API.surety().get(`/under-writer?bond_number=${bond_number}`);

export const getUWReviewAccountInfo = (
  agency_code,
  account_number,
  sub_producer
) =>
  API.surety().get(
    `/account-info?agency_code=${agency_code}&account_number=${account_number}&sub_producer=${sub_producer}`
  );

export const postUWDecision = data => {
  return API.surety().post(`/decision`, data);
};

export const getUWReviewWarningMessages = (
  agency_code,
  account_number,
  bond_number,
  is_new_account
) =>
  API.surety().get(
    `/under-writer/warning-messages?account_number=${account_number}&agency_code=${agency_code}&bond_number=${bond_number}&is_new_account=${is_new_account}`
  );
