import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from "react";
import * as api from "../../services/onlineQuotingService";
import cn from "classnames";
import _remove from "lodash/remove";
import { v4 } from "uuid";
import {
  Panel,
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  LoadingIndicator,
  Select,
  formatDate,
  Icon,
  ToolTip
} from "@ufginsurance/ui-kit";
import {
  toHtmlSelectOptions,
  arrayToOptions,
  addUpdateArrayItem
} from "../shared/util";
import { productKeys } from "../shared/constants";
import { classCodeFilter } from "../shared/classCodeFilter";
import OnlineQuotingContext from "../OnlineQuotingContext";
import "../Step2Classify.scss";
import { StateLongNames } from "../../constants/dictionary";

const ClassCodes = ({ activeAgencyCode, userInfo }) => {
  const { supportingData, updateSupportingDataPromise, step, toastErrr } =
    useContext(OnlineQuotingContext);

  const loggingData = useMemo(
    () => ({
      step,
      activeAgencyCode,
      userInfo
    }),
    [activeAgencyCode, step, userInfo]
  );

  const [classData, setClassData] = useState();
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classesLoaded, setLoaded] = useState(false);
  const [spinner, setSpinner] = useState(false);

  // making USstate as a local state because future component updates will be allowing the user to change this via a dropdown
  const [USstate] = useState(
    supportingData?.customerInformation?.accountHolder?.primaryAddress?.state
  );

  const initialValues = {
    business_type: [],
    search: "",
    lessorsRiskIndicator:
      USstate === "FL"
        ? "No"
        : supportingData?.lessorsRiskIndicator
        ? "Yes"
        : "No"
  };
  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange, updateForm } =
    form;

  const saveTODB = useCallback(() => {
    setSpinner(true);
    const dataToMergeAndSave = {
      classCode: selectedClasses.find(c => c.primary) || { code: null },
      additionalClassCodes: selectedClasses?.length
        ? selectedClasses.filter(c => !c.primary)
        : [],
      lessorsRiskIndicator: values.lessorsRiskIndicator === "Yes"
    };

    // saving class codes to supporting data
    updateSupportingDataPromise({ dataToMergeAndSave })
      .catch(({ error }) =>
        toastErrr({
          action: "updateSupportingDataPromise",
          description: "Unable to save supporting data",
          error,
          payload: dataToMergeAndSave
        })
      )
      .finally(() => setSpinner(false));
  }, [
    selectedClasses,
    toastErrr,
    updateSupportingDataPromise,
    values.lessorsRiskIndicator
  ]);

  const addClassCode = useCallback(
    (classObj, saveToDB = true) => {
      if (!classObj) return;

      //if no primary is found.
      if (!selectedClasses.find(c => c.primary === true)) {
        classObj.primary = true;
        //if item exist remove it and add primary at the front.
        _remove(selectedClasses, b => b.code === classObj.code);
        selectedClasses.splice(0, 0, classObj);
        setSelectedClasses([...selectedClasses]);
      } else {
        classObj.primary = false;
        const _selClasses = addUpdateArrayItem(
          selectedClasses,
          classObj,
          "code"
        );
        setSelectedClasses([..._selClasses]);
      }

      if (saveToDB) saveTODB();
    },
    [saveTODB, selectedClasses]
  );

  const resetForm = () => {
    const resetValues = {
      ...initialValues,
      lessorsRiskIndicator: values.lessorsRiskIndicator
    };

    updateForm({ values: resetValues });
  };

  useEffect(() => {
    if (!businessTypes.length) {
      api.getBusinessTypes().then(results => {
        if (!!results?.data?.formData?.businessTypes_UFG.length) {
          setBusinessTypes(results?.data?.formData?.businessTypes_UFG);
        }
      });
    }
  }, [businessTypes]);

  // run first time after supportingData is available and if the class list is empty
  useEffect(() => {
    if (!!supportingData && !classData && !spinner) {
      const products = !!supportingData?.policyLines.length
        ? Object.keys(productKeys).filter(p =>
            supportingData.policyLines.includes(p)
          )
        : [];

      const effectiveDate = formatDate(
        supportingData?.effectiveDate,
        "YYYY-MM-DD"
      );

      if (products.length) {
        setSpinner(true);
        api
          .getClassCodesMetaData({ businessType: "", products, effectiveDate })
          .then(results => {
            if (!(results && results.data) && results.data.length === 0) {
              //alert("No class codes returns");
              return;
            }

            // set a unique id for the class list
            let data = results.data.map(d => ({
              id: v4(),
              ...d
            }));

            const primaryState =
              StateLongNames[
                supportingData?.customerInformation?.accountHolder
                  ?.primaryAddress?.state
              ];
            // filter out state-specific classes
            // https://ufginsurance.atlassian.net/browse/OOQ-9894
            if (primaryState) {
              data = data.filter(
                x =>
                  x.jurisdiction.length === 0 ||
                  x.jurisdiction.includes(primaryState)
              );
            }

            // update the class lists with the new data from the api
            setFilteredClasses(data);
            setClassData(data);
          })
          .catch(error => {
            toastErrr({
              displayMessage: `An error occurred.  Unable to retreive class codes.`,
              action: "load class data",
              error,
              description: "api load of class data in add class modal"
            });
            //alert("Class codes metadata failure:" + error.response);
            console.error(
              "Class codes metadata failure:" + JSON.stringify(error)
            );
            setClassData([]); // set classData to empty so it doesn't keep trying to load
          })
          .finally(() => {
            setSpinner(false);
          });
      }
    }
  }, [classData, loggingData, spinner, supportingData, toastErrr]);

  // load the selected classes from the quote data
  useEffect(() => {
    if (!classesLoaded && classData && classData.length) {
      if (supportingData?.classCode) {
        const primaryClass = classData.find(
          c =>
            c.code === supportingData?.classCode?.code &&
            c.description === supportingData?.classCode?.description
        );
        if (primaryClass) addClassCode(primaryClass, false);
      }
      if (supportingData?.additionalClassCodes?.length) {
        supportingData.additionalClassCodes.forEach(savedCode => {
          const additionalClass = classData.find(
            c =>
              c.code === savedCode?.code &&
              c.description === savedCode?.description
          );
          if (additionalClass) addClassCode(additionalClass, false);
        });
      }
      setLoaded(true);
    }
  }, [
    addClassCode,
    classData,
    classesLoaded,
    supportingData.additionalClassCodes,
    supportingData.classCode
  ]);

  // FILTER THE CLASS DATA BASED ON UPDATED CRITERIA
  // filter the classes to include matches for the Business Type dropdown, the search text
  // ... and remove if the class is already selected
  // ... and display the appropriate class depending on the lessors' risk toggle
  useEffect(() => {
    const busType = (values.business_type || []).map(v => v.toLowerCase());
    const searchText = values.search.toLowerCase();
    const lessorsRiskIndicator = values.lessorsRiskIndicator === "Yes";

    const filteredData = classCodeFilter({
      data: classData,
      busType,
      searchText,
      lessorsRiskIndicator,
      selectedClasses
    });

    // update table data state
    setFilteredClasses(filteredData);
  }, [classData, selectedClasses, values]);

  const removeClassCode = classObj => {
    _remove(selectedClasses, b => b.code === classObj.code);
    setSelectedClasses([...selectedClasses]);

    saveTODB();
  };

  const appetiteValue = ({ clss, st8 }) => {
    return (
      clss?.exceptions?.states?.[st8]?.bp7BusinessOwners ||
      clss?.default?.bp7BusinessOwners
    );
  };

  const tooltipContent = row => {
    const color = appetiteValue({ clss: row, st8: USstate });
    return color === "green"
      ? "Likely acceptable"
      : color === "yellow"
      ? "May require UW review"
      : "Not eligible for Pro-Quote";
  };

  const colorBar = row => {
    const apptit = appetiteValue({ clss: row, st8: USstate });
    const color =
      apptit === "green"
        ? "oq__green"
        : apptit === "yellow"
        ? "oq__orange"
        : "oq__red";

    return (
      <Button wrapperClassName="oq__appetite__color-bar__wrapper">
        <span className={cn("oq__appetite__color-bar", color)}></span>
      </Button>
    );
  };

  const addClassButton = row => {
    if (appetiteValue({ clss: row, st8: USstate }) === "red") {
      return <span className="oq_unavailable">Unavailable Online</span>;
    }
    return (
      <Button
        className="oq__button__add-class"
        wrapperClassName="oq__add-button__small"
        onClick={() => addClassCode(row)}
        size="sm"
        variant="info"
        icon="farPlusCircle"
      >
        Add
      </Button>
    );
  };

  const tableColumns = [
    {
      key: "code",
      label: "Class Code",
      sortable: true
    },
    {
      key: "description",
      label: "Class Description",
      className: "oq__classes__unselected__description",
      sortable: true
    },
    {
      key: "businessType_UFG",
      label: "Business Type",
      sortable: true
    },
    {
      key: "code",
      label: "Appetite",
      sortable: true,
      className: "oq__appetite__column",
      element: row => {
        return (
          <ToolTip
            id={`appetiteTooltip-${row.id}`}
            inline={false}
            type="hover"
            variant="white"
            trigger={colorBar(row)}
            content={tooltipContent(row)}
          />
        );
      }
    },
    {
      key: "addbutton",
      label: "Add Class",
      className: "oq__appetite__add-class",
      element: addClassButton,
      sortable: false,
      align: "right"
    }
  ];

  const removeButton = row => {
    return (
      <Button
        wrapperClassName="oq__classes__remove-btn-wrapper"
        className="oq__classes__remove-btn"
        size="sm"
        variant="tertiary"
        onClick={() => {
          removeClassCode(row);
        }}
      >
        Delete
      </Button>
    );
  };

  const tableSelectedClassColumns = [
    {
      key: "",
      label: "",
      sortable: true,
      className: "oq__classes__selected__type-column",
      element: row => (
        <b>{row.primary ? "Primary Class:" : "Additional Class:"}</b>
      )
    },
    {
      key: "",
      label: "",
      sortable: true,
      className: "oq__classes__selected__code",
      element: row => `${row.code}`
    },
    {
      key: "",
      label: "",
      sortable: true,
      className: "oq__classes__selected__description",
      element: row => `${row.description}`
    },
    {
      key: "",
      label: "",
      sortable: true,
      element: row => `${row.businessType_UFG}`
    },
    {
      label: "",
      sortable: true,
      className: "oq__appetite__column",
      element: row => <>{colorBar(row)}</>
    },
    {
      key: "",
      label: "",
      className: "oq__classes__selected__remove",
      element: removeButton,
      align: "right"
    }
  ];

  const header = (
    <div className="flexRow wrap">
      <div>Search for Class</div>
      <div className="rowSpacer" />
      <div className="oq__classes__headerItems flexRow">
        <span>Rate State:</span>
        <span className="oq__classes__headerItem">
          {supportingData?.customerInformation?.accountHolder?.primaryAddress
            ?.state || ""}
        </span>
      </div>
    </div>
  );

  // if we don't have data at the beginning... show the loading indicator
  if (!supportingData) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {(!classData || spinner) && <LoadingIndicator />}
      {classData && !!classData.length && (
        <Panel
          className="oq__classes"
          title={header}
          titlebar
          rounded
          bgcolor="grey"
        >
          {selectedClasses.length > 0 && (
            <div>
              <Table
                rowKey="id"
                className="oq__classes__selected"
                columns={tableSelectedClassColumns}
                data={selectedClasses}
                showPagination={selectedClasses.length > 100}
                itemsPerPage={100}
                nowrap
              />
            </div>
          )}
          <Form className="oq__classes-form" context={form}>
            <FormGroup className="oq__classes__searchTextRow" noOuterPadding>
              <p className="padded-bottom">
                Select the class of the tenant that occupies the most physical
                space.
              </p>
            </FormGroup>
            <FormGroup
              className="oq__classes__searchRow addPadUnder"
              noOuterPadding
              wrap={false}
            >
              <Select
                className="oq__classes__lessorRisk"
                id="lessorsRiskIndicator"
                name="lessorsRiskIndicator"
                label="Lessors' Risk"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.lessorsRiskIndicator}
                disabled={!!selectedClasses.length || USstate === "FL"}
                options={arrayToOptions(["No", "Yes"])}
                isClearable={false}
                showSearch={false}
                size="lg"
                labelElement={
                  USstate === "FL" ? (
                    <ToolTip
                      position="top"
                      variant="white"
                      nowrap={false}
                      width={150}
                      trigger={<Icon size="1x" icon="fasExclamationCircle" />}
                      content={
                        <span>
                          We are not currently writing Lessors&lsquo; Risk in
                          the state of Florida
                        </span>
                      }
                    />
                  ) : null
                }
              />
              <Input
                id="search"
                name="search"
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                label="Search Class or Description"
                onChange={handleOnChange}
                value={values.search}
                className="oq__classes__search"
                maxLength={150}
                size="fill"
              />
              <Select
                id="business_type"
                name="business_type"
                placeholder="Filter by business type..."
                label="Business Type"
                options={toHtmlSelectOptions(businessTypes)}
                className="oq__classes__bussinessType"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.business_type}
                size="md"
                multi
              />
              {(values.search || values.business_type.length > 0) && (
                <Button isLink onClick={resetForm} labelSpace>
                  Reset
                </Button>
              )}
            </FormGroup>
            <div>
              <Table
                className="oq__class-table"
                rowKey="id"
                columns={tableColumns}
                data={filteredClasses}
                showPagination
                itemsPerPage={10}
                noResultsMessage="No results found"
                initialSort="description"
              />
            </div>
          </Form>
        </Panel>
      )}
    </div>
  );
};

export default ClassCodes;
