import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import * as routes from "../../constants/routes";
import { withRouter } from "react-router";
import BillingResources from "../billing/components/BillingResources";
import ClaimsResources from "../claims/components/ClaimsResources";
import AlwaysReload from "../../components/routing/AlwaysReload";
import AgencyInterface from "../agency-interface/components/AgencyInterface";
import { CMSProvider } from "../../cms-content/context";

const ResourcesHome = ({ location, ssoSession }) => {
  return (
    <CMSProvider>
      <Switch>
        <Redirect exact from={routes.RESOURCES} to={routes.BILLING_RESOURCES} />
        <AlwaysReload location={location}>
          <Route
            exact
            path={routes.BILLING_RESOURCES}
            render={() => <BillingResources ssoSession={ssoSession} />}
          />
          <Route
            exact
            path={routes.BILLING_RESOURCES_HEADER}
            render={() => <BillingResources ssoSession={ssoSession} />}
          />
          <Route
            exact
            path={routes.BILLING_RESOURCES_SUB_HEADER}
            render={() => <BillingResources ssoSession={ssoSession} />}
          />

          <Route path={routes.CLAIMS_RESOURCES} component={ClaimsResources} />

          <Route
            exact
            path={routes.AGENCY_INTERFACE}
            component={AgencyInterface}
          />
          <Route
            exact
            path={routes.AGENCY_INTERFACE_HEADER}
            component={AgencyInterface}
          />
          <Route
            exact
            path={routes.AGENCY_INTERFACE_SUB_HEADER}
            component={AgencyInterface}
          />
        </AlwaysReload>
      </Switch>
    </CMSProvider>
  );
};

ResourcesHome.propTypes = {
  location: PropTypes.object.isRequired,
  ssoSession: PropTypes.object.isRequired
};

export default connect(({ ssoSession }) => ({ ssoSession }))(
  withRouter(ResourcesHome)
);
