import React, { useEffect, useState, useContext } from "react";
import * as api from "../../../services/onlineQuotingService";
import * as SiUtils from "./scheduleItemUtils";
import { sortByProperty } from "../util";
import { Table, Icon, FlexRow, Button } from "@ufginsurance/ui-kit";
import ConfirmationModal from "../ConfirmationModal.js";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import ScheduleItemModal from "./ScheduleItemModal";
import { CoveragesRequiringAtleastOnScheduledItem } from "../constants";
import MissingItemPanel from "../MissingItemPanel";

import "./ScheduleItem.scss";

const ERROR_ADDING_ITEM =
  "Sorry, an error occurred. We were unable to add Scheduled Item.";

const ScheduleItemTable = ({
  field,
  scheduleItems,
  setScheduleItemOpenInCoverable,
  editScheduledItems = true
}) => {
  const {
    quoteData,
    updateQuote,
    toastErrr,
    quoteIsUpdating,
    showUpdatingToast,
    closeUpdatingToast
  } = useContext(OnlineQuotingContext);

  const [showEditModal, setShowEditModal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [itemToDelete, setItemToDelete] = useState();

  const scheduleItemsArray = scheduleItems.scheduleItems;

  useEffect(() => {
    if (!!setScheduleItemOpenInCoverable) {
      setScheduleItemOpenInCoverable(showEditModal);
    }
  }, [setScheduleItemOpenInCoverable, showEditModal]);

  // FUNCTION: ADD ITEM
  const handleAddItem = () => {
    const toastId = Math.random();
    const message = "Adding Schedule item";
    showUpdatingToast({ message, toastId });
    api
      .createScheduleItem({
        quoteId: quoteData.quoteID,
        clausePattern: scheduleItems.clausePattern,
        entityName: scheduleItems.entityName,
        coverableName: field.name,
        coverableFixedId: field.clauseScheduleItems.coverableFixedId
      })
      .then(results => {
        if (!!results?.data?.scheduleItemFixedId) {
          // add the new schedule item to the quote object
          const newData = { ...quoteData };

          const coverageArray = SiUtils.findCoverageToUpdate({
            field,
            quoteData: newData
          });

          if (!coverageArray) {
            console.error("No coverageArray");
            toastErrr({
              action: "createScheduleItem",
              description: "schedule item no coverageArray",
              misc: { field, quoteData: newData }
            });

            return false; // SHOW ERROR?
          }

          // find the coverage and and the new schedule item
          const coverageToUpdate = coverageArray.find(
            c => c.publicID === field.publicID
          );
          if (!coverageToUpdate) {
            console.error("No coverageToUpdate");
            toastErrr({
              action: "createScheduleItem",
              description: "schedule item no coverageToUpdate",
              misc: { field, quoteData: newData }
            });
            return false; // SHOW ERROR?
          }
          coverageToUpdate.clauseScheduleItems.scheduleItems.push(results.data);

          // add new item to "selectedItem" state so it's avail in the modal
          setSelectedItem({ isNew: true, data: results.data, field });

          // update the local quote object
          updateQuote({ newData, mergeData: false, sendToPC: false }, () => {
            // update the selected item and open the modal to edit it
            setShowEditModal(true);
          });
        } else {
          toastErrr({
            action: "createScheduleItem",
            description: "schedule item issue",
            misc: { results },
            displayMessage: ERROR_ADDING_ITEM
          });
        }
      })
      .catch(error => {
        console.error(error);
        toastErrr({
          action: "createScheduleItem",
          description: "unable to create schedule item",
          error,
          payload: {
            quoteId: quoteData.quoteID,
            clausePattern: scheduleItems.clausePattern,
            entityName: scheduleItems.entityName,
            coverableName: field.name,
            coverableFixedId: field.clauseScheduleItems.coverableFixedId
          },
          misc: { field },
          displayMessage: ERROR_ADDING_ITEM
        });
      })
      .finally(() => closeUpdatingToast({ toastId }));
  };

  // FUNCTION: EDIT ITEM
  const handleEditItem = row => {
    // this takes the selected row and adds it to the "selectedItem" state...
    // and passes that state to the SI modal
    setSelectedItem({
      isNew: false,
      data: {
        coverages: row.fld.coverages,
        scheduleItemFixedId: row.fld.scheduleItemFixedId,
        fieldsMetadata: row.fld.fieldsMetadata
      },
      field
    });
    setShowEditModal(true);
  };

  // clicking delete sets the itemToDelete state which
  // triggers the confirmation modal to display
  const handleDeleteItem = row => {
    setItemToDelete(row);
  };

  // FUNCTION: DELETE ITEM
  const doTheDelete = row => {
    const toastId = Math.random();
    showUpdatingToast({ message: "Deleting scheduled item", toastId });

    // make the call to delete the item
    api
      .deleteScheduledItem({
        quoteId: quoteData.quoteID,
        clausePattern: scheduleItems.clausePattern,
        entityName: scheduleItems.entityName,
        coverableName: field.name,
        scheduleItemId: row.scheduleItemFixedId,
        coverableFixedId: field.clauseScheduleItems.coverableFixedId
      })
      .then(() => {
        setItemToDelete(null);

        // after the delete, update the local quote data
        const newData = { ...quoteData };
        // find the coverage and remove the local schedule item
        const coverageArray = SiUtils.findCoverageToUpdate({
          field,
          quoteData: newData
        });

        if (!coverageArray) return false; // SHOW ERROR?

        const coverageToUpdate = coverageArray.find(
          c => c.publicID === field.publicID
        );
        coverageToUpdate.clauseScheduleItems.scheduleItems =
          coverageToUpdate.clauseScheduleItems.scheduleItems.filter(
            i => i.scheduleItemFixedId !== row.scheduleItemFixedId
          );
        // renumber the "Schedule Number" sequence for the remaining items (if it exists)
        let c = 1;
        coverageToUpdate.clauseScheduleItems.scheduleItems.forEach(i => {
          const scheduleNumberField = i.fieldsMetadata.find(
            ii => ii.key === "ScheduleNumber"
          );
          // not ever schedule item has a "ScheduleNumber" field... so we only update it if it exists
          if (scheduleNumberField) scheduleNumberField.integerValue = c++;
        });
        // update the local quote object
        updateQuote({ newData, mergeData: false, sendToPC: false }, () => {
          closeUpdatingToast({ toastId });
        });
      })
      .catch(e => console.error(e));
  };

  // TABLE COLUMN SETUP
  const cols =
    !!scheduleItemsArray && scheduleItemsArray.length === 0
      ? []
      : scheduleItemsArray[0].fieldsMetadata.map(fld => ({
          key: fld.key,
          label: fld.label
        }));

  // set up the data for the table rows
  const tableData = scheduleItemsArray
    .map(d => {
      const rowData = {
        scheduleItemFixedId: d.scheduleItemFixedId,
        fld: d
      };
      d.fieldsMetadata.forEach(fld => {
        const { value, displayValue } = SiUtils.fieldDefinition(fld);
        rowData[fld.key] = displayValue || value;
      });
      return rowData;
    })
    .sort(sortByProperty("ScheduleNumber"));

  const canNotDelete =
    CoveragesRequiringAtleastOnScheduledItem.includes(field.codeIdentifier) &&
    tableData.length === 1;

  // add the edit and delete icons
  cols.push({
    key: "actions",
    label: "",
    element: row => (
      <div className="oq__schedule-item-table__action-column">
        {editScheduledItems && (
          <Icon
            className="oq__table-actions__icon"
            icon="fasPencil"
            onClick={() => handleEditItem(row)}
          />
        )}
        {editScheduledItems && !canNotDelete && (
          <Icon
            className="oq__table-actions__icon"
            icon="fasTimesCircle"
            onClick={() => handleDeleteItem(row)}
          />
        )}
      </div>
    )
  });

  return (
    <div>
      {!!editScheduledItems && scheduleItemsArray.length === 0 ? (
        <MissingItemPanel
          onClickHandler={handleAddItem}
          className="oq__schedule-item__add-button"
          content={
            <>
              At least one <i>{field.name}</i> is required to continue.
            </>
          }
          buttonText="Add New"
        />
      ) : (
        !!editScheduledItems && (
          <FlexRow>
            <Button
              className="oq__schedule-item__add-button"
              size="sm"
              variant="info"
              disabled={quoteIsUpdating}
              onClick={handleAddItem}
            >
              Add Scheduled Item
            </Button>
          </FlexRow>
        )
      )}

      {scheduleItemsArray.length > 0 && (
        <div className="oq__coverage__schedule-item">
          <Table
            className="oq__schedule-item-table"
            rowKey="scheduleItemFixedId"
            columns={cols}
            data={tableData}
            showPagination={false}
            itemsPerPage={5}
          />
        </div>
      )}

      {showEditModal && (
        <ScheduleItemModal
          setShowModal={setShowEditModal}
          apiPayload={{
            quoteId: quoteData.quoteID,
            clausePattern: scheduleItems.clausePattern,
            entityName: scheduleItems.entityName,
            coverableName: field.name
          }}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          cancelTheAddItem={row => doTheDelete(row)}
        />
      )}
      {itemToDelete && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <>
              <div>Are you sure you want to remove this Scheduled Item?</div>
            </>
          }
          onClickYes={() => doTheDelete(itemToDelete)}
          onHide={() => setItemToDelete(null)}
          show
        />
      )}
    </div>
  );
};

export default ScheduleItemTable;
