export const FETCH_CONSENT_BONDS_STARTED = "FETCH_CONSENT_BONDS_STARTED";
export const FETCH_CONSENT_BONDS_SUCCEEDED = "FETCH_CONSENT_BONDS_SUCCEEDED";
export const FETCH_CONSENT_BONDS_FAILED = "FETCH_CONSENT_BONDS_FAILED";

export const FETCH_COMMERCIAL_QUOTES_STARTED =
  "FETCH_COMMERCIAL_QUOTES_STARTED";
export const FETCH_COMMERCIAL_QUOTES_SUCCEEDED =
  "FETCH_COMMERCIAL_QUOTES_SUCCEEDED";
export const FETCH_COMMERCIAL_QUOTES_FAILED = "FETCH_COMMERCIAL_QUOTES_FAILED";

export const FETCH_BID_REQUEST_HISTORY_STARTED =
  "FETCH_BID_REQUEST_HISTORY_STARTED";
export const FETCH_BID_REQUEST_HISTORY_SUCCEEDED =
  "FETCH_BID_REQUEST_HISTORY_SUCCEEDED";
export const FETCH_BID_REQUEST_HISTORY_FAILED =
  "FETCH_BID_REQUEST_HISTORY_FAILED";

export const FETCH_BILLING_COPIES_STARTED = "FETCH_BILLING_COPIES_STARTED";
export const FETCH_BILLING_COPIES_SUCCEEDED = "FETCH_BILLING_COPIES_SUCCEEDED";
export const FETCH_BILLING_COPIES_FAILED = "FETCH_BILLING_COPIES_FAILED";

export const FETCH_BILLING_COPIES_DATE_STARTED =
  "FETCH_BILLING_COPIES_DATE_STARTED";
export const FETCH_BILLING_COPIES_DATE_SUCCEEDED =
  "FETCH_BILLING_COPIES_DATE_SUCCEEDED";
export const FETCH_BILLING_COPIES_DATE_FAILED =
  "FETCH_BILLING_COPIES_DATE_FAILED";

export const FETCH_BILLING_COPIES_BOND_STARTED =
  "FETCH_BILLING_COPIES_BOND_STARTED";
export const FETCH_BILLING_COPIES_BOND_SUCCEEDED =
  "FETCH_BILLING_COPIES_BOND_SUCCEEDED";
export const FETCH_BILLING_COPIES_BOND_FAILED =
  "FETCH_BILLING_COPIES_BOND_FAILED";

export const FETCH_AGENCY_ACCOUNTS_STARTED = "FETCH_AGENCY_ACCOUNTS_STARTED";
export const FETCH_AGENCY_ACCOUNTS_SUCCEEDED =
  "FETCH_AGENCY_ACCOUNTS_SUCCEEDED";
export const FETCH_AGENCY_ACCOUNTS_FAILED = "FETCH_AGENCY_ACCOUNTS_FAILED";

export const FETCH_EXECUTED_BONDS_STARTED = "FETCH_EXECUTED_BONDS_STARTED";
export const FETCH_EXECUTED_BONDS_SUCCEEDED = "FETCH_EXECUTED_BONDS_SUCCEEDED";
export const FETCH_EXECUTED_BONDS_FAILED = "FETCH_EXECUTED_BONDS_FAILED";

export const FETCH_UPLOAD_HISTORY_STARTED = "FETCH_UPLOAD_HISTORY_STARTED";
export const FETCH_UPLOAD_HISTORY_SUCCEEDED = "FETCH_UPLOAD_HISTORY_SUCCEEDED";
export const FETCH_UPLOAD_HISTORY_FAILED = "FETCH_UPLOAD_HISTORY_FAILED";
