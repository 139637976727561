import React, { useCallback, useEffect, useState, useContext } from "react";
import { Button, Modal, FlexRow, LoadingIndicator } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";
import * as api from "../../services/onlineQuotingService";
import CheckboxButton from "../shared/CheckboxButton";
import OnlineQuotingContext from "../OnlineQuotingContext";

import "./BopPrefill.scss";

const BopPrefill = ({ setPrefillModal, history, show, referenceId }) => {
  const { supportingData, navigateToStep } = useContext(OnlineQuotingContext);

  const [businessData, setBusinessData] = useState();
  const [selectedBusinessData, setSelectedBusinessData] = useState();
  const [apiStatus, setApiStatus] = useState();

  const continueToStep2 = useCallback(() => {
    navigateToStep({ step: 2 }, ({ success }) => {
      setPrefillModal(false);
      if (success) history.push(routes.ONLINE_QUOTING_STEP2);
    });
  }, [history, navigateToStep, setPrefillModal]);

  //go to step 2 on continue
  const goToStep2 = () => {
    if (!selectedBusinessData) continueToStep2();
    //if business selected call api to update the mule database
    else
      api
        .updateBusinessData(referenceId, { location_id: selectedBusinessData })
        .finally(() => {
          continueToStep2();
        });
  };

  //call Bop prefill
  useEffect(() => {
    if (!businessData && !apiStatus) {
      const addressforBusiness = {};

      const physicalAddress =
        supportingData?.customerInformation?.accountHolder?.primaryAddress;
      addressforBusiness.address1 = physicalAddress?.addressLine1;
      addressforBusiness.city = physicalAddress?.city;
      addressforBusiness.state = physicalAddress?.state;
      addressforBusiness.zip = physicalAddress?.postalCode;

      if (!!addressforBusiness.address1) {
        setApiStatus("running");
        //get the business data from service
        api
          .getBusinessData(
            addressforBusiness.address1,
            addressforBusiness.city,
            addressforBusiness.state,
            addressforBusiness.zip
          )
          .then(response => {
            setApiStatus("complete");
            if (!!response?.data?.location_list?.bop_businesses.length)
              setBusinessData(response?.data?.location_list);
            else continueToStep2();
          })
          .catch(error => {
            setApiStatus("complete");
            setBusinessData(null);
            console.log(error);
            continueToStep2();
          });
      }
    }
  }, [apiStatus, businessData, continueToStep2, supportingData]);

  if (!show) return null;

  return (
    <Modal
      className="oq__modal oq__bop-prefill__modal"
      title="Select Business"
      closeIcon={false}
      size="lg"
      body={
        <div className="oq__bop-prefill">
          {!businessData || businessData?.bop_businesses.length === 0 ? (
            <LoadingIndicator
              className="oq__bop-prefill__loading"
              message="Searching for matching business data..."
            />
          ) : (
            <>
              <p>Is the Business you are attempting to quote listed below?</p>
              {businessData.bop_businesses &&
                businessData.bop_businesses.map(data => {
                  return (
                    <FlexRow
                      className="oq__bop-prefill__row"
                      key={data.unique_identifier}
                    >
                      <CheckboxButton
                        selected={
                          selectedBusinessData === data.unique_identifier
                        }
                        onClick={() => {
                          if (
                            selectedBusinessData &&
                            selectedBusinessData === data.unique_identifier
                          ) {
                            setSelectedBusinessData(undefined);
                          } else {
                            setSelectedBusinessData(data.unique_identifier);
                          }
                        }}
                      >
                        {data.businesses}
                      </CheckboxButton>
                    </FlexRow>
                  );
                })}
              <FlexRow align="right">
                <Button
                  variant="primary"
                  onClick={goToStep2}
                  disabled={!!selectedBusinessData}
                >
                  Not Listed
                </Button>
                <Button
                  variant="primary"
                  onClick={goToStep2}
                  disabled={!selectedBusinessData}
                >
                  Select
                </Button>
              </FlexRow>
            </>
          )}
        </div>
      }
      show={show}
      altCloseMethod={false}
      onHide={() => setPrefillModal(false)}
    />
  );
};
export default BopPrefill;
