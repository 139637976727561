import * as types from "../../constants/actionTypes";
import * as agencySweepService from "../services/agencySweepService";
import { DocumentService } from "../../services/documentService";

export const processAgencySweepPaymentStarted = () => ({
  type: types.PROCESS_AGENCY_SWEEP_PAYMENT_STARTED
});

export const processAgencySweepPaymentSucceeded = data => ({
  type: types.PROCESS_AGENCY_SWEEP_PAYMENT_SUCCEEDED,
  payload: data
});

export const processAgencySweepPaymentFailed = error => ({
  type: types.PROCESS_AGENCY_SWEEP_PAYMENT_FAILED,
  payload: error,
  error: true
});

export const clearAgencySweepPaymentSuccess = () => ({
  type: types.CLEAR_AGENCY_SWEEP_PAYMENT_SUCCEEDED
});

export const sendOasisPaymentConfirmationStarted = () => ({
  type: types.SEND_OASIS_PAYMENT_CONFIRMATION_STARTED
});

export const sendOasisPaymentConfirmationSucceeded = data => ({
  type: types.SEND_OASIS_PAYMENT_CONFIRMATION_SUCCEEDED,
  payload: data
});

export const sendOasisPaymentConfirmationFailed = error => ({
  type: types.SEND_OASIS_PAYMENT_CONFIRMATION_FAILED,
  payload: error,
  error: true
});

export const agencySweepPaymentReportStarted = () => ({
  type: types.AGENCY_SWEEP_PAYMENT_PDF_LOADING
});

export const agencySweepPaymentReportSucceded = () => ({
  type: types.AGENCY_SWEEP_PAYMENT_PDF_SUCCEEDED
});

export const agencySweepPaymentReportFailed = () => ({
  type: types.AGENCY_SWEEP_PAYMENT_PDF_FAILED
});

export const processAgencySweepPayment = paymentRequest => dispatch => {
  dispatch(processAgencySweepPaymentStarted());
  return agencySweepService.processAgencySweepPayment(paymentRequest).then(
    res => {
      dispatch(processAgencySweepPaymentSucceeded(res.data));
    },
    error => {
      dispatch(processAgencySweepPaymentFailed(error));
    }
  );
};

export const clearAgencySweepPayment = () => dispatch => {
  dispatch(clearAgencySweepPaymentSuccess());
};

export const sendOasisPaymentConfirmation = paymentRequest => dispatch => {
  dispatch(sendOasisPaymentConfirmationStarted());
  return agencySweepService.sendOasisPaymentConfirmation(paymentRequest).then(
    res => {
      dispatch(sendOasisPaymentConfirmationSucceeded(res.data));
    },
    error => {
      dispatch(sendOasisPaymentConfirmationFailed(error));
    }
  );
};

export const fetchAgencySweepPaymentReport = data => dispatch => {
  const documentService = new DocumentService();
  dispatch(agencySweepPaymentReportStarted());
  return agencySweepService.paymentConfirmationReport(data).then(
    res => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        res.data,
        "agencySweep_payment_report.pdf"
      );
      dispatch(agencySweepPaymentReportSucceded());
    },
    () => {
      documentService.clearRenderer();
      dispatch(agencySweepPaymentReportFailed());
    }
  );
};
