import { useEffect } from "react";
import { findTableOfContentsItemById } from "../../selectors";
import { useCurrentRouteMatch } from "../hooks";

export const useSubheader2RouteValidation = (
  tableOfContents,
  setSelectedSubheader2,
  selectedSubheader2
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteSubheader2Id = currentMatch?.params?.subheader2Id;

  useEffect(() => {
    const hasTableOfContents = tableOfContents && tableOfContents.length > 0;
    const isNewSubheader2SelectionRoute =
      hasTableOfContents &&
      currentRouteSubheader2Id &&
      selectedSubheader2?.id &&
      selectedSubheader2?.id !== currentRouteSubheader2Id;
    if (isNewSubheader2SelectionRoute) {
      const routeSubheader2TableOfContentItem = findTableOfContentsItemById(
        tableOfContents,
        currentRouteSubheader2Id
      );
      if (routeSubheader2TableOfContentItem) {
        setSelectedSubheader2(routeSubheader2TableOfContentItem);
      }
    }
  }, [
    tableOfContents,
    currentRouteSubheader2Id,
    selectedSubheader2,
    setSelectedSubheader2
  ]);

  useEffect(() => {
    const hasTableOfContents = tableOfContents && tableOfContents.length > 0;
    const isSubheader2RouteWithoutSelection =
      hasTableOfContents && currentRouteSubheader2Id && !selectedSubheader2;
    if (isSubheader2RouteWithoutSelection) {
      const routeSubheader2TableOfContentItem = findTableOfContentsItemById(
        tableOfContents,
        currentRouteSubheader2Id
      );
      if (routeSubheader2TableOfContentItem) {
        setSelectedSubheader2(routeSubheader2TableOfContentItem);
      }
    }
  }, [
    tableOfContents,
    currentRouteSubheader2Id,
    selectedSubheader2,
    setSelectedSubheader2
  ]);

  return null;
};
