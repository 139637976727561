import React from "react";
import { Accordion } from "@ufginsurance/ui-kit";
import PolicyDecs from "./../PolicyDecs";
import ClientNotices from "./../ClientNotices";
import PropTypes from "prop-types";

const PersonalTabContainer = ({
  agencyCode,
  policyDecsList,
  policyDecsError,
  policyDecsLoading,
  fetchPolicyDecs,
  removePolicyDec,
  fetchClientNotices,
  clientNoticesList,
  clientNoticesError,
  clientNoticesLoading,
  removeClientNotice,
  deleteClientNoticeError,
  resetClientNotices,
  fetchClientNoticeDocument,
  getPolicyDecsPersonalDocument,
  is109015Agent,
  activeSpecialtyCompanyBranch
}) => {
  return (
    <div id="home-personal-container">
      <Accordion
        textMain="Policy Decs"
        textExtra="New, renewal, and endorsed policy decs processed during the specified
        date range"
        isOpen
      >
        <PolicyDecs
          agencyCode={agencyCode}
          fetchPolicyDecs={fetchPolicyDecs}
          policyDecsList={policyDecsList}
          policyDecsLoading={policyDecsLoading}
          policyDecsError={policyDecsError}
          removePolicyDec={removePolicyDec}
          fetchPolicyDecsDocument={getPolicyDecsPersonalDocument}
          is109015Agent={is109015Agent}
          activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
        />
      </Accordion>

      <Accordion
        textMain="Client Notices"
        textExtra="Cancellations, reinstatements and lapse termination notices"
      >
        <ClientNotices
          agencyCode={agencyCode}
          fetchClientNotices={fetchClientNotices}
          clientNoticesList={clientNoticesList}
          clientNoticesError={clientNoticesError}
          clientNoticesLoading={clientNoticesLoading}
          removeClientNotice={removeClientNotice}
          deleteClientNoticeError={deleteClientNoticeError}
          resetClientNotices={resetClientNotices}
          fetchClientNoticeDocument={fetchClientNoticeDocument}
          is109015Agent={is109015Agent}
          activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
        />
      </Accordion>
    </div>
  );
};

export default PersonalTabContainer;

PersonalTabContainer.propTypes = {
  agencyCode: PropTypes.string.isRequired,
  policyDecsList: PropTypes.array,
  policyDecsError: PropTypes.string,
  policyDecsLoading: PropTypes.bool,
  fetchPolicyDecs: PropTypes.func.isRequired,
  removePolicyDec: PropTypes.func.isRequired,
  fetchClientNotices: PropTypes.func.isRequired,
  clientNoticesList: PropTypes.array,
  clientNoticesError: PropTypes.string,
  clientNoticesLoading: PropTypes.bool,
  removeClientNotice: PropTypes.func,
  deleteClientNoticeError: PropTypes.string,
  resetClientNotices: PropTypes.func,
  fetchClientNoticeDocument: PropTypes.func.isRequired,
  getPolicyDecsPersonalDocument: PropTypes.func,
  is109015Agent: PropTypes.bool,
  activeSpecialtyCompanyBranch: PropTypes.string
};
