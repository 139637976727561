import * as types from "./umailActionTypes";
import initialState from "../reducers/initialState";

//TODO: rename the state property paymentdetails to makeaPayment
const umailReducer = (state = initialState.umailData, action) => {
  switch (action.type) {
    case types.UMAIL_ACTIVATED:
      return {
        ...state,
        isActivated: true
      };
    case types.DISMISS_UMAIL:
      return {
        ...state,
        isLoading: false,
        isActivated: false,
        error: null
      };
    case types.POST_UMAIL_MESSAGE_STARTED:
      return {
        ...state,
        postUmailLoading: true,
        postUmailSuccess: false,
        postUmailError: null
      };
    case types.POST_UMAIL_MESSAGE_SUCCEEDED:
      return {
        ...state,
        postUmailLoading: false,
        postUmailError: null,
        postUmailSuccess: true
      };
    case types.POST_UMAIL_MESSAGE_FAILED:
      return {
        ...state,
        postUmailSuccess: false,
        postUmailLoading: false,
        postUmailError: action.payload
      };

    default:
      return state;
  }
};

export default umailReducer;
