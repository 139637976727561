import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";

const admministratorReducer = (state = initialState.administrator, action) => {
  switch (action.type) {
    case types.FETCH_ADMINISTRATOR_STARTED:
      return {
        ...state,
        isLoading: true,
        details: {},
        error: false
      };
    case types.FETCH_ADMINISTRATOR_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        details: action.payload,
        error: false
      };
    case types.FETCH_ADMINISTRATOR_FAILED:
      return {
        ...state,
        isLoading: false,
        details: {},
        error: action.payload
      };
    default:
      return state;
  }
};

export default admministratorReducer;
