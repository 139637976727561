import React, { useContext, useState } from "react";

import { locationDisplayName, sortByProperty } from "../../shared/util";
import { Button, Panel } from "@ufginsurance/ui-kit";
import { vehicleTypes } from "../../shared/constants";

import vehicleCoverages from "./vehicleCoverages";
import VehicleModalWizard from "./VehicleModalWizard";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import VehicleCardContents from "./VehicleCardContents";
import MissingItemPanel from "../../shared/MissingItemPanel";
import CoveragesInCards, {
  displayedCoverages,
  isMissingRequiredTerms
} from "../CoveragesInCards";
import ConfirmationModal from "../../shared/ConfirmationModal";

import "../../Step5RiskInfo.scss";

const VehicleCards = ({ vehicles, locations, coverageExclusions }) => {
  const { quoteData, deleteCoverablePromise, toastErrr } =
    useContext(OnlineQuotingContext);

  const [showItemForm, setShowItemForm] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState();
  const [itemToDelete, setItemToDelete] = useState();
  const [mode, setMode] = useState();

  const onDeleteVehicle = vehicle => {
    // collect the IDs of the coverables to be deleted...
    const params =
      vehicle?.entityName === "entity.CA7Truck" ||
      vehicle?.entityName === "entity.CA7Trailer"
        ? { ca7TruckIds: vehicle.fixedId }
        : vehicle?.entityName === "entity.CA7PrivatePassenger"
        ? { ca7PrivatePassengerIds: vehicle.fixedId }
        : null;

    if (!params) {
      toastErrr({
        action: "onDeleteVehicle",
        misc: {
          params
        },
        description: "failed to delete vehicle: no params",
        displayMessage: "Failed to remove vehicle(s)."
      });
      return false;
    }
    deleteCoverablePromise({
      coverableType: "vehicle",
      ...params
    })
      .catch(({ error }) =>
        toastErrr({
          action: "deleteCoverablePromise",
          misc: { params },
          description: "failed to delete vehicle",
          error,
          displayMessage: "Failed to remove vehicle(s)."
        })
      )
      .then(() => setItemToDelete(null));
  };

  const openItemEdit = vehicle => {
    if (vehicle?.id) setMode("EDIT");
    else setMode("ADD");
    setActiveVehicle(vehicle);
    setShowItemForm(true);
  };

  const onHideModal = () => {
    setShowItemForm(false);
    setActiveVehicle(null);
  };

  const deleteItemAfterConfirm = () => {
    if (itemToDelete) {
      onDeleteVehicle(itemToDelete, () => {
        setItemToDelete(null);
      });
    }
  };

  const deleteActionCancelled = () => setItemToDelete(null);

  const addAnotherItem = () => {
    setMode("ADD");
    setActiveVehicle(null);
    setShowItemForm(true);
  };

  const coverageFields = vehicle => {
    const vehicleTypeObj = vehicleTypes.find(
      t => t.key === (vehicle?.entityName || "").replace("entity.", "")
    );
    return (
      vehicleCoverages(quoteData, vehicle.fixedId, vehicleTypeObj) || []
    ).filter(c => c.selected === true);
  };

  return (
    <div>
      {itemToDelete && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <>
              <div>
                Are you sure you want to remove vehicle{" "}
                {itemToDelete.description}?
              </div>
            </>
          }
          onClickYes={deleteItemAfterConfirm}
          onClickNo={deleteActionCancelled}
          show
        />
      )}
      {showItemForm && (
        <VehicleModalWizard
          key={`${mode}${activeVehicle?.id}`}
          locations={locations}
          show={showItemForm}
          name="Vehicle"
          currentVehicle={activeVehicle}
          onHide={onHideModal}
          mode={mode}
          addAnotherItem={addAnotherItem}
          coverageExclusions={coverageExclusions}
        />
      )}
      <Panel
        id="oq__coverable__container__vehicle"
        rounded
        titlebar
        bgcolor="grey"
        className="oq__coverable__container oq__coverable__container__vehicle"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Vehicles ({vehicles.length})
            </span>
          </div>
        }
      >
        <>
          {vehicles.sort(sortByProperty("displayName")).map(vehicle => {
            const subDetails = [
              vehicle?.primaryClassCode
                ? `${vehicle.primaryClassCode}${vehicle.secondaryClassCode}`
                : vehicle.classCode,
              vehicle?.sizeClass,
              vehicle?.businessUseClass || vehicle?.use,
              vehicle?.radiusClass || vehicle?.type,
              vehicle?.secondaryClass
            ].filter(n => n !== undefined && n !== "Not Applicable");

            const coverages = displayedCoverages({
              coverages: coverageFields(vehicle),
              coverageExclusions
            });

            return (
              <Panel
                key={`container__vehicle__${vehicle?.fixedId}`}
                id={`container__vehicle__${vehicle?.fixedId}`}
                collapsible={!isMissingRequiredTerms({ coverages })}
                isOpen={isMissingRequiredTerms({ coverages })}
                rounded
                className="oq__coverable__card oq__coverable__card__vehicle"
                title={
                  <div>
                    <div className="oq__coverable__card__initial-content__container">
                      <span className="oq__coverable__card__initial-content">
                        <span className="oq__coverable__card__initial-content__title">
                          {`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                        </span>
                        <span className="oq__coverable__card__initial-content__secondary">
                          {subDetails.join(" - ")}
                        </span>
                        <span className="oq__coverable__card__initial-content__extended">
                          {locationDisplayName(vehicle.location)}
                        </span>
                      </span>
                    </div>
                  </div>
                }
              >
                <div className="oq__coverable__vehicle-wrapper">
                  <VehicleCardContents vehicle={vehicle} />
                  {coverages.length && (
                    <div className="oq__coverable__card__content__coverages">
                      <CoveragesInCards
                        quoteData={quoteData}
                        coverageFields={coverages}
                        coverageExclusions={coverageExclusions}
                        id={`coverages__vehicle__${vehicle.id}`}
                        alwaysDisplay
                        handleEditItem={() => openItemEdit(vehicle)}
                      />
                    </div>
                  )}
                  {
                    <div className="oq__coverable__card__actions">
                      <Button
                        wrapperClassName="oq__coverable__card__edit"
                        className="oq__coverable__card__edit-button"
                        inline
                        isLink
                        onClick={() => {
                          openItemEdit(vehicle);
                        }}
                      >
                        Edit Vehicle and Coverages
                      </Button>
                      <Button
                        wrapperClassName="oq__coverable__card__delete"
                        isLink
                        inline
                        variant="tertiary"
                        onClick={() => setItemToDelete(vehicle)}
                      >
                        Remove Vehicle
                      </Button>
                    </div>
                  }

                  {!vehicles.length ? (
                    <em className="oq__data-section__one-is-required">
                      At least one Vehicle is required to continue.
                    </em>
                  ) : (
                    ""
                  )}
                </div>
              </Panel>
            );
          })}
        </>
        {vehicles.length === 0 ? (
          <MissingItemPanel
            onClickHandler={() => openItemEdit(null)}
            content="A vehicle is required for Commercial Auto"
            buttonText="Add Vehicle"
          />
        ) : (
          <div>
            <Button
              block
              variant="outline"
              wrapperClassName="oq__coverable__add-button-wrapper"
              className="oq__coverable__card__add__button"
              icon="farPlus"
              onClick={() => {
                openItemEdit(null);
              }}
            >
              Add Vehicle
            </Button>
          </div>
        )}
      </Panel>
    </div>
  );
};

export default VehicleCards;
