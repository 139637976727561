import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../../../components/Factory";
import { Table } from "@ufginsurance/ui-kit";
import { Translations } from "../../../../components/translations";

const YearToDateList = props => {
  const {
    fetchYearToDateLossSummaryViewReport,
    selectedAgencyCode,
    yearToDateLossList
  } = props;

  const showReport = row => () => {
    fetchYearToDateLossSummaryViewReport(selectedAgencyCode, row.date);
  };

  const createColumns = () => [
    {
      key: "date",
      label: Translations.reports.tabClaims.yearToDateTableHeading,
      element: row => <span>{DateFormat(row.date, "MM/DD/YYYY")}</span>,
      className: "date"
    },
    {
      key: "",
      label: "",
      element: row => {
        return (
          <div>
            <button className="btn-as-link" onClick={showReport(row)}>
              {Translations.reports.tabClaims.viewReport}
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div className="table">
      <Table
        rowKey="id"
        data={yearToDateLossList}
        columns={createColumns()}
        itemsPerPage={20}
        showPagination
      />
    </div>
  );
};

YearToDateList.propTypes = {
  yearToDateLossList: PropTypes.array.isRequired,
  fetchYearToDateLossSummaryViewReport: PropTypes.func.isRequired,
  selectedAgencyCode: PropTypes.string
};

export default YearToDateList;
