import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Oasis from "./oasis";
import PolicyTermSummary, {
  CURRENT_POLICY_TERM,
  RENEWAL_POLICY_TERM
} from "./policyTermSummary";
import { Translations } from "../components/translations";
import EasyViewModal from "./easyViewModal";
import { isGuidewire, isCore } from "./origin";
import { LoadingIndicator, Accordion, Currency } from "@ufginsurance/ui-kit";
import {
  createLineOfBusinessDocumentSelections,
  createDecAndTypedFormsDocumentSelections,
  createAllDocumentSelections,
  getSelectedDocuments
} from "./currentPolicySelector";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const CurrentPolicy = ({
  activeAgencyCode,
  fetchCurrentSummary,
  currentSummary,
  currentSummaryError,
  policy,
  oasisDocumentSummaryCurrent,
  oasisDocumentSummaryIsLoadingCurrent,
  oasisDocumentSummaryErrorCurrent,
  oasisDocumentSummaryRenewal,
  oasisDocumentSummaryIsLoadingRenewal,
  oasisDocumentSummaryErrorRenewal,
  fetchOasisDocumentSummary,
  policy_type,
  currentSummaryIsloading,
  fetchCurrentPolicyDocument,
  fetchGWCurrentPolicyDocument,
  currentPolicyData,
  fetchUFGDocumentSummary
}) => {
  const [showEasyModal, setShowEasyModal] = useState(false);
  const [isPrintorViewLoading, setIsPrintorViewLoading] = useState(false);
  const [isPrintorViewRenewalLoading, setIsPrintorViewRenewalLoading] =
    useState(false);
  const [canPrintCurrent, setCanPrintCurrent] = useState(false);
  const [canPrintRenewal, setCanPrintRenewal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageRenewal, setErrorMessageRenewal] = useState("");
  const [selectAllCurrent, setSelectAllCurrent] = useState(false);
  const [selectAllRenewal, setSelectAllRenewal] = useState(false);
  const [selectedPolicyDocuments, setSelectedPolicyDocuments] = useState({});
  const [selectAllPoliciesCurrent, setSelectAllPoliciesCurrent] =
    useState(false);
  const [selectAllPoliciesRenewal, setSelectAllPoliciesRenewal] =
    useState(false);
  const [currentPolicy, setCurrentPolicy] = useState(null);

  useEffect(() => {
    if (currentPolicy !== policy) {
      setCanPrintCurrent(false);
      setCanPrintRenewal(false);
      setSelectAllCurrent(false);
      setSelectAllRenewal(false);
      setCurrentPolicy(policy);
      fetchCurrentSummary(
        activeAgencyCode,
        policy.policy_number,
        policy.effective_date,
        policy.company_branch
      );
    }
  }, [
    fetchCurrentSummary,
    policy,
    activeAgencyCode,
    setCanPrintCurrent,
    setCanPrintRenewal,
    setSelectAllCurrent,
    setSelectAllRenewal,
    currentPolicy
  ]);

  const handleShowEasyView = () => {
    setShowEasyModal(true);
  };

  const handleCloseEasyView = () => {
    setShowEasyModal(false);
  };

  const onSelectPolicyDocument = (code, policyTerm, selectedRows) => {
    const data = createLineOfBusinessDocumentSelections(
      selectedPolicyDocuments,
      selectedRows,
      currentPolicyData,
      code,
      policyTerm
    );
    setSelectedPolicyDocuments(data);
  };

  const handleSelectAllDecsAndTypedForms = policyTerm => e => {
    const isCurrent = policyTerm === CURRENT_POLICY_TERM;
    if (isCurrent) {
      setSelectAllPoliciesCurrent(e.target.checked);
      setCanPrintCurrent(e.target.checked);
      setSelectAllCurrent(false);
    } else {
      setSelectAllPoliciesRenewal(e.target.checked);
      setCanPrintRenewal(e.target.checked);
      setSelectAllRenewal(false);
    }
    const data = createDecAndTypedFormsDocumentSelections(
      selectedPolicyDocuments,
      currentSummary,
      policyTerm,
      currentPolicyData,
      e.target.checked
    );

    // Necessary to ensure LineOfBusinessDocumentsTable Table inside PolicyTermSummary gets preselected rows updated
    // Without this delay when the same number of selections are currently selected as the number of decs and forms
    // found the previous selections are not cleared
    setSelectedPolicyDocuments({});
    setTimeout(() => {
      setSelectedPolicyDocuments(data);
    }, 1);
  };

  const handleSelectAllDocuments = policyTerm => e => {
    const isCurrent = policyTerm === CURRENT_POLICY_TERM;
    if (isCurrent) {
      setSelectAllCurrent(e.target.checked);
      setCanPrintCurrent(e.target.checked);
      setSelectAllPoliciesCurrent(false);
    } else {
      setSelectAllRenewal(e.target.checked);
      setCanPrintRenewal(e.target.checked);
      setSelectAllPoliciesRenewal(false);
    }
    const data = createAllDocumentSelections(
      selectedPolicyDocuments,
      currentSummary,
      policyTerm,
      currentPolicyData,
      e.target.checked
    );
    setSelectedPolicyDocuments(data);
  };

  const handleViewAllLinesOfBusinessDocuments = policyTerm => () => {
    const documentsToView = getSelectedDocuments(
      policyTerm,
      selectedPolicyDocuments
    );
    const selectedDocumentsRequest = {
      policy_number: policy.policy_number,
      policy_type,
      document_information: documentsToView
    };
    if (policyTerm === CURRENT_POLICY_TERM) {
      setShowEasyModal(showEasyModal);
      setIsPrintorViewLoading(true);
      setErrorMessage("");
    } else {
      setShowEasyModal(showEasyModal);
      setIsPrintorViewRenewalLoading(true);
      setErrorMessageRenewal("");
    }
    fetchCurrentPolicyDocument(selectedDocumentsRequest)
      .then(() => {
        if (policyTerm === CURRENT_POLICY_TERM) {
          setShowEasyModal(showEasyModal);
          setIsPrintorViewLoading(false);
          setErrorMessage("");
        } else {
          setShowEasyModal(showEasyModal);
          setIsPrintorViewRenewalLoading(false);
          setErrorMessageRenewal("");
        }
      })
      .catch(() => {
        if (policyTerm === CURRENT_POLICY_TERM) {
          setShowEasyModal(showEasyModal);
          setIsPrintorViewLoading(false);
          setErrorMessage(<HelpDeskMessage display="anErrorOccurred" />);
        } else {
          setShowEasyModal(showEasyModal);
          setIsPrintorViewRenewalLoading(false);
          setErrorMessageRenewal(<HelpDeskMessage display="anErrorOccurred" />);
        }
      });
  };

  const renderCurrentPolicy = () => {
    return (
      <div>
        <EasyViewModal show={showEasyModal} onClose={handleCloseEasyView} />
        {currentSummary &&
          currentSummary
            .sort((a, b) => a.term < b.term)
            .map(summary => {
              const isCurrent = summary.term === CURRENT_POLICY_TERM;
              if (isGuidewire(summary.system_of_origin)) {
                return (
                  <div key={summary.policy_number + summary.term}>
                    <Accordion
                      triggerContent={renderOasisTriggerContent(
                        summary.policy_number,
                        summary.total_premium,
                        summary.lines_of_business,
                        summary.term
                      )}
                      isOpen={isCurrent}
                    >
                      <div>
                        <div>
                          <i>
                            {
                              Translations.account_overview.currentPolicy
                                .oasis_accordion
                            }
                            <span>
                              <button
                                className="company-warning__alert-link"
                                onClick={handleShowEasyView}
                              >
                                {
                                  Translations.account_overview.currentPolicy
                                    .easy_to_view
                                }
                              </button>
                            </span>
                          </i>
                        </div>
                        <Oasis
                          documentSummary={
                            isCurrent
                              ? oasisDocumentSummaryCurrent
                              : oasisDocumentSummaryRenewal
                          }
                          isLoading={
                            isCurrent
                              ? oasisDocumentSummaryIsLoadingCurrent
                              : oasisDocumentSummaryIsLoadingRenewal
                          }
                          documentSummaryError={
                            isCurrent
                              ? oasisDocumentSummaryErrorCurrent
                              : oasisDocumentSummaryErrorRenewal
                          }
                          policyNumber={policy.policy_number}
                          policyTerm={summary.term}
                          fetchDocumentSummary={fetchOasisDocumentSummary}
                          fetchGWCurrentPolicyDocument={
                            fetchGWCurrentPolicyDocument
                          }
                          policyType={policy_type}
                          policy={policy}
                        />
                      </div>
                    </Accordion>
                  </div>
                );
              } else if (isCore(summary.system_of_origin)) {
                return (
                  <PolicyTermSummary
                    key={summary.term}
                    activeAgencyCode={activeAgencyCode}
                    onSelectPolicyDocument={onSelectPolicyDocument}
                    summary={summary}
                    policyType={policy_type}
                    policy={policy}
                    selectedPolicyDocuments={selectedPolicyDocuments}
                    currentPolicyData={currentPolicyData}
                    fetchUFGDocumentSummary={fetchUFGDocumentSummary}
                    fetchCurrentPolicyDocument={fetchCurrentPolicyDocument}
                    errorMessage={
                      isCurrent ? errorMessage : errorMessageRenewal
                    }
                    selectAllPoliciesCurrent={selectAllPoliciesCurrent}
                    selectAllPoliciesRenewal={selectAllPoliciesRenewal}
                    handleSelectAllDecsAndTypedForms={
                      handleSelectAllDecsAndTypedForms
                    }
                    handleSelectAllDocuments={handleSelectAllDocuments}
                    handleViewAllLinesOfBusinessDocuments={
                      handleViewAllLinesOfBusinessDocuments
                    }
                    isPrintorViewLoading={isPrintorViewLoading}
                    isPrintorViewRenewalLoading={isPrintorViewRenewalLoading}
                    canPrintCurrent={canPrintCurrent}
                    canPrintRenewal={canPrintRenewal}
                    selectAllCurrent={selectAllCurrent}
                    selectAllRenewal={selectAllRenewal}
                  />
                );
              }
              return null;
            })}
      </div>
    );
  };

  const renderOasisTriggerContent = (
    policyNumber,
    premiumTotal,
    lineOfBusinesses,
    term
  ) => {
    return (
      <div className="current-policy-number">
        Policy Number {policyNumber}
        <div className="oasis-accordion-values">
          <span>
            Premium <Currency amount={premiumTotal} includeDecimals={false} />
          </span>
          {lineOfBusinesses.map(lob => (
            <span key={lob.name}> | {lob.name}</span>
          ))}
          {term === RENEWAL_POLICY_TERM && (
            <div className="header-renewal-content">Renewal</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="current-policy-container">
      {currentSummaryError || currentSummary.error_code !== undefined ? (
        Translations.general.error
      ) : currentSummaryIsloading ? (
        <LoadingIndicator />
      ) : (
        <Fragment>{renderCurrentPolicy()}</Fragment>
      )}
    </div>
  );
};

CurrentPolicy.propTypes = {
  activeAgencyCode: PropTypes.string.isRequired,
  fetchCurrentSummary: PropTypes.func.isRequired,
  currentSummary: PropTypes.array.isRequired,
  currentSummaryError: PropTypes.any,
  policy: PropTypes.object.isRequired,
  fetchOasisDocumentSummary: PropTypes.func.isRequired,
  policy_type: PropTypes.string.isRequired,
  currentSummaryIsloading: PropTypes.bool.isRequired,
  oasisDocumentSummaryCurrent: PropTypes.array.isRequired,
  oasisDocumentSummaryIsLoadingCurrent: PropTypes.bool.isRequired,
  oasisDocumentSummaryErrorCurrent: PropTypes.any,
  oasisDocumentSummaryRenewal: PropTypes.array.isRequired,
  oasisDocumentSummaryIsLoadingRenewal: PropTypes.bool.isRequired,
  oasisDocumentSummaryErrorRenewal: PropTypes.any,
  fetchCurrentPolicyDocument: PropTypes.func.isRequired,
  fetchGWCurrentPolicyDocument: PropTypes.func.isRequired,
  currentPolicyData: PropTypes.object,
  fetchUFGDocumentSummary: PropTypes.func.isRequired
};

export default CurrentPolicy;
