import React, { useContext } from "react";

import { Alert, formatDate } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";

import "./ProductPanelCustomMessage.scss";

import { useFlags } from "launchdarkly-react-client-sdk";
/**
  Launch Darkly flag data example

const ldFlag = {
  CA: {
    messageColor: "warning",
    message: "Some test message for California",
    start: "11/1/2023",
    end: null,
    products: ["ca7CommAuto", "wcmWorkersComp"]
  },
  MN: {
    messageColor: "warning",
    message: "Some test message for Minnesota",
    start: "10/15/2023",
    end: "10/20/2023",
    products: ["bp7BusinessOwners"]
  },
  IA: {
    messageColor: "warning",
    message: "Some test message for Iowa",
    start: "9/15/2023",
    end: "10/20/2023",
    products: []
  }
};
 */

const ProductsPanelCustomMessage = ({ effectiveDate, selectedState }) => {
  const { supportingData } = useContext(OnlineQuotingContext);

  const { proQuoteStep1CustomMessageByState } = useFlags();

  const getCustomMessage = () => {
    // no message for selected state
    if (!proQuoteStep1CustomMessageByState?.[selectedState]) return null;

    const { message, start, end, products } =
      proQuoteStep1CustomMessageByState?.[selectedState];

    // message object doesn't have the right properties
    if (!proQuoteStep1CustomMessageByState || !message || !start || !products)
      return null;

    /**
     * mesage exists for state
     * ... check additional criteria:
     * - start date
     * - end date
     * - products (selected)
     */

    const _effectiveDate = formatDate(effectiveDate);

    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;

    const basedOnDate =
      _effectiveDate >= startDate && (!endDate || _effectiveDate < endDate);

    const basedOnProductSelection =
      products.length === 0 ||
      (supportingData?.policyLines || []).some(p => products.includes(p));

    if (basedOnDate && basedOnProductSelection) {
      return proQuoteStep1CustomMessageByState?.[selectedState];
    }
  };

  const customMessage = getCustomMessage();

  if (!customMessage) return null;

  return (
    <Alert
      className="oq__custom-product-message"
      type={customMessage?.messageColor || "warning"}
      dismissible={false}
    >
      {customMessage?.message}
    </Alert>
  );
};

export default ProductsPanelCustomMessage;
