import { connect } from "react-redux";
import AgencyNumberTextbox from "../components/AgencyNumberTextbox";
import { agencyNumberReducerUpdated } from "../actions/globalactivity";
import { getSelectedAgencyCode } from "../selectors/agencySelectors";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  agencyNumber: getSelectedAgencyCode(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

const mapDispatchToProps = {
  handleAgentNumberUpdate: agencyNumberReducerUpdated
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyNumberTextbox);
