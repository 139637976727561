import React, { useState } from "react";
import {
  ActionCardContainer,
  ActionCard,
  LoadingIndicator,
  Modal,
  Button
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { useSuretyHomeContext } from "../SuretyHomeProvider";
import CommercialQuotesTable from "./CommercialQuotesTable";
import { Eportal } from "../../shared/eportal";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function Tiles() {
  const { suretyConsentOfSuretyEnabled } = useFlags();

  const [showModal, setShowModal] = useState(false);
  const { commercialQuotes, commercialQuotesIsLoading } =
    useSuretyHomeContext();

  const handleClose = () => setShowModal(false);

  return (
    <ActionCardContainer className="surety-tiles">
      <ActionCard
        title="Request A Bid Bond"
        link={Eportal.bidBondRequestUrl}
        color="dk-blue"
        target="_blank"
        textAlign="center"
      />
      <ActionCard
        title="Issue A Contract Bond"
        link={Eportal.contractBondIssueUrl}
        color="dk-blue"
        target="_blank"
        textAlign="center"
      />
      <ActionCard
        className="mobile-order5"
        title="Issue/Quote Commercial Bond"
        color="lt-blue"
        link={Eportal.quoteCommercialBondUrl}
        target="_blank"
        textAlign="center"
      />
      <ActionCard
        title="Request Consent of Surety"
        link={
          suretyConsentOfSuretyEnabled
            ? routes.SURETY_CONSENT_OF_SURETY
            : Eportal.suretyConsentUrl
        }
        color="dk-blue"
        target="_blank"
        textAlign="center"
      />
      <ActionCard
        title="Submit ufgQuick Account/Bond"
        link={routes.setStepUrl(1)}
        color="dk-blue"
        target="_blank"
        textAlign="center"
      />
      <ActionCard
        title={
          <>
            <span>Purchase Commercial Quote </span>
            {commercialQuotesIsLoading ? (
              <LoadingIndicator
                className="surety-tiles__loader"
                type="spinner"
                color="black"
                message=""
              />
            ) : (
              <span>({commercialQuotes?.length || 0})</span>
            )}
          </>
        }
        className="mobile-order6"
        color="lt-blue"
        textAlign="center"
        disabled={commercialQuotes?.length === 0 || commercialQuotesIsLoading}
        onClick={() => setShowModal(true)}
      />

      <Modal
        title="Purchase Commercial Quote"
        body={
          <CommercialQuotesTable
            commercialQuotesList={commercialQuotes || []}
          />
        }
        footer={<Button onClick={handleClose}>Close</Button>}
        show={showModal}
        onHide={handleClose}
        size="lg"
        className="surety-purchase-commercial-quote"
      />
    </ActionCardContainer>
  );
}
