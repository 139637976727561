import { toast } from "react-toastify";
import { Translations } from "../../components/translations";

export const authorizationForbiddenResponseInterceptor =
  responseInterceptor => {
    responseInterceptor.use(
      response => {
        return response;
      },
      error => {
        if (error && error.response && error.response.status === 403) {
          setTimeout(() => {
            toast.dismiss();
            toast(Translations.error_messages.error_403, {
              position: "top-center",
              className: "error",
              autoClose: false
            });
          }, 300);
        }
        return Promise.reject(error);
      }
    );
  };

export default authorizationForbiddenResponseInterceptor;
