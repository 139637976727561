import * as React from "react";
import { connect } from "react-redux";
import { Button } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";

const clipboardCopy = text => {
  // Use the Async Clipboard API when available. Requires a secure browsing
  // context (i.e. HTTPS)
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text).catch(function (err) {
      throw err !== undefined
        ? err
        : new DOMException("The request is not allowed", "NotAllowedError");
    });
  }

  // ...Otherwise, use document.execCommand() fallback

  // Put the text to copy into a <span>
  const span = document.createElement("span");
  span.textContent = text;

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = "pre";

  // Add the <span> to the page
  document.body.appendChild(span);

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection();
  const range = window.document.createRange();
  selection.removeAllRanges();
  range.selectNode(span);
  selection.addRange(range);

  // Copy text to the clipboard
  let success = false;
  try {
    success = window.document.execCommand("copy");
  } catch (err) {
    console.log("error", err);
  }

  // Cleanup
  selection.removeAllRanges();
  window.document.body.removeChild(span);

  return success
    ? Promise.resolve()
    : Promise.reject(
        new DOMException("The request is not allowed", "NotAllowedError")
      );
};

export const copyToClipboard = txt =>
  new Promise(function (resolve) {
    clipboardCopy(txt);
    setTimeout(() => {
      resolve({ success: true });
    }, 500);
  });

const CopyJWT = ({ token, quoteData, supportingData }) => {
  const jwt = () => {
    copyToClipboard(token).finally(() =>
      toast("JWT COPIED TO CLIPBOARD", {
        position: "top-center",
        className: "success"
      })
    );
  };

  const copyDto = () => {
    copyToClipboard(JSON.stringify({ dto: quoteData, supportingData })).finally(
      () =>
        toast("QUOTE OBJECTS COPIED TO CLIPBOARD", {
          position: "top-center",
          className: "success"
        })
    );
  };

  return (
    <>
      <Button size="sm" block icon="farCopy" onClick={jwt}>
        Copy JWT
      </Button>
      {quoteData && (
        <Button size="sm" block icon="farCopy" onClick={copyDto}>
          Copy Quote Data
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  token: state?.oidc?.user?.access_token
});

export default connect(mapStateToProps, {})(CopyJWT);
