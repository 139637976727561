import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Alert,
  useForm,
  FormGroup,
  Form,
  TextArea,
  Input,
  FlexRow,
  Checkbox,
  Dropdown
} from "@ufginsurance/ui-kit";
import DragAndDropAttachments from "./../components/commercial/commercial-lines/DragAndDropAttachments";
import { Translations } from "./../components/translations";
import AttachmentsTable from "./umailAttachmentsTable";
import * as R from "ramda";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const TEN_MEGABYTES = 10485760;
const MAX_ATTACHMENTS_SIZE = TEN_MEGABYTES;

const exceedsMaxSize = attachments => {
  const totalSize = R.sum(R.pluck("size", attachments));
  return totalSize > MAX_ATTACHMENTS_SIZE;
};

const { subjectoptions, umail_body, refoptions } =
  Translations.umail.render_form;

const RenderForm = ({
  name,
  email,
  agency,
  handleSubmit,
  handleClose,
  submitError,
  isSpecialtyPortalView,
  isSubmitting
}) => {
  const [maxSizeError, setMaxSizeError] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const closeClickedHandler = () => {
    handleClose();
  };

  const specialtySubjectOptions = () => {
    return isSpecialtyPortalView
      ? subjectoptions.filter(o => {
          return o.label.includes("Commercial") || o.label.includes("Claim");
        })
      : subjectoptions;
  };

  const specialtyReferenceOptions = () => {
    return isSpecialtyPortalView
      ? refoptions.filter(o => {
          return !o.label.includes("Surety");
        })
      : refoptions;
  };

  const onSubmit = formData => {
    const values = {
      ...formData.values,
      attachments
    };
    handleSubmit(values);
  };

  const form = useForm({
    values: {
      emailConfirmation: true,
      subject: null,
      referenceNumber: "",
      referenceType: null,
      message: ""
    },
    onSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  /**
   * selectedAttachments are the files user is drag-and-dropping or
   * selecting with file browser.
   */
  const onSelectFiles = selectedAttachments => {
    // selected attachments should be spread first because filtering unique files will take the
    // first occurrence. The previous document with same name will then be discarded by unique filter.
    // This way we can replace it with most recent selection and allows users to 'overwrite' initial document.
    const allAttachments = [...selectedAttachments, ...attachments];
    const uniqueAttachments = R.uniqBy(R.prop("name"), allAttachments);
    if (!exceedsMaxSize(uniqueAttachments)) {
      setMaxSizeError(null);
      setAttachments(uniqueAttachments);
    } else {
      setMaxSizeError(
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, but there was an error processing your request. Please ensure that the uploaded files do not exceed our 10MB size limit."
        />
      );
    }
  };

  const removeAttachment = file => () => {
    const filteredAttachments = attachments?.filter(o => o.name !== file.name);
    setMaxSizeError(
      exceedsMaxSize(filteredAttachments)
        ? <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, but there was an error processing your request. Please ensure that the uploaded files do not exceed our 10MB size limit."
        />
        : null
    );
    setAttachments(filteredAttachments);
  };

  return (
    <>
      <p>
        <span className="bold">{umail_body.agency} </span> {agency}
      </p>
      <p>
        <span className="bold">{umail_body.name}</span> {name}
      </p>
      <p>
        <span className="bold">{umail_body.email_address}</span> {email}
      </p>
      <Form context={form} id="umail-render-form">
        <FormGroup wrap={false}>
          <Checkbox
            id="emailConfirmation"
            name="emailConfirmation"
            label={umail_body.email_notification}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.emailConfirmation}
            small
          />
        </FormGroup>
        <FormGroup wrap={false}>
          <Dropdown
            id="subject"
            name="subject"
            placeholder={Translations.umail.subject_type.placeholder}
            label={umail_body.subject}
            options={specialtySubjectOptions()}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.subject}
            size="50p"
            required
          />
        </FormGroup>
        <FormGroup groupErrors wrap={false}>
          <Input
            id="referenceNumber"
            name="referenceNumber"
            label={umail_body.reference_number}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.referenceNumber || ""}
            maxLength={13}
            required
          />
          <Dropdown
            id="referenceType"
            name="referenceType"
            placeholder={Translations.umail.reference_type.placeholder}
            label={umail_body.reference_type}
            options={specialtyReferenceOptions()}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.referenceType}
            size="50p"
            required
          />
        </FormGroup>
        <FormGroup>
          <TextArea
            id="message"
            name="message"
            label={umail_body.message}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.message || ""}
            required
          />
        </FormGroup>
        <FlexRow>
          <div className="attachment form-group">
            <label htmlFor="attachmentsTable">{umail_body.attachments}</label>
            {!!attachments.length && (
              <div className="attachments-table-container">
                <AttachmentsTable
                  id="attachmentsTable"
                  attachments={attachments}
                  removeAttachment={removeAttachment}
                />
              </div>
            )}
            {maxSizeError && (
              <>
                <Alert type="error" dismissible={false}>
                  {maxSizeError}
                </Alert>
                <br />
              </>
            )}
            <DragAndDropAttachments
              id="render-form-file-input"
              allowMultiple
              onSelectFiles={onSelectFiles}
              allowedFileTypes={[
                "txt",
                "rtf",
                "pdf",
                "jpg",
                "gif",
                "bmp",
                "png",
                "tif",
                "doc",
                "docx",
                "xls",
                "xlsx"
              ]}
            />
            <br />
            <i>{umail_body.accepted_files}</i>
            <br />
            <br />
            <i>
              <b>{umail_body.note}</b>
              <span>{umail_body.accepted_limit}</span>
            </i>
          </div>
        </FlexRow>
        {submitError && (
          <FlexRow>
            <Alert type="error">{submitError}</Alert>
          </FlexRow>
        )}
        <FlexRow align="right">
          <Button
            type="reset"
            variant="secondary"
            onClick={closeClickedHandler}
          >
            {umail_body.cancel}
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isSubmitting}
            spinner={isSubmitting}
            spinnerPos="right"
          >
            {umail_body.submit}
          </Button>
        </FlexRow>
      </Form>
    </>
  );
};

RenderForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  agency: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  submitError: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default RenderForm;
