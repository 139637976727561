import qs from "query-string";
import API from "../../api";

export const getProducers = agencyCode => {
  return API.agency().get(`/agency/${agencyCode}/active-agents`);
};

export const searchLocaleByZipCodeV2 = zipCode => {
  return API.address().get(`/zip/${zipCode}?`);
};

export const getAgencyData = agencyCode => {
  return API.agency().get(`/agency/${agencyCode}`);
};

export const fetchSuretyBondForm = (bond_type, state, date_effective) => {
  const query = qs.stringify({
    request_option: "Upload",
    form_type: "BondForms",
    bond_type: "Contract",
    state,
    contract_form_type: bond_type,
    company_number: "UFC",
    date_effective
  });

  return API.surety().get(`/bond-forms?${query}`);
};

export const getAccounts = (agencyCode, accountNumber) => {
  const query = qs.stringify({
    agency_code: agencyCode,
    account_number: accountNumber
  });

  return API.surety().get(`/accounts?${query}`);
};

export const getOwners = (agencyCode, accountNumber, appType) => {
  return API.surety().get(
    `/owners?agency_code=${agencyCode}&account_number=${accountNumber}&app_type=${appType}`
  );
};

export const getLicensedStates = agencyCode => {
  return API.agent().get(`/agencies/${agencyCode}/licensed-states`);
};

export const getEligibility = (agencyCode, accountNumber) => {
  return API.surety().get(
    `/eligibility?agency_code=${agencyCode}&account_number=${accountNumber}`
  );
};

export const verifyAddress = (address, city, state, zip) => {
  const query = qs.stringify({
    address_line: address,
    city,
    state,
    zip
  });

  return API.surety().get(`/address?${query}`);
};

export const postQuickQuote = data => {
  return API.surety().post(`/submission`, data);
};

export const postQuickAccount = data => {
  return API.surety().post(`/quick/submissions/accounts`, data);
};

export const postQuickEligibility = (accountNumber, data) => {
  return API.surety().post(
    `/quick/submissions/accounts/${accountNumber}/eligibilities`,
    data
  );
};

export const postQuickObligee = data => {
  return API.surety().post(`/quick/submissions/obligees`, data);
};

export const postQuickBond = (accountNumber, data) => {
  return API.surety().post(
    `/quick/submissions/accounts/${accountNumber}/bonds`,
    data
  );
};

export const postQuickOwners = (accountNumber, data) => {
  return API.surety().post(
    `/quick/submissions/accounts/${accountNumber}/owners`,
    data
  );
};

export const postQuickCreditAuthorization = (accountNumber, data) => {
  return API.surety().post(
    `/quick/submissions/accounts/${accountNumber}/owners/credit-authorization`,
    data
  );
};

export const postSubmissionErrorEmail = data => {
  return API.surety().post(`/quick/submissions/notifications/errors`, data);
};

export const getBondObligeeList = account_name => {
  return API.surety().get(`/obligees?account_name=${account_name}`);
};

export const getSuretyQuote = id => {
  return API.surety().get(`data-store/${id}`);
};

export const postSuretyQuote = (id, data) => {
  return API.surety().post(`data-store/${id}`, data);
};

export const deleteSuretyQuote = id => {
  return API.surety().delete(`data-store/${id}`);
};

export const getDocumentsPacket = packetDetails => {
  const query = qs.stringify(packetDetails);
  return API.surety().get(`/documents/packet?${query}`, {
    responseType: "blob"
  });
};

export const postSuretyQuoteDocuments = ({
  quickSubmissionData: { account_number, bond_number },
  agencyCode,
  files
}) => {
  const formData = new FormData();
  const accountInfo = { account_number, agency_code: agencyCode };

  if (bond_number) {
    accountInfo.bond_number = bond_number;
  }

  formData.append("json_body", JSON.stringify(accountInfo));

  files.forEach((file, i) => {
    // custom for handling Excel files
    const isExcel = ["xls", "xlsx"].includes(
      file.name.split(".").slice(-1)[0].toLowerCase()
    );
    const excelFile = isExcel
      ? file.slice(0, file.size, "application/octet-stream")
      : null;

    const fileNumber = i === 0 ? "" : i + 1;
    formData.append(`file${fileNumber}`, isExcel ? excelFile : file, file.name);
  });

  return API.surety().post(`/documents/quick-upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};

export const quickQuoteAccount = data => {
  return API.surety().post(`/endpoint`, data);
};
