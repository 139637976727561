import React, { useContext } from "react";
import { Button, FormatDate, Currency, FlexRow } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import { logo_313 } from "../../constants/images";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import * as routes from "../../constants/routes";

const PremiumDepositConfirmationForm = ({
  activeAgencyCode,
  activeAgencyName,
  onCancelQuote
}) => {
  const { quoteData, supportingData, clearLocalQuote } =
    useContext(OnlineQuotingContext);

  const printConfirmation = () => {
    printPartOfPage(
      "PremiumDepositConfirmationForm",
      "Premium Deposit Confirmation Form Print",
      `
      body{
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
      }
      h3{
        border-bottom: solid 1px #aaa;
        padding-bottom: 1em;
      }
      .oq__pdcf__label {
        width: 200px;
        font-weight: bold;
      }
      
      .oq__pdcf__row {
        display: flex;
        padding: .25em 0;
      }
      img.ufglogo{
        width: 150px;
        margin: 20px 0;
      }
      `
    );
  };

  const redirectToSubmissionList = () => {
    onCancelQuote().then(() => {
      clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
    });
  };

  const printPartOfPage = (elementId, winName, css) => {
    // for IE11, it's kinda finicky about printing this new content
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      const printContent = document.createElement("div");
      const content = document.getElementById(elementId).cloneNode(true);
      printContent.appendChild(content);
      const style = document.createElement("style");
      style.setAttribute("type", "text/css");
      if (style.styleSheet) style.styleSheet.cssText = css;
      else style.appendChild(document.createTextNode(css));
      printContent.appendChild(style);
      const uniqueName = new Date();
      const windowName = winName + " " + uniqueName.getTime();
      const printWindow = window.open(
        "", // no url so it uses about:blank
        windowName,
        "left=50000,top=50000,width=0,height=0"
      );
      printWindow.document.write(printContent.innerHTML);
      printWindow.document.close();
      printWindow.addEventListener("load", function () {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      });
    } else {
      // for all other browsers, this is much smoother
      const iframe = document.createElement("iframe");
      iframe.setAttribute("title", elementId);
      iframe.setAttribute("id", elementId);
      iframe.setAttribute(
        "style",
        "height: 0px; width: 0px; position: absolute; opacity: 0; bottom: 0px; right: 0px;"
      );

      document.body.appendChild(iframe);
      const pri = iframe.contentWindow;

      pri.document.open();

      pri.document.write(document.getElementById(elementId).innerHTML);
      if (css) {
        const head = pri.document.getElementsByTagName("HEAD")[0];
        const style = document.createElement("style");
        style.type = "text/css";
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
      }

      pri.focus();

      // using an interval checker to see if the image is loaded so that the print doesn't kick off until the image is loaded
      let printChker = 0;
      const policyHolderIntervalRunner = setInterval(() => {
        printChker++;
        if (printChker > 10) clearInterval(policyHolderIntervalRunner);
        const image = pri.document.querySelector("#ufg-logo-print-image");
        if (image) {
          if (image.complete && image.naturalHeight !== 0) {
            clearInterval(policyHolderIntervalRunner);
            pri.print();
          }
        }
      }, 500);
    }
  };

  const today = new Date();
  return (
    <div>
      <div id="PremiumDepositConfirmationForm" className="hidden oq__print-div">
        <img
          id="ufg-logo-print-image"
          alt="UFG Insurance Logo"
          data-displaymode="Original"
          className="ufglogo"
          src={logo_313}
          title="UFG Insurance"
        />
        <h3>PREMIUM DEPOSIT CONFIRMATION FORM</h3>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Date</span>
          <span>
            <FormatDate date={today} format="M/D/YYYY" />
          </span>
        </div>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Customer ID</span>
          <span>{quoteData.bindData.accountNumber}</span>
        </div>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Policy Effective Date</span>
          <span>
            <FormatDate
              date={quoteData?.baseData?.periodStartDate}
              format="M/D/YYYY"
            />{" "}
            -
            <FormatDate
              date={quoteData?.baseData?.periodEndDate}
              format="M/D/YYYY"
            />
          </span>
        </div>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Quote Number</span>
          <span>{quoteData.bindData.policyNumber}</span>
        </div>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Deposit Premium</span>
          <span>
            <Currency amount={supportingData?.billingData?.downPayment} />
          </span>
        </div>
        <div className="oq__pdcf__row">
          <span className="oq__pdcf__label">Agency Information</span>
          <span>
            {activeAgencyCode} {activeAgencyName}
          </span>
        </div>
        <div>
          <p>
            This is confirmation of the amount entered as a deposit on the
            application. Transactions are not considered payment if returned by
            the financial institution for any reason (e.g., insufficient funds).
          </p>
          <p>
            Please note: If the option <b>Policyholder&apos;s account</b> was
            selected or if the insured&apos;s banking information was not
            entered, or entered incorrectly, the deposit amount will <b>not</b>{" "}
            be credited to the policy and a bill will be sent.
          </p>
        </div>
      </div>
      <FlexRow align="right">
        <Button onClick={printConfirmation}>Print Confirmation</Button>
        <Button
          variant="primary"
          type="submit"
          onClick={redirectToSubmissionList}
        >
          Return to UFG Agent
        </Button>
      </FlexRow>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    activeAgencyName: sessionSelector.getActiveAgencyName(state)
  };
};

export default connect(mapStateToProps, {})(PremiumDepositConfirmationForm);
