import qs from "query-string";
import API from "../api";

/**
 *
 * @param {string} zipCode 5 or 9 digit zip /^\d{5}(-\d{4})?$/
 */
export const searchLocaleByZipCode = zipCode => {
  return API.address().get(`/zip/${zipCode}`);
};

export const verifyAddress = (address, city, state, zipCode) => {
  const queryString = qs.stringify({
    address_line: address,
    city,
    state,
    zip: zipCode
  });
  return API.address().get(`/address?${queryString}`);
};

// used by online quoting
export const verifyAddressV2 = (address, city, state, zipCode) => {
  const queryString = qs.stringify({
    address_line: address,
    city,
    state,
    zip: zipCode
  });
  return API.address().get(`/address?${queryString}`);
};
