import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
  useRef
} from "react";
import OnlineQuotingContext from "../OnlineQuotingContext";
import { useHistory } from "react-router-dom";
import { useIndexedDB } from "react-indexed-db-hook";
import { sortByProperty } from "../../online-quoting/shared/util";

export const useQuotingUfgHelper = ({
  applicationId,
  userAgentCode,
  impersonateAgentRedirect,
  env
}) => {
  const { supportingData } = useContext(OnlineQuotingContext);

  const history = useHistory();
  const mounted = useRef(true);

  const loadQuote = useCallback(
    ({ agent, quoteId }) => {
      if (quoteId) {
        if (agent === userAgentCode) {
          // load the quote for this agent
          history.push(`/online_quoting/loader/${quoteId}`);
        }
        // switch agent and load it's quote
        else {
          // console.log("Switching agents to load new quote");
          impersonateAgentRedirect(
            applicationId,
            agent || "",
            `${window.location.origin}/online_quoting/loader/${quoteId}`
          );
        }
      } else {
        impersonateAgentRedirect(
          applicationId,
          agent || "",
          `${window.location.origin}/online_quoting/step1`
        );
      }
    },
    [applicationId, history, impersonateAgentRedirect, userAgentCode]
  );

  const quoteObjectForIndexedDb = useCallback(() => {
    if (!supportingData || Object.keys(supportingData).length === 0) return {};

    const d = new Date();
    const timestamp = d.getTime();

    return {
      quoteId: supportingData?.quoteNumber,
      agent: userAgentCode,
      policyLines: supportingData?.policyLines,
      effectiveDate: supportingData?.effectiveDate,
      currentPage: supportingData?.currentPage,
      account: supportingData?.customerInformation?.accountHolder?.displayName,
      address:
        supportingData?.customerInformation?.accountHolder?.primaryAddress
          .displayName,
      classCode: supportingData?.classCode?.code,
      classDescription: supportingData?.classCode?.description,
      bizType: supportingData?.classCode?.businessType_UFG,
      timestamp,
      env
    };
  }, [env, supportingData, userAgentCode]);

  /**
   * Recent quote references in the browser
   * { getByID, getAll, add, update, deleteRecord }
   */
  const dbRecent = useIndexedDB("recent");
  const [recentQuotes, setRecentQuotes] = useState([]);

  const refreshRecent = useCallback(
    () =>
      dbRecent.getAll().then(quotesFromDB => {
        if (mounted.current) setRecentQuotes(quotesFromDB);
      }),
    [dbRecent]
  );

  /**
   * Saving quote references in the browser
   * { getByID, getAll, add, update, deleteRecord }
   */
  const dbSaved = useIndexedDB("quotes");
  const [savedQuotes, setSavedQuotes] = useState([]);

  const refreshSaved = useCallback(
    () =>
      dbSaved.getAll().then(quotesFromDB => {
        if (mounted.current) setSavedQuotes(quotesFromDB);
      }),
    [dbSaved]
  );

  useEffect(() => {
    if (mounted.current) refreshSaved();
    if (mounted.current) refreshRecent();

    return () => {
      // This code runs when component is unmounted
      mounted.current = false;
    };
  }, [refreshSaved, refreshRecent]);

  const saveQuote = useCallback(() => {
    if (!supportingData || !supportingData?.quoteNumber) return;

    const thisQuote = quoteObjectForIndexedDb();

    dbSaved.getByID(supportingData.quoteNumber).then(i => {
      if (i)
        dbSaved
          .update(thisQuote, supportingData.quoteNumber)
          .then(refreshSaved)
          .catch(e => console.error({ e }));
      else
        dbSaved
          .add(thisQuote, supportingData.quoteNumber)
          .then(refreshSaved)
          .catch(e => console.error({ e }));
    });
  }, [supportingData, quoteObjectForIndexedDb, dbSaved, refreshSaved]);

  const deleteQuote = useCallback(
    ({ id, type }) => {
      // console.log({ id, type });
      if (type === "saved")
        dbSaved
          .deleteRecord(id)
          .then(refreshSaved)
          .catch(e => console.error({ e }));
      if (type === "recent")
        dbRecent
          .deleteRecord(id)
          .then(refreshRecent)
          .catch(e => console.error({ e }));
    },
    [dbSaved, dbRecent, refreshSaved, refreshRecent]
  );

  const clearAllSaved = useCallback(() => {
    dbSaved.clear().then(() => setSavedQuotes([]));
  }, [dbSaved]);

  const currentQuoteInStorage = (savedQuotes || []).find(
    q => q.quoteId === supportingData?.quoteNumber
  );

  // every time supportingData changes...
  // make sure the quote is displayed at the top of the recent quotes list
  // and truncate list to be 20 items long at most
  useEffect(() => {
    const d = new Date();
    const timestamp = d.getTime();
    const thisQuote = quoteObjectForIndexedDb();

    /** checking recent list */
    // if current quote is in the recent, then...
    if (mounted.current && supportingData?.quoteNumber) {
      dbRecent.getByID(supportingData.quoteNumber).then(quote => {
        const difference = timestamp - (quote?.timestamp || 0);

        if (quote) {
          // if quote is older than 30 seconds, then update local reference timestamp
          if (difference > 30000) {
            // console.log("update quote in recent", thisQuote);
            dbRecent
              .update(thisQuote, quote.quoteId)
              .then(refreshRecent)
              .catch(e => console.error({ e }));
          }
        } else {
          // console.log("add quote to recent", thisQuote);
          dbRecent
            .add(thisQuote, supportingData.quoteNumber)
            .then(refreshRecent)
            .catch(e => console.error({ e }));
        }
      });

      /** if list of recent quotes is greater than 20, then truncate the list */
      const leng = recentQuotes.length;
      if (leng > 20) {
        const itemsToRemove = leng - 20;
        recentQuotes
          .sort(sortByProperty("timestamp", false))
          .slice(-itemsToRemove)
          .forEach(q => {
            // console.info("delete quote from recent if over 20 count", q);
            dbRecent.deleteRecord(q.quoteId).then(refreshRecent);
          });
      }
    }
  }, [
    dbRecent,
    quoteObjectForIndexedDb,
    recentQuotes,
    refreshRecent,
    supportingData?.quoteNumber
  ]);

  useEffect(() => {
    const d = new Date();
    const timestamp = d.getTime();
    const thisQuote = quoteObjectForIndexedDb();

    /** checking recent list */
    // if current quote is in the recent, then...
    if (mounted.current && supportingData?.quoteNumber) {
      /** updating saved quote (if exists in saved db) */

      if (savedQuotes.some(q => q.quoteId === supportingData?.quoteNumber)) {
        // quote is in the saved list, update it
        dbSaved.getByID(supportingData.quoteNumber).then(quote => {
          const difference = timestamp - quote.timestamp;

          if (quote) {
            // if quote is older than 30 seconds, then update local reference timestamp
            if (!quote.timestamp || difference > 30000) {
              // console.log("update quote in saved", thisQuote);
              dbSaved
                .update(thisQuote, quote.quoteId)
                .then(refreshSaved)
                .catch(e => console.error({ e }));
            }
          }
        });
      }
    }
  }, [
    dbSaved,
    quoteObjectForIndexedDb,
    refreshSaved,
    savedQuotes,
    supportingData?.quoteNumber
  ]);

  /**
   * exposing data, functions, etc to the context
   */

  return useMemo(
    () => ({
      loadQuote,
      userAgentCode,
      savedQuotes,
      saveQuote,
      deleteQuote,
      clearAllSaved,
      currentQuoteInStorage,
      recentQuotes
    }),
    [
      loadQuote,
      userAgentCode,
      savedQuotes,
      saveQuote,
      deleteQuote,
      clearAllSaved,
      currentQuoteInStorage,
      recentQuotes
    ]
  );
};
