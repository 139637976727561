export const Eportal = {
  getAccountInfoLink: accountNumber =>
    `${Eportal.accountBondInquiryUrl}&NO_ACCOUNT=${accountNumber}`,
  getBondInfoLink: bondNumber =>
    `${Eportal.accountBondInquiryUrl}&NO_BOND=${bondNumber}`,
  getBidRequestLink: accountNumber =>
    `${Eportal.bidBondRequestUrl}?NO_ACCOUNT=${accountNumber}`,
  accountBondInquiryUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/codes/gotoBondCE.asp?page=WBINQ`,
  bidBondRequestUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/Contract/BidRequest/accountlist_portal.aspx`,
  contractBondIssueUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/FormUpload/default_portal.aspx?product=Contract`,
  suretyConsentUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/Contract/ConsentSurety/accountlist_portal.aspx`,
  quoteCommercialBondUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/FormUpload/default_portal.aspx?product=Commercial`,
  californiaLicenseBondUrl: `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgBonds/FormUpload/default_portal.aspx?product=California`
};
