import qs from "query-string";
import API from "../../api";

export const getStateList = activeSpecialtyCompanyBranch => {
  const params = {
    company_branch: activeSpecialtyCompanyBranch
  };

  const queryString = qs.stringify(params);
  if (activeSpecialtyCompanyBranch) {
    return API.agent().get(`/agent-manual/states?${queryString}`);
  }
  return API.agent().get(`/agent-manual/states`);
};

export const getTableOfContents = productId => {
  return API.agent().get(
    `/agent-manual/states/products/${productId}/table-of-contents`
  );
};

export const getStateProducts = stateId => {
  return API.agent().get(`/agent-manual/states/${stateId}/products`);
};

export const getContent = contentId => {
  return API.agent().get(
    `/agent-manual/states/products/table-of-contents/${contentId}`
  );
};
