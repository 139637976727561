import React from "react";
import { SidebarNav } from "../../components/menus/SidebarNav";
import { Translations } from "../../components/translations";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as routes from "../../constants/routes";

const SuretySideNav = () => {
  const { suretyAgentManualsUpdateEnabled, suretyDocumentsAndFormsEnabled } =
    useFlags();

  const getSuretyLinks = () => {
    const links = [];
    links.push({
      to: routes.SURETY,
      activeClassName: "nav-menu--active",
      text: "Surety"
    });
    if (suretyAgentManualsUpdateEnabled) {
      links.push({
        to: routes.SURETY_AGENT_MANUALS,
        activeClassName: "nav-menu--active",
        text: "Surety Agent Manuals"
      });
    }
    if (suretyDocumentsAndFormsEnabled) {
      links.push({
        to: routes.SURETY_DOCS_FORMS,
        activeClassName: "nav-menu--active",
        text: "Surety Documents and Forms"
      });
    }
    return links;
  };

  return (
    <SidebarNav links={getSuretyLinks()} header={Translations.Surety.surety} />
  );
};

export default SuretySideNav;
