import "./Step6QuoteSummary.scss";

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  ContentHeader,
  FlexRow,
  LoadingIndicator,
  Modal,
  formatDate
} from "@ufginsurance/ui-kit";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import * as api from "../services/onlineQuotingService";
import * as routes from "../constants/routes";
import BillingContactPanel from "./step6/BillingContactPanel";
import BillingSection from "./step6/BillingSection";
import BindModalContent from "./step6/BindModalContent";
import BottomNavigation from "./shared/BottomNavigation";
import CreditsDebits from "./step6/CreditsDebits/CreditsDebits";
import EffectiveDateStep6 from "./shared/effectiveDate/EffectiveDateStep6";
import LobPremiumTable from "./step6/lobTable/LobPremiumTable";
import OnlineQuotingContext from "./OnlineQuotingContext";
import OqModal from "./shared/OqModal";
import PolicyHolderEmail from "./step6/PolicyHolderEmail";
import ProducerField from "./step6/ProducerField";
import PricingReview from "./step6/PricingReview";
import ServiceCenterSection from "./step6/ServiceCenterSection";
import StateSpecificIds from "./step6/StateSpecificIds";
import TotalPremiumDisplay from "./step6/TotalPremiumDisplay";
import UnderWriterIssues from "./step6/UnderWriterIssues";
import UnderwriterSendForReview from "./step6/UnderwriterSendForReview";
import ValidateVehicles from "./step6/ValidateVehicles";
import Step6Context, { useStep6 } from "./step6/Step6Context";
import { DocumentService } from "../services/documentService";
import { IsAtleastOneVINMissing } from "./step5/vehicle/getAllVehicles";
import { cancelQuote } from "../top-action-cards/new-quote/actions/newQuoteActions";
import { logger } from "../loggers";
import { toHtmlSelectOptions } from "./shared/util";
import QuoteSummary from "./step6/quoteSummary/QuoteSummary";
import { sendFloridaWindHailNotes } from "./step5/building/floridaDetails";

const toastMessages = {
  proposal: "Please wait while we retrieve your requested documents.",
  policyDecs: "Please wait while we retrieve your requested documents."
};
const Step6QuoteSummary = ({
  activeAgencyCode,
  agentName,
  activeAgencyName,
  onCancelQuote
}) => {
  const {
    quoteData,
    hasBlockingUwIssues,
    navigateToStep,
    issueQuote,
    step,
    supportingData,
    loggingData,
    quoteIsUpdating,
    showUpdatingToast,
    closeUpdatingToast,
    supportingDataIsUpdating,
    updateSupportingDataPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const Step6_context_value = useStep6({ onCancelQuote });

  const history = useHistory();

  useEffect(() => {
    if (step !== 6 && !supportingDataIsUpdating) {
      updateSupportingDataPromise({
        dataToMergeAndSave: { currentPage: "6" }
      });
    }
  }, [step, updateSupportingDataPromise, supportingDataIsUpdating]);

  const [bindIssueEnabled, setBindIssueEnabled] = useState(false);
  const [bindAndIssueModal, setBindAndIssueModal] = useState(false);
  const [printPresentShow, setPrintPresentShow] = useState(false);
  const [formMetadata, setFormMetadata] = useState();
  const [gettingMetadata, setGettingMetadata] = useState();

  const [showVehicleValidation, setShowVehicleValidation] = useState(false);
  const [formIsValid_EffectiveDate, setFormIsValid_EffectiveDate] =
    useState(false);
  const [formIsValid_Producer, setFormIsValid_Producer] = useState(false);
  const [formIsValid_Email, setFormIsValid_Email] = useState(false);
  const [triggerFormValidation, setTriggerFormValidation] = useState(false);
  const [formIsValid_StateSpecificIds, setFormIsValid_StateSpecificIds] =
    useState(false);
  const [formIsValid_BillingSection, setFormIsValid_BillingSection] =
    useState(false);
  const [showBindIssueConfirmationModal, setBindIssueConfirmationModal] =
    useState(false);
  const [bindIssueWasClicked, setBindIssueWasClicked] = useState(false);
  const [navBackSpinner, setNavBackSpinner] = useState(false);

  const handleBindAndIssue = () => {
    if (!bindIssueEnabled) return;

    setBindIssueConfirmationModal(false);

    //if the quote is already bound just show the what to expect modal
    if (quoteData?.baseData?.periodStatus === "Bound") {
      setBindAndIssueModal(true);
      return;
    }

    issueQuote({
      quoteID: quoteData.quoteID,
      payload: {
        agentId: activeAgencyCode,
        agent_name: agentName,
        premium: quoteData?.quoteData?.offeredQuotes[0].premium?.total?.amount
      }
    })
      .then(({ bound, issues }) => {
        if (bound) {
          setBindAndIssueModal(true);
          sendFloridaWindHailNotes({ supportingData, quoteData });
        }

        if (issues)
          toastErrr({
            type: "validation",
            action: "issueQuote",
            description: "validation blocks bind quote",
            displayMessage: (
              <div className="oq__flow-error__msg">
                <div className="oq__flow-error__msg-text">
                  Unable to continue. Please fix these issues in order to
                  continue:
                </div>
                <div className="oq__flow-error__msg-list">
                  <ul>
                    {issues?.map(e => (
                      <li key={e.reason}>{e.reason}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          });
      })
      .catch(error => {
        console.error(error);
        toastErrr({
          action: "issueQuote",
          description: "unable to bind quote",
          error
        });
      })
      .finally(() => {});
  };

  const openConfimModalIfValid = () => {
    if (
      !formIsValid_EffectiveDate ||
      !formIsValid_Email ||
      !formIsValid_StateSpecificIds ||
      !formIsValid_Producer ||
      !formIsValid_BillingSection
    ) {
      setTriggerFormValidation(true);
    } else {
      setBindIssueConfirmationModal(true);
    }
  };

  useEffect(() => {
    if (triggerFormValidation) {
      setTriggerFormValidation(false);
    }
  }, [triggerFormValidation]);

  useEffect(() => {
    if (!!supportingData?.billingData?.bindAndIssueModalOpened) {
      setBindIssueConfirmationModal(false);
      setBindAndIssueModal(true);
    }
  }, [supportingData.billingData]);

  useEffect(() => {
    if (!gettingMetadata && !formMetadata && quoteData) {
      setGettingMetadata(true);
      api
        .getQuoteSummaryMetadata(quoteData.quoteID)
        .then(results => {
          const formData = results?.data?.formData || {};
          formData.umbrellaLimits = (formData?.umbrellaLimits || []).map(c => {
            return {
              value: c.code,
              label: c.name
            };
          });
          formData.producerOpts = toHtmlSelectOptions(formData.producers);
          setFormMetadata(formData);
          updateSupportingDataPromise({
            dataToMergeAndSave: {
              ServiceCenterAccountData: (formData?.accountContacts || []).find(
                x =>
                  x.accountContactRoles.some(
                    y => y?.subtype === "SvcCenter_UFG"
                  )
              )
            }
          });
        })
        .catch(error => {
          //alert("Class codes metadata failure:" + error.response);
          toastErrr({
            action: "getQuoteSummaryMetadata",
            description: "unable to get quote summary metadata",
            error
          });

          console.error(
            "Class  codes metadata failure:" + JSON.stringify(error)
          );
        });
    }
  }, [
    formMetadata,
    gettingMetadata,
    loggingData,
    quoteData,
    supportingData,
    toastErrr,
    updateSupportingDataPromise
  ]);

  useEffect(() => {
    // print and present only requires underwritter issues to be approved.
    if (!hasBlockingUwIssues) {
      setPrintPresentShow(true);
    } else {
      setPrintPresentShow(false);
    }

    //bind and issue requires both underwritter issues to be approved and the form to be valid.
    if (IsAtleastOneVINMissing({ quoteData, supportingData })) {
      setBindIssueEnabled(false);
      setShowVehicleValidation(true);
    } else if (
      !hasBlockingUwIssues &&
      formIsValid_Producer &&
      formIsValid_Email &&
      formIsValid_StateSpecificIds &&
      formIsValid_BillingSection &&
      formIsValid_EffectiveDate
    ) {
      setBindIssueEnabled(true);
    } else {
      setBindIssueEnabled(false);
    }
  }, [
    quoteData,
    formIsValid_BillingSection,
    formIsValid_Email,
    formIsValid_Producer,
    formIsValid_StateSpecificIds,
    supportingData,
    formIsValid_EffectiveDate,
    hasBlockingUwIssues
  ]);

  useEffect(() => {
    if (!quoteIsUpdating && bindIssueWasClicked) setBindIssueWasClicked(false);

    // only use the bindDataUpdating state to watch for updates to finish that block the bind button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteIsUpdating]);

  // --------------------------------
  //     START RETRIEVE DOCUMENTS
  // --------------------------------

  const openPDFdocument = (response, fileName) => {
    const documentService = new DocumentService();
    documentService.initializeRenderer("/loading-document");
    documentService.saveOrOpenDocument(response.data, fileName);
  };

  //8 seconds because when the doc exist. it takes about 7 seconds to download.
  //This prevents a second call to be done of a document that worked.
  const interval = 8000;
  const attempts = 30; //Total 4 minutes of waiting for the document to be generated.

  let proposalIntervalId;
  let proposalEndTime;
  let proposalPreventDupCalls = 0;
  const apiforPrintPresent = () => {
    if (proposalPreventDupCalls > 0) return;

    proposalPreventDupCalls++;

    const message = "Loading quote proposal...";
    showUpdatingToast({ message: toastMessages.proposal });
    api
      .getDocument(quoteData.quoteID, "proposal")
      .then(response => {
        proposalPreventDupCalls--;
        clearInterval(proposalIntervalId);
        closeUpdatingToast({ message: toastMessages.proposal });

        // send event to GA that proposal was opened
        logger.event({
          category: "OQ_eligibility",
          action: "opened_doc",
          label: "proposal"
        });

        openPDFdocument(response, `quote-proposal-${quoteData.quoteID}.pdf`);
      })
      .catch(error => {
        proposalPreventDupCalls--;
        if (
          error.response.status !== 404 || //Stop waiting if the error is not a 404.
          (error.response.status === 404 &&
            Number(new Date()) > proposalEndTime)
        ) {
          closeUpdatingToast({ message });
          clearInterval(proposalIntervalId);
          toastErrr({
            displayMessage:
              "Unable to load the quote proposal. Please try again later.",
            action: "getDocument",
            description: "failed to get quote proposal",
            error
          });
        }
      });
  };

  const printPresent = () => {
    showUpdatingToast({ message: toastMessages.proposal });

    apiforPrintPresent();
    proposalEndTime = Number(new Date()) + interval * attempts;
    proposalIntervalId = setInterval(apiforPrintPresent, interval);
  };

  let decIntervalId;
  let decEndTime;
  let decPreventDupCalls = 0;
  //OOQ-3350 used to track the successful returns of pdf.
  //Then only show the second pdf and ignore the first.
  //TODO: This is temporary. We need a better long-term solution.
  let numSuccessPdfs = 0;
  const apiforPolicyDec = () => {
    if (decPreventDupCalls > 0) return;

    showUpdatingToast({ message: toastMessages.policyDecs });

    decPreventDupCalls++;
    api
      .getDocument(quoteData.quoteID, "dec")
      .then(response => {
        decPreventDupCalls--;
        if (numSuccessPdfs >= 1) {
          clearInterval(decIntervalId);
          closeUpdatingToast({ message: toastMessages.policyDecs });

          // send event to GA that dec was opened
          logger.event({
            category: "OQ_eligibility",
            action: "opened_doc",
            label: "dec"
          });

          openPDFdocument(response, `policy-dec-${quoteData.quoteID}.pdf`);
        }
        numSuccessPdfs++;
      })
      .catch(error => {
        decPreventDupCalls--;
        if (Number(new Date()) > decEndTime) {
          closeUpdatingToast({ message: toastMessages.policyDecs });
          clearInterval(decIntervalId);
          toastErrr({
            displayMessage:
              "Unable to load the policy declarations.  Please try again later.",
            action: "getDocument",
            description: "failed to get policy decs",
            error
          });
        }
      });
  };

  const getPolicyDec = () => {
    showUpdatingToast({ message: toastMessages.policyDecs });
    apiforPolicyDec();
    decEndTime = Number(new Date()) + interval * attempts;
    decIntervalId = setInterval(apiforPolicyDec, interval);
  };

  // --------------------------------
  //     END RETRIEVE DOCUMENTS
  // --------------------------------

  const returnToStep5 = useCallback(() => {
    setNavBackSpinner(true);
    toast.dismiss();

    navigateToStep({ step: 5 }, ({ success }) => {
      if (success) history.push(routes.ONLINE_QUOTING_STEP5);
    });
  }, [history, navigateToStep]);

  return (
    <Step6Context.Provider value={Step6_context_value}>
      <div id="oq-step6" className="oq__step-container">
        <ContentHeader>
          {!hasBlockingUwIssues
            ? "All set: Your quote is ready to be issued"
            : "Almost done: Submit your quote for a quick review"}
        </ContentHeader>
        {bindAndIssueModal && (
          <OqModal
            className="oq-modal__premium-summary__bind-issue-modal"
            title="Policy issued successfully"
            show
            closeIcon={false}
            onHide={() => {}}
            body={
              <BindModalContent
                getPolicyDec={getPolicyDec}
                printPresent={printPresent}
                activeAgencyCode={activeAgencyCode}
                activeAgencyName={activeAgencyName}
              />
            }
          />
        )}
        {!formMetadata && <LoadingIndicator />}

        {formMetadata && (
          <div className="page-content">
            <div className="left-col">
              <TotalPremiumDisplay />
              <CreditsDebits />
              <PricingReview />
              <QuoteSummary />
              <FlexRow>
                {printPresentShow && (
                  <Button
                    variant="primary"
                    onClick={printPresent}
                    disabled={quoteIsUpdating}
                    wrapperClassName="oq__button__full-width"
                  >
                    Generate Proposal
                  </Button>
                )}
              </FlexRow>

              {quoteData?.quoteData?.offeredQuotes.length > 0 && (
                <LobPremiumTable />
              )}

              <ProducerField
                formMetadata={formMetadata}
                formIsValid={formIsValid_Producer}
                setFormIsValid={setFormIsValid_Producer}
                triggerFormValidation={triggerFormValidation}
              />
            </div>

            <div className="right-col">
              <div className="oq__bind">
                <UnderWriterIssues />

                {!hasBlockingUwIssues && (
                  <>
                    <h3>Bind and Issue Policy</h3>
                    <EffectiveDateStep6
                      initialValues={{
                        effectiveDate:
                          formatDate(
                            quoteData?.baseData?.periodStartDate,
                            "MM/DD/YYYY"
                          ) || ""
                      }}
                      quoteId={quoteData?.quoteID}
                      ispremiumSummaryPage
                      formIsValid={formIsValid_EffectiveDate}
                      setFormIsValid={setFormIsValid_EffectiveDate}
                      triggerFormValidation={triggerFormValidation}
                    />
                    <PolicyHolderEmail
                      formIsValid={formIsValid_Email}
                      setFormIsValid={setFormIsValid_Email}
                      triggerFormValidation={triggerFormValidation}
                    />
                    {showVehicleValidation && <ValidateVehicles />}
                    <StateSpecificIds
                      formIsValid={formIsValid_StateSpecificIds}
                      setFormIsValid={setFormIsValid_StateSpecificIds}
                      triggerFormValidation={triggerFormValidation}
                    />
                    <BillingContactPanel
                      contactsFromMetadata={formMetadata?.accountContacts}
                    />
                  </>
                )}
                <ServiceCenterSection
                  accountContacts={formMetadata?.accountContacts}
                  hasServiceCenterAccess={
                    formMetadata?.agencyInformation?.is_service_center
                  }
                />
                {!hasBlockingUwIssues && (
                  <BillingSection
                    formMetadata={formMetadata}
                    formIsValid={formIsValid_BillingSection}
                    setFormIsValid={setFormIsValid_BillingSection}
                  />
                )}
              </div>
            </div>

            {showBindIssueConfirmationModal && (
              <Modal
                className="modal-bind-quote-confirmation"
                size="md"
                title="Bind and Issue confirmation"
                body={
                  <div>
                    <p>
                      We appreciate your business, thank you for the
                      opportunity!
                    </p>
                    <p>
                      Please confirm you would like to bind and issue this
                      policy{" "}
                      <b>
                        effective{" "}
                        {formatDate(
                          quoteData?.baseData?.periodStartDate,
                          "MM/DD/YYYY"
                        )}
                      </b>
                      .
                    </p>
                  </div>
                }
                footer={
                  <FlexRow>
                    <div className="pull-left">
                      <Button
                        onClick={() => {
                          setBindIssueConfirmationModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="pull-right">
                      <Button
                        variant="primary"
                        onMouseDown={() => {
                          setBindIssueWasClicked(true);
                        }}
                        onClick={() => {
                          handleBindAndIssue();
                          setBindIssueConfirmationModal(false);
                        }}
                      >
                        Bind and Issue
                      </Button>
                    </div>
                  </FlexRow>
                }
                show={showBindIssueConfirmationModal}
                altCloseMethod={false}
                closeIcon={false}
              />
            )}
          </div>
        )}
        {formMetadata && (
          <BottomNavigation
            left={{
              text: "Back to Risk Information",
              onClick: () => returnToStep5(),
              spinner: navBackSpinner,
              disabled: navBackSpinner || quoteIsUpdating
            }}
            right={{
              jsx: (
                <span className="align-right">
                  <UnderwriterSendForReview />
                  {!hasBlockingUwIssues && (
                    <Button
                      variant="primary"
                      onClick={openConfimModalIfValid}
                      disabled={quoteIsUpdating}
                    >
                      Bind and Issue Policy
                    </Button>
                  )}
                </span>
              )
            }}
          />
        )}
      </div>
    </Step6Context.Provider>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    agentName: sessionSelector.getFirstAndLastName(state),
    activeAgencyName: sessionSelector.getActiveAgencyName(state)
  };
};

export default connect(mapStateToProps, { onCancelQuote: cancelQuote })(
  Step6QuoteSummary
);
