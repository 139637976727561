import React from "react";
import { Button, Form, FormGroup, Input } from "@ufginsurance/ui-kit";
import Address from "../../../../quick-program/quick-quoting/shared/Address";
import EffectiveExpirationDate from "../../../builders-risk/components/EffectiveExpirationDate";
import { RATE_RULES } from "../../../builders-risk/lib/constants";
import { isImbrState } from "../../../builders-risk/lib/utils";
import { ACCOUNT_NAME_MAX_LENGTH } from "../../lib/constants";
import {
  initialImbrCustomerValues,
  emptyImbrCustomerErrors
} from "../../lib/form-utils";

export default function NewCustomerInfo({
  form,
  licensedStates,
  onContinue,
  disableFields,
  showVerifyAddress,
  setView,
  setExistingCustomer
}) {
  const {
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    values,
    errors,
    updateForm,
    invalidFields
  } = form;

  const handleZipChange = (field, localeData) => {
    const locale = Array.isArray(localeData) ? localeData[0] : localeData;
    const zipErrors = [];

    if (!!locale?.state) {
      if (
        !isImbrState(locale.state) ||
        RATE_RULES.blockedStates.includes(locale.state)
      ) {
        zipErrors.push(
          "The state you have selected is not yet available for the Builders' Risk portal. Please contact your underwriter with questions."
        );
      } else if (!licensedStates.some(_ => _ === locale.state)) {
        zipErrors.push(
          "You are attempting to quote business in a state in which we do not have a license on file for. Please contact your UFG marketing representative for licensing procedures."
        );
      }
    }

    updateForm({
      values: {
        ...values,
        zip: field.value,
        city: locale?.city,
        state: locale?.state
      },
      errors: {
        ...errors,
        zip: zipErrors
      }
    });
  };

  const handleAccountNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(
        "Business Name must begin with letters or numbers. If you have any questions please contact the helpdesk at 800-895-6253."
      );
    return fieldErrors;
  };

  const handleNewCustomerToIMBROnClick = () => {
    onContinue(form);
  };

  const handleOnPrevious = () => {
    updateForm({
      values: initialImbrCustomerValues,
      errors: emptyImbrCustomerErrors
    });
    setExistingCustomer(null);
    setView("main");
  };

  return (
    <>
      <Form context={form}>
        <FormGroup>
          <Input
            id="account_name"
            name="account_name"
            label="Insured Name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleAccountNameValidate}
            value={values.account_name}
            required
            size="fill"
            maxLength={ACCOUNT_NAME_MAX_LENGTH}
            disabled={disableFields}
          />
        </FormGroup>
        <Address
          form={form}
          customZipChangeHandler={handleZipChange}
          showDefaultAddressField
          disableProjectCity
          disableProjectState
          addressLineFieldLabel="Insured Address"
          zipCodeLabel="Zip"
          disableFields={disableFields}
        />
        <FormGroup>
          <Input
            placeholder="Contractor Name"
            id="contractor_name"
            name="contractor_name"
            label="If insured is not the contractor, enter the contractor name"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.contractor_name}
            maxLength={55}
            size="fill"
            isClearable
          />
        </FormGroup>
        <EffectiveExpirationDate form={form} />
      </Form>

      {!showVerifyAddress && (
        <div className="modal-form-buttons">
          <Button onClick={handleOnPrevious}>Previous</Button>
          <Button
            variant="primary"
            disabled={
              !!Object.keys(errors)?.length ||
              !!invalidFields?.length ||
              !values?.account_name
            }
            onClick={handleNewCustomerToIMBROnClick}
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}
