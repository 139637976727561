import * as types from "../actions/reportClaimActionTypes";

export const startReportAClaim = () => ({
  type: types.START_REPORT_A_CLAIM,
  startReportAClaim: true
});

export const startReportAClaimWithPolicy = policyNumber => ({
  type: types.START_REPORT_A_CLAIM,
  startReportAClaim: true,
  policyNumber
});

export const cancelReportAClaim = () => ({
  type: types.CANCEL_REPORT_A_CLAIM,
  startReportAClaim: false
});
