import { mergeExclusions } from "../../shared/helpers/mergeExclusions";

import { generalCoverageControls } from "./jurisdictional/general";
import { autoCoverageControls } from "./jurisdictional/auto";

import { lineAutoCoverageControls } from "./line/auto";

export const getDynamicJurisdictionalCoverageControls = ({
  coverableName,
  productLine,
  quoteData,
  supportingData
}) => {
  const general = generalCoverageControls({ supportingData });
  const auto = autoCoverageControls({ coverableName, productLine, quoteData });

  const exclusions = mergeExclusions([general, auto]);

  return exclusions;
};

export const getDynamicLineLevelCoverageControls = ({ quoteData }) => {
  const auto = lineAutoCoverageControls({
    quoteData
  });

  const exclusions = mergeExclusions([auto]);

  return exclusions;
};
