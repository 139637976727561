import React from "react";
import { Button, Table } from "@ufginsurance/ui-kit";

export default function AccountSearchTable({ setView, setExistingCustomer }) {
  return (
    <Table
      id="add-customer-table"
      rowKey="id"
      columns={[
        { key: "add_customer_button", align: "center" },
        { key: "id", label: null, element: () => {} }
      ]}
      data={[
        {
          add_customer_button: (
            <Button
              isLink
              inline
              onClick={() => {
                setView("newCustomer");
                setExistingCustomer(null);
              }}
            >
              Add New Customer
            </Button>
          ),
          id: 1
        }
      ]}
    />
  );
}
