import { connect } from "react-redux";
import { updateSuretyQuoteData } from "./actions/suretyQuoteActions";
import { getCacheId } from "../selectors/cacheId";
import QuotingHeader from "./QuotingHeader";

const mapStateToProps = state => {
  return {
    cacheId: getCacheId(state)
  };
};

const mapDispatchToProps = {
  updateSuretyQuoteData
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotingHeader);
