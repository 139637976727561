import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useCallback
} from "react";
import { useHistory } from "react-router-dom";
import * as routes from "../constants/routes";
import * as newBusinessServices from "./services/newBusinessServices";

const NewBusinessContext = createContext({
    tableOfContentId: null,
    tableOfContents: {
        previousFetchedProductId: null,
        data: [],
        isLoading: false,
        error: null
    },
    currentHeader: {
        sequence: null,
        type: "",
        description: "",
        id: ""
    },
    content: {
        data: null,
        isLoading: false,
        error: null
    }
});

const FETCH_NEW_BUSINESS_TABLE_OF_CONTENTS =
    "FETCH_NEW_BUSINESS_TABLE_OF_CONTENTS";
const UPDATE_NEW_BUSINESS_OF_CONTENT_ID = "UPDATE_NEW_BUSINESS_OF_CONTENT_ID";
const FETCH_NEW_BUSINESS_CONTENT_STARTED = "FETCH_NEW_BUSINESS_CONTENT_STARTED";
const FETCH_NEW_BUSINESS_CONTENT_SUCCEEDED = "FETCH_NEW_BUSINESS_CONTENT_SUCCEEDED";

export const useNewBusinessContext = () => useContext(NewBusinessContext);

const newBusinessReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_NEW_BUSINESS_OF_CONTENT_ID:
            return {
                ...state,
                tableOfContentId: action.payload
            };
        case FETCH_NEW_BUSINESS_TABLE_OF_CONTENTS:
            return {
                ...state,
                tableOfContents: {
                    ...state.tableOfContents,
                    data: action.payload
                }
            };
        case FETCH_NEW_BUSINESS_CONTENT_STARTED:
            return {
                ...state,
                content: {
                    ...state.tableOfContents,
                    data: null,
                    isLoading: true,
                }
            };
        case FETCH_NEW_BUSINESS_CONTENT_SUCCEEDED:
            return {
                ...state,
                content: {
                    ...state.tableOfContents,
                    data: action.payload,
                    isLoading: false
                }
            };
        default:
            return state;
    }
};

export function NewBusinessProvider(props) {
    const { tableOfContents, content, tableOfContentId } =
        useNewBusinessContext();

    const history = useHistory();

    const [newBusinessData, updateNewBusinessData] = useReducer(
        newBusinessReducer,
        {
            tableOfContents,
            content,
            tableOfContentId
        }
    );

    const fetchTableOfContents = () => {
        newBusinessServices
            .getTableOfContents()
            .then(
                res => {
                    if (res && res.data && res.data.length > 0) {
                        updateNewBusinessData({
                            type: FETCH_NEW_BUSINESS_TABLE_OF_CONTENTS,
                            payload: res.data
                        });
                    }
                },
                () => { }
            )
            .finally(() => { });
    };

    const fetchContent = useCallback(contentId => {
        updateNewBusinessData({
            type: FETCH_NEW_BUSINESS_CONTENT_STARTED
        });

        newBusinessServices
            .getContent(contentId)
            .then(
                res => {
                    if (res && res.data) {
                        updateNewBusinessData({
                            type: FETCH_NEW_BUSINESS_CONTENT_SUCCEEDED,
                            payload: res.data
                        });
                    }
                },
                () => { }
            )
            .finally(() => { });
    }, []);

    const updateTableOfContentId = contentId => {
        updateNewBusinessData({
            type: UPDATE_NEW_BUSINESS_OF_CONTENT_ID,
            payload: contentId
        });
    };

    useEffect(() => {
        if (
            !tableOfContents.isLoading &&
            tableOfContents.data &&
            tableOfContents.data.length > 0
        ) {
            history.push(
                `${routes.NEW_BUSINESS_HEADER}/${tableOfContents.data[0].id}`
            );
        }
    }, [history, tableOfContents.data, tableOfContents.isLoading]);

    return (
        <NewBusinessContext.Provider
            value={{
                newBusinessData,
                fetchTableOfContents,
                updateTableOfContentId,
                tableOfContentId: newBusinessData.tableOfContentId,
                fetchContent
            }}
            {...props}
        >
            <div id="newBusiness">{props.children}</div>
        </NewBusinessContext.Provider>
    );
}
