import React from "react";
import cn from "classnames";
import { Icon } from "@ufginsurance/ui-kit";
import "./CheckboxButton.scss";

const CheckboxButton = ({ selected, invalid, children, ...rest }) => {
  return (
    <button
      type="button"
      className={cn("qq__checkbox-button", {
        selected,
        "qq__checkbox-button__invalid": invalid
      })}
      {...rest}
    >
      <div className="qq__checkbox-button__text">{children}</div>
      {!invalid && (
        <div className="qq__checkbox-button__checkbox">
          <Icon
            className="qq__checkbox-button__checkbox-icon"
            icon={selected === true ? "fasCheckSquare" : "fapSquare"}
            size="lg"
          />
        </div>
      )}
    </button>
  );
};

export default CheckboxButton;
