import React from "react";
import { Button, Modal } from "@ufginsurance/ui-kit";

const DeleteModal = ({
  title,
  warningMessage,
  show,
  setShowDeleteModal,
  setUpdateOwnerData,
  ownerData,
  deleteRow,
  setRow
}) => {
  const onClickDelete = () => {
    setShowDeleteModal(false);

    const updatedOwners = ownerData.reduce((newOwnersArray, a) => {
      console.log(a, deleteRow);
      if (a.id === deleteRow.id && !!a.is_new) {
        //ignore this list item
        return newOwnersArray;
      } else if (a.id === deleteRow.id) {
        //soft delete
        a.delete = true;
      }

      newOwnersArray.push(a);
      return newOwnersArray;
    }, []);

    setUpdateOwnerData(updatedOwners);
    setRow({});
  };

  return (
    <Modal
      size="sm"
      title={title}
      body={warningMessage}
      footer={
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="pull-left">
              <Button
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="col-sm-6 col-md-6">
            <div className="pull-right">
              <Button variant="tertiary" onClick={onClickDelete}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      }
      onHide={() => setShowDeleteModal(false)}
      show={show}
      altCloseMethod={false}
      closeIcon={false}
    />
  );
};
export default DeleteModal;
