import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { PanelGrid, LoadingIndicator } from "@ufginsurance/ui-kit";
import { agentManualTypes } from "../constants";
import TableOfContentPanel from "./TableOfContentPanel";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const TableOfContents = ({
  tableOfContents,
  onHeaderClick,
  onSubHeaderClick,
  onSubHeader2Click,
  areTableOfContentsLoading,
  tableOfContentsError,
  statesError,
  productsError,
  isContentLoading,
  contentElement
}) => {
  const buildTableofContentsPanel = (header, currentIndx, headers) => {
    let subHeaders = null;
    if (currentIndx === headers.length - 1) {
      subHeaders = tableOfContents.filter(t => t.sequence > header.sequence);
    } else {
      const nxtHdr = headers[currentIndx + 1];
      subHeaders = tableOfContents.filter(
        t => t.sequence > header.sequence && t.sequence < nxtHdr.sequence
      );
    }
    return (
      <TableOfContentPanel
        key={`tableOfContentsPanel_${currentIndx}`}
        header={header}
        subHeaders={subHeaders}
        onHeaderClick={onHeaderClick}
        onSubHeaderClick={onSubHeaderClick}
        onSubHeader2Click={onSubHeader2Click}
        contentElement={contentElement}
      />
    );
  };

  const headers = tableOfContents.filter(
    p => p.type === agentManualTypes.tableOfContents.header
  );

  const tableOfContentsHtmlPanels = headers.map((hdr, index) => {
    return buildTableofContentsPanel(hdr, index, headers);
  });

  return (
    <>
      {areTableOfContentsLoading && !isContentLoading && (
        <div className="row">
          <LoadingIndicator />
        </div>
      )}
      {(statesError || productsError) && (
        <div>
          <div>&nbsp;</div>
          <div className="friendly-error-messaging">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </div>
      )}
      {!tableOfContentsError && (
        <PanelGrid
          className="table-of-contents"
          sizes={{ sm: 2, md: 3, lg: 3, xl: 3 }}
        >
          {tableOfContentsHtmlPanels.map(content => {
            return content;
          })}
        </PanelGrid>
      )}
    </>
  );
};

TableOfContents.propTypes = {
  tableOfContents: PropTypes.array,
  onHeaderClick: PropTypes.func.isRequired,
  onSubHeaderClick: PropTypes.func.isRequired,
  onSubHeader2Click: PropTypes.func.isRequired,
  areTableOfContentsLoading: PropTypes.bool.isRequired,
  tableOfContentsError: PropTypes.object,
  statesError: PropTypes.object,
  productsError: PropTypes.object,
  isContentLoading: PropTypes.bool.isRequired,
  contentElement: PropTypes.element
};

export default withRouter(TableOfContents);
