import React from "react";
import { ButtonBar, Button } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";

const TabSelection = props => {
  const { tabs, onSelectTab, activeTab, isDisabled } = props;
  return (
    <div>
      <ButtonBar fullWidth size="lg">
        {tabs.map(tab => (
          <Button
            key={tab.id}
            variant={activeTab === tab.id ? "primary" : "plain"}
            onClick={() => {
              onSelectTab(tab.id);
            }}
            disabled={isDisabled}
          >
            {tab.label}
          </Button>
        ))}
      </ButtonBar>
    </div>
  );
};

TabSelection.propTypes = {
  tabs: PropTypes.array,
  onSelectTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
  isDisabled: PropTypes.bool
};

TabSelection.defaultProps = {
  isDisabled: false
};

export default TabSelection;
