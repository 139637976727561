import React, { useState } from "react";

import {
  Panel,
  LoadingIndicator,
  Button,
  Table,
  FormatDate
} from "@ufginsurance/ui-kit";
import { billingCopiesTransactionTypes } from "../../constants/dictionary.js";
import useGetBillingsByBond from "./hooks/useGetBillingsByBond";
import { fetchBondExecution } from "../shared/documents.js";

export default function BondOverviewBillingHistory({ agencyCode, bond }) {
  const [billingActivity, billingActivityLoading, billingActivityError] =
    useGetBillingsByBond(agencyCode, bond?.bond_number);
  const [bondFormsLoading, setBondFormsLoading] = useState({});

  const getBondExecutionForm = (bond_number, transaction_number) => {
    setBondFormsLoading({ ...bondFormsLoading, [transaction_number]: true });
    fetchBondExecution(
      bond_number,
      agencyCode,
      bond?.bond_type,
      transaction_number
    ).then(() => {
      const tempBondFormsLoading = { ...bondFormsLoading };
      delete tempBondFormsLoading[transaction_number];
      setBondFormsLoading(tempBondFormsLoading);
    });
  };

  const columnsBillingActivity = [
    {
      key: "entry_date",
      label: "Date",
      sortable: true,
      element: _ => {
        return <FormatDate date={_.entry_date} format="MM/DD/YYYY" />;
      }
    },
    {
      key: "transaction_code",
      label: "Activity Type",
      sortable: true,
      element: _ => {
        return <span>{billingCopiesTransactionTypes[_.transaction_code]}</span>;
      }
    },
    {
      key: "",
      label: "",
      sortable: false,
      align: "right",
      element: _ => (
        <Button
          isLink
          inline
          spinner={bondFormsLoading.hasOwnProperty(_.transaction_number)}
          size="sm"
          onClick={() =>
            getBondExecutionForm(_.bond_number, _.transaction_number)
          }
        >
          Transaction Report
        </Button>
      )
    }
  ];

  return (
    <>
      {billingActivityLoading ? (
        <LoadingIndicator />
      ) : (
        <Panel id="surety-billings-by-bond-activity" title="Billing History (Last 5 years)">
          <Table
            id="bondOverviewBillingHistoryTable"
            rowKey="transaction_number"
            columns={columnsBillingActivity}
            data={
              billingActivityError || !billingActivity ? [] : billingActivity
            }
            showPagination
            itemsPerPage={10}
            noResultsMessage="No records found"
            initialSort="entry_date"
            initialDirection="desc"
          />
        </Panel>
      )}
    </>
  );
}
