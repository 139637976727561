import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DatePicker,
  Form,
  FormGroup,
  TextArea,
  useForm,
  formatDate,
  FlexRow
} from "@ufginsurance/ui-kit";
import { parseLocation } from "parse-address";
import { useSuretyContext } from "../surety-context/SuretyContext";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { putSuretyOtherChange } from "../../components/home/surety/suretyTabService";
import ApiResponseModalBody from "../shared/ApiResponseModalBody/ApiResponseModalBody";

function OtherChange({ bond, setModalBody, producer }) {
  const { agency } = useSuretyContext();

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    change_details: "",
    effective_date: ""
  };

  const handleSubmit = ({ values }) => {
    setIsLoading(true);

    const parsedCSZ = parseLocation(
      Object.values(bond?.account_address).join(" ")
    );

    const payload = {
      agency_code: agency?.agencyCode,
      agency_name: agency.name,
      producer_code: producer.code,
      producer_name: producer.name,
      producer_email: producer.email,
      change_effective_date: values.effective_date,
      bond_number: bond.bond_number,
      account_name: bond.account_name,
      account_address: {
        address_line: bond.account_address.street,
        city: parsedCSZ.city,
        state: parsedCSZ.state,
        zip: parsedCSZ.zip
      },
      change_details: values.change_details
    };

    putSuretyOtherChange(bond.bond_number, payload)
      .then(res => {
        if (res.status === 200) {
          setSubmitSuccess(true);
        }
      })
      .catch(err => {
        console.error(err);
        setSubmitError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleSubmit
  });

  const {
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    values,
    errors,
    invalidFields,
    updateForm
  } = form;

  const handleCloseModal = () => {
    updateForm({
      values: initialValues,
      errors: {
        effective_date: [],
        change_details: []
      }
    });
    setModalBody("bondOverview");
    setSubmitSuccess(false);
    setSubmitError(false);
  };

  return (
    <>
      {submitError || submitSuccess ? (
        <ApiResponseModalBody
          isSuccess={submitSuccess}
          isError={submitError}
          message="Thank you! Your change request has been sent to the surety department. An email confirmation of this change has been sent to you."
        />
      ) : (
        <Form context={form}>
          <FormGroup>
            <DatePicker
              className=""
              id="effective_date"
              name="effective_date"
              label="Effective Date of Change"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.effective_date}
              format="YYYY-MM-DD"
              minDate={formatDate(new Date(), "MM/DD/YYYY")}
              required
              size="md"
            />
          </FormGroup>
          <FormGroup>
            <TextArea
              id="change_details"
              name="change_details"
              label="Please describe your change in detail"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.change_details}
              required
            />
          </FormGroup>
          <FlexRow align="right" className="edit-modal-buttons">
            <Button variant="plain" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!!Object.keys(errors).length || !!invalidFields.length}
              spinner={isLoading}
            >
              Submit Change
            </Button>
          </FlexRow>
        </Form>
      )}

      {(submitSuccess || submitError) && (
        <FlexRow align="right">
          <Button onClick={handleCloseModal}>Back to Bond Overview</Button>
        </FlexRow>
      )}
    </>
  );
}

OtherChange.propTypes = {
  bond: PropTypes.object.isRequired,
  setModalBody: PropTypes.func.isRequired,
  producer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  producer: {
    name: sessionSelector.getFirstAndLastName(state),
    code: sessionSelector.getAgentProducerCode(state),
    email: sessionSelector.getEmail(state)
  }
});

export default connect(mapStateToProps)(OtherChange);
