import * as types from "./actionTypes";
import * as reportService from "../services/reportAdministratorService";
import { logger } from "../../loggers";

const fetchAdminitratorStarted = () => ({
  type: types.FETCH_ADMINISTRATOR_STARTED
});

const fetchAdminitratorSucceeded = data => ({
  type: types.FETCH_ADMINISTRATOR_SUCCEEDED,
  payload: data
});

const fetchAdminitratorFailed = error => ({
  type: types.FETCH_ADMINISTRATOR_FAILED,
  payload: error
});

export const fetchAdministrator = agency_code => {
  return dispatch => {
    dispatch(fetchAdminitratorStarted());

    return reportService.getAdministrator(agency_code).then(
      res => dispatch(fetchAdminitratorSucceeded(res.data)),
      error => {
        dispatch(fetchAdminitratorFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};
