import React from "react";
import { useHistory } from "react-router-dom";
import { Button, FlexRow, Modal, Panel } from "@ufginsurance/ui-kit";
import {
  BUILDERS_RISK,
  ONLINE_QUOTING_STEP1,
  ONLINE_QUOTING_STEP1_EXISTING
} from "../../../constants/routes";
import "./ProductSelectionModal.scss";

const ProductSelectionModalOld = ({ isOpen, toggleProductSelectionModal }) => {
  const history = useHistory();
  const handleProQuoteOnClick = () => history.push(ONLINE_QUOTING_STEP1);
  const handleBuildersRiskOnClick = () => history.push(BUILDERS_RISK);
  const handleProQuoteExistingOnClick = () =>
    history.push(ONLINE_QUOTING_STEP1_EXISTING);

  return (
    <Modal
      title="Product Selection"
      containerClassName="product-selection"
      show={isOpen}
      onHide={toggleProductSelectionModal}
      body={
        <>
          <Panel rounded title="Commercial Small Business Quoting">
            <FlexRow align="right">
              <Button variant="outline" onClick={handleProQuoteExistingOnClick}>
                Existing Customer
              </Button>
              <Button variant="primary" onClick={handleProQuoteOnClick}>
                New Customer
              </Button>
            </FlexRow>
          </Panel>
          <Panel rounded title="Inland Marine Builders' Risk">
            <div>
              <p className="text-lead">Online Program Eligibility</p>
              <p className="text-bold">
                Please consult with your underwriter before submitting online if
                your Builders&apos; Risk project involves any of the following:
              </p>
              <ul className="criteria">
                <li>Frame projects greater than $1,500,000</li>
                <li>New commercial projects greater than $3,500,000</li>
                <li>Projects already started</li>
                <li>All renovation projects</li>
                <li>Projects located in PC 9-10</li>
                <li>Projects located in the State of Florida</li>
              </ul>
            </div>
            <hr />
            <FlexRow align="right">
              <Button
                variant="primary"
                size="sm"
                onClick={handleBuildersRiskOnClick}
              >
                Start a Quote
              </Button>
            </FlexRow>
          </Panel>
        </>
      }
    />
  );
};

export default ProductSelectionModalOld;
