import React from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { Table, Anchor } from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../components/Factory";
import { CLAIM_INFORMATION } from "../../constants/routes";
import { useHistory } from "react-router-dom";

const NameSearchResults = ({ policies, onPolicySelected }) => {
  const history = useHistory();

  const columns = [
    {
      key: "insured_name",
      label: "Insured Name",
      element: row => {
        const queryString = qs.stringify({ policy_number: row.policy_number });
        return (
          <div className="insured_name">
            <Anchor
              onClick={() => {
                onPolicySelected();
                history.push(`${CLAIM_INFORMATION}?${queryString}`);
              }}
              className="text-capitalize"
            >
              {row.insured_name.toLowerCase()}
            </Anchor>
          </div>
        );
      },
      sortable: true
    },
    {
      key: "insured_city",
      label: "City, State",
      element: row => (
        <span>
          {toTitleCase(row.insured_city)}, {row.insured_state}
        </span>
      )
    },
    {
      key: "policy_number",
      label: "Policy #",
      className: "policy-search__policy-number"
    },
    {
      key: "policy_description",
      label: "Dec"
    },
    {
      key: "agency_code",
      label: "Agent #"
    }
  ];

  return !policies || policies.length === 0 ? null : (
    <div className="name-search-results">
      <div className="policy-search">
        <Table
          rowKey="policy_number"
          data={policies}
          columns={columns}
          compact
          showPagination
          initialDirection="asc"
          initialSort="insured_name"
        />
      </div>
    </div>
  );
};

NameSearchResults.propTypes = {
  policies: PropTypes.array,
  onPolicySelected: PropTypes.func.isRequired
};

export default NameSearchResults;
