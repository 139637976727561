import React from "react";
import { DateFormat } from "../components/Factory";
import { Translations } from "./../components/translations";
import PropTypes from "prop-types";

const PolicyChangeCommercialVehicle = props => {
  const { policyInfo, amendDate } = props;

  const savedVehicleRedirect = () => {
    const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

    const formattedAmendDate = DateFormat(amendDate, "YYYYMMDD");
    window.open(
      `${baseLink}/AgentsOnly/ufgAgent/Changes/CommChanges/checkCommChanges.aspx?pol=${policyInfo.policyNumber}&cob=${policyInfo.company_branch}&dt=${formattedAmendDate}&saved=Y`
    );
  };

  return (
    <div className="row policy-changes-commercial">
      <b>
        {Translations.account_overview.changeRequests.pending_vehicle_change}
        <span>
          <button className="btn-as-link" onClick={savedVehicleRedirect}>
            {Translations.account_overview.changeRequests.click_here}
          </button>
        </span>
      </b>
    </div>
  );
};

PolicyChangeCommercialVehicle.propTypes = {
  policyInfo: PropTypes.object.isRequired,
  amendDate: PropTypes.string.isRequired
};

export default PolicyChangeCommercialVehicle;
