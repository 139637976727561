import * as api from "../../services/onlineQuotingService";

/*

  // https://ufginsurance.atlassian.net/wiki/spaces/OOQR2/pages/1014399288/QuotePatch+Object

patching the quote allows you to update any of these properties as needed:
{
  "quoteStatus": "Declined",
  "customerName": "Steven Stevenson",
  "firstName": "Steven",
  "lastName": "Stevenson",
  "dbaName": "Davidson Mercantile",
  "dbaFirstName": "David",
  "dbaLastName": "Davidson",
  "effectiveDate": {
    "year": 2020,
    "month": 7,
    "day": 24
  },
  "producerCode": "1511340134",
  "serviceCenterIndicator": false,
  "classCode": {
    "classCode": "12345",
    "description": "class description"
  },
  "additionalClassCodes:[
    {
      "classCode": "54321",
      "description": "another class description"
    }
  ]
}

eg: patchQuote({quoteId: 0123456789, newData: {custoemrName: "Dunder Mifflin"}})
*/

export const quotePatch = ({ newData, quoteId, callback }) => {
  if (!callback) {
    console.error("No callback received");
    return;
  }
  if (!newData && callback)
    callback({ success: false, error: "No data received" });
  if (!quoteId && callback)
    callback({ success: false, error: "No quoteId received" });

  api
    .patchQuote(quoteId, newData)
    .then(results => {
      if (callback) callback({ success: true, results, error: null });
    })
    .catch(error => {
      //TODO: need a better way to handle errors like this one accross the app.
      console.error(error);
      console.error("Error updating quote " + quoteId);
      if (callback) callback({ success: false, data: null, error });
    });
};
