import React, { useState } from "react";
import { Button, Modal } from "@ufginsurance/ui-kit";

const ConfirmationModal = ({
  title,
  warningMessage,
  show,
  onClickYes,
  onClickNo,
  onHide,
  className
}) => {
  const [showModal, setShowModal] = useState(show ? show : false);

  return (
    <Modal
      size="sm"
      title={title}
      body={<div>{warningMessage}</div>}
      className={className}
      footer={
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="pull-left">
              <Button
                onClick={() => {
                  setShowModal(false);
                  if (onClickNo) onClickNo();
                  if (onHide) onHide();
                }}
              >
                No
              </Button>
            </div>
          </div>
          <div className="col-sm-6 col-md-6">
            <div className="pull-right">
              <Button
                variant="tertiary"
                onClick={() => {
                  onClickYes();
                  setShowModal(false);
                  if (onHide) onHide();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      }
      show={showModal}
      altCloseMethod={false}
      closeIcon={false}
    />
  );
};

export default ConfirmationModal;
