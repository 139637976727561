import { connect } from "react-redux";
import HeaderUserMenu from "../components/header/HeaderUserMenu";
import {
  ssoActions,
  userSelector,
  sessionSelector
} from "@ufginsurance/sso-oidc-client-react";
import { withRouter } from "react-router";
import {
  getisSpecialtyPortalView,
  hasAccessToUfgAgentAndSpecialty
} from "../portalview/changePortalViewStateSelectors";
import {
  changeToUfgAgentPortalViewState,
  changeToMercerSpecialtyPortalViewState
} from "../portalview/changePortalViewStateAction";

const mapStateToProps = state => ({
  user: userSelector.getOpenIDUser(state),
  applicationId: sessionSelector.getApplicationId(state),
  firstName: sessionSelector.getFirstName(state),
  firstAndLastName: sessionSelector.getFirstAndLastName(state),
  agentCode: sessionSelector.getAgentCode(state),
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  agencies: sessionSelector.getAgencies(state),
  isMyProfileEnabled: sessionSelector.isMyProfileEnabled(state),
  isAgencyAdministrator: sessionSelector.isAgencyAdministrator(state),
  isEmployeeAuthentication: sessionSelector.isEmployeeAuthentication(state),
  canImpersonate: sessionSelector.canImpersonate(state),
  isImpersonatingAnotherAgent:
    sessionSelector.isImpersonatingAnotherAgent(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  hasAccessToUfgAgentAndSpecialty: hasAccessToUfgAgentAndSpecialty(state)
});

const mapDispatchToProps = {
  changeToUfgAgent: changeToUfgAgentPortalViewState,
  changeToMercer: changeToMercerSpecialtyPortalViewState,
  logoutAndRedirect: ssoActions.logoutAndRedirect,
  changeAgencyView: ssoActions.changeAgencyView,
  impersonateAgentRedirect: ssoActions.impersonateAgentRedirect
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderUserMenu)
);
