import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Table, LoadingIndicator, Modal, Anchor } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import * as dictionary from "../../constants/dictionary";
import * as routes from "../../constants/routes";
import { Translations } from "../../components/translations";
import { getForms } from "../services/documentsService";
import { printPdf } from "../../shared/documents/helpers";
import { v4 } from "uuid";
import useDebounce from "../../online-quoting/shared/useDebounce";

let abortController;

const CoverageFormsResultsGrid = ({ form, companyBranch }) => {
  const [formsList, setFormsList] = useState([]);
  const [isAxiosLoading, setIsAxiosLoading] = useState(false);
  const [previousValues, setPreviousValues] = useState({});
  const [documentsLoading, setDocumentsLoading] = useState([]);
  const [resultsGridLabel, setResultsGridLabel] = useState(
    Translations.documents_forms.results_grid_initial_label
  );
  const [showModal, setShowModal] = useState(false);

  const { values } = form;
  const debouncedValues = useDebounce(values, 500);
  const MIN_SEARCH_CHARS = 1;
  const MAX_EDITION_DATE_CHARS = 4;

  const onCloseModal = () => {
    setShowModal(false);
  };

  const padLeadingZeros = (str, max) => {
    return str ? str.padStart(max, "0") : str;
  };

  const onPrintStart = form_number => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      form_number
    )
      ? [...documentsLoading, form_number]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = form_number => {
    setDocumentsLoading(documentsLoading.filter(x => x !== form_number));
  };

  const onPrintError = () => {
    setShowModal(true);
  };

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const columns = [
    {
      key: "number",
      label: "Form Number",
      align: "left",
      sortable: true,
      searchable: true,
      noWrap: false
    },
    {
      key: "edition_date",
      label: "Edition",
      align: "left",
      sortable: true,
      element: row => {
        return padLeadingZeros(row.edition_date, MAX_EDITION_DATE_CHARS);
      }
    },
    {
      key: "name",
      label: "Form Name",
      align: "left",
      sortable: true,
      searchable: true,
      className: "document-library-form-name",
      element: row => {
        const requestInProgress =
          row.imageright_name && documentsLoading.includes(row.imageright_name);

        const form_number = `${row.imageright_name ?? ""}`;

        return (
          <div className="document-library-form-name__wrapper">
            {requestInProgress && (
              <span className="document-library-spinner">
                <LoadingIndicator message="" type="spinner" />
              </span>
            )}
            <Anchor
              href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${
                dictionary.DOCUMENT_FORM_TYPES.COVERAGE
              }/${row.imageright_name ?? ""}`}
              onClick={e => {
                e.preventDefault();

                printPdf({
                  form_number,
                  form_type: dictionary.DOCUMENT_FORM_TYPES.COVERAGE,
                  onStart: () => {
                    onPrintStart(form_number);
                  },
                  onComplete: () => {
                    onPrintComplete(form_number);
                  },
                  onError: onPrintError
                });
              }}
            >
              {row.name}
            </Anchor>
          </div>
        );
      }
    }
  ];

  const searchUsingDebounce = useCallback(
    (state, product, keyword, companyBranch) => {
      abortController?.abort();
      abortController = new AbortController();

      const options = {
        signal: abortController?.signal
      };

      setIsAxiosLoading(true);
      setFormsList([]); // Wipe out forms list to trigger loading indicator

      getForms(
        state.state_abbreviation,
        product.product_id,
        keyword,
        companyBranch,
        options
      ).then(
        res => {
          const formsWithId = res.data.map(form => {
            return {
              ...form,
              id: v4()
            };
          });

          setFormsList(formsWithId);
          setIsAxiosLoading(false);
        },
        err => {
          if (!(err?.code === "ERR_CANCELED")) {
            toast(<HelpDeskMessage display="anErrorOccurred" />, {
              position: "top-center",
              className: "error",
              autoClose: false,
              toastId: "documentLibraryFormsError"
            });
            setFormsList([]);
            setIsAxiosLoading(false);
          }
        }
      );
    },
    []
  );

  const shouldGetForms = useCallback(
    (selectedState, selectedProduct, keyword) => {
      const keywordLongEnough = keyword.length >= MIN_SEARCH_CHARS;
      const productChanged = previousValues.selectedProduct !== selectedProduct;
      const stateProductOnly =
        selectedState && selectedProduct && productChanged && !keyword;

      const stateKeywordOnly =
        selectedState && keyword && keywordLongEnough && !selectedProduct;

      return stateProductOnly || stateKeywordOnly;
    },
    [previousValues.selectedProduct]
  );

  // Fetch forms and populate table
  useEffect(() => {
    const { selectedState, selectedProduct, keyword } = debouncedValues;
    const validKeyword =
      keyword && keyword.length >= MIN_SEARCH_CHARS ? keyword : "";

    const stateOnly = selectedState && !keyword && !selectedProduct;

    const ableToRetrieveForms = shouldGetForms(
      selectedState,
      selectedProduct,
      keyword
    );

    if (ableToRetrieveForms) {
      searchUsingDebounce(
        selectedState,
        selectedProduct,
        validKeyword,
        companyBranch
      );
    } else if (stateOnly) {
      setResultsGridLabel(
        Translations.documents_forms.results_grid_initial_label
      );
      setFormsList([]);
    }

    setPreviousValues(debouncedValues);
  }, [shouldGetForms, debouncedValues, searchUsingDebounce, companyBranch]);

  useEffect(() => {
    if (
      isAxiosLoading &&
      resultsGridLabel !== Translations.documents_forms.no_results
    ) {
      setResultsGridLabel(Translations.documents_forms.no_results);
    }
  }, [isAxiosLoading, resultsGridLabel]);

  return (
    <div className="document-coverage-forms">
      {isAxiosLoading ? (
        <LoadingIndicator />
      ) : (
        <Table
          className="document-coverage-forms"
          data={formsList}
          columns={columns}
          showPagination
          rowKey="id"
          initialSort="number"
          initialDirection="asc"
          noResultsMessage={resultsGridLabel}
          searchable
          searchQuery={debouncedValues.keyword}
        />
      )}
      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </div>
  );
};

CoverageFormsResultsGrid.propTypes = {
  form: PropTypes.object.isRequired,
  companyBranch: PropTypes.string
};

export default CoverageFormsResultsGrid;
