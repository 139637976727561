import { connect } from "react-redux";
import AgencyEnrollment from "./AgencyEnrollment";
import {
  sessionSelector,
  ssoActions
} from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const mapStateToProps = state => ({
  agentCode: sessionSelector.getAgentCode(state),
  token: state?.oidc?.user?.access_token,
  isAgencyAdministrator: sessionSelector.isAgencyAdministrator(state),
  selectedAgencyCode: getSelectedAgencyCode(state)
});

const mapDispatchToProps = {
  rehydrateSession: ssoActions.rehydrateSession
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyEnrollment);
