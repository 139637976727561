import React, { useState, useEffect, useCallback, useContext } from "react";
import * as api from "../../../services/onlineQuotingService";
import cn from "classnames";
import _remove from "lodash/remove";
import _equal from "lodash/isEqual";
import { v4 } from "uuid";
import {
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  Select,
  LoadingIndicator,
  Popover,
  FlexRow,
  formatDate
} from "@ufginsurance/ui-kit";
import { toHtmlSelectOptions, addUpdateArrayItem } from "../../shared/util";
import { productKeys, lessorsRiskClasses } from "../../shared/constants";
import { classCodeFilter } from "../../shared/classCodeFilter";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { StateLongNames } from "../../../constants/dictionary";
import "../../Step2Classify.scss";

const ClassCodes = ({ onNextStep, onCancel }) => {
  const {
    quoteData,
    supportingData,
    patchQuote,
    updateSupportingDataPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [classData, setClassData] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classesLoaded, setLoaded] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [cancelSpinner, setCancelSpinner] = useState(false);

  const initialValues = {
    business_type: [],
    search: ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange, updateForm } =
    form;

  useEffect(() => {
    if (!businessTypes.length) {
      api.getBusinessTypes().then(results => {
        if (!!results?.data?.formData?.businessTypes_UFG.length) {
          setBusinessTypes(results?.data?.formData?.businessTypes_UFG);
        }
      });
    }
  }, [businessTypes]);

  // run first time after supportingData is available and if the class list is empty
  useEffect(() => {
    if (!!supportingData && !classData.length) {
      const products = !!supportingData?.policyLines.length
        ? Object.keys(productKeys).filter(p =>
            supportingData.policyLines.includes(p)
          )
        : [];

      const effectiveDate = formatDate(
        quoteData?.baseData?.periodStartDate,
        "YYYY-MM-DD"
      );
      if (products.length) {
        api
          .getClassCodesMetaData({ businessType: "", products, effectiveDate })
          .then(results => {
            if (!(results && results.data) && results.data.length === 0) {
              //alert("No class codes returns");
              return;
            }
            // set a unique id for the class list
            let data = results.data.map(d => ({
              id: v4(),
              ...d
            }));

            const primaryState =
              StateLongNames[
                supportingData?.customerInformation?.accountHolder
                  ?.primaryAddress?.state
              ];
            // filter out state-specific classes
            // https://ufginsurance.atlassian.net/browse/OOQ-9894
            if (primaryState) {
              data = data.filter(
                x =>
                  x.jurisdiction.length === 0 ||
                  x.jurisdiction.includes(primaryState)
              );
            }
            //exclude already added Addition classCodes BUT leave those in
            //tempAdditionalClassCodes in the main list of classData, until confirmed!.
            if (supportingData?.classCode?.code) {
              data = data.filter(c => c.code !== supportingData.classCode.code);
            }
            let additionalClasses = supportingData?.additionalClassCodes || [];
            if ((supportingData?.tempAdditionalClassCodes || []).length) {
              additionalClasses = additionalClasses.filter(
                y =>
                  !(supportingData?.tempAdditionalClassCodes || [])
                    .map(z => z.code)
                    .includes(y.code)
              );
            }
            if ((supportingData?.additionalClassCodes || []).length) {
              data = data.filter(
                c => !additionalClasses.map(x => x.code).includes(c.code)
              );
            }

            // remove lessors risk classes based on the supporting data flag
            // ... that is holding the value from step 2
            data = data.filter(c => {
              // is this class a lessors' risk class?
              const lessorsRiskFound = lessorsRiskClasses.find(
                cl => c.description === cl.description && c.code === cl.code
              );

              // hide all the items marked delete:
              // if lessorsFlag "Yes", then display whichever matches .isLessorsRisk "true"
              // if lessorsFlag "No", then display whichever no found or isLessorsRisk "false"
              // if not, then display the class (true)
              const l =
                lessorsRiskFound?.isLessorsRisk === "hidden"
                  ? false
                  : !!supportingData?.lessorsRiskIndicator
                  ? lessorsRiskFound?.isLessorsRisk === "true"
                  : !supportingData?.lessorsRiskIndicator
                  ? !lessorsRiskFound ||
                    lessorsRiskFound?.isLessorsRisk === "false"
                  : true;

              return l;
            });

            // update the class lists with the new data from the api
            setFilteredClasses(data);
            setClassData(data);
          })
          .catch(error => {
            //alert("Class codes metadata failure:" + error.response);
            toastErrr({
              displayMessage:
                "An error occurred.  Unable to retreive class codes.",
              action: "load class data",
              error,
              description: "api load of class data in add class modal"
            });
            console.error(
              "Class codes metadata failure:" + JSON.stringify(error)
            );
          });
      }
    }
  }, [classData.length, toastErrr, quoteData, supportingData]);

  const saveTODB = useCallback(() => {
    const combinedClasses = [
      ...(supportingData?.additionalClassCodes || []).filter(
        c => !selectedClasses.map(x => x.code).includes(c.code)
      ),
      ...selectedClasses
    ];

    const newData = {
      classCode: supportingData?.classCode,
      additionalClassCodes: combinedClasses
    };

    if (quoteData?.quoteID) {
      // use the useQuote patchQuote
      setSpinner(true);
      patchQuote({
        newData,
        quoteId: quoteData.quoteID,
        callback: ({ success, results, error }) => {
          // check the respnose to make sure that the class codes in the response match
          // what we think is to be true
          const responseDataIsCorrect = _equal(
            results.data.supportingData.additionalClassCodes,
            combinedClasses
          );

          // if the update of the classes results in what we expect, then it was a success
          // ... do a save to update the "tempAdditionalClassCodes"
          if (success && responseDataIsCorrect) {
            updateSupportingDataPromise({
              dataToMergeAndSave: {
                ...results.data.supportingData,
                tempAdditionalClassCodes: selectedClasses
              }
            }).then(() => {
              setSpinner(false);
              onNextStep();
            });
          } else {
            // we got results that we didn't expect, show an error and stop the spinner
            toastErrr({
              displayMessage: `An error occurred.  Unable to update class data.`,
              action: "update classes",
              error,
              payload: newData,
              misc: {
                additionalClassCodes:
                  results?.data?.supportingData?.additionalClassCodes
              },
              description:
                "update of classes on step 5 returned unexpected results"
            });
            setSpinner(false);
          }
        }
      });
    }
  }, [
    toastErrr,
    onNextStep,
    patchQuote,
    quoteData.quoteID,
    selectedClasses,
    supportingData,
    updateSupportingDataPromise
  ]);

  const resetForm = () => {
    const resetValues = {
      ...initialValues
    };

    updateForm({ values: resetValues });
  };

  useEffect(() => {
    const busType = (values.business_type || []).map(v => v.toLowerCase());

    const filteredData = classCodeFilter({
      data: classData,
      busType,
      searchText: values?.search,
      selectedClasses
    });

    // update table data state
    setFilteredClasses(filteredData);
  }, [classData, selectedClasses, supportingData.lessorsRiskIndicator, values]);

  const addClassCode = useCallback(
    classObj => {
      if (!classObj) return;

      classObj.primary = false;
      const _selClasses = addUpdateArrayItem(selectedClasses, classObj, "code");
      setSelectedClasses([..._selClasses]);
    },
    [selectedClasses]
  );

  const loadPreviousSaveClassCodes = useCallback(
    data => {
      (supportingData?.tempAdditionalClassCodes || []).forEach(savedCode => {
        const additionalClass = data.find(c => c.code === savedCode?.code);
        if (additionalClass) addClassCode(additionalClass);
      });
    },
    [addClassCode, supportingData]
  );

  const removeClassCode = classObj => {
    _remove(selectedClasses, b => b.code === classObj.code);
    setSelectedClasses([...selectedClasses]);

    /*
    if the class being remove is in both temp and original additions.
    remove it from both temp and originAddionitions
    */
    if (
      (supportingData?.tempAdditionalClassCodes || []).find(
        x => x.code === classObj.code
      ) &&
      (supportingData?.additionalClassCodes || []).find(
        x => x.code === classObj.code
      )
    ) {
      setSpinner(true);
      const newAdditional = (supportingData?.additionalClassCodes || []).filter(
        x => x.code !== classObj.code
      );

      const newTemp = (supportingData?.tempAdditionalClassCodes || []).filter(
        x => x.code !== classObj.code
      );
      updateSupportingDataPromise({
        dataToMergeAndSave: {
          additionalClassCodes: newAdditional,
          tempAdditionalClassCodes: newTemp
        }
      }).then(() => {
        setSpinner(false);
      });
    }
  };

  // load the selected classes from the quote data
  useEffect(() => {
    if (!classesLoaded && classData.length) {
      loadPreviousSaveClassCodes(classData);
      setLoaded(true);
    }
  }, [classData, classesLoaded, loadPreviousSaveClassCodes]);

  const addClassButton = row => {
    if (row.appetite === "red") {
      return <span className="oq_unavailable">Unavailable Online</span>;
    }
    return (
      <Button
        className="oq__button__add-class"
        wrapperClassName="oq__add-button__small"
        onClick={() => addClassCode(row)}
        size="sm"
        variant="info"
        icon={"farPlusCircle"}
        disabled={spinner}
      >
        Add
      </Button>
    );
  };

  const tooltipContent = row => {
    return row.appetite === "green"
      ? "Likely acceptable"
      : row.appetite === "yellow"
      ? "May require UW review"
      : "Not eligible for Pro-Quote";
  };

  const colorBar = row => {
    const color =
      row.appetite === "green"
        ? "oq__green"
        : row.appetite === "yellow"
        ? "oq__orange"
        : "oq__red";

    return (
      <div className="oq__appetite">
        <div className={cn("oq__appetite__color-bar", color)}></div>
      </div>
    );
  };

  const tableColumns = [
    {
      key: "code",
      label: "Class Code",
      sortable: true
    },
    {
      key: "description",
      label: "Class Description",
      className: "oq__classes__unselected__description",
      sortable: true
    },
    {
      key: "businessType_UFG",
      label: "Business Type",
      sortable: true
    },
    {
      key: "code",
      label: "Appetite",
      sortable: true,
      className: "oq__appetite__column",
      element: row => {
        return (
          <Popover
            id={`appetiteTooltip-${row.id}`}
            tooltip
            triggerContent={colorBar(row)}
            popoverContent={tooltipContent(row)}
            trigger="hover"
          />
        );
      }
    },
    {
      key: "",
      label: "",
      element: addClassButton,
      sortable: true,
      align: "right"
    }
  ];

  const removeButton = row => {
    return (
      <Button
        wrapperClassName="oq__classes__remove-btn-wrapper"
        className="oq__classes__remove-btn"
        size="sm"
        variant="tertiary"
        onClick={() => {
          removeClassCode(row);
        }}
        disabled={spinner}
      >
        Delete
      </Button>
    );
  };

  const tableSelectedClassColumns = [
    {
      key: "",
      label: "",
      sortable: true,
      className: "oq__classes__selected__type-column",
      element: row => (
        <b>{row.primary ? "Primary Class:" : "Additional Class:"}</b>
      )
    },
    {
      key: "",
      label: "",
      sortable: true,
      className: "oq__classes__selected__description",
      element: row => `${row.code} ${row.description}`
    },
    {
      key: "",
      label: "",
      sortable: true,
      element: row => `${row.businessType_UFG}`
    },
    {
      label: "",
      sortable: true,
      className: "oq__appetite__column",
      element: row => <>{colorBar(row)}</>
    },
    {
      key: "",
      label: "",
      className: "oq__classes__selected__remove",
      element: removeButton,
      align: "right"
    }
  ];

  // if we don't have data at the beginning... show the loading indicator
  if (!supportingData) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      {!classData.length && <LoadingIndicator />}
      {!!classData.length && (
        <div className="oq__classes oq__classes__in-modal">
          {selectedClasses.length > 0 && (
            <div>
              <Table
                rowKey="id"
                className="oq__classes__selected"
                columns={tableSelectedClassColumns}
                data={selectedClasses}
                showPagination={selectedClasses.length > 100}
                itemsPerPage={100}
                nowrap
              />
            </div>
          )}
          <Form className="oq__classes-form" context={form}>
            <FormGroup
              className="oq__classes__searchRow addPadUnder"
              noOuterPadding
            >
              <Input
                id="search"
                name="search"
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                label="Search Class or Description"
                onChange={handleOnChange}
                value={values.search}
                className="oq__classes__search"
                size="auto"
                maxLength={150}
              />
              <Select
                id="business_type"
                name="business_type"
                placeholder="Filter by business type..."
                label="Business Type"
                options={toHtmlSelectOptions(businessTypes)}
                className="oq__classes__bussinessType"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.business_type}
                multi
              />
              {(values.search || values.business_type.length > 0) && (
                <Button isLink onClick={resetForm} labelSpace>
                  Reset
                </Button>
              )}
            </FormGroup>
            <div>
              <Table
                rowKey="id"
                className="oq__class-table"
                columns={tableColumns}
                data={filteredClasses}
                showPagination
                itemsPerPage={10}
                noResultsMessage="No results found"
                initialSort="description"
              />
            </div>
          </Form>
        </div>
      )}
      <FlexRow align="right">
        <Button
          onClick={() => {
            setCancelSpinner(true);
            onCancel(() => {
              setCancelSpinner(false);
            });
          }}
          spinner={cancelSpinner}
          disabled={spinner || cancelSpinner}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            saveTODB();
          }}
          disabled={
            (selectedClasses || []).length === 0 || spinner || cancelSpinner
          }
          spinner={spinner}
        >
          Continue to Eligibility
        </Button>
      </FlexRow>
    </div>
  );
};

export default ClassCodes;
