import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import BillingDetails from "./BillingDetails";
import { Translations } from "../../../components/translations";
import {
  fetchBillingDetailsStatement,
  postBillingDetails
} from "../../../actions/billing";
import {
  LoadingIndicator,
  Button,
  Form,
  useForm,
  Dropdown,
  DateRange,
  formatDate,
  FormGroup,
  FlexRow,
  Currency
} from "@ufginsurance/ui-kit";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import uniqBy from "ramda/src/uniqBy";
import sortBy from "ramda/src/sortBy";
import pipe from "ramda/src/pipe";

const getPolicyNumber = prop("policy_number");

const getUniquePolicies = pipe(
  uniqBy(getPolicyNumber),
  sortBy(getPolicyNumber)
);

const isNotAgencyBilled = policies =>
  policies.filter(p => p.payment_plan !== "Agency Bill");

const getPoliciesAsOptions = pipe(
  getUniquePolicies,
  isNotAgencyBilled,
  map(p => ({
    value: p.policy_number,
    label: `${p.policy_number} - ${p.policy_type}`
  }))
);

const BillingDetailsContainerGuideWire = ({
  billingDetails,
  fetchBillingDetailsStatement,
  postBillingDetails,
  activeAgencyCode,
  accountNumber,
  policyDetails,
  accountData
}) => {
  const [isAccountDetails, setIsAccountDetails] = useState(true);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 547);
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6);

  const initialFromDate = new Date();
  initialFromDate.setDate(initialFromDate.getDate() - 365 * 1);

  const initialValues = {
    accountNumber,
    policy_number: "",
    billingDetailsContainerGuidewireRange: {
      from: initialFromDate,
      to: maxDate
    }
  };

  const unappliedAmount = !!billingDetails.unapplied_amount
    ? billingDetails.unapplied_amount
    : 0;

  const refreshBillingDetails = useCallback(
    values => {
      const formattedBeginDate = formatDate(
        values.billingDetailsContainerGuidewireRange.from,
        "YYYY-MM-DD"
      );
      const formattedEndDate = formatDate(
        values.billingDetailsContainerGuidewireRange.to,
        "YYYY-MM-DD"
      );
      fetchBillingDetailsStatement(
        values?.policy_number || accountNumber,
        activeAgencyCode,
        formattedBeginDate,
        formattedEndDate
      );
      setIsAccountDetails(!values?.policy_number);
    },
    [accountNumber, activeAgencyCode, fetchBillingDetailsStatement]
  );

  const handleSubmit = useCallback(
    ({ values }) => {
      refreshBillingDetails(values);
    },
    [refreshBillingDetails]
  );

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const accountNumberOptions = [{ value: accountNumber, label: accountNumber }];
  const policyNumbersOptions = policyDetails
    ? getPoliciesAsOptions(policyDetails)
    : [];

  useEffect(() => {
    if (accountNumber && activeAgencyCode && values) {
      refreshBillingDetails(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, activeAgencyCode]);

  const showBalance = () => {
    const hasBalance = billingDetails?.activities?.some(
      o => !isNaN(parseInt(o.balance, 10))
    );
    return (
      (isAccountDetails || accountData.accounts.origin === "ufg_core") &&
      hasBalance
    );
  };

  const printBillingDetails = () => {
    let unapplied_amount = null;
    if (!!billingDetails.unapplied_amount) {
      unapplied_amount = Number.parseFloat(
        billingDetails.unapplied_amount
      ).toFixed(2);
    } else {
      unapplied_amount = 0;
    }
    const activities = billingDetails.activities || [];
    activities.sort(function (a, b) {
      return a.activity_date > b.activity_date
        ? -1
        : a.activity_date < b.activity_date
        ? 1
        : 0;
    });
    const postBillingDetailsBody = {
      history: activities
    };
    if (values.billingDetailsContainerGuidewireRange.from) {
      postBillingDetailsBody.activity_date_from = formatDate(
        values.billingDetailsContainerGuidewireRange.from,
        "YYYY-MM-DD"
      );
    }
    if (values.billingDetailsContainerGuidewireRange.to) {
      postBillingDetailsBody.activity_date_to = formatDate(
        values.billingDetailsContainerGuidewireRange.to,
        "YYYY-MM-DD"
      );
    }
    if (unapplied_amount !== null) {
      postBillingDetailsBody.unapplied_amount = parseFloat(unapplied_amount);
    }

    postBillingDetails(postBillingDetailsBody);
  };
  const rangeValidation = ({ field, value, event }) => {
    const errors = handleOnValidate({ field, value, event });

    // date range should not exceed 18 months
    if (!!value.from && !!value.to) {
      const f = new Date(value.from);
      const t = new Date(value.to);
      if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) > 548) {
        errors.push(
          Translations.Billing_Details.Billing_Container
            .date_range_exceeds_error
        );
      }
    }
    return errors;
  };

  const isGuideWirePolicy = () => {
    if (values?.accountNumber?.value !== "" && values?.policy_number !== "") {
      if (values?.policy_number?.length === 11) {
        return true;
      }
    }
    return false;
  };

  const handleOnPolicyChange = ({ field, value }) => {
    const formattedBeginDate = formatDate(
      values?.billingDetailsContainerGuidewireRange?.from,
      "YYYY-MM-DD"
    );
    const formattedEndDate = formatDate(
      values?.billingDetailsContainerGuidewireRange?.to,
      "YYYY-MM-DD"
    );
    fetchBillingDetailsStatement(
      value || accountNumber,
      activeAgencyCode,
      formattedBeginDate,
      formattedEndDate
    );
    setIsAccountDetails(!value);
    handleOnChange({ field, value });
  };

  return (
    <>
      <Form context={form} className="billing-details-search-form">
        <FlexRow>
          <FormGroup>
            <Dropdown
              id="accountNumber"
              name="accountNumber"
              noLabel
              value={values.accountNumber}
              options={accountNumberOptions}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              disabled
              size="md"
            />
            <Dropdown
              id="policy_number"
              name="policy_number"
              noLabel
              options={policyNumbersOptions}
              onChange={handleOnPolicyChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.policy_number}
              isClearable
              size="md"
            />
          </FormGroup>
          <div className="align-right">
            <Button
              variant="primary"
              disabled={!billingDetails.activities}
              onClick={printBillingDetails}
            >
              PRINT
            </Button>
          </div>
        </FlexRow>
        <FlexRow>
          {isGuideWirePolicy() && (
            <FormGroup>
              <DateRange
                id="billingDetailsContainerGuidewireRange"
                name="billingDetailsContainerGuidewireRange"
                labelFrom={
                  Translations.Billing_Details.Billing_Container.label.beginDate
                }
                labelTo={
                  Translations.Billing_Details.Billing_Container.label.endDate
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={rangeValidation}
                value={values.billingDetailsContainerGuidewireRange}
                format="MM/DD/YYYY"
                required
                minDate={minDate}
                maxDate={maxDate}
              />
              <Button type="submit" variant="primary" labelSpace>
                Submit
              </Button>
            </FormGroup>
          )}
          <FlexRow className="align-right">
            {!!billingDetails.activities && (
              <span>
                Unapplied Amount:
                <Currency amount={unappliedAmount} />
              </span>
            )}
          </FlexRow>
        </FlexRow>
      </Form>

      <div className="billing-details-table">
        {billingDetails.isLoading ? (
          <LoadingIndicator />
        ) : billingDetails?.activities?.length > 0 ? (
          <BillingDetails
            activities={billingDetails.activities}
            showBalance={showBalance()}
          />
        ) : (
          <p>
            {!values?.policy_number
              ? Translations.validators.billing_table_error_account
              : Translations.validators.billing_table_error_policy}
          </p>
        )}
      </div>
    </>
  );
};

BillingDetailsContainerGuideWire.propTypes = {
  billingDetails: PropTypes.any.isRequired,
  fetchBillingDetailsStatement: PropTypes.func.isRequired,
  postBillingDetails: PropTypes.func.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  policyDetails: PropTypes.array,
  accountData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  billingDetails: state.billingDetails,
  accountData: state.accountData,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  fetchBillingDetailsStatement,
  postBillingDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailsContainerGuideWire);
