import React, { useContext } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Panel, Currency } from "@ufginsurance/ui-kit";
import { productKeys, cyberDisabledStates } from "../../shared/constants.js";
import { proQuoteProductsAvail } from "../../shared/productMatrix";
import CreditsDebits from "../CreditsDebits/CreditsDebits";
import LobItem from "./LobItem";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import Step6Context from "../Step6Context.js";

import "./LobPremiumTable.scss";

// TODO REFACTOR THESE CONSTANTS INTO THE PRODUCT KEYS (or at least into the constants.js file)
const proQuoteProducts = [
  "bp7BusinessOwners",
  "internetSecurity",
  "ca7CommAuto",
  "wcmWorkersComp",
  "cuCommercialUmbrella"
];

const alwaysEligibleProducts = ["internetSecurity", "cuCommercialUmbrella"];

const LobPremiumTable = ({ umbrellaDisabled }) => {
  const { quoteData, supportingData, get } = useContext(OnlineQuotingContext);
  const { proQuoteCreditsDebitsOnStep6 } = useFlags();
  const { productMatrixData } = useContext(Step6Context);

  const primaryLocation = get.location({ isPrimary: true });
  const selectedProducts = supportingData?.policyLines || [];

  const avilailableProducts = proQuoteProductsAvail({
    productMatrix: productMatrixData,
    effectiveDate: supportingData.effectiveDate
  });

  const productEligibleToAdd = lob => {
    if (alwaysEligibleProducts?.includes(lob)) {
      /**
       * FLORIDA CYBER
       * hide cyber if it's Florida
       * https://ufginsurance.atlassian.net/browse/OOQ-14909
       */
      const isBlockedByState =
        lob === "internetSecurity" &&
        cyberDisabledStates.includes(primaryLocation?.address?.state);
      return !isBlockedByState;
    }

    const isActive = avilailableProducts?.[lob]?.productAvailable;
    const hasNoConflicts = !selectedProducts?.some(p =>
      avilailableProducts?.[lob]?.conflicts?.includes(p)
    );

    // TODO: Maybe some point this should also evalute `required` other lobs in addition to the conflicts
    return isActive && hasNoConflicts;
  };

  if (!productMatrixData) return null;

  return (
    <Panel
      className="oq__premium__summary__lob-premiums-panel"
      rounded
      title="Premium Summary"
      id="PremiumSummary"
    >
      <div className="oq__premium__summary__lob__content">
        <table className="oq__premium__summary__lob-premiums-table">
          <thead className="sr-only" aria-hidden="false">
            <tr>
              <th>Product</th>
              <th>Credits & Debits</th>
              <th>Product Premium Amount</th>
              <th>Removable</th>
            </tr>
          </thead>
          <tbody>
            {proQuoteProducts.map(lob => {
              if (productEligibleToAdd(lob))
                return (
                  <LobItem
                    key={lob}
                    lob={lob}
                    product={productKeys[lob]}
                    umbrellaDisabled={umbrellaDisabled}
                  />
                );
              return null;
            })}

            <tr className="oq__premium__summary__lob__content__item">
              <td
                className="oq__premium__summary__lob__content__item-summary"
                colSpan="2"
              >
                <b>Total Premium</b>
              </td>
              <td className="oq__premium__summary__lob__content__item-total">
                <b>
                  <Currency
                    amount={
                      quoteData?.quoteData?.offeredQuotes?.[0]?.premium?.total
                        ?.amount || 0
                    }
                    includeDecimals={false}
                  />
                </b>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        {proQuoteCreditsDebitsOnStep6 && <CreditsDebits showAsLink />}
      </div>
    </Panel>
  );
};

export default LobPremiumTable;
