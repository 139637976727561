import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const classAppetiteGuideReducer = (
  state = initialState.classAppetiteGuide,
  action
) => {
  switch (action.type) {
    case types.FETCH_CLASS_APPETITE_STATES_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_STATES_SUCCEEDED:
      return {
        ...state,
        stateList: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_STATES_FAILED:
      return {
        ...state,
        stateList: [],
        isLoading: false,
        error: action.payload
      };

    case types.FETCH_CLASS_APPETITE_PRODUCTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        productList: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_PRODUCTS_FAILED:
      return {
        ...state,
        productList: [],
        isLoading: false,
        error: action.payload
      };

    case types.FETCH_CLASS_APPETITE_CLASSES_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED:
      return {
        ...state,
        classifications: action.payload,
        selectedClassification:
          action.payload.length > 0 ? action.payload[0] : null,
        isLoading: false,
        error: null
      };
    case types.FETCH_CLASS_APPETITE_CLASSES_FAILED:
      return {
        ...state,
        classifications: [],
        isLoading: false,
        error: action.payload
      };
    case types.CLASS_APPETITE_SET_SELECTED_CLASSIFICATION:
      return {
        ...state,
        selectedClassification: action.payload
      };
    default:
      return state;
  }
};

export default classAppetiteGuideReducer;
