import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";
import qs from "query-string";
import { toast } from "react-toastify";
import ExitQuoteModal from "./shared/ExitQuoteModal";
import "./OqTimeoutPopup.scss";

let oq_session_interval;

const OqTimeoutPopup = ({ history, OQ_container_ref }) => {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showExitQuoteModal, setShowExitQuoteModal] = useState(false);

  // a state used to display when the modal will appear when testing
  const [testingTimer, setTestingTimer] = useState();

  // if the user inputs a custom time in the querystring (eg: ?oqtimeout=5) then create a state with that value so it's used across renders
  const oqtimeout = useMemo(
    () => qs.parse(history.location.search)?.oqtimeout,
    [history.location.search]
  );
  const [customTimeout] = useState(
    oqtimeout && !isNaN(oqtimeout) && Number(oqtimeout) * 1000
  );

  // the actual time value used in the "setSessionIntervalChecker"
  const timeToTimeout = customTimeout || 1200000; // 10 seconds  1200000 = 20 minutes

  // ---------------------------------------------------------------
  // display toast for the testers using the custom timeout variable
  // ---------------------------------------------------------------
  useEffect(() => {
    if (customTimeout) {
      toast.dismiss();
      toast(
        <div>
          Custom timeout in use.{" "}
          {!!testingTimer && `Session timeout to occur at ${testingTimer}. `}
          To stop the override, remove the querystring and reload the page.
        </div>,
        {
          position: "top-center",
          className: "warning",
          autoClose: false,
          toastId: "oq__custom-timeout"
        }
      );
    }
  });

  // ---- funciton used to kick off the timer ---
  const startTheTimer = useCallback(() => {
    clearInterval(oq_session_interval);

    if (!!document.querySelector("#OQ")) {
      const sessionStartTime = new Date().getTime();

      // for testing -- update the state that displays when the modal will appear
      if (customTimeout) {
        setTestingTimer(
          new Date(sessionStartTime + customTimeout).toLocaleString("en-US", {
            timeZone: "America/Chicago"
          })
        );
      }

      oq_session_interval = setInterval(() => {
        const now = new Date().getTime();

        if (now - sessionStartTime > timeToTimeout) {
          setShowTimeoutModal(true);
        }
      }, 5000);
    }
  }, [customTimeout, timeToTimeout]);

  useEffect(() => {
    if (
      OQ_container_ref.current &&
      OQ_container_ref.current.getAttribute("listener") !== "true"
    ) {
      OQ_container_ref.current.setAttribute("listener", "true");
      // we have to set the listener on the body in order for it to capture clicks on modals
      document.body.addEventListener("mouseup", startTheTimer);
    }
    // start the timer -- only start it if it isn't already running
    if (!oq_session_interval) {
      startTheTimer();
    }

    // clean up... this runs when the component is unloaded...
    // this happens when the user leaves the Online Quoting component (like when they exit the quote)
    return () => {
      clearInterval(oq_session_interval);
      document.body.removeEventListener("mouseup", startTheTimer);
    };
  }, [OQ_container_ref, startTheTimer]);

  const handleContinue = () => {
    // restart a new timer
    startTheTimer();

    // hide the modal
    setShowTimeoutModal(false);
  };

  const handleExitQuote = () => {
    setShowExitQuoteModal(true);
  };

  return (
    <>
      {showExitQuoteModal && (
        <ExitQuoteModal
          history={history}
          setShowExitQuoteModal={setShowExitQuoteModal}
        />
      )}
      {showTimeoutModal && !showExitQuoteModal && (
        <Modal
          className="oq__modal oq__ux-timeout__modal"
          title="Session Paused"
          closeIcon={false}
          size="md"
          containerClassName="oq__ux-timeout"
          body={
            <div className="oq__ux-timeout">
              <p>
                Your data has been saved and we have paused your session. Please
                select continue to finish your quote.
              </p>
              <FlexRow>
                <Button isLink variant="tertiary" onClick={handleExitQuote}>
                  Exit Quote
                </Button>
                <Button
                  wrapperClassName="align-right"
                  variant="primary"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </FlexRow>
            </div>
          }
          show
          altCloseMethod={false}
          onHide={() => {}}
        />
      )}
    </>
  );
};
export default OqTimeoutPopup;
