import * as types from "../constants/actionTypes";

export const initialState = {
  show: false,
  accountBilled: [],
  policyBilled: [],
  accountBalance: 0,
  accountMinimumDue: 0,
  automaticPayments: false,
  isLoading: false,
  error: null,
  finalAudit: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BILLING_ACTIVITY_STARTED:
      return {
        ...initialState,
        isLoading: true,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        accountBilled: action.payload.account_billed,
        policyBilled: action.payload.policy_billed,
        accountBalance: action.payload.account_billed_balance,
        accountMinimumDue: action.payload.account_minimum_due,
        accountMinimumDueWithFees: action.payload.account_minimum_due_with_fees,
        automaticPayments: action.payload.automatic_payment_enrolled,
        accountIsPayable: action.payload.account_is_payable,
        finalAudit: action.payload.final_audit,
        isLoading: false,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
