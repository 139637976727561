import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, FlexRow, Modal } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";
import HeaderTop from "../../components/header/HeaderTop";
import "./PolicyChangesHeader.scss";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { toast } from "react-toastify";
import { usePolicyChangesContext } from "./context";
import { withdrawPolicyDraft } from "../redux/policyChangesService";

function PolicyChangesHeader(props) {
  const { showStep2Loader } = props;
  const { policyData } = usePolicyChangesContext();
  const { policyInfo } = policyData;

  const showErrorMessage = !policyInfo;

  const [showMessageModal, setShowMessageModal] = useState(false);

  const accountOverviewUrl = `${routes.ACCOUNT_HOME}/${policyInfo?.policy_number}/${routes.ACCOUNT_OVERVIEW_CHANGE_REQUESTS_ACTIVE_TAB}`;

  const history = useHistory();

  const exitPolicyChanges = () => {
    setShowMessageModal(false);
    history.push(accountOverviewUrl);
  };

  const onDeleteAndExitClick = async () => {
    withdrawPolicyDraft(policyInfo.policy_number, policyInfo.job_id)
      .then(res => {
        setShowMessageModal(false);
        if (res.status === 200) {
          history.replace(accountOverviewUrl);
        } else {
          const options = {
            position: "top-center",
            className: "error",
            autoClose: true
          };

          toast(<HelpDeskMessage display="anErrorOccurred" />, options);
        }
      })
      .catch(_err => {
        const options = {
          position: "top-center",
          className: "error",
          autoClose: false
        };
        toast(<HelpDeskMessage display="anErrorOccurred" />, options);
      });
  };

  return (
    <header className="header">
      <HeaderTop {...props} />
      <div className="policy-changes-header">
        {!showErrorMessage && !showStep2Loader && (
          <div className="secondary-nav">
            <Button
              isLink
              inline
              className="exit-link"
              icon="farAngleLeft"
              onClick={() => setShowMessageModal(true)}
            >
              EXIT CHANGE
            </Button>
          </div>
        )}

        <Modal
          title="Exit Confirmation"
          className="policy-changes-header-modal"
          body={"Are you sure you want to exit this change request?"}
          footer={
            <FlexRow align="left" className={"policy-changes-header-footer"}>
              <div className="policy-changes-header-footer-left">
                <Button
                  wrapperClassName="modal-cancel"
                  isLink
                  inline
                  variant={"tertiary"}
                  onClick={onDeleteAndExitClick}
                >
                  Delete Change and Exit
                </Button>
              </div>

              <div className="policy-changes-header-footer-right">
                <Button
                  wrapperClassName="modal-cancel"
                  inline
                  onClick={() => setShowMessageModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  wrapperClassName="modal-exit"
                  variant="primary"
                  onClick={exitPolicyChanges}
                >
                  Save Change and Exit
                </Button>
              </div>
            </FlexRow>
          }
          show={showMessageModal}
          onHide={() => setShowMessageModal(false)}
          altCloseMethod={false}
          size="md"
        />
      </div>
    </header>
  );
}

export default PolicyChangesHeader;
