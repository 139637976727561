import { useRouteMatch } from "react-router-dom";
import * as routes from "../../constants/routes";

export const useBaseRouteMatch = () => {
  return useRouteMatch(routes.NEW_BUSINESS);
};

export const useOnlyBaseRoute = () => {
  const headerMatch = useRouteMatch(routes.NEW_BUSINESS_HEADER)?.isExact;
  const subheaderMatch = useRouteMatch(
    routes.NEW_BUSINESS_SUB_HEADER
  )?.isExact;

  return !headerMatch && !subheaderMatch;
};

export const useHeaderRouteMatch = () => {
  return useRouteMatch(routes.NEW_BUSINESS_HEADER);
};

export const useSubheaderRouteMatch = () => {
  return useRouteMatch(routes.NEW_BUSINESS_SUB_HEADER);
};

export const useCurrentRouteMatch = () => {
  const baseRouteMatch = useBaseRouteMatch();
  const headerRouteMatch = useHeaderRouteMatch();
  const subheaderRouteMatch = useSubheaderRouteMatch();

  const currentMatch = (subheaderRouteMatch?.isExact && subheaderRouteMatch) ||
    (headerRouteMatch?.isExact && headerRouteMatch) ||
    (baseRouteMatch?.isExact && baseRouteMatch);

  // this returns the match object
  return currentMatch;
};
