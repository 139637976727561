// TODO: separate and move the CreditsDebits components into a new CreditsDebits subfolder for better organization
import React from "react";
import { Button, ButtonBar, FormGroup, Select } from "@ufginsurance/ui-kit";

const defaultComment = /UW approved.+(credit|debit)/;
// if is a workComp line, then value is decimal number, otherwise is whole number
const availableCredits = ({ isWorkComp }) => {
  let arrValues = [];
  if (isWorkComp) {
    arrValues = [...Array(11).keys()].slice(1);
  } else {
    arrValues = [...Array(26).keys()].slice(1);
  }
  return arrValues.map(x => ({
    label: String(x) + "%",
    value: isWorkComp ? String(x * 0.01) : String(x)
  }));
};

const CreditDebitFields = ({ lob, form, editMode }) => {
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const updateActionValue = (newValue, prevValue) => {
    if (values[lob + "Action"] === newValue) {
      const _values = {
        [lob + "Action"]: prevValue
      };

      if (
        editMode &&
        defaultComment.test(String(values[lob + "Comment"] || ""))
      )
        _values[lob + "Comment"] = "";
      updateForm({ values: _values });
    }
  };

  const isWorkComp = lob === "wcmWorkersComp";

  return (
    <FormGroup>
      <ButtonBar id={lob + "Action"} name={lob + "Action"}>
        <Button
          variant={values[lob + "Action"] === "credit" ? "primary" : undefined}
          onClick={() => updateActionValue("debit", "credit")}
        >
          Add Credit
        </Button>
        <Button
          variant={values[lob + "Action"] === "debit" ? "primary" : undefined}
          onClick={() => updateActionValue("credit", "debit")}
        >
          Add Debit
        </Button>
      </ButtonBar>
      <Select
        label="Amount"
        placeholder="Select"
        id={lob + "Amount"}
        name={lob + "Amount"}
        onChange={({ field, value }) => {
          if (
            editMode &&
            defaultComment.test(String(values[lob + "Comment"] || ""))
          ) {
            updateForm({
              values: {
                [lob + "Comment"]: ""
              }
            });
          }
          handleOnChange({ field, value });
        }}
        onBlur={handleOnBlur}
        onValidate={handleOnValidate}
        value={values[lob + "Amount"]}
        options={availableCredits({ isWorkComp }) || []}
        className="oq__credit-amount-dropdown"
        isClearable={false}
        required
      />
    </FormGroup>
  );
};

export default CreditDebitFields;
