import React from "react";
import { Translations } from "../../translations";

const BillingActivityAccordionHeader = () => {
  const { Billing_Details } = Translations;
  const { Billing_Container } = Billing_Details;

  return (
    <div className="panel-text">
      <span className="panel-title-text">
        {Billing_Container.Account_Activity}
      </span>
    </div>
  );
};

BillingActivityAccordionHeader.propTypes = {};

export default BillingActivityAccordionHeader;
