import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  cancelQuote,
  dismissQuoteDetailsError,
  fetchPrimaryRateStates,
  fetchProducers,
  goToPreviousStep,
  saveQuoteDetailsStep
} from "../actions/newQuoteActions";
import QuoteDetails from "./QuoteDetails";

const mapStateToProps = state => ({
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  error: state.newQuote.steps.quoteDetails.error,
  hasPreviousSteps: state.newQuote.previousSteps.length > 0,
  isPilotAgent: sessionSelector.isPilotAgent(state),
  producerNumber: sessionSelector.getAgentProducerCode(state),
  primaryRateStates: state.newQuote.steps.quoteDetails.primaryRateStates,
  producers: state.newQuote.steps.quoteDetails.producers,
  initialFormValues: state.newQuote.steps.quoteDetails.form
});

const mapDispatchToProps = {
  fetchPrimaryRateStates,
  fetchProducers,
  onClose: cancelQuote,
  onDismissError: dismissQuoteDetailsError,
  onReset: goToPreviousStep,
  onSubmit: saveQuoteDetailsStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteDetails);
