import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  Button,
  FlexRow,
  Form,
  InputSearch,
  LoadingIndicator,
  Table
} from "@ufginsurance/ui-kit";
import { v4 } from "uuid";
import useDebounce from "../../../../online-quoting/shared/useDebounce";
import { getAccountSearch } from "../../../../services/onlineQuotingService";
import { toTitleCase } from "../../../../components/Factory";
import { logger } from "../../../../loggers";
import AddNewCustomerButtonTable from "./AddNewCustomerButtonTable";
import "./AccountSearchTable.scss";
import {
  existingImbrCustomerInitialValues,
  initialImbrCustomerValues
} from "../../lib/form-utils";

export default function AccountSearchTable({
  form,
  setView,
  setExistingCustomer
}) {
  const abortController = useRef();
  const cache = useRef({});
  const [selectedRow, setSelectedRow] = useState();
  const [accountSearch, updateAccountSearch] = useReducer(
    (a, b) => ({
      ...a,
      ...b
    }),
    {
      isSearching: false,
      accounts: null
    }
  );

  const { handleOnChange, handleOnBlur, handleOnValidate, values, updateForm } =
    form;

  const formatAccounts = accounts => {
    if (!accounts) return [];

    return accounts.map(a => {
      const name = !!a?.accountHolder?.contactName
        ? a?.accountHolder?.contactName
        : `${a?.accountHolder?.lastName} ${a?.accountHolder?.firstName}`;

      const { primaryAddress } = a?.accountHolder;

      const primary_address = `${toTitleCase(
        primaryAddress?.addressLine1 || ""
      )} ${toTitleCase(primaryAddress?.city || "")} ${primaryAddress?.state}`;

      return {
        ...a,
        account_name: toTitleCase(name, ["LLC"]),
        account_address: primary_address,
        id: v4()
      };
    });
  };

  const query = useDebounce(values?.search || "", 500);

  useEffect(() => {
    if (query && query.length > 2) {
      abortController.current = new AbortController();
      updateAccountSearch({
        isSearching: true,
        accounts: null
      });

      if (!!cache.current?.[query]) {
        updateAccountSearch({
          accounts: formatAccounts(cache.current[query]),
          isSearching: false
        });
      } else {
        (async () => {
          try {
            const res = await getAccountSearch(
              {
                searchText: encodeURIComponent(query)
              },
              {
                signal: abortController.current?.signal
              }
            );
            if (res.status === 200 && res.data) {
              const accounts = formatAccounts(res.data);
              cache.current[query] = accounts;

              updateAccountSearch({
                accounts
              });
            }
          } catch (error) {
            logger.error({
              description: error.toString(),
              fatal: false
            });
          } finally {
            abortController.current = null;
            updateAccountSearch({
              isSearching: false
            });
          }
        })(query);
      }
    }
    return () => abortController.current?.abort();
  }, [query]);

  const columns = [
    { key: "account_name", label: "Account Name" },
    { key: "account_address", label: "Account Address" },
    { key: "account_number", label: null, element: null },
    { key: "id", label: null, element: () => {} }
  ];

  const handleOnSelectionChange = row => {
    setSelectedRow(row[0]);
  };

  const handleOnContinue = () => {
    updateForm({
      values: {
        ...existingImbrCustomerInitialValues(selectedRow)
      }
    });
    setExistingCustomer(selectedRow);
    setView("newCustomer");
  };

  const handleOnPrevious = () => {
    updateAccountSearch({
      isSearching: false,
      accounts: null
    });
    updateForm({
      values: initialImbrCustomerValues
    });
    setView("main");
  };

  return (
    <div className="existing-customer-search">
      <Form context={form}>
        <InputSearch
          id="search"
          name="search"
          label="Search"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.search}
        />
      </Form>
      {accountSearch?.isSearching && (
        <LoadingIndicator
          className="mb-1"
          color="blue"
          message="Searching accounts..."
        />
      )}
      <Table
        id="accountSearchResults"
        rowKey="id"
        columns={columns}
        data={accountSearch?.accounts || []}
        itemsPerPage={5}
        showPagination={accountSearch?.accounts?.length > 5}
        selectable
        onSelectionsChange={handleOnSelectionChange}
        selectableSingle={{
          rowAriaLabelColumn: "account_name"
        }}
      />
      <AddNewCustomerButtonTable
        setExistingCustomer={setExistingCustomer}
        setView={setView}
      />
      <FlexRow align="justify">
        <Button type="button" onClick={handleOnPrevious}>
          Previous
        </Button>
        <Button
          type="button"
          onClick={handleOnContinue}
          variant="primary"
          disabled={!selectedRow}
        >
          Use Selected Account
        </Button>
      </FlexRow>
    </div>
  );
}
