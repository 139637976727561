import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tabs, Tab } from "@ufginsurance/ui-kit";
import CommercialTabContainer from "./commercial/CommercialTabContainer";
import PersonalTabContainer from "./personal/PersonalTabContainer";
import BillingTab from "../../containers/HomeBillingContainer";
import ClaimsTabContainer from "./claims/ClaimsTabContainer";
import ServiceCenterContainer from "./service-center/ServiceCenterContainer";
import {
  HOME_COMMERCIAL_ACTIVE_TAB,
  HOME_PERSONAL_ACTIVE_TAB,
  HOME_BILLING_ACTIVE_TAB,
  HOME_CLAIMS_ACTIVE_TAB,
  HOME_SERVICE_CENTER_ACTIVE_TAB,
  HOME_ROOT_FRAGMENT
} from "../../constants/routes";
import { Translations } from "../../components/translations";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const getActiveTab = (tabs, passedInTab = "") => {
  const activeTabIndex = tabs.findIndex(
    x => x.path.toUpperCase() === passedInTab.toUpperCase()
  );
  return activeTabIndex >= 0 ? activeTabIndex : 0;
};

const Home = ({
  has_personal_lines_contract,
  has_commercial_lines_contract,
  hasServiceCenterAccess,
  history,
  match,
  isSpecialtyPortalView
}) => {
  const passedInTab = match.params.active_tab;
  const tabs = [
    {
      show: has_commercial_lines_contract,
      title: Translations.home.commercial_tab,
      component: <CommercialTabContainer />,
      path: HOME_COMMERCIAL_ACTIVE_TAB
    },
    {
      show: has_personal_lines_contract && !isSpecialtyPortalView,
      title: Translations.home.personal_tab,
      component: <PersonalTabContainer />,
      path: HOME_PERSONAL_ACTIVE_TAB
    },
    {
      show: !isSpecialtyPortalView,
      title: Translations.home.billing_tab,
      component: <BillingTab />,
      path: HOME_BILLING_ACTIVE_TAB
    },
    {
      show: true,
      title: Translations.home.claims_tab,
      component: <ClaimsTabContainer />,
      path: HOME_CLAIMS_ACTIVE_TAB
    },
    {
      show: hasServiceCenterAccess && !isSpecialtyPortalView,
      title: Translations.home.service_center_tab,
      component: <ServiceCenterContainer />,
      path: HOME_SERVICE_CENTER_ACTIVE_TAB
    }
  ];

  const visibleTabs = tabs.filter(x => x.show);
  const activeTabIndex = getActiveTab(visibleTabs, passedInTab);

  const onClickTab = tabIndex => {
    const activePath = visibleTabs[tabIndex].path;
    history.push(`${HOME_ROOT_FRAGMENT}/${activePath}`);
  };

  return (
    <div>
      <div className="home-container">
        <Tabs activeTab={activeTabIndex}>
          {visibleTabs.map(tab => (
            <Tab
              key={tab.title}
              id={`home-tab-${tab.title.toLowerCase().replace(" ", "-")}`}
              title={tab.title}
              onClick={onClickTab}
            >
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

Home.propTypes = {
  has_personal_lines_contract: PropTypes.bool.isRequired,
  has_commercial_lines_contract: PropTypes.bool.isRequired,
  hasServiceCenterAccess: PropTypes.bool.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  isSpecialtyPortalView: PropTypes.bool
};

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

export default connect(mapStateToProps)(Home);
