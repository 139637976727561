import React from "react";
import { SidebarNav } from "../../components/menus/SidebarNav";
import { connect } from "react-redux";
import { Translations } from "../../components/translations";
import PropTypes from "prop-types";
import * as routes from "../../constants/routes";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const YourUfgTeamNav = ({
  departmentData,
  departmentError,
  areDepartmentsLoading
}) => {
  const sideBarNavItems =
    departmentData?.map(item => ({
      text: item.name,
      activeClassName: "nav-menu--active",
      to: `${routes.YOUR_UFG_TEAM_ROOT}/${item.id}`
    })) || [];
  return areDepartmentsLoading ? (
    <nav className="nav-menu">
      <div className="title">
        <h3>{Translations.side_bar.ufg_staff}</h3>
      </div>
      <div>
        <LoadingIndicator />
      </div>
    </nav>
  ) : departmentError ? (
    <nav className="nav-menu">
      <div className="title">
        <h3>{Translations.side_bar.ufg_staff}</h3>
      </div>
      <div className="friendly-error-messaging">
        <HelpDeskMessage display="anErrorOccurred" />
      </div>
    </nav>
  ) : (
    <SidebarNav
      links={sideBarNavItems}
      header={Translations.side_bar.ufg_staff}
    />
  );
};

YourUfgTeamNav.propTypes = {
  departmentData: PropTypes.array,
  departmentError: PropTypes.object,
  areDepartmentsLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  departmentData: state.yourUfgTeamReducer.departmentData,
  departmentError: state.yourUfgTeamReducer.departmentError,
  areDepartmentsLoading: state.yourUfgTeamReducer.departmentLoading
});

export default connect(mapStateToProps)(YourUfgTeamNav);
