import { connect } from "react-redux";
import BondInformation from "./BondInfo";
import {
  fetchSuretyBondForm
} from "../actions/BondInfoAction";
import {
  fetchBondObligeeList,
  setObligeeSearchIsLoading
} from "../actions/quickBondObligeeAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { updateSuretyQuoteData } from "../actions/suretyQuoteActions";
import { getCacheId } from "../../selectors/cacheId";
import { fetchSuretyQuoteAgencyData } from "../actions/suretyQuoteAgencyDataAction";

const mapStateToProps = (state, ownProps) => {
  return {
    quoteData: ownProps.quoteData,
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    suretyBondFormListData: state.suretyBondFormList,
    quickBondObligeeList: state.quickBondObligeeList,
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state),
    agencyStates: state.suretyQuoteAgencyData?.data?.licensed_states
  };
};
const mapDispatchToProps = {
  fetchSuretyBondForm,
  fetchBondObligeeList,
  updateSuretyQuoteData,
  setObligeeSearchIsLoading,
  fetchSuretyQuoteAgencyData
};

export default connect(mapStateToProps, mapDispatchToProps)(BondInformation);
