import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AgencyLossRunForm from "./AgencyLossRunForm";
import { postAgencyLossReport } from "../../../services/claimsReportsService";
import { Translations } from "../../../../components/translations";
import { Modal } from "@ufginsurance/ui-kit";

const AgencyLossRun = props => {
  const {
    selectedAgencyCode,
    fetchEmailListDetails,
    fetchAdministrator,
    emailList,
    is109015Agent,
    hasAgencyClaimReportsAccess,
    administrator
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleSubmit = (values, formReset) => {
    postAgencyLossReport(values, selectedAgencyCode).then(
      () => {
        setShowModal(true);
        setSubmitFailed(false);
        formReset();
      },
      () => {
        setShowModal(false);
        setSubmitFailed(true);
      }
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchEmailListDetails(selectedAgencyCode);
    fetchAdministrator(selectedAgencyCode);
  }, [fetchAdministrator, fetchEmailListDetails, selectedAgencyCode]);

  return hasAgencyClaimReportsAccess ? (
    <div>
      <AgencyLossRunForm
        emailList={emailList}
        selectedAgencyCode={selectedAgencyCode}
        is109015Agent={is109015Agent}
        handleSubmit={handleSubmit}
        administratorName={administrator.name}
      />
      <Modal
        title="Your Request Has Been Submitted"
        body={
          <div className="agency-loss-run">The report will be emailed.</div>
        }
        show={showModal}
        onHide={handleClose}
      />
      {submitFailed && <span>Failed to retrieve loss runs</span>}
    </div>
  ) : (
    Translations.reports.error_message
  );
};

AgencyLossRun.propTypes = {
  selectedAgencyCode: PropTypes.string,
  emailList: PropTypes.array,
  fetchEmailListDetails: PropTypes.func,
  is109015Agent: PropTypes.bool,
  hasAgencyClaimReportsAccess: PropTypes.bool,
  administrator: PropTypes.object,
  fetchAdministrator: PropTypes.func
};

export default AgencyLossRun;
