import * as types from "../uwReviewActionTypes";
import initialState from "../../reducers/initialState";

const UWReviewAccountInfoReducer = (
  state = initialState.quickUnderWriterReviewAccountInfo,
  action
) => {
  switch (action.type) {
    case types.FETCH_UW_REVIEW_ACCOUNT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_UW_REVIEW_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        uwAccountInfoData: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_UW_REVIEW_ACCOUNT_FAILED:
      return {
        ...state,
        uwAccountInfoData: [],
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default UWReviewAccountInfoReducer;
