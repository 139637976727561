import React from "react";
import { Popover, Button } from "@ufginsurance/ui-kit";
import { useFlags } from "launchdarkly-react-client-sdk";

import "./ProductIsNewTooltip.scss";

const ProductIsNewTooltip = ({
  locationState,
  quoteEffectiveDate,
  product
}) => {
  const { proQuoteNewProducts } = useFlags();

  if (!quoteEffectiveDate || !proQuoteNewProducts) return null;

  const _quoteEffectiveDate = new Date(quoteEffectiveDate);

  const { start, end } = proQuoteNewProducts?.[locationState]?.[product] || {};
  const compareStart = new Date(start);
  const compareEnd = new Date(end);
  const isInDateWindow =
    compareStart <= _quoteEffectiveDate && _quoteEffectiveDate <= compareEnd;

  const newProductMessages = {
    BP7BusinessOwnersLine: "BOP is now available for quoting",
    CA7CommAutoLine: "Commercial Auto is now available to be bundled with BOP",
    WCMWorkersCompLine:
      "Workers' Compensation is now available to be bundled with BOP"
  };

  if (isInDateWindow)
    return (
      <Popover
        id={`popover${product}`}
        trigger="hover"
        triggerContent={
          <Button
            onClick={() => {}}
            isLink
            inline
            className="oq__product__new-tooltip__trigger"
          >
            New!
          </Button>
        }
        popoverContent={
          <div className="oq__product__new-tooltip__content">
            {newProductMessages[product]}
          </div>
        }
        theme="blue"
      />
    );

  return null;
};

export default ProductIsNewTooltip;
