import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, StatusPill, formatDate } from "@ufginsurance/ui-kit";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Translations } from "../../translations";
import EffectiveDateModal from "../../../online-quoting/shared/effectiveDate/EffectiveDateModal";
import { formatCurrencyUSD } from "../../../surety/shared/utils";
import "./QuoteInfo.scss";

const QuoteInfo = ({ result }) => {
  const [showEffectiveDateModal, setShowEffectiveDateModal] = useState(false);
  const [newEffectiveDate, setNewEffectiveDate] = useState("");

  const { quoteList } = Translations.commercial.new_submissions_tab;
  const searchStatus =
    Translations.commercial.new_submissions_tab.status_options.find(
      x => x.value === result.status
    );
  const statusName = searchStatus?.label || result.status;
  const statusColor = searchStatus?.color || "green";

  return (
    <>
      <ListGroup key="list" className="col-md-6 expanded-quotes-list">
        <ListGroupItem>
          <dt>{quoteList.quote_number}:</dt>
          <dd>
            <span className="quote_number">{result.quote_number}</span>
          </dd>
        </ListGroupItem>
        <ListGroupItem>
          <dt>{quoteList.product}:</dt>
          <dd>
            <span className="product">{result.product}</span>
          </dd>
        </ListGroupItem>
        <ListGroupItem>
          <dt>{quoteList.effective_date}:</dt>
          <dd>
            <span
              className={`${
                result.is_effective_date_expired && !newEffectiveDate
                  ? "effective-date-expired"
                  : ""
              }`}
            >
              {formatDate(
                !newEffectiveDate
                  ? result.effective_date
                  : newEffectiveDate.supportingData?.effectiveDate,
                "MM/DD/YYYY"
              )}
            </span>
            {result.is_effective_date_expired &&
              !!result.effective_date_expired_url && (
                <span>
                  {` - `}
                  <a
                    rel="noopener noreferrer"
                    href={result.effective_date_expired_url}
                  >
                    Edit
                  </a>
                </span>
              )}
            {result?.can_edit_quote &&
              !newEffectiveDate &&
              result?.core_system === "OnlineQuoting" && (
                /* OOQ will open edit effective date modal instead of redirect with url.
              online quoting edit link-button will display based off of can_edit_quote (flag looks for PC status Quoted or Draft & core_system is OnlineQuoting). */
                <div id="edit-quote">
                  <Button
                    isLink
                    className="edit-btn"
                    onClick={() => setShowEffectiveDateModal(true)}
                    inline
                  >
                    {` - `}
                    <span className="edit-text">Edit</span>
                  </Button>
                </div>
              )}
          </dd>
        </ListGroupItem>
        <ListGroupItem>
          <dt>{quoteList.status_last_updated}:</dt>
          <dd>
            <span className="status_last_updated">
              {formatDate(result?.status_last_updated, "MM/DD/YYYY")}
            </span>
          </dd>
        </ListGroupItem>
        <ListGroupItem>
          <dt>{quoteList.status}:</dt>
          <dd className="statusDeclined">
            <span>
              {/* 'NotTaken' is the only progress that needs space currently */}
              {result.progress === "NotTaken" ? "Not Taken" : result.progress}
            </span>
            <StatusPill
              text={statusName}
              color={statusColor}
              className="searchStatusPill"
            />
          </dd>
        </ListGroupItem>
        <ListGroupItem>
          <dt>{quoteList.premium_amount}:</dt>
          <dd>
            <span className="premium_amount">
              {result.premium_amount === 0 &&
              result.core_system === "BuildersRisk" ? (
                <span>-</span>
              ) : (
                <span>
                  {formatCurrencyUSD(
                    result.premium_amount,
                    result.core_system === "BuildersRisk" ? 0 : 2
                  )}
                </span>
              )}
            </span>
          </dd>
        </ListGroupItem>
        {result.policy_number && (
          <ListGroupItem>
            <dt>{quoteList.policy_number}:</dt>
            <dd>
              <span className="policy_number">{result.policy_number}</span>
            </dd>
          </ListGroupItem>
        )}
      </ListGroup>
      {showEffectiveDateModal && (
        <EffectiveDateModal
          initialValues={{
            effectiveDate: formatDate(result.effective_date, "MM/DD/YYYY")
          }}
          onHide={() => setShowEffectiveDateModal(false)}
          show={showEffectiveDateModal}
          quoteId={result.quote_number}
          setNewEffectiveDate={setNewEffectiveDate}
        />
      )}
    </>
  );
};

QuoteInfo.propTypes = {
  result: PropTypes.object
};

export default QuoteInfo;
