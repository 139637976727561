import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translations } from "../../components/translations";
import SubMenu from "../menus/SubMenu";
import { Link, withRouter } from "react-router-dom";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import RouteNavItem from "../../components/RouteNavItem";
import { commercialLinks } from "./CommercialLinks";
import { resourcesLinks } from "./ResourcesLinks";
import * as routes from "../../constants/routes";
import { agencyNumberReducerUpdated } from "../../actions/globalactivity";
import { getServiceCenterAccess } from "./serviceCenterSelector";

import {
  getisSpecialtyPortalView,
  getIsMercerSpecialtyBrokerPortalView
} from "../../portalview/changePortalViewStateSelectors";
import {
  COMMERCIAL_LINES,
  CLASS_APPETITE_GUIDE,
  RISK_CONTROL,
  AGENT_MANUALS,
  VIEW_UNDERWRITING_TEAM_UFG_ONLINE,
  MERCER_SPECIALTY_UNDERWRITING,
  UFG_SPECIALTY_UNDERWRITING,
  AGENCY_INTERFACE
} from "../../constants/routes";
import {
  ONLINE_ONLY,
  HEADER_NAV_ITEMS,
  NAV_EVENT_TYPES
} from "../../constants/dictionary";
import { useFlags } from "launchdarkly-react-client-sdk";

const HeaderMenu = props => {
  const [commercialNavExpanded, setCommercialNavExpanded] = useState(false);
  const [resourcesNavExpanded, setResourcesNavExpanded] = useState(false);
  const [suretyNavExpanded, setSuretyNavExpanded] = useState(false);
  const [reportsNavExpanded, setReportsNavExpanded] = useState(false);

  const { suretyAgentManualsUpdateEnabled, suretyDocumentsAndFormsEnabled } =
    useFlags();

  const {
    children,
    navExpanded,
    setUserNavExpanded,
    userNavExpanded,
    hasMarketingSolutionsAccess,
    handleToggleNav,
    location,
    isSpecialtyPortalView,
    agencyContractBranchCode,
    isMercerSpecialtyBrokerPortalView,
    navEventRef,
    is109015Agent,
    hasServiceCenterAccess
  } = props;

  const wrapperRef = useRef();

  const clearUserMenuNav = useCallback(
    type => {
      Object.keys(NAV_EVENT_TYPES).forEach(key => {
        const value = navEventRef.current[key];
        if (key !== type && !!value) {
          navEventRef.current[key] = "";
        }
      });

      setUserNavExpanded(false);
    },
    [navEventRef, setUserNavExpanded]
  );

  const updateNavMenuExpandedState = (resourceType = "close-all") => {
    setCommercialNavExpanded(resourceType === HEADER_NAV_ITEMS.COMMERCIAL);
    setResourcesNavExpanded(resourceType === HEADER_NAV_ITEMS.RESOURCES);
    setSuretyNavExpanded(resourceType === HEADER_NAV_ITEMS.SURETY);
    setReportsNavExpanded(resourceType === HEADER_NAV_ITEMS.REPORTS);
  };

  const updateNavMenuExpanded = (
    eventType = null,
    resourceType = "close-all"
  ) => {
    updateNavMenuExpandedState(resourceType);
    if (!eventType) return;
    navEventRef.current[eventType] = resourceType;
    clearUserMenuNav(eventType);
  };

  const handleHeaderMenuOutsideClick = useCallback(
    e => {
      if (
        !wrapperRef?.current ||
        wrapperRef.current.contains(e.target) ||
        userNavExpanded
      ) {
        return;
      }

      updateNavMenuExpandedState();
      navEventRef.current[NAV_EVENT_TYPES.MOUSE_ENTER] = "";
      navEventRef.current[NAV_EVENT_TYPES.TAB] = "";
    },
    [navEventRef, userNavExpanded]
  );

  const onMouseLeave = () => {
    const items = [
      navEventRef.current[NAV_EVENT_TYPES.MOUSE_ENTER],
      navEventRef.current[NAV_EVENT_TYPES.TAB]
    ];

    setCommercialNavExpanded(items.includes(HEADER_NAV_ITEMS.COMMERCIAL));
    setResourcesNavExpanded(items.includes(HEADER_NAV_ITEMS.RESOURCES));
    setSuretyNavExpanded(items.includes(HEADER_NAV_ITEMS.SURETY));
    setReportsNavExpanded(items.includes(HEADER_NAV_ITEMS.REPORTS));
  };

  const onRouteNavItemMouseLeave = () => {
    onMouseLeave();
  };

  const onHandleListItemMouseLeave = () => {
    navEventRef.current[NAV_EVENT_TYPES.MOUSE_ENTER] = "";
    onMouseLeave();
  };

  const handleCommercialMenuClosed = () => {
    setCommercialNavExpanded(false);
    handleToggleNav();
  };

  const handleResourcesMenuClosed = () => {
    setResourcesNavExpanded(false);
    handleToggleNav();
  };

  const handleSuretyMenuClosed = () => {
    setSuretyNavExpanded(false);
    handleToggleNav();
  };

  const handleReportsMenuClosed = () => {
    setReportsNavExpanded(false);
    handleToggleNav();
  };

  const commercialSubMenuItems = () => {
    if (isSpecialtyPortalView) {
      let commercialLinksFiltered = commercialLinks.filter(item => {
        return (
          item.to !== RISK_CONTROL &&
          item.to !== CLASS_APPETITE_GUIDE &&
          item.to !== COMMERCIAL_LINES
        );
      });

      const brokerManual = {
        to: AGENT_MANUALS,
        activeClassName: "nav-menu--active",
        text: Translations.commercial.broker_manuals
      };

      const underWritingTeam = {
        to: isMercerSpecialtyBrokerPortalView
          ? MERCER_SPECIALTY_UNDERWRITING
          : UFG_SPECIALTY_UNDERWRITING,
        activeClassName: "nav-menu--active",
        text: Translations.commercial.view_underwriting_team
      };
      commercialLinksFiltered = commercialLinksFiltered.map(item => {
        return item.to === AGENT_MANUALS
          ? brokerManual
          : item.to === VIEW_UNDERWRITING_TEAM_UFG_ONLINE
          ? underWritingTeam
          : item;
      });
      return commercialLinksFiltered;
    }
    return commercialLinks;
  };

  const resourcesSubMenuItems = (resourcesLinks, isSpecialtyPortalView) => {
    if (isSpecialtyPortalView) {
      const resourceLinkFiltered =
        resourcesLinks &&
        resourcesLinks.filter(item => {
          return item.to !== AGENCY_INTERFACE;
        });
      return resourceLinkFiltered;
    }
    return resourcesLinks;
  };

  const suretySubMenuItems = () => {
    const response = [];
    const addItem = (items, to, text) => {
      items.push({
        activeClassName: "nav-menu--active",
        to,
        text
      });
    };
    addItem(response, routes.SURETY, "Surety");
    if (suretyAgentManualsUpdateEnabled)
      addItem(response, routes.SURETY_AGENT_MANUALS, "Surety Agent Manuals");
    if (suretyDocumentsAndFormsEnabled)
      addItem(response, routes.SURETY_DOCS_FORMS, "Surety Documents and Forms");
    return response;
  };

  const reportsSubMenuItems = () => {
    const items = [];

    const asItem = (to, text, children) => ({
      activeClassName: "nav-menu--active",
      to,
      text,
      children: (children || []).map(_ => ({ ..._, to: `${to}/${_.to}` }))
    });

    const accountingRoute = `${routes.REPORTS}/${routes.REPORTS_ACCOUNTING_ACTIVE_TAB}`;
    const accountingItems = [
      asItem(routes.REPORTS_RECONCILE_EPAY, "Reconcile Electronic Payments"),
      asItem(routes.REPORTS_AGENCY_STATEMENTS, "Agency Statements"),
      asItem(routes.REPORTS_AGENCY_BILL_EPAY, "Agency Bill E-Pay"),
      asItem(routes.REPORTS_AGENCY_BILL_PAST_DUE, "Agency Bill Past Due")
    ];
    items.push(asItem(accountingRoute, "Accounting", accountingItems));

    //AGENCY
    const agencyRoute = `${routes.REPORTS}/${routes.REPORTS_AGENCY_ACTIVE_TAB}`;
    const agencyItems = [];
    agencyItems.push(
      asItem(routes.REPORTS_EXPERIENCE_DETAIL, "Experience Detail")
    );
    agencyItems.push(
      asItem(routes.REPORTS_MONTHLY_EXPERIENCE, "Monthly Experience")
    );
    if ((is109015Agent || hasServiceCenterAccess) && !isSpecialtyPortalView)
      agencyItems.push(
        asItem(routes.REPORTS_SERVICE_CENTER_SUMMARY, "Service Center Summary")
      );
    items.push(asItem(agencyRoute, "Agency", agencyItems));

    //CLAIMS
    const claimsRoute = `${routes.REPORTS}/${routes.REPORTS_CLAIMS_ACTIVE_TAB}`;
    const claimsItems = [
      asItem(routes.REPORTS_POLICY_LOSS, "Policy Loss Runs"),
      asItem(routes.REPORTS_AGENCY_LOSS, "Agency Loss Runs"),
      asItem(routes.REPORTS_YEAR_TO_DATE_LOSS, "Year to Date Loss Summary"),
      asItem(routes.REPORTS_YEAR_TO_WEEKLY_PAYMENT, "Weekly Payment Summary")
    ];
    items.push(asItem(claimsRoute, "Claims", claimsItems));

    //UNDERWRITING
    const uwRoute = `${routes.REPORTS}/${routes.REPORTS_UNDERWRITING_ACTIVE_TAB}`;
    const uwItems = [];
    uwItems.push(asItem(routes.REPORTS_IN_FORCE_LIST, "In-Force List"));
    if (!isSpecialtyPortalView)
      uwItems.push(asItem(routes.REPORTS_APP_QUOTE_STATUS, "App/Quote Status"));
    if (!isSpecialtyPortalView)
      uwItems.push(asItem(routes.REPORTS_HIT_RATIO, "3 Year Hit Ratio"));
    uwItems.push(
      asItem(routes.REPORTS_AGENCY_AUDIT_REPORT, "Agency Audit Report")
    );
    items.push(asItem(uwRoute, "Underwriting", uwItems));

    return items;
  };

  const marketingSolutionsUrl =
    window.env.REACT_APP_MARKETING_SOLUTIONS_BASEURL;

  const isRouteType = routeType => location.pathname.startsWith(routeType);

  useEffect(() => {
    if (userNavExpanded) {
      updateNavMenuExpandedState();
    }
  }, [userNavExpanded]);

  useEffect(() => {
    document.addEventListener("click", handleHeaderMenuOutsideClick);

    return () =>
      document.addEventListener("click", handleHeaderMenuOutsideClick);
  }, [handleHeaderMenuOutsideClick]);

  return (
    <div ref={wrapperRef}>
      <div className={`main-nav main-nav--${navExpanded ? "in" : "out"}`}>
        <div className="headerMenu__navbar-container">
          <ul className="main-nav__nav-list">
            <RouteNavItem
              to={routes.HOME}
              activeAtRoot
              closeMenu={handleToggleNav}
              onFocus={updateNavMenuExpandedState}
              onMouseEnter={updateNavMenuExpandedState}
              onMouseLeave={onRouteNavItemMouseLeave}
            >
              {Translations.navigation.home}
            </RouteNavItem>

            <RouteNavItem
              to={routes.ACCOUNT_HOME}
              closeMenu={handleToggleNav}
              onFocus={updateNavMenuExpandedState}
              onMouseEnter={updateNavMenuExpandedState}
              onMouseLeave={onRouteNavItemMouseLeave}
            >
              {Translations.navigation.account}
            </RouteNavItem>

            <li
              role="presentation"
              className={`route-item ${
                isRouteType(routes.COMMERCIAL) ? "active" : ""
              } `}
              onMouseEnter={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.MOUSE_ENTER,
                  HEADER_NAV_ITEMS.COMMERCIAL
                )
              }
              onMouseLeave={onHandleListItemMouseLeave}
              onClick={handleCommercialMenuClosed}
              onFocus={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.TAB,
                  HEADER_NAV_ITEMS.COMMERCIAL
                )
              }
            >
              {!isSpecialtyPortalView && <Link to={routes.COMMERCIAL}>
                {Translations.navigation.commercial}
              </Link>}
              {isSpecialtyPortalView && <Link to={routes.QUOTE_PROPOSAL}>
                {Translations.navigation.commercial}
              </Link>}
              <SubMenu
                openclosed={commercialNavExpanded ? "open" : "closed"}
                links={commercialSubMenuItems()}
                handleClick={handleCommercialMenuClosed}
              />
            </li>

            {!isSpecialtyPortalView && (
              <li
                role="presentation"
                className={`route-item surety ${
                  isRouteType(routes.SURETY) ? "active" : ""
                } `}
                onMouseEnter={() =>
                  updateNavMenuExpanded(
                    NAV_EVENT_TYPES.MOUSE_ENTER,
                    HEADER_NAV_ITEMS.SURETY
                  )
                }
                onMouseLeave={onHandleListItemMouseLeave}
                onClick={handleSuretyMenuClosed}
                onFocus={() =>
                  updateNavMenuExpanded(
                    NAV_EVENT_TYPES.TAB,
                    HEADER_NAV_ITEMS.SURETY
                  )
                }
              >
                <Link to={routes.SURETY}>Surety</Link>
                <SubMenu
                  openclosed={suretyNavExpanded ? "open" : "closed"}
                  links={suretySubMenuItems()}
                  handleClick={handleSuretyMenuClosed}
                  className="sub-menu--surety"
                />
              </li>
            )}

            <li
              role="presentation"
              className={`route-item reports ${
                isRouteType(routes.REPORTS) ? "active" : ""
              } `}
              onMouseEnter={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.MOUSE_ENTER,
                  HEADER_NAV_ITEMS.REPORTS
                )
              }
              onMouseLeave={onHandleListItemMouseLeave}
              onClick={handleReportsMenuClosed}
              onFocus={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.TAB,
                  HEADER_NAV_ITEMS.REPORTS
                )
              }
            >
              <Link to={routes.REPORTS}>{Translations.navigation.report}</Link>
              <SubMenu
                openclosed={reportsNavExpanded ? "open" : "closed"}
                links={reportsSubMenuItems()}
                handleClick={handleReportsMenuClosed}
                className={
                  !isSpecialtyPortalView
                    ? "sub-menu--reports"
                    : "sub-hasBrokerAccess-menu--reports"
                }
              />
            </li>

            <li
              role="presentation"
              className={`route-item resources ${
                isRouteType(routes.RESOURCES) ? "active" : ""
              } `}
              onMouseEnter={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.MOUSE_ENTER,
                  HEADER_NAV_ITEMS.RESOURCES
                )
              }
              onMouseLeave={onHandleListItemMouseLeave}
              onClick={handleResourcesMenuClosed}
              onFocus={() =>
                updateNavMenuExpanded(
                  NAV_EVENT_TYPES.TAB,
                  HEADER_NAV_ITEMS.RESOURCES
                )
              }
            >
              <Link to={routes.RESOURCES}>
                {Translations.navigation.resources}
              </Link>
              <SubMenu
                openclosed={resourcesNavExpanded ? "open" : "closed"}
                links={resourcesSubMenuItems(
                  resourcesLinks,
                  isSpecialtyPortalView
                )}
                handleClick={handleResourcesMenuClosed}
                className={
                  !isSpecialtyPortalView
                    ? "sub-menu--resources"
                    : "sub-hasBrokerAccess-menu--resources"
                }
              />
            </li>

            {hasMarketingSolutionsAccess &&
              agencyContractBranchCode !== ONLINE_ONLY && (
                <li
                  role="presentation"
                  className="route-item headerMenu__marketing"
                  onClick={updateNavMenuExpandedState}
                  onFocus={updateNavMenuExpandedState}
                  onMouseEnter={updateNavMenuExpandedState}
                  onMouseLeave={onHandleListItemMouseLeave}
                >
                  <a
                    href={marketingSolutionsUrl}
                    target="_self"
                    className="headerMenu__marketing-item"
                  >
                    <div>
                      {Translations.navigation.marketing}{" "}
                      {Translations.navigation.solutions}
                    </div>
                  </a>
                </li>
              )}
          </ul>
          {children}
        </div>
      </div>
    </div>
  );
};

HeaderMenu.propTypes = {
  children: PropTypes.element.isRequired,
  navExpanded: PropTypes.bool.isRequired,
  userNavExpanded: PropTypes.bool.isRequired,
  setUserNavExpanded: PropTypes.func.isRequired,
  handleToggleNav: PropTypes.func.isRequired,
  hasMarketingSolutionsAccess: PropTypes.bool,
  location: PropTypes.object,
  isSpecialtyPortalView: PropTypes.bool,
  agencyContractBranchCode: PropTypes.string,
  isMercerSpecialtyBrokerPortalView: PropTypes.bool,
  navEventRef: PropTypes.any.isRequired,
  is109015Agent: PropTypes.bool.isRequired,
  hasServiceCenterAccess: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  hasMarketingSolutionsAccess:
    sessionSelector.getHasMarketingSolutionsAccess(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  agencyContractBranchCode: sessionSelector.getAgencyContractBranchCode(state),
  isMercerSpecialtyBrokerPortalView:
    getIsMercerSpecialtyBrokerPortalView(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  hasServiceCenterAccess: getServiceCenterAccess(state)
});

const mapDispatchToProps = {
  handleAgentNumberUpdate: agencyNumberReducerUpdated
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)
);
