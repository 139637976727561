import React, { createContext, useReducer, useContext, useEffect } from "react";
import * as PolicyChangesService from "../redux/policyChangesService";

const PolicyChangesContext = createContext({
  policyInfo: null
});

const UPDATE_POLICY_INFO = "UPDATE_POLICY_INFO";

export const usePolicyChangesContext = () => useContext(PolicyChangesContext);

const policyChangesReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_POLICY_INFO:
      return {
        ...state,
        policyInfo: {
          ...action.payload,
          drivers: (action.payload?.drivers ?? []).map(driver => {
            return {
              ...driver,
              full_name: `${driver.last_name}, ${driver.first_name}`,
              orig_first_name: !driver?.orig_first_name
                ? driver.first_name
                : driver.orig_first_name,
              orig_last_name: !driver?.orig_last_name
                ? driver.last_name
                : driver.orig_last_name,
              orig_date_of_birth: !driver?.orig_date_of_birth
                ? driver.date_of_birth
                : driver.orig_date_of_birth,
              orig_gender: !driver?.orig_gender
                ? driver.gender
                : driver.orig_gender,
              orig_license_state: !driver?.orig_license_state
                ? driver.license_state
                : driver.orig_license_state,
              orig_license_number: !driver?.orig_license_number
                ? driver.license_number
                : driver.orig_license_number
            };
          })
        }
      };
    default:
      return state;
  }
};

export function PolicyChangesProvider(props) {
  const { policyInfo } = usePolicyChangesContext();
  const [policyData, updatePolicyData] = useReducer(policyChangesReducer, {
    policyInfo
  });

  const setPolicyInfo = data => {
    updatePolicyData({ type: UPDATE_POLICY_INFO, payload: data });
  };

  const updatePolicyChangeDriver = (
    policyNumber,
    jobId,
    driverNumber,
    data
  ) => {
    return PolicyChangesService.putPolicyChangeDriver(
      policyNumber,
      jobId,
      driverNumber,
      data
    ).then(res => {
      setPolicyInfo(res.data);
      return Promise.resolve(res);
    });
  };

  const createPolicyChangeDriver = (policyNumber, jobId, data) => {
    return PolicyChangesService.postPolicyChangeDriver(
      policyNumber,
      jobId,
      data
    ).then(res => {
      setPolicyInfo(res.data);
      return Promise.resolve(res);
    });
  };

  const removePolicyChangeDriver = async (
    policyNumber,
    jobId,
    driverNumber
  ) => {
    return await PolicyChangesService.removePolicyChangeDriver(
      policyNumber,
      jobId,
      driverNumber
    ).then(res => {
      setPolicyInfo(res.data);
      return Promise.resolve(res);
    });
  };

  const QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE = 424;
  const quotePolicyChangeDriver = async (policyNumber, jobId) => {
    return await PolicyChangesService.quotePolicyDraft(
      policyNumber,
      jobId
    ).then(res => {
      return Promise.resolve(res);
    });
  };

  useEffect(() => {
    if (!!props.policy?.policy_number && !policyInfo?.policy_number) {
      setPolicyInfo(props.policy);
    }
  }, [props.policy?.policy_number, policyInfo?.policy_number, props.policy]);

  return (
    <PolicyChangesContext.Provider
      value={{
        setPolicyInfo,
        policyData,
        policyInfo,
        updatePolicyChangeDriver,
        createPolicyChangeDriver,
        removePolicyChangeDriver,
        quotePolicyChangeDriver,
        QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE
      }}
    >
      <div id="policyChanges">{props.children}</div>
    </PolicyChangesContext.Provider>
  );
}
