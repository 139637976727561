import { connect } from "react-redux";
import PersonalTab from "./PersonalTab";
import {
  fetchPolicyDecs,
  removePolicyDec,
  fetchClientNotices,
  removeClientNotice,
  resetClientNotices,
  fetchClientNoticeDocument
} from "../../../actions/homePersonalActions";
import { getPolicyDecsPersonalDocument } from "../../../actions/homeCommercialActions";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getIsMercerSpecialtyBrokerPortalView,
  getisSpecialtyPortalView
} from "../../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => {
  return {
    ...state.homePersonal,
    agencyCode: getSelectedAgencyCode(state),
    is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    isMercerSpecialtyBrokerPortalView: getIsMercerSpecialtyBrokerPortalView(
      state
    )
  };
};

const mapDispatchToProps = {
  fetchPolicyDecs,
  removePolicyDec,
  fetchClientNotices,
  removeClientNotice,
  resetClientNotices,
  fetchClientNoticeDocument,
  getPolicyDecsPersonalDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalTab);
