import React, { useState } from "react";
import { Button, FlexRow, Modal, Panel, Table } from "@ufginsurance/ui-kit";
import DriverDetailsForm from "./DriverDetailsForm";
import { usePolicyChangesContext } from "../context";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import { toast } from "react-toastify";

const Step1DriverList = () => {
  const NO_RESULTS_MESSAGE = "There are no active drivers for this policy.";
  const DRIVER_STATUS_NOTE =
    "* Watch / Restricted / Excluded drivers cannot be edited or removed";
  const DRIVER_REMOVE_SUCCESS_MESSAGE =
    "The driver has been successfully removed.";

  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isRemoveLoadingDriverId, setIsRemoveLoadingDriverId] = useState(null);

  const { policyData, removePolicyChangeDriver } = usePolicyChangesContext();
  const { policyInfo } = policyData;

  const policyDrivers = policyInfo?.drivers || [];

  const onOpenModal = (data = null) => {
    setShowModal(true);
    setSelectedRowData(data);
  };

  const displayToast = success => {
    const options = {
      position: "top-center",
      className: success ? "success" : "error",
      autoClose: success
    };
    setIsRemoveLoadingDriverId(null);
    const message = success ? (
      DRIVER_REMOVE_SUCCESS_MESSAGE
    ) : (
      <HelpDeskMessage display="anErrorOccurred" />
    );

    toast(message, options);
  };

  const onRemoveClick = data => {
    const driversInOkStatus = policyDrivers.filter(
      driver => driver.driver_status === "OK"
    );

    if (driversInOkStatus.length === 1) {
      const options = {
        position: "top-center",
        className: "warning",
        autoClose: true
      };

      toast("Policy must have at least one active driver.", options);
    } else {
      setIsRemoveLoadingDriverId(data.driver_id);
      removePolicyChangeDriver(
        policyInfo?.policy_number,
        policyInfo?.job_id,
        data.driver_id
      )
        .then(res => {
          const success = res.status === 200;
          displayToast(success);
        })
        .catch(_err => {
          displayToast(false);
        });
      setSelectedRowData(null);
    }
  };

  const columns = [
    {
      key: "full_name",
      label: "Driver Name",
      className: "driver-full-name",
      sortable: true,
      element: row => {
        return <span className="driver-name">{row.full_name}</span>;
      }
    },
    {
      key: "license_number",
      label: "License Number",
      element: row => {
        return <span id={`${row.driver_id}`}>{row.license_number}</span>;
      }
    },
    {
      key: "license_state",
      label: "License State"
    },
    {
      key: "driver_status",
      label: "Driver Status",
      sortable: true,
      className: "driver-status"
    },
    {
      key: "actions",
      label: "",
      className: "driver-list-actions",
      element: row => {
        const isExactDriverRemoveLoading =
          row && row.driver_id && row.driver_id === isRemoveLoadingDriverId;
        return (
          row.driver_status === "OK" && (
            <div className="driver-list-actions__buttons">
              <Button
                className="edit-driver-button"
                onClick={() => {
                  onOpenModal(row);
                }}
                isLink
                inline
                icon="fasEdit"
              >
                Edit
              </Button>
              <Button
                variant="tertiary"
                isLink
                inline
                icon="farTrashAlt"
                className="policy-change-trash-icon"
                spinner={isExactDriverRemoveLoading}
                onClick={() => {
                  onRemoveClick(row);
                }}
              >
                Remove
              </Button>
            </div>
          )
        );
      }
    }
  ];

  const onHide = () => {
    setShowModal(false);
  };

  return (
    <div id="driverListStepOne">
      {showModal ? (
        <Modal
          title="Driver Details"
          show={showModal}
          onHide={onHide}
          body={
            <DriverDetailsForm data={selectedRowData} handleOnClose={onHide} />
          }
          size="lg"
          className="driver-information"
        />
      ) : null}

      <Panel rounded bgcolor="grey" title="Driver List">
        <Panel rounded bgcolor="white">
          <Table
            id="driver-list"
            initialSort="last_name"
            columns={columns}
            data={policyDrivers}
            rowKey="driver_id"
            noResultsMessage={NO_RESULTS_MESSAGE}
          />
          <FlexRow align="right">
            <em>{DRIVER_STATUS_NOTE}</em>
          </FlexRow>
        </Panel>
        <Button
          id="driver-change-add-button"
          block
          icon="farPlus"
          variant="outline"
          onClick={onOpenModal}
        >
          Add Driver
        </Button>
      </Panel>
    </div>
  );
};

export default Step1DriverList;
