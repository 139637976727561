import { connect } from "react-redux";
import { withRouter } from "react-router";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import ClaimsInquiryForm from "../components/ClaimsInquiryForm";
import { fetchClaim, fetchClaims } from "../actions/claimsActions";
import { fetchPoliciesSearchList } from "../../actions/policies";
import { cancelClaimInquiry } from "../actions/claimsInquiryActions";

const mapStateToProps = state => ({
  employeeUserName: sessionSelector.getEmployeeUserName(state),
  activeAgency: sessionSelector.getActiveAgency(state),
  claimNumber: state.claimsInquiry.claimNumber
});

const mapDispatchToProps = {
  fetchClaim,
  fetchClaims,
  fetchPoliciesSearchList,
  dismissModal: cancelClaimInquiry
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClaimsInquiryForm)
);
