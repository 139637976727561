import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const agencySweepPaymentConfirmationReducer = (
  state = initialState.agencySweepPaymentConfirmation,
  action
) => {
  switch (action.type) {
    case types.PROCESS_AGENCY_SWEEP_PAYMENT_STARTED:
      return {
        ...state,
        paymentDetailsLoading: true,
        paymentDetails: null,
        paymentDetailsError: null,
        responseStatusCode: 0
      };
    case types.PROCESS_AGENCY_SWEEP_PAYMENT_SUCCEEDED:
      return {
        ...state,
        paymentDetailsLoading: false,
        paymentDetails: action.payload,
        paymentDetailsError: null,
        responseStatusCode: 200
      };
    case types.PROCESS_AGENCY_SWEEP_PAYMENT_FAILED:
      return {
        ...state,
        paymentDetailsLoading: false,
        paymentDetails: null,
        paymentDetailsError: action.payload,
        responseStatusCode:
          (action.payload &&
            action.payload.response &&
            action.payload.response.status) ||
          0
      };
    case types.CLEAR_AGENCY_SWEEP_PAYMENT_SUCCEEDED:
      return {
        ...state,
        paymentDetailsLoading: false,
        paymentDetails: null,
        paymentDetailsError: null,
        responseStatusCode: 0
      };
    case types.SEND_OASIS_PAYMENT_CONFIRMATION_STARTED:
      return {
        ...state,
        oasisPaymentConfirmationLoading: true
      };
    case types.SEND_OASIS_PAYMENT_CONFIRMATION_SUCCEEDED:
      return {
        ...state,
        oasisPaymentConfirmationLoading: false
      };
    case types.SEND_OASIS_PAYMENT_CONFIRMATION_FAILED:
      return {
        ...state,
        oasisPaymentConfirmationLoading: false
      };
    case types.AGENCY_SWEEP_PAYMENT_PDF_LOADING:
      return {
        ...state,
        paymentReportLoading: true,
        paymentReportSuccess: false,
        paymentReportError: false
      };
    case types.AGENCY_SWEEP_PAYMENT_PDF_SUCCEEDED:
      return {
        ...state,
        paymentReportLoading: false,
        paymentReportSuccess: true
      };
    case types.AGENCY_SWEEP_PAYMENT_PDF_FAILED:
      return {
        ...state,
        paymentReportLoading: false,
        paymentReportError: true
      };
    default:
      return state;
  }
};

export default agencySweepPaymentConfirmationReducer;
