import React, { useState, useCallback, useMemo } from "react";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import VehicleFormStep1 from "./VehicleFormStep1.js";
import VehicleFormClassesStep2 from "./VehicleFormClassesStep2.js";
import VehicleFormCoveragesStep3 from "./VehicleFormCoveragesStep3.js";
import OqModal from "../../shared/OqModal.js";
import cn from "classnames";
import { toTitleCase } from "../../../components/Factory";

const VehicleModalWizard = ({
  show,
  onHide,
  currentVehicle,
  locations,
  mode,
  addAnotherItem,
  coverageExclusions
}) => {
  const titles = useMemo(
    () => [
      { step: 1, title: ` ${toTitleCase(mode || "")} Vehicle Details` },
      { step: 2, title: ` ${toTitleCase(mode || "")} Vehicle Class` },
      { step: 3, title: ` ${toTitleCase(mode || "")} Vehicle Coverages` }
    ],
    [mode]
  );

  const setTitle = useCallback(
    step => {
      const item = titles.find(d => d.step === step);
      if (item) {
        return setCurrentTitle(item.title);
      }
      return setCurrentTitle("");
    },
    [titles]
  );

  const [currentStep, setStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState(titles[0].title);
  const [currVehicle, setCurrVehicle] = useState(currentVehicle);
  const [scheduleItemOpenInCoverable, setScheduleItemOpenInCoverable] =
    useState();
  const [vehicleClasses, setVehicleClasses] = useState();

  const totalSteps = 3;

  const goToStep = useCallback(
    (step, vehicle) => {
      if (step <= totalSteps && step > 0) {
        const st = step;
        setCurrVehicle(vehicle);
        setTitle(st);
        setStep(st);
      }
    },
    [setTitle]
  );

  const onNextStep = useCallback(
    vehicle => {
      if (currentStep + 1 <= totalSteps) {
        const st = currentStep + 1;
        setCurrVehicle(vehicle);
        setTitle(st);
        setStep(st);
      } else if (currentStep + 1 > totalSteps) {
        onHide();
      }
    },
    [currentStep, onHide, setTitle]
  );

  const onPrevStep = useCallback(
    vehicle => {
      if (currentStep - 1 > 0) {
        const st = currentStep - 1;
        setCurrVehicle(vehicle);
        setTitle(st);
        setStep(st);
      }
    },
    [currentStep, setTitle]
  );

  // using this override to force the edit mode to be applied once the vehicle has an ID
  // ... this is mostly needed for step1 to disable the fields correctly
  const modeOverride = currVehicle?.fixedId ? "EDIT" : mode;

  const renderStepForm = useCallback(
    currentStep => {
      switch (currentStep) {
        case 1:
          return (
            <VehicleFormStep1
              key={`${currentStep}.${modeOverride}.${currVehicle?.id}`}
              currentVehicle={currVehicle}
              onNextStep={onNextStep}
              locations={locations}
              onCancel={onHide}
              onPrevStep={onPrevStep}
              mode={modeOverride}
              setVehicleClasses={setVehicleClasses}
              vehicleClasses={vehicleClasses}
            />
          );
        case 2:
          return (
            <VehicleFormClassesStep2
              key={`${currentStep}.${modeOverride}.${currVehicle?.id}`}
              currentVehicle={currVehicle}
              onNextStep={onNextStep}
              onCancel={onHide}
              onPrevStep={onPrevStep}
              vehicleClasses={vehicleClasses}
            />
          );

        case 3:
          if (currVehicle) {
            return (
              <VehicleFormCoveragesStep3
                key={`${currentStep}.${modeOverride}.${currVehicle?.id}`}
                currentVehicle={currVehicle}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
                onCancel={onHide}
                setScheduleItemOpenInCoverable={setScheduleItemOpenInCoverable}
                addAnotherItem={addAnotherItem}
                goToStep={goToStep}
                coverageExclusions={coverageExclusions}
              />
            );
          }
          return <LoadingIndicator />;
        default:
          onHide(); //if you are here closes the dialog.
      }
    },
    [
      modeOverride,
      currVehicle,
      onNextStep,
      locations,
      onHide,
      onPrevStep,
      vehicleClasses,
      addAnotherItem,
      goToStep,
      coverageExclusions
    ]
  );

  return (
    <>
      {show && (
        <OqModal
          className={cn("oq__modal__coverable oq__modal__coverable__vehicle", {
            scheduleItemOpenInCoverable
          })}
          title={`Step ${currentStep} of ${totalSteps}: ${currentTitle}`}
          show={show}
          onHide={onHide}
          body={<div>{renderStepForm(currentStep)}</div>}
          closeIcon={false}
          overlayHidden={scheduleItemOpenInCoverable}
        />
      )}
    </>
  );
};

export default VehicleModalWizard;
