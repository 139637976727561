/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as api from "../../services/onlineQuotingService";
import * as routes from "../../constants/routes";
import qs from "query-string";
import { connect } from "react-redux";
import { cancelQuote } from "../../top-action-cards/new-quote/actions/newQuoteActions";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import PremiumDepositConfirmationForm from "./PremiumDepositConfirmationForm";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const BindModalContent = ({
  getPolicyDec,
  printPresent,
  activeAgencyCode,
  activeAgencyName,
  onCancelQuote
}) => {
  const {
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    clearLocalQuote,
    showUpdatingToast,
    closeUpdatingToast,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [isAgencyPaymentMade, setIsAgencyPaymentMade] = useState(false);
  const [agencySweepPaymentInfo, setAgencySweepPaymentInfo] = useState(false);
  const [isAgencySweep, setIsAgencySweep] = useState(false);
  const [runPaymentOnce, setRunPaymentOnce] = useState(0);
  const [goToBillingSpinner, setGoToBillingSpinner] = useState();

  const agencySweepMessage = "Processing Agency Sweep Payment...";
  const hasServiceCenterAccess =
    !!quoteData?.baseData?.serviceCenterIndicator_UFG;

  //save the fact that bind and issue modal was ever opened
  const saveBindAndIssueModalOpened = useCallback(() => {
    if (!supportingData?.billingData?.bindAndIssueModalOpened)
      updateSupportingDataPromise({
        dataToMergeAndSave: {
          billingData: {
            ...supportingData.billingData,
            bindAndIssueModalOpened: true
          }
        }
      });
  }, [supportingData, updateSupportingDataPromise]);

  //use this for agency sweep.
  const processAgencyBill = useCallback(
    paymentInfo => {
      //if any of these required fields are blank don't make payment.

      if (
        !supportingData?.billingData?.isAgencyPaymentProcessed &&
        !!activeAgencyName &&
        !isAgencyPaymentMade &&
        !!activeAgencyCode &&
        !!quoteData?.baseData?.accountHolder?.contactName &&
        !!quoteData?.baseData?.accountNumber &&
        !!supportingData?.billingData?.minAmount &&
        !!supportingData?.billingData?.paymentMethodToken &&
        !!supportingData?.billingData?.premiumAmount
      ) {
        const payload = {
          agency_code: activeAgencyCode,
          payment_amount: Number(supportingData.billingData.minAmount),
          agent_name: activeAgencyName,
          insured_name: quoteData.baseData.accountHolder.contactName,
          send_confirmation_email: true,
          confirmation_to: [quoteData.baseData.accountHolder?.emailAddress1],
          payment_method_token: supportingData.billingData.paymentMethodToken,
          line_items: [
            {
              policy_account_number: quoteData?.baseData?.accountNumber,
              billed_by_type:
                paymentInfo.user_account_type === "A" ? "account" : "policy",
              payment_amount: Number(supportingData.billingData.minAmount),
              sequence_number: 1,
              balance_due: Number(paymentInfo.total_amount),
              total_balance: Number(supportingData.billingData.premiumAmount)
              //     company_branch: "0501",
              //     transaction_code: "01",
              //     declaration_code: "CA"
            }
          ]
        };

        api
          .makePayment({ payload })
          .then(results => {
            console.log("payment successfull", results);
            setIsAgencyPaymentMade(true);

            closeUpdatingToast({ message: agencySweepMessage });
            updateSupportingDataPromise({
              dataToMergeAndSave: {
                billingData: {
                  ...supportingData.billingData,
                  isAgencyPaymentProcessed: true
                }
              }
            });
          })
          .catch(error => {
            toastErrr({
              displayMessage:
                "An error occurred.  Agency Sweep Payment did not occur.",
              action: "makePayment",
              description: "payment failed",
              error,
              payload
            });

            closeUpdatingToast({ message: agencySweepMessage });
          });
      }
    },
    [
      activeAgencyCode,
      activeAgencyName,
      closeUpdatingToast,
      isAgencyPaymentMade,
      toastErrr,
      quoteData?.baseData?.accountHolder?.contactName,
      quoteData?.baseData?.accountHolder?.emailAddress1,
      quoteData?.baseData?.accountNumber,
      supportingData.billingData,
      updateSupportingDataPromise
    ]
  );

  // -----------------------------------
  //         Agency Sweep
  // -----------------------------------

  useEffect(() => {
    if (!runPaymentOnce) {
      saveBindAndIssueModalOpened();
      setRunPaymentOnce(true);
      const accountId = quoteData?.baseData?.accountNumber;

      if (supportingData?.billingData?.billingPaymentMethod === "agencySweep") {
        setIsAgencySweep(true);
        if (supportingData?.billingData?.isAgencyPaymentProcessed)
          setIsAgencyPaymentMade(true);
      }

      if (
        accountId &&
        supportingData?.billingData?.billingPaymentMethod === "agencySweep" &&
        !agencySweepPaymentInfo &&
        !supportingData?.billingData?.isAgencyPaymentProcessed
      ) {
        showUpdatingToast({ message: agencySweepMessage });

        const interval = 3000;
        let attemptCounter = 0;
        const maxAttempts = 5;

        const apiForAgencySweepPayment = () => {
          attemptCounter++;
          if (attemptCounter > maxAttempts) {
            clearInterval(agencySweepIntervalRunner);
            toastErrr({
              displayMessage:
                "An error occurred.  Account unable to process Agency Sweep Payment.",
              misc: {
                accountId,
                attempts: attemptCounter,
                interval,
                maxAttempts
              },
              action: "apiForPolicyHolderPayment",
              description: "failed after max number of attempts"
            });
          }

          api.getPaymentEligiblity({ accountId }).then(results => {
            if (
              results.data?.eligibility === 6 &&
              !!supportingData?.billingData?.paymentMethodToken
            ) {
              setAgencySweepPaymentInfo(results.data);
              clearInterval(agencySweepIntervalRunner);
              processAgencyBill(results.data);
            }
          });
        };

        // start the interval to check for eligilibity
        // this is used because the systems don't update quickly enough after the quote
        // is issued... it seems to take 5-10 seconds for things to update to make the
        // eligibility flag come back as "6"
        const agencySweepIntervalRunner = setInterval(
          apiForAgencySweepPayment,
          interval
        );
      }
    }
  }, [
    activeAgencyCode,
    agencySweepPaymentInfo,
    toastErrr,
    processAgencyBill,
    quoteData?.baseData?.accountHolder?.primaryAddress?.postalCode,
    quoteData?.baseData?.accountNumber,
    runPaymentOnce,
    saveBindAndIssueModalOpened,
    showUpdatingToast,
    supportingData?.billingData
  ]);

  // policy holder....
  const onContinueToBilling = () => {
    setGoToBillingSpinner(true);
    const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

    const queryString = qs.stringify({
      num: quoteData?.baseData?.accountNumber,
      typeap: "A",
      min: supportingData?.billingData?.minAmount,
      bal: supportingData?.billingData?.premiumAmount,
      uitype: "C",
      payor: "I",
      frm: "DB",
      agtNum: activeAgencyCode,
      CancellationUrl:
        window.env.REACT_APP_PUBLICSITE_BASEURL + "/commercial/commercial_lines"
    });

    const policyholderSweepPayNowUrl = `${baseLink}/payments/billing?${queryString}`;

    updateSupportingDataPromise({
      dataToMergeAndSave: {
        billingData: {
          ...supportingData.billingData,
          isPHPaymentProcessed: true
        }
      }
    }).then(() => {
      window.open(policyholderSweepPayNowUrl, "_self");
    });
  };

  const redirectToSubmissionList = () => {
    onCancelQuote().then(() => {
      clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
    });
  };

  return (
    <div>
      <p>
        Thank you for choosing UFG Insurance for your business. Policy{" "}
        {quoteData?.bindData?.policyNumber} for{" "}
        {quoteData?.baseData?.accountHolder?.displayName} has been successfully
        bound and issued. Here are copies of the{" "}
        <Button onClick={getPolicyDec} isLink inline>
          policy declaration
        </Button>{" "}
        and{" "}
        <Button onClick={printPresent} isLink inline>
          quote proposal/application
        </Button>{" "}
        for your reference.
      </p>
      <p>Here’s what to expect next:</p>
      <ul className="oq__step6-modal-list">
        <li>
          The policy declaration pages will be posted at{" "}
          <Button onClick={redirectToSubmissionList} isLink inline>
            ufgagent.com
          </Button>{" "}
          on the following business day.
        </li>
        {hasServiceCenterAccess && (
          <li>
            The policy will be serviced by UFG Service Center, which you can
            monitor in the Service Center section at{" "}
            <Button onClick={redirectToSubmissionList} isLink inline>
              ufgagent.com
            </Button>
            .
          </li>
        )}
        <li>Driver acceptability subject to MVR review.</li>
      </ul>
      <p>
        <HelpDeskMessage
          before="If you have any additional questions, please contact a member of your
        underwriting team. Also, "
        />
      </p>
      {supportingData?.billingData?.applyDownPayment && (
        <FlexRow>
          <p>
            Once the bound application is submitted, you will be directed to a
            third-party website, Paymentus to complete the down payment. If the
            policyholder’s down payment is declined for any reason, your agency
            will be billed the down payment amount.
          </p>
        </FlexRow>
      )}

      {isAgencySweep && (
        <PremiumDepositConfirmationForm onCancelQuote={onCancelQuote} />
      )}

      <FlexRow align="right">
        {!supportingData?.billingData?.applyDownPayment && (
          <Button
            variant="primary"
            type="submit"
            onClick={redirectToSubmissionList}
          >
            Return to UFG Agent
          </Button>
        )}
        {supportingData?.billingData?.applyDownPayment && (
          <Button
            wrapperClassName="oq__step6__ph-billing-buttons"
            variant="primary"
            type="submit"
            onClick={onContinueToBilling}
            disabled={goToBillingSpinner}
            spinner={goToBillingSpinner}
          >
            Continue to Billing
          </Button>
        )}
      </FlexRow>
    </div>
  );
};
export default connect(null, { onCancelQuote: cancelQuote })(BindModalContent);
