import * as types from "./actionTypes";

export const activateClaimsInquiryCard = (claimNumber = "") => ({
  type: types.CLAIMS_INQUIRY_ACTIVATED,
  payload: claimNumber
});

export const cancelClaimInquiry = () => ({
  type: types.CANCEL_CLAIM_INQUIRY
});
