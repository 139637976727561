import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ContentHeader } from "@ufginsurance/ui-kit";
import LogoUsage from "./LogoUsage";
import AgencyLinking from "./AgencyLinking";

const usageContent = [
  {
    name: "AGENCY LINKING AGREEMENT",
    description: "Complete our online linking agreement.",
    id: "agency_linking"
  },
  {
    name: "LOGO USAGE GUIDELINES",
    description: "Choose from three logos to download.",
    id: "logo_usage"
  }
];
const WebsiteLogoUsage = ({
  resetWebsiteLogoState,
  agency_name,
  agency_number,
  email,
  postAgencyLinkingDetails,
  updateWebsiteLogoState,
  is109015Agent,
  agencyLinking,
  agencyLinkingLoading,
  agencyLinkingError
}) => {
  const [modal, setModal] = useState(null);

  const openModal = data => () => {
    setModal(data);
    if (!data) resetWebsiteLogoState();
  };

  const renderModals = () => {
    return modal === "agency_linking" ? (
      <AgencyLinking
        openModal={openModal}
        agency_name={agency_name}
        agency_number={agency_number}
        agencyLinkingError={agencyLinkingError}
        email={email}
        postAgencyLinkingDetails={postAgencyLinkingDetails}
        updateWebsiteLogoState={updateWebsiteLogoState}
        is109015Agent={is109015Agent}
        agencyLinking={agencyLinking}
        agencyLinkingLoading={agencyLinkingLoading}
      />
    ) : modal === "logo_usage" ? (
      <LogoUsage openModal={openModal} />
    ) : null;
  };

  return (
    <div className="usage-container">
      {renderModals()}
      <div>
        <ContentHeader>Website and Logo Usage</ContentHeader>
      </div>
      <div className="usage-body">
        {usageContent.map(content => (
          <div className="content-item" key={content.id}>
            <Button
              aria-label={`View ${content.name}`}
              onClick={openModal(content.id)}
            >
              <b>{content.name}</b>
            </Button>
            <p>{content.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

WebsiteLogoUsage.propTypes = {
  resetWebsiteLogoState: PropTypes.func.isRequired,
  agency_name: PropTypes.string.isRequired,
  agency_number: PropTypes.string.isRequired,
  agencyLinkingError: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  postAgencyLinkingDetails: PropTypes.func.isRequired,
  updateWebsiteLogoState: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool.isRequired,
  agencyLinking: PropTypes.object,
  agencyLinkingLoading: PropTypes.bool.isRequired
};

export default WebsiteLogoUsage;
