import React, { useContext } from "react";
import OnlineQuotingContext from "../OnlineQuotingContext";
import AddAdditionalClassCodes from "./classCode/AddAdditionalClassCodes";

const SelectedClass = () => {
  const { supportingData, step } = useContext(OnlineQuotingContext);
  return (
    <>
      <div className="oq__sidebar__summary__item">
        <b>Primary Class:</b>
        <div className="oq__sidebar__summary__item__selected-class">
          {supportingData?.classCode?.description}
        </div>
      </div>
      {((supportingData?.additionalClassCodes || []).length > 0 ||
        step === 5) && (
        <div className="oq__sidebar__summary__item">
          <b>Additional Classes:</b>
          <ul className="oq__sidebar__summary__additional-classes">
            {(supportingData?.additionalClassCodes || []).map(c => (
              <li key={`${c.code}${c.description}`}>
                <div className="oq__sidebar__summary__item__selected-class">
                  {c.description}
                </div>
              </li>
            ))}
          </ul>
          {step === 5 && <AddAdditionalClassCodes />}
        </div>
      )}
    </>
  );
};

export default SelectedClass;
