import { useEffect } from "react";
import { useCurrentRouteMatch } from "../hooks";

export const useContentRouteFetch = (
  isContentLoading,
  fetchContent,
  previousFetchedContentId
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteSubheaderId = currentMatch?.params?.subheaderId;
  const currentRouteSubheader2Id = currentMatch?.params?.subheader2Id;

  useEffect(() => {
    if (
      currentRouteSubheaderId &&
      !currentRouteSubheader2Id &&
      !isContentLoading &&
      previousFetchedContentId !== currentRouteSubheaderId
    ) {
      fetchContent(currentRouteSubheaderId);
    }
  }, [
    fetchContent,
    currentRouteSubheaderId,
    currentRouteSubheader2Id,
    isContentLoading,
    previousFetchedContentId
  ]);

  useEffect(() => {
    if (
      currentRouteSubheaderId &&
      currentRouteSubheader2Id &&
      !isContentLoading &&
      previousFetchedContentId !== currentRouteSubheader2Id
    ) {
      fetchContent(currentRouteSubheader2Id);
    }
  }, [
    fetchContent,
    currentRouteSubheaderId,
    currentRouteSubheader2Id,
    isContentLoading,
    previousFetchedContentId
  ]);

  return null;
};
