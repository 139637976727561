import React from "react";
import { Translations } from "../../../components/translations";
import { Tabs, Tab } from "@ufginsurance/ui-kit";
import AgencyStatementContainer from "../../containers/AgencyStatementContainer";
import PropTypes from "prop-types";
import AgencyBillEpayContainer from "./../../containers/AgencyBillEpayContainer";
import ElectronicPaymentsFormContainer from "./../../containers/ElectronicPaymentsFormContainer";
import AgencyBillPastDue from "./AgencyBillPastDue";
import { getActiveTabIndex, onTabClick } from "./../../../constants/routes";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const Accounting = ({ hasAgencyAccountingReportsAccess, match, history }) => {
  const onAccountingTabClick = tabIndex => {
    onTabClick(tabIndex, match, history);
  };

  return (
    <div className="accounting-container">
      <div>
        <Tabs activeTab={getActiveTabIndex(match)}>
          <Tab
            title={`${Translations.reports.tabAccounting.reconcile_Electronic_Payments}`}
            onClick={onAccountingTabClick}
          >
            <div>
              <div className="reports-above-form-message">
                <HelpDeskMessage display="questionsOrAssistance" />
              </div>
              <ElectronicPaymentsFormContainer />
            </div>
          </Tab>
          <Tab
            title={Translations.reports.tabAccounting.agency_Statements}
            onClick={onAccountingTabClick}
          >
            <div>
              <div className="reports-above-form-message">
                <HelpDeskMessage display="questionsOrAssistance" />
              </div>
              <span>
                <AgencyStatementContainer
                  hasAgencyAccountingReportsAccess={
                    hasAgencyAccountingReportsAccess
                  }
                />
              </span>
            </div>
          </Tab>
          <Tab
            title={Translations.reports.tabAccounting.agency_Bill_EPay}
            onClick={onAccountingTabClick}
          >
            <AgencyBillEpayContainer />
          </Tab>
          <Tab title="Agency Bill Past Due" onClick={onAccountingTabClick}>
            <AgencyBillPastDue />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

Accounting.propTypes = {
  hasAgencyAccountingReportsAccess: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};
export default Accounting;
