import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const quickSubmission = (state = initialState.quickSubmission, action) => {
  switch (action.type) {
    case types.UPDATE_SUBMISSION_ERROR:
      return {
        ...state,
        submissionData: null,
        prevSubmissionData: null,
        isLoading: false,
        error: action.payload
      };
    case types.UPDATE_SUBMISSION_MSG:
      return {
        ...state,
        isLoading: true,
        isLongSubmission: true
      };
    case types.POST_QUICK_ACCOUNT_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "account"
      };
    case types.POST_QUICK_ELIGIBILITY_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "eligibility"
      };
    case types.POST_QUICK_OBLIGEE_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "obligee"
      };
    case types.POST_QUICK_BOND_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "bond"
      };
    case types.POST_QUICK_OWNERS_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "owners"
      };
    case types.POST_QUICK_CREDIT_AUTHORIZATION_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "creditAuthorization"
      }
    case types.POST_QUICK_FINAL_STARTED:
      return {
        ...state,
        isLoading: true,
        currentStep: "final"
      };
    case types.POST_QUICK_QUOTE_SUCCEEDED:
      return {
        ...state,
        submissionData: action.payload.submissionResponse,
        prevSubmissionData: action.payload.prevSubmissionData,
        isLoading: false,
        error: null
      };
    case types.POST_QUICK_QUOTE_FAILED:
      return {
        ...state,
        submissionData: null,
        prevSubmissionData: null,
        isLoading: false,
        error: action.payload
      };

    // Create a general, post submission failed for all the various steps.
    default:
      return state;
  }
};

export default quickSubmission;
