import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "@ufginsurance/ui-kit";
import propTypes from "prop-types";
import { useSuretyHomeContext } from "../SuretyHomeProvider";
import {
  formatAccountsDropdownOptions,
  formatBondDropdownOption
} from "../../shared/utils";
import { debounceApi } from "../../../util/debounce";
import { getUWReviewBondInfo } from "../../../underwriter-review/uwReviewServices";
import { ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH } from "../../shared/constants";

export default function AccountBondSearchInput({
  label,
  form,
  onChangeHandler
}) {
  const { accounts, accountsIsLoading } = useSuretyHomeContext();
  const { values, errors, handleOnBlur, handleOnValidate, updateForm } = form;
  const [dropdownOptions, setDropdownOptions] = useState();
  const [data, setData] = useState();
  const [isBondLoading, setIsBondLoading] = useState(false);
  const [accountBondSearchInput, setAccountBondSearchInput] = useState();
  const cache = useRef({});

  const handleBondSearch = bondNumber => {
    setIsBondLoading(true);
    getUWReviewBondInfo(bondNumber)
      .then(res => {
        // getUWReviewBondInfo will return 200 even if there's no data.
        // Checking for existance of an account_number as a proxy for finding a bond
        if (res.status === 200 && res.data.account_number) {
          cache.current[bondNumber] = res.data;
          setData(res.data);
          setAccountBondSearchInput(null);
          setDropdownOptions(formatBondDropdownOption([res.data], bondNumber));
        }
      })
      .catch(err => {
        console.error("Bond not found: " + err);
      })
      .finally(() => setIsBondLoading(false));
  };

  const searchUsingDebounce = (method, value) =>
    debounceApi(method(value), 100);

  const handleAccountBondSearchInputChange = value => {
    // If value is potentially a bond number, perform a bond search
    if (value.length === 8) {
      searchUsingDebounce(handleBondSearch, value);
    } else if (data != null && accounts != null) {
      setData(null);
    } else if (data != null) {
      setData(null);
    }
    updateForm({
      values: {
        ...values,
        accountBondSearch: "",
        accountBondSearchInput: value
      }
    });
  };

  const handleAccountBondSearchOnChange = ({ value }) => {
    if (onChangeHandler) onChangeHandler(value);
    updateForm({
      values: {
        ...values,
        accountBondSearch: value,
        accountBondSearchInput: ""
      },
      errors: value?.length
        ? {
            bondTypeSelect: [],
            uwMessage: []
          }
        : errors
    });
  };

  const handleAccountBondSearchOnValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const regex = /[~=`{}_[\\^<>\]]/g;
    if (value && regex.test(value)) {
      fieldErrors.push(
        `Business Name can be alpha or numeric characters only.`
      );
    }
    return fieldErrors;
  };

  useEffect(() => {
    if (!data)
      setDropdownOptions(formatAccountsDropdownOptions(accounts || []));
  }, [accounts, data]);

  return (
    <Dropdown
      id="accountBondSearch"
      name="accountBondSearch"
      placeholder="Start typing the account name or bond number..."
      noLabel={!label}
      label={label}
      onChange={handleAccountBondSearchOnChange}
      onInputChange={handleAccountBondSearchInputChange}
      onBlur={handleOnBlur}
      onValidate={handleAccountBondSearchOnValidate}
      value={values.accountBondSearch || accountBondSearchInput}
      inputValue={
        values.accountBondSearchInput?.length <=
        ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH
          ? values.accountBondSearchInput
          : values.accountBondSearchInput?.substr(
              0,
              ACCOUNT_NAME_BOND_NUMBER_MAX_LENGTH
            )
      }
      options={dropdownOptions || []}
      size="auto"
      isSearchable
      isLoading={accountsIsLoading || isBondLoading}
      loadingMessage={() => "Searching..."}
      hideIndicatorUntilSearch
      isClearable
    />
  );
}

AccountBondSearchInput.propTypes = {
  form: propTypes.object.isRequired,
  label: propTypes.string,
  onChangeHandler: propTypes.func
};
