import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";
import "./docs-and-forms.scss";
import ButtonNavigation from "./components/ButtonNavigation";
import FilterForm from "./components/FilterForm";
import { SuretyDocsFormsProvider } from "./context";
import DocumentFormsTable from "./components/DocumentFormsTable";

export default function DocsAndForms({ ssoSession }) {
  return (
    <SuretyDocsFormsProvider ssoSession={ssoSession}>
      <ContentHeader>Surety Documents and Forms</ContentHeader>
      <div>
        <p>
          Refer to your Power of Attorney Agreement on limitations and
          instructions regarding your authority to execute bonds.
        </p>
        <p>
          Please reference your Line of Authority before issuing a bond. All bonds
          need to be approved by your underwriter if your account does not have a
          Line of Authority or the job size is outside your current Line of
          Authority.
        </p>
        </div>
      <ButtonNavigation />
      <FilterForm />
      <DocumentFormsTable />
    </SuretyDocsFormsProvider>
  );
}
