export class DocumentService {
  createNewWindow(url) {
    const newWindow = window.open(url);
    newWindow.document.title = "Loading document...";
    return newWindow;
  }

  closeNewWindow() {
    if (!this.newWindow) {
      return;
    }

    this.newWindow.close();
  }

  createBlob(blob, options) {
    return new Blob([blob], options);
  }

  createBlobURL(pdf) {
    return window.URL.createObjectURL(pdf);
  }

  initializeRenderer(url) {
    if (this.requiresNewWindow()) {
      this.newWindow = this.createNewWindow(url);
    }
  }

  hexStringToBytes = hexString => {
    if (!hexString) {
      return new Uint8Array();
    }

    const bytes = [];

    // hex string begins with 0x so we start at index 2 to skip these characters
    for (let i = 2; i < hexString.length; i += 2) {
      bytes.push(parseInt(hexString.substr(i, 2), 16));
    }

    return new Uint8Array(bytes);
  };

  base64ToBytes = base64 => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  fileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split("base64,")[1]);
      reader.onerror = error => reject(error);
    });
  };

  // TODO: We may want to look at using downloadjs-next or similar: https://www.npmjs.com/package/downloadjs-next
  saveOrOpenDocument(bytes, filename, mimeType = "application/pdf") {
    const blobOptions = { type: mimeType };

    const blob = this.createBlob(bytes, blobOptions);

    if (this.isEdgeOrIE()) {
      this.openAsMSBlobDownload(blob, filename);
    } else {
      const fileURL = this.createBlobURL(blob);
      if (this.isAndroidDevice()) {
        this.openAsHTML5Download(fileURL, filename);
      } else if (this.isChromeOnIOS()) {
        this.openAsDataURL(blob);
      } else {
        this.openAsBlobUri(fileURL);
      }
    }
  }

  clearRenderer() {
    if (this.requiresNewWindow()) {
      this.closeNewWindow();
    }
  }

  openAsDataURL(blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        const a = document.createElement("a");
        a.style.display = "none";
        a.setAttribute("href", reader.result);
        document.body.appendChild(a);
        a.click();
      },
      false
    );
    reader.readAsDataURL(blob);
  }

  openAsMSBlobDownload(blob, filename) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  }

  openAsBlobUri(fileURL) {
    if (!this.newWindow) window.location = fileURL;
    else this.newWindow.location = fileURL;
  }

  openAsHTML5Download(fileURL, filename) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = fileURL;
    a.download = filename;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
  }

  requiresNewWindow() {
    return (
      !this.isEdgeOrIE() && !this.isAndroidDevice() && !this.isChromeOnIOS()
    );
  }

  isEdgeOrIE() {
    return window.navigator && window.navigator.msSaveOrOpenBlob;
  }

  isAndroidDevice() {
    return window.navigator.userAgent.match(/Android/i);
  }

  isChromeOnIOS() {
    return (
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
      window.navigator.userAgent.match(/CriOS/i)
    );
  }
}
