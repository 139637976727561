import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import _equal from "lodash/isEqual";
import { useCMSContext } from "./context";
import CMSContent from "./CMSContent";
import Breadcrumbs from "./Breadcrumbs";
import TableOfContents from "./TableOfContents";
import {
  useCurrentRouteMatch,
  useOnlyBaseRoute
} from "../resources/agency-interface/Hooks/hooks";

const CMSLayout = ({
  children,
  baseRoute,
  fetchContent,
  fetchTableOfContents,
  defaultBreadcrumbText,
  service,
  companyBranch
}) => {
  const { cmsData, headerRoute } = useCMSContext();

  const history = useHistory();
  const currentMatch = useCurrentRouteMatch(baseRoute);
  const onlyBaseRouteMatch = useOnlyBaseRoute(baseRoute);

  const [currentHeader, setCurrentHeader] = useState(null);
  const [currentSubheader, setCurrentSubheader] = useState(null);

  const isNewPage =
    cmsData.tableOfContents.data.length === 0 ||
    !headerRoute.includes(baseRoute);

  const onBaseRouteClick = () => {
    history.push(baseRoute);
  };

  const headerLink = currentMatch.path
    .replace(":headerId", currentHeader?.id)
    .replace(":subheaderId", currentHeader?.subheaders?.[0]?.id);
  const subheaderLink = currentMatch.path
    .replace(":headerId", currentHeader?.id)
    .replace(":subheaderId", currentSubheader?.id);

  useEffect(() => {
    if (isNewPage) {
      fetchTableOfContents(service, companyBranch);
    }
  }, [
    baseRoute,
    cmsData.tableOfContents.data.length,
    companyBranch,
    fetchTableOfContents,
    headerRoute,
    isNewPage,
    service
  ]);

  // set current header and subheader
  useEffect(() => {
    if (!isNewPage) {
      const { headerId, subheaderId } = currentMatch.params;

      const header = !headerId
        ? cmsData.tableOfContents.data[0]
        : cmsData.tableOfContents.data.find(content => {
            return content.id === headerId;
          });

      const subheader = !subheaderId
        ? header?.subheaders[0]
        : header?.subheaders.find(content => {
            return content.id === subheaderId;
          });

      if (
        !_equal(header, currentHeader) &&
        !_equal(subheader, currentSubheader)
      ) {
        if (subheader) {
          fetchContent(subheader?.id, companyBranch);
        } else if (header) {
          fetchContent(header?.id, companyBranch);
        }

        setCurrentHeader(header);
        setCurrentSubheader(subheader);
      }
    }
  }, [
    cmsData.tableOfContents.data,
    companyBranch,
    currentHeader,
    currentMatch.params,
    currentSubheader,
    fetchContent,
    isNewPage
  ]);

  // redirect base route to first table of content route
  useEffect(() => {
    if (!isNewPage) {
      const defaultSubHeader = cmsData?.tableOfContents?.data[0]?.subheaders[0];

      const defaultHeader = !defaultSubHeader
        ? cmsData?.tableOfContents?.data[0]
        : cmsData?.tableOfContents?.data.find(s =>
            s.subheaders.some(b => b.id === defaultSubHeader.id)
          );

      if (onlyBaseRouteMatch && defaultHeader && defaultHeader) {
        history.replace(
          `${baseRoute}/header/${defaultHeader.id}/subheader/${defaultSubHeader.id}`
        );
      } else if (onlyBaseRouteMatch && defaultHeader) {
        history.replace(`${baseRoute}/header/${defaultHeader.id}`);
      }
    }
  }, [
    baseRoute,
    cmsData.tableOfContents.data,
    history,
    isNewPage,
    onlyBaseRouteMatch
  ]);

  return (
    <>
      <Breadcrumbs
        defaultBreadcrumbText={defaultBreadcrumbText}
        onBaseRouteClick={onBaseRouteClick}
        links={[
          {
            ...currentHeader,
            link: headerLink
          },
          {
            ...currentSubheader,
            link: subheaderLink
          }
        ]}
      />
      <CMSContent
        id={`cms-content-resoures-${defaultBreadcrumbText.replace(" ", "")}`}
        classNames={`cms-content cms-content--${defaultBreadcrumbText.replace(
          " ",
          ""
        )}`}
        content={cmsData.content.data?.display_text}
      />
      {children}
      <TableOfContents baseRoute={baseRoute} />
    </>
  );
};

CMSLayout.propTypes = {
  children: PropTypes.any,
  baseRoute: PropTypes.string.isRequired,
  fetchContent: PropTypes.func.isRequired,
  fetchTableOfContents: PropTypes.func.isRequired,
  defaultBreadcrumbText: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  companyBranch: PropTypes.string
};

export default CMSLayout;
