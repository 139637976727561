import { connect } from "react-redux";
import FeedbackModal from "./FeedbackModal";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  ...state.feedbackModalData,
  agency: sessionSelector.getActiveAgencyName(state),
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  agentFirstAndLastName: sessionSelector.getFirstAndLastName(state),
  agentEmail: sessionSelector.getEmail(state)
});

export default connect(mapStateToProps)(FeedbackModal);
