import { connect } from "react-redux";
import { copyAgencySweepPaymentRequestDetails } from "../complete-payment/agencySweepPaymentRequestDetailsAction";
import { fetchOasisInvoiceDetail } from "../complete-payment/agencySweepOasisInvoiceDetailAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import CompletePaymentOasis from "./CompletePaymentOasis";
import * as completePaymentSelector from "./completePaymentSelector";
import * as completePaymentOasisSelector from "./completePaymentOasisSelector";

const mapStateToProps = state => ({
  loading: completePaymentOasisSelector.getInvoiceDetailsLoading(state),
  error: completePaymentOasisSelector.getInvoiceDetailsError(state),
  hasBillingInformation: completePaymentOasisSelector.hasBillingInformation(
    state
  ),
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  agencySweepAccountNumber: completePaymentSelector.getAgencySweepAccountNumber(
    state
  ),
  hasDirectBillPayNowEnrollment: completePaymentSelector.hasDirectBillPayNowEnrollment(
    state
  ),
  isOasisAccount: completePaymentSelector.isOasisAccount(state),
  invoiceDetails: completePaymentOasisSelector.getInvoiceDetails(state),
  invoiceAccountNumber: completePaymentOasisSelector.getBillingInformationAccountNumber(
    state
  ),
  billingSummary: completePaymentOasisSelector.getBillingSummary(state)
});

const mapDispatchToProps = {
  fetchOasisInvoiceDetail,
  copyAgencySweepPaymentRequestDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePaymentOasis);
