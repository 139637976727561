import { v4 } from "uuid";

//injected in Containers via mapStateToProps

export const getAccountBilledCompletePaymentRows = state => {
  return hasAccountBilledTableDataStored(state)
    ? createAccountBilledTableRows(state)
    : null;
};

export const getPolicyBilledCompletePaymentRows = state => {
  return hasPolicyBilledTableDataStored(state)
    ? createPolicyBilledTableRows(state)
    : null;
};

//utility functions

export const hasAccountBilledTableDataStored = state => {
  return !!(
    state &&
    state.agencySweepAccountBillingActivity &&
    state.agencySweepAccountBillingActivity.billingActivity &&
    state.agencySweepAccountBillingActivity.billingActivity.accountBilled &&
    state.agencySweepAccountBillingActivity.billingActivity.accountBilled
      .length &&
    state.agencySweepAccountBillingActivity.accountData &&
    state.agencySweepAccountBillingActivity.accountData.accounts.account_number
  );
};

export const hasPolicyBilledTableDataStored = state => {
  return !!(
    state &&
    state.agencySweepAccountBillingActivity &&
    state.agencySweepAccountBillingActivity.billingActivity &&
    state.agencySweepAccountBillingActivity.billingActivity.policyBilled &&
    state.agencySweepAccountBillingActivity.billingActivity.policyBilled
      .length &&
    state.agencySweepAccountBillingActivity.accountData &&
    state.agencySweepAccountBillingActivity.accountData.accounts.account_number
  );
};

export const createAccountBilledTableRows = state => {
  const accountRow = {
    rowId: v4(),
    rowType: "account",
    account_number:
      state.agencySweepAccountBillingActivity.accountData.accounts
        .account_number,
    insured:
      state.agencySweepAccountBillingActivity.accountData.accounts
        .primary_account_name,
    min_due:
      state.agencySweepAccountBillingActivity.billingActivity
        .accountMinimumDueWithFees,
    balance:
      state.agencySweepAccountBillingActivity.billingActivity.accountBalance
  };
  const payablePolicies = state.agencySweepAccountBillingActivity.billingActivity.accountBilled.filter(
    policy => policy.pay_now
  );
  const policyRows = payablePolicies.map(policy => {
    const accountDataPolicies = state.agencySweepAccountBillingActivity.accountData.accounts.policy_summary.filter(
      pol => policy.policy_number === pol.policy_number
    );
    return {
      rowId: v4(),
      rowType: "policy",
      account_number:
        state.agencySweepAccountBillingActivity.accountData.accounts
          .account_number,
      expiration_date:
        accountDataPolicies &&
        accountDataPolicies[0] &&
        accountDataPolicies[0].expiration_date,
      ...policy
    };
  });
  return [accountRow, ...policyRows];
};

export const createPolicyBilledTableRows = state => {
  const payablePolicies = state.agencySweepAccountBillingActivity.billingActivity.policyBilled.filter(
    policy => policy.pay_now
  );
  const policyRows = payablePolicies.map(policy => {
    const accountDataPolicies = state.agencySweepAccountBillingActivity.accountData.accounts.policy_summary.filter(
      pol => policy.policy_number === pol.policy_number
    );
    return {
      rowId: v4(),
      ...policy,
      insured_name:
        (accountDataPolicies &&
          accountDataPolicies[0] &&
          accountDataPolicies[0].insured_name) ||
        state.agencySweepAccountBillingActivity.accountData.accounts
          .primary_account_name,
      expiration_date:
        accountDataPolicies &&
        accountDataPolicies[0] &&
        accountDataPolicies[0].expiration_date
    };
  });
  return policyRows;
};

export const createVerifyPaymentDetails = (
  formValues,
  accountBilledTableData,
  policyBilledTableData
) => {
  const accountsToPay =
    accountBilledTableData &&
    accountBilledTableData
      .filter(row => row.rowType === "account")
      .map(accountRow => ({
        accountPolicy: accountRow.account_number,
        payment_amount: formValues[accountRow.account_number],
        insured: accountRow.insured,
        minimum: accountRow.min_due,
        balance: accountRow.balance,
        billed_by_type: "account"
      })).filter(accountRow => !isNaN(parseFloat(accountRow.payment_amount)));
  const paymentLineItems = [];
  if (accountsToPay && accountsToPay.length) {
    paymentLineItems.push(accountsToPay[0]);
  }

  const policiesToPay =
    policyBilledTableData &&
    policyBilledTableData.map(policyRow => ({
      accountPolicy: policyRow.policy_number,
      payment_amount: formValues[policyRow.policy_number],
      insured: policyRow.insured_name,
      minimum: policyRow.min_due,
      balance: policyRow.balance,
      billed_by_type: "policy"
    }));
  if (policiesToPay && policiesToPay.length) {
    paymentLineItems.push(...policiesToPay);
  }

  return {
    lineItems: paymentLineItems,
    totalPaymentAmount: formValues.totalAmount
  };
};
