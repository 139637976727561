export const FETCH_UW_REVIEW_ACCOUNT_STARTED =
  "FETCH_UW_REVIEW_ACCOUNT_STARTED";
export const FETCH_UW_REVIEW_ACCOUNT_SUCCEEDED =
  "FETCH_UW_REVIEW_ACCOUNT_SUCCEEDED";
export const FETCH_UW_REVIEW_ACCOUNT_FAILED = "FETCH_UW_REVIEW_ACCOUNT_FAILED";

export const FETCH_UW_REVIEW_BOND_STARTED = "FETCH_UW_REVIEW_BOND_STARTED";
export const FETCH_UW_REVIEW_BOND_SUCCEEDED = "FETCH_UW_REVIEW_BOND_SUCCEEDED";
export const FETCH_UW_REVIEW_BOND_FAILED = "FETCH_UW_REVIEW_BOND_FAILED";

export const UW_REVIEW_DECISION_STARTED = "UW_REVIEW_DECISION_STARTED";
export const UW_REVIEW_DECISION_SUCCEEDED = "UW_REVIEW_DECISION_SUCCEEDED";
export const UW_REVIEW_DECISION_FAILED = "UW_REVIEW_DECISION_FAILED";

export const UW_REVIEW_WARNING_MESSAGES_STARTED =
  "UW_REVIEW_WARNING_MESSAGES_STARTED";
export const UW_REVIEW_WARNING_MESSAGES_SUCCEEDED =
  "UW_REVIEW_WARNING_MESSAGES_SUCCEEDED";
export const UW_REVIEW_WARNING_MESSAGES_FAILED =
  "UW_REVIEW_WARNING_MESSAGES_FAILED";
