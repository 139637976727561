import structuredClone from "@ungap/structured-clone";

/**
 *
 * @param {*} array
 * @returns an object:
 * {
 *  formData: {
 *   coverageControl: [...]
 *  }
 * }
 */

export const mergeExclusions = (array = []) => {
  // if not array or if array is empty, return empty object
  if (!Array.isArray(array) || !array.length) return {};

  /**
   * using structuredClone here to create a copy of the data...
   * otherwise, JavaScript will attempt to update the originating object
   * which we don't want to do, because this should output an original
   * set of data without affecting the inputted data
   */
  return structuredClone(array).reduce((acc, curr) => {
    // if accumulator is null or empty, then set to value to be the newer object
    if (!acc || Object.keys(acc).length === 0) return curr;

    const existingCovgs = acc?.formData?.coverageControl || [];
    const newCovgs = curr?.formData?.coverageControl || [];

    if (!acc?.formData) {
      acc.formData = { coverageControl: [] };
    }
    // loop thru coverages in newer object
    acc.formData.coverageControl = newCovgs.reduce(
      (existingCovgs, newCovg) =>
        mergeCoverageExclusions(existingCovgs, newCovg),
      existingCovgs
    );

    // Handle stateCoverageControl
    if (curr?.formData?.stateCoverageControl) {
      if (!acc?.formData?.stateCoverageControl) {
        acc.formData.stateCoverageControl = {};
      }
      Object.keys(curr.formData.stateCoverageControl).forEach(key => {
        if (acc.formData.stateCoverageControl[key] === undefined) {
          acc.formData.stateCoverageControl[key] = [];
        }
        const existingStateCovgs = acc.formData.stateCoverageControl[key] || [];
        const newStateCovgs = curr.formData.stateCoverageControl[key] || [];
        acc.formData.stateCoverageControl[key] = newStateCovgs.reduce(
          (existingStateCovgs, newCovg) =>
            mergeCoverageExclusions(existingStateCovgs, newCovg),
          existingStateCovgs
        );
      });
    }

    return acc;
  });
};

const mergeCoverageExclusions = (existingCovgs, newCovg) => {
  const foundCovg = existingCovgs.find(
    i => i.codeIdentifier === newCovg.codeIdentifier
  );
  const otherCovgs = existingCovgs.filter(
    c => c.codeIdentifier !== newCovg.codeIdentifier
  );

  if (foundCovg) {
    // merge together
    const updatedCoverage = { ...foundCovg, ...newCovg };

    // ...then look at the terms and merge them if they exist in the new object

    const existingTerms = foundCovg?.termControl || [];
    const newTerms = newCovg?.termControl || [];

    // if there are terms in the new coverage, then merge them with existing terms
    if (newTerms.length) {
      updatedCoverage.termControl = newTerms.reduce(
        (existingTerms, newTerm) => {
          const foundTerm = existingTerms.find(
            t => t.patternCode === newTerm.patternCode
          );
          const otherTerms = existingTerms.filter(
            t => t.patternCode !== newTerm.patternCode
          );

          if (foundTerm) {
            // merge together
            const updatedTerm = { ...foundTerm, ...newTerm };

            // ...then look at the options and merge them if they exist in the new object

            /**
             * there are 4 different ways to controlling options:
             * options
             * onlyShowOptions
             * lessThanOptions
             * greaterThanOptions
             *
             * ...merge each one if it exists
             */

            const optionKeys = [
              "options",
              "onlyShowOptions",
              "lessThanOptions",
              "greaterThanOptions"
            ];

            optionKeys.forEach(key => {
              const existingOptions = foundTerm?.[key] || [];
              const newOptions = newTerm?.[key] || [];

              // if there are options in the new term, then merge them with existing options
              if (newOptions.length) {
                updatedTerm[key] = newOptions.reduce(
                  (existingOptions, newOption) => {
                    /**
                     * in some cases, we use the code to filter...
                     * in others, we use the name of the option
                     */
                    const foundOption = existingOptions.find(o => {
                      if (o.name) return o.name === newOption.name;
                      else if (o.code) return o.code === newOption.code;
                      return false;
                    });
                    const otherOptions = existingOptions.filter(o => {
                      if (o.name) return o.name !== newOption.name;
                      if (o.code) return o.code !== newOption.code;
                      return false;
                    });

                    if (foundOption) {
                      // merge the latter option over the existing
                      const updatedOption = {
                        ...foundOption,
                        ...newOption
                      };
                      return [...otherOptions, updatedOption];
                    }
                    // else !foundOption
                    return [...existingOptions, newOption];
                  },
                  existingOptions
                );
              }
            });

            return [...otherTerms, updatedTerm];
          }
          // else !foundTerm
          return [...existingTerms, newTerm];
        },
        existingTerms
      );
    }

    return [...otherCovgs, updatedCoverage];
  }
  // new coverage, just add it
  return [...existingCovgs, newCovg];
};
