import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const quickOwnersList = (state = initialState.quickOwnersList, action) => {
  switch (action.type) {
    case types.FETCH_OWNERS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_OWNERS_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_OWNERS_FAILED:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: action.payload
      };
    case types.CLEAR_OWNERS:
      return initialState.quickOwnersList;

    default:
      return state;
  }
};

export default quickOwnersList;
