import React, { useCallback, useContext, useState } from "react";
import {
  Button,
  Modal,
  Form,
  useForm,
  FormGroup,
  Input,
  InputEmail
} from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import _equal from "lodash/isEqual";
import { quotePatch } from "../shared/quotePatching";

const EditCustomerName = ({ initialValues, onHide, quoteId }) => {
  const {
    step,
    supportingData,
    updateSupportingDataPromise,
    updateAccountholderEmail,
    patchQuotePromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [spinner, setSpinner] = useState();

  const onSubmit = useCallback(
    async ({ values }) => {
      // actions to take after the update is completed
      const afterUpdateSuccess = () => onHide();
      const afterUpdateFailure = ({ error }) =>
        toastErrr({
          action: "EditCustomerName > onSubmit",
          description: "unable to edit customer",
          error,
          misc: { values }
        });

      setSpinner(true);

      //set data based on subtype to be used later
      let newData = {};
      if (values.subtype === "Company")
        newData = { customerName: values.companyName };
      else
        newData = {
          firstName: values.firstName,
          lastName: values.lastName
        };

      // if we're in the quoting system pages
      if (step) {
        if (step < 3) {
          // update the supporting data
          updateSupportingDataPromise({
            dataToMergeAndSave: {
              customerInformation: {
                ...supportingData?.customerInformation,
                accountHolder: {
                  ...supportingData?.customerInformation?.accountHolder,
                  contactName: values.companyName,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  emailAddress1: values.emailAddress1
                }
              }
            }
          })
            .then(afterUpdateSuccess)
            .catch(afterUpdateFailure);
        } else {
          // use the useQuote patchQuote

          let success = true;

          /**
           * HANDLE THE EMAIL UPDATE
           */
          // if the email address field changed, then update the accountHolder contact
          if (initialValues?.emailAddress1 !== values.emailAddress1) {
            await updateAccountholderEmail({
              emailAddress1: values.emailAddress1
            }).catch(() => (success = false));
          }

          /**
           * HANDLE NAME UPDATE
           */
          if (
            initialValues?.companyName !== values.companyName ||
            initialValues?.firstName !== values.firstName ||
            initialValues?.lastName !== values.lastName
          ) {
            await patchQuotePromise({
              newData,
              quoteId,
              updateMessage: "Updating Customer"
            }).catch(() => (success = false));
          }

          if (success) afterUpdateSuccess();
          else afterUpdateFailure();
        }
      } else {
        // we're not in the quoting system pages
        // update using the static quotePatch function
        quotePatch({
          newData,
          quoteId,
          callback: ({ success, error }) => {
            if (success) afterUpdateSuccess();
            else if (error) afterUpdateFailure({ error });
          }
        });
      }
    },
    [
      step,
      onHide,
      toastErrr,
      updateSupportingDataPromise,
      supportingData?.customerInformation,
      initialValues?.emailAddress1,
      initialValues?.companyName,
      initialValues?.firstName,
      initialValues?.lastName,
      updateAccountholderEmail,
      patchQuotePromise,
      quoteId
    ]
  );

  // initialize the hook
  const form = useForm({ values: initialValues, onSubmit });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields
  } = form;

  const formNotChanged = _equal(initialValues, values);

  return (
    <Modal
      size="md"
      title="Edit Customer"
      className="oq__edit-customer__modal"
      body={
        <div>
          <Form context={form}>
            <FormGroup>
              {values.subtype === "Company" && (
                <Input
                  id="companyName"
                  name="companyName"
                  label="Customer Name"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.companyName}
                  size="lg"
                  required
                />
              )}
              {values.subtype === "Person" && (
                <>
                  <Input
                    id="firstName"
                    name="firstName"
                    label="Customer First Name"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.firstName}
                    required
                  />
                  <Input
                    id="lastName"
                    name="lastName"
                    label="Customer Last Name"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.lastName}
                    required
                  />
                </>
              )}
            </FormGroup>
            {step > 1 && (
              <FormGroup>
                <InputEmail
                  id="emailAddress1"
                  name="emailAddress1"
                  label="Policyholder Email Address"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.emailAddress1}
                  size="lg"
                />
                {!values.emailAddress1 && (
                  <>
                    <p>
                      An email address is optional to quote. To support
                      UFG&apos;s efforts to be paperless, an email address will
                      be required to bind.
                    </p>
                  </>
                )}
              </FormGroup>
            )}
            <FormGroup align="right">
              <Button variant="plain" onClick={onHide} disabled={spinner}>
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                disabled={spinner || !!invalidFields.length || formNotChanged}
                spinner={spinner}
              >
                Update
              </Button>
            </FormGroup>
          </Form>
        </div>
      }
      show
      onHide={onHide}
      altCloseMethod={false}
      closeIcon={false}
    />
  );
};

export default EditCustomerName;
