import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./../../../components/translations";
import AttachmentsTable from "./AttachmentsTable";
import DragAndDropAttachments from "./DragAndDropAttachments";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const ViewAttachments = props => {
  const {
    show,
    result,
    attachmentData,
    handleClose,
    fetchBlobAttachFile,
    removeAttachment,
    updateAttachment,
    uploadProgress
  } = props;

  const onHandleClose = e => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    handleClose();
  };

  const onSelectFiles = files => {
    const formData = new FormData();
    Object.values(files).forEach((data, i) => {
      formData.append(`file${i}`, data, data.name);
    });
    updateAttachment(formData);
  };

  return (
    <Modal
      show={show}
      onHide={onHandleClose}
      title={Translations.commercial_lines.view_attachments.header}
      body={
        <form>
          <p className="quote-num">Quote: {result && result.quote_number}</p>
          <AttachmentsTable
            canModify={result && result.can_modify_attachments}
            attachmentsList={attachmentData}
            fetchBlobAttachFile={fetchBlobAttachFile}
            removeAttachment={removeAttachment}
          />
          {result && result.can_modify_attachments && (
            <div className="attachment">
              <p>
                <i>
                  <strong>Note: </strong>
                  {
                    Translations.commercial_lines.view_attachments
                      .accepted_file_types
                  }
                </i>
              </p>

              <DragAndDropAttachments
                id="view-attachments-file-input"
                allowMultiple
                onSelectFiles={onSelectFiles}
                allowedFileTypes={[
                  "txt",
                  "rtf",
                  "pdf",
                  "jpg",
                  "gif",
                  "bmp",
                  "png",
                  "tif",
                  "doc",
                  "docx",
                  "xls"
                ]}
                uploadProgress={uploadProgress}
              />
            </div>
          )}
        </form>
      }
      footer={
        <FlexRow align="right">
          <Button type="button" variant="secondary" onClick={onHandleClose}>
            Close
          </Button>
        </FlexRow>
      }
    />
  );
};

ViewAttachments.propTypes = {
  handleClose: PropTypes.func.isRequired,
  fetchBlobAttachFile: PropTypes.func.isRequired,
  show: PropTypes.bool,
  result: PropTypes.object,
  attachmentData: PropTypes.array,
  removeAttachment: PropTypes.func,
  updateAttachment: PropTypes.func,
  uploadProgress: PropTypes.object
};

export default ViewAttachments;
