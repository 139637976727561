import { connect } from "react-redux";
import PolicyChangesForm from "./policyChangesForm";
import { activateOtherChangesModal } from "./other-changes/otherChangesModalActions";
import {
  processChange,
  createPolicyDraft,
  getChangeRequestEligibilityInfo
} from "./redux/policyChangesAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../selectors/agencySelectors";

const mapStateToProps = state => ({
  commercial_underwriter:
    sessionSelector.getCommercialUnderWriterUsername(state),
  agentCode: sessionSelector.getAgentCode(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  selectedAgencyCode: getSelectedAgencyCode(state)
});

const mapDispatchToProps = {
  processChange,
  activateOtherChangesModal,
  createPolicyDraft,
  getChangeRequestEligibilityInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyChangesForm);
