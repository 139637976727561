import React from "react";
import { Panel, Currency } from "@ufginsurance/ui-kit";

const JobSitePanel = ({ jobSite, footer, is109015Agent }) => {
  const titleInfo = (
    <>
      <div>
        {jobSite.street_address_or_description +
          " " +
          jobSite.city +
          ", " +
          jobSite.state +
          " " +
          jobSite.zip}
      </div>
      <div className="subtitle">{jobSite.project_description}</div>
    </>
  );
  return (
    <>
      <Panel className="panel__jobsite" title={titleInfo} footer={footer}>
        <div className="summary">
          <Panel className="leftSide" noBorder>
            <div>
              <p>
                <span>Protection Class:</span>
                <span>{jobSite.protection_class}</span>
              </p>
            </div>
            <div>
              <p>
                <span>Project Type:</span>
                <span>{jobSite.project_type}</span>
              </p>
            </div>
            <div>
              <p>
                <span>Construction Type:</span>
                <span>{jobSite.construction_type}</span>
              </p>
            </div>
            <div>
              <p>
                <span>Number of Stories:</span>
                <span>
                  {Number(jobSite.number_of_stories).toLocaleString()}
                </span>
              </p>
            </div>
          </Panel>
          <Panel className="rightSide" noBorder>
            <div>
              <p>
                <span>Building Limit:</span>
                <span>
                  <Currency
                    includeDecimals={false}
                    amount={jobSite.building_limit}
                  ></Currency>
                </span>
              </p>
            </div>
            <div>
              <p>
                <span>Policy Deductible:</span>
                <span>
                  <Currency
                    includeDecimals={false}
                    amount={jobSite.deductible}
                  ></Currency>
                </span>
              </p>
            </div>
            <div>
              <p>
                <span>Soft Costs Coverage:</span>
                <span>
                  <Currency
                    includeDecimals={false}
                    amount={jobSite.soft_cost_limit}
                  ></Currency>
                </span>
              </p>
            </div>
          </Panel>
          {is109015Agent && (
            <Panel highlight="blue">
              Job Site Premium Indication *for internal users*:{" "}
              {!!jobSite?.premium ? (
                <Currency amount={jobSite.premium} />
              ) : (
                "insufficient data for premium calculation."
              )}
            </Panel>
          )}
        </div>
      </Panel>
    </>
  );
};

export default JobSitePanel;
