import "./polyfills";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { unregister } from "./registerServiceWorker";
import configureStore from "./store";
import { logger } from "./loggers";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import LaunchDarklyContainer from "./LaunchDarklyContainer";
import { createRoot } from "react-dom/client";

// Unregister the service worker from create-react-app
unregister();

logger.initialize(
  window.env.REACT_APP_GA_TRACKING_ID,
  window.env.REACT_APP_GA_DEBUG,
  window.env.REACT_APP_GA_DIAGNOSTICS
);
logger.pageview(window.location.pathname + window.location.search);

const store = configureStore();

(async () => {
  const launchDarklyConfig = {
    clientSideID: window.env.REACT_APP_LAUNCHDARKLY_KEY,
    user: {
      key: "ufgAgentUser"
    },
    options: {}
  };
  const LaunchDarklyProvider = await asyncWithLDProvider(launchDarklyConfig);
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <LaunchDarklyProvider>          
          <LaunchDarklyContainer store={store} />
        </LaunchDarklyProvider>
      </BrowserRouter>
    </Provider>
  );
})();
