export const MAKE_A_PAYMENT_ACTIVATED = "MAKE_A_PAYMENT_ACTIVATED";
export const DISMISS_MAKE_A_PAYMENT = "DISMISS_MAKE_A_PAYMENT";
export const START_NEW_QUOTE = "START_NEW_QUOTE";
export const DISMISS_NEW_QUOTE = "DISMISS_NEW_QUOTE";
export const CHANGE_NEW_QUOTE_MODAL = "CHANGE_NEW_QUOTE_MODAL";
export const FETCH_PAYMENT_ELIGIBILITY_STARTED =
  "FETCH_PAYMENT_ELIGIBILITY_STARTED";
export const FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED =
  "FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED";
export const FETCH_PAYMENT_ELIGIBILITY_FAILED =
  "FETCH_PAYMENT_ELIGIBILITY_FAILED";
