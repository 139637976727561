import React, { useEffect, useState, useMemo } from "react";
import Proptypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/pro-regular-svg-icons";
import * as R from "ramda";
import { Translations } from "../../translations";

// We should have a list of valid mimetypes and compare with file.type instead of looking at file extension
const isValidFileType = R.curry((allowedExtensions, file) => {
  if (!file || !file.name) {
    return false;
  }

  const extension = R.last(file.name.split(".")).toLowerCase();
  return allowedExtensions.includes(extension);
});

const overrideEventDefaults = e => {
  e.preventDefault();
  e.stopPropagation();
};

const disableWindowDragOverEvent = () => {
  window.addEventListener("dragover", overrideEventDefaults);
};

const disableWindowDropEvent = () => {
  window.addEventListener("drop", overrideEventDefaults);
};

// Original implementation was taken mostly from the reference link below.
// Reference: https://spin.atomicobject.com/2018/09/13/file-uploader-react-typescript/
const DragAndDropAttachments = props => {
  const { id, allowedFileTypes, allowMultiple, uploadProgress, onSelectFiles } =
    props;

  const [dragEventCounter, setDragEventCounter] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadFailed, setUploadFailed] = useState(false);

  // Using a ref to fire the click event on the "hidden" file input field
  // when user clicks on the larger drag-and-drop area.
  const fileUploaderInput = React.createRef();

  const handleOnDragEnter = e => {
    overrideEventDefaults(e);
    setDragEventCounter(dragEventCounter + 1);
  };

  const handleOnDragLeave = e => {
    overrideEventDefaults(e);
    setDragEventCounter(dragEventCounter + 1);
  };

  const handleOnDrop = e => {
    overrideEventDefaults(e);
    setDragEventCounter(0);
    handleFileUpload(Array.from(e.dataTransfer.files));
  };

  const handleOnFileChange = e => {
    handleFileUpload(Array.from(e.target.files));
  };

  const handleFileUpload = data => {
    const validFiles = data.filter(isValidFileType(allowedFileTypes));
    if (validFiles && validFiles.length) {
      setFiles(validFiles);
      onSelectFiles(validFiles);
    }

    if (
      !validFiles ||
      validFiles.length === 0 ||
      validFiles.length < files.length
    ) {
      setUploadFailed(true);
    }
  };

  const handleClickToBrowse = () => {
    fileUploaderInput.current.value = "";
    fileUploaderInput.current.click();
  };

  const fileName = useMemo(() => {
    // This doesn't do anything in ufgMail because we're not uploading to server immediately
    // like we do in commercial lines.

    const uploadInProgress =
      files.length && uploadProgress && uploadProgress.uploading;

    return uploadInProgress
      ? "Uploading " + files.map(f => f.name).join(", ")
      : "Drop Attachment";
  }, [uploadProgress, files]);

  const fileTypes = useMemo(() => {
    return allowedFileTypes ? `.${allowedFileTypes.join(", .")}` : "*";
  }, [allowedFileTypes]);

  const fileUploadClasses = useMemo(() => {
    return uploadFailed ? "file-uploader file-upload-error" : "file-uploader";
  }, [uploadFailed]);

  useEffect(() => {
    if (uploadFailed) {
      console.log("\n\nthe upload did fail!");
      const _clearErrorState = () => {
        setUploadFailed(false);
      };

      setTimeout(_clearErrorState, 2000);
    }
  }, [uploadFailed]);

  useEffect(() => {
    disableWindowDragOverEvent();
    disableWindowDropEvent();

    return () => {
      window.removeEventListener("dragover", overrideEventDefaults);
      window.removeEventListener("drop", overrideEventDefaults);
    };
  }, []);

  return (
    <div
      className={fileUploadClasses}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
    >
      <div
        className="file-uploader__contents"
        role="presentation"
        onClick={handleClickToBrowse}
      >
        <span className="file-uploader__file-name">
          {!uploadFailed && (
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className="file-uploader__icon"
            />
          )}
          <span
            id={`file-upload_wrapper-${id}`}
            className="file-upload_wrapper"
          >
            {!uploadFailed ? (
              <div>{fileName}</div>
            ) : (
              <div>
                {
                  Translations.commercial.new_submissions_tab.attachments
                    .uploadError
                }
              </div>
            )}
            <div className="file-uploader_text">
              {
                Translations.commercial.new_submissions_tab.attachments
                  .clickToBrowse
              }
            </div>
          </span>
        </span>
      </div>
      <input
        aria-labelledby={`file-upload_wrapper-${id}`}
        ref={fileUploaderInput}
        type="file"
        className="file-uploader__input"
        accept={fileTypes}
        multiple={allowMultiple}
        onChange={handleOnFileChange}
      />
    </div>
  );
};

DragAndDropAttachments.propTypes = {
  id: Proptypes.string.isRequired,
  onSelectFiles: Proptypes.func,
  allowedFileTypes: Proptypes.array,
  uploadProgress: Proptypes.object,
  allowMultiple: Proptypes.bool
};

DragAndDropAttachments.defaultProps = {
  onSelectFiles: () => {}
};

export default DragAndDropAttachments;
