import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import AccountServices from "./AccountServices";
import {
  fetchAutoIdCards,
  fetchAccountServiceLinks,
  fetchDriversListReport,
  fetchVehiclesListReport
} from "../../../actions/accountActions";
import { getisSpecialtyPortalView } from "../../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  actions: state.accountData.accountServiceLinks.actions,
  isLoading: state.accountData.accountServiceLinks.isLoading,
  requestedPolicyNumber:
    state.accountData.accountServiceLinks.requestedPolicyNumber,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  insuredName: state.accountData.accounts.primary_account_name,
  activeAgencyName: sessionSelector.getActiveAgencyName(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  driversListReportError: state.accountData.driversListReport.error,
  isDriversListReportLoading: state.accountData.driversListReport.isLoading,
  vehiclesListReportError: state.accountData.vehiclesListReport.error,
  isVehiclesListReportLoading: state.accountData.vehiclesListReport.isLoading
});

const mapDispatchToProps = {
  fetchAutoIdCards,
  fetchAccountServiceLinks,
  fetchDriversListReport,
  fetchVehiclesListReport
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountServices);
