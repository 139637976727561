export const validateAccountHasBond = state => {
  //If account has bond number
  return !!state?.quickSubmission?.submissionData?.bond_number;
};

export const checkSubmissionStatusPended = state => {
  //checking submission status approved
  return state?.quickSubmission?.submissionData?.submission_status === "Pended";
};

export const checkIsItNewAccount = state => {
  //checking new account
  return state?.quickSubmission?.submissionData?.is_new_account === true;
};

export const checkBondHasBidBond = state => {
  //checking bid bond
  return state?.quickSubmission?.submissionData?.type_of_bond === "B";
};

export const checkBondHasPerformanceAndPayment = state => {
  //checking p&p bond
  return state?.quickSubmission?.submissionData?.type_of_bond === "P";
};
