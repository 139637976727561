import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../translations";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const DriversListErrorModal = ({
  onClose,
  show,
  policyNumber
}) => {

  return (
    <div>
      <Modal
        body={
          <div className="print-id-cards__table">
            {Translations.account_overview.accountServices.drivers_list_no_report}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
            >
              {Translations.general.close}
            </Button>
          </FlexRow>
        }
        title={
          <>
            {Translations.account_overview.accountServices.drivers_list_report_header}
            {policyNumber}
          </>
        }
        show={show}
        onHide={onClose}
      />
    </div>
  );
};

DriversListErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  policyNumber: PropTypes.string.isRequired
};

export default DriversListErrorModal;
