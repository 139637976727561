import React, { useState, useCallback, useContext } from "react";
import _equal from "lodash/isEqual";
import ClassCodes from "./ClassCodes";
import OqModal from "../../shared/OqModal.js";
import EligibilityQuestions from "./EligibilityQuestions";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const ClassCodeWizardTitles = [
  { step: 1, title: " Select Additional Class Codes" },
  { step: 2, title: " Answer Eligibility Questions" }
];

const ClassCodeWizard = ({ show, onHide }) => {
  const {
    quoteData,
    supportingData,
    patchQuote,
    updateSupportingDataPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const setTitle = useCallback(step => {
    const item = ClassCodeWizardTitles.find(d => d.step === step);
    if (item) {
      return setCurrentTitle(item.title);
    }
    return setCurrentTitle("");
  }, []);

  const [currentStep, setStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState(
    ClassCodeWizardTitles[0].title
  );

  const totalSteps = 2;

  const onCancel = useCallback(
    callback => {
      const confirmedClasses = [
        ...(supportingData?.additionalClassCodes || []).filter(
          c =>
            !(supportingData?.tempAdditionalClassCodes || [])
              .map(x => x.code)
              .includes(c.code)
        )
      ];
      const newData = {
        classCode: supportingData?.classCode,
        additionalClassCodes: confirmedClasses
      };

      // if we have already added a class to the quote, then run patch quote to remove it
      if (
        quoteData?.quoteID &&
        !!(supportingData?.tempAdditionalClassCodes || []).length
      ) {
        patchQuote({
          newData,
          quoteId: quoteData.quoteID,
          callback: ({ success, results, error }) => {
            // check the respnose to make sure that the class codes in the response match
            // what we think is to be true
            const responseDataIsCorrect = _equal(
              results.data.supportingData.additionalClassCodes,
              confirmedClasses
            );

            // if the update of the classes results in what we expect, then it was a success
            // ... do a save to update the "tempAdditionalClassCodes"
            if (success && responseDataIsCorrect) {
              updateSupportingDataPromise({
                dataToMergeAndSave: {
                  ...results.data.supportingData,
                  tempAdditionalClassCodes: null
                }
              }).then(() => {
                if (callback) callback();
                onHide();
              });
            } else {
              // we got results that we didn't expect, show an error and stop the spinner
              toastErrr({
                displayMessage: `An error occurred.  Unable to update class data.`,
                action: "update classes",
                payload: newData,
                error,
                misc: {
                  additionalClassCodes:
                    results?.data?.supportingData?.additionalClassCodes
                },
                description:
                  "cancellation of classes on step 5 returned unexpected results"
              });
            }
          }
        });
      } else {
        // if we haven't updated the quote object at all, then just close the modal
        if (callback) callback();
        onHide();
      }
    },
    [
      toastErrr,
      onHide,
      patchQuote,
      quoteData.quoteID,
      supportingData,
      updateSupportingDataPromise
    ]
  );

  const onNextStep = useCallback(() => {
    if (currentStep + 1 <= totalSteps) {
      const st = currentStep + 1;
      setTitle(st);
      setStep(st);
    } else if (currentStep + 1 > totalSteps) {
      onHide();
    }
  }, [currentStep, onHide, setTitle]);

  const onPrevStep = useCallback(() => {
    if (currentStep - 1 > 0) {
      const st = currentStep - 1;
      setTitle(st);
      setStep(st);
    }
  }, [currentStep, setTitle]);

  const renderStepForm = useCallback(
    currentStep => {
      switch (currentStep) {
        case 1:
          return <ClassCodes onNextStep={onNextStep} onCancel={onCancel} />;

        case 2:
          return (
            <EligibilityQuestions
              onNextStep={onNextStep}
              onPrevStep={onPrevStep}
              onCancel={onCancel}
            />
          );
        default:
          onHide(); //if you are here closes the dialog.
      }
    },
    [onNextStep, onPrevStep, onCancel, onHide]
  );

  return (
    <OqModal
      className="oq__modal__add-class"
      title={`Step ${currentStep} of ${totalSteps}: ${currentTitle}`}
      show={show}
      onHide={onHide}
      body={<div>{renderStepForm(currentStep)}</div>}
      closeIcon={false}
    />
  );
};

export default ClassCodeWizard;
