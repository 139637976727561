//injected in Containers via mapStateToProps

export const hasDirectBillPayNowEnrollment = state => {
  return !!(
    state &&
    state.directBillPayNowEnrollment &&
    state.directBillPayNowEnrollment.enrollment &&
    state.directBillPayNowEnrollment.enrollment.token
  );
};

export const getAgencySweepAccountNumber = state => {
  return (
    state &&
    state.agencySweepAccountBillingActivity.accountData &&
    state.agencySweepAccountBillingActivity.accountData.accounts &&
    state.agencySweepAccountBillingActivity.accountData.accounts.account_number
  );
};

export const hasAccountAndBillingInformation = state => {
  return !!(
    state &&
    state.agencySweepAccountBillingActivity &&
    state.agencySweepAccountBillingActivity.accountData &&
    state.agencySweepAccountBillingActivity.billingActivity
  );
};

export const isOasisAccount = state => {
  return !!(
    state &&
    state.agencySweepAccountBillingActivity &&
    state.agencySweepAccountBillingActivity.accountData &&
    state.agencySweepAccountBillingActivity.accountData.accounts &&
    state.agencySweepAccountBillingActivity.accountData.accounts.origin ===
      "oasis"
  );
};

export const getDirectBillPaymentMethodToken = state => {
  return (
    state &&
    state.directBillPayNowEnrollment &&
    state.directBillPayNowEnrollment.enrollment &&
    state.directBillPayNowEnrollment.enrollment.token
  );
};

//utility functions

export const validateAccountNumberMatch = (
  uriAccountNumber,
  agencySweepAccountNumber
) => {
  return (
    agencySweepAccountNumber === uriAccountNumber ||
    (parseFloat(agencySweepAccountNumber) === parseFloat(uriAccountNumber) &&
      !isNaN(parseFloat(uriAccountNumber)))
  );
};
