//injected in Containers via mapStateToProps

export const getPaymentInformation = state => {
  return (
    state &&
    state.agencySweepPaymentRequestDetails &&
    state.agencySweepPaymentRequestDetails.agencySweepPaymentInfo
  );
};

//utility functions

export const createPaymentSubmissionRequest = (
  agencyCode,
  agentFullName,
  agencySweepPaymentInfo,
  directBillPaymentMethodToken,
  formValues
) => {
  return {
    agency_code: agencyCode,
    agent_name: agentFullName,
    insured_name:
      agencySweepPaymentInfo &&
      agencySweepPaymentInfo.lineItems &&
      agencySweepPaymentInfo.lineItems.length &&
      agencySweepPaymentInfo.lineItems[0].insured,
    send_confirmation_email: formValues.send_confirmation_email,
    confirmation_to: formValues.emails.trim().split(","),
    payment_amount:
      parseFloat(
        agencySweepPaymentInfo && agencySweepPaymentInfo.totalPaymentAmount
      ) &&
      parseFloat(
        parseFloat(
          agencySweepPaymentInfo && agencySweepPaymentInfo.totalPaymentAmount
        ).toFixed(2)
      ),
    payment_method_token: directBillPaymentMethodToken,
    line_items:
      agencySweepPaymentInfo &&
      agencySweepPaymentInfo.lineItems &&
      agencySweepPaymentInfo.lineItems.length &&
      agencySweepPaymentInfo.lineItems.map(lineItem => ({
        policy_account_number: lineItem.accountPolicy,
        billed_by_type: lineItem.billed_by_type,
        payment_amount:
          Number(lineItem.payment_amount) &&
          parseFloat(Number(lineItem.payment_amount).toFixed(2)),
        balance_due:
          Number(lineItem.minimum) &&
          parseFloat(Number(lineItem.minimum).toFixed(2)),
        total_balance:
          Number(lineItem.balance) &&
          parseFloat(Number(lineItem.balance).toFixed(2))
      }))
  };
};
