import React, { useEffect, useState, useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Modal,
  ContentHeader,
  useForm,
  Form,
  Panel,
  FormGroup,
  DatePicker,
  Dropdown,
  Alert,
  Button,
  FlexRow,
  formatDate
} from "@ufginsurance/ui-kit";
import { typeOfBond } from "../constants/suretyDictionary";
import PropTypes from "prop-types";
import ProjectInformation from "./ProjectInformation";
import Obligee from "./Obligee";
import Address from "../shared/Address";
import * as routes from "../../../constants/routes";
import { toTitleCase } from "../../../components/Factory";
import VerifyAddress from "../shared/AddressVerification/VerifyAddress";
import BottomNav from "../shared/BottomNav";
import { getAllowExistingObligeePoBoxOnly } from "../shared/util";

// update Effective Date to today and set min/max
let today = new Date();
const minDate = new Date();
minDate.setDate(today.getDate());
const maxDate = new Date();
maxDate.setDate(today.getDate() + 30);
today = formatDate(new Date(Date.now()), "YYYY-MM-DD");

const BondInfo = ({
  activeAgencyCode,
  agencyStates,
  cacheId,
  fetchBondObligeeList,
  fetchSuretyBondForm,
  fetchSuretyQuoteAgencyData,
  history,
  quickBondObligeeList,
  setObligeeSearchIsLoading,
  suretyBondFormListData,
  suretyQuote,
  updateSuretyQuoteData
}) => {
  const { suretyWhitelistNysEnabled } = useFlags();
  const bondInfo = !!suretyQuote && suretyQuote.bond_information;

  const initialValues = {
    effective_date: bondInfo?.effective_date ?? today,
    type_of_bond: bondInfo?.type_of_bond ?? "",
    zip: bondInfo?.zip ?? "",
    city: bondInfo?.city ?? "",
    state: bondInfo?.state ?? "",
    bond_form: bondInfo?.bond_form ?? "",
    form_number: "",
    edition_date: "",
    obligee_name: bondInfo?.obligee_name ?? "",
    obligee_account_number: bondInfo?.obligee_account_number ?? "",
    obligee_type: bondInfo?.obligee_type ?? "",
    obligee_address_is_pobox: bondInfo?.obligee_address_is_pobox ?? false,
    project_description: bondInfo?.project_description ?? "",
    project_completion_date: bondInfo?.project_completion_date ?? "",
    obligee_address_line: bondInfo?.obligee_address_line ?? "",
    obligee_building_number: bondInfo?.obligee_building_number ?? "",
    obligee_street_name: bondInfo?.obligee_street_name ?? "",
    obligee_unit_type: bondInfo?.obligee_unit_type ?? "",
    obligee_unit_number: bondInfo?.obligee_unit_number ?? "",
    obligee_pobox: bondInfo?.obligee_pobox ?? "",
    obligee_zip: bondInfo?.obligee_zip ?? "",
    obligee_city: bondInfo?.obligee_city ?? "",
    obligee_state: bondInfo?.obligee_state ?? "",
    obligee_confidence: bondInfo?.obligee_confidence ?? 0,
    obligee_verify_date: bondInfo?.obligee_verify_date ?? today,
    maintenance_period: bondInfo?.maintenance_period ?? "",
    liquidated_damages: bondInfo?.liquidated_damages ?? "",
    bid_date: bondInfo?.bid_date ?? "",
    bid_estimate: bondInfo?.bid_estimate ?? "",
    bid_bond: bondInfo?.bid_bond ?? "Percent",
    bid_bond_amount: bondInfo?.bid_bond_amount ?? "",
    bid_bond_percent: bondInfo?.bid_bond_percent ?? "",
    contract_date: bondInfo?.contract_date ?? "",
    contract_amount: bondInfo?.contract_amount ?? "",
    second_lowest_bidder: bondInfo?.second_lowest_bidder ?? ""
  };

  const backToEligibility = () => {
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      bond_information: {
        ...form.values,
        bondInfoPreventReload: Boolean(form.values.bond_form.length)
      },
      hasBond: true
    }).then(() => history.push(routes.setStepUrl(3)));
  };

  const skipBondInfo = () => {
    //should this get erased? what if they change their mind after entering info but skipping?
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      bond_information: null,
      hasBond: false
    });
    history.push(routes.setStepUrl(5));
  };

  const [addressToVerify, setAddressToVerify] = useState();

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const handleFormSubmit = fields => {
    const isAddressRequiringVerification =
      !values.obligee_address_is_pobox &&
      values.obligee_account_number === "0000000000";
    if (isAddressRequiringVerification) {
      setAddressToVerify({
        address_line: values.obligee_address_line,
        city: values.obligee_city,
        state: values.obligee_state,
        zip: values.obligee_zip
      });

      setShowVerifyModal(true);
    } else {
      updateSuretyQuoteData(cacheId, {
        ...suretyQuote,
        bond_information: fields.values,
        hasBond: true
      });
      history.push(routes.setStepUrl(5));
    }
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleFormSubmit
  });

  const {
    values,
    errors,
    updateForm,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields
  } = form;

  const [formInitialized, setFormInitialized] = useState(false);
  const [showTypeOfBond, setShowTypeOfBond] = useState(typeOfBond);
  const [preventReload, setPreventReload] = useState(
    bondInfo?.bondInfoPreventReload ?? false
  );
  const [showBidBond, setShowBidBond] = useState(false);
  const [showPerformancePayment, setShowPerformancePayment] = useState(false);
  const [readyToContinue, setReadyToContinue] = useState(false);
  const maintenancePeriodMin = 12;

  const handleObligeeContinue = verifiedAddress => {
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      bond_information: {
        ...values,
        obligee_address_line: verifiedAddress.address_line,
        obligee_building_number: verifiedAddress.building_number,
        obligee_street_name: verifiedAddress.street_name,
        obligee_unit_type: verifiedAddress.unit_type,
        obligee_unit_number: verifiedAddress.unit_number,
        obligee_pobox: verifiedAddress.pobox,
        obligee_city: verifiedAddress.city,
        obligee_state: verifiedAddress.state,
        obligee_zip: verifiedAddress.zip,
        obligee_confidence: verifiedAddress.confidence,
        obligee_verify_date: verifiedAddress.verify_date
      },
      hasBond: true
    });
    history.push(routes.setStepUrl(5));
  };

  const updateAddressFormFields = useCallback(
    ({ address }) => {
      updateForm({
        values: {
          obligee_address_line: toTitleCase(address.address_line),
          obligee_city: toTitleCase(address.city),
          obligee_state: address.state,
          obligee_zip: address.zip,
          obligee_building_number: address.building_number,
          obligee_street_name: address.street_name,
          obligee_unit_type: address.unit_type,
          obligee_unit_number: address.unit_number,
          obligee_pobox: address.pobox,
          obligee_verify_date: address.verify_date
        }
      });
    },
    [updateForm]
  );

  const toggleBidBondAndPerformancePayment = value => {
    if (value === "B") {
      setShowBidBond(true);
      setShowPerformancePayment(false);
    } else if (value === "P") {
      setShowBidBond(false);
      setShowPerformancePayment(true);
    } else {
      setShowBidBond(false);
      setShowPerformancePayment(false);
    }
  };

  const getDefaultBondForm = bondType =>
    bondType === "B"
      ? "BID BOND"
      : bondType === "P"
      ? "PERFORMANCE AND PAYMENT BOND"
      : "";

  const formatBondFormDropdown = forms => {
    const primaryBondFormLabel = getDefaultBondForm(values.type_of_bond);
    const primaryBond = forms.filter(
      o => o.form_description === primaryBondFormLabel
    );
    const restBonds = forms
      .filter(o => o.form_description !== primaryBondFormLabel)
      .sort((a, b) =>
        a.form_description > b.form_description
          ? 1
          : a.form_description < b.form_description
          ? -1
          : 0
      );
    const formsData = [...primaryBond, ...restBonds];
    return formsData.map(form => ({
      value: form.form_description,
      label: form.form_description
    }));
  };

  // Get agency's licensed states list.
  useEffect(() => {
    if (!agencyStates?.length) {
      fetchSuretyQuoteAgencyData(activeAgencyCode);
    }
  }, [activeAgencyCode, agencyStates, fetchSuretyQuoteAgencyData]);

  useEffect(() => {
    toggleBidBondAndPerformancePayment(values.type_of_bond);
  }, [values.type_of_bond]);

  useEffect(() => {
    if (values.bond_form) {
      const form = suretyBondFormListData?.bondFormData?.find(form => {
        return form.form_description === values.bond_form;
      });

      if (
        values.form_number !== form?.form_number &&
        values.edition_date !== form?.edition_date
      )
        updateForm({
          values: {
            form_number: form?.form_number,
            edition_date: form?.edition_date
          }
        });
    }
  }, [
    suretyBondFormListData?.bondFormData,
    updateForm,
    values.bond_form,
    values.edition_date,
    values.form_number
  ]);

  const handleOnEffDateValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({
      field,
      value,
      validation
    });
    const tmpDate = new Date(value);
    const todayDate = new Date();

    if (errors?.effective_date?.length > 0) delete errors.maintenance_period;
    if (tmpDate.getDate() >= todayDate.getDate() && fieldErrors.length > 0)
      fieldErrors.splice(0, 1);
    return fieldErrors;
  };

  const handleBondFormValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!values.bond_form) {
      delete errors[field];
    }
    return fieldErrors;
  };

  useEffect(() => {
    if (preventReload) {
      setPreventReload(false);
    } else {
      updateForm({
        values: {
          bond_form: "",
          form_number: "",
          edition_date: ""
        }
      });

      if (
        !!values.type_of_bond &&
        !!values.state &&
        !!values.effective_date &&
        !preventReload
      ) {
        fetchSuretyBondForm(
          typeOfBond.find(s => s.value === values.type_of_bond).enum,
          values.state,
          formatDate(values.effective_date, "YYYY-MM-DD")
        ).then(() => {
          updateForm({
            values: {
              bond_form: getDefaultBondForm(values.type_of_bond)
            }
          });
        });
      }
    }
    setShowTypeOfBond(showTypeOfBond);
    //Developer's Note: Disabled linter to be able to prevent the reload of the BondForm dropdown when coming from another page with valid information
    //eslint-disable-next-line
  }, [
    fetchSuretyBondForm,
    showTypeOfBond,
    values.type_of_bond,
    values.state,
    values.effective_date,
    bondInfo
  ]);

  const isBondFormDropdownEnabled =
    !!values.type_of_bond && !!values.state && !!values.effective_date;

  useEffect(() => {
    const allowExistingObligeePoBoxOnly =
      getAllowExistingObligeePoBoxOnly(values);
    setReadyToContinue(
      Object.keys(errors).length === 0 &&
        (invalidFields.length === 0 ||
          // Allow an existing obligee with only a pobox and no street address.
          (invalidFields.length === 1 &&
            invalidFields?.some(
              field => field.name === "obligee_address_line"
            ) &&
            allowExistingObligeePoBoxOnly))
    );
  }, [values, errors, invalidFields]);

  useEffect(() => {
    if (!formInitialized && bondInfo) {
      toggleBidBondAndPerformancePayment(bondInfo.type_of_bond);
    }

    // set the form to be initialized so that individual field changes update the form
    setFormInitialized(true);
  }, [formInitialized, bondInfo]);

  const handleEffectiveDateOnChange = Input => {
    if (
      !!values.maintenance_period &&
      values.maintenance_period < maintenancePeriodMin
    ) {
      updateForm({
        errors: {
          maintenance_period: [
            `This field must be a numeric and must be 12 or greater`
          ]
        }
      });
    } else {
      updateForm({
        errors: { maintenance_period: [] }
      });
    }
    handleOnChange(Input);
  };

  const handleAddressIsPOBoxChange = event => {
    const isPOBox = !!event?.value;
    updateForm({
      values: {
        ...values,
        obligee_address_line: "",
        obligee_building_number: "",
        obligee_street_name: "",
        obligee_unit_number: "",
        obligee_unit_type: "",
        obligee_pobox: "",
        obligee_confidence: isPOBox ? 100 : 0,
        obligee_verify_date: formatDate(new Date(Date.now()), "YYYY-MM-DD")
      },
      errors: {
        ...errors,
        obligee_address_line: [],
        obligee_city: [],
        obligee_state: [],
        obligee_zip: []
      }
    });
    handleOnChange(event);
  };

  return (
    <>
      <ContentHeader>Now we&apos;re down to the details</ContentHeader>
      <Alert id="warning_msg" className="qq__alert">
        If you don&apos;t see your form, select a form that is a close match to
        the form you need to issue.
        <br />
        Use &ldquo;Upload Documents&rdquo; option on the Confirmation screen to
        send us a copy of the form you intend to use.
      </Alert>
      <Form className="qq__form__step1 qq__form" context={form}>
        <Panel rounded bgcolor="grey" title="Enter bond information" titlebar>
          <FormGroup>
            <DatePicker
              id="effective_date"
              name="effective_date"
              label="Bond Effective Date"
              onChange={handleEffectiveDateOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnEffDateValidate}
              value={values.effective_date}
              required
              minDate={minDate}
              maxDate={maxDate}
              format="YYYY-MM-DD"
            />
            <Dropdown
              id="type_of_bond"
              name="type_of_bond"
              label="Type of Bond"
              placeholder=""
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.type_of_bond}
              options={showTypeOfBond || []}
              className="grow"
              size="lg"
              required
            />
          </FormGroup>
          <Address
            form={form}
            agencyStates={(agencyStates || []).map(a => ({
              value: a,
              label: a
            }))}
            activeAgencyCode={activeAgencyCode}
            showBondLabelAddress
            disableProjectCity
            disableProjectState
            checkIsNonSuretyState={!suretyWhitelistNysEnabled}
            checkIsAgencyState
          />
          <FormGroup>
            <Dropdown
              id="bond_form"
              name="bond_form"
              label="Bond Form"
              placeholder=""
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleBondFormValidate}
              value={values.bond_form}
              options={formatBondFormDropdown(
                suretyBondFormListData.bondFormData
              )}
              className=""
              size="lg"
              isLoading={suretyBondFormListData.isLoading}
              disabled={!isBondFormDropdownEnabled}
              loadingMessage={() => {
                return "Searching for bond form...";
              }}
              required
            />
          </FormGroup>
        </Panel>
        <Obligee
          form={form}
          formInitialized={formInitialized}
          activeAgencyCode={activeAgencyCode}
          agencyStates={agencyStates}
          fetchBondObligeeList={fetchBondObligeeList}
          quickBondObligeeList={quickBondObligeeList}
          bondInfo={bondInfo}
          setObligeeSearchIsLoading={setObligeeSearchIsLoading}
          handleAddressIsPOBoxChange={handleAddressIsPOBoxChange}
        />
        <ProjectInformation
          form={form}
          showBidBond={showBidBond}
          showPerformancePayment={showPerformancePayment}
          bondInfo={bondInfo}
          effDate={values.effective_date}
          maintenancePeriodMin={maintenancePeriodMin}
        />
        <BottomNav leftNav>
          <FlexRow className="nav-button__wrapper">
            <Button isLink onClick={backToEligibility}>
              Previous
            </Button>
            <div className="align-right">
              <Button isLink onClick={skipBondInfo}>
                Skip Bond Information and Qualify Only
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!readyToContinue}
              >
                Continue to Review Information
              </Button>
            </div>
          </FlexRow>
        </BottomNav>
      </Form>
      {showVerifyModal && (
        <Modal
          show
          altCloseMethod={false}
          onHide={() => {
            setShowVerifyModal(false);
          }}
          title="Verify Addresses"
          className="qq__verify-address__modal"
          size="lg"
          closeIcon={false}
          body={
            <VerifyAddress
              updateAddressFormFields={updateAddressFormFields} //Ill get back to this
              addressToVerify={addressToVerify}
              setAddressToVerify={setAddressToVerify}
              handleContinue={handleObligeeContinue}
              setShowVerifyModal={setShowVerifyModal}
            />
          }
        />
      )}
    </>
  );
};

export default BondInfo;

BondInfo.propTypes = {
  fetchSuretyBondForm: PropTypes.func,
  suretyBondFormListData: PropTypes.any,
  history: PropTypes.any,
  activeAgencyCode: PropTypes.string.isRequired,
  fetchBondObligeeList: PropTypes.func,
  quickBondObligeeList: PropTypes.any,
  suretyQuote: PropTypes.object.isRequired,
  updateSuretyQuoteData: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired,
  setObligeeSearchIsLoading: PropTypes.func.isRequired,
  agencyStates: PropTypes.array.isRequired,
  fetchSuretyQuoteAgencyData: PropTypes.func.isRequired
};
