import React, { useState } from "react";
import { Button, Table, LoadingIndicator, Modal } from "@ufginsurance/ui-kit";
import { useSuretyDocsFormsContext } from "../context";
import { getForm } from "../service";
import { DocumentService } from "../../../services/documentService";
import { v4 } from "uuid";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const DocumentFormsTable = () => {
  const {
    currentSection,
    formValues,
    docsAndFormsList,
    isLoadingDocsAndFormsList
  } = useSuretyDocsFormsContext();

  const [documentsLoading, setDocumentsLoading] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  const ErrorModalBody = () => {
    return (
      <div>
        We&apos;re sorry, we could not locate the requested document.
        <br />
        <br />
        <HelpDeskMessage display="questionsOrAssistance" />
      </div>
    );
  };

  const getAndRenderForm = (formNumber, editionDate) => {
    const formId = `${formNumber.trim()}_${editionDate}`;
    const documentService = new DocumentService();

    setDocumentsLoading({ ...documentsLoading, [formId]: true });

    getForm(formNumber.trim(), editionDate)
      .then(
        response => {
          if (response && response.data) {
            documentService.initializeRenderer("/loading-document");
            documentService.saveOrOpenDocument(response.data, `${formId}.pdf`);
          }
        },
        () => {
          documentService.clearRenderer();
          setShowErrorModal(true);
        }
      )
      .finally(() => {
        const tempDocumentsLoading = { ...documentsLoading };
        delete tempDocumentsLoading[formId];
        setDocumentsLoading(tempDocumentsLoading);
      });
  };

  const columns = [
    {
      key: "name",
      label: currentSection.includes("bonds") ? "Bond Form Name" : "Form Name",
      sortable: false,
      element: row => {
        const formId = `${row.form_number}_${row.edition_date}`;
        const requestInProgress =
          formId && documentsLoading.hasOwnProperty(formId);
        return (
          <Button
            size="sm"
            className="form_name"
            isLink
            inline
            onClick={() => getAndRenderForm(row.form_number, row.edition_date)}
            spinner={requestInProgress}
          >
            {row.name}
          </Button>
        );
      }
    },
    { key: "company", label: "Company", sortable: false }
  ];

  // If in bonds section, add form and obligee type columns.
  if (currentSection.includes("bonds")) {
    columns.unshift({
      key: "bond_form_type",
      label: "Bond Type",
      sortable: false
    });

    columns.unshift({
      key: "obligee_type",
      label: "Obligee",
      sortable: false,
      element: () => " "
    });
  }

  // If in other forms section, remove company column.
  if (currentSection === "other") {
    columns.pop();
  }

  if (isLoadingDocsAndFormsList) {
    return <LoadingIndicator color="blue" />;
  }

  const commercialSubtable = ({ row, isShowing }) => {
    if (!isShowing) return;
    const data = row?.commercial_form_details || {};
    data.id = v4();
    return (
      <Table
        rowKey="id"
        data={[data]}
        isMinimal
        columns={[
          {
            key: "related_documents",
            label: "Related Documents",
            element: _ => {
              return (_.related_documents ?? [])
                .filter(doc => !!doc.edition_date)
                .map(doc => {
                  const docId = `${_.id}_${doc.form_number}_${doc.edition_date}`;
                  return (
                    <div key={docId}>
                      <Button
                        isLink
                        inline
                        size="sm"
                        className="form_name"
                        onClick={() =>
                          getAndRenderForm(doc.form_number, doc.edition_date)
                        }
                      >
                        {doc.form_description}
                      </Button>
                    </div>
                  );
                });
            }
          },
          {
            key: "rate_per_thousand",
            label: "Rate Per Thousand",
            element: _ => {
              const doc = (_.related_documents ?? []).find(
                d => d.form_description === "Rate Information"
              );
              if (_.rate_per_thousand !== "0" || !doc)
                return `$${_.rate_per_thousand}`;
              return (
                <Button
                  isLink
                  inline
                  size="sm"
                  className="form_name"
                  onClick={() => getAndRenderForm(doc.form_number)}
                >
                  View
                </Button>
              );
            }
          },
          {
            key: "renwal_type",
            label: "Renewal Type",
            element: _ => _.renewal_type
          },
          {
            key: "risk_rating",
            label: "Risk Rating",
            element: _ => {
              const doc = (_.related_documents ?? []).find(
                d => d.form_description === "Risk Rating Information"
              );
              if (_.risk_rating !== "10" || !doc) return `${_.risk_rating}`;
              return (
                <Button
                  isLink
                  inline
                  size="sm"
                  className="form_name"
                  onClick={() => getAndRenderForm(doc.form_number)}
                >
                  View
                </Button>
              );
            }
          },
          {
            key: "is_direct_bill",
            label: "Direct Bill",
            element: _ => (_.is_direct_bill ? "Yes" : "No")
          }
        ]}
      />
    );
  };

  const orderObligee = obligee => {
    switch ((obligee || "").toUpperCase()) {
      case "ANY OBLIGEE":
        return 1;
      case "FEDERAL":
        return 2;
      case "STATE":
        return 3;
      case "COUNTY":
        return 4;
      case "MUNICIPALITY":
        return 5;
      default:
        return 6;
    }
  };

  return (
    <>
      <Table
        searchable
        searchQuery={formValues.keyword}
        rowKey="name"
        groupHeaderKey={
          currentSection.includes("bonds") ? "obligee_type" : null
        }
        groupHeaderContents={row => <span>{row.obligee_type}</span>}
        columns={columns}
        data={(docsAndFormsList || [])
          .sort((a, b) => (a.name < b.name ? 1 : -1))
          .sort((a, b) =>
            orderObligee(a.obligee_type) > orderObligee(b.obligee_type) ? 1 : -1
          )}
        showPagination
        itemsPerPage={10}
        noResultsMessage="No Documents or Forms found"
        expandedContent={
          currentSection.includes("bondsCommercial") ? commercialSubtable : null
        }
      />
      <Modal
        title={"Document not found"}
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        body={<ErrorModalBody />}
      />
    </>
  );
};

export default DocumentFormsTable;
