import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../components/Factory";
import { Table } from "@ufginsurance/ui-kit";

const oasisTable = props => {
  const { documentSummary, fetchGWCurrentPolicyDocument, policyType, policy } =
    props;

  const handleDocumentClick = row => () => {
    fetchGWCurrentPolicyDocument(
      row.document_id,
      policy.policy_number,
      policyType
    );
  };

  const createColumns = () => [
    {
      key: "process_date",
      label: "Process Date",
      sortable: true,
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "document_type",
      label: "Document Type",
      element: row => (
        <button className="btn-as-link" onClick={handleDocumentClick(row)}>
          {row.document_type}
        </button>
      )
    },
    {
      key: "effective_date",
      label: "Effective Date",
      element: row => (
        <span>{DateFormat(row.effective_date, "MM/DD/YYYY")}</span>
      )
    }
  ];

  return (
    <div className="oasis-table">
      <Table
        rowKey="id"
        data={documentSummary}
        columns={createColumns(documentSummary)}
        initialSort="process_date"
        initialDirection="desc"
      />
    </div>
  );
};

oasisTable.propTypes = {
  documentSummary: PropTypes.array.isRequired,
  fetchGWCurrentPolicyDocument: PropTypes.func,
  policyType: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired
};

export default oasisTable;
