export const DriverLicenseValidationRegex = {
  AL: [
    {
      regex: /^[0-9]{1,8}$/,
      description: "Up to 8 Digits"
    }
  ],
  AK: [
    {
      regex: /^[0-9]{1,7}$/,
      description: "Up to 7 Digits"
    }
  ],
  AZ: [
    {
      regex: /^[A-Z]{1}[0-9]{1,8}$/,
      description: "1 Letter and up to 8 Digits"
    },
    {
      regex: /^[A-Z]{2}[0-9]{2,5}$/,
      description: "2 Letters and up to 5 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  AR: [
    {
      regex: /^[0-9]{4,9}$/,
      description: "4 to 9 Digits"
    }
  ],
  CA: [
    {
      regex: /^[A-Z]{1}[0-9]{7}$/,
      description: "1 Letter and 7 Digits"
    }
  ],
  CO: [
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    },
    {
      regex: /^[A-Z]{1}[0-9]{3,6}$/,
      description: "1 Letter and 3-6 Digits"
    },
    {
      regex: /^[A-Z]{2}[0-9]{2,5}$/,
      description: "2 Letters and 2-5 Digits"
    }
  ],
  CT: [
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  DE: [
    {
      regex: /^[0-9]{1,7}$/,
      description: "Up to 7 Digits"
    }
  ],
  DC: [
    {
      regex: /^[0-9]{7}$/,
      description: "7 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  FL: [
    {
      regex: /^[A-Z]{1}[0-9]{12}$/,
      description: "1 Letter and 12 Digits"
    }
  ],
  GA: [
    {
      regex: /^[0-9]{7,9}$/,
      description: "7 to 9 Digits"
    }
  ],
  HI: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: "1 Letter and 8 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  ID: [
    {
      regex: /^[A-Z]{2}[0-9]{6}[A-Z]{1}$/,
      description: "2 Letters, 6 Digits and 1 Letter"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  IL: [
    {
      regex: /^[A-Z]{1}[0-9]{11,12}$/,
      description: "1 Letter and 11-12 Digits"
    }
  ],
  IN: [
    {
      regex: /^[A-Z]{1}[0-9]{9}$/,
      description: "1 Letter and 9 Digits"
    },
    {
      regex: /^[0-9]{9,10}$/,
      description: "9 to 10 Digits"
    }
  ],
  IA: [
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    },
    {
      regex: /^[0-9]{3}[A-Z]{2}[0-9]{4}$/,
      description: "3 Digits, 2 Letters and 4 Digits"
    }
  ],
  KS: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: "1 Letter and 8 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  KY: [
    {
      regex: /^[A-Z]{1}[0-9]{8,9}$/,
      description: "1 Letter and 8-9 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  LA: [
    {
      regex: /^[0-9]{1,9}$/,
      description: "Up to 9 Digits"
    }
  ],
  ME: [
    {
      regex: /^[0-9]{7,8}$/,
      description: "7-8 Digits"
    },
    {
      regex: /^[0-9]{7}[A-Z]{1}$/,
      description: "7 Digits and 1 Letter"
    }
  ],
  MD: [
    {
      regex: /^[A-Z]{1}[0-9]{12}$/,
      description: "1 Letter and 12 Digits"
    }
  ],
  MA: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: "1 Letter and 8 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  MI: [
    {
      regex: /^[A-Z]{1}[0-9]{10}$/,
      description: "1 Letter and 10 Digits"
    },
    {
      regex: /^[A-Z]{1}[0-9]{12}$/,
      description: "1 Letter and 12 Digits"
    }
  ],
  MN: [
    {
      regex: /^[A-Z]{1}[0-9]{12}$/,
      description: "1 Letter and 12 Digits"
    }
  ],
  MS: [
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  MO: [
    {
      regex: /^[A-Z]{1}[0-9]{5,9}$/,
      description: "1 Letter and 5-9 Digits"
    },
    {
      regex: /^[A-Z]{1}[0-9]{6}[R]{1}$/,
      description: "1 Letter and 6 Digits and R"
    },
    {
      regex: /^[0-9]{8}[A-Z]{2}$/,
      description: "8-9 Digits and 1 to 2 Letters"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  MT: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: "1 Letter and 8 Digits"
    },
    {
      regex: /^[0-9]{13,14}$/,
      description: "13 to 14 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    },
    {
      regex: /^[A-Z]{3}[0-9]{10}$/,
      description: "3 Letter and 10 Digits"
    }
  ],
  NE: [
    {
      regex: /^[A-Z]{1}[0-9]{3,8}$/,
      description: "1 Letter and 3 to 8 Digits"
    }
  ],
  NV: [
    {
      regex: /^[0-9]{9,10}$/,
      description: "9 or 10 Digits"
    },
    {
      regex: /^[0-9]{12}$/,
      description: "12 Digits"
    },
    {
      regex: /^[X]{1}[0-9]{8}$/,
      description: "Starts with X and 8 Digits"
    }
  ],
  NH: [
    {
      regex: /^[0-9]{2}[A-Z]{3}[0-9]{5}$/,
      description: "2 Digits, 3 Letters and 5 Digits"
    }
  ],
  NJ: [
    {
      regex: /^[A-Z]{1}[0-9]{14}$/,
      description: "1 Letter and 14 Digits"
    }
  ],
  NM: [
    {
      regex: /^[0-9]{8,9}$/,
      description: "8 or 9 Digits"
    }
  ],
  NY: [
    {
      regex: /^[A-Z]{1}([0-9]{7}|[0-9]{18})$/,
      description: "1 Letter and 7 or 18 Digits"
    },
    {
      regex: /^([0-9]{8,9}|[0-9]{16})$/,
      description: "8, 9, or 16 Digits"
    },
    {
      regex: /^[A-Z]{8}$/,
      description: "8 Letters"
    }
  ],
  NC: [
    {
      regex: /^[0-9]{1,12}$/,
      description: "1 to 12 Digits"
    }
  ],
  ND: [
    {
      regex: /^[A-Z]{3}[0-9]{6}$/,
      description: "3 Letters and 6 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  OH: [
    {
      regex: /^[A-Z]{1}[0-9]{4,8}$/,
      description: "1 Letter and 4 to 8 Digits"
    },
    {
      regex: /^[A-Z]{2}[0-9]{3,7}$/,
      description: "2 Letters and 3 to 7 Digits"
    },
    {
      regex: /^[0-9]{8}$/,
      description: "8 Digits"
    }
  ],
  OK: [
    {
      regex: /^[A-Z]{1}[0-9]{9}$/,
      description: "1 Letter and 9 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    }
  ],
  OR: [
    {
      regex: /^[0-9]{1,9}$/,
      description: "Up to 9 Digits"
    },
    {
      regex: /^[A-Z]{1}[0-9]{6}$/,
      description: "1 Letter and 6 Digits"
    },
    {
      regex: /^[A-Z]{2}[0-9]{5}$/,
      description: "2 Letters and 5 Digits"
    }
  ],
  PA: [
    {
      regex: /^[0-9]{8}$/,
      description: "8 Digits"
    }
  ],
  RI: [
    {
      regex: /^[0-9]{7}$/,
      description: "7 Digits"
    },
    {
      regex: /^[A-Z]{1}[0-9]{6}$/,
      description: "1 Letter and 6 Digits"
    }
  ],
  SC: [
    {
      regex: /^[0-9]{5,11}$/,
      description: "5 to 11 Digits"
    }
  ],
  SD: [
    {
      regex: /^[0-9]{6,10}$/,
      description: "6 to 10 Digits"
    },
    {
      regex: /^[0-9]{12}$/,
      description: "12 Digits"
    }
  ],
  TN: [
    {
      regex: /^[0-9]{7,9}$/,
      description: "7 to 9 Digits"
    }
  ],
  TX: [
    {
      regex: /^[0-9]{8}$/,
      description: "8 Digits"
    }
  ],
  UT: [
    {
      regex: /^[0-9]{4,10}$/,
      description: "4 to 10 Digits"
    }
  ],
  VT: [
    {
      regex: /^[0-9]{8}$/,
      description: "8 Digits"
    },
    {
      regex: /^[0-9]{7}[A]$/,
      description: "7 Digits and 1 Letter"
    }
  ],
  VA: [
    {
      regex: /^[A-Z]{1}[0-9]{8}$/,
      description: "1 Letter and 8 Digits"
    },
    {
      regex: /^[0-9]{9}$/,
      description: "9 Digits"
    },
    {
      regex: /^[0-9]{12}$/,
      description: "12 Digits"
    }
  ],
  WA: [
    {
      regex: /^(?=.{12}$)[A-Z]{1,7}[A-Z0-9\\*]{4,11}$/,
      description: "12 Letters or Digits or *"
    }
  ],
  WV: [
    {
      regex: /^[0-9]{7}$/,
      description: "7 Digits"
    },
    {
      regex: /^[A-Z]{1,2}[0-9]{5,6}$/,
      description: "1 to 2 Letters and 5 to 6 Digits"
    }
  ],
  WI: [
    {
      regex: /^[A-Z]{1}[0-9]{13}$/,
      description: "1 Letter and 13 Digits"
    }
  ],
  WY: [
    {
      regex: /^[0-9]{9,10}$/,
      description: "9 to 10 Digits"
    }
  ]
};
