import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { PolicySearchWrapper } from "../containers/PolicySearchWrapper";
import { fetchPoliciesSearchList } from "../actions/policies";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => {
  return {
    searchKeyword: state.policiesData.searchKeyword || "",
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state)
  };
};

const mapDispatchToProps = {
  fetchPoliciesSearchList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySearchWrapper);
