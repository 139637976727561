import * as types from "./topActionCardActionTypes";
import initialState from "../reducers/initialState";

//TODO: rename the state property paymentdetails to makeaPayment
const makeAPaymentReducer = (state = initialState.paymentDetails, action) => {
  switch (action.type) {
    case types.MAKE_A_PAYMENT_ACTIVATED:
      return {
        ...state,
        isActivated: true
      };

    case types.DISMISS_MAKE_A_PAYMENT:
      return {
        ...state,
        isLoading: false,
        isActivated: false,
        error: null,
        payment: {
          return_code: null,
          eligibility: false
        }
      };
    case types.FETCH_PAYMENT_ELIGIBILITY_STARTED:
      return {
        ...state,
        isLoading: true
      };
    case types.FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED:
      return {
        ...state,
        payment: action.payload,
        isLoading: false,
        error: false
      };
    case types.FETCH_PAYMENT_ELIGIBILITY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: state.isActivated ? action.payload : null, //the time to resolve promise sometimes is more than we dismiss the modal.
        payment: {
          return_code: null,
          eligibility: false
        }
      };
    default:
      return state;
  }
};

export default makeAPaymentReducer;
