import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { Panel } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";
import { Translations } from "../translations";
import PolicySearchBarContainer from "../../containers/PolicySearchBarContainer";
import CommercialNav from "../commercial/CommercialNav";
import YourUfgTeamNav from "../../your-ufg-team/components/YourUfgTeamNav";
import NavigationContainer from "./NavigationContainer";
import ResourcesSideNav from "../../resources/components/ResourcesSideNav";
import SuretySideNav from "../../surety/components/SuretySideNav";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";
import { startReportAClaim } from "../../top-action-cards/report-claim/actions/reportClaimActions";
import { connect } from "react-redux";

const Sidebar = ({ isSpecialtyPortalView }) => {
  const renderSearchContainer = ({location}) => {
    if (location.pathname.includes("surety")) return null;
    return (
      <Panel title={Translations.search.sidebar_title} highlight="blue">
        <PolicySearchBarContainer />
      </Panel>
    );
  };

  return (
    <div>
      <Switch>
        <Route
          path={routes.COMMERCIAL}
          component={() => (
            <CommercialNav isSpecialtyPortalView={isSpecialtyPortalView} />
          )}
        />
        <Route path={routes.YOUR_UFG_TEAM_ROOT} component={YourUfgTeamNav} />
        <Route path={routes.RESOURCES} component={() => (<ResourcesSideNav isSpecialtyPortalView = {isSpecialtyPortalView} />)}/>
        <Route path={routes.SURETY} component={SuretySideNav} />
      </Switch>
      <Route path={routes.HOME} render={renderSearchContainer} />
      <Route path={routes.HOME} component={NavigationContainer} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isSpecialtyPortalView: getisSpecialtyPortalView(state)
  };
};

const mapDispatchToProps = {
  startReportAClaim
};

Sidebar.propTypes = {
  isSpecialtyPortalView: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
