import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AgencyAuditForm from "./AgencyAuditForm";
import { postAgencyAudit } from "../../../services/underwritingReportsService";
import { Translations } from "../../../../components/translations";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const AgencyAudit = props => {
  const {
    emailList,
    selectedAgencyCode,
    is109015Agent,
    administrator,
    hasAgencyProductionReportsAccess,
    fetchUnderwritingEmailListDetails,
    fetchAdministrator
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [emailTo, setEmailTo] = useState("");

  const handleSubmit = (values, formReset) => {
    postAgencyAudit(values, selectedAgencyCode).then(
      () => {
        setShowModal(true);
        setSubmitFailed(false);
        setEmailTo(values.emailTo);
        formReset();
      },
      () => {
        setShowModal(false);
        setSubmitFailed(true);
        setEmailTo("");
      }
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setEmailTo("");
  };

  useEffect(() => {
    fetchUnderwritingEmailListDetails(selectedAgencyCode);
    fetchAdministrator(selectedAgencyCode);
  }, [
    fetchAdministrator,
    fetchUnderwritingEmailListDetails,
    selectedAgencyCode
  ]);

  return (
    <div>
      {selectedAgencyCode && selectedAgencyCode === "109015" ? (
        <div className="underwriting-error-message">
          {Translations.reports.internal_user}
        </div>
      ) : hasAgencyProductionReportsAccess ? (
        <div>
          <AgencyAuditForm
            emailList={emailList}
            selectedAgencyCode={selectedAgencyCode}
            is109015Agent={is109015Agent}
            submit={handleSubmit}
            administratorName={administrator.name}
          />
          <Modal
            title={Translations.reports.request_Submitted}
            body={
              <div className="agency-audit">
                {Translations.reports.emailTo}
                {emailTo}
              </div>
            }
            footer={
              <FlexRow align="right">
                <Button variant="secondary" onClick={handleClose}>
                  {Translations.general.close}
                </Button>
              </FlexRow>
            }
            show={showModal}
            onHide={handleClose}
          />
          {submitFailed && (
            <span>
              {Translations.reports.tabUnderwriting.agencyAudit.submissionFail}
            </span>
          )}
        </div>
      ) : (
        <div className="underwriting-error-message">
          {Translations.reports.error_message}
        </div>
      )}
    </div>
  );
};

AgencyAudit.propTypes = {
  selectedAgencyCode: PropTypes.string,
  emailList: PropTypes.array,
  fetchUnderwritingEmailListDetails: PropTypes.func,
  is109015Agent: PropTypes.bool,
  hasAgencyProductionReportsAccess: PropTypes.bool,
  administrator: PropTypes.object,
  fetchAdministrator: PropTypes.func
};

export default AgencyAudit;
