import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { Translations } from "../../../components/translations";
import { Modal } from "@ufginsurance/ui-kit";

const PilotAgents = ({ error, onClose, onDismissError }) => {
  const zipCodeError =
    Translations.start_new_quote.customer_modal.zip_code_error;
  return (
    <Modal
      show
      title="Specially Selected Pilot Agents"
      onHide={onClose}
      body={
        <div className="new-quote-modal__body new-quote-modal--pilot-agents">
          {error ? (
            error === zipCodeError
          ) : null ? (
            <Alert bsStyle="danger" onDismiss={onDismissError}>
              {error}
            </Alert>
          ) : null}
          <p>{Translations.start_new_quote.pilot_agents.description}</p>
        </div>
      }
    />
  );
};

PilotAgents.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDismissError: PropTypes.func.isRequired
};

export default PilotAgents;
