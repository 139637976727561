/* eslint-disable react/prop-types */
import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
  Input,
  Checkbox,
  Switch,
  Select,
  DatePicker,
  Button,
  TextArea
} from "@ufginsurance/ui-kit";
import { inputTypes, textTypes } from "./util";
import CheckboxButton from "./CheckboxButton";

const fieldObjectType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(inputTypes),
  disabled: PropTypes.bool,
  validation: PropTypes.shape({
    pattern: PropTypes.oneOf([PropTypes.RegExp, PropTypes.undefined]),
    errorMessage: PropTypes.string,
    validatorfunc: PropTypes.func
  }),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isPrefilled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  className: PropTypes.string,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  filters: PropTypes.arrayOf(PropTypes.object),
  display: PropTypes.bool,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func
});

export const Field = memo(props => {
  const {
    f,
    handleOnChange,
    handleOnBlur,
    onValidate,
    onToggle,
    hiddenFields,
    disabledByFilter,
    requiredByFilter,
    onKeyDown
  } = props;

  const handleFieldValidation = useCallback(
    ({ field, value, validation }) => {
      //console.log("FIELD VALIDATION", f);
      // get standard errors from useFrom
      const fieldErrors = onValidate({
        field,
        value,
        validation
      });

      // if field has validation rules, then test it
      if (
        f.validation &&
        f.validation.pattern &&
        f.validation.errorMessage &&
        !RegExp(f.validation.pattern).test(value)
      ) {
        fieldErrors.push(f.validation.errorMessage);
      }

      // if field has validation function rules, then test it
      if (
        f.validation &&
        f.validation.validatorfunc &&
        f.validation.errorMessage &&
        !f.validation.validatorfunc(value)
      ) {
        fieldErrors.push(f.validation.errorMessage);
      }

      return fieldErrors;
    },
    [f, onValidate]
  );

  return makeField({
    ...props,
    handleFieldValidation,
    handleOnChange,
    handleOnBlur,
    handleToggle: onToggle,
    hiddenFields,
    disabledByFilter,
    requiredByFilter,
    onKeyDown
  });
});

Field.propTypes = {
  f: fieldObjectType.isRequired
};

const makeField = ({
  f,
  handleOnChange,
  handleOnBlur,
  handleFieldValidation,
  handleToggle,
  value,
  hiddenFields,
  disabledByFilter,
  requiredByFilter,
  onValidate,
  onKeyDown,
  ...rest
}) => {
  // console.log(f);

  const handleFieldOnChange = field => {
    handleOnChange({ field, type: f.type, fieldOnChange: f.onChange });
  };

  const handleFieldOnblur = field => {
    handleOnBlur({ field, type: f.type, fieldOnBlur: f.onBlur });
  };

  const classes = cn(
    f.className,

    "oq__form-field",
    f.id.replace(/\./g, "_"),
    {
      isPrefilled: f.isPrefilled,
      [f.fullStoryId]: f.fullStoryId
    }
  );

  const isDisabled =
    disabledByFilter !== undefined ? disabledByFilter : f.disabled;
  const isRequired = f.required || requiredByFilter;

  if (f.type === "textArea") {
    return (
      <TextArea
        type={f.type}
        min={f.min}
        max={f.max}
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.label}
        labelElement={f.labelElement}
        className={`${classes} oq__form-field__textarea`}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyDown}
        onValidate={handleFieldValidation}
        value={value || ""}
        required={isRequired}
        requiredError={f.requiredError}
        disabled={isDisabled}
        minLength={f.minLength}
        maxLength={f.maxLength}
        {...rest}
      />
    );
  }

  if (textTypes.includes(f.type)) {
    return (
      <Input
        type={f.type}
        min={f.min}
        max={f.max}
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.label}
        labelElement={f.labelElement}
        className={`${classes} oq__form-field__input`}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyDown}
        onValidate={handleFieldValidation}
        value={value || ""}
        required={isRequired}
        requiredError={f.requiredError}
        disabled={isDisabled}
        minLength={f.minLength}
        maxLength={f.maxLength}
        mask={f?.mask}
        stripMaskFromValue={!!f?.mask && f.stripMaskFromValue !== false}
        {...rest}
      />
    );
  }

  /*
  --------
  DROPDOWN
  --------
  */

  if (f.type === "select") {
    /*
    some fields are converted to dropdown on the fly...
    if a field becomes a dropdown and has a value, we can
    force the value to be one of the available options.
    exists & opts make this magic
    */
    const exists = f.options.some(o => o.value === value);
    const opts =
      f.forceOptionsToValue && !exists
        ? [...f.options, { value, label: value }]
        : f.options;

    // render the dropdown
    return (
      <Select
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.label}
        labelElement={f.labelElement}
        className={`${classes} oq__form-field__dropdown`}
        placeholder={null}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onValidate={handleFieldValidation}
        options={opts}
        value={value || ""}
        required={isRequired}
        requiredError={f.requiredError}
        disabled={isDisabled}
        // ↓↓↓ a whole bunch of dropdown props
        isClearable={false}
        isLoading={f.isLoading}
        showSearch={f.isSearchable}
        multi={f.multi}
        {...rest}
      />
    );
  }

  /*
  ----------------------
  DROPDOWN TO CHECKBOXES
  ----------------------
  */

  if (f.type === "dropdownToTextboxes") {
    const checkboxes = f.checkboxes;

    if (!checkboxes) return null;

    return (
      <>
        <div className="oq__dropdown-to-textboxes">
          {checkboxes.map(c => {
            const isSelected = f.defaultValue === c.code;
            return (
              <CheckboxButton
                key={c.name}
                selected={isSelected}
                onClick={() => {
                  if (!isSelected) {
                    handleFieldOnChange({
                      field: f.path,
                      value: c.code
                    });
                  }
                }}
              >
                {c.label}
              </CheckboxButton>
            );
          })}
        </div>
      </>
    );
  }

  if (f.type === "scheduleItemContact") {
    /*
    some fields are converted to dropdown on the fly...
    if a field becomes a dropdown and has a value, we can
    force the value to be one of the available options.
    exists & opts make this magic
    */
    const exists = f.options.some(o => o.value === value);
    const opts =
      f.forceOptionsToValue && !exists
        ? [...f.options, { value, label: value }]
        : f.options;

    // render the dropdown
    // OOQ-12771 - when we don't have any contacts, then don't show the dropdown, just the ADD button
    return (
      <>
        {opts?.length > 0 && (
          <Select
            key={f.id}
            id={f.id}
            name={f.id}
            label={f.label}
            labelElement={f.labelElement}
            className={`${classes} oq__form-field__dropdown oq__schedule-item-contact`}
            placeholder={null}
            onChange={handleFieldOnChange}
            onBlur={handleFieldOnblur}
            onKeyDown={onKeyDown}
            onValidate={handleFieldValidation}
            options={opts}
            value={value || ""}
            required={isRequired}
            requiredError={f.requiredError}
            disabled={isDisabled}
            isClearable={false}
            isLoading={f.isLoading}
            size="full"
            {...rest}
          />
        )}
        <div className="oq__schedule-item__contact-buttons">
          {!!value && (
            <Button
              wrapperClassName="oq__schedule-item__edit-contact"
              onClick={() =>
                f.onClickEditScheduleItemContact({ contactId: value })
              }
              variant="outline"
              disabled={f.isLoading}
            >
              Edit Contact for Account
            </Button>
          )}
          <Button
            block
            wrapperClassName="oq__schedule-item__add-contact"
            variant="outline"
            onClick={f.onClickAddScheduleItemContact}
            disabled={f.isLoading}
          >
            Add New Contact to Account
          </Button>
        </div>
      </>
    );
  }

  /*
  ------
  SWITCH
  ------
  A "required" property that is applied to a checkbox, switch, or binary
  toggle component will return invalid if it is in the 'false' state.
  Because of this, the Switch and Binary Toggle components will never
  be ‘required’ and that by being in the deselected state, it will return false.
  */
  if (f.type === "switch") {
    return (
      <Switch
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.labelElement || f.label}
        className={`${classes} oq__form-field__switch`}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onValidate={handleFieldValidation}
        value={!!value || false}
        disabled={isDisabled}
        noLabel
        {...rest}
      />
    );
  }

  /*
  --------
  CHECKBOX
  --------
  */
  if (f.type === "checkbox") {
    return (
      <Checkbox
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.label}
        className={`${classes} oq__form-field__checkbox`}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onValidate={handleFieldValidation}
        value={!!value || false}
        required={isRequired}
        requiredError={f.requiredError}
        disabled={isDisabled}
        {...rest}
      />
    );
  }

  /*
  ------------------------
  DATAITEM  just for view.
  ------------------------
  // not used at this time
  */
  // if (f.type === "data") {
  //   return (
  //     <DataItem
  //       key={f.id}
  //       id={f.id}
  //       name={f.id}
  //       label={f.label}
  //       className={classes}
  //       value={JSON.stringify(f.defaultValue)}
  //       {...rest}
  //     />
  //   );
  // }

  /*
  ----------
  DATEPICKER
  ----------
  */
  if (f.type === "date") {
    //OOQ-3620 IE not able to handle null in value...
    //This could be moved to the DatePicker component...
    if (
      (value?.hasOwnProperty("year") && !value?.year) ||
      (value?.hasOwnProperty("month") && !(value?.month >= 0)) ||
      (value?.hasOwnProperty("day") && !value?.day)
    )
      value = "";

    return (
      <DatePicker
        key={f.id}
        id={f.id}
        name={f.id}
        label={f.label}
        labelElement={f.labelElement}
        className={`${classes} oq__form-field__datepicker`}
        onChange={handleFieldOnChange}
        onBlur={handleFieldOnblur}
        onKeyDown={onKeyDown}
        onValidate={handleFieldValidation}
        value={value || ""}
        required={isRequired}
        requiredError={f.requiredError}
        disabled={isDisabled}
        format="OBJECT"
        minDate={f.minDate}
        maxDate={f.maxDate}
        {...rest}
      />
    );
  }

  /*
    -----------
    TOGGLE LINK
    -----------

    A link that allows the user to trigger the view to
    show and hide a set of fields based on the field's id
  */
  if (f.type === "togglelink") {
    return (
      <Button key={f.id} onClick={() => handleToggle(f.id)} isLink inline>
        {hiddenFields && hiddenFields.indexOf(f.id) === -1 && (
          <span className="add">+ Add {f.label}</span>
        )}
        {hiddenFields && hiddenFields.indexOf(f.id) >= 0 && (
          <span className="remove">Remove {f.label}</span>
        )}
      </Button>
    );
  }

  return null;
};

makeField.propTypes = {
  f: fieldObjectType.isRequired
};
