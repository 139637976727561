import React, { useContext, useState, useEffect } from "react";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";

import {
  FormGroup,
  ButtonBar,
  Select,
  Switch,
  Input,
  Button,
  Form,
  useForm
} from "@ufginsurance/ui-kit";
import { currencyToString, sortByProperty } from "../shared/util";

import OnlineQuotingContext from "../OnlineQuotingContext";
import BillingPaymentMethod from "../step6/BillingPaymentMethod";

const numberOfInstallments = {
  "payment_plan:2": "10",
  "payment_plan:5": "1",
  "payment_plan:6": "11",
  "prod-bc:8002": "0",
  "prod-bc:8004": "3"
};

const BillingSection = ({
  formMetadata,
  activeAgencyCode,
  agentName,
  formIsValid,
  setFormIsValid
}) => {
  const {
    quoteData,
    updateBindingData,
    supportingData,
    updateSupportingDataPromise,
    quoteIsUpdating,
    toastErrr
  } = useContext(OnlineQuotingContext);

  // used to track the status of the BillingPaymentMethod form component
  // defaults to true because the BillingPaymentMethod component is conditionally displayed
  const [
    formIsValid_BillingPaymentMethod,
    setFormIsValid_BillingPaymentMethod
  ] = useState(true);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [showPaymentMethods, setShowPaymentMethods] = useState(
    supportingData?.billingData?.applyDownPayment
  );

  // initial values for the fields for the useForm hook
  const initialValues = {
    payment_option: quoteData?.bindData?.selectedPaymentPlan || "",
    applyDownPayment: supportingData?.billingData?.applyDownPayment || false,
    downPayment: supportingData?.billingData?.downPayment || ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  const {
    updateForm,
    values,
    handleOnValidate,
    handleOnBlur,
    handleOnChange,
    invalidFields,
    validateForm
  } = form;

  useEffect(() => {
    // is this form and the BillingPaymentMethod form valid?
    const isValid = !invalidFields.length && formIsValid_BillingPaymentMethod;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [
    formIsValid,
    setFormIsValid,
    invalidFields,
    formIsValid_BillingPaymentMethod
  ]);

  const getPaymentPlanData = ({ bindingData }) => {
    if (!bindingData) return {};

    const payPlan = (bindingData?.paymentPlans || []).find(
      d => d.billingId === bindingData?.selectedPaymentPlan
    );
    const paymentData = payPlan
      ? {
          totalPremium: payPlan?.total?.amount,
          remainingPremium:
            payPlan?.total?.amount - payPlan?.downPayment?.amount,
          downPayment: payPlan?.downPayment?.amount,
          installmentAmount: payPlan?.installment?.amount,
          numberOfInstallments:
            numberOfInstallments[bindingData?.selectedPaymentPlan]
        }
      : {};

    return paymentData;
  };

  // onChange occurs when an option is selected in the menu
  // onChange returns ({ field, value, values, updateForm })
  const changeBillingMethod = ({ billingMethod }) => {
    // selectedPaymentPlan Note: when bill type is changed, set the payment plan to null so that
    // ...it wont end up in incompatability with paln selected to the bill type changed
    const newBindingData = {
      ...quoteData.bindData,
      billingMethod_UFG: billingMethod,
      selectedPaymentPlan: null
    };

    updateBindingData({
      bindingData: newBindingData,
      updateMessage: "Updating billing information..."
    })
      .then(({ data }) => {
        // callback

        // update form with default payment option for billing method
        updateForm({
          values: {
            payment_option: data.bindData.selectedPaymentPlan
          }
        });

        //save in supporting data
        const billingData = {
          ...supportingData.billingData,
          billingMethod
        };

        updateSupportingDataPromise({
          dataToMergeAndSave: {
            billingData
          }
        });
      })
      .catch(({ error }) =>
        toastErrr({
          displayMessage: `An error occurred.  Unable to update billing method.`,
          action: "changeBillingMethod: updateBindingData",
          misc: { bindingData: newBindingData },
          description: "update binding data failed",
          error
        })
      );
  };

  const handleOnChangePaymentOptions = ({ value }) => {
    const newBindingData = {
      ...quoteData.bindData,
      selectedPaymentPlan: value
    };

    updateBindingData({
      bindingData: newBindingData,
      updateMessage: "Updating billing information..."
    })
      .then(({ data }) => {
        //set the payplanState
        const payPlan = getPaymentPlanData({ bindingData: data.bindData });

        // update form with payment option selected
        updateForm({
          values: {
            payment_option: data.bindData.selectedPaymentPlan,
            downPayment: currencyToString(payPlan?.downPayment)
          },
          errors: {
            downPayment: []
          }
        });

        //save in supporting data
        const billingData = {
          ...supportingData.billingData,
          downPayment: currencyToString(payPlan?.downPayment),
          minAmount: currencyToString(payPlan?.downPayment),
          premiumAmount: currencyToString(payPlan?.totalPremium)
        };

        updateSupportingDataPromise({
          dataToMergeAndSave: {
            billingData
          }
        });
      })
      .catch(({ error }) =>
        toastErrr({
          displayMessage: `An error occurred.  Unable to update payment option.`,
          action: "handleOnChangePaymentOptions: updateBindingData",
          misc: { bindingData: newBindingData },
          description: "update binding data failed",
          error
        })
      );
  };

  //Calculate Payment details when which is toggled
  const handleOnChangeApplyDownPayment = ({ value }) => {
    const payPlan = getPaymentPlanData({ bindingData: quoteData.bindData });

    updateForm({
      values: {
        applyDownPayment: value,
        downPayment: currencyToString(payPlan?.downPayment)
      },
      errors: {
        downPayment: []
      }
    });

    //save in supporting data
    const billingData = {
      ...supportingData.billingData,
      applyDownPayment: value,
      downPayment: currencyToString(payPlan?.downPayment),
      minAmount: currencyToString(payPlan?.downPayment),
      premiumAmount: currencyToString(payPlan?.totalPremium)
    };

    updateSupportingDataPromise({
      dataToMergeAndSave: {
        billingData
      }
    }).then(
      // after update to supporting data, set the payment method state
      // it also updates the supporting data, so we want to update it after this update is finishes
      ({ success }) => {
        const show = success ? value : false;
        setShowPaymentMethods(show);
      }
    );
  };

  //
  //
  //
  // TODO: this should actually happen in the blur event so it doesn't happen on each character update
  // it should also only save if the value is valid (greater than the minimum)
  // also there's a bug where the user loads the quote and a payment plan is already selected,
  // ... it doesn't work correctly
  //
  //
  //
  //handle downpayment change and save down payment enetered
  const handleOnChangeDownPayment = field => {
    handleOnChange(field);

    //save in supporting data
    const billingData = {
      ...supportingData.billingData,
      downPayment: field.value
    };

    updateSupportingDataPromise({
      dataToMergeAndSave: {
        billingData
      }
    });
  };

  //down payment field validation
  const handleDownPaymentValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    if (value) {
      const valueNum = Number(value.replace("$", ""));
      const payPlan = getPaymentPlanData({
        bindingData: quoteData.bindData
      });
      if (valueNum < payPlan?.downPayment) {
        fieldErrors.push(
          `Down payment amount entered does not meet the required minimum due ($${payPlan?.downPayment})`
        );
      }
      if (valueNum > payPlan?.totalPremium) {
        fieldErrors.push(
          `Down Payment cannot be greater than total premium $${payPlan?.totalPremium}`
        );
      }
    }

    return fieldErrors;
  };

  useEffect(() => {
    const options = (quoteData?.bindData?.paymentPlans || [])
      .map(o => ({ label: o.name, value: o.billingId }))
      .sort(sortByProperty("label"));
    setPaymentOptions(options);

    // when quoteData is updated, save the billing payment method in supportingData...
    // this only needs updated if the value is set in quoteData and is different than what's in supportingData
    // ... otherwise, this updateSupportingData runs on every render
    if (
      quoteData?.bindData?.billingMethod_UFG &&
      quoteData?.bindData?.billingMethod_UFG !==
        supportingData?.billingData?.billingMethod
    ) {
      const billingData = {
        ...supportingData.billingData
      };
      billingData.billingMethod = quoteData?.bindData?.billingMethod_UFG;
      updateSupportingDataPromise({
        dataToMergeAndSave: {
          billingData
        }
      });
    }
  }, [quoteData, supportingData, updateSupportingDataPromise]);

  // on first component render, run the form validation in case they are editing the quote
  // ... and the data already exists and is invalid, so the invalid fields are highlighted
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <>
      <hr />
      <Form context={form}>
        <FormGroup>
          <div className="oq__billing-method">
            <span className="oq__billing-method__label">Billing Type</span>
            <ButtonBar id="billing_type" name="billing_type">
              {formMetadata?.agencyInformation?.billing_type !== "A" && (
                <Button
                  variant={
                    quoteData?.bindData?.billingMethod_UFG === "DirectBill"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    changeBillingMethod({ billingMethod: "DirectBill" })
                  }
                  disabled={quoteIsUpdating}
                >
                  Direct Bill
                </Button>
              )}
              {formMetadata?.agencyInformation?.billing_type !== "D" && (
                <Button
                  variant={
                    quoteData?.bindData?.billingMethod_UFG === "AgencyBill"
                      ? "primary"
                      : undefined
                  }
                  onClick={() =>
                    changeBillingMethod({ billingMethod: "AgencyBill" })
                  }
                  disabled={quoteIsUpdating}
                >
                  Agency Bill
                </Button>
              )}
            </ButtonBar>
          </div>
          <Select
            id="payment_option"
            name="payment_option"
            placeholder="Select a plan..."
            label={
              formMetadata?.agencyInformation?.billing_type === "D"
                ? "Direct Bill Payment Option"
                : formMetadata?.agencyInformation?.billing_type === "A"
                ? "Agency Bill Payment Option"
                : "Payment Option"
            }
            className={
              formMetadata?.agencyInformation?.billing_type === "B"
                ? "payment_options"
                : "payment_option-agency-direct-only"
            }
            options={paymentOptions}
            onChange={handleOnChangePaymentOptions}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.payment_option}
            size="auto"
            disabled={quoteIsUpdating}
            required
            isClearable={false}
          />
        </FormGroup>

        {quoteData?.bindData?.billingMethod_UFG === "DirectBill" && (
          <>
            <p className="padded-top">
              <b>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://agents.ufginsurance.com/resources/billing/header/16926/subheader/16927"
                >
                  Learn more about our billing and installment options.
                </a>
              </b>
            </p>
            <FormGroup>
              <Switch
                id="applyDownPayment"
                name="applyDownPayment"
                label="Do you wish to make a down payment?"
                onChange={handleOnChangeApplyDownPayment}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.applyDownPayment}
                disabled={quoteIsUpdating}
                className="oq__down-payment-select"
              />

              {!!values.applyDownPayment && (
                <Input
                  id="downPayment"
                  name="downPayment"
                  label="Down Payment Amount"
                  onChange={handleOnChangeDownPayment}
                  onBlur={handleOnBlur}
                  onValidate={handleDownPaymentValidate}
                  value={values.downPayment}
                  disabled={quoteIsUpdating}
                  required
                  className="oq__down-payment"
                  mask="currencyWithDecimals"
                />
              )}
            </FormGroup>
          </>
        )}
      </Form>
      {quoteData?.bindData?.billingMethod_UFG === "DirectBill" &&
        showPaymentMethods && (
          <BillingPaymentMethod
            activeAgencyCode={activeAgencyCode}
            agentName={agentName}
            formIsValid={formIsValid_BillingPaymentMethod}
            setFormIsValid={setFormIsValid_BillingPaymentMethod}
          />
        )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    agentName: sessionSelector.getFirstAndLastName(state),
    hasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state),
    activeAgencyName: sessionSelector.getActiveAgencyName(state)
  };
};

export default connect(mapStateToProps, {})(BillingSection);
