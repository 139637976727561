import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import HeaderContainer from "../../containers/HeaderContainer";
import ConsentSuretySteps from "./ConsentSuretySteps";
import CoSFormWrapper from "./components/CoS-FormWrapper";
import FooterContainer from "../../../src/containers/FooterContainer";
import "./ConsentOfSuretyLayout.scss";
import "./formGroup.scss";

const ConsentOfSurety = () => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    document.title = `${document.title} | Consent of Surety`;
  }, []);

  return (
    <div id="CoS">
      <HeaderContainer />
      <div className="cost__below-nav">
        <ConsentSuretySteps step={step} />
      </div>

      <section id="CoScontent" className="noborder">
        <div id="CoSbody" className="container">
          <CoSFormWrapper step={step} setStep={setStep} />
        </div>
      </section>
      <FooterContainer />
    </div>
  );
};

export default withRouter(ConsentOfSurety);
