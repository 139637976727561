import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  FlexRow,
  Tabs,
  Tab,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import OqModal from "../../shared/OqModal";
import BopSummary from "./BopSummary";
import AutoSummary from "./AutoSummary";
import WorkCompSummary from "./WorkCompSummary";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import getExclusionStep4 from "../../step4/getExclusionStep4";
import { hasAutoWithoutHNO } from "../../shared/util";

import * as api from "../../../services/onlineQuotingService";
import "./QuoteSummary.scss";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";

const QuoteSummary = () => {
  const { quoteData, supportingData, toastErrr } =
    useContext(OnlineQuotingContext);
  const [showModal, setShowModal] = useState(false);

  const [coverageExclusions, setCoverageExclusions] = useState({});
  const [loadingMetadata, setLoadingMetadata] = useState(false);

  useEffect(() => {
    if (Object.keys(coverageExclusions)?.length === 0 && !loadingMetadata) {
      setLoadingMetadata(true);
      getExclusionStep4({
        quoteData,
        supportingData,
        toastErrr,
        callback: exclusions => {
          api
            .getCoverageControlData({ dto: quoteData, supportingData })
            .then(exclus => {
              const specific =
                exclus?.data?.controls?.coverable?.stateSpecific
                  ?.stateCoverageControl;

              const allExclusions = {
                formData: {
                  stateCoverageControl: { ...specific },

                  coverageControl: [
                    ...(exclus?.data?.controls?.coverable.location
                      ?.coverageControl || []),
                    ...(exclus?.data?.controls?.coverable.building
                      ?.coverageControl || []),
                    ...(exclus?.data?.controls?.coverable.vehicle
                      ?.coverageControl || []),
                    ...(exclus?.data?.controls?.coverable.workcomp
                      ?.coverageControl || []),
                    ...(exclus?.data?.recommended || [])
                  ]
                }
              };

              setCoverageExclusions(
                mergeExclusions([exclusions, allExclusions])
              );
              setLoadingMetadata(false);
            })
            .catch(error =>
              toastErrr({
                api: "getCoverageControlData",
                description: "api failure getCoverageControlData",
                error
              })
            );
        }
      });
    }
  }, [
    quoteData,
    supportingData,
    coverageExclusions,
    loadingMetadata,
    toastErrr
  ]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  //console.log("QuoteSummary coverageExclusions:", coverageExclusions);
  return (
    <>
      <FlexRow>
        <Button wrapperClassName="oq__button__full-width" onClick={toggleModal}>
          Quote Summary
        </Button>
      </FlexRow>
      <OqModal
        size="xxl"
        className="oq__modal oq__modal__quote_summary"
        title={`Quote Summary #${quoteData?.quoteID}`}
        body={
          <>
            {!!loadingMetadata ? (
              <LoadingIndicator />
            ) : (
              <Tabs activeTab={0} className={"oq__product-coverages"}>
                {quoteData?.lobData?.hasOwnProperty("bp7BusinessOwners") && (
                  <Tab title="BOP-Pro">
                    <BopSummary
                      coverageExclusions={coverageExclusions}
                      hasUmbrella={quoteData?.lobData?.hasOwnProperty(
                        "cuCommercialUmbrella"
                      )}
                      hasCyber={quoteData?.lobData?.hasOwnProperty(
                        "internetSecurity"
                      )}
                    />
                  </Tab>
                )}
                {hasAutoWithoutHNO(quoteData, supportingData) && (
                  <Tab title="Commercial Auto">
                    <AutoSummary coverageExclusions={coverageExclusions} />
                  </Tab>
                )}
                {quoteData?.lobData?.hasOwnProperty("wcmWorkersComp") && (
                  <Tab title="Workers' Compensation">
                    <WorkCompSummary coverageExclusions={coverageExclusions} />
                  </Tab>
                )}
              </Tabs>
            )}
          </>
        }
        altCloseMethod
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default QuoteSummary;
