import React, { useState, useContext, useEffect } from "react";
import { sortByProperty, locationDisplayName } from "../../shared/util";
import { Button, Panel } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import getAllLocations from "../../step5/location/getAllLocations";
import EmployeeCardContents from "../../step5/employees/EmployeeCardContents";
import { useHistory } from "react-router-dom";
import * as routes from "../../../constants/routes";
import "../../Step5RiskInfo.scss";

const Employees = ({ coverageExclusions }) => {
  const { get, quoteData, supportingData } = useContext(OnlineQuotingContext);
  const history = useHistory();
  const [locations, setlocations] = useState([]);

  useEffect(() => {
    if (quoteData && locations.length === 0) {
      const inlocations = getAllLocations({ quoteData, supportingData });
      setlocations(inlocations);
    }
  }, [locations.length, quoteData, supportingData]);

  const locationsWorkComp = (locations || [])
    .filter(loc => !!loc?.wcmLocationFixedID)
    .sort(sortByProperty("id"));

  return (
    <div>
      <Panel
        id="oq__coverable__container__employee"
        noBorder
        titlebar
        className="oq__coverable__container oq__coverable__container__employee"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Workers&apos; Comp Classes
            </span>{" "}
            <Button
              variant="outline"
              wrapperClassName="align-right"
              isLink
              inline
              className="link-not-bolded"
              onClick={() => {
                history.push({
                  pathname: routes.ONLINE_QUOTING_STEP5,
                  hash: "Employees"
                });
              }}
            >
              Back to Classes
            </Button>
          </div>
        }
      >
        {locationsWorkComp.map(loc => {
          const employees = get.employees({
            locationId: loc.wcmLocationFixedID
          });

          if (employees.length > 0)
            return (
              <Panel
                id={`oq__coverable__container__employee__${loc.id}`}
                key={`oq__coverable__container__employee__${loc.id}`}
                highlight="none"
                className="oq__coverable__container oq__coverable__employee"
                rounded
                isOpen
                collapsible
                title={
                  <span className="oq__coverable__card__employee__location-adress">
                    {locationDisplayName(loc)}
                  </span>
                }
              >
                <div className="oq__coverable__card__employee__wrapper">
                  {employees
                    .filter(e => loc.wcmLocationFixedID === e.locationId)
                    .sort(sortByProperty("id"))
                    .map(item => (
                      <EmployeeCardContents
                        key={item.id}
                        employee={item}
                        coverageExclusions={coverageExclusions}
                      />
                    ))}
                </div>
              </Panel>
            );

          return null;
        })}
      </Panel>
    </div>
  );
};

export default Employees;
