import React, { useEffect, useState } from "react";

import { Icon, Panel, Table } from "@ufginsurance/ui-kit";
import { formatCurrencyUSD } from "../shared/utils";

export default function BondOverviewBondRating({ bond }) {
  const [receivedRatings, setReceivedRatings] = useState([]);

  useEffect(() => {
    let bonds = !bond?.rates ? [] : [...bond.rates];

    if (bonds.length && bond?.number_of_employees > 0) {
      bonds[0].number_of_employees = bond?.number_of_employees;
    }

    bonds.push({
      premium: bond?.bond_premium,
      commission_amount: bond?.total_commission_amount,
      className: "last-row"
    });

    bonds = bonds.map(v => ({ ...v, id: bonds.indexOf(v) }));
    setReceivedRatings(bonds);
  }, [bond]);

  const bondRatingColumns = [
    {
      key: "employees",
      label: "Employee(s)",
      align: "center",
      element: _ => {
        return (
          <span>{_.number_of_employees ? _.number_of_employees : ""}</span>
        );
      },
      className: !receivedRatings[0]?.number_of_employees ? `hidden` : ``
    },
    {
      key: "contract_amount",
      label: "Bond Amount",
      element: _ => {
        return (
          <span>
            {_.contract_amount ? formatCurrencyUSD(_.contract_amount, 2) : ""}
          </span>
        );
      },
      className:
        receivedRatings.filter(rating => rating.contract_amount).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "firstTimes",
      label: "",
      element: _ => {
        return _.contract_amount ? <Icon icon="farTimes" /> : <span />;
      },
      className:
        receivedRatings.filter(rating => rating.contract_rate).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "contract_rate",
      label: "Standard Rate",
      element: _ => {
        return <span>{_.contract_rate ? _.contract_rate : ""}</span>;
      },
      className:
        receivedRatings.filter(rating => rating.contract_rate).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "firstPlus",
      label: "",
      element: _ => {
        return _.contract_amount && _.maintenance_amount ? (
          <Icon icon="farPlus" />
        ) : (
          <span />
        );
      }
    },
    {
      key: "maintenance_amount",
      label: "Maintenance Bond Amount",
      element: _ => {
        return (
          <span>
            {_.maintenance_amount
              ? formatCurrencyUSD(_.maintenance_amount, 2)
              : ""}
          </span>
        );
      },
      className:
        receivedRatings.filter(rating => rating.maintenance_amount).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "secondTimes",
      label: "",
      element: _ => {
        return _.maintenance_amount ? (
          <Icon icon="farTimes" />
        ) : _.maintenance_rate ? (
          <Icon icon="farPlus" />
        ) : (
          <span />
        );
      },
      className:
        receivedRatings.filter(
          rating => rating.maintenance_amount || rating.maintenance_rate
        ).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "maintenance_rate",
      label: "Maintenance Standard Rate",
      element: _ => {
        return <span>{_.maintenance_rate ? _.maintenance_rate : ""}</span>;
      },
      className:
        receivedRatings.filter(rating => rating.maintenance_rate).length === 0
          ? `hidden`
          : ``
    },
    {
      key: "equals",
      label: "",
      element: _ => {
        return <Icon icon="farEquals" />;
      }
    },
    {
      key: "premium",
      label: "Premium",
      element: _ => {
        return <span>{_.premium ? formatCurrencyUSD(_.premium, 2) : ""}</span>;
      }
    },
    {
      key: "commission_rate",
      label: "Comm. Rate",
      element: _ => {
        return <span>{_.commission_rate ? _.commission_rate : ""}</span>;
      }
    },
    {
      key: "commission_amount",
      label: "Comm. Amount",
      element: _ => {
        return (
          <span>
            {_.commission_amount
              ? formatCurrencyUSD(_.commission_amount, 2)
              : ""}
          </span>
        );
      }
    }
  ];
  return (
    <Panel
      id="surety-bond-rating"
      className="surety-bond-rating"
      title="Bond Rating"
    >
      <Table
        rowKey="id"
        id="bondRatingTable"
        columns={bondRatingColumns}
        data={receivedRatings}
        noResultsMessage="No records found"
      />
    </Panel>
  );
}
