import qs from "query-string";
import {
  isOasisPolicyNumber,
  stripPrefix,
  getPrefix
} from "../../../common/policy";

const autoGlassClaimsUrl = window.env.REACT_APP_AUTO_GLASS_CLAIMS_BASEURL;

export const glassRedirect = () => {
  window.open(
    `https://${autoGlassClaimsUrl}/self-service/Default.aspx?ClientTag=BAEFCDD3-0C57-4E2A-A8FD-D6C10C8D2F88`
  );
};

export const handleRedirect = (
  dateOfLoss,
  policyNumber,
  claimType,
  agentCode
) => {
  const queryString = qs.stringify({
    dtloss: dateOfLoss,
    txtpolnum: isOasisPolicyNumber(policyNumber)
      ? stripPrefix(policyNumber)
      : policyNumber,
    claimType,
    AgtCode: agentCode,
    // Unclear whether policyprefix is required for the legacy ufg.com site
    // to work correctly. Providing default of empty string in case it does.
    policyprefix: isOasisPolicyNumber(policyNumber)
      ? getPrefix(policyNumber)
      : ""
  });
  return window.open(
    `${window.env.REACT_APP_LEGACY_SITE_URL}/agentsonly/ufgagent/claims/fnoltransmit.aspx?${queryString}`
  );
};
