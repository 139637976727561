import * as api from "../../../services/onlineQuotingService";

/**
 * FLORIDA WINDHAIL-only lookup to see if WindHail term value is set
 * to "Not Applicable" - used to display the special Florida radio buttons
 */
export const floridaSelectedLocationWindHailIsNotApplicable = ({
  quoteData,
  location // the location DTO object quoteData?.lobData?.bp7BusinessOwners?.coverables?.locations[obj]
}) => {
  const windHailTermValue =
    quoteData?.lobData?.bp7BusinessOwners?.coverages?.locationClausesGroups
      ?.find(
        l =>
          String(l?.coverableFixedId || "") ===
          String(location?.bp7LocationFixedID)
      )
      ?.clausesGroups.defaults?.find(
        c => c?.codeIdentifier === "BP7LocationPropertyDeds"
      )
      ?.terms?.find(t => t?.publicID === "BP7WindHailDed")?.chosenTermValue;

  return (
    windHailTermValue === "Not Applicable" &&
    !windHailCounties.includes(location?.address?.county)
  );
};

export const sendFloridaWindHailNotes = ({ supportingData, quoteData }) => {
  if (!supportingData?.locations) return;
  //check if the customer has a seperate Wind/hail policy...
  // in FL then send a note.
  const locations = Object.values(supportingData?.locations || {});

  const locFLWindKeys = locations.filter(
    loc =>
      !!Object.keys(loc).find(x => x === "floridaWindHail") &&
      loc?.floridaWindHail !== ""
  );

  if (locFLWindKeys.length) {
    //Send Note:
    const payload = [];
    locFLWindKeys.forEach(value => {
      payload.push({
        subject: "Separate wind/hail policy",
        body: `Does the insured have a separate wind/hail policy for location:${
          value?.address
        }? ${value?.floridaWindHail === "Yes" ? "Yes" : "No"}`
      });
    });
    api.sendNote({ payload, quoteId: quoteData.quoteID });
  }
};

const windHailCounties = [
  // tier 1
  "Citrus County",
  "Dixie County",
  "Duval County",
  "Flagler County",
  "Hernando County",
  "Hillsborough County",
  "Levy County",
  "Nassau County",
  "Pasco County",
  "St. Johns County",
  "Volusia County",
  // tier 2
  "Alachua County",
  "Baker County",
  "Bradford County",
  "Clay County",
  "Columbia County",
  "Gilchrist County",
  "Hamilton County",
  "Lafayette County",
  "Lake County",
  "Marion County",
  "Orange County",
  "Osceola County",
  "Polk County",
  "Putnam County",
  "Seminole County",
  "Sumter County",
  "Suwannee County",
  "Union County"
];

// All of the FL counties:
/*
- Alachua County
- Baker County
Bay County
- Bradford County
Brevard County
Broward County
Calhoun County
Charlotte County
- Citrus County
- Clay County
Collier County
- Columbia County
DeSoto County // 319 S Brevard Ave ARCADIA, FL 34266-4308
- Dixie County
- Duval County
Escambia County
- Flagler County
Franklin County
Gadsden County
- Gilchrist County
Glades County
Gulf County
- Hamilton County
Hardee County
Hendry County
- Hernando County
Highlands County
- Hillsborough County
Holmes County
Indian River County
Jackson County
Jefferson County
- Lafayette County
- Lake County
Lee County
Leon County
- Levy County
Liberty County
Madison County
Manatee County
- Marion County // 908 N Pine Ave OCALA, FL 34475-5104
Martin County
Miami-Dade County
Monroe County
- Nassau County
Okaloosa County
Okeechobee County
- Orange County
- Osceola County
Palm Beach County
- Pasco County
Pinellas County
- Polk County
- Putnam County
- St. Johns County
St. Lucie County
Santa Rosa County
Sarasota County
- Seminole County
- Sumter County
- Suwannee County
Taylor County - // 128 S JEFFERSON ST PERRY, FL 32347-3233
- Union County
Volusia County
Wakulla County
Walton County
Washington County
*/
