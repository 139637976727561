import { connect } from "react-redux";
import MakeAPaymentFormHolder from "./makeAPaymentFormHolder";
import {
  dismissMakeAPayment,
  checkPaymentEligibility
} from "./../topActionCardAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  paymentDetails: state.paymentDetails,
  accountData: state.accountData,
  agency_number: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  handleClose: () => dismissMakeAPayment,
  checkPaymentEligibility
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeAPaymentFormHolder);
