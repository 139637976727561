import cn from "classnames";
import React, { useContext } from "react";
import { Panel } from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../OnlineQuotingContext";

const UnderWriterIssues = () => {
  const { stpException, getFilteredUnderwriterIssues } =
    useContext(OnlineQuotingContext);

  const uwIssues = getFilteredUnderwriterIssues();

  const allIssuesResolved = !uwIssues.some(
    u => u.hasApprovalOrRejection === false
  );

  return (
    <>
      {uwIssues.length > 0 && (
        <Panel
          collapsible={stpException}
          isOpen={!allIssuesResolved}
          rounded
          title={
            stpException
              ? "Just a heads up"
              : "Underwriter issues for this quote"
          }
          className={cn("oq__uw-issues__list__panel", {
            "oq__uw-issues__list__panel__stp": stpException
          })}
        >
          <div>
            {
              <div className="oq__uw-issues__list-header__stp">
                <p>
                  {stpException
                    ? "An underwriter needs to take a look at the items noted here after you bind. We'll let you know if updates are needed."
                    : "Below is a list of items needing reviewed by your underwriter."}
                </p>
              </div>
            }
          </div>

          <ul className="oq__uw-issues__list">
            {uwIssues.map(i => (
              <li key={i.publicID}>
                <span className="oq__underwriter__issue_desc">
                  {i.shortDescription}
                </span>
              </li>
            ))}
          </ul>
        </Panel>
      )}
    </>
  );
};

export default UnderWriterIssues;
