import React, { useContext } from "react";
import cn from "classnames";
import "./VehicleFormStep1.scss";
import {
  vehicleTypes,
  vehicleRadiusClassOptions
} from "../../shared/constants";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const ViewVehicle = ({ vehicle }) => {
  const { supportingData } = useContext(OnlineQuotingContext);

  let vehicleEntityName = (vehicle?.entityName || "").replace("entity.", "");
  //Search in supportingData for this vehicle in trailerIDs.
  if ((supportingData?.trailerIDs || []).find(c => c === vehicle.fixedId)) {
    vehicleEntityName = "CA7Trailer";
  }
  const vehicleTypeObj = vehicleTypes.find(t => t.key === vehicleEntityName);

  const coverableDetailItems = [
    {
      label: "VIN",
      value: vehicle?.vin,
      className: "oq__coverable__card__content-vin"
    },
    { label: "Vehicle Type", value: vehicleTypeObj?.label }
  ];
  if (vehicle.radiusClass)
    coverableDetailItems.push({
      label: "Radius",
      value: vehicleRadiusClassOptions.find(
        o => o.value === vehicle.radiusClass
      ).label
    });

  return (
    <div className="oq__coverable__card__content">
      <div className="oq__coverable__card__content__column-container">
        <div className="oq__coverable__card__content__column">
          <ul className="">
            {coverableDetailItems.map(c => {
              if (c.visible !== false)
                return (
                  <li
                    key={c.label}
                    className="oq__coverable__card__content__item"
                  >
                    <b className="oq__coverable__card__content__item-label">
                      {c.label}:
                    </b>
                    <span
                      className={cn(
                        "oq__coverable__card__content__item-value",
                        c?.className
                      )}
                    >
                      {c.value}
                    </span>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewVehicle;
