import React from "react";
import { ContentHeader, Button } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import { withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const ErrorPaymentDetail = ({ history, match }) => {
  const accountNumber = match.params.account_number;
  const { AgencySweep } = Translations;

  const onBackToAccountOverview = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    );
  };

  return (
    <div className="error-details">
      <ContentHeader>We&apos;re Sorry</ContentHeader>
      <div className="row inner-error-details-row">
        <p>
          <HelpDeskMessage
            display="questionsOrAssistance"
            before="There was an error processing this page. The payment attempt was unsuccessful."
          />
        </p>
        <p>
          <b>To make a payment over the phone call:</b> UFG Billing Customer
          Service 1-800-637-6309.
        </p>
        <p>
          <b>Hours:</b> Monday-Friday 7 a.m. to 6 p.m. CT.
        </p>
      </div>
      <br />
      <Button variant="secondary" onClick={onBackToAccountOverview}>
        {AgencySweep.back_to_account_overview}
      </Button>
    </div>
  );
};

ErrorPaymentDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ErrorPaymentDetail);
