import { useState, useEffect, useRef } from "react";
import { getDocuments, getBondDocuments } from "../service";
import { toTitleCase } from "../../../components/Factory";
import { logger } from "../../../loggers";
import { toast } from "react-toastify";

/**
 * Fetches the appropriate documents/forms for Surety.
 * @param {String} agencyCode
 * @param {String} category
 * @param {String{}} formValues
 * @returns [documentsAndForms, isLoading]
 */
export const useGetDocsAndForms = (agencyCode, category, formValues) => {
  const [allDocsAndForms, setAllDocsAndForms] = useState({});
  const [docsAndFormsList, setDocsAndFormsList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const hasErred = useRef(false);

  const handleError = error => {
    hasErred.current = true;
    setTimeout(() => hasErred.current = false, 200);
    toast("Unable to fetch documents and forms", {
      className: "error",
      toastId: "suretyDocumentFormsFetchError"
    });
    logger.error({
      description: error.toString(),
      fatal: false
    });
  };

  useEffect(() => {
    if (!agencyCode || isLoading || hasErred.current) return;

    // Fetch Non-Bonds: Applications, Riders, Other, ConsentOfSurety.
    if (!category.includes("bonds")) {
      setIsLoading(true);

      const cat =
        category === "consent" ? "ConsentOfSurety" : toTitleCase(category);

      if (allDocsAndForms[cat]) {
        setDocsAndFormsList(allDocsAndForms[cat]);
        setIsLoading(false);
      } else {
        getDocuments(agencyCode, cat)
          .then(res => {
            setAllDocsAndForms({ ...allDocsAndForms, [cat]: res.data });
          })
          .catch(handleError)
          .finally(() => setIsLoading(false));
      }
    } else {
      // Fetch bonds docs/forms.
      if (!formValues.state) return; // Required.
      setIsLoading(true);
      const bondsCategory = category.split("bonds")[1]; // ("Contract" || "Commercial").
      const id = JSON.stringify({
        a: formValues.bondType,
        b: formValues.obligeeType,
        c: formValues.state });

      if (allDocsAndForms?.[id]?.[bondsCategory]) {
        setDocsAndFormsList(allDocsAndForms[id][bondsCategory]);
        setIsLoading(false);
      } else {
        getBondDocuments(
          agencyCode,
          toTitleCase(bondsCategory),
          formValues.state,
          formValues.bondType,
          formValues.obligeeType
        )
          .then(res => {
            setAllDocsAndForms({
              ...allDocsAndForms,
              [id]: { [bondsCategory]: res.data }
            });
          })
          .catch(handleError)
          .finally(() => setIsLoading(false));
      }
    }
  }, [
    agencyCode,
    allDocsAndForms,
    category,
    isLoading,
    formValues.bondType,
    formValues.obligeeType,
    formValues.state
  ]);

  return [docsAndFormsList, isLoading];
};
