import { connect } from "react-redux";
import MonthlyExperience from "../components/agency/monthly-experience/MonthlyExperience";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  hasAgencyProductionReportsAccess: sessionSelector.hasAgencyProductionReportsAccess(
    state
  )
});

export default connect(mapStateToProps)(MonthlyExperience);
