import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  cancelQuote,
  dismissCustomerAndAddressValidationError,
  existingCustomerSelected,
  fetchExistingCustomers,
  goToPreviousStep,
  lookupLocationByZipCode,
  saveCustomerStep
} from "../actions/newQuoteActions";
import { getCustomersAsOptions } from "../selectors";
import Customer from "./Customer";

const mapStateToProps = state => ({
  // TODO - Chris: Determine if new quote should use selected agency code or not.
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  agencyNumber: sessionSelector.getAgentCode(state),
  cities: state.newQuote.steps.customer.cities,
  customersByAmsId: state.newQuote.steps.customer.customersByAmsId,

  // Moved this out of CustomerForm due to performance problems with
  // a large number of existing customers (e.g. 109015 agents) that
  // caused text input to hang while typing.
  // Part of: https://ufginsurance.atlassian.net/browse/OPM-5258
  customersAsOptions: getCustomersAsOptions(state),

  error:
    state.newQuote.steps.customer.error ||
    state.newQuote.steps.addressValidation.error,
  hasPreviousSteps: state.newQuote.previousSteps.length > 0,
  isAdverseRiskCustomer: state.newQuote.steps.customer.isAdverseRisk,
  isFromEditCustomer: state.newQuote.initiatedFrom === "Edit Customer",
  isPilotCustomer: state.newQuote.steps.customer.isPilotCustomer,
  isPilotPerson: state.newQuote.steps.customer.isPilotPerson,
  isPilotCompany: state.newQuote.steps.customer.isPilotCompany,
  initialFormValues: state.newQuote.steps.customer.form,
  states: state.newQuote.steps.customer.states,
  account: state.newQuote.steps.customer.account,
  billingCities: state.newQuote.steps.customer.billingCities,
  billingStates: state.newQuote.steps.customer.billingStates,
  loading: state.newQuote.loading.length > 0
});

const mapDispatchToProps = {
  existingCustomerSelected,
  fetchExistingCustomers,
  onClose: cancelQuote,
  onDismissError: dismissCustomerAndAddressValidationError,
  onReset: goToPreviousStep,
  onSubmit: saveCustomerStep,
  onValidZipCodeChange: lookupLocationByZipCode,
  onValidBillingZipCodeChange: lookupLocationByZipCode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
