import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Translations } from "../../../components/translations";
import { glassRedirect, handleRedirect } from "../services/redirect";
import {
  Tabs,
  Tab,
  FormGroup,
  Form,
  formatDate,
  useForm,
  Button,
  DatePicker,
  Dropdown,
  Input,
  Table
} from "@ufginsurance/ui-kit";

import { debounceApi } from "../../../util/debounce";
import API from "../../../api";

let abortController;

const ReportClaim = ({
  agencyNumber,
  onHide,
  isSpecialtyPortalView,
  is109015Agent
}) => {
  const [policyListData, setPolicyListData] = useState([]);
  const [claimType, setClaimType] = useState("");
  const [dateOfLoss, setDateOfLoss] = useState("");

  const [accountSearchInputValue, setAccountSearchInputValue] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [showAccountSearchTable, setShowAccountSearchTable] = useState(false);

  const { report_a_claim } = Translations;
  const { buttons } = Translations.newQuote.lineOfBusiness;

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 365 * 12);

  const maxDate = new Date();

  const automobileText = isSpecialtyPortalView
    ? Translations.report_a_claim.selections.automobile_gkll
    : Translations.report_a_claim.selections.automobile;

  const MIN_SEARCH_CHARS = 3;

  const claimTypeOptions = () => {
    const options = [
      {
        value: "Auto",
        label: automobileText
      },
      {
        value: "Liability",
        label: Translations.report_a_claim.selections.liability
      },
      {
        value: "Property",
        label: Translations.report_a_claim.selections.property
      }
    ];

    return isSpecialtyPortalView
      ? options
      : options.concat({
          value: "Glass",
          label: Translations.report_a_claim.selections.auto_glass
        });
  };

  const formSubmit = ({ values }) => {
    if (values.accountSearch) {
      if (claimType === "Glass") {
        glassRedirect();
      } else {
        handleRedirect(
          dateOfLoss,
          selectedPolicy.policy_number,
          claimType,
          selectedPolicy.agency_code
        );
      }
    }
  };

  const initialValues = {
    accountSearch: "",
    claimType: "",
    datePicker: ""
  };

  const form = useForm({ values: initialValues, onSubmit: formSubmit });
  const { values, updateForm, handleOnChange, handleOnBlur, handleOnValidate } =
    form;

  const claimTypeOnChange = ({ field, value, event }) => {
    setClaimType(value);
    handleOnChange({ field, value, event });
  };

  const datePickerOnChange = ({ field, value, event }) => {
    setDateOfLoss(formatDate(value, "MMDDYYYY"));
    handleOnChange({ field, value, event });
  };

  const optionLabel = useCallback(
    policy => {
      return is109015Agent
        ? `${policy.insured_name} (${policy.insured_city}, ${policy.insured_state}, ${policy.policy_number}, ${policy.policy_description}, ${policy.agency_code}, ${policy.expiration_date})`
        : `${policy.insured_name} (${policy.insured_city}, ${policy.insured_state}, ${policy.policy_number}, ${policy.policy_description}, ${policy.expiration_date})`;
    },
    [is109015Agent]
  );

  useEffect(() => {
    if (
      policyListData.length === 1 &&
      accountSearchInputValue !== optionLabel(policyListData[0])
    ) {
      setAccountSearchInputValue(optionLabel(policyListData[0]));
      setShowAccountSearchTable(false);
      setSelectedPolicy(policyListData[0]);
      updateForm({
        values: { ...values, accountSearch: policyListData[0] },
        errors: { accountSearch: [] }
      });
    }
  }, [
    policyListData,
    optionLabel,
    updateForm,
    values,
    accountSearchInputValue
  ]);

  // TODO: Refactor
  const searchUsingDebounce = debounceApi(value => {
    const policyTypeCommercial = "X";

    if (value.length >= MIN_SEARCH_CHARS) {
      abortController?.abort();
      abortController = new AbortController();

      const params = `agency_number=${agencyNumber}&search_text=${value}&search_type=${policyTypeCommercial}`;
      const options = {
        signal: abortController.signal
      };

      API.agent()
        .get(`/policies?${params}`, options)
        .then(res => {
          setPolicyListData(
            res.data.policies.map(p => {
              const key = v4();

              return {
                id: key,
                ...p,
                expiration_date: formatDate(p.expiration_date, "MM/DD/YYYY"),
                label: optionLabel(p),
                value: key
              };
            })
          );
        });
    }
  }, 500);

  const buttonDisabled = () => {
    return !values.accountSearch || !values.claimType || !values.datePicker;
  };

  const accountSearchColumns = [
    {
      key: "",
      label: "Business Name (City, State)",
      element: row => (
        <div>{`${row.insured_name} (${row.insured_city}, ${row.insured_state})`}</div>
      )
    },
    {
      key: "",
      label: "Policy Number",
      element: row => <div>{row.policy_number}</div>
    },
    {
      key: "",
      label: "Dec",
      element: row => <div>{row.policy_description}</div>
    },
    {
      key: "",
      label: "Exp. Date",
      element: row => <div>{row.expiration_date}</div>
    },
    {
      key: "",
      label: "Agent",
      element: row => <div>{row.agency_code}</div>
    }
  ];

  const accountSearchTable = () => {
    return (
      <Table
        key={"accountSearchTable"}
        rowKey="id"
        columns={accountSearchColumns}
        onRowClick={handleOnRowClick}
        data={policyListData}
        showPagination={values.accountSearch.length > 100}
        itemsPerPage={100}
        selectable
        nowrap
        className="accountSearchTable"
      />
    );
  };

  const handleOnRowClick = data => {
    setAccountSearchInputValue(optionLabel(data.row));
    setShowAccountSearchTable(false);
    setSelectedPolicy(data.row);
    updateForm({
      values: { ...values, accountSearch: data.row },
      errors: { accountSearch: [] }
    });
  };

  const handleAccountSearchChange = ({ value }) => {
    setAccountSearchInputValue(value);
    if (value === "") {
      setPolicyListData([]);
      setShowAccountSearchTable(false);
      updateForm({
        values: { ...values, accountSearch: "" }
      });
    } else if (value.length >= 3) {
      searchUsingDebounce(value);
      setShowAccountSearchTable(true);
    } else {
      setShowAccountSearchTable(false);
    }
  };

  return (
    <div className="report-claim-content">
      <Tabs>
        <Tab title="Online">
          <p className="report-claim-tab-text">{report_a_claim.online_tab}</p>
          <p>
            {Translations.formatString(
              report_a_claim.online_tab_workers_compensation,
              <a
                href="//www.ufginsurance.com/docs/default-source/claims/wc-first-report.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {report_a_claim.injury_or_illness_form}
              </a>
            )}
          </p>
          <div id="report-claim__form">
            <Form context={form}>
              <div id="report-claim__form-upper-inputs">
                <FormGroup className="uikit__form-group__hide-errors">
                  <div className="account-search-input__wrapper">
                    <Input
                      placeholder=""
                      id="accountSearch"
                      name="accountSearch"
                      label="Search for Business Name or Policy Number"
                      onChange={handleAccountSearchChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={accountSearchInputValue}
                      size="full"
                      required
                      requiredError="Account is required"
                      isClearable
                    />
                  </div>
                  {showAccountSearchTable && accountSearchTable()}
                </FormGroup>
              </div>
              <FormGroup>
                <Dropdown
                  id="claimType"
                  name="claimType"
                  label={report_a_claim.labels.claim_type}
                  onChange={claimTypeOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.claimType}
                  options={claimTypeOptions()}
                  size="md"
                  required
                />
                <div className="orSpacer" />
                <DatePicker
                  id="datePicker"
                  name="datePicker"
                  label={report_a_claim.labels.date_of_loss}
                  onChange={datePickerOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.datePicker}
                  format="DATE"
                  required
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </FormGroup>
              <FormGroup align="right">
                <Button variant="plain" onClick={onHide}>
                  {buttons.cancel}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={buttonDisabled()}
                >
                  {buttons.continue}
                </Button>
              </FormGroup>
            </Form>
          </div>
        </Tab>
        <Tab title="Email">
          <p className="report-claim-tab-text">
            {Translations.formatString(
              report_a_claim.email_tab,
              <a
                href={`mailto:${window.env.REACT_APP_NEW_CLAIM_IMAGERIGHT_EMAIL}`}
              >
                {report_a_claim.email}
              </a>
            )}
          </p>
          <p>
            {Translations.formatString(
              report_a_claim.email_tab_workers_compensation,
              <a
                href="//www.ufginsurance.com/docs/default-source/claims/wc-first-report.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {report_a_claim.injury_or_illness_form}
              </a>
            )}
          </p>
        </Tab>
        <Tab title="Phone">
          <p className="report-claim-tab-text">{report_a_claim.phone_tab}</p>
        </Tab>
        <Tab title="Fax">
          <p className="report-claim-tab-text">{report_a_claim.fax_tab}</p>
        </Tab>
      </Tabs>
    </div>
  );
};

ReportClaim.propTypes = {
  onHide: PropTypes.func,
  agencyNumber: PropTypes.string.isRequired,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  is109015Agent: PropTypes.bool.isRequired
};

export default ReportClaim;
