import React, { useEffect, useState, useContext } from "react";
import * as routes from "../constants/routes";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "./OnlineQuotingContext";
import "./OnlineQuotingLayout.scss";

// http://localhost:8080/online_quoting/loader/0001849730
// http://localhost:8080/online_quoting/loader/2020-10-01 09:12:53.053

const QuoteLoader = ({ history, location }) => {
  const [status, setStatus] = useState();

  const { getQuote, step, setStep } = useContext(OnlineQuotingContext);

  useEffect(() => {
    if (step) setStep(null);
    // only run once when component is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // !status and setStatus makes this effect only run one time
    if (!status) {
      setStatus("loading");

      // set step to be the number in the path. e.g "3" in  /online_quoting/step3
      const path = location.pathname;

      const regEx = `(${routes.ONLINE_QUOTING_LOADER}/)(.+)`;
      const qstringId = path.match(regEx) || [];

      const id = qstringId?.[2] || null;

      const queryStr = queryString.parse(location.search);
      const stepOverride = queryStr.step;

      getQuote(id, ({ success, step }) => {
        const stepToUse = stepOverride || step;
        if (!success) setStatus("failure");
        else history.replace(routes.ONLINE_QUOTING + "/step" + stepToUse);
      });
    }
  }, [getQuote, history, location, location.pathname, status]);

  if (status === "loading")
    return (
      <LoadingIndicator
        message="Loading Quote"
        className="oq__quote-loader-wrapper"
      />
    );

  if (status === "failure")
    return (
      <div className="oq__quote-not-found">
        <h2>Unable to load quote.</h2>
        <b>We are sorry... unfortunately, we are unable load the quote.</b>
        <div>
          <Link to={routes.ONLINE_QUOTING_STEP1}>
            Click here to start a new quote.
          </Link>
        </div>
      </div>
    );

  return null;
};

export default QuoteLoader;
