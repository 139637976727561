import moment from "moment";
import qs from "query-string";
import API from "../api";
import { DateFormat } from "../components/Factory";

export const getAccountDetails = search_number => {
  const queryString = qs.stringify({ search_number });
  return API.agent().get(`/accounts?${queryString}`);
};

/**
 * Enum of allowed values for 'search_type' parameter in search policies endpoint
 */
export const SearchPoliciesSearchTypes = {
  All: "A",
  Commercial: "C",
  Personal: "P",
  Bonds: "B",
  CommercialAndPersonal: "X",
  ServiceCenter: "X"
};

export const searchPolicies = (agency_number, search_text, search_type) => {
  const queryString = qs.stringify({
    agency_number,
    search_text,
    search_type
  });
  return API.agent().get(`/policies?${queryString}`);
};

export const searchPolicyTermsHistory = (agency_code, policy_number) => {
  const queryString = qs.stringify({
    agency_code,
    policy_number
  });
  return API.agent().get(`/policies/previous?${queryString}`);
};

export const checkPaymentEligibility = (
  search_number,
  agency_number,
  zip_code
) => {
  const queryString = qs.stringify({
    search_number,
    agency_number,
    zip_code
  });
  return API.agent().get(`/billing/payment-eligibility?${queryString}`);
};

export const fetchAccountServiceLinks = (
  policy_number,
  agency_number,
  company_branch,
  effective_date
) => {
  const queryString = qs.stringify({
    agency_number,
    company_branch,
    effective_date: DateFormat(effective_date, "YYYY-MM-DD")
  });
  return API.agent().get(
    `/policies/${policy_number}/service-links?${queryString}`
  );
};

export const fetchDriversListReport = request => {
  const {
    company_branch,
    agency_code,
    policy_number,
    agency_name,
    insured_name,
    expiration_date
  } = request;
  const momentExpirationDate = moment(expiration_date).isValid()
    ? moment(expiration_date).subtract(
        1,
        "days"
      ) /* a policy expires on the expiration date not through it so pass the prior day */
    : moment(Date.now());
  const queryString = qs.stringify({
    company_branch,
    agent_number: agency_code,
    date: moment
      .min([moment(Date.now()), momentExpirationDate])
      .format("YYYY-MM-DD"),
    policy_number,
    request_type: "print-active",
    agency_name,
    insured_name
  });
  return API.agent().get(`/drivers-report?${queryString}`, {
    responseType: "blob"
  });
};

export const fetchVehiclesListReport = request => {
  const {
    company_branch,
    agency_code,
    policy_number,
    agency_name,
    insured_name,
    expiration_date
  } = request;
  const momentExpirationDate = moment(expiration_date).isValid()
    ? moment(expiration_date).subtract(
        1,
        "days"
      ) /* a policy expires on the expiration date not through it so pass the prior day */
    : moment(Date.now());
  const queryString = qs.stringify({
    company_branch,
    agent_number: agency_code,
    date: moment
      .min([moment(Date.now()), momentExpirationDate])
      .format("YYYY-MM-DD"),
    policy_number,
    request_type: "print-active",
    agency_name,
    insured_name
  });
  return API.agent().get(`/vehicles-report?${queryString}`, {
    responseType: "blob"
  });
};

export const getAutoIdCards = search_number => {
  return API.agent().get(`/policies/${search_number}/auto-id-cards`);
};

export const getAutoIdCard = (id_number, search_number, policy_type) => {
  const queryString = qs.stringify({
    policy_number: search_number,
    policy_type
  });
  return API.documents().get(`/documents/${id_number}?${queryString}`, {
    responseType: "blob"
  });
};
