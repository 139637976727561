import React from "react";
import { BillingResourcesProvider } from "../context";
import TableOfContents from "./TableOfContents";
import Content from "./Content";
import Breadcrumbs from "./Breadcrumbs";

export default function BillngResources({ ssoSession }) {
  return (
    <BillingResourcesProvider ssoSession={ssoSession}>
      <Breadcrumbs />
      <Content />
      <TableOfContents />
    </BillingResourcesProvider>
  );
}
