import React from "react";
import { Popover, Icon } from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";

const Trigger = (
  <span>
    <Icon icon="fasInfoCircle" />
  </span>
);

const InfoIconPopover = ({ content }) => {
  const Overlay = (
    <div>
      <p>{content}</p>
    </div>
  );
  return (
    <span className="helpText">
      <Popover
        id="info-icon-popover"
        placement="right"
        popoverClass="info-icon-popover__overlay"
        triggerContent={Trigger}
        popoverContent={Overlay}
        trigger="click"
      />
    </span>
  );
};

export default InfoIconPopover;

InfoIconPopover.defaultProps = {
  content: Translations.Billing_Details.InfoIconPopover.defaultText
};
