import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translations } from "../../../components/translations";
import { LoadingIndicator, Currency, Table } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const PaymentsPastDueTable = ({
  billingPastDueList,
  selectedAgencyCode,
  isLoading,
  error
}) => {
  const columns = [
    {
      key: "number",
      label: Translations.home_billing_tab.policy,
      element: row => {
        return (
          <Link
            to={`${routes.ACCOUNT_HOME}/${row.number}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`}
          >
            {row.number}
          </Link>
        );
      }
    },
    {
      key: "insured_name",
      label: Translations.home_billing_tab.policyholder,
      element: row => (row.insured_name ? row.insured_name.toUpperCase() : "")
    },
    {
      key: "next_payment_amount",
      label: Translations.home_billing_tab.minimum,
      element: row => <Currency amount={row.next_payment_amount} />,
      align: "right"
    },
    {
      key: "policy_term",
      label: Translations.home_billing_tab.activity,
      element: row => {
        return row.policy_term === "R" ? "Renewal" : "New";
      }
    }
  ];

  const getErrorMessage = e => {
    if (e.response)
      if (e.response.status === 404)
        return Translations.home_billing_tab.not_found;
    return <HelpDeskMessage display="anErrorOccurred" />;
  };

  return (
    <div className="home-billing-table">
      {selectedAgencyCode === "109015" ? (
        Translations.home_billing_tab.billing_payments_internal_user
      ) : isLoading ? (
        <LoadingIndicator />
      ) : error ? (
        <div className="friendly-error-messaging">{getErrorMessage(error)}</div>
      ) : (
        <Table
          rowKey="id"
          data={billingPastDueList}
          columns={columns}
          itemsPerPage={20}
          showPagination
        />
      )}
    </div>
  );
};

PaymentsPastDueTable.propTypes = {
  billingPastDueList: PropTypes.array.isRequired,
  selectedAgencyCode: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.any
};

export default PaymentsPastDueTable;
