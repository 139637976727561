import { connect } from "react-redux";
import AppQuoteStatus from "../components/underwriting/app-quote-status/AppQuoteStatus";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  fetchAppQuoteStatusList,
  fetchAppQuoteStatusReport
} from "../actions/appQuoteStatusAction";

const mapStateToProps = state => ({
  appQuoteStatusList: state.appQuoteStatusListData.submissions,
  selectedAgencyCode: getSelectedAgencyCode(state),
  isLoading: state.appQuoteStatusListData.isLoading,
  error: state.appQuoteStatusListData.error,
  activeUserProducerName: sessionSelector.getFirstAndLastName(state),
  activeAgency: sessionSelector.getActiveAgency(state),
  hasAgencyProductionReportsAccess: sessionSelector.hasAgencyProductionReportsAccess(state)
});

const mapDispatchToProps = {
  fetchAppQuoteStatusList,
  fetchAppQuoteStatusReport
};

export default connect(mapStateToProps, mapDispatchToProps)(AppQuoteStatus);
