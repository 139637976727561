/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import _set from "lodash/set";
import { Button, FlexRow, Panel, SelectNonForm } from "@ufginsurance/ui-kit";
import {
  quotePath,
  productKeys,
  getCoverageGroupPath
} from "../../shared/constants";
import { massageMetadata, sortCoverages } from "../../shared/util.js";
import coveragePanels, {
  getCoverageDetailsForLogging,
  applyExclusions
} from "../../shared/coveragePanels";
import { locationCoverageControls } from "../../shared/dynamicCoverageControls/location";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import ProductCoverages from "../ProductCoverages.js";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";

import "../../Step4PolicyInfo.scss";

import _remove from "lodash/remove";
import AdvantageModal from "../../shared/AdvantageModal";

const LocationFormCoveragesStep2 = ({
  itemData,
  onNextStep,
  onPrevStep,
  locationfixedId,
  coverageExclusions,
  locationCoverages,
  setScheduleItemOpenInCoverable,
  protectionClass = "",
  bp7LocationFixedID,
  onCancel
}) => {
  const {
    quoteData,
    supportingData,
    validateCoverablesPromise,
    updateCoveragesPromise,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [panels, setPanels] = useState([]);
  const [continueWasClicked, setContinueWasClicked] = useState(false);
  const [haveCoveragesChanged, setHaveCoveragesChanged] = useState(false);
  // merge location-specifc coverage exclusions with the data from api metadata
  const mergedCoverageExclusions = mergeExclusions([
    coverageExclusions,
    locationCoverageControls({
      location: itemData,
      quoteData,
      supportingData
    })
  ]);

  //on submit
  const onSubmit = () => {
    setContinueWasClicked(false);

    //Check if coverages have changed.
    if (!haveCoveragesChanged) {
      onNextStep({ item: itemData, newQuoteData: quoteData });
      return;
    }

    validateCoverablesPromise({
      coverableName: "location",
      coverablefixedId: bp7LocationFixedID
    }).then(({ success }) => {
      setHaveCoveragesChanged(false);
      if (success) onNextStep({ item: itemData, newQuoteData: quoteData });
    });
  };

  const saveCoverages = (coverage, termToUpdate, action = "added") => {
    // object shape of the coverage to be updated:
    //lobData.bp7BusinessOwners.coverages.locationClausesGroups(filter by coverableFixedId)[0].clausesGroups.
    // either defaults:[] or coverages:[] or addlCoverages:[],

    if (!getCoverageGroupPath(coverage.coverageCategoryCode) && !termToUpdate)
      toastErrr({
        action: "location step 2 > saveCoverages",
        description: `failed to ${action} coverage/term`,
        details: `getCoverageGroupPath call failed for ${coverage.coverageCategoryCode} (coverage.coverageCategoryCode)`,
        misc: { coverage, termToUpdate, action },
        displayMessage: `An error occurred. Coverage was not ${action}.`
      });

    const coverableToSave = { coverableFixedId: coverage.coverableFixedId };

    _set(coverableToSave, getCoverageGroupPath(coverage.coverageCategoryCode), [
      coverage
    ]);

    const coveragesToSave = {
      bp7BusinessOwners: {
        locationClausesGroups: [coverableToSave]
      }
    };

    updateCoveragesPromise({
      coveragesToSave,
      productName: "bp7BusinessOwners",
      coveragePath: quotePath.bp7LocationCoverages,
      coverageDetails: getCoverageDetailsForLogging({
        coverage,
        termToUpdate,
        action
      })
    }).then(() => {
      setHaveCoveragesChanged(true);

      if (continueWasClicked) {
        onSubmit();
      }
      setContinueWasClicked(false);
    });
  };

  const removeCoverageAndSave = coverageToRemove => {
    coverageToRemove.selected = !coverageToRemove.selected;
    coverageToRemove.terms = [];
    saveCoverages(
      coverageToRemove,
      null,
      coverageToRemove.selected ? "added" : "removed"
    );
  };

  useEffect(() => {
    if (quoteData && !!locationCoverages?.length) {
      const _panels = [];
      const _coverageFields = sortCoverages(locationCoverages);

      const visibleCoverages = applyExclusions(
        _coverageFields,
        coverageExclusions
      );

      if (_coverageFields.length > 0) {
        _panels.push({
          panels: massageMetadata(
            coveragePanels({
              fields: visibleCoverages,
              coverageExclusions: mergedCoverageExclusions,
              removeCoverageAndSave,
              quoteData,
              supportingData,
              setScheduleItemOpenInCoverable
            }),
            quoteData
          ),
          allCoverages: visibleCoverages
        });
      }

      setPanels([..._panels]);
    } else {
      onNextStep();
    }
  }, [quoteData, coverageExclusions, locationCoverages]);

  // manage the status of the form errors to disable the continue button
  const [formValidStatus, setFormValidStatus] = useState(false);
  const updateFormStatus = (formId, invalidFields) => {
    if (formId && invalidFields) {
      const hasErrors = invalidFields.length > 0;
      if (formId === "coverableCoverages" && formValidStatus !== hasErrors)
        setFormValidStatus(hasErrors);
    }
  };

  const goToPreviousStep = () => {
    //if there were changes...run validation
    if (!haveCoveragesChanged) {
      onPrevStep(locationfixedId);
    } else {
      validateCoverablesPromise({
        coverableName: "location",
        coverablefixedId: bp7LocationFixedID
      }).then(({ success }) => {
        if (success) onPrevStep(locationfixedId);
      });
    }
  };

  const contentAfterCoverages = (
    <Panel
      id="oq__location__protection-class-panel"
      bgcolor="white"
      collapsible={false}
      className="oq__location__protection-class-panel"
    >
      <SelectNonForm
        id="protectionClass"
        name="protectionClass"
        label="Protection Class"
        options={[{ value: protectionClass, label: protectionClass }]}
        onChange={() => {}}
        value={protectionClass}
        size="md"
        disabled
        isClearable={false}
      />
    </Panel>
  );

  if (!!itemData?.advantagePointDistanceToCoastRules && !itemData?.isPrimary) {
    return <AdvantageModal Location={itemData} onCancel={onCancel} />;
  }

  return (
    <div id="oq__location__step2">
      <div className="oq__coverage-wrapper">
        {panels &&
          panels.length > 0 &&
          panels.map((p, index) => (
            <ProductCoverages
              key={locationfixedId}
              panels={p.panels}
              allCoverages={p.allCoverages}
              selectedCoverageTitle={productKeys.bp7BusinessOwners.label}
              saveCoverages={saveCoverages}
              isOpen={index < 3}
              popupCoverageSearch={false}
              updateFormStatus={updateFormStatus}
              formId="coverableCoverages"
              contentAfterCoverages={contentAfterCoverages}
              searchTableRowKey="publicID"
            />
          ))}
        <FlexRow>
          <Button
            isLink
            inline
            disabled={formValidStatus}
            onClick={goToPreviousStep}
            className="back"
          >
            Back to Location Details
          </Button>

          <Button
            onMouseDown={() => setContinueWasClicked(true)}
            wrapperClassName="push-right"
            className="continue"
            variant="primary"
            disabled={formValidStatus}
            onClick={onSubmit}
          >
            {!haveCoveragesChanged ? "Close" : "Continue"}
          </Button>
        </FlexRow>
      </div>
    </div>
  );
};

export default LocationFormCoveragesStep2;
