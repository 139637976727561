import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import BillingDetails from "./BillingDetails";
import { Translations } from "../../../components/translations";
import {
  fetchBillingDetailsStatement,
  postBillingDetails
} from "../../../actions/billing";
import {
  LoadingIndicator,
  Button,
  Form,
  useForm,
  Dropdown,
  FlexRow,
  ButtonBar
} from "@ufginsurance/ui-kit";
import map from "ramda/src/map";
import prop from "ramda/src/prop";
import uniqBy from "ramda/src/uniqBy";
import sortBy from "ramda/src/sortBy";
import pipe from "ramda/src/pipe";

const getPolicyNumber = prop("policy_number");

const getUniquePolicies = pipe(
  uniqBy(getPolicyNumber),
  sortBy(getPolicyNumber)
);

const isNotAgencyBilled = policies =>
  policies.filter(p => p.payment_plan !== "Agency Bill");

const getPoliciesAsOptions = pipe(
  getUniquePolicies,
  isNotAgencyBilled,
  map(p => ({
    value: p.policy_number,
    label: `${p.policy_number} - ${p.policy_type}`
  }))
);

const BillingDetailsContainer = ({
  billingDetails,
  fetchBillingDetailsStatement,
  postBillingDetails,
  activeAgencyCode,
  accountNumber,
  policyDetails,
  accountData
}) => {
  const [isAccountDetails, setIsAccountDetails] = useState(true);
  const [isActiveTab, setActiveTab] = useState(true);

  const initialFromDate = new Date();
  initialFromDate.setDate(initialFromDate.getDate() - 365 * 1);

  const initialValues = {
    accountNumber,
    policy_number: ""
  };

  const refreshBillingDetails = useCallback(
    values => {
      fetchBillingDetailsStatement(
        values?.policy_number || accountNumber,
        activeAgencyCode
      );
      setIsAccountDetails(!values?.policy_number);
    },
    [accountNumber, activeAgencyCode, fetchBillingDetailsStatement]
  );

  const handleSubmit = useCallback(
    ({ values }) => {
      refreshBillingDetails(values);
    },
    [refreshBillingDetails]
  );

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const policyNumbersOptions = policyDetails
    ? getPoliciesAsOptions(policyDetails)
    : [];

  useEffect(() => {
    if (accountNumber && activeAgencyCode && values) {
      refreshBillingDetails(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, activeAgencyCode]);

  const showBalance = () => {
    const hasBalance = billingDetails?.activities?.some(
      o => !isNaN(parseInt(o.balance, 10))
    );
    return (
      (isAccountDetails || accountData.accounts.origin === "ufg_core") &&
      hasBalance
    );
  };

  const printBillingDetails = () => {
    let unapplied_amount = null;
    if (!!billingDetails.unapplied_amount) {
      unapplied_amount = Number.parseFloat(
        billingDetails.unapplied_amount
      ).toFixed(2);
    } else {
      unapplied_amount = 0;
    }
    const activities = billingDetails.activities || [];
    activities.sort(function(a, b) {
      return a.activity_date > b.activity_date
        ? -1
        : a.activity_date < b.activity_date
        ? 1
        : 0;
    });
    const postBillingDetailsBody = {
      history: activities
    };
    if (unapplied_amount !== null) {
      postBillingDetailsBody.unapplied_amount = parseFloat(unapplied_amount);
    }

    postBillingDetails(postBillingDetailsBody);
  };

  const handleOnPolicyChange = ({ field, value }) => {
    fetchBillingDetailsStatement(value || accountNumber, activeAgencyCode);
    setIsAccountDetails(!value);
    handleOnChange({ field, value });
  };

  const selectActiveTab = () => {
    setActiveTab(!isActiveTab);
    if (isActiveTab) {
      values.policy_number = policyNumbersOptions[0].value;
      handleOnPolicyChange({
        field: "policy_number",
        value: policyNumbersOptions[0].value
      });
    } else {
      handleOnPolicyChange({ field: "policy_number", value: "" });
    }
  };

  return (
    <>
      <div className="billing-details-container">
        <div className="billing-details-tab">
          <FlexRow>
            <ButtonBar>
              <Button
                name="accountActivity"
                variant={!isActiveTab ? null : "primary"}
                onClick={selectActiveTab}
              >
                {
                  Translations.account_overview.billingDetailsTab
                    .accountActivity
                }
              </Button>
              <Button
                name="policyActivity"
                variant={isActiveTab ? null : "primary"}
                onClick={selectActiveTab}
              >
                {Translations.account_overview.billingDetailsTab.policyActivity}
              </Button>
            </ButtonBar>
            {!isActiveTab && (
              <Form context={form} className="billing-details-policy-form">
                <Dropdown
                  id="policy_number"
                  name="policy_number"
                  noLabel
                  options={policyNumbersOptions}
                  onChange={handleOnPolicyChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.policy_number}
                  isClearable
                  size="lg"
                />
              </Form>
            )}
            <div className="align-right">
              <Button
                variant="primary"
                disabled={!billingDetails.activities}
                onClick={printBillingDetails}
              >
                PRINT
              </Button>
            </div>
          </FlexRow>
        </div>
      </div>

      <div className="billing-details-table">
        {billingDetails.isLoading ? (
          <LoadingIndicator />
        ) : billingDetails?.activities?.length > 0 ? (
          <BillingDetails
            activities={billingDetails.activities}
            showBalance={showBalance()}
          />
        ) : (
          <p>
            {!values?.policy_number
              ? Translations.validators.billing_table_error_account
              : Translations.validators.billing_table_error_policy}
          </p>
        )}
      </div>
    </>
  );
};

BillingDetailsContainer.propTypes = {
  billingDetails: PropTypes.any.isRequired,
  fetchBillingDetailsStatement: PropTypes.func.isRequired,
  postBillingDetails: PropTypes.func.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  policyDetails: PropTypes.array,
  accountData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  billingDetails: state.billingDetails,
  accountData: state.accountData,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  fetchBillingDetailsStatement,
  postBillingDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailsContainer);
