import { connect } from "react-redux";
import QQSideBar from "./QQSideBar";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    suretyQuote: state.suretyQuote.data
  };
};

export default connect(mapStateToProps)(QQSideBar);
