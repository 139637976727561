import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  LoadingIndicator,
  Form,
  FormGroup,
  useForm,
  FlexRow,
  RadioButtonContainer,
  RadioButton,
  SelectNonForm
} from "@ufginsurance/ui-kit";

import Viewer from "./Viewer";
import { Translations } from "../../../../components/translations";

const AgencyStatement = ({
  isLoading,
  error,
  statementList,
  hasAgencyAccountingReportsAccess,
  selectedAgencyCode,
  fetchAccountingStatementViewPDF,
  fetchAgencyStatementListDetails,
  isSpecialtyPortalView,
  fetchPurchasedAgencyCodes,
  purchasedAgencyCodes,
  isLoadingPurchasedAgencyCodes,
  fetchSponsoredAgencyCodes,
  sponsoredAgencyCodes,
  isLoadingSponsoredAgencyCodes
}) => {
  const [changedAgencyOption, setChangedAgencyOption] = useState(null);

  const renderViewer = (title, records) => {
    return records.map(record => (
      <Viewer
        key={record.id}
        title={title}
        statementList={record}
        fetchAccountingStatementViewPDF={fetchAccountingStatementViewPDF}
        report_group={record.report_group}
        // Do not use record's agency_code because sold-to agencies will not work
        // https://ufginsurance.atlassian.net/browse/OPM-5520
        // Api changed to work with sold-to agencies.
        agency_code={record.agency_code}
      />
    ));
  };

  useEffect(() => {
    if (
      fetchPurchasedAgencyCodes &&
      selectedAgencyCode &&
      hasAgencyAccountingReportsAccess
    ) {
      fetchPurchasedAgencyCodes(selectedAgencyCode);
      fetchSponsoredAgencyCodes(selectedAgencyCode);
    }
  }, [
    selectedAgencyCode,
    fetchPurchasedAgencyCodes,
    hasAgencyAccountingReportsAccess,
    fetchSponsoredAgencyCodes
  ]);

  const agencyListTitle = (
    isSpecialtyPortalView,
    hasAgencyAccountingReportsAccess
  ) => {
    return isSpecialtyPortalView && hasAgencyAccountingReportsAccess
      ? Translations.reports.tabAccounting.agencyStatement.broker
      : Translations.reports.tabAccounting.agencyStatement.agency;
  };

  const hasBillingTypeStatements = (billingType, statements) => {
    return (
      (statements?.filter(group => {
        return (
          (group?.report?.filter(statement => {
            return (
              (statement?.items?.filter(item => {
                return (
                  (item?.billing_type || "") === billingType &&
                  (item?.documents?.length || 0) > 0
                );
              })?.length || 0) > 0
            );
          })?.length || 0) > 0
        );
      })?.length || 0) > 0
    );
  };

  const getStatementsByReportGroupAndBillingType = (
    reportGroup,
    statements,
    filterByBillingType
  ) => {
    return statements
      .filter(group =>
        reportGroup === "*" ? true : group.report_group === reportGroup
      )
      .map(group => {
        return {
          ...group,
          report: group.report.map(statement => {
            return {
              ...statement,
              items: statement.items.filter(
                item => (item?.billing_type || "") === filterByBillingType
              )
            };
          })
        };
      });
  };

  const initialValues = { billingType: "direct" };
  const form = useForm({ values: initialValues });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;
  const hasDirectBillStatements = hasBillingTypeStatements(
    "direct",
    statementList
  );
  const hasAgencyBillStatements = hasBillingTypeStatements(
    "agency",
    statementList
  );
  const showRadioButtonFilter =
    hasDirectBillStatements && hasAgencyBillStatements;
  const billingTypeFilter = showRadioButtonFilter
    ? values.billingType
    : hasDirectBillStatements
    ? "direct"
    : "agency";

  const allAgencyListStatementsList = getStatementsByReportGroupAndBillingType(
    "*",
    statementList,
    billingTypeFilter
  );
  const hasAnyAgencyListStatements = hasBillingTypeStatements(
    billingTypeFilter,
    allAgencyListStatementsList
  );

  const agencyOptions = useMemo(() => {
    return [
      {
        label:
          agencyListTitle(
            isSpecialtyPortalView,
            hasAgencyAccountingReportsAccess
          ) + selectedAgencyCode,
        value: selectedAgencyCode
      },
      ...purchasedAgencyCodes.map(agencyCode => {
        return {
          label:
            Translations.reports.tabAccounting.agencyStatement.purchased +
            agencyCode,
          value: agencyCode
        };
      }),
      ...sponsoredAgencyCodes.map(agencyCode => {
        return {
          label:
            Translations.reports.tabAccounting.agencyStatement.sponsored +
            agencyCode,
          value: agencyCode
        };
      })
    ];
  }, [
    hasAgencyAccountingReportsAccess,
    isSpecialtyPortalView,
    purchasedAgencyCodes,
    selectedAgencyCode,
    sponsoredAgencyCodes
  ]);

  useEffect(() => {
    if (
      agencyOptions &&
      selectedAgencyCode &&
      hasAgencyAccountingReportsAccess
    ) {
      const defaultOption = agencyOptions.find(
        o => o.value === selectedAgencyCode
      );
      setChangedAgencyOption(defaultOption);
    }
  }, [selectedAgencyCode, hasAgencyAccountingReportsAccess, agencyOptions]);

  const handleAgencyChange = option => {
    const changedOption = agencyOptions.find(o => o.value === option.value);
    setChangedAgencyOption(changedOption);

    if (
      fetchAgencyStatementListDetails &&
      selectedAgencyCode &&
      hasAgencyAccountingReportsAccess
    ) {
      fetchAgencyStatementListDetails(
        selectedAgencyCode,
        option.value === selectedAgencyCode ? null : option.value
      );
    }
  };

  useEffect(() => {
    if (
      fetchAgencyStatementListDetails &&
      selectedAgencyCode &&
      hasAgencyAccountingReportsAccess
    ) {
      fetchAgencyStatementListDetails(selectedAgencyCode, null);
    }
  }, [
    selectedAgencyCode,
    fetchAgencyStatementListDetails,
    hasAgencyAccountingReportsAccess
  ]);

  return selectedAgencyCode && selectedAgencyCode === "109015" ? (
    <p className="agency-error-message">{Translations.reports.internal_user}</p>
  ) : hasAgencyAccountingReportsAccess ? (
    <div>
      {isLoading ||
      isLoadingPurchasedAgencyCodes ||
      isLoadingSponsoredAgencyCodes ? (
        <LoadingIndicator />
      ) : error ? (
        <p>{Translations.reports.tabAccounting.agencyStatement.error}</p>
      ) : (
        <>
          {(agencyOptions?.length ?? 0) > 1 && (
            <SelectNonForm
              id="statementAgencies"
              name="statementAgencies"
              placeholder="Agency statements"
              onChange={handleAgencyChange}
              value={changedAgencyOption?.value || selectedAgencyCode || ""}
              options={agencyOptions}
              size="lg"
              isClearable={false}
              noLabel
            />
          )}
          {showRadioButtonFilter && (
            <Form context={form}>
              <FormGroup>
                <RadioButtonContainer
                  id="billingType"
                  name="billingType"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.billingType}
                  required
                >
                  <FlexRow align="left">
                    <RadioButton
                      id="directBillStatementsFilter"
                      label="Direct Bill Statements"
                      optionValue="direct"
                      className="agency-statements-button"
                    />
                    <RadioButton
                      id="agencyBillStatementsFilter"
                      label="Agency Bill Statements"
                      optionValue="agency"
                      className="agency-statements-button"
                    />
                  </FlexRow>
                </RadioButtonContainer>
              </FormGroup>
            </Form>
          )}
          {!hasAnyAgencyListStatements && changedAgencyOption && (
            <span>
              <br />
              No agency statements exist.
            </span>
          )}
          {hasAnyAgencyListStatements &&
            renderViewer(
              changedAgencyOption?.label || "",
              allAgencyListStatementsList,
              changedAgencyOption?.value || ""
            )}
        </>
      )}
    </div>
  ) : (
    <p> {Translations.reports.tabAccounting.agencyStatement.error_message}</p>
  );
};

AgencyStatement.propTypes = {
  selectedAgencyCode: PropTypes.string.isRequired,
  statementList: PropTypes.array.isRequired,
  error: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  fetchAgencyStatementListDetails: PropTypes.func.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  hasAgencyAccountingReportsAccess: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  fetchPurchasedAgencyCodes: PropTypes.func.isRequired,
  purchasedAgencyCodes: PropTypes.array,
  isLoadingPurchasedAgencyCodes: PropTypes.bool,
  fetchSponsoredAgencyCodes: PropTypes.func.isRequired,
  sponsoredAgencyCodes: PropTypes.array,
  isLoadingSponsoredAgencyCodes: PropTypes.bool
};

export default AgencyStatement;
