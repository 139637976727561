import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OasisTable from "./oasisTable";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import { Translations } from "../components/translations";

const Oasis = props => {
  const {
    documentSummary,
    isLoading,
    documentSummaryError,
    fetchGWCurrentPolicyDocument,
    policyType,
    policy,
    policyNumber,
    fetchDocumentSummary,
    policyTerm
  } = props;

  const [prevPolicyNumber, setPrevPolicyNumber] = useState();

  useEffect(() => {
    if (!!policyNumber && policyNumber !== prevPolicyNumber) {
      fetchDocumentSummary(policyNumber, policyTerm);
      setPrevPolicyNumber(policyNumber);
    }
  }, [fetchDocumentSummary, policyNumber, policyTerm, prevPolicyNumber]);

  return (
    <div className="current-policy-container">
      {documentSummaryError ? (
        Translations.account_overview.currentPolicy.error
      ) : isLoading ? (
        <LoadingIndicator />
      ) : (
        <OasisTable
          documentSummary={documentSummary}
          fetchGWCurrentPolicyDocument={fetchGWCurrentPolicyDocument}
          policyType={policyType}
          policy={policy}
        />
      )}
    </div>
  );
};

Oasis.propTypes = {
  policyNumber: PropTypes.string.isRequired,
  policyTerm: PropTypes.string.isRequired,
  documentSummary: PropTypes.array.isRequired,
  documentSummaryError: PropTypes.any,
  isLoading: PropTypes.any,
  fetchDocumentSummary: PropTypes.func.isRequired,
  fetchGWCurrentPolicyDocument: PropTypes.func,
  policyType: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired
};

export default Oasis;
