import { connect } from "react-redux";
import policyChangeCommercialVehicle from "./policyChangeCommercialVehicle";

const mapStateToProps = (state, ownProps) => ({
  policyInfo: ownProps.policyInfo
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(policyChangeCommercialVehicle);
