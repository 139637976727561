import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Translations } from "../components/translations";
import { PhoneLink } from "@ufginsurance/ui-kit";

const HelpDeskMessage = ({ display, before, after, noLinks }) => {
  const IsNotLoaded = value =>
    !value || (typeof value === "object" && Object.keys(value).length === 0);

  let helpDeskDetails = useSelector(state => state.helpDesk.helpDeskDetails);

  if (IsNotLoaded(helpDeskDetails)) {
    helpDeskDetails = {
      open_time: "7 a.m.",
      close_time: "5 p.m.",
      time_zone: "CT",
      phone: "800-895-6253",
      email: "helpdesk@unitedfiregroup.com"
    };
  }

  const generic = "{0} is available between {1} and {2} {3} at {4}.";
  const questionsOrAssistance =
    "For questions or assistance, please contact UFG {0} @ {4} between {1} and {2} {3} Monday through Friday.";
  const pleaseTryAgain =
    "Please try again or contact UFG {0} at {4} between {1} and {2} {3} Monday through Friday.";
  const anErrorOccurred =
    "We're sorry, but an error occurred while processing your request. Please try again. For further assistance, contact UFG {0} by phone at {4} between {1} and {2} {3} Monday through Friday or by email at {5}.";
  const trySubmittingAgain =
    "We are experiencing issues processing your request. Please try submitting again or contact UFG {0} by phone at {4} between {1} and {2} or by email at {5}.";

  let message = generic;
  if (display === "questionsOrAssistance") message = questionsOrAssistance;
  else if (display === "pleaseTryAgain") message = pleaseTryAgain;
  else if (display === "trySubmittingAgain") message = trySubmittingAgain;
  else if (display === "anErrorOccurred") message = anErrorOccurred;

  const phoneNumber = noLinks ? (
    helpDeskDetails.phone
  ) : (
    <PhoneLink phoneNumber={`+1${helpDeskDetails.phone}`}>
      {helpDeskDetails.phone}
    </PhoneLink>
  );

  const helpDesk = noLinks ? (
    "Help Desk"
  ) : (
    <a href={"mailto:" + helpDeskDetails.email}>Help Desk</a>
  );

  const helpDeskEmail = noLinks ? (
    helpDeskDetails.email
  ) : (
    <a href={"mailto:" + helpDeskDetails.email}>{helpDeskDetails.email}</a>
  );

  return (
    <span>
      {!!before && <>{before} </>}
      {Translations.formatString(
        message,
        helpDesk,
        helpDeskDetails.open_time,
        helpDeskDetails.close_time,
        helpDeskDetails.time_zone,
        phoneNumber,
        helpDeskEmail
      )}
      {!!after && <> {after}</>}
    </span>
  );
};

HelpDeskMessage.propTypes = {
  display: PropTypes.oneOf([
    "availability",
    "questionsOrAssistance",
    "pleaseTryAgain",
    "trySubmittingAgain",
    "anErrorOccurred"
  ]),
  before: PropTypes.any,
  after: PropTypes.any,
  noLinks: PropTypes.bool
};

export default HelpDeskMessage;
