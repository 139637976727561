import React from "react";
import PropTypes from "prop-types";
import ErrorView from "./ErrorView";
import ErrorLevel from "../constants/errorLevel";
import errorCodes from "../constants/errorCodes";

const CustomErrorView = ({ error }) => {
  if (!error) return null;

  const errStatus =
    error.data && error.data.status
      ? parseInt(error.data.status, 10)
      : errorCodes.ServerError;

  return (errStatus === 400 || errStatus === 500) &&
    error.data &&
    (error.data.message || error.data.error) ? (
    <p className={`alert alert-danger alertNoMargin`}>
      {error.data.message || error.data.error}
    </p>
  ) : (
    <ErrorView statusCode={errStatus} level={ErrorLevel.danger} />
  );
};

CustomErrorView.propTypes = {
  error: PropTypes.object
};

export default CustomErrorView;
