import * as types from "../actions/actionTypes";
import { v4 } from "uuid";

export const initialState = {
  notes: {},
  loading: {},
  errors: {}
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLAIMS_NOTES_STARTED: {
      const { claimNumber } = action.payload;
      return {
        ...state,
        loading: {
          ...state.loading,
          [claimNumber]: true
        },
        errors: {
          ...state.errors,
          [claimNumber]: null
        }
      };
    }
    case types.FETCH_CLAIMS_NOTES_SUCCEEDED: {
      const { claimNumber, data } = action.payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [claimNumber]: data.map(n => ({
            id: v4(),
            ...n
          }))
        },
        loading: {
          ...state.loading,
          [claimNumber]: false
        }
      };
    }
    case types.FETCH_CLAIMS_NOTES_FAILED: {
      const { claimNumber, error } = action.payload;
      return {
        ...state,
        notes: {
          ...state.notes,
          [claimNumber]: []
        },
        loading: {
          ...state.loading,
          [claimNumber]: false
        },
        errors: {
          ...state.errors,
          [claimNumber]: error
        }
      };
    }

    default:
      return state;
  }
};
