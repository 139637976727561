import API from "../../../api";
import qs from "query-string";

export const getTableOfContents = companyBranch => {
  const params = companyBranch
    ? {
        company_branch: companyBranch
      }
    : {};

  const queryString = qs.stringify(params);
  return API.agent().get(`/resources/claims/table-of-contents?${queryString}`);
};

export const getContent = (contentId, companyBranch) => {
  const params = companyBranch
    ? {
        company_branch: companyBranch
      }
    : {};

  const queryString = qs.stringify(params);

  return API.agent().get(
    `/resources/claims/table-of-contents/${contentId}?${queryString}`
  );
};
