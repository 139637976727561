import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import NavItem from "react-bootstrap/lib/NavItem";
import { Translations } from "./translations";
import * as routes from "../constants/routes";

const RouteNavItem = props => {
  const {
    to,
    eventKey,
    children,
    onSelect,
    location,
    activeAtRoot,
    tabRoot,
    closeMenu,
    history,
    onFocus,
    onMouseEnter,
    onMouseLeave
  } = props;

  const path = location.pathname.toLowerCase();

  // we are active if path matches the to location
  // or path is at root and we can be active when at root.
  let isActive =
    path === to.toLowerCase() ||
    (path.startsWith(to.toLowerCase()) && to.toLowerCase() !== "/") ||
    (activeAtRoot && path === "/") ||
    (tabRoot && path.toLowerCase().includes(tabRoot));

  if (
    path === routes.ACCOUNT_HOME &&
    children === Translations.navigation.account
  ) {
    isActive = true; // account navigation
  }
  if (
    path.startsWith(routes.HOME_ROOT_FRAGMENT) &&
    children === Translations.navigation.home
  ) {
    isActive = true; // home navigation
  }

  const pushToHistory = () => {
    history.push(to);

    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <NavItem
      className="route-item"
      eventKey={eventKey}
      onSelect={onSelect}
      onClick={pushToHistory}
      active={isActive}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </NavItem>
  );
};

RouteNavItem.defaultProps = {
  activeAtRoot: false
};

RouteNavItem.propTypes = {
  activeAtRoot: PropTypes.bool,
  children: PropTypes.any,
  closeMenu: PropTypes.func,
  eventKey: PropTypes.string,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  to: PropTypes.string.isRequired,
  tabRoot: PropTypes.string,
  onFocus: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default withRouter(RouteNavItem);
