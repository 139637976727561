import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useNewBusinessContext } from "../context";
import { PanelGrid, Panel, Anchor } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";

const TableOfContents = () => {
  const {
    newBusinessData,
    fetchTableOfContents,
    tableOfContentId,
  } = useNewBusinessContext();

  const history = useHistory();

  const [tableOfContents, setTableOfContents] = useState([]);

  const businessHeaders = tableOfContents.filter(item => item.type === "Header");

  const buildPanelContent = (header, currentIndx, headers) => {
    let subHeaders = null;
    if (currentIndx === headers.length - 1) {
      subHeaders = tableOfContents.filter(t => t.sequence > header.sequence);
    } else {
      const nxtHdr = headers[currentIndx + 1];
      subHeaders = tableOfContents.filter(
        t => t.sequence > header.sequence && t.sequence < nxtHdr.sequence
      );
    }

    return subHeaders.map(subHeader => {
      const isCurrent = subHeader.id === tableOfContentId;
      const subHeaderClass = isCurrent
        ? "table-of-contents__link--disabled"
        : "table-of-contents__link";
      return (
        <Anchor
          className={`${subHeaderClass}`}
          key={subHeader.sequence}
          onClick={() => {
            if (subHeader.open_new_window) {
              window.open(`${subHeader.navigation_link}`, "_blank");
            }
            else if (!isCurrent) {
              history.push(
                `${routes.NEW_BUSINESS}/header/${header.id}/subheader/${subHeader.id}`
              );
            }
          }}
        >
          {subHeader.description}
        </Anchor>
      );
    });
  };

  useEffect(() => {
    if (newBusinessData.tableOfContents.data.length === 0) {
      fetchTableOfContents();
    } else if (newBusinessData.tableOfContents.data.length >= 1) {
      setTableOfContents(newBusinessData.tableOfContents.data);
    }
  }, [
    newBusinessData.tableOfContents.data,
    fetchTableOfContents
  ]);

  return (
    <div className="table-of-contents">
      <PanelGrid sizes={{ sm: 2, md: 3, lg: 3, xl: 3 }}>
        {businessHeaders.map((header, index) => {
          return (
            <Panel title={header.description} key={header.sequence}>
              {buildPanelContent(header, index, businessHeaders)}
            </Panel>
          );
        })}
      </PanelGrid>
    </div>
  );
};

export default TableOfContents;
