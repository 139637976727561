import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@ufginsurance/ui-kit";
import RenderForm from "./renderForm";
import { Translations } from "./../components/translations";
import { ufginsurance } from "./../constants/images";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const Umail = ({
  agentCode,
  handleClose,
  name,
  email,
  agency,
  postUmailMessage,
  producer_number,
  isActivated,
  isSpecialtyPortalView
}) => {
  const initialState = {
    formData: {},
    showConfirmation: false,
    submitError: null,
    submission: null,
    isSubmitting: false,
    confirmedAttachments: []
  };
  const [formState, setFormState] = useState(initialState);

  const closeClickedHandler = () => {
    setFormState(initialState);
    handleClose();
  };

  const handleSubmit = ({
    emailConfirmation,
    subject,
    referenceNumber,
    referenceType,
    message,
    attachments
  }) => {
    const form = {
      agency_code: agentCode,
      producer_number,
      producer_name: name,
      producer_email: email,
      send_agent_confirmation_email: emailConfirmation,
      subject_type: subject,
      email_message: message,
      reference_number: referenceNumber,
      reference_type: referenceType
    };
    setFormState({
      ...formState,
      submitError: null,
      isSubmitting: true,
      confirmedAttachments: []
    });
    postUmailMessage(form, attachments)
      .then(() => {
        setFormState({
          ...formState,
          showConfirmation: true,
          submission: { ...form },
          isSubmitting: false,
          confirmedAttachments: attachments
        });
      })
      .catch(() => {
        setFormState({
          ...formState,
          submitError: <HelpDeskMessage display="anErrorOccurred" />,
          isSubmitting: false,
          confirmedAttachments: []
        });
      });
  };

  const renderConfirmation = () => {
    return (
      <div>
        <b>{Translations.umail.umail_header.thank_you_message}</b>
        <div>
          {Translations.umail.umail_header.producer_name}{" "}
          {formState.submission.producer_name}
        </div>
        <div>
          {Translations.umail.umail_header.producer_number} {agentCode}{" "}
          {formState.submission.producer_number}
        </div>
        <div>
          {Translations.umail.umail_header.producer_email}{" "}
          {formState.submission.producer_email}
        </div>
        <br />
        <div className="umail-confirmation__section">
          <div>{Translations.umail.umail_header.message_header}</div>
          <div>{formState.submission.email_message}</div>
        </div>
        <br />
        <div className="umail-confirmation__section">
          <div>{Translations.umail.umail_header.attachments_header}</div>
          <div>
            {formState.confirmedAttachments.length
              ? formState.confirmedAttachments.map(val => (
                  <div key={val.name}>{val.name}</div>
                ))
              : Translations.umail.umail_header.no_attachments}
          </div>
        </div>
        <br />
        <div className="umail-confirmation__section">
          <div>
            {Translations.umail.umail_header.file_number}{" "}
            {formState.submission.reference_number}
          </div>
          <div>
            {Translations.umail.umail_header.user_data} {agentCode}
          </div>
        </div>
        <br />
        <p>{Translations.umail.umail_header.confirmation_message}</p>
        <div className="modal-form-buttons">
          <Button variant="secondary" onClick={closeClickedHandler}>
            {Translations.general.close}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={isActivated}
      onHide={closeClickedHandler}
      title={
        formState.showConfirmation ? (
          <img
            src={ufginsurance}
            className="ufg_insurance_logo"
            alt="UFG Insurance Logo"
          />
        ) : (
          Translations.umail.umail_header.umail
        )
      }
      subTitle={Translations.umail.umail_header.header_message}
      body={
        formState.showConfirmation ? (
          renderConfirmation()
        ) : (
          <RenderForm
            handleSubmit={handleSubmit}
            handleClose={closeClickedHandler}
            submitError={formState.submitError}
            name={name}
            email={email}
            agency={agency}
            isSpecialtyPortalView={isSpecialtyPortalView}
            isSubmitting={formState.isSubmitting}
          />
        )
      }
      altCloseMethod={false}
      className="umail-modal"
    />
  );
};

Umail.propTypes = {
  agentCode: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  agency: PropTypes.string,
  postUmailMessage: PropTypes.func,
  producer_number: PropTypes.string,
  isActivated: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool.isRequired
};

export default Umail;
