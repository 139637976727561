import React, { useState } from "react";
import "../shared/ViewCoverages.scss";
import {
  getDefaultValueFromTerm,
  findCoverageControl,
  PcInputNumberTypes,
  findCoverageTermsControl
} from "../shared/coveragePanels";
import {
  Panel,
  Button,
  LoadingIndicator,
  formatDate
} from "@ufginsurance/ui-kit";
import ScheduleItemTable from "../shared/scheduleItems/ScheduleItemTable";
import { stringToCurrency, sortCoverages } from "../shared/util";
import classNames from "classnames";

export const displayedCoverages = ({ coverages, coverageExclusions }) =>
  (sortCoverages(coverages) || []).filter(c => {
    //skip coverages that are supposed to be hidden.
    const coverageControl = findCoverageControl(
      c.codeIdentifier,
      coverageExclusions
    );
    return coverageControl?.control !== "hidden" && c.selected;
  });

export const isMissingRequiredTerms = ({ coverages }) =>
  coverages.some(
    d =>
      (d?.clauseScheduleItems &&
        d?.clauseScheduleItems?.scheduleItems.length === 0) ||
      (d?.terms || []).some(
        t =>
          t.required &&
          ((t.chosenTermValue === undefined || t.chosenTermValue) === null ||
            t.chosenTermValue === "")
      )
  );

const CoveragesInCards = ({
  coverageFields,
  coverageExclusions,
  alwaysDisplay = false,
  editCoverable,
  coverable,
  id,
  editScheduledItems = true,
  handleEditItem
}) => {
  const [show, setShow] = useState(false);

  if (coverageExclusions === undefined)
    return (
      <LoadingIndicator
        message="Loading Coverages"
        type="spinner"
        className="oq__view__coverage__loading-metadata"
      />
    );
  if (!coverageFields || coverageFields?.length === 0) return null;

  return (
    <div
      className={classNames("oq__card-coverage-list__container", {
        "oq__card-coverage-list__container__expanded": show,
        "oq__card-coverage-list__container__collapsed": !show
      })}
    >
      {!alwaysDisplay && (
        <div className="oq__view__coverage__toggle-button">
          <Button
            isLink
            onClick={() => setShow(y => !y)}
            icon={show ? "farAngleUp" : "farAngleDown"}
            inline
          >
            {show
              ? `Coverages (${coverageFields.length})`
              : `Coverages (${coverageFields.length})`}
          </Button>
        </div>
      )}
      {(show || alwaysDisplay) && (
        <>
          <div
            className={classNames("oq__card-coverage-list", {
              "oq__card-coverage-list__alwaysDisplay": alwaysDisplay
            })}
          >
            {coverageFields.map(c => {
              const missingRequiredTerms =
                !c?.clauseScheduleItems &&
                (c?.terms || []).some(
                  t =>
                    t.required &&
                    (t.chosenTermValue === undefined ||
                      t.chosenTermValue === null ||
                      t.chosenTermValue === "")
                );

              return (
                <Panel
                  key={c.rowKey || c.publicID}
                  id={id}
                  bgcolor="white"
                  className="oq__view__coverage-panel"
                  highlight="blue"
                >
                  <div className="oq__view__coverage__title">
                    <span className="oq__view__coverage__title-header">
                      <span className="oq__view__coverage__title-text">
                        {c.name}
                      </span>
                      {missingRequiredTerms && (
                        <span className="oq__view__coverage__missing-required">
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() =>
                              handleEditItem
                                ? handleEditItem()
                                : editCoverable(coverable)
                            }
                          >
                            Complete Coverage
                          </Button>
                        </span>
                      )}
                    </span>
                    {c?.classificationName && (
                      <span className="oq__view__coverage__title-classificationName">
                        {c?.classificationName}
                      </span>
                    )}
                  </div>

                  {c.hasOwnProperty("clauseScheduleItems") ? (
                    <ScheduleItemTable
                      field={c}
                      scheduleItems={c.clauseScheduleItems}
                      coverableFixedId={c.coverableFixedId}
                      editScheduledItems={editScheduledItems}
                    />
                  ) : (
                    c.terms
                      .filter(t => !t.hasOwnProperty("jsx")) // filters out bureau id helper elements (like the "or" text that displays between fields)
                      .map(t => {
                        // hide terms that are hidden using the coverageControls
                        if (
                          (
                            findCoverageControl(
                              c.codeIdentifier,
                              coverageExclusions
                            )?.termControl || []
                          ).some(
                            c =>
                              t.patternCode &&
                              c.patternCode === t.patternCode &&
                              c.control === "hidden"
                          )
                        )
                          return null;

                        const termControls = findCoverageTermsControl(
                          c.publicID,
                          t.publicID,
                          coverageExclusions
                        );

                        if (
                          termControls?.control === "hidden" ||
                          c.selected === false //Don't show terms when selected is false. OOQ-3049
                        )
                          return null;

                        let value = getDefaultValueFromTerm(t);
                        const nonSelected =
                          (t.options || []).length === 1 &&
                          t.options[0].name === "None Selected";

                        if (
                          !nonSelected &&
                          (t.options || []).length > 0 &&
                          t.hasOwnProperty("chosenTerm")
                        )
                          value = t.options.find(o => o.code === value)?.name;

                        if (t.valueType === "Money")
                          value = stringToCurrency(value, 0);
                        else if (t.valueType === "datetime")
                          value = formatDate(value, "M/D/YYYY");
                        // if it's a number, use "toLocaleString" to inject the commas into it
                        // but only if it's a format that SHOULD be a number (this lets Ids and text fields stay the same)
                        else if (
                          PcInputNumberTypes.includes(t?.valueType) &&
                          !isNaN(value)
                        )
                          value = Number(value).toLocaleString();

                        return (
                          <div
                            key={t.publicID}
                            className="oq__view__coverage__term"
                          >
                            <span className="oq__view__coverage__term-name">
                              {t.name.replace(/:$/, "")}:
                            </span>
                            <span className="oq__view__coverage__term-value">
                              {value || ""}
                            </span>
                          </div>
                        );
                      })
                  )}
                </Panel>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default CoveragesInCards;
