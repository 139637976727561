import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const InboxMessages = ({ messages, openMessage, quote_number }) => {
  const handleClick = item => {
    openMessage(item, messages.can_reply);
  };

  return (
    <div>
      {messages.has_messages ? (
        <div className="message-body">
          <div className="quote-count"> Quote: {quote_number} </div>
          <div className="message-container">
            {messages.conversations &&
              messages.conversations.map((item, i) => (
                <div
                  key={item.id}
                  id={i}
                  onClick={() => handleClick(item)}
                  onKeyPress={() => handleClick(item)}
                  className={item.unread_message_count > 0 ? "has-unread" : ""}
                  role="button"
                  tabIndex={0}
                >
                  <h5>{item.subject}</h5>
                  <p>
                    <i>
                      Last updated by:
                      <i className="author-name">
                        {item.messages[item.messages.length - 1].author.name}
                      </i>{" "}
                      on{" "}
                      {moment(
                        item.last_updated + " +0000",
                        "MMM D, YYYY h:mm A Z"
                      ).format("lll")}
                      <span className="unread-count">
                        {item.unread_message_count} unread
                      </span>
                    </i>
                  </p>
                </div>
              ))}
          </div>
        </div>
      ) : (
        "There are no messages to display"
      )}
    </div>
  );
};

InboxMessages.propTypes = {
  openMessage: PropTypes.func,
  quote_number: PropTypes.string,
  messages: PropTypes.object
};

export default InboxMessages;
