import * as types from "../constants/actionTypes";
import * as currentPolicyService from "../services/currentPolicyService";
import { logger } from "../loggers";
import { DocumentService } from "../services/documentService";
import { CURRENT_POLICY_TERM } from "./policyTermSummary";

const fetchCurrentSummaryStarted = () => ({
  type: types.FETCH_CURRENT_SUMMARY_STARTED
});

const fetchCurrentSummarySucceeded = data => ({
  type: types.FETCH_CURRENT_SUMMARY_SUCCEEDED,
  payload: data
});

const fetchCurrentSummaryFailed = error => ({
  type: types.FETCH_CURRENT_SUMMARY_FAILED,
  payload: error,
  error: true
});

export const fetchCurrentSummary =
  (activeAgencyCode, policyNumber, effectiveDate, companyBranch) =>
  dispatch => {
    dispatch(fetchCurrentSummaryStarted());

    return currentPolicyService
      .getCurrentSummary(
        policyNumber,
        activeAgencyCode,
        effectiveDate,
        companyBranch
      )
      .then(
        response => {
          dispatch(fetchCurrentSummarySucceeded(response.data));
        },
        error => {
          dispatch(fetchCurrentSummaryFailed(error));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

const fetchOasisDocumentSummaryStarted = isCurrentTerm => ({
  type: types.FETCH_OASIS_DOCUMENT_SUMMARY_STARTED,
  isCurrentTerm
});

const fetchOasisDocumentSummarySucceeded = (data, isCurrentTerm) => ({
  type: types.FETCH_OASIS_DOCUMENT_SUMMARY_SUCCEEDED,
  payload: data,
  isCurrentTerm
});

const fetchOasisDocumentSummaryFailed = (error, isCurrentTerm) => ({
  type: types.FETCH_OASIS_DOCUMENT_SUMMARY_FAILED,
  payload: error,
  error: true,
  isCurrentTerm
});

export const fetchOasisDocumentSummary =
  (policyNumber, policyTerm) => dispatch => {
    dispatch(
      fetchOasisDocumentSummaryStarted(policyTerm === CURRENT_POLICY_TERM)
    );
    return currentPolicyService
      .getOasisDocumentSummary(policyNumber, policyTerm)
      .then(
        response => {
          dispatch(
            fetchOasisDocumentSummarySucceeded(
              response.data,
              policyTerm === CURRENT_POLICY_TERM
            )
          );
        },
        error => {
          dispatch(
            fetchOasisDocumentSummaryFailed(
              error,
              policyTerm === CURRENT_POLICY_TERM
            )
          );
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const fetchUFGDocumentSummaryStarted = (
  declarationCode,
  policyTerm
) => ({
  type: types.FETCH_UFG_DOCUMENT_SUMMARY_STARTED,
  declarationCode,
  policyTerm
});

export const fetchUFGDocumentSummarySucceeded = (
  declarationCode,
  data,
  policyTerm
) => {
  return {
    type: types.FETCH_UFG_DOCUMENT_SUMMARY_SUCCEEDED,
    payload: data.map(c => ({
      dec_code: declarationCode,
      form_type: c.document_type,
      ...c
    })),
    declarationCode,
    policyTerm
  };
};

export const fetchUFGDocumentSummaryFailed = (
  declarationCode,
  policyTerm,
  error
) => ({
  type: types.FETCH_UFG_DOCUMENT_SUMMARY_FAILED,
  declarationCode,
  policyTerm,
  error
});

export const fetchUFGDocumentSummary =
  (activeAgencyCode, policyType, declarationCode, policy, policyTerm) =>
  dispatch => {
    dispatch(fetchUFGDocumentSummaryStarted(declarationCode, policyTerm));

    return currentPolicyService
      .getUFGDocumentSummary(
        activeAgencyCode,
        policyType,
        declarationCode,
        policy,
        policyTerm
      )
      .then(
        response => {
          dispatch(
            fetchUFGDocumentSummarySucceeded(
              declarationCode,
              response.data,
              policyTerm
            )
          );
        },
        error => {
          logger.error({
            description: error.toString()
          });
          dispatch(
            fetchUFGDocumentSummaryFailed(declarationCode, policyTerm, error)
          );
        }
      );
  };

export const fetchCurrentPolicyDocument = data => () => {
  const documentService = new DocumentService();
  return currentPolicyService.selectDocumentsToView(data).then(
    response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `CurrentPolicyDocument.pdf`
      );
      return response;
    },
    () => {
      documentService.clearRenderer();
    }
  );
};

export const fetchGWCurrentPolicyDocument =
  (document_id, policy_number, policy_type) => () => {
    const documentService = new DocumentService();
    return currentPolicyService
      .selectGWDocumentsToView(document_id, policy_number, policy_type)
      .then(
        response => {
          documentService.initializeRenderer("/loading-document");
          documentService.saveOrOpenDocument(
            response.data,
            `Current-Policy-Document-Guidewire.pdf`
          );
        },
        () => {
          documentService.clearRenderer();
        }
      );
  };
