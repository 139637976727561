import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

/**
 * Returns the agency code based on:
 * 1. Are they a home office agent?
 * 2. Have they impersonated an agent?
 * 3. Have they entered a 6 digit agency code.
 */
export const getSelectedAgencyCode = state => {
  const activeAgencyCode = sessionSelector.getActiveAgencyCode(state);
  const is109015Agent = sessionSelector.isAgentIn109015AgencyCode(state);
  const selectedAgencyCode =
    state && state.currentAgency && state.currentAgency.agencyNumber;
  return !is109015Agent
    ? activeAgencyCode
    : !selectedAgencyCode
      ? activeAgencyCode
      : selectedAgencyCode;
};
