import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Icon,
  Modal,
  Form,
  useForm,
  Dropdown,
  FormGroup,
  TextArea,
  Table
} from "@ufginsurance/ui-kit";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { getProducerCode } from "../../../selectors/agentSelectors";
import { postSuretyUploadDocuments } from "../../services";
import DragAndDropAttachments from "../../../components/commercial/commercial-lines/DragAndDropAttachments";
import { ALLOWED_FILE_TYPES } from "../../../quick-program/quick-quoting/constants/suretyDictionary";
import AccountBondSearchInput from "../../home/components/AccountBondSearchInput";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";
import { convertBytesToMB, exceedsMaxSize } from "../../shared/utils";
import { logger } from "../../../loggers";
import ProducerDropdown from "../../../shared/ProducerDropdown/ProducerDropdown";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

function DocumentUploadModals({
  account,
  agencyCode,
  producerCode,
  postSuretyUploadDocuments,
  showModal,
  setShowModal,
  fileTypes = ALLOWED_FILE_TYPES
}) {
  const [showAlert, setShowAlert] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isRequired, setIsRequired] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  const handleUploadDocsSubmit = ({ values }) => {
    setIsLoading(true);
    if (!attachments.length) {
      setShowAlert("You have no documents to upload");
    }

    const payload = {
      agency_code: agencyCode,
      producer_code: values?.producer_code,
      agent_firstname: values?.producer?.first_name,
      agent_lastname: values?.producer?.last_name,
      agent_email: values?.producer?.email,
      agent_phone: values?.producer?.phone,
      account_or_bond_number:
        (account ? account.account_number : values.accountBondSearch) || "",
      message_to_underwriter: values.uwMessage || ""
    };

    if (values.bondTypeSelect) {
      payload.bond_type = values.bondTypeSelect;
    }

    postSuretyUploadDocuments({
      payload,
      files: attachments
    })
      .then(() => {
        resetForm();
        setShowSuccessModal(true);
      })
      .catch(err => {
        toast(
          <HelpDeskMessage
            display="pleaseTryAgain"
            before="We're sorry, but an error occurred while uploading your document(s). Please try again."
          />,
          {
            toastId: "surety-document-upload-error",
            position: "top-center",
            className: "error",
            autoClose: false
          }
        );
        logger.error({
          description: "surety-document-upload-error: " + err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    accountBondSearch: "",
    accountBondSearchInput: "",
    bondTypeSelect: "",
    uwMessage: "",
    producer_code: producerCode,
    producer: {
      first_name: "",
      last_name: "",
      email: "",
      phone: ""
    }
  };

  const initialFormErrors = {
    bondTypeSelect: [],
    uwMessage: []
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleUploadDocsSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const checkAndSetAttachments = attachments => {
    if (!exceedsMaxSize(attachments)) {
      setShowAlert(null);
      setAttachments(attachments);
    } else {
      setShowAlert("The combined filesize is too large");
    }
  };

  const handleSelectFiles = selectedAttachments => {
    const allAttachments = attachments.length
      ? [...selectedAttachments, ...attachments]
      : selectedAttachments;
    const uniqueAttachments = Array.from(
      new Map(allAttachments.map(att => [att.name, att])).values()
    );
    checkAndSetAttachments(uniqueAttachments);
  };

  const handleRemoveAttachment = file => {
    const filteredAttachments = attachments.filter(o => o.name !== file.name);
    checkAndSetAttachments(filteredAttachments);
  };

  const resetForm = () => {
    if (attachments) setAttachments([]);
    updateForm({
      values: initialValues,
      errors: initialFormErrors
    });
    setShowModal(false);
    setShowAlert(false);
  };

  useEffect(() => {
    if (account || values.accountBondSearch) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
  }, [account, values.accountBondSearch]);

  useEffect(() => {
    // Account Overview Document Upload required fields
    if (
      account &&
      attachments.length &&
      values?.producer_code &&
      values?.producer?.email
    ) {
      setSubmitIsDisabled(false);
    } // Surety Home Document Upload required fields
    else if (
      !account &&
      attachments.length &&
      !isLoading &&
      values?.producer_code &&
      values?.producer?.email &&
      (values.accountBondSearch || (values.bondTypeSelect && values.uwMessage))
    ) {
      setSubmitIsDisabled(false);
    } else {
      setSubmitIsDisabled(true);
    }
  }, [account, attachments, isLoading, values]);

  return (
    <div className="document-upload__modals">
      <Modal
        title="Upload Documents"
        show={showSuccessModal}
        body={
          <>
            <div className="document-upload-success-modal">
              <Icon size="2x" icon="fasCheck" className="success-icon" />
              <span className="success-text">
                Success! We got your documents!
              </span>
            </div>
            <Button onClick={() => setShowSuccessModal(false)}>Exit</Button>
          </>
        }
      />

      <Modal
        title="Document Upload"
        className="document-upload-modal"
        onHide={resetForm}
        show={showModal}
        body={
          <div>
            {account ? (
              <div className="account-info">
                <p className="bold">{account.account_name}</p>
                <p className="bold mb-0">Account No:</p>
                <p className="mb-1">{account.account_number}</p>
              </div>
            ) : (
              <p>
                Please search for your customer using the account or bond
                number. If you do not have an account or bond number, please
                include additional details along with your attachment.
              </p>
            )}

            <Form context={form}>
              {!account && (
                <>
                  <AccountBondSearchInput
                    label="Search Account or Bond Number"
                    form={form}
                  />

                  <FormGroup>
                    <Dropdown
                      id="bondTypeSelect"
                      name="bondTypeSelect"
                      value={values.bondTypeSelect}
                      onBlur={handleOnBlur}
                      onChange={handleOnChange}
                      onValidate={handleOnValidate}
                      size="full"
                      label="Select Contract or Commercial"
                      required={isRequired}
                      options={[
                        { value: "Contract", label: "Contract" },
                        { value: "Commercial", label: "Commercial" }
                      ]}
                    />
                  </FormGroup>
                </>
              )}

              <ProducerDropdown
                form={form}
                required={isRequired}
                label="Agency Contact"
                size="fill"
                allowOther
              />

              <FormGroup>
                <TextArea
                  id="uwMessage"
                  name="uwMessage"
                  label="Add Message to Underwriter"
                  value={values.uwMessage}
                  onChange={handleOnChange}
                  onValidate={handleOnValidate}
                  onBlur={handleOnBlur}
                  required={isRequired}
                  className="uw-message"
                />
              </FormGroup>

              <DragAndDropAttachments
                id="document-upload-modal-file-input"
                allowMultiple
                onSelectFiles={handleSelectFiles}
                allowedFileTypes={fileTypes}
              />

              <div className="upload-filesize mt-1">
                <i>
                  We only accept the following file types {fileTypes.join(", ")}
                </i>
                <br />
                <i>
                  <b>Note* </b>
                  <span>Total size of all attachments cannot exceed 10mb.</span>
                </i>
              </div>

              <hr />

              <p className="lead">Your Attachments</p>

              {showAlert && (
                <Alert
                  className="alert-message"
                  type="error"
                  onDismiss={() => setShowAlert(null)}
                >
                  {showAlert || <DefaultErrorResponse />}
                </Alert>
              )}

              <Table
                data={attachments}
                rowKey="name"
                columns={[
                  {
                    key: "name",
                    label: "Name",
                    sortable: true
                  },
                  {
                    label: "Size",
                    className: "size",
                    key: "fileSize",
                    align: "center",
                    element: row => (
                      <span role="presentation">
                        {convertBytesToMB(row.size)} mb
                      </span>
                    )
                  },
                  {
                    label: "Remove",
                    className: "remove",
                    key: "removeFile",
                    align: "center",
                    element: row => {
                      return (
                        <div
                          className="remove-icon"
                          role="presentation"
                          onClick={() => handleRemoveAttachment(row)}
                        >
                          <Icon icon="fasTimesCircle" />
                        </div>
                      );
                    }
                  }
                ]}
              />

              <div className="modal-form-buttons">
                <Button onClick={() => resetForm()}>Cancel</Button>
                <Button
                  type="submit"
                  variant="primary"
                  spinner={isLoading}
                  disabled={submitIsDisabled}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        }
      />
    </div>
  );
}

DocumentUploadModals.propTypes = {
  account: PropTypes.object,
  agencyCode: PropTypes.string.isRequired,
  producerCode: PropTypes.string.isRequired,
  postSuretyUploadDocuments: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string)
};

const mapStateToProps = state => ({
  agencyCode: getSelectedAgencyCode(state),
  producerCode: getProducerCode(state),
  postSuretyUploadDocuments
});

export default connect(mapStateToProps)(DocumentUploadModals);
