import React from "react";

import { Input } from "@ufginsurance/ui-kit";

const SearchInput = ({ form }) => {
  const labelText = "Search Keyword or Form Number";
  const { values, handleOnBlur, handleOnValidate, handleOnChange } = form;

  return (
    <Input
      placeholder=""
      id="keyword-form-number-search"
      name="keyword"
      label={labelText}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      onValidate={handleOnValidate}
      value={values.keyword}
      isClearable
      className="document-coverage-forms-search-field"
    />
  );
};

export default SearchInput;
