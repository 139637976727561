import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const billingReducer = (state = initialState.billingDetails, action) => {
  switch (action.type) {
    case types.FETCH_BILLING_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_BILLING_SUCCEEDED:
      return {
        ...state,
        activities: action.payload.activities.map((element, index) => ({
          ...element,
          index
        })),
        unapplied_amount: action?.payload?.unapplied_amount,
        isLoading: false
      };
    case types.FETCH_BILLING_FAILED:
      return {
        ...state,
        isLoading: false,
        activities: null,
        unapplied_amount: null,
        error: action.payload
      };
    case types.BILLING_HISTORY_PRINT_POST_STARTED:
      return {
        ...state,
        isPrintPostFailed: false
      };
    case types.BILLING_HISTORY_PRINT_POST_SUCCEEDED:
      return {
        ...state,
        isPrintPostFailed: false
      };
    case types.BILLING_HISTORY_PRINT_POST_FAILED:
      return {
        ...state,
        isPrintPostFailed: action.payload
      };
    default:
      return state;
  }
};

export default billingReducer;
