import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Anchor,
  Panel,
  PanelGrid,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const ProductsList = ({
  stateProducts,
  onProductListClick,
  areProductsLoading,
  productsError
}) => {
  return (
    <>
      {areProductsLoading && (
        <>
          <div>&nbsp;</div>
          <LoadingIndicator />
        </>
      )}
      {productsError && (
        <div>
          <div>&nbsp;</div>
          <div className="friendly-error-messaging">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </div>
      )}
      <PanelGrid className="products-grid" sm={2} md={3} lg={3} xl={4}>
        {stateProducts &&
          stateProducts.length > 0 &&
          stateProducts.map(product => (
            <Panel
              title={product.product}
              key={product?.id}
              footer={
                <Anchor
                  className="products-grid__link"
                  onClick={() => {
                    onProductListClick(product);
                  }}
                >
                  View {product.product} Manual
                </Anchor>
              }
            >
              <div>{product.brief_description}</div>
            </Panel>
          ))}
      </PanelGrid>
    </>
  );
};

ProductsList.propTypes = {
  onProductListClick: PropTypes.func.isRequired,
  stateProducts: PropTypes.array,
  areProductsLoading: PropTypes.bool.isRequired,
  productsError: PropTypes.object
};

export default withRouter(ProductsList);
