import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";

/*--------VERIFY ADDRESS SECTION---------- */
const fetchVerifyAddressServiceStarted = () => ({
  type: types.FETCH_VERIFY_ADDRESS_STARTED
});

const fetchVerifyAddressServiceSucceeded = data => ({
  type: types.FETCH_VERIFY_ADDRESS_SUCCEEDED,
  payload: data
});

const fetchVerifyAddressServiceFailed = error => ({
  type: types.FETCH_VERIFY_ADDRESS_FAILED,
  payload: error
});

export const verifyAddresses = addresses => dispatch => {
  dispatch(fetchVerifyAddressServiceStarted());

  const arrApi = [];
  const arrData = [];
  for (let i = 0; i < addresses.length; i++) {
    const data = addresses[i];

    arrApi.push(
      quickQuotingServices.verifyAddress(
        data.address,
        data.city,
        data.state,
        data.zip
      )
    );
  }

  Promise.all(arrApi)
    .then(
      response => {
        for (let i = 0; i < response.length; i++) {
          arrData.push(response[i].data);
        }
      },
      error => {
        dispatch(fetchVerifyAddressServiceFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    )
    .then(dispatch(fetchVerifyAddressServiceSucceeded(arrData)));
};

export const verifyAddress = (address, city, state, zip) => dispatch => {
  dispatch(fetchVerifyAddressServiceStarted());
  return quickQuotingServices.verifyAddress(address, city, state, zip).then(
    response => {
      dispatch(fetchVerifyAddressServiceSucceeded(response.data));
    },
    error => {
      dispatch(fetchVerifyAddressServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
