import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import * as routes from "../../../constants/routes";
import { Icon, Anchor } from "@ufginsurance/ui-kit";
import { useBillingResourcesContext } from "../context";
import { useCurrentRouteMatch, useOnlyBaseRoute } from "../Hooks/hooks";
import _equal from "lodash/isEqual";

const Breadcrumbs = () => {
  const { billingData, updateTableOfContentId } = useBillingResourcesContext();

  const history = useHistory();
  const currentMatch = useCurrentRouteMatch();
  const onlyBaseRouteMatch = useOnlyBaseRoute();

  const defaultHeaderText = "Billing";

  const [currentHeader, setCurrentHeader] = useState(null);
  const [currentSubheader, setCurrentSubheader] = useState(null);

  // set current header and subheader
  useEffect(() => {
    const { headerId, subheaderId } = currentMatch.params;

    const header = !headerId
      ? billingData.tableOfContents.data.find(h => h.type === "Header")
      : billingData.tableOfContents.data.find(content => {
          return content.id === headerId;
        });

    const subheader = !subheaderId
      ? billingData.tableOfContents.data.find(
          s => s.type === "Sub-Header1" && s.sequence > header.sequence
        )
      : billingData.tableOfContents.data.find(content => {
          return content.id === subheaderId;
        });

    if (
      !_equal(header, currentHeader) &&
      !_equal(currentSubheader, subheader)
    ) {
      if (subheader) {
        updateTableOfContentId(subheader?.id);
      } else if (header) {
        updateTableOfContentId(header?.id);
      }
      setCurrentHeader(header);
      setCurrentSubheader(subheader);
    }
  }, [
    billingData.tableOfContents.data,
    currentMatch.params,
    currentHeader,
    currentSubheader,
    updateTableOfContentId
  ]);

  // redirect base route to first table of content route
  useEffect(() => {
    if (billingData?.tableOfContents?.data) {
      const defaultSubHeader = billingData?.tableOfContents?.data.find(
        s => s.type !== "Header"
      );

      const defaultHeader = !defaultSubHeader
        ? billingData?.tableOfContents?.data[0]
        : billingData?.tableOfContents?.data.find(
            s => s.type === "Header" && s.sequence < defaultSubHeader?.sequence
          );

      if (onlyBaseRouteMatch && defaultHeader && defaultHeader) {
        history.replace(
          `${routes.BILLING_RESOURCES}/header/${defaultHeader.id}/subheader/${defaultSubHeader.id}`
        );
      } else if (onlyBaseRouteMatch && defaultHeader) {
        history.replace(
          `${routes.BILLING_RESOURCES}/header/${defaultHeader.id}`
        );
      }
    }
  }, [billingData.tableOfContents.data, history, onlyBaseRouteMatch]);

  const onBaseRouteClick = () => {
    history.push(routes.BILLING_RESOURCES);
  };

  const onHeaderClick = () => {
    const tmpSubHeader = billingData?.tableOfContents?.data.find(
      s => s.sequence > currentHeader.sequence
    );

    history.push(
      `${routes.BILLING_RESOURCES}/header/${currentHeader.id}/subheader/${tmpSubHeader.id}`
    );
  };

  return (
    <div id="billing-resource__breadcrumbs">
      <Switch>
        {/* base route */}
        <Route exact path={routes.BILLING_RESOURCES}>
          <div className={`billing-resource__breadcrumb`}>
            <Anchor
              className="breadcrumb__link breadcrumb__link--disabled"
              onClick={() => {}}
            >
              {defaultHeaderText}
            </Anchor>
          </div>
        </Route>

        {/* header route */}
        <Route exact path={routes.BILLING_RESOURCES_HEADER}>
          <div className={`billing-resource__breadcrumb`}>
            <Anchor className={`breadcrumb__link`} onClick={onBaseRouteClick}>
              {defaultHeaderText}
              {<Icon size="lg" icon="farAngleRight" />}
            </Anchor>
          </div>

          {currentHeader && (
            <div className={`billing-resource__breadcrumb`}>
              <Anchor
                className="breadcrumb__link breadcrumb__link--disabled"
                onClick={() => {}}
              >
                {currentHeader.description}
              </Anchor>
            </div>
          )}
        </Route>

        {/* subheader route */}
        <Route exact path={routes.BILLING_RESOURCES_SUB_HEADER}>
          <div className={`billing-resource__breadcrumb`}>
            <Anchor className={`breadcrumb__link`} onClick={onBaseRouteClick}>
              {defaultHeaderText}
              {<Icon size="lg" icon="farAngleRight" />}
            </Anchor>
          </div>

          {currentHeader && (
            <div className={`billing-resource__breadcrumb`}>
              <Anchor className={`breadcrumb__link`} onClick={onHeaderClick}>
                {currentHeader.description}
                {<Icon size="lg" icon="farAngleRight" />}
              </Anchor>

              <Anchor
                className="breadcrumb__link breadcrumb__link--disabled"
                onClick={() => {}}
              >
                {currentSubheader && currentSubheader.description}
              </Anchor>
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(Breadcrumbs);
