import API from "../../api";
import qs from "query-string";

export const referToUnderwriter = (policy_number, job_number, message) => {
  return API.agent().post(
    `/policy-change/${policy_number}/refer-to-uw/${job_number}`,
    { message }
  );
};

export const putPolicyChange = (policyNumber, jobId, effective_date) => {
  const queryString = qs.stringify({ effective_date });

  return API.agent().put(
    `/policy-change/${policyNumber}/draft/${jobId}?${queryString}`
  );
};

export const fetchPolicyDraft = (policy_number, job_number) => {
  return API.agent().get(`/policy-change/${policy_number}/draft/${job_number}`);
};

export const withdrawPolicyDraft = (policy_number, job_number) => {
  return API.agent().delete(
    `/policy-change/${policy_number}/draft/${job_number}`
  );
};

export const createPolicyDraft = (
  policy_number,
  change_type,
  effective_date
) => {
  const queryString = qs.stringify({ change_type, effective_date });
  return API.agent().post(
    `/policy-change/${policy_number}/draft?${queryString}`
  );
};

export const quotePolicyDraft = (policy_number, job_number) => {
  return API.agent().post(
    `/policy-change/${policy_number}/quote/${job_number}`
  );
};

export const fetchPendingTransactions = (policy_number, type) => {
  if (type) {
    return API.agent().get(
      `/policies/${policy_number}/policy-transaction/${type}`
    );
  }

  return API.agent().get(
    `/policies/${policy_number}/policy-transaction/pending`
  );
};

export const getChangeRequestEligibilityInfo = (policy_number, agent_code) =>
  API.agent().get(
    `/policies/${policy_number}/change-requests/eligibility-info?agency_number=${agent_code}`
  );

export const postHandleSubmit = (policy_number, data) =>
  API.agent().post(
    `/policies/${policy_number}/change-requests/commercial-auto`,
    data
  );

export const postPersonalAutoHandleSubmit = (policy_number, data) =>
  API.agent().post(
    `/policies/${policy_number}/change-requests/personal-auto`,
    data
  );

export const postHomeOwnersHandleSubmit = (policy_number, data) =>
  API.agent().post(
    `/policies/${policy_number}/change-requests/homeowners`,
    data
  );

export const postOtherHandleSubmit = (policy_number, data) =>
  API.agent().post(`/policies/${policy_number}/change-requests/other`, data);

export const postAdditionalInterestHandleSubmit = (policy_number, data) =>
  API.agent().post(
    `/policies/${policy_number}/change-requests/additional-interest`,
    data
  );

export const postCommercialAIhandleSubmit = (policy_number, data) =>
  API.agent().post(
    `/policies/${policy_number}/change-requests/commercial-interest-loss-payee-mortgagee`,
    data
  );

export const getPolicyChangesDocument = (policy_number, change_id) =>
  API.documents().get(
    `/documents/policy-changes/${policy_number}?id=${change_id}`,
    { responseType: "blob" }
  );

export const postOtherPolicyChange = (data, attachments) => {
  const resourcePath = "/other-changes-notification";
  const formData = new FormData();
  formData.append("json_body", JSON.stringify(data));
  attachments.forEach((file, i) => {
    const fileNumber = i === 0 ? "" : i + 1;
    // https://ufginsurance.atlassian.net/browse/OOA-2475
    // certain uploaded file content-types provided e.g. for .xlsx when processed by services are corrupted,
    // rewriting the content-type to a generic streamed file solves this issue.
    const genericFileContentType = "application/octet-stream";
    const fileBlob = file.slice(0, file.size, genericFileContentType);
    const newFile = new File([fileBlob], file.name, {
      type: genericFileContentType
    });
    formData.append(`file${fileNumber}`, newFile);
  });

  return API.agent().post(resourcePath, formData, {
    headers: { "content-type": "multipart/form-data" }
  });
};

export const otherChangePolicyChangeReport = data => {
  return API.agent().post(`/other-changes-document`, data, {
    responseType: "blob"
  });
};

// new policy change form
export const postPolicyChangeDriver = (policyNumber, jobId, data) => {
  return API.agent().post(
    `/policy-change/${policyNumber}/draft/${jobId}/driver`,
    data
  );
};

export const putPolicyChangeDriver = (policyNumber, jobId, driverId, data) => {
  return API.agent().put(
    `/policy-change/${policyNumber}/draft/${jobId}/driver/${driverId}`,
    data
  );
};

export const removePolicyChangeDriver = (policyNumber, jobId, driverId) => {
  return API.agent().delete(
    `/policy-change/${policyNumber}/draft/${jobId}/driver/${driverId}`
  );
};

export const issuePolicyChangeDriver = (policyNumber, jobId) => {
  return API.agent().post(`policy-change/${policyNumber}/bind/${jobId}`);
};

export const getDriversLicenseValidation = () => {
  return API.agent().get(`/driver-license-validation-list`);
};
