import React from "react";
import { SuretyConstructionSpecialty } from "../constants/suretyDictionary";

const ConstructionSpecialty = ({ quoteData, step }) => {
  const Constructionspec = SuretyConstructionSpecialty.filter(
    a => a.value === quoteData?.construction_specialty
  ).map(s => s.label);
  const lines = step > 1 ? Constructionspec : "";
  return (
    <>
      <div className="qq__sidebar__summary__item">
        <b>Construction Specialty:</b>
        <div className="qq__sidebar__summary__item-lines">{lines}</div>
      </div>
    </>
  );
};

export default ConstructionSpecialty;
