import * as types from "./actionTypes";
import * as reportService from "../services/serviceCenterService";
import { logger } from "../../loggers";

const fetchServiceCenterProducerStarted = () => ({
  type: types.FETCH_SERVICE_CENTER_PRODUCER_STARTED
});

const fetchServiceCenterProducerSucceeded = data => ({
  type: types.FETCH_SERVICE_CENTER_PRODUCER_SUCCEEDED,
  payload: data
});

const fetchServiceCenterProducerFailed = error => ({
  type: types.FETCH_SERVICE_CENTER_PRODUCER_FAILED,
  payload: error
});

export const fetchServiceCenterProducerDetails = agency_code => dispatch => {
  dispatch(fetchServiceCenterProducerStarted());

  return reportService.getServiceCenter(agency_code).then(
    res => dispatch(fetchServiceCenterProducerSucceeded(res.data)),
    error => {
      dispatch(fetchServiceCenterProducerFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
