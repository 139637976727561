export const FETCH_AGENCY_PRODUCERS_STARTED = "FETCH_AGENCY_PRODUCERS_STARTED";
export const FETCH_AGENCY_PRODUCERS_SUCCEEDED =
  "FETCH_AGENCY_PRODUCERS_SUCCEEDED";
export const FETCH_AGENCY_PRODUCERS_FAILED = "FETCH_AGENCY_PRODUCERS_FAILED";

export const FETCH_SURETY_BONDFORM_STARTED = "FETCH_SURETY_BONDFORM_STARTED";
export const FETCH_SURETY_BONDFORM_SUCCEEDED =
  "FETCH_SURETY_BONDFORM_SUCCEEDED";
export const FETCH_SURETY_BONDFORM_FAILED = "FETCH_SURETY_BONDFORM_FAILED";

export const FETCH_AGENCY_ACCOUNTS_STARTED = "FETCH_AGENCY_ACCOUNTS_STARTED";
export const FETCH_AGENCY_ACCOUNTS_SUCCEEDED =
  "FETCH_AGENCY_ACCOUNTS_SUCCEEDED";
export const FETCH_AGENCY_ACCOUNTS_FAILED = "FETCH_AGENCY_ACCOUNTS_FAILED";

export const FETCH_OWNERS_STARTED = "FETCH_OWNERS_STARTED";
export const FETCH_OWNERS_SUCCEEDED = "FETCH_OWNERS_SUCCEEDED";
export const FETCH_OWNERS_FAILED = "FETCH_OWNERS_FAILED";
export const CLEAR_OWNERS = "CLEAR_OWNERS";

export const FETCH_ELIGIBILITY_STARTED = "FETCH_ELIGIBILITY_STARTED";
export const FETCH_ELIGIBILITY_SUCCEEDED = "FETCH_ELIGIBILITY_SUCCEEDED";
export const FETCH_ELIGIBILITY_FAILED = "FETCH_ELIGIBILITY_FAILED";
export const CLEAR_ELIGIBILITY_DATA = "CLEAR_ELIGIBILITY_DATA";

export const FETCH_VERIFY_ADDRESS_STARTED = "FETCH_VERIFY_ADDRESS_STARTED";
export const FETCH_VERIFY_ADDRESS_SUCCEEDED = "FETCH_VERIFY_ADDRESS_SUCCEEDED";
export const FETCH_VERIFY_ADDRESS_FAILED = "FETCH_VERIFY_ADDRESS_FAILED";

// Submission...
export const UPDATE_SUBMISSION_ERROR = "UPDATE_SUBMISSION_ERROR";
export const UPDATE_SUBMISSION_MSG = "UPDATE_SUBMISSION_MSG";

export const POST_QUICK_ACCOUNT_STARTED = "POST_QUICK_ACCOUNT_STARTED";
export const POST_QUICK_ELIGIBILITY_STARTED = "POST_QUICK_ELIGIBILITY_STARTED";
export const POST_QUICK_OBLIGEE_STARTED = "POST_QUICK_OBLIGEE_STARTED";
export const POST_QUICK_BOND_STARTED = "POST_QUICK_BOND_STARTED";
export const POST_QUICK_OWNERS_STARTED = "POST_QUICK_OWNERS_STARTED";
export const POST_QUICK_CREDIT_AUTHORIZATION_STARTED = "POST_QUICK_CREDIT_AUTHORIZATION_STARTED";

export const POST_QUICK_FINAL_STARTED = "POST_QUICK_FINAL_STARTED"; // @todo.

export const POST_QUICK_QUOTE_SUCCEEDED = "POST_QUICK_FINAL_SUCCEEDED";
export const POST_QUICK_QUOTE_FAILED = "POST_QUICK_QUOTE_FAILED";
// END Submission.

export const FETCH_BOND_OBLIGEE_STARTED = "FETCH_BOND_OBLIGEE_STARTED";
export const FETCH_BOND_OBLIGEE_SUCCEEDED = "FETCH_BOND_OBLIGEE_SUCCEEDED";
export const FETCH_BOND_OBLIGEE_FAILED = "FETCH_BOND_OBLIGEE_FAILED";
export const SET_BOND_OBLIGEE_LOADING = "SET_BOND_OBLIGEE_LOADING";

export const FETCH_SURETY_QUOTE_DATA_STARTED =
  "FETCH_SURETY_QUOTE_DATA_STARTED";
export const FETCH_SURETY_QUOTE_DATA_SUCCEEDED =
  "FETCH_SURETY_QUOTE_DATA_SUCCEEDED";
export const FETCH_SURETY_QUOTE_DATA_FAILED = "FETCH_SURETY_QUOTE_DATA_FAILED";

export const UPDATE_SURETY_QUOTE_DATA_STARTED =
  "UPDATE_SURETY_QUOTE_DATA_STARTED";
export const UPDATE_SURETY_QUOTE_DATA_SUCCEEDED =
  "UPDATE_SURETY_QUOTE_DATA_SUCCEEDED";
export const UPDATE_SURETY_QUOTE_DATA_FAILED =
  "UPDATE_SURETY_QUOTE_DATA_FAILED";
export const CLEAR_SURETY_QUOTE_DATA = "CLEAR_SURETY_QUOTE_DATA";

export const FETCH_SURETY_QUOTE_AGENCY_DATA_STARTED =
  "FETCH_SURETY_QUOTE_AGENCY_DATA_STARTED";
export const FETCH_SURETY_QUOTE_AGENCY_DATA_SUCCEEDED =
  "FETCH_SURETY_QUOTE_AGENCY_DATA_SUCCEEDED";
export const FETCH_SURETY_QUOTE_AGENCY_DATA_FAILED =
  "FETCH_SURETY_QUOTE_AGENCY_DATA_FAILED";

export const FETCH_SURETY_ZIP_STARTED = "FETCH_SURETY_ZIP_STARTED";
export const FETCH_SURETY_ZIP_SUCCEEDED = "FETCH_SURETY_ZIP_SUCCEEDED";
export const FETCH_SURETY_ZIP_FAILED = "FETCH_SURETY_ZIP_FAILED";

export const DELETE_SURETY_QUOTE_STARTED = "DELETE_SURETY_QUOTE_STARTED";
export const DELETE_SURETY_QUOTE_SUCCEEDED = "DELETE_SURETY_QUOTE_SUCCEEDED";
export const DELETE_SURETY_QUOTE_FAILED = "DELETE_SURETY_QUOTE_FAILED";

export const POST_QUICK_DOCS_STARTED = "POST_QUICK_DOCS_STARTED";
export const POST_QUICK_DOCS_SUCCEEDED = "POST_QUICK_DOCS_SUCCEEDED";
export const POST_QUICK_DOCS_FAILED = "POST_QUICK_DOCS_FAILED";

export const FETCH_DOCUMENTS_PACKET_STARTED = "FETCH_DOCUMENTS_PACKET_STARTED";
export const FETCH_DOCUMENTS_PACKET_SUCCEEDED =
  "FETCH_DOCUMENTS_PACKET_SUCCEEDED";
export const FETCH_DOCUMENTS_PACKET_FAILED = "FETCH_DOCUMENTS_PACKET_FAILED";
export const CLEAR_DOCUMENTS_PACKET_ERROR = "CLEAR_DOCUMENTS_PACKET_ERROR";
