import React from "react";
import cn from "classnames";
import "./QuotingSteps.scss";
import { Icon } from "@ufginsurance/ui-kit";

const QuotingSteps = ({ step }) => {
  const steps = [
    { num: 1, name: "Customer Information" },
    { num: 2, name: "Owner Information" },
    { num: 3, name: "Check Eligibility" },
    { num: 4, name: "Bond information" },
    { num: 5, name: "Review Information" }
  ];

  return (
    <>
      <div className="qq__steps-menu">
        <ul className="qq__steps-step">
          {steps.map(s => {
            return (
              <li
                key={s.name}
                className={cn("qq__steps-step__item", {
                  active: s.num === step
                })}
              >
                <div className="qq__steps-step__wrapper">
                  {s.num < 5 && (
                    <div className="qq__steps-step__progress-bar"></div>
                  )}
                  <div className="qq__steps-step__num">
                    <span className="qq__steps__step-number">
                      <span className="qq__steps-step__num-label">{s.num}</span>
                      <span className="qq__steps-step__check">
                        <Icon icon="fasCheck" />
                      </span>
                    </span>
                  </div>
                  <div className="qq__steps-step__label">{s.name}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default QuotingSteps;
