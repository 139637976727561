import API from "../../api";

export const getAgencyActivity = agencyCode => {
  return API.surety().get(`/agency/${agencyCode}/activity`);
};

export const getConsentOfSurety = ({ agencyCode, bondNumber }) => {
  return API.surety().get(
    `/agency/${agencyCode}/bond/${bondNumber}/consent-of-surety`
  );
};
