import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import ElectronicPaymentsForm from "../components/electronic-payments/ElectronicPaymentsForm";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  isHomeAgent: sessionSelector.isHomeAgent(state),
  agencyCode: getSelectedAgencyCode(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

export default connect(mapStateToProps)(ElectronicPaymentsForm);
