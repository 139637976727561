import { connect } from "react-redux";
import PrintAutoIdCards from "./PrintAutoIdCards";
import { viewAutoIdCard } from "../../../actions/accountActions";

const mapStateToProps = state => ({
  isLoading: state.accountData.isLoadingAutoIdCards,
  idCards: state.accountData.idCards,
  policyType: state.accountData.accounts.insurance_type
});

const mapDispatchToProps = {
  viewAutoIdCard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintAutoIdCards);
