import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";

const agencyBillEpayReducer = (state = initialState.agencyBillEpay, action) => {
  switch (action.type) {
    case types.FETCH_AGENCY_BILL_PAY_STARTED:
      return {
        ...state,
        billPayLoading: true,
        billPayDetails: null,
        billPayError: false
      };
    case types.FETCH_AGENCY_BILL_PAY_SUCCEEDED:
      return {
        ...state,
        billPayLoading: false,
        billPayDetails: action.payload,
        billPayError: false
      };
    case types.FETCH_AGENCY_BILL_PAY_FAILED:
      return {
        ...state,
        billPayLoading: false,
        billPayDetails: {},
        billPayError: true
      };
    case types.FETCH_BILL_ENROLLMENT_STARTED:
      return {
        ...state,
        billEnrollLoading: true,
        billEnrollDetails: null,
        billEnrollError: false
      };
    case types.FETCH_BILL_ENROLLMENT_SUCCEEDED:
      return {
        ...state,
        billEnrollLoading: false,
        billEnrollDetails: action.payload,
        billEnrollError: false
      };
    case types.FETCH_BILL_ENROLLMENT_FAILED:
      return {
        ...state,
        billEnrollLoading: false,
        billEnrollDetails: null,
        billEnrollError: true
      };
    case types.GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_STARTED:
      return {
        ...state,
        getGuidewireAgencyStatementOptionsLoading: true,
        validStatementOptions: null,
        getGuidewireAgencyStatementOptionsError: false
      };
    case types.GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_SUCCEEDED:
      return {
        ...state,
        getGuidewireAgencyStatementOptionsLoading: false,
        validStatementOptions: action.payload,
        getGuidewireAgencyStatementOptionsError: false
      };
    case types.GET_GUIDEWIRE_AGENCY_STATEMENT_OPTIONS_FAILED:
      return {
        ...state,
        getGuidewireAgencyStatementOptionsLoading: false,
        validStatementOptions: null,
        getGuidewireAgencyStatementOptionsError: true
      };
    default:
      return state;
  }
};

export default agencyBillEpayReducer;
