import React, { useCallback, useEffect, useState } from "react";
import {
  ContentHeader,
  Panel,
  useForm,
  FormGroup,
  Form,
  Button,
  TextArea,
  FlexRow,
  Modal,
  Alert,
  FormatDate,
  ButtonDropNav
} from "@ufginsurance/ui-kit";
import UWHeaderTop from "./UWHeaderTop";
import FooterContainer from "../../src/containers/FooterContainer";
import UWBondInformation from "./uwBondInformation";
import PropTypes from "prop-types";
import qs from "query-string";
import { toTitleCase } from "../components/Factory";
import {
  formatAddress,
  formatZip
} from "../../src/quick-program/quick-quoting/shared/util";
import { formatPhoneNumber } from "../../src/shared/util";
import { uwTransform } from "./uwTransform";

const reviewedStatusMessages = {
  account: {
    Q: "Account Status is Quick Accepted.",
    X: "Account Status is Quick Declined."
  },
  bond: {
    D: "Request has been reviewed.  Bond status is Declined.",
    A: "Request has been reviewed.  Bond status is Approved.",
    X: "Request has been reviewed.  Bond status is Approved."
  }
};

const UWReview = ({
  fetchUWReviewBondInfo,
  uwReviewBondInfoData,
  location,
  fetchUWReviewAccountInfo,
  uwReviewAccountInfoData,
  uwReviewDecision,
  uwReviewDecisionData,
  fetchUWReviewWarningMessagesInfo,
  uwReviewWarningMessagesData
}) => {
  const today = new Date();

  const onUwDecisionSuccess =
    uwReviewDecisionData.data?.message === "SUCCESSFUL";
  const hasWarningMessages =
    uwReviewWarningMessagesData.data?.warn_messages || [];

  const initialValues = {
    message_to_agent: "",
    kybtoggle: ""
  };

  const bond_number = !!qs.parse(location.search).bond_number
    ? qs.parse(location.search).bond_number
    : "";
  const account_number = qs.parse(location.search).account_number;
  const agency_code = qs.parse(location.search).agency;
  const sub_producer = qs.parse(location.search).producer;
  const is_new_account = !!qs.parse(location.search).is_new_account
    ? qs.parse(location.search).is_new_account === "true"
    : false;

  const onSubmit = () => {};

  const form = useForm({ values: initialValues, onSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  useEffect(() => {
    if (!!account_number && !!bond_number) {
      fetchUWReviewAccountInfo(agency_code, account_number, sub_producer);
      fetchUWReviewBondInfo(bond_number);
      fetchUWReviewWarningMessagesInfo(
        agency_code,
        account_number,
        bond_number,
        is_new_account
      );
    } else {
      const bondNumber = "";
      fetchUWReviewAccountInfo(agency_code, account_number, sub_producer);
      fetchUWReviewWarningMessagesInfo(
        agency_code,
        account_number,
        bondNumber,
        is_new_account
      );
    }
  }, [
    fetchUWReviewBondInfo,
    bond_number,
    account_number,
    fetchUWReviewAccountInfo,
    agency_code,
    sub_producer,
    is_new_account,
    fetchUWReviewWarningMessagesInfo
  ]);

  const [approvedStatus, setApprovedStatus] = useState(false);
  const [declinationReason, setDeclinationReason] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);

  const onClickApprove = () => {
    setApprovedStatus(true);
    const final_status = "Approved";
    const data = {
      account_number,
      bond_number,
      final_status
    };

    if (
      uwReviewBondInfoData &&
      uwReviewBondInfoData.uwBondInfoData.type_of_bond === "Performance" &&
      uwReviewBondInfoData.uwBondInfoData.bond_state === "KY"
    ) {
      if (Boolean(values.kybtoggle)) {
        data.is_kentucky_tax_applicable = values.kybtoggle === "true";
      }
    }

    if (!uwReviewDecisionData.isLoading) {
      uwReviewDecision(
        uwTransform(data, uwReviewAccountInfoData, values)
      ).catch(err => {
        console.error(err);
      });
    }
    setShowConfirmation(null);
  };

  const onClickDecline = () => {
    if (!uwReviewDecisionData.isLoading) {
      uwReviewDecision(
        uwTransform(
          {
            account_number,
            bond_number,
            final_status: declinationReason
          },
          uwReviewAccountInfoData,
          values
        )
      ).catch(err => console.error(err));
    }
    setShowConfirmation(null);
  };

  const getStatusMessage = useCallback(() => {
    let statusMessage = "";
    if (uwReviewBondInfoData.uwBondInfoData.bond_status_short) {
      statusMessage =
        reviewedStatusMessages.bond[
          uwReviewBondInfoData.uwBondInfoData.bond_status_short
        ];
    } else if (uwReviewAccountInfoData.uwAccountInfoData) {
      statusMessage =
        reviewedStatusMessages.account[
          uwReviewAccountInfoData.uwAccountInfoData.account_status_short
        ];
    }
    return statusMessage;
  }, [
    uwReviewBondInfoData.uwBondInfoData,
    uwReviewAccountInfoData.uwAccountInfoData
  ]);

  const statusMessage = getStatusMessage();

  const uwDecisionAlreadyMade =
    (uwReviewBondInfoData.uwBondInfoData.bond_status_short &&
      ["A", "X", "D"].includes(
        uwReviewBondInfoData.uwBondInfoData.bond_status_short
      )) ||
    (!uwReviewBondInfoData.uwBondInfoData.bond_status_short &&
      ["Q", "X"].includes(
        uwReviewAccountInfoData.uwAccountInfoData.account_status_short
      ));

  const getModalTitle = () => {
    if (!showConfirmation) return "Confirm";
    if (showConfirmation === "decline") {
      return declinationReason === "Declined-Credit"
        ? "Confirm Decline: Credit Related"
        : "Confirm Decline: Not Credit Related";
    }
    return "Confirm Approval";
  };

  const approveButtonDisabled =
    uwReviewBondInfoData?.uwBondInfoData?.bond_state === "KY" &&
    uwReviewBondInfoData?.uwBondInfoData?.type_of_bond === "Performance" &&
    !values.kybtoggle;

  return (
    <div id="UW">
      <nav className="header">
        <div className="headerMenu__navbar-container">
          <UWHeaderTop />
        </div>
      </nav>

      <section id="content" className="noborder">
        <div id="body" className="container">
          <div className="uwHeading">
            {!!uwReviewBondInfoData.uwBondInfoData.form_number ? (
              <ContentHeader>
                Review Requested-
                {uwReviewBondInfoData.uwBondInfoData.form_number}
              </ContentHeader>
            ) : (
              <ContentHeader>Review Requested</ContentHeader>
            )}
          </div>
          {uwDecisionAlreadyMade && !onUwDecisionSuccess && (
            <Alert dismissible={false} type="note">
              {statusMessage}
            </Alert>
          )}
          {hasWarningMessages.length > 0 && !onUwDecisionSuccess && (
            <Alert
              id="warning_msg"
              className="uw__alert__messages"
              dismissible={false}
              type="warning"
            >
              {hasWarningMessages.map(error => (
                <div key={error}>{error}</div>
              ))}
            </Alert>
          )}
          {!!onUwDecisionSuccess && (
            <Panel bgcolor="grey">
              Request{" "}
              <b>{!!approvedStatus === true ? "Approved" : "Declined"}</b> on{" "}
              {<FormatDate date={today} format="M/D/YYYY" />} by{" "}
              {uwReviewDecisionData.data?.reviewer_firstname}{" "}
              {uwReviewDecisionData.data?.reviewer_lastname}
              <div className="message_to_agent">
                <b>Message to Agent:</b>
                <p className="message_text">{values.message_to_agent}</p>
              </div>
            </Panel>
          )}

          <FlexRow>
            <FlexRow className="uwColumn">
              <Panel className="section_items">
                <div className="uw_items">Account Information</div>
                <div className="uw_items__item-list">
                  <b>Customer:</b>
                  <p>
                    {toTitleCase(
                      uwReviewAccountInfoData.uwAccountInfoData.account_name
                    )}
                    <br />
                    {formatAddress(
                      uwReviewAccountInfoData.uwAccountInfoData
                        .account_street_address
                    )}
                    <br />
                    {toTitleCase(
                      uwReviewAccountInfoData.uwAccountInfoData.account_city
                    )}
                    , {uwReviewAccountInfoData.uwAccountInfoData.account_state}{" "}
                    {formatZip(
                      uwReviewAccountInfoData.uwAccountInfoData.account_zip
                    )}
                  </p>
                </div>
                <div>
                  <b>Account:</b>
                  <p className="uwAccountNum">{account_number}</p>
                </div>
                <div>
                  <b>Current Account Status:</b>
                  <p>
                    {toTitleCase(
                      uwReviewAccountInfoData.uwAccountInfoData.account_status
                    )}
                  </p>
                </div>
                <div>
                  <b>Agency:</b>
                  <p>
                    {uwReviewAccountInfoData.uwAccountInfoData.agency_name}
                    <br />
                    {uwReviewAccountInfoData.uwAccountInfoData.agency_code}
                    <br />
                    {formatPhoneNumber(
                      uwReviewAccountInfoData.uwAccountInfoData.agency_phone
                    )}
                  </p>
                </div>
                <div>
                  <b>Requestor:</b>
                  <p>
                    {
                      uwReviewAccountInfoData.uwAccountInfoData
                        .requestor_firstname
                    }
                    &nbsp;
                    {
                      uwReviewAccountInfoData.uwAccountInfoData
                        .requestor_lastname
                    }
                    <br />
                    {uwReviewAccountInfoData.uwAccountInfoData.requestor_email}
                    <br />
                    {formatPhoneNumber(
                      uwReviewAccountInfoData.uwAccountInfoData.requestor_phone
                    )}
                  </p>
                </div>
                <div>
                  <b>Underwriter:</b>
                  <p>
                    {uwReviewAccountInfoData.uwAccountInfoData.uw_name}
                    <br />
                    {uwReviewAccountInfoData.uwAccountInfoData.uw_email}
                    <br />
                    {formatPhoneNumber(
                      uwReviewAccountInfoData.uwAccountInfoData.uw_phone
                    )}
                  </p>
                </div>
              </Panel>
            </FlexRow>
            <FlexRow className="uwColumn">
              <Panel>
                <UWBondInformation
                  uwReviewBondInfoData={uwReviewBondInfoData}
                  bondNumber={bond_number}
                  form={form}
                  uwDecisionAlreadyMade={uwDecisionAlreadyMade}
                />
              </Panel>
            </FlexRow>
          </FlexRow>
          {!onUwDecisionSuccess && !uwDecisionAlreadyMade && (
            <Form context={form}>
              <FormGroup>
                <TextArea
                  id="message_to_agent"
                  name="message_to_agent"
                  label="Add message to Agent"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.message_to_agent}
                  maxLength={234}
                />
              </FormGroup>
            </Form>
          )}
          {!onUwDecisionSuccess && !uwDecisionAlreadyMade && (
            <FlexRow
              id="uw-action-buttons-row"
              className="qq__bottom-row"
              align="right"
            >
              <ButtonDropNav
                id="uwDecline"
                buttonText="Decline"
                variant="tertiary"
                links={[
                  {
                    text: "Decline: Credit Related",
                    to: "Declined-Credit"
                  },
                  { text: "Decline: Not Credit Related", to: "Declined-Other" }
                ]}
                onClick={v => {
                  setDeclinationReason(v.to);
                  setShowConfirmation("decline");
                }}
              />
              <Button
                variant="primary"
                wide
                onClick={() => setShowConfirmation("approve")}
                disabled={approveButtonDisabled}
              >
                Approve
              </Button>
            </FlexRow>
          )}
        </div>
      </section>
      <FooterContainer />
      <Modal
        size="sm"
        title={getModalTitle()}
        onHide={() => setShowConfirmation(null)}
        show={showConfirmation?.length}
        altCloseMethod={false}
        body={
          <div>
            Are you sure you want to{" "}
            <b>{showConfirmation === "approve" ? "Approve" : "Decline"}</b> this{" "}
            {!!bond_number ? "bond" : "account"}?
          </div>
        }
        footer={
          <>
            <Button variant="plain" onClick={() => setShowConfirmation(null)}>
              Cancel
            </Button>
            <Button
              variant={showConfirmation === "approve" ? "primary" : "tertiary"}
              spinner={uwReviewDecisionData.isloading}
              disabled={uwReviewDecisionData.isloading}
              wide
              onClick={
                showConfirmation === "approve" ? onClickApprove : onClickDecline
              }
            >
              {showConfirmation === "approve" ? "Approve" : "Decline"}
            </Button>
          </>
        }
      />
    </div>
  );
};

UWReview.propTypes = {};

export default UWReview;

UWReview.propTypes = {
  uwReviewBondInfoData: PropTypes.any,
  fetchUWReviewBondInfo: PropTypes.func.isRequired,
  uwReviewAccountInfoData: PropTypes.any,
  fetchUWReviewAccountInfo: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  uwReviewDecision: PropTypes.func.isRequired,
  uwReviewDecisionData: PropTypes.any,
  fetchUWReviewWarningMessagesInfo: PropTypes.func.isRequired,
  uwReviewWarningMessagesData: PropTypes.any
};
