import { productKeys, productsByLineCode } from "../shared/constants";

export const addBopFieldsAtLineLevel = ({
  coverageExclusions,
  supportingData,
  quoteData
}) => {
  const fields = [];

  const damageToPremisesValue = supportingData?.policyCoverages
    ?.damageToPremisesRentedToYouCoverage
    ? String(
        supportingData?.policyCoverages?.damageToPremisesRentedToYouCoverage
      )
    : coverageExclusions?.formData?.policyCoverages
        ?.damageToPremisesRentedToYouCoverage
    ? String(
        coverageExclusions?.formData?.policyCoverages
          ?.damageToPremisesRentedToYouCoverage
      )
    : "50000";

  fields.push({
    name: "Damage To Premises Rented To You",
    description: "Damage To Premises Rented To You",
    required: true,
    selected: true,
    updated: false,
    terms: [
      {
        name: "Damage To Premises Rented To You",
        type: "DirectBP7TenantsFireLiabLimitType",
        options: [],
        required: true,
        valueType: "Other",
        chosenTerm: damageToPremisesValue.toString(),
        chosenTermValue: damageToPremisesValue.toString(),
        directValueMin: 50000,
        isAscendingBetter: true,
        directValueMax: 1000000,
        coveragePublicID: "BP7StructureTenantsFireLiab",
        updated: false,
        publicID: "BP7TenantsFireLiabLimit",
        patternCode: "BP7TenantsFireLiabLimit",
        directValue: damageToPremisesValue
      }
    ],
    publicID: "BP7StructureTenantsFireLiab",
    coverageCategoryDisplayName: "Building Standard Coverages",
    coverageCategoryCode: "BP7BuildingStdGrp",
    isValid: true,
    hasTerms: true,
    coveragesPath: `lobData.bp7BusinessOwners.policylevel.building`
  });

  const bopNebraskaLandlordField =
    quoteData?.lobData?.bp7BusinessOwners?.coverables?.businessOwnersLine
      ?.bp7LineOwnerLandlordOrTenantPolicy;
  if (!!bopNebraskaLandlordField)
    fields.push({
      codeIdentifier: "BP7.NebraskaLandlord",
      name: "Nebraska Requirement",
      description: "Owner, Landlord Or Tenant Policy",
      required: true,
      updated: false,
      publicID: `bop.details`,
      selected: true,
      terms: [
        {
          name: "Is this an Owner, Landlord, or Tenant Policy?",
          type: "bopLine",
          options: [
            { name: "Yes", code: "Yes" },
            { name: "No", code: "No" }
          ],
          required: true,
          patternCode: "bp7LineOwnerLandlordOrTenantPolicy",
          updated: false,
          chosenTerm: bopNebraskaLandlordField || "",
          publicID: "bp7LineOwnerLandlordOrTenantPolicy",
          chosenTermValue: "",
          isAscendingBetter: true,
          isCovTermEditable_UFG: true,
          coveragePublicID: "bop.details"
        }
      ],
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "BopStdGrp",
      coverageCategoryDisplayName: `${productKeys.bp7BusinessOwners.label} Details`
    });

  const bopKYFirefightersField =
    quoteData?.lobData?.bp7BusinessOwners?.coverables?.businessOwnersLine
      ?.bp7TaxSurchargeExempt_UFG;
  if (quoteData?.baseData?.baseState_UFG === "KY" || bopKYFirefightersField)
    fields.push({
      codeIdentifier: "BP7.KentuckyFirefighters",
      name: "Kentucky Firefighters",
      description: "Kentucky Firefighters",
      required: false,
      updated: false,
      publicID: `bop.details`,
      selected: true,
      terms: [
        {
          name: "Kentucky Firefighters / Law Enforcement Surcharge",
          type: "bopLine",
          options: [
            { name: "None", code: "n" },
            { name: "Federal Government", code: "F" },
            {
              name: "Resident nonprofit educational/charitable institution",
              code: "E"
            },
            { name: "Resident nonprofit religious institutions", code: "R" },
            { name: "State and local government", code: "S" }
          ],
          required: false,
          patternCode: "bp7TaxSurchargeExempt_UFG",
          updated: false,
          chosenTerm: bopKYFirefightersField || "n",
          publicID: "bp7TaxSurchargeExempt_UFG",
          chosenTermValue: bopKYFirefightersField || "n",
          isAscendingBetter: false,
          isCovTermEditable_UFG: true,
          coveragePublicID: "bp7TaxSurchargeExempt_UFG"
        }
      ],
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "BopStdGrp",
      coverageCategoryDisplayName: `${productKeys.bp7BusinessOwners.label} Details`
    });

  return fields;
};

export const updateBopLineFields = ({
  field,
  value,
  quoteData,
  updateLineDetailsPromise,
  callback
}) => {
  const bopLineFromDTO =
    quoteData?.lobData?.bp7BusinessOwners?.coverables?.businessOwnersLine;

  const bopLine = structuredClone(bopLineFromDTO);
  const payload = { ...bopLine, [field]: value };

  const termToUpdate = bopLine?.[field];

  // if value has changed, then do the save if...
  // and of these conditions are true:
  // 1. if there's a value, update it
  // 2. if we're changing the existing value to be null (or "")
  // 3. if there was an existing value and we're changing it because the new value is different

  if ((!!value && value !== termToUpdate) || (!value && !!termToUpdate))
    updateLineDetailsPromise({
      payload,
      line: productsByLineCode.BP7BusinessOwnersLine
    }).then(() => {
      if (callback) callback();
    });
};
