import { useEffect } from "react";
import { useDepartmentRouteMatch } from "../hooks";

export const useDepartmentContactsFetch = ({
  agencyCode,
  fetchUfgTeamContactDetails,
  setPreviousDepartment,
  previousDepartment,
  previousAgencyCode,
  areContactsLoading,
  departmentData,
  activeSpecialtyCompanyBranch,
  previousCompanyBranch
}) => {
  const yourUfgTeamDepartmentRouteMatch = useDepartmentRouteMatch();

  useEffect(() => {
    const foundDepartment = departmentData.find(dep => dep.id === yourUfgTeamDepartmentRouteMatch?.params?.departmentId);
    const departmentRouteOrAgencyOrCompanyBranchChanged = (
      yourUfgTeamDepartmentRouteMatch?.params?.departmentId !== previousDepartment ||
      activeSpecialtyCompanyBranch !== previousCompanyBranch ||
      agencyCode !== previousAgencyCode
    );
    if (
      yourUfgTeamDepartmentRouteMatch?.params?.departmentId &&
      !areContactsLoading &&
      foundDepartment &&
      departmentRouteOrAgencyOrCompanyBranchChanged
    ) {
      setPreviousDepartment(yourUfgTeamDepartmentRouteMatch.params.departmentId);
      fetchUfgTeamContactDetails(
        agencyCode,
        yourUfgTeamDepartmentRouteMatch.params.departmentId
      );
    }
  }, [
    agencyCode,
    fetchUfgTeamContactDetails,
    yourUfgTeamDepartmentRouteMatch,
    setPreviousDepartment,
    previousDepartment,
    previousAgencyCode,
    areContactsLoading,
    departmentData,
    activeSpecialtyCompanyBranch,
    previousCompanyBranch
  ]);

  return null;
};
