import qs from "query-string";
import API from "../api";

export const getNewsArchives = (state, company_branch) => {
  const queryString = qs.stringify({
    state,
    company_branch
  });
  if (company_branch !== null) {
    return API.agent().get(`/news-archive?${queryString}`);
  }
  return API.agent().get(`/news-archive?state=${state}`);
};

export const getNewsCurrent = (state, company_branch) => {
  const queryString = qs.stringify({
    state,
    company_branch
  });
  if (company_branch !== null) {
    return API.agent().get(`/news-current?${queryString}`);
  }
  return API.agent().get(`/news-current?state=${state}`);
};
