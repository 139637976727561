export const validateState = value => {
  if (!value) {
    return "State is required.";
  }
};

export const validateCity = value => {
  if (!value) {
    return "City is required.";
  }
};

export const validateCustomerId = (
  existingCustomerIds,
  selectedCustomerId
) => value => {
  if (value === "") {
    return "Customer ID is required.";
  }

  if (value.length < 2)
    return "Customer ID must be at least two characters long.";

  const matchesSelectedCustomer =
    selectedCustomerId && value === selectedCustomerId;
  if (
    (!matchesSelectedCustomer || !selectedCustomerId) &&
    existingCustomerIds.some(id => id.toUpperCase() === value.toUpperCase())
  ) {
    return "Customer ID already exists. Please enter a different Customer ID.";
  }

  const amsCustomerIdRegex = /^([a-zA-Z0-9][^"'&#;/\\]{1,50})$/;
  if (!amsCustomerIdRegex.test(value))
    return "Customer ID must begin with an alphanumeric character and not contain the following characters: \" ' & # ; / \\";
};

export const validateCompanyName = value => {
  if (!value) {
    return "Company Name is required.";
  }
};

export const validateMailingAddress = value => {
  const regex = /^[A-Za-z0-9.-]{1,9}[ ]{1}[A-Za-z0-9.]+([A-Za-z0-9. ]|[ ]{1}#[0-9])*$/;
  if (!value) {
    return "Mailing Address is required.";
  } else if (!regex.test(value)) {
    return "Not a valid street address. The mailing address should look like 123 Main St.";
  }
};

export const validateBillingAddress = value => {
  if (!value) {
    return "Billing Address is required.";
  }
};

export const validateFirstName = value => {
  if (!value) {
    return "First Name is required.";
  }
};

export const validateLastName = value => {
  if (!value) {
    return "Last Name is required.";
  }
};

export const validateZip = value => {
  const regex = /^\d{5}(-\d{4})?$/;

  if (!value) {
    return "Zip Code is required.";
  } else if (!regex.test(value)) {
    return "Must be 5 or 9 digits.";
  }
};

// TODO: Delete when done refactoring
export const validateZipCode = value => {
  const regex = /^\d{5}$/;

  if (!value) {
    return "Zip Code is required.";
  } else if (!regex.test(value)) {
    return "Must be 5 digits.";
  }
};

// TODO: Delete when done refactoring
export const validateAreaCode = value => {
  const regex = /^\d{4}$/;

  if (value && !regex.test(value)) {
    return "Must be 4 digits.";
  }
};
