import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PolicySearchList from "../components/account/search/PolicySearchList";
import { dismissPolicyModal } from "../actions/policies";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  policies: state.policiesData.search_result,
  search_type: state.policiesData.search_type,
  isLoading: state.policiesData.isLoading,
  isReportClaim: state.policiesData.isReportClaim,
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  isLoadingPolicyTermsHistory: state.policiesData.isLoadingPolicyTermsHistory,
  policyTermsHistoryError: state.policiesData.isLoadingPolicyTermsHistory,
  hasPolicyTermsHistory: state.policiesData.hasPolicyTermsHistory,
  policyTermsHistory: state.policiesData.policyTermsHistory
});

const mapDispatchToProps = dispatch => ({
  policySelected: () => {
    dispatch(dismissPolicyModal);
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PolicySearchList)
);
