export function getActivityListSelector(store) {
  return store.policyActivityData.activityList;
}
export function getCancellationListSelector(store) {
  return store.policyActivityData.cancellationList;
}

export function getIsActivityLoadingSelector(store) {
  return store.policyActivityData.isActivityLoading;
}

export function getErrorActivityListSelector(store) {
  return store.policyActivityData.errorActivityList;
}

export function getCancellationListLoadingSelector(store) {
  return store.policyActivityData.isCancellationLoading;
}

export function getErrorCancellationListSelector(store) {
  return store.policyActivityData.errorCancellationList;
}
