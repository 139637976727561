import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const agencySweepOasisInvoiceDetailReducer = (
  state = initialState.agencySweepOasisInvoiceDetail,
  action
) => {
  switch (action.type) {
    case types.FETCH_OASIS_INVOICE_DETAIL_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_OASIS_INVOICE_DETAIL_SUCCEEDED:
      return {
        ...state,
        billingInformation: action.payload.billing_information,
        isLoading: false
      };
    case types.FETCH_OASIS_INVOICE_DETAIL_FAILED:
      return {
        ...state,
        billingInformation: "",
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default agencySweepOasisInvoiceDetailReducer;
