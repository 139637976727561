import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const activityReducer = (state = initialState.activityData, action) => {
  switch (action.type) {
    case types.FETCH_ACTIVITY_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        activity: action.payload,
        isLoading: false
      };
    case types.FETCH_ACTIVITY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default activityReducer;
