import qs from "query-string";
import API from "../../api";

export const getTypes = () => {
  return API.agent().get(`/documents-forms/types`);
};

export const getStates = companyBranch => {
  const branchParam = companyBranch ? `?company_branch=${companyBranch}` : "";
  const apiUrl = `${window.env.REACT_APP_M4_AGENT_BASEURL}/coverage-forms/states${branchParam}`;
  return API.agent().get(apiUrl);
};

export const getProducts = state => {
  return API.agent().get(`/coverage-forms/states/${state}/products`);
};

export const getForms = (
  stateAbbrevation,
  product,
  keyword,
  companyBranch,
  options
) => {
  const parameters = keyword
    ? {
        state_abbreviation: stateAbbrevation,
        product_id: product,
        keyword
      }
    : companyBranch
    ? {
        state_abbreviation: stateAbbrevation,
        product_id: product,
        company_branch: companyBranch
      }
    : {
        state_abbreviation: stateAbbrevation,
        product_id: product
      };

  const queryString = qs.stringify(parameters);

  return API.agent().get(`coverage-forms/forms?${queryString}`, options);
};

export const getFormsByFormCategory = (
  type,
  state_abbv,
  category,
  companyBranch
) => {
  const params = companyBranch
    ? {
        category_id: category,
        company_branch: companyBranch
      }
    : {
        category_id: category
      };

  const queryString = qs.stringify(params);

  return API.agent().get(
    `/documents-forms/types/${type}/states/${state_abbv}/forms?${queryString}`
  );
};

export const printCoverageForms = form_number => {
  return API.agent().get(`form?form_number=${form_number}`, {
    responseType: "blob"
  });
};

export const printDocuments = id => {
  return API.agent().get(`/documents-forms/form/${id}`, {
    responseType: "blob"
  });
};

export const getCategories = type => {
  return API.agent().get(`/documents-forms/types/${type}/categories`);
};
