import { createSlice } from "@reduxjs/toolkit";
import { getAgencyInfo } from "../../services/agencyService";
import { logger } from "../../loggers";

const agencyInfoSlice = createSlice({
  name: "agencyInfoSlice",
  initialState: {
    isLoading: false,
    data: null,
    error: false
  },
  reducers: {
    started: state => {
      state.isLoading = true;
      state.data = null;
      state.error = false;
    },
    succeeded: (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      state.error = false;
    },
    failed: state => {
      state.isLoading = false;
      state.data = null;
      state.error = true;
    }
  }
});

export const fetchAgencyInfo = agencyCode => dispatch => {
  const { started, succeeded, failed } = agencyInfoSlice.actions;
  const onError = e => {
    dispatch(failed());
    logger.error({
      description: e.toString(),
      fatal: true
    });
  };
  dispatch(started());
  return getAgencyInfo(agencyCode).then(
    response => {
      if (response?.status === 200) dispatch(succeeded(response?.data));
      else onError(response);
    },
    error => onError(error)
  );
};

export default agencyInfoSlice.reducer;
