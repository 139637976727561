import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  Form,
  FormGroup,
  useForm,
  TextArea,
  formatDate,
  DatePicker,
  Alert,
  Table,
  Icon
} from "@ufginsurance/ui-kit";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { dismissOtherChanges } from "./otherChangesModalActions";
import { postOtherPolicyChange } from "../redux/policyChangesService";
import { toast } from "react-toastify";
import DragAndDropAttachments from "../../components/commercial/commercial-lines/DragAndDropAttachments";
import { Translations } from "../../components/translations";
import { debounceApi } from "../../util/debounce";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const OtherChangesModal = ({
  policyInfo,
  setShowSuccessModal,
  effectiveDate,
  isActivated,
  handleClose,
  insuredName,
  agentFirstAndLastName,
  agentEmail,
  agentCode,
  address,
  activeAgencyName,
  agencyCode,
  printPDFdata,
  minDate,
  maxDate,
  updateParentEffectDate
}) => {
  const streetAddress = address?.address1;
  const regionalAddress = `${address?.city}, ${address?.state} ${address?.zip}`;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [showAlert, setShowAlert] = useState({});
  const [previousEffectiveDate, setPreviousEffectiveDate] = useState("");

  const { changeRequests } = Translations.account_overview;
  const fileSizeErrMsg = changeRequests.other_changes_modal_max_file_size;

  const MAX_ATTACHMENTS_SIZE = 10485760;
  const convertBytesToMB = b => parseFloat(b / Math.pow(1024, 2)).toFixed(2);
  const today = new Date();

  const initialValues = {
    effectiveDate: "",
    changeRequested: ""
  };

  const handleSubmission = ({ values }) => {
    const data = {
      policy_number: policyInfo.policyNumber,
      insured: insuredName,
      effective_date: formatDate(values.effectiveDate, "YYYY-MM-DD"),
      agency_number: agencyCode,
      agency_name: activeAgencyName,
      submitted_by: {
        name: agentFirstAndLastName,
        email: agentEmail
      },
      change: values.changeRequested,
      insurance_type: policyInfo.insurance_type
    };
    setIsSubmitLoading(true);
    postOtherPolicyChange(data, attachments).then(
      res => {
        setIsSubmitLoading(false);
        closeAndReset();
        toast.dismiss();
        setShowSuccessModal(true);
        printPDFdata({
          policy_number: policyInfo.policyNumber,
          insured_name: insuredName,
          effective_date_of_change: formatDate(effectiveDate, "YYYY-MM-DD"),
          agency_code: agencyCode,
          agency_name: activeAgencyName,
          producer_name: agentFirstAndLastName,
          producer_email: agentEmail,
          submitted_date:
            today.getFullYear() +
            "-" +
            (1 + today.getMonth()) +
            "-" +
            today.getDate(),
          change_request_notes: values.changeRequested,
          attachments: attachments.map(att => att.name)
        });
        return res.data;
      },
      _err => {
        setIsSubmitLoading(false);
        displayToastError();
      }
    );
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const resetForm = () => {
    updateForm({
      values: initialValues
    });
  };

  const closeAndReset = () => {
    handleClose();
    resetForm();
    setAttachments([]);
  };

  const handleEffectiveDateOnChange = useCallback(
    ({ field, value }) => {
      updateForm({
        values: {
          ...values,
          [field]: value
        },
        errors: {
          ...errors,
          effectiveDate: []
        }
      });

      if (updateParentEffectDate) {
        updateParentEffectDate({ field, value });
      }
    },
    [updateParentEffectDate, updateForm, values, errors]
  );

  const exceedsMaxSize = files => {
    const totalSize = files.reduce((tot, file) => (tot += file.size), 0);
    return Boolean(totalSize > MAX_ATTACHMENTS_SIZE);
  };

  const handleSelect = selectedAttachments => {
    const allAttachments = attachments.length
      ? [...selectedAttachments, ...attachments]
      : selectedAttachments;
    const uniqueAttachments = Array.from(
      new Map(allAttachments.map(file => [file.name, file])).values()
    );

    if (!exceedsMaxSize(uniqueAttachments)) {
      setShowAlert({});
      setAttachments(uniqueAttachments);
    } else {
      setShowAlert({
        msg: fileSizeErrMsg,
        type: "error"
      });
    }
  };

  const handleRemoveAttachmentUsingDebounce = debounceApi(file => {
    const filteredAttachments = attachments.filter(o => o.name !== file.name);
    if (!exceedsMaxSize(filteredAttachments)) {
      setShowAlert({});
      setAttachments(filteredAttachments);
    } else {
      setShowAlert({
        msg: fileSizeErrMsg,
        type: "error"
      });
    }
  }, 300);

  const handleRemoveAttachment = file => () => {
    handleRemoveAttachmentUsingDebounce(file);
  };

  const handleDismissAlert = () => {
    setShowAlert({});
  };

  useEffect(() => {
    const errorsExist = Object.keys(errors).length >= 1;

    const shouldDisable =
      values.changeRequested.length === 0 ||
      values.effectiveDate === "" ||
      errorsExist;

    setSubmitDisabled(shouldDisable);
  }, [values.changeRequested, values.effectiveDate, errors]);

  const displayToastError = () => {
    const options = {
      position: "top-center",
      className: "error",
      autoClose: false
    };
    toast(<HelpDeskMessage display="trySubmittingAgain" />, options);
  };

  useEffect(() => {
    if (!values.effectiveDate && effectiveDate) {
      handleEffectiveDateOnChange({
        field: "effectiveDate",
        value: effectiveDate
      });
    } else if (previousEffectiveDate !== effectiveDate) {
      handleEffectiveDateOnChange({
        field: "effectiveDate",
        value: effectiveDate
      });
      setPreviousEffectiveDate(effectiveDate);
    }
  }, [
    previousEffectiveDate,
    effectiveDate,
    handleEffectiveDateOnChange,
    values.effectiveDate
  ]);

  return (
    <Modal
      onHide={closeAndReset}
      show={isActivated}
      title="Other Policy Change"
      className="other-changes-modal"
      body={
        <>
          <ul id="other-changes-information" className="policy-info">
            <li className="policy-info__item">
              <span className="policy-info__label bold">Policy Number: </span>
              <div className="policy-info__value">
                <span id="policy-number">{`${policyInfo.policyNumber} - ${policyInfo.policyType}`}</span>
              </div>
            </li>
            <li className="policy-info__item">
              <span className="policy-info__label bold">Insured: </span>
              <div className="policy-info__value">
                <span id="insured-name">{insuredName}</span>
                <span id="insured-address-street">{streetAddress}</span>
                <span id="insured-address-regional">{regionalAddress}</span>
              </div>
            </li>
            <li className="policy-info__item">
              <span className="policy-info__label bold">Agency: </span>
              <div className="policy-info__value">
                <span id="agency-name">{activeAgencyName}</span>
                <span id="agent-code">{agentCode}</span>
              </div>
            </li>
            <li className="policy-info__item">
              <span className="policy-info__label bold">Name: </span>
              <div className="policy-info__value">
                <span id="producer-name">{agentFirstAndLastName}</span>
              </div>
            </li>
            <li className="policy-info__item">
              <span className="policy-info__label bold">Email Address: </span>
              <div className="policy-info__value">
                <span id="producer-email">{agentEmail}</span>
              </div>
            </li>
          </ul>
          <Form className="other-changes__form" context={form}>
            <FormGroup>
              <DatePicker
                id="effectiveDate"
                name="effectiveDate"
                label={changeRequests.effective_date}
                onChange={handleEffectiveDateOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.effectiveDate}
                format="DATE"
                required
                className="effective-date"
                minDate={minDate}
                maxDate={maxDate}
                size="md"
              />
            </FormGroup>
            <FormGroup>
              <TextArea
                id="changeRequested"
                name="changeRequested"
                label="Change Requested"
                placeholder="Please describe your change request in detail."
                required
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.changeRequested}
              />
            </FormGroup>

            {!!showAlert?.type && (
              <Alert type={showAlert.type} onDismiss={handleDismissAlert}>
                {showAlert.msg || Translations.changeRequests.general_error}
              </Alert>
            )}
            <div className="other-changes__file-uploader">
              <DragAndDropAttachments
                id="other-changes-file-input"
                allowMultiple
                onSelectFiles={handleSelect}
                allowedFileTypes={[
                  "txt",
                  "rtf",
                  "pdf",
                  "jpg",
                  "gif",
                  "bmp",
                  "png",
                  "tif",
                  "doc",
                  "docx",
                  "xls",
                  "xlsx"
                ]}
              />
              <div className="upload-filesize mt-1">
                <i>{Translations.otherChangeRequest.accepted_files}</i>
                <br />
                <i>
                  <b>{Translations.otherChangeRequest.note}</b>
                  <span>{Translations.otherChangeRequest.accepted_limit}</span>
                </i>
              </div>
              <div className="policy-info__text">
                {Translations.otherChangeRequest.your_Attachments}
              </div>
              <Table
                className="other-changes__attachments-list"
                data={attachments}
                rowKey="name"
                itemsPerPage={20}
                columns={[
                  {
                    key: "name",
                    label: "Name",
                    sortable: true,
                    className: "name"
                  },
                  {
                    label: "Size",
                    className: "size",
                    key: "fileSize",
                    align: "right",
                    element: row => (
                      <span role="presentation">
                        {convertBytesToMB(row.size)}
                      </span>
                    )
                  },
                  {
                    label: "Remove",
                    className: "remove",
                    key: "removeFile",
                    align: "right",
                    element: row => {
                      return (
                        <div
                          className="remove-icon"
                          role="presentation"
                          onClick={handleRemoveAttachment(row)}
                        >
                          <Icon icon="fasTimesCircle" />
                        </div>
                      );
                    }
                  }
                ]}
              />
            </div>
            <div className="modal-form-buttons">
              <Button
                id="other-changes-modal-cancel-button"
                type="reset"
                variant="plain"
                onClick={closeAndReset}
              >
                Cancel
              </Button>
              <Button
                id="other-changes-modal-submit-button"
                type="submit"
                variant="primary"
                spinnerPos="right"
                spinner={isSubmitLoading}
                disabled={submitDisabled || isSubmitLoading}
                // onClick={handleSubmission}
                data-component="other-changes-submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </>
      }
    />
  );
};

OtherChangesModal.propTypes = {
  policyInfo: PropTypes.object,
  setShowSuccessModal: PropTypes.func,
  effectiveDate: PropTypes.string,
  isActivated: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  insuredName: PropTypes.string,
  agentFirstAndLastName: PropTypes.string,
  agentEmail: PropTypes.string,
  agentCode: PropTypes.string,
  address: PropTypes.object,
  activeAgencyName: PropTypes.string,
  agencyCode: PropTypes.string,
  printPDFdata: PropTypes.func,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  updateParentEffectDate: PropTypes.func
};

const mapStateToProps = state => ({
  isActivated: state.otherChangesModalData.isActivated,
  address: state.accountData.accounts.address,
  insuredName: state.accountData.accounts.primary_account_name,
  agentFirstAndLastName: sessionSelector.getFirstAndLastName(state),
  agentEmail: sessionSelector.getEmail(state),
  agentCode: sessionSelector.getAgentCode(state),
  activeAgencyName: sessionSelector.getActiveAgencyName(state),
  agencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  handleClose: () => dismissOtherChanges
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherChangesModal);
