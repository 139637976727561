import { connect } from "react-redux";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import ClaimsPayments from "../components/ClaimsPayments";
import { fetchClaimsPayments } from "../actions/claimsActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = (state, props) => ({
  payments: state.claims.payments[props.claimNumber] || [],
  selectedAgencyCode: getSelectedAgencyCode(state),
  employeeUserName: sessionSelector.getEmployeeUserName(state)
});

const mapDispatchToProps = {
  fetchClaimsPayments
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsPayments);
