import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const suretyQuoteAgencyDataReducer = (
  state = initialState.suretyZip,
  action
) => {
  switch (action.type) {
    case types.FETCH_SURETY_ZIP_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_SURETY_ZIP_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_SURETY_ZIP_FAILED:
      return {
        ...state,
        data: null,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default suretyQuoteAgencyDataReducer;
