import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useReducer
} from "react";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { useGetValidAgencyStates, useGetDocsAndForms } from "./hooks";

const SuretyDocsFormsContext = createContext({
  initialFormValues: {
    state: "",
    keyword: "",
    bondType: "All",
    obligeeType: "All"
  }
});

export const useSuretyDocsFormsContext = () =>
  useContext(SuretyDocsFormsContext);

export function SuretyDocsFormsProvider({ ssoSession, ...props }) {
  const { initialFormValues } = useSuretyDocsFormsContext();
  const [currentSection, setCurrentSection] = useState("bondsContract");
  // The values for the FilterForm component, these are used as the initial
  // values for the form context, and are served directly to the form inputs.
  const [formValues, updateFormValues] = useReducer(
    (formValues, newFormValues) => ({ ...formValues, ...newFormValues }),
    initialFormValues
  );
  const activeAgency = sessionSelector.getActiveAgency({ ssoSession });
  const [validAgencyStates, validAgencyStatesIsLoading] =
    useGetValidAgencyStates(activeAgency.agencyCode, currentSection);

  const defaultAgencyState = validAgencyStates?.find(
    state => state.abbreviation === activeAgency.state
  );

  // Reset form values, preserve agency state.
  const resetFormValues = useCallback(() => {
    updateFormValues({
      ...initialFormValues,
      state: defaultAgencyState?.name
    });
  }, [defaultAgencyState, initialFormValues]);

  // Set default state value to agency state.
  useEffect(() => {
    if (defaultAgencyState && !formValues.state) {
      updateFormValues({ state: defaultAgencyState?.name });
    }
  }, [defaultAgencyState, formValues.state]);

  // Reset form values when not on bonds section.
  useEffect(() => {
    if (!currentSection.includes("bonds")) resetFormValues();
  }, [currentSection, resetFormValues]);

  // Fetch relevant documents when section changes.
  const [docsAndFormsList, isLoadingDocsAndFormsList] = useGetDocsAndForms(
    activeAgency.agencyCode,
    currentSection,
    formValues
  );

  return (
    <SuretyDocsFormsContext.Provider
      value={{
        activeAgency,
        currentSection,
        docsAndFormsList,
        formValues,
        isLoadingDocsAndFormsList,
        resetFormValues,
        setCurrentSection,
        updateFormValues,
        validAgencyStates,
        validAgencyStatesIsLoading
      }}
      {...props}
    >
      <div id="docs-and-forms">{props.children}</div>
    </SuretyDocsFormsContext.Provider>
  );
}
