import React, { useCallback, useContext } from "react";
import { FlexRow, Panel, Button } from "@ufginsurance/ui-kit";
import CoveragesInCards, {
  displayedCoverages
} from "../../step5/CoveragesInCards";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import getAllCoverages from "../../step4/getAllCoverages";
import { getProductPaths, productsByLineCode } from "../../shared/constants";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import {
  workCompFieldOptions,
  addWorkmansCompDetailsFields
} from "../../step4/workmansCompDetails";
import HiredAutoView from "./HiredAutoView";
import { addBopFieldsAtLineLevel } from "../../step4/bopLineFields";

import BP7EnhancementView from "./BP7EnhancementView";
const LobCoverages = ({ lobName, lobKey, coverageExclusions }) => {
  const { quoteData, getQuotePart, get, supportingData } =
    useContext(OnlineQuotingContext);

  const history = useHistory();

  const coverageFields = useCallback(
    productName => {
      const prodPath = (
        getProductPaths({ quoteData, showUmbrella: true }) || []
      )?.find(x => x.productName === productName);

      const covs = getAllCoverages({
        coveragePaths: prodPath || [],
        getQuotePart
      });
      if (productName === productsByLineCode.WCMWorkersCompLine) {
        const primaryLocation = get.location({ isPrimary: true });
        covs.unshift(
          addWorkmansCompDetailsFields({
            quoteData,
            wcLineOptions: workCompFieldOptions({
              state: primaryLocation.address.state
            })
          })
        );
      }

      if (
        productName === productsByLineCode.BP7BusinessOwnersLine &&
        coverageExclusions
      ) {
        covs.push(
          ...addBopFieldsAtLineLevel({
            coverageExclusions,
            supportingData,
            quoteData
          })
        );
      }
      return (covs || [])?.filter(c => c.selected);
    },
    [quoteData, getQuotePart, get, supportingData, coverageExclusions]
  );

  const getBP7Enhancement = useCallback(
    productName => {
      const covs = coverageFields(productName);
      const enhanceCov = covs.find(
        c => c.codeIdentifier === "BP7EnhancementEndorsement_UFG"
      );
      return enhanceCov;
    },
    [coverageFields]
  );

  const coverages = displayedCoverages({
    coverages: coverageFields(lobKey).filter(
      c => c.codeIdentifier !== "BP7EnhancementEndorsement_UFG"
    ),
    coverageExclusions
  });

  return (
    <Panel
      noBorder
      className={`oq__coverage-panel__${lobKey} oq__quote-summary__column__section`}
      title={
        <FlexRow>
          <span>{lobName} Coverages</span>
          {lobKey !== "cuCommercialUmbrella" && (
            <Button
              variant="outline"
              wrapperClassName="align-right"
              isLink
              inline
              className="link-not-bolded"
              onClick={() => {
                history.push({
                  pathname: routes.ONLINE_QUOTING_STEP4,
                  hash: lobKey
                });
              }}
            >
              Back to Coverages
            </Button>
          )}
        </FlexRow>
      }
    >
      <div className="oq__coverable__card__content__coverages">
        <CoveragesInCards
          quoteData={quoteData}
          coverageFields={coverages}
          coverageExclusions={coverageExclusions}
          id="coverages__bop"
          alwaysDisplay
          editScheduledItems={false}
        />
        {lobKey === productsByLineCode.BP7BusinessOwnersLine && (
          <>
            <HiredAutoView />
            <BP7EnhancementView coverage={getBP7Enhancement(lobKey)} />
          </>
        )}
      </div>
    </Panel>
  );
};

export default LobCoverages;
