import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  LoadingIndicator
} from "@ufginsurance/ui-kit";

import getSelectedClassColumns from "./getSelectedClassColumns";
import OnlineQuotingContext from "../../OnlineQuotingContext";

import "../../Step2Classify.scss";

const VehicleFormClassesStep2 = ({
  currentVehicle,
  onNextStep,
  onPrevStep,
  vehicleClasses
}) => {
  const { updateCoverablesPromise, toastErrr } =
    useContext(OnlineQuotingContext);

  /**
   * when editing a vehicle, set the selected class
   */
  const currentVehicleClass =
    currentVehicle.vehicleType === "CA7Truck" &&
    !!currentVehicle?.primaryClassCode
      ? vehicleClasses.find(
          c =>
            c.classCode ===
            `${currentVehicle?.primaryClassCode}${currentVehicle.secondaryClassCode}`
        )
      : currentVehicle.vehicleType === "CA7PrivatePassenger" &&
        !!currentVehicle.type
      ? vehicleClasses.find(c => c.type === currentVehicle?.type)
      : null;

  const vehicleWithClass = !!currentVehicleClass ? currentVehicle : null;

  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(currentVehicleClass);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableSelectedClassColumns, setTableSelectedClassColumns] = useState(
    []
  );
  const [vehicleWithClassData, setVehicleWithClassData] =
    useState(vehicleWithClass);
  const [classAutoSelected] = useState(vehicleWithClass);

  const initialValues = {
    search: ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange } = form;

  // function to set the class data as the user adds/removes the class on the step
  const updateVehicleClass = useCallback(
    classObj => {
      let vehicle = {
        ...currentVehicle,
        classCode: classObj?.classCode,
        sizeClass: classObj?.sizeClass,
        radiusClass: classObj?.radiusClass,
        businessUseClass: classObj?.businessUse,
        fleet: classObj?.fleet,
        primaryClassCode: classObj?.primaryClassCode,
        secondaryClass: classObj?.secondaryClass,
        secondaryClassCode: classObj?.secondaryClassCode,

        // Private Passenger Specific Fields
        type: classObj?.type,
        use: classObj?.use,
        operatorExperience: classObj?.operatorExperience
      };

      // if we're removing a class, then remove these properties from the vehicle object
      if (!classObj) {
        const {
          classCode,
          sizeClass,
          radiusClass,
          businessUseClass,
          fleet,
          primaryClassCode,
          secondaryClass,
          secondaryClassCode,
          type,
          use,
          operatorExperience,
          ...cleanVehicle
        } = vehicle;
        vehicle = cleanVehicle;
      }

      updateCoverablesPromise({
        coverableType: "vehicle",
        coverables: { vehicles: [vehicle] },
        action: "Updating"
      })
        .then(() => {
          setSelectedClass(classObj);
          setVehicleWithClassData(!!classObj ? vehicle : null);
        })
        .catch(({ error }) =>
          toastErrr({
            action: "updateCoverablesPromise",
            misc: {
              coverables: { vehicles: [vehicle] }
            },
            description: "failed to update vehicle",
            error,
            displayMessage: `Failed to update vehicle.`
          })
        );
    },
    [currentVehicle, updateCoverablesPromise, toastErrr]
  );

  const selectClassCode = useCallback(
    classObj => {
      if (!classObj) return;
      updateVehicleClass(classObj);
    },
    [updateVehicleClass]
  );

  const removeClassCode = useCallback(() => {
    updateVehicleClass(null);
  }, [updateVehicleClass]);

  // configure the table columns for the main table and the selected row that
  // shows at the top of the modal
  useEffect(() => {
    if (currentVehicle?.vehicleType) {
      const removeButton = row => {
        return (
          <Button
            wrapperClassName="oq__classes__remove-btn-wrapper"
            className="oq__classes__remove-btn"
            size="sm"
            variant="tertiary"
            onClick={() => removeClassCode(row)}
          >
            Remove
          </Button>
        );
      };

      const selectClassButton = row => {
        return (
          <Button
            wrapperClassName="oq__add-button__small"
            onClick={() => selectClassCode(row)}
            size="sm"
            variant="info"
            icon={"farPlusCircle"}
            disabled={!!selectedClass}
          >
            Add
          </Button>
        );
      };

      const _tableColumns = getSelectedClassColumns({
        vehicleType: currentVehicle?.vehicleType
      });

      // set the main table
      setTableColumns([
        ..._tableColumns,
        {
          key: "",
          label: "",
          element: selectClassButton,
          sortable: false,
          align: "right"
        }
      ]);

      // set the row of the selected class at the top
      setTableSelectedClassColumns([
        ..._tableColumns.map(s => ({ ...s, label: "" })),
        {
          key: "",
          label: "",
          className: "oq__classes__selected__remove",
          element: removeButton,
          align: "right"
        }
      ]);
    }
  }, [currentVehicle, removeClassCode, selectClassCode, selectedClass]);

  // update the filtered class list when...
  // 1. the users searches
  // 2. the user adds or removes a class
  useEffect(() => {
    const searchText = values.search;
    /* Made the search field able to search multiple columns at a 
    time with spaces between the search tokens */
    const searchTokens = searchText.split(" ");
    const filteredData = searchTokens.reduce((vehicleClasses, searchToken) => {
      return vehicleClasses.filter(c => {
        // s defaults to "true" if there's no searchText
        // if there is searchText, then default is false...
        // and we'll set it to true if anything matches in the tableColumns loop below
        let s = !searchToken;

        // use the table-columns definition to compare the searchText with data actually visible in the table
        if (!!searchToken) {
          tableColumns.forEach(col => {
            const columnKey = col?.key;
            if (
              !!columnKey &&
              (c?.[columnKey] || "")
                .toLowerCase()
                .includes(searchToken.toLowerCase())
            )
              s = true;
          });
        }

        return s && selectedClass?.classCode !== c.classCode;
      });
    }, vehicleClasses);
    setFilteredClasses(filteredData);
  }, [vehicleClasses, selectedClass, tableColumns, values.search]);

  const onSubmit = () => {
    onNextStep(vehicleWithClassData);
  };

  return (
    <div className="oq__classes__vehicle">
      {!vehicleClasses.length && <LoadingIndicator />}
      <Form
        className="oq__form__vehicle__step2 oq__classes__form"
        context={form}
      >
        {!!vehicleClasses.length && (
          <>
            {selectedClass && (
              <>
                {currentVehicle?.isVehicleNew && classAutoSelected && (
                  <p className="oq__auto__auto-selected-text">
                    Based on the information entered we’ve assigned the vehicle
                    class below. If you feel that it is incorrect, you can
                    select a different class by deleting this selection.
                  </p>
                )}
                <Table
                  key="selectedClassCodes"
                  rowKey="classCode"
                  className="oq__classes__selected"
                  columns={tableSelectedClassColumns}
                  data={[selectedClass]}
                  showPagination={false}
                  itemsPerPage={100}
                  nowrap
                />
              </>
            )}
            <FormGroup
              key={"searchFields"}
              className="oq__classes__searchRow addPadUnder"
              noOuterPadding
            >
              <Input
                id="search"
                name="search"
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                label="Search Class or Description"
                onChange={handleOnChange}
                value={values.search}
                className="oq__classes__search"
                size="auto"
              />
            </FormGroup>
            <FormGroup key={"allClassCodesGrp"}>
              <Table
                key={`${currentVehicle?.vehicleType}.${currentVehicle.fixedId}`}
                rowKey="classCode"
                className="oq__class-table"
                columns={tableColumns}
                data={filteredClasses}
                showPagination
                itemsPerPage={5}
                noResultsMessage="No results found"
                initialSort="classCode"
              />
            </FormGroup>
          </>
        )}
        <FormGroup className="oq__bottom-row">
          <Button
            isLink
            inline
            className="back"
            disabled={!vehicleWithClassData}
            onClick={() => {
              onPrevStep(vehicleWithClassData || currentVehicle);
            }}
          >
            Back to Vehicle Details
          </Button>

          <Button
            variant="primary"
            className="continue"
            wrapperClassName="push-right"
            disabled={!vehicleWithClassData}
            onClick={onSubmit}
          >
            Continue
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default VehicleFormClassesStep2;
