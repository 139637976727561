import { useEffect } from "react";
import { useCurrentRouteMatch } from "../hooks";

export const useProductsRouteFetch = (
  previousFetchedStateId,
  areProductsLoading,
  fetchStateProducts
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteStateId = currentMatch?.params?.stateId;

  useEffect(() => {
    if (
      currentRouteStateId &&
      previousFetchedStateId !== currentRouteStateId &&
      !areProductsLoading
    ) {
      fetchStateProducts(currentRouteStateId);
    }
  }, [
    fetchStateProducts,
    currentRouteStateId,
    previousFetchedStateId,
    areProductsLoading
  ]);

  return null;
};
