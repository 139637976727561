import React, { useCallback, useState } from "react";
import * as api from "../../services/onlineQuotingService";

import { Button, ToolTip, Alert } from "@ufginsurance/ui-kit";
let abortController;

const GenerateMetadata = ({ env, agentEnvLabel }) => {
  const [spinner, setSpinner] = useState();
  const [status, setStatus] = useState();
  const [lastStatusData, setLastStatusData] = useState();
  const [initialStatusCheck, setInitialStatusCheck] = useState();

  const closeTooltip = () =>
    document
      .querySelector("#uikit_tooltip_triggerid_meatadata_regenerate")
      .click();

  const howOldIsMetaData = useCallback(() => {
    return new Promise((resolve, reject) => {
      abortController = new AbortController();

      api
        .getEapiStatus({
          signal: abortController?.signal
        })
        .then(resp => {
          // console.log(resp);
          const eapi = resp?.data;
          if (!eapi || eapi.status !== "pass") {
            reject({
              success: false,
              message: "EAPI failure"
            });
            return;
          }
          const sapi = resp?.data?.details.find(
            d => d.system === "policy-center-metadata-sapi"
          );
          const metadata = sapi?.details?.find(
            d => d.system === "online-quoting-metadata"
          );
          const _metadata = metadata?.details?.find(d => d.generationCompleted);

          // keep track of previous date to be able to send the flag: generationDateWasUpdated
          const prevDateStamp = lastStatusData?.genDate;

          if (setLastStatusData)
            setLastStatusData({
              genDate: _metadata?.generationCompleted,
              newEnvironment: _metadata?.newEnvironment
            });

          if (!_metadata?.generationCompleted) {
            reject({
              success: false,
              message: "generation date failure"
            });
            return;
          }

          const _genDate = new Date(_metadata?.generationCompleted);
          const _newDate = new Date(prevDateStamp);

          resolve({
            success: true,
            message: "Mule API Status Good",
            prevDateStamp,
            generationDate: _genDate,
            generationDateWasUpdated: _genDate > _newDate,
            newEnvironment: _metadata?.newEnvironment
          });
        });
    });
  }, [lastStatusData?.genDate]);

  const generate = () => {
    setSpinner(true);

    alert(
      "Metadata generation starts when you close this alert... then please wait for a notification that the metadata generation is compeleted."
    );
    api.metadataGenerate().then(() => {
      let c = 0;

      const pollStatus = setInterval(() => {
        howOldIsMetaData()
          .then(({ generationDateWasUpdated }) => {
            if (c > 15) {
              clearInterval(pollStatus);
              closeTooltip();
              setSpinner(false);
              alert("Metadata generation took too long.  Contact a developer.");
            }
            if (generationDateWasUpdated) {
              clearInterval(pollStatus);
              setSpinner(false);
              closeTooltip();
              alert("Metadata generation completed.");
            }
            c++;
          })
          .catch(({ error }) => {
            clearInterval(pollStatus);
            setSpinner(false);
            alert(error);
          });
      }, 60000);
    });
  };

  const checkStatus = () => {
    if (!lastStatusData?.genDate && !initialStatusCheck) {
      setInitialStatusCheck(true);
      howOldIsMetaData()
        .then(response => setStatus(response))
        .catch(response => setStatus(response));

      return abortController?.abort();
    }
  };

  return (
    <>
      {!status && (
        <Button size="sm" block onClick={checkStatus}>
          Check EAPI Status
        </Button>
      )}
      {status && (
        <>
          <ToolTip
            id="meatadata_regenerate"
            useCloseIcon
            width={200}
            position="top"
            variant="white"
            nowrap={false}
            inline={false}
            trigger={
              <Button size="sm" block icon="farLayerGroup">
                Generate Metadata
              </Button>
            }
            content={
              <div>
                <b>
                  Are you sure you want to generate the metadata for
                  <br /> {agentEnvLabel}
                  {env}?
                </b>
                <br />
                <br />
                If you do this, it is going to block access to requests for
                everyone using ProQuote until is completed.
                <br />
                <br />
                The process can take 3-15 minutes... you see a confirmation when
                the update is completed.
                <br />
                <br />
                <b style={{ color: "red" }}>
                  Make sure you communicate with the team before you do this!
                </b>
                <br />
                <Button
                  block
                  variant="primary"
                  onClick={generate}
                  disabled={spinner}
                  spinner={spinner}
                >
                  Yes
                </Button>
                <Button block variant="plain" onClick={closeTooltip}>
                  No
                </Button>
              </div>
            }
            type="click"
          />
          {lastStatusData?.genDate && (
            <div>
              Metadata last generated from{" "}
              {lastStatusData?.newEnvironment && <>New</>} {env}
              :
              <br />
              {lastStatusData?.genDate}
              <br />
              <br />
            </div>
          )}
          {lastStatusData && !lastStatusData?.genDate && (
            <p>Metadata generation date not found.</p>
          )}
          {status?.message && (
            <>
              {status?.success && <p>{status?.message}</p>}
              {!status?.success && (
                <Alert type="error" dismissible={false}>
                  {status?.message}
                </Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default GenerateMetadata;
