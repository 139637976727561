import React, { useState } from "react";

import { Button, FlexRow } from "@ufginsurance/ui-kit";
import OqModal from "../shared/OqModal";
import PricingReviewForm from "./PricingReviewForm";

const PricingReview = () => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <FlexRow>
        <Button wrapperClassName="oq__button__full-width" onClick={toggleModal}>
          Request a Pricing Review
        </Button>
      </FlexRow>
      <OqModal
        size="lg"
        className="oq__modal oq__modal__send-uw-review"
        title={
          showConfirmation
            ? "Your Pricing Review has been Submitted"
            : "Request Pricing Review"
        }
        body={
          <PricingReviewForm
            onCancel={toggleModal}
            onShowConfirmation={() => setShowConfirmation(true)}
          />
        }
        altCloseMethod={false}
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default PricingReview;
