import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";
import { v4 } from "uuid";

const underwritingReducer = (
  state = initialState.underwritingProducerEmailList,
  action
) => {
  switch (action.type) {
    case types.FETCH_UNDERWRITING_EMAIL_LIST_STARTED:
      return {
        ...state,
        isLoading: true,
        emailList: [],
        error: false
      };
    case types.FETCH_UNDERWRITING_EMAIL_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        emailList: action.payload.map(n => ({
          id: v4(),
          ...n
        })),
        error: false
      };
    case types.FETCH_UNDERWRITING_EMAIL_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        emailList: [],
        error: action.payload
      };
    case types.FETCH_SERVICE_CENTER_PRODUCER_STARTED:
      return {
        ...state,
        isServiceCenterAccessLoading: true,
        serviceCenterAccess: {},
        errorServiceCenterAccess: false
      };
    case types.FETCH_SERVICE_CENTER_PRODUCER_SUCCEEDED:
      return {
        ...state,
        isServiceCenterAccessLoading: false,
        serviceCenterAccess: action.payload,
        errorServiceCenterAccess: false
      };
    case types.FETCH_SERVICE_CENTER_PRODUCER_FAILED:
      return {
        ...state,
        isServiceCenterAccessLoading: false,
        serviceCenterAccess: {},
        errorServiceCenterAccess: action.payload
      };
    default:
      return state;
  }
};

export default underwritingReducer;
