import React from "react";
import { Switch, formatDate } from "@ufginsurance/ui-kit";
import { productKeys, productsByLineCode } from "../shared/constants";
import ProductIsNewTooltip from "./ProductIsNewTooltip";
import { productIsAvail, bopProIsAvail } from "../shared/productMatrix";

const ProductLines = ({ productMatrix, form, isBranch11Agent }) => {
  const { values, updateForm, handleOnChange, handleOnBlur, handleOnValidate } =
    form;

  const effectiveDate = formatDate(form?.values?.effective_date);

  const showUmbrella =
    effectiveDate && bopProIsAvail({ productMatrix, effectiveDate });

  const handleProductChange = ({ field, value }, p) => {
    /**
     * using updateForm to update multiple checkbox values at the same time (instead of handleOnChange)
     * this loses the ability to use the built-in validation for these inputs... which we don't use anyhow
     */
    let newValues = { [field]: value };

    /**
     * custom handling of turnning off umbrella if you turn off BOP...
     * maybe this can be changed to use the product matrix if OOQ-11463 happens in the future
     */
    if (p.code === "BP7BusinessOwnersLine" && value === false) {
      // turn off umbrella
      newValues.cuCommercialUmbrella = false;
    }

    // OOQ-11463 clear required products when the require product is not selected.
    if (value === false) {
      productMatrix
        .filter(
          k =>
            (k.requires || []).length > 0 && (k.requires || []).includes(p.code)
        )
        .forEach(k => {
          newValues = { ...newValues, [productsByLineCode[k.code]]: false };
        });
    }
    updateForm({ values: newValues });
  };

  const productsToDisplay = (productMatrix || [])
    // OOQ-7909 if agent is Branch 11, filter products available
    .filter(product => {
      const { isProQuote, productAvailable } = productIsAvail({
        product,
        effectiveDate
      });
      return (isBranch11Agent ? isProQuote || false : true) && productAvailable;
    })
    // sort products based on their sort key in the 'productKeys' object
    .sort((a, b) => {
      const productA = productKeys?.[a?.code]?.sortOrder || 0;
      const productB = productKeys?.[b?.code]?.sortOrder || 0;
      return productA - productB;
    });

  /**
   * if there's no product matrix data
   * ... or if there's no products to display from the product matrix data
   *
   * then display no products message
   */
  if (!productMatrix || !productsToDisplay.length)
    return <div>No products available for online quoting.</div>;

  // sort the product lines by the same order as they are displayed in the
  // productsByLineCode object
  const sortByKey = Object.keys(productsByLineCode);
  productMatrix.sort(function (a, b) {
    return sortByKey.indexOf(a.code) - sortByKey.indexOf(b.code);
  });

  return (
    <>
      {productsToDisplay.map(p => {
        const { conflicts, requires } = productIsAvail({
          product: p,
          effectiveDate
        });
        // figure out if any products are selected
        const selected =
          Object.keys(productKeys).filter(p => !!values[p]) || [];

        const pKey = productsByLineCode[p.code];
        const product = productKeys[pKey];
        const label = product?.productListStep1Label || product.label; // use custom step1 label if it exists

        /**
         * disabled
         *
         * this checks the conflicts and looks to see if a required product is selected
         */
        const disabled =
          conflicts.some(c => selected.includes(c)) ||
          (requires.length > 0 && !selected.some(r => requires.includes(r)));

        return (
          <React.Fragment key={pKey}>
            <Switch
              id={pKey}
              name={pKey}
              label={
                <>
                  {label}
                  <ProductIsNewTooltip
                    product={p.code}
                    locationState={form?.values?.physical_state}
                    quoteEffectiveDate={form?.values?.effective_date || ""}
                  />
                </>
              }
              onChange={field => handleProductChange(field, p)}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values[pKey]}
              className="oq__product-switches"
              disabled={disabled}
              noLabel
            />
          </React.Fragment>
        );
      })}
      {showUmbrella && (
        <Switch
          id="cuCommercialUmbrella"
          name="cuCommercialUmbrella"
          label="Umbrella"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.cuCommercialUmbrella}
          disabled={!values.bp7BusinessOwners}
          className="oq__product-switches"
          noLabel
        />
      )}
    </>
  );
};

export default ProductLines;
