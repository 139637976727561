import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ExternalLink, Icon } from "@ufginsurance/ui-kit";
import { isActiveLink } from "../utils/Utils";
import "./SidebarNav.scss";

const isExternalLink = link => {
  return /^https?:\/\//.test(link);
};

export const SidebarNav = ({ header, links }) => {
  return (
    <nav className="nav-menu">
      {header && (
        <div className="title">
          <h3>{header}</h3>
        </div>
      )}
      {links.map(link => {
        return isExternalLink(link.to) ? (
          <div key={link.to} className="commercial-side-nav-external">
            <Icon icon="fasCaretRight" className="nav-menu-icon" />
            <ExternalLink to={link.to}>{link.text}</ExternalLink>
          </div>
        ) : (
          <NavLink
            key={link.to}
            to={link.to}
            isActive={(match, location) => isActiveLink(link, match, location)}
            activeClassName={link.activeClassName}
            target={link.to.indexOf("/") === 0 ? "" : "_blank"}
          >
            <Icon icon="fasCaretRight" className="nav-menu-icon" />
            {link.text}
          </NavLink>
        );
      })}
    </nav>
  );
};

SidebarNav.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      activeClassName: PropTypes.string
    }).isRequired
  ).isRequired
};

export default SidebarNav;
