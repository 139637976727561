import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import CustomerIdPopover from "./CustomerIdPopover";
import {
  LoadingIndicator,
  Button,
  FlexRow,
  Input,
  Dropdown,
  Checkbox
} from "@ufginsurance/ui-kit";
import {
  validateFirstName,
  validateLastName,
  validateCity,
  validateCustomerId,
  validateMailingAddress,
  validateState,
  validateZip,
  validateCompanyName
} from "../formValidation";
import ZipCodeInput from "./ZipCodeInput";
import { Translations } from "../../../components/translations";

const isDropdownEnabled = options => options.length > 1;

const getDefaultCanEditCustomerValue = (initialValues, isFromEditCustomer) =>
  !initialValues.existingCustomer || isFromEditCustomer;

const getRequiredFields = (
  isPilotCustomer,
  isPilotCompany,
  billingSameAsMailing
) => {
  const defaultRequiredFields = [
    "customerId",
    "companyName",
    "mailingAddress",
    "city",
    "state",
    "zip"
  ];

  if (isPilotCustomer) {
    const billingAddressFields = [
      "billingAddress",
      "billingCity",
      "billingState",
      "billingZip"
    ];
    // If agent selected billing same as mailing then we don't 'require' the fields.
    // This is because otherwise we'd need to make sure to update billing address values with
    // mailing whenever they change so that validation succeeds.
    const pilotRequiredFields = defaultRequiredFields.concat(
      billingSameAsMailing ? [] : billingAddressFields
    );

    if (isPilotCompany) {
      return pilotRequiredFields;
    }

    // Pilot Person
    return [...pilotRequiredFields, "firstName", "lastName"];
  }

  return defaultRequiredFields;
};

const canSubmitForm = ({
  isAdverseRiskCustomer,
  values,
  errors,
  isPilotCustomer,
  isPilotCompany,
  billingSameAsMailing,
  loading
}) => {
  const hasErrors = Object.keys(errors).length > 0;

  if (isAdverseRiskCustomer || hasErrors || loading) {
    return false;
  }

  return !getRequiredFields(
    isPilotCustomer,
    isPilotCompany,
    billingSameAsMailing
  ).some(x => !values?.[x]);
};

const addressesAreEqual = (values, billingAddress) => {
  if (!values || !billingAddress) {
    return false;
  }

  return (
    values.mailingAddress.trim() === billingAddress.billingAddress &&
    values.city === billingAddress.billingCity &&
    values.state === billingAddress.billingState &&
    values.zip === billingAddress.billingZip
  );
};

const CustomerForm = ({
  account,
  billingCities,
  billingStates,
  cities,
  customer,
  customersByAmsId,
  customersAsOptions,
  errors,
  existingCustomerSelected,
  handleReset,
  handleSubmit,
  hasPreviousSteps,
  initialValues,
  isAdverseRiskCustomer,
  isFromEditCustomer,
  onValidZipCodeChange,
  setValues,
  states,
  values,
  zipCodeNotFound,
  isPilotCompany,
  isPilotCustomer,
  isPilotPerson,
  loading,
  handleOnFieldValidate,
  setErrors,
  handleOnChange,
  handleOnBlur
}) => {
  const [canEditCustomer, setCanEditCustomer] = useState(
    getDefaultCanEditCustomerValue(initialValues, isFromEditCustomer)
  );
  const [billingSameAsMailing, setBillingSameAsMailing] = useState(false);
  const [hasFormTouched, setHasFormTouched] = useState(false);

  useEffect(() => {
    const nextValues = {
      ...values,
      city: cities.length === 1 ? cities[0] : null,
      state: states.length === 1 ? states[0] : null
    };
    setValues(nextValues);
    // eslint-disable-next-line
  }, [cities, states]);

  useEffect(() => {
    const nextValues = {
      ...values,
      billingCity: billingCities.length === 1 ? billingCities[0] : null,
      billingState: billingStates.length === 1 ? billingStates[0] : null
    };
    setValues(nextValues);
    // eslint-disable-next-line
  }, [billingCities, billingStates]);

  useEffect(() => {
    if (account?.additional_addresses) {
      const billingAddresses =
        account.additional_addresses
          ?.filter(a => a.address_type === "billing")
          .map(a => ({
            billingAddress: a.address1,
            billingZip: a.zip,
            billingCity: a.city,
            billingState: a.state
          })) || [];

      const billingAddress =
        billingAddresses.length > 0 ? billingAddresses[0] : undefined;
      const nextValues = {
        ...values,
        firstName: account.firstname,
        lastName: account.lastname,
        ...billingAddress,
        billingSameAsMailing: addressesAreEqual(values, billingAddress)
      };
      setValues(nextValues);
      setBillingSameAsMailing(addressesAreEqual(nextValues));
    }
    // eslint-disable-next-line
  }, [account]);

  const handleEditClick = () => {
    setCanEditCustomer(true);
  };

  const handleExistingCustomerChange = fieldName => option => {
    // react-select is sending us an empty array when user clears field. No idea why...
    const value = !!option && !Array.isArray(option) ? option : "";
    setValues({
      ...values,
      [fieldName]: value
    });
    setCanEditCustomer(value === "");

    const existingCustomer = customersByAmsId.get(value.value);
    existingCustomerSelected(existingCustomer);

    if (!existingCustomer) {
      return;
    }

    // We setValues here in addition to redux in order to fix issue
    // with submit button being disabled until user clicks off of the
    // existing customer dropdown.
    // https://ufginsurance.atlassian.net/browse/OPM-5091
    const {
      agency_management_system_id,
      business_address,
      name
    } = existingCustomer;
    setValues({
      existingCustomer: agency_management_system_id,
      customerId: agency_management_system_id,
      companyName: name,
      mailingAddress: business_address.address1,
      city: business_address.city,
      state: business_address.state,
      zip: business_address.zip
    });
  };

  const handleValidZipCodeChange = zip => {
    onValidZipCodeChange("mailing_ufg", zip);
  };

  const handleValidBillingZipCodeChange = zip => {
    onValidZipCodeChange("billing", zip);
  };

  const canSubmit = canSubmitForm({
    isAdverseRiskCustomer,
    customer,
    values,
    errors,
    isPilotCustomer,
    isPilotCompany,
    billingSameAsMailing,
    loading
  });

  useEffect(() => {
    if (values) {
      validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!hasFormTouched) {
      const requiredFields = getRequiredFields(
        isPilotCustomer,
        isPilotCompany,
        billingSameAsMailing
      );
      if (requiredFields.find(x => values?.[x])) {
        setHasFormTouched(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        return validateFirstName(value);
      case "lastName":
        return validateLastName(value);
      case "customerId":
        return validateCustomerId(
          existingCustomerIds,
          values.existingCustomer
        )(value);
      case "companyName":
        return validateCompanyName(value);
      case "billingAddress":
      case "mailingAddress":
        return validateMailingAddress(value);
      case "billingZip":
      case "zip":
        return validateZip(value);
      case "billingCity":
      case "city":
        return validateCity(value);
      case "billingState":
      case "state":
        return validateState(value);
      default:
        return null;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFields = () => {
    if (values) {
      const fields = getRequiredFields(
        isPilotCustomer,
        isPilotCompany,
        billingSameAsMailing
      );
      const currentErrors = { ...errors };
      for (const field of fields) {
        const error = validateField(field, values[field] || "");
        if (error) {
          currentErrors[field] = [error];
        } else if (currentErrors[field]) {
          currentErrors[field] = [];
        }
      }
      setErrors(currentErrors);
    }
  };

  const handleZipCodeChange = (name, zip) => {
    setValues({
      ...values,
      [name]: zip
    });
  };

  const MAILING_ADDRESS = {
    address: {
      id: "mailingAddress",
      label: isFromEditCustomer
        ? Translations.start_new_quote.customer_modal.address_label
        : Translations.start_new_quote.customer_modal.mailing_address,
      disabled: (!isFromEditCustomer && !canEditCustomer) || loading
    },
    zip: {
      id: "zip",
      disabled: !canEditCustomer || loading,
      label: Translations.start_new_quote.customer_modal.zip_code,
      handleValidateZip: handleValidZipCodeChange
    },
    city: {
      id: "city",
      label: Translations.start_new_quote.customer_modal.city,
      options: cities || [],
      disabled: !isDropdownEnabled(cities) || !canEditCustomer || loading
    },
    state: {
      id: "state",
      label: Translations.start_new_quote.customer_modal.state,
      options: states || [],
      disabled: !isDropdownEnabled(states) || !canEditCustomer || loading
    }
  };

  const BILLING_ADDRESS = {
    address: {
      id: "billingAddress",
      label: Translations.start_new_quote.customer_modal.address_label,
      disabled: values.billingSameAsMailing || loading
    },
    zip: {
      id: "billingZip",
      disabled: billingSameAsMailing || loading,
      label: Translations.start_new_quote.customer_modal.zip_code,
      handleValidateZip: handleValidBillingZipCodeChange
    },
    city: {
      id: "billingCity",
      label: Translations.start_new_quote.customer_modal.city,
      options: billingCities || [],
      disabled:
        !isDropdownEnabled(billingCities) ||
        values.billingSameAsMailing ||
        loading
    },
    state: {
      id: "billingState",
      label: Translations.start_new_quote.customer_modal.state,
      options: billingStates || [],
      disabled:
        !isDropdownEnabled(billingStates) ||
        values.billingSameAsMailing ||
        loading
    }
  };

  const renderAddress = ({ address, zip, city, state }) => {
    return (
      <div>
        <div className="row">
          <div className="col-sm-5">
            <label htmlFor={address.id} className="form-field">
              {address.label}
            </label>
            <Input
              id={address.id}
              value={values[address.id]}
              name={address.id}
              noLabel
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              disabled={address.disabled}
              onValidate={handleOnValidate}
            />
            {hasFormTouched && errors[address.id] && (
              <div>{errors[address.id]}</div>
            )}
          </div>
          <div className="col-sm-5 zipCodeWrapper">
            <label htmlFor={zip.id} className="uikit__label">
              {zip.label}
            </label>
            <ZipCodeInput
              id={zip.id}
              name={zip.id}
              field={{
                id: zip.id,
                name: zip.id,
                disabled: zip.disabled,
                validate: validateZip,
                value: values[zip.id] || ""
              }}
              form={{
                setFieldValue: handleZipCodeChange,
                setFieldTouched: () => {}
              }}
              disabled={zip.disabled}
              onValidate={validateZip}
              onValidZipCodeChange={zip.handleValidateZip}
            />
            {hasFormTouched && errors[zip.id] && <div>{errors[zip.id]}</div>}
            {zipCodeNotFound && (
              <div role="alert">
                {Translations.start_new_quote.customer_modal.zip_code_error}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5">
            <label htmlFor={city.id} className="form-field">
              {city.label}
            </label>
            <Dropdown
              id={city.id}
              name={city.id}
              noLabel
              value={values[city.id] || ""}
              options={city.options || []}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              onValidate={handleOnValidate}
              placeholder="Please Select"
              disabled={city.disabled}
            />
            {hasFormTouched && errors[city.id] && <div>{errors[city.id]}</div>}
          </div>
          <div className="col-sm-5">
            <label htmlFor={state.id} className="form-field">
              {state.label}
            </label>
            <Dropdown
              id={state.id}
              name={state.id}
              noLabel
              value={values[state.id] || ""}
              options={state.options || []}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              onValidate={handleOnValidate}
              placeholder="Please Select"
              disabled={state.disabled}
            />
            {hasFormTouched && errors[state.id] && (
              <div>{errors[state.id]}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPilotPersonName = () => {
    if (!isFromEditCustomer || !isPilotPerson) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-sm-5">
          <label htmlFor="firstName" className="form-field">
            {Translations.start_new_quote.customer_modal.first_name}
          </label>
          <Input
            id="firstName"
            className="firstName"
            name="firstName"
            noLabel
            value={values.firstName}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            disabled={loading}
            maxLength="30"
            onValidate={handleOnValidate}
          />
          {hasFormTouched && errors.firstName && <div>{errors.firstName}</div>}
        </div>
        <div className="col-sm-5">
          <label htmlFor="lastName" className="form-field">
            {Translations.start_new_quote.customer_modal.last_name}
          </label>
          <Input
            id="lastName"
            className="lastName"
            name="lastName"
            noLabel
            value={values.lastName}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            disabled={loading}
            maxLength="30"
            onValidate={handleOnValidate}
          />
          {hasFormTouched && errors.lastName && <div>{errors.lastName}</div>}
        </div>
      </div>
    );
  };

  const renderBillingAddress = () => {
    if (!isFromEditCustomer || !isPilotCustomer) {
      return null;
    }
    const { billingSameAsMailing } = values;
    const sameAsMailing = (
      <Fragment>
        {
          Translations.start_new_quote.customer_modal
            .billing_address_section_header
        }
        <span className="billing-same-as-mailing">
          <Checkbox
            id="billingSameAsMailing"
            name="billingSameAsMailing"
            label={Translations.start_new_quote.customer_modal.same_as_mailing}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.none}
            disabled={loading}
          />
        </span>
      </Fragment>
    );

    return billingSameAsMailing ? (
      <div className="col-sm-10 form-section__header">{sameAsMailing}</div>
    ) : (
      <React.Fragment>{renderAddress(BILLING_ADDRESS)}</React.Fragment>
    );
  };

  const existingCustomerIds = [...customersByAmsId.keys()];

  const handleOnValidate = () => [];

  if (!values) {
    return <></>;
  }

  return (
    <div>
      {!isFromEditCustomer && (
        <div>{Translations.start_new_quote.customer_modal.customer_note}</div>
      )}
      <div className="form">
        <div>
          {!isFromEditCustomer && (
            <div className="col-sm-5 customer-search">
              <br />
              <Dropdown
                id="existingCustomer"
                name="existingCustomer"
                isSearchable
                isClearable
                label={
                  Translations.start_new_quote.customer_modal.select_message
                }
                value={values.existingCustomer || ""}
                options={customersAsOptions || []}
                onBlur={handleOnBlur}
                onChange={handleExistingCustomerChange("existingCustomer")}
                placeholder={
                  Translations.start_new_quote.customer_modal
                    .customer_select_placeholder
                }
                onValidate={handleOnFieldValidate}
              />
            </div>
          )}
          {!canEditCustomer && (
            <div className="col-sm-5">
              <button
                className="edit-btn btn-as-link"
                onClick={handleEditClick}
              >
                {Translations.start_new_quote.edit_button}
              </button>
            </div>
          )}
        </div>
        <div>
          {!isFromEditCustomer && (
            <div className="col-sm-10 form-section__header">
              {Translations.start_new_quote.customer_modal.enter_new_customer}
            </div>
          )}
          {loading && (
            <div className="col-sm-10">
              <LoadingIndicator />
            </div>
          )}
          <div className="row">
            <div className="col-sm-5">
              <label htmlFor="customerId" className="form-field">
                {Translations.start_new_quote.customer_modal.customer_id}
              </label>
              <CustomerIdPopover />
              <Input
                noLabel
                id="customerId"
                name="customerId"
                placeholder={
                  Translations.start_new_quote.customer_modal.enter_customer_id
                }
                value={values.customerId}
                hideLabel
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                disabled={!canEditCustomer || loading}
                onValidate={handleOnValidate}
                maxLength={30}
              />
              {hasFormTouched && errors.customerId && (
                <div>{errors.customerId}</div>
              )}
            </div>
            <div className="col-sm-5">
              <label htmlFor="companyName" className="form-field">
                {Translations.start_new_quote.customer_modal.business_name}
              </label>
              <Input
                id="companyName"
                name="companyName"
                noLabel
                placeholder={
                  Translations.start_new_quote.customer_modal
                    .enter_business_name
                }
                value={values.companyName}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                disabled={!canEditCustomer || loading}
                onValidate={handleOnValidate}
                maxLength={27}
              />
              {hasFormTouched && errors.companyName && (
                <div>{errors.companyName}</div>
              )}
              {isAdverseRiskCustomer && (
                <div>{Translations.start_new_quote.adverse_risk_error}</div>
              )}
            </div>
          </div>
          {renderPilotPersonName()}
          {renderAddress(MAILING_ADDRESS)}
          {renderBillingAddress(BILLING_ADDRESS)}
        </div>
      </div>

      <FlexRow className="customer-form__button-container" align="right">
        <Button type="reset" variant="secondary" onClick={handleReset}>
          {hasPreviousSteps
            ? Translations.start_new_quote.back_button
            : Translations.start_new_quote.cancel_button}
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={!canSubmit}
          onClick={() => handleSubmit(values)}
        >
          {isFromEditCustomer
            ? Translations.start_new_quote.customer_modal
                .edit_customer_submit_button
            : Translations.start_new_quote.customer_modal.submit_button}
        </Button>
      </FlexRow>
    </div>
  );
};

CustomerForm.propTypes = {
  account: PropTypes.object,
  billingCities: PropTypes.array.isRequired,
  billingStates: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  customer: PropTypes.any,
  customersByAmsId: PropTypes.instanceOf(Map),
  customersAsOptions: PropTypes.array.isRequired,
  errors: PropTypes.object,
  existingCustomerSelected: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasPreviousSteps: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  isAdverseRiskCustomer: PropTypes.bool,
  isFromEditCustomer: PropTypes.bool,
  onValidZipCodeChange: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  zipCodeNotFound: PropTypes.bool,
  isPilotCompany: PropTypes.bool,
  isPilotCustomer: PropTypes.bool,
  isPilotPerson: PropTypes.bool,
  loading: PropTypes.bool,
  handleOnFieldValidate: PropTypes.func,
  setErrors: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func
};

export default CustomerForm;
