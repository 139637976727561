import React, { useState, useContext, useEffect } from "react";
import {
  Input,
  useForm,
  Form,
  FormGroup,
  Switch,
  Button,
  Panel,
  FlexRow
} from "@ufginsurance/ui-kit";
import { stripCurrencyChars } from "../../shared/util";
import _isEqual from "lodash/isEqual";
import OnlineQuotingContext from "../../OnlineQuotingContext";

import "../../Step2Classify.scss";

const Employee = ({
  employee,
  location,
  refreshEmployeesFromDto,
  updateValidStatus,
  removeNewClass
}) => {
  const {
    updateCoverablesPromise,
    deleteCoverablePromise,
    quoteIsUpdating,
    toastErrr,
    get
  } = useContext(OnlineQuotingContext);

  // classCode is used throughout this component as an identifier to make form inputs specific to this component
  const classCode = employee.code || employee.classCodeForClassDescription;
  const description =
    employee.description || employee.classDescriptionByClassCode;
  const locationId = location.wcmLocationFixedID;

  const fieldId = `${classCode}^${description}`.replace(/[\W_]+/g, "_");

  const defaultValues = {
    selectBy: "Class Code",
    governingLaw: "State Act",
    numberPartTimeEmployees: 0,
    partialDiseaseAdjustment: 0,
    numberFullTimeEmployees: 0
  };

  const initialValues = {
    [`${fieldId}|ifAny`]: employee?.ifAny === "Yes",
    [`${fieldId}|payroll`]: employee?.payroll
      ? employee?.payroll.toString()
      : "",
    [`${fieldId}|classCode`]: classCode,
    [`${fieldId}|description`]: description || ""
  };

  // a state to compare form value changes
  const [prevValues, setPreviousValues] = useState(initialValues);

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  // update the status of the form to disable the Continue button when form is invalid
  useEffect(() => {
    const description =
      employee.description || employee.classDescriptionByClassCode;
    updateValidStatus({
      description,
      valid: invalidFields.length > 0
    });
    // only update the status when the invalidFields value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidFields]);

  const convertEmployeeToJsonDTO = ({ values, allEmployees }) => {
    const convertedFormValues = {
      locationId: locationId.toString(),
      payroll: values[`${fieldId}|ifAny`]
        ? 0
        : Number(values[`${fieldId}|payroll`]),
      classCodeForClassDescription: values[`${fieldId}|classCode`],
      classDescriptionByClassCode: values[`${fieldId}|description`],
      ifAny: values[`${fieldId}|ifAny`] ? "Yes" : "No"
    };

    const thisEmployee = employee.fixedId
      ? { ...employee, ...convertedFormValues }
      : { ...defaultValues, ...convertedFormValues };

    const otherEmployeesAtSameLocation =
      allEmployees.filter(others => {
        return (
          others.fixedId !== thisEmployee?.fixedId &&
          Number(others.locationId) === Number(locationId)
        );
      }) || [];

    const obj = {
      locationId,
      employees: [...otherEmployeesAtSameLocation, thisEmployee]
    };

    return obj;
  };

  const saveEmployee = ({ values }) => {
    const formIsValid = values[`${fieldId}|ifAny`]
      ? values[`${fieldId}|payroll`] === "0"
      : !!values[`${fieldId}|payroll`];

    if (!quoteIsUpdating && !_isEqual(values, prevValues) && !formIsValid)
      return false;

    const allEmployees = get.employees({ locationId });

    const coverables = convertEmployeeToJsonDTO({
      values,
      employee,
      allEmployees
    });

    updateCoverablesPromise({
      coverableType: "employee",
      coverables,
      toastMessage: "Updating Class"
    })
      .then(({ data, success }) => {
        if (success) {
          // if is a new employee, then update employee with the fixedId
          // ... this newEmployeeAdded function refreshes the selected classes with the new data
          if (employee.newClass) refreshEmployeesFromDto({ data });
          else setPreviousValues(values);
        }
      })
      .catch(({ error }) =>
        toastErrr({
          action: "employee step1 > handleFormSubmit",
          description: "unable to add/update work-comp",
          error,
          payload: coverables,
          displayMessage: "Failed to update class and payroll."
        })
      );
  };

  const deleteEmployee = itemToDelete => {
    const locationId = Number(itemToDelete.locationId);
    const employeeIds = itemToDelete.id;

    deleteCoverablePromise({
      coverableType: "employee",
      locationId,
      employeeIds,
      toastMessage: "Deleting Class"
    })
      .then(({ data }) => refreshEmployeesFromDto({ data }))
      .catch(({ error }) =>
        toastErrr({
          action: "onDeleteEmployee",
          description: "delete work comp",
          misc: { itemToDelete, locationId, employeeIds },
          error,
          displayMessage: "Failed to remove class and payroll."
        })
      );
  };

  const removeEmployee = () => {
    // if is a new employee (not in DTO), then remove the employee from array
    if (employee.newClass) {
      removeNewClass({ description: employee.description });
      return false;
    }

    // else send an update to PC to remove employee
    deleteEmployee(employee);
  };

  const handleOnPayrollValidate = field => {
    const formErrors = handleOnValidate(field);

    if (!!field.value && stripCurrencyChars(field.value) > 999999999) {
      formErrors.push("Payroll cannot exceed 999,999,999.");
    }

    if (
      values[`${fieldId}|ifAny`] &&
      field.value &&
      stripCurrencyChars(field.value) === 0
    ) {
      formErrors.push(
        "Estimated Annual Payroll should be greater than $0 when 'If Any' is selected as 'No'. Please enter a valid payroll amount."
      );
    }

    if (
      !values[`${fieldId}|ifAny`] &&
      field.value &&
      stripCurrencyChars(field.value) === 0
    ) {
      formErrors.push(
        "Estimated Annual Payroll should be greater than $0 when 'If Any' is selected as 'No'. Please enter a valid payroll amount."
      );
    }

    return formErrors;
  };

  const handleOnBlurPayroll = field => {
    handleOnBlur(field);

    // save to PC if value has changed
    if (
      !!field.value &&
      stripCurrencyChars(field.value).toString() !== prevValues[field.field]
    )
      saveEmployee({ values });
  };

  const handleOnChangeIfAny = field => {
    // if switch is changed to ON, then payroll is defaulted to 0 and try saving
    const newVals = { ...values, [field.field]: field.value };
    if (field.value) {
      newVals[`${fieldId}|payroll`] = "0";
      setTimeout(() => saveEmployee({ values: newVals }));
    } else {
      newVals[`${fieldId}|payroll`] = "";
      // we don't try saving here, because payroll is empty and required now
    }

    updateForm({ values: newVals });
  };

  ///OOQ-6536 exceptions on payroll for those two classCodes 9428 and 9447 and state is WI
  const disablePayroll =
    values[`${fieldId}|ifAny`] ||
    // location state is Wisconsin and class code is 9428 or 9447
    (location.address.state === "WI" &&
      (classCode === "9428" || classCode === "9447"));

  if (!location) return <div>NO LOCATION DEFINED</div>;

  return (
    <div className="oq__employee">
      <Panel>
        <FlexRow>
          <b>
            <span className="oq__employee__class-code">
              {values[`${fieldId}|classCode`]}
            </span>{" "}
            -{" "}
            <span className="oq__employee__class-description">
              {values[`${fieldId}|description`]}
            </span>
          </b>
          <Button
            wrapperClassName="oq__employee__remove"
            isLink
            variant="tertiary"
            onClick={() => removeEmployee()}
          >
            Remove
          </Button>
        </FlexRow>
        <Form className="oq__form" context={form}>
          <FormGroup>
            <Switch
              id={`${fieldId}|ifAny`}
              name={`${fieldId}|ifAny`}
              label="If Any"
              onChange={handleOnChangeIfAny}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values[`${fieldId}|ifAny`]}
              size="50p"
            />
            {!disablePayroll && (
              <Input
                id={`${fieldId}|payroll`}
                name={`${fieldId}|payroll`}
                label="Estimated Annual Payroll"
                onChange={handleOnChange}
                onBlur={handleOnBlurPayroll}
                onValidate={handleOnPayrollValidate}
                value={values[`${fieldId}|payroll`]}
                required={!values[`${fieldId}|ifAny`]}
                numbersOnly
                mask="currency"
                stripMaskFromValue
                size="50p"
              />
            )}
          </FormGroup>
        </Form>
      </Panel>
    </div>
  );
};

export default Employee;
