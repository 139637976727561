import { createSlice } from "@reduxjs/toolkit";

export const agencyProfileSlice = createSlice({
  name: "agencyProfile",
  initialState: {
    manifest: {
      version: ""
    },
    fetchManifestError: false
  },
  reducers: {
    succeeded: (state, action) => {
      state.manifest = action.payload;
      state.fetchManifestError = false;
    },
    failed: state => {
      state.manifest = { version: "" };
      state.fetchManifestError = true;
    }
  }
});

const { succeeded, failed } = agencyProfileSlice.actions;

export const fetchAgencyProfileManifest = () => dispatch => {
  fetch(
    `${
      window.env.REACT_APP_UFGDIGITAL_MFE_URL
    }/agency-profile/manifest.json?version=${new Date().getTime()}`
  )
    .then(response => {
      response
        .json()
        .then(data => {
          if (data?.version) {
            dispatch(succeeded(data));
          }
        })
        .catch(() => {
          dispatch(failed());
        });
    })
    .catch(() => {
      dispatch(failed());
    });
};

export default agencyProfileSlice.reducer;
