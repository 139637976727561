import { useEffect } from "react";
import { findTableOfContentsItemById } from "../../selectors";
import { isHeaderType, isSubHeader1Type } from "../../constants";
import { useCurrentRouteMatch } from "../hooks";

export const useDefaultSubheaderRouteValidation = ({
  selectedProduct,
  defaultSelectedSubheader,
  previousTableOfContentsProductId,
  tableOfContents,
  onHeaderClick,
  onSubHeaderClick
}) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteSubheaderId = currentMatch?.params?.subheaderId;
  const currentRouteProductId = currentMatch?.params?.productId;

  useEffect(() => {
    const isProductSelectionRouteWithDefaultSubheader =
      defaultSelectedSubheader &&
      !currentRouteSubheaderId &&
      currentRouteProductId === selectedProduct?.id &&
      currentRouteProductId === previousTableOfContentsProductId;
    if (isProductSelectionRouteWithDefaultSubheader) {
      const matchedDefaultTableOfContentItem = findTableOfContentsItemById(
        tableOfContents,
        defaultSelectedSubheader.id
      );
      if (matchedDefaultTableOfContentItem) {
        if (isHeaderType(defaultSelectedSubheader)) {
          onHeaderClick(defaultSelectedSubheader);
        } else if (isSubHeader1Type(defaultSelectedSubheader)) {
          onSubHeaderClick(defaultSelectedSubheader);
        }
      }
    }
  }, [
    defaultSelectedSubheader,
    currentRouteSubheaderId,
    currentRouteProductId,
    selectedProduct,
    tableOfContents,
    onHeaderClick,
    onSubHeaderClick,
    previousTableOfContentsProductId
  ]);

  return null;
};
