// TODO: separate and move the CreditsDebits components into a new CreditsDebits subfolder for better organization
import React from "react";
import { FormGroup, Select } from "@ufginsurance/ui-kit";
import { dividendPlanTypes } from "../../shared/constants";

const DividendFields = ({ primaryLocationState, lob, form }) => {
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const availablePlanType = () => {
    return dividendPlanTypes.map(x => ({
      label: x.label,
      value: x.value
    }));
  };

  return (
    <>
      <FormGroup>
        {primaryLocationState === "WI" && (
          <p>
            Wisconsin policies can receive dividends but not direct credits and
            debits.
          </p>
        )}
        <Select
          label="Dividend Plan Type"
          placeholder="Select"
          id={lob + "DividendPlanType"}
          name={lob + "DividendPlanType"}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values[lob + "DividendPlanType"]}
          options={availablePlanType() || []}
          isClearable
          size="lg"
          required
        />
      </FormGroup>
    </>
  );
};

export default DividendFields;
