import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../components/translations";
import { Button, FlexRow, Modal } from "@ufginsurance/ui-kit";

const EasyViewModal = ({ onClose, show }) => {
  return (
    <div>
      <Modal
        title="Easy-to-View Format"
        show={show}
        onHide={onClose}
        body={
          <div className={"easy-to-view-note"}>
            {Translations.account_overview.currentPolicy.easy_to_view_modal}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button variant="secondary" onClick={onClose}>
              {Translations.account_overview.currentPolicy.close}
            </Button>
          </FlexRow>
        }
      />
    </div>
  );
};

EasyViewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EasyViewModal;
