import React, { useState, useEffect } from "react";
import ServiceCenterForm from "./ServiceCenterForm";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import { Alert } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";

const ServiceCenterSummary = ({
  hasServiceCenterAccess,
  selectedAgencyCode,
  commercialDateList,
  personalDateList,
  fetchCommercialDateListDetails,
  fetchPersonalDateListDetails,
  fetchServiceCenterSummary,
  is109015Agent,
  producerHasServiceCenterAccess
}) => {
  const [viewDocumentError, setViewDocumentError] = useState(null);

  useEffect(() => {
    if (
      fetchCommercialDateListDetails &&
      fetchCommercialDateListDetails &&
      selectedAgencyCode &&
      producerHasServiceCenterAccess
    ) {
      fetchCommercialDateListDetails(selectedAgencyCode);
      fetchPersonalDateListDetails(selectedAgencyCode);
    }
  }, [
    fetchCommercialDateListDetails,
    fetchPersonalDateListDetails,
    selectedAgencyCode,
    producerHasServiceCenterAccess
  ]);

  const handleSubmit = (values, resetForm) => {
    setViewDocumentError(null);
    fetchServiceCenterSummary(
      values.selectLine,
      selectedAgencyCode,
      values.reportDate
    ).then(
      () => {
        resetForm();
      },
      () => {
        setViewDocumentError(<HelpDeskMessage display="anErrorOccurred" />);
      }
    );
  };

  return (
    <>
      <div className="reports-above-form-message">
        <HelpDeskMessage display="questionsOrAssistance" />
      </div>
      <div>
        {selectedAgencyCode && selectedAgencyCode === "109015" ? (
          <div className="agency-error-message">
            {Translations.reports.internal_user}
          </div>
        ) : hasServiceCenterAccess && producerHasServiceCenterAccess ? (
          <div>
            {viewDocumentError && (
              <div className="reports-above-form-message">
                <Alert type="error">{viewDocumentError}</Alert>
              </div>
            )}
            <ServiceCenterForm
              onSubmit={handleSubmit}
              commercialDateList={commercialDateList}
              personalDateList={personalDateList}
            />
          </div>
        ) : (
          <div className="agency-error-message">
            {is109015Agent
              ? Translations.reports.tabAgency.serviceCenterError
              : Translations.reports.tabAgency.serviceCenterErrorMessage}
          </div>
        )}
      </div>
    </>
  );
};

ServiceCenterSummary.propTypes = {
  hasServiceCenterAccess: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  commercialDateList: PropTypes.array,
  personalDateList: PropTypes.array,
  fetchCommercialDateListDetails: PropTypes.func.isRequired,
  fetchPersonalDateListDetails: PropTypes.func.isRequired,
  fetchServiceCenterSummary: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool,
  producerHasServiceCenterAccess: PropTypes.bool
};
export default ServiceCenterSummary;
