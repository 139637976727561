import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from "react";
import * as api from "../../../services/onlineQuotingService";
import { v4 } from "uuid";

import {
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table,
  LoadingIndicator,
  FlexRow,
  Panel
} from "@ufginsurance/ui-kit";
import { sortByProperties } from "../../shared/util";
import Employee from "./Employee";
import OnlineQuotingContext from "../../OnlineQuotingContext";

import "../../Step2Classify.scss";
import "./employee.scss";

const _tableColumns = [
  { key: "code", label: "Code" },
  {
    key: "description",
    label: "Description",
    className: "oq__classes__unselected__description"
  }
];

const _tableColumnsRecommanded = [
  { key: "code" },
  {
    key: "description",
    className: "oq__classes__unselected__description"
  }
];

const EmployeesManage = ({ location, onCancel }) => {
  const { get, quoteIsUpdating, toastErrr, supportingData } =
    useContext(OnlineQuotingContext);

  const locationId = location.wcmLocationFixedID;

  const [classData, setClassData] = useState();
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classesValidState, setClassesValidState] = useState({});
  const [recommendedClasses, setRecommendedClasses] = useState([]);

  const addressState = location.address.state;
  const bopCode = supportingData?.classCode?.code;

  //load class data at render
  useEffect(() => {
    if (addressState && !classData) {
      api
        .getEmployeeClassCodes(addressState, bopCode)
        .then(result => {
          const allClasses =
            (result?.data || []).map(c => ({ id: v4(), ...c })) || [];
          setClassData(allClasses);

          const startingSelectedClasses = get.employees({ locationId });
          setSelectedClasses(startingSelectedClasses);
          setFilteredClasses(allClasses);
          setRecommendedClasses(
            allClasses.filter(
              r =>
                !!r.recommended &&
                !startingSelectedClasses.some(
                  s =>
                    r.description === s.classDescriptionByClassCode ||
                    r.description === s.description
                )
            )
          );
        })
        .catch(error => {
          //alert("Class codes metadata failure:" + error.response);
          toastErrr({
            displayMessage: `An error occurred.  Unable to retreive employee class codes.`,
            action: "load class data",
            error,
            description: "api load of class data in employee class codes"
          });
          console.error(
            "Class  codes metadata failure:" + JSON.stringify(error)
          );
        });
    }
  }, [addressState, classData, toastErrr, bopCode, get, locationId]);

  const form = useForm({
    values: {
      search: ""
    },
    onSubmit: () => {}
  });
  const { handleOnBlur, handleOnValidate, values, handleOnChange } = form;

  const refreshEmployeesFromDto = ({ data }) => {
    const allDtoEmployeesAtLocation = get.employees({ data, locationId });
    const newEmployees = (selectedClasses || []).filter(c => c.newClass);

    setSelectedClasses([
      ...allDtoEmployeesAtLocation,
      // add new employees, but remove any that match the description in ones that exist in the DTO
      // typically, that duplicate exists when adding a new employee/class
      ...newEmployees.filter(
        c =>
          !allDtoEmployeesAtLocation.some(
            e => e.classDescriptionByClassCode === c.description
          )
      )
    ]);
  };

  const addEmployee = useCallback(
    newClass => {
      setSelectedClasses([...selectedClasses, newClass]);
    },
    [selectedClasses]
  );

  const removeNewClass = ({ description }) => {
    const updatedClasses = (selectedClasses || []).filter(
      c => !(c.newClass && c.description === description)
    );
    setSelectedClasses(updatedClasses);
  };

  const updateValidStatus = ({ description, valid }) => {
    setClassesValidState({ ...classesValidState, [description]: valid });
  };

  const someClassHasInvalidState = () =>
    Object.keys(classesValidState).some(c => classesValidState[c]);

  // configure the table columns for the main table and the selected row that
  // shows at the top of the modal
  const selectClassButton = useCallback(
    row => {
      return (
        <Button
          wrapperClassName="oq__add-button__small"
          onClick={() => addEmployee({ ...row, newClass: true })}
          size="sm"
          variant="info"
          icon="farPlusCircle"
        >
          Add
        </Button>
      );
    },
    [addEmployee]
  );

  const AddColumn = useMemo(
    () => ({
      key: "",
      label: "",
      element: selectClassButton,
      sortable: false,
      align: "right"
    }),
    [selectClassButton]
  );

  const tableColumns = useMemo(
    () => [..._tableColumns, AddColumn],
    [AddColumn]
  );
  const tableColumnsCommended = [..._tableColumnsRecommanded, AddColumn];

  // update the filtered class list when...
  // 1. the users searches
  // 2. the user adds or removes a class
  useEffect(() => {
    const searchText = values.search;

    const filteredData = (classData || []).filter(c => {
      // s defaults to "true" if there's no searchText
      // if there is searchText, then default is false...
      // and we'll set it to true if anything matches in the tableColumns loop below
      let s = !searchText;

      // use the table-columns definition to compare the searchText with data actually visible in the table
      if (!!searchText) {
        tableColumns.forEach(col => {
          const columnKey = col?.key;
          if (
            !!columnKey &&
            (c?.[columnKey] || "")
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
            s = true;
        });
      }

      const sel = selectedClasses.some(
        e =>
          e.classDescriptionByClassCode === c.description ||
          e.description === c.description
      );

      return s && !sel;
    });

    const recommClasses = (classData || []).filter(
      r =>
        !!r.recommended &&
        !selectedClasses.some(
          s =>
            r.description === s.classDescriptionByClassCode ||
            r.description === s.description
        )
    );

    setRecommendedClasses(recommClasses);
    setFilteredClasses(filteredData);
  }, [classData, selectedClasses, tableColumns, values.search]);

  return (
    <div>
      <div>
        <div className="oq__employees__selected">
          {selectedClasses
            .sort(
              sortByProperties([
                { field: "locationId", asc: true },
                { field: "fixedId", asc: true }
              ])
            )
            .map(e => {
              return (
                <Employee
                  location={location}
                  key={e.id}
                  employee={e}
                  refreshEmployeesFromDto={refreshEmployeesFromDto}
                  updateValidStatus={updateValidStatus}
                  removeNewClass={removeNewClass}
                />
              );
            })}
        </div>
        <div className="oq__classes__workcomp">
          {!classData && <LoadingIndicator message="Loading Classes" />}
          <Form className="oq__classes__form" context={form}>
            {classData && (
              <>
                {!!recommendedClasses.length && (
                  <FormGroup key="allClassCodesGrpRecommanded">
                    <Panel
                      title={`Recommended based on your selected classification: ${supportingData?.classCode?.description}`}
                    >
                      <Table
                        rowKey="id"
                        className="oq__employee__classes-table oq__class-table"
                        columns={tableColumnsCommended}
                        data={recommendedClasses}
                        showPagination
                        itemsPerPage={5}
                        noResultsMessage="No results found"
                        initialSort="code"
                      />
                    </Panel>
                  </FormGroup>
                )}
                <FormGroup
                  key="searchFields"
                  className="oq__classes__searchRow addPadUnder"
                  noOuterPadding
                >
                  <Input
                    id="search"
                    name="search"
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    label="Search Class or Description"
                    onChange={handleOnChange}
                    value={values.search}
                    className="oq__classes__search"
                    size="auto"
                  />
                </FormGroup>
                <FormGroup key="allClassCodesGrp">
                  <Table
                    rowKey="id"
                    className="oq__employee__classes-table oq__class-table"
                    columns={tableColumns}
                    data={filteredClasses}
                    showPagination
                    itemsPerPage={5}
                    noResultsMessage="No results found"
                    initialSort="code"
                  />
                </FormGroup>
              </>
            )}
          </Form>
        </div>
      </div>
      <FlexRow align="right">
        <Button
          variant="plain"
          onClick={() => onCancel()}
          disabled={quoteIsUpdating}
          className="cancel"
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          className="continue"
          disabled={quoteIsUpdating || someClassHasInvalidState()}
          onClick={() => onCancel()}
        >
          Continue
        </Button>
      </FlexRow>
    </div>
  );
};

export default EmployeesManage;
