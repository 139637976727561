import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { processAgencySweepPayment } from "../payment-confirmation/agencySweepPaymentConfirmationAction";
import VerifyPayment from "./VerifyPayment";
import * as completePaymentSelector from "../complete-payment/completePaymentSelector";
import * as verifyPaymentSelector from "../verify-payment/verifyPaymentSelector";
import * as paymentConfirmationSelector from "../payment-confirmation/paymentConfirmationSelector";

const mapStateToProps = state => ({
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  agentFullName: sessionSelector.getFirstAndLastName(state),
  agentCode: sessionSelector.getAgentCode(state),
  hasDirectBillPayNowEnrollment: completePaymentSelector.hasDirectBillPayNowEnrollment(
    state
  ),
  directBillPaymentMethodToken: completePaymentSelector.getDirectBillPaymentMethodToken(
    state
  ),
  isOasisAccount: completePaymentSelector.isOasisAccount(state),
  agentEmail: sessionSelector.getEmail(state),
  agencySweepPaymentInfo: verifyPaymentSelector.getPaymentInformation(state),
  paymentDetailsLoading: paymentConfirmationSelector.getPaymentDetailsLoading(
    state
  ),
  paymentDetailsResponseStatusCode: paymentConfirmationSelector.getPaymentDetailsResponseStatusCode(
    state
  )
});

const mapDispatchToprops = {
  processAgencySweepPayment
};

export default connect(mapStateToProps, mapDispatchToprops)(VerifyPayment);
