import React, { useState, useContext } from "react";
import classnames from "classnames";
import { Button } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import CreditsDebitsModal from "./CreditsDebitsModal";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  modifierPath,
  statesWorkCompModifierTypes
} from "../../shared/constants";
import _get from "lodash/get";

const CreditsDebits = ({ showAsLink }) => {
  const { quoteData, get } = useContext(OnlineQuotingContext);

  const { proQuoteWorkCompCreditsEnabled } = useFlags();

  const [showModal, setShowModal] = useState(false);

  const lobwithCredits = ["bp7BusinessOwners", "ca7CommAuto"];

  const primaryLocation = get.location({ isPrimary: true });
  const primaryLocationState = primaryLocation?.address?.state;

  // ADD WORK COMP IF LAUNCH DARKLY ALLOWS IT
  if (proQuoteWorkCompCreditsEnabled) {
    // ADD WORK COMP IF STATE SUPPORTS IT
    if (
      statesWorkCompModifierTypes[primaryLocationState] &&
      statesWorkCompModifierTypes[primaryLocationState] !== "none"
    )
      lobwithCredits.push("wcmWorkersComp");
  }

  // TODO: only do WC for these states

  const selectedProducts = Object.keys(quoteData?.lobData).filter(x =>
    lobwithCredits.includes(x)
  );

  const editMode = selectedProducts.some(
    lob =>
      _get(quoteData, modifierPath(lob), {})?.totalModifier !== 0 ||
      _get(quoteData, modifierPath(lob + "-Dividend"), {})?.planApplies ===
        "Yes"
  );

  return (
    <>
      <Button
        isLink={showAsLink}
        inline={showAsLink}
        wrapperClassName={classnames({
          "oq__button__full-width": !showAsLink,
          "oq__credits-button": showAsLink
        })}
        className={classnames({
          "oq__credits-button__align-right": showAsLink
        })}
        onClick={() => setShowModal(!showModal)}
      >
        {editMode ? "Edit" : "Apply"} Credits or Debits
      </Button>

      {showModal && (
        <CreditsDebitsModal
          setShowModal={setShowModal}
          selectedProducts={selectedProducts}
          primaryLocationState={primaryLocationState}
          editMode={editMode}
        />
      )}
    </>
  );
};
export default CreditsDebits;
