import React, { useEffect } from "react";
import PropTypes from "prop-types";
import NewsMessage from "./newsMessage";
import { Table, Modal } from "@ufginsurance/ui-kit";

const NewsArchives = props => {
  const {
    newsArchive,
    onClose,
    agentState,
    fetchNewsArchives,
    activeSpecialtyCompanyBranch
  } = props;

  const getColumns = () => [
    {
      key: "entry_text",
      label: "",
      element: row => (
        <div className="news-archives__message">
          <NewsMessage news={row} />
        </div>
      )
    }
  ];

  useEffect(() => {
    fetchNewsArchives(agentState, activeSpecialtyCompanyBranch);
  }, [activeSpecialtyCompanyBranch, agentState, fetchNewsArchives]);

  return (
    <div className="News">
      <Modal
        show
        onHide={onClose}
        title="ufgNews Archives"
        body={
          <div className="news-archive-table">
            {newsArchive && newsArchive.length > 0 && (
              <Table
                className="no-th"
                rowKey="entry_id_number"
                data={newsArchive}
                columns={getColumns()}
                itemsPerPage={15}
                showPagination
              />
            )}
            <br className="clearMe" />
          </div>
        }
      />
    </div>
  );
};

NewsArchives.propTypes = {
  fetchNewsArchives: PropTypes.func.isRequired,
  newsArchive: PropTypes.array.isRequired,
  agentState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  activeSpecialtyCompanyBranch: PropTypes.string
};

export default NewsArchives;
