import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Translations } from "../../translations";
import { DATE_FORMAT } from "../../../claims/services/claimsUtil";
import {
  Currency,
  Table,
  Icon,
  Alert,
  LoadingIndicator,
  Anchor
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";

const ClaimPaymentsTable = ({
  removeClaimPayment,
  agencyCode,
  claimPaymentsList,
  claimPaymentsError,
  claimPaymentDeleteError,
  is109015Agent,
  claimPaymentsLoading
}) => {
  const history = useHistory();

  const [showAlert, setShowAlert] = useState(true);

  const onRemoveClaimPayment = paymentNumber => () => {
    setShowAlert(true);
    removeClaimPayment(
      agencyCode,
      moment().format("YYYY-MM-DD"),
      paymentNumber
    );
  };

  const handleDismissAlert = () => {
    setShowAlert(false);
  };

  const columns = [
    {
      label: Translations.claims_tab.policy_holder,
      key: "insurance_name"
    },
    {
      label: Translations.claims_tab.claim_number,
      key: "claim_number",
      element: row => {
        return (
          <Anchor
            onClick={() => {
              history.push(
                `${routes.CLAIM_INFORMATION}?claim_number=${row.claim_number}`
              );
            }}
          >
            {row.claim_number}
          </Anchor>
        );
      }
    },
    {
      label: Translations.claims_tab.policy_number,
      key: "",
      element: row => {
        const policyNumber = (row.policy_prefix || "") + row.policy_number;
        return (
          <Anchor
            onClick={() => {
              history.push(`${routes.ACCOUNT_HOME}/${policyNumber}`);
            }}
          >
            {policyNumber}
          </Anchor>
        );
      }
    },
    {
      label: Translations.claims_tab.amount,
      key: "payment_amount",
      element: row => <Currency amount={row.payment_amount} />
    },
    {
      label: Translations.claims_tab.remove,
      key: "remove",
      align: "center",
      element: row => (
        <>
          {!is109015Agent && (
            <Icon
              icon="fasTimesCircle"
              onClick={onRemoveClaimPayment(row.payment_number)}
            />
          )}
        </>
      )
    }
  ];

  const hasClaims = claimPaymentsList && claimPaymentsList.length > 0;
  return (
    <div>
      <div className="claim-status-heading">
        <b>{Translations.claims_tab.header_payments}</b>
      </div>
      {!claimPaymentsLoading && (!hasClaims || claimPaymentsError) && (
        <div className="friendly-error-messaging">
          {Translations.claims_tab.claim_payment_fail_error_message}
        </div>
      )}
      {claimPaymentDeleteError && showAlert && (
        <div className="row">
          <Alert type="error" onDismiss={handleDismissAlert}>
            {claimPaymentDeleteError}
          </Alert>
          &nbsp;
        </div>
      )}
      {claimPaymentsLoading ? (
        <LoadingIndicator />
      ) : (
        hasClaims && (
          <Table
            rowKey="id"
            groupHeaderKey="payment_date"
            groupHeaderContents={row => (
              <>
                {Translations.claims_tab.payment_date}{" "}
                {moment(row.payment_date).format(DATE_FORMAT)}
              </>
            )}
            columns={columns}
            data={claimPaymentsList}
            showPagination
            itemsPerPage={20}
            className="claims-status-table"
          />
        )
      )}
    </div>
  );
};

ClaimPaymentsTable.propTypes = {
  agencyCode: PropTypes.string,
  claimPaymentsList: PropTypes.array,
  removeClaimPayment: PropTypes.func.isRequired,
  claimPaymentsError: PropTypes.string,
  claimPaymentDeleteError: PropTypes.string,
  is109015Agent: PropTypes.bool,
  claimPaymentsLoading: PropTypes.bool.isRequired
};

export default ClaimPaymentsTable;
