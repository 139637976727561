import React from "react";
import { ToolTip, Icon } from "@ufginsurance/ui-kit";

// https://ufginsurance.atlassian.net/browse/OOQ-15133

export const CA7CombinedSingleLimitText1 = (
  field,
  quoteData,
  supportingData
) => {
  if (
    quoteData?.lobData?.cuCommercialUmbrella ||
    supportingData?.packages?.umbrella?.addWhenRating
  )
    field.labelElement = (
      <ToolTip
        className="oq__coverageUiOverride"
        nowrap={false}
        width={400}
        variant="white"
        content={
          <span>
            Underlying Uninsured Motorists Combined Single Limit must be at
            least $500,000 to eligible to be covered on Commercial Umbrella.
          </span>
        }
        trigger={<Icon aria-hidden="false" size="lg" icon="fasInfoCircle" />}
      />
    );

  return field;
};
