import React from "react";
import { Button } from "@ufginsurance/ui-kit";
import { appetiteGuideLink } from "../shared/constants";

const AppetiteGuideLink = () => {
  return (
    <>
      <div className="oq__sidebar__summary__item">
        <b>Appetite Guide:</b>

        <Button
          isLink
          inline
          variant="info"
          onClick={() => window.open(appetiteGuideLink, "_blank")}
        >
          View Appetite Guide
        </Button>
      </div>
    </>
  );
};

export default AppetiteGuideLink;
