/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { Modal } from "@ufginsurance/ui-kit";
import { updateCoverageValue } from "../shared/coveragePanels";
import FormSetup from "../shared/FormSetup";
import CoverageSearch from "./CoverageSearch";
import OnlineQuotingContext from "../OnlineQuotingContext";

const ProductCoverages = ({
  panels,
  allCoverages,
  selectedCoverageTitle,
  saveCoverages,
  updateFormStatus,
  formId,
  contentAfterCoverages,
  searchTableRowKey,
  coverageUpdating,
  className
}) => {
  const { toastErrr } = useContext(OnlineQuotingContext);

  const [showCoverageSearch, setShowCoverageSearch] = useState(false);

  const addCoverageAndSave = coverage => {
    coverage.selected = true;
    coverage.updated = true;
    saveCoverages(coverage);
  };

  const localUpdateCoverageValue = (fieldName, value) => {
    updateCoverageValue({
      fieldName,
      value,
      allCoverages,
      toastErrr,
      saveCoverages
    });
  };
  return (
    <div className="oq__product-coverages">
      <>
        {showCoverageSearch && (
          <Modal
            title={`${selectedCoverageTitle} Coverage`}
            show={showCoverageSearch}
            onHide={() => {
              setShowCoverageSearch(false);
            }}
            body={
              <CoverageSearch
                unselectedCoverages={allCoverages.filter(
                  c => c.selected === false
                )}
                formTitle={`${selectedCoverageTitle} Coverage`}
                onAddCoverage={addCoverageAndSave}
              />
            }
          />
        )}
        {panels && panels.length > 0 && (
          <FormSetup
            sectionHeader=""
            className={className}
            panels={panels}
            handleFormSubmit={() => {}}
            submitBtnLabel=""
            saveCurrentValues={localUpdateCoverageValue}
            hideSubmitBtn
            useCoveragePanel
            updateFormStatus={updateFormStatus}
            formId={formId}
            coverageUpdating={coverageUpdating}
          />
        )}
        {contentAfterCoverages}
        <div className="oq__policy-info__coverages">
          <CoverageSearch
            unselectedCoverages={allCoverages.filter(c => c.selected === false)}
            formTitle={`${selectedCoverageTitle} Coverage`}
            onAddCoverage={addCoverageAndSave}
            rowKey={searchTableRowKey}
          />
        </div>
      </>
    </div>
  );
  //TODO put the search coverages table here instead of CoverageDataSection
  // combine coverages not selected and additionalCoverages
};

export default ProductCoverages;
