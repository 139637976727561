import React, { useEffect, useState, useContext } from "react";
import {
  LoadingIndicator,
  Button,
  useForm,
  Form,
  FormGroup,
  Input,
  Select
} from "@ufginsurance/ui-kit";
import * as api from "../../../services/onlineQuotingService";
import { toTitleCase } from "../../../components/Factory.js";
import { addressLineValidate, zipCodeValidate, zipCodePattern } from "../util";
import VerifyAddress from "../../../shared/components/AddressValidate/VerifyAddressSingle.js";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const ERROR_UPDATING_SCHEDITEM_CONTACT =
  "Sorry, an error occurred.  We were unable to save the changes to the contact.";

const ScheduleItemContact = ({
  selectedContact,
  setSelectedContact,
  updateScheduleItemFromContactEditor
}) => {
  const { getQuotePart, toastErrr, showUpdatingToast, closeUpdatingToast } =
    useContext(OnlineQuotingContext);

  const [availableCities, setAvailableCities] = useState([]);
  const [availableStates, setAvailableStates] = useState([]);
  const [contactToEdit, setContactToEdit] = useState();
  const [readyToContinue, setReadyToContinue] = useState(true);
  const [zipSearching, setZipSearching] = useState(false);
  const [addressToVerify, setAddressToVerify] = useState();

  const contactId = selectedContact?.id || null;
  const accountId = getQuotePart("baseData.accountNumber");

  const initialValues = {
    contactName: "",
    firstName: "",
    lastName: "",
    subtype: "Company",
    addressLine1: "",
    zip: "",
    city: "",
    county: "",
    countyCode_UFG: "",
    country: "US",
    state: "",
    publicID: ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  const {
    values,
    updateForm,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields
  } = form;

  // IF EDIT, get contact details at load time
  useEffect(() => {
    if (!!selectedContact?.id && !contactToEdit) {
      api
        .getScheduleItemContact({ accountId, contactId: selectedContact?.id })
        .then(response => {
          const c = response.data;
          setContactToEdit(c);
          updateForm({
            values: {
              contactName: c.contactName,
              firstName: c.firstName,
              lastName: c.lastName,
              subtype: c.subtype,
              addressLine1: c.primaryAddress.addressLine1,
              zip: c.primaryAddress.postalCode,
              city: c.primaryAddress.city,
              county: c.primaryAddress.county,
              countyCode_UFG: "",
              country: "US",
              state: c.primaryAddress.state,
              publicID: c.publicID
            }
          });
          setAvailableStates([c.primaryAddress.state]);
        });
    }
  }, [accountId, contactToEdit, selectedContact, updateForm]);

  // function triggered to start the address validation process
  // setting the state of the address kicks it off
  const validateAddress = () => {
    setAddressToVerify({
      addressLine1: values.addressLine1,
      city: values.city,
      state: values.state,
      zip: values.zip
    });
  };

  // function used by address validation...
  // to update the address fields to the options chosen by the address validation
  const updateAddressFormFields = address => {
    const _address = {
      addressLine1: toTitleCase(address.addressFields.address1),
      city: toTitleCase(address.addressFields.city),
      state: address.addressFields.state,
      postalCode: address.addressFields.zip,
      county: address.addressFields.county,
      countyCode: address.addressFields.countyCode
    };
    updateForm({
      values: { address: _address }
    });

    handleFormSubmit({ address: _address });
  };
  // cancelling closes the contact modal
  const handleCancel = () => setSelectedContact(null);

  // ADD/UPDATE Contact
  const handleFormSubmit = ({ address }) => {
    const payload = {
      subtype: values.subtype,
      primaryAddress: {
        state: address.state,
        country: "US",
        addressLine1: address.addressLine1,
        city: address.city,
        postalCode: address.postalCode,
        county: address.county,
        countyCode_UFG: address.countyCode
      }
    };

    if (values.subtype === "Company") {
      payload.contactName = values.contactName;
    } else {
      payload.firstName = values.firstName;
      payload.lastName = values.lastName;
    }

    // disable Continue button
    setReadyToContinue(false);
    const toastId = Math.random();
    showUpdatingToast({
      message: `${contactId ? "Updating" : "Adding"} Contact`,
      toastId
    });

    if (!contactId) {
      // no contactId means we're adding a contact
      api
        .addScheduleItemContact({ accountId, payload })
        .then(response => {
          setSelectedContact(null);
          updateScheduleItemFromContactEditor({ contact: response.data });
        })
        .catch(error =>
          toastErrr({
            action: "addScheduleItemContact",
            description: "unable to save SchedItem field",
            error,
            payload,
            displayMessage: ERROR_UPDATING_SCHEDITEM_CONTACT
          })
        )
        .finally(() => closeUpdatingToast({ toastId }));
    } else {
      // we're editing an existing contact
      api
        .updateContact({ accountId, contactId, payload })
        .then(response => {
          setSelectedContact(null);
          updateScheduleItemFromContactEditor({ contact: response.data });
        })
        .catch(error =>
          toastErrr({
            action: "updateContact",
            misc: { accountId, contactId },
            description: "unable to save SchedItem field",
            error,
            payload,
            displayMessage: ERROR_UPDATING_SCHEDITEM_CONTACT
          })
        )
        .finally(() => closeUpdatingToast({ toastId }));
    }
  };

  // validation for address field
  const handleStreetValidate = field => {
    const fieldErrors = handleOnValidate(field);

    const addressLineCheck = addressLineValidate(field.value);
    if (addressLineCheck) fieldErrors.push(addressLineCheck);

    return fieldErrors;
  };

  const handleZipValidate = field => {
    const fieldErrors = handleOnValidate(field);

    const zipErrorCheck = zipCodeValidate(field.value);
    if (zipErrorCheck) fieldErrors.push(zipErrorCheck);

    return fieldErrors;
  };

  const handleZipOnChange = ({ value }) => {
    // reset city/state along with updating zip
    form.updateForm({
      values: {
        zip: value,
        county: "",
        countyCode_UFG: "",
        city: "",
        state: ""
      }
    });

    if (zipCodePattern.test(value)) {
      setZipSearching(true);
      api
        .searchLocaleByZipCodeV2(value)
        .then(result => {
          if (result?.data && !!result?.data.length) {
            // set city dropdown options
            setAvailableCities(
              (result?.data || []).map(d => {
                const city = toTitleCase(d.city);
                return { value: city, label: city };
              })
            );

            setAvailableStates(
              (result?.data || []).map(d => {
                return {
                  label: d.state,
                  value: d.state
                };
              })
            );

            const zipData = result?.data[0];

            // set city & state values
            form.updateForm({
              values: {
                zip: value,
                city: toTitleCase(zipData?.city),
                state: zipData?.state
              },
              errors: {
                zip: [],
                city: [],
                state: []
              }
            });
          }
        })
        .finally(() => {
          setZipSearching(false);

          // after running the zip code lookup... set the zip code field to be focused
          document
            .querySelector(".oq__form__scheduleitem__contact #zip")
            .focus();
        });
    }
  };

  // if we're editing a contact, show loading indicator until the contact data is ready
  if (contactId && !contactToEdit) return <LoadingIndicator />;

  return (
    <div>
      <Form
        className="oq__form oq__form__scheduleitem__contact oq-forms"
        context={form}
      >
        <FormGroup>
          <Select
            id="subtype"
            name="subtype"
            label="Contact Type"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.subtype}
            options={[
              { value: "Company", label: "Company" },
              { value: "Person", label: "Person" }
            ]}
            disabled={!!contactId}
          />
        </FormGroup>
        <FormGroup>
          {values.subtype === "Company" && (
            <Input
              id="contactName"
              name="contactName"
              label="Business Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.contactName}
              required
              className="grow"
              size="auto"
              maxLength={60}
            />
          )}
          {values.subtype === "Person" && (
            <>
              <Input
                id="firstName"
                name="firstName"
                label="First Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.firstName}
                required
                className="grow"
                maxLength={30}
              />
              <Input
                id="lastName"
                name="lastName"
                label="Last Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.lastName}
                required
                className="grow"
                maxLength={30}
              />
            </>
          )}
        </FormGroup>
        <FormGroup className="oq__schedule-item__address">
          <Input
            id="addressLine1"
            name="addressLine1"
            label="Address"
            placeholder="123 Main Street"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleStreetValidate}
            value={values.addressLine1}
            required
            className="grow address"
            disabled={!!addressToVerify}
            maxLength={60}
          />
        </FormGroup>
        <FormGroup groupErrors className="oq__schedule-item__address2">
          <div className="oq__input-with-spinner">
            {zipSearching && (
              <LoadingIndicator
                className="oq__input__spinner"
                type="spinner"
                message=""
              />
            )}
            <Input
              id="zip"
              name="zip"
              label="Zip"
              placeholder="12345-1234"
              onChange={handleZipOnChange}
              onBlur={handleOnBlur}
              onValidate={handleZipValidate}
              value={values.zip}
              required
              className="zip"
              disabled={zipSearching || !!addressToVerify}
            />
          </div>
          <Select
            id="city"
            name="city"
            label="City"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.city}
            required
            options={
              availableCities.length > 0
                ? availableCities
                : [
                    {
                      value: values.city,
                      label: toTitleCase(values.city)
                    }
                  ]
            }
            placeholder=""
            isClearable={false}
            className="city"
            disabled={availableCities.length <= 1 || !!addressToVerify}
          />
          <Select
            id="state"
            name="state"
            label="State"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.state}
            required
            options={availableStates}
            placeholder=""
            isClearable={false}
            className="state"
            disabled
            size="fill"
          />
        </FormGroup>
        {!addressToVerify && (
          <FormGroup align="right">
            <Button isLink onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={validateAddress}
              disabled={!readyToContinue || !!invalidFields.length}
            >
              {!!contactId ? "Save Changes" : "Add Contact"}
            </Button>
          </FormGroup>
        )}
      </Form>
      {addressToVerify && (
        <VerifyAddress
          addressToVerify={addressToVerify}
          onContinue={updateAddressFormFields}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ScheduleItemContact;
