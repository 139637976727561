export const lineAutoCoverageControls = ({ quoteData }) => {
  const coverageControl = [];

  /**
   * OOQ-14751 override coverage control
   * this will show the 1.5M option when
   * quote is NJ, and has a heavy truck
   */

  const hasAHeavyTruck =
    quoteData?.lobData?.ca7CommAuto?.coverables?.vehicles?.some(
      v => v?.grossVehicleWeight > 26000
    );
  if (quoteData?.baseData?.baseState_UFG === "NJ" && hasAHeavyTruck)
    coverageControl.push({
      name: "Liability",
      codeIdentifier: "CA7VehicleLiab",
      termControl: [
        {
          name: "Liability Limit",
          patternCode: "CA7LimitText",
          options: [
            {
              name: "500,000",
              control: "hidden"
            },
            {
              name: "750,000",
              control: "hidden"
            },
            {
              name: "1,000,000",
              control: "hidden"
            }
          ],
          onlyShowOptions: [
            {
              name: "1,500,000",
              control: "visible"
            }
          ]
        }
      ]
    });

  return {
    formData: {
      coverageControl
    }
  };
};
