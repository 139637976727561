import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Popover } from "@ufginsurance/ui-kit";

const ConfirmDeletePopover = props => {
  const { attachment, removeAttachment } = props;

  const handleCancel = () => {
    document.querySelector("#confirm-delete-popover").classList.remove("show");
  };

  const handleRemove = () => {
    if (removeAttachment) {
      removeAttachment(attachment.id);
    }

    document.body.click();
  };

  const renderTrigger = (
    <span className="confirm-delete-popover__icon">
      <FontAwesomeIcon icon={faTimesCircle} />
    </span>
  );

  const renderOverlay = (
    <div>
      <p>Are you sure you want to delete this file?</p>
      <div className="confirm-delete-popover__buttons">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <span className="delete-attachment-spacer" />
        <Button variant="primary" onClick={handleRemove}>
          Yes
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      id="confirm-delete-popover"
      placement="left"
      popoverClass="confirm-delete-popover__overlay"
      triggerContent={renderTrigger}
      popoverContent={renderOverlay}
      theme="white"
    />
  );
};

ConfirmDeletePopover.propTypes = {
  attachment: PropTypes.object,
  removeAttachment: PropTypes.func
};

export default ConfirmDeletePopover;
