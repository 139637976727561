import _get from "lodash/get";
import { quotePath } from "../../shared/constants";

const getAllLocations = ({ quoteData, supportingData }) => {
  //OOQ-2826
  //combine locations and merge their linesOfBusiness if same fixedID
  const concatLocations = (locsA, locsB) => {
    locsB.forEach(b => {
      const found = locsA.find(item => item.id === b.id);
      if (!found) {
        locsA.push(b);
        return;
      }

      const combinedLocation = {
        ...b,
        ...found,
        linesOfBusiness: found.linesOfBusiness.concat(b.linesOfBusiness)
      };
      //update found item in locsA array.
      const newArr = locsA.map(a => {
        return a.id === found.id ? combinedLocation : a;
      });
      locsA = [...newArr];
    });
    return [...locsA];
  };

  const getLocationsFromStore = (path, lob) => {
    const inlocations = getCoveragebleFromStore(path);
    return inlocations.map(l => ({
      ...l,
      disableDelete: l.isPrimary,
      linesOfBusiness: [lob]
    }));
  };

  const getCoveragebleFromStore = path => {
    const obj = _get(quoteData, path, []);
    return obj.map(l => ({
      ...l,
      id: l?.id || l?.fixedId || l?.fixedID
    }));
  };

  let inlocations = getLocationsFromStore(
    quotePath.bp7locations,
    "bp7BusinessOwners"
  );
  //Auto locations not in supportingData.autoLocationFixedIDs should not have the linesOfBussiness = "ca7CommAuto"
  const autoLocations = getLocationsFromStore(
    quotePath.ca7locations,
    "ca7CommAuto"
  ).map(l => {
    if (!!(supportingData?.autoLocationFixedIDs || []).includes(l.fixedID)) {
      return { ...l, linesOfBusiness: ["ca7CommAuto"] };
    }
    return { ...l, linesOfBusiness: [] };
  });

  inlocations = concatLocations(inlocations, autoLocations);
  const wcompLocations = getLocationsFromStore(
    quotePath.wcmlocations,
    "wcmWorkersComp"
  );
  inlocations = concatLocations(inlocations, wcompLocations);
  return inlocations;
};
export default getAllLocations;
