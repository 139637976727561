import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator } from "@ufginsurance/ui-kit";
import { getURLParams } from "../shared/util";
import HeaderContainer from "../../../containers/HeaderContainer";
import FooterContainer from "../../../containers/FooterContainer";
import "./packets.scss";

export default function PacketDownload({ fetchDocumentsPacket }) {
  const [urlParams, setURLParams] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const allowedQueryParams = [
      "account_number",
      "agency_code",
      "bond_number",
      "submission_result"
    ];
    const queryParams = getURLParams(allowedQueryParams);

    if (!queryParams?.agency_code || !queryParams?.account_number) {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    setURLParams(queryParams);
    fetchDocumentsPacket(queryParams)
      .then(() => setIsLoading(false))
      .catch(() => setIsValid(false));
  }, [fetchDocumentsPacket]);

  const handleClick = () => {
    if (urlParams) {
      setIsLoading(false);
      fetchDocumentsPacket(urlParams)
        .then(() => setIsLoading(false))
        .catch(() => setIsValid(false));
    }
  };

  return (
    <>
      <HeaderContainer />

      <div id="body" className="container p-4">
        <div className="packet-wrapper">
          <div>
            {isLoading && (
              <LoadingIndicator
                type="spinner"
                message=""
                color="green"
                className="loading-icon"
              />
            )}
          </div>

          {!isValid ? (
            <p>
              Hmmm, there seems to be something wrong with the link provided...
              <br />
              We weren&apos;t able to retrieve your packet.
            </p>
          ) : (
            <div>
              <h1>Please wait, while we retrieve your packet...</h1>

              <p>
                If the file does not load, please enable/allow popups from this
                site, or try clicking{" "}
                <Button onClick={handleClick} isLink inline>
                  here.
                </Button>
              </p>
            </div>
          )}
        </div>
      </div>

      <FooterContainer />
    </>
  );
}
