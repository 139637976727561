import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateFormat, toTitleCase } from "../components/Factory";
import { Translations } from "../components/translations";
import { Alert } from "react-bootstrap";
import { LoadingIndicator, Button, Table, FlexRow } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const getPolicyDocumentColumns = handleCellClickForPrint => {
  return [
    {
      key: "process_date",
      label: "Process Date",
      sortable: true,
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "document_description",
      label: "Document / Form Type",
      element: row => (
        <button className="btn-as-link" onClick={handleCellClickForPrint(row)}>
          {toTitleCase(row.document_description)}
        </button>
      )
    },
    {
      key: "state",
      label: "State"
    },
    {
      key: "form_number",
      label: "Form Number"
    },
    {
      key: "edition",
      label: "Edition Date"
    }
  ];
};

const getFilingDocumentColumns = () => {
  return [
    {
      key: "process_date",
      label: "Process Date",
      sortable: true,
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "document_description",
      label: "Document / Form Type"
    },
    {
      key: "state",
      label: "State"
    },
    {
      key: "form_number",
      label: "Form Number"
    },
    {
      key: "edition",
      label: "Edition Date"
    }
  ];
};

const LineOfBusinessDocumentsTable = ({
  policyType,
  policyTerm,
  policy,
  declarationCode,
  documents,
  isLoading,
  fetchDocumentSummary,
  fetchCurrentPolicyDocument,
  activeAgencyCode,
  onSelectPolicyDocument,
  selectedPolicyDocuments
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isPrintOrViewLoading, setIsPrintOrViewLoading] = useState(false);
  const [requestedDocumentSummary, setRequestedDocumentSummary] =
    useState(false);
  const [activePage, setActivePage] = useState(1);
  const selectedDocuments =
    selectedPolicyDocuments[declarationCode + policyTerm] || [];

  useEffect(() => {
    if (!requestedDocumentSummary) {
      setRequestedDocumentSummary(true);
      fetchDocumentSummary(
        activeAgencyCode,
        policyType,
        declarationCode,
        policy,
        policyTerm
      );
    }
  }, [
    fetchDocumentSummary,
    activeAgencyCode,
    policyType,
    declarationCode,
    policy,
    policyTerm,
    requestedDocumentSummary,
    setRequestedDocumentSummary
  ]);

  const handlePageChange = page => {
    setActivePage(page);
  };

  const handleOnSelectionChange = selectedRows => {
    onSelectPolicyDocument(declarationCode, policyTerm, selectedRows);
  };

  const handlePrintClick = () => {
    fetchDocumentsAndHandleError(selectedDocuments);
  };

  const handlePrintClickAll = () => {
    fetchDocumentsAndHandleError(
      documents.filter(item => item.reference_number)
    );
  };

  const handleCellClickForPrint = row => e => {
    e.preventDefault();
    e.stopPropagation();
    fetchDocumentsAndHandleError([row]);
  };

  const fetchDocumentsAndHandleError = selectedDocuments => {
    const data = {
      policy_number: policy.policy_number,
      policy_type: policyType,
      document_information: selectedDocuments
    };
    setErrorMessage("");
    setIsPrintOrViewLoading(true);
    fetchCurrentPolicyDocument(data)
      .then(() => {
        setErrorMessage("");
        setIsPrintOrViewLoading(false);
      })
      .catch(() => {
        setErrorMessage(<HelpDeskMessage display="anErrorOccurred" />);
        setIsPrintOrViewLoading(false);
      });
  };

  const canPrint = selectedDocuments.length && selectedDocuments.length > 0;
  const columnsPolicyDocuments = getPolicyDocumentColumns(
    handleCellClickForPrint
  );
  const columnsFilings = getFilingDocumentColumns();
  const lineOfBusinessPolicyDocuments = documents.filter(
    item => item.reference_number
  );
  const lineOfBusinessFilingDocuments = documents.filter(
    item => !item.reference_number
  );
  return (
    <div className="ufg-core-table">
      {isLoading && <LoadingIndicator />}
      {documents.length > 0 && (
        <Fragment>
          <Table
            rowKey="id"
            data={lineOfBusinessPolicyDocuments}
            selectable
            selectAllButton="all"
            preSelectedRows={selectedDocuments}
            columns={columnsPolicyDocuments}
            itemsPerPage={20}
            showPagination
            onSelectionsChange={handleOnSelectionChange}
            activePage={activePage}
            onPageChange={handlePageChange}
          />
          {lineOfBusinessFilingDocuments.length > 0 && (
            <div className="filings_documents">
              <div className="filingsHeader">
                {Translations.account_overview.currentPolicy.filings}
              </div>
              <Table
                rowKey="id"
                data={lineOfBusinessFilingDocuments}
                columns={columnsFilings}
                itemsPerPage={20}
                showPagination
              />
            </div>
          )}
          <div>
            {errorMessage && <Alert bsStyle="danger">{errorMessage}</Alert>}
            <FlexRow>
              <Button
                onClick={handlePrintClick}
                disabled={!canPrint || isPrintOrViewLoading}
                variant="primary"
              >
                {Translations.account_overview.currentPolicy.view_Print}
              </Button>
              <Button
                onClick={handlePrintClickAll}
                disabled={isPrintOrViewLoading}
              >
                View/Print All
              </Button>
            </FlexRow>
            {isPrintOrViewLoading && <LoadingIndicator />}
          </div>
        </Fragment>
      )}
    </div>
  );
};

LineOfBusinessDocumentsTable.propTypes = {
  policyType: PropTypes.string.isRequired,
  policyTerm: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired,
  declarationCode: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchDocumentSummary: PropTypes.func.isRequired,
  fetchCurrentPolicyDocument: PropTypes.func.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  onSelectPolicyDocument: PropTypes.func,
  selectedPolicyDocuments: PropTypes.object
};

export default LineOfBusinessDocumentsTable;
