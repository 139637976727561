import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";

const monthlyExperienceRedirect = selectedAgencyCode => {
  const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
  window.open(
    `${baseLink}/AgentsOnly/ufgAgent/Reports/MonthlyExpReport.aspx?AgentCode=${selectedAgencyCode}`
  );
};

const MonthlyExperience = ({
  hasAgencyProductionReportsAccess,
  selectedAgencyCode
}) => {
  return (
    <>
      <div className="reports-above-form-message">
        <HelpDeskMessage display="questionsOrAssistance" />
      </div>
      <div>
        {selectedAgencyCode && selectedAgencyCode === "109015" ? (
          <div className="agency-error-message">
            {Translations.reports.internal_user}
          </div>
        ) : hasAgencyProductionReportsAccess ? (
          <div>{monthlyExperienceRedirect(selectedAgencyCode)}</div>
        ) : (
          <div className="agency-error-message">
            {Translations.reports.tabAgency.access_error_message}
          </div>
        )}
      </div>
    </>
  );
};

MonthlyExperience.propTypes = {
  selectedAgencyCode: PropTypes.string,
  hasAgencyProductionReportsAccess: PropTypes.bool
};

export default MonthlyExperience;
