export const iconStatus = [
  {
    status: "In Force",
    iconName: "fasCheckCircle",
    color: "#2A8200"
  },
  {
    status: "Cancelled Subject To Audit",
    iconName: "fasTimesCircle",
    color: "#B7312C"
  },
  {
    status: "Cancel",
    iconName: "fasTimesCircle",
    color: "#B7312C"
  },
  {
    status: "Cancel Flat",
    iconName: "fasTimesCircle",
    color: "#B7312C"
  },
  {
    status: "Cancel Mid-Term",
    iconName: "fasTimesCircle",
    color: "#B7312C"
  },
  {
    status: "Final Lapse",
    iconName: "fasExclamationTriangle",
    color: "#B7312C"
  },
  {
    status: "Policy is Lapsed",
    iconName: "fasExclamationTriangle",
    color: "#B7312C"
  },
  {
    status: "Intial Cancel",
    iconName: "fasTimesCircle",
    color: "#B7312C"
  },
  {
    status: "Pending Cancel",
    iconName: "farClock",
    color: "#DE8800"
  },
  {
    status: "Pending Payment",
    iconName: "farClock",
    color: "#DE8800"
  },
  {
    status: "Expired",
    iconName: "fasMinusCircle",
    color: "#B7312C"
  }
];
