import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../components/Factory";
import { Translations } from "./../components/translations";
import {
  LoadingIndicator,
  Accordion,
  Table,
  Button
} from "@ufginsurance/ui-kit";

const RequestedPolicyChanges = props => {
  const { requestedChanges, fetchPolicyChangesViewDocument, policy_number } =
    props;
  const { changeRequests } = Translations.account_overview;

  const [documentsLoading, setDocumentsLoading] = useState([]);

  const handleDocumentClick = row => () => {
    setDocumentsLoading(
      !documentsLoading.includes(row.change_id)
        ? [...documentsLoading, row.change_id]
        : documentsLoading
    );

    fetchPolicyChangesViewDocument(policy_number, row.change_id).finally(() => {
      setDocumentsLoading(documentsLoading.filter(x => x !== row.change_id));
    });
  };

  const createColumns = (changeRequests, documentsLoading) => [
    {
      key: "change_date",
      label: changeRequests.peronal_changes_table_heading.date,
      sortable: true,
      element: row => <span>{DateFormat(row.change_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "change_type",
      label: changeRequests.peronal_changes_table_heading.type_of_Request,
      element: row => {
        const requestInProgress = documentsLoading.includes(row.change_id);
        return row.has_document ? (
          <div>
            <Button
              isLink
              onClick={handleDocumentClick(row)}
              disabled={requestInProgress}
            >
              {changeRequests.table_change_types[row.change_type] !== undefined
                ? changeRequests.table_change_types[row.change_type]
                : changeRequests.table_change_types.other_change}
            </Button>
            {requestInProgress && <LoadingIndicator message="" />}
          </div>
        ) : changeRequests.table_change_types[row.change_type] !== undefined ? (
          changeRequests.table_change_types[row.change_type]
        ) : (
          changeRequests.table_change_types.other_change
        );
      },
      sortable: true
    },
    {
      key: "agent_number",
      label: changeRequests.peronal_changes_table_heading.agent_code,
      sortable: true
    }
  ];

  return (
    <div className="policy-change">
      {
        <div className="m-t">
          <Accordion isOpen textMain={changeRequests.recent_changes_heading}>
            <Fragment>
              <div className="policy-submissions-note">
                {" "}
                {changeRequests.policy_submissions}{" "}
              </div>
              <div>
                <div className="col-sm-12 m-t">
                  <Table
                    data={requestedChanges}
                    columns={createColumns(changeRequests, documentsLoading)}
                    itemsPerPage={20}
                    rowKey="change_id"
                    compact
                    showPagination
                  />
                </div>
              </div>
            </Fragment>
          </Accordion>
        </div>
      }
    </div>
  );
};

RequestedPolicyChanges.propTypes = {
  requestedChanges: PropTypes.array.isRequired,
  fetchPolicyChangesViewDocument: PropTypes.func,
  policy_number: PropTypes.string.isRequired
};

export default RequestedPolicyChanges;
