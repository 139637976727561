import React from "react";
import PropTypes from "prop-types";
import CustomErrorView from "../../components/CustomErrorView";
import { toTitleCase } from "../../components/Factory";
import ClassAppetiteGuideAppetiteDetail from "./ClassAppetiteGuideAppetiteDetail";

const COMMERCIAL_PRODUCT_CODE = "CP2";
const WORKERS_COMPENSATION_PRODUCT_CODE = "WC2";

const ClassAppetiteGuideClassifications = props => {
  const {
    classifications,
    stateCode,
    productCode,
    selectedClassification,
    handleClassificationItemClick
  } = props;
  
  const onHandleClassificationItemClick = event => {
    handleClassificationItemClick(event.target.value, classifications);
  };

  const combineCodeAndSequence = (
    classificationCode,
    classificationSequence
  ) => {
    return classificationCode + "_" + classificationSequence;
  };

  const showSelectorText =
    productCode !== COMMERCIAL_PRODUCT_CODE &&
    productCode !== WORKERS_COMPENSATION_PRODUCT_CODE;

  if (classifications && classifications.error)
    return <CustomErrorView error={classifications.error} />;
  if (!classifications || classifications.length === 0) return null;
  return (
    <div className="classifications-container">
      {showSelectorText ? (
        <h6>Select a class to view details</h6>
      ) : (
        <h6>&nbsp;</h6>
      )}

      <div className="class-appetite-search-results">
        <ul className="user-menu__list">
          {classifications.map(classification => {
            const selected =
              selectedClassification &&
              combineCodeAndSequence(
                selectedClassification.code,
                selectedClassification.sequence
              ) ===
                combineCodeAndSequence(
                  classification.code,
                  classification.sequence
                );
            return (
              <li
                key={combineCodeAndSequence(
                  classification.code,
                  classification.sequence
                )}
                className={"class-selection "}
              >
                <button
                  value={combineCodeAndSequence(
                    classification.code,
                    classification.sequence
                  )}
                  data-classcode={combineCodeAndSequence(
                    classification.code,
                    classification.sequence
                  )}
                  onClick={onHandleClassificationItemClick}
                  className={selected ? "active" : null}
                  aria-expanded={selected}
                  aria-controls={`classification-${classification.code}`}
                >
                  {classification.code}
                  &nbsp; {toTitleCase(classification.description)}
                </button>
                {selected && (
                  <div
                    className="uikit-sr-only"
                    id={`classification-${classification.code}`}
                  >
                    <ClassAppetiteGuideAppetiteDetail
                      selectedState={stateCode}
                      selectedProduct={productCode}
                      classItem={selectedClassification}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

ClassAppetiteGuideClassifications.propTypes = {
  classifications: PropTypes.array,
  handleClassificationItemClick: PropTypes.func,
  stateCode: PropTypes.string,
  productCode: PropTypes.string,
  selectedClassification: PropTypes.object
};

export default ClassAppetiteGuideClassifications;
