import React from "react";
import { Translations } from "../../../components/translations";
import { Tabs, Tab } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import PolicyLossRunContainer from "../../containers/PolicyLossRunContainer";
import AgencyLossRunContainer from "../../containers/AgencyLossRunContainer";
import YearToDateLossSummaryContainer from "../../containers/YearToDateLossSummaryContainer";
import WeeklyPaymentContainer from "../../containers/WeeklyPaymentContainer";
import {
  getActiveTabIndex,
  onTabClick as tabClick
} from "./../../../constants/routes";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const Claims = props => {
  const { match, history } = props;

  const onTabClick = tabIndex => {
    tabClick(tabIndex, match, history);
  };

  return (
    <div className="claims-container">
      <div>
        <Tabs activeTab={getActiveTabIndex(match)}>
          <Tab
            title={Translations.reports.tabClaims.policyLossRuns}
            onClick={onTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <PolicyLossRunContainer />
          </Tab>

          <Tab
            title={Translations.reports.tabClaims.agencyLossRuns}
            onClick={onTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <AgencyLossRunContainer />
          </Tab>
          <Tab
            className="yeartoDate"
            title={Translations.reports.tabClaims.yeartoDateLossSummary}
            onClick={onTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <YearToDateLossSummaryContainer />
          </Tab>
          <Tab
            className="weeklyPayment"
            title={Translations.reports.tabClaims.weeklyPaymentSummary}
            onClick={onTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <WeeklyPaymentContainer />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
};
Claims.propTypes = propTypes;
export default Claims;
