import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button, Currency, LoadingIndicator } from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";
import * as paymentConfirmationSelector from "./paymentConfirmationSelector";

const PaymentConfirmation = ({
  paymentDetails,
  history,
  match,
  insuredName,
  isOasisPaymentConfirmationLoading,
  isOasisPayment,
  sendOasisPaymentConfirmation,
  fetchAgencySweepPaymentReport,
  originalOasisInvoiceDetails,
  agencySweepPaymentInfo,
  paymentReportLoading,
  activeAgencyCode
}) => {
  const [paymentRequestConfirmation, setPaymentRequestConfirmation] = useState(
    {}
  );
  const accountNumber = match.params.account_number;
  const {
    confirmation_number,
    payment_id,
    payment_date,
    paid_amounts,
    total_amount
  } = paymentRequestConfirmation;
  const hasPaymentConfirmation = confirmation_number > 0 && payment_id > 0;
  const { payment } = Translations;

  useEffect(() => {
    if (!paymentDetails) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
    setPaymentRequestConfirmation(paymentDetails);
  }, [paymentDetails, accountNumber, history]);

  useEffect(() => {
    const hasPaymentConfirmation =
      paymentRequestConfirmation &&
      paymentRequestConfirmation.confirmation_number > 0 &&
      paymentRequestConfirmation.payment_id > 0;
    if (
      isOasisPayment &&
      originalOasisInvoiceDetails &&
      hasPaymentConfirmation
    ) {
      const paymentConfirmation =
        paymentConfirmationSelector.createAllOasisPaymentConfirmations(
          activeAgencyCode,
          accountNumber,
          paymentRequestConfirmation,
          originalOasisInvoiceDetails
        );
      sendOasisPaymentConfirmation(paymentConfirmation);
    }
  }, [
    paymentRequestConfirmation,
    isOasisPayment,
    activeAgencyCode,
    accountNumber,
    originalOasisInvoiceDetails,
    sendOasisPaymentConfirmation
  ]);

  const handleBackToAccountOverviewClick = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    );
  };

  const onPrintPage = () => {
    const paymentData = paymentConfirmationSelector.createPaymentPdfRequest(
      agencySweepPaymentInfo,
      paymentDetails
    );
    fetchAgencySweepPaymentReport(paymentData);
  };

  return (
    <div className="payment-container">
      {hasPaymentConfirmation && (
        <div>
          <div>
            <h2>
              {Translations.formatString(payment.confirmation.Agency_Sweep)}
            </h2>
          </div>
          <hr />
          {isOasisPaymentConfirmationLoading ? (
            <div>
              <LoadingIndicator
                message={
                  payment.confirmation
                    .oasis_payment_request_confirmation_loader_prompt
                }
              />
              <br />
            </div>
          ) : (
            <>
              <div>
                <div>
                  <h3>
                    {Translations.formatString(
                      payment.confirmation.Payment_Confirmation
                    )}
                  </h3>
                  {Translations.formatString(
                    payment.confirmation.payment_authorized
                  )}
                </div>
                <div className="payment-info-bold">
                  {Translations.formatString(
                    payment.confirmation.confirmation_number,
                    confirmation_number
                  )}
                </div>
                <div className="payment-info-bold">
                  {Translations.formatString(
                    payment.confirmation.payment_id,
                    payment_id
                  )}
                </div>
                <div className="please-text">
                  {paymentDetails.confirmation_email_failed ? (
                    <p>
                      {Translations.formatString(
                        payment.confirmation.payment_authorization
                      )}
                      <Button
                        variant="plain"
                        isLink
                        className="payment-info-bold print-btn"
                        onClick={onPrintPage}
                      >
                        {Translations.formatString(
                          payment.confirmation.print_page
                        )}
                      </Button>
                      ,
                      {Translations.formatString(
                        payment.confirmation.payment_authorization1
                      )}
                    </p>
                  ) : (
                    <p>
                      {Translations.formatString(
                        payment.confirmation.please_text1
                      )}
                      &nbsp;
                      <Button
                        variant="plain"
                        isLink
                        className="payment-info-bold"
                        onClick={onPrintPage}
                      >
                        {Translations.formatString(
                          payment.confirmation.print_page
                        )}
                      </Button>
                      {Translations.formatString(
                        payment.confirmation.please_text2
                      )}
                      {paymentReportLoading && <LoadingIndicator />}
                    </p>
                  )}
                </div>
                <div className="payment-activity-table">
                  <div className="bold">
                    {insuredName}
                    <hr />
                  </div>
                  {paid_amounts.map(item => (
                    <React.Fragment key={item.policy_account_number}>
                      <div className="inner-tab-row no-padding-top">
                        <div className="inner-row">
                          <div>
                            <b>
                              {payment.confirmation.account_policy_column_label}
                            </b>
                          </div>
                          <div>{item && item.policy_account_number}</div>
                        </div>
                        <div>
                          <div>
                            <b>
                              {payment.confirmation.amount_paid_column_label}
                            </b>
                          </div>
                          <div>
                            <Currency amount={item.payment_amount}></Currency>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="inner-tab-row ">
                    <div className="inner-row">
                      <div>
                        <b>{payment.confirmation.payment_total_column_label}</b>
                      </div>
                    </div>
                    <div className="inner-row">
                      <div>
                        <b>
                          <Currency amount={total_amount} />
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="inner-tab-date">
                    {Translations.formatString(
                      payment.confirmation.payment_date,
                      new Date(payment_date).toLocaleString()
                    )}
                  </div>
                </div>
                <br />
                <div>
                  {Translations.formatString(
                    payment.confirmation.payment_questions
                  )}
                  <br />
                  <b>
                    {Translations.formatString(
                      payment.confirmation.billing_customer_service
                    )}
                  </b>
                  <br />
                  {Translations.formatString(
                    payment.confirmation.billing_phone
                  )}
                  <br />
                  {Translations.formatString(
                    payment.confirmation.billing_hours
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!isOasisPaymentConfirmationLoading && (
        <div className="button-div">
          <Button
            variant="secondary"
            className="return-button"
            onClick={handleBackToAccountOverviewClick}
          >
            {payment.confirmation.back_to_account_overview}
          </Button>
        </div>
      )}
    </div>
  );
};

PaymentConfirmation.propTypes = {
  paymentDetails: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  insuredName: PropTypes.string.isRequired,
  isOasisPaymentConfirmationLoading: PropTypes.bool.isRequired,
  isOasisPayment: PropTypes.bool.isRequired,
  sendOasisPaymentConfirmation: PropTypes.func.isRequired,
  fetchAgencySweepPaymentReport: PropTypes.func.isRequired,
  originalOasisInvoiceDetails: PropTypes.object,
  agencySweepPaymentInfo: PropTypes.object,
  paymentReportLoading: PropTypes.bool,
  activeAgencyCode: PropTypes.string.isRequired
};

export default withRouter(PaymentConfirmation);
