// Define image path constants for CDN images only. Import images directly for project specific images

export const headerLogo = `${window.env.REACT_APP_CDN_URL}/logo.png`;
export const ufginsurance = `${window.env.REACT_APP_CDN_URL}/UFG_logo.png`;
export const specialtyLogo = `https://www.ufginsurance.com/images/default-source/logos/specialty.png`;
export const logo_151 = `/global/agentportal/UFG_logo_151.png`;
export const logo_313 = `/global/agentportal/UFG_logo_313.png`;
export const online_quoting = `/global/agentportal/online_quoting_lets_get_started.png`;
export const logoGetStarted = `/global/agentportal/Lets_get_started.png`;
export const logoUFGinsurance= `/global/agentportal/ufg-logo.png`;
export const logoMini =
  "https://www.ufginsurance.com/images/default-source/development-images/common/ufg-logo-100px.jpg";
export const logoSmall =
  "https://www.ufginsurance.com/images/default-source/development-images/common/ufg-logo-125px.jpg";
export const logoLarge =
  "https://www.ufginsurance.com/images/default-source/development-images/common/ufg-logo-150px.jpg";
