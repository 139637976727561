import React from "react";
import PropTypes from "prop-types";
import { DropdownNonForm, LoadingIndicator } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { withRouter } from "react-router-dom";

const StatesDropDown = ({
  onStatesListClick,
  stateListData,
  selectedState,
  areStatesLoading,
  statesError
}) => {
  return (
    <>
      <div className="states-selection">
        <span className="states-selection__text">
          Currently viewing products manuals for{" "}
          {selectedState && (
            <span className="sr-only">{selectedState.label}</span>
          )}
        </span>
        {areStatesLoading && (
          <LoadingIndicator
            type="spinner"
            message=""
            className="states__loader"
          />
        )}
        <DropdownNonForm
          label=""
          aria-label="list of states, type to search"
          id="statesList"
          name="statesList"
          onChange={onStatesListClick}
          options={stateListData}
          value={selectedState || undefined}
          isClearable={false}
          isSearchable
          size="md"
          noLabel
        />
      </div>
      {statesError && (
        <>
          <div>&nbsp;</div>
          <div className="friendly-error-messaging">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </>
      )}
    </>
  );
};

StatesDropDown.propTypes = {
  onStatesListClick: PropTypes.func.isRequired,
  stateListData: PropTypes.array,
  selectedState: PropTypes.object,
  areStatesLoading: PropTypes.bool.isRequired,
  statesError: PropTypes.object
};

export default withRouter(StatesDropDown);
