export function getSavedLossesListError(store) {
  return store.homeClaims.SavedLossesError;
}
export function getSavedLossesList(store) {
  return store.homeClaims.SavedLossesList;
}
export function getSavedLossesListIsLoading(store) {
  return store.homeClaims.SavedLossesIsLoading;
}
export function getSubmittedLossesListError(store) {
  return store.homeClaims.SubmittedLossesError;
}
export function getSubmittedLossesList(store) {
  return store.homeClaims.SubmittedLossesList;
}
export function getSubmittedLossesListIsLoading(store) {
  return store.homeClaims.SubmittedLossesIsLoading;
}
export function getClaimsOpenedLoading(store) {
  return store.homeClaims.claimsOpenedLoading;
}
export function getClaimPaymentsLoading(store) {
  return store.homeClaims.claimPaymentsLoading;
}
