import React, { useEffect, useReducer, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import {
  DropdownNonForm,
  LoadingIndicator,
  Button
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { Translations } from "../../translations";
import {
  sortByProperty,
  clearLocalQuoteManually
} from "../../../online-quoting/shared/util";
import { cloneDeep } from "lodash";
import SubmissionResults from "./SubmissionResults";
import { toggleProductSelectionModal } from "../../../commercial/shared/ProductSelection/ProductSelectionModal";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import PreQuoteModal from "../../../online-quoting/step1/PreQuoteModal";

const filterByKeyword = (keyword, submissions) => {
  const includesKeyword = value => {
    return !!value && value.toLowerCase().includes(keyword.toLowerCase());
  };

  return submissions.filter(
    item =>
      item &&
      (includesKeyword(item.Customer.name) ||
        includesKeyword(item.Customer.business_address.address1) ||
        includesKeyword(item.Customer.business_address.city) ||
        includesKeyword(item.Customer.business_address.zip) ||
        includesKeyword(item.Customer.business_address.state) ||
        includesKeyword(item.quote_number))
  );
};

const filterByStatus = (status, submissions) =>
  submissions.filter(
    item =>
      item &&
      item.status &&
      status &&
      status.value &&
      item.status.toLowerCase() === status.value.toLowerCase()
  );

export const filterSubmissions = (keyword, status, submissions) => {
  let filtered = [...submissions];
  if (status.value === "") {
    filtered = filtered.filter(
      item =>
        item && item.status !== "Inactive" && item.status !== "Deactivated"
    );
  } else {
    filtered = filterByStatus(status, filtered);
  }

  if (keyword !== "") {
    filtered = filterByKeyword(keyword, filtered);
  }

  // sort submssions in by date first so latest submissions appear in customer accordion
  filtered.sort(sortByProperty("last_updated", false));

  filtered.sort((a, b) => {
    const name1 = (a?.Customer?.name || "").toLowerCase();
    const name2 = (b?.Customer?.name || "").toLowerCase();
    return name1 > name2 ? 1 : name1 === name2 ? 0 : -1;
  });
  return filtered;
};

const initialState = {
  keyword: "",
  status: {
    label: "Show all *",
    value: ""
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "KEYWORD_CHANGED":
      return { ...state, keyword: action.keyword };
    case "STATUS_CHANGED":
      return { ...state, status: action.status };
    case "REFRESHED":
      return { ...initialState };
    default:
      throw new Error(`Unhandled action type: ${!!action ? action.type : ""}`);
  }
};

const NewSubmissionsContainer = ({
  fetchSubmissionList,
  submissionList,
  agencyCode,
  isLoading,
  toggleProductSelectionModal
}) => {
  const history = useHistory();
  const { buildersRiskEnabled } = useFlags();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPreQuoteModal, setShowPreQuoteModal] = useState(false);

  const handleRefresh = () => {
    dispatch({ type: "REFRESHED" });
    fetchSubmissionList(agencyCode);
  };

  const handleChange = e => {
    dispatch({ type: "KEYWORD_CHANGED", keyword: e.target.value });
  };

  const handleFilterChange = selectedStatus => {
    dispatch({ type: "STATUS_CHANGED", status: selectedStatus });
  };

  const handleAddNewQuote = () => {
    if (buildersRiskEnabled) {
      toggleProductSelectionModal();
    } else {
      clearLocalQuoteManually();
      history.push(routes.ONLINE_QUOTING_STEP1);
    }
  };

  useEffect(() => {
    fetchSubmissionList(agencyCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyCode]);

  const filtered = useMemo(
    () => filterSubmissions(state.keyword, state.status, submissionList),
    [state.keyword, state.status, submissionList]
  );

  const { actions, messages, status_options, control_labels } =
    Translations.commercial.new_submissions_tab;

  let priorCustomer = undefined;

  const clonedFiltered = cloneDeep(filtered);

  return (
    <div className="newSubmissionPanel">
      {showPreQuoteModal && (
        <PreQuoteModal
          history={history}
          onContinue={handleAddNewQuote}
          showPreQuoteModal={showPreQuoteModal}
          setShowPreQuoteModal={setShowPreQuoteModal}
        />
      )}
      <div className="row search-submission-panel">
        <div>
          <div className="newSubmissionPanel__form">
            <div className="hdr-search-search search-submission">
              <label htmlFor={"searchSubmission"}>
                {control_labels.search_submission_label}
              </label>
              <label className="sr-only" htmlFor="searchSubmissionButton">
                Search
              </label>
              <div className="searchHolder">
                <input
                  id="searchSubmission"
                  type="text"
                  className="form-control"
                  value={state.keyword}
                  onChange={handleChange}
                  placeholder={messages.searchBar}
                />
                <button
                  id="searchSubmissionButton"
                  type="button"
                  className="search-button"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </div>
            <div className="hdr-search-search">
              <DropdownNonForm
                label={control_labels.filter_label}
                id="filter"
                name="filter"
                onChange={handleFilterChange}
                options={status_options}
                value={state.status}
                isClearable={false}
                size="md"
              />
            </div>
            <div className="submission-btn-panel">
              <Button role="presentation" onClick={handleRefresh} isLink>
                {actions.refresh}
              </Button>

              <div className="commercial-new-quote">
                <Button
                  onClick={() => {
                    setShowPreQuoteModal(true);
                  }}
                  variant="primary"
                >
                  {actions.newQuote}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row quotes">
          <div className="col-sm-12 col-md-12 col-lg-12 m-t">
            {messages.note}
          </div>
        </div>
        <div className="row submission-result-panel">
          {isLoading ? (
            <LoadingIndicator />
          ) : clonedFiltered && clonedFiltered.length > 0 ? (
            clonedFiltered.map((obj, i) => {
              //The plan was for customers to always exist in the NBC database.
              //However, the New Quote feature isn't done and we want to test GW integration.
              //So for now we will relax that limitation and show all.
              if (obj.Customer.id === undefined) {
                obj.Customer.id =
                  priorCustomer !== undefined &&
                  priorCustomer.name === obj.Customer.name
                    ? priorCustomer.id
                    : v4();
              }

              if (
                priorCustomer !== undefined &&
                priorCustomer.id === obj.Customer.id
              ) {
                return null;
              }
              priorCustomer = obj.Customer;
              return (
                <SubmissionResults
                  key={obj.Customer.id + v4()}
                  index={i}
                  result={obj}
                  submissionList={clonedFiltered}
                  handleRefresh={handleRefresh}
                />
              );
            })
          ) : (
            <p>
              <HelpDeskMessage
                display="questionsOrAssistance"
                before="We're sorry but we are unable to locate your submission based on the information you provided. Please create a new submission."
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

NewSubmissionsContainer.propTypes = {
  submissionList: PropTypes.array,
  agencyCode: PropTypes.string.isRequired,
  fetchSubmissionList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleProductSelectionModal: PropTypes.func.isRequired
};
export default connect(null, { toggleProductSelectionModal })(
  NewSubmissionsContainer
);
