import React, { useContext, useState, useEffect, useCallback } from "react";
import * as routes from "../../../constants/routes";
import { Table, Panel, Button } from "@ufginsurance/ui-kit";
import { useHistory } from "react-router-dom";
import { obfuscate, sortByProperty } from "../../shared/util";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { quotePath } from "../../shared/constants";

const Drivers = () => {
  const {
    quoteData,
    supportingData,
    getQuotePart,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);

  const [drivers, setDrivers] = useState([]);

  const getCoveragebleFromStore = useCallback(
    path => {
      const obj = getQuotePart(path, true);
      return obj.map(l => ({
        ...l,
        id: l?.id || l?.fixedId || l?.fixedID
      }));
    },
    [getQuotePart]
  );

  useEffect(() => {
    const inDrivers = getCoveragebleFromStore(quotePath.drivers) || [];
    setDrivers(inDrivers.sort(sortByProperty("fixedId")));
  }, [
    quoteData,
    supportingData,
    updateSupportingDataPromise,
    getCoveragebleFromStore
  ]);

  const history = useHistory();
  const columns = [
    {
      key: "firstName",
      label: "Driver Name",
      className: "driver_full_name",
      element: driver => `${driver.firstName} ${driver.lastName}`,
      sortable: true
    },
    {
      key: "licenseNumber",
      label: "License Number",
      element: row => {
        return obfuscate(row.licenseNumber);
      }
    },
    { key: "licenseState", label: "License State" }
  ];

  return (
    <Panel
      id="oq__coverable__container__driver"
      noBorder
      className="oq__quote-summary__column__section "
      title={
        <div className="flexRow flexRow__full-width flexRow__centered-vertically">
          <span className="oq__data-section__title">
            Drivers ({drivers.length})
          </span>
          <Button
            variant="outline"
            wrapperClassName="align-right"
            isLink
            inline
            className="link-not-bolded"
            onClick={() => {
              history.push({
                pathname: routes.ONLINE_QUOTING_STEP5,
                hash: "Drivers"
              });
            }}
          >
            Back to Drivers
          </Button>
        </div>
      }
    >
      <Table
        isOpen={false}
        drivers={drivers}
        id="driver-table"
        rowKey="id"
        columns={columns}
        data={drivers}
        showPagination
        itemsPerPage={5}
        initialSort="firstName"
      />
    </Panel>
  );
};
export default Drivers;
