import { connect } from "react-redux";
import { searchKeywordUpdated } from "../actions/policies";
import PolicySearchInput from "./PolicySearchInput";

const mapStateToProps = (state, ownProps) => ({
  iconEnable: ownProps.iconEnable,
  searchPlaceholder: ownProps.searchPlaceholder
});

const mapDispatchToProps = dispatch => ({
  handleformSubmission: searchKeyword => {
    dispatch(searchKeywordUpdated(searchKeyword));
  }
});

const PolicySearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySearchInput);

export default PolicySearchBarContainer;
