import moment from "moment";
import { DATE_FORMAT } from "../claims/services/claimsUtil";
import { toTitleCase } from "../components/Factory";

export const getInsuredName = state => {
  return state.claims && state.claims.claims && state.claims.claims.length > 0
    ? state.claims.claims[0].insured_name
    : "Not Available";
};

export const createM4PrintPaymentDetailsRequest = (
  details,
  paymentNumber,
  claimNumber,
  policyNumber,
  insuredName
) => {
  const request = {
    insured: insuredName,
    policy_number: policyNumber,
    claim_number: claimNumber,
    payment_details: [],
    claimants: [],
    recipients: []
  };
  details.claimants.forEach(function(claimant) {
    request.claimants.push({
      name: toTitleCase(claimant.claimant_name),
      amount: parseFloat(claimant.claimant_amount
        ? String(claimant.claimant_amount).replace("$", "") 
        : "0"),
      coverage: claimant.cover
    });
  });
  const recipient = {
    name: toTitleCase(details.name_payment_sent_to),
    status: toTitleCase(
      details.check_status +
        " " +
        moment(details.date_status).format(DATE_FORMAT)
    ),
    amount: parseFloat(details.total_amount_paid || "0"),
    street: toTitleCase(details.payment_address_1_sent_to),
    city: toTitleCase(details.payment_city_state_zip_sent_to),
    state: "",
    zip: "",
    issued_on: details.date_check_issued_on
  };
  if (details.payment_address_2_sent_to) {
    recipient.street_2 = toTitleCase(details.payment_address_2_sent_to);
  }
  request.recipients = [recipient];
  let paymentDetails = "";
  details.payment_detail.forEach(function(detail, index) {
    if (index !== 0) {
      paymentDetails += "\n";
    }
    paymentDetails += toTitleCase(detail);
  });
  request.payment_details = [
    {
      number: paymentNumber,
      details: paymentDetails
    }
  ];
  return request;
};

export const createPrintPaymentDetailsRequest = (
  details,
  paymentNumber,
  claimNumber,
  policyNumber,
  insuredName
) => {
  const payload = {
    payment_number: paymentNumber,
    claim_number: claimNumber,
    payment_detail: "",
    policy_number: policyNumber,
    insured: insuredName,
    issue_date: details.date_check_issued_on,
    sent_to: {
      name: details.name_payment_sent_to,
      status:
        details.check_status +
        " " +
        moment(details.date_status).format(DATE_FORMAT),
      amount: details.total_amount_paid
    },
    primary_list: []
  };
  details.claimants.forEach(function(claimant) {
    payload.primary_list.push({
      claimant: claimant.claimant_name,
      amount: parseFloat(claimant.claimant_amount.replace("$", "")),
      coverage: claimant.cover
    });
  });
  details.payment_detail.forEach(function(detail, index) {
    if (index !== 0) {
      payload.payment_detail += "\n";
    }
    payload.payment_detail += detail;
  });
  if (details.payment_address_1_sent_to) {
    payload.sent_to.name += "\n" + details.payment_address_1_sent_to;
  }
  if (details.payment_address_2_sent_to) {
    payload.sent_to.name += "\n" + details.payment_address_2_sent_to;
  }
  if (details.payment_city_state_zip_sent_to) {
    payload.sent_to.name += "\n" + details.payment_city_state_zip_sent_to;
  }
  return payload;
};
