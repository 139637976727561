import * as types from "../constants/actionTypes";
import * as billingService from "../services/billingService";
import { logger } from "../loggers";

const fetchDirectBillPayNowEnrollmentStarted = () => ({
  type: types.FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_STARTED
});

const fetchDirectBillPayNowEnrollmentSucceeded = data => ({
  type: types.FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_SUCCEEDED,
  payload: data
});

const fetchDirectBillPayNowEnrollmentFailed = error => ({
  type: types.FETCH_DIRECT_BILL_PAY_NOW_ENROLLMENT_FAILED,
  payload: error,
  error: true
});

export const fetchDirectBillPayNowEnrollment = agency_code => dispatch => {
  dispatch(fetchDirectBillPayNowEnrollmentStarted());
  return billingService.getDirectBillPayNowEnrollment(agency_code).then(
    response =>
      dispatch(fetchDirectBillPayNowEnrollmentSucceeded(response.data)),
    error => {
      dispatch(fetchDirectBillPayNowEnrollmentFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
