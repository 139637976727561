import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { logger } from "../../../loggers";
import { getPendingActivityList } from "../../services/index";
import { checkIfDateLessThanYearOld } from "../../shared/utils";

export default function useGetPendingActivityList(agencyCode, account) {
  const [data, setData] = useState();
  const [isPendingActivityListLoading, setIsPendingActivityListLoading] =
    useState(false);
  const cache = useRef({});
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (
      !agencyCode ||
      !account?.account_number ||
      isPendingActivityListLoading ||
      error.current
    )
      return;

    if (cache.current[account?.account_number]) {
      setData(cache.current[account?.account_number]);
      return;
    }

    setIsPendingActivityListLoading(true);

    getPendingActivityList(agencyCode, account?.account_number)
      .then(res => {
        if (res.status === 200 && res.data.length) {
          const d = (res?.data ?? [])
            .map(_ => ({
              ..._,
              id: v4()
            }))
            .filter(datum => datum.account_id === account?.account_number) // Endpoint returns all bonds for the agent, not the account
            .filter(datum => datum.bid_date.trim() !== "") // Filter out rows that don't have an bid_date, since those aren't fully complete
            .filter(datum => checkIfDateLessThanYearOld(datum.bid_date)); // Filter out rows that have a bid date more than a year old.

          cache.current[account?.account_number] = d;
          setData(d);
        } else {
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsPendingActivityListLoading(false));
  }, [agencyCode, account, isPendingActivityListLoading]);

  return [data, isPendingActivityListLoading, error.current];
}
