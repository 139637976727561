import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  Input,
  Switch,
  Dropdown,
  useForm,
  InputEmail,
  TextArea,
  Icon
} from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";
import { agencyChecklistSubmit } from "../services/AgencyInterfaceServices";
import {
  MANAGEMENT_SYSTEM,
  INTERFACE_CODES
} from "./AgencyInterfaceDictionary";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { useCMSContext } from "../../../cms-content/context";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const AgencyInterfaceChecklist = ({
  firstAndLastName,
  agentEmail,
  activeAgencyName,
  agencyCode,
  hasServiceCenterAccess
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { agencyInterfaceChecklistShow, updateAgencyInterfaceChecklistShow } =
    useCMSContext();

  const initialValues = {
    interfaceContactName: firstAndLastName,
    interfaceContactEmail: agentEmail,
    managementSystem: "",
    downloadCommercialLines: false,
    downloadServiceCenter: false,
    initialPolicyLoad: false,
    downloadClaims: false,
    downloadCommissionStatements: false,
    downloadeDocs: false,
    accountID: "",
    userID: "",
    comments: ""
  };

  const initialFormErrors = {
    interfaceContactName: "",
    interfaceContactEmail: "",
    managementSystem: "",
    accountID: "",
    userID: ""
  };

  const form = useForm({ values: initialValues });

  const {
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm,
    values
  } = form;

  const displayToastError = () => {
    const options = {
      position: "top-center",
      className: "error",
      autoClose: false
    };
    toast(<HelpDeskMessage display="trySubmittingAgain" />, options);
  };

  const isValidManagementSystem = (managementSystem, code) => {
    return MANAGEMENT_SYSTEM.some(
      x => x.value === managementSystem && x.code.includes(code)
    );
  };

  const resetForm = () => {
    updateForm({
      values: initialValues,
      errors: initialFormErrors
    });
  };

  const closeAndReset = () => {
    updateAgencyInterfaceChecklistShow(false);
    resetForm();
  };

  const handleIDValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const IDPattern = /^[a-z0-9]+$/i;

    if (value && !IDPattern.test(value)) {
      if (field === "accountID") {
        fieldErrors.push(`Account ID should be alphanumeric`);
      }
      if (field === "userID") {
        fieldErrors.push(`User ID should be alphanumeric`);
      }
    }
    return fieldErrors;
  };

  const handleCommercialLinesChange = ({ field, value }) => {
    // Anytime commercialLines option changes
    updateForm({
      values: {
        [field]: value,
        downloadServiceCenter: false,
        initialPolicyLoad: false
      }
    });
  };

  const handleManagementSystemChange = ({ value }) => {
    updateForm({
      values: {
        ...values,
        managementSystem: value,
        downloadCommercialLines: false,
        downloadServiceCenter: false,
        initialPolicyLoad: false,
        downloadClaims: false,
        downloadCommissionStatements: false,
        downloadeDocs: false
      }
    });
  };

  const handleSubmission = () => {
    const data = {
      agency_name: activeAgencyName,
      agency_number: agencyCode,
      submitted_by: firstAndLastName,
      contact_name: values.interfaceContactName,
      contact_email: values.interfaceContactEmail,
      managment_system: values.managementSystem,
      download_commercial_lines: values.downloadCommercialLines ? "Yes" : "No",
      policy_center_only: values.downloadServiceCenter ? "Yes" : "No",
      need_initial_policy_load: values.initialPolicyLoad ? "Yes" : "No",
      account_id: values.accountID,
      user_id: values.userID,
      comments: values.comments
    };

    if (isValidManagementSystem(values.managementSystem, "claims")) {
      data.download_claims = values.downloadClaims ? "Yes" : "No";
    }
    if (isValidManagementSystem(values.managementSystem, "commission")) {
      data.download_direct_bill_commission_statement =
        values.downloadCommissionStatements ? "Yes" : "No";
    }
    if (isValidManagementSystem(values.managementSystem, "docs")) {
      data.download_edoc = values.downloadeDocs ? "Yes" : "No";
    }

    setIsSubmitLoading(true);
    agencyChecklistSubmit(data).then(
      res => {
        setIsSubmitLoading(false);
        closeAndReset();
        toast.dismiss();
        setShowSuccessModal(true);
        return res.data;
      },
      _err => {
        setIsSubmitLoading(false);
        displayToastError();
      }
    );
  };

  return (
    <>
      <Fragment>
        <Modal
          title="Agency Interface"
          show={agencyInterfaceChecklistShow}
          onHide={closeAndReset}
          className="agency-interface-modal"
          subTitle="Send UFG Information about your agency for initial set-up."
          body={
            <>
              <Form className="agency_interface__form" context={form}>
                <FormGroup>
                  <Input
                    placeholder="Interface Contact Name"
                    id="interfaceContactName"
                    name="interfaceContactName"
                    label="Interface Contact Name"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.interfaceContactName}
                    required
                    size="50p"
                  />
                  <InputEmail
                    id="interfaceContactEmail"
                    name="interfaceContactEmail"
                    label="Interface Contact Email"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.interfaceContactEmail}
                    required
                    size="50p"
                  />
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    id="managementSystem"
                    name="managementSystem"
                    placeholder="--Select System --"
                    label="Select Management System"
                    options={MANAGEMENT_SYSTEM}
                    onChange={handleManagementSystemChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.managementSystem}
                    size="50p"
                    required
                    isClearable={false}
                  />
                </FormGroup>
                <div className="row agency_interface__text">
                  <p className="mb-1">
                    <i>{Translations.agency_interface.management}</i>
                  </p>
                  {isValidManagementSystem(
                    values.managementSystem,
                    INTERFACE_CODES.agencyNote
                  ) && (
                    <div>
                      <p>
                        <b>{Translations.agency_interface.note}</b>
                        {Translations.agency_interface.additionalSetup}
                      </p>
                      <ul>
                        <li>{Translations.agency_interface.optionCompany}</li>
                        <li>{Translations.agency_interface.optionRenewal}</li>
                      </ul>
                      {Translations.agency_interface.loseData}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  <b>{Translations.agency_interface.downloadOptions}</b>
                </div>
                <div className="mt-1">
                  <i>{Translations.agency_interface.requiredOptions}</i>
                </div>
                <FormGroup align="right">
                  <Switch
                    id="downloadCommercialLines"
                    name="downloadCommercialLines"
                    label={
                      Translations.agency_interface.downloadCommercialLines
                    }
                    noLabel
                    onChange={handleCommercialLinesChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.downloadCommercialLines}
                    size="full"
                  />
                  {!!values?.downloadCommercialLines && (
                    <>
                      {hasServiceCenterAccess && (
                        <div className="nested-download-switch ml-5">
                          <Switch
                            id="downloadServiceCenter"
                            name="downloadServiceCenter"
                            label={
                              Translations.agency_interface
                                .downloadServiceCenter
                            }
                            noLabel
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                            onValidate={handleOnValidate}
                            value={values.downloadServiceCenter}
                            size="full"
                          />
                        </div>
                      )}
                      <div className="nested-download-switch ml-5">
                        <Switch
                          id="initialPolicyLoad"
                          name="initialPolicyLoad"
                          label={
                            Translations.agency_interface.initialPolicyLoad
                          }
                          noLabel
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values.initialPolicyLoad}
                          size="full"
                        />
                      </div>
                    </>
                  )}
                </FormGroup>

                <FormGroup>
                  {isValidManagementSystem(
                    values.managementSystem,
                    INTERFACE_CODES.claims
                  ) && (
                    <Switch
                      id="downloadClaims"
                      name="downloadClaims"
                      label={Translations.agency_interface.downloadClaims}
                      noLabel
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.downloadClaims}
                      size="full"
                    />
                  )}
                  {isValidManagementSystem(
                    values.managementSystem,
                    INTERFACE_CODES.commission
                  ) && (
                    <Switch
                      id="downloadCommissionStatements"
                      name="downloadCommissionStatements"
                      label={
                        Translations.agency_interface
                          .downloadCommissionStatements
                      }
                      noLabel
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.downloadCommissionStatements}
                      size="full"
                    />
                  )}
                  {isValidManagementSystem(
                    values.managementSystem,
                    INTERFACE_CODES.docs
                  ) && (
                    <Switch
                      id="downloadeDocs"
                      name="downloadeDocs"
                      label={Translations.agency_interface.downloadeDocs}
                      noLabel
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.downloadeDocs}
                      size="full"
                    />
                  )}
                </FormGroup>
                <div className="row agency_interface__text">
                  <b>{Translations.agency_interface.ivans}</b>
                </div>
                <FormGroup>
                  <Input
                    id="accountID"
                    name="accountID"
                    label={Translations.agency_interface.accountID}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleIDValidate}
                    value={values.accountID}
                    size="md"
                    maxLength={10}
                  />
                  <Input
                    id="userID"
                    name="userID"
                    label={Translations.agency_interface.userID}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleIDValidate}
                    value={values.userID}
                    size="md"
                    maxLength={10}
                  />
                  <TextArea
                    id="comments"
                    name="comments"
                    label={Translations.agency_interface.comments}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values.comments}
                  />
                </FormGroup>
              </Form>
              <div className="modal-form-buttons">
                <Button
                  id="agency-interface-modal-cancel-button"
                  type="reset"
                  variant="plain"
                  onClick={closeAndReset}
                >
                  Cancel
                </Button>
                <Button
                  id="agency-interface-modal-submit-button"
                  type="submit"
                  variant="primary"
                  spinnerPos="right"
                  spinner={isSubmitLoading}
                  onClick={handleSubmission}
                  disabled={
                    invalidFields.length > 0 ||
                    // Disabled if all switches are false
                    (!values.downloadCommercialLines &&
                      !values.downloadClaims &&
                      !values.downloadeDocs &&
                      !values.downloadCommissionStatements) ||
                    isSubmitLoading
                  }
                >
                  Submit
                </Button>
              </div>
            </>
          }
        />
      </Fragment>
      <ConfirmationModal
        className="agency_interface_checklist__success-modal"
        id="agency_interface_checklist-success-modal"
        show={showSuccessModal}
        altCloseMethod
        closeIcon
        title="Agency Interface"
        message={
          <div className="row">
            <div className="col-md-1">
              <Icon
                size="2x"
                icon="farCheck"
                className="agency-interface-checklist-icon"
              />
            </div>
            <div className="col-md-11">
              <p>
                {Translations.agency_interface.successMessage}
                {Translations.agency_interface.interfaceSetup}
              </p>
              <p>
                {" "}
                <HelpDeskMessage before="Questions?" />
              </p>
            </div>
          </div>
        }
        footer={
          <>
            <Button
              id="agency_interface_checklist-close-button"
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
};

AgencyInterfaceChecklist.propTypes = {
  firstAndLastName: PropTypes.string,
  agentEmail: PropTypes.string,
  activeAgencyName: PropTypes.string,
  agencyCode: PropTypes.string,
  hasServiceCenterAccess: PropTypes.bool
};

export default AgencyInterfaceChecklist;
