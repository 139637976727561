import * as types from "../constants/actionTypes";
import * as newsService from "../services/newsService";
import { logger } from "../loggers";

const fetchNewsArchivesActionStarted = () => ({
  type: types.FETCH_NEWS_ARCHIVE_STARTED
});

const fetchNewsArchivesActionSucceeded = data => ({
  type: types.FETCH_NEWS_ARCHIVE_SUCCEEDED,
  payload: data
});

const fetchNewsArchivesActionFailed = error => ({
  type: types.FETCH_NEWS_ARCHIVE_FAILED,
  payload: error
});

// Async action creator using thunk middleware
export function fetchNewsArchives(agentState, company_branch) {
  return dispatch => {
    dispatch(fetchNewsArchivesActionStarted());
    return newsService.getNewsArchives(agentState, company_branch).then(
      response => {
        dispatch(fetchNewsArchivesActionSucceeded(response.data));
      },
      error => {
        dispatch(fetchNewsArchivesActionFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
}
