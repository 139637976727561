import React from "react";
import { NewBusinessProvider } from "../context";
import TableOfContents from "./TableOfContents";
import Content from "./Content";
import Breadcrumbs from "./Breadcrumbs";

export default function NewBusinessProcedures() {
    return (
        <NewBusinessProvider>
            <Breadcrumbs />
            <Content />
            <TableOfContents />
        </NewBusinessProvider>
    );
}
