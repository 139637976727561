import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import qs from "query-string";
import { Translations } from "../../components/translations";
import CustomErrorView from "../../components/CustomErrorView";
import {
  Button,
  FlexRow,
  Modal,
  Anchor,
  ExternalLink
} from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const MakeAPayment = ({
  paymentDetails,
  handleClose,
  accountData,
  checkPaymentEligibility,
  agency_number,
  history
}) => {
  const [searchNumber, setsearchNumber] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [allowRedirection, setallowRedirection] = useState(false);
  const AGENCY_BILL_ERROR_MESSAGE = "AGENCY_BILL_ERROR_MESSAGE";
  const WORKERS_COMPENSATION_ERROR_MESSAGE =
    "WORKERS_COMPENSATION_ERROR_MESSAGE";

  const isAccountDetailsPage = useCallback(() => {
    const path = window.location.pathname.split("/");
    if (!(path[1] === "account" && path[2] && path[2].length > 0)) return false;
    return Object.keys(accountData.accounts).length > 0;
  }, [accountData]);

  const setStateFromAccountData = useCallback(() => {
    const { account_number, address } = accountData.accounts;
    if (searchNumber.length > 0) return;
    setsearchNumber(account_number ? account_number : "");
    setzipCode(address.zip ? address.zip.split("-")[0] : "");
  }, [accountData.accounts, searchNumber]);

  useEffect(() => {
    if (isAccountDetailsPage()) setStateFromAccountData();
  }, [setStateFromAccountData, isAccountDetailsPage]);

  const handleAccountNumberChange = e => {
    setsearchNumber(e.target.value);
    e.preventDefault();
  };

  const handleZipChange = e => {
    setzipCode(e.target.value);
    e.preventDefault();
  };

  const validatePaymentEligibility = () => {
    checkPaymentEligibility(searchNumber, agency_number, zipCode);
  };

  const handlePaymentClose = () => {
    setsearchNumber("");
    setzipCode("");
    handleClose();
  };

  const subTitleText = () => {
    return (
      <div>
        <p>{Translations.payment.details}</p>
        <p className="italic">{Translations.payment.payments_tip}</p>
      </div>
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    setallowRedirection(true);
    validatePaymentEligibility();
  };

  const renderErrorStatus = () => {
    let errorMessage = undefined;

    const handleIVRError = payment => {
      const { return_code, eligibility } = payment;
      if (!return_code) return null;
      if (eligibility) return null;
      const ivrCode = parseInt(return_code);
      errorMessage = {
        data: {
          status: 400,
          message:
            ivrCode === 1 ? (
              Translations.payment.ivr.unrecognized_account
            ) : ivrCode === 2 ? (
              Translations.payment.ivr.not_eligible
            ) : ivrCode === 3 ? (
              Translations.payment.ivr.policy_billed_by_account
            ) : ivrCode === 7 ? (
              AGENCY_BILL_ERROR_MESSAGE
            ) : ivrCode === 8 ? (
              WORKERS_COMPENSATION_ERROR_MESSAGE
            ) : (
              <HelpDeskMessage
                display="questionsOrAssistance"
                before="The information you entered could not be found. Please ensure that you entered it correctly."
              />
            )
        }
      };
    };

    const handleHttpError = e => {
      if (!e) return null;
      const error_code =
        e && e.data && e.data.error_code ? Number(e.data.error_code) : null;
      errorMessage = {
        data: {
          status: 400,
          message:
            error_code === 206 ? (
              Translations.payment.ivr.unrecognized_account
            ) : (
              <HelpDeskMessage
                display="questionsOrAssistance"
                before="The information you entered could not be found. Please ensure that you entered it correctly."
              />
            )
        }
      };
    };

    const { error, payment } = paymentDetails;
    handleHttpError(error);
    if (!errorMessage) handleIVRError(payment);

    return getErrorComponent(errorMessage);
  };

  const getErrorComponent = errorMessage => {
    if (errorMessage?.data?.message === AGENCY_BILL_ERROR_MESSAGE) {
      return (
        <p className={`alert alert-danger alertNoMargin`}>
          This is an Agency Bill policy. Please view, modify, and/or pay your
          statement under <br />
          <Anchor to="/" onClick={handleAgencyBillEpayClick}>
            {"Reports > Accounting > Agency Bill E-Pay"}
          </Anchor>
        </p>
      );
    } else if (
      errorMessage?.data?.message === WORKERS_COMPENSATION_ERROR_MESSAGE
    ) {
      return (
        <p className={`alert alert-danger alertNoMargin`}>
          This {"workers'"} compensation policy is enrolled in Pay as You Go
          billing. For additional information, contact{" "}
          <ExternalLink to="https://www.smartpayllc.com" target="_blank">
            SmartPay Solutions
          </ExternalLink>{" "}
          by email at{" "}
          <Anchor href="mailto:support@smartpayllc.com">
            support@smartpayllc.com
          </Anchor>{" "}
          or by phone at{" "}
          <span className="smart-pay-phone-number-label">877-905-0786</span>
          <Anchor
            href="tel:8779050786"
            className="smart-pay-mobile-phone-number-link"
          >
            877-905-0786
          </Anchor>{" "}
          8 a.m. to 8 p.m. ET Monday-Friday.
        </p>
      );
    }
    return errorMessage ? <CustomErrorView error={errorMessage} /> : null;
  };

  const isEligibleForMakePayment = () => {
    if (paymentDetails.isLoading) return false;
    return JSON.parse(paymentDetails.payment.eligibility);
  };

  const redirectToPaymentPage = () => {
    if (!allowRedirection) return;
    setallowRedirection(false);
    const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
    const { payment } = paymentDetails;

    if (payment.origin && payment.origin.toLowerCase() === "mercer") {
      window.location = `${baseLink}/payments/Mercer/${searchNumber}`;
    } else {
      const queryString = qs.stringify({
        type: payment.user_account_type,
        minimum: payment.minimum_amount,
        balance: payment.total_amount,
        CancellationUrl: window.location.href
      });
      window.location = `${baseLink}/payments/ExpressBill/${searchNumber}?${queryString}`;
    }
  };

  const handleAgencyBillEpayClick = () => {
    handleClose();
    history.push(
      `${routes.REPORTS}/${routes.REPORTS_ACCOUNTING_ACTIVE_TAB}/${routes.REPORTS_AGENCY_BILL_EPAY}`
    );
  };

  const areInputsValid = searchNumber.length >= 4 && zipCode.length === 5;
  const show = paymentDetails.isActivated;
  const isLoading = paymentDetails.isLoading;

  if (isEligibleForMakePayment()) redirectToPaymentPage();

  return (
    <div>
      <div className="custom-modal-payment">
        {show && (
          <Modal
            title={Translations.payment.make_a_payment}
            onHide={handlePaymentClose}
            size="lg"
            body={
              <>
                <div className="row">
                  <div>{renderErrorStatus()}</div>
                </div>
                <div className="row payment-flex-container">
                  <div className="payment-flex-left-right">
                    <div>
                      <form>
                        <div className="row">
                          <FlexRow>
                            <div>
                              <b>Direct Bill</b>
                            </div>
                          </FlexRow>
                          <FlexRow>{subTitleText()}</FlexRow>
                          <div className="form-group bs-form-group-reset">
                            <label
                              htmlFor="AccountNumber"
                              className="bs-label-reset"
                            >
                              {Translations.payment.account_placeholder}
                            </label>
                            <input
                              type="text"
                              id="AccountNumber"
                              name="AccountNumber"
                              onChange={handleAccountNumberChange}
                              onBlur={handleAccountNumberChange}
                              defaultValue={searchNumber}
                              required
                              className="payment-input form-control"
                              disabled={isLoading}
                            />
                          </div>
                          <div className="form-group bs-form-group-reset">
                            <label htmlFor="ZipCode" className="bs-label-reset">
                              {Translations.payment.zip_placeholder}
                            </label>
                            <input
                              type="number"
                              id="ZipCode"
                              name="ZipCode"
                              className="payment-input form-control"
                              onChange={handleZipChange}
                              onBlur={handleZipChange}
                              defaultValue={zipCode}
                              required
                              disabled={isLoading}
                            />
                          </div>
                          <FlexRow align="left">
                            <Button
                              type="submit"
                              variant="primary"
                              id="makePayment"
                              disabled={!areInputsValid || isLoading}
                              spinnerPos="right"
                              spinner={isLoading}
                              onClick={handleSubmit}
                            >
                              Pay Now
                            </Button>
                          </FlexRow>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row payment-flex-center">
                    <div className="divider">OR</div>
                  </div>
                  <div className="payment-flex-left-right">
                    <div className="row">
                      <FlexRow>
                        <div>
                          <b>Agency Bill</b>
                        </div>
                      </FlexRow>
                      <FlexRow>
                        <p>
                          View, modify, and/or pay your agency bill statement
                          under{" "}
                          <Anchor to="/" onClick={handleAgencyBillEpayClick}>
                            {"Reports > Accounting > Agency Bill E-Pay"}
                          </Anchor>
                        </p>
                      </FlexRow>
                    </div>
                  </div>
                </div>
              </>
            }
            show={show}
          />
        )}
      </div>
    </div>
  );
};

MakeAPayment.propTypes = {
  paymentDetails: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  accountData: PropTypes.object,
  checkPaymentEligibility: PropTypes.func.isRequired,
  agency_number: PropTypes.string,
  history: PropTypes.object.isRequired
};

export default withRouter(MakeAPayment);
