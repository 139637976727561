import React from "react";
import LobCoverages from "./LobCoverages";
import { PanelGrid } from "@ufginsurance/ui-kit";
import Vehicles from "./Vehicles";
import Drivers from "./Drivers";
import StateSpecific from "./StateSpecific";

const AutoSummary = ({ coverageExclusions }) => {
  return (
    <PanelGrid
      sizes={{ sm: 1, md: 2, lg: 2, xl: 2 }}
      className="oq__quote-summary__tab"
    >
      <div className="oq__quote-summary__column">
        <LobCoverages
          lobName="Auto"
          lobKey="ca7CommAuto"
          coverageExclusions={coverageExclusions}
        />
        <StateSpecific
          lobKey={"ca7CommAuto"}
          coverageExclusions={coverageExclusions}
        />
      </div>
      <div className="oq__quote-summary__column">
        <Drivers />
        <Vehicles coverageExclusions={coverageExclusions} />
      </div>
    </PanelGrid>
  );
};

export default AutoSummary;
