import React, { useContext } from "react";

import { Currency } from "@ufginsurance/ui-kit";

import { YNToBool, toCommaNumber } from "../../shared/util";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const BuildingCardContents = ({ building }) => {
  const { supportingData, get } = useContext(OnlineQuotingContext);

  const location = get.location({ bp7LocationFixedID: building.locationId });

  const currentYear = new Date().getFullYear();

  //check if Year fields are visible
  const yearFieldsVisibile = () => {
    return (
      Number(building.yearBuilt) > 1000 &&
      currentYear - Number(building.yearBuilt) > 20
    );
  };

  //if if condominium Sq Ft is visible
  const CondSqrFtVisible = () => {
    let nonCondominiumBoolean = false;
    building.classifications.forEach(data => {
      if (data.classPropertyType !== "Office Condominium") {
        nonCondominiumBoolean = true;
      }
    });
    return (
      !nonCondominiumBoolean &&
      building.percentOccupiedByOwner === "10% or less"
    );
  };

  //check if Liability Exposure is visible
  const liabExpVisible = classData => {
    return (
      classData?.liabilityExposureBase === "Annual Gross Sales" ||
      classData?.liabilityExposureBase === "Annual Payroll"
    );
  };

  //check if Condo By Law is visible
  const condoByLawVisible = classData => {
    const selectedState = location?.address?.state;
    return (
      selectedState &&
      selectedState === "PA" &&
      classData?.classDescription ===
        "Condominiums - Residential Condominium (Association risk only)"
    );
  };

  //check if Townhouse By Law is visible
  const townhouseByLawVisibile = classData => {
    const selectedState = location?.address?.state;
    return (
      selectedState &&
      selectedState === "MN" &&
      (classData?.classDescription ===
        "Townhouses or Similar Associations - Over 4 families with mercantile or office occupancy" ||
        classData?.classDescription ===
          "Townhouses or Similar Associations - Over 4 families with no mercantile or office occupancy" ||
        classData?.classDescription ===
          "Townhouses or Similar - 4 families or less, w/mercantile or office occupancy - lessor's risk only")
    );
  };

  //get Liability Exposure
  const liabilityExposure = classData => {
    const identifier = `${classData.classCode}|${
      classData.northAmericanIndustryClassificationSystemNAICSCode
    }|${
      classData.standardIndustrialClassificationSICCode
    }|${classData?.classDescription?.replace(/\s/g, "")}`;

    return classData?.exposure
      ? classData.exposure
      : supportingData?.buildings?.[building?.fixedId]?.[
          identifier + "~exposure"
        ] || "";
  };

  //get Liability Exposure
  const grossSales = classData => {
    const identifier = `${classData.classCode}|${
      classData.northAmericanIndustryClassificationSystemNAICSCode
    }|${
      classData.standardIndustrialClassificationSICCode
    }|${classData?.classDescription?.replace(/\s/g, "")}`;

    return classData?.grossSales_UFG
      ? classData.grossSales_UFG
      : supportingData?.buildings?.[building?.fixedId]?.[
          identifier + "~grossSales_UFG"
        ] || "";
  };

  const extras = [];

  if (YNToBool(building?.basementPresent)) extras.push("Basement");
  if (YNToBool(building?.automaticSprinklerSystemPresent))
    extras.push("Automatic Sprinkler");

  //check if mine subsidence indicator is visible
  const mineSubVisible = () => {
    const selectedState = location?.address?.state;
    return selectedState === "IL" || selectedState === "OH";
  };
  if (YNToBool(building?.mineSubsidenceApplies) && mineSubVisible)
    extras.push("Mine Subsidence");

  const coverableDetailItems = [
    { label: "Year Built", value: building.yearBuilt },
    { label: "Number of Stories", value: building.numberOfStories },
    { label: "Construction Type", value: building.constructionType },
    { label: "Roof Type", value: building.roofType },
    {
      label: "Total Condominium Building Sq Ft",
      value: building.totalCondominiumBuildingSquareFootage,
      visible: CondSqrFtVisible()
    },
    {
      label: "Year Roofing Replaced",
      value: building.yearRoofingReplaced,
      visible: yearFieldsVisibile()
    },
    {
      label: "Year Plumbing Replaced",
      value: building.yearPlumbingReplaced,
      visible: yearFieldsVisibile()
    },
    {
      label: "Year Heating Replaced",
      value: building.yearHeatingReplaced,
      visible: yearFieldsVisibile()
    },
    {
      label: "Year Wiring Replaced",
      value: building.yearWiringReplaced,
      visible: yearFieldsVisibile()
    },
    {
      label: "Building Features",
      value: extras.join(", "),
      visible: extras && extras.length > 0
    }
  ];

  return (
    <div className="oq__coverable__card__content">
      <div className="oq__coverable__card__content__column-container">
        <div className="oq__coverable__card__content__column">
          <ul className="">
            {coverableDetailItems.map(c => {
              if (c.visible !== false)
                return (
                  <li
                    key={c.label}
                    className="oq__coverable__card__content__item"
                  >
                    <b className="oq__coverable__card__content__item-label">
                      {c.label}:
                    </b>
                    <span className="oq__coverable__card__content__item-value">
                      {c.value}
                    </span>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
        <div className="oq__coverable__card__content__column oq__coverable__card__building__class-details">
          {building.classifications.map(classData => {
            return (
              <React.Fragment key={classData?.classDescription}>
                <b className="oq__coverable__card__content__class-header">
                  {classData?.classDescription}
                </b>
                <ul
                  className="oq__coverable__card__content__class"
                  key={classData.classCode}
                >
                  {classData?.classificationSquareFootage && (
                    <li>
                      <b>Classification Sq Ft:</b>
                      <span>
                        {toCommaNumber(classData?.classificationSquareFootage)}
                      </span>
                    </li>
                  )}
                  {liabExpVisible(classData) && (
                    <li>
                      <b>{classData?.liabilityExposureBase}:</b>
                      <span>
                        <Currency
                          includeDecimals={false}
                          amount={liabilityExposure(classData)}
                        />
                      </span>
                    </li>
                  )}
                  {!liabExpVisible(classData) && (
                    <li>
                      <b>Annual Gross Sales:</b>
                      <span>
                        <Currency
                          includeDecimals={false}
                          amount={grossSales(classData)}
                        />
                      </span>
                    </li>
                  )}
                  {condoByLawVisible(classData) && (
                    <li>
                      <b>Condo By Laws &amp; Condo Residence:</b>
                      <span>{classData?.condoByLaws || "No"}</span>
                    </li>
                  )}
                  {townhouseByLawVisibile(classData) && (
                    <li>
                      <b>Townhouse By Laws &amp; Townhouse Assoc:</b>
                      <span>{classData?.townhouseByLaw || "No"}</span>
                    </li>
                  )}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BuildingCardContents;
