import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";
import { v4 } from "uuid";
import { INVALID_COMPANY_BRANCH } from "../constants";

const agentManualReducer = (state = initialState.agentManuals, action) => {
  switch (action.type) {
    case types.FETCH_STATES_LIST_STARTED:
      return {
        ...state,
        states: {
          stateListData: [],
          isLoading: true,
          error: null,
          previousCompanyBranch: INVALID_COMPANY_BRANCH
        }
      };
    case types.FETCH_STATES_LIST_SUCCEEDED:
      return {
        ...state,
        states: {
          stateListData: action.payload.map(stateRecord => ({
            id: v4(),
            value: stateRecord.id,
            label: stateRecord.state
          })),
          isLoading: false,
          error: null,
          previousCompanyBranch: action.previousCompanyBranch
        }
      };
    case types.FETCH_STATES_LIST_FAILED:
      return {
        ...state,
        states: {
          stateListData: [],
          isLoading: false,
          error: action.payload,
          previousCompanyBranch: action.previousCompanyBranch
        }
      };
    case types.SET_SELECTED_STATE:
      return {
        ...state,
        selectedState: action.payload
      };
    case types.FETCH_STATE_PRODUCTS_STARTED:
      return {
        ...state,
        products: {
          stateProducts: [],
          isLoading: true,
          error: null,
          previousFetchedStateId: null
        }
      };
    case types.FETCH_STATE_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        products: {
          stateProducts: action.payload,
          isLoading: false,
          error: null,
          previousFetchedStateId: action.previousFetchedStateId
        }
      };
    case types.FETCH_STATE_PRODUCTS_STARTED_FAILED:
      return {
        ...state,
        products: {
          stateProducts: [],
          isLoading: false,
          error: action.payload,
          previousFetchedStateId: action.previousFetchedStateId
        }
      };
    case types.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      };
    case types.FETCH_TABLE_OF_CONTENTS_STARTED:
      return {
        ...state,
        tableOfContents: {
          data: [],
          isLoading: true,
          error: null,
          previousFetchedProductId: null
        }
      };
    case types.FETCH_TABLE_OF_CONTENTS_SUCCEEDED:
      return {
        ...state,
        tableOfContents: {
          data: action.payload,
          isLoading: false,
          error: null,
          previousFetchedProductId: action.productId
        }
      };
    case types.FETCH_TABLE_OF_CONTENTS_FAILED:
      return {
        ...state,
        tableOfContents: {
          data: [],
          isLoading: false,
          error: action.payload,
          previousFetchedProductId: action.productId
        }
      };
    case types.FETCH_CONTENT_STARTED:
      return {
        ...state,
        content: {
          data: null,
          isLoading: true,
          error: null,
          previousFetchedContentId: null
        }
      };
    case types.FETCH_CONTENT_SUCCEEDED:
      return {
        ...state,
        content: {
          data: action.payload,
          isLoading: false,
          error: null,
          previousFetchedContentId: action.previousFetchedContentId
        }
      };
    case types.FETCH_CONTENT_FAILED:
      return {
        ...state,
        content: {
          data: null,
          isLoading: false,
          error: action.payload,
          previousFetchedContentId: action.previousFetchedContentId
        }
      };
    default:
      return state;
  }
};

export default agentManualReducer;
