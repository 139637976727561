export const policyNumberRegex = /^[0-9\b]+$/;

export const isActiveLink = (link, match, location) => {
  const isRoot = (path) => path.split("/").filter(_ => _ !== "").length === 1;
  const getRoot = (path) => path.split("/").filter(_ => _ !== "")[0] || "";
  const isRootPath = isRoot(location.pathname);
  const isRootLink = isRoot(link.to);
  return (!isRootLink && location.pathname.startsWith(link.to))
    || (
      isRootPath
      && isRootLink
      && getRoot(location.pathname) === getRoot(link.to)
    );
};
