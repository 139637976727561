import React, { useCallback, useEffect, useState } from "react";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import API from "../api";
import {
  useForm,
  Form,
  FormGroup,
  TextArea,
  Select,
  Button,
  LoadingIndicator,
  ContentHeader
} from "@ufginsurance/ui-kit";
import { useFlags } from "launchdarkly-react-client-sdk";
import { toast } from "react-toastify";
import { diffLines } from "diff";
import { isJsonString, guid } from "../online-quoting/shared/util";

import "./ProductManagement.scss";

const ProductManagement = ({ employeeUsername }) => {
  const [currentState, setCurrentState] = useState();
  const [gettingState, setGettingState] = useState();
  const [currentStateVersions, setCurrentStateVersions] = useState();
  const [latestVersion, setLatestVersion] = useState();
  const [saving, setSaving] = useState();

  const { agentPortalAdminAccess } = useFlags();

  const statesList =
    // ALL STATES
    // "AK,AL,AR,AS,AZ,CA,CO,CT,DC,DE,FL,GA,GU,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MP,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,PR,RI,SC,SD,TN,TX,UM,UT,VA,VI,VT,WA,WI,WV,WY"
    // PROQUOTE STATES
    "AL,AR,AZ,CA,CO,FL,IA,ID,IL,IN,KS,KY,LA,MN,MO,MS,MT,ND,NE,NJ,NM,NV,OH,OK,OR,PA,SD,TN,TX,UT,WI,WY"
      .split(",")
      .map(s => {
        return {
          label: s,
          value: s
        };
      });

  const onSubmit = async ({ values }) => {
    const { versionDescription, productMatrix } = values;

    const payload = {
      versionDescription,
      productMatrix: JSON.parse(productMatrix)
    };
    setSaving(true);
    const save = await API.aws("adminProQuoteProductMatrix")
      .post(`state/${currentState}`, payload, {
        headers: { "Content-Type": "application/json" }
      })
      .then(
        () => {},
        error => {
          console.error(error);
          toast(`Error updating ${currentState}`, {
            position: "top-center",
            className: "error",
            autoClose: false
          });
        }
      )
      .finally(() => setSaving(false));

    if (save) {
      toast(`${currentState} updated successfully`, {
        position: "top-center",
        className: "success",
        autoClose: true
      });

      // update version list and data
      fetchVersionsForState(currentState);
    }
  };

  const form = useForm({
    values: {
      versions: "",
      USstate: "",
      versionDescription: "",
      productMatrix: "",
      user: employeeUsername
    },
    onSubmit
  });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const fetchVersionsForState = useCallback(
    USstate => {
      // call API to get versions of state data
      setGettingState(true);
      API.aws("adminProQuoteProductMatrix")
        .get(`state/${USstate}`)
        .then(
          response => {
            setCurrentState(USstate);
            if (response?.data?.versions?.length) {
              const versions = response.data.versions;
              setCurrentStateVersions(versions);

              const latestVersionProductMatrix = JSON.stringify(
                JSON.parse(versions[0].productMatrix),
                null,
                2
              );
              updateForm({
                values: {
                  productMatrix: latestVersionProductMatrix,
                  versions: "0",
                  versionDescription: ""
                }
              });
              setLatestVersion(latestVersionProductMatrix);
            } else {
              // reset form on no versions
              updateForm({
                values: {
                  productMatrix: "",
                  versions: "",
                  versionDescription: ""
                }
              });
              toast(`No data for state: ${USstate}`, {
                position: "top-center",
                className: "info",
                autoClose: true
              });
              setCurrentStateVersions([]);
              setLatestVersion("");
            }
          },
          error => {
            console.error(error);
            // reset form on errors
            updateForm({
              values: {
                productMatrix: "",
                versions: "",
                versionDescription: ""
              }
            });
            toast(`Error getting data for state: ${USstate}`, {
              position: "top-center",
              className: "error",
              autoClose: false
            });
          }
        )
        .finally(() => setGettingState(false));
    },
    [updateForm]
  );

  useEffect(() => {
    if (!gettingState && values.USstate && values.USstate !== currentState) {
      const { USstate } = values;
      fetchVersionsForState(USstate);
    }
  }, [currentState, gettingState, fetchVersionsForState, updateForm, values]);

  const handleVersionChange = ({ field, value }) => {
    const newVersionIndex = value;
    const selectedVersion = JSON.stringify(
      JSON.parse(currentStateVersions[newVersionIndex].productMatrix),
      null,
      2
    );
    updateForm({
      values: {
        [field]: value,
        productMatrix: selectedVersion
      }
    });
  };

  const DifferenceFromLatestVersion = () => {
    if (
      latestVersion &&
      values.productMatrix &&
      isJsonString(values.productMatrix)
    ) {
      const Old = JSON.stringify(JSON.parse(latestVersion), null, 2);
      const New = JSON.stringify(JSON.parse(values.productMatrix), null, 2);

      return diffLines(Old, New, {
        ignoreWhitespace: false,
        newlineIsToken: true
      }).map(part => {
        // green for additions
        // red for deletions
        // grey for common parts
        const color = part.added ? "green" : part.removed ? "red" : "grey";
        return (
          <div className={color} key={guid()}>
            {part.value}
          </div>
        );
      });
    }

    // else
    return null;
  };

  const noChangesToJson =
    JSON.stringify(values.productMatrix) === JSON.stringify(latestVersion);

  return (
    <div id="proquote-product-management">
      <ContentHeader>ProQuote Manage Product Matrix</ContentHeader>

      <Form context={form}>
        <FormGroup>
          <Select
            id="USstate"
            name="USstate"
            label="Select a State"
            options={statesList}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.USstate}
            size="md"
            required
            disabled={gettingState}
          />
          {currentStateVersions && (
            <Select
              id="versions"
              name="versions"
              label={
                <div>
                  Available Versions{" "}
                  <small>(selcting replaces data below)</small>
                </div>
              }
              options={currentStateVersions.map((v, i) => {
                const d = new Date(v.updatedTime * 1000);
                return {
                  label: `${d.toLocaleString()} - ${v.user} - ${
                    v.versionDescription
                  }`,
                  value: String(i)
                };
              })}
              onChange={handleVersionChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.versions}
              size="fill"
              disabled={gettingState || currentStateVersions?.length === 0}
              isClearable={false}
            />
          )}
        </FormGroup>
        {gettingState ? (
          <LoadingIndicator />
        ) : (
          values.USstate && (
            <>
              <FormGroup>
                <TextArea
                  style={{ height: "1400px" }}
                  id="productMatrix"
                  name="productMatrix"
                  label={<span>Product data for {values.USstate}</span>}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.productMatrix}
                  required
                  className="w50p"
                />

                <div className="difference-wrapper">
                  <p>Changes from latest version:</p>
                  {isJsonString(values.productMatrix) ? (
                    <DifferenceFromLatestVersion />
                  ) : (
                    <code>Input is not valid JSON</code>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <TextArea
                  id="versionDescription"
                  name="versionDescription"
                  label={"Version Description"}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.versionDescription}
                  required
                />
                <div>User: {employeeUsername}</div>
              </FormGroup>

              {agentPortalAdminAccess === "READ_WRITE" && (
                <FormGroup>
                  <Button
                    type="submit"
                    spinner={saving}
                    disabled={
                      // if data is unchanged or is invalid JSON
                      // ... or no version descript
                      // ... or is saving
                      noChangesToJson ||
                      !isJsonString(values.productMatrix) ||
                      !values.versionDescription ||
                      saving
                    }
                  >
                    Submit
                  </Button>
                </FormGroup>
              )}
            </>
          )
        )}
      </Form>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    employeeUsername: sessionSelector.getEmployeeUserName(state)
  };
};

export default connect(mapStateToProps, {})(ProductManagement);
