import React, { useContext, useEffect } from "react";
import { FormGroup, Input, Form, useForm } from "@ufginsurance/ui-kit";
import { addUpdateArrayItem } from "../shared/util";
import OnlineQuotingContext from "../OnlineQuotingContext";
import { stateSpecificFields } from "../shared/constants";

const StateSpecificIds = ({
  formIsValid,
  setFormIsValid,
  triggerFormValidation
}) => {
  const {
    quoteData,
    updateBindingData,
    toastErrr,
    quoteIsUpdating,
    supportingData,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);

  // ----- Setup default values of field visibility in the show state -----

  // It's important for the default state to be set in order for the form
  // ... to correctly understand which fields are invalid

  let renderedStatefields = [];

  const initialValues = {};
  const dtoStateIds = quoteData?.bindData?.officialStateIds_UFG || [];

  const fldValue = ({ f, currentState }) => {
    if (!f) return { value: null, hasSupData: null }; // should really only get hit if no related field
    const value = f.nonStateField
      ? quoteData?.bindData?.[f.nonStateField]
      : dtoStateIds.find(j => j.jurisdiction === currentState)?.[f.name];
    const hasSupData = supportingData?.stateIdsStep6?.hasOwnProperty(
      `${currentState}_${f.name}`
    );
    return { value, hasSupData };
  };

  dtoStateIds.forEach(idObj => {
    const currentState = idObj?.jurisdiction;
    // get field data for each state... fail to empty array if no state fields exist
    const stateFieldObj = stateSpecificFields(currentState);

    if (stateFieldObj) {
      const allFieldsForState = stateFieldObj?.fields || [];
      allFieldsForState.forEach(f => {
        // get values for field and related fields
        const fld = fldValue({ f, currentState });
        const relatedFld = fldValue({
          f: allFieldsForState.find(x => x.name === f.relatedField),
          currentState
        });

        // determine on whether the field should be rendered on page
        if (
          (!fld.value && !relatedFld.value) ||
          fld.hasSupData ||
          relatedFld.hasSupData
        ) {
          // if it's rendered, then include the initialValue
          initialValues[`${currentState}_${f.name}`] = fld.value || "";

          const existingFields = renderedStatefields.find(
            s => s.stateCode === currentState
          );
          // if fields already exist for this state, then add this field to that currentState's collection
          if (existingFields) {
            existingFields.fields = [...existingFields.fields, f];
            renderedStatefields = addUpdateArrayItem(
              renderedStatefields,
              existingFields,
              "stateCode"
            );
          } else {
            // if not, then start a new currentState collection
            const _stateObj = {
              stateCode: currentState,
              stateName: stateFieldObj?.stateName,
              fields: [f]
            };
            renderedStatefields.push(_stateObj);
          }
        }
      });
    }
  });

  // form hook
  const form = useForm({ values: initialValues, onSubmit: () => {} });

  // ---- shared blur method used to update form values for:
  const handleBlurAndSave = ({
    field,
    value,
    usState,
    dtoField,
    label,
    nonStateField
  }) => {
    // callback to be run after the blur event checks the field is valid (in the handleOnBlur below)
    const callback = ({ error }) => {
      const dtoObject = (quoteData?.bindData?.officialStateIds_UFG || []).find(
        j => j.jurisdiction === usState
      );

      const originalValue = nonStateField
        ? quoteData?.bindData?.[nonStateField]
        : dtoObject?.[dtoField] || "";

      const _nonStateField = nonStateField ? { [nonStateField]: value } : {};

      const newBindingData = {
        ...quoteData.bindData,
        ..._nonStateField,
        officialStateIds_UFG: quoteData.bindData.officialStateIds_UFG.map(i => {
          if (i.jurisdiction === usState) return { ...i, [dtoField]: value };
          return i;
        })
      };

      // if there are no errors and the value has changed from the original value,
      // then send the value to PC
      if (
        (value === "" && originalValue) ||
        (value && value !== originalValue && !error.length)
      ) {
        const stateIdsStep6 = supportingData?.stateIdsStep6 || {};
        stateIdsStep6[field] = value;

        // after supporting data is updated, update pc data
        updateBindingData({
          bindingData: newBindingData,
          updateMessage: "Updating State IDs..."
        })
          .then(() => {
            updateSupportingDataPromise({
              dataToMergeAndSave: {
                stateIdsStep6
              }
            });
          })
          .catch(({ error }) =>
            // if the update to PC fails, reset the field back to the original value
            //and indicate user about the failure
            {
              updateForm({
                values: {
                  [field]: originalValue
                },
                errors: {
                  [field]: []
                }
              });
              //indicate user about the failure
              toastErrr({
                displayMessage: `An error occurred.  Unable to update ${label}.`,
                action: "handleBlurAndSave",
                misc: { bindingData: newBindingData },
                description: "update binding data failed",
                error
              });
            }
          );
      }
    };

    handleOnBlur({ field, value, callback });
  };

  const {
    updateForm,
    values,
    handleOnValidate,
    handleOnBlur,
    handleOnChange,
    invalidFields,
    errors,
    validateForm
  } = form;

  // Update the form state for the parent component to disable the bind button
  // ... if the fields are invalid
  useEffect(() => {
    const isValid = !invalidFields.length;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [formIsValid, setFormIsValid, invalidFields]);

  useEffect(() => {
    if (triggerFormValidation) validateForm();
  }, [triggerFormValidation, form, validateForm]);

  return (
    <>
      {renderedStatefields &&
        renderedStatefields.length > 0 &&
        !!(renderedStatefields || []).find(
          x => !!(x || []).fields.find(y => !!y?.nonStateField)
        ) && (
          <>
            <hr />
            <h4>State Specific Bureau IDs</h4>
          </>
        )}
      {renderedStatefields.map(s => {
        return (
          <Form
            key={s.stateCode}
            context={form}
            className="oq__form oq__form__step6__state-specific"
          >
            <FormGroup>
              {s.fields.map((f, index) => {
                const label = f.nonStateField
                  ? f.label
                  : `${s.stateName}: ${f.label}`;

                //These checks make sure if only "OR" is left in the list of fields it does not show.
                if (f.jsx && index === 1) return f.jsx;
                if (f.jsx && index !== 1) return null;

                return (
                  <Input
                    key={f.name}
                    label={label}
                    id={`${s.stateCode}_${f.name}`}
                    name={`${s.stateCode}_${f.name}`}
                    value={values[`${s.stateCode}_${f.name}`]}
                    //requiredError={f.requiredError}
                    onChange={field => {
                      handleOnChange({
                        field: field.field,
                        value: field.value.toUpperCase()
                      });
                      // reset the errors on the related required field, if exists
                      if (f.required && errors?.[f.required]) {
                        updateForm({
                          errors: { [field.field]: [], [f.required]: [] }
                        });
                      }
                    }}
                    onBlur={({ field, value }) => {
                      handleBlurAndSave({
                        field,
                        value,
                        usState: s.stateCode,
                        dtoField: f.name,
                        label: `${s.stateCode}: ${f.label}`,
                        nonStateField: f.nonStateField
                      });
                    }}
                    onValidate={field => {
                      const errors = handleOnValidate(field);
                      if (f.regExValidation) {
                        const matcher = new RegExp(f.regExValidation);
                        if (!matcher.test(field.value))
                          errors.push(f.errorMessage);
                      } else if (
                        !!field.value &&
                        !new RegExp("^\\d{" + f.requiredLength + "}$").test(
                          field.value
                        )
                      ) {
                        errors.push(
                          `ID format for ${f.label} is invalid. Required format is ${f.requiredLength} digits.`
                        );
                      }
                      return errors;
                    }}
                    disabled={
                      f.disabled
                        ? !!values[f.disabled]
                        : false || quoteIsUpdating
                    }
                    // required={f.required ? !values[f.required] : true}
                    maxLength={f.requiredLength}
                    numbersOnly
                  />
                );
              })}
            </FormGroup>
          </Form>
        );
      })}
    </>
  );
  // const dtoMissingMN = MN && !MN?.unEmploymentId;
};
export default StateSpecificIds;
