import React, { useContext } from "react";
import { productKeys } from "../shared/constants";
import OnlineQuotingContext from "../OnlineQuotingContext";
import { filterOutAutoIfHNO } from "../shared/util";

const SelectedProducts = () => {
  const { quoteData, supportingData, step } = useContext(OnlineQuotingContext);

  // product data in step2 is formatted differently than steps 3+
  const lineArray =
    step === 2
      ? Object.keys(productKeys).filter(
          p => !!supportingData?.policyLines?.includes(p)
        )
      : quoteData?.lobData
      ? filterOutAutoIfHNO(quoteData, supportingData)
      : null;

  if (!lineArray) return null;
  // an array of lines by their display name to show under the BOP header
  const lines = lineArray.map(l => productKeys[l]?.label);

  return (
    <>
      <div className="oq__sidebar__summary__item">
        <b>Product(s):</b>
        <div className="oq__sidebar__summary__item-lines">
          {lines.join(", ")}
        </div>
      </div>
    </>
  );
};

export default SelectedProducts;
