import React from "react";
import { SidebarNav } from "../../components/menus/SidebarNav";
import { Translations } from "../../components/translations";
import { AGENCY_INTERFACE } from "../../constants/routes";
import { resourcesLinks } from "../../components/header/ResourcesLinks";

const ResourcesSideNav = props => {
  const { isSpecialtyPortalView } = props;

  const resourcesSubMenuItems = (resourcesLinks, isSpecialtyPortalView) => {
    if (isSpecialtyPortalView) {
      const resourceLinkFiltered = resourcesLinks && resourcesLinks.filter(item => {
        return item.to !== AGENCY_INTERFACE;
      });
      return resourceLinkFiltered;
    }
    return resourcesLinks;
  };

  return (
    <SidebarNav
      links={resourcesSubMenuItems(resourcesLinks,isSpecialtyPortalView)}
      header={Translations.Resources.resources}
    />
  );
};

export default ResourcesSideNav;
