import { getCoverageGroupPath } from "../../shared/constants";
import _get from "lodash/get";
import { formatDate } from "@ufginsurance/ui-kit";
// schedule field types:
/*
  AdditionalInsured
  AdditionalInterest
  AutoNumber
  BigDecimal
  Boolean
  Date
  ForeignKey
  ForeignKeyWithOptionLabels
  Integer
  IntegerRange
  Option
  Percent
  PolicyContact
  PolicyLocation
  String
  TextArea
  TypeKey
*/

export const fieldDefinition = fld => {
  const getValueFromOptions = (options, value, valueOptionKey = "value") => {
    if (!options || !value) return "";
    const v = options.find(f => f.publicId === value);
    return !!v ? v[valueOptionKey] : "";
  };

  const definition = {
    AutoNumber: {
      value:
        !!fld.visible && !!fld?.integerValue
          ? fld?.integerValue.toString()
          : "",
      type: "text",
      valueKey: "integerValue"
    },
    AdditionalInsured: {
      displayValue:
        !!fld.visible &&
        !!fld.valueRange &&
        getValueFromOptions(fld.valueRange, fld.stringValue),
      value: (!!fld.visible && !!fld.visible && fld?.stringValue) || "",
      type: "scheduleItemContact",
      valueKey: "stringValue"
    },
    BigDecimal: {
      value:
        !!fld.visible && !!fld?.integerValue
          ? fld?.integerValue.toString()
          : "",
      type: "text",
      valueKey: "integerValue"
    },
    Boolean: {
      value: (!!fld.visible && fld?.booleanValue) || "",
      type: "switch",
      valueKey: "booleanValue"
    },
    Date: {
      displayValue:
        (!!fld.visible && formatDate(fld?.dateValue, "MM/DD/YYYY")) || null,
      value: (!!fld.visible && fld?.dateValue) || null,
      type: "date",
      valueKey: "dateValue"
    },
    ForeignKey: {
      displayValue:
        !!fld.visible &&
        !!fld.valueRange &&
        getValueFromOptions(fld.valueRange, fld.foreignKeyIdValue),
      value: (!!fld.visible && !!fld.visible && fld?.foreignKeyIdValue) || "",
      type: "select",
      valueKey: "foreignKeyIdValue"
    },
    Integer: {
      value:
        !!fld.visible && !!fld?.integerValue
          ? fld?.integerValue.toString()
          : "",
      type: "text",
      valueKey: "integerValue"
    },
    IntegerRange: {
      value:
        !!fld.visible && !!fld?.integerValue
          ? fld?.integerValue.toString()
          : "",
      type: "text",
      valueKey: "integerValue"
    },
    Option: {
      value: (!!fld.visible && !!fld.visible && fld?.stringValue) || "",
      type: "select",
      valueKey: "stringValue"
    },
    Percent: {
      value:
        !!fld.visible && !!fld?.integerValue
          ? fld?.integerValue.toString()
          : "",
      type: "text",
      valueKey: "integerValue"
    },
    PolicyContact: {
      value: "UNKNOWN-PolicyContact",
      type: "select",
      valueKey: "stringValue"
    },
    PolicyLocation: {
      value: "UNKNOWN-PolicyLocation",
      type: "select",
      valueKey: "stringValue"
    },
    String: {
      value: (!!fld.visible && fld?.stringValue) || "",
      type: "text",
      valueKey: "stringValue"
    },
    TextArea: {
      value: (!!fld.visible && fld?.stringValue) || "",
      type: "text",
      valueKey: "stringValue"
    }
  };

  return (
    definition[fld.type] || { value: "UNKNOWN", type: "text", valueKey: null }
  );
};

export const findCoverageToUpdate = ({ field, quoteData }) => {
  let coverageArray = null;

  // COVERABLE: if the SI is on a coverable then we have to find the coverable object
  // to get it's available coverages... otherwise, the coverages are simply avail on the lob
  if (!!field.coverableType) {
    if (!field.coveragesPath) return false; // SHOW ERROR?
    const coverablesAvailable = _get(quoteData, field.coveragesPath);
    const selectedCoverable = coverablesAvailable.find(
      c => c.coverableFixedId === field.clauseScheduleItems.coverableFixedId
    );
    // find coverages in selected coverable object
    coverageArray = _get(
      selectedCoverable,
      getCoverageGroupPath(field.coverageCategoryCode)
    );
  } else {
    // NOT COVERABLE: find the coverages in the fields path
    coverageArray = _get(quoteData, field.coveragesPath);
  }

  return coverageArray;
};
