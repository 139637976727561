import React, { useState, useEffect } from "react";
import { Translations } from "../../translations";
import PropTypes from "prop-types";
import { Button } from "@ufginsurance/ui-kit";
import qs from "query-string";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

const BillingAgencySweepButtons = ({
  accountNumber,
  accountData,
  hasDirectBillPayNowEnrollment,
  is109015Agent,
  billingActivity,
  isAgencyAdministrator,
  copyAccountAndBillingActivity,
  isOasisAccount
}) => {
  const [canShowEnrollmentButton, setCanShowEnrollmentButton] = useState(false);
  useEffect(() => {
    const showEnrollment =
      !hasDirectBillPayNowEnrollment &&
      !!isAgencyAdministrator &&
      !is109015Agent;
    const billingActivityStored = !!(
      billingActivity &&
      ((billingActivity.accountBilled &&
        billingActivity.accountBilled.length) ||
        (billingActivity.policyBilled && billingActivity.policyBilled.length))
    );
    if (billingActivityStored) {
      setCanShowEnrollmentButton(showEnrollment);
    }
  }, [
    isAgencyAdministrator,
    hasDirectBillPayNowEnrollment,
    is109015Agent,
    billingActivity
  ]);

  const [canShowPayNowButton, setCanShowPayNowButton] = useState(false);
  useEffect(() => {
    if (billingActivity && !is109015Agent) {
      const accountIsPayableOrBilledByPoliciesArePayable =
        !!billingActivity.accountIsPayable ||
        (billingActivity.policyBilled &&
          billingActivity.policyBilled.length > 0 &&
          billingActivity.policyBilled.filter(policy => policy.pay_now).length >
            0);

      const canPayWithAgencySweep =
        hasDirectBillPayNowEnrollment &&
        !is109015Agent &&
        accountIsPayableOrBilledByPoliciesArePayable;
      setCanShowPayNowButton(canPayWithAgencySweep);
    }
  }, [is109015Agent, hasDirectBillPayNowEnrollment, billingActivity]);

  const handleEnrollment = () => {
    const queryString = qs.stringify({
      BillType: "DB",
      RedirectUrl: `https://${window.location.hostname}${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    });
    window.location = `${baseLink}/AgentsOnly/ufgAgent/AgencySweepEnrollment/default.aspx?${queryString}`;
  };

  const history = useHistory();
  const handlePayNowClick = () => {
    copyAccountAndBillingActivity(accountNumber, accountData, billingActivity);
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}${
        isOasisAccount ? routes.AGENCY_SWEEP : routes.LEGACY_AGENCY_SWEEP
      }`
    );
  };

  return (
    <div>
      {canShowEnrollmentButton && (
        <div>
          <Button
            id="enrollBtnId"
            key="enrollBtnId"
            className="paynow-btn enroll-btn"
            onClick={handleEnrollment}
            variant="primary"
          >
            {
              Translations.Billing_Details.Account_Billing_Activity.Agency_Sweep
                .To_Enroll
            }
          </Button>
        </div>
      )}

      {canShowPayNowButton && (
        <div>
          <Button
            id="payNowBtnId"
            key="payNowBtnId"
            className="paynow-btn"
            onClick={handlePayNowClick}
            variant="primary"
          >
            {
              Translations.Billing_Details.Account_Billing_Activity.Agency_Sweep
                .Enrolled
            }
          </Button>
        </div>
      )}
    </div>
  );
};

BillingAgencySweepButtons.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  accountData: PropTypes.object.isRequired,
  hasDirectBillPayNowEnrollment: PropTypes.bool.isRequired,
  billingActivity: PropTypes.object.isRequired,
  is109015Agent: PropTypes.bool.isRequired,
  isAgencyAdministrator: PropTypes.bool.isRequired,
  copyAccountAndBillingActivity: PropTypes.func.isRequired,
  isOasisAccount: PropTypes.bool.isRequired
};

export default BillingAgencySweepButtons;
