import React, { useState } from "react";
import { connect } from "react-redux";
import { DropdownNonForm, Button } from "@ufginsurance/ui-kit";
import {
  fetchMessages,
  startConversation,
  replyMessage,
  markAsRead,
  resetMessages,
  viewAttachments,
  fetchBlobAttachFile,
  removeAttachment,
  updateAttachment
} from "../../../actions/commercialLinesAction";
import { Translations } from "../../translations";
import Actions from "./Actions";
import PropTypes from "prop-types";
import QuoteInfo from "./QuoteInfo";
import ViewMessage from "./ViewMessage";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import ViewAttachments from "./ViewAttachments";
import { DocumentService } from "../../../services/documentService";
import * as api from "../../../services/onlineQuotingService";
import { toast } from "react-toastify";
import "./SubmissionInfo.scss";

const SubmissionInfo = props => {
  const [viewMessage, setViewMessage] = useState(false);
  const [show, setShow] = useState(false);
  const [printProposalLoading, setPrintProposalLoading] = useState(false);

  const {
    agentCode,
    result,
    getMessages,
    resetMessages,
    startConversation,
    markAsRead,
    replyMessage,
    removeAttachment,
    viewAttachments,
    attachmentData,
    fetchBlobAttachFile,
    isMessagesLoading,
    isReplyMessageLoading,
    isStartConversationLoading,
    updateAttachment,
    messages
  } = props;

  const handleClose = () => {
    setViewMessage(false);
    setShow(false);
    resetMessages();
  };

  const handleViewMessage = () => {
    setViewMessage(true);
    getMessages(result.id, "Producer");
  };

  const handleMarkAsRead = conversationId => {
    const user_role = "Producer";
    const submissionId = result.id;
    markAsRead(submissionId, conversationId, user_role);
  };

  const handleReplyMessage = (conversationId, replyMsgText) => {
    const body = {
      message_body: replyMsgText,
      author: {
        code: agentCode,
        name: "",
        email: "",
        user_role: 0,
        user_role_description: "Producer"
      }
    };
    const submissionId = result.id;
    const user_role = "Producer";
    return replyMessage(submissionId, conversationId, user_role, body);
  };

  const handleRemoveAttachment = id => {
    removeAttachment(id, result.id);
  };

  const handleViewAttachments = () => {
    setShow(true);
    viewAttachments(result.id);
  };

  const handleFetchBlobAttachFile = attachmentId => {
    const attachment = attachmentData.find(attachment => {
      return attachment.id === attachmentId;
    });
    fetchBlobAttachFile(attachment, result.id);
  };

  const handleUpdateAttachment = data => {
    updateAttachment(data, result.id);
  };

  const calculatePrintOptions = data => {
    if (!data || !data.printing) return [];
    const options = [];
    if (data.printing.can_print_quote_proposal)
      options.push({
        value: data.printing.quote_proposal_url,
        label:
          Translations.commercial.new_submissions_tab.print_options
            .print_quote_proposal
      });
    if (data.printing.can_print_rating_worksheet)
      options.push({
        value: data.printing.rating_worksheet_url,
        label:
          Translations.commercial.new_submissions_tab.print_options
            .print_rating_worksheet
      });
    if (data.printing.can_print_application)
      options.push({
        value: data.printing.application_url,
        label:
          Translations.commercial.new_submissions_tab.print_options
            .print_application
      });
    if (data.printing.can_print_message_log)
      options.push({
        value: data.printing.message_log_url,
        label:
          Translations.commercial.new_submissions_tab.print_options
            .print_message_log
      });
    return options;
  };

  const openPrintLink = option => {
    if (!!option && !!option.value) window.open(option.value);
  };

  const { actions } = Translations.commercial.new_submissions_tab;
  const printOptions = calculatePrintOptions(result);

  const openPDFdocument = (response, fileName) => {
    const documentService = new DocumentService();
    documentService.initializeRenderer("/loading-document");
    documentService.saveOrOpenDocument(response.data, fileName);
  };

  const handleOOQProposal = () => {
    setPrintProposalLoading(true);
    api
      .getDocument(result.quote_number, "proposal")
      .then(response => {
        openPDFdocument(response, `quote-proposal-${result.quote_number}.pdf`);
        setPrintProposalLoading(false);
      })
      .catch(() => {
        toast(`Unable to load the quote proposal. Please try again later.`, {
          position: "top-center",
          className: "error",
          autoClose: false
        });
      });
  };

  const pcStatusCodes = [
    "Quoted",
    "Draft",
    "Quoting",
    "Binding",
    "Bound",
    "Issued",
    "InProgress",
    "In Progress"
  ];

  return (
    <div>
      <div className="row expanded-result">
        <div className="col-md-3  expanded-options-list">
          {result?.core_system === "OnlineQuoting"
            ? !!result?.premium_amount &&
              result?.premium_amount !== "0.00" &&
              pcStatusCodes.includes(result?.status) &&
              !result?.has_active_uw_issues && (
                //online quoting proposals handled differently here.
                <Button
                  onClick={handleOOQProposal}
                  icon="fasPrint"
                  wrapperClassName="button__printProposalApp"
                  disabled={printProposalLoading}
                  spinner={printProposalLoading}
                >
                  Print the Proposal/App
                </Button>
              )
            : !!printOptions.length && (
                <DropdownNonForm
                  id="openPrintLink"
                  name="openPrintLink"
                  options={printOptions}
                  onChange={openPrintLink}
                  placeholder={
                    Translations.commercial.new_submissions_tab
                      .print_options_dropdown
                  }
                  isClearable={false}
                  size="auto"
                  compactIndicator
                  noLabel
                />
              )}
          {result && result.can_access_messaging && (
            <p>
              <Button
                isLink
                inline
                size="sm"
                className="viewMessage"
                onClick={handleViewMessage}
              >
                {actions.viewSendMessages}
              </Button>{" "}
              <i>({result && result.unread_message_count} new)</i>
            </p>
          )}
          {result && result.can_access_attachments && (
            <p>
              <Button
                size="sm"
                isLink
                inline
                className="attachments-link"
                onClick={handleViewAttachments}
              >
                {actions.viewSendAttachments}
              </Button>{" "}
              <i>({result && result.unseen_attachment_count} new)</i>
            </p>
          )}
        </div>
        <div className="quote-list-wrapper">
          <QuoteInfo result={result} />
          <Actions {...props} />
        </div>
      </div>
      <div className="hrule" />
      <div />

      <ViewMessage
        messages={messages}
        show={viewMessage}
        handleClose={handleClose}
        startConversation={startConversation}
        markAsRead={handleMarkAsRead}
        replyMessage={handleReplyMessage}
        result={result}
        isLoading={isMessagesLoading}
        isReplyMessageLoading={isReplyMessageLoading}
        isStartConversationLoading={isStartConversationLoading}
        agentCode={agentCode}
      />
      <ViewAttachments
        {...props}
        show={show}
        handleClose={handleClose}
        fetchBlobAttachFile={handleFetchBlobAttachFile}
        removeAttachment={handleRemoveAttachment}
        updateAttachment={handleUpdateAttachment}
      />
    </div>
  );
};

SubmissionInfo.propTypes = {
  result: PropTypes.object,
  agentCode: PropTypes.string,
  viewAttachments: PropTypes.func,
  getMessages: PropTypes.func,
  startConversation: PropTypes.func,
  markAsRead: PropTypes.func,
  replyMessage: PropTypes.func,
  attachmentData: PropTypes.array,
  viewMessage: PropTypes.func,
  fetchBlobAttachFile: PropTypes.func,
  removeAttachment: PropTypes.func,
  updateAttachment: PropTypes.func,
  isLoading: PropTypes.bool,
  isMessagesLoading: PropTypes.bool,
  isReplyMessageLoading: PropTypes.bool,
  resetMessages: PropTypes.func,
  isStartConversationLoading: PropTypes.bool,
  messages: PropTypes.object
};

const mapStateToProps = state => {
  return {
    ...state.commercialLines,
    isLoading: state.commercialLines.isLoading,
    isMessagesLoading: state.commercialLines.isMessagesLoading,
    isReplyMessageLoading: state.commercialLines.isReplyMessageLoading,
    isStartConversationLoading:
      state.commercialLines.isStartConversationLoading,
    agentCode: sessionSelector.getAgentCode(state),
    attachmentData: state.commercialLines.attachmentData
  };
};

const mapDispatchToProps = {
  getMessages: fetchMessages,
  startConversation,
  resetMessages,
  replyMessage,
  markAsRead,
  viewAttachments,
  fetchBlobAttachFile,
  removeAttachment,
  updateAttachment
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionInfo);
