import React from "react";
import PropTypes from "prop-types";
import LineOfBusinessDocumentsTable from "./lineOfBusinessDocumentsTable";
import { Translations } from "../components/translations";
import { Accordion, Currency, Button, LoadingIndicator } from "@ufginsurance/ui-kit";
import { Alert } from "react-bootstrap";
import { atleastOneDocumentSelected } from "./currentPolicySelector";

export const CURRENT_POLICY_TERM = "Current";
export const RENEWAL_POLICY_TERM = "Renewal";

const PolicyTermSummary = ({
  summary,
  policyType,
  policy,
  onSelectPolicyDocument,
  selectedPolicyDocuments,
  activeAgencyCode,
  currentPolicyData,
  fetchUFGDocumentSummary,
  fetchCurrentPolicyDocument,
  errorMessage,
  selectAllPoliciesCurrent,
  selectAllPoliciesRenewal,
  handleSelectAllDecsAndTypedForms,
  selectAllCurrent,
  selectAllRenewal,
  handleSelectAllDocuments,
  handleViewAllLinesOfBusinessDocuments,
  isPrintorViewLoading,
  isPrintorViewRenewalLoading,
  canPrintCurrent,
  canPrintRenewal
}) => {
  const isCurrentPolicyTerm = summary?.term === CURRENT_POLICY_TERM || false;
  const printAllLinesOfBusinessDocumentsDisabled = isCurrentPolicyTerm
    ? (!atleastOneDocumentSelected(summary.term, selectedPolicyDocuments) &&
        !canPrintCurrent) ||
      isPrintorViewLoading
    : (!atleastOneDocumentSelected(summary.term, selectedPolicyDocuments) &&
        !canPrintRenewal) ||
      isPrintorViewRenewalLoading;
  return (
    <div key={summary.policy_number + summary.term}>
      <div className="current-documents">
        {!isCurrentPolicyTerm && (
          <div className="ufg-renewal-heading">
            <span className="current-policy-number">Renewal |</span>
            Policy Number {policy.policy_number} | Premium{" "}
            <Currency amount={summary.total_premium} includeDecimals={false} />{" "}
          </div>
        )}
        <div>
          <div className="current-ufg-core">
            {summary &&
              summary.lines_of_business.map((lob, index) => {
                return (
                  <div key={lob.declaration_code}>
                    <Accordion
                      textMain={lob.name}
                      textExtra={
                        <span>
                          Policy Number {policy.policy_number} | Premium{" "}
                          <Currency
                            amount={lob.premium}
                            includeDecimals={false}
                          />
                        </span>
                      }
                      isOpen={index === 0}
                    >
                      <div>
                        <p>
                          <b>
                            {
                              Translations.account_overview.currentPolicy
                                .ufg_accordion_note
                            }
                          </b>
                          {
                            Translations.account_overview.currentPolicy
                              .ufg_accordion_note_info
                          }
                        </p>
                        <p>
                          <i>
                            {
                              Translations.account_overview.currentPolicy
                                .choose_items
                            }
                          </i>
                        </p>
                        <LineOfBusinessDocumentsTable
                          policyType={policyType}
                          policyTerm={summary.term}
                          policy={policy}
                          declarationCode={lob.declaration_code}
                          documents={
                            currentPolicyData?.ufgDocuments[
                              lob.declaration_code + summary.term
                            ]?.documents || []
                          }
                          isLoading={
                            isCurrentPolicyTerm
                              ? isPrintorViewLoading
                              : isPrintorViewRenewalLoading
                          }
                          fetchDocumentSummary={fetchUFGDocumentSummary}
                          fetchCurrentPolicyDocument={
                            fetchCurrentPolicyDocument
                          }
                          activeAgencyCode={activeAgencyCode}
                          onSelectPolicyDocument={onSelectPolicyDocument}
                          selectedPolicyDocuments={selectedPolicyDocuments}
                        />
                      </div>
                    </Accordion>
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          {errorMessage && <Alert bsStyle="danger">{errorMessage}</Alert>}
          <label
            htmlFor={
              isCurrentPolicyTerm
                ? "selectAllPoliciesCurrent"
                : "selectAllPoliciesRenewal"
            }
          >
            <input
              id={
                isCurrentPolicyTerm
                  ? "selectAllPoliciesCurrent"
                  : "selectAllPoliciesRenewal"
              }
              type="checkbox"
              name={
                isCurrentPolicyTerm
                  ? "selectAllPoliciesCurrent"
                  : "selectAllPoliciesRenewal"
              }
              checked={
                isCurrentPolicyTerm
                  ? selectAllPoliciesCurrent
                  : selectAllPoliciesRenewal
              }
              onChange={handleSelectAllDecsAndTypedForms(summary.term)}
            />
            <span> Select all policies, decs & typed forms</span>
          </label>
          <br />
          <label
            htmlFor={
              isCurrentPolicyTerm ? "selectAllCurrent" : "selectAllRenewal"
            }
          >
            <input
              id={isCurrentPolicyTerm ? "selectAllCurrent" : "selectAllRenewal"}
              type="checkbox"
              name={
                isCurrentPolicyTerm ? "selectAllCurrent" : "selectAllRenewal"
              }
              checked={
                isCurrentPolicyTerm ? selectAllCurrent : selectAllRenewal
              }
              onChange={handleSelectAllDocuments(summary.term)}
            />
            <span>
              {isCurrentPolicyTerm
                ? Translations.account_overview.currentPolicy.select_current
                : Translations.account_overview.currentPolicy.select_renewal}
            </span>
          </label>
          <div className="view-print-button">
            <Button
              id={
                isCurrentPolicyTerm
                  ? "viewAllLinesOfBusinessDocumentsBtn"
                  : "viewAllLinesOfBusinessDocumentsRenewalBtn"
              }
              variant={"primary"}
              disabled={printAllLinesOfBusinessDocumentsDisabled}
              onClick={handleViewAllLinesOfBusinessDocuments(summary.term)}
            >
              {Translations.account_overview.currentPolicy.view_Print}
            </Button>
            {isCurrentPolicyTerm
              ? isPrintorViewLoading && <LoadingIndicator />
              : isPrintorViewRenewalLoading && <LoadingIndicator />}
          </div>
        </div>
      </div>
    </div>
  );
};

PolicyTermSummary.propTypes = {
  summary: PropTypes.object.isRequired,
  policyType: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired,
  onSelectPolicyDocument: PropTypes.func,
  selectedPolicyDocuments: PropTypes.object.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  currentPolicyData: PropTypes.object.isRequired,
  fetchUFGDocumentSummary: PropTypes.func.isRequired,
  fetchCurrentPolicyDocument: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  selectAllPoliciesCurrent: PropTypes.bool,
  selectAllPoliciesRenewal: PropTypes.bool,
  handleSelectAllDecsAndTypedForms: PropTypes.func.isRequired,
  selectAllCurrent: PropTypes.bool,
  selectAllRenewal: PropTypes.bool,
  handleSelectAllDocuments: PropTypes.func.isRequired,
  handleViewAllLinesOfBusinessDocuments: PropTypes.func.isRequired,
  isPrintorViewLoading: PropTypes.bool,
  isPrintorViewRenewalLoading: PropTypes.bool,
  canPrintCurrent: PropTypes.bool,
  canPrintRenewal: PropTypes.bool
};

export default PolicyTermSummary;
