import React from "react";
import PropTypes from "prop-types";
import { Accordion, Anchor, ExternalLink } from "@ufginsurance/ui-kit";
import BillingDetailsContainer from "./BillingDetailsContainer";
import BillingDetailsContainerGuideWire from "./BillingDetailsContainerGuideWire";
import BillingActivityContainer from "../../../containers/BillingActivityContainer";
import InstallmentScheduleContainer from "./InstallmentScheduleContainer";
import { Translations } from "../../../components/translations";
import { Link } from "react-router-dom";
import * as routes from "../../../constants/routes";

const isAgencyBill = policyDetail =>
  !!(
    policyDetail &&
    policyDetail.payment_plan &&
    policyDetail.payment_plan.includes("Agency Bill")
  );

const isWorkersCompensationPayAsYouGo = policyDetail =>
  !!(
    policyDetail &&
    policyDetail.payment_plan &&
    policyDetail.payment_plan.toLowerCase().includes("pay as you go")
  );

const BillingContainer = ({
  accountNumber,
  policyDetails,
  selectedPolicyDetail
}) => {
  const isGuideWire = selectedPolicyDetail?.policy_number?.length > 8;
  return isAgencyBill(selectedPolicyDetail) ? (
    <p>
      This is an Agency Bill policy. Please view, modify, and/or pay your
      statement under{" "}
      <Anchor
        as={Link}
        to={`${routes.REPORTS}/${routes.REPORTS_ACCOUNTING_ACTIVE_TAB}/${routes.REPORTS_AGENCY_BILL_EPAY}`}
      >
        {"Reports > Accounting > Agency Bill E-Pay"}
      </Anchor>
      .
    </p>
  ) : isWorkersCompensationPayAsYouGo(selectedPolicyDetail) ? (
    <p>
      This {"workers'"} compensation policy is enrolled in Pay as You Go
      billing. For additional information, contact{" "}
      <ExternalLink to="https://www.smartpayllc.com" target="_blank">
        SmartPay Solutions
      </ExternalLink>{" "}
      by email at{" "}
      <Anchor href="mailto:support@smartpayllc.com">
        support@smartpayllc.com
      </Anchor>{" "}
      or by phone at{" "}
      <span className="smart-pay-phone-number-label">877-905-0786</span>
      <Anchor
        href="tel:8779050786"
        className="smart-pay-mobile-phone-number-link"
      >
        877-905-0786
      </Anchor>{" "}
      8 a.m. to 8 p.m. ET Monday-Friday.
    </p>
  ) : (
    <div className="billing-container">
      <BillingActivityContainer accountNumber={accountNumber} />
      <div className="billing-details">
        <Accordion
          accordionIndex={1}
          textMain={
            Translations.Billing_Details.Billing_Container.Billing_Details
          }
        >
          {isGuideWire && (
            <BillingDetailsContainerGuideWire
              accountNumber={accountNumber}
              policyDetails={policyDetails}
            />
          )}
          {!isGuideWire && (
            <BillingDetailsContainer
              accountNumber={accountNumber}
              policyDetails={policyDetails}
            />
          )}
        </Accordion>
      </div>
      <div className="billing">
        <Accordion
          accordionIndex={2}
          textMain={
            Translations.Billing_Details.Billing_Container.Installment_Schedule
          }
        >
          <InstallmentScheduleContainer
            accountNumber={accountNumber}
            policyDetails={policyDetails}
          />
        </Accordion>
      </div>
    </div>
  );
};

BillingContainer.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  policyDetails: PropTypes.array,
  selectedPolicyDetail: PropTypes.object
};

export default BillingContainer;
