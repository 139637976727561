import React from "react";
import { convertStatesAndAbbreviations } from "../../components/Factory";

export const hardCodedErrorsToIgnore_stateSpecific = [
  /A Contracting Class Code must be selected when Construction Class Adjustment is Applied/,
  /A contracting class code is required when a Contracting Class Premium endorsement is added for.*./
];

export const appetiteGuideLink =
  "https://indd.adobe.com/view/6266ba4e-6d4d-441a-b9aa-31b367ba73a4";

export const quotePath = {
  contactName: "baseData.accountHolder.displayName",
  primaryAddress: {
    address: "baseData.accountHolder.primaryAddress.addressLine1",
    city: "baseData.accountHolder.primaryAddress.city",
    state: "baseData.accountHolder.primaryAddress.state",
    zip: "baseData.accountHolder.primaryAddress.postalCode"
  },
  drivers: "lobData.ca7CommAuto.coverables.commAutoDrivers",
  vehicles: "lobData.ca7CommAuto.coverables.vehicles",
  locations: "coverables.locations",
  bp7locations: "lobData.bp7BusinessOwners.coverables.locations",
  ca7locations: "lobData.ca7CommAuto.coverables.locations",
  wcmlocations: "lobData.wcmWorkersComp.coverables.locations",
  cyberCoverages: "lobData.internetSecurity.coverages.clausesGroups.coverages",
  ca7Vehicle: "lobData.ca7CommAuto.coverables.vehicles",
  coverages: "coverages.clausesGroups.coverages",
  addlCoverages: "coverages.clausesGroups.addlCoverages",
  defaultCoverages: "coverages.clausesGroups.defaults",
  addlInsdCoverages: "coverages.clausesGroups.addlInsdCoverages",
  contractorBus: "coverages.clausesGroups.contractorBus",
  groupsCoverages: "clausesGroups.coverages",
  groupsAddlCoverages: "clausesGroups.addlCoverages",
  groupsDefaultCoverages: "clausesGroups.defaults",
  groupsAddlInsdCoverages: "clausesGroups.addlInsdCoverages",
  groupsHiredAuto: "clausesGroups.hiredAutoClausesGroups",
  groupsNonOwnedAuto: "clausesGroups.nonOwnedAutoClausesGroups",
  groupsContractorBusCoverages: "clausesGroups.ContractorBus",
  groupsMotelBusGrp: "clausesGroups.motelBus",
  groupsSelfStorageBusGrp: "clausesGroups.selfStorageBus",
  groupsLandscapeBusGrp: "clausesGroups.landscapeBus",
  splitableGrp: "clausesGroups.splitableCoverages",
  groupsExclCoverages: "clausesGroups.exclusions",
  bp7LocationCoverages:
    "lobData.bp7BusinessOwners.coverages.locationClausesGroups",
  ca7VehicleCoverages: "lobData.ca7CommAuto.coverages",
  wcmEmployeeCoverages:
    "lobData.wcmWorkersComp.coverages.coveredEmployeeClausesGroups",
  bp7BuildingCoverages:
    "lobData.bp7BusinessOwners.coverages.buildingClausesGroups",
  bp7ClassificationCoverages:
    "lobData.bp7BusinessOwners.coverages.classificationClausesGroups",
  bp7JurisdictionsCoverages:
    "lobData.bp7BusinessOwners.coverages.jurisdictionClausesGroups",
  wcmJurisdictionsCoverages:
    "lobData.wcmWorkersComp.coverages.jurisdictionClausesGroups",
  ca7JurisdictionCoverable: "lobData.ca7CommAuto.coverables.jurisdictions",
  wcm7JurisdictionCoverable: "lobData.wcmWorkersComp.coverables.jurisdictions",
  industryCode: "baseData.industryCode_UFG.code",
  culGroupCoverages: "cuCommercialUmbrella.clausesGroups.coverages",
  questionCodes: {
    BP7BusinessOwnersLine: "BP7BusinessownersPreQual",
    bp7BusinessOwners: "BP7BusinessownersPreQual",
    CA7CommAutoLine: "CA7CommAutoPreQual",
    ca7CommAuto: "CA7CommAutoPreQual"
    // GL7GeneralLiabilityLine: "???",
    // gl7GeneralLiability: "???",
    // GaragePro: "???",
    // InlandMarineLine: "???",
    // CommercialPropertyLine: "???",
    // WorkersCompLine: "???"
  }
};

export const getProductPaths = ({ quoteData, showUmbrella }) => {
  const productNames = Object.keys(quoteData.lobData).filter(p =>
    showUmbrella ? true : p !== "cuCommercialUmbrella"
  ); //hide comUmbrella.
  return productNames.map(productName => ({
    productName,
    coveragesPath: `lobData.${productName}.${quotePath.coverages}`,
    addlCoveragesPath: `lobData.${productName}.${quotePath.addlCoverages}`,
    defaultCoveragesPath: `lobData.${productName}.${quotePath.defaultCoverages}`,
    addlInsdCoveragesPath: `lobData.${productName}.${quotePath.addlInsdCoverages}`,
    contractorBusCoveragesPath: `lobData.${productName}.${quotePath.contractorBus}`
  }));
};

export const getCoverageGroupPath = coverageType => {
  if (coverageType.endsWith("StdGrp") || coverageType.endsWith("StdGrp_UFG"))
    return quotePath.groupsCoverages;
  if (coverageType.endsWith("AddlGrp")) return quotePath.groupsAddlCoverages;
  if (coverageType.endsWith("DefaultsGrp"))
    return quotePath.groupsDefaultCoverages;
  if (
    coverageType.endsWith("AddlInsuredGrp") ||
    coverageType.endsWith("AddlInsdGrp")
  )
    return quotePath.groupsAddlInsdCoverages;
  if (coverageType.endsWith("MotelBusGrp")) return quotePath.groupsMotelBusGrp;
  if (coverageType.endsWith("ContractorBusGrp"))
    return quotePath.groupsContractorBusCoverages;
  if (coverageType.endsWith("SelfStorageBusGrp"))
    return quotePath.groupsSelfStorageBusGrp;
  if (coverageType.endsWith("LandscapeBusGrp"))
    return quotePath.groupsLandscapeBusGrp;
  if (coverageType.endsWith("SplittableGrp")) return quotePath.splitableGrp;
  if (coverageType.endsWith("ExclGrp")) return quotePath.groupsExclCoverages;
  return "";
};

export const coverageTypeSortOrder = [
  { code: "BP7LineStdGrp", order: 1 },
  { code: "BP7LineDefaultsGrp", order: 2 },
  { code: "BP7LineContractorBusGrp", order: 3 },
  { code: "BP7LineAddlGrp", order: 4 },
  { code: "BP7LineAddlInsuredGrp", order: 5 },
  { code: "BP7LineCondGrp", order: 6 },
  { code: "BP7LineExclGrp", order: 7 },
  { code: "BP7LineHiddenGrp", order: 8 },
  { code: "BP7JurisdictionStdGrp", order: 9 },
  { code: "BP7JurisdictionAddlGrp", order: 10 },
  { code: "BP7JurisdictionCondGrp", order: 11 },
  { code: "BP7JurisdictionExclGrp", order: 12 },
  { code: "BP7JurisdictionHiddenGrp", order: 13 },
  { code: "BP7LocationStdGrp", order: 14 },
  { code: "BP7LocationDefaultsGrp", order: 15 },
  { code: "BP7LocationAddlGrp", order: 16 },
  { code: "BP7LocationAddlInsuredGrp", order: 17 },
  { code: "BP7LocationCondGrp", order: 18 },
  { code: "BP7LocationExclGrp", order: 19 },
  { code: "BP7LocationHiddenGrp", order: 20 },
  { code: "BP7BuildingStdGrp", order: 21 },
  { code: "BP7BuildingDefaultsGrp", order: 22 },
  { code: "BP7BuildingAddlGrp", order: 23 },
  { code: "BP7BuildingAddlInsuredGrp", order: 24 },
  { code: "BP7BuildingCondGrp", order: 25 },
  { code: "BP7BuildingExclGrp", order: 26 },
  { code: "BP7BuildingHiddenGrp", order: 27 },
  { code: "BP7ClassificationStdGrp", order: 28 },
  { code: "BP7ClassificationAddlGrp", order: 29 },
  { code: "BP7ClassificationCondGrp", order: 30 },
  { code: "BP7ClassificationExclGrp", order: 31 },
  { code: "BP7ClassificationMotelBusGrp", order: 32 },
  { code: "BP7ClassificationSelfStorageBusGrp", order: 33 },
  { code: "BP7ClassificationLandscapeBusGrp", order: 34 },
  { code: "BP7ClassificationFunctionalBPPValuationGrp", order: 35 },
  { code: "BP7ClassificationHiddenGrp", order: 36 },
  { code: "CA7LineStdGrp", order: 37 },
  { code: "CA7LineAddlGrp", order: 38 },
  { code: "CA7LineAddlInsdGrp", order: 39 },
  { code: "CA7LineCondGrp", order: 40 },
  { code: "CA7LineExclGrp", order: 41 },
  { code: "CA7ManuscriptEndorsementGrp", order: 42 },
  { code: "CA7PrivatePassengerStdGrp", order: 43 },
  { code: "CA7PrivatePassengerAddlGrp", order: 44 },
  { code: "CA7PrivatePassengerAddlInsdGrp", order: 45 },
  { code: "CA7PrivatePassengerCondGrp", order: 46 },
  { code: "CA7PrivatePassengerExclGrp ", order: 47 },
  { code: "CA7TruckStdGrp", order: 48 },
  { code: "CA7TruckAddlGrp", order: 49 },
  { code: "CA7TruckAddlInsdGrp", order: 50 },
  { code: "CA7TruckCondGrp", order: 51 },
  { code: "CA7TruckExclGrp", order: 52 },
  { code: "CA7JurisdictionStdGrp", order: 53 },
  { code: "CA7JurisdictionAddlGrp", order: 54 },
  { code: "CA7JurisdictionCondGrp", order: 55 },
  { code: "CA7JurisdictionExclGrp", order: 56 },
  { code: "CA7NOAStdGrp", order: 56.01 },
  { code: "CA7NOAAddlGrp", order: 56.02 },
  { code: "CA7NOACondGrp", order: 56.03 },
  { code: "CA7NOAExclGrp", order: 56.04 },
  { code: "CA7HAUStdGrp", order: 56.11 },
  { code: "CA7HAUAddlGrp", order: 56.12 },
  { code: "CA7HAUCondGrp", order: 56.13 },
  { code: "CA7HAUExclGrp", order: 56.14 },
  { code: "WCMWorkersCompLineStdGrp", order: 57 },
  { code: "WCMWorkersCompLineAddlGrp", order: 58 },
  { code: "WCMWorkersCompLineCondGrp", order: 59 },
  { code: "WCMWorkersCompLineExclGrp", order: 60 },
  { code: "WCMWorkersCompLineHiddenGrp", order: 61 },
  { code: "WCMLocationStdGrp", order: 62 },
  { code: "WCMLocationAddlGrp", order: 63 },
  { code: "WCMLocationCondGrp", order: 64 },
  { code: "WCMLocationExclGrp", order: 65 },
  { code: "WCMLocationHiddenGrp", order: 66 },
  { code: "WCMJurisdictionStdGrp", order: 67 },
  { code: "WCMJurisdictionAddlGrp", order: 68 },
  { code: "WCMJurisdictionAuditGrp", order: 69 },
  { code: "WCMJurisdictionCondGrp", order: 70 },
  { code: "WCMJurisdictionExclGrp", order: 71 },
  { code: "WCMJurisdictionHiddenGrp", order: 72 },
  { code: "WCMJurisdictionSplittableGrp", order: 73 },
  { code: "WCMCoveredEmployeeStdGrp", order: 74 },
  { code: "WCMCoveredEmployeeAddlGrp", order: 75 },
  { code: "WCMCoveredEmployeeCondGrp", order: 76 },
  { code: "WCMCoveredEmployeeExclGrp", order: 77 },
  { code: "WCMCoveredEmployeeHiddenGrp", order: 78 },
  { code: "WCMCoveredEmployeeSplittableGrp", order: 79 }
];

export const getCoverageGroupOrder = coverageCategoryCode => {
  if (!coverageCategoryCode) return 0;
  const found = coverageTypeSortOrder.find(c =>
    coverageCategoryCode.startsWith(c.code)
  );
  return found ? found.order : 0;
};

const clauseGroupsOrder = [
  {
    code: "jurisdictionClausesGroups",
    order: 0
  },
  {
    code: "buildingClausesGroups",
    order: 1
  },
  { code: "locationClausesGroups", order: 2 },
  { code: "classificationClausesGroups", order: 3 },
  { code: "nonOwnedAutoClausesGroups", order: 4 },
  { code: "hiredAutoClausesGroups", order: 5 },
  { code: "privatePassengerClausesGroups", order: 6 },
  { code: "coveredEmployeeClausesGroups", order: 7 }
];

export const getClauseGroupsOrder = clauseGroupName => {
  const found = clauseGroupsOrder.find(c => clauseGroupName.startsWith(c.code));
  return found ? found.order : 9999;
};

// the keys used for products as shown in the quote object
export const productKeys = {
  BP7BusinessOwnersLine: {
    label: "BOP-Pro",
    productListStep1Label: "BOP",
    lineOfBusiness: "BP7BusinessOwnersLine",
    productLine: "bp7BusinessOwners",
    key: "BP7BusinessOwners",
    sortOrder: "1"
  },
  bp7BusinessOwners: {
    label: "BOP-Pro",
    productListStep1Label: "BOP", // only used for step 1
    lineOfBusiness: "BP7BusinessOwnersLine",
    key: "BP7BusinessOwners",
    sortOrder: "1",
    line: "BP7Line"
  },
  BP7Line: {
    // only used on step6
    label: "BOP-Pro",
    lineOfBusiness: "BP7BusinessOwnersLine",
    key: "bp7BusinessOwners",
    sortOrder: "1"
  },
  CXLine: {
    // only used on step6
    label: "Umbrella",
    lineOfBusiness: "CXLine",
    productLine: "umbrella",
    key: "CommercialUmbrella",
    sortOrder: "2"
  },
  CA7CommAutoLine: {
    label: "Auto",
    lineOfBusiness: "CA7CommAutoLine",
    shortDescription: "Auto",
    productLine: "ca7CommAuto",
    key: "CA7CommAuto",
    sortOrder: "3"
  },
  ca7CommAuto: {
    label: "Auto",
    lineOfBusiness: "CA7CommAutoLine",
    shortDescription: "Auto",
    key: "CA7CommAuto",
    sortOrder: "3",
    line: "CA7Line"
  },
  CA7Line: {
    // only used on step6
    label: "Auto",
    lineOfBusiness: "CA7CommAutoLine",
    key: "ca7CommAuto",
    sortOrder: "3"
  },
  wcmWorkersComp: {
    label: "Workers' Compensation",
    lineOfBusiness: "WCMWorkersCompLine",
    shortDescription: "Work Comp",
    key: "WorkersComp",
    sortOrder: "4",
    line: "WCMLine"
  },
  WCMWorkersCompLine: {
    label: "Workers' Compensation",
    lineOfBusiness: "WorkersCompLine",
    shortDescription: "Work Comp",
    productLine: "wcmWorkersComp",
    key: "wcmWorkersComp",
    sortOrder: "4"
  },
  WCMLine: {
    // only used on step6
    label: "Workers' Compensation",
    lineOfBusiness: "WorkersCompLine",
    shortDescription: "Work Comp",
    key: "wcmWorkersComp",
    sortOrder: "4"
  },
  garagePro: {
    label: "GaragePro",
    lineOfBusiness: "GaragePro",
    productLine: "garagePro",
    key: "GaragePro",
    sortOrder: "7"
  },
  GaragePro: {
    // only used on step6
    label: "GaragePro",
    lineOfBusiness: "GaragePro",
    productLine: "garagePro",
    key: "GaragePro",
    sortOrder: "7"
  },
  commercialProperty: {
    label: "Property",
    lineOfBusiness: "CommercialPropertyLine",
    key: "CommercialProperty",
    sortOrder: "6"
  },
  gl7GeneralLiability: {
    label: "General Liability",
    lineOfBusiness: "GL7GeneralLiabilityLine",
    key: "GL7GeneralLiability"
  },
  inlandMarine: {
    label: "Inland Marine",
    lineOfBusiness: "InlandMarineLine",
    key: "InlandMarine",
    sortOrder: "5"
  },
  ISPLine: {
    label: "Cyber",
    lineOfBusiness: "ISPLine",
    key: "internetSecurity",
    productLine: "internetSecurity",
    sortOrder: "2",
    line: "ISPLine"
  },
  internetSecurity: {
    label: "Cyber",
    lineOfBusiness: "ISPLine",
    key: "internetSecurity",
    sortOrder: "2",
    line: "ISPLine"
  },
  cuCommercialUmbrella: {
    label: "Umbrella",
    lineOfBusiness: "CuCommercialUmbrella",
    key: "cuCommercialUmbrella",
    sortOrder: "5"
  }
};

/*
Note: the order of the keys in the "productsByLineCode" object controls
the order that the products are displayed in the Select Products panel
*/
export const productsByLineCode = {
  BP7BusinessOwnersLine: "bp7BusinessOwners",
  CA7CommAutoLine: "ca7CommAuto",
  GaragePro: "garagePro",
  WCMWorkersCompLine: "wcmWorkersComp",
  CommercialPropertyLine: "commercialProperty",
  GL7GeneralLiabilityLine: "gl7GeneralLiability",
  InlandMarineLine: "inlandMarine"
};

export const vehicleTypes = [
  {
    label: "Commercial",
    key: "CA7Truck",
    coverageGroupName: "truckClausesGroups",
    entityName: "entity.CA7Truck"
  },
  {
    label: "Private Passenger",
    key: "CA7PrivatePassenger",
    coverageGroupName: "privatePassengerClausesGroups",
    entityName: "entity.CA7PrivatePassenger"
  },
  {
    label: "Trailer",
    key: "CA7Trailer",
    coverageGroupName: "truckClausesGroups",
    entityName: "entity.CA7Truck"
  }
  /* // future vehicle Types

  {
    label: "Public Transportation",
    key: "CA7PublicTransportation",
    coverageGroupName: "publicTransportationClausesGroups",
    entityName: "entity.CA7PublicTransportation"
  },
  {
    label: "Special Type",
    key: "CA7SpecialType",
    coverageGroupName: "specialTypeClausesGroups",
    entityName: "entity.CA7SpecialType"
  } */
];

export const vehicleRadiusClassOptions = [
  { label: "Local up to 50 miles", value: "Local" },
  { label: "Intermediate 51 to 200 miles", value: "Intermediate" },
  { label: "Long Distance Over 200 miles", value: "Long Distance" }
];

export const vehicleSizeClassOptions = [
  { label: "Light Truck (0 - 10,000 lbs)", value: "Light Truck" },
  { label: "Medium Truck (10,001 - 20,000 lbs)", value: "Medium Truck" },
  { label: "Heavy Truck (20,001 - 45,000 lbs)", value: "Heavy Truck" },
  { label: "Extra-Heavy Truck (Over 45,000 lbs)", value: "Extra-Heavy Truck" }
];

export const flowValidation = {
  fromStep3: "PrequalUpdate",
  fromStep4: "LineUpdate",
  line: "LineUpdate",
  prequal: "PrequalUpdate",
  all: "All",
  step4: "ToPolicyInfo",
  step5: "ToRiskInfo",
  location: "LocationUpdate",
  building: "BuildingUpdate",
  jurisdiction: "JurisdictionUpdate",
  driver: "DriverUpdate",
  vehicle: "VehicleUpdate",
  employee: "CoveredEmployeeUpdate"
};

export const dividendPlanTypes = [
  { label: "Standard Flat 5%", type: "Standard Flat", value: "5" },
  { label: "Standard Flat 10%", type: "Standard Flat", value: "10" },
  { label: "Variable", type: "Variable", value: "0" }
];
export const validationWarningsToIgnore = {
  step4: [
    "Cyber Incident Exclusion is required and has been added to the policy."
  ]
};

export const modifierPath = lob => {
  switch (lob) {
    case "bp7BusinessOwners":
    case "BP7Line":
      return "lobData.bp7BusinessOwners.coverables.jurisdiction[0].modifier";
    case "ca7CommAuto":
    case "CA7Line":
      return "lobData.ca7CommAuto.modifier[0]";
    case "wcmWorkersComp":
    case "WCMLine":
      return "lobData.wcmWorkersComp.coverables.jurisdictions[0].modifier";
    case "wcmWorkersComp-Dividend":
    case "WCMLine-Dividend":
      return "lobData.wcmWorkersComp.coverables.jurisdictions[0].dividends";

    default:
      return "";
  }
};

export const enhancedEndorsementHighlights = {
  BP7VoluntaryPropertyDamageCareCustodyAndControl_UFG: {
    name: "Voluntary Property Damage - Care, Custody And Control Property Damage Coverage",
    type: "BOP",
    line: "BOP"
  },
  BP7AddlInsdVendors: {
    name: "Additional Insured - Vendors",
    type: "BOP",
    line: "BOP"
  },
  BP7AdditionalInsuredVendorsBP7021_UFG: {
    name: "Additional Insured - Vendors - UFG BP7021",
    type: "BOP",
    line: "BOP"
  },
  BP7AddlInsdEngineersArchitectsSurveyorsNotEngagedB: {
    name: "Additional Insured - Engineers, Architects or Surveyors Not Engaged by the Named Insured",
    type: "BOP",
    line: "BOP"
  },
  BP7AddlInsdStatePoliticalSubdivisionsPermits: {
    name: "Additional Insured - State Or Governmental Agency OR Subdivision Or Political Subdivision - Permits Or Authorizations",
    type: "BOP",
    line: "BOP"
  },
  BP7ForgeryAlteration: {
    name: "Forgery Alteration",
    type: "BOP",
    line: "BOP"
  },
  BP7ElectrData: { name: "Electronic Data", type: "BOP", line: "BOP" },
  BP7InterruptionCompOps: {
    name: "Interruption of Computer Operations",
    type: "BOP",
    line: "BOP"
  },
  BP7CompFraudFundsTransferFraud: {
    name: "Computer Fraud and Funds Transfer Fraud",
    type: "BOP",
    line: "BOP"
  },
  BP7AddlInsdGrantorOfFranchiseEndorsement: {
    name: "Additional Insured - Grantor of Franchise",
    type: "Location",
    line: "BOP"
  },
  BP7LocationFireDeptService: {
    name: "Fire Department Services",
    type: "Location",
    line: "BOP"
  },
  BP7WaterBackUpAndSumpOverflowLocation: {
    name: "Water Back-Up And Sump Overflow",
    type: "Location",
    line: "BOP"
  },
  BP7FineArts: { name: "Fine Arts", type: "Location", line: "BOP" },
  BP7LocationOutdoorSigns: {
    name: "Location Outdoor Signs",
    type: "Location",
    line: "BOP"
  },
  BP7LocationMoneySecurities: {
    name: "Money and Securities",
    type: "Location",
    line: "BOP"
  },
  BP7AddlInsdManagersLessorsPremises: {
    name: "Additional Insured - Managers or Lessors of Premises",
    type: "Location",
    line: "BOP"
  },
  BP7DebrisRmvlAddlIns: {
    name: "Debris Removal Additional Insurance",
    type: "Building",
    line: "BOP"
  },
  BP7OrdinanceOrLawCov: {
    name: "Ordinance or Law",
    type: "Building",
    line: "BOP"
  },
  BP7SpoilgCov: { name: "Spoilage", type: "Building", line: "BOP" },
  BP7BrandsAndLabels: {
    name: "Brands and Labels",
    type: "Building",
    line: "BOP"
  },
  BP7FoodContamination: {
    name: "Food Contamination",
    type: "Building",
    line: "BOP"
  },
  BP7ClassificationValuablePapers: {
    name: "Valuable Papers",
    type: "Building",
    line: "BOP"
  },
  BP7ClassificationAccountsReceivable: {
    name: "Accounts Receivable",
    type: "Building",
    line: "BOP"
  },
  BP7UtilitySrvcsDirectDamage: {
    name: "Utility Services - Direct Damage",
    type: "Building",
    line: "BOP"
  },
  BP7UtilitySrvcsTimeElement: {
    name: "Utility Services - Time Element",
    type: "Building",
    line: "BOP"
  },
  CA7AdditionalInsuredCityOfCedarRapidsIowa_UFG: {
    name: "Additional Insured - City of Cedar Rapids Iowa",
    type: "Auto",
    line: "Auto"
  },
  CA7AddInsuredIterestEndorsemet_UFG: {
    name: "Additional Insured or Interest Endorsement",
    type: "Auto",
    line: "Auto"
  },
  CA7BusinessInterruption: {
    name: "Business Interruption",
    type: "Auto",
    line: "Auto"
  },
  CA7FellowEmplCovForDesignatedEmplsPoss: {
    name: "Fellow Employee Coverage For Designated Employees/Positions",
    type: "Auto",
    line: "Auto"
  },
  CA7RentalReimbursementTTT: {
    name: "Rental Reimbursement",
    type: "Vehicle",
    line: "Auto"
  },
  CA7RentalReimbursementPPT: {
    name: "Rental Reimbursement",
    type: "Vehicle",
    line: "Auto"
  },
  CA7TruckRentalReimbursementTTT: {
    name: "Rental Reimbursement",
    type: "Vehicle",
    line: "Auto"
  },
  CA7PrivatePassengerRentalReimbursementPPT: {
    name: "Rental Reimbursement",
    type: "Vehicle",
    line: "Auto"
  },
  CA7AudioVisualAndDataElectrEquipCovTTT: {
    name: "Audio, Visual And Data Electronic Equipment Coverage Added Limits",
    type: "Vehicle",
    line: "Auto"
  },
  CA7AudioVisualAndDataElectrEquipCovPPT: {
    name: "Audio, Visual And Data Electronic Equipment Coverage Added Limits",
    type: "Vehicle",
    line: "Auto"
  },
  CA7AutoLoanLeaseGapCovTTT: {
    name: "Auto Loan/Lease Gap",
    type: "Vehicle",
    line: "Auto"
  },
  CA7AutoLoanLeaseGapCovPrivatePassengerPPT: {
    name: "Auto Loan/Lease Gap",
    type: "Vehicle",
    line: "Auto"
  },
  CA7LossPayableClauseTTT: {
    name: "Loss Payable Clause",
    type: "Vehicle",
    line: "Auto"
  },
  CA7LossPayableClausePPT: {
    name: "Loss Payable Clause",
    type: "Vehicle",
    line: "Auto"
  },
  CA7LossPayableClauseAudioVisualAndDataElectrEquTTT: {
    name: "Loss Payable Clause - Audio, Visual And Data Electronic Equipment Coverage Added Limits",
    type: "Vehicle",
    line: "Auto"
  },
  CA7MultiPurposeEquipTTT: {
    name: "Multipurpose Equipment",
    type: "Vehicle",
    line: "Auto"
  },
  CA7TapesRecordsAndDiscsCovTTT: {
    name: "Tapes, Records And Discs",
    type: "Vehicle",
    line: "Auto"
  },
  CA7FarmLaborContractorsTTT: {
    name: "Transportation Of Seasonal Or Migrant Agricultural Workers",
    type: "Vehicle",
    line: "Auto"
  },
  CA7TruckersUniformIntermodalInterchangeEndtFormTTT: {
    name: "Truckers - Uniform Intermodal Interchange Endorsement Form UIIE - 1",
    type: "Vehicle",
    line: "Auto"
  }
};

// prettier-ignore
export const lessorsRiskClasses = [
  {"code": "03181","description":"Chiropractor - Office","isLessorsRisk":"false"},
  {"code": "03181","description":"Chiropractor - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03191","description":"Chiropractor - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03191","description":"Chiropractor - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03221","description":"Dentist and Oral Surgeon - Office","isLessorsRisk":"false"},
  {"code": "03221","description":"Dentist and Oral Surgeon - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03231","description":"Dentist and Oral Surgeon - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03231","description":"Dentist and Oral Surgeon - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03241","description":"Graphic Designer - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03241","description":"Graphic Designer - Office","isLessorsRisk":"false"},
  {"code": "03251","description":"Graphic Designer - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03251","description":"Graphic Designer - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03261","description":"Nurses - Office","isLessorsRisk":"false"},
  {"code": "03261","description":"Nurses - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03271","description":"Nurses - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03271","description":"Nurses - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03281","description":"Optometrists - Office","isLessorsRisk":"false"},
  {"code": "03281","description":"Optometrists - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03291","description":"Optometrists - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03291","description":"Optometrists - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03301","description":"Physical Therapists - Office","isLessorsRisk":"false"},
  {"code": "03301","description":"Physical Therapists - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03311","description":"Physical Therapists - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03311","description":"Physical Therapists - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03331","description":"Physicians & Surgeons - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03331","description":"Physicians & Surgeons - Office","isLessorsRisk":"false"},
  {"code": "03341","description":"Physicians & Surgeons - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03341","description":"Physicians & Surgeons - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03351","description":"Podiatrists - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03351","description":"Podiatrists - Office","isLessorsRisk":"false"},
  {"code": "03361","description":"Podiatrists - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03361","description":"Podiatrists - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03371","description":"Speech Therapists - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03371","description":"Speech Therapists - Office","isLessorsRisk":"false"},
  {"code": "03381","description":"Speech Therapists - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03381","description":"Speech Therapists - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03391","description":"Stenographic Services - Office","isLessorsRisk":"false"},
  {"code": "03391","description":"Stenographic Services - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03401","description":"Stenographic Services - Condominium - Office","isLessorsRisk":"false"},
  {"code": "03401","description":"Stenographic Services - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03411","description":"Travel Agents - Office","isLessorsRisk":"false"},
  {"code": "03411","description":"Travel Agents - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03421","description":"Travel Agents - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "03421","description":"Travel Agents - Condominium - Office","isLessorsRisk":"false"},

 // "61213 - Malls - LRO - Mini or Strip No Food" only has a LRO, no non-LR class 
  {"code": "61213","description":"Malls - LRO - Mini or Strip No Food","isLessorsRisk":"true"},
  
  {"code": "63611","description":"Accounting Services - Except CPAs - Office","isLessorsRisk":"false"},
  {"code": "63611","description":"Accounting Services - Except CPAs - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63621","description":"Accounting Services - Except CPAs - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63621","description":"Accounting Services - Except CPAs - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63631","description":"Accounting Services - CPAs - Office","isLessorsRisk":"false"},
  {"code": "63631","description":"Accounting Services - CPAs - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63641","description":"Accounting Services - CPAs - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63641","description":"Accounting Services - CPAs - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63651","description":"Advertising and Related Services - Office","isLessorsRisk":"false"},
  {"code": "63651","description":"Advertising and Related Services - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63661","description":"Advertising and Related Services - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63661","description":"Advertising and Related Services - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63671","description":"Bookkeeping Services - Office","isLessorsRisk":"false"},
  {"code": "63671","description":"Bookkeeping Services - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63681","description":"Bookkeeping Services - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63681","description":"Bookkeeping Services - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63691","description":"Collection Agencies - Office","isLessorsRisk":"false"},
  {"code": "63691","description":"Collection Agencies - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63711","description":"Collection Agencies - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63711","description":"Collection Agencies - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63721","description":"Credit Reporting Agencies - Office","isLessorsRisk":"false"},
  {"code": "63721","description":"Credit Reporting Agencies - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63731","description":"Credit Reporting Agencies - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63731","description":"Credit Reporting Agencies - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63741","description":"Detective or Investigative Agencies - Private - Office","isLessorsRisk":"false"},
  {"code": "63741","description":"Detective or Investigative Agencies - Private - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63751","description":"Detective or Investigative Agencies - Private - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63751","description":"Detective or Investigative Agencies - Private - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63761","description":"Employment Agencies (Personal Injury And Advertising Injury Endorsement BP 04 37) Office","isLessorsRisk":"false"},
  {"code": "63761","description":"Employment Agencies (Personal Injury And Advertising Injury Endorsement BP 04 37) Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63771","description":"Employment Agencies (Personal Injury And Advertising Injury Endorsement BP 04 37) Condominium Office","isLessorsRisk":"false"},
  {"code": "63771","description":"Employment Agencies  - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63781","description":"Engineers or Architects - Consulting - Not engaged in actual construction - Office","isLessorsRisk":"false"},
  {"code": "63781","description":"Engineers or Architects - Consulting - Not engaged in actual construction - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63791","description":"Engineers or Architects - Consulting - Not engaged in actual construction - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63791","description":"Engineers or Architects - Consulting - Not engaged in actual construction Condominium Office Lessors","isLessorsRisk":"hidden"},
  {"code": "63811","description":"Health Maintenance Organizations - Office","isLessorsRisk":"false"},
  {"code": "63811","description":"Health Maintenance Organizations - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63821","description":"Health Maintenance Organizations - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63821","description":"Health Maintenance Organizations - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63831","description":"Inspection and Appraisal Companies - Inspecting for insurance or valuation purposes - Office","isLessorsRisk":"false"},
  {"code": "63831","description":"Inspection and Appraisal Companies - Inspecting for insurance or valuation - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63841","description":"Inspection and Appraisal Companies - Inspecting for insurance or valuation -Condo - Office","isLessorsRisk":"false"},
  {"code": "63841","description":"Inspection and Appraisal Companies - Inspecting for insurance or valuation - Condo - Office - Lessor","isLessorsRisk":"hidden"},
  {"code": "63851","description":"Insurance Agents - Office","isLessorsRisk":"false"},
  {"code": "63851","description":"Insurance Agents - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63861","description":"Insurance Agents - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63861","description":"Insurance Agents - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63871","description":"Interior Decorators - Office","isLessorsRisk":"false"},
  {"code": "63871","description":"Interior Decorators - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63881","description":"Interior Decorators - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63881","description":"Interior Decorators - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63891","description":"Labor Union (Personal Injury And Advertising Injury Endorsement BP 04 37 must be attached.) - Office","isLessorsRisk":"false"},
  {"code": "63891","description":"Labor Union (Personal Injury And Advertising Injury Endorsement BP 04 37) - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63911","description":"Labor Union (Personal Injury And Advertising Injury Endorsement BP 04 37) - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63911","description":"Labor Union - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63921","description":"Lawyers - Office","isLessorsRisk":"false"},
  {"code": "63921","description":"Lawyers - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63931","description":"Lawyers - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63931","description":"Lawyers - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63941","description":"Manufacturers' Representatives - Office","isLessorsRisk":"false"},
  {"code": "63941","description":"Manufacturers' Representatives - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63951","description":"Manufacturers' Representatives - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63951","description":"Manufacturers' Representatives - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63961","description":"Marine Appraisers or Surveyors - Office","isLessorsRisk":"false"},
  {"code": "63961","description":"Marine Appraisers or Surveyors - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63971","description":"Marine Appraisers or Surveyors - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63971","description":"Marine Appraisers or Surveyors - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63981","description":"Medical Offices - Office","isLessorsRisk":"false"},
  {"code": "63981","description":"Medical Offices - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "63991","description":"Medical Offices - Condominium - Office","isLessorsRisk":"false"},
  {"code": "63991","description":"Medical Offices - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64011","description":"Payroll Accounting Services - Office","isLessorsRisk":"false"},
  {"code": "64011","description":"Payroll Accounting Services - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64021","description":"Payroll Accounting Services - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64021","description":"Payroll Accounting Services - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64031","description":"Political Campaign Headquarters or Offices - Office","isLessorsRisk":"false"},
  {"code": "64031","description":"Political Campaign Headquarters or Offices - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64051","description":"Political Campaign Headquarters or Offices - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64051","description":"Political Campaign Headquarters or Offices - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64061","description":"Real Estate Agents - Office","isLessorsRisk":"false"},
  {"code": "64061","description":"Real Estate Agents - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64071","description":"Real Estate Agents - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64071","description":"Real Estate Agents - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64081","description":"Security and Patrol Agencies (Personal Injury And Advertising Injury Endorsement BP 04 37) - Office","isLessorsRisk":"false"},
  {"code": "64081","description":"Security and Patrol Agencies - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64091","description":"Security and Patrol Agencies - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64091","description":"Security and Patrol Agencies - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64121","description":"Ticket Agencies - Theatrical - Office","isLessorsRisk":"false"},
  {"code": "64121","description":"Ticket Agencies - Theatrical - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64131","description":"Ticket Agencies - Theatrical - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64131","description":"Ticket Agencies - Theatrical - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64141","description":"Ticket Agencies - Other Than Theatrical - Office","isLessorsRisk":"false"},
  {"code": "64141","description":"Ticket Agencies - Other Than Theatrical - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64151","description":"Ticket Agencies - Other Than Theatrical - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64151","description":"Ticket Agencies - Other Than Theatrical - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64161","description":"Title Agents - Office","isLessorsRisk":"false"},
  {"code": "64161","description":"Title Agents - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64171","description":"Title Agents - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64171","description":"Title Agents - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64181","description":"Veterinarians Office - Office","isLessorsRisk":"false"},
  {"code": "64181","description":"Veterinarians Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "64191","description":"Veterinarians Office - Condominium - Office","isLessorsRisk":"false"},
  {"code": "64191","description":"Veterinarians Office - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "65111","description":"Water Companies - Office","isLessorsRisk":"false"},
  {"code": "65111","description":"Water Companies - Lessors Risk Only","isLessorsRisk":"hidden"},

  /* these only have lessors risk... no non-LR */
  {"code": "65141","description":"Townhouses or Similar - 4 families or less,  no mercantile or office occupancy - lessor's risk only","isLessorsRisk":"hidden"},
  {"code": "65141","description":"Boarding Houses/Rooms - Three or four family lessor's risks only - no mercantile or office occupancy","isLessorsRisk":"hidden"},
  {"code": "65141","description":"Dwellings - Three or Four Family (Lessor's Risk Only) - No Mercantile Occupancy","isLessorsRisk":"hidden"},
  {"code": "65142","description":"Boarding Houses/Rooms - Three or four family lessor's risks only - mercantile or office occupancy","isLessorsRisk":"hidden"},
  {"code": "65142","description":"Townhouses or Similar - 4 families or less, w/mercantile or office occupancy - lessor's risk only","isLessorsRisk":"hidden"},
  {"code": "65142","description":"Dwellings - Three or Four Family (Lessor's Risk Only) - With Mercantile Occupancy","isLessorsRisk":"hidden"},
  {"code": "65144","description":"4 families or fewer, with no office occupancy - includes 3- or 4- family lessor's risk only","isLessorsRisk":"hidden"},
  {"code": "65145","description":"4 families or fewer, with office occupancy - includes 3 or 4 family lessor's risk only and garden","isLessorsRisk":"hidden"},
  {"code": "69145","description":"Dwellings - Three or Four Family (Lessor's Risk Only) - Residential Condominiums","isLessorsRisk":"hidden"},

  {"code": "65161","description":"Water Companies - Condominium - Office","isLessorsRisk":"false"},
  {"code": "65161","description":"Water Companies - Condominium - Office - Lessors Risk Only","isLessorsRisk":"hidden"},
  {"code": "65171","description":"Not Otherwise Classified - Office","isLessorsRisk":"false"},
  {"code": "65171","description":"Not Otherwise Classified - Lessors Risk Only","isLessorsRisk":"true"},
  {"code": "65231","description":"Not Otherwise Classified - Condominium - Office","isLessorsRisk":"false"},
  {"code": "65231","description":"Not Otherwise Classified - Condominium - Office - Lessors Risk Only","isLessorsRisk":"true"},

  // "66213 - Malls - LRO - Mini or Strip With Food" only has a LRO, no non-LR class 
  {"code": "66213","description":"Malls - LRO - Mini or Strip With Food","isLessorsRisk":"true"},
  {"code": "59999", "description": "Retail Stores (Not Otherwise Classified)", "isLessorsRisk":"true"}

]

export const allowedPrivatePassengerClasses = ["7391", "7398", "7399"];

export const contractorLiabilityExposureMinimums = {
  AL: { minimum: "18240" },
  AR: { minimum: "30000" },
  AZ: { minimum: "26400" },
  CA: { minimum: "33600" },
  CO: { minimum: "23500" },
  FL: { minimum: "20800" },
  IA: { minimum: "36000" },
  ID: { minimum: "32800" },
  IL: { minimum: "26800" },
  IN: { minimum: "34080" },
  KS: { minimum: "19900" },
  KY: { minimum: "33700" },
  LA: { minimum: "19500" },
  MN: { minimum: "18500" },
  MO: { minimum: "35440" },
  MS: { minimum: "10400" },
  MT: { minimum: "15700" },
  ND: { minimum: "37700" },
  NE: { minimum: "35520" },
  NJ: { minimum: "17000" },
  NM: { minimum: "34800" },
  NV: { minimum: "36560" },
  OK: { minimum: "19200" },
  OR: { minimum: "23300" },
  OH: { minimum: "19500" },
  PA: { minimum: "5200" },
  SD: { minimum: "31840" },
  TN: { minimum: "13300" },
  TX: { minimum: "31900" },
  UT: { minimum: "45400" },
  WI: { minimum: "23700" },
  WY: { minimum: "19500" }
};

/**
 * statesWorkCompModifierTypes
 * acceptable values:
 * - creditsDebits
 * - none
 * - both
 *
 * "both" currently has the same affect as "creditsDebits"
 * ... I think it was initially setup to support both "creditsDebits" and "dividends",
 * ... but was never implemented
 */
export const statesWorkCompModifierTypes = {
  AL: "creditsDebits",
  AR: "creditsDebits",
  AZ: "creditsDebits",
  CA: "creditsDebits",
  CO: "creditsDebits",
  IA: "creditsDebits",
  ID: "creditsDebits",
  IL: "creditsDebits",
  IN: "creditsDebits",
  LA: "creditsDebits",
  KS: "creditsDebits",
  KY: "creditsDebits",
  MN: "creditsDebits",
  MO: "creditsDebits",
  MS: "creditsDebits",
  MT: "creditsDebits",
  NM: "creditsDebits",
  NJ: "creditsDebits",
  NV: "creditsDebits",
  OK: "creditsDebits",
  PA: "creditsDebits",
  SD: "creditsDebits",
  TN: "creditsDebits",
  TX: "creditsDebits",
  UT: "creditsDebits",
  WI: "dividends"
};

export const priorLossesFields = {
  WCMWorkersCompLine: {
    panelTitle: "Prior Workers' Compensation Claims",
    tables: [
      {
        rowLabel: "Workers' Compensation Claim Count",
        claimType: "WCMPriorClaims",
        fields: [
          {
            label: "Current Term",
            id: "year1"
          },
          {
            label: "Prior Term",
            id: "year2"
          },
          {
            label: "Second Prior Term",
            id: "year3"
          }
        ]
      }
    ]
  },
  CA7CommAutoLine: {
    panelTitle: "Prior Auto Claims",
    tables: [
      {
        rowLabel: "Auto Liability Claim Count",
        claimType: "CAPriorLiabilityClaims",
        fields: [
          {
            label: "Current Term",
            id: "year1"
          },
          {
            label: "Prior Term",
            id: "year2"
          },
          {
            label: "Second Prior Term",
            id: "year3"
          }
        ]
      },
      {
        rowLabel: "Auto Physical Damage Claim Count",
        claimType: "CAPriorPhyDamageClaims",
        fields: [
          {
            label: "Current Term",
            id: "year1"
          },
          {
            label: "Prior Term",
            id: "year2"
          },
          {
            label: "Second Prior Term",
            id: "year3"
          }
        ]
      }
    ]
  }
};

const _stateSpecificFields = {
  CA: {
    stateCode: "CA",
    stateName: "California",
    fields: [{ name: "bureauId", label: "Bureau ID", requiredLength: 7 }]
  },
  MN: {
    isCustom: true, // isCustom makes this state's field use a custom field injection on StateDataSection
    stateCode: "MN",
    stateName: "Minnesota",
    fields: [
      {
        name: "unEmploymentId",
        label: "Unemployment ID",
        requiredLength: 12,
        regExValidation: "^$|^\\d{10}$|^\\d{12}$|^EXEMPT$",
        errorMessage: "Unemployment ID format is EXEMPT or 10 or 12 digits."
      }
    ]
  },
  NJ: {
    isCustom: true, // isCustom makes this state's field use a custom field injection on StateDataSection
    stateCode: "NJ",
    stateName: "New Jersey",
    fields: [
      {
        name: "bureauId",
        label: "Bureau ID",
        requiredLength: 6
      },
      {
        name: "stateTaxID",
        label: "State Tax ID",
        requiredLength: 12
      },
      {
        name: "taxIDNumber",
        label: "Taxpayer ID",
        requiredLength: 9,
        isRequired: true
      }
    ]
  },
  PA: {
    stateCode: "PA",
    stateName: "Pennsylvania",
    fields: [{ name: "bureauId", label: "Bureau Id", requiredLength: 7 }]
  },
  WI: {
    stateCode: "WI",
    stateName: "Wisconsin",
    fields: [{ name: "bureauId", label: "Bureau ID", requiredLength: 9 }]
  }
};

const intraInterStates = [
  "AL",
  "AR",
  "AZ",
  "CO",
  "FL",
  "IA",
  "ID",
  "IN",
  "IL",
  "KS",
  "KY",
  "LA",
  "MO",
  "MS",
  "MT",
  "NE",
  "NV",
  "NM",
  "OR",
  "OK",
  "SD",
  "TN",
  "TX",
  "UT"
];

export const stateSpecificFields = stateCode => {
  const fieldDetails = intraInterStates.find(s => s === stateCode);

  if (fieldDetails) {
    return {
      stateCode,
      stateName: convertStatesAndAbbreviations(stateCode, "name"),
      fields: [
        {
          name: "ncciid",
          label: "NCCI Interstate ID",
          requiredLength: 9,
          disabled: `${stateCode}_ncciintrastate`,
          required: `${stateCode}_ncciintrastate`,
          relatedField: "ncciintrastate",
          nonStateField: "ncciid_UFG",
          requiredError: `A NCCI ID is required for ${stateCode}`
        },
        {
          name: "nccii_or",
          jsx: (
            <div
              key="ncciid-or-text"
              className="oq__state-specific__ncciid__or-block"
            >
              or
            </div>
          )
        },
        {
          name: "ncciintrastate",
          label: "NCCI Intrastate ID",
          requiredLength: 9,
          disabled: `${stateCode}_ncciid`,
          required: `${stateCode}_ncciid`,
          relatedField: "ncciid",
          requiredError: `A NCCI ID is required for ${stateCode}`
        }
      ]
    };
  }

  return _stateSpecificFields[stateCode];
};

//convertStatesAndAbbreviations(coverableName, "abbr");

export const CoveragesRequiringAtleastOnScheduledItem = [
  "BP7ContrctrsInstalltnToolsAndEquipmtCov"
];

export const bopQuestionsreversFilters = {
  Applicantscookingareaincludegrills_UFG: [
    {
      id: "Applicantscookingareaprotectedbyul300_UFG",
      default: true
    },
    {
      id: "Applicantssuppressioninspectiontag_UFG",
      default: true
    },
    {
      id: "Applicantsservicecontracttwovisits_UFG",
      default: true
    },
    {
      id: "Applicantskeepclasskportablefire_UFG",
      default: true
    },
    {
      id: "Componentsapplicantssuppressionsys_UFG",
      default: true
    }
  ],
  Applicanthiresubcontractors_UFG: [
    {
      id: "ApplicantSubcontAggAddInsuranceWaiverOfSub_UFG",
      default: true
    },
    {
      id: "ApplicantTotSubcontCostsTotalAnnualRevenues_UFG",
      default: true
    },
    {
      id: "ApplicantReqSubcontProvideCertOfIns_UFG",
      default: true
    },
    {
      id: "ApplicantReqSubcontSubcontractAgreement_UFG",
      default: true
    }
  ]
};

export const questionCodesToFormat = [
  "Applicantpremiseshasswimpool_UFG",
  "Applicanthiresubcontdoesanycriterianot_UFG",
  "Applicantsgasolinesalesoperation_UFG",
  "DoesApplicantsGasolineSales_UFG"
];

export const genderOptions = {
  F: "Female",
  M: "Male",
  Unknown: "Unknown"
};

//constant for state description
export const stateOptions = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
  other: "Other"
};

export const cyberDisabledStates = ["FL"];
