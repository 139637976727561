import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { v4 } from "uuid";
import {
  useForm,
  FormGroup,
  Form,
  TextArea,
  FlexRow,
  Button
} from "@ufginsurance/ui-kit";
import { Translations } from "../../../translations";

const UnreadMessages = ({
  can_reply,
  selectedConversation,
  onBackToMessagesClick,
  onHandleClose,
  onReplyMessage,
  isReplyMessageLoading,
  isLoading
}) => {
  const { messages, underwriter_name, underwriter_photo_url } =
    selectedConversation;

  const onSubmit = formData => {
    onReplyMessage(formData.values.replyMessage);
  };

  const form = useForm({
    values: {
      replyMessage: ""
    },
    onSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <div>
      <div className="message-body">
        <button
          onClick={onBackToMessagesClick}
          className="btn btn-as-link back-to-messages-link"
        >
          &lt; Back to Messages
        </button>
        <p>You are having a conversation with</p>
        <div className="conversation-container">
          <div
            className="photo-space"
            style={{ backgroundImage: `url(${underwriter_photo_url})` }}
          />
          <div className="message-details">
            <h4>{underwriter_name}</h4>
          </div>
        </div>
        <div className="message-list">
          {messages &&
            messages.map(item => (
              <div className="messages" key={item.id || v4()}>
                <h6>
                  {item.author.name}{" "}
                  {item.is_read === false && <span>New</span>} <br />
                  <i>
                    {moment(
                      item.created_on + " +0000",
                      "MMM D, YYYY h:mm A Z"
                    ).format("lll")}
                  </i>
                </h6>
                <p>{item.body}</p>
              </div>
            ))}
        </div>
        {can_reply && (
          <Form context={form} id="unread-messages-form">
            <FormGroup>
              <TextArea
                id="replyMessage"
                name="replyMessage"
                label="Enter your response..."
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.replyMessage || ""}
                required
              />
            </FormGroup>
            <FlexRow align="right">
              <Button
                className="messaging-close"
                variant="secondary"
                onClick={onHandleClose}
              >
                {Translations.commercial.new_submissions_tab.messages.close}
              </Button>
              <Button
                type="submit"
                className="messaging-start"
                variant="primary"
                disabled={
                  !values.replyMessage || isReplyMessageLoading || isLoading
                }
                spinner={isReplyMessageLoading || isLoading}
                spinnerPos="right"
              >
                {Translations.commercial.new_submissions_tab.messages.submit}
              </Button>
            </FlexRow>
          </Form>
        )}
      </div>
    </div>
  );
};

UnreadMessages.propTypes = {
  onBackToMessagesClick: PropTypes.func.isRequired,
  selectedConversation: PropTypes.object,
  can_reply: PropTypes.bool,
  onHandleClose: PropTypes.func.isRequired,
  onReplyMessage: PropTypes.func.isRequired,
  isReplyMessageLoading: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default UnreadMessages;
