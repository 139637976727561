import React from "react";
import { ButtonDropNav } from "@ufginsurance/ui-kit";

export const CheckboxOptions = {
  "BOP-Pro Select": {
    order: 1,
    compare: {
      text: "Compare BOP-Pro Select",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-coveragecomparison-boppro-plus.pdf"
    }
  },
  "BOP-Pro Plus": {
    order: 2,
    compare: {
      text: "Compare BOP-Pro Plus",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-coveragecomparison-boppro-plus.pdf"
    }
  },
  "BOP-Pro Contractor": {
    order: 3,
    compare: {
      text: "Compare BOP-Pro Contractor",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-contractors-coveragecomparison.pdf"
    }
  },
  "BOP-Pro Contractor Plus": {
    order: 4,
    compare: {
      text: "Compare BOP-Pro Contractor Plus",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-contractors-coveragecomparison.pdf"
    }
  },
  "BOP-Pro Restaurant": {
    order: 5,
    compare: {
      text: "Compare BOP-Pro Restaurant",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-restaurant-coveragecomparison.pdf"
    }
  },
  "BOP-Pro Restaurant Plus": {
    order: 6,
    compare: {
      text: "Compare BOP-Pro Restaurant Plus",
      link: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-restaurant-coveragecomparison.pdf"
    }
  }
};

// BP7EndorsementType_UFG - this coverage is located in BOP on step 4
export const BP7EndorsementType_UFG = field => {
  // checkboxes is used when converting a coverage dropdown to checkbox options
  const checkboxes = field.options
    .map(o => {
      return { ...o, label: o.name };
    })
    .sort((a, b) => {
      const A = CheckboxOptions[a.name];
      const B = CheckboxOptions[b.name];

      if (A?.order && B?.order) return A.order - B.order;

      return 0;
    });

  const compareOptions = [
    {
      text: "Compare All BOP-Pro Enhancements",
      to: "https://www.ufginsurance.com/docs/librariesprovider2/commercial/products/bop-pro/ufg-boppro-coveragecomparison.pdf",
      newWindow: true
    }
  ];

  field?.options?.forEach(option => {
    const o = CheckboxOptions[option.name];
    compareOptions.push({
      text: o.compare.text,
      to: o.compare.link,
      newWindow: true
    });
  });

  return {
    ...field,
    className: "oq__coverage_enhancement_endorsement",
    dataType: "dropdownToTextboxes",
    checkboxes,
    jsxAfter: (
      <div className="oq__endoresement-after-jsx">
        <ButtonDropNav
          id="compareEnhancement"
          buttonText="Compare BOP-Pro Enhancements"
          variant="link"
          links={compareOptions}
        />
      </div>
    )
  };
};
