import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CertificateHistoryTable from "./CertificateHistoryTable";
import { FormGroup, Form, useForm, Input, Dropdown, Button } from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";

const CertificateHistoryForm = props => {
  const { certificateHistoryIsLoading, certificateHistoryList, fetchCertificateHistory, certificateHistoryError, agencyCode } = props;

  const onSubmit = useCallback(
    ({ values }) => {
      fetchCertificateHistory(
        agencyCode,
        values.searchText,
        values.searchType
      );
    },
    [agencyCode, fetchCertificateHistory]
  );

  const initialValues = {
    searchType: "",
    searchText: ""
  };
  const form = useForm({ values: initialValues, onSubmit });
  const {
    values,
    // errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate
  } = form;

  const handleSearchTextValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (values.searchType && values.searchType === "C") {
      if (!/^.{3,}$/.test(value))
        fieldErrors.push(
          "Certificate holder's name must be at least 3 characters"
        );
    }
    if (values.searchType && values.searchType === "N") {
      if (!/^.{3,}$/.test(value))
        fieldErrors.push("Insured Name must be at least 3 characters");
    }

    return fieldErrors;
  };

  return (
    <div className="certificate-history-form-container">
      <Form context={form}>
        <FormGroup>
          <Dropdown
            id="searchType"
            name="searchType"
            options={Translations.home_service_center_tab.search_type}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.searchType}
            size="md"
            label="Search Type"
            required
          />
          <Input
            id="searchText"
            name="searchText"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleSearchTextValidate}
            value={values.searchText}
            required
            label="Search Text"
          />
          <div className="certificate-history-submit-container">
          <Button
            type="submit"
            disabled={!values.searchText || !values.searchType}
            variant="primary"
            spinner={certificateHistoryIsLoading}            
          >
            Submit
          </Button>
          </div>
        </FormGroup>
      </Form>

      {certificateHistoryError !== null ? (
        <p className="friendly-error-messaging">
          {certificateHistoryError != null
            ? `${Translations.home_service_center_tab.error} `
            : ""}
        </p>
      ) : certificateHistoryList.length > 0 ? (
        <CertificateHistoryTable
          certificateHistoryList={certificateHistoryList}
        />
      ) : null}
    </div>
  );
};

export default CertificateHistoryForm;

CertificateHistoryForm.propTypes = {
  certificateHistoryIsLoading: PropTypes.bool,
  agencyCode: PropTypes.string,
  fetchCertificateHistory: PropTypes.func.isRequired,
  certificateHistoryList: PropTypes.array,
  certificateHistoryError: PropTypes.any
};
