import * as types from "../constants/actionTypes";

const fetchChangeToUfgAgentPortalViewSucceeded = data => ({
  type: types.CHANGE_TO_UFG_AGENT_PORTAL_VIEW_STATE_SUCCEEDED,
  payload: data
});

export const changeToUfgAgentPortalViewState = () => dispatch => {
  return new Promise(resolve => {
    resolve(dispatch(fetchChangeToUfgAgentPortalViewSucceeded()));
  });
};

const fetchChangeToUfgSpecialtyPortalViewSucceeded = data => ({
  type: types.CHANGE_TO_UFG_SPECIALTY_PORTAL_VIEW_STATE_SUCCEEDED,
  payload: data
});

export const changeToUfgSpecialtyPortalViewState = () => dispatch => {
  return new Promise(resolve => {
    resolve(dispatch(fetchChangeToUfgSpecialtyPortalViewSucceeded()));
  });
};

const fetchChangeToMercerPortalViewSucceeded = data => ({
  type: types.CHANGE_TO_MERCER_PORTAL_VIEW_STATE_SUCCEEDED,
  payload: data
});

export const changeToMercerSpecialtyPortalViewState = () => dispatch => {
  return new Promise(resolve => {
    resolve(dispatch(fetchChangeToMercerPortalViewSucceeded()));
  });
};
