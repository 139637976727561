import * as types from "./actionTypes";
import * as claimsService from "../services/claimsService";
import * as homeClaimsService from "../../services/homeClaimsService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

// CLAIM DETAILS
const fetchClaimsStarted = () => ({
  type: types.FETCH_CLAIMS_STARTED
});

export const fetchClaimsSucceeded = claims => ({
  type: types.FETCH_CLAIMS_SUCCEEDED,
  payload: claims
});

const fetchClaimsFailed = error => ({
  type: types.FETCH_CLAIMS_FAILED,
  payload: error,
  error: true
});

export const fetchClaims = (number, selectedAgencyCode, closeAfterDate) => {
  return dispatch => {
    dispatch(fetchClaimsStarted());
    return claimsService
      .fetchClaims(number, selectedAgencyCode, closeAfterDate)
      .then(
        result => {
          dispatch(fetchClaimsSucceeded(result.data));
          return result;
        },
        error => {
          dispatch(fetchClaimsFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
          throw error.response;
        }
      );
  };
};

export const clearClaims = () => ({
  type: types.CLEAR_CLAIMS
});

// CLAIM INFORMATION BY CLAIM NUMBER
const fetchClaimStarted = () => ({
  type: types.FETCH_CLAIM_STARTED
});

export const fetchClaimSucceeded = data => ({
  type: types.FETCH_CLAIM_SUCCEEDED,
  payload: data
});

const fetchClaimFailed = error => ({
  type: types.FETCH_CLAIM_FAILED,
  payload: error,
  error: true
});

export const fetchClaim = (claimNumber, selectedAgencyCode) => {
  return dispatch => {
    dispatch(fetchClaimStarted());
    return claimsService.fetchClaim(claimNumber, selectedAgencyCode).then(
      result => {
        dispatch(fetchClaimSucceeded(result.data));
        return result;
      },
      error => {
        dispatch(fetchClaimFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
        throw error.response;
      }
    );
  };
};

// PAYMENTS
const fetchClaimsPaymentsStarted = () => ({
  type: types.FETCH_CLAIMS_PAYMENTS_STARTED
});

export const fetchClaimsPaymentsSucceeded = (claimNumber, data) => ({
  type: types.FETCH_CLAIMS_PAYMENTS_SUCCEEDED,
  payload: { claimNumber, data }
});

const fetchClaimsPaymentsFailed = error => ({
  type: types.FETCH_CLAIMS_PAYMENTS_FAILED,
  payload: error,
  error: true
});

export const fetchClaimsPayments = (claimNumber, selectedAgencyCode) => {
  return dispatch => {
    dispatch(fetchClaimsPaymentsStarted());
    return claimsService
      .fetchClaimsPayments(claimNumber, selectedAgencyCode)
      .then(
        result =>
          dispatch(fetchClaimsPaymentsSucceeded(claimNumber, result.data)),
        error => {
          dispatch(fetchClaimsPaymentsFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

// PAYMENT DETAILS
const fetchClaimsPaymentDetailsStarted = () => ({
  type: types.FETCH_CLAIMS_PAYMENT_DETAILS_STARTED
});

export const fetchClaimsPaymentDetailsSucceeded = data => ({
  type: types.FETCH_CLAIMS_PAYMENT_DETAILS_SUCCEEDED,
  payload: data
});

const fetchClaimsPaymentDetailsFailed = error => ({
  type: types.FETCH_CLAIMS_PAYMENT_DETAILS_FAILED,
  payload: error,
  error: true
});

export const fetchClaimsPaymentDetails = (
  claimNumber,
  paymentNumber,
  selectedAgencyCode
) => {
  return dispatch => {
    dispatch(fetchClaimsPaymentDetailsStarted());
    return claimsService
      .fetchClaimsPaymentDetails(claimNumber, paymentNumber, selectedAgencyCode)
      .then(
        result => dispatch(fetchClaimsPaymentDetailsSucceeded(result.data)),
        error => {
          dispatch(fetchClaimsPaymentDetailsFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

// PRINT PAYMENT DETAILS
const printClaimsPaymentDetailsStarted = () => ({
  type: types.PRINT_CLAIMS_PAYMENT_DETAILS_STARTED
});

export const printClaimsPaymentDetailsSucceeded = () => ({
  type: types.PRINT_CLAIMS_PAYMENT_DETAILS_SUCCEEDED
});

const printClaimsPaymentDetailsFailed = () => ({
  type: types.PRINT_CLAIMS_PAYMENT_DETAILS_FAILED,
  error: true
});

export const printClaimsPaymentDetails = (
  payload,
  claimNumber,
  paymentNumber
) => {
  return dispatch => {
    const documentService = new DocumentService();
    dispatch(printClaimsPaymentDetailsStarted());
    return homeClaimsService.printClaimsPaymentDetails(payload).then(
      response => {
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          `UFG_Claim-${claimNumber}_Payment-${paymentNumber}.pdf`
        );
        dispatch(printClaimsPaymentDetailsSucceeded());
      },
      error => {
        documentService.clearRenderer();
        dispatch(printClaimsPaymentDetailsFailed());
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

// NOTES
const fetchClaimsNotesStarted = claimNumber => ({
  type: types.FETCH_CLAIMS_NOTES_STARTED,
  payload: { claimNumber }
});

export const fetchClaimsNotesSucceeded = (claimNumber, data) => ({
  type: types.FETCH_CLAIMS_NOTES_SUCCEEDED,
  payload: { claimNumber, data }
});

const fetchClaimsNotesFailed = (claimNumber, error) => ({
  type: types.FETCH_CLAIMS_NOTES_FAILED,
  payload: { claimNumber, error },
  error: true
});

export const fetchClaimsNotes = claimNumber => {
  return dispatch => {
    dispatch(fetchClaimsNotesStarted(claimNumber));
    return claimsService.fetchClaimsNotes(claimNumber).then(
      result => dispatch(fetchClaimsNotesSucceeded(claimNumber, result.data)),
      error => {
        dispatch(fetchClaimsNotesFailed(claimNumber, error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

export const fetchClaimNoteDocument =
  (claimNumber, documentId, isAdjusterNote) => () => {
    const documentService = new DocumentService();
    return claimsService
      .fetchClaimNoteDocument(claimNumber, documentId, isAdjusterNote)
      .then(
        response => {
          documentService.initializeRenderer("/loading-document");
          documentService.saveOrOpenDocument(
            response.data,
            `AdjusterNotes-${claimNumber}.pdf`,
            "application/pdf"
          );
        },
        () => {
          documentService.clearRenderer();
        }
      );
  };

// CLAIMANTS
const fetchClaimsClaimantsStarted = () => ({
  type: types.FETCH_CLAIMS_CLAIMANTS_STARTED
});

export const fetchClaimsClaimantsSucceeded = (claimNumber, data) => ({
  type: types.FETCH_CLAIMS_CLAIMANTS_SUCCEEDED,
  payload: { claimNumber, data }
});

export const fetchClaimsClaimantsFailed = (claimNumber, error) => ({
  type: types.FETCH_CLAIMS_CLAIMANTS_FAILED,
  payload: { claimNumber, error },
  error: true
});

export const fetchClaimsClaimants = (claimNumber, selectedAgencyCode) => {
  return dispatch => {
    dispatch(fetchClaimsClaimantsStarted());
    return claimsService
      .fetchClaimsClaimants(claimNumber, selectedAgencyCode)
      .then(
        result =>
          dispatch(fetchClaimsClaimantsSucceeded(claimNumber, result.data)),
        error => {
          dispatch(fetchClaimsClaimantsFailed(claimNumber, error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};
