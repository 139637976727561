import React, { useContext } from "react";
import { Panel, StepIndicator } from "@ufginsurance/ui-kit";
import "./OQSideBar.scss";
import { online_quoting } from "../constants/images";
import AppetiteGuideLink from "./sidebar/AppetiteGuideLink";
import CustomerInfo from "./sidebar/CustomerInfo";
import EffectiveDate from "./sidebar/EffectiveDate";
import OnlineQuotingContext from "./OnlineQuotingContext";
import SelectedProducts from "./sidebar/SelectedProducts";
import SelectedClass from "./sidebar/SelectedClass";
import UfgInternalHelpers from "./UfgInternalHelpers";
import UnderwriterAlert from "./sidebar/UnderwriterAlert";
import UnderwriterDisplay from "./sidebar/UnderwriterDisplay";

const OQSideBar = () => {
  const { quoteData, step, supportingData, stpException, hasBlockingUwIssues } =
    useContext(OnlineQuotingContext);

  return (
    <>
      {step === 1 && (
        <div className="oq__welcome-panel">
          <img
            src={online_quoting}
            className="oq__welcome-panel__image"
            alt="Let's get started. Welcome to online quoting at UFG"
          />
        </div>
      )}
      {(supportingData || quoteData) && step > 1 && (
        <div>
          {step > 3 && <UnderwriterAlert />}

          {step > 1 && <StepIndicator currentStep={step} totalSteps={6} />}

          <Panel
            className="oq__sidebar__summary"
            highlight="blue"
            title="Quote Summary"
          >
            {(step !== 6 || (!stpException && hasBlockingUwIssues)) && (
              <EffectiveDate />
            )}
            {step > 2 && (
              <div
                className="oq__sidebar__summary__item"
                data-quote-number={`${quoteData?.quoteID || ""}`}
              >
                <b>Quote Number:</b>
                <p className="oq__sidebar__quoteId">
                  {quoteData?.quoteID || ""}
                </p>
              </div>
            )}
            <CustomerInfo />
            <SelectedProducts />
            <AppetiteGuideLink />
            {step > 2 && <SelectedClass />}
          </Panel>
          <UnderwriterDisplay />
        </div>
      )}
      <UfgInternalHelpers />
    </>
  );
};
export default OQSideBar;
