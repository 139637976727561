import qs from "query-string";
import API from "../api";

export const fetchClaimsOpened = (
  agency_code,
  start_date,
  employeeUserName
) => {
  const queryString = qs.stringify({
    agency_code,
    start_date,
    employee_name: employeeUserName
  });
  return API.agent().get(`claims/opened?${queryString}`);
};

export const removeClaimOpened = claim_number => {
  const queryString = qs.stringify({ record_number: claim_number });
  return API.agent().delete(`/claims/remove-activity/Opened?${queryString}`);
};

export const fetchClaimPayments = (agency_code, start_date) => {
  const queryString = qs.stringify({ agency_code, start_date });
  return API.agent().get(`claims/payments?${queryString}`);
};

export const removeClaimPayment = payment_number => {
  const queryString = qs.stringify({ record_number: payment_number });
  return API.agent().delete(`/claims/remove-activity/Payments?${queryString}`);
};

export const fetchClaimStatuses = agency_code => {
  const queryString = qs.stringify({ agency_code });
  return API.agent().get(`/claims/status-closing-reopenings?${queryString}`);
};

export const removeClaimStatus = (agency_code, document_din) => {
  const queryString = qs.stringify({ agency_code, document_din });
  return API.agent().delete(`/claims/status-closing-reopenings?${queryString}`);
};

export const fetchSavedLossesList = agency_code => {
  const queryString = qs.stringify({ agency_code });
  return API.agent().get(`/claims/saved-losses?${queryString}`);
};

export const deleteSavedLosses = (agency_code, control_number) => {
  const queryString = qs.stringify({ agency_code, control_number });
  return API.agent().delete(`/claims/saved-losses?${queryString}`);
};

//Home page Client notices submitted losses
export const fetchSubmittedLossesList = agency_code => {
  const queryString = qs.stringify({ agency_code });
  return API.agent().get(`/claims/submitted-losses?${queryString}`);
};

export const printClaimsPaymentDetails = payload => {
  return API.agent().post(`/reports/payment/details`, payload, {
    responseType: "blob"
  });
};
