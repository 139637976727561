import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import * as routes from "../../../constants/routes";
import * as onlineQuotingApi from "../../../services/onlineQuotingService";
import { toastError } from "../../../online-quoting/shared/util";
import { v4 } from "uuid";
import { Accordion, Button, StatusPill } from "@ufginsurance/ui-kit";
import { editCustomer } from "../../../top-action-cards/new-quote/actions/newQuoteActions";
import SubmissionInfo from "./SubmissionInfo";
import { useFlags } from "launchdarkly-react-client-sdk";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import PreQuoteModal from "../../../online-quoting/step1/PreQuoteModal";

const SubmissionResults = ({
  submissionList,
  onEditCustomerClick,
  result,
  history,
  handleRefresh
}) => {
  const { Customer } = result;
  const { imbrDevToolsEnabled } = useFlags();

  const [creatingQuote, setCreatingQuote] = useState();
  const [showPreQuoteModal, setShowPreQuoteModal] = useState(false);

  const handleEditCustomerClick = e => {
    e.stopPropagation();
    onEditCustomerClick(result.Customer);
  };

  const handleAddQuoteForExistingCustomerClick = () => {
    const customer = result.Customer;

    setCreatingQuote(customer.id);
    onlineQuotingApi
      .createQuoteExistingCustomer({
        customer,
        core_system: result.core_system
      })
      .then(
        results => {
          if (results?.data?.referenceId) {
            setCreatingQuote(null);
            history.push(
              `${routes.ONLINE_QUOTING_LOADER}/${results.data.referenceId}`
            );
          }
        },
        error => {
          console.log({ error });
          setCreatingQuote(null);
          toastError({
            log: {
              action: "create quote from account on NSL",
              description: "Unable to create quote from account on NSL",
              error: error?.response || error,
              payload: {
                customer,
                core_system: result.core_system
              },
              severity: "critical",
              type: "general"
            },
            displayMessage: <HelpDeskMessage display="trySubmittingAgain" />,
            toastId: "unable-to-save-supporting-data"
          });
        }
      );
  };

  // accordion trigger extra contrent
  const overview =
    Customer && Customer.business_address ? (
      <>
        <span className="submission-address-block">
          <span className="address-line">
            {Customer.business_address.address1}
          </span>
          <span className="city-state-zip-line">
            {Customer.business_address.city}, {Customer.business_address.state}{" "}
            {Customer.business_address.zip}
          </span>
        </span>
        {imbrDevToolsEnabled && result?.core_system === "BuildersRisk" && (
          <StatusPill color="blue" text="Builders' Risk" />
        )}
      </>
    ) : null;

  const handleShowPreQuoteModal = e => {
    e.stopPropagation();
    setShowPreQuoteModal(true);
  };

  const getCustomerAction = (
    <div className="customerActions">
      <ul>
        {!Customer.policy_center_account_id && (
          <li>
            <Button inline isLink onClick={handleEditCustomerClick}>
              Edit Customer
            </Button>
          </li>
        )}
        <li>
          <Button
            inline
            isLink
            spinner={creatingQuote === Customer.id}
            disabled={creatingQuote === Customer.id}
            onClick={handleShowPreQuoteModal}
          >
            Add a quote
          </Button>
        </li>
      </ul>
    </div>
  );

  return (
    <Accordion
      textMain={Customer.name}
      textExtra={overview}
      textRight={getCustomerAction}
      lazyRender
    >
      <div>
        {showPreQuoteModal && (
          <PreQuoteModal
            history={history}
            onContinue={handleAddQuoteForExistingCustomerClick}
            showPreQuoteModal={showPreQuoteModal}
            setShowPreQuoteModal={setShowPreQuoteModal}
          />
        )}
        {submissionList &&
          submissionList.map((obj, i) => {
            if (obj.Customer.id !== Customer.id) return null;
            if (obj.id === undefined) obj.id = v4();
            return (
              <SubmissionInfo
                key={`submission${obj.id}`}
                index={i}
                result={obj}
                history={history}
                handleRefresh={handleRefresh}
              />
            );
          })}
      </div>
    </Accordion>
  );
};

SubmissionResults.propTypes = {
  result: PropTypes.object.isRequired,
  submissionList: PropTypes.array.isRequired,
  onEditCustomerClick: PropTypes.func.isRequired,
  history: PropTypes.object,
  handleRefresh: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onEditCustomerClick: editCustomer
};

export default withRouter(connect(null, mapDispatchToProps)(SubmissionResults));
