import React from "react";
import { locationDisplayName } from "../util";
import { Alert } from "@ufginsurance/ui-kit";

// https://ufginsurance.atlassian.net/browse/OOQ-15277

export const BP7WindstormOrHailExcl = {
  jsxAfterTitle: ({ coverage, get }) => {
    const buildingId = coverage.coverableFixedId;
    const locations = get.locations({ lob: "bp7BusinessOwners" });
    const location = locations.find(l =>
      l?.buildings?.some(b => b.fixedId === buildingId)
    );
    const BP7LocationPropertyDeds = get.coverage({
      lob: "bp7BusinessOwners",
      coverableClauseType: "locationClausesGroups",
      coverableFixedId: location.bp7LocationFixedID,
      publicID: "BP7LocationPropertyDeds"
    });
    if (
      BP7LocationPropertyDeds?.terms?.some(
        t =>
          t.publicID === "BP7WindHailDed" &&
          t.chosenTermValue !== "Not Applicable"
      )
    )
      return (
        <div>
          <Alert
            className="oq__coverage__alert"
            dismissible={false}
            type="warning"
            block
            label={locationDisplayName(location)}
          >
            Please verify that the Windstorm or Hail Deductible is set to not
            applicable when the Windstorm Or Hail Exclusion is selected on all
            buildings.
          </Alert>
        </div>
      );
    return null;
  }
};
