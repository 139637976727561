import API from "../../api";

export const getUfgTeamDepartmentData = (agencyCode, companyBranch) => {
  //Specialty agents don't have access to the agent site, so we ommit the
  //specialty_type query param.
  if (!!companyBranch) {
    return API.agent().get(
      `/agencies/${agencyCode}/contacts/departments?company_branch=${companyBranch}`
    );
  }
  return API.agent().get(`/agencies/${agencyCode}/contacts/departments`);
};

export const getUfgTeamContactsData = (agencyCode, departmentId) => {
  return API.agent().get(
    `/agencies/${agencyCode}/contacts?department=${departmentId}`
  );
};
