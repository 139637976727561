import React, { createContext, useRef } from "react";

// THIS IS FOR GLOBAL STATES ONLY
// Before use, consider creating a context more locally scoped for your needs.

export const GlobalContext = createContext();

export const GlobalContextStore = props => {
  const footer = useRef();
  const content = useRef();

  return (
    <GlobalContext.Provider value={{ footer, content }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
