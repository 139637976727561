import { formatDate } from "@ufginsurance/ui-kit";
import { productKeys } from "./constants";

/**
 * 
 * productIsAvail
 * 
 * @param {object} product = product matrix object for a single product
 * @param {date} effectiveDate
 * @returns object
{ 
  isProQuote: true, 
  productAvailable: true,
  conflicts: [ "GaragePro", "garagePro", "CA7CommAutoLine", "ca7CommAuto", "BP7BusinessOwnersLine", "bp7BusinessOwners" ]
}
 */
export const productIsAvail = ({ product, effectiveDate: dt }) => {
  if (!product || !dt) return {};

  // date from the form (user-selected date for submission)
  const effectiveDate = new Date(dt);

  // data from the product matrix entry for this product
  const {
    onlineQuotingEffectiveDate,
    legacyEffectiveDate,
    expirationDate,
    conflicts,
    requires
  } = product;

  const proQuoteDate = onlineQuotingEffectiveDate
    ? new Date(onlineQuotingEffectiveDate)
    : null;
  const legacyDate = legacyEffectiveDate ? new Date(legacyEffectiveDate) : null;
  const expires = expirationDate ? new Date(expirationDate) : null;
  const isExpired = expirationDate && effectiveDate > expires;

  /**
   *
   * isOnlineQuoting
   *
   * if product displays becuase the effectiveDate is after onlineQuotingEffectiveDate
   *
   * obey the "conflict" and "requires" rules from the product matrix
   *
   */

  if (!!proQuoteDate && effectiveDate >= proQuoteDate && !isExpired)
    return {
      productAvailable: true,
      isProQuote: proQuoteDate && effectiveDate >= proQuoteDate,
      conflicts:
        conflicts.reduce(
          (acc, lob) => [...acc, lob, productKeys?.[lob]?.productLine],
          []
        ) || [],
      requires:
        requires.reduce(
          (acc, lob) => [...acc, lob, productKeys?.[lob]?.productLine],
          []
        ) || []
    };

  /**
   *
   * isLegacy
   *
   * if product displays becuase the effectiveDate is after legacyEffectiveDate
   *
   * product conflicts will all other products and requires none
   *
   */
  if (
    effectiveDate >= legacyDate &&
    (!proQuoteDate || proQuoteDate >= effectiveDate) &&
    !isExpired
  )
    return {
      productAvailable: true,
      isProQuote: proQuoteDate && effectiveDate >= proQuoteDate,
      conflicts: [
        "garagePro",
        "bp7BusinessOwners",
        "ca7CommAuto",
        "wcmWorkersComp"
      ].filter(p => p !== productKeys?.[product.code]?.productLine),
      requires: []
    };

  /**
   *
   * else product not displayed
   *
   */
  return { productAvailable: false };
};

/**
 * 
 * proQuoteProductsAvail
 * 
 * @param {object} productMatrix = the full product matrix data set for a single US state 
 * @param {date} effectiveDate
 * @returns object (example below)
 * 
{
    "CA7CommAutoLine": {
        "productAvailable": true,
        "isProQuote": true,
        "conflicts": [ "GaragePro", "garagePro", "CA7CommAutoLine", "ca7CommAuto", "BP7BusinessOwnersLine", "bp7BusinessOwners" ]
        "requires": [ "BP7BusinessOwnersLine", "bp7BusinessOwners" ]
    },
    "ca7CommAuto": {
        "productAvailable": true,
        "isProQuote": true
        "conflicts": [ "GaragePro", "garagePro", "CA7CommAutoLine", "ca7CommAuto", "BP7BusinessOwnersLine", "bp7BusinessOwners" ]
        "requires": [ "BP7BusinessOwnersLine", "bp7BusinessOwners" ]
    },
   ... more lobs ...
}
 */
export const proQuoteProductsAvail = ({ productMatrix, effectiveDate }) =>
  productMatrix?.reduce((acc, product) => {
    const availability = productIsAvail({
      product,
      effectiveDate: formatDate(effectiveDate)
    });
    return {
      ...acc,
      [product.code]: availability,
      [productKeys[product.code]?.productLine]: availability
    };
  }, {});

/**
 * if productMatrix has BOP that is onlineQuotingEnabled, then show Umbrella
 */
export const bopProIsAvail = ({ productMatrix, effectiveDate }) =>
  (productMatrix || [])?.some(
    p =>
      p.code === "BP7BusinessOwnersLine" &&
      productIsAvail({ product: p, effectiveDate }).productAvailable &&
      productIsAvail({ product: p, effectiveDate }).isProQuote
  );
