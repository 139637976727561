const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

export const HOME = "/";
export const HOME_ROOT_FRAGMENT = "/home";
export const HOME_OVERVIEW = `${HOME_ROOT_FRAGMENT}/:active_tab?`;
export const HOME_COMMERCIAL_ACTIVE_TAB = "commercial";
export const HOME_PERSONAL_ACTIVE_TAB = "personal";
export const HOME_BILLING_ACTIVE_TAB = "billing";
export const HOME_CLAIMS_ACTIVE_TAB = "claims";
export const HOME_SERVICE_CENTER_ACTIVE_TAB = "service-center";
export const COMMERCIAL = "/commercial";
export const ONLINE_QUOTING_APPETITE = "/appetite";
export const LEGACY_CONTENT_REDIRECT = "/AgentsOnly";
export const ERROR = "/error";

//Account Overview
export const ACCOUNT_HOME = "/account";
export const ACCOUNT_OVERVIEW = `${ACCOUNT_HOME}/:search_number/:active_tab?`;
export const ACCOUNT_OVERVIEW_CURRENT_POLICY_ACTIVE_TAB = "current-policy";
export const ACCOUNT_OVERVIEW_POLICY_ACTIVITY_ACTIVE_TAB = "policy-activity";
export const ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB = "billing-activity";
export const ACCOUNT_OVERVIEW_CLAIMS_ACTIVITY_ACTIVE_TAB = "claims-activity";
export const ACCOUNT_OVERVIEW_CHANGE_REQUESTS_ACTIVE_TAB = "change-requests";
export const ACCOUNT_OVERVIEW_ACTIVE_TAB_INDICIES = {
  [ACCOUNT_OVERVIEW_CURRENT_POLICY_ACTIVE_TAB]: 0,
  [ACCOUNT_OVERVIEW_POLICY_ACTIVITY_ACTIVE_TAB]: 1,
  [ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB]: 2,
  [ACCOUNT_OVERVIEW_CLAIMS_ACTIVITY_ACTIVE_TAB]: 3,
  [ACCOUNT_OVERVIEW_CHANGE_REQUESTS_ACTIVE_TAB]: 4
};
export const ACCOUNT_OVERVIEW_ACTIVE_TAB_ROUTES = {
  0: ACCOUNT_OVERVIEW_CURRENT_POLICY_ACTIVE_TAB,
  1: ACCOUNT_OVERVIEW_POLICY_ACTIVITY_ACTIVE_TAB,
  2: ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB,
  3: ACCOUNT_OVERVIEW_CLAIMS_ACTIVITY_ACTIVE_TAB,
  4: ACCOUNT_OVERVIEW_CHANGE_REQUESTS_ACTIVE_TAB
};

//Agency Profile
export const AGENCY_PROFILE = "/agency-profile";
export const AGENCY_PROFILE_ACTIVE_TAB = "/agency-profile/:activeTab";
export const AGENCY_PROFILE_MY_PROFILE = "/my-profile";
export const AGENCY_PROFILE_AGENCY_ENROLLMENT = "/agency-enrollment";

//Agency Sweep Payments
export const LEGACY_AGENCY_SWEEP = "/agency-sweep-payment";
export const AGENCY_SWEEP = "/agency-sweep";
export const VERIFY_AGENCY_SWEEP = "/verify-agency-sweep";
export const PAYMENT_CONFIRMATION_AGENCY_SWEEP = "/payment-confirmation";
export const AGENCY_SWEEP_OASIS_COMPLETE_PAYMENT = `${ACCOUNT_HOME}/:account_number${AGENCY_SWEEP}`;
export const AGENCY_SWEEP_LEGACY_COMPLETE_PAYMENT = `${ACCOUNT_HOME}/:account_number${LEGACY_AGENCY_SWEEP}`;
export const AGENCY_SWEEP_VERIFY_PAYMENT = `${ACCOUNT_HOME}/:account_number${VERIFY_AGENCY_SWEEP}`;
export const PAYMENT_CONFIRMATION = `${ACCOUNT_HOME}/:account_number${PAYMENT_CONFIRMATION_AGENCY_SWEEP}`;

export const PERSONAL = "/personal";
export const REPORTS = "/reports";
export const REPORTS_ACTIVE_TAB = `${REPORTS}/:active_tab/:active_child_tab`;
export const REPORTS_ACCOUNTING_ACTIVE_TAB = "accounting";
export const REPORTS_AGENCY_ACTIVE_TAB = "agency";
export const REPORTS_CLAIMS_ACTIVE_TAB = "claims";
export const REPORTS_UNDERWRITING_ACTIVE_TAB = "underwriting";

//Reports accounting
export const REPORTS_RECONCILE_EPAY = "reconcile-epay";
export const REPORTS_AGENCY_STATEMENTS = "agency-statements";
export const REPORTS_AGENCY_BILL_EPAY = "agency-bill-epay";
export const REPORTS_AGENCY_BILL_PAST_DUE = "agency-bill-past-due";

//Reports Agency
export const REPORTS_EXPERIENCE_DETAIL = "experience-detail";
export const REPORTS_MONTHLY_EXPERIENCE = "monthly-experience";
export const REPORTS_SERVICE_CENTER_SUMMARY = "service-center-summary";

//Reports Agency
export const REPORTS_POLICY_LOSS = "policy-loss";
export const REPORTS_AGENCY_LOSS = "agency-loss";
export const REPORTS_YEAR_TO_DATE_LOSS = "yeartodate-loss";
export const REPORTS_YEAR_TO_WEEKLY_PAYMENT = "weekly-payment";

//Reports Underwriting
export const REPORTS_IN_FORCE_LIST = "inforce-list";
export const REPORTS_APP_QUOTE_STATUS = "app-quote-status";
export const REPORTS_HIT_RATIO = "hit-ratio";
export const REPORTS_AGENCY_AUDIT_REPORT = "agency-audit-report";
export const REPORTS_PREDICTIVE_ANALYTICS_AGENCY =
  "predictive-analytics-agency";
export const REPORTS_PREDICTIVE_ANALYTICS_POLICY =
  "predictive-analytics-policy";

export const REPORTS_ACTIVE_TABS = {
  [REPORTS_ACCOUNTING_ACTIVE_TAB]: [
    REPORTS_RECONCILE_EPAY,
    REPORTS_AGENCY_STATEMENTS,
    REPORTS_AGENCY_BILL_EPAY,
    REPORTS_AGENCY_BILL_PAST_DUE
  ],
  [REPORTS_AGENCY_ACTIVE_TAB]: [
    REPORTS_EXPERIENCE_DETAIL,
    REPORTS_MONTHLY_EXPERIENCE,
    REPORTS_SERVICE_CENTER_SUMMARY
  ],
  [REPORTS_CLAIMS_ACTIVE_TAB]: [
    REPORTS_POLICY_LOSS,
    REPORTS_AGENCY_LOSS,
    REPORTS_YEAR_TO_DATE_LOSS,
    REPORTS_YEAR_TO_WEEKLY_PAYMENT
  ],
  [REPORTS_UNDERWRITING_ACTIVE_TAB]: [
    REPORTS_IN_FORCE_LIST,
    REPORTS_APP_QUOTE_STATUS,
    REPORTS_HIT_RATIO,
    REPORTS_AGENCY_AUDIT_REPORT,
    REPORTS_PREDICTIVE_ANALYTICS_AGENCY,
    REPORTS_PREDICTIVE_ANALYTICS_POLICY
  ]
};

export const RESOURCES = "/resources";
export const MARKETING = "/marketing";
export const UMAIL = "/umail";
export const HELP_DESK = "/help_desk";
export const CONTACT_US = "/contact_us";
export const MORATORIUM = "/moratorium";
export const WEBSITEUSAGE = "/website-and-logo-usage";

// Policy Changes
export const POLICY_CHANGES = "/policy-changes";
export const POLICY_CHANGES_STEP = `/policy-changes/:policyNumber/jobId/:jobId/step/:step`;

// Commercial
export const COMMERCIAL_LINES = "/commercial/commercial_lines";
export const QUOTE_PROPOSAL = "/commercial/quote_proposal";
export const AGENT_MANUALS = "/commercial/manuals";
export const INVALID_AGENT_MANUALS_STATE = `${AGENT_MANUALS}/state`;
export const AGENT_MANUALS_STATE = `${AGENT_MANUALS}/state/:stateId`;
export const AGENT_MANUALS_STATE_PRODUCT = `${AGENT_MANUALS}/state/:stateId/product/:productId`;
export const AGENT_MANUALS_STATE_PRODUCT_SUBHEADER = `${AGENT_MANUALS}/state/:stateId/product/:productId/subheader/:subheaderId`;
export const AGENT_MANUALS_STATE_PRODUCT_SUBHEADER_SUBHEADER2 = `${AGENT_MANUALS}/state/:stateId/product/:productId/subheader/:subheaderId/subheader-2/:subheader2Id`;

export const DOCUMENT_LIBRARY = "/commercial/document_library";
export const DOCUMENT_LIBRARY_COVERAGE =
  "/commercial/document_library/coverage-forms";

export const DOCUMENT_LIBRARY_PRINT_FORM_ROOT =
  "/commercial/document_library/print";
export const DOCUMENT_LIBRARY_PRINT_FORM_NUMBER = `${DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/:form_type/:form_number`;

export const COMMERCIAL_VALUE = "/commercial/_360_value";
export const RISK_CONTROL =
  "https://www.ufginsurance.com/insurance/services/risk-control";
export const NEW_BUSINESS = "/commercial/new_business";
export const NEW_BUSINESS_HEADER = `${NEW_BUSINESS}/header/:headerId`;
export const NEW_BUSINESS_SUB_HEADER = `${NEW_BUSINESS_HEADER}/subheader/:subheaderId`;
export const CLASS_APPETITE_GUIDE = "/commercial/class_appetite_guide";
export const THREE_SIXTY_VALUE = `${baseLink}/AgentsOnly/ufgAgent/Commercial/360Value.aspx`;

// Your UFG Team
export const YOUR_UFG_TEAM_ROOT = "/your_ufg_team";
export const YOUR_UFG_TEAM_DEPARTMENT_ID = `${YOUR_UFG_TEAM_ROOT}/:departmentId`;
export const VIEW_UNDERWRITING_TEAM = "/your_ufg_team/CommercialUnderwriting";
export const VIEW_UNDERWRITING_TEAM_UFG_ONLINE = "/your_ufg_team/UfgOnline";
export const MERCER_SPECIALTY_UNDERWRITING =
  "/your_ufg_team/MercerSpecialtyUnderwriting";
export const UFG_SPECIALTY_UNDERWRITING =
  "/your_ufg_team/UfgSpecialtyUnderwriting";

//Resources
export const BILLING_RESOURCES = "/resources/billing";
export const BILLING_RESOURCES_HEADER = "/resources/billing/header/:headerId";
export const BILLING_RESOURCES_SUB_HEADER = `${BILLING_RESOURCES_HEADER}/subheader/:subheaderId`;

export const CLAIMS_RESOURCES = "/resources/claims";
export const CLAIMS_RESOURCES_HEADER = `${CLAIMS_RESOURCES}/header/:headerId`;
export const CLAIMS_RESOURCES_SUBHEADER = `${CLAIMS_RESOURCES}/header/:headerId/subheader/:subheaderId`;

export const AGENCY_INTERFACE = "/resources/agency_interface";
export const AGENCY_INTERFACE_HEADER = `${AGENCY_INTERFACE}/header/:headerId`;
export const AGENCY_INTERFACE_SUB_HEADER = `${AGENCY_INTERFACE_HEADER}/subheader/:subheaderId`;

export const EPORTAL_MY_PROFILE =
  "/AgentsOnly/codes/gotoMISCE.asp?NXTISPEC=APE11&CAMFRM=I&isSpecialtyPortalView=False";
export const EPORTAL_AGENCY_PROFILE =
  "/AgentsOnly/codes/gotoMISCE.asp?NXTISPEC=APA01&CAMFRM=I&isSpecialtyPortalView=False";
export const CLAIMS_INQUIRY = "/claims_inquiry";
export const CLAIM_INFORMATION = "/claim_information";

//Surety
export const SURETY = "/surety";
export const SURETY_AGENT_MANUALS = `${SURETY}/agent-manuals`;
export const SURETY_AGENT_MANUALS_CONTENT_PAGE = `${SURETY}/agent-manuals/:contentId`;
export const SURETY_DOCS_FORMS = `${SURETY}/document-library`;
export const SURETY_ACCOUNT_OVERVIEW = `${SURETY}/account/:account_number`;
export const SURETY_BOND_OVERVIEW = `${SURETY}/bond/:bond_number`;
export const SURETY_CONSENT_OF_SURETY = `${SURETY}/consent`;

//Quick Quoting
export const QUICK_QUOTING = "/quick_quoting";
export const setStepUrl = step => {
  return `/quick_quoting/step/${step}`;
};
export const SURETY_PACKET_DOWNLOAD = "/surety/quick-program-packet";

export const UW_REVIEW = "/underwriter-review";
//online quoting:
export const ONLINE_QUOTING = "/online_quoting";
export const ONLINE_QUOTING_LOADER = "/online_quoting/loader";
export const VENDOR_QUOTING_LOADER = "/online_quoting/ivans";
export const ONLINE_QUOTING_STEP1 = "/online_quoting/step1";
export const ONLINE_QUOTING_STEP1_EXISTING = "/online_quoting/step1#search";
export const ONLINE_QUOTING_STEP2 = "/online_quoting/step2";
export const ONLINE_QUOTING_STEP3 = "/online_quoting/step3";
export const ONLINE_QUOTING_STEP4 = "/online_quoting/step4";
export const ONLINE_QUOTING_STEP5 = "/online_quoting/step5";
export const ONLINE_QUOTING_STEP6 = "/online_quoting/step6";

// Quick Quoting.
export const QUICK_QUOTING_STEP1 = "/quick_quoting/step1";
export const QUICK_QUOTING_STEP2 = "/quick_quoting/step2";
export const QUICK_QUOTING_STEP3 = "/quick_quoting/step3";
export const QUICK_QUOTING_STEP4 = "/quick_quoting/step4";
export const QUICK_QUOTING_STEP5 = "/quick_quoting/step5";
export const QUICK_QUOTING_STEP6 = "/quick_quoting/step6";

// Monolines.
export const BUILDERS_RISK = `${COMMERCIAL}/builders-risk`;

export const getActiveTabIndex = ({ params }) => {
  return (
    (params.active_tab &&
      params.active_child_tab &&
      REPORTS_ACTIVE_TABS[params.active_tab].findIndex(
        o => o === params.active_child_tab
      )) ||
    0
  );
};

export const onTabClick = (tabIndex, match, history) => {
  const { params } = match;

  // Use active tab if provided otherwise default to first report of the first
  // tab in reporting UI.
  const activeTab = params.active_tab || REPORTS_ACCOUNTING_ACTIVE_TAB;
  const childTabRoute = REPORTS_ACTIVE_TABS[activeTab][tabIndex];
  history.push(`${REPORTS}/${activeTab}/${childTabRoute}`);
};

export const VOID_PAYMENTS = "/void-payments";
