import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ContentHeader,
  ExternalLink,
  Form,
  useForm,
  Icon,
  Input,
  FormGroup,
  Button
} from "@ufginsurance/ui-kit";
import { voidConfirmationNumber } from "./VoidPaymentsServices";
import { logoUFGinsurance } from "../constants/images";
import { Translations } from "../components/translations";
import { toast } from "react-toastify";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const VoidPayments = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const initialValues = {
    confirmationID: ""
  };

  const handleSubmission = () => {
    setIsSubmitLoading(true);
    voidConfirmationNumber(values.confirmationID).then(
      res => {
        setIsSubmitLoading(false);
        if (res.data) {
          toast("Sucess! Confirmation ID has been voided", {
            position: "top-center",
            className: "success"
          });
        }
      },
      _err => {
        setIsSubmitLoading(false);
        displayToastError(_err);
      }
    );
  };

  const displayToastError = error => {
    if (error.status === 401) {
      const message =
        "We're sorry, you do not have access to the page or application requested.\n Please contact the help desk for access.";
      const options = {
        position: "top-center",
        className: "error",
        autoClose: true
      };
      toast(message, options);
    }
    const options = {
      position: "top-center",
      className: "error",
      autoClose: true
    };
    toast(<HelpDeskMessage display="trySubmittingAgain" />, options);
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  useEffect(() => {
    setSubmitDisabled(values.confirmationID.length <= 0);
  }, [values.confirmationID]);

  return (
    <div className="void-payments">
      <ContentHeader>
        <Link to="/" className="void-payments__logo">
          <img src={logoUFGinsurance} alt="UFG Insurance Logo" />
        </Link>
      </ContentHeader>
      <div className="void-payments__container">
        <p>
          <ExternalLink
            to={`${window.env.REACT_APP_UFG_ADMINISTRATOR_URL}`}
            target="_self"
            rel="noopener noreferrer"
            className="mb-15 ufg-administartor-link"
          >
            {<Icon size="lg" icon="farAngleLeft" />}
            {Translations.void_payments.ufgAdministrator_route}
          </ExternalLink>
        </p>

        <ContentHeader>Void Billing Transactions</ContentHeader>
        {Translations.void_payments.void_payments_note}
        <div className="void-payments__file-uploader">
          <Form className="void-payments__form" context={form}>
            <FormGroup>
              <Input
                placeholder="Confirmation ID"
                id="confirmationID"
                name="confirmationID"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.confirmationID}
                required
                size="md"
                isClearable
              />
            </FormGroup>
          </Form>
          <Button
            id="void-payment-submit-button"
            type="submit"
            variant="primary"
            spinnerPos="right"
            spinner={isSubmitLoading}
            disabled={submitDisabled || isSubmitLoading}
            onClick={handleSubmission}
            data-component="void-payment-submit"
          >
            Void IDs
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VoidPayments;
