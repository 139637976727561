import React, { useState, useContext } from "react";
import { Button } from "@ufginsurance/ui-kit";
import UnderwriterReviewForm from "./UnderwriterReviewForm";
import OnlineQuotingContext from "../OnlineQuotingContext";
import "./UnderwriterSendForReview.scss";

const UnderwriterSendForReview = () => {
  const [showModal, setShowModal] = useState(false);
  const { stpException, hasBlockingUwIssues } =
    useContext(OnlineQuotingContext);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <span className="oq__send-for-review">
        <Button variant="outline" onClick={toggleModal}>
          {stpException || !hasBlockingUwIssues
            ? "Consult with Underwriter"
            : "Send for Underwriting Review"}
        </Button>
      </span>

      {showModal && (
        <UnderwriterReviewForm onCancel={toggleModal} showModal={showModal} />
      )}
    </>
  );
};

export default UnderwriterSendForReview;
