import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@ufginsurance/ui-kit";
import { Alert } from "react-bootstrap";
import { Translations } from "../../../components/translations";

export const getAddressDifferences = (enteredAddress, suggestedAddress) => {
  const fieldDifferences = [];
  if (enteredAddress.address_line !== suggestedAddress.address_line) {
    fieldDifferences.push("Street");
  }

  if (enteredAddress.city !== suggestedAddress.city) {
    fieldDifferences.push("City");
  }

  if (enteredAddress.state !== suggestedAddress.state) {
    fieldDifferences.push("State");
  }

  if (enteredAddress.zip !== suggestedAddress.zip) {
    fieldDifferences.push("Zip Code");
  }

  // Comma separate all but last element
  const lastIndex =
    fieldDifferences.length === 1 ? fieldDifferences.length : -1;
  const differences = fieldDifferences.slice(0, lastIndex).join(", ");

  // If more than 1 element append ' and ' before last field difference
  return fieldDifferences.length > 1
    ? differences + " and " + fieldDifferences.slice(-1)
    : differences;
};

const handleSubmit = (address, handler) => () => {
  handler(address);
};

const renderCityStateZip = address =>
  `${address.city}, ${address.state} ${address.zip}`;

const AddressValidation = ({
  enteredAddress,
  error,
  isAdverseRiskCustomer,
  suggestedAddress,
  onBackClick,
  onDismissError,
  onClose,
  onSubmit
}) => {
  const addressDifferences = getAddressDifferences(
    enteredAddress,
    suggestedAddress
  );

  return (
    <Modal
      show
      title="Address Validation"
      onHide={onClose}
      body={
        <div className="address-validation-modal__body">
          {isAdverseRiskCustomer ? (
            <Alert bsStyle="danger">
              {Translations.start_new_quote.adverse_risk_error}
            </Alert>
          ) : error ? (
            <Alert bsStyle="danger" onDismiss={onDismissError}>
              {error}
            </Alert>
          ) : null}
          <div className="info-block">
            <p className="title">You entered the following address:</p>
            <div className="text">
              <div>{enteredAddress.address_line}</div>
              <div>{renderCityStateZip(enteredAddress)}</div>
            </div>
          </div>
          <div className="info-block">
            <p className="title">
              Our address validation service recommends the following address:
            </p>
            <div className="text">
              <div>{suggestedAddress.address_line}</div>
              <div>{renderCityStateZip(suggestedAddress)}</div>
            </div>
          </div>
          <div className="info-block">
            <p className="title">
              The following were corrected - {addressDifferences}
            </p>
          </div>
          <div>
            <p>
              To update the entered location address to the recommended address,
              click &#39;Update to Recommended Address.&#39;
            </p>
          </div>
          <div className="address-modal-footer">
            <Button variant="secondary" onClick={onBackClick}>
              Back
            </Button>
            <Button
              className="address-footer__primary-btn"
              onClick={handleSubmit(enteredAddress, onSubmit)}
            >
              Leave Address As Is
            </Button>
            <Button
              variant="primary"
              className="address-footer__primary-btn"
              onClick={handleSubmit(suggestedAddress, onSubmit)}
            >
              Update to Recommended Address
            </Button>
          </div>
        </div>
      }
    />
  );
};

const addressShape = {
  address_line: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired
};

AddressValidation.propTypes = {
  enteredAddress: PropTypes.shape(addressShape).isRequired,
  error: PropTypes.string,
  isAdverseRiskCustomer: PropTypes.bool,
  suggestedAddress: PropTypes.shape(addressShape).isRequired,
  onBackClick: PropTypes.func.isRequired,
  onDismissError: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddressValidation;
