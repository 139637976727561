import { quotePath, productKeys } from "../../shared/constants";
import { addUpdateArrayItem, _get } from "../../shared/util";
import { toTitleCase } from "../../../components/Factory";

export const addBureauIdField = ({
  fixedId,
  quoteData,
  usState,
  fieldDesc,
  className,
  panelLabel = `Required Bureau IDs`,
  terms
}) => {
  const jurisdiction = _get(
    quoteData,
    quotePath.wcm7JurisdictionCoverable,
    []
  ).find(j => Number(j.fixedId) === Number(fixedId));

  return {
    codeIdentifier: `WCM.${terms[0].name}`,
    name: panelLabel,
    description: terms[0].name,
    required: true,
    className,
    updated: false,
    publicID: `${terms[0].name}.${fixedId}`,
    selected: true,
    terms: terms.map(t => {
      if (t.jsx) return t;

      return {
        name: t.label,
        type: "workCompLine",
        options: [],
        required: t.isRequired,
        patternCode: t.name,
        updated: false,
        publicID: t.name,
        chosenTermValue: jurisdiction?.[t.name] || "",
        chosenTerm: jurisdiction?.[t.name] || "",
        valueType: "shorttext",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        readonly: t.isDisabled || false,
        maxLength: t.requiredLength,
        coveragePublicID: fixedId,
        onChange: ({ field, updateForm }) =>
          updateForm({ values: { [field.field]: field.value.toUpperCase() } }),
        validation: t.regExValidation // if term has custom validation, use it
          ? {
              validatorfunc: value => {
                if (value) return new RegExp(t.regExValidation).test(value);
                return true;
              },
              errorMessage: t.errorMessage
            }
          : {
              //  if no custom validation, then create general validation for length
              validatorfunc: value => {
                if (value)
                  return RegExp(`^\\d{${t.requiredLength}}$`).test(value);
                return true;
              },
              errorMessage: `Official ID format for ${t.label} in ${usState} is invalid. Required format is ${t.requiredLength} digits.`
            },
        requiredLength: t.requiredLength
      };
    }),

    isValid: true,
    hasTerms: true,
    coverageCategoryCode: "WorkersCompStdGrp",
    coverageCategoryDisplayName: `${productKeys.wcmWorkersComp.label} ${fieldDesc}`
  };
};

// -----------------------------------
// ---- NCCI Fields
// -----------------------------------
export const addNcciidFields = ({
  fixedId,
  quoteData,
  usState,
  usStateAbr,
  terms
}) => {
  const ncciiFields = addBureauIdField({
    fixedId,
    quoteData,
    fieldDesc: "NCCI Interstate ID",
    usState,
    usStateAbr,
    className: "oq__state-specific__ncciid",
    terms
  });

  const ncciidTerm = ncciiFields.terms.find(
    t => t.name === "NCCI Interstate ID"
  );
  const ncciidValue =
    quoteData?.lobData?.wcmWorkersComp?.coverables?.workersCompLine?.ncciid;
  ncciidTerm.readonly =
    !!quoteData?.lobData?.wcmWorkersComp?.coverables?.jurisdictions?.some(
      c => c.state === usStateAbr && !!c.ncciintrastate
    );
  ncciidTerm.chosenTermValue = ncciidValue || "";

  const ncciintrastateTerm = ncciiFields.terms.find(
    t => t.name === "NCCI Intrastate ID"
  );
  ncciintrastateTerm.readonly = !!ncciidValue;

  return ncciiFields;
};

export const saveJurisdictionField = ({
  allCoverages,
  fieldName,
  value,
  quoteData,
  updateCoverablesPromise,
  coverableType,
  path,
  overrides,
  callback
}) => {
  const [codeId, , _fieldName, fixedId] = fieldName.split(".");

  //clone array so we don't affect the og dto until after the save is complete
  let jurisdictions = Array.from(_get(quoteData, path, []));

  const termToUpdate = jurisdictions.find(j => j.fixedId === Number(fixedId));

  // console.log({ allCoverages, fieldName, _fieldName, fixedId, codeId });

  const termLabel =
    allCoverages?.find(c => c?.codeIdentifier === codeId)?.name || "";

  jurisdictions = addUpdateArrayItem(
    jurisdictions,
    { ...termToUpdate, ...overrides, [_fieldName]: value },
    "fixedId"
  );

  // if value has changed, then do the save if...
  // and of these conditions are true:
  // 1. if there's a value, update it
  // 2. if we're changing the existing value to be null (or "")
  // 3. if there was an existing value and we're changing it because the new value is different
  if (
    (!!value && value !== termToUpdate?.[_fieldName]) ||
    (!value && !!termToUpdate?.[_fieldName])
  )
    updateCoverablesPromise({
      coverableType,
      coverables: { jurisdictions },
      toastMessage: toTitleCase(`Updating ${termLabel}`)
    }).then(callback);
  else {
    callback({ success: true });
  }
};

export const saveNonOwnedAutoFields = ({
  allCoverages,
  get,
  fieldName,
  value,
  quoteData,
  updateCoverablesPromise,
  coverableType,
  path,
  callback
}) => {
  const [codeId, , field, fixedId] = fieldName.split(".");

  //clone array so we don't affect the og dto until after the save is complete
  const existingJurisdiction = Array.from(_get(quoteData, path, [])).find(
    j => j.fixedId === Number(fixedId)
  );

  const { nonOwnedAuto } = existingJurisdiction;

  if (!nonOwnedAuto) {
    console.error("No nonOwnedAuto in dto");
    callback({ success: false, error: "No nonOwnedAuto in dto" });
  }
  const _value = !isNaN(value) ? Number(value) : value;
  const updatedJurisdiction = {
    ...existingJurisdiction,
    nonOwnedAuto: { ...nonOwnedAuto, [field]: _value }
  };

  // if value has changed, then do the save if...
  // and of these conditions are true:
  // 1. if there's a value, update it
  // 2. if we're changing the existing value to be null (or "")
  // 3. if there was an existing value and we're changing it because the new value is different
  const existingValue = existingJurisdiction?.nonOwnedAuto?.[field];
  if ((!!_value && _value !== existingValue) || (!_value && !!existingValue)) {
    // if updated field is the location, then also update the location object in the payload
    if (field === "locationFixedID")
      updatedJurisdiction.nonOwnedAuto.location = get.location(
        {
          fixedID: _value
        },
        "ca7CommAuto"
      );

    const termLabel =
      allCoverages?.find(c => c?.codeIdentifier === codeId)?.name || "";

    updateCoverablesPromise({
      coverableType,
      coverables: { jurisdictions: [updatedJurisdiction] },
      toastMessage: toTitleCase(`Updating ${termLabel}`)
    }).then(callback);
  } else {
    callback({ success: true });
  }
};

export const addAutoHiredNonOwned = ({ fixedId, quoteData, get, usState }) => {
  const jurisdiction = _get(
    quoteData,
    quotePath.ca7JurisdictionCoverable,
    []
  ).find(j => Number(j.fixedId) === Number(fixedId));

  const hiredAuto = jurisdiction?.hiredAuto || "No";
  const nonOwnedAutoWanted = jurisdiction?.nonOwnedAutoWanted || "No";

  const autoPanels = [
    {
      codeIdentifier: "CA7.hiredAuto",
      name: "Hired and Non-Owned Auto Coverage",
      description: "Details",
      required: true,
      updated: false,
      publicID: `hiredAuto.${fixedId}`,
      selected: true,
      terms: [
        {
          name: "Hired Auto Coverage",
          type: "hiredAuto",
          options: [
            {
              name: "Yes",
              code: "Yes"
            },
            {
              name: "No",
              code: "No"
            }
          ],
          required: false,
          patternCode: "hiredAuto",
          updated: false,
          chosenTerm: hiredAuto,
          publicID: "hiredAuto",
          chosenTermValue: hiredAuto,
          isAscendingBetter: true,
          isCovTermEditable_UFG: true,
          coveragePublicID: `${fixedId}`
        },
        {
          name: "Non-Owned Auto Coverage",
          type: "nonOwnedAutoWanted",
          options: [
            {
              name: "Yes",
              code: "Yes"
            },
            {
              name: "No",
              code: "No"
            }
          ],
          required: false,
          patternCode: "nonOwnedAutoWanted",
          updated: false,
          chosenTerm: nonOwnedAutoWanted,
          publicID: "nonOwnedAutoWanted",
          chosenTermValue: nonOwnedAutoWanted,
          isAscendingBetter: true,
          isCovTermEditable_UFG: true,
          coveragePublicID: `${fixedId}`
        }
      ],
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "CA7JurisdictionStdGrp",
      coverageCategoryDisplayName: "Jurisdiction Standard Coverages"
    }
  ];

  if (nonOwnedAutoWanted === "Yes" && jurisdiction.nonOwnedAuto) {
    const locationsOptionsForState = get
      .locations()
      .filter(l => l.address.state === usState)
      .map(l => ({
        name: `${l.address.addressLine1}, ${l.address.city}`,
        code: l.fixedID.toString(),
        data: l
      }));

    const riskOptions = [
      // "Auto Services",
      "Other than Auto Services Partnership or LLC",
      // "Auto Services Partnership or LLC",
      // "Auto Dealers",
      "Other than Auto Services"
    ].map(o => ({ code: o, name: o }));

    const vals = Object.keys(jurisdiction.nonOwnedAuto).reduce((obj, key) => {
      const v = jurisdiction.nonOwnedAuto[key];
      return { ...obj, [key]: isNaN(v) ? v : v.toString() };
    }, {});

    const nonOwnAutoTerms = [
      {
        name: "Location",
        type: "locationFixedID",
        options: locationsOptionsForState,
        required: true,
        patternCode: "locationFixedID",
        updated: false,
        chosenTerm: vals.locationFixedID,
        publicID: "locationFixedID",
        chosenTermValue: vals.locationFixedID,
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: `${fixedId}`
      },
      {
        name: "Risk Type",
        type: "caNonOwnershipRiskType",
        options: riskOptions,
        required: true,
        patternCode: "caNonOwnershipRiskType",
        updated: false,
        chosenTerm: vals.caNonOwnershipRiskType,
        publicID: "caNonOwnershipRiskType",
        chosenTermValue: vals.caNonOwnershipRiskType,
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: `${fixedId}`
      },
      {
        name: "Number of Employees",
        type: "caNumberOfEmployees",
        options: [],
        required: true,
        patternCode: "caNumberOfEmployees",
        stripMaskFromValue: false,
        updated: false,
        publicID: "caNumberOfEmployees",
        directValue: vals.caNumberOfEmployees || "",
        valueType: "Count",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: `${fixedId}`
      },
      {
        name: "Number of Volunteers",
        type: "caNumberOfVolunteers",
        options: [],
        required: true,
        patternCode: "caNumberOfVolunteers",
        stripMaskFromValue: false,
        updated: false,
        publicID: "caNumberOfVolunteers",
        directValue: vals.caNumberOfVolunteers || "",
        valueType: "Count",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: `${fixedId}`
      }
    ];

    const showPartnerField = [
      "Auto Dealers",
      "Auto Services Partnership or LLC",
      "Other than Auto Services Partnership or LLC"
    ].includes(vals.caNonOwnershipRiskType);

    if (showPartnerField)
      nonOwnAutoTerms.push({
        name: "Number of Partners",
        type: "caNumberOfPartners",
        options: [],
        required: true,
        patternCode: "caNumberOfPartners",
        stripMaskFromValue: false,
        updated: false,
        publicID: "caNumberOfPartners",
        directValue: vals.caNumberOfPartners || "",
        valueType: "Count",
        isAscendingBetter: true,
        isCovTermEditable_UFG: true,
        coveragePublicID: `${fixedId}`
      });

    autoPanels.push({
      codeIdentifier: `CA7.nonOwnedAuto`,
      name: "Non-Owned Auto",
      description: "Details",
      required: true,
      updated: false,
      publicID: `nonOwnedAuto.${fixedId}`,
      selected: true,
      terms: nonOwnAutoTerms,
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "CA7JurisdictionStdGrp",
      coverageCategoryDisplayName: "Jurisdiction Standard Coverages"
    });
  }

  return autoPanels;
};

export const addCA7JurisdictionCoverable = ({ fixedId, quoteData }) => {
  const jurisdiction = _get(
    quoteData,
    quotePath.ca7JurisdictionCoverable,
    []
  ).find(j => Number(j.fixedId) === Number(fixedId));

  const fields = [];

  if (jurisdiction?.jurisdiction === "KY") {
    fields.push({
      codeIdentifier: "CA7.KentuckyFirefighters",
      name: "Kentucky Firefighters",
      description: "Kentucky Firefighters",
      required: false,
      updated: false,
      publicID: `ca7FFLESurchargeExempt_UFG.${fixedId}`,
      selected: true,
      terms: [
        {
          name: "Kentucky Firefighters / Law Enforcement Surcharge",
          type: "autoLine",
          options: [
            { name: "None", code: "n" },
            { name: "Federal Government", code: "F" },
            {
              name: "Resident nonprofit educational/charitable institution",
              code: "E"
            },
            { name: "Resident nonprofit religious institutions", code: "R" },
            { name: "State and local government", code: "S" }
          ],
          required: false,
          patternCode: "ca7FFLESurchargeExempt_UFG",
          updated: false,
          chosenTerm: jurisdiction?.ca7FFLESurchargeExempt_UFG || "n",
          publicID: "ca7FFLESurchargeExempt_UFG",
          chosenTermValue: jurisdiction?.ca7FFLESurchargeExempt_UFG || "n",
          isAscendingBetter: false,
          isCovTermEditable_UFG: true,
          coveragePublicID: `${fixedId}`
        }
      ],
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "CA7JurisdictionStdGrp",
      coverageCategoryDisplayName: `${productKeys.ca7CommAuto.label} Details`
    });
  }
  return fields;
};

export const addWorkCompFields = ({ fixedId, quoteData }) => {
  const jurisdiction = _get(
    quoteData,
    quotePath.wcm7JurisdictionCoverable,
    []
  ).find(j => Number(j.fixedId) === Number(fixedId));

  const fields = [];

  if (jurisdiction?.state === "NE") {
    fields.push({
      codeIdentifier: "WCM.flexibleRatingIndicator",
      name: "Apply Flexible Rating Adjustment",
      description: "Apply Flexible Rating Adjustment",
      showBefore: "Flexible Rating Adjustment",
      required: true,
      updated: false,
      publicID: `flexibleRatingIndicator.${fixedId}`,
      selected: true,
      terms: [
        {
          name: "Apply Flexible Rating Adjustment",
          type: "autoLine",
          options: [
            { name: "Yes", code: "Yes" },
            { name: "No", code: "No" }
          ],
          required: false,
          patternCode: "flexibleRatingIndicator",
          updated: false,
          chosenTerm: jurisdiction?.flexibleRatingIndicator || "n",
          publicID: "flexibleRatingIndicator",
          chosenTermValue: jurisdiction?.flexibleRatingIndicator || "n",
          // isAscendingBetter: false,
          // isCovTermEditable_UFG: true,
          coveragePublicID: `${fixedId}`
        }
      ],
      isValid: true,
      hasTerms: true,
      coverageCategoryCode: "CA7JurisdictionStdGrp",
      coverageCategoryDisplayName: `${productKeys.ca7CommAuto.label} Details`
    });
  }
  return fields;
};
