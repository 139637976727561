import { floridaSelectedLocationWindHailIsNotApplicable } from "../../step5/building/floridaDetails";

export const buildingCoverageControls = ({
  building,
  supportingData,
  quoteData
}) => {
  const coverageControl = [];

  /**
   * FLORIDA WINDHAIL
   * https://ufginsurance.atlassian.net/browse/OOQ-14835
   * if:
   * - is Florida and...
   * - location has Windhail = "Not Applicable" and...
   * - building Florida Field is in SupportingData as "No"
   * then:
   * - hide the Building coverage
   */

  const buildingsLocation =
    quoteData?.lobData?.bp7BusinessOwners?.coverables?.locations?.find(
      l => String(l.bp7LocationFixedID) === String(building.locationId)
    ) || {};

  if (
    buildingsLocation?.address?.state === "FL" &&
    floridaSelectedLocationWindHailIsNotApplicable({
      quoteData,
      location: buildingsLocation
    }) &&
    supportingData?.locations?.[buildingsLocation.fixedID]?.floridaWindHail ===
      "No"
  ) {
    coverageControl.push({
      codeIdentifier: "BP7StructureBuilding",
      name: "Building",
      control: "hidden"
    });
  }

  return { formData: { coverageControl } };
};
