import React, { useState } from "react";
import { Icon, Button, Modal, FlexRow } from "@ufginsurance/ui-kit";
import "./QuotingSteps.scss";

const QuotingHeader = ({ updateSuretyQuoteData, cacheId }) => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  const saveAndExit = () => {
    updateSuretyQuoteData(cacheId, null);
    window.close();
  };

  return (
    <div>
      <Modal
        title={"Exit Confirmation"}
        className="qq__ufg-modal"
        body={
          "Data entered will NOT be saved if you exit at this time. Are you sure you want to Exit?"
        }
        footer={
          <FlexRow align="right">
            <Button
              wrapperClassName="qq_cancel"
              isLink
              inline
              onClick={() => {
                setShowMessageModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              wrapperClassName="qq_exit"
              variant="primary"
              onClick={() => {
                saveAndExit();
                setShowMessageModal(false);
              }}
            >
              Exit
            </Button>
          </FlexRow>
        }
        show={showMessageModal}
        onHide={() => setShowMessageModal(false)}
        altCloseMethod={false}
        size="sm"
      />

      <div id="QQ__header">
        <div id="QQ_menu" className="qq__main-nav">
          <div className="headerMenu__navbar-container">
            <ul className="main-nav__nav-list">
              <li role="presentation" className="route-item">
                <button onClick={() => setShowMessageModal(true)}>
                  <span>
                    <Icon icon="farAngleLeft" /> EXIT QUOTE
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotingHeader;
