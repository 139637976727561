import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import CustomerForm from "./CustomerForm";
import { Modal, Alert, Form, useForm } from "@ufginsurance/ui-kit";

const Customer = ({
  initialFormValues,
  isFromEditCustomer,
  onClose,
  onReset,
  account,
  billingCities,
  billingStates,
  cities,
  customersByAmsId,
  customersAsOptions,
  existingCustomerSelected,
  hasPreviousSteps,
  isAdverseRiskCustomer,
  isPilotCompany,
  isPilotCustomer,
  isPilotPerson,
  loading,
  onValidZipCodeChange,
  states,
  error,
  agencyCode,
  fetchExistingCustomers = () => {},
  onSubmit,
  onDismissError
}) => {
  useEffect(() => {
    if (!isFromEditCustomer) {
      fetchExistingCustomers(agencyCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = values => {
    const convertOptionToValue = key => {
      return values[key] !== null && typeof values[key] === "object"
        ? values[key].value
        : values[key];
    };
    const form = {
      ...values,
      city: convertOptionToValue("city"),
      state: convertOptionToValue("state"),
      billingCity: convertOptionToValue("billingCity"),
      billingState: convertOptionToValue("billingState")
    };
    onSubmit(form);
  };

  const renderError = () => {
    return !error ||
      error === "Unable to retrieve location for zip code entered." ? null : (
      <Alert type="error" onDismiss={onDismissError}>
        {error}
      </Alert>
    );
  };

  const bodyClasses = cn("new-quote-modal__body", "new-quote-modal--customer", {
    "edit-customer-modal-body": isFromEditCustomer
  });

  const form = useForm({ values: initialFormValues, onSubmit: handleSubmit });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const setValues = values => {
    updateForm({
      values
    });
  };

  const setErrors = errors => {
    updateForm({
      errors
    });
  };

  const zipCodeNotFound =
    error && error === "Unable to retrieve location for zip code entered.";

  return (
    <Modal
      show
      onHide={onClose}
      size="lg"
      title={
        isFromEditCustomer
          ? "Edit Customer Information"
          : "Customer Information"
      }
      body={
        <div className={bodyClasses}>
          {renderError()}
          {initialFormValues && (
            <Form context={form}>
              <CustomerForm
                enableReinitialize
                initialValues={initialFormValues}
                cities={cities}
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
                handleOnFieldValidate={handleOnValidate}
                handleOnChange={handleOnChange}
                handleOnBlur={handleOnBlur}
                customersByAmsId={customersByAmsId}
                customersAsOptions={customersAsOptions}
                existingCustomerSelected={existingCustomerSelected}
                hasPreviousSteps={hasPreviousSteps}
                isAdverseRiskCustomer={isAdverseRiskCustomer}
                isFromEditCustomer={isFromEditCustomer}
                isPilotCustomer={isPilotCustomer}
                isPilotPerson={isPilotPerson}
                isPilotCompany={isPilotCompany}
                onValidZipCodeChange={onValidZipCodeChange}
                states={states}
                account={account}
                billingCities={billingCities}
                billingStates={billingStates}
                loading={loading}
                zipCodeNotFound={zipCodeNotFound}
                handleSubmit={handleSubmit}
                handleReset={onReset}
              />
            </Form>
          )}
        </div>
      }
    />
  );
};

Customer.propTypes = {
  account: PropTypes.object,
  billingCities: PropTypes.array.isRequired,
  billingStates: PropTypes.array.isRequired,
  agencyCode: PropTypes.string.isRequired,
  cities: PropTypes.array.isRequired,
  customersByAmsId: PropTypes.instanceOf(Map),
  customersAsOptions: PropTypes.array.isRequired,
  error: PropTypes.string,
  existingCustomerSelected: PropTypes.func.isRequired,
  fetchExistingCustomers: PropTypes.func.isRequired,
  hasPreviousSteps: PropTypes.bool,
  initialFormValues: PropTypes.object.isRequired,
  isAdverseRiskCustomer: PropTypes.bool,
  isFromEditCustomer: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDismissError: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidZipCodeChange: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  isPilotCompany: PropTypes.bool,
  isPilotCustomer: PropTypes.bool,
  isPilotPerson: PropTypes.bool,
  loading: PropTypes.bool
};

export default Customer;
