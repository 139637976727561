import qs from "query-string";
import API from "../api";

export const fetchPolicyDecs = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => {
  const params = {
    start_date: begin_date,
    end_date
  };

  if (company_branch) {
    params.company_branch = company_branch;
  }

  const queryString = qs.stringify(params);

  return API.agent().get(
    `/agencies/${agency_code}/personal/policies/declarations?${queryString}`
  );
};

export const removePolicyDec = (agency_code, policy, process_date) => {
  const { policy_number, effective_date, dec_code, status } = policy;
  const queryString = qs.stringify({
    status,
    policy_number,
    process_date,
    effective_date,
    dec_code,
    delete_type: "policy-declaration",
    origin: "ufg_core" //hardcode until we handle the oden_notice jen story
  });
  return API.agent().delete(
    `/agencies/${agency_code}/personal/decs-and-audit-bills?${queryString}`
  );
};

export const fetchClientNotices = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => {
  const params = {
    start_date: begin_date,
    end_date
  };

  if (company_branch) {
    params.company_branch = company_branch;
  }

  const queryString = qs.stringify(params);

  return API.agent().get(
    `/agencies/${agency_code}/personal/client-notices?${queryString}`
  );
};

export const removeClientNotice = (agency_code, clientNoticeItem) => {
  const queryString = qs.stringify({
    status: clientNoticeItem.status,
    policy_number: clientNoticeItem.policy_number,
    process_date: clientNoticeItem.processed_date,
    effective_date: clientNoticeItem.effective_date,
    image_right_process_key: clientNoticeItem.image_right_process_key,
    delete_type: "client-notice",
    origin: clientNoticeItem.source
  });
  return API.agent().delete(
    `/agencies/${agency_code}/personal/decs-and-audit-bills?${queryString}`
  );
};
