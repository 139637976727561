import * as types from "./actionTypes";
import * as classAppetiteService from "../services/classAppetiteService";
import { logger } from "../../loggers";

//stateList
const fetchStatesListStarted = () => ({
  type: types.FETCH_CLASS_APPETITE_STATES_STARTED
});

export const fetchStatesListSucceeded = data => ({
  type: types.FETCH_CLASS_APPETITE_STATES_SUCCEEDED,
  payload: data
});

const fetchStatesListFailed = error => ({
  type: types.FETCH_CLASS_APPETITE_STATES_FAILED,
  payload: error,
  error: true
});

export const fetchStatesList = () => {
  return dispatch => {
    dispatch(fetchStatesListStarted());
    return classAppetiteService.fetchStatesList().then(
      result => dispatch(fetchStatesListSucceeded(result.data)),
      error => {
        dispatch(fetchStatesListFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

//productList
const fetchProductsListStarted = () => ({
  type: types.FETCH_CLASS_APPETITE_PRODUCTS_STARTED
});

export const fetchProductsListSucceeded = data => ({
  type: types.FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED,
  payload: data
});

const fetchProductsListFailed = error => ({
  type: types.FETCH_CLASS_APPETITE_PRODUCTS_FAILED,
  payload: error,
  error: true
});

export const fetchProductsList = stateAbbreviation => {
  return dispatch => {
    dispatch(fetchProductsListStarted());
    return classAppetiteService.fetchProductsList(stateAbbreviation).then(
      result => dispatch(fetchProductsListSucceeded(result.data)),
      error => {
        dispatch(fetchProductsListFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

//classifications
const fetchClassesListStarted = () => ({
  type: types.FETCH_CLASS_APPETITE_CLASSES_STARTED
});

export const fetchClassesListSucceeded = data => ({
  type: types.FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED,
  payload: data
});

const fetchClassesListFailed = error => ({
  type: types.FETCH_CLASS_APPETITE_CLASSES_FAILED,
  payload: error,
  error: true
});

export const fetchClassesList = (stateAbbreviation, productCode) => {
  return dispatch => {
    dispatch(fetchClassesListStarted());
    return classAppetiteService
      .fetchClassesList(stateAbbreviation, productCode)
      .then(
        result => dispatch(fetchClassesListSucceeded(result.data)),
        error => {
          dispatch(fetchClassesListFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };
};

//selected classification

export const setSelectedClassification = classification => ({
  type: types.CLASS_APPETITE_SET_SELECTED_CLASSIFICATION,
  payload: classification
});
