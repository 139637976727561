import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";
import { buildAddressLine } from "../shared/util";
import { v4 } from "uuid";

/*--------AGENCY PRODUCERS SECTION---------- */
const fetchAgencyProducersServiceStarted = () => ({
  type: types.FETCH_AGENCY_PRODUCERS_STARTED
});

const fetchAgencyProducersServiceSucceeded = data => ({
  type: types.FETCH_AGENCY_PRODUCERS_SUCCEEDED,
  payload: data
});

const fetchAgencyProducersServiceFailed = error => ({
  type: types.FETCH_AGENCY_PRODUCERS_FAILED,
  payload: error
});
export const fetchAgencyProducers = agency => dispatch => {
  dispatch(fetchAgencyProducersServiceStarted());
  return quickQuotingServices.getProducers(agency).then(
    response => {
      dispatch(
        fetchAgencyProducersServiceSucceeded(producerOptions(response.data))
      );
    },
    error => {
      dispatch(fetchAgencyProducersServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
export const producerOptions = arr => {
  return arr
    ? arr.map(o => ({
        label: o.first_name + " " + o.last_name,
        value: o.first_name + " " + o.last_name
      }))
    : [];
};

/*--------AGENCY ACCOUNTS SECTION---------- */
const fetchAgencyAccountsServiceStarted = () => ({
  type: types.FETCH_AGENCY_ACCOUNTS_STARTED
});

const fetchAgencyAccountsServiceSucceeded = data => ({
  type: types.FETCH_AGENCY_ACCOUNTS_SUCCEEDED,
  payload: data
});

const fetchAgencyAccountsServiceFailed = error => ({
  type: types.FETCH_AGENCY_ACCOUNTS_FAILED,
  payload: error
});

export const fetchAgencyAccounts = (agencyCode, accountNumber) => dispatch => {
  dispatch(fetchAgencyAccountsServiceStarted());
  return quickQuotingServices.getAccounts(agencyCode, accountNumber).then(
    response => {
      dispatch(
        fetchAgencyAccountsServiceSucceeded(
          convertAgencyAccountData(response.data)
        )
      );
    },
    error => {
      dispatch(fetchAgencyAccountsServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

const convertAgencyAccountData = data =>
  data
    .filter(account => account.account_name)
    .map(account => {
      account.account_number = account.account_number.toString();
      account.confidence = "100";

      if (account.zip && account.zip.toString().length === 9) {
        const firstZip = account.zip.toString().substring(0, 5);
        const lastZip = account.zip.toString().replace(firstZip, "");
        account.zip = `${firstZip}-${lastZip}`;
      }

      if (!account.unit_type) {
        account.unit_type = null;
      }

      account.building_number = account.street_number;
      account.street_name = account.street_address;
      delete account.street_address;

      account.address_line = buildAddressLine({
        building_number: account.building_number,
        street_name: account.street_name,
        unit_number: account.unit_number,
        unit_type: account.unit_type
      });

      return account;
    });

/*--------OWNERS SECTION---------- */
const fetchOwnersServiceStarted = () => ({
  type: types.FETCH_OWNERS_STARTED
});

const fetchOwnersServiceSucceeded = data => ({
  type: types.FETCH_OWNERS_SUCCEEDED,
  payload: data
});

const fetchOwnersServiceFailed = error => ({
  type: types.FETCH_OWNERS_FAILED,
  payload: error
});

const clearOwnersData = () => ({
  type: types.CLEAR_OWNERS
});

const transformOwnerData = owners => {
  return owners.map(owner => {
    owner.id = v4();
    owner.address_line = buildAddressLine(owner);
    owner.is_new = false;

    if (!(owner.unit_type || owner.unit_number)) {
      owner.unit_type = null;
    }

    return owner;
  });
};

export const fetchOwners = (agencyCode, accountNumber, appType) => dispatch => {
  dispatch(fetchOwnersServiceStarted());
  return quickQuotingServices
    .getOwners(agencyCode, accountNumber, appType)
    .then(
      response => {
        dispatch(
          fetchOwnersServiceSucceeded(transformOwnerData(response.data))
        );
      },
      error => {
        dispatch(fetchOwnersServiceFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const clearOwners = () => dispatch => {
  dispatch(clearOwnersData());
};
