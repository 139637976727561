import { useRouteMatch } from "react-router-dom";

export const useBaseRouteMatch = (baseRoute) => {
  return useRouteMatch(baseRoute);
};

export const useOnlyBaseRoute = (baseRoute) => {
  const headerMatch = useRouteMatch(`${baseRoute}/header/:headerId`)?.isExact;
  const subheaderMatch = useRouteMatch(
    `${baseRoute}/header/:headerId/subheader/:subheaderId`
  )?.isExact;

  return !headerMatch && !subheaderMatch;
};

export const useHeaderRouteMatch = (baseRoute) => {
  return useRouteMatch(`${baseRoute}/header/:headerId`);
};

export const useSubheaderRouteMatch = (baseRoute) => {
  return useRouteMatch(`${baseRoute}/header/:headerId/subheader/:subheaderId`);
};

export const useCurrentRouteMatch = (baseRoute) => {
  const baseRouteMatch = useBaseRouteMatch(baseRoute);
  const headerRouteMatch = useHeaderRouteMatch(baseRoute);
  const subheaderRouteMatch = useSubheaderRouteMatch(baseRoute);

  const currentMatch = (subheaderRouteMatch?.isExact && subheaderRouteMatch) ||
    (headerRouteMatch?.isExact && headerRouteMatch) ||
    (baseRouteMatch?.isExact && baseRouteMatch);

  // this returns the match object
  return currentMatch;
};
