import React from "react";
import { Alert } from "@ufginsurance/ui-kit";
import "./Address.scss";

/**
 * If this is for a new account
 * and after the user has inputted the name for the account
 * display this allert message above ONLY the physical address fields
 */

const PhysicalAddressMessage = ({ form, type }) => {
  if (
    !form.values.account_number &&
    (form.values.account_name || form.values.last_name) &&
    type === "physical"
  )
    return (
      <Alert
        className="oq__address__physical-warning-message"
        type="warning"
        dismissible={false}
      >
        The Physical Business Address is the location where coverages will
        apply.
      </Alert>
    );

  return null;
};

export default PhysicalAddressMessage;
