import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import {
  ContentHeader,
  Panel,
  FlexRow,
  StatusPill,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";
import sanitizeHtml from "sanitize-html";
import { fetchMoratoriumDetails } from "./../../actions/moratoriumActions";
import CMSContent from "../../cms-content/CMSContent";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const sanitize = text =>
  sanitizeHtml(text, {
    allowedTags: ["b", "i", "em", "strong", "a"],
    allowedAttributes: {
      a: ["href", "target"]
    }
  });

const Moratorium = ({ fetchMoratoriumDetails, details }) => {
  const [detailsLoading, setDetailsLoading] = useState(false);

  useEffect(() => {
    setDetailsLoading(true);
    fetchMoratoriumDetails()
      .catch(() => {
        toast(<HelpDeskMessage display="anErrorOccurred" />, {
          className: "error",
          autoClose: false
        });
      })
      .finally(() => {
        setDetailsLoading(false);
      });
  }, [fetchMoratoriumDetails]);

  return (
    <div className="moratorium-static">
      <div>
        <ContentHeader>{Translations.alert.Moratorium_header}</ContentHeader>
      </div>

      {detailsLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <FlexRow>
            <p>
              When a specific geographical area is under an increase threat of
              loss due to a weather-related event, an insurance company
              temporarily suspends an agents and brokers binding authority for
              select lines of business and certain actions.
            </p>
          </FlexRow>
          <FlexRow>
            {!details?.length && (
              <p>No Temporary Suspensions for {new Date().getFullYear()}.</p>
            )}
            {details.map((item, index) => (
              <div key={v4()} className="moratorium-detail">
                <Panel
                  shadow
                  title={
                    <>
                      <FlexRow align="justify">
                        <div className="title">
                          <b>{item.title}</b>
                        </div>

                        <StatusPill
                          color={item.status === "Active" ? "orange" : "grey"}
                          text={item.status}
                        />
                      </FlexRow>
                      <div className="geoImpact">{item.geo_impact}</div>
                    </>
                  }
                >
                  <CMSContent
                    id={`cms-content-moratoriums-${index}`}
                    classNames=""
                    content={sanitize(item?.description || "")}
                  />
                  {item.status === "Inactive" && (
                    <p className="expired">Expired {item.expiration_date}</p>
                  )}
                </Panel>
              </div>
            ))}
          </FlexRow>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.moratorium
  };
};

const mapDispatchToProps = {
  fetchMoratoriumDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Moratorium);

Moratorium.propTypes = {
  fetchMoratoriumDetails: PropTypes.func.isRequired,
  details: PropTypes.array
};
