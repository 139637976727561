import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

export const getEmailWithoutDomain = state => {
  const email = sessionSelector.getEmail(state);

  if (!email) {
    return "";
  }

  const atIndex = email.indexOf("@");
  return atIndex > -1 ? email.substring(0, atIndex) : email;
};

export const getAgentName = state => {
	return sessionSelector.getFirstAndLastName(state);
};

export const getProducerCode = state => {
	return sessionSelector.getAgentProducerCode(state);
};

/**
 * Get the Producer/Agent data.
 * @param {Object} The agent portal redux store.
 * @returns Object of producer/agent details.
 */
export const getProducer = state => {
  return {
    code: getProducerCode(state),
    name: getAgentName(state),
    email: sessionSelector.getEmail(state)
  }
}
