import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ContentHeader,
  Table,
  Currency,
  Button,
  Alert,
  LoadingIndicator,
  useForm,
  Form,
  FormGroup,
  Input
} from "@ufginsurance/ui-kit";
import HelpText from "./../../components/account/billing-details/InfoIconPopover";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";
import * as completePaymentSelector from "./completePaymentSelector";
import * as completePaymentOasisSelector from "./completePaymentOasisSelector";
import moment from "moment";

const CompletePaymentOasis = ({
  hasBillingInformation,
  hasDirectBillPayNowEnrollment,
  agencySweepAccountNumber,
  isOasisAccount,
  invoiceDetails,
  invoiceAccountNumber,
  fetchOasisInvoiceDetail,
  copyAgencySweepPaymentRequestDetails,
  loading,
  error,
  match,
  history,
  billingSummary
}) => {
  const accountNumber = match.params.account_number;
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [errors, setErrors] = useState(null);

  const form = useForm({
    values: {
      totalAmount: 0
    },
    errors: []
  });
  const { handleOnChange, handleOnValidate, updateForm } = form;

  const onSubmit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    const storedAccountNumberMatchesURI = completePaymentSelector.validateAccountNumberMatch(
      accountNumber,
      agencySweepAccountNumber
    );
    if (!storedAccountNumberMatchesURI || !hasDirectBillPayNowEnrollment) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
  }, [
    accountNumber,
    agencySweepAccountNumber,
    hasDirectBillPayNowEnrollment,
    history
  ]);

  useEffect(() => {
    const storedBillingInformationIsNewAccount = !completePaymentSelector.validateAccountNumberMatch(
      accountNumber,
      invoiceAccountNumber
    );
    if (
      (!hasBillingInformation || storedBillingInformationIsNewAccount) &&
      isOasisAccount
    ) {
      fetchOasisInvoiceDetail(accountNumber);
    }
  }, [
    isOasisAccount,
    hasBillingInformation,
    accountNumber,
    invoiceAccountNumber,
    fetchOasisInvoiceDetail
  ]);

  const handleValidatePaymentAmount = (min, max) => ({
    field,
    value,
    validation
  }) => {
    if (value === 0) return [];
    value = String(value).replace("$", "");
    if (value === "") return [];
    const fieldErrors = handleOnValidate({ field, value, validation });
    const currencyNumberRegex = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;
    const isCurrencyFormat = currencyNumberRegex.test(value);
    min = Number(min) && parseFloat(Number(min).toFixed(2));
    max = Number(max) && parseFloat(Number(max).toFixed(2));
    value = Number(value) && parseFloat(Number(value).toFixed(2));

    if (!isCurrencyFormat || (value !== 0 && !value)) {
      fieldErrors.push(Translations.AgencySweep.errors.must_currency_format);
      setContinueButtonDisabled(true);
    } else if (value < min) {
      fieldErrors.push(Translations.AgencySweep.errors.less_than_min);
      setContinueButtonDisabled(true);
    } else if (value > max) {
      fieldErrors.push(Translations.AgencySweep.errors.more_than_max);
      setContinueButtonDisabled(true);
    } else {
      setContinueButtonDisabled(false);
    }
    setErrors(fieldErrors);
    return fieldErrors;
  };

  const handleOnBlur = () => {
    updateForm(form);
  };

  const handleChangeValue = ({ event, field, value }) => {
    event.target.value = value = value.replace("$", "");
    handleOnChange({ event, field, value });
    if (form.values && field) {
      form.values[field] = event.target.value;
      updateForm(form);
    }
  };

  const getExpirationDate = ({ accountPolicy }) => {
    const policySummary = billingSummary.find(
      item => item.policy_number === accountPolicy
    );
    const expDate =
      policySummary && policySummary.policy_expiration_date
        ? moment(policySummary.policy_expiration_date).format("MM/DD/YYYY")
        : "";
    return expDate;
  };

  const getColumns = () => {
    return [
      { key: "accountPolicy", label: "Account/Policy" },
      {
        key: "insured",
        label: "Insured",
        className: "agency-sweep-oasis-insured-column"
      },
      {
        key: "expDate",
        label: "Exp Date",
        element: row => getExpirationDate(row)
      },
      {
        key: "minimum",
        className: "agency-sweep-oasis-min-due-column",
        label: "Minimum",
        element: row => (
          <span>
            <Currency amount={row.minimum} />{" "}
            {row.input && (
              <HelpText content={Translations.AgencySweep.help_text} />
            )}
          </span>
        )
      },
      {
        key: "balance",
        label: "Balance",
        element: row => <Currency amount={row.balance} />
      },
      {
        key: "test",
        label: "Enter Payment Amount",
        element: row => {
          if (row.balance && row.hasOwnProperty("input")) {
            return (
              <Input
                noLabel
                id="totalAmount"
                name="totalAmount"
                label="totalAmount"
                className="payment-amount-input-oasis"
                onChange={handleChangeValue}
                onBlur={handleOnBlur}
                onValidate={handleValidatePaymentAmount(
                  row.minimum,
                  row.balance
                )}
                value={
                  form.values.totalAmount ? `$${form.values.totalAmount}` : ""
                }
              />
            );
          }
        }
      }
    ];
  };

  const handleContinue = () => {
    const paymentData = completePaymentOasisSelector.createVerifyPaymentDetails(
      form.values.totalAmount,
      invoiceDetails
    );
    copyAgencySweepPaymentRequestDetails(paymentData);
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}${routes.VERIFY_AGENCY_SWEEP}`
    );
  };

  const handleAccountOverview = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    );
  };

  return (
    <div className="account-sweep">
      <div className="row">
        <ContentHeader>{Translations.AgencySweep.title}</ContentHeader>
        <h3>{Translations.AgencySweep.heading}</h3>
        <div className="row"> {Translations.AgencySweep.description}</div>
        <div className="row">{Translations.AgencySweep.eligible} </div>
        <div className="row">&nbsp;</div>
        <ul>
          {Translations.AgencySweep.eligibility_conditions.map(val => (
            <li key={val}>{val}</li>
          ))}
        </ul>
      </div>
      <Form onSubmit={onSubmit} context={form}>
        <FormGroup wrap={false} className="form-group">
          <div className="row"> {Translations.AgencySweep.description2}</div>
          {errors && errors.length > 0 && (
            <div>
              <div className="row">&nbsp;</div>
              <Alert type="error">
                {errors.map(error => (
                  <div key={error}>{error}</div>
                ))}
              </Alert>
            </div>
          )}
          {}
          <div className="row">&nbsp;</div>
          {!error && (
            <div className="row agency-sweep-emphasis">
              {Translations.AgencySweep.enter_payments}
            </div>
          )}
          {loading ? (
            <>
              <div className="row">&nbsp;</div>
              <div className="row">
                <LoadingIndicator />
              </div>
              <div className="row">&nbsp;</div>
            </>
          ) : (
            invoiceDetails &&
            invoiceDetails.length > 0 && (
              <div className="row">
                <div>
                  <Table
                    rowKey="insured"
                    className="agency-sweep-table"
                    data={invoiceDetails}
                    columns={getColumns()}
                    itemsPerPage={20}
                  />
                </div>
              </div>
            )
          )}
          {!error && (
            <div className="row">
              <span className="agency-sweep-emphasis-payment-amount">
                {Translations.AgencySweep.total_pay_amount}{" "}
                <Currency amount={Number(form.values.totalAmount) || 0} />
              </span>
              <div className="row">
                {Translations.AgencySweep.agency_bank_account}
              </div>
            </div>
          )}
          <div className="row">&nbsp;</div>
          <Button
            onClick={handleContinue}
            variant="primary"
            disabled={
              continueButtonDisabled || !Number(form.values.totalAmount)
            }
          >
            {Translations.AgencySweep.continue}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="secondary" onClick={handleAccountOverview}>
            {Translations.AgencySweep.back_to_account_overview}
          </Button>
          <div className="row">&nbsp;</div>
          <div className="row">{Translations.AgencySweep.disclaimer}</div>
        </FormGroup>
      </Form>
    </div>
  );
};

export default CompletePaymentOasis;

CompletePaymentOasis.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  hasBillingInformation: PropTypes.bool.isRequired,
  fetchOasisInvoiceDetail: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  copyAgencySweepPaymentRequestDetails: PropTypes.func.isRequired,
  hasDirectBillPayNowEnrollment: PropTypes.bool.isRequired,
  agencySweepAccountNumber: PropTypes.string,
  isOasisAccount: PropTypes.bool.isRequired,
  invoiceDetails: PropTypes.array,
  invoiceAccountNumber: PropTypes.string,
  billingSummary: PropTypes.array
};
