import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ExternalLink } from "@ufginsurance/ui-kit";
import { isActiveLink } from "../utils/Utils";
import "./SubMenu.scss";
import { v4 } from "uuid";

const isExternalLink = link => {
  return /^https?:\/\//.test(link);
};

const SubMenu = ({ openclosed, links, handleClick, className = "" }) => {
  const displaySubMenuItem = link => {
    return isExternalLink(link.to) ? (
      <li key={v4()}>
        <ExternalLink className="nav-menu--link" to={link.to}>
          {link.text}
        </ExternalLink>
      </li>
    ) : (
      <li key={v4()}>
        <NavLink
          to={link.to}
          isActive={(match, location) => isActiveLink(link, match, location)}
          activeClassName={link.activeClassName}
          className="nav-menu--link"
          target={link.to.indexOf("/") === 0 ? "" : "_blank"}
          onClick={handleClick}
        >
          {link.text}
        </NavLink>
      </li>
    );
  };

  const displayMegaMenuItem = link => {
    return (
      <div className="mega-menu-item" key={v4()}>
        <li key={v4()}>
          <div className="nav-menu--link">{link.text}</div>
        </li>
        {link.children.map(_ => displaySubMenuItem(_))}
      </div>
    );
  };

  const hasChildren = links?.some(_ => _.children?.length > 0);

  return (
    <div
      className={`sub-menu sub-menu--${openclosed} ${className} hidden-md hidden-sm hidden-xs`}
    >
      <ul className={`${hasChildren ? "mega-menu" : ""}`} key={v4()}>
        {links.map(link =>
          link?.children?.length
            ? displayMegaMenuItem(link)
            : displaySubMenuItem(link)
        )}
      </ul>
    </div>
  );
};

SubMenu.propTypes = {
  openclosed: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      activeClassName: PropTypes.string
    }).isRequired
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default SubMenu;
