import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  Panel,
  Table,
  Anchor,
  Button,
  LoadingIndicator,
  FormatDate,
  DropdownNonForm
} from "@ufginsurance/ui-kit";
import { useSuretyContext } from "../../surety-context/SuretyContext";
import { useSuretyHomeContext } from "../SuretyHomeProvider";
import useGetAgencyActivity from "../hooks/useGetAgencyActivity";
import { getIsBondOverviewLinkValid } from "../../shared/utils";
import { fetchBondDocuments, fetchBondExecution } from "../../shared/documents";

function AgencyActivity({ history }) {
  const { updateBondOverview } = useSuretyContext();
  const { agencyCode, accounts } = useSuretyHomeContext();
  const [currentActivityType, setCurrentActivityType] = useState();
  const [activityTypeOptions, setActivityTypeOptions] = useState();
  const [bondFormsLoading, setBondFormsLoading] = useState({});
  const [activity, activityIsLoading, activityError] =
    useGetAgencyActivity(agencyCode);

  const getBondExecutionForm = (bond_number, transaction_number) => {
    setBondFormsLoading({ ...bondFormsLoading, [transaction_number]: true });
    fetchBondExecution(bond_number, agencyCode, null, transaction_number).then(
      () => {
        const tempBondFormsLoading = { ...bondFormsLoading };
        delete tempBondFormsLoading[transaction_number];
        setBondFormsLoading(tempBondFormsLoading);
      }
    );
  };

  const getOpenDocumentLinkLabel = _ => {
    switch (_) {
      case "executed_bonds":
      case "upload_history_licensepermit":
      case "upload_history_notary":
      case "upload_history_quick":
        return "View Documents";
      case "upload_history_contract":
        return "Change";
      default:
        return "";
    }
  };

  const getMetadataValue = (metadata, field) => {
    const property = (metadata ?? []).find(_ => _.field === field);
    if (property === Object(property)) return property?.value ?? "";
    return null;
  };

  const openDocument = _ => {
    let url = "";
    switch (_.activity_category) {
      case "bid_request":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgAgent/Documents/SuretyPackage.aspx?` +
          `Bond=${_.bond_number}&BondType=Bid&IncludeApplication`;
        break;
      case "executed_bonds":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgBonds/ExecutedBondList_Portal.aspx?` +
          `bn=${_.bond_number}`;
        break;
      case "upload_history_licensepermit":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgAgent/Documents/SuretyPackage.aspx?` +
          `bond=${_.bond_number}&bondType=licensepermit`;
        break;
      case "upload_history_notary":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgAgent/Documents/SuretyPackage.aspx?` +
          `bond=${_.bond_number}&bondType=notary`;
        break;
      case "upload_history_contract":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgBonds/FormUpload/uploadentry_portal.aspx?` +
          `bondno=${_.bond_number}`;
        if (getMetadataValue(_?.metadata, "is_no_bond_form"))
          url += "&noBondForm=Y";
        break;
      case "consent_of_surety":
        url =
          window.env.REACT_APP_LEGACY_SITE_URL +
          `/AgentsOnly/ufgAgent/Documents/SuretyPackage.aspx?` +
          `bond=${_.bond_number}&ConsentType=${getMetadataValue(
            _.metadata,
            "consent_type"
          )}&BondType=ConsentOfSurety&IncludeApplication&date=${getMetadataValue(
            _.metadata,
            "consent_date"
          )}`;
        break;

      default:
        url = "";
    }
    if (!url) console.err("Insufficient metadata to construct URL.");
    else window.open(url, "_blank");
  };

  const getActivitiesColumnText = _ => {
    if (!_.has_document && _.activity_category !== "consent_of_surety") return;
    if (_.activity_category === "bid_request") {
      const requestStatus = getMetadataValue(_.metadata, "request_status");
      return requestStatus === "Pend" ? (
        "Pending"
      ) : requestStatus === "Approved" ? (
        <Button
          isLink
          inline
          size="sm"
          className="nowrap"
          onClick={() => openDocument(_)}
        >
          {requestStatus}
        </Button>
      ) : (
        requestStatus
      );
    }
    if (_.activity_category === "consent_of_surety") {
      const consentStatus = getConsentStatusText(
        getMetadataValue(_.metadata, "consent_status")
      );
      return consentStatus !== "View Documents" ? (
        consentStatus
      ) : (
        <Button
          isLink
          inline
          size="sm"
          className="nowrap"
          onClick={() => openDocument(_)}
        >
          {consentStatus}
        </Button>
      );
    }
    if (_.activity_category === "upload_history_quick") {
      return (
        <Button
          isLink
          inline
          size="sm"
          className="nowrap"
          onClick={() => fetchBondDocuments("original", _.bond_number)}
        >
          View Documents
        </Button>
      );
    }
    if (_.activity_category === "billing") {
      return (
        <Button
          isLink
          inline
          className="nowrap"
          spinner={bondFormsLoading.hasOwnProperty(
            getMetadataValue(_?.metadata, "transaction_number")
          )}
          size="sm"
          onClick={() =>
            getBondExecutionForm(
              _.bond_number,
              getMetadataValue(_?.metadata, "transaction_number")
            )
          }
        >
          Transaction Report
        </Button>
      );
    }

    return (
      <Button
        isLink
        inline
        size="sm"
        className="nowrap"
        onClick={() => openDocument(_)}
      >
        {getOpenDocumentLinkLabel(_.activity_category)}
      </Button>
    );
  };

  const getConsentStatusText = consentStatus => {
    if (consentStatus === "P") return "Pended";
    if (consentStatus === "D") return "Declined";
    if (consentStatus === "A") return "View Documents";
    if (consentStatus !== "P" && consentStatus !== "D" && consentStatus !== "A")
      return "Requested";
  };

  const columns = [
    {
      key: "date",
      label: "Date",
      sortable: true,
      dataType: "date",
      element: _ => {
        return <FormatDate date={_.date} format="M/D/YYYY" />;
      }
    },
    {
      key: "account_name",
      label: "Account",
      sortable: true,
      element: ({ account_number, account_name }) => {
        const accountNumber =
          accounts && account_name
            ? accounts.find(
                acc =>
                  acc.account_name.toLowerCase().trim() ===
                  account_name.toLowerCase().trim()
              )?.account_number
            : null;
        const handleClick = () => {
          if (account_number) {
            history.push(`/surety/account/${account_number}`);
          }
          if (!account_number && accountNumber) {
            history.push(`/surety/account/${accountNumber}`);
          }
        };
        return (
          <Anchor
            className={`wrap-text${
              !account_number && !accountNumber ? " link-disabled" : ""
            }`}
            onClick={handleClick}
          >
            {account_name}
          </Anchor>
        );
      }
    },
    {
      key: "bond_number",
      label: "Bond No",
      sortable: true,
      align: "center",
      element: ({ bond_number, activity_type }) => (
        <Anchor
          className={`wrap-text${
            getIsBondOverviewLinkValid(activity_type) ? "" : " link-disabled"
          }`}
          onClick={() => updateBondOverview({ bond_number, showModal: true })}
        >
          {bond_number}
        </Anchor>
      )
    },
    { key: "activity_type", label: "Activity Type", sortable: true },
    {
      key: "",
      label: "",
      sortable: false,
      align: "right",
      element: _ => {
        return getActivitiesColumnText(_);
      }
    }
  ];

  // Create the options for the Dropdown filter.
  useEffect(() => {
    const activityTypes = activity
      .filter(
        (item, index, self) =>
          index === self.findIndex(i => i.activity_type === item.activity_type)
      )
      .map(act => ({ label: act.activity_type, value: act.activity_type }));

    setActivityTypeOptions(activityTypes);
  }, [activity]);

  return (
    <Panel
      titlebar
      rounded
      bgcolor="grey"
      id="surety-agency-activity"
      title={
        <>
          <strong>Recent Activity</strong> <span>(for the last 30 days)</span>
        </>
      }
    >
      {activityIsLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DropdownNonForm
            id="activityTypeFilter"
            name="activityTypeFilter"
            className="mb-1"
            options={activityTypeOptions || []}
            onChange={_ => setCurrentActivityType(_?.value || "")}
            value={currentActivityType}
            size="50p"
            label="Activity Type"
          />
          <Table
            rowKey="id"
            columns={columns}
            data={
              activityError
                ? []
                : activity.filter(act => {
                    if (currentActivityType) {
                      return act.activity_type === currentActivityType;
                    }
                    return act;
                  })
            }
            showPagination
            itemsPerPage={10}
            initialSort="date"
            noResultsMessage="No activity found"
          />
        </>
      )}
    </Panel>
  );
}

export default withRouter(AgencyActivity);
