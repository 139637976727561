const AddressToSingleLine = address => {
  return (
    address &&
    `${address.address1} ${HandleUndefinedString(
      address.address2
    )} ${HandleUndefinedString(address.city)}, ${HandleUndefinedString(
      address.state
    )} ${HandleUndefinedString(address.zip)}`
  );
};

//
const HandleUndefinedString = input => {
  return input ? input : "";
};
export default AddressToSingleLine;
