export function getCommercialDateIsLoading(store) {
  return store.serviceCenterSummary.commercialDateIsLoading;
}

export function getCommercialDateList(store) {
  return store.serviceCenterSummary.commercialDateList;
}

export function getCommercialDateError(store) {
  return store.serviceCenterSummary.commercialDateError;
}

export function getPersonalDateList(store) {
  return store.serviceCenterSummary.personalDateList;
}
