import * as types from "../suretyTabActionTypes";
import initialState from "../../../../reducers/initialState";

const agencyAccountsListReducer = (
  state = initialState.agencyAccountsList,
  action
) => {
  switch (action.type) {
    case types.FETCH_AGENCY_ACCOUNTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_AGENCY_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_AGENCY_ACCOUNTS_FAILED:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default agencyAccountsListReducer;
