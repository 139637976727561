import React from "react";
const FormatSsn = ({ data }) => {
  const addingSsnDashes =
    data.slice(0, 3) + "-" + data.slice(3, 5) + "-" + data.slice(5, 9);

  const ssnDisplay = !!data ? addingSsnDashes : " ";

  return (
    <>
      <div>{ssnDisplay}</div>
    </>
  );
};

export default FormatSsn;
