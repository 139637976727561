import * as types from "../constants/actionTypes";
import initialState from "../reducers/initialState";

const changePortalViewStateReducer = (
  state = initialState.portalViewState,
  action
) => {
  switch (action.type) {
    case types.CHANGE_TO_UFG_AGENT_PORTAL_VIEW_STATE_SUCCEEDED:
      return {
        ...state,
        isSpecialtyPortalView: false,
        isUfgSpecialtyBrokerPortalView: false
      };
    case types.CHANGE_TO_UFG_SPECIALTY_PORTAL_VIEW_STATE_SUCCEEDED:
      return {
        ...state,
        isSpecialtyPortalView: true,
        isUfgSpecialtyBrokerPortalView: true
      };
    case types.CHANGE_TO_MERCER_PORTAL_VIEW_STATE_SUCCEEDED:
      return {
        ...state,
        isSpecialtyPortalView: true,
        isUfgSpecialtyBrokerPortalView: false
      };
    default:
      return state;
  }
};

export default changePortalViewStateReducer;
