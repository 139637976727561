import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as routes from "../constants/routes";
import moment from "moment";
import { useFlags } from "launchdarkly-react-client-sdk";

const isEnabledForEmployee = employeeUsername => {
  const enabledEmployeeUsernames = (window.env.REACT_APP_FORCED_PASSWORD_CHANGE_NOTIFICATION_ENABLE_EMPLOYEE_WHITELIST || "").split(' ') || [];
  const match = enabledEmployeeUsernames?.find(
    username => employeeUsername && username.toLowerCase() === employeeUsername.toLowerCase()
  ) || false;
  return !!match;
};

const LOCAL_STORAGE_DAYS_UNTIL_FORCED_PASSWORD_CHANGE_KEY = "numberOfDaysUntilForcedPasswordChange";

export const usePasswordResetNotification = (
  lastPasswordChangeDate,
  isHomeAgent,
  isEmployeeAuthentication,
  employeeUsername
) => {
  const { agencyProfileMicrofrontendEnabled } = useFlags();

  const [displayedNotification, setDisplayedNotification] = useState(false);
  const [displayedDebug, setDisplayedDebug] = useState(false);
  const enableDebug = window.env.REACT_APP_FORCED_PASSWORD_CHANGE_ENABLE_DEBUG === "true";
  const forcedPasswordChangeDaysLimit = parseFloat(window.env.REACT_APP_FORCED_PASSWORD_CHANGE_DAYS_LIMIT || "90");
  const notifyDaysBefore = parseFloat(window.env.REACT_APP_FORCED_PASSWORD_CHANGE_NOTIFY_DAYS_BEFORE || "10");
  const legacyUfgAgentBaseUrl = window.env.REACT_APP_LEGACY_SITE_URL;
  const myProfileUrl = agencyProfileMicrofrontendEnabled ? routes.AGENCY_PROFILE_MY_PROFILE : `${legacyUfgAgentBaseUrl}${routes.EPORTAL_MY_PROFILE}`;
  const isHomeAgentOrEmployeeNotificationEnabled = isEnabledForEmployee(employeeUsername);
  const lastPasswordChangeDateObject = lastPasswordChangeDate ? moment(lastPasswordChangeDate) : moment();
  const forcedPasswordChangeDayDate = moment().subtract(forcedPasswordChangeDaysLimit, "days");
  const lastPasswordChangeDateAlreadyExpired = lastPasswordChangeDate && lastPasswordChangeDateObject.valueOf() <= forcedPasswordChangeDayDate.valueOf();
  const numberOfDaysUntilForcedPasswordChange = lastPasswordChangeDate && lastPasswordChangeDateObject.diff(forcedPasswordChangeDayDate, "days");
  const notificationBeenDisplayedForUser = window.sessionStorage.getItem(LOCAL_STORAGE_DAYS_UNTIL_FORCED_PASSWORD_CHANGE_KEY) === `${numberOfDaysUntilForcedPasswordChange}`;

  if (enableDebug && !displayedDebug) {
    setDisplayedDebug(true);
    console.log("START REACT_APP_FORCED_PASSWORD_CHANGE_ENABLE_DEBUG");
    console.log("Last password change date:", lastPasswordChangeDateObject.format("MM-DD-YYYY hh:mm:ss A"));
    console.log(`Forced password change after Last password change date: (${forcedPasswordChangeDaysLimit} days before now):`, forcedPasswordChangeDayDate.format("MM-DD-YYYY hh:mm:ss A"));
    console.log("User already past password expiration:", lastPasswordChangeDateAlreadyExpired);
    console.log(`Days until forced password change (difference between Last password change date and Forced password change date in days):`, numberOfDaysUntilForcedPasswordChange);
    console.log("Has already been displayed for user:", notificationBeenDisplayedForUser);
    console.log("END REACT_APP_FORCED_PASSWORD_CHANGE_ENABLE_DEBUG");
  }

  useEffect(() => {
    if (!displayedNotification &&
      (
        isHomeAgentOrEmployeeNotificationEnabled || (!isHomeAgent && !isEmployeeAuthentication)
      )) {
      if (!lastPasswordChangeDateAlreadyExpired &&
        (numberOfDaysUntilForcedPasswordChange <= notifyDaysBefore && numberOfDaysUntilForcedPasswordChange >= 0) &&
        !notificationBeenDisplayedForUser
      ) {
        window.sessionStorage.setItem(LOCAL_STORAGE_DAYS_UNTIL_FORCED_PASSWORD_CHANGE_KEY, numberOfDaysUntilForcedPasswordChange);
        setDisplayedNotification(true);
        toast(
          <div>
            <div>Your password will expire
              {numberOfDaysUntilForcedPasswordChange === 0 ?
                ` tomorrow` :
                ` in ${numberOfDaysUntilForcedPasswordChange} day${numberOfDaysUntilForcedPasswordChange === 1 ? '' : 's'}`}{". Please visit "}
              <Link
                to="#"
                className="white-link underline"
                onClick={() => window.open(myProfileUrl, "_self")}
              >
                My Profile
              </Link> to update your password as soon as possible.
            </div>
          </div>,
          {
            position: "top-center",
            className: "warning",
            autoClose: false
          }
        );
      }
    }
  }, [
    displayedNotification,
    lastPasswordChangeDate,
    isHomeAgent,
    isEmployeeAuthentication,
    forcedPasswordChangeDaysLimit,
    myProfileUrl,
    isHomeAgentOrEmployeeNotificationEnabled,
    numberOfDaysUntilForcedPasswordChange,
    lastPasswordChangeDateObject,
    forcedPasswordChangeDayDate,
    lastPasswordChangeDateAlreadyExpired,
    enableDebug,
    notifyDaysBefore,
    notificationBeenDisplayedForUser
  ]);

  return null;
};

usePasswordResetNotification.propTypes = {
  lastPasswordChangeDate: PropTypes.string,
  isHomeAgent: PropTypes.bool,
  isEmployeeAuthentication: PropTypes.bool,
  employeeUsername: PropTypes.string
};
