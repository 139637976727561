import { Panel, Table, formatDate } from "@ufginsurance/ui-kit";
import React from "react";
import { toTitleCase } from "../../../components/Factory";
import PropTypes from "prop-types";

export default function AccountOverviewPendingActivityPanel({
  pendingActivityList
}) {
  const columns = [
    {
      key: "bid_date",
      label: "Effective Date",
      sortable: true,
      element: _ => <>{formatDate(_.bid_date, "MM/DD/YYYY")}</>,
      dataType: "date"
    },
    {
      key: "reference_id",
      label: "Bond No",
      sortable: true,
      element: _ => <> {_.reference_id}</>
    },
    {
      key: "bid_type",
      label: "Activity Type",
      sortable: true,
      element: _ => <>{_.bid_type}</>
    },
    {
      key: "request_status",
      label: "Status",
      sortable: true,
      nowrap: true,
      element: _ => (
        <>
          {toTitleCase(
            _.request_status === "Pend" ? "Pending" : _.request_status
          )}
        </>
      )
    }
  ];
  return (
    <Panel
      className="account-overview__pending-activity-list"
      bgcolor="grey"
      titlebar
      title="Pending Activity"
    >
      <>
        <Table
          rowKey="id"
          columns={columns}
          data={pendingActivityList}
          showPagination
          itemsPerPage={10}
        />
      </>
    </Panel>
  );
}
AccountOverviewPendingActivityPanel.propTypes = {
  pendingActivityList: PropTypes.array
};
