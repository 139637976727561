import * as types from "./actionTypes";
import * as service from "./service";
import { logger } from "../../../src/loggers";

const fetchSuretyAgentManualsNavigationStarted = () => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_STARTED
});

const fetchSuretyAgentManualsNavigationSucceeded = data => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_SUCCEEDED,
  payload: data
});

const fetchSuretyAgentManualsNavigationFailed = error => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_NAVIGATION_FAILED,
  payload: error
});

export const getNavigation = (parentId, dispatch) => {
  dispatch(fetchSuretyAgentManualsNavigationStarted());
  return service.getNavigation(parentId).then(
    response => {
      if (response.status !== 200) {
        dispatch(fetchSuretyAgentManualsNavigationFailed(response));
      } else {
        dispatch(fetchSuretyAgentManualsNavigationSucceeded(response.data));
      }
    },
    error => {
      dispatch(fetchSuretyAgentManualsNavigationFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

const fetchSuretyAgentManualsContentStarted = () => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_CONTENT_STARTED
});

const fetchSuretyAgentManualsContentSucceeded = data => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_CONTENT_SUCCEEDED,
  payload: data
});

const fetchSuretyAgentManualsContentFailed = error => ({
  type: types.FETCH_SURETY_AGENT_MANUALS_CONTENT_FAILED,
  payload: error
});

export const getContent = (contentId, dispatch) => {
  dispatch(fetchSuretyAgentManualsContentStarted());
  return service.getContent(contentId).then(
    response => {
      if (response.status !== 200) {
        dispatch(fetchSuretyAgentManualsContentFailed(response));
      } else {
        dispatch(fetchSuretyAgentManualsContentSucceeded(response.data));
      }
    },
    error => {
      dispatch(fetchSuretyAgentManualsContentFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
