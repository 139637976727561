import React from "react";
import PropTypes from "prop-types";

const IndividualContactCard = ({ contact }) => {
  const {
    key = "",
    photo_id = "",
    first_name = "",
    last_name = "",
    title = "",
    phone_number = "",
    cell_phone_number = "",
    toll_free_number = "",
    fax_number = "",
    email = "",
    employee_description = ""
  } = contact;
  const name = `${first_name} ${last_name}`;
  const imgUrl = `${window.env.REACT_APP_LEGACY_SITE_URL}/agentsonly/mugs/${photo_id}.jpg`;
  return (
    <div className="col-md-6 row mt-30 contact-card" key={key}>
      <img className="col-md-3" src={imgUrl} alt={name} />
      <div className="col-md-9">
        <div className="font-weight-bold">{name}</div>
        <div className="mb-15 italic">
          {employee_description ? <>{employee_description}&nbsp;</> : ""}
          {title}
        </div>
        {phone_number && (
          <div>
            <span className="font-weight-bold">Phone: </span>
            {phone_number}
          </div>
        )}
        {cell_phone_number && (
          <div>
            <span className="font-weight-bold">Cell phone: </span>
            {cell_phone_number}
          </div>
        )}
        {toll_free_number && (
          <div>
            <span className="font-weight-bold">Toll free: </span>
            {toll_free_number}
          </div>
        )}
        {fax_number && (
          <div>
            <span className="font-weight-bold">Fax: </span>
            {fax_number}
          </div>
        )}
        {email && (
          <div>
            <span className="font-weight-bold">Email: </span>
            <a className="email" href={"mailto:" + email}>
              {email}
            </a>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

IndividualContactCard.propTypes = {
  contact: PropTypes.object
};

export default IndividualContactCard;
