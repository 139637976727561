import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  sessionSelector,
  ssoActions
} from "@ufginsurance/sso-oidc-client-react";
import { LoadingIndicator, Tabs, Tab } from "@ufginsurance/ui-kit";
import { CLARION_DOOR_POLICY_PREFIX } from "../../constants/dictionary";
import { Translations } from "../translations";
import {
  getisSpecialtyPortalView,
  getIsMercerSpecialtyBrokerPortalView
} from "../../portalview/changePortalViewStateSelectors";
import {
  changeToMercerSpecialtyPortalViewState,
  changeToUfgSpecialtyPortalViewState,
  changeToUfgAgentPortalViewState
} from "../../portalview/changePortalViewStateAction";
import {
  fetchAccountData,
  leftAccountOverview
} from "../../actions/accountActions";
import { searchAllLinesForPolicies } from "../../actions/policies";
import AccountContainer from "./home/AccountContainer";
import AccountServicesContainer from "./account-services/AccountServicesContainer";
import CurrentPolicyContainer from "../../current-policy/currentPolicyContainer";
import PolicyActivityContainer from "../../policy-activity/policyActivityContainer";
import BillingContainer from "./billing-details/BillingContainer";
import ClaimsContainer from "../../claims/containers/ClaimsContainer";
import AccountChangeRequestContainer from "../../account-change-requests/accountChangeRequestContainer";
import * as routes from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

/**
 * react-router will specify a unique key for the location object. This can be
 * used to determine that we should perform a 'refresh' even if the url
 * is the same pathname.
 */
export const shouldRefreshFromUrl = (currentLocation, previousLocation) =>
  currentLocation.pathname === previousLocation.pathname &&
  currentLocation.key !== previousLocation.key;

const Account = ({
  accountData,
  isSpecialtyPortalView,
  isMercerSpecialtyPortalView,
  match,
  history,
  location,
  agencies,
  changeAgencyView,
  is109015Agent,
  changeToUfgAgent,
  activeAgencyCode,
  changeToUfgSpecialty,
  changeToMercer,
  searchAllLinesForPolicies,
  fetchAccountData,
  onAccountUnmount
}) => {
  const [selectedPolicyDetail, setSelectedPolicyDetail] = useState();
  const [agencyCode, setAgencyCode] = useState();
  const [showSwitchView, setShowSwitchView] = useState(false);
  const [showAccountError, setShowAccountError] = useState(false);

  const previousSearchNumberRef = useRef();
  const prevLocationRef = useRef();

  const searchNumber = match.params.search_number;
  const accountDetail = accountData.accounts;
  const policySummary = (accountDetail && accountDetail.policy_summary) || [];

  const findDefaultPolicy = (policies, searchNumber) => {
    if (!policies) return;
    const matchingPolicy = policies.find(
      policy => policy.policy_number === searchNumber
    );
    return matchingPolicy ? matchingPolicy : policies[0];
  };

  const selectedFoundInAccountDetail = selectedPolicyDetail
    ? policySummary?.find(
        policy => policy.policy_number === selectedPolicyDetail.policy_number
      ) ?? false
    : false;

  const selectedOrFirstPolicy = selectedFoundInAccountDetail
    ? selectedPolicyDetail
    : findDefaultPolicy(policySummary, searchNumber);

  const isAgencyGroupError = accountData =>
    accountData.error &&
    accountData.error.data &&
    (accountData.error.data.message === "Agency in group" ||
      accountData.error.data.message ===
        "Agent does not match requesting agent");

  const handleSpecialtySwitch = () => {
    return isMercerSpecialtyPortalView
      ? changeToUfgSpecialty()
      : changeToMercer();
  };

  const onSelectPolicy = selectedPolicyDetail => {
    setSelectedPolicyDetail(selectedPolicyDetail);
  };

  const searchPolicies = useCallback(() => {
    const searchKeyword = match.params.search_number;
    if (!searchKeyword) {
      return;
    }
    searchAllLinesForPolicies(activeAgencyCode, searchKeyword).then(
      policies => {
        if (policies !== undefined) {
          const BOND = "B";
          const POLICY = policies[0];
          if (POLICY.policy_type !== BOND) {
            setAgencyCode(POLICY.agency_code);
            setShowSwitchView(POLICY.agency_code === activeAgencyCode);
          } else {
            const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
            window.open(
              `${baseLink}/AgentsOnly/codes/gotoBondCE.asp?page=WBINQ&NO_BOND=${POLICY.policy_number}&txtAgentCode=${POLICY.agency_code}`
            );
          }
        }
      }
    );
  }, [activeAgencyCode, match.params.search_number, searchAllLinesForPolicies]);

  const getPolicyInfoForAccountService = selectedPolicyDetail => {
    return selectedPolicyDetail === undefined
      ? {}
      : {
          policyNumber: selectedPolicyDetail?.policy_number,
          agencyNumber: selectedPolicyDetail?.agent_number,
          effective_date: selectedPolicyDetail?.effective_date,
          expiration_date: selectedPolicyDetail?.expiration_date,
          company_branch: selectedPolicyDetail?.company_branch,
          rate_state: selectedPolicyDetail?.rate_state
        };
  };

  const getPolicyInfoForChange = (accountDetail, selectedPolicyDetail) => {
    return {
      policyNumber: selectedPolicyDetail?.policy_number,
      agencyNumber: selectedPolicyDetail?.agent_number,
      primary_account_name: accountDetail?.primary_account_name,
      effective_date: selectedPolicyDetail?.effective_date,
      expiration_date: selectedPolicyDetail?.expiration_date,
      address: accountDetail.address?.address1,
      city: accountDetail.address?.city,
      state: accountDetail.address?.state,
      zip: accountDetail.address?.zip,
      rate_state: selectedPolicyDetail?.rate_state,
      company_branch: selectedPolicyDetail?.company_branch,
      isCancelled: selectedPolicyDetail?.is_cancelled,
      policyType: selectedPolicyDetail?.policy_type,
      insurance_type: accountDetail?.insurance_type,
      origin: accountDetail?.origin,
      status: selectedPolicyDetail?.status
    };
  };

  const getActiveTabIndex = () => {
    return (
      (match.params.active_tab &&
        routes.ACCOUNT_OVERVIEW_ACTIVE_TAB_INDICIES[match.params.active_tab]) ||
      0
    );
  };

  const onTabClick = tabIndex => {
    const accountOverviewBasePath = `${routes.ACCOUNT_HOME}/${match.params.search_number}`;
    const tabRoute = routes.ACCOUNT_OVERVIEW_ACTIVE_TAB_ROUTES[tabIndex] || "";
    history.push(`${accountOverviewBasePath}/${tabRoute}`);
  };

  const specialtySwitchText = () => {
    return isMercerSpecialtyPortalView
      ? Translations.header.ufgSpecialty_switch_broker
      : Translations.header.mercer_switch;
  };

  const handleChangeAgencyView = agencyCode => {
    // If the agent has access to the agency code associated with the account
    // or policy then we switch to that agency.
    // If the agent doesn't have access to the associated agency then we set
    // a flag to show generic no data error. Even though we can identify that
    // scenario ahead of time and the agent clicking 'switch view' will likely
    // be confused it has been decided to do it this way since the R1 requirements
    // were written this way.
    if (agencies.some(x => x.agencyCode === agencyCode)) {
      changeAgencyView(agencyCode);
    } else {
      setShowAccountError(true);
    }
  };

  const renderSwitchAgencyError = (searchNumber, agencyCode) => {
    return (
      <div className="no-results-info">
        {Translations.formatString(
          Translations.Name_Search.agentAccountError,
          searchNumber,
          agencyCode
        )}
        <div className="switch-view">
          <HelpDeskMessage
            display="questionsOrAssistance"
            before={Translations.formatString(
              "In order to access this policy, you can {0} or select the correct agent code from the menu at the top of this screen. If you do not have access to this agent code but feel you should, please contact your agency administrator.",
              <button
                type="button"
                className="btn-as-link"
                onClick={() => handleChangeAgencyView(agencyCode)}
              >
                {Translations.Name_Search.switchViewButtonText}
              </button>
            )}
          />
        </div>
      </div>
    );
  };

  const searchNumberChanged = (currentSearchNumber, previousSearchNumber) =>
    currentSearchNumber && currentSearchNumber !== previousSearchNumber;

  // Initialize Account Overview in redux on unmount
  useEffect(() => {
    return () => {
      onAccountUnmount();
    };
  }, [onAccountUnmount]);

  // Fetch account data if search number from route match
  // If there is no agency_code, then search all policies which will set
  // agency_code as a side effect (searchPolicies)
  useEffect(() => {
    const searchNumber = match.params.search_number;
    if (searchNumber) {
      fetchAccountData(activeAgencyCode, searchNumber, isSpecialtyPortalView);
    }
    if (location.state && location.state.agency_code) {
      setAgencyCode(location?.state?.agency_code);
    } else {
      searchPolicies();
    }
  }, [
    activeAgencyCode,
    fetchAccountData,
    isSpecialtyPortalView,
    location.state,
    match.params.search_number,
    searchPolicies
  ]);

  // set previous props
  useEffect(() => {
    previousSearchNumberRef.current = match.params.search_number;
    prevLocationRef.current = location;
  }, [location, match.params.search_number]);

  useEffect(() => {
    const currentSearchNumber = match.params.search_number;
    const previousSearchNumber = previousSearchNumberRef.current;
    const prevLocation = prevLocationRef.current;

    // Clearing policy selection here to avoid depending on if statement
    // order and future refactorings potentially breaking this again.
    // https://ufginsurance.atlassian.net/browse/OPM-5461
    if (searchNumberChanged(currentSearchNumber, previousSearchNumber)) {
      setSelectedPolicyDetail(null);
      fetchAccountData(
        activeAgencyCode,
        currentSearchNumber,
        isSpecialtyPortalView
      );
    } else if (shouldRefreshFromUrl(location, prevLocation)) {
      setSelectedPolicyDetail(null);
    }
  }, [
    activeAgencyCode,
    fetchAccountData,
    isSpecialtyPortalView,
    location,
    match.params.search_number,
    setSelectedPolicyDetail
  ]);

  const showSwitchAgencyError =
    !showAccountError &&
    (showSwitchView || agencyCode) &&
    isAgencyGroupError(accountData);

  const showNoAccountResultsError =
    !selectedOrFirstPolicy || accountData.error || showAccountError;

  const shouldAllowSpecialtySwitch =
    (isMercerSpecialtyPortalView &&
      selectedOrFirstPolicy?.company_branch ===
        window.env.REACT_APP_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH) ||
    (!isMercerSpecialtyPortalView &&
      isSpecialtyPortalView &&
      selectedOrFirstPolicy?.company_branch ===
        window.env.REACT_APP_MERCER_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH) ||
    (is109015Agent &&
      !isSpecialtyPortalView &&
      (selectedOrFirstPolicy?.company_branch ===
        window.env.REACT_APP_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH ||
        selectedOrFirstPolicy?.company_branch ===
          window.env.REACT_APP_MERCER_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH));

  const shouldAllowUfgAgentSwitch =
    isSpecialtyPortalView &&
    selectedOrFirstPolicy?.company_branch !==
      window.env.REACT_APP_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH &&
    selectedOrFirstPolicy?.company_branch !==
      window.env.REACT_APP_MERCER_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH;

  return !accountDetail || !policySummary || accountData.isLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      {showSwitchAgencyError ? (
        <>
          {/* render switch agency error */}
          {renderSwitchAgencyError(searchNumber, agencyCode)}
        </>
      ) : showNoAccountResultsError ? (
        <div className="no-results-info">
          {/* show account error if no results */}
          {(isMercerSpecialtyPortalView || isSpecialtyPortalView) &&
          searchNumber.startsWith(CLARION_DOOR_POLICY_PREFIX) ? (
            <HelpDeskMessage
              display="questionsOrAssistance"
              before={
                <>
                  The policy number you’re searching for is not currently
                  available in ufgSpecialty. Please review your entry or contact
                  your underwriter for more information.
                  <br />
                </>
              }
            />
          ) : (
            <HelpDeskMessage
              display="questionsOrAssistance"
              before="Unfortunately, the account number you entered could not be found. Please ensure the number was entered correctly."
            />
          )}
        </div>
      ) : shouldAllowSpecialtySwitch ? (
        <div className="switch-view">
          {/* if needs to switch to some specialty */}
          {Translations.formatString(
            Translations.Name_Search.switchViewSpecialty,
            selectedOrFirstPolicy?.policy_number,
            <button
              type="button"
              className="btn-as-link"
              onClick={() => handleSpecialtySwitch()}
            >
              {specialtySwitchText()}
            </button>
          )}
        </div>
      ) : shouldAllowUfgAgentSwitch ? (
        <div className="switch-view">
          {/* If needs to switch to ufgAgent */}
          {Translations.formatString(
            Translations.Name_Search.switchViewSpecialty,
            selectedOrFirstPolicy?.policy_number,
            <button
              type="button"
              className="btn-as-link"
              onClick={() => changeToUfgAgent()}
            >
              {Translations.header.ufgAgent_switch}
            </button>
          )}
        </div>
      ) : (
        <div>
          <div>
            <AccountContainer
              accountDetails={accountDetail}
              policyDetails={policySummary}
              selectedPolicyDetail={selectedOrFirstPolicy}
              onSelectPolicy={onSelectPolicy}
              isSpecialtyPortalView={isSpecialtyPortalView}
              isMercerSpecialtyPortalView={isMercerSpecialtyPortalView}
            />
            <div>
              {!(accountDetail.insurance_type === "personal") && (
                <AccountServicesContainer
                  policyInfo={getPolicyInfoForAccountService(
                    selectedOrFirstPolicy
                  )}
                />
              )}
              {selectedOrFirstPolicy?.policy_number && (
                <Tabs activeTab={getActiveTabIndex()}>
                  <Tab
                    title={
                      Translations.account_overview.accountHome
                        .current_policy_type
                    }
                    onClick={onTabClick}
                  >
                    <CurrentPolicyContainer
                      policy={selectedOrFirstPolicy}
                      policy_type={accountDetail.insurance_type}
                    />
                  </Tab>
                  <Tab
                    title={
                      Translations.account_overview.accountHome.policy_title
                    }
                    onClick={onTabClick}
                  >
                    <PolicyActivityContainer
                      policy_number={selectedOrFirstPolicy.policy_number}
                      policy_type={accountDetail.insurance_type}
                    />
                  </Tab>

                  {!isSpecialtyPortalView && (
                    <Tab
                      title={
                        Translations.account_overview.accountHome.billing_title
                      }
                      onClick={onTabClick}
                    >
                      <BillingContainer
                        selectedPolicyDetail={selectedOrFirstPolicy}
                        accountNumber={accountDetail.account_number}
                        policyDetails={policySummary}
                      />
                    </Tab>
                  )}

                  <Tab
                    title={
                      Translations.account_overview.accountHome.claims_title
                    }
                    onClick={onTabClick}
                  >
                    <ClaimsContainer
                      policyNumber={selectedOrFirstPolicy.policy_number}
                    />
                  </Tab>

                  {!isSpecialtyPortalView && (
                    <Tab
                      title={
                        Translations.account_overview.accountHome.change_title
                      }
                      onClick={onTabClick}
                    >
                      <AccountChangeRequestContainer
                        policyInfo={getPolicyInfoForChange(
                          accountDetail,
                          selectedOrFirstPolicy
                        )}
                      />
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  accountData: state.accountData,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  agencies: sessionSelector.getAgencies(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  isMercerSpecialtyPortalView: getIsMercerSpecialtyBrokerPortalView(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state)
});

const mapDispatchToProps = {
  fetchAccountData,
  onAccountUnmount: leftAccountOverview,
  changeAgencyView: ssoActions.changeAgencyView,
  searchAllLinesForPolicies,
  changeToMercer: changeToMercerSpecialtyPortalViewState,
  changeToUfgSpecialty: changeToUfgSpecialtyPortalViewState,
  changeToUfgAgent: changeToUfgAgentPortalViewState
};

Account.propTypes = {
  accountData: PropTypes.object.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  fetchAccountData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onAccountUnmount: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  changeAgencyView: PropTypes.func,
  searchAllLinesForPolicies: PropTypes.func.isRequired,
  agencies: PropTypes.array,
  isSpecialtyPortalView: PropTypes.bool,
  isMercerSpecialtyPortalView: PropTypes.bool,
  changeToMercer: PropTypes.func,
  changeToUfgSpecialty: PropTypes.func,
  is109015Agent: PropTypes.bool,
  changeToUfgAgent: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
