import { connect } from "react-redux";
import Claims from "../components/Claims";
import { fetchClaims } from "../actions/claimsActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getPolicySummarySelector } from "../../components/account/selectors/PolicySelector";
import { startReportAClaimWithPolicy } from "../../top-action-cards/report-claim/actions/reportClaimActions";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const mapStateToProps = state => ({
  claims: state.claims.claims,
  claimsLoading: state.claims.claimsLoading,
  claimsError: state.claims.claimsError,
  claimants: state.claims.claimants,
  employeeUserName: sessionSelector.getEmployeeUserName(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  policySummary: getPolicySummarySelector(state)
});

const mapDispatchToProps = {
  fetchClaims,
  startReportAClaimWithPolicy
};

export default connect(mapStateToProps, mapDispatchToProps)(Claims);
