import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";

/*--------ELIGIBILITY SECTION---------- */
const fetchEligibilityServiceStarted = () => ({
  type: types.FETCH_ELIGIBILITY_STARTED
});

const fetchEligibilityServiceSucceeded = data => ({
  type: types.FETCH_ELIGIBILITY_SUCCEEDED,
  payload: data
});

const fetchEligibilityServiceFailed = error => ({
  type: types.FETCH_ELIGIBILITY_FAILED,
  payload: error
});

export const clearEligibility = () => ({
  type: types.CLEAR_ELIGIBILITY_DATA
});
export const fetchEligibility = (agencyCode, accountNumber) => dispatch => {
  dispatch(fetchEligibilityServiceStarted());
  return quickQuotingServices.getEligibility(agencyCode, accountNumber).then(
    response => {
      dispatch(fetchEligibilityServiceSucceeded(response.data));
    },
    error => {
      dispatch(fetchEligibilityServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
