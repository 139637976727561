export function getPastDueLoading(store) {
  return store.homeBilling.pastDueLoading;
}
export function getDueSoonLoading(store) {
  return store.homeBilling.dueSoonLoading;
}
export function getBillingDueSoonList(store) {
  return store.homeBilling.billingDueSoonList;
}
export function getDueSoonError(store) {
  return store.homeBilling.dueSoonError;
}
export function getBillingPastDueList(store) {
  return store.homeBilling.billingPastDueList;
}
export function getPastDueError(store) {
  return store.homeBilling.pastDueError;
}
export function getPastDueFetchedAgencyCode(store) {
  return store.homeBilling.pastDueFetchedAgencyCode;
}
export function getDueSoonFetchedAgencyCode(store) {
  return store.homeBilling.dueSoonFetchedAgencyCode;
}
