import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Anchor, Icon, Input, Popover } from "@ufginsurance/ui-kit";
import {
  ADDRESS_MAX_LENGTH,
  POBOX_MAX_LENGTH,
  poboxPattern,
  statesList,
  streetPattern
} from "../../constants/suretyDictionary";
import { getIsNewAccountOrObligee } from "../util";
import Address from "../Address";

export default function MultiAddress({
  disableFields,
  fetchSuretyZip,
  form,
  setDisableFields,
  showAddPoboxButton,
  stateOptions,
  suretyZip,
  checkIsNonSuretyState,
  checkIsAgencyState
}) {
  const [showPoBoxFields, setShowPoBoxFields] = useState(false);

  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const handleAddressPoBoxOnChange = ({ field, value }) => {
    const isPoBox = poboxPattern.test(value);
    updateForm({
      values: { [field]: value },
      errors: {
        [field]: isPoBox ? ["Physical Address cannot be a P.O. Box"] : []
      }
    });
  };

  const handleAddressPoBoxValidation = field => {
    const { value } = field;
    const fieldErrors = handleOnValidate(field);
    const isPoBox = poboxPattern.test(value);

    if (value && isPoBox) {
      fieldErrors.push("Physical Address cannot be a P.O. Box");
    }

    if (value && !isPoBox && !streetPattern.test(value)) {
      fieldErrors.push(
        "Not a valid address. A street address should look like '123 Main St.'"
      );
    }

    return fieldErrors;
  };

  const handlePoboxButtonClick = () => {
    if (showPoBoxFields) {
      updateForm({
        values: {
          ...values,
          pobox: ""
        }
      });
    }
    setShowPoBoxFields(p => !p);
  };

  useEffect(() => {
    setShowPoBoxFields(values?.pobox && values?.address_line);
  }, [values.pobox, values.address_line]);

  return (
    <>
      <Address
        form={form}
        agencyStates={statesList}
        disableFields={disableFields}
        setDisableFields={setDisableFields}
        showDefaultAddressField
        stateOptions={stateOptions}
        suretyZip={suretyZip}
        fetchSuretyZip={fetchSuretyZip}
        checkIsNonSuretyState={checkIsNonSuretyState}
        checkIsAgencyState={checkIsAgencyState}
        renderAddressLine={
          <Input
            id="address_line"
            name="address_line"
            label="Physical Address"
            required
            labelElement={
              <Popover
                tooltip
                id="addressLinePopover"
                trigger="hover"
                triggerContent={<Icon icon={"fasInfoCircle"} />}
                popoverContent="We require a physical address for account verification"
              />
            }
            placeholder="123 Main Street"
            onChange={handleAddressPoBoxOnChange}
            onBlur={handleOnBlur}
            onValidate={handleAddressPoBoxValidation}
            value={values.address_line}
            className="grow address"
            maxLength={ADDRESS_MAX_LENGTH}
            disabled={disableFields}
          />
        }
      />

      {showPoBoxFields && (
        <Address
          prefix="pobox"
          form={form}
          agencyStates={statesList}
          disableFields={disableFields}
          setDisableFields={setDisableFields}
          stateOptions={stateOptions}
          suretyZip={suretyZip}
          fetchSuretyZip={fetchSuretyZip}
          renderAddressLine={
            <Input
              id="pobox"
              name="pobox"
              label="P.O. Box Number"
              placeholder="1234"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.pobox}
              className="grow address"
              maxLength={POBOX_MAX_LENGTH}
              numbersOnly
            />
          }
        />
      )}

      {(showAddPoboxButton ||
        getIsNewAccountOrObligee(values?.account_number)) && (
        <Anchor
          variant={showPoBoxFields ? "red" : "blue"}
          onClick={handlePoboxButtonClick}
        >
          {showPoBoxFields
            ? "- Remove Mailing Address"
            : "+ Add a P.O. Box for Mailing"}
        </Anchor>
      )}
    </>
  );
}

MultiAddress.propTypes = {
  disableFields: PropTypes.bool,
  fetchSuretyZip: PropTypes.func,
  form: PropTypes.object.isRequired,
  setDisableFields: PropTypes.func,
  showAddPoboxButton: PropTypes.bool,
  stateOptions: PropTypes.array,
  suretyZip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkIsNonSuretyState: PropTypes.bool,
  checkIsAgencyState: PropTypes.bool
};
