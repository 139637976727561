import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator, Modal } from "@ufginsurance/ui-kit";

function SubmissionProcess({ quickSubmission }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (quickSubmission.isLoading && !showModal) setShowModal(true);
  }, [quickSubmission, showModal]);

  return (
    <Modal
      title="Submission Setup"
      show={showModal}
      onHide={() => setShowModal(false)}
      closeIcon={false}
      body={
        <LoadingIndicator
          message={
            quickSubmission?.isLongSubmission
              ? "We are now finalizing your Quick submission."
              : "Please stand by while we review your account."
          }
        />
      }
    />
  );
}

SubmissionProcess.propTypes = {
  quickSubmission: PropTypes.object
};

export default SubmissionProcess;
