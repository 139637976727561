import React, { useState, useContext } from "react";
import cn from "classnames";
import {
  Accordion,
  Button,
  FlexRow,
  Form,
  FormGroup,
  useForm,
  Switch
} from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import OqModal from "../../shared/OqModal.js";
import PriorLossesTable from "./PriorLossesTable";
import { priorLossesFields, productKeys } from "../../shared/constants";
import { usePriorLosses } from "./usePriorLosses";
import "./PriorLosses.scss";

const PriorLossesPanel = ({ lob }) => {
  const {
    quoteData,
    updatePriorLosses,
    supportingData,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);

  const [showEditModal, setShowEditModal] = useState(false);

  const {
    fieldList,
    fieldValues,
    quoteHasLosses,
    requireLosses,
    isUnknown,
    payloadWithValues
  } = usePriorLosses({
    lob,
    quoteData,
    supportingData
  });

  const hasClaimsSwitchId = `${lob}_hasClaims`;
  const addLossesSwitchId = `${lob}_addLosses`;

  // FORM FOR SWITCHES
  const initialValues = {
    [hasClaimsSwitchId]:
      // defaults to true if:
      // 1. losses exist
      // 2. 'add losses' switch is ON
      // 3: dto isUnknown = false (same as claimInfoUnknown = true)
      quoteHasLosses || requireLosses || isUnknown || false,
    [addLossesSwitchId]:
      quoteHasLosses || supportingData?.losses?.[lob]?.addLosses || false
  };
  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const resetClaimValuesToZero = () => {
    updatePriorLosses({
      lob,
      payload: payloadWithValues({ claimInfoUnknown: false, yearsValues: 0 })
    });
  };

  const updateAddLossesSupportingDataToMatch = value => {
    updateSupportingDataPromise({
      dataToMergeAndSave: { losses: { [lob]: { addLosses: value } } }
    });
  };

  // using custom handlers here because the logic is too hard to follow in useEffects
  const handleHasClaimsChange = fld => {
    const { value } = fld;

    // API call updates claimInfoKnown
    // if value is "true", then the year values should be Null
    // if value is "false" then we must set the year values to "0"
    updatePriorLosses({
      lob,
      payload: payloadWithValues({
        claimInfoUnknown: value,
        yearsValues: value ? undefined : 0
      })
    }).then(() => {
      if (value) {
        handleOnChange(fld);
      } else {
        // when unchecking the 'hasClaims', also force the 'addLosses' switch to OFF
        updateForm({
          values: { [hasClaimsSwitchId]: false, [addLossesSwitchId]: false }
        });

        // update supporting data for addLosses field so we remember it's value for future renders
        if (supportingData?.losses?.[lob]?.addLosses)
          updateAddLossesSupportingDataToMatch(false);
      }
    });
  };

  // using custom handlers here because the logic is too hard to follow in useEffects
  const handleAddLossesChange = fld => {
    const { value } = fld;

    if (value !== supportingData?.losses?.[lob]?.addLosses) {
      // when 'addLosses' is ON then:
      // values are set to 0 to be ready for modal/inputs to add values
      // else they are null and Uknown checkbox is true
      updatePriorLosses({
        lob,
        payload: payloadWithValues({
          claimInfoUnknown: !value,
          yearsValues: !value ? undefined : 0
        })
      }).then(() => {
        handleOnChange(fld);
        updateAddLossesSupportingDataToMatch(value);
      });
    }
  };

  const AccordionSecondaryTitle = values[addLossesSwitchId] ? (
    !quoteHasLosses ? (
      <em className="oq__data-section__one-is-required">
        Previous claim counts are required to continue.
      </em>
    ) : null
  ) : (
    <span>
      Including claim counts helps us better rate your quote without underwriter
      review.
    </span>
  );

  return (
    <div>
      <Accordion
        className="oq__coverable__accordion oq__prior-losses__accordion"
        textMain={priorLossesFields[lob].panelTitle}
        textExtra={AccordionSecondaryTitle}
        isOpen
      >
        <div>
          <Form context={form}>
            <FormGroup>
              <Switch
                id={hasClaimsSwitchId}
                name={hasClaimsSwitchId}
                label={`Has the applicant had ${productKeys[lob].label} claims in the last three years?`}
                onChange={handleHasClaimsChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values[hasClaimsSwitchId]}
                noLabel
                size="50p"
              />
              {values[hasClaimsSwitchId] && (
                <Switch
                  id={addLossesSwitchId}
                  name={addLossesSwitchId}
                  labelElement={
                    <div>
                      <div>Would you like to add claims now?</div>
                      <small>
                        (You can update or provide these to your underwriter
                        later.)
                      </small>
                    </div>
                  }
                  onChange={handleAddLossesChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values[addLossesSwitchId]}
                  noLabel
                  size="50p"
                />
              )}
            </FormGroup>
            <FlexRow>
              {
                // SHOW ADD/EDIT button if the "Add Losses" toggle is checked
                values[addLossesSwitchId] && (
                  <Button
                    variant="primary"
                    icon={quoteHasLosses ? null : "farPlus"}
                    onClick={() => setShowEditModal(true)}
                  >
                    {quoteHasLosses ? "Edit" : "Add"} Claim Counts
                  </Button>
                )
              }
              {quoteHasLosses && (
                <Button
                  isLink
                  wrapperClassName="push-right"
                  className="remove"
                  onClick={() => resetClaimValuesToZero()}
                >
                  Remove Claim Counts
                </Button>
              )}
            </FlexRow>
          </Form>
          {quoteHasLosses && (
            <PriorLossesTable
              lob={lob}
              fieldList={fieldList}
              fieldValues={fieldValues}
              showInputs={false}
            />
          )}
        </div>
      </Accordion>
      {showEditModal && (
        <OqModal
          className={cn()}
          title={priorLossesFields[lob].panelTitle}
          closeIcon={false}
          body={
            <div>
              <PriorLossesTable
                lob={lob}
                fieldList={fieldList}
                fieldValues={fieldValues}
                onClose={() => setShowEditModal(false)}
                showInputs
              />
            </div>
          }
          show
          size="xl"
        />
      )}
    </div>
  );
};

export default PriorLossesPanel;
