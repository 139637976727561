import * as types from "../../constants/actionTypes";
import {
  getUfgTeamContactsData,
  getUfgTeamDepartmentData
} from "../services/yourUfgTeamService";

export const setPreviousDepartment = department => ({
  type: types.SET_YOUR_UFG_TEAM_PREVIOUS_DEPARTMENT,
  previousDepartment: department
});

export const fetchDepartmentStarted = () => ({
  type: types.FETCH_UFG_DEPARTMENT_DATA_STARTED
});
export const fetchDepartmentSuccess = (
  data,
  previousAgencyCode,
  previousCompanyBranch
) => ({
  type: types.FETCH_UFG_DEPARTMENT_DATA_SUCCEEDED,
  payload: data,
  previousAgencyCode,
  previousCompanyBranch
});
export const fetchDepartmentFailure = (
  err,
  previousAgencyCode,
  previousCompanyBranch
) => ({
  type: types.FETCH_UFG_DEPARTMENT_DATA_FAILED,
  payload: err,
  previousAgencyCode,
  previousCompanyBranch
});

export const fetchUfgTeamDepartmentData = (agencyCode, companyBranch) => dispatch => {
    dispatch(fetchDepartmentStarted());
    getUfgTeamDepartmentData(agencyCode, companyBranch).then(result => {
      dispatch(fetchDepartmentSuccess(result.data, agencyCode, companyBranch));
    },
    error => {
      dispatch(fetchDepartmentFailure(error.response || error, agencyCode, companyBranch));
    });
  };

export const fetchContactsStarted = () => ({
  type: types.FETCH_CONTACT_DETAILS_STARTED
});
export const fetchContactsSuccess = data => ({
  type: types.FETCH_CONTACT_DETAILS_SUCCEEDED,
  payload: data
});
export const fetchContactsFailure = err => ({
  type: types.FETCH_CONTACT_DETAILS_FAILED,
  payload: err
});

export const fetchUfgTeamContactDetails = (agencyCode, departmentId) => dispatch => {
    dispatch(fetchContactsStarted());
    getUfgTeamContactsData(agencyCode, departmentId).then(result => {
      dispatch(fetchContactsSuccess(result.data));
    },
    error => {
      dispatch(fetchContactsFailure(error.response || error));
    });
  };
