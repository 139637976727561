import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import { FormGroup, Form, useForm, Dropdown, Button } from "@ufginsurance/ui-kit";

const ClassAppetiteGuideSelector = props => {
  const [stateOpts, setStateOpts] = useState([]);
  const [productOpts, setProductOpts] = useState([]);

  const handleSubmition = ({ values }) => {
    props.handleButtonClick(values.selectedState, values.selectedProduct);
  };

  const initialValues = {
    selectedState: props.defaultState || "",
    selectedProduct: ""
  };
  const form = useForm({ values: initialValues, onSubmit: handleSubmition });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  useEffect(() => {
    const stateOptionItems = props.stateList.map(state => ({
      label: state.name,
      value: state.abbreviation
    }));
    setStateOpts(stateOptionItems);
  }, [props.stateList]);

  useEffect(() => {
    const productOptionItems = props.productList.map(product => ({
      label: product.product_name + "" + addTrademark(product.product_code),
      value: product.product_code
    }));
    setProductOpts(productOptionItems);
  }, [props.productList, values]);

  const addTrademark = productCode => {
    return productCode === "BP3" || productCode === "BP2" ? "\u00AE" : "";
  };

  const handleStateChange = ({ field, value }) => {
    props.handleStateChange(value);
    handleOnChange({ field, value });
    handleProductChange({ field: "selectedProduct", value: "" });
  };
  const handleProductChange = ({ field, value }) => {
    props.handleProductChange(value); // this seems to be an empty function -- weird
    handleOnChange({ field, value });
  };

  return (
    <div className="class-appetite-search-container">
      <Form context={form}>
        <FormGroup>
          <Dropdown
            id="selectedState"
            name="selectedState"
            label={Translations.commercial.State}
            options={stateOpts}
            onChange={handleStateChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.selectedState}
            isSearchable
            size="md"
          />

          <Dropdown
            id="selectedProduct"
            name="selectedProduct"
            label={Translations.commercial.Product}
            options={productOpts}
            onChange={handleProductChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.selectedProduct}
            isSearchable
            size="md"
          />

          <Button
            type="submit"
            variant="primary"
            labelSpace
            disabled={!!!values.selectedProduct || !!!values.selectedState}
          >
            Submit
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

ClassAppetiteGuideSelector.propTypes = {
  stateList: PropTypes.array,
  defaultState: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  productList: PropTypes.array,
  handleStateChange: PropTypes.func,
  handleProductChange: PropTypes.func,
  handleButtonClick: PropTypes.func
};

export default ClassAppetiteGuideSelector;
