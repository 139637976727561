// Inland Marine - Builders Risk, Utilities.
import { formatDate } from "@ufginsurance/ui-kit";
import {
  RATE_RULES,
  PROTECTION_CLASSES,
  STATUS,
  ADDITIONAL_INSURED_TYPES
} from "./constants";
import { formatCurrencyUSD } from "../../../surety/shared/utils";
import { v4 } from "uuid";

export const getEmptyJobSite = () => {
  return {
    base_rate: 0,
    building_limit: "",
    city: "",
    construction_type: "",
    deductible: "",
    windhail_deductible: 0,
    premium: 0,
    number_of_stories: "",
    project_description: "",
    project_type: "",
    protection_class: "",
    soft_cost_limit: "",
    state: "",
    street_address_or_description: "",
    zip: "",
    county: "",
    id: v4()
  };
};

export const getEmptyBuildersRisk = agencyCode => {
  const today = formatDate(new Date(), "YYYY-MM-DD");
  return {
    address_line: "",
    account_name: "",
    account_number: "",
    account_search_input: "",
    account_search: "",
    account_type: "",
    additional_insured: [],
    agency_code: agencyCode,
    city: "",
    comments: "",
    contractor_name: "",
    deductible: 0,
    deductible_modification_factor: 0,
    windhail_deductible: 0,
    documents: [],
    effective_date: today,
    expiration_date: getImbrExpirationDate(today),
    first_name: "",
    job_sites: [],
    last_name: "",
    lob: "Inland Marine Builders' Risk",
    new_producer: null, // For "other" option in producer_code dropdown.
    premium: "",
    minimum_premium: 0,
    producer_code: "",
    producer: null,
    risk_indication_id: "",
    state: "",
    status: STATUS.InProgress,
    underwriter: null,
    zip: "",
    step: 1
  };
};

export const getPrefilledBuildersRisk = (data, agencyCode, step = 1) => {
  const today = formatDate(new Date(), "YYYY-MM-DD");
  return {
    ...getEmptyBuildersRisk(),
    address_line: data.address_line || "",
    account_name: data.account_name || "",
    account_number: data.account_number || "",
    account_search_input: data.account_search_input || "",
    account_search: data.account_search || "",
    account_type: data.account_type || "",
    additional_insured: data.additional_insured || [],
    city: data.city || "",
    comments: data.comments || "",
    contractor_name: data.contractor_name || "",
    deductible: data.deductible || 0,
    deductible_modification_factor: data.deductible_modification_factor || 0,
    windhail_deductible: data.windhail_deductible || 0,
    effective_date: data.effective_date || today,
    expiration_date: data.expiration_date || getImbrExpirationDate(today),
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    new_producer: data?.new_producer || null,
    premium: data.premium || "",
    coverage_amount: data.coverage_amount || "",
    minimum_premium: data.minimum_premium || 0,
    producer_code: data.producer_code || "",
    producer: data?.producer || null,
    state: data.state || "",
    zip: data.zip || "",
    documents: data.documents || [],
    job_sites: (data.job_sites || []).map(_ => ({
      ...getEmptyJobSite(),
      base_rate: _.base_rate || 0,
      building_limit: String(_.building_limit || ""),
      city: _.city || "",
      construction_type: _.construction_type || "",
      county: _.county || "",
      deductible: _.deductible || "",
      premium: _.premium || 0,
      id: _.id || v4(),
      number_of_stories: String(_.number_of_stories || ""),
      project_description: _.project_description || "",
      project_type: _.project_type || "",
      protection_class: _.protection_class ? _.protection_class.toString() : "",
      soft_cost_limit: String(_.soft_cost_limit || ""),
      state: _.state || "",
      street_address_or_description: _.street_address_or_description || "",
      windhail_deductible: _.windhail_deductible || 0,
      zip: _.zip || ""
    })),
    agency_code: data.agency_code || agencyCode,
    status: data.status || STATUS.InProgress,
    underwriter: data?.underwriter || null,
    risk_indication_id: data.risk_indication_id || "",
    step: data.step || step
  };
};

export const jobSiteConstructionTypeRate = (construction_type, state) => {
  if (!construction_type || !state) return 0;
  return RATE_RULES.constructionType[construction_type][state] || 0;
};

export const jobSiteLimitsArray = (jobSites = []) => {
  if (!jobSites?.length) return;
  const buildingLimits = jobSites.map(
    ({ street_address_or_description, building_limit }) => ({
      label: street_address_or_description,
      value: building_limit
    })
  );
  const totalLimit = buildingLimits.reduce(
    (total, limit) => (total += Number(limit.value)),
    0
  );

  return { buildingLimits, totalLimit };
};

export const formatJobSitesWithNewDeductible = (jobSites, newDeductible) => {
  return (jobSites || [])?.map(site => ({
    ...site,
    deductible: newDeductible
  }));
};

export const getImbrExpirationDate = effective_date => {
  if (!effective_date) return "";
  const expDate = new Date(effective_date);
  expDate.setFullYear(expDate.getFullYear() + 1);
  //Setting the full year comes a day behind the business requirements.
  //Start date of 2/13/23 should expire 2/13/24, not 2/12/24 adding a year alone does.
  expDate.setDate(expDate.getDate() + 1);
  return formatDate(expDate, "YYYY-MM-DD");
};

export const getProjectLimitError = (maxLimit, constructionType) =>
  `Project Limits greater than ${formatCurrencyUSD(maxLimit, 0)} ${
    constructionType === "Frame" ? "for frame constructions" : ""
  } are not allowed online. Please refer this risk to your underwriter.`;

export const createRiskIndicationPayload = values => {
  const payload = Object.assign({}, values);
  if (payload.hasOwnProperty("new_producer") && !payload?.new_producer?.email) {
    delete payload.new_producer;
  }

  delete payload.risk_indication_id;
  delete payload?.jobSiteLimits;
  delete payload?.building_number;
  delete payload?.confidence;
  delete payload?.documents;
  delete payload?.pobox;
  delete payload?.street_name;
  delete payload?.unit_number;
  delete payload?.unit_type;
  delete payload?.verify_date;
  delete payload?.coverage_amount;
  delete payload?.minimum_premium;
  delete payload?.windhail_deductible;
  delete payload?.priorStep;
  delete payload?.is_minimum_premium;

  // Cruft from OOQ's AccountOrNameSelector.
  delete payload.account_org_type_UFG;
  delete payload.account_publicID;
  delete payload.account_search_input;

  // Typecasting.
  payload.job_sites = Array.from(payload.job_sites).map(site => {
    if (!site.soft_cost_limit) delete site.soft_cost_limit;
    if (!site.premium) delete site.premium;

    return {
      ...site,
      building_limit: parseInt(site.building_limit),
      deductible: parseInt(site.deductible),
      number_of_stories: parseInt(site.number_of_stories),
      protection_class: parseInt(site.protection_class),
      ...(site.soft_cost_limit && {
        soft_cost_limit: parseInt(site.soft_cost_limit)
      })
    };
  });

  return payload;
};

export const isImbrState = (state = "") => {
  const uniqueImbrStates = new Set();

  for (const constructionType in RATE_RULES.constructionType) {
    Object.keys(RATE_RULES.constructionType[constructionType]).forEach(s =>
      uniqueImbrStates.add(s.toUpperCase())
    );
  }

  return uniqueImbrStates.has(state.toUpperCase());
};

export const getProtectionClasses = protectionClass => {
  const options = [...PROTECTION_CLASSES];
  if (!!protectionClass && !options.includes(protectionClass))
    options.push(protectionClass);
  return options.map(_ => ({
    value: _,
    label: _
  }));
};

export const findAdditionalInsuredLabel = additionalInsuredType => {
  return ADDITIONAL_INSURED_TYPES.find(
    ({ value }) => value === additionalInsuredType
  )?.label;
};
