import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateFormat, toTitleCase } from "../../../components/Factory";
import { Translations } from "../../../components/translations";
import ViewStatement from "./ViewStatement";
import { Currency, Table, Button, Anchor } from "@ufginsurance/ui-kit";
import qs from "query-string";

const BillingActivityTable = props => {
  const {
    billingActivityList,
    agencyCode,
    is109015Agent,
    isOasisAccount,
    accountNumber,
    getLegacyViewStatementPDF,
    getGWViewStatementPDF,
    billedBy,
    billingActivity
  } = props;

  const [show, setShow] = useState(false);
  const [billingStatements, setBillingStatements] = useState([]);
  const [earnedPremiumStatements, setEarnedPremiumStatements] = useState([]);

  const handleShow = row => () => {
    if (isOasisAccount) {
      const billing_statements = row.billing_statements.map(c => ({
        policy_number: row.policy_number,
        type: "gw",
        ...c
      }));

      setShow(true);
      setBillingStatements(billing_statements);
      setEarnedPremiumStatements([]);
    } else {
      const billing_statements = row.billing_statements.map(c => ({
        policy_number: row.policy_number,
        type: "legacy",
        ...c
      }));
      const earned_premium_statements =
        row.earned_premium_statements?.map(ep => ({
          policy_number: row.policy_number,
          type: "legacy",
          ...ep
        })) || [];

      setShow(true);
      setBillingStatements(billing_statements);
      setEarnedPremiumStatements(earned_premium_statements);
    }
  };

  const handleClose = () => {
    setShow(false);
    setBillingStatements([]);
    setEarnedPremiumStatements([]);
  };

  const onPayClick = policyholderSweepPayNowUrl => {
    window.location = policyholderSweepPayNowUrl;
  };

  const createColumns = (agencyCode, is109015Agent) => {
    return [
      {
        key: "policy_number",
        className: "policy-number",
        label: Translations.account_overview.billingDetailsTab.policy,
        sortable: true
      },
      {
        key: "policy_type",
        label: Translations.account_overview.billingDetailsTab.type,

        element: row => {
          return <>{toTitleCase(row.policy_type)}</>;
        }
      },
      {
        key: "who_pay",
        element: row => {
          return <span className="capitalize">{row.who_pay}</span>;
        },
        label: Translations.account_overview.billingDetailsTab.payor
      },
      {
        key: "billing_day",
        sortable: true,
        className: "bill-date",
        label: Translations.account_overview.billingDetailsTab.bill_date
      },
      {
        key: "balance",
        element: row => {
          return <Currency amount={row.balance} />;
        },
        label: Translations.account_overview.billingDetailsTab.balance
      },
      {
        key: "min_due",
        className: "amount-due",
        element: row => {
          return <Currency amount={row.min_due} />;
        },
        label: Translations.account_overview.billingDetailsTab.amount_due
      },
      {
        key: "pay_now",
        className: "pay-now",
        element: row => {
          if (!is109015Agent && row.pay_now) {
            const { accountBalance = 0, accountMinimumDueWithFees = 0 } =
              billingActivity;

            const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
            const accountBilled = billedBy === "account";
            const queryString = qs.stringify({
              num: accountBilled ? accountNumber : row.policy_number,
              typeap: accountBilled ? "A" : "P",
              min: accountBilled ? accountMinimumDueWithFees : row.min_due,
              bal: accountBilled ? accountBalance : row.balance,
              uitype: "P",
              payor: "I",
              frm: "DB",
              agtNum: agencyCode,
              CancellationUrl: window.location.href
            });
            const policyholderSweepPayNowUrl = `${baseLink}/payments/billing?${queryString}`;
            return (
              <Button
                variant="primary"
                className="paynow-btn sml"
                onClick={() => onPayClick(policyholderSweepPayNowUrl)}
              >
                Pay
              </Button>
            );
          }
        },
        label: Translations.account_overview.billingDetailsTab.policyholderSweep
      },
      {
        key: "status_detail",
        className: "capitialize",
        label: Translations.account_overview.billingDetailsTab.status
      },
      {
        key: "due_date",
        className: "due-date",
        element: row => {
          return <span>{DateFormat(row.due_date)}</span>;
        },
        label: Translations.account_overview.billingDetailsTab.due_date
      },

      {
        key: "view_statement",
        className: "view-statement",
        element: row => {
          return <Anchor onClick={handleShow(row)}>Statement</Anchor>;
        },
        label: Translations.account_overview.billingDetailsTab.view_statement
      }
    ];
  };

  const columns = createColumns(agencyCode, is109015Agent);

  return (
    <div className="billing-activity-table">
      <Table
        rowKey="policy_number"
        data={billingActivityList}
        columns={columns}
        itemsPerPage={20}
        initialSort="policy_number"
        initialDirection="asc"
      />
      <ViewStatement
        billing_statements={billingStatements}
        earned_premium_statements={earnedPremiumStatements}
        show={show}
        handleClose={handleClose}
        getLegacyViewStatementPDF={getLegacyViewStatementPDF}
        getGWViewStatementPDF={getGWViewStatementPDF}
        accountNumber={accountNumber}
      />
    </div>
  );
};

BillingActivityTable.propTypes = {
  billingActivityList: PropTypes.array.isRequired,
  agencyCode: PropTypes.string.isRequired,
  getLegacyViewStatementPDF: PropTypes.func,
  accountNumber: PropTypes.string,
  getGWViewStatementPDF: PropTypes.func,
  is109015Agent: PropTypes.bool.isRequired,
  billedBy: PropTypes.string.isRequired,
  isOasisAccount: PropTypes.bool.isRequired,
  billingActivity: PropTypes.object.isRequired
};

export default BillingActivityTable;
