import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import {
  FormGroup,
  Form,
  useForm,
  Dropdown,
  DatePicker,
  Popover,
  Input,
  Button,
  Icon
} from "@ufginsurance/ui-kit";

const InForceListForm = props => {
  const renderTrigger = (
    <span className="agency-loss-runs-popover__trigger">
      <Icon icon="fasQuestion" />
    </span>
  );

  const renderOverlay = (
    <div>
      {Translations.reports.tabUnderwriting.inForce.popOverText}
      {props.administratorName}.
    </div>
  );

  const {
    emailList,
    is109015Agent,
    submit,
    serviceCenterOptionType,
    isSpecialtyPortalView
  } = props;
  const { billType, businessType, sortListBy, reportFormat } =
    Translations.reports.tabUnderwriting.inForceListForm;
  let businessTypeOptions;
  if (serviceCenterOptionType) {
    businessTypeOptions = {
      ...businessType,
      4: "Service Center"
    };
  }

  if (isSpecialtyPortalView) {
    for (let i = 0; i < businessType.length; i++) {
      if (businessType[i].isSpecialty) {
        businessTypeOptions = businessType[i];
      }
    }
  }

  let specialtybillType;
  if (isSpecialtyPortalView) {
    for (let i = 0; i < billType.length; i++) {
      if (billType[i].isSpecialty) {
        specialtybillType = billType[i];
      }
    }
  }

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 365 * 6);

  const initialValues = {
    is109015Agent,
    billType: "0",
    businessType: "0",
    sortByList: "3",
    reportFormatTypes: "P",
    name: "",
    emailTo: "",
    inForceDate: ""
  };

  const handleSubmission = ({ values }) => {
    if (isSpecialtyPortalView) {
      values.billType = specialtybillType?.id;
      values.businessType = businessTypeOptions?.id;
    }
    const formData = {
      ...values
    };
    submit(formData, () => updateForm({ values: initialValues }));
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  return (
    <div className="reports-form__container">
      <div className="reports-form__note">
        {Translations.reports.tabUnderwriting.inForce.note}
      </div>

      <Form context={form} className="in-force-list-form">
        <FormGroup>
          <DatePicker
            id="inForceDate"
            name="inForceDate"
            label={
              Translations.reports.tabUnderwriting.inForce.inForceListLabel
                .inForceDate
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.inForceDate}
            format="DATE"
            required
            minDate={minDate}
          />
        </FormGroup>
        {!isSpecialtyPortalView && (
          <div>
            <FormGroup>
              <Dropdown
                id="billType"
                name="billType"
                label={
                  Translations.reports.tabUnderwriting.inForce.inForceListLabel
                    .billType
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.billType}
                size="md"
                options={billType.map(val => ({
                  value: val.id,
                  label: val.value
                }))}
              />
            </FormGroup>
            <FormGroup>
              <Dropdown
                id="businessType"
                name="businessType"
                label={
                  Translations.reports.tabUnderwriting.inForce.inForceListLabel
                    .businessType
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.businessType}
                size="md"
                options={businessType.map(val => ({
                  value: val.id,
                  label: val.value
                }))}
              />
            </FormGroup>
          </div>
        )}
        {isSpecialtyPortalView && (
          <div>
            <Input
              id="billType"
              name="billType"
              label={
                Translations.reports.tabUnderwriting.inForce.inForceListLabel
                  .billType
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={specialtybillType?.value}
              size="md"
            />
            <Input
              id="businessType"
              name="businessType"
              label={
                Translations.reports.tabUnderwriting.inForce.inForceListLabel
                  .businessType
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={businessTypeOptions?.value}
              size="md"
            />
          </div>
        )}

        <FormGroup>
          <Dropdown
            id="sortByList"
            name="sortByList"
            label={
              Translations.reports.tabUnderwriting.inForce.inForceListLabel
                .sortByList
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.sortByList}
            size="lg"
            options={Object.keys(sortListBy).map(key => ({
              value: key,
              label: sortListBy[key]
            }))}
          />
        </FormGroup>

        {!is109015Agent ? (
          <FormGroup>
            <Dropdown
              id="emailTo"
              name="emailTo"
              label={Translations.reports.tabClaims.agencyLossRun.label.emailTo}
              labelElement={
                <Popover
                  id="email-to-popover"
                  placement="top"
                  triggerContent={renderTrigger}
                  popoverContent={renderOverlay}
                  trigger="click"
                  theme="white"
                  maxWidth={300}
                />
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.emailTo}
              size="lg"
              options={emailList.map(q => ({
                value: q.email_address,
                label: q.email_address
              }))}
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <Input
              id="emailTo"
              name="emailTo"
              label={Translations.reports.tabClaims.agencyLossRun.label.emailTo}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={({ field, value, validation }) => {
                const errors = handleOnValidate({ field, value, validation });
                // eslint-disable-next-line
                const emailRegex = /@/;
                if (!!is109015Agent && emailRegex.test(value)) {
                  errors.push("Do not enter an email address.");
                }
                return errors;
              }}
              value={values.emailTo}
              maxLength={30}
              required
            />
            <div className="align-with-input">@unitedfiregroup.com</div>
          </FormGroup>
        )}

        <FormGroup>
          <Dropdown
            id="reportFormatTypes"
            name="reportFormatTypes"
            label={
              Translations.reports.tabClaims.agencyLossRun.label.reportFormat
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.reportFormatTypes}
            size="md"
            options={Object.keys(reportFormat).map(key => ({
              value: key,
              label: reportFormat[key]
            }))}
            isClearable={false}
          />
        </FormGroup>
        {is109015Agent && (
          <FormGroup>
            <Input
              id="name"
              name="name"
              label={Translations.reports.tabClaims.agencyLossRun.label.name}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.name}
              maxLength={22}
              required
            />
          </FormGroup>
        )}
        <FormGroup className="pad-top">
          <Button type="submit" variant="primary">
            {Translations.reports.tabClaims.agencyLossRun.label.getReport}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

InForceListForm.propTypes = {
  emailList: PropTypes.array,
  is109015Agent: PropTypes.bool,
  submit: PropTypes.func,
  administratorName: PropTypes.string,
  serviceCenterOptionType: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool
};

export default InForceListForm;
