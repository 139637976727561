import { connect } from "react-redux";
import {
  cancelQuote,
  dismissPilotAgentsError
} from "../actions/newQuoteActions";
import PilotAgents from "./PilotAgents";

// TODO: selectors
const mapStateToProps = state => ({
  error: state.newQuote.steps.pilotAgents.error
});

const mapDispatchToProps = {
  onClose: cancelQuote,
  onDismissError: dismissPilotAgentsError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PilotAgents);
