import React, { useState, useEffect } from "react";
import {
  Anchor,
  useForm,
  FormGroup,
  Input,
  Dropdown,
  Form,
  FlexRow,
  Button
} from "@ufginsurance/ui-kit";
import {
  SuretyTitleOptions,
  statesList,
  legalEntityTitleDisplay
} from "../constants/suretyDictionary";
import Address from "../shared/Address";
import "./OwnerInfo.scss";
import { v4 } from "uuid";
import MultiAddress from "../shared/MultiAddress/MultiAddress";

const AddOwner = ({
  setShowAddModal,
  setUpdatedOwnerData,
  ownerData,
  updateSuretyQuoteData,
  suretyQuote,
  cacheId,
  licensedStates,
  registerPercentageValues
}) => {
  const [titleRequired, setTitleRequired] = useState(false);

  const [readyToContinue, setReadyToContinue] = useState(true);

  const initialValues = {
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    address_line: "",
    street_name: "",
    zip: "",
    city: "",
    state: "",
    ssn: "",
    married_domestic: "",
    spouse_title: "",
    spouse_first_name: "",
    spouse_middle_name: "",
    spouse_last_name: "",
    spouse_address_line: "",
    spouse_zip: "",
    spouse_city: "",
    spouse_state: "",
    spouse_ssn: "",
    reference_number: "",
    title_other: "",
    spouse_title_other: "",
    order_credit: true
  };

  // onSubmit event
  const handleFormSubmit = fields => {
    const concatSpouseName = _ => {
      return (_.spouse_middle_name || "").trim().length > 0
        ? `${_.spouse_first_name} ${_.spouse_middle_name} ${_.spouse_last_name}`
        : `${_.spouse_first_name} ${_.spouse_last_name}`;
    };
    const concatOwnerNameForSpouse = _ => {
      return (_.middle_name || "").trim().length > 0
        ? `${_.first_name} ${_.middle_name} ${_.last_name}`
        : `${_.first_name} ${_.last_name}`;
    };

    const ownerValues = {
      id: v4(),
      title: fields.values.title,
      first_name: fields.values.first_name.trim(),
      middle_name: fields.values.middle_name.trim(),
      last_name: fields.values.last_name.trim(),
      address_line: fields.values.address_line, //this is getting hacky. We need to refactor to address line on both UI and Mule layer.
      street_name: fields.values.address_line,
      zip: fields.values.zip,
      city: fields.values.city,
      state: fields.values.state,
      pobox: fields.values.pobox,
      ssn: fields.values.ssn,
      spouse_name:
        fields.values.married_domestic === "true"
          ? concatSpouseName(fields.values).trim()
          : "",
      spouse_ssn:
        fields.values.married_domestic === "true"
          ? fields.values.spouse_ssn
          : "",
      title_other: fields.values.title_other,
      delete: false,
      ownership_percent: "0",
      is_new: true,
      is_owner: true
    };

    // when Spouse is added to Owner, then adding the owner values to spouse array as Spouse
    const spouseValues = {
      id: v4(),
      title: fields.values.spouse_title,
      title_other: fields.values.title_other,
      first_name: fields.values.spouse_first_name.trim(),
      middle_name: fields.values.spouse_middle_name.trim(),
      last_name: fields.values.spouse_last_name.trim(),
      //this is getting hacky. We need to refactor to address line on both UI and Mule layer.
      address_line:
        fields.values.spouse_address_line === ""
          ? fields.values.address_line
          : fields.values.spouse_address_line,
      street_name:
        fields.values.spouse_street_name === ""
          ? fields.values.street_name
          : fields.values.spouse_street_name,
      zip:
        fields.values.spouse_zip === ""
          ? fields.values.zip
          : fields.values.spouse_zip,
      city:
        fields.values.spouse_city === ""
          ? fields.values.city
          : fields.values.spouse_city,
      state:
        fields.values.spouse_state === ""
          ? fields.values.state
          : fields.values.spouse_state,
      ssn: fields.values.spouse_ssn,
      delete: false,
      ownership_percent: "0",
      is_owner: false,
      is_new: true,
      spouse_name: concatOwnerNameForSpouse(fields.values).trim(),
      spouse_ssn: fields.values.ssn
    };

    // this case is causing missing fields when submitting account (i.e. building_number, etc. aka pitney bowes parsed values)
    if (fields.values.married_domestic !== "true") {
      // Adding new owner
      const addOwnerUpdateQuoteValues = {
        ...suretyQuote,
        ownerData: [...ownerData, ownerValues],
        verifyModalData: {
          //maybe this would be better as a state on OwnerInfo
          show: true,
          addresses: [
            {
              id: ownerValues.id,
              type: "owner",
              address_line: ownerValues.address_line,
              city: ownerValues.city,
              state: ownerValues.state,
              zip: ownerValues.zip,
              pobox: ownerValues.pobox
            }
          ]
        }
      };

      registerPercentageValues([ownerValues.id]);
      updateSuretyQuoteData(cacheId, addOwnerUpdateQuoteValues);
      setUpdatedOwnerData([...ownerData, ownerValues]);

      setShowAddModal(false);
    } else {
      const sameAddresses =
        ownerValues.street_name?.toUpperCase().trim() ===
          spouseValues.street_name?.toUpperCase().trim() &&
        ownerValues.zip.replace(/-/g, "").trim() ===
          spouseValues.zip.replace(/-/g, "").trim() &&
        ownerValues.city.toUpperCase().trim() ===
          spouseValues.city.toUpperCase().trim() &&
        ownerValues.state.trim() === spouseValues.state.trim();

      setShowAddModal(false);

      //adding spouse
      const addSpouseUpdateQuoteValues = {
        ...suretyQuote,
        ownerData: [...ownerData, ownerValues, spouseValues],
        verifyModalData: {
          //maybe this would be better as a state on OwnerInfo
          sharedAddress: sameAddresses, //indicates that the address should be applied to owner and spouse
          show: true,
          addresses: [
            {
              id: ownerValues.id,
              type: "owner",
              reference_number: ownerValues.reference_number,
              address_line: ownerValues.address_line,
              city: ownerValues.city,
              state: ownerValues.state,
              zip: ownerValues.zip
            },
            {
              id: spouseValues.id,
              type: "spouse",
              reference_number: spouseValues.reference_number,
              address_line: spouseValues.address_line,
              city: spouseValues.city,
              state: spouseValues.state,
              zip: spouseValues.zip
            }
          ]
        }
      };

      registerPercentageValues([ownerValues.id, spouseValues.id]);

      updateSuretyQuoteData(cacheId, addSpouseUpdateQuoteValues);
      setUpdatedOwnerData([...ownerData, ownerValues, spouseValues]);
    }

    setShowAddModal(false);
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleFormSubmit
  });

  const {
    values,
    errors,
    updateForm,
    handleOnChange,
    handleOnValidate,
    handleOnBlur,
    invalidFields
  } = form;

  useEffect(() => {
    const legalEntityBusinessType = legalEntityTitleDisplay.find(
      a => a.value === suretyQuote.business_type
    );
    const hasPrimaryOwner = ownerData.some(
      obj => obj.delete === false && legalEntityBusinessType.label === obj.title
    );
    setTitleRequired(!hasPrimaryOwner);
  }, [ownerData, suretyQuote.business_type]);

  useEffect(() => {
    if (
      suretyQuote?.business_type &&
      suretyQuote?.newCustomer === true &&
      suretyQuote?.status === "W" &&
      suretyQuote?.account_number === "0000000000"
    ) {
      if (suretyQuote?.ownerData?.length === 0) {
        const legalEntityBusinessType = legalEntityTitleDisplay.find(
          a => a.value === suretyQuote.business_type
        );

        if (!!legalEntityBusinessType) {
          const defaultTitle = SuretyTitleOptions.find(
            a => a.value === legalEntityBusinessType.label
          );

          // sets the title only if it's empty and we have a good default in mind
          if (!!defaultTitle && !values.title) {
            updateForm({
              values: {
                title: defaultTitle.value
              },
              errors: {
                title: ""
              }
            });
          }
        }
      }
    }
  }, [
    suretyQuote?.account_number,
    suretyQuote.business_type,
    suretyQuote?.newCustomer,
    suretyQuote?.ownerData?.length,
    suretyQuote?.status,
    updateForm,
    values.title
  ]);

  const [addressDisplay, setAddressDisplay] = useState(false);

  const removeAddress = () => {
    setAddressDisplay(true);
    if (addressDisplay === true) {
      setAddressDisplay(false);
    }
  };

  const handleOnSsnValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    //regex for same sequence number (111-11-1111,222-22-222,etc....)
    const ssnRegex = /^(\d)(?!\1+$)\d{8}$/;
    if (!!value && (value === "123456789" || !ssnRegex.test(value)))
      fieldErrors.push(" Invalid Social Security Number");
    return fieldErrors;
  };
  useEffect(() => {
    if (values.ssn.length === 9 || values.spouse_ssn.length === 9) {
      const indexSsn = invalidFields.findIndex(a =>
        ["ssn", "spouse_ssn"].includes(a.name)
      );
      if (indexSsn > -1) {
        invalidFields.splice(indexSsn, 1);
      }
    }

    setReadyToContinue(
      Object.keys(errors).length === 0 && invalidFields.length === 0
    );
  }, [values, errors, invalidFields]);

  return (
    <Form className="qq__form__addeditowner qq__form" context={form}>
      <FormGroup className="modal_fields">
        <Dropdown
          id="title"
          name="title"
          label="Title"
          placeholder=""
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.title}
          required={titleRequired}
          options={SuretyTitleOptions}
          size="md"
        />
        {values.title === "OTHER" && (
          <Input
            id="title_other"
            name="title_other"
            label="Other Description"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.title_other}
            className="grow"
          />
        )}
        <Input
          id="first_name"
          name="first_name"
          label="First Name"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.first_name}
          required
          className="firstName"
          maxLength={20}
        />
        <Input
          id="middle_name"
          name="middle_name"
          label="Middle Name"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.middle_name}
          className="middleName"
          maxLength={15}
        />
        <Input
          id="last_name"
          name="last_name"
          label="Last Name"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.last_name}
          required
          className="lastName"
          maxLength={20}
        />
      </FormGroup>
      <FormGroup>
        <MultiAddress
          form={form}
          showDefaultAddressField
          agencyStates={statesList}
          stateOptions={licensedStates}
          showAddPoboxButton
        />
      </FormGroup>
      <FormGroup className="ssn-form-group">
        <Input
          id="ssn"
          name="ssn"
          label="Social Security Number"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnSsnValidate}
          value={values.ssn}
          required
          size="md"
          mask="ssn"
          stripMaskFromValue
        />
        <Dropdown
          id="married_domestic"
          name="married_domestic"
          label="Marital Status"
          options={[
            { value: "false", label: "Single" },
            { value: "true", label: "Married/Domestic Partner" }
          ]}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.married_domestic}
          size="lg"
          required
          isClearable={false}
        />
      </FormGroup>

      {values.married_domestic === "true" && (
        <>
          <div className="spouse_spacing">
            <b>Spouse or Domestic Partner Information</b>
            <div>
              Spouse/domestic partner must be listed as an individual
              indemnitor.
            </div>
          </div>
          <FormGroup className="modal_fields">
            <Dropdown
              id="spouse_title"
              name="spouse_title"
              label="Title"
              placeholder=""
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.spouse_title}
              options={SuretyTitleOptions}
              size="md"
            />
            {values.spouse_title === "OTHER" && (
              <Input
                id="spouse_title_other"
                name="spouse_title_other"
                label="Other Description"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.spouse_title_other}
                className="grow"
              />
            )}
            <Input
              id="spouse_first_name"
              name="spouse_first_name"
              label="First Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.spouse_first_name}
              required
              className="firstName"
              maxLength={20}
            />
            <Input
              id="spouse_middle_name"
              name="spouse_middle_name"
              label="Middle Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.spouse_middle_name}
              className="middleName"
              maxLength={15}
            />
            <Input
              id="spouse_last_name"
              name="spouse_last_name"
              label="Last Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.spouse_last_name}
              required
              className="lastName"
              maxLength={20}
            />
          </FormGroup>
          <FormGroup>
            <Anchor
              key="address_display"
              onClick={removeAddress}
              variant={addressDisplay ? "red" : "blue"}
            >
              {addressDisplay
                ? "- Remove Mailing Address"
                : "+ Add Mailing Address"}
            </Anchor>
          </FormGroup>
          {addressDisplay && (
            <Address
              form={form}
              prefix="spouse"
              showDefaultAddressField
              agencyStates={statesList}
              stateOptions={licensedStates}
            />
          )}
          <FormGroup>
            <Input
              id="spouse_ssn"
              name="spouse_ssn"
              label="Social Security Number"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnSsnValidate}
              value={values.spouse_ssn}
              required
              size="lg"
              mask="ssn"
              stripMaskFromValue
            />
          </FormGroup>
        </>
      )}

      <FlexRow className="qq__bottom-row">
        <FormGroup className="align-right">
          <Button variant="plain" onClick={() => setShowAddModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={!readyToContinue}>
            Continue
          </Button>
        </FormGroup>
      </FlexRow>
    </Form>
  );
};

export default AddOwner;
