import qs from "query-string";
import API from "../../api";

export const getPurchasedAgencyCodes = agency_code => {
  return API.agent().get(`/agency/${agency_code}/purchased-agencies`);
};

export const getSponsoredAgencyCodes = agency_code => {
  return API.agent().get(`/agency/${agency_code}/sponsored-agencies`);
};

export const getAgencyStatementList = (
  agency_code,
  purchased_sponsored_agency
) => {
  const queryString = qs.stringify({
    purchased_sponsored_agency
  });
  return API.agent()
    .get(
      `/reports/agencies/${agency_code}/accounting/agency-statement/list${
        purchased_sponsored_agency ? `?${queryString}` : ""
      }`
    )
    .then(response => {
      // This API has been very flakey and returning empty 200 OK responses.
      // Rejecting with a error object like we would receive for 'No results found'.
      return !response || !response.data
        ? Promise.reject({
            response: {
              data: {
                error_code: 404,
                message: "No results found"
              }
            }
          })
        : response;
    });
};

export const getAgencyStatementDocuments = ({
  agency_code,
  source,
  billing_type,
  report_type,
  report_group,
  document_id,
  date
}) => {
  const queryString = qs.stringify({
    source,
    billing_type,
    report_type,
    report_group,
    document_id,
    date
  });
  return API.agent().get(
    `reports/agencies/${agency_code}/accounting/agency-statement?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const getAgencyBillPastDue = agency_code => {
  return API.agent().get(
    `/reports/agencies/${agency_code}/accounting/agency-bill-past-due`,
    { responseType: "blob" }
  );
};

const getTimeZone = () => {
  //retrieves the user's time zone offset from the browser per their own unique locale in the format of "-hh:mm"
  //Date.getTimezoneOffset() only returns an integer in minutes for the offset
  const timezoneOffset = new Date().getTimezoneOffset();
  const offset = Math.abs(timezoneOffset);
  const offsetOperator = timezoneOffset < 0 ? "+" : "-";
  const offsetHours = Math.floor(offset / 60)
    .toString()
    .padStart(2, "0");
  const offsetMinutes = Math.floor(offset % 60)
    .toString()
    .padStart(2, "0");
  return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};

export const getElectronicPaymentsReport = ({
  agency_code,
  payment_type,
  start_date,
  end_date,
  is_specialty_agency
}) => {
  const timeZoneOffset = getTimeZone();
  return API.agent().post(
    `agency/${agency_code}/reconciled-payments/print`,
    {
      payment_type,
      start_date,
      end_date,
      time_zone: timeZoneOffset,
      is_specialty_agency
    },
    {
      responseType: "blob"
    }
  );
};
