import React, { useContext } from "react";
import { enhancedEndorsementHighlights } from "../shared/constants";
import "./AdditionalCoveragesName.scss";
import OnlineQuotingContext from "../OnlineQuotingContext";

const AdditionalCoveragesName = ({ coverage, extraLabel }) => {
  const { quoteData } = useContext(OnlineQuotingContext);

  const enhancedBOPEndorsementCoverageExists =
  quoteData?.lobData?.bp7BusinessOwners?.coverages?.clausesGroups?.coverages.some(
    c => c.codeIdentifier === "BP7EnhancementEndorsement_UFG" && c.selected
  );

  const enhancedAUTOEndorsementCoverageExists =
    quoteData?.lobData?.ca7CommAuto?.coverages?.clausesGroups?.coverages.some(
      c => c.codeIdentifier === "CA7BusinessAutoUltraEnd_UFG" && c.selected
    );

  return (
    <div className="oq__additional-coverages__name-wrapper">
      <div className="oq__additional-coverages__name">{coverage.name}</div>
      {enhancedBOPEndorsementCoverageExists &&
        enhancedEndorsementHighlights[coverage.codeIdentifier]?.line === "BOP" && (
          <div className="oq__enhancedEndorsementHighlight">
            <small>
              Coverage already included in your selected BOP-Pro Enhancement
            </small>
          </div>
        )}
         {enhancedAUTOEndorsementCoverageExists &&
        enhancedEndorsementHighlights[coverage.codeIdentifier]?.line === "Auto" && (
          <div className="oq__enhancedEndorsementHighlight">
            <small>
            Coverage already included in the Business Auto Ultra Endorsement
            </small>
          </div>
        )}
      {extraLabel}
    </div>
  );
};

export default AdditionalCoveragesName;
