import { priorLossesFields } from "../../shared/constants";
/*
A helper function used here and on step5 for disabling the Continue button
Massages the data from the DTO to dynamically generate the table components and inuputs

Params:
  @param lob {String} : used to look up which config to use from `priorLossesFields` in constants
  @param quoteData {Object} : dto
  @param supportingData {Object} : common local storage object 
*/
export const usePriorLosses = ({ lob, quoteData, supportingData }) => {
  // get all loss objects from dto - then filter for this lob
  const dtoLossesForLob = (quoteData?.riskAnalysis?.priorLosses || []).filter(
    l => l.lob === lob
  );

  /*
    fieldList: Array of data massaged with data from quoteData
    Example result:
    [
      {
        "rowLabel": "Workers' Compensation Claim Count",
        "claimType": "WCMPriorClaims",
        "unknown": "claimInfoUnknown",
        "fields": [
          {
            "label": "Current Term",
            "id": "year1",
            "value": ""
          },
          {
            "label": "Prior Term",
            "id": "year2",
            "value": ""
          },
          {
            "label": "Second Prior Term",
            "id": "year3",
            "value": ""
          }
        ]
      }
    ]
  */
  const fieldList = priorLossesFields[lob].tables.map(row => {
    const fieldGroup = dtoLossesForLob.find(l => l.claimType === row.claimType);
    return {
      ...row,
      fields: row.fields.map(f => {
        const v = fieldGroup?.priorYears?.[f.id];
        const value = v === undefined || v === null ? "" : v.toString();
        return { ...f, value };
      })
    };
  });

  /*
  fieldValues: read from dto data and create object of field values
  ...for the table and the modal to update them
  Example result:
  {
    "WCMPriorClaims.year1": "0",
    "WCMPriorClaims.year2": "0",
    "WCMPriorClaims.year3": "0"
  }
  */
  const fieldValues = fieldList.reduce((acc, curr) => {
    curr.fields.forEach(f => (acc[`${curr.claimType}.${f.id}`] = f.value));
    return acc;
  }, {});

  // quoteHasLosses: Boolen
  const quoteHasLosses = Object.keys(fieldValues).some(
    f => !!Number(fieldValues[f])
  );

  // isUnknown: Boolen
  // because all unknown checkboxes are tied together,
  // ... we can just pass the value of the first one in the list
  const isUnknown = dtoLossesForLob?.[0]?.claimInfoUnknown || false;

  // requireLosses: Boolen
  const requireLosses = supportingData?.losses?.[lob]?.addLosses;

  const payloadWithValues = ({ claimInfoUnknown, yearsValues }) => {
    return fieldList.map(f => {
      const priorYears = {
        year1: yearsValues,
        year2: yearsValues,
        year3: yearsValues
      };

      return {
        claimType: f.claimType,
        lob,
        claimInfoUnknown,
        priorYears
      };
    });
  };

  return {
    fieldList,
    fieldValues,
    quoteHasLosses,
    isUnknown,
    requireLosses,
    payloadWithValues
  };
};
