import { useEffect } from "react";
import { useCurrentRouteMatch } from "../hooks";

export const useTableOfContentsRouteFetch = (
  previousTableOfContentsProductId,
  areTableOfContentsLoading,
  fetchTableOfContents
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteProductId = currentMatch?.params?.productId;

  useEffect(() => {
    if (
      currentRouteProductId &&
      previousTableOfContentsProductId !== currentRouteProductId &&
      !areTableOfContentsLoading
    ) {
      fetchTableOfContents(currentRouteProductId);
    }
  }, [
    fetchTableOfContents,
    currentRouteProductId,
    areTableOfContentsLoading,
    previousTableOfContentsProductId
  ]);

  return null;
};
