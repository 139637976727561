import React from "react";
import { useRouteMatch } from "react-router-dom";
import { StepIndicator } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";

const SidebarStepper = _props => {
  const policyChangesStepRouteMatch = useRouteMatch(routes.POLICY_CHANGES_STEP);

  const { step } = policyChangesStepRouteMatch.params;

  return (
    <StepIndicator
      className="policy-changes-step-indicator"
      currentStep={
        !isNaN(Number(step)) ? Math.max(1, parseInt(Number(step))) : 1
      }
      totalSteps={2}
    />
  );
};

SidebarStepper.propTypes = {};

export default SidebarStepper;
