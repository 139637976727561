import React from "react";
import PropTypes from "prop-types";
import { Table, Icon, Anchor } from "@ufginsurance/ui-kit";
import { withRouter } from "react-router-dom";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";

const ClientNoticesTable = props => {
  const { removeClientNotice, clientNoticesList, is109015Agent, history } =
    props;

  const handleDocumentClick = row => () => {
    props.fetchClientNoticeDocument({
      status: row.status,
      policy_number: row.policy_number,
      effective_date: row.effective_date,
      processed_date: row.processed_date,
      job_number: row.job_number,
      image_right_process_key: row.image_right_process_key,
      document_id: row.document_id
    });
  };

  const columns = [
    {
      key: "account_number",
      label: Translations.commercial_tab.account_number,
      element: row =>
        row.policy_number ? (
          row.account_number
        ) : (
          <Anchor
            onClick={() => {
              history.push(`${routes.ACCOUNT_HOME}/${row.account_number}`);
            }}
          >
            {row.account_number}
          </Anchor>
        )
    },
    {
      key: "policy_number",
      label: Translations.commercial_tab.policy_number,
      element: row => (
        <Anchor
          onClick={() => {
            history.push(`${routes.ACCOUNT_HOME}/${row.policy_number}`);
          }}
        >
          {row.policy_number}
        </Anchor>
      )
    },
    {
      key: "insured_name",
      label: Translations.commercial_tab.policyholder_name
    },
    {
      key: "status_description",
      label: Translations.commercial_tab.type,
      element: row => (
        <Anchor onClick={handleDocumentClick(row)}>
          {row.status_description}
        </Anchor>
      ),
      sortable: true
    },
    {
      key: "remove",
      label: Translations.commercial_tab.remove,
      className: "client-notices-table__remove",
      element: row => {
        return (
          !is109015Agent &&
          row.source === "ufg_core" && (
            <div
              className="remove-icon"
              role="presentation"
              onClick={removeClientNotice(row)}
            >
              <Icon icon="fasTimesCircle" />
            </div>
          )
        );
      }
    }
  ];
  return (
    <div className="client-notices-table">
      <Table
        rowKey="id"
        data={clientNoticesList}
        columns={columns}
        itemsPerPage={20}
        showPagination
      />
    </div>
  );
};

ClientNoticesTable.propTypes = {
  clientNoticesList: PropTypes.array,
  removeClientNotice: PropTypes.func.isRequired,
  fetchClientNoticeDocument: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool,
  history: PropTypes.object
};
export default withRouter(ClientNoticesTable);
