import React from "react";
import { Translations } from "../../../components/translations";
import PropTypes from "prop-types";
import { Currency, Table, FormatDate } from "@ufginsurance/ui-kit";

const sortByDueDate = (a, b) => {
  return new Date(a.due_date) - new Date(b.due_date);
};

const InstallmentDetails = ({ installments }) => {
  const columns = [
    {
      key: "due_date_empty",
      label: Translations.account_overview.installments.due_date,
      sortable: true,
      sortCompare: sortByDueDate
    },
    {
      key: "policy_number",
      label: Translations.account_overview.installments.policy_number
    },
    {
      key: "bill_date",
      label: Translations.account_overview.installments.bill_date,
      element: row => (
        <FormatDate date={row?.bill_date ?? ""} format="MM/DD/YYYY" />
      )
    },
    {
      key: "balance",
      label: Translations.account_overview.installments.balance,
      element: row => <Currency amount={row.balance} />
    },
    {
      key: "amount_due",
      label: Translations.account_overview.installments.amount_due,
      element: row => (
        <>
          <Currency amount={row.amount_due} />
          {row.amount_due_changed && `*`}
        </>
      )
    }
  ];

  const groupedInstallments = [];
  installments.forEach(installment => {
    installment?.policies?.forEach(policy => {
      groupedInstallments.push({
        due_date: installment.due_date,
        grouped_balance: installment.balance,
        grouped_amount_due: installment.amount_due,
        grouped_amount_due_changed: installment.amount_due_changed,
        ...policy
      });
    });
  });

  return (
    <div>
      <div className="installment-schedule">
        <Table
          id="grouped-installment-schedule-table"
          rowKey="key"
          groupHeaderKey="due_date"
          groupHeaderRowElements={row => (
            <React.Fragment key={row.key}>
              <td className="installment-schedule-group-header">
                <FormatDate date={row?.due_date ?? ""} format="MM/DD/YYYY" />
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td className="installment-schedule-group-header">
                <Currency amount={row.grouped_balance} />
              </td>
              <td className="installment-schedule-group-header">
                <Currency amount={row.grouped_amount_due} />
              </td>
            </React.Fragment>
          )}
          columns={columns}
          data={groupedInstallments}
          showPagination={false}
          initialSortCompare={sortByDueDate}
          initialDirection="asc"
        />
      </div>
      <div className="installment-indication-value">
        {Translations.account_overview.installmentSchedule.indication_value}
      </div>
    </div>
  );
};

InstallmentDetails.propTypes = {
  installments: PropTypes.array.isRequired
};

export default InstallmentDetails;
