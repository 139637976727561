import { useMemo, useState, useEffect } from "react";
import * as keys from "../../../constants/localStorageKeys";

const useLocalStorageState = (defaultValue, key) => {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export const useQuote = () => {
  const [step, setStep] = useLocalStorageState(null, keys.CURRENT_STEP);

  return useMemo(
    () => ({
      step,
      setStep
    }),
    [step, setStep]
  );
};
