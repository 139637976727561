import React, { useLayoutEffect } from "react";
import { Icon } from "@ufginsurance/ui-kit";
import cn from "classnames";
import "./UpdateOverlay.scss";

const UpdateOverlay = ({ message, className }) => {
  const spinnerRef = React.createRef();

  useLayoutEffect(() => {
    if (!!spinnerRef.current) {
      const ref = spinnerRef.current;

      const containerBound = ref.getBoundingClientRect();
      const spinner = spinnerRef.current.querySelector(
        ".oq__update-overlay__coverage-inner"
      );

      const spinnerBound = spinner.getBoundingClientRect();

      // if window scroll position puts the top of the container into the viewport, then it's 0
      // otherwise it's the distance to the top of the viewport
      const topOfViewableContainer =
        containerBound.top > 0 ? 0 : Math.abs(containerBound.top);

      // if the window scroll position put the top of the container above the viewport, then the offset is 0,
      // otherwise it's the distance of the container above the viewport
      const topOffset =
        containerBound.top < 0 ? 0 : Math.abs(containerBound.top);

      // if the bottom of the container is below the viewport, then it's the height of the viewport
      const bottomOfViewableContainer =
        containerBound.bottom > window.innerHeight
          ? window.innerHeight
          : containerBound.bottom;

      // set the position of the spinner and message
      spinner.style.top =
        topOfViewableContainer +
        (bottomOfViewableContainer - topOffset) / 2 -
        spinnerBound.height / 2 +
        "px";
    }
  }, [spinnerRef]);

  return (
    <div
      ref={spinnerRef}
      className={cn("oq__update-overlay__coverage", className)}
    >
      <div className="oq__update-overlay__coverage-inner">
        <div className="oq__update-overlay__coverage__spinner">
          <Icon icon="farSpinnerThird" />
        </div>
        {message && (
          <div className="oq__update-overlay__coverage-message">{message}</div>
        )}
      </div>
    </div>
  );
};

export default UpdateOverlay;
