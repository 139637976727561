import React, { useState } from "react";
import { connect } from "react-redux";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Icon } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import * as routes from "../../../constants/routes";
import * as actions from "../../../actions/commercialLinesAction";
import { Translations } from "../../translations";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import ConfirmationModal from "../../modal/ConfirmationModal";
import { deleteRiskIndicationRecord } from "../../../commercial/shared/services";
import defaultToastError from "../../../shared/error/DefaultToastError";

const Actions = ({
  result,
  agencyCode,
  history,
  deleteQuote,
  copyQuote,
  editQuote,
  editApp,
  handleRefresh
}) => {
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("");

  const { buttonsList } = Translations.commercial.new_submissions_tab;

  const { deletequotemessage, copyquotemessage } =
    Translations.commercial.new_submissions_tab.actionmodalmessages;

  const handleDelete = () => {
    setShowModal(true);
    setType("delete");
  };

  const handleDeleteBuildersRisk = () => {
    setShowModal(true);
    setType("imbrDelete");
  };

  const handleEdit = () => {
    if (result.quote_number)
      history.push(`${routes.ONLINE_QUOTING_LOADER}/${result.quote_number}`);
  };

  const handleEditBuildersRisk = () => {
    if (result.id) history.push(`${routes.BUILDERS_RISK}/?id=${result.id}`);
  };

  const handleEditQuote = () => {
    editQuote(result.id).then(() => {
      if (result.edit_quote_url) window.location = result.edit_quote_url;
    });
  };

  const handleBeginApp = () => {
    editApp(result.id).then(() => {
      if (result.begin_app_url) window.location = result.begin_app_url;
    });
  };

  const handleEditApp = () => {
    editApp(result.id).then(() => {
      if (result.edit_app_url) window.location = result.edit_app_url;
    });
  };

  const handleCopy = () => {
    setShowModal(true);
    setType("copy");
  };

  const handleConfirm = () => {
    setShowModal(false);
    if (type === "delete") {
      deleteQuote(result.id, agencyCode);
    } else if (type === "copy") {
      copyQuote(result.id);
    } else if (type === "imbrDelete") {
      deleteRiskIndicationRecord(result.id)
        .then(() => {
          handleRefresh();
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(error => defaultToastError(error));
    }
  };

  const getConfirmationModalMessage = actionType => {
    if (actionType === "copy") return copyquotemessage;
    if (actionType === "imbrDelete" && result.status === "UnderReview")
      return "This submission is currently in underwriter review, are you sure you want to delete this?";
    return deletequotemessage;
  };

  if (!result) {
    return null;
  }

  return (
    <>
      <ListGroup
        key="listgroup"
        className="col-md-3 expanded-buttons-list action-list-group"
      >
        {
          // In R1, users were able to go directly to PC to edit a quote. API should update this flag to make more sense. IE: can_edit_OQ_quote.
          result.core_system === "OnlineQuoting" && result.can_edit_quote && (
            <ListGroupItem onClick={handleEdit}>
              <Icon
                icon="fasEdit"
                className="expanded-button-icon expanded-icon"
              />
              {buttonsList.editQuote}
            </ListGroupItem>
          )
        }
        {result.core_system === "BuildersRisk" && result.can_edit_quote && (
          <ListGroupItem onClick={handleEditBuildersRisk}>
            <Icon
              icon="fasEdit"
              className="expanded-button-icon expanded-icon"
            />
            {buttonsList.editQuote}
          </ListGroupItem>
        )}
        {result.core_system === "BuildersRisk" && (
          <ListGroupItem onClick={handleDeleteBuildersRisk}>
            <Icon
              icon="farTrashAlt"
              className="expanded-button-icon expanded-icon"
            />
            Delete Quote
          </ListGroupItem>
        )}
        {!["BuildersRisk", "OnlineQuoting"].includes(result.core_system) && (
          <>
            {result.can_edit_quote && (
              <ListGroupItem onClick={handleEditQuote}>
                <Icon
                  icon="fasEdit"
                  className="expanded-button-icon expanded-icon"
                />
                {buttonsList.editQuote}
              </ListGroupItem>
            )}

            {result.can_begin_app && (
              <ListGroupItem onClick={handleBeginApp}>
                <Icon
                  icon="fasFile"
                  className="expanded-button-icon expanded-icon"
                />
                {buttonsList.beginApp}
              </ListGroupItem>
            )}

            {result.can_edit_app && (
              <ListGroupItem onClick={handleEditApp}>
                <Icon
                  icon="fasEdit"
                  size="2x"
                  className="expanded-button-icon expanded-icon"
                />
                {buttonsList.editApp}
              </ListGroupItem>
            )}

            {result.can_copy && (
              <ListGroupItem onClick={handleCopy}>
                <Icon
                  icon="fasCopy"
                  className="expanded-button-icon expanded-icon"
                />
                {buttonsList.copyQuote}
              </ListGroupItem>
            )}

            {result.can_delete && (
              <ListGroupItem onClick={handleDelete}>
                <Icon
                  icon="farTrashAlt"
                  className="expanded-button-icon expanded-icon"
                />
                {buttonsList.deleteQuote}
              </ListGroupItem>
            )}
          </>
        )}
      </ListGroup>

      <ConfirmationModal
        show={showModal}
        message={getConfirmationModalMessage(type)}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

Actions.propTypes = {
  result: PropTypes.object,
  agencyCode: PropTypes.string.isRequired,
  history: PropTypes.object,
  deleteQuote: PropTypes.func,
  copyQuote: PropTypes.func,
  editQuote: PropTypes.func,
  editApp: PropTypes.func,
  handleRefresh: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    // TODO - Chris: Be explicit about what we actually need.
    // Spreading a large object makes debugging really difficult.
    ...state.commercialLines,
    agencyCode: getSelectedAgencyCode(state)
  };
};
const mapDispatchToProps = {
  deleteQuote: actions.deleteQuote,
  copyQuote: actions.copyQuote,
  editQuote: actions.editQuote,
  editApp: actions.editApp
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
