import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback
} from "react";
import * as api from "../services/onlineQuotingService";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { useLocation } from "react-router-dom";
import { ONLINE_QUOTING_STEP1 } from "../constants/routes";

import {
  LoadingIndicator,
  Button,
  Panel,
  Input,
  Select,
  Switch,
  DatePicker,
  isValidDate,
  Form,
  FormGroup,
  useForm,
  Icon,
  Popover,
  ContentHeader,
  InputEmail,
  ToolTip
} from "@ufginsurance/ui-kit";
import {
  toHtmlSelectOptions,
  arrayToOptions,
  removeDuplicates,
  decodeBase64
} from "./shared/util";
import { getMinMax } from "./shared/effectiveDate/getMinMax";
import { productKeys } from "./shared/constants";
import {
  mapExtraDataFromAddressService,
  makeSupportingDataObj,
  supportingDataToFormData
} from "./shared/supportingDataHelpers";
import { selectAccount } from "./step1/accountSelect";
import { useFlags } from "launchdarkly-react-client-sdk";
import useDebounce from "./shared/useDebounce";
import AccountSearchModal from "./step1/AccountSearchModal";
import Address from "./step1/Address";
import BopPrefill from "./step1/BopPrefill";
import BottomNavigation from "./shared/BottomNavigation";
import OQstrings from "./shared/strings";
import OnlineQuotingContext from "./OnlineQuotingContext";
import ProductsPanels from "./step1/ProductsPanel";
import ValidateCustomer from "./step1/ValidateCustomer";
import VerifyAddressesModal from "../shared/components/AddressValidate/VerifyAddressesModal";
import queryString from "query-string";
import "./Step1Profile.scss";

// update Effective Date to today and set min/max
const today = new Date();
const currentYear = today.getFullYear();

/*
----------------
STEP 1 COMPONENT
----------------
*/

const Step1Profile = ({ history, location, agencyContractBranchCode }) => {
  const {
    supportingData,
    updateSupportingDataPromise,
    clearLocalQuote,
    step,
    setStep,
    effectiveDateOverride,
    loggingData,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const { proquoteMaxEffectiveDateDays, proQuoteUseAwsProductMatrix } =
    useFlags();

  const { minDate, maxDate } = getMinMax({
    proquoteMaxEffectiveDateDays,
    effectiveDateOverride,
    step
  });

  const { hash, search } = useLocation();

  useEffect(() => {
    // OOQ-12826 - on first load, make sure page is scrolled to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (step !== 1) setStep(1);
  }, [setStep, step]);

  const queryStr = queryString.parse(location.search);

  const [showSearch, setShowSearch] = useState(hash === "#search");
  const [accountFromSearch, setAccountFromSearch] = useState();
  const [isCompany, setIsCompany] = useState(true);

  // a state to hold the metadata that's populated from the api call
  const [rawMetadata, setRawMetadata] = useState();
  const [initialSupportData, setInitialSupportData] = useState();

  // agencyStates is the states allowed by the agency call getAgencyData
  const [agencyStates, setAgencyStates] = useState([]);

  // inputOpts holds the field options from metadata
  const [inputOpts, setInputOpts] = useState({});

  // selectedState is updated by accountSearch and zipSearch
  // updating the selectedState triggers the lookup of new productMatrix data
  const [selectedState, setSelectedState] = useState();

  // productMatrix holds the data from the service: getProductMatrixForState
  // used to populate the ProductLines component
  const [productMatrix, setProductMatrix] = useState();

  // addresses state is used to handle the attributes of the
  // address lines and the buttons that toggle their visibility
  const [addresses, setAddresses] = useState({
    physical: {
      type: "physical",
      label: "Physical Business",
      options: { city: [], state: [] },
      isLoading: { city: false, state: false },
      zipcodeFail: false,
      selectedState: null,
      disabled: { city: true, state: true }
    },
    mailing_ufg: {
      type: "mailing_ufg",
      label: "Mailing",
      options: { city: [], state: [] },
      display: false,
      isLoading: { city: false, state: false },
      zipcodeFail: false,
      selectedState: null,
      disabled: { city: true, state: true }
    },
    other: {
      type: "other",
      label: "DBA",
      options: { city: [], state: [] },
      display: false,
      buttonDisplay: false,
      isLoading: { city: false, state: false },
      zipcodeFail: false,
      selectedState: null,
      disabled: { city: true, state: true }
    }
  });

  const [verifyModal, setVerifyModal] = useState(false);
  const [validateModal, setValidateModal] = useState(false);
  const [prefillModal, setPrefillModal] = useState(false);

  // allows the form to be set up before updating the quote storage
  const [formInitialized, setFormInitialized] = useState(false);

  // used for setting the class code from the appetite guide page
  const [appetiteClasscode, setAppetiteClassCode] = useState();
  const [loadingClassData, setLoadingClassData] = useState();
  const [defaultedBop, setDefaultedBop] = useState();

  // Refs
  const selectedStateRef = useRef();

  const isBranch11Agent = agencyContractBranchCode === "11";

  // OOQ-7909 - garaged auto states should only display pro quote states if user is branch 11
  const garagedStatesOptions = isBranch11Agent
    ? agencyStates.filter(s =>
        (rawMetadata?.proQuoteEnabledStates || []).includes(s)
      )
    : agencyStates;

  // ---------------
  //   SETUP FORM
  // ---------------

  // default form values
  const initialValues = {
    account_search: "",
    business_start_year: "",
    effective_date: "",
    account_org_type_UFG: "",
    account_number: "",
    account_publicID: "",
    account_name: "",
    last_name: "",
    first_name: "",
    has_DBA: false,
    DBA_name: "",
    garaged_autos: false,
    garaged_states: [],
    producer_code: "",
    is_inforce: false,
    policyholder_email: ""
  };

  // add default form values to include address fields
  Object.keys(addresses).forEach(type => {
    initialValues[type + "_address1"] = "";
    initialValues[type + "_zip"] = "";
    initialValues[type + "_city"] = "";
    initialValues[type + "_county"] = "";
    initialValues[type + "_county_code"] = "";
    initialValues[type + "_state"] = "";
    initialValues[type + "_is_autofilled"] = false;
    initialValues[type + "_publicID"] = false;
    initialValues[type + "_verified_status"] = "";
  });

  // add default form values of products
  Object.keys(productKeys).forEach(p => (initialValues[p] = false));

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  const {
    values,
    updateForm,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    errors
  } = form;

  // every time form values are updated, update the quote object
  // only if an account name or number has been added
  // debouncedValues helps provide only the most recent values get applied
  const debouncedValues = useDebounce(values, 500);

  // if we have a query param with classcode.
  useEffect(() => {
    if (!appetiteClasscode && queryStr?.combinedClass && !loadingClassData) {
      // prevent trying to load the data multiple times
      setLoadingClassData(true);
      api
        .getClassCodesMetaData({
          businessType: "",
          products: ["bp7BusinessOwners"]
        })
        .then(results => {
          if (!(results && results.data) && results.data.length === 0) {
            return;
          }

          const [classCode, classDescription] = decodeBase64(
            queryStr.combinedClass
          ).split("#");

          const selectedClassFromQueryString = results.data.find(
            c => c.code === classCode && c.description === classDescription
          );

          setAppetiteClassCode(selectedClassFromQueryString);
        })
        .catch(error => {
          toastErrr({
            action: "getClassCodesMetaData",
            description: "api load of class data in add class modal",
            error
          });

          console.error(
            "Class codes metadata failure:" + JSON.stringify(error)
          );
        });
    }
  }, [
    appetiteClasscode,
    location,
    loggingData,
    queryStr,
    loadingClassData,
    supportingData,
    toastErrr
  ]);

  useEffect(() => {
    // Make sure we have a value (user has entered something in input)
    // OOQ-2806 - save quote once name, address, and effectiveDate is entered
    if (
      debouncedValues &&
      formInitialized &&
      (!!debouncedValues.account_name ||
        !!debouncedValues.last_name ||
        !!debouncedValues.account_number) &&
      !!debouncedValues.effective_date &&
      isValidDate(debouncedValues.effective_date) &&
      !!debouncedValues.physical_address1 &&
      !!debouncedValues.physical_zip &&
      !!debouncedValues.physical_city &&
      !!debouncedValues.physical_state &&
      !Object.keys(errors).length // no form errors exist
    ) {
      debouncedValues.currentPage = String(1);

      const dataToMergeAndSave = makeSupportingDataObj(
        { values: debouncedValues },
        []
      );

      //if we got a class code from appetite guide use it in supportingData.
      if (!!appetiteClasscode) dataToMergeAndSave.classCode = appetiteClasscode;
      if (!!queryStr?.lr)
        dataToMergeAndSave.lessorsRiskIndicator = queryStr?.lr === "1";

      // if class codes exist because we returned to step1 from 2,
      // then use the class code we previously selected
      if (!!supportingData?.classCode)
        dataToMergeAndSave.classCode = supportingData?.classCode;
      if (!!supportingData?.additionalClassCodes.length)
        dataToMergeAndSave.additionalClassCodes =
          supportingData?.additionalClassCodes;

      // save prequote if not yet saved

      const payload = !supportingData
        ? { dataToMergeAndSave }
        : { dataToMergeAndSave, referenceId: supportingData?.referenceId };

      updateSupportingDataPromise(payload);
    }
    // including more dependencies causes weird save loop when using the Reset feature
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValues, formInitialized]);

  // when selectedState changes: get the product matrix for the selected state
  // useRef is used to get previous state so it doesn't run on every change
  // instead it runs only when the state value changes
  useEffect(() => {
    if (!!selectedState && selectedState !== selectedStateRef.current) {
      // the physical_state has changed, update the products and reset their values

      api
        .getProductMatrixForState({
          stateAbr: selectedState,
          useAWS: proQuoteUseAwsProductMatrix
        })
        .then(results => {
          setProductMatrix(results.data.products || []);
        })
        .catch(error => {
          toastErrr({
            action: "getProductMatrixForState",
            description: "unable to load product matrix",
            error
          });
        });

      const newValues = {};
      Object.keys(productKeys).forEach(p => (newValues[p] = false));
      updateForm({ values: { ...newValues } });
    }
    // update the selectedStateRef for comparing on the next update
    selectedStateRef.current = selectedState;
  }, [
    selectedState,
    updateForm,
    setProductMatrix,
    appetiteClasscode,
    proQuoteUseAwsProductMatrix,
    loggingData,
    supportingData,
    toastErrr
  ]);

  useEffect(() => {
    if (
      selectedState &&
      !defaultedBop &&
      appetiteClasscode &&
      !values.bp7BusinessOwners
    ) {
      updateForm({ values: { bp7BusinessOwners: true } });
      setDefaultedBop(true);
    }
  }, [
    selectedState,
    defaultedBop,
    appetiteClasscode,
    updateForm,
    values.bp7BusinessOwners
  ]);

  /*
  -----------------
  LOAD THE METADATA
  -----------------
  */
  useEffect(() => {
    if (!formInitialized) {
      const metadataCall = api
        .getCustomerInfoMetaData()
        .then(metadata => metadata)
        .catch(error => {
          toastErrr({
            action: "getCustomerInfoMetaData",
            description: "unable to customerInfo metadata",
            error
          });
        });

      const apisToRun = [metadataCall];

      if (supportingData?.referenceId) {
        apisToRun.push(
          api
            .getQuoteByReferenceId(supportingData?.referenceId)
            .then(data => data)
            .catch(error => {
              toastErrr({
                action: "getQuoteByReferenceId",
                description: "unable to load quote by reference",
                displayMessage:
                  "An error occurred.  We were unable to continue the quote that was previously in use.  Please continue with a new quote.",
                error
              });
            })
        );
      }

      Promise.all(apisToRun).then(res => {
        const metadata = res[0]?.data?.formData;
        const supportData = res[1]?.data || null;

        if (!!metadata) {
          setRawMetadata(metadata);

          // update the licensed states
          setAgencyStates(metadata.licensed_states.sort());

          // FIELD OPTIONS
          const opts = {};
          //get producers from page metadata
          opts.producer_code = toHtmlSelectOptions(metadata.producers);

          const default_producer = (metadata?.producers || []).find(
            p => p.current_producer === true
          );
          // set the org type filter to be the person field types to toggle the business name and first/last name fields
          opts.account_org_type_UFG = {
            person: metadata?.accountOrgTypeOptions
              .filter(o => metadata?.personAccountOrgTypes.includes(o.code))
              .map(o => ({ label: o.name, value: o.code })),
            company: metadata?.accountOrgTypeOptions
              .filter(o => metadata?.companyAccountOrgTypes.includes(o.code))
              .map(o => ({ label: o.name, value: o.code })),
            combined: metadata.accountOrgTypeOptions.map(o => ({
              label: o.name,
              value: o.code
            }))
          };
          setInputOpts(opts);

          // ---------------------------
          // load data from quote object
          // ---------------------------

          if (!!supportData) {
            updateFormFromSupportingData({
              supportData,
              producer: default_producer
            });
          } else {
            //OOQ-3514 making first item the list before sorting the default.
            updateForm({
              values: { producer_code: default_producer?.code || "" }
            });
          }
          // set the form to be initialized so that individual field changes update the form
          setFormInitialized(true);
        }
      });
    }
    // we only need this to run once to get things going
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportingData]);

  const updateFormFromSupportingData = useCallback(
    ({ supportData, producer }) => {
      setInitialSupportData(supportData);
      // convert the supportData to form data
      const newValues = {
        ...values,
        ...supportingDataToFormData({ supportingData: supportData })
      };

      if (!!producer) newValues.producer_code = producer?.code;

      // update the field options for the addresses
      const newAddressObj = { ...addresses };
      Object.keys(addresses).forEach(type => {
        const cityOptions = arrayToOptions([newValues[type + "_city"]]);
        const stateOptions = arrayToOptions([newValues[type + "_state"]]);
        newAddressObj[type] = {
          ...newAddressObj[type],
          display: type === "physical" || !!newValues[type + "_address1"],
          options: {
            city: cityOptions,
            state: stateOptions
          },
          disabled: {
            address1: !!supportData?.customerInformation?.accountNumber,
            zip: !!supportData?.customerInformation?.accountNumber,
            city: cityOptions.length < 2,
            state: stateOptions.length < 2
          },
          buttonDisplay:
            (type === "other" && !!newValues[type + "_address1"]) ||
            (type === "other" && newValues.has_DBA === true) ||
            type !== "other"
        };
      });
      setAddresses(newAddressObj);

      // set the selected state to show the product lines
      if (!!newValues.physical_state) {
        setSelectedState(newValues.physical_state);
      }

      // set the field values
      updateForm({ values: newValues });
    },
    [addresses, updateForm, values]
  );

  /**
   * When loading a quote from the NSL, the supporting data exists before the form...
   * update the form to match the supporting data
   */
  useEffect(() => {
    if (
      supportingData?.customerInformation?.accountHolder?.contactName &&
      !values?.account_name &&
      !values?.last_name
    )
      updateFormFromSupportingData({
        supportData: supportingData
      });
  }, [
    supportingData,
    updateFormFromSupportingData,
    values?.account_name,
    values?.last_name
  ]);

  // Popup component for the label of GaragedAutos state selection
  const triggerEl = <Icon icon="fasInfoCircle" />;
  const popoverEl =
    "The states shown are only those that you are licensed in. Please contact your UFG marketing representative for licensing procedures.";
  const tooltip = (
    <Popover
      id="chooseotherStatesTooltip"
      trigger="hover"
      triggerContent={triggerEl}
      popoverContent={popoverEl}
      theme="white"
      maxWidth={300}
    />
  );

  useEffect(() => {
    selectAccount({
      account: accountFromSearch,
      form,
      setSelectedState,
      addresses,
      setAddresses,
      agencyStates
    });
    if (accountFromSearch?.accountHolder?.subtype === "Company")
      setIsCompany(true);
    // this only needs to be caught when "accountFromSearch" is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFromSearch]);

  /*
  =================
  FORM INPUT EVENTS
  =================
  */

  const handleAccountNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(OQstrings.error.business_name_special_characters);
    return fieldErrors;
  };

  const handleLastNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(OQstrings.error.last_name_special_characters);
    return fieldErrors;
  };

  const handleFirstNameValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && !/^[a-zA-Z0-9_].*/.test(value))
      fieldErrors.push(OQstrings.error.first_name_special_characters);
    return fieldErrors;
  };

  const handleBusinessStartYearValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      const y = !isNaN(value) ? Number(value) : null;
      if (!y || y < 1000 || y > currentYear)
        fieldErrors.push("The year entered is not valid. Please try again.");
    }
    return fieldErrors;
  };

  const resetAccountSearch = () => {
    setAccountFromSearch(null);
    // clear the local quote obj so the user is starting a whole new quote
    clearLocalQuote({ goto: null });

    const newValues = { ...initialValues };
    const default_producer = (rawMetadata?.producers || []).find(
      p => p.current_producer === true
    );

    // reset the form to the default values
    form.updateForm({
      values: { ...newValues, producer_code: default_producer?.code || "" },
      errors: {
        account_number: [],
        account_name: [],
        physical_address1: [],
        physical_zip: [],
        physical_city: [],
        physical_county: [],
        physical_county_code: [],
        physical_state: [],
        last_name: [],
        first_name: [],
        account_search: [],
        account_org_type_UFG: [],
        DBA_name: []
      }
    });

    // update the address displayed
    setAddresses({
      ...addresses,
      physical: {
        ...addresses.physical,
        disabled: {
          ...addresses.physical.disabled,
          address1: false,
          zip: false
        }
      },
      mailing_ufg: {
        ...addresses.mailing_ufg,
        display: false,
        disabled: {
          ...addresses.mailing_ufg.disabled,
          address1: false,
          zip: false
        }
      },
      other: {
        ...addresses.other,
        display: false,
        disabled: {
          ...addresses.other.disabled,
          address1: false,
          zip: false
        }
      }
    });

    // remove the selected state
    setSelectedState(null);
  };

  // a generic method to make a switch toggle the visibility of another field
  const handleSwitchFieldHide = (field, fieldsToHideShow, callback) => {
    const formData = {
      values: {
        [field.field]: field.value
      },
      errors: {}
    };

    fieldsToHideShow.forEach(f => {
      formData.values[f] = "";
      formData.errors[f] = [];
    });

    updateForm(formData);
    if (callback) callback();
  };

  const removeAddress = ({ address }) => {
    // update the display state of the address
    setAddresses({
      ...addresses,
      [address.type]: { ...address, display: !address.display }
    });

    // clear the values and errors from the form for the address
    updateForm({
      values: {
        ...values,
        [address.type + "_address1"]: "",
        [address.type + "_zip"]: "",
        [address.type + "_city"]: "",
        [address.type + "_county"]: "",
        [address.type + "_county_code"]: "",
        [address.type + "_state"]: ""
      },
      errors: {
        [address.type + "_address1"]: [],
        [address.type + "_zip"]: [],
        [address.type + "_city"]: [],
        [address.type + "_county"]: [],
        [address.type + "_county_code"]: [],
        [address.type + "_state"]: []
      }
    });
  };

  const updateNameFromSearchModal = account_name => {
    if (isCompany) updateForm({ values: { account_name } });
    else {
      // split name by last space to get last name
      const names = (account_name || "").split(/\s/);
      const last = names.pop();
      updateForm({
        values: { first_name: names.join(" ") || "", last_name: last || "" }
      });
    }
  };

  // if we don't have data at the beginning... show the loading indicator
  if (Object.keys(inputOpts).length === 0) {
    return <LoadingIndicator />;
  }

  const addressesToVerify = !!values
    ? Object.keys(addresses).reduce((acc, type) => {
        const address1 = values[type + "_address1"];
        const city = values[type + "_city"];
        const state = values[type + "_state"];
        const zip = values[type + "_zip"];
        const county = values[type + "_county"];
        const countyCode = values[type + "_countyCode"];
        const label = addresses[type].label;
        if (!!address1)
          acc[type] = { address1, city, state, zip, county, countyCode, label };
        return acc;
      }, {})
    : {};

  const afterAddressVerify = addressData => {
    /** update supportingData with response */
    const newValues = { ...values };

    const extraAddressValues = {};
    Object.keys(addressData).forEach(type => {
      const { addressFields, rawVerificationData, isOriginal, confidence } =
        addressData[type];

      if (addressFields?.address1) {
        newValues[type + "_address1"] = addressFields.address1;
        newValues[type + "_city"] = addressFields.city;
        newValues[type + "_state"] = addressFields.state;
        newValues[type + "_zip"] = addressFields.zip;
        newValues[type + "_county"] = addressFields.county;
        newValues[type + "_county_code"] = addressFields.county_code;
      }
      /**
       * VERIFIED
       * if using Original, then it's "override"
       * ... otherwise, check the confidence level
       */
      // set to 'verified' if user selects offered address...
      // ... otherwise, it's "override"
      newValues[type + "_verified_status"] = isOriginal
        ? "override"
        : confidence > 50
        ? "verified"
        : "unverified";

      // add the extra values from the service to the dropdown selection
      // so the saved extra values match the users' selection
      extraAddressValues[type] =
        mapExtraDataFromAddressService(rawVerificationData);
    });

    const dataToMergeAndSave = makeSupportingDataObj(
      { values: newValues, extraAddressValues },
      []
    );

    updateSupportingDataPromise({ dataToMergeAndSave }).then(() => {
      setVerifyModal(false);
      setValidateModal(true);
    });
  };

  /* 
  ----------------------
  NAVIGATION VALIDATION
  ----------------------
  */
  const formErrorsCount = invalidFields.length;
  const selectedProducts = Object.keys(productKeys).filter(p => !!values[p]);

  const searchForCustomerButton = (
    <Button
      isLink
      inline
      icon="farSearch"
      wrapperClassName="oq__account-search__open-modal"
      onClick={() => setShowSearch(true)}
    >
      Search for Customer
    </Button>
  );
  const toggleCompanyIndividual = (
    <Button
      labelSpace
      wrapperClassName="oq__account__company-individual-toggle"
      inline
      isLink
      onClick={() => {
        setIsCompany(!isCompany);
        updateForm({
          values: { account_name: "", last_name: "", first_name: "" },
          errors: { account_name: [], last_name: [], first_name: [] }
        });
      }}
    >
      Switch to {isCompany ? "Individual" : "Business"}
    </Button>
  );

  const isExistingAccount =
    !!accountFromSearch?.accountNumber ||
    !!accountFromSearch?.source === "mis" ||
    !!accountFromSearch?.inforce ||
    /***
     * if starting a quote from the NSL, then we're using quote loader
     * and the supporting data will have an account number...
     * ... which means we're using an existing account
     */
    !!supportingData?.customerInformation?.accountNumber;

  const accountEntered =
    !!accountFromSearch || !!values.account_name || !!values.last_name;

  return (
    <>
      <ContentHeader>Let&rsquo;s start with the basics</ContentHeader>

      {showSearch && (
        <AccountSearchModal
          setAccountFromSearch={setAccountFromSearch}
          updateNameField={updateNameFromSearchModal}
          onHide={() => {
            setShowSearch(false);
            // we use a history.replace here in order to remove the hash #search so that the search modal doesn't appear again if the user refreshes the page
            history.replace({ pathname: ONLINE_QUOTING_STEP1, search });
          }}
        />
      )}

      <Form className="oq__form__step1 oq__form" context={form}>
        <Panel
          rounded
          bgcolor="grey"
          title={
            <div className="oq__form__step1__business-panel-title">
              Business Information{" "}
              <small>
                {isExistingAccount
                  ? "(using an existing account)"
                  : "(creating a new account)"}
              </small>
              {values.account_name && (
                <Button
                  labelSpace
                  wrapperClassName="oq__account-search__reset"
                  isLink
                  inline
                  onClick={resetAccountSearch}
                >
                  Clear Form
                </Button>
              )}
            </div>
          }
          titlebar
        >
          <FormGroup className="oq__form__step1__account">
            {isCompany ? (
              <Input
                id="account_name"
                name="account_name"
                label="Business Name"
                labelElement={searchForCustomerButton}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleAccountNameValidate}
                value={values.account_name}
                required
                className="oq__form__company-individual-field grow"
                size="auto"
                disabled={isExistingAccount}
                maxLength={60}
              />
            ) : (
              <>
                <Input
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  labelElement={searchForCustomerButton}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleLastNameValidate}
                  value={values.last_name}
                  required
                  className="oq__form__company-individual-field grow"
                  disabled={isExistingAccount}
                  maxLength={30}
                />
                <Input
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleFirstNameValidate}
                  value={values.first_name}
                  required
                  className="grow"
                  disabled={isExistingAccount}
                  maxLength={30}
                />
              </>
            )}

            {!isExistingAccount && toggleCompanyIndividual}
          </FormGroup>

          {!!values.is_inforce && (
            <div className="oq__account-search__errors">
              The account is already insured by your agency with UFG Insurance.
              Your quote will be reviewed by your UFG underwriter.
            </div>
          )}
          <FormGroup>
            <DatePicker
              id="effective_date"
              name="effective_date"
              label="Effective Date"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.effective_date}
              required
              className="oq__form__step1__effective-date"
              minDate={minDate}
              maxDate={maxDate}
              disabled={!accountEntered}
            />
            <Select
              id="account_org_type_UFG"
              name="account_org_type_UFG"
              label="Legal Entity"
              placeholder="Select a legal entity"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.account_org_type_UFG}
              options={
                !!values?.account_name
                  ? inputOpts?.account_org_type_UFG?.company || []
                  : !!values?.last_name
                  ? inputOpts?.account_org_type_UFG?.person || []
                  : []
              }
              className="grow oq__form__step1__accountOrgType"
              required
              isClearable
              disabled={
                !accountEntered ||
                (!!values.is_inforce && !!values.account_org_type_UFG)
              }
            />
            <Select
              id="producer_code"
              name="producer_code"
              label="Producer"
              placeholder="Select a producer"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.producer_code}
              options={inputOpts.producer_code || []}
              className="oq__form__step1__producer"
              required
              isClearable
              disabled={!accountEntered}
            />

            <Input
              id="business_start_year"
              name="business_start_year"
              label="Business Start Year"
              className="oq__form__step1__start-year"
              numbersOnly
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleBusinessStartYearValidate}
              value={values.business_start_year}
              required
              maxLength={4}
              disabled={
                !accountEntered ||
                (!!initialSupportData?.businessStartYear && !!values.is_inforce)
              }
            />
          </FormGroup>
          <FormGroup>
            <Switch
              id="has_DBA"
              name="has_DBA"
              label="Is there a DBA?"
              onChange={field => {
                // clicking on has_DBA will reset the value and errors for the DBA fields
                // it also toggles the button to toggle the address
                // and always hides the DBA address fields
                handleSwitchFieldHide(
                  field,
                  [
                    "DBA_name",
                    "other_address1",
                    "other_zip",
                    "other_city",
                    "other_state"
                  ],
                  () => {
                    setAddresses({
                      ...addresses,
                      other: {
                        ...addresses.other,
                        buttonDisplay: field.value,
                        display: false
                      }
                    });
                  }
                );
              }}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.has_DBA}
              className="grow"
              disabled={!accountEntered || !!values.is_inforce}
            />
            {values.has_DBA && (
              <Input
                id="DBA_name"
                name="DBA_name"
                label="DBA Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.DBA_name}
                required
                className="grow"
                disabled={!accountEntered || !!values.is_inforce}
                maxLength={30}
              />
            )}
          </FormGroup>
        </Panel>

        {accountEntered && (
          <Panel
            rounded
            bgcolor="grey"
            title="Address"
            titlebar
            className="oq__step1__address-panel"
          >
            {Object.keys(addresses).map(ak => {
              const a = addresses[ak];

              return (
                (a.display === undefined || !!a.display) && (
                  <Address
                    key={a.type}
                    type={a.type}
                    form={form}
                    agencyStates={agencyStates}
                    addresses={addresses}
                    setAddresses={setAddresses}
                    setProductMatrix={setProductMatrix}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    accountIsInforce={values.is_inforce}
                    disabled={!accountEntered}
                  />
                )
              );
            })}
            <FormGroup>
              {Object.keys(addresses).map(addressKey => {
                if (addressKey === "physical") return null;

                const a = addresses[addressKey];
                return (
                  a.display !== undefined &&
                  (a.buttonDisplay === undefined ||
                    a.buttonDisplay === true) && (
                    <Button
                      className="oq__toggle-address"
                      key={a.type}
                      isLink
                      inline
                      variant={a.display ? "tertiary" : "info"}
                      onClick={() => {
                        removeAddress({ address: a });
                      }}
                    >
                      {a.display === false && <>+ Add {a.label} Address</>}
                      {a.display === true && (
                        <span>Remove {a.label} Address</span>
                      )}
                    </Button>
                  )
                );
              })}
            </FormGroup>
            <FormGroup>
              <InputEmail
                id="policyholder_email"
                name="policyholder_email"
                label="Policyholder Email Address (Optional)"
                labelElement={
                  <ToolTip
                    position="top"
                    variant="white"
                    nowrap={false}
                    width={250}
                    content={
                      <div>
                        An email address is optional to quote. To support
                        UFG&apos;s efforts to be paperless, an email address
                        will be required to bind.
                      </div>
                    }
                    trigger={
                      <Button isLink inline>
                        Why we&apos;re asking
                      </Button>
                    }
                  />
                }
                placeholder=""
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.policyholder_email}
                disabled={!accountEntered}
                className="email"
                size="lg"
              />
            </FormGroup>

            <FormGroup>
              <Switch
                id="garaged_autos"
                name="garaged_autos"
                label="Are there additional locations or garaged autos in other states?"
                onChange={field =>
                  handleSwitchFieldHide(field, ["garaged_states"])
                }
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.garaged_autos}
                className="grow"
                disabled={!!values.is_inforce}
              />
              {!!values.garaged_autos && (
                <Select
                  id="garaged_states"
                  name="garaged_states"
                  label="Select states of locations and garaged autos"
                  placeholder="Select states"
                  labelElement={tooltip}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.garaged_states}
                  required
                  options={arrayToOptions(
                    removeDuplicates(garagedStatesOptions)
                  )}
                  multi
                  className="grow"
                  disabled={!!values.is_inforce}
                />
              )}
            </FormGroup>
          </Panel>
        )}
        {!!selectedState && (
          <ProductsPanels
            form={form}
            isBranch11Agent={isBranch11Agent}
            rawMetadata={rawMetadata}
            selectedState={selectedState}
            productMatrix={productMatrix}
          />
        )}
      </Form>
      {verifyModal && (
        <VerifyAddressesModal
          form={form}
          addressesToVerify={addressesToVerify}
          show={verifyModal}
          onContinue={afterAddressVerify}
          onCancel={() => setVerifyModal(false)}
        />
      )}
      {validateModal && (
        <ValidateCustomer
          show={validateModal}
          setValidateModal={setValidateModal}
          setPrefillModal={setPrefillModal}
          history={history}
        />
      )}
      {prefillModal && (
        <BopPrefill
          show={prefillModal}
          setPrefillModal={setPrefillModal}
          history={history}
          referenceId={supportingData?.referenceId}
        />
      )}

      <BottomNavigation
        right={{
          text: "Continue to Classify Business",
          onClick: () => setVerifyModal(true),
          disabled: formErrorsCount > 0 || selectedProducts.length === 0
        }}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  quoteData: ownProps.quoteData,
  updateQuote: ownProps.updateQuote,
  agencyContractBranchCode: sessionSelector.getAgencyContractBranchCode(state)
});

export default connect(mapStateToProps, {})(Step1Profile);
