import React, { useContext } from "react";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import OqModal from "../../shared/OqModal";
import _remove from "lodash/remove";
import Step5Context from "../Step5Context";

const BadBuildingModal = ({ building, onCancel }) => {
  const { quoteIsUpdating, deleteCoverablePromise, toastErrr } =
    useContext(OnlineQuotingContext);
  const { showBadBuildingModal, setShowBadBuildingModal } =
    useContext(Step5Context);

  const onDeleteBuilding = () => {
    deleteCoverablePromise({
      coverableType: "building",
      buildingIds: String(building.fixedId),
      locationId: building.locationId
    })
      .catch(({ error }) => {
        toastErrr({
          action: "onDeleteBuilding",
          misc: { building },
          error,
          description: "Failed to remove building",
          displayMessage: "Failed to remove building."
        });
      })
      .finally(() => {
        setShowBadBuildingModal(false);
        onCancel();
      });
  };

  if (!showBadBuildingModal) return null;

  const warningValue =
    building?.automaticSprinklerSystemPresent === "No"
      ? " $500,000 "
      : " $3,500,000 ";

  return (
    <OqModal
      title={<span>Building: {building?.description} is not eligible</span>}
      className="oq__bad-building__modal"
      show
      closeIcon={false}
      size="md"
      body={
        <div>
          <p>
            We’re sorry but due to the building information you entered the
            submission is not eligible for coverage.
          </p>
          <p>
            This buliding’s features allow for a maximum total of
            <span className="oq__bad-building__warning-amount">
              {warningValue}
            </span>
            of Limit and BPP.
          </p>
          <p>
            Please contact your underwriter if you have any questions. Thank
            you!
          </p>
          <FlexRow align="right">
            <Button
              className="oq__bad-building__close"
              onClick={() => setShowBadBuildingModal(false)}
            >
              Edit Building
            </Button>

            <Button
              variant="tertiary"
              onClick={() => {
                onDeleteBuilding(building);
              }}
              disabled={quoteIsUpdating}
            >
              Remove Building
            </Button>
          </FlexRow>
        </div>
      }
    />
  );
};

export default BadBuildingModal;
