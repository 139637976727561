import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "@ufginsurance/ui-kit";
import { DateFormat } from "../../../../components/Factory";

const AgencyOasis = ({
  documents,
  title,
  fetchAccountingStatementViewPDF,
  report_group,
  agency_code,
  source,
  billing_type
}) => {
  const [documentsLoading, setDocumentsLoading] = useState([]);

  const handleDocumentClick = (date, report_type, document_id, pdfId) => () => {
    setDocumentsLoading(items => [...items, pdfId]);
    fetchAccountingStatementViewPDF({
      agency_code,
      source,
      billing_type,
      report_group,
      date,
      report_type,
      document_id
    }).finally(() => {
      setDocumentsLoading(items => items.filter(_ => _ !== pdfId));
    });
  };

  const getColumns = () => [
    {
      label: "",
      key: "date",
      element: row => <div>{DateFormat(row.date, "MM/DD/YYYY")}</div>
    },
    {
      label: "",
      key: "report_information",
      element: row => {
        const reportInfo = row.report_information.filter(
          r => r.report_type === "statement"
        );
        if (!reportInfo.length) return null;
        const pdfId = row.id + "statement";
        return (
          <div>
            <Button
              isLink
              inline
              className="nowrap"
              spinner={documentsLoading.includes(pdfId)}
              disabled={documentsLoading.includes(pdfId)}
              onClick={handleDocumentClick(
                row.date,
                "statement",
                reportInfo.length === 0 ? "0" : reportInfo[0].id
              )}
            >
              Monthly Statement
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <div className="accounting-title-oasis">
        <span>{title}</span>
      </div>
      <Table
        className="noHeaders"
        rowKey="id"
        data={documents}
        columns={getColumns()}
        itemsPerPage={15}
        showPagination
      />
    </div>
  );
};

AgencyOasis.propTypes = {
  documents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  report_group: PropTypes.string,
  agency_code: PropTypes.string,
  source: PropTypes.string,
  billing_type: PropTypes.string
};

export default AgencyOasis;
