import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Form,
  FormGroup,
  Table,
  useForm,
  Currency
} from "@ufginsurance/ui-kit";
import { StateLongNames } from "../../../constants/dictionary";
import * as billingServices from "../services/billingResourceServices";
import { useBillingResourcesContext } from "../context";

const ServiceCharges = () => {
  const { agencyCode, defaultAgentState } = useBillingResourcesContext();

  const columns = [
    {
      key: "id",
      className: "billing_resources_hide_service_key_column"
    },
    {
      key: "invoice_fee",
      label: "Invoice Fee",
      element: row => <Currency amount={row.invoice_fee} />
    },
    {
      key: "bank_return_fee",
      label: "Bank Return Fee",
      element: row => <Currency amount={row.bank_return_fee} />
    },
    {
      key: "late_fee",
      label: "Late Fee",
      element: row => <Currency amount={row.late_fee} />
    }
  ];

  const [licensedStates, setLicensedStates] = useState([]);
  const [serviceFees, setServiceFees] = useState([]);

  const initialValues = {
    states: defaultAgentState
  };

  const form = useForm({ values: initialValues });
  const { values, handleOnBlur, handleOnValidate, updateForm } = form;

  const fetchLicensedStates = agency => {
    billingServices.getLicensedStates(agency).then(
      res => {
        if (res && res.data && res.data.length > 0) {
          const tmpStates = res.data.map(s => {
            return {
              label: StateLongNames[s] ?? s,
              value: s
            };
          });

          setLicensedStates(tmpStates);
        }
      },
      () => {}
    );
  };

  const fetchServices = state => {
    billingServices.getServiceChargeFees(state).then(
      res => {
        if (res && res.data) {
          setServiceFees([
            {
              ...res.data,
              id: "1"
            }
          ]);
        }
      },
      () => {}
    );
  };

  const handleChangeValue = ({ value }) => {
    updateForm({
      values: { states: value }
    });
    fetchServices(value);
  };

  useEffect(() => {
    fetchLicensedStates(agencyCode);
    fetchServices(defaultAgentState);
  }, [agencyCode, defaultAgentState]);

  return (
    <div>
      <b>Service Charges By State</b>
      <Form context={form}>
        <FormGroup>
          <Dropdown
            id="states"
            name="states"
            placeholder="Select State"
            label="Select State"
            options={licensedStates}
            onChange={handleChangeValue}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.states}
            size="md"
            isClearable={false}
          />
        </FormGroup>

        <FormGroup>
          <Table rowKey="id" columns={columns} data={serviceFees} />
        </FormGroup>
      </Form>
    </div>
  );
};

export default ServiceCharges;
