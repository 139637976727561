import * as types from "../constants/actionTypes";
import * as policyDecsService from "../services/policyDecsService";
import * as renewalUpdatesService from "../services/renewalUpdatesService";
import * as clientNoticesService from "../services/clientNoticesService";
import { DocumentService } from "../services/documentService";

import { logger } from "../loggers";
import { Translations } from "../components/translations";

const POLICY_DECS_FAIL_ERROR_MESSAGE =
  Translations.commercial_tab.policy_decs_fail_error_message;

const CLIENT_NOTICES_FAIL_ERROR_MESSAGE =
  Translations.commercial_tab.client_notices_fail_error_message;

const renewalUpdatesFetchStarted = () => ({
  type: types.FETCH_RENEWAL_UPDATES_STARTED
});

const renewalUpdatesFetchSucceeded = (data, agencyCode) => ({
  type: types.FETCH_RENEWAL_UPDATES_SUCCEEDED,
  payload: data,
  agencyCode
});

const renewalUpdatesFetchFailed = error => ({
  type: types.FETCH_RENEWAL_UPDATES_FAILED,
  payload: error
});

const fetchPolicyDecsStarted = () => ({
  type: types.FETCH_COMMERCIAL_POLICY_DECS_STARTED
});

const fetchPolicyDecsSucceeded = data => ({
  type: types.FETCH_COMMERCIAL_POLICY_DECS_SUCCEEDED,
  payload: data
});

const fetchPolicyDecsFetchFailed = error => ({
  type: types.FETCH_COMMERCIAL_POLICY_DECS_FAILED,
  payload: error,
  error: true
});

const renewalUpdateDeleteSucceeded = () => ({
  type: types.DELETE_RENEWAL_UPDATES_SUCCEEDED
});

const renewalUpdateDeleteFailed = error => ({
  type: types.DELETE_RENEWAL_UPDATES_FAILED,
  payload: error
});

const fetchClientNoticesStarted = () => ({
  type: types.FETCH_COMMERCIAL_CLIENT_NOTICES_STARTED
});

export const resetClientNotices = () => ({
  type: types.RESET_COMMERCIAL_CLIENT_NOTICES
});

const fetchClientNoticesSucceeded = data => ({
  type: types.FETCH_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED,
  payload: data
});

const fetchClientNoticesFailed = error => ({
  type: types.FETCH_COMMERCIAL_CLIENT_NOTICES_FAILED,
  payload: error,
  error: true
});

const clientNoticesDeleteSucceeded = () => ({
  type: types.DELETE_COMMERCIAL_CLIENT_NOTICES_SUCCEEDED
});

const clientNoticesDeleteFailed = error => ({
  type: types.DELETE_COMMERCIAL_CLIENT_NOTICES_FAILED,
  payload: error
});

export const fetchRenewalUpdates = agency_code => dispatch => {
  dispatch(renewalUpdatesFetchStarted());
  return renewalUpdatesService.fetchRenewalUpdates(agency_code).then(
    response => {
      dispatch(renewalUpdatesFetchSucceeded(response.data, agency_code));
    },
    error => {
      const errorMessage =
        error.response &&
        error.response.data &&
        error.response.data.message &&
        (error.response.data.error_code === 101 ||
          error.response.status === 404)
          ? Translations.renewalUpdates.errors.noResults
          : Translations.renewalUpdates.errors.default;
      dispatch(renewalUpdatesFetchFailed(errorMessage));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const deleteRenewalUpdate =
  ({
    agency_code,
    process_date,
    stat_type,
    effective_date,
    cbp_indicator,
    policy_number
  }) =>
  dispatch => {
    return renewalUpdatesService
      .deleteRenewalUpdate({
        agency_code,
        process_date,
        stat_type,
        effective_date,
        cbp_indicator,
        policy_number
      })
      .then(
        () => {
          dispatch(fetchRenewalUpdates(agency_code));
          dispatch(renewalUpdateDeleteSucceeded());
        },
        error => {
          dispatch(
            renewalUpdateDeleteFailed(
              Translations.renewalUpdates.errors.default
            )
          );
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const fetchPolicyDecs =
  (agency_code, begin_date, end_date, company_branch) => dispatch => {
    dispatch(fetchPolicyDecsStarted());
    return policyDecsService
      .fetchPolicyDecs(agency_code, begin_date, end_date, company_branch)
      .then(
        response => {
          if (response.data && response.data.length > 0)
            dispatch(fetchPolicyDecsSucceeded(response.data));
          else {
            dispatch(
              fetchPolicyDecsFetchFailed(POLICY_DECS_FAIL_ERROR_MESSAGE)
            );
          }
        },
        error => {
          dispatch(fetchPolicyDecsFetchFailed(POLICY_DECS_FAIL_ERROR_MESSAGE));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const removePolicyDec =
  (agency_code, policy, process_date, begin_date, end_date) => dispatch => {
    return policyDecsService
      .removePolicyDec(agency_code, policy, process_date)
      .then(
        () => {
          dispatch(fetchPolicyDecs(agency_code, begin_date, end_date));
        },
        error => {
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const fetchClientNotices =
  (agency_code, begin_date, end_date, company_branch) => dispatch => {
    dispatch(fetchClientNoticesStarted());
    return clientNoticesService
      .fetchClientNotices(agency_code, begin_date, end_date, company_branch)
      .then(
        response => {
          if (response.data && response.data.length > 0)
            dispatch(fetchClientNoticesSucceeded(response.data));
          else {
            dispatch(
              fetchClientNoticesFailed(CLIENT_NOTICES_FAIL_ERROR_MESSAGE)
            );
          }
        },
        error => {
          dispatch(fetchClientNoticesFailed(CLIENT_NOTICES_FAIL_ERROR_MESSAGE));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const removeClientNotice =
  (agency_code, clientNoticeItem, begin_date, end_date) => dispatch => {
    return clientNoticesService
      .removeClientNotice(agency_code, clientNoticeItem)
      .then(
        () => {
          dispatch(fetchClientNotices(agency_code, begin_date, end_date));
          dispatch(clientNoticesDeleteSucceeded());
        },
        error => {
          dispatch(
            clientNoticesDeleteFailed(
              Translations.renewalUpdates.errors.default
            )
          );
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const fetchClientNoticeDocument = data => () => {
  const documentService = new DocumentService();
  return clientNoticesService.fetchClientNoticeCommercialDocument(data).then(
    response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${data.policy_number}-client-notices-commercial.pdf`
      );
    },
    () => {
      documentService.clearRenderer();
    }
  );
};

export const getPolicyDecsPersonalDocument = body => () => {
  const documentService = new DocumentService();
  return policyDecsService.fetchPolicyDecsPersonalDocument(body).then(
    response => {
      const { processed_date } = body.documents[0];
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${processed_date}-policy-decs.pdf`
      );
    },
    () => {
      documentService.clearRenderer();
    }
  );
};

export const getPolicyDecsCommercialDocumentFailed = () => {
  return {
    type: types.FETCH_POLICY_DECS_COMMERICIAL_DOCUMENT_FAILED,
    payload: true
  };
};

export const getPolicyDecsCommercialDocument = body => dispatch => {
  const documentService = new DocumentService();
  return policyDecsService.fetchPolicyDecsCommercialDocument(body).then(
    response => {
      const { processed_date } = body.documents[0];
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${processed_date}-policy-decs.pdf`
      );
    },
    () => {
      documentService.clearRenderer();
      dispatch(getPolicyDecsCommercialDocumentFailed());
      if (
        document.getElementsByClassName(body.documents[0].policy_number)
          .length > 0
      ) {
        document.getElementsByClassName(
          body.documents[0].policy_number
        )[0].style.display = "none";
      }
    }
  );
};

export const DismissErrorModal = () => ({
  type: types.FETCH_POLICY_DECS_COMMERICIAL_DOCUMENT_FAILED,
  payload: false
});
