import moment from "moment";
import { ProducerStatuses, ProducerTypes } from "../constants";
import * as types from "../../../constants/actionTypes";

// DGB-315: https://ufginsurance.atlassian.net/browse/DGB-315
const MAX_CITY_LENGTH = 20;

export const initialState = {
  currentStep: "lineOfBusiness",
  previousSteps: [],
  initiatedFrom: "",
  quoteStarted: false,
  loading: [],

  // This is the customer object from `customersByAmsId`.
  // Updated when dropdown changes or enter from 'Edit Customer'.
  existingCustomer: null,

  billingAddress: null,
  mailingAddress: null,
  submission: null,

  // The 'form' property in each step is NOT updated on each change.
  // They serve only as a means of persisting state between step transitions.
  steps: {
    lineOfBusiness: {
      form: {
        lineOfBusiness: ""
      }
    },
    customer: {
      billingSameAsMailing: true,
      billingCities: [],
      billingStates: [],
      cities: [],
      states: [],
      customersByAmsId: new Map(),
      customerDetails: null,
      customerDetailsSubmitLoading: true,
      customerDetailsSubmitError: false,
      customerDetailsSubmitSuccess: false,
      error: null,
      isAdverseRisk: false,
      form: {
        existingCustomer: null,
        // This is really the ams id but UI says Customer ID
        customerId: "",
        companyName: "",
        firstName: "",
        lastName: "",
        mailingAddress: "",
        city: null,
        state: null,
        zip: "",
        // TODO - Chris: Revisit if this is necessary or billingCity/State can be moved into appropriate actions
        billingAddress: "",
        billingZip: "",
        billingCity: null,
        billingState: null
      }
    },
    addressValidation: {
      enteredAddress: null,
      suggestedAddress: null,
      suggestedAddressIsLoading: false,
      suggestedAddressError: null
    },
    quoteDetails: {
      createSubmissionError: false,
      createSubmissionIsLoading: false,
      primaryRateStates: [],
      primaryRateStatesLoading: false,
      primaryRateStatesError: false,
      producers: [],
      producersLoading: false,
      producersError: false,
      error: null,
      form: {
        effectiveDate: moment().format("MM/DD/YYYY"),
        primaryRateState: null,
        producer: null
      }
    },
    pilotAgents: {
      billingSameAsMailing: true,
      billingCities: [],
      billingStates: [],
      quoteType: null,
      product: null,
      customerType: null,
      error: null,
      form: {
        firstName: "",
        lastName: "",
        companyName: "",
        legalEntity: null,
        billingAddress: "",
        billingZip: "",
        billingCity: null,
        billingState: null
      }
    },
    pilotAddressValidation: {
      error: null,
      enteredAddress: null,
      suggestedAddress: null
    }
  }
};

const convertExistingCustomerToForm = existingCustomer => {
  if (!existingCustomer) {
    return { ...initialState.steps.customer.form };
  }

  const { agency_management_system_id, business_address, name } =
    existingCustomer;

  return {
    existingCustomer: agency_management_system_id,
    customerId: agency_management_system_id,
    companyName: name,
    mailingAddress: business_address.address1,
    city: business_address.city,
    state: business_address.state,
    zip: business_address.zip
  };
};

const getProducerOrDefault = (
  fourDigitProducerNumber,
  defaultValue,
  producers
) => {
  // Api sends producer number on producers as int without filling up to 4 digits.
  // Need to take from thousands and remove any leading 0's.
  const producerNumber = fourDigitProducerNumber % 1000;
  const producer = producers.find(
    p => parseInt(p.number, 10) === producerNumber
  );
  return producer ? producer.number : defaultValue;
};

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case types.START_QUOTE: {
      return {
        ...initialState,
        currentStep: action.payload.initialStep
          ? action.payload.initialStep
          : initialState.currentStep,
        initiatedFrom: action.payload.initiatedFrom
          ? action.payload.initiatedFrom
          : initialState.initiatedFrom,
        quoteStarted: true
      };
    }
    // case types.ADD_QUOTE_FOR_EXISTING_CUSTOMER: {
    //   return {
    //     ...state,
    //     steps: {
    //       ...state.steps,
    //       customer: {
    //         ...state.steps.customer,
    //         customerDetails: action.payload.customer
    //       }
    //     }
    //   };
    // }
    case types.FETCH_PILOT_ACCOUNT_SUCCEEDED: {
      const { account } = action.payload;
      const billingAddresses = account.additional_addresses
        .filter(a => a.address_type === "billing")
        .map(a => ({
          billingAddress: a.address1,
          billingZip: a.zip,
          billingCity: a.city,
          billingState: a.state
        }));

      const billingAddress =
        billingAddresses.length > 0 ? billingAddresses[0] : undefined;

      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.FETCH_PILOT_ACCOUNT_STARTED
        ),
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            isPilotCustomer: true,
            isPilotPerson: account.subtype === "Person",
            isPilotCompany: account.subtype === "Company",
            account,
            billingCities: billingAddress
              ? [
                  {
                    value: billingAddress.billingCity,
                    label: billingAddress.billingCity
                  }
                ]
              : [],
            billingStates: billingAddresses
              ? [
                  {
                    value: billingAddress.billingState,
                    label: billingAddress.billingState
                  }
                ]
              : []
          }
        }
      };
    }
    case types.DEFAULT_PILOT_FORM_FROM_ACCOUNT: {
      const { account, billingAddress, mailingAddress, billingSameAsMailing } =
        action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            customerType: account.subtype,
            billingSameAsMailing,
            billingCities: [
              {
                value: billingAddress.city,
                label: billingAddress.city
              }
            ],
            billingStates: [
              {
                value: billingAddress.state,
                label: billingAddress.state
              }
            ],
            form: {
              ...state.steps.pilotAgents.form,
              firstName: account.firstname,
              lastName: account.lastname,
              companyName: account.contact_name,
              legalEntity: account.account_organization_type,
              mailingAddress: mailingAddress.address_line,
              mailingCity: mailingAddress.city,
              mailingState: mailingAddress.state,
              mailingZip: mailingAddress.zip,
              billingAddress: billingAddress.address_line,
              billingCity: billingAddress.city,
              billingState: billingAddress.state,
              billingZip: billingAddress.zip
            }
          }
        }
      };
    }
    case types.EDIT_CUSTOMER: {
      const form = convertExistingCustomerToForm(action.payload.customer);
      return {
        ...state,
        existingCustomer: action.payload.customer,
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            cities: [
              {
                value: form.city,
                label: form.city
              }
            ],
            states: [
              {
                value: form.state,
                label: form.state
              }
            ],
            form: {
              ...state.steps.customer.form,
              ...form
            }
          }
        }
      };
    }
    case types.FINISH_QUOTE:
    case types.CANCEL_QUOTE: {
      return {
        ...initialState
      };
    }
    case types.SAVE_STEP: {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.step]: {
            ...state.steps[action.payload.step],
            form: action.payload.form
          }
        }
      };
    }
    case types.GO_TO_NEXT_STEP: {
      return {
        ...state,
        currentStep: action.payload.step,
        previousSteps: [...state.previousSteps, state.currentStep]
      };
    }
    case types.GO_TO_PREVIOUS_STEP: {
      const currentStep = state.currentStep;
      const previousStep = [...state.previousSteps].pop();
      return {
        ...state,
        previousSteps: state.previousSteps.slice(
          0,
          state.previousSteps.length - 1
        ),
        currentStep: previousStep ? previousStep : "",
        steps: {
          ...state.steps,
          [currentStep]: initialState.steps[currentStep]
        }
      };
    }
    case types.PILOT_UPDATE_FORM_FIELD: {
      const { field } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            form: {
              ...state.steps.pilotAgents.form,
              [field.name]: field.value
            }
          }
        }
      };
    }
    case types.EXISTING_CUSTOMER_SELECTED: {
      const form = convertExistingCustomerToForm(action.payload.customer);
      return {
        ...state,
        existingCustomer: action.payload.customer,
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            isAdverseRisk: false,
            // We also need to update the form here in order for the
            // form reset to work correctly when clearing a selected existing
            // from the dropdown customer.
            // https://ufginsurance.atlassian.net/browse/OPM-5091
            form: {
              ...form
            },
            cities: [
              {
                value: form.city,
                label: form.city
              }
            ],
            states: [
              {
                value: form.state,
                label: form.state
              }
            ]
          }
        }
      };
    }
    case types.FETCH_EXISTING_CUSTOMERS_SUCCEEDED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            customersByAmsId: new Map(
              action.payload.map(c => [c.agency_management_system_id, { ...c }])
            )
          }
        }
      };
    }
    case types.FETCH_EXISTING_CUSTOMERS_FAILED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            customersByAmsId: new Map()
          }
        }
      };
    }
    case types.CREATE_OR_UPDATE_CUSTOMER_STARTED: {
      return {
        ...state,
        loading: [...state.loading, types.CREATE_OR_UPDATE_CUSTOMER_STARTED],
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            customerDetails: null,
            customerDetailsSubmitLoading: true,
            customerDetailsSubmitSuccess: false
          }
        }
      };
    }
    case types.CREATE_OR_UPDATE_CUSTOMER_SUCCEEDED: {
      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.CREATE_OR_UPDATE_CUSTOMER_STARTED
        ),
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            customerDetails: action.payload.data,
            customerDetailsSubmitSuccess: true,
            customerDetailsSubmitLoading: false,
            customerDetailsSubmitError: false,
            error: null,
            isAdverseRisk: false
          }
        }
      };
    }
    case types.CREATE_OR_UPDATE_CUSTOMER_FAILED: {
      const { error, isAdverseRisk } = action.payload;
      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.CREATE_OR_UPDATE_CUSTOMER_STARTED
        ),
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            error,
            isAdverseRisk,
            customerDetailsSubmitLoading: false,
            customerDetailsSubmitError: true,
            customerDetailsSubmitSuccess: false
          }
        }
      };
    }
    case types.DISMISS_CUSTOMER_AND_ADDRESS_VALIDATION_ERROR: {
      return {
        ...state,
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            error: null,
            customerDetailsSubmitError: true
          },
          addressValidation: {
            ...state.steps.addressValidation,
            error: null
          }
        }
      };
    }
    case types.DISMISS_QUOTE_DETAILS_ERROR: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            createSubmissionError: false,
            primaryRateStatesError: false,
            producersError: false,
            error: null
          }
        }
      };
    }
    case types.DISMISS_PILOT_AGENTS_ERROR: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            error: null
          }
        }
      };
    }
    case types.LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED: {
      const { addressType, suggestions } = action.payload;
      const cities = (suggestions || [])
        .filter(a => a.city.length <= MAX_CITY_LENGTH)
        .map(a => ({
          value: a.city,
          label: a.city
        }));
      const states = [...new Set((suggestions || []).map(a => a.state))].map(
        state => ({
          value: state,
          label: state
        })
      );
      const isMailingAddress = addressType === "mailing_ufg";
      const nextCustomerValues = isMailingAddress
        ? {
            cities,
            states
          }
        : {
            billingCities: cities,
            billingStates: states
          };
      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.LOOKUP_LOCATION_BY_ZIP_CODE_STARTED
        ),
        steps: {
          ...state.steps,
          customer: {
            ...state.steps.customer,
            ...nextCustomerValues,
            error: null
          }
        }
      };
    }
    case types.LOOKUP_LOCATION_BY_ZIP_CODE_FAILED: {
      const { addressType } = action.payload;
      const isMailingAddress = addressType === "mailing_ufg";
      const { customer } = state.steps;
      const nextCustomerValues = isMailingAddress
        ? {
            ...customer,
            cities: [],
            states: [],
            form: {
              ...customer.form,
              city: null,
              state: null,
              zip: ""
            }
          }
        : {
            ...customer,
            billingCities: [],
            billingStates: [],
            form: {
              ...customer.form,
              billingCity: null,
              billingState: null,
              billingZip: ""
            }
          };

      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.LOOKUP_LOCATION_BY_ZIP_CODE_STARTED
        ),
        steps: {
          ...state.steps,
          customer: {
            ...nextCustomerValues,
            error: action.payload.error
          }
        }
      };
    }
    case types.PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_SUCCEEDED: {
      const { suggestions } = action.payload;
      const suggestion = suggestions[0] || {};
      const billingCities = (suggestions || [])
        .filter(a => a.city.length <= MAX_CITY_LENGTH)
        .map(a => ({
          value: a.city,
          label: a.city
        }));
      const billingStates = [
        ...new Set((suggestions || []).map(a => a.state))
      ].map(state => ({
        value: state,
        label: state
      }));
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            billingCities,
            billingStates,
            error: null,
            form: {
              ...state.steps.pilotAgents.form,
              billingCity: billingCities.length === 1 ? suggestion.city : null,
              billingState:
                billingStates.length === 1 ? suggestion.state : null,
              billingZip: action.payload.zipCode
            }
          }
        }
      };
    }
    case types.PILOT_LOOKUP_LOCATION_BY_ZIP_CODE_FAILED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            billingCities: [],
            States: [],
            error: action.payload.error,
            form: {
              ...state.steps.pilotAgents.form,
              billingCity: null,
              billingState: null,
              billingZip: ""
            }
          }
        }
      };
    }
    case types.LOOKUP_LOCATION_BY_ZIP_CODE_STARTED: {
      return {
        ...state,
        loading: [...state.loading, types.LOOKUP_LOCATION_BY_ZIP_CODE_STARTED]
      };
    }
    case types.VERIFY_ADDRESS_STARTED: {
      return {
        ...state,
        loading: [...state.loading, types.VERIFY_ADDRESS_STARTED]
      };
    }
    case types.FETCH_PILOT_ACCOUNT_STARTED: {
      return {
        ...state,
        loading: [...state.loading, types.FETCH_PILOT_ACCOUNT_STARTED]
      };
    }
    case types.FETCH_PILOT_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: state.loading.filter(
          x => x !== types.FETCH_PILOT_ACCOUNT_STARTED
        )
      };
    }
    case types.VERIFY_ADDRESS_SUCCEEDED: {
      return {
        ...state,
        loading: state.loading.filter(x => x !== types.VERIFY_ADDRESS_STARTED),
        steps: {
          ...state.steps,
          addressValidation: {
            ...state.steps.addressValidation,
            suggestedAddress: action.payload.suggestedAddress,
            error: null
          }
        }
      };
    }
    case types.VERIFY_ADDRESS_FAILED: {
      return {
        ...state,
        loading: state.loading.filter(x => x !== types.VERIFY_ADDRESS_STARTED),
        steps: {
          ...state.steps,
          addressValidation: {
            ...state.steps.addressValidation,
            error: action.payload.error
          }
        }
      };
    }
    case types.SAVE_MAILING_ADDRESS: {
      // Not destructing `address` to avoid shadowing redux's `state` variable.
      const { address } = action.payload;

      const geolocation = address.geolocation
        ? address.geolocation
        : address.longitude && address.latitude
        ? {
            longitude: Number.parseFloat(address.longitude),
            latitude: Number.parseFloat(address.latitude)
          }
        : undefined;

      return {
        ...state,
        mailingAddress: {
          address1: address.address_line
            ? address.address_line
            : address.address1,
          city: address.city,
          state: address.state,
          zip: address.zip,
          geolocation
        }
      };
    }
    case types.SAVE_ENTERED_ADDRESS: {
      return {
        ...state,
        steps: {
          ...state.steps,
          addressValidation: {
            ...state.steps.addressValidation,
            enteredAddress: action.payload.address
          }
        }
      };
    }
    case types.FETCH_PRIMARY_RATE_STATES_STARTED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            primaryRateStatesLoading: true,
            primaryRateStatesError: false,
            error: null
          }
        }
      };
    }
    case types.FETCH_PRIMARY_RATE_STATES_SUCCEEDED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            primaryRateStates: action.payload.primaryRateStates,
            primaryRateStatesLoading: false,
            primaryRateStatesError: false
          }
        }
      };
    }
    case types.FETCH_PRIMARY_RATE_STATES_FAILED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            primaryRateStates: [],
            primaryRateStatesLoading: false,
            primaryRateStatesError: true,
            error: action.payload.error
          }
        }
      };
    }
    case types.FETCH_PRODUCERS_STARTED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            producersLoading: true,
            producersError: false,
            error: null
          }
        }
      };
    }
    case types.FETCH_PRODUCERS_SUCCEEDED: {
      const activeProducerFilter = p =>
        p.status === ProducerStatuses.active &&
        p.type === ProducerTypes.producer;
      const alphabeticalSort = prop => (a, b) => a[prop].localeCompare(b[prop]);
      const producers = action.payload.producers
        .filter(activeProducerFilter)
        .sort(alphabeticalSort("name"));
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            form: {
              ...state.steps.quoteDetails.form,
              producer: getProducerOrDefault(
                action.payload.producerNumber,
                initialState.steps.quoteDetails.form.producer,
                producers
              )
            },
            producers,
            producersLoading: false,
            producersError: false
          }
        }
      };
    }
    case types.FETCH_PRODUCERS_FAILED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          quoteDetails: {
            ...state.steps.quoteDetails,
            producers: [],
            producersLoading: false,
            producersError: true,
            error: action.payload.error
          }
        }
      };
    }
    case types.CREATE_SUBMISSION_STARTED: {
      const { currentStep } = state;
      return {
        ...state,
        steps: {
          ...state.steps,
          [currentStep]: {
            ...state.steps[currentStep],
            error: null
          }
        }
      };
    }
    case types.CREATE_SUBMISSION_SUCCEEDED: {
      const { currentStep } = state;
      return {
        ...state,
        submission: action.payload.submission,
        steps: {
          ...state.steps,
          [currentStep]: {
            ...state.steps[currentStep],
            error: null
          }
        }
      };
    }
    case types.CREATE_SUBMISSION_FAILED: {
      const { currentStep } = state;
      return {
        ...state,
        submission: null,
        steps: {
          ...state.steps,
          [currentStep]: {
            ...state.steps[currentStep],
            error: action.payload.error
          }
        }
      };
    }
    // TODO: Verify this is necessary
    case types.BILLING_SAME_AS_MAILING_CHANGE: {
      const { checked } = action.payload.checked;
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            // Don't touch this.. apparently checkboxes are terrible in react/redux.
            // Using variations of `checked` only ended up with redux not correctly updating true/false value
            // and the react checkbox ending up in uncontrolled status.
            billingSameAsMailing: !state.steps.pilotAgents.billingSameAsMailing,
            form: {
              ...state.steps.pilotAgents.form,
              billingAddress: checked
                ? state.steps.customer.form.mailingAddress
                : initialState.steps.pilotAgents.form.billingAddress,
              billingZip: checked
                ? state.steps.customer.form.zip
                : initialState.steps.pilotAgents.form.billingZip,
              billingCity: checked
                ? state.steps.customer.form.city
                : initialState.steps.pilotAgents.form.billingCity,
              billingState: checked
                ? state.steps.customer.form.state
                : initialState.steps.pilotAgents.form.billingState
            }
          }
        }
      };
    }
    case types.PILOT_QUOTE_TYPE_CHANGE: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            quoteType: action.payload.quoteType,
            product: initialState.steps.pilotAgents.product
          }
        }
      };
    }
    case types.PILOT_CUSTOMER_TYPE_CHANGE: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAgents: {
            ...state.steps.pilotAgents,
            customerType: action.payload.customerType,
            form: {
              ...state.steps.pilotAgents.form,
              companyName:
                action.payload.customerType === "Company"
                  ? state.steps.pilotAgents.form.companyName
                  : initialState.steps.pilotAgents.form.companyName,
              firstName:
                action.payload.customerType === "Person"
                  ? state.steps.pilotAgents.form.firstName
                  : initialState.steps.pilotAgents.form.firstName,
              lastName:
                action.payload.customerType === "Person"
                  ? state.steps.pilotAgents.form.lastName
                  : initialState.steps.pilotAgents.form.lastName,
              legalEntity: initialState.steps.pilotAgents.form.legalEntity
            }
          }
        }
      };
    }
    case types.PILOT_VERIFY_ADDRESS_SUCCEEDED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAddressValidation: {
            ...state.steps.pilotAddressValidation,
            suggestedAddress: action.payload.suggestedAddress,
            error: null
          }
        }
      };
    }
    case types.PILOT_VERIFY_ADDRESS_FAILED: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAddressValidation: {
            ...state.steps.pilotAddressValidation,
            error: action.payload.error
          }
        }
      };
    }
    case types.PILOT_SAVE_ENTERED_ADDRESS: {
      return {
        ...state,
        steps: {
          ...state.steps,
          pilotAddressValidation: {
            ...state.steps.pilotAddressValidation,
            enteredAddress: action.payload.address
          }
        }
      };
    }
    case types.PILOT_SAVE_BILLING_ADDRESS: {
      // Not destructing `address` to avoid shadowing redux's `state` variable.
      const { address } = action.payload;
      const geolocation =
        address.longitude && address.latitude
          ? {
              longitude: address.longitude,
              latitude: address.latitude
            }
          : undefined;
      return {
        ...state,
        billingAddress: {
          address1: address.address_line
            ? address.address_line
            : address.address1,
          city: address.city,
          state: address.state,
          zip: address.zip,
          geolocation
        }
      };
    }
    case types.NEW_QUOTE_DISMISS_ERROR: {
      const { currentStep } = state;
      return {
        ...state,
        steps: {
          ...state.steps,
          [currentStep]: {
            ...state.steps[currentStep],
            error: null
          }
        }
      };
    }
    default:
      return state;
  }
};
