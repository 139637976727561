import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DATE_FORMAT } from "../services/claimsUtil";
import PaymentDetailsContainer from "../containers/PaymentDetailsContainer";
import { Translations } from "../../components/translations";
import { toTitleCase } from "../../components/Factory";
import { Currency, Table, Anchor } from "@ufginsurance/ui-kit";

const ClaimsPayments = ({
  fetchClaimsPayments,
  payments,
  claimNumber,
  selectedAgencyCode,
  policyNumber,
  employeeUserName
}) => {
  const [show, setShow] = useState(false);
  const [paymentNumber, setPaymentNumber] = useState(false);

  useEffect(() => {
    fetchClaimsPayments(
      claimNumber,
      selectedAgencyCode,
      employeeUserName
    );
  }, [
    fetchClaimsPayments,
    claimNumber,
    selectedAgencyCode,
    employeeUserName
  ]);

  const handleShow = paymentNumber => () => {
    setShow(true);
    setPaymentNumber(paymentNumber);
  };

  const handleClose = () => {
    setShow(false);
  };

  const columns = [
    {
      key: "payment_number",
      label: Translations.Claims.claims_detail.payment_number,
      align: "left",
      className: "payments-payment-number",
      element: row => {
        const paymentNumber = row.payment_number;
        return (
          <Anchor onClick={handleShow(paymentNumber)}
          >
            {row.payment_number}
          </Anchor>
        );
      },
      sortable: false
    },
    {
      key: "issue_date",
      label: Translations.Claims.claims_detail.issue_date,
      align: "left",
      className: "payments-payment-issue-date",
      element: row => {
        return <span>{moment(row.issue_date).format(DATE_FORMAT)}</span>;
      },
      sortable: false
    },
    {
      key: "payment_amount",
      label: Translations.Claims.claims_detail.total_paid,
      align: "right",
      className: "payments-payment-payment-amount",
      element: row => {
        return (
          <span>
            <Currency amount={row.payment_amount} />
          </span>
        );
      },
      sortable: false
    },

    {
      key: "payee_name",
      label: Translations.Claims.claims_detail.payee,
      align: "left",
      className: "payments-payment-payee-name",
      headerAlign: "left",
      element: row => {
        return <span>{toTitleCase(row.payee_name)}</span>;
      },
      sortable: false
    }
  ];

  const hasPayments = payments && payments.length > 0;
  const shouldShowModal = show && paymentNumber;

  return (
    <div>
      {hasPayments ? (
        <div className="payments">
          <Table
            data={payments}
            columns={columns}
            compact
            showPagination
            className="claims-payments-table"
            rowKey="id"
          />

          {shouldShowModal && (
            <PaymentDetailsContainer
              claimNumber={claimNumber}
              paymentNumber={paymentNumber}
              policyNumber={policyNumber}
              show={show}
              handleClose={handleClose}
            />
          )}
        </div>
      ) : (
        <div>{Translations.Claims.claims_detail.no_payments}</div>
      )}
    </div>
  );
};

ClaimsPayments.propTypes = {
  fetchClaimsPayments: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  claimNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedAgencyCode: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  employeeUserName: PropTypes.string
};

export default ClaimsPayments;
