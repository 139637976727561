import React, { useState, useEffect } from "react";
import { Panel } from "@ufginsurance/ui-kit";
import "./QQSideBar.scss";
import CustomerInfo from "./CustomerInfo";
import LegalEntity from "./LegalEntity";
import ConstructionSpecialty from "./ConstructionSpecialty";
import OwnerData from "./OwnerData";
import AccountNumber from "./AccountNumber";

const QQSideBar = ({ suretyQuote, step, updateQuote, history }) => {
  const [showAccountNumber, setShowAccountNumber] = useState(true);

  useEffect(() => {
    if (
      !!suretyQuote &&
      !!suretyQuote?.account_number &&
      parseInt(suretyQuote?.account_number) === 0
    ) {
      setShowAccountNumber(false);
    }
  }, [suretyQuote]);

  return (
    <>
      {step === 1 && (
        <div className="qq__welcome-panel">
          <img
            src={window.env.REACT_APP_SURETY_UFGQUICK_WELCOME}
            className="ufg_quick_welcome"
            alt="Welcome to ufgQuick Quote"
          />
        </div>
      )}

      {suretyQuote && step > 1 && step <= 4 && (
        <div>
          <Panel
            className="qq__sidebar__summary"
            highlight="red"
            title="Summary"
          >
            <CustomerInfo
              quoteData={suretyQuote}
              step={step}
              updateQuote={updateQuote}
            />
            {showAccountNumber && (
              <AccountNumber
                quoteData={suretyQuote}
                step={step}
                updateQuote={updateQuote}
              />
            )}

            <LegalEntity quoteData={suretyQuote} step={step} />
            <ConstructionSpecialty quoteData={suretyQuote} step={step} />
            {suretyQuote && step > 2 && (
              <OwnerData
                quoteData={suretyQuote}
                step={step}
                history={history}
              />
            )}
          </Panel>
        </div>
      )}
    </>
  );
};
export default QQSideBar;
