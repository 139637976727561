import * as types from "../constants/actionTypes";
import initialState from "./../reducers/initialState";

const policyActivityReducer = (state = initialState.activityData, action) => {
  switch (action.type) {
    case types.FETCH_POLICY_ACTIVITY_LIST_STARTED:
      return {
        ...state,
        isActivityLoading: true,
        activityList: [],
        errorActivityList: false
      };
    case types.FETCH_POLICY_ACTIVITY_LIST_SUCCEEDED:
      return {
        ...state,
        isActivityLoading: false,
        activityList: action.payload,
        errorActivityList: false
      };
    case types.FETCH_POLICY_ACTIVITY_LIST_FAILED:
      return {
        ...state,
        isActivityLoading: false,
        activityList: [],
        errorActivityList: action.payload
      };
    case types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_STARTED:
      return {
        ...state,
        isCancellationLoading: true,
        cancellationList: [],
        errorCancellationList: false
      };
    case types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_SUCCEEDED:
      return {
        ...state,
        isCancellationLoading: false,
        cancellationList: action.payload,
        errorCancellationList: false
      };
    case types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_FAILED:
      return {
        ...state,
        isCancellationLoading: false,
        cancellationList: [],
        errorCancellationList: action.payload
      };
    default:
      return state;
  }
};

export default policyActivityReducer;
