import React, { useState } from "react";
import {
  Alert,
  Anchor,
  Button,
  LoadingIndicator,
  Modal,
  Panel,
  Table,
  Form,
  useForm,
  Popover,
  Icon
} from "@ufginsurance/ui-kit";
import { useSuretyHomeContext } from "../SuretyHomeProvider";
import {
  formatAccountsDropdownOptions,
  openPopupWindow
} from "../../shared/utils";
import AccountBondSearchInput from "./AccountBondSearchInput";
import { Eportal } from "../../shared/eportal";
import { BOND_NUM_LENGTH } from "../../shared/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSuretyContext } from "../../surety-context/SuretyContext";

export default function AccountBondSearch({ history }) {
  const { updateBondOverview } = useSuretyContext();
  const { accounts, accountsIsLoading } = useSuretyHomeContext();
  const [showModal, setShowModal] = useState(false);
  const { suretyAccountOverviewEnabled, suretyBondOverviewEnabled } =
    useFlags();

  const form = useForm({
    values: { accountBondSearch: "", accountBondSearchInput: "" },
    onSubmit: () => {}
  });

  return (
    <div className="account-bond-search--wrapper">
      <Panel
        rounded
        bgcolor="grey"
        title={
          <>
            <strong>Surety Account or Bond Search</strong>
            <span>
              <Popover
                id="full-bond-partial-account-popover"
                trigger="hover"
                triggerContent={<Icon icon="fasInfoCircle" className="ml-sm" />}
                popoverContent="Full bond number or partial account name is required to search."
              />
            </span>
          </>
        }
        titlebar
      >
        <Form context={form}>
          <AccountBondSearchInput
            form={form}
            onChangeHandler={value => {
              if (!value) return;
              if (value.length === BOND_NUM_LENGTH) {
                if (suretyBondOverviewEnabled) {
                  updateBondOverview({ bond_number: value, showModal: true });
                } else {
                  openPopupWindow(Eportal.getBondInfoLink(value));
                }
              } else if (!suretyAccountOverviewEnabled) {
                openPopupWindow(Eportal.getAccountInfoLink(value));
              } else {
                history.push(`/surety/account/${value}`);
              }
            }}
          />
        </Form>

        <Anchor onClick={() => setShowModal(true)}>
          View Full Surety Account List
        </Anchor>
      </Panel>

      <Modal
        title="Account List"
        id="surety-account-list"
        body={
          <>
            {!accounts?.length && !accountsIsLoading ? (
              <Alert label="No accounts" type="warning">
                &nbsp;
              </Alert>
            ) : (
              <Table
                data={formatAccountsDropdownOptions(accounts || [])}
                rowKey="account_number"
                initialSort="account_name"
                columns={[
                  {
                    key: "account_name",
                    label: "Account Name",
                    sortable: true,
                    element: _ => (
                      <span className="break-word">{_.account_name}</span>
                    )
                  },
                  {
                    key: "account_number",
                    label: "Account",
                    sortable: true
                  },
                  {
                    key: "state",
                    label: "State",
                    element: _ => <span className="nowrap">{_.state}</span>,
                    sortable: true
                  },
                  {
                    key: "viewAccount",
                    label: "",
                    element: _ => (
                      <Anchor
                        target="_blank"
                        className="nowrap"
                        onClick={() => {
                          if (!suretyAccountOverviewEnabled) {
                            openPopupWindow(
                              Eportal.getAccountInfoLink(_.value)
                            );
                          } else {
                            history.push(`/surety/account/${_.value}`);
                          }
                        }}
                      >
                        View Account
                      </Anchor>
                    )
                  }
                ]}
                showPagination
                itemsPerPage={10}
              />
            )}
            {accountsIsLoading && (
              <LoadingIndicator message="Fetching accounts..." color="blue" />
            )}
          </>
        }
        footer={<Button onClick={() => setShowModal(false)}>Close</Button>}
        onHide={() => setShowModal(false)}
        show={showModal}
        size="lg"
        altCloseMethod={false}
      />
    </div>
  );
}
