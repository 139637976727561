import React, { useState, useCallback } from "react";
import EmployeesManage from "./EmployeesManage.js";
import OqModal from "../../shared/OqModal.js";
import { locationDisplayName } from "../../shared/util";

const EmployeeModalWizard = ({ show, onHide, location, mode }) => {
  const titles = [
    {
      step: 1,
      title: `Workers' Comp Classes for ${locationDisplayName(location)}`
    }
  ];

  const [currentStep] = useState(1);
  const [currentTitle] = useState(titles[0].title);

  const renderStepForm = useCallback(
    currentStep => {
      switch (currentStep) {
        case 1:
          return (
            <EmployeesManage
              location={location}
              onCancel={onHide}
              mode={mode}
            />
          );

        default:
          onHide(); //if you are here closes the dialog.
      }
    },
    [mode, location, onHide]
  );

  return (
    <>
      {show && (
        <OqModal
          className={`oq__modal__coverable__workcomp oq__form__workcomp__step${currentStep}`}
          title={currentTitle}
          show={show}
          onHide={onHide}
          body={<div>{renderStepForm(currentStep)}</div>}
          closeIcon={false}
        />
      )}
    </>
  );
};

export default EmployeeModalWizard;
