import { useState, useEffect } from "react";
import { useRootRouteMatch, useDepartmentRouteMatch } from "../hooks";

export const useDepartmentRouteValidation = (
  departmentData
) => {
  const [department, setDepartment] = useState(null);
  const yourUfgTeamRootRoute = useRootRouteMatch();
  const yourUfgTeamDepartmentRouteMatch = useDepartmentRouteMatch();

  useEffect(() => {
    const hasDepartments = departmentData && departmentData.length > 0;
    if (hasDepartments && yourUfgTeamRootRoute?.isExact) {
      setDepartment(departmentData[0]);
    }
  }, [
    yourUfgTeamRootRoute,
    departmentData,
    department
  ]);

  useEffect(() => {
    const hasDepartments = departmentData && departmentData.length > 0;
    if (hasDepartments && yourUfgTeamDepartmentRouteMatch?.isExact && yourUfgTeamDepartmentRouteMatch?.params?.departmentId) {
      const newDepartmentSelection = departmentData.find(dep => dep.id === yourUfgTeamDepartmentRouteMatch.params.departmentId);
      if (newDepartmentSelection && department?.id !== newDepartmentSelection.id) {
        setDepartment(newDepartmentSelection);
      }
    }
  }, [
    yourUfgTeamDepartmentRouteMatch,
    departmentData,
    department
  ]);

  return {
    department
  };
};
