import * as types from "../constants/actionTypes";
import * as policyActivityService from "../services/policyActivityService";
import { DocumentService } from "../services/documentService";

const fetchPolicyActivityListStarted = () => ({
  type: types.FETCH_POLICY_ACTIVITY_LIST_STARTED
});

const fetchPolicyActivityListSucceeded = data => ({
  type: types.FETCH_POLICY_ACTIVITY_LIST_SUCCEEDED,
  payload: data
});

const fetchPolicyActivityListFailed = error => ({
  type: types.FETCH_POLICY_ACTIVITY_LIST_FAILED,
  payload: error,
  error: true
});

const fetchPolicyActivityCancellationListStarted = () => ({
  type: types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_STARTED
});

const fetchPolicyActivityCancellationListSucceeded = data => ({
  type: types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_SUCCEEDED,
  payload: data
});

const fetchPolicyActivityCancellationListFailed = error => ({
  type: types.FETCH_POLICY_ACTIVITY_CANCELLATION_LIST_FAILED,
  payload: error,
  error: true
});

export function fetchPolicyActivityList(data) {
  return dispatch => {
    dispatch(fetchPolicyActivityListStarted());

    return policyActivityService.getActivityList(data).then(
      response => {
        dispatch(fetchPolicyActivityListSucceeded(response.data));
      },
      error => {
        dispatch(fetchPolicyActivityListFailed(error));
      }
    );
  };
}

export function fetchPolicyActivityCancellationList(data) {
  return dispatch => {
    dispatch(fetchPolicyActivityCancellationListStarted());

    return policyActivityService.getCancellationList(data).then(
      response => {
        return dispatch(
          fetchPolicyActivityCancellationListSucceeded(response.data)
        );
      },
      error => {
        return dispatch(fetchPolicyActivityCancellationListFailed(error));
      }
    );
  };
}

export const fetchActivityDocument = (
  document_id,
  policy_number,
  policy_type
) => () => {
  const documentService = new DocumentService();
  return policyActivityService
    .getActivityDocument(document_id, policy_number, policy_type)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${policy_number}-policy-activity.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};

export const fetchCancellationDocument = (
  policy_number,
  date,
  document_id
) => () => {
  const documentService = new DocumentService();
  return policyActivityService
    .getCancellationDocument(policy_number, date, document_id)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        `${policy_number}-cancellation-Memo.pdf`
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};
