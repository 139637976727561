export function getEmailListIsLoading(store) {
  return store.producerEmailList.isLoading;
}

export function getEmailList(store) {
  return store.producerEmailList.emailList;
}

export function getEmailListError(store) {
  return store.producerEmailList.error;
}
