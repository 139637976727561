import API from "../../../api";

export const agencyChecklistSubmit = data => {
  const resourcePath = "/agency-interface-checklist";
  const formData = new FormData();
  formData.append("json_body", JSON.stringify(data));

  return API.agent().post(resourcePath, formData);
};

export const getTableOfContents = () => {
  return API.agent().get(`/resources/agency-interface/table-of-contents`);
};

export const getContent = contentId => {
  return API.agent().get(
    `/resources/agency-interface/table-of-contents/${contentId}`
  );
};
