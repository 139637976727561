import { connect } from "react-redux";
import ClaimsNotes from "../components/ClaimsNotes";
import {
  fetchClaimsNotes,
  fetchClaimNoteDocument
} from "../actions/claimsActions";

const mapStateToProps = (state, props) => ({
  notes: state.claimNotes.notes[props.claimNumber] || [],
  loading: !!state.claimNotes.loading[props.claimNumber]
});

const mapDispatchToProps = {
  fetchClaimsNotes,
  fetchClaimNoteDocument
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsNotes);
