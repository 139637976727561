import qs from "query-string";
import API from "../api";

export const fetchClientNotices = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => {
  const params = {
    start_date: begin_date,
    end_date
  };

  if (company_branch) {
    params.company_branch = company_branch;
  }

  const queryString = qs.stringify(params);

  return API.agent().get(
    `agencies/${agency_code}/commercial/client-notices?${queryString}`
  );
};

export const fetchClientNoticeCommercialDocument = data => {
  // stringify will strip any undefined fields for us when it creates the query string
  const queryString = qs.stringify({
    policy_number: data.policy_number,
    effective_date: data.effective_date,
    image_right_process_key: data.image_right_process_key,
    job_number: data.job_number,
    process_date: data.processed_date,
    document_id: data.document_id
  });
  return API.agent().get(
    `/reports/client-notices/commercial/${data.status}?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const fetchClientNoticePersonalDocument = data => {
  // stringify will strip any undefined fields for us when it creates the query string
  const queryString = qs.stringify({
    policy_number: data.policy_number,
    effective_date: data.effective_date,
    image_right_process_key: data.image_right_process_key,
    job_number: data.job_number,
    process_date: data.processed_date
  });
  return API.agent().get(
    `/reports/client-notices/personal/${data.status}?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const removeClientNotice = (agency_code, clientNoticeItem) => {
  const queryString = qs.stringify({
    status: clientNoticeItem.status,
    policy_number: clientNoticeItem.policy_number,
    process_date: clientNoticeItem.processed_date,
    effective_date: clientNoticeItem.effective_date,
    image_right_process_key: clientNoticeItem.image_right_process_key,
    delete_type: "client-notice",
    origin: clientNoticeItem.source
  });
  return API.agent().delete(
    `/agencies/${agency_code}/commercial/decs-and-audit-bills?${queryString}`
  );
};
