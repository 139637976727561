import React, { useState, useEffect } from "react";
import {
  Panel,
  FormGroup,
  TextArea,
  DatePicker,
  Input,
  Dropdown
} from "@ufginsurance/ui-kit";
import { BidBond, BidBondPercent } from "../constants/suretyDictionary";
import { getTZFlatDate } from "../shared/util";

const ProjectInformation = ({
  form,
  showBidBond,
  showPerformancePayment,
  bondInfo,
  effDate,
  maintenancePeriodMin
}) => {
  const { values, updateForm, handleOnChange, handleOnBlur, handleOnValidate } =
    form;

  // Add day for rule: Project Completion > Bond Effective Date.
  const firstProjCompDate = getTZFlatDate(effDate, 1);

  const handleNumberValidate = field => {
    const fieldErrors = handleOnValidate(field);
    const hasDecimals = field.value.includes(".");

    if (Number(field.value) < maintenancePeriodMin || hasDecimals)
      fieldErrors.push(
        `This field must be a numeric and must be ${maintenancePeriodMin} or greater`
      );

    return fieldErrors;
  };

  const handleCompletionDateValidate = fieldObj => {
    const { value } = fieldObj;
    const fieldErrors = handleOnValidate(fieldObj);
    const effectiveDate = effDate;
    if (!!value && !!effectiveDate) {
      const f = new Date(value);
      const t = new Date(effectiveDate);
      if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) >= 0) {
        fieldErrors.push(
          "Project Completion date must be greater than the Bond Effective Date"
        );
      }
    }
    return fieldErrors;
  };

  const handleOnContractAmountValidate = field => {
    const fieldErrors = handleOnValidate(field);
    if (Number(field.value) === 0)
      fieldErrors.push("Contract Amount must be greater than zero");
    return fieldErrors;
  };

  const [showPercentBidBond, setShowPercentBidBond] = useState(true);
  const [showAmountBidBond, setShowAmountBidBond] = useState(false);

  const handleOnBidBondChange = Input => {
    if (Input.value === "Amount") {
      setShowAmountBidBond(true);
      setShowPercentBidBond(false);
      updateForm({
        values: {
          bid_bond_percent: ""
        }
      });
    } else {
      setShowAmountBidBond(false);
      setShowPercentBidBond(true);
      updateForm({
        values: {
          bid_bond_amount: ""
        }
      });
    }
    handleOnChange(Input);
  };

  const contractDateValidation = fieldObj => {
    const { value } = fieldObj;
    const errors = handleOnValidate(fieldObj);
    const completionDate = values.project_completion_date;

    if (!!value && !!completionDate) {
      const f = new Date(value);
      const t = new Date(completionDate);
      if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) < 0) {
        errors.push("Contract Date must be prior to Completion Date.");
      }
    }
    return errors;
  };

  const handleOnContractDateChange = ({ field, value }) => {
    if (!values.project_completion_date) {
      values.project_completion_date = value;
    }
    handleOnChange({ field, value });
  };

  const handleOnNumericFieldBlur = fieldObj => {
    const { field } = fieldObj;
    const parsedValue = parseFloat(values[field]);
    const newValue =
      parsedValue === 0 || isNaN(parsedValue)
        ? values[field]
        : parseFloat(values[field]).toFixed(2);

    values[field] = newValue;
    handleOnChange(field, newValue);
    handleOnBlur(fieldObj);
  };

  useEffect(() => {
    if (!!bondInfo?.bid_bond_amount) {
      setShowAmountBidBond(true);
      setShowPercentBidBond(false);
    } else {
      setShowAmountBidBond(false);
      setShowPercentBidBond(true);
    }
  }, [bondInfo]);

  return (
    <Panel
      rounded
      bgcolor="grey"
      title="Enter project information"
      titlebar
      className="project-information"
    >
      <FormGroup>
        <TextArea
          id="project_description"
          name="project_description"
          label="Project Description"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.project_description}
          maxLength={234}
          required
        />
      </FormGroup>
      <FormGroup>
        <DatePicker
          id="project_completion_date"
          name="project_completion_date"
          label="Project Completion Date"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleCompletionDateValidate}
          value={values.project_completion_date}
          required
          className="grow"
          minDate={firstProjCompDate}
          format="YYYY-MM-DD"
        />
        <Input
          id="maintenance_period"
          name="maintenance_period"
          label={`Maintenance Period (# of Months)`}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleNumberValidate}
          value={values.maintenance_period}
          required
          size="auto"
          numbersOnly
          maxLength={3}
        />
        <Input
          id="liquidated_damages"
          name="liquidated_damages"
          label="Liquidated Damages ($/per day)"
          onChange={handleOnChange}
          onBlur={handleOnNumericFieldBlur}
          onValidate={handleOnValidate}
          value={values.liquidated_damages}
          required
          size="auto"
          maxLength={13}
          numbersOnly
        />
      </FormGroup>
      {showBidBond && (
        <FormGroup>
          <DatePicker
            id="bid_date"
            name="bid_date"
            label="Bid Date"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.bid_date}
            required
            className="grow"
            maxDate={values.project_completion_date}
            format="YYYY-MM-DD"
          />
          <Input
            id="bid_estimate"
            name="bid_estimate"
            label="Bid Estimate"
            onChange={handleOnChange}
            onBlur={handleOnNumericFieldBlur}
            onValidate={handleOnValidate}
            value={values.bid_estimate}
            required
            numbersOnly
            maxLength={13}
          />
          <Dropdown
            id="bid_bond"
            name="bid_bond"
            label="Bid Bond % or Amount"
            onChange={handleOnBidBondChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.bid_bond}
            options={BidBond}
            required
            className=""
          />
          {showAmountBidBond && (
            <Input
              id="bid_bond_amount"
              name="bid_bond_amount"
              label="Bid Bond Amount"
              onChange={handleOnChange}
              onBlur={handleOnNumericFieldBlur}
              onValidate={handleOnValidate}
              value={values.bid_bond_amount}
              numbersOnly
              maxLength={13}
              required
            />
          )}
          {showPercentBidBond && (
            <Dropdown
              id="bid_bond_percent"
              name="bid_bond_percent"
              label="Bid Bond Percent"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.bid_bond_percent}
              options={BidBondPercent}
              required
            />
          )}
        </FormGroup>
      )}
      {showPerformancePayment && (
        <FormGroup>
          <DatePicker
            id="contract_date"
            name="contract_date"
            label="Contract Date"
            onChange={handleOnContractDateChange}
            onBlur={handleOnBlur}
            onValidate={contractDateValidation}
            value={values.contract_date}
            required
            className="grow"
            maxDate={values.project_completion_date}
            format="YYYY-MM-DD"
          />
          <Input
            id="contract_amount"
            name="contract_amount"
            label="Contract Amount"
            onChange={handleOnChange}
            onBlur={handleOnNumericFieldBlur}
            onValidate={handleOnContractAmountValidate}
            value={values.contract_amount}
            required
            numbersOnly
            maxLength={13}
          />
          <Input
            id="second_lowest_bidder"
            name="second_lowest_bidder"
            label="Second Lowest Bidder"
            onChange={handleOnChange}
            onBlur={handleOnNumericFieldBlur}
            onValidate={handleOnValidate}
            value={values.second_lowest_bidder}
            numbersOnly
            maxLength={13}
          />
        </FormGroup>
      )}
    </Panel>
  );
};
export default ProjectInformation;
