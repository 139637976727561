import React, { useContext } from "react";
import OnlineQuotingContext from "../OnlineQuotingContext";

const TotalPremiumDisplay = () => {
  const { quoteData } = useContext(OnlineQuotingContext);

  const quoteStatuses = ["Quoted", "Bound"];

  const totalPremium =
    quoteStatuses.includes(quoteData?.baseData?.periodStatus) &&
    !!quoteData?.quoteData?.offeredQuotes.length
      ? Number(
          quoteData?.quoteData?.offeredQuotes[0].premium?.total?.amount
        ).toLocaleString()
      : "";
  return (
    <div className="oq__premium">
      <h4>Total Premium</h4>
      <div className="oq__premium__amt">${totalPremium}</div>
    </div>
  );
};

export default TotalPremiumDisplay;
