import { connect } from "react-redux";
import ServiceCenterTab from "./ServiceCenterTab";
import { fetchCertificateHistory } from "../../../actions/homeServiceCenterAction";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";

const mapStateToProps = state => {
  return {
    ...state.homeServiceCenter,
    agencyCode: getSelectedAgencyCode(state)
  };
};

const mapDispatchToProps = {
  fetchCertificateHistory
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCenterTab);
