import { connect } from "react-redux";
import policyActivityForm from "./policyActivityForm";
import { fetchPolicyActivityList } from "./policyActivityActions";

const mapDispatchToProps = {
  fetchPolicyActivityList
};

export default connect(
  null,
  mapDispatchToProps
)(policyActivityForm);
