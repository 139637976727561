export const clientIDRequestInterceptor = (
  requestInterceptor,
  client_id,
  client_secret
) => {
  if (client_id !== undefined && client_secret !== undefined) {
    requestInterceptor.use(config => {
        config.headers.client_id = client_id;
        config.headers.client_secret = client_secret;
      return config;
    });
  }
};
