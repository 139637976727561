import React, { useEffect } from "react";
import {
  ContentHeader,
  Panel,
  Form,
  FormGroup,
  Switch,
  useForm,
  FlexRow,
  Button,
  Checkbox,
  Input
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import "../customer-info/CustomerInfo.scss";
import "./Eligibility.scss";
import PropTypes from "prop-types";
import BottomNav from "../shared/BottomNav";
import _equal from "lodash/isEqual";

export const eligibilityInitValues = {
  business_less_than_3years: false,
  bankruptcy: false,
  litigation: false,
  liens: false,
  claims_loss: false,
  surety: false,
  eligibility_questions: false,
  business_moreinfo: "",
  bankruptcy_moreinfo: "",
  litigation_moreinfo: "",
  liens_moreinfo: "",
  claims_loss_moreinfo: "",
  surety_moreinfo: ""
};

const Eligibility = ({
  history,
  suretyQuote,
  updateSuretyQuoteData,
  activeAgencyCode,
  eligibilityListData,
  fetchEligibility,
  cacheId
}) => {
  const handleFormSubmit = () => {
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      bond_information: {
        ...suretyQuote.bond_information,
        bondInfoPreventReload: true
      },
      eligibility: values
    }).then(() => history.push(routes.setStepUrl(4)));
  };

  const eligibilityInitValues = {
    business_less_than_3years: false,
    bankruptcy: false,
    litigation: false,
    liens: false,
    claims_loss: false,
    surety: false,
    eligibility_questions: false,
    business_moreinfo: "",
    bankruptcy_moreinfo: "",
    litigation_moreinfo: "",
    liens_moreinfo: "",
    claims_loss_moreinfo: "",
    surety_moreinfo: ""
  };

  const isExistingAccountHasEligibility =
    suretyQuote?.account_number !== "0000000000";

  const form = useForm({
    values: isExistingAccountHasEligibility
      ? {
          ...eligibilityInitValues,
          eligibility_questions: true
        }
      : eligibilityInitValues,
    onSubmit: handleFormSubmit
  });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  const handleSwitchToggle = (field, fieldToToggle) => {
    updateForm({
      values: {
        [field.field]: field.value,
        [fieldToToggle]: ""
      },
      errors: {
        [fieldToToggle]: []
      }
    });
  };

  useEffect(() => {
    if (!!suretyQuote) {
      fetchEligibility(activeAgencyCode, suretyQuote?.account_number);
    }
  }, [activeAgencyCode, fetchEligibility, suretyQuote]);

  useEffect(() => {
    if (!!suretyQuote?.eligibility) {
      const quoteDataValues = {
        ...suretyQuote.eligibility
      };

      if (!_equal(values, quoteDataValues))
        updateForm({
          values: quoteDataValues,
          errors: {}
        });
    } else if (
      !!eligibilityListData?.eligibilityData &&
      eligibilityListData?.eligibilityData.length
    ) {
      const newValues = {
        ...eligibilityListData?.eligibilityData
      };

      if (!_equal(values, newValues))
        updateForm({
          values: newValues,
          errors: {}
        });
    }
  }, [updateForm, eligibilityListData, suretyQuote?.eligibility, values]);

  const backToOwnerLink = () => {
    updateSuretyQuoteData(cacheId, {
      ...suretyQuote,
      eligibility: values
    });
    history.push(routes.setStepUrl(2));
  };

  // initialize these once
  const handleBusinessFieldSwitch = field =>
    handleSwitchToggle(field, "business_moreinfo");
  const handleBankruptcyFieldSwitch = field =>
    handleSwitchToggle(field, "bankruptcy_moreinfo");
  const handleLitigationFieldSwitch = field =>
    handleSwitchToggle(field, "litigation_moreinfo");
  const handleLiensFieldSwitch = field =>
    handleSwitchToggle(field, "liens_moreinfo");
  const handleClaimsLossFieldSwitch = field =>
    handleSwitchToggle(field, "claims_loss_moreinfo");
  const handleSuretyFieldSwitch = field =>
    handleSwitchToggle(field, "surety_moreinfo");

  return (
    <div>
      <ContentHeader>Ready to check eligibility?</ContentHeader>
      <Form className="qq__form__step3" context={form}>
        <Panel
          rounded
          bgcolor="grey"
          title="Confirm eligibility"
          titlebar
          className="qq__panel"
        >
          <FormGroup>
            <Switch
              id="business_less_than_3years"
              name="business_less_than_3years"
              label="Has the business or any owner been in business or under current management for less than 3 years?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.business_less_than_3years}
              className="grow"
              size={values.business_less_than_3years ? "lg" : "full"}
              onChange={handleBusinessFieldSwitch}
              noLabel
            />
            {!!values?.business_less_than_3years && (
              <Input
                id="business_moreinfo"
                name="business_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.business_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>
          <FormGroup>
            <Switch
              id="bankruptcy"
              name="bankruptcy"
              label="Has the business or any owner ever filed for bankruptcy?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.bankruptcy}
              className="grow"
              size={values.bankruptcy ? "lg" : "full"}
              onChange={handleBankruptcyFieldSwitch}
              noLabel
            />
            {values.bankruptcy && (
              <Input
                id="bankruptcy_moreinfo"
                name="bankruptcy_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.bankruptcy_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>

          <FormGroup>
            <Switch
              id="litigation"
              name="litigation"
              label="Has the business or any owner been involved in any current or pending litigation?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.litigation}
              className="grow"
              size={values.litigation ? "lg" : "full"}
              onChange={handleLitigationFieldSwitch}
              noLabel
            />
            {values.litigation && (
              <Input
                id="litigation_moreinfo"
                name="litigation_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.litigation_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>
          <FormGroup>
            <Switch
              id="liens"
              name="liens"
              label="Has the business or any owner had any liens filed against them or been delinquent on any federal or state taxes in the last 5 years?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.liens}
              className="grow"
              size={values.liens ? "lg" : "full"}
              onChange={handleLiensFieldSwitch}
              noLabel
            />
            {values.liens && (
              <Input
                id="liens_moreinfo"
                name="liens_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.liens_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>
          <FormGroup>
            <Switch
              id="claims_loss"
              name="claims_loss"
              label="Has the business or any owner failed to complete a contract or been involved in a surety claim?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.claims_loss}
              className="grow"
              size={values.claims_loss ? "lg" : "full"}
              onChange={handleClaimsLossFieldSwitch}
              noLabel
            />
            {values.claims_loss && (
              <Input
                id="claims_loss_moreinfo"
                name="claims_loss_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.claims_loss_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>
          <FormGroup>
            <Switch
              id="surety"
              name="surety"
              label="Has the business or any owner been denied by or have open bonds with another surety in the past 12 months?"
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.surety}
              className="grow"
              size={values.surety ? "lg" : "full"}
              onChange={handleSuretyFieldSwitch}
              noLabel
            />
            {values.surety && (
              <Input
                id="surety_moreinfo"
                name="surety_moreinfo"
                label="Please provide more information"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.surety_moreinfo}
                className="grow"
              />
            )}
          </FormGroup>
        </Panel>
        <BottomNav leftNav>
          <FlexRow>
            <Button labelSpace isLink inline onClick={backToOwnerLink}>
              Previous
            </Button>
            <FormGroup className="align-right">
              <Checkbox
                id="eligibility_questions"
                name="eligibility_questions"
                label="I have reviewed and accurately answered the eligibility questions above."
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.eligibility_questions}
                size="lg"
                className="checkbox__eligbility-questions"
                required
                requiredError="Required"
              />
              <Button
                labelSpace
                variant="primary"
                type="submit"
                disabled={
                  !(
                    Object.keys(errors).length === 0 &&
                    invalidFields.length === 0
                  )
                }
              >
                Continue to Bond Information
              </Button>
            </FormGroup>
          </FlexRow>
        </BottomNav>
      </Form>
    </div>
  );
};
export default Eligibility;

Eligibility.propTypes = {
  activeAgencyCode: PropTypes.string.isRequired,
  history: PropTypes.any,
  fetchEligibility: PropTypes.func,
  eligibilityListData: PropTypes.any,
  suretyQuote: PropTypes.object.isRequired,
  updateSuretyQuoteData: PropTypes.func.isRequired,
  cacheId: PropTypes.string.isRequired
};
