import * as quickQuotingServices from "../../quickQuotingServices";
import * as types from "../../quickQuotingActionTypes";
import { DocumentService } from "../../../../services/documentService";

const fetchDocumentsPacketStarted = () => ({
  type: types.FETCH_DOCUMENTS_PACKET_STARTED
});

const fetchDocumentsPacketSucceeded = () => ({
  type: types.FETCH_DOCUMENTS_PACKET_SUCCEEDED
});

const fetchDocumentsPacketFailed = error => ({
  type: types.FETCH_DOCUMENTS_PACKET_FAILED,
  payload: error,
  error: true
});

export const clearDocumentsPacketError = () => ({
  type: types.CLEAR_DOCUMENTS_PACKET_ERROR,
  error: false
});

export const fetchDocumentsPacket = packetDetails => dispatch => {
  dispatch(fetchDocumentsPacketStarted());
  const documentService = new DocumentService();
  return quickQuotingServices
    .getDocumentsPacket(packetDetails)
    .then(
      response => {
        dispatch(fetchDocumentsPacketSucceeded());
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          "documents-packet.pdf"
        );
      },
      error => {
        dispatch(fetchDocumentsPacketFailed(error));
        documentService.clearRenderer();
      }
    )
    .catch(e => {
      dispatch(fetchDocumentsPacketFailed(e));
      documentService.clearRenderer();
    });
};
