import { connect } from "react-redux";
import { withRouter } from "react-router";
import Navigation from "./Navigation";
import { activateUmailCard } from "../../umail/umailAction";
import { activateHelpDeskCard } from "./../../help-desk/helpDeskAction";
import { activateMakeAPayment } from "../../top-action-cards/topActionCardAction";
import { startReportAClaim } from "../../top-action-cards/report-claim/actions/reportClaimActions";
import { activateClaimsInquiryCard } from "../../claims/actions/claimsInquiryActions";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

const mapDispatchToProps = {
  activateUmailCard,
  activateMakeAPayment,
  startReportAClaim,
  activateHelpDeskCard,
  activateClaimsInquiryCard
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
