import React, { useState, useContext, useEffect } from "react";
import * as api from "../../services/onlineQuotingService";
import { Button, FlexRow, Table } from "@ufginsurance/ui-kit";
import { vehicleTypes } from "../shared/constants";
import {
  getAllVehicles,
  IsAtleastOneVINMissing
} from "../step5/vehicle/getAllVehicles";
import { toCommaNumber, stringToCurrency } from "../shared/util";
import OQstrings from "../shared/strings";
import OnlineQuotingContext from "../OnlineQuotingContext";
import OqModal from "../shared/OqModal.js";

import "./ValidateVehicles.scss";

const ValidateVehicles = () => {
  const {
    quoteData,
    updateQuote,
    toastErrr,
    showUpdatingToast,
    closeUpdatingToast,
    supportingData
  } = useContext(OnlineQuotingContext);

  const [allVehicles, setAllVehicles] = useState(getAllVehicles({ quoteData }));
  const [hideValidateVehicles, setHideValidateVehicles] = useState(false);
  const [fieldErrors, setfieldErrors] = useState({});
  const [showVehicleTable, setShowVehicleTable] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (allVehicles) {
      const newValues = {};
      allVehicles.forEach(v => {
        if (v?.fixedId) newValues[v.fixedId] = v?.vin || "";
      });
      setValues(newValues);
      setfieldErrors({});
    }
  }, [allVehicles, showVehicleTable]);

  useEffect(() => {
    let allVehicles = getAllVehicles({ quoteData });
    allVehicles = allVehicles.filter(
      x => !(supportingData?.trailerIDs || []).includes(x.fixedId)
    );
    setAllVehicles(allVehicles);
    setHideValidateVehicles(
      !IsAtleastOneVINMissing({ quoteData, supportingData })
    );
  }, [quoteData, supportingData]);

  const getVehicleType = vehicle => {
    const vehicleType = (vehicle?.entityName || "").replace("entity.", "");
    return vehicleTypes.find(t => t.key === vehicleType)?.label;
  };

  const handleVinValidate = ({ field, value }) => {
    const regex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/;
    if (!!value && !regex.test(value)) {
      setfieldErrors({
        ...fieldErrors,
        [field]: OQstrings.error.vehicle_invalid_vin
      });
    } else {
      setfieldErrors({
        ...fieldErrors,
        [field]: ""
      });
    }
  };

  const saveNewVINs = () => {
    let vehiclesData = [];
    allVehicles.forEach(vehicle => {
      vehiclesData = [
        ...vehiclesData,
        {
          fixedId: vehicle.fixedId,
          vin: values[vehicle.fixedId] || vehicle.vin
        }
      ];
    });
    const payload = {
      sessionUUID: quoteData.sessionUUID,
      quoteId: quoteData.quoteID,
      vehiclesData
    };

    const message = "Updating vehicle VIN...";
    showUpdatingToast({ message });
    api
      .patchQuote(quoteData.quoteID, payload)
      .then(results => {
        updateQuote({
          newData: results?.data?.dto,
          newSupportingData: results?.data?.supportingData,
          mergeData: false,
          sendToPC: false
        });

        closeUpdatingToast({ message });
        setShowVehicleTable(false);
      })
      .catch(error => {
        toastErrr({
          action: "saveNewVINs",
          description: "failed to update vehicle",
          error,
          payload,
          displayMessage: "Failed to update vehicles."
        });

        closeUpdatingToast({ message });
      });
  };

  const showVinInput = vehicle => {
    return (
      <>
        <input
          type="text"
          id={vehicle.fixedId}
          name={vehicle.fixedId}
          maxLength={17}
          onChange={event => {
            setValues({ ...values, [vehicle.fixedId]: event.target.value });
          }}
          onBlur={event => {
            const vin = event.target.value;
            handleVinValidate({ field: vehicle.fixedId, value: vin });
          }}
          value={values[vehicle.fixedId]}
          required
        />
        {fieldErrors[vehicle.fixedId] && (
          <div className="oq__error-text">{fieldErrors[vehicle.fixedId]}</div>
        )}
      </>
    );
  };

  const _tableColumns = [
    { label: "VIN", key: "", element: row => showVinInput(row) },
    { label: "Year", key: "year" },
    { label: "Make", key: "make" },
    { label: "Model", key: "model" },

    { label: "Vehicle Type", key: "", element: row => getVehicleType(row) },
    {
      label: "Original Cost New",
      key: "originalCostNew",
      element: row => stringToCurrency(row?.originalCostNew, 0)
    },
    {
      label: "Gross Vehicle Weight",
      element: row => ` ${toCommaNumber(row?.grossVehicleWeight || 0, 0)} lbs`
    }
  ];

  if (hideValidateVehicles) return null;

  return (
    <>
      {showVehicleTable && (
        <OqModal
          className="oq-modal__premium-summary__bind-issue-modal"
          title="Please include the VIN for the following vehicles"
          show
          closeIcon={false}
          onHide={() => setShowVehicleTable(false)}
          body={
            <>
              <FlexRow>
                <Table
                  className="oq__premium-summary__lob-premiums-table"
                  rowKey="fixedId"
                  columns={_tableColumns}
                  data={allVehicles}
                  noResultsMessage="No results found"
                />
              </FlexRow>
              <FlexRow align="right">
                <Button
                  isLink
                  onClick={() => {
                    setShowVehicleTable(false);
                    setValues({});
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    saveNewVINs();
                  }}
                >
                  Continue
                </Button>
              </FlexRow>
            </>
          }
        />
      )}
      {allVehicles.length > 0 && (
        <div className="oq__step6__vin-input">
          <h4>Missing Vehicle Identification Numbers</h4>
          <FlexRow>
            <p className="oq__step6__vin-input_error-text">
              Please review vehicles to include missing VINs.
            </p>
            <Button
              wrapperClassName="align-right"
              variant="primary"
              onClick={() => {
                setShowVehicleTable(true);
              }}
            >
              Review Vehicles
            </Button>
          </FlexRow>
        </div>
      )}
    </>
  );
};

export default ValidateVehicles;
