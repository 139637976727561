import qs from "query-string";
import API from "../../../api";

export const getConsentBonds = (agency_code, date_effective) => {
  return API.surety().get(
    `/bonds?agency_code=${agency_code}&date_effective=${date_effective}`
  );
};

export const getBidRequestHistory = agency => {
  return API.surety().get(`/bond-request?agency_code=${agency}`);
};

export const getConvertedBonds = (agency, sort_opt) => {
  return API.surety().get(
    `/final-bonds?agency_code=${agency}&sort_option=${sort_opt}`
  );
};

export const getBillingsByBond = (agency, bondNo) => {
  return API.surety().get(
    `/billings-by-bond?agency_code=${agency}&bond_number=${bondNo}`
  );
};

export const getBillingsByDate = (agency, begDt, endDt, sort_opt) => {
  return API.surety().get(
    `/billings-by-date?agency_code=${agency}&date_effective=${begDt}&date_end=${endDt}&sort_option=${sort_opt}`
  );
};

export const getCommercialQuotes = agency => {
  return API.surety().get(`/commercial-quoted?agency_code=${agency}`);
};

export const getUploadHistory = (agency, date_effective) => {
  return API.surety().get(
    `/license-and-permit?agency_code=${agency}&date_effective=${date_effective}`
  );
};

export const getIssueContractUploadHistory = (agency, date_effective) => {
  return API.surety().get(
    `/bonds-history?agency_code=${agency}&date_effective=${date_effective}`
  );
};

export const getAccounts = (agencyCode, accountNumber) => {
  const query = qs.stringify({
    agency_code: agencyCode,
    account_number: accountNumber
  });

  return API.surety().get(`/accounts?${query}`);
};

export const getExecutedBonds = agency => {
  return API.surety().get(`/executed-bonds?agency_code=${agency}`);
};

export const putSuretyOtherChange = (bondNumber, payload) => {
  return API.surety().put(`/bond/${bondNumber}/other-change`, payload);
};
