import * as api from "../../../services/onlineQuotingService";
import { allowedPrivatePassengerClasses } from "../../shared/constants";

export const getSizeClassByWeight = weight => {
  if (weight <= 10000) return "Light Truck";
  if (weight >= 10001 && weight <= 20000) return "Medium Truck";
  if (weight >= 20001 && weight <= 45000) return "Heavy Truck";
  if (weight >= 45001) return "Extra-Heavy Truck";
};

const secondaryClassCodeLookup = {
  Distributor: 39,
  Contractor: 83,
  Service: 83,
  Habitational: 99,
  Retail: 99,
  Hotel: 99,
  "Office Condo": 99,
  Office: 99,
  "Restaurant-Casual Dining": 99,
  "Restaurant-Fast Food": 99,
  "Restaurant-Fine Dining": 99,
  "Restaurant-Limited Cooking": 99,
  "Self-Storage": 99
};

const businessUseLookup = {
  39: "Commercial",
  83: "Service",
  99: "Retail"
};

export const getVehicleClassData = async ({ vehicle, supportingData }) => {
  return new Promise((resolve, reject) => {
    api
      .getVehicleClassCodes(vehicle?.vehicleType)
      .then(result => {
        const allClasses = (result?.data || []).map(c => ({
          ...c,
          businessUseClass: c.businessUse,
          secondaryClass: c.description
        }));

        const secondaryCode =
          secondaryClassCodeLookup[supportingData.classCode.businessType_UFG];

        let businessUse = businessUseLookup[secondaryCode];

        let foundClass;
        // let possibleMatches;

        /**
         * type: PRIVATE PASSENGER
         * only gets a few classes restricted by "allowedPrivatePassengerClasses"
         * doesn't get auto-assigned a class... just return
         */
        if (vehicle.vehicleType === "CA7PrivatePassenger") {
          // OOQ-8462 - private passenger vehicles only get a few classes

          const classData = allClasses.filter(c =>
            allowedPrivatePassengerClasses.includes(c.classCode)
          );

          resolve({
            success: true,
            classData,
            foundClass: null,
            error: null
          });
        } else if (vehicle.actualVehicleType === "CA7Trailer") {
          /**
           * type: TRAILER
           * note: trailer type vehicles use Trailer sizeClass
           */
          const classData = allClasses.filter(
            c =>
              c.sizeClass === vehicle.sizeClass &&
              c.radiusClass === vehicle.radiusClass
          );

          foundClass = classData.find(
            c =>
              c.fleet === "No" && Number(c.secondaryClassCode) === secondaryCode
          );
          // possibleMatches = classData.filter(
          //   c =>
          //     c.fleet === "No" && Number(c.secondaryClassCode) === secondaryCode
          // );

          resolve({ success: true, classData, foundClass, error: null });
        } else {
          /**
           * type: COMMERCIAL = TRUCK
           */
          const vehicleSizeClass =
            vehicle.sizeClass ||
            getSizeClassByWeight(
              vehicle.grossVehicleWeight,
              vehicle.grossCombinationWeight
            );

          const classData = allClasses.filter(
            c =>
              c.sizeClass === vehicleSizeClass &&
              c.radiusClass === vehicle.radiusClass
          );

          /**
           * All Heavy Truck-Tractor and Extra-Heavy Truck-Tractor vehicle classes
           * have the Business Use "All Uses"
           */
          if (
            vehicleSizeClass.includes("Extra-Heavy Truck-Tractor") ||
            vehicleSizeClass.includes("Extra-Heavy Truck")
          )
            businessUse = "All Uses";

          foundClass = classData.find(
            c =>
              c.fleet === "No" &&
              Number(c.secondaryClassCode) === secondaryCode &&
              c.businessUse === businessUse
          );
          // possibleMatches = classData.filter(
          //   c =>
          //     c.fleet === "No" &&
          //     Number(c.secondaryClassCode) === secondaryCode &&
          //     c.businessUse === businessUse
          // );

          // console.log({
          //   vehicleSizeClass,
          //   radiusClass: vehicle.radiusClass,
          //   classData,
          //   secondaryCode,
          //   businessUse,
          //   foundClass,
          //   possibleMatches
          // });

          resolve({
            success: true,
            classData: classData.length > 0 ? classData : allClasses, // Truck-Tractor types don't match classes for Long Distance radius, so the class list is empty, so just send all classes
            foundClass,
            error: null
          });
        }
      })
      .catch(({ error }) => reject({ success: false, error }));
  });
};
