import AuditBreakdownModal from "../components/AuditBreakdownModal";
import { connect } from "react-redux";
import { fetchAuditBreakdown } from "../actions/auditBreakdownActions";

const mapStateToProps = state => ({
  audits: state.auditBreakdown.audits,
  isLoading: state.auditBreakdown.isLoading
});

const mapDispatchToProps = {
  fetchAuditBreakdown
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditBreakdownModal);
