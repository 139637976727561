import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Form, Panel, Switch } from "@ufginsurance/ui-kit";
import { useSuretyContext } from "../../surety-context/SuretyContext";

export default function CosDevTools() {
  const { suretyDevToolsEnabled } = useFlags();
  const { cosDevToolsForm } = useSuretyContext();

  if (!suretyDevToolsEnabled) return null;

  const { values, handleOnBlur, handleOnChange, handleOnValidate } =
    cosDevToolsForm;

  return (
    <Panel title="Consent of Surety Dev Tools">
      <Form context={cosDevToolsForm}>
        <Switch
          id="submissionOverride"
          name="submissionOverride"
          label="Override Submission Blockers?"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.submissionOverride}
        />
      </Form>
    </Panel>
  );
}
