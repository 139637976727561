import React, { useState } from "react";
import PropTypes from "prop-types";
import { Anchor, LoadingIndicator, Modal, Table } from "@ufginsurance/ui-kit";
import { printPdf } from "../../shared/documents/helpers";
import * as dictionary from "../../constants/dictionary";
import * as routes from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const propTypes = {
  forms: PropTypes.array
};

const FormLinks = ({ forms }) => {
  const [showModal, setShowModal] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState([]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPrintStart = form_number => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      form_number
    )
      ? [...documentsLoading, form_number]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = form_number => {
    setDocumentsLoading(documentsLoading.filter(x => x !== form_number));
  };

  const onPrintError = () => {
    setShowModal(true);
  };

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const columns = [
    {
      key: "name",
      label: "Form Number",
      align: "left",
      element: row => {
        const requestInProgress =
          row.name && documentsLoading.includes(row.name);

        const form_number = `${row.name ?? ""}`;

        return (
          <div className="class-appetite-guide-form-name__wrapper">
            {requestInProgress && (
              <span className="class-appetite-guide-spinner">
                <LoadingIndicator message="" type="spinner" />
              </span>
            )}

            <Anchor
              href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${
                dictionary.DOCUMENT_FORM_TYPES.COVERAGE
              }/${row.name ?? ""}`}
              className="text-capitalize form-number"
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => {
                e.preventDefault();

                printPdf({
                  form_number,
                  form_type: dictionary.DOCUMENT_FORM_TYPES.COVERAGE,
                  onStart: () => {
                    onPrintStart(form_number);
                  },
                  onComplete: () => {
                    onPrintComplete(form_number);
                  },
                  onError: onPrintError
                });
              }}
            >
              {row.name}
            </Anchor>
          </div>
        );
      }
    },
    {
      key: "description",
      label: "Form Description",
      align: "left"
    }
  ];

  return (
    <div className="form-links">
      <h3 className="blackText">Suggested Forms</h3>
      <Table
        data={forms}
        columns={columns}
        compact
        showPagination
        className="classAppetiteFormsTable"
        rowKey="name"
      />

      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </div>
  );
};

FormLinks.propTypes = propTypes;
export default FormLinks;
