import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";

/*--------BOND OBLIGEE SECTION---------- */
const fetchBondObligeeServiceStarted = () => ({
  type: types.FETCH_BOND_OBLIGEE_STARTED
});

const fetchBondObligeeServiceSucceeded = data => ({
  type: types.FETCH_BOND_OBLIGEE_SUCCEEDED,
  payload: data
});

const fetchBondObligeeServiceFailed = error => ({
  type: types.FETCH_BOND_OBLIGEE_FAILED,
  payload: error
});

export const setObligeeSearchIsLoading = data => ({
  type: types.SET_BOND_OBLIGEE_LOADING,
  payload: data
})

export const fetchBondObligeeList = account_name => dispatch => {
  dispatch(fetchBondObligeeServiceStarted());
  return quickQuotingServices.getBondObligeeList(account_name).then(
    response => {
      dispatch(fetchBondObligeeServiceSucceeded(response.data));
    },
    error => {
      dispatch(fetchBondObligeeServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
