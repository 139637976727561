import * as types from "../suretyTabActionTypes";
import initialState from "../../../../reducers/initialState";

const billingCopiesListReducer = (
  state = initialState.billingCopiesList,
  action
) => {
  switch (action.type) {
    case types.FETCH_BILLING_COPIES_DATE_STARTED:
      return {
        ...state,
        isLoading: true,
        customMsg: "",
        error: null
      };
    case types.FETCH_BILLING_COPIES_DATE_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        customMsg:
          !!action.payload && !action.payload.length
            ? "There are no bond transaction reports for this date range for your agency."
            : ""
      };
    case types.FETCH_BILLING_COPIES_DATE_FAILED:
      return {
        ...state,
        data: [],
        error: action.payload,
        isLoading: false,
        customMsg:
          "There are no bond transaction reports for this date range for your agency."
      };

    case types.FETCH_BILLING_COPIES_BOND_STARTED:
      return {
        ...state,
        isLoading: true,
        customMsg: "",
        error: null
      };
    case types.FETCH_BILLING_COPIES_BOND_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        customMsg:
          !!action.payload && !action.payload.length
            ? "There are no bond transaction reports for this policy number."
            : ""
      };
    case types.FETCH_BILLING_COPIES_BOND_FAILED:
      return {
        ...state,
        data: [],
        error: action.payload,
        isLoading: false,
        customMsg:
          "There are no bond transaction reports for this policy number."
      };
    default:
      return state;
  }
};

export default billingCopiesListReducer;
