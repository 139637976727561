import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DateFormat, toTitleCase } from "../../Factory";
import { Translations } from "../../translations";
import { LoadingIndicator, Table, ExternalLink } from "@ufginsurance/ui-kit";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
const SubmittedLosses = ({
  fetchClaimSubmittedLosses,
  selectedAgencyCode,
  pastFetchedSubmittedAgencyCode,
  SubmittedLossesList,
  isLoading,
  error
}) => {
  useEffect(() => {
    if (pastFetchedSubmittedAgencyCode !== selectedAgencyCode) {
      fetchClaimSubmittedLosses(selectedAgencyCode);
    }
  }, [
    fetchClaimSubmittedLosses,
    pastFetchedSubmittedAgencyCode,
    selectedAgencyCode
  ]);

  const createColumns = () => [
    {
      key: "submitted_date",
      label: Translations.home_claims_Tab.table_labels.submitted_date,
      element: row => {
        return DateFormat(row.submitted_date, "MM/DD/YYYY");
      },
      sortable: true
    },
    {
      key: "insurance_name",
      label: Translations.home_claims_Tab.table_labels.name,
      element: row => {
        return toTitleCase(row.insurance_name);
      }
    },
    {
      key: "policy_number",
      label: Translations.home_claims_Tab.table_labels.policy_number,
      element: row => {
        const policyNumber = (row.policy_prefix || "") + row.policy_number;
        const linkToFnol = `${baseLink}/AgentsOnly/ufgAgent/Claims/FNOLTransfer.aspx?wcnum=${row.control_number}`;
        return (
          <ExternalLink
            to={linkToFnol}
            rel="noopener noreferrer"
            target="_blank"
          >
            {policyNumber}
          </ExternalLink>
        );
      }
    },
    {
      key: "loss_date",
      label: Translations.home_claims_Tab.table_labels.loss_date,
      element: row => {
        return DateFormat(row.loss_date, "MM/DD/YYYY");
      }
    },
    {
      key: "policy_type",
      label: Translations.home_claims_Tab.table_labels.type
    },
    {
      key: "submitted_by",
      label: Translations.home_claims_Tab.table_labels.submitted_by,
      element: row => {
        return toTitleCase(row.submitted_by);
      }
    }
  ];

  const columns = createColumns();
  const hasSubmittedLosses =
    SubmittedLossesList && SubmittedLossesList.length > 0;
  return (
    <div className="submitted-losses-table">
      {isLoading ? (
        <LoadingIndicator />
      ) : error || !hasSubmittedLosses ? (
        <div className="friendly-error-messaging">
          {Translations.home_claims_Tab.submitted_losses_error}
        </div>
      ) : (
        <Table
          rowKey="id"
          hover
          bordered={false}
          data={SubmittedLossesList}
          columns={columns}
          itemsPerPage={20}
          initialDirection="desc"
          initialSort="submitted_date"
          showPagination
        />
      )}
    </div>
  );
};

SubmittedLosses.propTypes = {
  fetchClaimSubmittedLosses: PropTypes.func.isRequired,
  SubmittedLossesList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  selectedAgencyCode: PropTypes.string,
  pastFetchedSubmittedAgencyCode: PropTypes.string
};

export default SubmittedLosses;
