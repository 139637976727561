import React, { useContext, useEffect } from "react";
import {
  Form,
  useForm,
  FormGroup,
  DatePicker,
  formatDate
} from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../../OnlineQuotingContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMinMax } from "./getMinMax";

/**
 * this form is used in the quoting system step 6 only.
 */

const EffectiveDateStep6 = ({
  initialValues,
  quoteId,
  formIsValid,
  setFormIsValid,
  triggerFormValidation
}) => {
  const {
    patchQuotePromise,
    toastErrr,
    supportingData,
    effectiveDateOverride
  } = useContext(OnlineQuotingContext);

  const { proquoteMaxEffectiveDateDays } = useFlags();

  // get OG effective date, if possible (only avail in SupportingData after Feb)
  // if not avail, then fall back to the current effective date in the quote
  const ogEffectiveDate =
    supportingData?.effectiveDateOriginal || supportingData?.effectiveDate;
  const originalEffectiveDate = new Date(
    ogEffectiveDate.year,
    ogEffectiveDate.month,
    ogEffectiveDate.day
  );

  const hasAuto = (supportingData?.policyLines || []).some(
    l => l === "CA7CommAutoLine" || l === "ca7CommAuto"
  );

  /**
   * SET THE MIN AND MAX VALUES
   */
  const { minDate, maxDate } = getMinMax({
    effectiveDateOverride,
    proquoteMaxEffectiveDateDays,
    hasAuto,
    originalEffectiveDate
  });

  const onSubmit = ({ value, errors }) => {
    // only save if the value changed
    if (
      (errors?.error || []).length > 0 ||
      !value ||
      value === initialValues.effectiveDate
    )
      return;

    const payload = { effectiveDate: formatDate(value, "OBJECT") };
    patchQuotePromise({
      newData: payload,
      quoteId,
      updateMessage: "Updating Effective Date"
    }).catch(({ error }) =>
      toastErrr({
        error,
        payload,
        action: "EffectiveDateModal > onSubmit",
        description: "unable to save effective date"
      })
    );
  };

  const form = useForm({ values: initialValues, onSubmit });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    validateForm
  } = form;

  useEffect(() => {
    const isValid = !invalidFields.length;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [formIsValid, setFormIsValid, invalidFields]);

  useEffect(() => {
    if (triggerFormValidation) validateForm();
  }, [triggerFormValidation, validateForm]);

  return (
    <>
      <Form context={form} className="oq__step6__effective-date">
        <FormGroup>
          <DatePicker
            id="effectiveDate"
            name="effectiveDate"
            label="Effective Date"
            onChange={handleOnChange}
            onBlur={({ field, value }) =>
              handleOnBlur({
                field,
                value,
                callback: errors => onSubmit({ field, value, errors })
              })
            }
            onValidate={handleOnValidate}
            value={values.effectiveDate}
            format="MM/DD/YYYY"
            required
            minDate={minDate}
            maxDate={maxDate}
          />
        </FormGroup>
      </Form>
    </>
  );
};

export default EffectiveDateStep6;
