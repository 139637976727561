import React, { useEffect } from "react";
import "./CoSSideBar.scss";
import { Panel, LoadingIndicator, Anchor } from "@ufginsurance/ui-kit";
import { useSuretyContext } from "../../surety-context/SuretyContext";
import { getAgencyInfo } from "../../../services/agencyService";
import { toTitleCase } from "../../../components/Factory";
import { useFlags } from "launchdarkly-react-client-sdk";
import { openPopupWindow } from "../../shared/utils";
import { Eportal } from "../../shared/eportal";
import CosDevTools from "../components/CosDevTools";
import { getSuretyAccount } from "../../services";

const CoSSideBar = ({
  step,
  form: { values },
  history,
  underwriter,
  setUnderwriter,
  setCstEmail
}) => {
  const { agency } = useSuretyContext();

  const { suretyAccountOverviewEnabled, suretyBondOverviewEnabled } =
    useFlags();
  const { updateBondOverview } = useSuretyContext();

  useEffect(() => {
    Promise.allSettled([
      getAgencyInfo(agency?.agencyCode),
      getSuretyAccount(agency?.agencyCode, values?.account_number)
    ]).then(results => {
      setUnderwriter(
        results[1].value?.data?.surety_underwriter ||
          results[0].value?.data?.ufgteam?.surety_underwriter
      );
      setCstEmail(
        results[1].value?.data?.account_cst_email ||
          results[0].value?.data?.ufgteam?.surety_cst?.email ||
          ""
      );
    });
  }, [agency.agencyCode, values.account_number, setCstEmail, setUnderwriter]);

  return (
    <>
      <div className="cos__welcome-panel">
        {step === 1 && (
          <img
            src={window.env.REACT_APP_SURETY_CONSENT_OF_SURETY_WELCOME}
            className="ufg_quick_welcome"
            alt="Welcome to ufg Consent of Surety"
          />
        )}
        {(step === 2 || step === 3) && (
          <Panel highlight="red" title="Summary">
            <div>
              <p>
                <span className="bold">Customer Information:</span> <br />
                {step === 2 ? (
                  toTitleCase(values.account_name)
                ) : (
                  <Anchor
                    target="_blank"
                    className="nowrap"
                    onClick={() => {
                      if (!suretyAccountOverviewEnabled) {
                        openPopupWindow(
                          Eportal.getAccountInfoLink(values?.account_number)
                        );
                      } else {
                        history.push(
                          `/surety/account/${values?.account_number}`
                        );
                      }
                    }}
                  >
                    {values?.account_name}
                  </Anchor>
                )}
                <br />
                {toTitleCase(values.address_line)} <br />
                {toTitleCase(values.city)}, {values.state} {values.zip}
              </p>
            </div>
            <div>
              <p>
                <span className="bold">Bond No:</span> <br />
                {step === 2 ? (
                  values.accountBondSearch
                ) : (
                  <Anchor
                    target="_blank"
                    className="nowrap"
                    onClick={() => {
                      if (suretyBondOverviewEnabled) {
                        updateBondOverview({
                          bond_number: values?.accountBondSearch,
                          showModal: true
                        });
                      } else {
                        openPopupWindow(
                          Eportal.getBondInfoLink(values?.accountBondSearch)
                        );
                      }
                    }}
                  >
                    {values?.accountBondSearch}
                  </Anchor>
                )}
              </p>
            </div>
          </Panel>
        )}

        <Panel id="underwriter-info" highlight="red" title="Your Underwriter">
          {!underwriter ? (
            <LoadingIndicator />
          ) : (
            <div className="underwriter">
              <p>
                <span className="bold">
                  {underwriter?.first_name} {underwriter?.last_name}
                </span>
              </p>
              <div className="underwriter-contact-info">
                {(underwriter?.phone_number || underwriter?.phone) && (
                  <p>
                    <span className="bold">Phone:</span>{" "}
                    {underwriter?.phone_number || underwriter?.phone}
                  </p>
                )}
                {(underwriter?.toll_free_number || underwriter?.toll_free) && (
                  <p>
                    <span className="bold">Toll free:</span>{" "}
                    {underwriter?.toll_free_number || underwriter?.toll_free}
                  </p>
                )}
                {(underwriter?.fax_number || underwriter?.fax) && (
                  <p>
                    <span className="bold">Fax:</span>{" "}
                    {underwriter?.fax_number || underwriter?.fax}
                  </p>
                )}
                {underwriter?.email && (
                  <p>
                    <span className="bold">Email: </span>
                    <a href={`mailto:${underwriter?.email}`}>
                      {underwriter?.email}
                    </a>
                  </p>
                )}
              </div>
            </div>
          )}
        </Panel>
      </div>
      <CosDevTools />
    </>
  );
};

export default CoSSideBar;
