import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Popover, Button } from "@ufginsurance/ui-kit";
import { Translations } from "../../translations";

const ConfirmPopover = props => {
  const { confirmEventData, confirmEvent, confirmMessage } = props;

  const handleCancel = () => {
    document.querySelector("#confirm-popover").classList.remove("show");
  };

  const handleConfirm = () => {
    confirmEvent(confirmEventData);
    document.body.click();
  };

  const renderTrigger = (
    <span className="confirm-delete-popover__icon">
      <FontAwesomeIcon icon={faTimesCircle} />
    </span>
  );

  const renderOverlay = (
    <div>
      <p>{confirmMessage}</p>
      <div className="confirm-delete-popover__buttons">
        <Button variant="secondary" onClick={handleCancel}>
          {Translations.general.cancel}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {Translations.general.yes}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      id="confirm-popover"
      placement="left"
      popoverClass="confirm-delete-popover__overlay"
      triggerContent={renderTrigger}
      popoverContent={renderOverlay}
      theme="white"
    />
  );
};

ConfirmPopover.propTypes = {
  confirmEventData: PropTypes.object,
  confirmEvent: PropTypes.func,
  confirmMessage: PropTypes.string
};

export default ConfirmPopover;
