import qs from "query-string";
import { parseLocation } from "parse-address";
import { toTitleCase } from "../../../components/Factory";
import {
  suretyLegalEntities,
  poboxPattern,
  VALID_BONDS_UNIT_TYPE,
  NON_SURETY_STATES
} from "../constants/suretyDictionary";
import { StateLongNames } from "../../../constants/dictionary";

export const formatAddress = phrase => {
  const knownAcronyms = ["NE", "NW", "SE", "SW"];
  return toTitleCase(phrase)
    .split(" ")
    .map(word => {
      if (knownAcronyms.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      return word;
    })
    .join(" ");
};

export const formatZip = value => {
  if (value) {
    const zipFormat = value.indexOf("-");
    return value.length === 5 || zipFormat >= 0
      ? value
      : value.slice(0, 5) + "-" + value.slice(5, 9);
  }

  return value;
};

export const formatPercent = value =>
  `${percentRemoveZero(value).replace("%", "")}%`;

export const percentRemoveZero = value =>
  value === "0" ? value : !!value ? value.replace(/^0+/, "") : "0";

export const formatSsn = (value = "") => {
  return value.length === 9
    ? value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5, 9)
    : value;
};

export const normalizeUnverifiedAddress = address => {
  const addressLineObject = parseLocation(address.address_line);

  // address parser does not understand the concept of pobox, pobox will be parsed as unit_type
  const hasPoBox = poboxPattern.test(addressLineObject?.sec_unit_type);

  const parsedAddressLine = buildParsedAddressLine(addressLineObject, hasPoBox);

  //remove parsed out content from street name
  const streetName = (address?.address_line?.toUpperCase() || "")
    .replace(parsedAddressLine.building_number, "")
    .replace(parsedAddressLine.unit_number, "")
    .replace(parsedAddressLine.unit_type, "")
    .replace("#", "")
    .replaceAll("  ", " ")
    .trim();

  return {
    address_line: address.address_line.trim().toUpperCase(),
    building_number: parsedAddressLine.building_number,
    street_name: streetName,
    pobox: address?.pobox
      ? address?.pobox
      : hasPoBox
      ? parsedAddressLine.unit_number
      : "",
    city: address.city.toUpperCase(),
    state: address.state,
    zip: address.zip,
    unit_number: parsedAddressLine.unit_number,
    unit_type: parsedAddressLine.unit_type,
    confidence: "0"
  };
};

export const normalizeVerifiedAddress = (address, mailingPobox) => ({
  address_line: buildAddressLine(address),
  building_number: address.building_number,
  street_name: address.street_name?.toUpperCase(),
  pobox: mailingPobox ? mailingPobox : address?.pobox ?? "",
  city: address.city.toUpperCase(),
  state: address.state,
  zip: address.zip,
  unit_number: address.unit_number || "",
  unit_type: address.unit_type?.toUpperCase() || null,
  confidence: address.confidence,
  verify_date: address.verify_date
});

export const buildParsedAddressLine = (address, hasPoBox) => {
  if (hasPoBox && !address.hasOwnProperty("street")) {
    return {
      address_line: `PO Box ${address.sec_unit_num}`.toUpperCase(),
      unit_number: address.sec_unit_num || "",
      unit_type: address.sec_unit_type?.toUpperCase() || null
    };
  }
  let formatted_address_line = "";
  const street_name = buildStreetName(address);

  if (address.number)
    formatted_address_line = formatted_address_line.concat(address.number);

  formatted_address_line = formatted_address_line.concat(" " + street_name);

  if (address.sec_unit_type) {
    const _string =
      address.sec_unit_type === "#"
        ? `, ${address.sec_unit_type}`
        : `, ${address.sec_unit_type} .`;

    formatted_address_line = formatted_address_line.concat(_string);
  }
  if (address.sec_unit_num) {
    const _str =
      address?.sec_unit_type === "#"
        ? address.sec_unit_num
        : ` ${address.sec_unit_num}`;

    formatted_address_line = formatted_address_line.concat(_str);
  }

  return {
    address_line: formatted_address_line.toUpperCase(),
    building_number: address.number,
    street_name,
    unit_number: address.sec_unit_num || "",
    unit_type: address.sec_unit_type?.toUpperCase() || null
  };
};

export const buildAddressLine = address => {
  let addressLine = address.building_number.concat(" " + address.street_name);

  if (address.unit_type) {
    const _str =
      address.unit_type === "#"
        ? `, ${address.unit_type}${address.unit_number}`
        : `, ${address.unit_type}. ${address.unit_number}`;

    addressLine += _str;
  }

  if (!!address.pobox && !addressLine) {
    addressLine += ` PO Box ${address.pobox}`;
  }

  return addressLine.trim().toUpperCase();
};

export const buildStreetName = streetFields => {
  let street_name = "";

  if (streetFields.prefix)
    street_name = street_name.concat(streetFields.prefix + " ");
  if (streetFields.street)
    street_name = street_name.concat(streetFields.street);
  if (streetFields.type)
    street_name = street_name.concat(" " + streetFields.type);
  if (streetFields.suffix) {
    street_name = street_name.concat(" " + streetFields.suffix);
  }

  return street_name.toUpperCase();
};

export const getValidUnitType = ({ unit_type, unit_number }) => {
  // If PitneyBowes returns a unit that is not valid, we substitute it with #.
  if (
    !!unit_number?.length &&
    !VALID_BONDS_UNIT_TYPE.includes(unit_type.toUpperCase())
  ) {
    return "#";
  }

  if (
    !!unit_number?.length &&
    !!VALID_BONDS_UNIT_TYPE.includes(unit_type.toUpperCase())
  ) {
    return unit_type;
  }

  return "";
};

export const getTZFlatDate = (dateStr, addDays) => {
  if (!dateStr) return;

  // Prevent TZ interference.
  const zeroedDate = new Date(`${dateStr} 00:00:00`);

  if (Boolean(addDays) && typeof addDays === "number") {
    zeroedDate.setDate(zeroedDate.getDate() + addDays);
  }

  return zeroedDate;
};

export const getURLParams = allowedKeys => {
  const params = qs.parse(window.location.search);

  if (allowedKeys) {
    allowedKeys.forEach(key => {
      if (!params[key]) {
        delete params[key];
      }
    });
  }

  return params;
};

export const getIsLegalEntityType = account => {
  if (!!account?.isNew) {
    return true;
  }

  if (
    !!account?.legal_entity_type &&
    suretyLegalEntities
      .map(ent => ent.value.toUpperCase())
      .includes(account?.legal_entity_type.toUpperCase())
  ) {
    return true;
  }

  return false;
};

export const getIsNewAccountOrObligee = accountNumber =>
  !accountNumber || !parseInt(accountNumber);

export const getAddressFieldIDs = prefix => {
  const isPoBox = prefix === "pobox";
  return {
    addressLineFieldId:
      prefix && !isPoBox ? `${prefix}_address_line` : "address_line",
    zipFieldId: prefix && !isPoBox ? `${prefix}_zip` : "zip",
    cityFieldId: prefix && !isPoBox ? `${prefix}_city` : "city",
    stateFieldId: prefix && !isPoBox ? `${prefix}_state` : "state",
    poboxFieldId: prefix && !isPoBox ? `${prefix}_pobox` : "pobox"
  };
};

export const getInvalidStatesErrorMsg = () => {
  const st = NON_SURETY_STATES.map(s => StateLongNames[s]).join(", ");
  return `Quick Program is currently unavailable in ${st}. Please contact your surety underwriter for ${st} submissions.`;
};

/**
 * Checks if an existing obligee has a PO Box without a street address.
 * @param {*} values
 * @param {*} invalidFields
 * @returns Boolean
 */
export const getAllowExistingObligeePoBoxOnly = values => {
  return !!(
    values?.obligee_pobox &&
    !values?.obligee_address_line &&
    !getIsNewAccountOrObligee(values?.obligee_account_number)
  );
};

export const isObligeeAddressPOBox = values => {
  return (
    getAllowExistingObligeePoBoxOnly(values) ||
    !!values?.obligee_address_is_pobox
  );
};
