import API from "../../api";

export const getTableOfContents = () => {
  return API.agent().get(`/resources/new-business/table-of-contents`);
};

export const getContent = contentId => {
  return API.agent().get(
    `/resources/new-business/table-of-contents/${contentId}`
  );
};
