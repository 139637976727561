import React from "react";
import {
  Panel,
  ContentHeader,
  FormGroup,
  Dropdown,
  TextArea,
  formatDate,
  Currency,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import { useSuretyContext } from "../../surety-context/SuretyContext";
import useGetContacts from "../hooks/useGetContactList";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";
import { toTitleCase } from "../../../components/Factory";

const ReviewConsent = ({
  form: { handleOnChange, handleOnBlur, handleOnValidate, values },
  consentOfSuretyTypes
}) => {
  const { agency } = useSuretyContext();
  const [contactList, isContactsLoading] = useGetContacts(agency?.agencyCode);

  if (isContactsLoading) return <LoadingIndicator color="blue" />;
  if (!isContactsLoading && !agency) return <DefaultErrorResponse />;

  const formatContactList = contactListOptions => {
    if (!contactListOptions) {
      contactListOptions = [];
    }
    return contactListOptions.map(({ name, number }) => ({
      label: name,
      value: `${number}` // Must be string for Dropdown.
    }));
  };

  return (
    <div>
      <ContentHeader>Review Consent of Surety</ContentHeader>
      <Panel rounded bgcolor="grey" title="Summary" titlebar>
        <Panel>
          <div className="mb-1">
            <b>Consent of Surety Type:</b>
            <div>
              {consentOfSuretyTypes?.find(
                ({ value }) => value === values.consent_type
              )?.label || ""}
            </div>
          </div>
          <div className="mb-1">
            <b>Project Owner/Obligee:</b>
            <div>
              <div>{toTitleCase(values.obligee_name)}</div>
              <div>{toTitleCase(values.obligee_address_line)}</div>
              <div>
                {toTitleCase(values.obligee_city)}, {values.obligee_state}{" "}
                {values.obligee_zip}
              </div>
            </div>
          </div>
          <div className="mb-1">
            <b>Sign and Seal Date:</b>
            <div>{formatDate(values.sign_seal_date, "MM/DD/YYYY")}</div>
          </div>
          {values.consent_type === "F" && (
            <div className="mb-1">
              <b>Final Contract Price:</b>
              <div>
                {values?.final_contract_price ? (
                  <Currency amount={values.final_contract_price} />
                ) : (
                  "-"
                )}
              </div>
            </div>
          )}
          <div className="mb-1">
            <b>Project Description:</b>
            <div>{values?.project_description || "-"}</div>
          </div>
          {!!values.retainage_change_request?.length && (
            <div className="mb-1">
              <b>Requested Change In Retainage:</b>
              <div>{values?.retainage_change_request || "-"}</div>
            </div>
          )}
          <div className="mb-1">
            <b>{"Architect's Project No:"}</b>
            <div>{values?.architect_project_num || "-"}</div>
          </div>
          <div className="mb-1">
            <b>Contract For:</b>
            <div>{values?.contract_for || "-"}</div>
          </div>
          <div className="mb-1">
            <b>Contract Dated:</b>
            <div>{formatDate(values?.contract_dated, "MM/DD/YYYY") || "-"}</div>
          </div>
          <div className="mb-1">
            <b>Are All Bills Paid?:</b>
            <div>{toTitleCase(values?.all_bills_paid) || "-"}</div>
          </div>
          <div className="mb-1">
            <b>Number of claims against the contractor to date:</b>
            <div>{values?.claims_against_contractor || "-"}</div>
          </div>
          <div className="mb-1">
            <b>Total Value of Claims to Date:</b>
            <div>
              {values?.total_claim_value ? (
                <Currency amount={values.total_claim_value} />
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="mb-1">
            <b>Scheduled Completion Date:</b>
            <div>
              {formatDate(values?.completion_date, "MM/DD/YYYY") || "-"}
            </div>
          </div>
        </Panel>
        <Panel title="Contact Information">
          <div className="mb-1">
            <div>
              Please choose the person to contact regarding your consent of
              surety request. All responses will be sent to this contact. If the
              contact name is not listed below, please have your agency
              administrator add to the agency profile.
            </div>
          </div>
          <FormGroup>
            <Dropdown
              id="contact_person"
              name="contact_person"
              label="Contact Person"
              options={formatContactList(contactList)}
              value={values.contact_person}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              required
              size="lg"
            />
          </FormGroup>
          <FormGroup>
            <TextArea
              id="comments"
              name="comments"
              label="Comments/Remarks"
              value={values.comments}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
            />
          </FormGroup>
        </Panel>
      </Panel>
    </div>
  );
};

export default ReviewConsent;
