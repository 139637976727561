import React, { useState } from "react";
import PropTypes from "prop-types";
import InboxMessages from "./Messages/InboxMessages";
import NewConversation from "./Messages/NewConversation";
import UnreadMessages from "./Messages/UnreadMessages";
import { Translations } from "../../translations";
import { LoadingIndicator, Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const ViewMessage = ({
  messages,
  isLoading,
  show,
  handleClose,
  markAsRead,
  startConversation,
  replyMessage,
  result,
  isReplyMessageLoading,
  isStartConversationLoading,
  agentCode
}) => {
  const [showInbox, setShowInbox] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [canReply, setCanReply] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState({});

  const onHandleClose = () => {
    setShowConversation(false);
    setShowInbox(false);
    setShowUnread(false);
    handleClose();
  };

  const openConversation = () => {
    setShowConversation(true);
    setShowInbox(true);
    setShowUnread(false);
  };

  const openMessage = (selectedConversation, can_reply) => {
    setShowUnread(true);
    setShowInbox(true);
    setSelectedConversation(selectedConversation);
    setCanReply(can_reply);
    markAsRead(selectedConversation.id);
  };

  const onReplyMessage = replyText => {
    replyMessage(selectedConversation.id, replyText).then(() => {
      // Return to conversation list modal
      setShowInbox(false);
    });
  };

  const onStartConversation = formValues => {
    const newConversation = { ...formValues };
    const body = {
      conversation_subject: newConversation.subject,
      message_body: newConversation.message,
      author: {
        code: agentCode,
        name: "",
        email: "",
        user_role: 0,
        user_role_description: "Producer"
      }
    };
    const user_role = "Producer";
    const submissionId = result.id;
    startConversation(submissionId, user_role, body).then(() => {
      // Return to conversation list modal
      setShowConversation(false);
      setShowInbox(false);
      setShowUnread(false);
    });
  };

  const handleBackToMessagesClick = () => {
    setShowUnread(false);
    setShowInbox(false);
  };

  const renderInboxModal = () => {
    const numberOfConversations =
      messages && messages.conversations ? messages.conversations.length : 0;

    return (
      <Modal
        show={show}
        onHide={onHandleClose}
        title={<>{numberOfConversations} conversation(s)</>}
        body={
          <>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <form>
                <InboxMessages
                  messages={messages}
                  openMessage={openMessage}
                  quote_number={result.quote_number}
                />
              </form>
            )}
          </>
        }
        footer={
          <FlexRow align="right">
            <Button
              className="messaging-close"
              variant="secondary"
              onClick={onHandleClose}
            >
              {Translations.commercial.new_submissions_tab.messages.close}
            </Button>
            {!isLoading && messages && messages.can_start_conversation && (
              <Button
                className="messaging-start"
                variant="primary"
                onClick={openConversation}
              >
                {
                  Translations.commercial.new_submissions_tab.messages
                    .startConversation
                }
              </Button>
            )}
          </FlexRow>
        }
        altCloseMethod={false}
      />
    );
  };

  const renderConversationModal = () => {
    return (
      <Modal
        show={show}
        onHide={onHandleClose}
        title={
          Translations.commercial.new_submissions_tab.messages
            .conversationEntryHeader
        }
        body={
          <NewConversation
            messages={messages}
            result={result}
            onStartConversation={onStartConversation}
            isLoading={isLoading}
            onHandleClose={onHandleClose}
            isStartConversationLoading={isStartConversationLoading}
          />
        }
        altCloseMethod={false}
        className="new-conversation-modal"
      />
    );
  };

  const renderUnreadModal = () => {
    const currentConversation =
      messages?.conversations?.find(o => o.id === selectedConversation.id) ??
      null;

    return currentConversation ? (
      <Modal
        show={show}
        onHide={onHandleClose}
        title={
          <>
            <span className="capitalize">{selectedConversation.subject}</span>
            {selectedConversation &&
              ` (${selectedConversation.unread_message_count} unread)`}
          </>
        }
        body={
          <UnreadMessages
            can_reply={canReply}
            selectedConversation={currentConversation}
            onBackToMessagesClick={handleBackToMessagesClick}
            onHandleClose={onHandleClose}
            onReplyMessage={onReplyMessage}
            isReplyMessageLoading={isReplyMessageLoading}
            isLoading={isLoading}
          />
        }
        altCloseMethod={false}
        className="reply-message-modal"
      />
    ) : null;
  };

  // This prevents a lot of wasted calls to renderInboxModal within
  // the New Submissions page where renderInboxModal is executed despite
  // not needing to display anything.
  if (!show) {
    return null;
  }

  // TODO - Why showInbox when false?
  return !showInbox
    ? renderInboxModal()
    : showConversation
    ? renderConversationModal()
    : showUnread
    ? renderUnreadModal()
    : null;
};

ViewMessage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  startConversation: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  show: PropTypes.bool,
  result: PropTypes.object,
  messages: PropTypes.object,
  replyMessage: PropTypes.func,
  isLoading: PropTypes.bool,
  isReplyMessageLoading: PropTypes.bool,
  isStartConversationLoading: PropTypes.bool,
  agentCode: PropTypes.string
};

export default ViewMessage;
