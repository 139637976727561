import React, { useContext } from "react";
import { Button } from "@ufginsurance/ui-kit";
import "./CoverageTitle.scss";
import { isSuggestedCoverage } from "./coveragePanels";
import OnlineQuotingContext from "../OnlineQuotingContext";
const CoverageTitle = ({
  coverage,
  handleRemoveCoverage,
  readonly,
  coverageExclusions,
  jsxAppendTitle = null,
  jsxAfterTitle = null
}) => {
  const { get } = useContext(OnlineQuotingContext);
  const isAddbtn =
    isSuggestedCoverage({ coverage, coverageExclusions }) || !coverage.selected;

  return (
    <div className="oq__coverage__title-container">
      <div className="oq__coverage__title-row">
        <div className="oq__coverage__title">
          <span className="oq__coverage__title-text">{coverage.name}</span>
          {jsxAppendTitle && (
            <span className="oq__coverage__title-jsx-append">
              {jsxAppendTitle({ coverage, get })}
            </span>
          )}
        </div>
        {!coverage.required && !readonly && (
          <div>
            <Button
              isLink
              variant={isAddbtn ? "info" : "tertiary"}
              className="oq__coverage__action-link"
              onClick={e => {
                handleRemoveCoverage(coverage);
                e.stopPropagation();
              }}
              dataAttrib={[
                {
                  name: isAddbtn
                    ? "fullstory_oq_add_coverage"
                    : "fullstory_oq_remove_coverage",
                  value: coverage.name
                }
              ]}
            >
              {isAddbtn ? "Add This Coverage" : "Remove This Coverage"}
            </Button>
          </div>
        )}
      </div>
      {jsxAfterTitle && (
        <div className="oq__coverage__title-jsx-after">
          {jsxAfterTitle({ coverage, get })}
        </div>
      )}

      {!!coverage?.classificationName && (
        <div className="oq__coverage__sub-title-row">
          {coverage.classificationName}
        </div>
      )}
    </div>
  );
};

export default CoverageTitle;
