import React, { useContext } from "react";
import {
  Button,
  SelectNonForm,
  Panel,
  formatDate,
  Alert
} from "@ufginsurance/ui-kit";
import {
  ssoActions,
  sessionSelector
} from "@ufginsurance/sso-oidc-client-react";
import cn from "classnames";
import { connect } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortByProperty } from "./shared/util";
import { useHistory } from "react-router-dom";

import "./UfgInternalHelpers.scss";

import CopyJWT from "./UfgHelpers/CopyJWT";
import GenerateMetadata from "./UfgHelpers/GenerateMetadata";
import LoadQuoteByNumberForm from "./UfgHelpers/LoadQuoteByNumberForm";
import StoreQuotesLocally from "./UfgHelpers/storedQuotes/StoreQuotesLocally";
import OnlineQuotingContext from "./OnlineQuotingContext";
import QuotingUfgHelperContext from "./UfgHelpers/QuotingUfgHelperContext";
import { useQuotingUfgHelper } from "./UfgHelpers/useQuotingUfgHelper";

const UfgInternalHelpers = ({
  applicationId,
  userAgentCode,
  impersonateAgentRedirect,
  canImpersonate
}) => {
  const {
    quoteData,
    step,
    supportingData,
    refreshQuoteFromPC,
    setForceFailures,
    forceFailures,
    effectiveDateOverride,
    setEffectiveDateOverride
  } = useContext(OnlineQuotingContext);

  const {
    proQuoteDeveloperTools,
    proquoteQuickTestAgents,
    proQuoteMonolinePolicy
  } = useFlags();

  const history = useHistory();

  // if useSupportChannelMulesoftEnvironment === false, then it's NEW dev/qa
  const { useSupportChannelMulesoftEnvironment, guidewirePcDynamicRouting } =
    useFlags();

  let agentEnvLabel = !useSupportChannelMulesoftEnvironment ? "New-" : "";
  if (guidewirePcDynamicRouting === 2) agentEnvLabel = "ATM-";
  const env = (
    window?.env?.REACT_APP_API_BASEURL ||
    window?.location?.host ||
    ""
  ).split(".")?.[1];

  const quotingUfgHelper = useQuotingUfgHelper({
    applicationId,
    userAgentCode,
    impersonateAgentRedirect,
    env: agentEnvLabel + env
  });

  // ----------------------------------------------------------------------------
  // REACT_APP_IS_PROD is "true" on PreProd and Prod
  // return null will make none of the code below it render
  // ----------------------------------------------------------------------------
  if (!proQuoteDeveloperTools) return null;

  // ----------------------------------------------------------------------------
  // REACT_APP_IS_PROD is null on all environments that are not PreProd and Prod
  // The following will render on Dev, QA, SIT, etc
  // ----------------------------------------------------------------------------

  return (
    <QuotingUfgHelperContext.Provider value={quotingUfgHelper}>
      <>
        <div className="oq-sidebar-data-for-testing">
          <Panel rounded bgcolor="grey" title="UFG Internal Tools" titlebar>
            {proQuoteMonolinePolicy && (
              <>
                <Alert type="error">
                  This is a MONOLINE agent and will NOT work to create quotes in
                  this UI.
                </Alert>
                <br />
              </>
            )}
            {env && (
              <div>
                Agent Env:{" "}
                <span className="oq-pc-environment">
                  {agentEnvLabel}
                  {env}
                </span>
              </div>
            )}
            {supportingData?.quoteNumber && (
              <div id="oq__quoteNumber" className="hidden">
                {supportingData?.quoteNumber}
              </div>
            )}
            <div className="oq__testing-panel">
              {step <= 2 && supportingData && (
                <div>
                  Reference Id:{" "}
                  <span className="quoteId">{supportingData?.referenceId}</span>
                </div>
              )}
              {step > 2 && (
                <div>
                  Submission Id:{" "}
                  <span className="quoteId">{quoteData?.quoteID}</span>
                </div>
              )}
              {userAgentCode && <div>Agent Code: {userAgentCode}</div>}

              {step === 6 && (
                <div>
                  <br />
                  <span>Effective Date: </span>
                  {formatDate(supportingData.effectiveDate, "MM/DD/YYYY")}
                </div>
              )}

              <br />

              <StoreQuotesLocally env={agentEnvLabel + env} />

              <br />

              {canImpersonate && proquoteQuickTestAgents && (
                <>
                  <div className="oq-sidebar-data-for-testing-impersonating">
                    <SelectNonForm
                      id="oq__switch__agent"
                      name="oq__switch__agent"
                      label="Switch to Agent"
                      value={userAgentCode}
                      isClearable={false}
                      size="full"
                      options={[
                        {
                          value: "",
                          label: "[let me enter my own]"
                        },
                        ...(proquoteQuickTestAgents || [])
                          .sort(sortByProperty("code"))
                          .map(a => ({
                            value: a.code.toString(),
                            label: (
                              <span>
                                {a.name}
                                <div>{a.code}</div>
                              </span>
                            )
                          }))
                      ]}
                      onChange={e =>
                        impersonateAgentRedirect(
                          applicationId,
                          e?.value || "",
                          `${window.location.origin}/online_quoting/step1`
                        )
                      }
                    />
                  </div>
                </>
              )}

              <LoadQuoteByNumberForm />

              <hr />

              <div className="oq__helper__bottom-buttons">
                {step > 2 && (
                  <Button
                    className="oq__reload-quote-from-pc"
                    block
                    size="sm"
                    onClick={refreshQuoteFromPC}
                  >
                    Update Quote Data from PC
                  </Button>
                )}
                <Button
                  block
                  size="sm"
                  className={cn({ active: forceFailures, forceFailures })}
                  onClick={() => setForceFailures(!forceFailures)}
                  variant={forceFailures ? "info" : "plain"}
                >
                  Covg Failures {!!forceFailures && "✓"}
                </Button>
                <Button
                  block
                  className={cn({ active: effectiveDateOverride })}
                  onClick={() =>
                    setEffectiveDateOverride(!effectiveDateOverride)
                  }
                  size="sm"
                  variant={effectiveDateOverride ? "info" : "plain"}
                >
                  EffiveDate Override {!!effectiveDateOverride && "✓"}
                </Button>
                <Button
                  block
                  size="sm"
                  onClick={() => history.push("/commercial/commercial_lines")}
                >
                  Jump to NSL
                </Button>
                <CopyJWT
                  quoteData={quoteData}
                  supportingData={supportingData}
                />
                <GenerateMetadata env={env} agentEnvLabel={agentEnvLabel} />
              </div>
            </div>
          </Panel>
        </div>
      </>
    </QuotingUfgHelperContext.Provider>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  token: state?.oidc?.user?.id_token,
  userAgentCode: sessionSelector.getAgentCode(state),
  applicationId: sessionSelector.getApplicationId(state),
  canImpersonate: sessionSelector.canImpersonate(state)
});

const mapDispatchToProps = {
  logoutAndRedirect: ssoActions.logoutAndRedirect,
  impersonateAgentRedirect: ssoActions.impersonateAgentRedirect
};

export default connect(mapStateToProps, mapDispatchToProps)(UfgInternalHelpers);
