import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const suretyBondFormListReducer = (
  state = initialState.suretyBondFormList,
  action
) => {
  switch (action.type) {
    case types.FETCH_SURETY_BONDFORM_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_SURETY_BONDFORM_SUCCEEDED:
      return {
        ...state,
        bondFormData: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_SURETY_BONDFORM_FAILED:
      return {
        ...state,
        bondFormData: [],
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default suretyBondFormListReducer;
