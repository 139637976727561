import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translations } from "../../translations";
import { DateFormat } from "../../Factory";
import { fetchBlobFile } from "../../../actions/commercialLinesAction";
import { Table, Alert, Popover, Icon, Button } from "@ufginsurance/ui-kit";

const QuoteProposalTable = ({
  quoteProposalList,
  fetchBlobFile,
  sortableColumns,
  loadingProposalDocumentIds
}) => {
  const GUIDEWIRE = "GuideWire";
  const isGuideWire = proposal => (proposal?.core_system ?? "") === GUIDEWIRE;

  const renderProductRow = row => {
    const products = row?.product?.lines?.join(", ").trim() ?? "";
    return (
      <span>
        {row?.product?.type ?? ""} &nbsp;
        {isGuideWire(row) && !!products && (
          <Popover
            id={"package-" + row?.id ?? ""}
            theme="white"
            placement="top"
            trigger="hover"
            triggerContent={<Icon icon="fasInfoCircle" />}
            popoverContent={products}
          />
        )}
      </span>
    );
  };

  const columns = [
    {
      key: "client_name",
      label: Translations.commercial.client_name,
      sortable: sortableColumns.includes("client_name"),
      element: row => {
        return (
          <Button
            isLink
            inline
            spinner={loadingProposalDocumentIds?.includes(row.id) ?? false}
            spinnerPos={"right"}
            disabled={loadingProposalDocumentIds?.includes(row.id) ?? false}
            onClick={() => {
              fetchBlobFile(row.id);
            }}
          >
            {row.client_name}
          </Button>
        );
      }
    },
    {
      key: "product",
      label: Translations.commercial.Product,
      sortable: sortableColumns.includes("product"),
      element: row => {
        return renderProductRow(row);
      }
    },
    {
      key: "created",
      label: Translations.commercial.Created,
      sortable: sortableColumns.includes("created"),
      element: row => {
        return <span>{DateFormat(row.created, "MMM DD, YYYY hh:mmA")}</span>;
      }
    }
  ];

  return (
    <div className="quote-proposal-table">
      <div>
        {quoteProposalList && quoteProposalList.length > 0 ? (
          <Table
            rowKey="id"
            data={quoteProposalList}
            initialSort="created"
            initialDirection="desc"
            columns={columns}
            itemsPerPage={20}
            showPagination
          />
        ) : (
          <div>
            <Alert type="plain" dismissible={false}>
              {Translations.commercial.table_error}
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  fetchBlobFile
};

QuoteProposalTable.propTypes = {
  quoteProposalList: PropTypes.array,
  fetchBlobFile: PropTypes.func.isRequired,
  sortableColumns: PropTypes.array,
  loadingProposalDocumentIds: PropTypes.array
};

QuoteProposalTable.defaultProps = {
  sortableColumns: []
};

export default connect(null, mapDispatchToProps)(QuoteProposalTable);
