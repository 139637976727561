import { v4 } from "uuid";
import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const homeClaimsReducer = (state = initialState.homeClaims, action) => {
  switch (action.type) {
    case types.FETCH_CLAIMS_STATUSES_STARTED:
      return {
        ...state,
        claimsStatusesLoading: true,
        claimsStatusesError: null,
        claimsStatusDeleteError: null
      };
    case types.FETCH_CLAIMS_STATUSES_SUCCEEDED:
      return {
        ...state,
        claimsStatusesList: action.payload.flatMap(x => {
          return x.claims.map(c => ({
            ...c,
            document_date: x.document_date
          }));
        }),
        claimsStatusesLoading: false,
        claimsStatusesError: null,
        pastFetchClaimsStatusAgencyCode: action.agencyCode
      };
    case types.FETCH_CLAIMS_STATUSES_FAILED:
      return {
        ...state,
        claimsStatusesLoading: false,
        claimsStatusesError: action.payload,
        claimsStatusesList: []
      };
    case types.REMOVE_CLAIMS_STATUS_FAILED:
      return {
        ...state,
        claimsStatusesLoading: false,
        claimsStatusDeleteError: action.payload
      };

    case types.FETCH_CLAIMS_OPENED_STARTED:
      return {
        ...state,
        claimsOpenedLoading: true,
        claimsOpenedError: null,
        claimOpenedDeleteError: null
      };
    case types.FETCH_CLAIMS_OPENED_SUCCEEDED:
      return {
        ...state,
        // We need a single array to support header sorting with react-data-sort so we
        // need to flatten the data returned by api (array of objects with nested claims).
        claimsOpenedList: action.payload.flatMap(x => {
          return x.claims.map(c => ({
            ...c,
            opened_date: x.open_date
          }));
        }),
        claimsOpenedLoading: false,
        claimsOpenedError: null,
        pastFetchedClaimsOpenedCode: action.agencyCode
      };
    case types.FETCH_CLAIMS_OPENED_FAILED:
      return {
        ...state,
        claimsOpenedList: [],
        claimsOpenedLoading: false,
        claimsOpenedError: action.payload
      };
    case types.REMOVE_CLAIM_OPENED_FAILED:
      return {
        ...state,
        claimsOpenedLoading: false,
        claimOpenedDeleteError: action.payload
      };

    case types.FETCH_CLAIM_PAYMENTS_STARTED:
      return {
        ...state,
        claimPaymentsLoading: true,
        claimPaymentsError: null,
        claimPaymentDeleteError: null
      };
    case types.FETCH_CLAIM_PAYMENTS_SUCCEEDED:
      return {
        ...state,
        claimPaymentsList: action.payload.flatMap(x => {
          return x.payments.map(c => ({
            id: v4(),
            ...c,
            payment_date: x.payment_date
          }));
        }),
        claimPaymentsLoading: false,
        claimPaymentsError: null,
        pastFectchedClaimsPaymentCode: action.agencyCode
      };
    case types.FETCH_CLAIM_PAYMENTS_FAILED:
      return {
        ...state,
        claimPaymentsLoading: false,
        claimPaymentsError: action.payload
      };
    case types.REMOVE_CLAIM_PAYMENT_FAILED:
      return {
        ...state,
        claimPaymentsLoading: false,
        claimPaymentDeleteError: action.payload
      };
    case types.FETCH_CLAIMS_SAVED_LOSSES_LIST_STARTED:
      return {
        ...state,
        SavedLossesIsLoading: true,
        SavedLossesError: null
      };
    case types.FETCH_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED:
      return {
        ...state,
        SavedLossesList:
          action.payload &&
          action.payload.map(savedLoss => ({
            ...savedLoss,
            id: v4()
          })),
        SavedLossesIsLoading: false,
        SavedLossesError: null,
        pastFetchedSavedLossesAgencyCode: action.agencyCode
      };
    case types.FETCH_CLAIMS_SAVED_LOSSES_LIST_FAILED:
      return {
        ...state,
        SavedLossesIsLoading: false,
        SavedLossesError: action.payload
      };
    case types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_STARTED:
      return {
        ...state,
        SubmittedLossesIsLoading: true,
        SubmittedLossesError: null
      };
    case types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_SUCCEEDED:
      return {
        ...state,
        SubmittedLossesList:
          action.payload &&
          action.payload.map(submittedLoss => ({
            ...submittedLoss,
            id: v4()
          })),
        SubmittedLossesIsLoading: false,
        SubmittedLossesError: null,
        pastFetchedSubmittedAgencyCode: action.agencyCode
      };
    case types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_FAILED:
      return {
        ...state,
        SubmittedLossesIsLoading: false,
        SubmittedLossesError: action.payload
      };
    default:
      return state;
  }
};

export default homeClaimsReducer;
