import React from "react";
import PropTypes from "prop-types";
import { logoLarge } from "../../constants/images";
import { CLAIMS_DEPARTMENT_ID } from "../constants";

const OrganizationContacts = ({ contacts, department }) => {
  const { sub_heading } = contacts.find(o => o.sub_heading) || {};
  return (
    <>
      <div>
        {sub_heading && (
          <div className="contact-info-sub-heading">{sub_heading}</div>
        )}
        {contacts.map(info => {
          const city = info.address?.city ? `${info.address?.city}, ` : "";
          const state = info.address?.state ? `${info.address?.state} ` : "";
          const zip = info.address?.zip_code ? `${info.address?.zip_code}` : "";
          return (
            <div key={info.title} className="row contact-info-container">
              <div className="col-md-4">
                <img src={logoLarge} alt="UFG Insurance" />
                <div className="font-weight-bold">&nbsp;</div>
              </div>
              <div className="col-md-8">
                {info.title && (
                  <div>
                    <span className="font-weight-bold">{info.title}</span>
                  </div>
                )}
                {info.employee_description && (
                  <div>
                    <span className="font-weight-bold">
                      {info.employee_description}
                    </span>
                  </div>
                )}
                {info.description && (
                  <div>
                    <span
                      className={
                        department.id === CLAIMS_DEPARTMENT_ID
                          ? "non-person-claims-contact-tagline"
                          : "non-person-contact-tagline"
                      }
                    >
                      {info.description}
                    </span>
                    <div>&nbsp;</div>
                  </div>
                )}
                {info.phone_number && (
                  <div>
                    <span className="font-weight-bold">Phone: </span>
                    {info.phone_number}
                  </div>
                )}
                {info.toll_free_number && (
                  <div>
                    <span className="font-weight-bold">Toll free: </span>
                    {info.toll_free_number}
                  </div>
                )}
                {info.fax_number && (
                  <div>
                    <span className="font-weight-bold">Send me a fax: </span>
                    {info.fax_number}
                  </div>
                )}
                {info.email && (
                  <div>
                    <span className="font-weight-bold">Email: </span>
                    <a className="email" href={"mailto:" + info.email}>
                      {info.email}
                    </a>{" "}
                  </div>
                )}
                {info.hours && (
                  <div>
                    <span className="font-weight-bold">Hours: </span>
                    {info.hours}
                  </div>
                )}
                {info.address && <div>&nbsp;</div>}
                {info.address?.street && (
                  <div>
                    <span className="font-weight-bold">
                      {info.address?.street}
                    </span>
                  </div>
                )}
                {info.address?.additional_address && (
                  <div>
                    <span className="font-weight-bold">
                      {info.address?.additional_address}
                    </span>
                  </div>
                )}
                {(city || state || zip) && (
                  <div>
                    <span className="font-weight-bold">
                      {city}
                      {state}
                      {zip}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

OrganizationContacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  department: PropTypes.object.isRequired
};

export default OrganizationContacts;
