import qs from "query-string";
import API from "../api";

export const getQuoteProposalList = (agencyCode, days, company_branch) => {
  const params = {
    agency_code: agencyCode,
    days_created_within: days
  };

  if (company_branch) {
    params.company_branch = company_branch;
  }

  const queryString = qs.stringify(params);
  return API.commercial().get(`/proposals?${queryString}`);
};

export const getQuotePdfData = id => {
  return API.commercial().get(`/proposals/${id}`, {
    responseType: "blob"
  });
};

export const getSubmissions = agencyCode => {
  const queryString = qs.stringify({ agency_code: agencyCode });
  return API.commercial().get(`/customers/submissions?${queryString}`);
};

export const deleteQuote = submissionId => {
  return API.commercial().delete(`/customers/submissions/${submissionId}`);
};

export const copyQuote = submissionId => {
  return API.commercial().post(`/customers/submissions/${submissionId}/copy`);
};

export const editQuote = submissionId => {
  return API.commercial().patch(
    `/customers/submissions/${submissionId}/quote-edited`
  );
};

export const editApp = submissionId => {
  return API.commercial().patch(
    `/customers/submissions/${submissionId}/app-edited`
  );
};

export const createSubmission = form => {
  return API.commercial().post(`/customers/submissions`, form);
};

export const getCustomers = agency_code => {
  const queryString = qs.stringify({ agency_code });
  return API.commercial().get(`/customers?${queryString}`);
};

export const createCustomer = (agency_code, agent_number, form) => {
  const queryString = qs.stringify({ agency_code, agent_number });
  return API.commercial().post(`/customers?${queryString}`, form);
};

export const updateCustomer = (id, agencyCode, agentNumber, form) => {
  const queryString = qs.stringify({
    agency_code: agencyCode,
    agent_number: agentNumber
  });
  return API.commercial().post(`/customers/${id}?${queryString}`, form);
};

export const getAccount = (customerId, accountId) => {
  return API.commercial().get(`/customers/${customerId}/accounts/${accountId}`);
};

export const createAccount = (customerId, account, agencyCode, agentNumber) => {
  const queryString = qs.stringify({
    agency_code: agencyCode,
    agent_number: agentNumber
  });
  return API.commercial().post(
    `/customers/${customerId}/accounts?${queryString}`,
    account
  );
};

export const updateAccount = (customerId, account) => {
  return API.commercial().put(
    `/customers/${customerId}/accounts/${account.account_number}`,
    account
  );
};

// TODO - Chris: Don't think this is used, but keeping for now.
export const isAdverseRisk = (customerId, agencyCode) => {
  const queryString = qs.stringify({ agency_code: agencyCode });
  return API.commercial().get(
    `/customers/${customerId}/is-adverse-risk?${queryString}`
  );
};

export const viewAttachments = submissionId => {
  return API.commercial().get(`/submissions/${submissionId}/attachments`);
};

export const getAttachment = (id, submissionId) => {
  return API.commercial().get(
    `/submissions/${submissionId}/attachments/${id}`,
    {
      responseType: "blob"
    }
  );
};

export const removeAttachment = (id, submissionId) => {
  return API.commercial().delete(
    `/submissions/${submissionId}/attachments/${id}`
  );
};

export const updateAttachment = (data, submissionId) => {
  return API.commercial().post(
    `/submissions/${submissionId}/attachments/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

export const getMessages = (submissionId, role) => {
  const queryString = qs.stringify({
    submission_id: submissionId,
    user_role: role
  });
  return API.commercial().get(`/conversations?${queryString}`);
};

// TODO - Unused role param
export const startConversation = (submissionId, role, data) => {
  const queryString = qs.stringify({ submission_id: submissionId });
  return API.commercial().post(`/conversations/start?${queryString}`, data);
};

// TODO - Unused role param
export const replyMessage = (submissionId, conversationId, role, data) => {
  const queryString = qs.stringify({ submission_id: submissionId });
  return API.commercial().post(
    `/conversations/${conversationId}/reply?${queryString}`,
    data
  );
};

export const markAsRead = (submissionId, conversationId, role) => {
  const queryString = qs.stringify({
    submission_id: submissionId,
    user_role: role
  });
  return API.commercial().patch(
    `/conversations/${conversationId}/mark-as-read?${queryString}`
  );
};
