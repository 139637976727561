import qs from "query-string";
import API from "../api";

export const fetchPolicyDecs = (
  agency_code,
  begin_date,
  end_date,
  company_branch
) => {
  const params = {
    start_date: begin_date,
    end_date
  };

  if (company_branch) {
    params.company_branch = company_branch;
  }

  const queryString = qs.stringify(params);

  return API.agent().get(
    `/agencies/${agency_code}/commercial/policies/declarations?${queryString}`
  );
};

export const removePolicyDec = (agency_code, policy, process_date) => {
  const { policy_number, effective_date, dec_code, status } = policy;
  const queryString = qs.stringify({
    status,
    policy_number,
    process_date,
    effective_date,
    dec_code,
    delete_type: "policy-declaration",
    origin: "ufg_core" //hardcode until we handle the oden_notice jen story
  });
  return API.agent().delete(
    `/agencies/${agency_code}/commercial/decs-and-audit-bills?${queryString}`
  );
};

export const fetchPolicyDecsPersonalDocument = body => {
  return API.documents().post(`/document-bundle/personal`, body, {
    responseType: "blob"
  });
};

export const fetchPolicyDecsCommercialDocument = body => {
  return API.documents().post(`/document-bundle/commercial`, body, {
    responseType: "blob"
  });
};
