import React, { useState, useContext } from "react";
import { Button, Icon } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import ConfirmationModal from "../../shared/ConfirmationModal";

const LobRemove = ({ lob, product, isBopWithHnoAuto }) => {
  const { changeLineOfBusiness } = useContext(OnlineQuotingContext);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [removeSpinner, setRemoveSpinner] = useState();
  if (isBopWithHnoAuto || lob === "bp7BusinessOwners") return null;

  const removeAfterConfirm = () => {
    setRemoveSpinner(true);
    changeLineOfBusiness({
      action: "Removing",
      product,
      rateAfter: true
    }).finally(() => setRemoveSpinner(false));
  };
  return (
    <>
      <Button
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
        isLink
        inline
        variant="secondary"
        title={`Remove ${product.label}`}
        spinner={removeSpinner}
      >
        {removeSpinner ? " " : <Icon icon="fasTrashAlt" />}
      </Button>
      {showDeleteConfirmation && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <>
              <div>
                Are you sure you want to remove the {product.label} Policy?
              </div>
            </>
          }
          onClickYes={removeAfterConfirm}
          show={showDeleteConfirmation}
        />
      )}
    </>
  );
};
export default LobRemove;
