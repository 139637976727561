export const CHECKLIST_ID = "agency-interface-checklist";
const CHECKLIST_CMS_ID = "agency-interface-checklist-cms";
const REPORT_A_CLAIM_ID = "#report-a-claim-trigger";

export const addChecklistTrigger = customFunction => {
  // Table of Contents trigger
  const trigger = document.querySelector(`#${CHECKLIST_ID}`);

  if (trigger && trigger.getAttribute("clickListener") !== "true") {
    trigger.setAttribute("clickListener", "true");
    trigger.addEventListener("click", customFunction);
  }

  // CMS Content trigger
  const cmsTrigger = document.querySelector(`#${CHECKLIST_CMS_ID}`);

  if (cmsTrigger && cmsTrigger.getAttribute("clickListener") !== "true") {
    cmsTrigger.setAttribute("clickListener", "true");
    cmsTrigger.addEventListener("click", customFunction);
  }
};

export const addReportAClaimTrigger = customFunction => {
  const trigger = document.querySelector(REPORT_A_CLAIM_ID);

  if (trigger && trigger.getAttribute("clickListener") !== "true") {
    trigger.setAttribute("clickListener", "true");
    trigger.addEventListener("click", customFunction);
  }
}
