import { logger } from "../../loggers";
import * as types from "../../constants/actionTypes";
import { getStateList, getStateProducts, getTableOfContents, getContent } from "../services/agentManualService";

const fetchStateListStarted = () => ({
  type: types.FETCH_STATES_LIST_STARTED
});

const fetchStatelListSucceeded = (data, previousCompanyBranch) => ({
  type: types.FETCH_STATES_LIST_SUCCEEDED,
  payload: data,
  previousCompanyBranch
});

const fetchStateListFailed = (error, previousCompanyBranch) => ({
  type: types.FETCH_STATES_LIST_FAILED,
  payload: error,
  previousCompanyBranch
});

export const fetchStatesListDetails = activeSpecialtyCompanyBranch => {
  return dispatch => {
    dispatch(fetchStateListStarted());
    return getStateList(activeSpecialtyCompanyBranch).then(
      result => {
        dispatch(fetchStatelListSucceeded(result.data, activeSpecialtyCompanyBranch));
      },
      error => {
        dispatch(fetchStateListFailed(error.response || error, activeSpecialtyCompanyBranch));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

export const setSelectedState = (state) => ({
  type: types.SET_SELECTED_STATE,
  payload: state
});

const fetchStateProductsStarted = () => ({
  type: types.FETCH_STATE_PRODUCTS_STARTED
});

const fetchStateProductsSucceeded = (data, stateId) => ({
  type: types.FETCH_STATE_PRODUCTS_SUCCEEDED,
  payload: data,
  previousFetchedStateId: stateId
});

const fetchStateProductsFailed = (error, stateId) => ({
  type: types.FETCH_STATE_PRODUCTS_STARTED_FAILED,
  payload: error,
  previousFetchedStateId: stateId
});

export const fetchStateProducts = stateId => {
  return dispatch => {
    dispatch(fetchStateProductsStarted());
    return getStateProducts(stateId).then(
      result => {
        dispatch(fetchStateProductsSucceeded(result.data, stateId));
      },
      error => {
        dispatch(fetchStateProductsFailed(error.response || error, stateId));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

export const setSelectedProduct = (state) => ({
  type: types.SET_SELECTED_PRODUCT,
  payload: state
});

const fetchTableOfContentsStarted = () => ({
  type: types.FETCH_TABLE_OF_CONTENTS_STARTED
});

const fetchTableOfContentsSucceeded = (data, productId) => ({
  type: types.FETCH_TABLE_OF_CONTENTS_SUCCEEDED,
  payload: data,
  productId
});

const fetchTableOfContentsFailed = (error, productId) => ({
  type: types.FETCH_TABLE_OF_CONTENTS_FAILED,
  payload: error,
  productId
});

export const fetchTableOfContents = productId => {
  return dispatch => {
    dispatch(fetchTableOfContentsStarted());
    return getTableOfContents(productId).then(
      result => {
        dispatch(fetchTableOfContentsSucceeded(result.data, productId))
      },
      error => {
        dispatch(fetchTableOfContentsFailed(error.response || error, productId));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

const fetchContentStarted = () => ({
  type: types.FETCH_CONTENT_STARTED
});

const fetchContentSucceeded = (data, previousFetchedContentId) => ({
  type: types.FETCH_CONTENT_SUCCEEDED,
  payload: data,
  previousFetchedContentId
});

const fetchContentFailed = (error, previousFetchedContentId) => ({
  type: types.FETCH_CONTENT_FAILED,
  payload: error,
  previousFetchedContentId
});

export const fetchContent = contentId => {
  return dispatch => {
    dispatch(fetchContentStarted());
    return getContent(contentId).then(
      result => {
        dispatch(fetchContentSucceeded(result.data, contentId))
      },
      error => {
        dispatch(fetchContentFailed(error.response || error, contentId));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};
