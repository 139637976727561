import { connect } from "react-redux";
import PaymentDetails from "../components/PaymentDetails";
import {
  fetchClaimsPaymentDetails,
  printClaimsPaymentDetails
} from "../actions/claimsActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getInsuredName } from "../../selectors/claimsSelectors";

const mapStateToProps = state => ({
  paymentDetails: state.claims.paymentDetails,
  insuredName: getInsuredName(state),
  employeeUserName: sessionSelector.getEmployeeUserName(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  printPaymentDetails: state.claims.printPaymentDetails,
  claimPaymentDetailsLoading: state.claims.isLoading
});

const mapDispatchToProps = {
  fetchClaimsPaymentDetails,
  printClaimsPaymentDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
