import React, { useEffect } from "react";

const baseUrl = window.env.REACT_APP_LEGACY_SITE_URL;
const REDIRECTION_MESSAGE = "Please wait while we redirect your request...";

const LegacyContentRedirect = () => {

  useEffect(() => {
    const url = window.location.href;
    const urlPath = url.replace(window.location.origin, "");
    if (urlPath) {
      window.open(`${baseUrl}${urlPath}`, "_self");
    }
  }, []);

  return (
    <>
      <div>&nbsp;</div>
      <div className="no-results-info">{REDIRECTION_MESSAGE}</div>
    </>
  );
};

export default LegacyContentRedirect;
