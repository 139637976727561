import * as types from "../constants/actionTypes";
import * as accountholderService from "../services/accountholderService";
import { CLARION_DOOR_POLICY_PREFIX } from "../constants/dictionary";

const fetchPoliciesSearchStarted = searchKeyword => ({
  type: types.FETCH_MY_POLICY_SEARCH_STARTED,
  payload: searchKeyword.trim()
});

const fetchPoliciesSearchSucceeded = data => ({
  type: types.FETCH_MY_POLICY_SEARCH_SUCCEEDED,
  payload: data
});

const fetchPoliciesSearchFailed = error => ({
  type: types.FETCH_MY_POLICY_SEARCH_FAILED,
  payload: error,
  error: true
});

const fetchPoliciesSearchDidNotReturnData = search_type => ({
  type: types.FETCH_MY_POLICY_SEARCH_RETURNED_NODATA,
  payload: search_type,
  error: false
});

const setPolicySearchType = search_type => ({
  type: types.SET_POLICY_SEARCH_TYPE,
  payload: search_type,
  error: false
});

export const dismissPolicyModal = {
  type: types.DISMISS_MODAL_WINDOW
};

export const searchKeywordUpdated = searchKeyword => ({
  type: types.SEARCH_KEYWORD_UPDATED,
  payload: searchKeyword
});

export const searchKeywordUpdatedReportClaims = searchKeyword => ({
  type: types.SEARCH_KEYWORD_UPDATED_REPORT_CLAIMS,
  payload: searchKeyword.trim()
});

export const searchAllLinesForPolicies = (agency_number, search_text) =>
  fetchPoliciesSearchList(
    agency_number,
    search_text,
    accountholderService.SearchPoliciesSearchTypes.All
  );

export const calculateSearchType = search_text => {
  const pattern = /^\d+$/;
  let search_type = "name";
  if (pattern.test(search_text)) {
    search_type =
      search_text.length < 8
        ? "invalid_number"
        : search_text.length === 10
        ? "account"
        : "policy";
  }
  return search_type;
};

export const searchPolicyTermsHistoryStarted = () => ({
  type: types.FETCH_POLICY_TERMS_HISTORY_STARTED
});

export const searchPolicyTermsHistorySuccess = data => ({
  type: types.FETCH_POLICY_TERMS_HISTORY_SUCCESS,
  payload: data
});

export const searchPolicyTermsHistoryFailed = () => ({
  type: types.FETCH_POLICY_TERMS_HISTORY_FAILED
});

export const fetchPoliciesSearchList =
  (agency_number, search_text, search_type, fetchPolicyTermsHistory = false) =>
  dispatch => {
    dispatch(fetchPoliciesSearchStarted(search_text));
    return accountholderService
      .searchPolicies(agency_number, search_text, search_type)
      .then(
        response => {
          const searchType = calculateSearchType(search_text);
          const nonClarionDoorPolicies =
            response?.data?.policies?.filter(policy => {
              return (
                !policy?.policy_number?.startsWith(
                  CLARION_DOOR_POLICY_PREFIX
                ) ?? true
              );
            }) ?? [];
          if (
            (searchType === "policy" && nonClarionDoorPolicies.length > 0) ||
            (searchType !== "policy" &&
              (response?.data?.policies.length ?? 0) > 0)
          ) {
            dispatch(fetchPoliciesSearchSucceeded(response.data));
            return response.data.policies;
          }
          dispatch(fetchPoliciesSearchDidNotReturnData(searchType));
          if (fetchPolicyTermsHistory && searchType === "policy") {
            dispatch(searchPolicyTermsHistoryStarted());
            accountholderService
              .searchPolicyTermsHistory(agency_number, search_text)
              .then(
                response => {
                  dispatch(searchPolicyTermsHistorySuccess(response.data));
                },
                () => {
                  dispatch(searchPolicyTermsHistoryFailed());
                }
              );
          }
        },
        () => {
          // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
          const search_type = calculateSearchType(search_text);
          dispatch(fetchPoliciesSearchFailed(search_type));
          dispatch(setPolicySearchType(search_type));
        }
      );
  };
