import React, { useEffect } from "react";
import {
  ContentHeader,
  Button,
  Currency,
  Form,
  useForm,
  Input,
  FormGroup,
  Checkbox,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import PaymentDetailsTable from "./PaymentDetailsTable";
import { Translations } from "../../components/translations";
import PropTypes from "prop-types";
import * as routes from "../../constants/routes";
import * as verifyPaymentSelector from "./verifyPaymentSelector";

const VerifyPayment = ({
  agencySweepPaymentInfo,
  hasDirectBillPayNowEnrollment,
  directBillPaymentMethodToken,
  isOasisAccount,
  activeAgencyCode,
  paymentDetailsLoading,
  agentFullName,
  processAgencySweepPayment,
  match,
  history,
  agentEmail,
  paymentDetailsResponseStatusCode,
  agentCode
}) => {
  const accountNumber = match.params.account_number;

  useEffect(() => {
    if (!hasDirectBillPayNowEnrollment) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
  }, [hasDirectBillPayNowEnrollment, accountNumber, history]);

  useEffect(() => {
    if (isOasisAccount && !agencySweepPaymentInfo) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}${routes.AGENCY_SWEEP}`
      );
    }
    if (!isOasisAccount && !agencySweepPaymentInfo) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
  }, [isOasisAccount, agencySweepPaymentInfo, accountNumber, history]);

  useEffect(() => {
    if (paymentDetailsResponseStatusCode) {
      history.push(
        `${routes.ACCOUNT_HOME}/${accountNumber}${routes.PAYMENT_CONFIRMATION_AGENCY_SWEEP}`
      );
    }
  }, [paymentDetailsResponseStatusCode, accountNumber, history]);

  const handleSubmission = ({ values }) => {
    const paymentRequest = verifyPaymentSelector.createPaymentSubmissionRequest(
      activeAgencyCode,
      agentFullName,
      agencySweepPaymentInfo,
      directBillPaymentMethodToken,
      values
    );
    processAgencySweepPayment(paymentRequest);
  };

  const handleBackToCompletePaymentClick = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}${
        isOasisAccount ? routes.AGENCY_SWEEP : routes.LEGACY_AGENCY_SWEEP
      }`
    );
  };

  const initialValues = {
    send_confirmation_email: true,
    emails: agentEmail
  };
  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    errors
  } = form;

  const validateEmail = ({ value }) => {
    let errors = "";
    if (values.send_confirmation_email) {
      value
        .trim()
        .split(",")
        .forEach(email => {
          const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if (!emailRegex.test(email.trim())) {
            errors = Translations.VerifyPayment.Invalid_email;
          }
        });
      return errors ? [errors] : [];
    }
  };
  const disableBtn =
    paymentDetailsLoading ||
    (values.send_confirmation_email &&
      (errors && errors.emails && errors.emails.length) > 0);
  return (
    <div className="verify-payment">
      <ContentHeader>{Translations.VerifyPayment.title}</ContentHeader>
      <div className="sub-heading">
        {Translations.VerifyPayment.sub_heading}
      </div>
      <p className="info-text"> {Translations.VerifyPayment.description} </p>
      <ul>
        {Translations.VerifyPayment.payment_guidelines.map(val => (
          <li key={val}>{val}</li>
        ))}
      </ul>
      {agencySweepPaymentInfo &&
        agencySweepPaymentInfo.lineItems &&
        agencySweepPaymentInfo.lineItems.length && (
          <PaymentDetailsTable lineItems={agencySweepPaymentInfo.lineItems} />
        )}
      <div>
        {Translations.VerifyPayment.Payment_Processed}{" "}
        <b>
          {agentFullName}, {agentCode}
        </b>
      </div>
      <Form context={form}>
        <FormGroup>
          <div className="email-check">
            <Checkbox
              type="checkbox"
              name="send_confirmation_email"
              id="send_confirmation_email"
              label={Translations.VerifyPayment.Email_Confirmation}
              value={values.send_confirmation_email}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
            />
          </div>
        </FormGroup>
        {values.send_confirmation_email && (
          <FormGroup>
            <div className="row email-verification">
              <div className="col-12">
                <Input
                  type="text"
                  name="emails"
                  id="emails"
                  placeholder="Email"
                  label={Translations.VerifyPayment.Email}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={values.emails}
                  maxLength={1000}
                  onValidate={validateEmail}
                  required
                />
              </div>
            </div>
          </FormGroup>
        )}
        <h2>
          {" "}
          <b>
            {Translations.VerifyPayment.Total_Payment}{" "}
            <Currency
              amount={
                (agencySweepPaymentInfo &&
                  agencySweepPaymentInfo.totalPaymentAmount) ||
                0
              }
            />{" "}
          </b>{" "}
        </h2>
        <p> {Translations.VerifyPayment.agency_bank_account} </p>
        {paymentDetailsLoading && <LoadingIndicator />}
        <div className="btn-wrapper">
          <Button type="submit" variant="primary" disabled={disableBtn}>
            {" "}
            {paymentDetailsLoading
              ? Translations.VerifyPayment.Submitting_Payment
              : Translations.VerifyPayment.Process_Payment}{" "}
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={handleBackToCompletePaymentClick}
          >
            {" "}
            {Translations.VerifyPayment.Complete_Payment}{" "}
          </Button>
        </div>
      </Form>
      <div className="important-info">
        <b> {Translations.VerifyPayment.Important} </b>{" "}
        {Translations.VerifyPayment.description2}
      </div>
    </div>
  );
};

export default VerifyPayment;

VerifyPayment.propTypes = {
  hasDirectBillPayNowEnrollment: PropTypes.bool.isRequired,
  directBillPaymentMethodToken: PropTypes.string,
  isOasisAccount: PropTypes.bool.isRequired,
  processAgencySweepPayment: PropTypes.func,
  agentFullName: PropTypes.string,
  agencySweepPaymentInfo: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  activeAgencyCode: PropTypes.string,
  paymentDetailsLoading: PropTypes.bool,
  agentEmail: PropTypes.string,
  paymentDetailsResponseStatusCode: PropTypes.number.isRequired,
  agentCode: PropTypes.string
};
