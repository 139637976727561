import "./UnderwriterAlert.scss";

import React, { useContext } from "react";
import { Alert } from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../OnlineQuotingContext";

const UnderwriterAlert = () => {
  const {
    // quoteData,
    stpException,
    getFilteredUnderwriterIssues,
    hasBlockingUwIssues
  } = useContext(OnlineQuotingContext);

  // const uwIssues = getFilteredUnderwriterIssues({ quoteData });
  // const hasWUIssues = uwIssues.length > 0;
  const uwAlertData = getFilteredUnderwriterIssues().some(
    i =>
      i?.currentBlockingPoint === "Rejected" ||
      i?.approvalBlockingPoint === "Rejected"
  )
    ? {
        type: "error",
        label: "Rejected Issues",
        message: "This quote has rejected Underwriter Issues."
      }
    : !stpException && hasBlockingUwIssues
    ? {
        type: "warning",
        label: "Quick Review",
        message: "Submit your quote for a quick underwriter review."
      }
    : stpException && !hasBlockingUwIssues
    ? {
        type: "success",
        label: "On Track",
        message:
          "Your quote is on track to be issued before underwriter review."
      }
    : {
        type: "success",
        label: "On Track",
        message:
          "Your quote is on track to be issued without underwriter review."
      };
  return (
    <>
      <Alert
        block
        className="oq__sidebar__underwriter-alert"
        type={uwAlertData.type}
        label={uwAlertData.label}
        dismissible={false}
      >
        {uwAlertData.message}
      </Alert>
    </>
  );
};

export default UnderwriterAlert;
