import React, { useState } from "react";
import {
  Button,
  FlexRow,
  Form,
  FormatDate,
  Modal,
  Panel,
  useForm
} from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../components/Factory";
import EffectiveExpirationDate from "./components/EffectiveExpirationDate";
import "./QuoteSummary.scss";

export default function QuoteSummary({
  form: quoteForm,
  isValidEffectiveDate
}) {
  const [showModal, setShowModal] = useState(false);

  const {
    effective_date,
    expiration_date,
    account_name,
    first_name,
    last_name,
    address_line,
    city,
    state,
    zip
  } = quoteForm.values;

  const editEffectiveDateForm = useForm({
    values: {
      effective_date,
      expiration_date
    },
    onSubmit: ({ values }) => {
      quoteForm.updateForm({
        values: {
          ...quoteForm.values,
          ...values
        }
      });
      setShowModal(false);
    }
  });

  const { values, errors, invalidFields, updateForm } = editEffectiveDateForm;

  const handleClose = () => {
    updateForm({
      values: {
        effective_date,
        expiration_date
      },
      errors: {
        effective_date: [],
        expiration_date: []
      }
    });
    setShowModal(false);
  };

  return (
    <Panel
      highlight="blue"
      title="Quote Summary"
      className="im__sidebar__summary"
    >
      <div className="im__sidebar__summary__item">
        <b>Effective Date:</b>
        <div>
          <FormatDate
            className={
              isValidEffectiveDate
                ? "im__sidebar__summary__effective-date"
                : "im__sidebar__summary__effective-invalid-date"
            }
            date={effective_date || ""}
            format="MM/DD/YYYY"
          />
          <Button
            isLink
            variant="primary"
            className="im__sidebar__summary__edit-effective-date"
            onClick={() => setShowModal(true)}
          >
            Edit Effective Date
          </Button>
        </div>
      </div>
      <div className="im__sidebar__summary__item">
        <b>Insured Information</b>
        <div>
          {!!(account_name || "") ? (
            <span>{account_name}</span>
          ) : (
            <span>
              {first_name || ""} {last_name || ""}
            </span>
          )}
        </div>
        <div>{toTitleCase(address_line || "")}</div>
        <div>
          <span className="im__sidebar__summary__city">
            {toTitleCase(city || "")}
          </span>
          ,
          <span className="im__sidebar__summary__state">
            {(state || "").toUpperCase()}
          </span>
          <span className="im__sidebar__summary__postalCode">{zip || ""}</span>
          <span className="im__sidebar__summary__addressType">(Primary)</span>
        </div>
      </div>
      <div className="im__sidebar__summary__item">
        <b>Product(s):</b>
        <span>Inland Marine Builders&apos; Risk</span>
      </div>

      <Modal
        title="Edit Effective Date"
        show={showModal}
        onHide={handleClose}
        body={
          <Form context={editEffectiveDateForm}>
            <EffectiveExpirationDate form={editEffectiveDateForm} />
            <FlexRow align="right">
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !values?.effective_date ||
                  !!Object.keys(errors)?.length ||
                  !!invalidFields?.length ||
                  values?.effective_date === quoteForm?.values?.effective_date
                }
              >
                Update
              </Button>
            </FlexRow>
          </Form>
        }
      />
    </Panel>
  );
}
