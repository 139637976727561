import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomerContainer from "./components/CustomerContainer";
import LineOfBusinessContainer from "./components/LineOfBusinessContainer";
import QuoteDetailsContainer from "./components/QuoteDetailsContainer";
import CustomerAddressValidationContainer from "./components/CustomerAddressValidationContainer";
import PilotAgentsContainer from "./components/PilotAgentsContainer";
import PilotAddressValidationContainer from "./components/PilotAddressValidationContainer";
import * as routes from "./../../constants/routes";
import * as keys from "../../constants/localStorageKeys";

const StartNewQuote = ({ currentStep, quoteStarted, history }) => {
  // useEffect here because: Render methods should be a pure function of props and state...
  // Stateless functional components are expected to be pure functions, i.e. contain no side effects
  useEffect(() => {
    if (quoteStarted && currentStep === "oasis") {
      window.localStorage.setItem(keys.QUOTE_SUPPORT_DATA, null);
      window.localStorage.setItem(keys.CURRENT_QUOTE_DATA, null);
      window.localStorage.setItem(keys.CURRENT_STEP, 1);
      history.push(routes.ONLINE_QUOTING_STEP1);
    }
  }, [currentStep, history, quoteStarted]);

  if (!quoteStarted) {
    return null;
  }
  switch (currentStep) {
    case "lineOfBusiness":
      return <LineOfBusinessContainer />;
    case "oasis":
      // is handled in the useEffect above
      return null;
    case "ufg_core":
      return <CustomerContainer />;
    case "quoteDetails":
      return <QuoteDetailsContainer />;
    case "addressValidation":
      return <CustomerAddressValidationContainer />;
    case "pilotAgents":
      return <PilotAgentsContainer />;
    case "pilotAddressValidation":
      return <PilotAddressValidationContainer />;
    default:
      return null;
  }
};

StartNewQuote.propTypes = {
  currentStep: PropTypes.string.isRequired,
  quoteStarted: PropTypes.bool,
  history: PropTypes.any
};

export default StartNewQuote;
