import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import * as paymentConfirmationSelector from "./paymentConfirmationSelector";
import * as completePaymentSelector from "../complete-payment/completePaymentSelector";
import PaymentConfirmationRouter from "./PaymentConfirmationRouter";
import * as verifyPaymentSelector from "../verify-payment/verifyPaymentSelector";
import {
  clearAgencySweepPayment,
  sendOasisPaymentConfirmation,
  fetchAgencySweepPaymentReport
} from "./agencySweepPaymentConfirmationAction";

const mapStateToProps = state => ({
  paymentDetails: state.agencySweepPaymentConfirmation.paymentDetails,
  insuredName: paymentConfirmationSelector.getPaymentRequestInsuredName(state),
  paymentDetailsResponseStatusCode: paymentConfirmationSelector.getPaymentDetailsResponseStatusCode(
    state
  ),
  agencySweepPaymentInfo: verifyPaymentSelector.getPaymentInformation(state),
  paymentReportLoading:
    state.agencySweepPaymentConfirmation.paymentReportLoading,
  isOasisPaymentConfirmationLoading: paymentConfirmationSelector.isOasisPaymentConfirmationLoading(
    state
  ),
  isOasisPayment: completePaymentSelector.isOasisAccount(state),
  originalOasisInvoiceDetails: paymentConfirmationSelector.getOriginalOasisInvoiceDetails(
    state
  ),
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  clearAgencySweepPayment,
  sendOasisPaymentConfirmation,
  fetchAgencySweepPaymentReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmationRouter);
