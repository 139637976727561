import * as types from "../constants/actionTypes";
import { fetchServiceCenterProducerDetails } from "../reports/actions/serviceCenterAction";
import { getSelectedAgencyCode } from "../selectors/agencySelectors";

export const agencyNumberReducerUpdated = agencyCode => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.AGENCY_CODE_CHANGED,
    payload: isNaN(parseInt(agencyCode, 10)) ? null : agencyCode
  });

  // We want the selected agency code and not the one provided. If the provided
  // agency code is undefined, null, empty, or similar the selector will handle
  // those scenarios and return the appropriate agency code.
  const selectedAgencyCode = getSelectedAgencyCode(getState());
  dispatch(fetchServiceCenterProducerDetails(selectedAgencyCode));
};
