export const FETCH_CLASS_APPETITE_STATES_STARTED =
  "FETCH_CLASS_APPETITE_STATES_STARTED";
export const FETCH_CLASS_APPETITE_STATES_SUCCEEDED =
  "FETCH_CLASS_APPETITE_STATES_SUCCEEDED";
export const FETCH_CLASS_APPETITE_STATES_FAILED =
  "FETCH_CLASS_APPETITE_STATES_FAILED";

export const FETCH_CLASS_APPETITE_PRODUCTS_STARTED =
  "FETCH_CLASS_APPETITE_PRODUCTS_STARTED";
export const FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED =
  "FETCH_CLASS_APPETITE_PRODUCTS_SUCCEEDED";
export const FETCH_CLASS_APPETITE_PRODUCTS_FAILED =
  "FETCH_CLASS_APPETITE_PRODUCTS_FAILED";

export const CLASS_APPETITE_SET_SELECTED_CLASSIFICATION =
  "CLASS_APPETITE_SET_SELECTED_CLASSIFICATION";

export const FETCH_CLASS_APPETITE_CLASSES_STARTED =
  "FETCH_CLASS_APPETITE_CLASSES_STARTED";
export const FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED =
  "FETCH_CLASS_APPETITE_CLASSES_SUCCEEDED";
export const FETCH_CLASS_APPETITE_CLASSES_FAILED =
  "FETCH_CLASS_APPETITE_CLASSES_FAILED";
