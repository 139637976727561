export const ProducerTypes = {
  nonProducer: "N",
  producer: "P"
};

export const ProducerStatuses = {
  active: "A"
};

export const LOB = [
  {
    id: "personal_lines",
    label: "Personal Lines"
  },
  {
    id: "commercial_lines",
    label: "Commercial Lines"
  }
];

export const QUOTE_TYPES = [
  {
    id: "standard_quote",
    label: "Standard Quote"
  },
  {
    id: "pilot_quote",
    label: "Pilot Quote"
  }
];

export const PRODUCT_TYPES = [
  {
    id: "GL7GeneralLiability",
    label: "General Liability"
  },
  {
    id: "CA7CommAuto",
    label: "Commercial Auto"
  },
  {
    id: "UniPak",
    label: "Uni-Pak"
  }
];

export const CUSTOMER_TYPES = [
  {
    id: "Person",
    label: "Person"
  },
  {
    id: "Company",
    label: "Company"
  }
];

export const STATE_OPTIONS = [
  {
    value: "",
    label: "-- Select State --"
  }
];

export const CITY_OPTIONS = [
  {
    value: "",
    label: "-- Select City --"
  }
];

export const PILOT_STATES = ["CO", "ID", "MT", "NM", "UT", "WY"];

const PERSON_LEGAL_ENTITY_OPTIONS = [
  {
    value: "foreignlimitedpartnership",
    label: "Foreign Limited Partnership"
  },
  {
    value: "generalpartnership",
    label: "General Partnership"
  },
  {
    value: "individual",
    label: "Individual"
  },
  {
    value: "jointventure",
    label: "joint Venture"
  },
  {
    value: "partnership",
    label: "Partnership"
  },
  {
    value: "solepropship",
    label: "Sole Proprietor"
  },
  {
    value: "other",
    label: "Other"
  }
];

const COMPANY_LEGAL_ENTITY_OPTIONS = [
  {
    value: "association",
    label: "Association"
  },
  {
    value: "corporation",
    label: "Corporation"
  },
  {
    value: "ccorporation",
    label: "C Corporation"
  },
  {
    value: "scorporation",
    label: "S Corporation"
  },
  {
    value: "domesticprofitcorporation",
    label: "Domestic Profit Corporation"
  },
  {
    value: "foreigncorporation",
    label: "Foreign Corporation"
  },
  {
    value: "foreignlimitedliabilitycompany",
    label: "Foreign Limited Liability Company"
  },
  {
    value: "foreignlimitedpartnership",
    label: "Foreign Limited Partnership"
  },
  {
    value: "generalpartnership",
    label: "General Partnership"
  },
  {
    value: "government",
    label: "Governmental Unit"
  },
  {
    value: "jointventure",
    label: "Joint Venture"
  },
  {
    value: "limitedcorporation",
    label: "Limited Corporation"
  },
  {
    value: "llp",
    label: "Limited Liability Partnership"
  },
  {
    value: "limitedpartnership",
    label: "Limited Partnership"
  },
  {
    value: "nonprofitcorporation",
    label: "Nonprofit Corporation"
  },
  {
    value: "partnership",
    label: "Partnership"
  },
  {
    value: "professionalcorporation",
    label: "Professional Corporation"
  },
  {
    value: "religiousorganization",
    label: "Religious Organization"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const getLegalEntityOptions = customerType => {
  switch (customerType) {
    case "Person":
      return PERSON_LEGAL_ENTITY_OPTIONS;
    case "Company":
      return COMPANY_LEGAL_ENTITY_OPTIONS;
    default:
      return [];
  }
};
