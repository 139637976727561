import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const quickEligibilityList = (
  state = initialState.quickEligibilityList,
  action
) => {
  switch (action.type) {
    case types.FETCH_ELIGIBILITY_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_ELIGIBILITY_SUCCEEDED:
      return {
        ...state,
        eligibilityData: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_ELIGIBILITY_FAILED:
      return {
        ...state,
        eligibilityData: [],
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default quickEligibilityList;
