import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "@ufginsurance/ui-kit";

export default function AccountUwModal({
  underwriter,
  showUwModal,
  setShowUwModal
}) {
  return (
    <div>
      <Modal
        title={
          <strong>{`Your Underwriter: ${underwriter.first_name}  ${underwriter.last_name}`}</strong>
        }
        show={showUwModal}
        onHide={setShowUwModal}
        className="uw-info-modal"
        body={
          <div className="uw-info-modal__details">
            <img
              src={`${window.env.REACT_APP_LEGACY_SITE_URL}/agentsonly/mugs/${underwriter.photo_id}.jpg`}
              alt={`{underwriter.first_name} {underwriter.first_name}`}
            />
            <div>
              <p>
                <span className="bold">
                  {underwriter.first_name} {underwriter.last_name}
                </span>
                <br />
                <em>{underwriter.title}</em>
              </p>
              <div className="contact-info">
                {underwriter.phone_number && (
                  <p>
                    <span className="bold">Phone:</span>{" "}
                    {underwriter.phone_number}
                  </p>
                )}
                {underwriter.toll_free_number && (
                  <p>
                    <span className="bold">Toll free:</span>{" "}
                    {underwriter.toll_free_number}
                  </p>
                )}
                {underwriter.fax_number && (
                  <p>
                    <span className="bold">Fax:</span> {underwriter.fax_number}
                  </p>
                )}
                {underwriter.email && (
                  <p>
                    <span className="bold">Email: </span>
                    <a href={`mailto:${underwriter.email}`}>
                      {underwriter.email}
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        }
        footer={<Button onClick={() => setShowUwModal(false)}>Close</Button>}
      />
    </div>
  );
}

AccountUwModal.propTypes = {
  underwriter: PropTypes.object.isRequired,
  showUwModal: PropTypes.bool.isRequired,
  setShowUwModal: PropTypes.func.isRequired
};
