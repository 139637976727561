import React from "react";
import {
  Button,
  FlexRow,
  InputEmail,
  Form,
  FormGroup,
  Input,
  Modal,
  useForm
} from "@ufginsurance/ui-kit";

export default function NewProducerModal({
  showNewProducerModal,
  setShowNewProducerModal,
  form: parentForm
}) {
  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: ""
  };

  const form = useForm({
    values: initialValues,
    onSubmit: () => {}
  });

  const {
    values,
    errors,
    invalidFields,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    updateForm
  } = form;

  const resetForm = () => {
    updateForm({
      values: initialValues,
      errors: {
        first_name: [],
        last_name: [],
        phone: [],
        email: []
      }
    });
  };

  const handleSubmit = () => {
    parentForm.updateForm({
      values: {
        ...parentForm.values,
        new_producer: values,
        producer_code: "-1",
        producer: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone
        }
      },
      errors: {
        ...parentForm.errors,
        producer_code: [],
        producer: []
      }
    });

    resetForm();
    setShowNewProducerModal(false);
  };

  const handleClose = () => {
    const hasNewProducer = !!parentForm.values?.new_producer?.email;

    parentForm.updateForm({
      values: {
        ...parentForm.values,
        producer_code: hasNewProducer ? "-1" : "",
        producer: null
      },
      errors: {
        ...parentForm.errors,
        producer_code: [],
        producer: []
      }
    });
    resetForm();
    setShowNewProducerModal(false);
  };

  return (
    <Modal
      show={showNewProducerModal}
      onHide={handleClose}
      title="Create Producer"
      size="md"
      body={
        <Form context={form}>
          <FormGroup>
            <Input
              id="first_name"
              name="first_name"
              label="First Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.first_name}
              size="50p"
              required
            />
            <Input
              id="last_name"
              name="last_name"
              label="Last Name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.last_name}
              size="50p"
              required
            />
          </FormGroup>

          <FormGroup>
            <Input
              id="phone"
              name="phone"
              label="Phone Number"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.phone}
              size="50p"
              mask="phone"
              required
            />
            <InputEmail
              id="email"
              name="email"
              label="Email"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.email}
              size="50p"
              required
            />
          </FormGroup>

          <FlexRow align="right">
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={
                !!Object.keys(errors)?.length || !!invalidFields?.length
              }
            >
              Add Producer
            </Button>
          </FlexRow>
        </Form>
      }
    />
  );
}
