import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import ClassAppetiteGuide from "../components/ClassAppetiteGuide";
import {
  fetchStatesList,
  fetchProductsList,
  fetchClassesList,
  setSelectedClassification
} from "../actions/classAppetiteActions";

const mapStateToProps = state => ({
  stateList: state.classAppetiteGuide.stateList,
  defaultState: sessionSelector.getActiveAgencyState(state),
  productList: state.classAppetiteGuide.productList,
  classifications: state.classAppetiteGuide.classifications,
  selectedClassification: state.classAppetiteGuide.selectedClassification,
  isHomeAgent: sessionSelector.isHomeAgent(state)
});

const mapDispatchToProps = {
  fetchStatesList,
  fetchProductsList,
  fetchClassesList,
  setSelectedClassification
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassAppetiteGuide);
