import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator, Accordion, Currency } from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";
import BillingActivityTable from "./BillingActivityTable";
import BillingActivityAccordionHeader from "./BillingActivityAccordionHeader";
import { ACTIVE_IN_SERVICE_CENTER } from "../../../constants/dictionary";
import BillingAgencySweepButtons from "./BillingAgencySweepButtons";

const hasAccountBillingActivity = accountBilled =>
  accountBilled && accountBilled.length > 0;

const hasPolicyBilledActivity = policyBilled =>
  policyBilled && policyBilled.length > 0;

const AccountBillingActivity = ({
  accountNumber,
  accountData,
  activeAgencyCode,
  billingActivity,
  hasDirectBillPayNowEnrollment,
  fetchBillingActivityStatement,
  fetchDirectBillPayNowEnrollment,
  getGWViewStatementPDF,
  getLegacyViewStatementPDF,
  is109015Agent,
  isAgencyAdministrator,
  copyAccountAndBillingActivity,
  isOasisAccount
}) => {
  useEffect(() => {
    fetchBillingActivityStatement(accountNumber, activeAgencyCode);
  }, [accountNumber, activeAgencyCode, fetchBillingActivityStatement]);

  useEffect(() => {
    fetchDirectBillPayNowEnrollment(activeAgencyCode);
  }, [activeAgencyCode, fetchDirectBillPayNowEnrollment]);

  const hasBillingActivity = () => {
    const { accountBilled, policyBilled } = billingActivity;

    return (
      hasAccountBillingActivity(accountBilled) ||
      hasPolicyBilledActivity(policyBilled)
    );
  };

  const {
    accountBilled,
    policyBilled,
    accountBalance,
    accountMinimumDueWithFees,
    automaticPayments,
    isLoading,
    finalAudit
  } = billingActivity;
  const { Billing_Details } = Translations;
  const { Account_Billing_Activity } = Billing_Details;
  const ufgPolicyholder = window.env.REACT_APP_UFG_POLICYHOLDER_BASEURL;
  const emailTo = window.env.REACT_APP_EMAIL_ID;

  return (
    <div className="billing-activity">
      <Accordion
        accordionIndex={0}
        isOpen
        triggerContent={<BillingActivityAccordionHeader />}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="billing-activity account-activity-container">
            <div className="agency-sweep-buttons">
              <BillingAgencySweepButtons
                accountNumber={accountNumber}
                accountData={accountData}
                billingActivity={billingActivity}
                hasDirectBillPayNowEnrollment={hasDirectBillPayNowEnrollment}
                is109015Agent={is109015Agent}
                activeAgencyCode={activeAgencyCode}
                isAgencyAdministrator={isAgencyAdministrator}
                copyAccountAndBillingActivity={copyAccountAndBillingActivity}
                isOasisAccount={isOasisAccount}
              />
            </div>

            {hasAccountBillingActivity(accountBilled) && (
              <div>
                <p className="bold">
                  {Translations.formatString(
                    Account_Billing_Activity.account_number_label,
                    accountNumber
                  )}
                </p>
                {finalAudit && (
                  <div>
                    <p className="italic">
                      {accountData.is_service_center &&
                      accountData.service_center_status &&
                      accountData.service_center_status ===
                        ACTIVE_IN_SERVICE_CENTER
                        ? Account_Billing_Activity.final_audit_sc
                        : Account_Billing_Activity.final_audit}
                    </p>
                  </div>
                )}

                {!finalAudit && (
                  <div>
                    <p className="italic">
                      {Account_Billing_Activity.Billed_By_Account}
                    </p>
                    <BillingActivityTable
                      billingActivityList={accountBilled}
                      agencyCode={activeAgencyCode}
                      getLegacyViewStatementPDF={getLegacyViewStatementPDF}
                      accountNumber={accountNumber}
                      billingActivity={billingActivity}
                      is109015Agent={is109015Agent}
                      getGWViewStatementPDF={getGWViewStatementPDF}
                      billedBy="account"
                      minimumDue={billingActivity.accountMinimumDueWithFees}
                      balance={billingActivity.accountBalance}
                      isOasisAccount={isOasisAccount}
                    />
                    <p className="m-t accountBillSummary">
                      {Translations.formatString(
                        Account_Billing_Activity.Account_text,
                        accountNumber,
                        <span className="hint-para">
                          <Currency amount={accountBalance} />
                        </span>,
                        <span
                          id="accountMinimumDueWithFees"
                          className="hint-para"
                        >
                          <Currency amount={accountMinimumDueWithFees} />
                        </span>
                      )}
                      <br />
                      {automaticPayments === false ? (
                        <span>
                          {
                            Account_Billing_Activity.Auomatic_Payments
                              .Unregistered
                          }
                          &nbsp;
                          <a
                            href={ufgPolicyholder}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Account_Billing_Activity.ufgPolicyholder}
                          </a>
                        </span>
                      ) : (
                        <span>
                          {
                            Account_Billing_Activity.Auomatic_Payments
                              .Registered
                          }
                        </span>
                      )}
                    </p>
                  </div>
                )}
                <hr />
              </div>
            )}

            {!finalAudit && hasPolicyBilledActivity(policyBilled) && (
              <div>
                <div className="account-billing-policy-wording">
                  {Account_Billing_Activity.Billed_By_Policy}
                </div>
                <div>
                  <BillingActivityTable
                    billingActivityList={policyBilled}
                    agencyCode={activeAgencyCode}
                    getLegacyViewStatementPDF={getLegacyViewStatementPDF}
                    accountNumber={accountNumber}
                    billingActivity={billingActivity}
                    is109015Agent={is109015Agent}
                    getGWViewStatementPDF={getGWViewStatementPDF}
                    billedBy="policy"
                    isOasisAccount={isOasisAccount}
                  />
                </div>
                <hr />
              </div>
            )}

            {!finalAudit && hasBillingActivity() ? (
              <div>
                <p className="query-mail">
                  {Account_Billing_Activity.Contact}
                  <a href={`mailto:${emailTo}`}>
                    {Account_Billing_Activity.QueryMail}
                  </a>
                </p>
              </div>
            ) : !finalAudit ? (
              <p>{Account_Billing_Activity.no_results_error_message}</p>
            ) : null}
          </div>
        )}
      </Accordion>
    </div>
  );
};

AccountBillingActivity.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  accountData: PropTypes.object.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  billingActivity: PropTypes.object.isRequired,
  hasDirectBillPayNowEnrollment: PropTypes.bool.isRequired,
  fetchBillingActivityStatement: PropTypes.func,
  fetchDirectBillPayNowEnrollment: PropTypes.func,
  getGWViewStatementPDF: PropTypes.func,
  getLegacyViewStatementPDF: PropTypes.func,
  is109015Agent: PropTypes.bool.isRequired,
  isAgencyAdministrator: PropTypes.bool.isRequired,
  copyAccountAndBillingActivity: PropTypes.func.isRequired,
  isOasisAccount: PropTypes.bool.isRequired
};

export default AccountBillingActivity;
