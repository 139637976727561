import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ClassAppetiteGuideSelector from "./ClassAppetiteGuideSelector";
import ClassAppetiteGuideClassifications from "./ClassAppetiteGuideClassifications";
import ClassAppetiteGuideAppetiteDetail from "./ClassAppetiteGuideAppetiteDetail";
import ClassAppetiteGuideFilter from "./ClassAppetiteGuideFilter";
import { ContentHeader } from "@ufginsurance/ui-kit";
import { filterClassifications } from "./ClassificationsUtil.js";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";

const ClassAppetiteGuide = ({
  fetchStatesList,
  fetchProductsList,
  fetchClassesList,
  setSelectedClassification,
  stateList,
  defaultState,
  productList,
  classifications,
  selectedClassification,
  history,
  isHomeAgent
}) => {
  const [
    isClassificationsContainerEnabled,
    setIsClassificationsContainerEnabled
  ] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (defaultState) {
      fetchStatesList().then(fetchProductsList(defaultState));
    }
  }, [fetchStatesList, fetchProductsList, defaultState]);

  const handleStateDropdownChange = state => {
    fetchProductsList(state);
    setSelectedClassification(null);
    setIsClassificationsContainerEnabled(false);
    setSelectedState(state);
  };

  const handleProductDropdownChange = () => {
    setSelectedClassification(null);
    setIsClassificationsContainerEnabled(false);
  };

  const handleSubmitButtonOnClick = (state, product) => {
    if (product === window.env.REACT_APP_CLASS_APPETITE_BOP_PRO_PRODUCT_CODE) {
      if (isHomeAgent) {
        window.open(
          window.env.REACT_APP_HOME_AGENT_CLASS_APPETITE_NEW_WINDOW_URL,
          "_blank"
        );
      } else {
        history.push(routes.ONLINE_QUOTING_APPETITE);
      }
    } else {
      fetchClassesList(state, product);
    }
    setIsClassificationsContainerEnabled(
      product !== window.env.REACT_APP_CLASS_APPETITE_BOP_PRO_PRODUCT_CODE
    );
    setSelectedState(state);
    setSelectedProduct(product);
    setFilterText("");
  };

  const handleFilterChange = changedFilterText => {
    if (typeof changedFilterText !== "string") {
      setFilterText(changedFilterText?.target?.value ?? "");
    } else {
      setFilterText(changedFilterText);
    }
  };

  const handleClassificationItemClick = (code_sequence, classifications) => {
    const code = code_sequence?.substring(0, code_sequence?.indexOf("_"));
    const sequence = code_sequence?.substring(
      code_sequence?.indexOf("_") + 1,
      code_sequence?.length
    );
    const classItem = classifications?.find(
      e => e.code === code && e.sequence === sequence
    );
    setSelectedClassification(classItem);
  };

  const filteredClassifications = filterClassifications(
    classifications,
    filterText
  );
  return (
    <div className="class-appetite-container">
      <ContentHeader>Class Appetite Guide</ContentHeader>
      <ClassAppetiteGuideSelector
        stateList={stateList}
        defaultState={defaultState}
        productList={productList}
        handleStateChange={handleStateDropdownChange}
        handleProductChange={handleProductDropdownChange}
        handleButtonClick={handleSubmitButtonOnClick}
      />
      {classifications &&
        classifications.length > 0 &&
        isClassificationsContainerEnabled && (
          <div className="row">
            <h3 className="blackText classifications-header">
              {Translations.classAppetiteGuide.available_classes}
            </h3>
            <ClassAppetiteGuideFilter
              handleFilterChange={handleFilterChange}
              filterText={filterText}
            />
            <div className="col-md-5 class-appetite">
              <div className="classifications">
                <ClassAppetiteGuideClassifications
                  classifications={filteredClassifications}
                  productCode={selectedProduct}
                  stateCode={selectedState}
                  handleClassificationItemClick={handleClassificationItemClick}
                  selectedClassification={selectedClassification}
                />
              </div>
            </div>
            <div aria-hidden className="col-md-7 class-appetite-detail">
              {selectedClassification && (
                <ClassAppetiteGuideAppetiteDetail
                  selectedState={selectedState}
                  selectedProduct={selectedProduct}
                  classItem={selectedClassification}
                />
              )}
            </div>
          </div>
        )}
    </div>
  );
};

ClassAppetiteGuide.propTypes = {
  fetchStatesList: PropTypes.func,
  fetchProductsList: PropTypes.func,
  fetchClassesList: PropTypes.func,
  setSelectedClassification: PropTypes.func,
  stateList: PropTypes.array,
  defaultState: PropTypes.string,
  productList: PropTypes.array,
  classifications: PropTypes.array,
  selectedClassification: PropTypes.object,
  history: PropTypes.object.isRequired,
  isHomeAgent: PropTypes.bool.isRequired
};

export default ClassAppetiteGuide;
