import { convertStatesAndAbbreviations } from "../../../../components/Factory";

// OOQ-4429 filter stateExclusion by state/coverableName just for ca7CommAuto
export const autoCoverageControls = ({
  coverableName,
  productLine,
  quoteData
}) => {
  if (productLine !== "ca7CommAuto") return {};

  /**
   * example of quoteData?.lobData?.ca7CommAuto?.coverables?.jurisdictions object:
   *
   * "jurisdictions": [
          {
            "hiredAuto": "Yes",
            "jurisdiction": "NJ",
            "nonOwnedAutoWanted": "No",
            "funeralDirectorMedicalPayments": "No",
            "unAndUnderinsuredMotoristNoOfIndNamedInsured": 0,
            "entityName": "entity.CA7Jurisdiction",
            "fixedId": 200328
          }
        ],
   */
  const stateAbbr = convertStatesAndAbbreviations(coverableName, "abbr");
  const nonOwnedAuto = (
    quoteData?.lobData?.ca7CommAuto?.coverables?.jurisdictions || []
  ).find(f => f.jurisdiction === stateAbbr && !!f.nonOwnedAuto)?.nonOwnedAuto;

  return {
    formData: {
      coverageControl: [
        {
          name: "Non Owned Auto Liability Extended Partners",
          codeIdentifier: "CA7NonOwnedAutoLiabExtendedPartnersNOA",
          control:
            nonOwnedAuto?.caNonOwnershipRiskType ===
            "Other than Auto Services Partnership or LLC"
              ? "visible"
              : "hidden",
          termControl: [
            {
              name: "Extended Coverage For Partners",
              patternCode: "CA7ExtendedCovForPartners",
              control: "hidden"
            }
          ]
        }
      ]
    }
  };
};
