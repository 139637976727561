import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../loggers";

/*--------AGENCY ACCOUNTS SECTION---------- */
const fetchSuretyQuoteAgencyDataStarted = () => ({
  type: types.FETCH_SURETY_QUOTE_AGENCY_DATA_STARTED
});

const fetchSuretyQuoteAgencyDataSucceeded = data => ({
  type: types.FETCH_SURETY_QUOTE_AGENCY_DATA_SUCCEEDED,
  payload: data
});

const fetchSuretyQuoteAgencyDataFailed = error => ({
  type: types.FETCH_SURETY_QUOTE_AGENCY_DATA_FAILED,
  payload: error
});

export const fetchSuretyQuoteAgencyData = activeAgencyCode => dispatch => {
  dispatch(fetchSuretyQuoteAgencyDataStarted());
  return quickQuotingServices.getAgencyData(activeAgencyCode).then(
    response => {
      dispatch(fetchSuretyQuoteAgencyDataSucceeded(response.data));
    },
    error => {
      dispatch(fetchSuretyQuoteAgencyDataFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
