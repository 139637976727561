import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import { FormGroup, Form, useForm, Dropdown, Button } from "@ufginsurance/ui-kit";

const getReportDates = (
  commercialReportDates,
  personalReportDates,
  selectedLine
) => {
  const dates =
    selectedLine === "commercial" ? commercialReportDates : personalReportDates;
  return [{ value: "", label: "-- Select One --" }].concat(
    dates.map(d => ({ value: d.date, label: d.date }))
  );
};

const ServiceCenterForm = ({
  onSubmit,
  commercialDateList,
  personalDateList
}) => {
  const { selectLine } = Translations.reports.tabAgency.monthlyExperienceTab;

  const initialValues = {
    selectLine: "commercial",
    reportDate: null
  };

  const handleSubmit = ({ values }) => {
    // onSubmit(values,resetForm)
    // tested with 151134
    onSubmit(values, () => updateForm({ values: initialValues }));
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  return (
    <div className="reports-form__container">
      <div className="reports-form__note">View the monthly summary report</div>
      <Form className="service-center-form" context={form}>
        <FormGroup>
          <Dropdown
            id="selectLine"
            name="selectLine"
            label="Select Line"
            options={Object.entries(selectLine).map(([key, value]) => ({
              value: key,
              label: value
            }))}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.selectLine}
            size="lg"
          />
        </FormGroup>

        <FormGroup>
          <Dropdown
            id="reportDate"
            name="reportDate"
            label="Select Report Date"
            options={getReportDates(
              commercialDateList,
              personalDateList,
              values.selectLine
            )}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.reportDate}
            size="lg"
          />
        </FormGroup>
        <FormGroup className="pad-top">
          <Button type="submit" variant="primary">
            {Translations.reports.tabClaims.agencyLossRun.label.getReport}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

ServiceCenterForm.propTypes = {
  onSubmit: PropTypes.func,
  commercialDateList: PropTypes.array,
  personalDateList: PropTypes.array
};

export default ServiceCenterForm;
