import * as types from "../quickQuotingActionTypes";
import initialState from "../../../reducers/initialState";

const agencyProducersListReducer = (
  state = initialState.agencyProducersList,
  action
) => {
  switch (action.type) {
    case types.FETCH_AGENCY_PRODUCERS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_AGENCY_PRODUCERS_SUCCEEDED:
      return {
        ...state,
        producerData: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_AGENCY_PRODUCERS_FAILED:
      return {
        ...state,
        producerData: [],
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default agencyProducersListReducer;
