import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator, ButtonBar, Button } from "@ufginsurance/ui-kit";
import PrintAutoIdCardsContainer from "./PrintAutoIdCardsContainer";
import AuditBreakdownModalContainer from "../../../audit-breakdown/containers/AuditBreakdownModalContainer";
import DriversListErrorModal from "./DriversListErrorModal";
import VehiclesListErrorModal from "./VehiclesListErrorModal";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

const accountServiceButtonIds = {
  SHOW_AUDIT_BREAKDOWN: "show_audit_breakdown",
  SHOW_AUTO_ID_CARDS: "show_auto_id_cards",
  SHOW_UPDATE_DRIVERS_LIST: "show_update_drivers_list",
  SHOW_DRIVERS_LIST: "show_drivers_list",
  SHOW_VEHICLES_LIST: "show_vehicle_list",
  SHOW_360_VALUE: "show_go_to_360_value",
  SHOW_RESIDENTIAL_VALUATION: "show_residential_valuation",
  SHOW_PRESCRIPTION_CARDS: "show_rx_cards"
};

const AccountServices = ({
  actions,
  activeAgencyCode,
  isLoading,
  policyInfo,
  requestedPolicyNumber,
  fetchAutoIdCards,
  fetchAccountServiceLinks,
  fetchDriversListReport,
  fetchVehiclesListReport,
  insuredName,
  activeAgencyName,
  isSpecialtyPortalView,
  driversListReportError,
  isDriversListReportLoading,
  vehiclesListReportError,
  isVehiclesListReportLoading
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showAudits, setShowAudits] = useState(false);
  const [showDriversListModal, setShowDriversListModal] = useState(false);
  const [showVehiclesListModal, setShowVehiclesListModal] = useState(false);

  useEffect(() => {
    if (
      policyInfo.policyNumber &&
      policyInfo.policyNumber !== requestedPolicyNumber &&
      activeAgencyCode &&
      !isLoading
    ) {
      fetchAccountServiceLinks(
        policyInfo.policyNumber,
        activeAgencyCode,
        policyInfo.company_branch,
        policyInfo.effective_date
      );
    }
  }, [
    policyInfo,
    requestedPolicyNumber,
    activeAgencyCode,
    isLoading,
    fetchAccountServiceLinks
  ]);

  const handleUFG360 = () => {
    if (isSpecialtyPortalView) {
      return window.open("https://www.360-value.com/apps/iv/Start");
    }
    return window.open(
      `${baseLink}/AgentsOnly/ufgAgent/Commercial/360Value.aspx`
    );
  };

  const handleDriversListClick = () => {
    if (!isDriversListReportLoading) {
      fetchDriversListReport({
        policy_number: policyInfo.policyNumber,
        effective_date: policyInfo.effective_date,
        expiration_date: policyInfo.expiration_date,
        company_branch: policyInfo.company_branch,
        agency_code: activeAgencyCode,
        agency_name: activeAgencyName ? activeAgencyName : "",
        insured_name: insuredName ? insuredName : ""
      });
    }
  };

  useEffect(() => {
    if (driversListReportError) {
      setShowDriversListModal(true);
    }
  }, [driversListReportError, setShowDriversListModal]);

  const handleVehiclesListClick = () => {
    if (!isVehiclesListReportLoading) {
      fetchVehiclesListReport({
        policy_number: policyInfo.policyNumber,
        effective_date: policyInfo.effective_date,
        expiration_date: policyInfo.expiration_date,
        company_branch: policyInfo.company_branch,
        agency_code: activeAgencyCode,
        agency_name: activeAgencyName ? activeAgencyName : "",
        insured_name: insuredName ? insuredName : ""
      });
    }
  };

  useEffect(() => {
    if (vehiclesListReportError) {
      setShowVehiclesListModal(true);
    }
  }, [vehiclesListReportError, setShowVehiclesListModal]);

  const getActions = () => [
    {
      id: accountServiceButtonIds.SHOW_AUDIT_BREAKDOWN,
      label: " View Audit Breakdown",
      icon: "fasList",
      content: (
        <AuditBreakdownModalContainer
          show={showAudits}
          onHide={() => setShowAudits(false)}
          searchNumber={policyInfo.policyNumber}
        />
      ),
      link: "",
      onClick: () => setShowAudits(true)
    },
    {
      id: accountServiceButtonIds.SHOW_AUTO_ID_CARDS,
      label: " Print Auto ID Cards",
      icon: "farPrint",
      content: (
        <PrintAutoIdCardsContainer
          show={showModal}
          searchNumber={policyInfo.policyNumber}
          onClose={() => setShowModal(false)}
          policyType={policyInfo.policy_type}
          state={policyInfo.rate_state}
          policyNumber={policyInfo.policyNumber}
          fetchAutoIdCards={fetchAutoIdCards}
        />
      ),
      onClick: () => setShowModal(true),
      link: ""
    },
    {
      id: accountServiceButtonIds.SHOW_DRIVERS_LIST,
      label: " View Drivers List",
      icon: "fasListOl",
      content: (
        <DriversListErrorModal
          onClose={() => setShowDriversListModal(false)}
          show={showDriversListModal}
          policyNumber={policyInfo.policyNumber}
        />
      ),
      onClick: handleDriversListClick
    },
    {
      id: accountServiceButtonIds.SHOW_VEHICLES_LIST,
      label: " View Vehicle List",
      icon: "fasCarAlt",
      content: (
        <VehiclesListErrorModal
          onClose={() => setShowVehiclesListModal(false)}
          show={showVehiclesListModal}
          policyNumber={policyInfo.policyNumber}
        />
      ),
      onClick: handleVehiclesListClick
    },
    {
      id: accountServiceButtonIds.SHOW_360_VALUE,
      label: " Go To 360 View",
      icon: "farCircleNotch",
      content: "",
      onClick: () => {
        handleUFG360({ isSpecialtyPortalView });
      }
    },
    {
      id: accountServiceButtonIds.SHOW_RESIDENTIAL_VALUATION,
      label: " Go To Residential Valuation",
      icon: "farHome",
      content: "",
      onClick: () =>
        window.open("https://rct4.msbexpress.net/unitedfire/login.aspx")
    },
    {
      id: accountServiceButtonIds.SHOW_PRESCRIPTION_CARDS,
      label: " Get Rx Card",
      icon: "fasFilePrescription",
      content: "",
      onClick: () =>
        window.open(require("../../../assets/optum-wc-rx-card.pdf"))
    }
  ];

  const hasAnyActionableActions = () =>
    Object.values(actions || {}).filter(_ => _ !== false).length > 0;

  if (!hasAnyActionableActions()) return;

  return (
    <div className="Account-service-card">
      <div className="account-services__title">Account Services:</div>
      {
        <>
          <ButtonBar fullWidth className="account-services__actions">
            {getActions().map(action => {
              return !!actions[action.id] ? (
                <Button
                  key={action.id}
                  className="account-services__action"
                  onClick={action.onClick}
                  icon={action.icon}
                >
                  {action.label}
                  {action.id === accountServiceButtonIds.SHOW_DRIVERS_LIST &&
                    isDriversListReportLoading && (
                      <LoadingIndicator type="spinner" />
                    )}
                  {action.id === accountServiceButtonIds.SHOW_VEHICLES_LIST &&
                    isVehiclesListReportLoading && (
                      <LoadingIndicator type="spinner" />
                    )}
                </Button>
              ) : null;
            })}
          </ButtonBar>
          {getActions().map(action => {
            return !!actions[action.id] ? (
              <React.Fragment key={action.id}>{action.content}</React.Fragment>
            ) : null;
          })}
        </>
      }
    </div>
  );
};

AccountServices.propTypes = {
  actions: PropTypes.object.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  policyInfo: PropTypes.object,
  requestedPolicyNumber: PropTypes.string,
  fetchAutoIdCards: PropTypes.func.isRequired,
  fetchAccountServiceLinks: PropTypes.func.isRequired,
  fetchDriversListReport: PropTypes.func.isRequired,
  fetchVehiclesListReport: PropTypes.func.isRequired,
  insuredName: PropTypes.string,
  activeAgencyName: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool,
  driversListReportError: PropTypes.bool,
  isDriversListReportLoading: PropTypes.bool,
  vehiclesListReportError: PropTypes.bool,
  isVehiclesListReportLoading: PropTypes.bool
};

export default AccountServices;
