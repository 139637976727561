import { connect } from "react-redux";
import YearToDateLossSummary from "../components/claims/year-to-date-loss-summary/YearToDateLossSummary";
import {
  fetchYearToDateLossReportListDetails,
  fetchYearToDateLossSummaryViewReport
} from "../actions/yearToDateLossActions";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  yearToDateLossList: state.yearToDateLossListReportData.yearToDateLossList,
  isLoading: state.yearToDateLossListReportData.isLoading,
  error: state.yearToDateLossListReportData.error
});

const mapDispatchToProps = {
  fetchYearToDateLossReportListDetails,
  fetchYearToDateLossSummaryViewReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YearToDateLossSummary);
