import React, { useState, useEffect, useCallback } from "react";
import OqModal from "../shared/OqModal";
import { LoadingIndicator } from "@ufginsurance/ui-kit";

const CreateQuoteModal = () => {
  const [message, setMessage] = useState(0);
  const messages = [
    {
      title: "Account setup",
      message: "Please stand by while we set up your account..."
    },
    {
      title: "Quote preparation",
      message: "Just a moment as we prepare a quote based on your selections..."
    },
    {
      title: "Data verification",
      message:
        "Please wait while we prefill your quote with our available third-party data..."
    },
    {
      title: "Quote customization",
      message:
        "Thanks for your patience as we customize the quote for your review..."
    }
  ];

  const intervalTimer = useCallback(() => setMessage(message + 1), [message]);

  useEffect(() => {
    if (message === messages.length - 1) {
      return;
    }
    const id = setInterval(intervalTimer, 3000);
    return () => clearInterval(id);
  }, [message, messages.length, intervalTimer]);

  return (
    <OqModal
      title={messages[message]?.title}
      show
      closeIcon={false}
      onHide={() => {}}
      body={
        <>
          <LoadingIndicator message={messages[message]?.message} />
        </>
      }
    />
  );
};

export default CreateQuoteModal;
