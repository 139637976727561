export const FETCH_CLAIMS_STARTED = "FETCH_CLAIMS_STARTED";
export const FETCH_CLAIMS_SUCCEEDED = "FETCH_CLAIMS_SUCCEEDED";
export const FETCH_CLAIMS_FAILED = "FETCH_CLAIMS_FAILED";

export const FETCH_CLAIMS_PAYMENTS_STARTED = "FETCH_CLAIMS_PAYMENTS_STARTED";
export const FETCH_CLAIMS_PAYMENTS_SUCCEEDED =
  "FETCH_CLAIMS_PAYMENTS_SUCCEEDED";
export const FETCH_CLAIMS_PAYMENTS_FAILED = "FETCH_CLAIMS_PAYMENTS_FAILED";

export const FETCH_CLAIMS_PAYMENT_DETAILS_STARTED =
  "FETCH_CLAIMS_PAYMENT_DETAILS_STARTED";
export const FETCH_CLAIMS_PAYMENT_DETAILS_SUCCEEDED =
  "FETCH_CLAIMS_PAYMENT_DETAILS_SUCCEEDED";
export const FETCH_CLAIMS_PAYMENT_DETAILS_FAILED =
  "FETCH_CLAIMS_PAYMENT_DETAILS_FAILED";

export const FETCH_CLAIMS_NOTES_STARTED = "FETCH_CLAIMS_NOTES_STARTED";
export const FETCH_CLAIMS_NOTES_SUCCEEDED = "FETCH_CLAIMS_NOTES_SUCCEEDED";
export const FETCH_CLAIMS_NOTES_FAILED = "FETCH_CLAIMS_NOTES_FAILED";

export const FETCH_CLAIMS_CLAIMANTS_STARTED = "FETCH_CLAIMS_CLAIMANTS_STARTED";
export const FETCH_CLAIMS_CLAIMANTS_SUCCEEDED =
  "FETCH_CLAIMS_CLAIMANTS_SUCCEEDED";
export const FETCH_CLAIMS_CLAIMANTS_FAILED = "FETCH_CLAIMS_CLAIMANTS_FAILED";

export const PRINT_CLAIMS_PAYMENT_DETAILS_STARTED =
  "PRINT_CLAIMS_PAYMENT_DETAILS_STARTED";
export const PRINT_CLAIMS_PAYMENT_DETAILS_SUCCEEDED =
  "PRINT_CLAIMS_PAYMENT_DETAILS_SUCCEEDED";
export const PRINT_CLAIMS_PAYMENT_DETAILS_FAILED =
  "PRINT_CLAIMS_PAYMENT_DETAILS_FAILED";

export const SUBMIT_REQUEST_STATUS = "SUBMIT_REQUEST_STATUS";

export const CLAIMS_INQUIRY_ACTIVATED = "CLAIMS_INQUIRY_ACTIVATED";
export const CANCEL_CLAIM_INQUIRY = "CANCEL_CLAIM_INQUIRY";
export const DISMISS_MODAL_WINDOW = "DISMISS_MODAL_WINDOW";

export const FETCH_CLAIM_STARTED = "FETCH_CLAIM_STARTED";
export const FETCH_CLAIM_SUCCEEDED = "FETCH_CLAIM_SUCCEEDED";
export const FETCH_CLAIM_FAILED = "FETCH_CLAIM_FAILED";

export const CLEAR_CLAIMS = "CLEAR_CLAIMS";
