import React, { useState } from "react";
import { Accordion } from "@ufginsurance/ui-kit";
import PolicyDecs from "./../PolicyDecs";
import ClientNotices from "./../ClientNotices";
import PropTypes from "prop-types";
import QuoteProposals from "./../../commercial/commercial-lines/QuoteProposals";
import RenewalUpdates from "./RenewalUpdates";

const CommercialTab = ({
  fetchRenewalUpdates,
  agencyCode,
  policyDecsList,
  policyDecsLoading,
  fetchPolicyDecs,
  policyDecsError,
  removePolicyDec,
  getPolicyDecsCommercialDocument,
  policyDecsCommercialDocumentFailed,
  is109015Agent,
  DismissErrorModal,
  renewalUpdatesList,
  deleteRenewalUpdate,
  renewalUpdatesError,
  renewalUpdatesLoading,
  deleteError,
  fetchClientNotices,
  clientNoticesList,
  clientNoticesError,
  clientNoticesLoading,
  removeClientNotice,
  deleteClientNoticeError,
  resetClientNotices,
  fetchClientNoticeDocument,
  quoteProposals,
  updateQuoteProposal,
  pastFetchedRenewalAgencyCode,
  pastQuoteProposalsFilter,
  pastFetchedQuoteProposalsCode,
  isSpecialtyPortalView,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch,
  areQuoteProposalsLoading,
  activeSpecialtyCompanyBranch,
  loadingProposalDocumentIds
}) => {
  const [isRenewalUpdatesOpen, setIsRenewalUpdatesOpen] = useState(false);
  const [isQuoteProposalsOpen, setIsQuoteProposalsOpen] = useState(false);
  const toggleRenewalUpdatesAccordian = () => {
    setIsRenewalUpdatesOpen(!isRenewalUpdatesOpen);
  };
  const toggleQuoteProposalsAccordian = () => {
    setIsQuoteProposalsOpen(!isQuoteProposalsOpen);
  };

  const handleFetchRenewalUpdates = () => {
    if (agencyCode !== pastFetchedRenewalAgencyCode) {
      fetchRenewalUpdates(agencyCode);
    }
  };

  return (
    <div id="home-commercial-container" className="home_commercial_tab">
      <Accordion
        isOpen
        textMain="Policy Decs"
        textExtra="New, renewal and endorsed policy decs and annual premium audits"
      >
        <PolicyDecs
          agencyCode={`${agencyCode}`}
          fetchPolicyDecs={fetchPolicyDecs}
          policyDecsList={policyDecsList}
          policyDecsLoading={policyDecsLoading}
          policyDecsError={policyDecsError}
          removePolicyDec={removePolicyDec}
          fetchPolicyDecsDocument={getPolicyDecsCommercialDocument}
          policyDecsCommercialDocumentFailed={
            policyDecsCommercialDocumentFailed
          }
          is109015Agent={is109015Agent}
          DismissErrorModal={DismissErrorModal}
          activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
        />
      </Accordion>
      {!is109015Agent && !isSpecialtyPortalView && (
        <Accordion
          textMain="Renewal Updates"
          textExtra="Update your drivers list and commercial umbrella renewal forms"
          onClick={toggleRenewalUpdatesAccordian}
        >
          {isRenewalUpdatesOpen ? (
            <RenewalUpdates
              renewalUpdatesList={renewalUpdatesList}
              fetchRenewalUpdates={handleFetchRenewalUpdates}
              deleteRenewalUpdate={deleteRenewalUpdate}
              error={renewalUpdatesError}
              isLoading={renewalUpdatesLoading}
              deleteError={deleteError}
            />
          ) : (
            <></>
          )}
        </Accordion>
      )}
      <Accordion
        textMain="Client Notices"
        textExtra="Cancellations, reinstatements and lapse termination notices"
      >
        <div className="client_notices">
          <ClientNotices
            agencyCode={`${agencyCode}`}
            fetchClientNotices={fetchClientNotices}
            clientNoticesList={clientNoticesList}
            clientNoticesError={clientNoticesError}
            clientNoticesLoading={clientNoticesLoading}
            removeClientNotice={removeClientNotice}
            deleteClientNoticeError={deleteClientNoticeError}
            resetClientNotices={resetClientNotices}
            fetchClientNoticeDocument={fetchClientNoticeDocument}
            is109015Agent={is109015Agent}
            activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
          />
        </div>
      </Accordion>
      <Accordion
        className="quote-proposals-accordion"
        textMain="Quote Proposals"
        textExtra="Proposals recently prepared by your underwriter"
        onClick={toggleQuoteProposalsAccordian}
      >
        <div className="quote_proposal">
          <br />
          {isQuoteProposalsOpen && (
            <QuoteProposals
              agencyCode={agencyCode}
              quoteProposals={quoteProposals}
              updateQuoteProposal={updateQuoteProposal}
              daysFilterTabs={[7, 30]}
              sortableColumns={["created"]}
              pastQuoteProposalsFilter={pastQuoteProposalsFilter}
              pastFetchedQuoteProposalsCode={pastFetchedQuoteProposalsCode}
              pastFetchedQuoteProposalsSpecialtyCompanyBranch={
                pastFetchedQuoteProposalsSpecialtyCompanyBranch
              }
              areQuoteProposalsLoading={areQuoteProposalsLoading}
              activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
              loadingProposalDocumentIds={loadingProposalDocumentIds}
            />
          )}
        </div>
      </Accordion>
    </div>
  );
};

CommercialTab.propTypes = {
  quoteProposals: PropTypes.object.isRequired,
  updateQuoteProposal: PropTypes.func.isRequired,
  agencyCode: PropTypes.string.isRequired,
  renewalUpdatesList: PropTypes.array,
  renewalUpdatesError: PropTypes.string,
  renewalUpdatesLoading: PropTypes.bool,
  policyDecsList: PropTypes.array,
  policyDecsError: PropTypes.string,
  policyDecsLoading: PropTypes.bool,
  is109015Agent: PropTypes.bool,
  fetchRenewalUpdates: PropTypes.func.isRequired,
  deleteRenewalUpdate: PropTypes.func.isRequired,
  fetchPolicyDecs: PropTypes.func.isRequired,
  removePolicyDec: PropTypes.func.isRequired,
  deleteError: PropTypes.string,
  fetchClientNotices: PropTypes.func.isRequired,
  clientNoticesList: PropTypes.array,
  clientNoticesError: PropTypes.string,
  clientNoticesLoading: PropTypes.bool,
  removeClientNotice: PropTypes.func,
  deleteClientNoticeError: PropTypes.string,
  resetClientNotices: PropTypes.func,
  fetchClientNoticeDocument: PropTypes.func.isRequired,
  getPolicyDecsCommercialDocument: PropTypes.func,
  policyDecsCommercialDocumentFailed: PropTypes.bool,
  DismissErrorModal: PropTypes.func,
  pastFetchedRenewalAgencyCode: PropTypes.string,
  pastQuoteProposalsFilter: PropTypes.number,
  pastFetchedQuoteProposalsCode: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch: PropTypes.string,
  areQuoteProposalsLoading: PropTypes.bool.isRequired,
  activeSpecialtyCompanyBranch: PropTypes.string,
  loadingProposalDocumentIds: PropTypes.array
};

export default CommercialTab;
