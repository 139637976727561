import { connect } from "react-redux";
import AgencyAudit from "../components/underwriting/agency-audit/AgencyAudit";
import { fetchUnderwritingEmailListDetails } from "../actions/inforceListAction";
import { fetchAdministrator } from "../actions/reportAdministratorAction";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getUnderwritingEmailListIsLoading,
  getUnderwritingEmailList,
  getUnderwritingEmailListError
} from "../selectors/underwritingSelector";
import {
  getAdministratorIsLoading,
  getAdministrator,
  getAdministratorError
} from "../selectors/administratorSelector";

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  isLoading: getUnderwritingEmailListIsLoading(state),
  emailList: getUnderwritingEmailList(state),
  error: getUnderwritingEmailListError(state),
  hasAgencyProductionReportsAccess: sessionSelector.hasAgencyProductionReportsAccess(
    state
  ),
  administratorIsLoading: getAdministratorIsLoading(state),
  administrator: getAdministrator(state),
  administratorError: getAdministratorError(state)
});

const mapDispatchToProps = {
  fetchUnderwritingEmailListDetails,
  fetchAdministrator
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyAudit);
