import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import WebsiteLogoUsage from "./WebsiteLogoUsage";
import {
  postAgencyLinkingDetails,
  updateWebsiteLogoState,
  resetWebsiteLogoState
} from "./WebsiteLogoActions";

const mapStateToProps = state => ({
  agency_number: sessionSelector.getActiveAgencyCode(state),
  agency_name: sessionSelector.getActiveAgency(state).name,
  email: sessionSelector.getEmail(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  ...state.websiteLogo
});

const mapDispatchToProps = {
  postAgencyLinkingDetails,
  resetWebsiteLogoState,
  updateWebsiteLogoState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteLogoUsage);
