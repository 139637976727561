import React, {
  useMemo,
  useCallback,
  useContext,
  useState,
  useEffect
} from "react";
import * as api from "../../services/onlineQuotingService";

import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useForm, formatDate } from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../OnlineQuotingContext";

export default React.createContext(null);

const useStep6 = ({ onCancelQuote }) => {
  const [productMatrixData, setProductMatrixData] = useState();
  const [loadingProductMatrixData, setLoadingProductMatrixData] = useState();

  const { quoteData, showUpdatingToast, closeUpdatingToast } =
    useContext(OnlineQuotingContext);

  const { proQuoteUseAwsProductMatrix } = useFlags();

  useEffect(() => {
    const primaryState = quoteData?.baseData?.baseState_UFG;

    const quoteEffectiveDate = new Date(
      formatDate(quoteData?.baseData?.periodStartDate, "MM/DD/YYYY")
    );

    if (!loadingProductMatrixData) {
      setLoadingProductMatrixData(true);

      if (primaryState && !productMatrixData) {
        api
          .getProductMatrixForState({
            stateAbr: primaryState,
            useAWS: proQuoteUseAwsProductMatrix
          })
          .then(results => {
            const activeProducts = results?.data?.products.filter(p => {
              const { effectiveDate, expirationDate } = p;

              const isActive =
                effectiveDate && new Date(effectiveDate) <= quoteEffectiveDate;
              const isExpired =
                expirationDate && new Date(expirationDate) < quoteEffectiveDate;

              return isActive && !isExpired;
            });
            setProductMatrixData(activeProducts);
          });
      }
    }
  }, [
    loadingProductMatrixData,
    proQuoteUseAwsProductMatrix,
    productMatrixData,
    quoteData?.baseData?.baseState_UFG,
    quoteData?.baseData?.periodStartDate
  ]);

  /**
   * UNDERWRITER ISSUES
   */
  const sendUwForm = useForm({ values: { comments: "" } });
  const sendUWIssue = useCallback(
    callBack => {
      const message = "Sending to Underwriter for Review";
      showUpdatingToast({ message });
      api
        .sendForReview(quoteData.quoteID, {
          message: sendUwForm.values.comments
        })
        .then(() => {
          closeUpdatingToast({ message });
          onCancelQuote().then(() => {
            if (callBack) {
              callBack(true);
            }
          });
        })
        .catch(error => {
          console.error(error);
          closeUpdatingToast({ message });
          if (callBack) {
            callBack(false);
          }
          toast(`Unable to send to Underwrite for review.`, {
            position: "top-center",
            className: "error",
            autoClose: true
          });
        });
    },
    [
      closeUpdatingToast,
      onCancelQuote,
      quoteData?.quoteID,
      sendUwForm?.values?.comments,
      showUpdatingToast
    ]
  );

  return useMemo(
    () => ({
      sendUwForm,
      sendUWIssue,
      productMatrixData
    }),
    [sendUwForm, sendUWIssue, productMatrixData]
  );
};

export { useStep6 };
