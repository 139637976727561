import React from "react";
import PropTypes from "prop-types";
import DirectOasis from "./DirectOasis";
import AgencyOasis from "./AgencyOasis";
import { Translations } from "../../../../components/translations";
import orderBy from "lodash/orderBy";

const Oasis = ({
  directList,
  agencyList,
  fetchAccountingStatementViewPDF,
  report_group,
  agency_code,
  source
}) => {
  return (
    <div>
      {directList.map(item => {
        return (
          <div key={item.billing_type}>
            <DirectOasis
              documents={orderBy(item.documents, "date", "desc")}
              title={
                Translations.reports.tabAccounting.agencyStatement
                  .direct_bill_oasis
              }
              fetchAccountingStatementViewPDF={fetchAccountingStatementViewPDF}
              report_group={report_group}
              agency_code={agency_code}
              source={source}
              billing_type={item.billing_type}
            />
          </div>
        );
      })}

      {agencyList.map(item => {
        return (
          <div key={item.billing_type}>
            <AgencyOasis
              documents={orderBy(item.documents, "date", "desc")}
              title={
                Translations.reports.tabAccounting.agencyStatement
                  .agency_bill_oasis
              }
              fetchAccountingStatementViewPDF={fetchAccountingStatementViewPDF}
              report_group={report_group}
              agency_code={agency_code}
              source={source}
              billing_type={item.billing_type}
            />
          </div>
        );
      })}
    </div>
  );
};

Oasis.propTypes = {
  directList: PropTypes.array.isRequired,
  agencyList: PropTypes.array.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  report_group: PropTypes.string,
  agency_code: PropTypes.string,
  source: PropTypes.string
};

export default Oasis;
