import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line valid-jsdoc
/** Use when we need to force a <Route /> componet to always reload even if
 *  we're on the same page. This can wrap an individual <Route /> or
 *  a <Switch />.
 *  Reference: https://github.com/ReactTraining/react-router/issues/1982#issuecomment-319907084
 */
const AlwaysReload = props => {
  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      key: props.location.key
    })
  );
  return <div>{childrenWithProps}</div>;
};

AlwaysReload.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired
};

export default AlwaysReload;
