import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import YearToDateList from "./YearToDateList";
import { Translations } from "../../../../components/translations";

const YearToDateLossSummary = ({
  error,
  fetchYearToDateLossReportListDetails,
  fetchYearToDateLossSummaryViewReport,
  isLoading,
  selectedAgencyCode,
  yearToDateLossList
}) => {
  useEffect(() => {
    fetchYearToDateLossReportListDetails(selectedAgencyCode);
  }, [fetchYearToDateLossReportListDetails, selectedAgencyCode]);

  return (
    <div className="claims-year-weekly-container">
      {error == null || error ? (
        Translations.reports.tabClaims.yearToDateNoReport
      ) : (
        <div>
          <div>{Translations.reports.tabClaims.yearToDateReportSummary}</div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <YearToDateList
              yearToDateLossList={yearToDateLossList}
              selectedAgencyCode={selectedAgencyCode}
              fetchYearToDateLossSummaryViewReport={
                fetchYearToDateLossSummaryViewReport
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

YearToDateLossSummary.propTypes = {
  error: PropTypes.any,
  fetchYearToDateLossReportListDetails: PropTypes.func.isRequired,
  fetchYearToDateLossSummaryViewReport: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  yearToDateLossList: PropTypes.array
};

export default YearToDateLossSummary;
