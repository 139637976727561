/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactAdapterConsumer from "../../shared/components/React/ReactAdapterConsumer";
import { LoadingIndicator, FlexRow, ContentHeader, Panel } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { fetchAgencyProfileManifest } from "../AgencyProfileSlice";
import useGetAgencyInfo from "../../shared/hooks/useGetAgencyInfo";

const AgencyEnrollment = ({
  token,
  agentCode,
  rehydrateSession,
  isAgencyAdministrator,
  selectedAgencyCode
}) => {
  const dispatch = useDispatch();

  const baseUrl = window.env.REACT_APP_M4_AGENT_BASEURL;
  const clientId = window.env.REACT_APP_CLIENT_ID;
  const clientSecret = window.env.REACT_APP_CLIENT_SECRET_ID;

  const agencyProfileData = useSelector(state => state.agencyProfile);

  const manifestVersion = agencyProfileData?.manifest?.version;

  const showErrorMessage = agencyProfileData?.fetchManifestError;

  const [agency, setAgency] = useState(null);

  const [isLoadingAgencyData] = useGetAgencyInfo(
    selectedAgencyCode,
    async agencyData => {
      setAgency(agencyData);
    }
  );

  useEffect(() => {
    if (!agencyProfileData?.manifest?.version) {
      dispatch(fetchAgencyProfileManifest());
    }
  }, [agencyProfileData?.manifest?.version, dispatch]);

  if (isLoadingAgencyData) return <LoadingIndicator />;

  if (!isLoadingAgencyData && !isAgencyAdministrator)
    return (
      <>
        <ContentHeader>
          {agency?.name} (Agency Code: {agency?.code})
        </ContentHeader>
        <p>
          Agency Enrollment is only available to employees who have access. If you
          have questions, please contact your agency administrator.
        </p>
        <Panel noBorder bgcolor="grey">
          <p>
            <b>Agency Administrator:</b>{" "}
            {agency?.agency_contacts?.administrator?.name || ""}
          </p>
          <p style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            <b>Email:</b>{" "}
            <a href={`mailto:${agency?.agency_contacts?.administrator?.email}`}>
              {agency?.agency_contacts?.administrator?.email || ""}
            </a>
          </p>
        </Panel>
      </>
    );

  return (
    <>
      {showErrorMessage && (
        <FlexRow>
          <div className="no-results-info">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </FlexRow>
      )}

      {!manifestVersion && !showErrorMessage && <LoadingIndicator />}

      {manifestVersion && !showErrorMessage && (
        <ReactAdapterConsumer
          {...{
            token,
            agentCode,
            clientId,
            baseUrl,
            clientSecret,
            rehydrateSession,
            isAgencyAdministrator,
            agencyAdminName: agency?.agency_contacts?.administrator?.name ?? "",
            agencyAdminEmail:
              agency?.agency_contacts?.administrator?.email ?? "",
            scope: "agencyProfile",
            module: "AgencyEnrollment",
            url: `${window.env.REACT_APP_UFGDIGITAL_MFE_URL}/agency-profile/${manifestVersion}/agencyProfile_${manifestVersion}.js`
          }}
        />
      )}
    </>
  );
};

export default AgencyEnrollment;
