import _equal from "lodash/isEqual";
import React, { useContext, useState } from "react";

import {
  Button,
  Select,
  Form,
  FormGroup,
  Input,
  LoadingIndicator,
  useForm
} from "@ufginsurance/ui-kit";

import { toTitleCase } from "../../components/Factory.js";
import * as api from "../../services/onlineQuotingService";
import OnlineQuotingContext from "../OnlineQuotingContext";
import VerifyAddress from "../../shared/components/AddressValidate/VerifyAddressSingle.js";
import OQstrings from "../shared/strings";
import {
  addressLineValidate,
  zipCodePattern,
  zipCodeValidate
} from "../shared/util";
import { pcContactToForm } from "./BillingContactPanel";

import "./BillingContact.scss";

const ERROR_UPDATE_BILLING_DATA =
  "Sorry, an error occurred. We were unable to save billing contact information.";

const BillingContactForm = ({ contact, setContact, setShow }) => {
  const {
    quoteData,
    showUpdatingToast,
    closeUpdatingToast,
    toastErrr,
    quoteIsUpdating,
    rateQuote
  } = useContext(OnlineQuotingContext);

  const [availableStates, setAvailableStates] = useState([
    { label: contact.state, value: contact.state }
  ]);
  const [availableCities, setAvailableCities] = useState([]);
  const [zipSearching, setZipSearching] = useState();
  const [addressToVerify, setAddressToVerify] = useState();

  const form = useForm({ values: contact, onSubmit: () => {} });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  /**
   *
   * Zip code search
   *
   */
  const searchLocaleByZipCodeV2 = value => {
    setZipSearching(true);
    api
      .searchLocaleByZipCodeV2(value)
      .then(result => {
        const zipCodeErrors = [];
        if (result?.data?.length) {
          // for city dropdown
          setAvailableCities(
            result?.data.map(d => ({
              value: toTitleCase(d.city),
              label: toTitleCase(d.city)
            }))
          );
          // for state dropdown
          setAvailableStates(
            result?.data.map(d => ({
              label: d.state,
              value: d.state
            }))
          );
          // set city & state values
          updateForm({
            values: {
              postalCode: value,
              city: toTitleCase(result.data[0]?.city),
              state: result.data[0]?.state
            },
            errors: {
              postalCode: zipCodeErrors,
              city: [],
              state: []
            }
          });
        } else {
          /**
           * if the zip code returns an empty array,
           * then update the form to reflect the bad zip
           */
          setAvailableCities([]);
          setAvailableStates([]);
          updateForm({
            values: {
              postalCode: value,
              city: "",
              state: ""
            },
            errors: {
              postalCode: ["Zip code not found"]
            }
          });
        }
      })
      .finally(() => setZipSearching(false));
  };

  const handleZipOnChange = ({ field, value }) => {
    handleOnChange({ field, value });
    if (zipCodePattern.test(value)) {
      searchLocaleByZipCodeV2(value);
    }
  };

  const handleZipValidate = field => {
    const fieldErrors = handleOnValidate(field);
    const zipErrorCheck = zipCodeValidate(field.value);
    if (zipErrorCheck) fieldErrors.push(zipErrorCheck);
    return fieldErrors;
  };

  const handleStreetValidate = field => {
    const fieldErrors = handleOnValidate(field);
    const addressLineCheck = addressLineValidate(field.value);
    if (addressLineCheck) fieldErrors.push(addressLineCheck);
    return fieldErrors;
  };

  const regexStringBeginsWithSpecialChars = /^('|`|"|&|@|#|;|\/|\\)/;

  const handlebusinessNameValidate = ({ field, value, validation }) => {
    const invalidBillFields = [];
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && regexStringBeginsWithSpecialChars.test(value)) {
      fieldErrors.push(OQstrings.error.business_name_special_characters);
      invalidBillFields.push(field);
    }
    return fieldErrors;
  };

  const handleLastNameValidate = ({ field, value, validation }) => {
    const invalidBillFields = [];
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && regexStringBeginsWithSpecialChars.test(value)) {
      fieldErrors.push(OQstrings.error.last_name_special_characters);
      invalidBillFields.push(field);
    }
    return fieldErrors;
  };

  const handleFirstNameValidate = ({ field, value, validation }) => {
    const invalidBillFields = [];
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value && regexStringBeginsWithSpecialChars.test(value)) {
      fieldErrors.push(OQstrings.error.first_name_special_characters);
      invalidBillFields.push(field);
    }
    return fieldErrors;
  };

  const handleUpdateButton = () => {
    /**
     * if address has changed, then do address validation
     */
    if (
      values.addressLine1 !== contact.addressLine1 ||
      values.postalCode !== contact.postalCode
    ) {
      const { addressLine1, city, state, postalCode } = values;
      setAddressToVerify({ addressLine1, city, state, zip: postalCode });
    } else {
      /**
       * just save the contact name/type
       */
      saveContact({ address: values });
    }
  };

  // function used by address validation...
  // to update the address fields to the options chosen by the address validation
  const updateAddressFormFields = address => {
    const _address = {
      addressLine1: toTitleCase(address.addressFields.address1),
      city: toTitleCase(address.addressFields.city),
      state: address.addressFields.state,
      postalCode: address.addressFields.zip,
      county: address.addressFields.county,
      countyCode: address.addressFields.countyCode
    };
    updateForm({
      values: { address: _address }
    });
    saveContact({ address: _address });
  };

  const saveContact = ({ address }) => {
    /**
     * form to Payload conversion
     */
    const payload = {
      subtype: values.subtype,
      primaryAddress: {
        addressLine1: address?.addressLine1,
        city: address?.city,
        state: address?.state,
        postalCode: address?.postalCode,
        county: address?.county,
        countyCode: address?.countyCode,
        addressType: "billing",
        country: "US"
      }
    };

    if (values.subtype === "Company") {
      payload.contactName = values.contactName;
    } else {
      payload.firstName = values.firstName;
      payload.lastName = values.lastName;
    }

    const subTypeChanged = values.subtype !== contact?.subtype;

    /**
     * on more check to compare with initial values, if they're the same then just close
     */
    if (_equal(values, contact)) setShow(false);

    const message = "Updating billing contact...";
    showUpdatingToast({ message });
    /**
     * if isAccountHolder or if the subtype changed, then create a new billing contact
     */
    if (contact.isAccountHolder || subTypeChanged) {
      api
        .addBillingContact({
          accountId: quoteData.baseData.accountNumber,
          payload: { ...payload, accountContactRoles: ["BillingContact"] }
        })
        .then(response => {
          //update the billingContact state
          setContact(pcContactToForm(response.data));
          closeUpdatingToast({ message });
          rateQuote().then(() => {
            setShow(false);
          });
        })
        .catch(error => {
          //reset the form data to its original
          toastErrr({
            action: "addBillingContact",
            description: "billing data update",
            error,
            payload,
            displayMessage: ERROR_UPDATE_BILLING_DATA
          });
          closeUpdatingToast({ message });
        });
    } else {
      /**
       * is not account holder
       * else, update the current billing contact
       */
      // used for when updating a billing contact that is not the main AccountHolder
      api
        .updateBillingContact({
          accountId: quoteData.baseData.accountNumber,
          contactId: contact.id,
          payload
        })
        .then(response => {
          setContact(pcContactToForm(response.data));
          closeUpdatingToast({ message });
          rateQuote().then(() => {
            setShow(false);
          });
        })
        .catch(error => {
          //reset the form data to its original
          toastErrr({
            misc: { contact },
            action: "updateBillingContact",
            description: "billing update issue",
            error,
            payload,
            displayMessage: ERROR_UPDATE_BILLING_DATA
          });
          closeUpdatingToast({ message });
        });
    }
  };

  const toggleName = () => {
    updateForm({
      values: {
        subtype: values?.subtype === "Company" ? "Person" : "Company",
        firstName: "",
        lastName: "",
        contactName: ""
      }
    });
  };

  return (
    <div className="oq__billing-contact__container">
      <div>
        <Form context={form}>
          <FormGroup>
            {values?.subtype === "Company" ? (
              <Input
                id="contactName"
                name="contactName"
                label="Billing Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handlebusinessNameValidate}
                value={values.contactName}
                size="50p"
                required
                maxLength={60}
              />
            ) : (
              <>
                <Input
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleFirstNameValidate}
                  value={values.firstName}
                  size="md"
                  required
                  maxLength={30}
                />
                <Input
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleLastNameValidate}
                  value={values.lastName}
                  size="md"
                  required
                  maxLength={30}
                />
              </>
            )}
            <Button onClick={toggleName} labelSpace isLink>
              Change to
              {values?.subtype === "Company" ? " Person" : " Company"}
            </Button>
          </FormGroup>
          <FormGroup groupErrors className="Address">
            <Input
              id="addressLine1"
              name="addressLine1"
              label="Billing Address"
              placeholder="123 Main Street"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleStreetValidate}
              value={values.addressLine1}
              className="flexGrow"
              required
              maxLength={60}
              disabled={!!addressToVerify}
            />
            <Input
              id="postalCode"
              name="postalCode"
              placeholder="12345-1234"
              label="Zip"
              className="zip"
              labelElement={
                zipSearching ? (
                  <LoadingIndicator
                    className="oq__label-spinner"
                    type="spinner"
                    message="Searching"
                  />
                ) : null
              }
              onChange={handleZipOnChange}
              onBlur={handleOnBlur}
              onValidate={handleZipValidate}
              value={values.postalCode}
              disabled={!!addressToVerify}
              required
              requiredError="Zip is required."
            />
            <Select
              id="city"
              name="city"
              placeholder=""
              label="City"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.city}
              options={
                availableCities.length > 0
                  ? availableCities
                  : [
                      {
                        value: values.city,
                        label: toTitleCase(values.city)
                      }
                    ]
              }
              className="city"
              disabled={availableCities.length <= 1}
              isClearable={false}
            />
            <Select
              id="state"
              name="state"
              label="State"
              placeholder=""
              className="state"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.state}
              options={availableStates || []}
              disabled
              isClearable={false}
            />
          </FormGroup>
          {!addressToVerify && (
            <div>
              <br />
              <FormGroup align="right">
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button
                  variant="primary"
                  onClick={handleUpdateButton}
                  disabled={
                    !!invalidFields.length ||
                    _equal(values, contact) ||
                    quoteIsUpdating
                  }
                >
                  Continue
                </Button>
              </FormGroup>
            </div>
          )}
        </Form>
        {addressToVerify && (
          <VerifyAddress
            addressToVerify={addressToVerify}
            onContinue={updateAddressFormFields}
            onCancel={() => setAddressToVerify(null)}
            disableContinueButton={quoteIsUpdating}
          />
        )}
      </div>
    </div>
  );
};

export default BillingContactForm;
