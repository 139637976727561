export default {
  error: {
    zip_code_not_in_agent_state:
      "You are attempting to quote business in a state in which we do not have a license on file for. Please contact your UFG marketing representative for licensing procedures.",
    business_name_special_characters:
      "Business Name must begin with letters or numbers.",
    first_name_special_characters:
      "First Name must begin with letters or numbers.",
    last_name_special_characters:
      "Last Name must begin with letters or numbers.",
    vehicle_invalid_vin: "The VIN entered is not valid."
  }
};
