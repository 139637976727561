import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { headerLogo, specialtyLogo } from "../../constants/images";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link } from "react-router-dom";
import { Translations } from "../../components/translations";
import AgencyNumberTextboxContainer from "../../containers/AgencyNumberTextboxContainer";
import { useHistory } from "react-router";
import { Icon } from "@ufginsurance/ui-kit";

const HeaderTop = ({
  handleToggleNav,
  is109015Agent,
  minimal,
  isSpecialtyPortalView,
  isMercerSpecialtyPortalView,
  changeToUfgSpecialty,
  changeToMercer
}) => {
  const { brokersHeadersEnabled } = useFlags();

  const history = useHistory();

  const handleHeaderLogo = () => {
    const imageElement = (
      <img
        src={isSpecialtyPortalView ? specialtyLogo : headerLogo}
        alt={
          isSpecialtyPortalView
            ? "UFG Insurance: Specialty. It's Simple. Trust Matters."
            : "UFG Insurance: Agent. Doing Business"
        }
      />
    );

    if (minimal) {
      return <div className="headerTop__logo">{imageElement}</div>;
    }

    return (
      <Link to="/" className="headerTop__logo">
        {imageElement}
      </Link>
    );
  };

  const redirectToHome = () => {
    if (history.location.pathname !== "/") {
      history.replace("/");
    }
  };

  const specialtySwitcherText = () => {
    return isMercerSpecialtyPortalView
      ? Translations.header.ufgSpecialty_switch_broker
      : Translations.header.mercer_switch;
  };

  const specialtyHeaderText = () => {
    return isMercerSpecialtyPortalView
      ? Translations.header.mercer_insurance
      : Translations.header.ufg_specialty_insurance;
  };

  const handleSpecialtySwitch = () => {
    return isSpecialtyPortalView && isMercerSpecialtyPortalView
      ? changeToUfgSpecialty().then(redirectToHome)
      : changeToMercer().then(redirectToHome);
  };

  return (
    <div className="headerTop__navbar-container">
      <div className="flexRow align-center">
        <div className="">{handleHeaderLogo()}</div>
        <div className="flexGrow">
          {is109015Agent && (
            <div className="headerTop__agentNumberContainer">
              <div className="row headerTop__agency-view-input">
                <AgencyNumberTextboxContainer />
              </div>
            </div>
          )}
        </div>
        <div className="headerTop__contact">
          <div role="presentation">
            {Translations.navigation.help_title}
            <span>{Translations.navigation.help_phone}</span>
          </div>
        </div>
        <button
          type="button"
          className="headerTop__navbar-button hidden-lg"
          data-toggle="headerTop__collapse"
          data-target="#navbar"
          aria-expanded="false"
          aria-controls="navbar"
          data-icon="bars"
          onClick={handleToggleNav}
        >
          <Icon icon="fasBars" className="headerTop__fa-bars" />
        </button>
      </div>

      {isSpecialtyPortalView && brokersHeadersEnabled && (
        <div className="flexRow">
          <div>
            <h3>{specialtyHeaderText()}</h3>
          </div>

          <div className="align-right">
            <Link to="#" onClick={handleSpecialtySwitch}>
              {specialtySwitcherText()}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

HeaderTop.propTypes = {
  minimal: PropTypes.bool,
  handleToggleNav: PropTypes.func,
  is109015Agent: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool,
  isMercerSpecialtyPortalView: PropTypes.bool,
  changeToUfgSpecialty: PropTypes.func,
  changeToMercer: PropTypes.func
};

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state)
});

export default connect(mapStateToProps, {})(HeaderTop);
