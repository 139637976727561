import { connect } from "react-redux";
import BillingTab from "../components/home/billing/BillingTab";
import {
  fetchBillingPastDue,
  fetchBillingDueSoon
} from "../actions/homeBillingActions";
import { getSelectedAgencyCode } from "../selectors/agencySelectors";
import {
  getPastDueLoading,
  getDueSoonLoading,
  getBillingDueSoonList,
  getDueSoonError,
  getPastDueError,
  getBillingPastDueList,
  getPastDueFetchedAgencyCode,
  getDueSoonFetchedAgencyCode
} from "../components/home/billing/HomeBillingSelectors";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  pastDueLoading: getPastDueLoading(state),
  dueSoonLoading: getDueSoonLoading(state),
  billingDueSoonList: getBillingDueSoonList(state),
  billingPastDueList: getBillingPastDueList(state),
  pastDueError: getPastDueError(state),
  dueSoonError: getDueSoonError(state),
  pastDueFetchedAgencyCode: getPastDueFetchedAgencyCode(state),
  dueSoonFetchedAgencyCode: getDueSoonFetchedAgencyCode(state)
});

const mapDispatchToProps = {
  fetchBillingPastDue,
  fetchBillingDueSoon
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingTab);
