import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { logger } from "./index";
import PropTypes from "prop-types";

const withPageViewTracking = WrappedComponent => {
  const TheComponent = props => {
    const { history } = props;

    useEffect(() => {
      // on mount
      logger.pageview(`${history.location.pathname}${history.location.search}`);

      const historyUnlisten = history.listen(location => {
        logger.pageview(`${location.pathname}${location.search}`);
      });

      // returned function will be called on component unmount
      return () => {
        if (historyUnlisten) {
          historyUnlisten();
        }
      };
    }, [history]);

    return <WrappedComponent {...props} />;
  };

  const getDisplayName = WrappedComponent => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
  };

  TheComponent.displayName = `withPageViewTracking(${getDisplayName(
    WrappedComponent
  )})`;

  TheComponent.propTypes = {
    history: PropTypes.any
  };
  return withRouter(TheComponent);
};

export default withPageViewTracking;
