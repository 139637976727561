import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DateFormat, toTitleCase } from "../../Factory";
import { faChevronCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translations } from "../../translations";
import ConfirmPopover from "./ConfirmPopover";
import { LoadingIndicator, Table, Anchor } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";

const SavedLosses = ({
  SavedLossesList,
  isLoading,
  removeClaimsSavedLosses,
  selectedAgencyCode,
  error,
  is109015Agent,
  pastFetchedSavedLossesAgencyCode,
  fetchClaimSavedLosses
}) => {
  useEffect(() => {
    if (pastFetchedSavedLossesAgencyCode !== selectedAgencyCode) {
      fetchClaimSavedLosses(selectedAgencyCode);
    }
  }, [
    selectedAgencyCode,
    pastFetchedSavedLossesAgencyCode,
    fetchClaimSavedLosses
  ]);

  const continueRow = row => () => {
    window.open(
      `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgAgent/Claims/FNOLTransmit.aspx?wcnum=${row.control_number}`
    );
  };

  const createColumns = (
    removeClaimsSavedLosses,
    selectedAgencyCode,
    is109015Agent
  ) => [
    {
      key: "reported_date",
      label: Translations.home_claims_Tab.table_labels.save_date,
      element: row => {
        return DateFormat(row.reported_date, "MM/DD/YYYY");
      },
      sortable: true
    },
    {
      key: "insurance_name",
      label: Translations.home_claims_Tab.table_labels.policyholder,
      element: row => {
        return toTitleCase(row.insurance_name);
      }
    },
    {
      key: "policy_number",
      label: Translations.home_claims_Tab.table_labels.policy_number,
      element: row => {
        const policyNumber =
          (row.policy_prefix || "").trim() + row.policy_number;
        return (
          <Anchor
            href={`${routes.ACCOUNT_HOME}/${policyNumber}`}
            onClick={() => {}}
          >
            {policyNumber}
          </Anchor>
        );
      }
    },
    {
      key: "loss_date",
      label: Translations.home_claims_Tab.table_labels.loss_date,
      element: row => {
        return DateFormat(row.loss_date, "MM/DD/YYYY");
      }
    },
    {
      key: "",
      label: Translations.home_claims_Tab.table_labels.continue,
      headerClasses: "continue-header",
      element: row => (
        <div
          className="continue-icon"
          role="presentation"
          onClick={continueRow(row)}
        >
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </div>
      )
    },
    {
      key: "",
      label: Translations.home_claims_Tab.table_labels.remove,
      element: row => {
        return is109015Agent ? null : (
          <ConfirmPopover
            confirmEventData={{ ...row, agency_code: selectedAgencyCode }}
            confirmEvent={() => {
              removeClaimsSavedLosses({
                control_number: row.control_number,
                agency_code: selectedAgencyCode
              });
            }}
            confirmMessage={Translations.home_claims_Tab.delete_popover}
          />
        );
      }
    }
  ];

  const columns = createColumns(
    removeClaimsSavedLosses,
    selectedAgencyCode,
    is109015Agent
  );

  const hasSavedLosses = SavedLossesList && SavedLossesList.length > 0;
  return (
    <div className="saved-losses-table">
      {isLoading ? (
        <LoadingIndicator />
      ) : error || !hasSavedLosses ? (
        <div className="friendly-error-messaging">
          {Translations.home_claims_Tab.saved_losses_error}
        </div>
      ) : (
        <Table
          rowKey="id"
          data={SavedLossesList}
          columns={columns}
          itemsPerPage={20}
          initialSort="reported_date"
          initialDirection="desc"
          showPagination
        />
      )}
    </div>
  );
};

SavedLosses.propTypes = {
  fetchClaimSavedLosses: PropTypes.func.isRequired,
  SavedLossesList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  removeClaimsSavedLosses: PropTypes.func.isRequired,
  error: PropTypes.any,
  is109015Agent: PropTypes.bool,
  pastFetchedSavedLossesAgencyCode: PropTypes.string
};

export default SavedLosses;
