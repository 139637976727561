import { v4 } from "uuid";
import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";

const agencyStatementListReducer = (
  state = initialState.agencyStatementList,
  action
) => {
  switch (action.type) {
    case types.FETCH_AGENCY_STATEMENT_LIST_STARTED:
      return {
        ...state,
        isLoading: true,
        statementList: [],
        error: false
      };
    case types.FETCH_AGENCY_STATEMENT_LIST_SUCCEEDED: {
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        statementList: (payload || []).map(x => ({
          id: v4(),
          ...x,
          report: x.report.map(r => ({
            id: v4(),
            ...r,
            items: r.items.map(i => ({
              id: v4(),
              ...i,
              documents: i.documents.map(d => ({
                id: v4(),
                ...d
              }))
            }))
          }))
        })),
        error: false
      };
    }
    case types.FETCH_AGENCY_STATEMENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        statementList: [],
        error: action.payload
      };
    case types.FETCH_PURCHASED_AGENCY_CODES_STARTED:
      return {
        ...state,
        isLoadingPurchasedAgencyCodes: true,
        purchasedAgencyCodes: [],
        purchasedAgencyCodesError: false
      };
    case types.FETCH_PURCHASED_AGENCY_CODES_SUCCEEDED:
      return {
        ...state,
        isLoadingPurchasedAgencyCodes: false,
        purchasedAgencyCodes: action.payload || [],
        purchasedAgencyCodesError: false
      };
    case types.FETCH_PURCHASED_AGENCY_CODES_FAILED:
      return {
        ...state,
        isLoadingPurchasedAgencyCodes: false,
        purchasedAgencyCodes: [],
        purchasedAgencyCodesError: true
      };
    case types.FETCH_SPONSORED_AGENCY_CODES_STARTED:
      return {
        ...state,
        isLoadingSponsoredAgencyCodes: true,
        sponsoredAgencyCodes: [],
        sponsoredAgencyCodesError: false
      };
    case types.FETCH_SPONSORED_AGENCY_CODES_SUCCEEDED:
      return {
        ...state,
        isLoadingSponsoredAgencyCodes: false,
        sponsoredAgencyCodes: action.payload || [],
        sponsoredAgencyCodesError: false
      };
    case types.FETCH_SPONSORED_AGENCY_CODES_FAILED:
      return {
        ...state,
        isLoadingSponsoredAgencyCodes: false,
        sponsoredAgencyCodes: [],
        sponsoredAgencyCodesError: true
      };
    default:
      return state;
  }
};

export default agencyStatementListReducer;
