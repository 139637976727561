import * as auditsService from "../services/auditsService";
import * as types from "../actions/actionTypes";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

export const fetchAuditBreakdownStarted = () => ({
  type: types.FETCH_AUDIT_BREAKDOWN_STARTED
});

export const fetchAuditBreakdownSucceeded = data => ({
  type: types.FETCH_AUDIT_BREAKDOWN_SUCCEDED,
  payload: data
});

export const fetchAuditBreakdownFailed = error => ({
  type: types.FETCH_AUDIT_BREAKDOWN_FAILED,
  payload: error,
  error: true
});

export function fetchAuditBreakdown(policy_number) {
  return dispatch => {
    dispatch(fetchAuditBreakdownStarted(policy_number));

    return auditsService.getAudits(policy_number).then(
      response => {
        dispatch(fetchAuditBreakdownSucceeded(response.data));
      },
      error => {
        dispatch(fetchAuditBreakdownFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
}

export const fetchAuditDocument = (policy_number, id_number) => () => {
  const documentService = new DocumentService();

  return auditsService.getAuditDocument(policy_number, id_number).then(
    response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(response.data, "sample-audit.pdf");
    },
    error => {
      documentService.clearRenderer();
      logger.error({
        description: error.toString(),
        fatal: false
      });
    }
  );
};
