import * as types from "../constants/actionTypes";
import * as newsService from "../services/newsService";
import { logger } from "../loggers";

const fetchNewsCurrentStarted = () => ({
  type: types.FETCH_NEWS_CURRENT_STARTED
});

const fetchNewsCurrentSucceeded = data => ({
  type: types.FETCH_NEWS_CURRENT_SUCCEEDED,
  payload: data
});

const fetchNewsCurrentFailed = error => ({
  type: types.FETCH_NEWS_CURRENT_FAILED,
  payload: error,
  error: true
});

// Async action creator using thunk middleware
export function fetchNewsCurrent(state, company_branch) {
  return dispatch => {
    dispatch(fetchNewsCurrentStarted());

    return newsService.getNewsCurrent(state, company_branch).then(
      response => {
        dispatch(fetchNewsCurrentSucceeded(response.data));
      },
      error => {
        dispatch(fetchNewsCurrentFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
}
