import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  FormGroup,
  Form,
  useForm,
  DatePicker,
  Dropdown,
  Button,
  Alert,
  Modal
} from "@ufginsurance/ui-kit";

const getPrimaryRateStateOptions = primaryRateStates => {
  return primaryRateStates
    ? Object.keys(primaryRateStates).map(o => ({
        label: primaryRateStates[o],
        value: o
      }))
    : [];
};

const getProducerOptions = producers => {
  return producers
    ? producers.map(o => ({ label: o.name, value: o.number }))
    : [];
};

const QuoteDetails = props => {
  const {
    error,
    hasPreviousSteps,
    initialFormValues,
    onClose,
    onDismissError,
    onReset,
    onSubmit,
    primaryRateStates,
    producers,
    agencyCode,
    fetchPrimaryRateStates,
    fetchProducers,
    producerNumber
  } = props;

  useEffect(() => {
    fetchPrimaryRateStates(agencyCode);
    fetchProducers(agencyCode, producerNumber);
  }, [agencyCode, fetchPrimaryRateStates, fetchProducers, producerNumber]);

  const primaryRateOptions = getPrimaryRateStateOptions(primaryRateStates);

  const producerOptions = getProducerOptions(producers);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 30);
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 100);
  maxDate.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const initialFromDate = new Date();
  initialFromDate.setDate(initialFromDate.getDate() - 8);

  const handleReset = () => {
    onReset();
  };

  const handleSubmit = ({ values }) => {
    onSubmit(values);
  };

  // initialValues set to a static state value because a lookup changes producer down below
  const [initialValues] = useState(initialFormValues);
  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  //console.log(initialFormValues, initialValues, props);
  // there's a producer lookup... update the producer
  useEffect(() => {
    if (initialFormValues.producer !== initialValues.producer) {
      updateForm({
        producer: initialFormValues.producer
      });
    }
  }, [initialFormValues, initialValues, updateForm]);

  return (
    <Modal
      body={
        <div className="new-quote-modal__body new-quote-modal--quote-details">
          {error && (
            <Alert type="error" onDismiss={onDismissError}>
              {error}
            </Alert>
          )}

          <Form context={form} className="quote-details-form">
            <FormGroup>
              <DatePicker
                id="effectiveDate"
                name="effectiveDate"
                label="Effective Date"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.effectiveDate}
                format="MM/DD/YYYY"
                required
                minDate={minDate}
                maxDate={maxDate}
              />

              <Dropdown
                id="primaryRateState"
                name="primaryRateState"
                label="Primary Rate State"
                options={primaryRateOptions}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.primaryRateState}
                size="md"
                required
              />
            </FormGroup>

            <FormGroup>
              <Dropdown
                id="producer"
                name="producer"
                label="Producer"
                options={producerOptions}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.producer}
                size="md"
                required
              />
            </FormGroup>

            <FormGroup align="right" className="quote-details-form__buttons">
              <Button type="reset" variant="secondary" onClick={handleReset}>
                {hasPreviousSteps ? "Back" : "Cancel"}
              </Button>
              <Button variant="primary" type="submit">
                Continue
              </Button>
            </FormGroup>
          </Form>
        </div>
      }
      title="Quote Detail"
      show
      onHide={onClose}
    />
  );
};

QuoteDetails.propTypes = {
  agencyCode: PropTypes.string.isRequired,
  error: PropTypes.string,
  fetchPrimaryRateStates: PropTypes.func.isRequired,
  fetchProducers: PropTypes.func.isRequired,
  hasPreviousSteps: PropTypes.bool,
  initialFormValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onDismissError: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  primaryRateStates: PropTypes.array.isRequired,
  producerNumber: PropTypes.string.isRequired,
  producers: PropTypes.array.isRequired
};

export default QuoteDetails;
