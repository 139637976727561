import React, { useContext } from "react";
import * as routes from "../../constants/routes";
import * as api from "../../services/onlineQuotingService";
import * as keys from "../../constants/localStorageKeys";
import { connect } from "react-redux";

import { clearLocalQuoteManually, isJsonString } from "./util";

import { toast } from "react-toastify";
import { logger } from "../../loggers";
import { cancelQuote } from "../../top-action-cards/new-quote/actions/newQuoteActions";
import OnlineQuotingContext from "../OnlineQuotingContext";
import ConfirmationModal from "../shared/ConfirmationModal";

const ExitQuoteConfirmationModal = ({
  history,
  onExit,
  setShowExitQuoteModal,
  setShowExitQuoteConfirmationModal
}) => {
  const { toastErrr } = useContext(OnlineQuotingContext);
  const sData = window.localStorage.getItem(keys.QUOTE_SUPPORT_DATA);
  const supportingData =
    sData && isJsonString(sData) ? JSON.parse(sData) : null;

  // get quoteId from supporting data...
  // quoteNumber exists if it's past step2 (in PC)
  // otherwise it's a step1/2 referenceId
  const quoteId =
    supportingData?.quoteNumber || supportingData?.referenceId || null;

  const deleteQuote = () => {
    const params = {};
    if (quoteId) {
      api
        .deleteQuote(quoteId, params)
        .then(() => {
          toast(`You have successfully deleted the quote.`, {
            position: "top-center",
            className: "success",
            autoClose: true
          });

          // send event to GA
          logger.event({
            category: "OQ_quote",
            action: "deleted",
            value: supportingData?.currentPage
          });

          setShowExitQuoteConfirmationModal(false);
          setShowExitQuoteModal(false);

          onExit().then(() => {
            clearLocalQuoteManually();
            history.push(routes.HOME);
          });
        })
        .catch(error => {
          toastErrr({
            action: "deleteQuote",
            description: "unable to delete quote",
            error,
            displayMessage: "There was an error attempting to delete the quote"
          });
        });
    }
  };

  const cancelDeleteConfirm = () => {
    setShowExitQuoteConfirmationModal(false);
  };

  return (
    <ConfirmationModal
      title="Delete confirmation"
      warningMessage="Are you sure you want to delete this quote?"
      onClickYes={deleteQuote}
      onClickNo={cancelDeleteConfirm}
      show
    />
  );
};

export default connect(null, { onExit: cancelQuote })(
  ExitQuoteConfirmationModal
);
