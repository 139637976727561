import { connect } from "react-redux";
import HitRatio from "../components/underwriting/3-year-hit-ratio/HitRatio";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  hasAgencyProductionReportsAccess: sessionSelector.hasAgencyProductionReportsAccess(
    state
  )
});

export default connect(mapStateToProps)(HitRatio);
