import {
  getRenewalOrExecutedSuretyBondDocuments,
  getExecutedBondDocument,
  getBondDocumentById
} from "../services";
import defaultToastError from "../../shared/error/DefaultToastError";
import { logger } from "../../loggers";
import { toast } from "react-toastify";

export const fetchBondDocumentById = async (bondNumber, documentId) => {
  try {
    const { data, status } = await getBondDocumentById(bondNumber, documentId);
    if (status !== 200 || !data) {
      throw new Error("Error: No bond document data was found.");
    }
    const blob = new Blob([data], {
      type: "application/pdf"
    });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  } catch (error) {
    if (error?.response?.status === 404) {
      toast(`Error: No bond document was found.`, {
        className: "error",
        toastId: "suretyBondDocumentByIdFetchError"
      });
      logger.error({
        description: error.toString(),
        fatal: false
      });
    } else {
      defaultToastError(error);
    }
  }
};

export const fetchBondDocuments = async (version, bondNumber) => {
  try {
    const { data, status } = await getRenewalOrExecutedSuretyBondDocuments(
      bondNumber,
      version
    );
    if (status !== 200 || !data) {
      throw new Error("Error: No associated bond form data was found.");
    }
    const blob = new Blob([data], {
      type: "application/pdf"
    });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  } catch (error) {
    if (error?.response?.status === 404) {
      toast(
        `Error: No associated ${
          version === "original" ? "Executed" : "Renewal"
        } Bond Form was found.`,
        {
          className: "error",
          toastId: "suretyExecutedOrRenewalBondFormFetchError"
        }
      );
      logger.error({
        description: error.toString(),
        fatal: false
      });
    } else {
      defaultToastError(error);
    }
  }
};

export const fetchBondExecution = async (
  bondNumber,
  agencyNumber,
  bondType = "Bid",
  transactionNumber = null
) => {
  try {
    const { data, status } = await getExecutedBondDocument(
      bondNumber,
      getBondType(bondType),
      agencyNumber,
      transactionNumber
    );
    if (status !== 200 || !data) {
      throw new Error("Error: No bill was found.");
    }
    const blob = new Blob([data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const pdfWindow = window.open(url, "_blank");
    const onCloseInterval = setInterval(() => {
      if (pdfWindow.closed) {
        clearInterval(onCloseInterval);
        URL.revokeObjectURL(url);
      }
    }, 5000);
  } catch (error) {
    if (error?.response?.status === 404) {
      toast(`Error: No associated bill found.`, {
        className: "error",
        toastId: "suretyExecutedBondFormFetchError"
      });
      logger.error({
        description: error.toString(),
        fatal: false
      });
    } else {
      defaultToastError(error);
    }
  }
};

const getBondType = bondType => {
  switch (bondType) {
    case "LICENSE & PERMIT":
      return "LicenseAndPermit";
    case "CONTRACT":
      return "Contract";
    case "NOTARY PUBLIC":
      return "Notary";
    case "Quick":
      return "Quick";
    case "CONSENT OF SURETY":
      return "ConsentOfSurety";
    case "Bid":
    default:
      // On the backend, Quick is really the only thing with a different path. Default of Bid is fine.
      return "Bid";
  }
};
