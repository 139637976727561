const kentuckyCountyNames = [
  "Bell",
  "Boyd",
  "Breathitt",
  "Butler",
  "Carter",
  "Christian",
  "Clay",
  "Daviess",
  "Edmonson",
  "Elliott",
  "Floyd",
  "Greenup",
  "Hancock",
  "Harlan",
  "Henderson",
  "Hopkins",
  "Jackson",
  "Johnson",
  "Knott",
  "Knox",
  "Laurel",
  "Lawrence",
  "Lee",
  "Leslie",
  "Letcher",
  "McCreary",
  "McLean",
  "Martin",
  "Morgan",
  "Muhlenberg",
  "Ohio",
  "Owsley",
  "Perry",
  "Union",
  "Webster",
  "Whitley",
  "Wolfe"
];
const kentuckyCountyCodes = [
  "007",
  "010",
  "013",
  "016",
  "022",
  "024",
  "026",
  "030",
  "031",
  "032",
  "036",
  "045",
  "046",
  "048",
  "051",
  "054",
  "055",
  "058",
  "060",
  "061",
  "063",
  "064",
  "065",
  "066",
  "067",
  "074",
  "075",
  "080",
  "088",
  "089",
  "092",
  "095",
  "097",
  "113",
  "117",
  "118",
  "119"
];

export const isKentuckyMineSubsidence = location =>
  location?.address?.state === "KY" &&
  (kentuckyCountyCodes.includes(location?.address?.countyCode_UFG) ||
    kentuckyCountyNames.some(n => location?.address?.county.startsWith(n)));
