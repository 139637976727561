import { v4 } from "uuid";
import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const installmentReducer = (
  state = initialState.installmentDetails,
  action
) => {
  switch (action.type) {
    case types.FETCH_INSTALLMENT_STARTED:
      return {
        ...initialState.installmentDetails,
        isLoading: true
      };
    case types.FETCH_INSTALLMENT_SUCCEEDED:
      return {
        ...state,
        print_installment_data: action.payload.print_installment_data,
        installments: (action.payload.installments || []).map(x => ({
          key: v4(),
          ...x,
          policies: (x.policies || []).map(p => ({
            key: v4(),
            ...p
          }))
        })),
        isLoading: false
      };
    case types.FETCH_INSTALLMENT_FAILED:
      return {
        ...state,
        print_installment_data: {},
        isLoading: false,
        error: action.payload
      };
    case types.INSTALLMENT_PRINT_POST_STARTED:
      return {
        ...state,
        isPrintPostFailed: false
      };
    case types.INSTALLMENT_PRINT_POST_SUCCEEDED:
      return {
        ...state,
        isPrintPostFailed: false
      };
    case types.INSTALLMENT_PRINT_POST_FAILED:
      return {
        ...state,
        isPrintPostFailed: action.payload
      };
    default:
      return state;
  }
};

export default installmentReducer;
