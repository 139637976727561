import React, { useState, useContext } from "react";
import * as api from "../../services/onlineQuotingService";
import { v4 } from "uuid";
import { toTitleCase } from "../../components/Factory";
import { isCancel } from "axios";
import { sortByProperty } from "../shared/util";

import {
  InputApiCall,
  Button,
  Table,
  Alert,
  FormGroup,
  FlexRow,
  Form,
  useForm,
  Modal
} from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";

let abortController;
const minSearchCharacters = 3;

const AccountSearchModal = ({
  onHide,
  setAccountFromSearch,
  updateNameField
}) => {
  const { toastErrr } = useContext(OnlineQuotingContext);

  const [searchResults, setSearchResults] = useState();
  const [selectedInTable, setSelectedInTable] = useState();
  const [searchedText, setSearchedText] = useState();

  const form = useForm({ values: { account_search: "" }, onSubmit: () => {} });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const resetAccountSearch = () => {
    setSearchResults(null);
    setSearchedText(null);
    setSelectedInTable(null);
  };

  /**
   * DO ACCOUNT SEARCH
   */
  const handleAccountSearch = async searchString => {
    if (searchString?.length < minSearchCharacters) return;

    const params = { searchText: encodeURIComponent(searchString) };

    return await api
      .getAccountSearch(params, {
        signal: abortController?.signal
      })
      .then(results => {
        const accounts = results.data
          .map(a => {
            const id = v4();

            const account_name = !!a?.accountHolder?.contactName
              ? a?.accountHolder?.contactName
              : `${a?.accountHolder?.lastName} ${a?.accountHolder?.firstName}`;

            const {
              addressLine1 = "",
              city = "",
              state = "",
              postalCode = ""
            } = a?.accountHolder?.primaryAddress;

            return {
              id,
              value: a?.id,
              account_name,
              account_address: `${toTitleCase(addressLine1)}, ${toTitleCase(
                city
              )}, ${state} ${postalCode}`,
              account_number: a?.accountNumber || "",
              account: { ...a, id, type: "api" }
            };
          })
          .sort(sortByProperty("label"));

        return { searchString, accounts };
      })
      .catch(error => {
        if (!isCancel(error)) {
          toastErrr({
            action: "getAccountSearch",
            description: "account search failure",
            error,
            payload: params
          });
        }
      }); // end API
  };

  const handleOnSearch = props => {
    const { searchString, accounts } = props;
    setSearchedText(searchString);
    setSearchResults(accounts);
  };

  /**
   * reset the results if the user changes the value
   * ... doing so on keydown avoids the debouncing time to wait
   */
  const handleOnKeydown = e => {
    const key = e?.event?.key;

    if (key === "Enter") {
      e.event.preventDefault();
      return false;
    }
    const resetKeys = ["Backspace", "Delete"];
    if (
      key &&
      !e?.event?.metaKey && // avoid combination keys like CTRL
      (key?.length === 1 || resetKeys.includes(key))
    )
      resetAccountSearch();
  };

  /**
   * TABLE COLUMN CONFIG
   */
  const columns = [
    {
      key: "account_name",
      label: "Customer Name",
      className: "account__search__result-name"
    },
    {
      key: "account_address",
      label: "Customer Address",
      className: "account__search__result-address"
    }
  ];

  /**
   * set the search input focus when the component first renders
   */
  const onModalOpen = () => {
    const input = document.querySelector(
      "#account__search__form #account_search"
    );
    if (input) input.focus();
  };

  return (
    <Modal
      title="Commercial Small Business Quoting"
      show
      className="oq__account-search__modal"
      altCloseMethod={false}
      onReveal={onModalOpen}
      body={
        <div>
          <Form id="account__search__form" context={form}>
            <FormGroup>
              <InputApiCall
                id="account_search"
                name="account_search"
                label="Search for Customer"
                placeholder="Search for Customer"
                className="oq__account-search"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.account_search}
                searchFunction={handleAccountSearch}
                onKeyDown={handleOnKeydown}
                onReset={resetAccountSearch}
                onSearch={handleOnSearch}
                debounceTime={1500}
                size="full"
                showOptions={false}
              />
            </FormGroup>
          </Form>

          {searchResults?.length > 0 && (
            <Table
              id="oq__account-search__results"
              rowKey="id"
              columns={columns}
              data={searchResults}
              showPagination
              itemsPerPage={5}
              selectable
              selectableSingle={{
                rowAriaLabelColumn: "name",
                tableAriaLabel: "Select a single row"
              }}
              onRowClick={selectedRow => {
                setSelectedInTable(selectedRow);
              }}
            />
          )}
          {searchResults?.length === 0 && (
            <div className="oq__account-search__no-results">
              <p>
                No accounts found that match <b>{searchedText}</b>.{" "}
              </p>
              <div>
                Create a new account for:{" "}
                <Button
                  wrapperClassName="oq__account-search__no-results__create-link"
                  inline
                  isLink
                  onClick={() => {
                    updateNameField(searchedText);
                    onHide();
                  }}
                >
                  {" "}
                  {searchedText}
                </Button>
              </div>
            </div>
          )}
          {selectedInTable?.row?.account?.inforce && (
            <div>
              <br />
              <Alert type="warning" dismissible={false}>
                The account `{selectedInTable?.row?.account_name}` is already
                insured by your agency with UFG Insurance. Your quote will be
                reviewed by your UFG underwriter.
              </Alert>
            </div>
          )}
          <FlexRow align="right" className="pad-top">
            <Button className="cancel" onClick={onHide}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="continue"
              disabled={!selectedInTable}
              onClick={() => {
                /**
                 * sends the exact object that is returned from the account search
                 * */
                setAccountFromSearch(selectedInTable?.row?.account);
                onHide();
              }}
            >
              Use Selected Customer
            </Button>
          </FlexRow>
        </div>
      }
      onHide={onHide}
      size="lg"
    />
  );
};

export default AccountSearchModal;
