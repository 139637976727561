import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Button, FlexRow, Modal } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";

const QuoteUnderwriterReviewErrorModal = ({
  history,
  showUnderwriterReviewErrorModal,
  setShowUnderwriterReviewErrorModal,
  policyNumber
}) => {
  const onExitUnderwriterReviewErrorModal = () => {
    setShowUnderwriterReviewErrorModal(false);
    history.push(`${routes.ACCOUNT_HOME}/${policyNumber}/change-requests`);
  };

  return (
    <>
      {showUnderwriterReviewErrorModal && (
        <Modal
          id="driver-change__underwriter-review-error-modal"
          show={showUnderwriterReviewErrorModal}
          title={<b>We’re sorry…</b>}
          body={
            <FlexRow>
              <div>
                <p>
                  <b>
                    There was a problem processing your policy change request. A
                    notification has been sent to your underwriter for review.
                    They will reach out to you with any questions before issuing
                    your change.
                  </b>
                </p>
              </div>
            </FlexRow>
          }
          footer={
            <FlexRow>
              <Button
                onClick={onExitUnderwriterReviewErrorModal}
                variant="primary"
                wide
              >
                Exit
              </Button>
            </FlexRow>
          }
          altCloseMethod={false}
          closeIcon
          onHide={onExitUnderwriterReviewErrorModal}
        />
      )}
    </>
  );
};

QuoteUnderwriterReviewErrorModal.propTypes = {
  history: PropTypes.object.isRequired,
  showUnderwriterReviewErrorModal: PropTypes.bool.isRequired,
  setShowUnderwriterReviewErrorModal: PropTypes.func.isRequired,
  policyNumber: PropTypes.string
};

export default withRouter(QuoteUnderwriterReviewErrorModal);
