import React, { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 } from "uuid";
import {
  Alert,
  Button,
  ContentHeader,
  Currency,
  Dropdown,
  FlexRow,
  Form,
  FormGroup,
  Icon,
  Modal,
  Panel,
  TextArea,
  Table
} from "@ufginsurance/ui-kit";
import { convertBytesToMB, formatCurrencyUSD } from "../../surety/shared/utils";
import DocumentUploadModal from "./DocumentUploadModal";
import {
  jobSiteLimitsArray,
  formatJobSitesWithNewDeductible,
  findAdditionalInsuredLabel
} from "./lib/utils";
import useGetDeductibleOptions from "./hooks/useGetDeductibleOptions";
import NewProducerModal from "./components/NewProducerModal";
import useGetFormattedProducerOptions from "./hooks/useGetFormattedProducerOptions";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { deleteRiskIndicationDocument } from "../shared/services/index";
import AdditionalInsuredModal from "./components/AdditionalInsuredModal";
import { toTitleCase } from "../../components/Factory";

export default function Summary({
  form,
  producers,
  agencyCode,
  isValidEffectiveDate
}) {
  const {
    errors,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    updateForm,
    values
  } = form;
  const initialDeductible = values.job_sites[0].deductible;
  const { buildersRiskDocumentUploadEnabled } = useFlags();
  const [showEditDeductibleModal, setShowEditDeductibleModal] = useState(false);
  const [showNewProducerModal, setShowNewProducerModal] = useState(false);
  const [showAdditionalInsuredModal, setShowAdditionalInsuredModal] =
    useState(false);
  const [tempDeductible, setTempDeductible] = useState({
    value: initialDeductible
  });
  const [deductibleOptions] = useGetDeductibleOptions(values);
  const [producerOptions] = useGetFormattedProducerOptions(producers, values);
  const isPremiumEditable = false;
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [editAddInID, setEditAddInID] = useState();
  const [additionalToDelete, setAdditionalToDelete] = useState(null);

  // Set the summary premium with the deductible of the first job site (initialDeductible).
  useEffect(() => {
    const jobSiteLimits = jobSiteLimitsArray(values?.job_sites || []);
    updateForm({
      values: {
        ...values,
        jobSiteLimits,
        deductible: Number(initialDeductible)
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeductibleOnChange = ({ value, event }) => {
    setTempDeductible({ value, premium: event.newValue?.modifiedPremium });
  };

  const removeAttachment = attachment => {
    setAttachmentToDelete(attachment);
  };

  const removeAttachmentClose = () => {
    setAttachmentToDelete(null);
  };

  const removeAttachmentConfirm = () => {
    deleteRiskIndicationDocument(
      values.risk_indication_id,
      attachmentToDelete.name,
      values.agency_code
    ).then(res => {
      if (res.status === 200) {
        updateForm({
          values: {
            ...values,
            documents: values.documents.filter(
              _ => _.name !== attachmentToDelete.name
            )
          }
        });
      }
    });
    setAttachmentToDelete(null);
  };

  const removeAdditional = id => {
    setAdditionalToDelete(id);
  };

  const removeAdditionalClose = () => {
    setAdditionalToDelete(null);
  };

  const removeAdditionalConfirm = () => {
    const filteredAddIns = values.additional_insured.filter(
      i => additionalToDelete !== i.id
    );
    updateForm({
      values: {
        ...values,
        additional_insured: filteredAddIns
      }
    });
    setAdditionalToDelete(null);
  };

  const handleUpdateDeductible = () => {
    const updatedJobSites = formatJobSitesWithNewDeductible(
      values.job_sites,
      tempDeductible.value
    );

    updateForm({
      values: {
        ...values,
        deductible: parseInt(tempDeductible.value),
        premium: Number(tempDeductible.premium),
        job_sites: updatedJobSites
      }
    });
    setShowEditDeductibleModal(false);
  };

  const handleEditDeductibleModalOnClose = () => {
    // Reset the values to default.
    setTempDeductible({ value: values?.deductible, premium: values?.premium });
    setShowEditDeductibleModal(false);
  };

  const handleProducerCodeOnChange = ({ value, event, field }) => {
    if (value === "other") setShowNewProducerModal(true);

    const selectedProducer = producers?.find(
      producer => producer.number === parseInt(value)
    );

    if (selectedProducer) {
      updateForm({
        values: {
          ...values,
          producer_code: value,
          producer: {
            first_name: selectedProducer.first_name,
            last_name: selectedProducer.last_name,
            email: selectedProducer.email,
            phone: selectedProducer.phone_number
          }
        },
        errors: {
          ...errors,
          producer_code: [],
          producer: []
        }
      });
    } else {
      handleOnChange({ value, event, field });
    }
  };

  const hasKentuckyJobsites = () => {
    return (values?.job_sites || []).some(_ => _.state === "KY");
  };

  const useMinimumPremium = values?.minimum_premium >= values.premium;
  const isPremiumBlocked = values?.premium === "";

  return (
    <div className="summary">
      <ContentHeader>All Set: Here is your quote</ContentHeader>
      {isPremiumBlocked && (
        <Alert
          type="warning"
          dismissible={false}
          className="premium-blocked-alert"
        >
          We apologize, we cannot return a quote at this time. You can submit
          your application for underwriter review.
        </Alert>
      )}
      {!isValidEffectiveDate && (
        <Alert className="mb-2" type="error" dismissible={false}>
          Effective date cannot be in the past.
        </Alert>
      )}
      {!isPremiumBlocked && useMinimumPremium > 0 && (
        <Alert className="mb-2" type="warning" dismissible={false}>
          Minimum premium is{" "}
          <span>{formatCurrencyUSD(values.minimum_premium, 0)}</span>
        </Alert>
      )}
      <Form context={form} className="overview">
        <div className="premium-info">
          <b>Premium Indication</b>
          <div className="premium-indication-value">
            {isPremiumBlocked ? (
              <>N/A</>
            ) : (
              <span>
                {formatCurrencyUSD(
                  useMinimumPremium ? values?.minimum_premium : values?.premium,
                  0
                )}
              </span>
            )}
          </div>
          <Panel title="Rating" className="rating-details mt-1" rounded>
            <div>
              {values?.jobSiteLimits?.buildingLimits?.length > 1 ? (
                <div>
                  {values.jobSiteLimits.buildingLimits.map(jobSite => (
                    <FlexRow
                      className="summary__siteLimit"
                      align="justify"
                      key={v4()}
                    >
                      <span className="summary__align_text">
                        {jobSite.label.length > 22
                          ? jobSite.label.substring(0, 22) + "..."
                          : jobSite.label}{" "}
                      </span>
                      <Currency
                        includeDecimals={false}
                        amount={jobSite.value || 0}
                      />
                    </FlexRow>
                  ))}
                </div>
              ) : (
                <FlexRow align="justify">
                  <span>Total Project Limit</span>
                  <Currency
                    includeDecimals={false}
                    amount={values?.jobSiteLimits?.totalLimit || 0}
                  />
                </FlexRow>
              )}
            </div>
            <hr />
            <p>
              <span>Deductible</span>{" "}
              <span>
                <Currency
                  amount={values?.deductible || 0}
                  includeDecimals={false}
                />
                <Button
                  isLink
                  className="noOutline"
                  onClick={() => setShowEditDeductibleModal(true)}
                  disabled={isPremiumBlocked}
                >
                  Edit
                </Button>
              </span>
            </p>
            <hr />
            {hasKentuckyJobsites() && (
              <>
                <span className="rating-table-alert">
                  KY tax and surcharge fees are not included in this premium
                  indication.
                </span>
                <hr />
              </>
            )}
            {values.windhail_deductible > 0 && (
              <>
                <span className="rating-table-alert">
                  A Wind and Hail deductible of{" "}
                  {parseInt(100 * values.windhail_deductible, 10)}% applies.
                </span>
                <hr />
              </>
            )}
            <p>
              <span>Coverage Premium</span>
              {isPremiumBlocked ? (
                <>N/A</>
              ) : (
                <span>
                  {formatCurrencyUSD(values?.coverage_amount || 0, 0)}
                </span>
              )}
            </p>
            {isPremiumEditable && (
              <Button
                isLink
                className="noOutline mt-1"
                disabled={isPremiumBlocked}
              >
                Review/Edit Coverages
              </Button>
            )}
          </Panel>
        </div>

        <div className="extras">
          <b>Additional Interests/Insured</b>
          <hr className="my-1" />
          {!!values?.additional_insured?.length && (
            <ul className="additional-insured-list">
              {values.additional_insured.map(
                ({
                  address_line,
                  city,
                  company_name,
                  first_name,
                  id,
                  last_name,
                  state,
                  relationship,
                  zip
                }) => {
                  const name = toTitleCase(
                    !!company_name ? company_name : `${first_name} ${last_name}`
                  );
                  return (
                    <li key={id}>
                      <div>{name}</div>
                      <div>
                        <em>
                          {toTitleCase(
                            findAdditionalInsuredLabel(relationship)
                          )}
                        </em>
                      </div>
                      <div>{toTitleCase(address_line)}</div>
                      <div>
                        {toTitleCase(city)}, {state.toUpperCase()} {zip}
                      </div>
                      <FormGroup className="additional-insured-list__buttons">
                        <Button
                          id="editAddlInsureds"
                          isLink
                          variant="primary"
                          onClick={() => {
                            setEditAddInID(id);
                            setShowAdditionalInsuredModal(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          isLink
                          variant="tertiary"
                          onClick={() => {
                            removeAdditional(id);
                          }}
                        >
                          Remove
                        </Button>
                      </FormGroup>
                    </li>
                  );
                }
              )}
            </ul>
          )}
          <Button
            isLink
            inline
            className="my-1"
            onClick={() => setShowAdditionalInsuredModal(true)}
          >
            Add Additional Insured
          </Button>
          <hr />
          <b>Add Comments/Remarks and/or Supporting Documents</b>
          <FormGroup>
            <TextArea
              id="comments"
              name="comments"
              label="Comments/Remarks"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.comments}
            />
          </FormGroup>

          {buildersRiskDocumentUploadEnabled && (
            <>
              <DocumentUploadModal form={form} />
              {values?.documents?.length > 0 && (
                <Table
                  className="mt-2"
                  data={values.documents}
                  rowKey="name"
                  columns={[
                    {
                      key: "name",
                      label: "Name",
                      sortable: true
                    },
                    {
                      label: "Size",
                      className: "size",
                      key: "fileSize",
                      align: "center",
                      element: row => (
                        <span role="presentation">
                          {convertBytesToMB(row.size)} mb
                        </span>
                      )
                    },
                    {
                      label: "Remove",
                      className: "remove",
                      key: "removeFile",
                      align: "center",
                      element: row => {
                        return (
                          <Button
                            onClick={() => removeAttachment(row)}
                            type="button"
                            isLink
                            variant="secondary"
                          >
                            <Icon
                              className="remove-icon"
                              icon="fasTimesCircle"
                            />
                          </Button>
                        );
                      }
                    }
                  ]}
                />
              )}
            </>
          )}

          <FormGroup>
            <Dropdown
              id="producer_code"
              name="producer_code"
              label="Producer"
              className="mt-1"
              options={producerOptions}
              value={values.producer_code}
              onChange={handleProducerCodeOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              required
              size="lg"
            />
          </FormGroup>
        </div>
      </Form>

      {showEditDeductibleModal && (
        <Modal
          show={showEditDeductibleModal}
          onHide={handleEditDeductibleModalOnClose}
          title="Edit Deductible"
          size="sm"
          body={
            <Form context={form}>
              {deductibleOptions?.some(
                _ => values?.minimum_premium >= _?.modifiedPremium
              ) && (
                <Alert className="mb-2" type="warning" dismissible={false}>
                  Minimum premium is{" "}
                  <Currency
                    amount={values?.minimum_premium}
                    includeDecimals={false}
                  />
                </Alert>
              )}
              <Dropdown
                id="deductible"
                name="deductible"
                label="Deductible"
                options={deductibleOptions}
                onChange={handleDeductibleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={String(tempDeductible.value)}
                size="fill"
                required
              />
            </Form>
          }
          footer={
            <FlexRow align="right">
              <Button onClick={handleEditDeductibleModalOnClose}>Cancel</Button>
              <Button variant="primary" onClick={handleUpdateDeductible}>
                Update Deductible
              </Button>
            </FlexRow>
          }
        />
      )}
      {!!attachmentToDelete && (
        <ConfirmationModal
          show={!!attachmentToDelete}
          id={v4()}
          title="Remove Attachment"
          message={`Are you sure you want to remove this attachment? Filename: ${attachmentToDelete.name}`}
          handleClose={removeAttachmentClose}
          handleConfirm={removeAttachmentConfirm}
          confirmText="Confirm"
          closeIcon
        />
      )}
      {showNewProducerModal && (
        <NewProducerModal
          form={form}
          showNewProducerModal={showNewProducerModal}
          setShowNewProducerModal={setShowNewProducerModal}
        />
      )}
      {showAdditionalInsuredModal && (
        <AdditionalInsuredModal
          agencyCode={agencyCode}
          buildersRiskForm={form}
          showAdditionalInsuredModal={showAdditionalInsuredModal}
          setShowAdditionalInsuredModal={setShowAdditionalInsuredModal}
          editAddInID={editAddInID}
          setEditAddInID={setEditAddInID}
        />
      )}
      {!!additionalToDelete && (
        <ConfirmationModal
          show={!!additionalToDelete}
          title="Remove Additional Insured"
          message={`Are you sure you want to remove this Additional Insured?`}
          handleClose={removeAdditionalClose}
          handleConfirm={removeAdditionalConfirm}
          confirmText="Confirm"
          closeIcon
        />
      )}
    </div>
  );
}
