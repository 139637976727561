import React, { useState, useCallback, useContext } from "react";

import {
  Button,
  Icon,
  Currency,
  FormGroup,
  FlexRow,
  Select,
  useForm,
  Form
} from "@ufginsurance/ui-kit";
import { productKeys, quotePath } from "../shared/constants";
import ConfirmationModal from "../shared/ConfirmationModal";
import OnlineQuotingContext from "../OnlineQuotingContext";
import OqModal from "../shared/OqModal";
import _set from "lodash/set";
import { sortByProperty } from "../shared/util";
import { getCoverageDetailsForLogging } from "../shared/coveragePanels";
import "./UmbrellaForm.scss";

const umbrellaLimitsAllowed = [
  "1,000,000",
  "2,000,000",
  "3,000,000",
  "4,000,000",
  "5,000,000"
];

const UmbrellaForm = () => {
  const {
    quoteData,
    changeLineOfBusiness,
    updateCoveragesPromise,
    rateQuote,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const cuCoverages =
    quoteData.lobData?.cuCommercialUmbrella?.coverages?.clausesGroups
      ?.coverages;
  const libCoverage = cuCoverages?.find(
    coverage => coverage.name === "Commercial Umbrella Liability"
  );

  const umbrellaLimitTerms = libCoverage?.terms[0];

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAddUmbrellaForm, setShowAddUmbrellaForm] = useState(false);
  const [spinnerUmbrella, setSpinnerUmbrella] = useState(false);
  const [liabilityLimitOption, setLiabilityLimitOption] = useState([]);

  const [initialUmbrellaValue, setInitialUmbrellaValue] = useState();

  const handleLimitChange = () => {
    //set the data to update the coverage
    setSpinnerUmbrella(true);

    const updatedCoverage = { ...libCoverage };
    updatedCoverage.updated = true;
    updatedCoverage.terms[0].updated = true;
    updatedCoverage.terms[0].chosenTerm = values.umbrellaLimit;

    const coveragesToSave = {};
    const productName = "cuCommercialUmbrella";
    _set(coveragesToSave, quotePath.culGroupCoverages, [updatedCoverage]);

    updateCoveragesPromise({
      coveragesToSave,
      productName,
      coverageDetails: getCoverageDetailsForLogging({
        coverage: updatedCoverage,
        termToUpdate: updatedCoverage?.terms?.[0] || null
      })
    }).then(() => {
      setSpinnerUmbrella(false);
      setShowAddUmbrellaForm(false);

      rateQuote().catch(error =>
        toastErrr({
          action: "umbrella: rateQuote",
          displayMessage:
            "An error occurred. Unable to rate quote after updating Umbrella.",
          description: "failed to rate after updating umbrella",
          error
        })
      );
    });
  };

  // initialize the hook
  const initialValues = {
    umbrellaLimit: ""
  };

  const formUmbrella = useForm({
    values: initialValues,
    onSubmit: handleLimitChange
  });
  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm
  } = formUmbrella;

  const quoteHasUmbrella = quoteData?.lobData?.hasOwnProperty(
    "cuCommercialUmbrella"
  );

  //Load limit options
  const LoadLimitOptions = () => {
    let option = [];

    if (umbrellaLimitTerms) {
      option = (umbrellaLimitTerms?.options || [])
        .filter(data => umbrellaLimitsAllowed.includes(data.name))
        .map(data => ({
          label: data.name,
          value: data.code
        }))
        .sort(sortByProperty("label"));
    }

    setLiabilityLimitOption(option);
    //set the default value for the drop down in the form
    updateForm({
      values: {
        umbrellaLimit: umbrellaLimitTerms?.chosenTerm || ""
      }
    });
    setInitialUmbrellaValue(umbrellaLimitTerms?.chosenTerm || "");
  };

  // add umberlla
  const addUmbrella = () => {
    changeLineOfBusiness({
      action: "Adding",
      product: productKeys.CXLine,
      rateAfter: true
    });
  };

  // remove umberlla
  const removeUmbrella = useCallback(() => {
    changeLineOfBusiness({
      action: "Removing",
      product: productKeys.CXLine,
      rateAfter: true
    });
  }, [changeLineOfBusiness]);

  const umbrellaTotal =
    quoteHasUmbrella &&
    quoteData?.quoteData?.offeredQuotes[0].linePremiumDTO_UFG.find(
      l => l.lob === "CXLine"
    )?.total?.amount;

  const showSelectedLimit = (umbrellaLimitTerms?.chosenTermValue || "").replace(
    ",000,000",
    "M"
  );

  return (
    <>
      {showAddUmbrellaForm && (
        <OqModal
          className="oq_modal__edit_umbrella-liability_limit"
          title="Update the Liability Limit"
          show={showAddUmbrellaForm}
          closeIcon={false}
          size="sm"
          body={
            <div>
              {liabilityLimitOption.length > 0 && (
                <Form
                  context={formUmbrella}
                  className="oq__form__edit_umbrella-liability_limit"
                >
                  <FormGroup>
                    <Select
                      id="umbrellaLimit"
                      name="umbrellaLimit"
                      label="Liability Limit"
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      onValidate={handleOnValidate}
                      value={values.umbrellaLimit}
                      options={liabilityLimitOption || []}
                      required
                      size="full"
                      isClearable={false}
                    />
                  </FormGroup>
                </Form>
              )}
            </div>
          }
          footer={
            <FlexRow className="oq__form__edit_limit_footer">
              <Button
                disabled={spinnerUmbrella}
                onClick={() => setShowAddUmbrellaForm(false)}
              >
                {values.umbrellaLimit === initialUmbrellaValue
                  ? "Close"
                  : "Cancel"}
              </Button>
              <Button
                variant="primary"
                type="button"
                wrapperClassName="align-right"
                onClick={handleLimitChange}
                disabled={
                  !!invalidFields.length ||
                  spinnerUmbrella ||
                  values.umbrellaLimit === initialUmbrellaValue
                }
                spinner={spinnerUmbrella}
              >
                Update Quote
              </Button>
            </FlexRow>
          }
        />
      )}
      {!quoteHasUmbrella && (
        <tr className="oq__premium__summary__lob__content__item">
          <td colSpan="3">
            <span className="oq__premium__summary__lob__content__item-add">
              <Button onClick={addUmbrella} isLink inline>
                Add Umbrella
              </Button>
            </span>
          </td>
        </tr>
      )}
      {quoteHasUmbrella && (
        <>
          <tr className="oq__premium__summary__lob__content__item">
            <td colSpan="3">
              <div className="oq__premium__summary__umbrella-row">
                <span>Umbrella (${showSelectedLimit})</span>
                <Button
                  className="oq__premium__summary__lob__content__item-edit-button"
                  onClick={() => {
                    LoadLimitOptions();
                    setShowAddUmbrellaForm(true);
                  }}
                  isLink
                  inline
                >
                  Edit Limit
                </Button>
                <span>
                  <b>
                    {umbrellaTotal && (
                      <Currency
                        amount={umbrellaTotal}
                        includeDecimals={false}
                      />
                    )}
                  </b>
                </span>
              </div>
            </td>
            <td>
              <Button
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
                isLink
                inline
                variant="secondary"
                title="Remove Umbrella"
              >
                <Icon icon="fasTrashAlt" />
              </Button>
            </td>
          </tr>
        </>
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <div>Are you sure you want to remove Umbrella Policy?</div>
          }
          onClickYes={removeUmbrella}
          show={showDeleteConfirmation}
          onHide={() => {
            setShowDeleteConfirmation(!showDeleteConfirmation);
          }}
        />
      )}
    </>
  );
};

export default UmbrellaForm;
