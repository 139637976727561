import { useEffect } from "react";
import { findStateById } from "../../selectors";
import { useCurrentRouteMatch } from "../hooks";

export const useStateRouteValidation = (
  selectedState,
  stateListData,
  setSelectedState
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteStateId = currentMatch?.params?.stateId;

  useEffect(() => {
    const hasStatesList = stateListData && stateListData.length > 0;
    const isStateSelectionRouteWithoutSelection =
      hasStatesList && !selectedState && currentRouteStateId;
    if (isStateSelectionRouteWithoutSelection) {
      const routeState = findStateById(stateListData, currentRouteStateId);
      if (routeState) {
        setSelectedState(routeState);
      }
    }
  }, [stateListData, selectedState, currentRouteStateId, setSelectedState]);

  useEffect(() => {
    const newStateSelectionRouteChange =
      selectedState?.value &&
      currentRouteStateId &&
      selectedState.value !== currentRouteStateId;
    if (newStateSelectionRouteChange) {
      const routeState = findStateById(stateListData, currentRouteStateId);
      if (routeState) {
        setSelectedState(routeState);
      }
    }
  }, [selectedState, currentRouteStateId, stateListData, setSelectedState]);

  return null;
};
