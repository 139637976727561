import React, { useContext } from "react";
import cn from "classnames";
import { Modal } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import UpdateOverlayTransparent from "./UpdateOverlayTransparent";

const OqModal = ({ className, size, body, ...rest }) => {
  /**
   * because this modal is used for updating the effective date on the NSL
   * (where the oq context doesn't exist), we pull in the 'quoteIsUpdating' from
   * the context conditionally
   */
  const oq_context = useContext(OnlineQuotingContext);
  const quoteIsUpdating = oq_context?.quoteIsUpdating;

  return (
    <Modal
      className={cn("oq__modal", className)}
      size={size ? size : "lg"}
      altCloseMethod={false}
      body={
        <>
          {quoteIsUpdating && <UpdateOverlayTransparent />}
          {body}
        </>
      }
      {...rest}
    />
  );
};

export default OqModal;
