import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";

import {
  FormGroup,
  Form,
  useForm,
  Input,
  Dropdown,
  DateRange,
  Button
} from "@ufginsurance/ui-kit";

const { form_errors } = Translations.reports.tabAgency.monthlyExperienceTab;

const ExperienceDetailForm = ({
  emailList,
  is109015Agent,
  submit,
  hasAgencyProductionReportsAccess,
  email,
  isLoadingReport
}) => {
  const { reportFormat, sortListBy } =
    Translations.reports.tabAgency.experienceDetailTab;
  const initialValues = {
    reportFormatTypes: "P",
    name: "",
    emailTo: hasAgencyProductionReportsAccess ? email || null : null,
    beginDate: "",
    endDate: "",
    sortByList: "1",
    experienceDetailFormRange: { from: "", to: "" }
  };

  const handleSubmission = ({ values }) => {
    const formData = {
      ...values,
      beginDate: values.experienceDetailFormRange.from,
      endDate: values.experienceDetailFormRange.to
    };
    submit(formData, () => updateForm({ values: initialValues }));
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 365 * 6);

  const rangeValidation = ({ field, value, event }) => {
    const errors = handleOnValidate({ field, value, event });
    if (!!value.from && !!value.to) {
      const f = new Date(value.from);
      const t = new Date(value.to);
      if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) > 365) {
        errors.push(form_errors.date_exceeds);
      }
    }
    return errors;
  };

  return (
    <div className="reports-form__container">
      <div className="reports-form__note">
        {Translations.reports.tabAgency.experienceDetailTab.note}
      </div>

      <Form context={form} className="agency-experience-detail-form">
        <FormGroup>
          <DateRange
            id="experienceDetailFormRange"
            name="experienceDetailFormRange"
            labelFrom={
              Translations.reports.tabAgency.experienceDetailTab.label.beginDate
            }
            labelTo={
              Translations.reports.tabAgency.experienceDetailTab.label.endDate
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={rangeValidation}
            value={values.experienceDetailFormRange}
            format="MM/DD/YYYY"
            required
            minDate={minDate}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            id="sortByList"
            name="sortByList"
            label={
              Translations.reports.tabAgency.experienceDetailTab.label
                .sortListBy
            }
            options={Object.keys(sortListBy).map(key => ({
              value: key,
              label: sortListBy[key]
            }))}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.sortByList}
            size="lg"
          />
        </FormGroup>

        {!is109015Agent ? (
          <FormGroup>
            <Dropdown
              id="emailTo"
              name="emailTo"
              label={
                Translations.reports.tabAgency.experienceDetailTab.label.emailTo
              }
              placeholder="Please select a person to send the report to"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.emailTo}
              size="lg"
              options={emailList.map(q => ({
                value: q.email_address,
                label: q.name + " " + q.email_address
              }))}
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <Input
              id="emailTo"
              name="emailTo"
              label={
                Translations.reports.tabAgency.experienceDetailTab.label.emailTo
              }
              placeholder="Please select a person to send the report to"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={({ field, value, validation }) => {
                const errors = handleOnValidate({ field, value, validation });
                // eslint-disable-next-line
                const emailRegex = /@/;
                if (!!is109015Agent && emailRegex.test(value)) {
                  errors.push("Do not enter an email address.");
                }

                return errors;
              }}
              value={values.emailTo}
              required
            />
            <div className="align-with-input">@unitedfiregroup.com</div>
          </FormGroup>
        )}

        <FormGroup>
          <Dropdown
            id="reportFormatTypes"
            name="reportFormatTypes"
            label={
              Translations.reports.tabAgency.experienceDetailTab.label
                .reportFormat
            }
            placeholder="Please select a person to send the report to"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.reportFormatTypes}
            options={Object.keys(reportFormat).map(key => ({
              value: key,
              label: reportFormat[key]
            }))}
            size="md"
            required
            isClearable={false}
          />
        </FormGroup>

        {is109015Agent && (
          <FormGroup>
            <Input
              id="name"
              name="name"
              label={Translations.reports.tabClaims.agencyLossRun.label.name}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.name}
              required
              maxLength={22}
            />
          </FormGroup>
        )}

        <FormGroup className="pad-top">
          <Button
            type="submit"
            variant="primary"
            spinner={isLoadingReport}
            spinnerPos="right"
            disabled={invalidFields.length > 0}
          >
            {Translations.reports.tabClaims.agencyLossRun.label.getReport}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

ExperienceDetailForm.propTypes = {
  emailList: PropTypes.array,
  is109015Agent: PropTypes.bool,
  submit: PropTypes.func,
  email: PropTypes.string,
  hasAgencyProductionReportsAccess: PropTypes.bool,
  isLoadingReport: PropTypes.bool.isRequired
};

export default ExperienceDetailForm;
