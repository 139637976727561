import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";
import {
  createAccountPayload,
  createBondPayload,
  createObligeePayload,
  createOwnersPayload,
  submissionTransform
} from "../summary/submissionTransform";
import { getIsNewAccountOrObligee } from "../shared/util";

/*--------SUBMISSION SECTION---------- */
const updateSubmissionServiceError = data => ({
  type: types.UPDATE_SUBMISSION_ERROR,
  payload: data
});

export const updateQuickSubmissionError = data => dispatch => {
  return dispatch(updateSubmissionServiceError(data));
};

export const postSuretyQuickQuote = suretyQuote => async dispatch => {
  // Transform the data for submission.
  const payload = submissionTransform(suretyQuote);
  let hasSubmitted = false;
  let finalResponse;

  function SubmissionError(error, step) {
    this.name = "SubmissionError";
    this.error = error.toString();
    this.step = step;
  }

  const retryAPI = async (api, step, delay = 2400) => {
    const wait = () => new Promise(resolve => setTimeout(resolve, delay));

    try {
      return await wait().then(api);
    } catch (error) {
      console.log({ error });
      try {
        return await wait().then(api);
      } catch (err) {
        console.log({ err });
        throw new SubmissionError(err, step);
      }
    }
  };

  try {
    setTimeout(() => {
      if (!hasSubmitted) dispatch({ type: types.UPDATE_SUBMISSION_MSG });
    }, 10000);

    // Account.
    if (
      getIsNewAccountOrObligee(payload.account_number) ||
      payload.isAccountUpdated
    ) {
      await dispatch({ type: types.POST_QUICK_ACCOUNT_STARTED });
      const accountPayload = createAccountPayload(payload);
      const accountResponse = await retryAPI(
        () => quickQuotingServices.postQuickAccount(accountPayload),
        "account"
      );

      payload.account_number = accountResponse.data.account_number;
    }

    // Eligibility.
    await dispatch({ type: types.POST_QUICK_ELIGIBILITY_STARTED });
    await retryAPI(
      () =>
        quickQuotingServices.postQuickEligibility(payload.account_number, {
          ...payload.eligibility,
          agency_code: payload.agency_code
        }),
      "eligibility"
    );

    // Owner(s).
    let ownersResponse;
    try {
      await dispatch({ type: types.POST_QUICK_OWNERS_STARTED });
      ownersResponse = await quickQuotingServices.postQuickOwners(
        payload.account_number,
        createOwnersPayload(payload)
      );

      // If partial submit, throw error and handle resubmit in catch block.
      if (ownersResponse?.data?.code === 206) {
        const errMsg = "Quick Submit: Partial Owners Error";
        logger.error({
          description: errMsg,
          fatal: false
        });
        throw new Error(errMsg);
      }
    } catch (_e) {
      console.log({ _e });
      // If partial success/failure, retry with failed owners.
      if (ownersResponse?.data && ownersResponse?.data?.code === 206) {
        const retryOwners = [];
        payload.owners.forEach(o => {
          ownersResponse.data.owners_errors.forEach(errOwner => {
            // Have to use ssn, api doesn't return id or ref.
            if (o.ssn === errOwner.ssn) retryOwners.push(o);
          });
        });

        const erroredOwnersSuretyQuote = {
          ...payload,
          owners: retryOwners
        };

        try {
          ownersResponse = await quickQuotingServices.postQuickOwners(
            payload.account_number,
            createOwnersPayload(erroredOwnersSuretyQuote)
          );

          if (ownersResponse?.data?.code === 206) {
            throw new SubmissionError(
              `Failure to submit owners: ${ownersResponse.data.owners_errors
                .map(o => `${o.first_name} ${o.last_name}`)
                .join(", ")}`,
              "owner"
            );
          }
        } catch (ownerError) {
          console.low({ ownerError });
          throw new SubmissionError(ownerError, "owner");
        }
      } else {
        // Full owners list retry.
        try {
          ownersResponse = await quickQuotingServices.postQuickOwners(
            payload.account_number,
            createOwnersPayload(payload)
          );
        } catch (e) {
          console.log({ e });
          throw new SubmissionError(e, "owner");
        }
      }
    }

    // Bond.
    if (payload.hasBond) {
      // Obligee.
      if (
        getIsNewAccountOrObligee(
          payload?.bond_information?.obligee?.account_number
        )
      ) {
        await dispatch({ type: types.POST_QUICK_OBLIGEE_STARTED });
        const obligeePayload = createObligeePayload(payload);
        const obligeeResponse = await retryAPI(
          () => quickQuotingServices.postQuickObligee(obligeePayload),
          "obligee"
        );

        payload.bond_information.obligee.account_number =
          obligeeResponse.data.obligee_number;
      }

      // Bond.
      await dispatch({ type: types.POST_QUICK_BOND_STARTED });
      const bondPayload = createBondPayload(payload);
      const bondResponse = await retryAPI(
        () =>
          quickQuotingServices.postQuickBond(
            payload.account_number,
            bondPayload
          ),
        "bond"
      );

      payload.bond_information.bond_number = bondResponse.data.bond_number;
    }

    // Credit Authorization.
    if (
      (payload.owners || []).some(
        _ => _.order_credit === true || _.is_new === true
      )
    ) {
      await dispatch({ type: types.POST_QUICK_CREDIT_AUTHORIZATION_STARTED });
      await retryAPI(
        () =>
          quickQuotingServices.postQuickCreditAuthorization(
            payload.account_number,
            {
              agency_code: payload.agency_code,
              producer_number: payload.sub_producer,
              app_type: payload.app_type
            }
          ),
        "creditAuthorization"
      );
    }

    // Legacy Submission, Final Payload.
    await dispatch({ type: types.POST_QUICK_FINAL_STARTED });
    const finalSubResponse = await retryAPI(
      () => quickQuotingServices.postQuickQuote(payload),
      "submit"
    );

    if (finalSubResponse) {
      hasSubmitted = true;
      finalResponse = finalSubResponse.data;
      dispatch({
        type: types.POST_QUICK_QUOTE_SUCCEEDED,
        payload: {
          submissionResponse: finalSubResponse.data,
          prevSubmissionData: payload
        }
      });
    }
  } catch (error) {
    console.log({ error });
    // Send error email to Surety team.
    if (error?.step) {
      try {
        await quickQuotingServices.postSubmissionErrorEmail({
          step: error.step,
          submission_body: payload
        });
      } catch (err) {
        console.log({ err });
        logger.error({
          description: `Quick Submission Error Email: ${err.toString()}`,
          fatal: true
        });
      }
    }

    // Update the UI.
    await dispatch({
      type: types.POST_QUICK_QUOTE_FAILED,
      payload: error
    });
    logger.error({
      description: `Quick Submission Error (${
        error?.step ?? ""
      }): ${error.toString()}`,
      fatal: true
    });
  }

  return [hasSubmitted, finalResponse];
};
