import * as types from "../constants/actionTypes";
import * as moratoriumService from "../services/moratoriumService";
import { logger } from "../loggers";

const fetchMoratoriumListingsStarted = () => ({
  type: types.FETCH_MORATORIUM_LISTINGS_STARTED
});

const fetchMoratoriumListingsSucceeded = data => ({
  type: types.FETCH_MORATORIUM_LISTINGS_SUCCEEDED,
  payload: data
});

const fetchMoratoriumListingsFailed = error => ({
  type: types.FETCH_MORATORIUM_LISTINGS_FAILED,
  payload: error,
  error: true
});

const fetchMoratoriumDetailsStarted = () => ({
  type: types.FETCH_MORATORIUM_DETAILS_STARTED
});

const fetchMoratoriumDetailsSucceeded = data => ({
  type: types.FETCH_MORATORIUM_DETAILS_SUCCEEDED,
  payload: data
});

const fetchMoratoriumDetailsFailed = error => ({
  type: types.FETCH_MORATORIUM_DETAILS_FAILED,
  payload: error,
  error: true
});

export const fetchMoratoriumListings = () => {
  return dispatch => {
    dispatch(fetchMoratoriumListingsStarted());

    return moratoriumService.fetchMoratoriumListings().then(
      res => dispatch(fetchMoratoriumListingsSucceeded(res.data)),
      error => {
        dispatch(fetchMoratoriumListingsFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};

export const fetchMoratoriumDetails = () => {
  return dispatch => {
    dispatch(fetchMoratoriumDetailsStarted());
    return moratoriumService.fetchMoratoriumDetails().then(
      res => dispatch(fetchMoratoriumDetailsSucceeded(res.data)),
      error => {
        dispatch(fetchMoratoriumDetailsFailed(error.response));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
  };
};
