import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const yourUfgTeamReducer = (state = initialState.yourUfgTeam, action) => {
  switch (action.type) {
    case types.SET_YOUR_UFG_TEAM_PREVIOUS_DEPARTMENT:
      return {
        ...state,
        previousDepartment: action.previousDepartment
      };
    case types.FETCH_UFG_DEPARTMENT_DATA_STARTED:
      return {
        ...state,
        departmentData: [],
        departmentLoading: true,
        departmentError: null,
        contactDetails: null
      };
    case types.FETCH_UFG_DEPARTMENT_DATA_SUCCEEDED:
      return {
        ...state,
        departmentData: action.payload.map(z => {
          return {
            ...z,
            companybranch: action.previousCompanyBranch
          };
        }),
        departmentLoading: false,
        departmentError: null,
        previousCompanyBranch: action.previousCompanyBranch,
        previousAgencyCode: action.previousAgencyCode
      };
    case types.FETCH_UFG_DEPARTMENT_DATA_FAILED:
      return {
        ...state,
        departmentLoading: false,
        departmentError: action.payload,
        previousCompanyBranch: action.previousCompanyBranch,
        previousAgencyCode: action.previousAgencyCode
      };
    case types.FETCH_CONTACT_DETAILS_STARTED:
      return {
        ...state,
        contactDetails: null,
        contactLoading: true,
        contactError: null
      };
    case types.FETCH_CONTACT_DETAILS_SUCCEEDED:
      return {
        ...state,
        contactDetails: action.payload,
        contactLoading: false
      };
    case types.FETCH_CONTACT_DETAILS_FAILED:
      return {
        ...state,
        contactLoading: false,
        contactError: action.payload
      };

    default:
      return state;
  }
};

export default yourUfgTeamReducer;
