import React, { useState, useCallback, useEffect, useContext } from "react";
import LocationFormStep1 from "./LocationFormStep1.js";
import LocationFormCoveragesStep2 from "./LocationCoveragesStep2";
import OqModal from "../../shared/OqModal.js";
import { getLobCoverages } from "./locationCoverages.js";
import cn from "classnames";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const LocationModalWizard = ({
  show,
  onHide,
  itemData,
  locations,
  coverageExclusions
}) => {
  const { quoteData } = useContext(OnlineQuotingContext);

  const startTitle = " Location Details";
  const [currentStep, setStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState(startTitle);
  const [lobCoverages, setLobCoverages] = useState();
  const [totalSteps, setTotalSteps] = useState(2);
  const [scheduleItemOpenInCoverable, setScheduleItemOpenInCoverable] =
    useState();
  const [thisItem, setThisItem] = useState(itemData);

  // update the coverages every time the quotedata is updated
  // this is important to keep because it...
  // - updates the coverages' data when coverages are added (to show terms, etc)
  useEffect(() => {
    //OOQ-5793 make sure you don't try to update locations coverages when thisItem is null.
    if (thisItem && quoteData) {
      const newCoverages = getLobCoverages(quoteData, thisItem);
      setLobCoverages(newCoverages);
    }
  }, [thisItem, quoteData]);

  // update the title each time the step updates
  useEffect(() => {
    if (currentStep === 1) setCurrentTitle(startTitle);
    else if (lobCoverages?.length) {
      const lobLabel = lobCoverages[currentStep - 2]?.label || "";
      setCurrentTitle(`${lobLabel} Location Coverages`);
    }
  }, [currentStep, lobCoverages]);

  const onNextStep = useCallback(
    ({ item, newQuoteData }) => {
      if (item && newQuoteData) {
        setThisItem(item);

        const newLobCoverages = getLobCoverages(newQuoteData, item);
        setLobCoverages(newLobCoverages);

        const newTotalSteps = newLobCoverages.length + 1;
        setTotalSteps(totalSteps);

        const nextStep = currentStep + 1;
        if (nextStep <= newTotalSteps) {
          setStep(nextStep);
        } else if (nextStep > newTotalSteps) {
          onHide();
        }
      }
    },
    [currentStep, onHide, totalSteps]
  );

  const onPrevStep = useCallback(() => {
    if (currentStep - 1 > 0) {
      const st = currentStep - 1;
      setStep(st);
    }
  }, [currentStep]);

  const renderStepForm = useCallback(
    currentStep => {
      if (currentStep === 1) {
        return (
          <LocationFormStep1
            key={currentStep}
            itemData={thisItem}
            onNextStep={onNextStep}
            locations={locations}
            onCancel={onHide}
            onPrevStep={onPrevStep}
            locationfixedId={thisItem?.fixedID}
          />
        );
      }

      if (thisItem?.fixedID && !!lobCoverages.length) {
        return (
          <LocationFormCoveragesStep2
            key={`${currentStep}.${thisItem?.fixedID}`}
            itemData={thisItem}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            locationfixedId={thisItem?.fixedID}
            coverageExclusions={coverageExclusions}
            locationCoverages={lobCoverages[currentStep - 2]?.locationCoverages}
            setScheduleItemOpenInCoverable={setScheduleItemOpenInCoverable}
            protectionClass={thisItem?.fireProtection}
            bp7LocationFixedID={thisItem?.bp7LocationFixedID}
            onCancel={onHide}
          />
        );
      }
      if (thisItem && currentStep > totalSteps) onHide(); //if you are here close the dialog.
    },
    [
      thisItem,
      lobCoverages,
      totalSteps,
      onHide,
      onNextStep,
      locations,
      onPrevStep,
      coverageExclusions
    ]
  );

  return (
    <OqModal
      className={cn("oq__modal__coverable oq__modal__coverable__location", {
        scheduleItemOpenInCoverable
      })}
      title={
        currentStep > 1
          ? `Step ${currentStep} of ${totalSteps}: ${currentTitle}`
          : currentTitle
      }
      show={show}
      onHide={onHide}
      body={<div>{renderStepForm(currentStep)}</div>}
      closeIcon={false}
      altCloseMethod={false}
      overlayHidden={scheduleItemOpenInCoverable}
    />
  );
};

export default LocationModalWizard;
