import { toTitleCase } from "../../components/Factory";
import { arrayToOptions } from "../shared/util";
import OQstrings from "../shared/strings";

// ------------
//   SELECT
// ------------
export const selectAccount = ({
  account,
  form,
  setSelectedState,
  addresses,
  setAddresses,
  agencyStates
}) => {
  // if the type is 'api' then we selected from the api data
  // otherwise, it's either a new account
  if (account?.type === "api") {
    // find account in the option data from the search that just occurred
    const a = account?.accountHolder;

    // start setting values to update the form
    let newValues = {
      account_number: account?.accountNumber || null,
      account_publicID: a?.publicID || "",
      account_org_type_UFG: account?.accountOrgType_UFG || "",
      has_DBA: !!a?.dbaNames?.length,
      DBA_name: (!!a?.dbaNames?.length && a?.dbaNames[0].slice(0, 60)) || "",
      business_start_year: account?.businessStartYear || "",
      is_inforce: !!account?.inforce,
      garaged_autos: !!account?.garageStates?.length,
      garaged_states: account?.garageStates || []
    };
    // about: "is_inforce"
    // it displays an error if it is "in-force - prevents the user from continuing
    // and prevents the supporting data from getting updated

    let addressState = {};

    // if accountHolder, update the AH data
    if (!!a) {
      // update the selected state so that the products get updates
      setSelectedState(a?.primaryAddress?.state);

      newValues = {
        ...newValues,
        physical_address1:
          toTitleCase(a?.primaryAddress?.addressLine1.slice(0, 60)) || "",
        physical_zip: a?.primaryAddress?.postalCode || "",
        physical_city: toTitleCase(a?.primaryAddress?.city) || "",
        physical_county: a?.primaryAddress?.county || "",
        physical_county_code: a?.primaryAddress?.countyCode || "",
        physical_state: a?.primaryAddress?.state || "",
        physical_is_autofilled: !!a?.primaryAddress?.addressLine1,
        physical_publicID: a?.primaryAddress?.publicID || "",
        policyholder_email: a?.emailAddress1 || ""
      };

      // update the phsycial address options to include the city and state values
      addressState = {
        ...addresses,
        physical: {
          ...addresses.physical,
          options: {
            city: arrayToOptions([toTitleCase(a?.primaryAddress?.city)]),
            state: arrayToOptions([a?.primaryAddress?.state])
          },
          disabled: {
            ...addresses.physical.disabled,
            address1: !!account?.accountNumber,
            zip: !!account?.accountNumber
          }
        }
      };

      // if any additional addresses exist, update the form values and set the form options
      if (!!a?.additionalAddresses?.length) {
        a.additionalAddresses
          .filter(a => Object.keys(addresses).includes(a.addressType))
          .forEach(a => {
            const type = a.addressType;

            if (!!a?.addressLine1) {
              newValues = {
                ...newValues,
                [type + "_address1"]:
                  toTitleCase(a?.addressLine1.slice(0, 60)) || "",
                [type + "_zip"]: a?.postalCode || "",
                [type + "_city"]: toTitleCase(a?.city) || "",
                [type + "_county"]: a?.county || "",
                [type + "_county_code"]: a?.countyCode || "",
                [type + "_state"]: a?.state || "",
                [type + "_is_autofilled"]: !!a?.addressLine1,
                [type + "_publicID"]: a?.publicID || ""
              };

              addressState = {
                ...addressState,
                [type]: {
                  ...addressState[type],
                  display: true,
                  buttonDisplay: true,
                  options: {
                    city: arrayToOptions([toTitleCase(a?.city)]),
                    state: arrayToOptions([a?.state])
                  },
                  disabled: {
                    ...addressState[type].disabled,
                    address1: !!account?.accountNumber,
                    zip: !!account?.accountNumber
                  }
                }
              };
            }
          });
      }
    }

    // if it's a company, then update the account_name, otherwise it's an individual
    if (a?.subtype === "Company" || !a?.lastName) {
      newValues.account_name =
        a?.contactName.slice(0, 60) || a?.displayName.slice(0, 60) || "";
    } else {
      newValues.last_name = a?.lastName.slice(0, 30) || "";
      newValues.first_name = a?.firstName.slice(0, 30) || "";
    }

    // check to make sure that the state inputted is one of the agencies licensed states
    // display an error if not
    const zipCodeError =
      !!a?.primaryAddress?.state &&
      !agencyStates.includes(a?.primaryAddress?.state)
        ? [OQstrings.error.zip_code_not_in_agent_state]
        : [];

    form.updateForm({
      values: {
        ...newValues
      },
      errors: {
        account_number: [],
        account_name: [],
        physical_address1: [],
        physical_zip: [...zipCodeError],
        physical_city: [],
        physical_county: [],
        physical_county_code: [],
        physical_state: [],
        account_search: [],
        account_org_type_UFG: [],
        DBA_name: [],
        garaged_autos: [],
        garaged_states: []
      }
    });

    // update the addresses state all at once
    setAddresses(addressState);
  } else if (account?.type === "business" || account?.type === "individual") {
    // user selected "Add Account Option"
    form.updateForm({
      values: {
        account_number: "",
        account_name: account.type === "business" ? account.contactName : "",
        last_name: account.type === "individual" ? account.contactName : "",
        first_name: "",
        physical_address1: "",
        physical_zip: "",
        physical_city: "",
        physical_county: "",
        physical_county_code: "",
        physical_state: "",
        physical_is_autofilled: false,
        account_org_type_UFG: "",
        DBA_name: "",
        is_inforce: false,
        business_start_year: "",
        garaged_autos: false,
        garaged_states: []
      },
      errors: {
        account_number: [],
        account_name: [],
        last_name: [],
        first_name: [],
        physical_address1: [],
        physical_zip: [],
        physical_city: [],
        physical_county: [],
        physical_county_code: [],
        physical_state: [],
        account_search: [],
        account_org_type_UFG: [],
        DBA_name: [],
        business_start_year: [],
        garaged_autos: [],
        garaged_states: []
      }
    });

    setSelectedState(null);
  } else {
    return null;
  }
};
