import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { toast } from "react-toastify";
import { Button, FlexRow } from "@ufginsurance/ui-kit";
import { usePolicyChangesContext } from "../context";
import * as routes from "../../../constants/routes";
import QuoteUnderwriterReviewErrorModal from "./QuoteUnderwriterReviewErrorModal";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const Step1DriverFooter = ({ history }) => {
  const { driverChangesPhase2 } = useFlags();
  const {
    quotePolicyChangeDriver,
    policyData,
    QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE
  } = usePolicyChangesContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showUnderwriterReviewErrorModal, setShowUnderwriterReviewErrorModal] =
    useState(false);

  const successMessage = "Policy change has been submitted to underwriting.";
  const errorMessage = <HelpDeskMessage display="anErrorOccurred" />;

  const submitBtnText = driverChangesPhase2
    ? "Continue to Change Summary"
    : "Submit to Underwriting";

  // TODO: remove this when driverChangesPhase2 goes live
  const onSubmitPhaseOne = () => {
    setIsLoading(true);
    quotePolicyChangeDriver(
      policyData?.policyInfo?.policy_number,
      policyData?.policyInfo?.job_id,
      policyData?.policyInfo?.effective_date
    )
      .then(() => {
        toast(successMessage, { className: "success" });
        setIsLoading(false);
        history.push(
          `${routes.ACCOUNT_HOME}/${policyData?.policyInfo?.policy_number}/change-requests`
        );
      })
      .catch(quoteError => {
        if (
          quoteError?.response?.status === QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE
        ) {
          setShowUnderwriterReviewErrorModal(true);
        } else {
          toast(`${errorMessage}`, {
            className: "error",
            autoClose: false
          });
        }
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    const { policy_number, job_id } = policyData?.policyInfo;
    history.push(
      `${routes.POLICY_CHANGES}/${policy_number}/jobId/${job_id}/step/2`
    );
  };

  return (
    <>
      <FlexRow align="right">
        {policyData?.policyInfo && (
          <Button
            id="driverChangeSubmit"
            onClick={driverChangesPhase2 ? onSubmit : onSubmitPhaseOne}
            variant="primary"
            spinner={isLoading}
            disabled={isLoading}
          >
            {submitBtnText}
          </Button>
        )}
      </FlexRow>
      <QuoteUnderwriterReviewErrorModal
        showUnderwriterReviewErrorModal={showUnderwriterReviewErrorModal}
        setShowUnderwriterReviewErrorModal={setShowUnderwriterReviewErrorModal}
        policyNumber={policyData?.policyInfo?.policy_number}
      />
    </>
  );
};

Step1DriverFooter.propTypes = {
  history: PropTypes.object
};

export default withRouter(Step1DriverFooter);
