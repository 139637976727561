import qs from "query-string";
import API from "../api";
//import { DateFormat } from "../components/Factory";

// -------------------- ADDRESS --------------------------------

export const searchLocaleByZipCodeV2 = zipCode => {
  return API.base("quoting").get(`/eapi/address/v2/zip/${zipCode}?`);
};

export const getEapiStatus = () => {
  return API.onlineQuoting(true).get(`/status`);
};

// -------------------- METADATA --------------------------------

export const metadataGenerate = () => {
  return API.onlineQuoting(true).post(`/metadata`, {});
};

export const getCustomerInfoMetaData = () => {
  return API.onlineQuoting(true).get(`/metadata/contactDetails-page-metadata`);
};

export const getClassCodesMetaData = ({
  businessType,
  products,
  effectiveDate
}) => {
  let params = businessType ? `businessType=${businessType}` : ``;
  products.map(p => {
    params = `${params}&${p}=true`;
    return p; //eslink staff.
  });
  params = effectiveDate
    ? `${params}&${qs.stringify({ effectiveDate })}`
    : params;
  return API.onlineQuoting(true).get(`/metadata/class-codes?${params}`);
};

export const getPolicyLineMetaData = quoteId => {
  return API.onlineQuoting(true).get(
    `quote/${quoteId}/metadata/policyInfo-page-metadata`
  );
};

export const getContactMetaData = () => {
  return API.onlineQuoting(true).get(`/metadata/contactDetails-page-metadata`);
};

export const getQuoteSummaryMetaData = () => {
  return API.onlineQuoting(true).get(`/metadata/quoteSummary-page-metadata`);
};

export const getBuildingCoveragesMetadata = () => {
  return API.onlineQuoting(true).get(
    `/metadata/building-coverageInfo-page-metadata`
  );
};

export const getVehicleFormMetadata = () => {
  return API.onlineQuoting(true).get(`/metadata/vehicleDetails-page-metadata`);
};

export const getVehicleClassCodes = vehicleType => {
  return API.onlineQuoting(true).get(
    `/metadata/vehicle-class-codes?filter=${vehicleType}`
  );
};
export const getEmployeeClassCodes = (state, bopCode) => {
  const queryString = qs.stringify({ filter: state, bop_code: bopCode });

  return API.onlineQuoting(true).get(
    `/metadata/employee-class-codes?${queryString}`
  );
};

export const getQuoteSummaryMetadata = quoteId => {
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/metadata/quoteSummary-page-metadata`
  );
};

export const getBuildingFormMetadata = quoteId => {
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/metadata/buildings-page-metadata`
  );
};

export const getLocationFormMetadata = quoteId => {
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/metadata/locations-page-metadata`
  );
};

export const getRiskInfoPageMetadata = quoteId => {
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/metadata/riskInfo-page-metadata`
  );
};

export const getDriverFormMetadata = () => {
  return API.onlineQuoting(true).get(`/metadata/driverDetails-page-metadata`);
};

// TODO, replace this with a harness available after the AWS PROD is avail
export const getCoverageControlData = payload => {
  return API.url(window.env.REACT_APP_AWS_PROQUOTE_COVERAGE_CONTROL).post(
    "/coverages",
    payload
  );
};

export const sendNote = ({ payload, quoteId }) => {
  return API.url(window.env.REACT_APP_AWS_PROQUOTE_SUBMISSIONS).post(
    `/quote/${quoteId}/notes`,
    payload
  );
};

// -------------------- ELEGIBILITY --------------------------------

export const getEligibilityQuestions = quoteId => {
  return API.onlineQuoting(true).get(`/quote/${quoteId}/questions?status=all`);
};

export const getUnAnsweredEligibilityQuestions = quoteId => {
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/questions?status=unanswered`
  );
};

export const updateEligibilityQuestions = (quoteId, payload) => {
  return API.onlineQuoting(false).put(`/quote/${quoteId}/questions`, payload);
};

// -------------------- MISC GETS --------------------------------

export const getAgencyData = agencyCode => {
  return API.base("quoting").get(`/eapi/agency/v2/agency/${agencyCode}`);
};

export const getBusinessTypes = () => {
  return API.onlineQuoting(true).get(
    `/metadata/classifyCustomer-page-metadata`
  );
};

export const getProducers = () => {
  return API.onlineQuoting(false).get(`/organizations`);
};

export const getDocument = (quoteId, docType) => {
  const queryString = qs.stringify({ docType });
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/document?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const getAccounts = (accountOrgType, businessName) => {
  const queryString = qs.stringify({ accountOrgType, businessName });
  return API.onlineQuoting(false).get(`/accounts?${queryString}`);
};

export const getProductMatrixForState = ({ stateAbr, useAWS }) => {
  if (useAWS)
    return API.url(window.env.REACT_APP_AWS_PRODUCT_MATRIX).get(
      `/state/${stateAbr}`
    );

  return API.onlineQuoting(true).get(`/metadata/availability/${stateAbr}`);
};

// -------------------- BOP PREFILL --------------------------------

// search for businesses
export const getBusinessData = (address, city, state, zipCode) => {
  const queryString = qs.stringify({
    address_line: address,
    city,
    state,
    zip_code: zipCode
  });
  return API.onlineQuoting(true).get(`/metadata/bopLocations?${queryString}`);
};

// update data from bop prefill
export const updateBusinessData = (referenceId, payload) => {
  const encodedRefId = encodeURIComponent(referenceId);
  return API.onlineQuoting(false).post(
    `/quote/${encodedRefId}/bopLocation`,
    payload
  );
};

// -------------------- MISC ACTIONS --------------------------------

export const validateCustomer = payload => {
  return API.onlineQuoting(false).post(`/quote/validate-customer`, payload);
};

export const sendForReview = (quoteId, payload) => {
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/sendForReview`,
    payload
  );
};

// used to update step and load fresh quote when navigating
export const getQuoteNavigation = (quoteId, page) => {
  const queryString = qs.stringify({
    page
  });
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/navigate?${queryString}`
  );
};

// used for checking quote validation for specific areas of the quote
export const getFlowValidation = (quoteId, page) => {
  const queryString = !!page
    ? qs.stringify({
        page
      })
    : "";
  return API.onlineQuoting(true).get(
    `/quote/${quoteId}/validate?${queryString}`
  );
};

export const saveHiredNonOwnedAuto = (quoteId, payload) => {
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/customize?package=hiredNonOwnedAuto`,
    payload
  );
};

// -------------------- ACCOUNT SEARCH --------------------------------

// https://ufginsurance.atlassian.net/wiki/spaces/OOQR2/pages/405996635/Customer+Information+Form
// options allow the inclusion of axios.CancelToken to stop the request
export const getAccountSearch = (params, options = {}) => {
  const queryString = params
    ? Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&")
    : null;
  return API.onlineQuoting(true).get(`/accounts?${queryString}`, options);
};

// find account by Id
// options allow the inclusion of axios.CancelToken to stop the request
export const getAccountById = (accountNumber, options = {}) => {
  return API.onlineQuoting(true).get(`/accounts/${accountNumber}`, options);
};

// -------------------- GET QUOTE --------------------------------

export const getQuoteByReferenceId = referenceId => {
  return API.onlineQuoting(true).get(`/quote/data?reference_id=${referenceId}`);
};

export const getSupportingDataByQuoteId = quoteId => {
  return API.onlineQuoting(true).get(`/quote/data?quote_number=${quoteId}`);
};

export const getQuoteData = jobNumber => {
  return API.onlineQuoting(true).get(`/quote/${jobNumber}`);
};

// -------------------- GET QUOTE IVANS/Vendor Quotes ---------------
export const getVendorQuote = quoteId => {
  return API.url(window.env.REACT_APP_AWS_PROQUOTE_SUBMISSIONS).get(
    `/load-quote-from-pc/${quoteId}`
  );
};
// -------------------- UPDATE QUOTE --------------------------------

export const createQuote = payload => {
  return API.onlineQuoting(false).post(`/quote`, payload);
};

export const createQuoteExistingCustomer = ({ customer, core_system }) => {
  return API.onlineQuoting(false).post(`/accounts/quotes`, {
    Customer: customer,
    core_system
  });
};

// saveQuote updates SupportingData
export const saveQuote = payload => {
  return API.onlineQuoting(false).post(`/quote/save`, payload);
};

export const updateQuote = (quoteId, payload) => {
  return API.onlineQuoting(false).put(`/quote/${quoteId}`, payload);
};

export const patchQuote = (quoteId, payload) => {
  return API.onlineQuoting(false).patch(`/quote/${quoteId}`, payload);
};

export const priorlosses = (quoteId, payload) => {
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/priorlosses`,
    payload
  );
};

export const updateBindingData = (quoteId, payload) => {
  return API.onlineQuoting(false).put(`/quote/${quoteId}/rate`, payload);
};

export const updateQuoteCoverages = (quoteId, payload, coverageDetails) => {
  const queryString = qs.stringify({ ...coverageDetails });
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/coverages?${queryString}`,
    payload
  );
};

export const updateQuoteCoverables = ({ coverableType, quoteId, payload }) => {
  const queryString = qs.stringify({ coverableType });

  if (
    payload?.fixedId ||
    payload?.fixedID ||
    // vehicle updates still use an array of one item, but requires a PUT... silly Mule
    (coverableType === "vehicle" &&
      (payload?.vehicles || []).length === 1 &&
      payload?.vehicles[0]?.fixedId)
  )
    return API.onlineQuoting(false).put(
      `/quote/${quoteId}/coverables?${queryString}`,
      payload
    );

  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/coverables?${queryString}`,
    payload
  );
};

export const deleteQuoteCoverable = ({
  coverableType,
  quoteId,
  coverableId,
  ca7TruckIds,
  ca7PrivatePassengerIds,
  buildingIds,
  locationId,
  employeeIds
}) => {
  const queryString = qs.stringify({
    coverableType,
    coverableId,
    ca7TruckIds,
    ca7PrivatePassengerIds,
    buildingIds,
    locationId,
    employeeIds
  });
  return API.onlineQuoting(false).delete(
    `/quote/${quoteId}/coverables?${queryString}`
  );
};

export const addLineOfBusiness = (quoteId, lob, payload) => {
  const queryString = qs.stringify({ lob });
  return API.onlineQuoting(false).put(
    `/quote/${quoteId}/lineOfBusiness?${queryString}`,
    payload
  );
};

export const removeLineOfBusiness = (quoteId, lob) => {
  const queryString = qs.stringify({ lob });
  return API.onlineQuoting(false).delete(
    `/quote/${quoteId}/lineOfBusiness?${queryString}`
  );
};

export const updateLineDetails = (quoteId, payload, line) => {
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/line/${line}`,
    payload
  );
};

export const rateQuote = quoteId => {
  return API.onlineQuoting(true).get(`/quote/${quoteId}/rate`);
};

export const issueQuote = (quoteId, payload) => {
  return API.onlineQuoting(false).post(`/quote/${quoteId}/issue`, payload);
};

export const exitQuote = payload => {
  return API.onlineQuoting(false).post(`quote/exit`, payload);
};

export const getQuotes = accountNumber => {
  return API.onlineQuoting(true).get(`/accounts/${accountNumber}/quotes`);
};

export const deleteQuote = (quoteId, params) => {
  const queryString = qs.stringify(params);
  return API.onlineQuoting(false).delete(`/quote/${quoteId}?${queryString}`);
};

// -------------------- SCHEDULE ITEMS --------------------------------

export const createScheduleItem = ({
  quoteId,
  clausePattern,
  entityName,
  coverableName,
  coverableFixedId
}) => {
  const queryString = qs.stringify({
    clausePattern,
    entityName,
    coverableName,
    coverableFixedId
  });
  return API.onlineQuoting(false).post(
    `/quote/${quoteId}/scheduledItems?${queryString}`,
    {}
  );
};

export const updateScheduledItem = ({
  quoteId,
  scheduleItemId,
  clausePattern,
  entityName,
  coverableName,
  fieldsMetadata,
  coverages,
  coverableFixedId
}) => {
  const queryString = qs.stringify({
    clausePattern,
    entityName,
    coverableName,
    coverableFixedId
  });
  return API.onlineQuoting(false).put(
    `/quote/${quoteId}/scheduledItems/${scheduleItemId}?${queryString}`,
    {
      fieldsMetadata,
      coverages
    }
  );
};

export const deleteScheduledItem = ({
  quoteId,
  clausePattern,
  entityName,
  coverableName,
  scheduleItemId,
  coverableFixedId
}) => {
  const queryString = qs.stringify({
    clausePattern,
    entityName,
    coverableName,
    coverableFixedId
  });
  return API.onlineQuoting(false).delete(
    `/quote/${quoteId}/scheduledItems/${scheduleItemId}?${queryString}`
  );
};

export const getScheduleItemContacts = ({ accountId }) => {
  return API.onlineQuoting(false).get(
    `/accounts/${accountId}/contacts?filterAccountHolder=true`
  );
};

export const getContacts = ({ accountId }) => {
  return API.onlineQuoting(false).get(`/accounts/${accountId}/contacts`);
};

export const getScheduleItemContact = ({ accountId, contactId }) => {
  return API.onlineQuoting(false).get(
    `/accounts/${accountId}/contacts/${contactId}`
  );
};

export const updateServiceCenterContact = ({ accountId, payload }) => {
  return API.onlineQuoting(false).post(
    `/accounts/${accountId}/serviceCenterContact`,
    payload
  );
};

export const addScheduleItemContact = ({ accountId, payload }) => {
  return API.onlineQuoting(false).post(
    `/accounts/${accountId}/contacts`,
    payload
  );
};

export const updateContact = ({ accountId, contactId, payload }) => {
  return API.onlineQuoting(false).put(
    `/accounts/${accountId}/contacts/${contactId}`,
    payload
  );
};

//------------------BILLING CONTACT--------------------------------
export const updateBillingContact = ({ accountId, contactId, payload }) => {
  return API.onlineQuoting(false).put(
    `/accounts/${accountId}/contacts/${contactId}`,
    payload
  );
};

export const getBillingContacts = ({ accountId }) => {
  return API.onlineQuoting(false).get(`/accounts/${accountId}/contacts`);
};

export const getBillingContact = ({ accountId, contactId }) => {
  return API.onlineQuoting(false).get(
    `/accounts/${accountId}/contacts/${contactId}`
  );
};

export const addBillingContact = ({ accountId, payload }) => {
  return API.onlineQuoting(false).post(
    `/accounts/${accountId}/contacts`,
    payload
  );
};

//------------------ PAYMENT --------------------------------

export const getPaymentEligiblity = ({ accountId }) => {
  return API.onlineQuoting(false).get(
    `/accounts/${accountId}/payment-eligibility`
  );
};

export const makePayment = ({ payload }) => {
  return API.base("quoting").post(
    `/eapi/ufgagent/v2/billing/payment-request`,
    payload
  );
};

// -------------------- VEHICLES END POINTS --------------------------------

export const getVehicleByVIN = ({ vin }) => {
  return API.base("quoting").get(`/eapi/vehicles-detail/v2/vehicles/${vin}?`);
};

export const getSendEmail = ({ payload }) => {
  return API.base("quoting").post(`/eapi/online-quoting/v2/emails`, payload);
};

/*
// example to use to test api slow response
export const getAccountSearch = params => {
  const queryString = params
    ? Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&")
    : null;

  const p = new Promise((resolve, reject) => {
    const call = API.onlineQuoting().get(`/accounts?${queryString}`);
    setTimeout(() => {
      resolve(call);
    }, 2000);
  });
  return p;
};
*/
