import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InForceListForm from "./InForceListForm";
import { postInForceList } from "../../../services/underwritingReportsService";
import { Translations } from "../../../../components/translations";
import { Modal } from "@ufginsurance/ui-kit";

const InForceList = props => {
  const {
    selectedAgencyCode,
    fetchUnderwritingEmailListDetails,
    fetchAdministrator,
    emailList,
    is109015Agent,
    hasAgencyProductionReportsAccess,
    administrator,
    hasServiceCenterAccess,
    isSpecialtyPortalView
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [emailTo, setEmailTo] = useState("");

  const handleSubmit = (values, formReset) => {
    postInForceList(values, selectedAgencyCode).then(
      () => {
        setShowModal(true);
        setSubmitFailed(false);
        setEmailTo(values.emailTo);
        formReset();
      },
      () => {
        setShowModal(false);
        setSubmitFailed(true);
        setEmailTo("");
      }
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setEmailTo("");
  };

  useEffect(() => {
    fetchUnderwritingEmailListDetails(selectedAgencyCode);
    fetchAdministrator(selectedAgencyCode);
  }, [
    fetchAdministrator,
    fetchUnderwritingEmailListDetails,
    selectedAgencyCode
  ]);

  return (
    <div>
      {selectedAgencyCode && selectedAgencyCode === "109015" ? (
        <div className="underwriting-error-message">
          {Translations.reports.internal_user}
        </div>
      ) : hasAgencyProductionReportsAccess ? (
        <div>
          <InForceListForm
            emailList={emailList}
            selectedAgencyCode={selectedAgencyCode}
            is109015Agent={is109015Agent}
            submit={handleSubmit}
            administratorName={administrator.name}
            serviceCenterOptionType={hasServiceCenterAccess}
            isSpecialtyPortalView={isSpecialtyPortalView}
          />
          <Modal
            title={Translations.reports.request_Submitted}
            body={
              <div className="in-force-List">
                {Translations.reports.emailTo}
                {emailTo}
              </div>
            }
            show={showModal}
            onHide={handleClose}
          />
          {submitFailed && (
            <span>
              {Translations.reports.tabUnderwriting.inForce.failureNote}
            </span>
          )}
        </div>
      ) : (
        <div className="underwriting-error-message">
          {Translations.reports.error_message}
        </div>
      )}
    </div>
  );
};

InForceList.propTypes = {
  selectedAgencyCode: PropTypes.string,
  emailList: PropTypes.array,
  fetchUnderwritingEmailListDetails: PropTypes.func,
  is109015Agent: PropTypes.bool,
  hasAgencyProductionReportsAccess: PropTypes.bool,
  administrator: PropTypes.object,
  fetchAdministrator: PropTypes.func,
  hasServiceCenterAccess: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool
};

export default InForceList;
