import {
  AccountNameMaxLength,
  appTypes,
  poboxPattern
} from "../constants/suretyDictionary";

const appendProjectInfo = bond_information => {
  if (bond_information.type_of_bond === "B") {
    return {
      bid_date: bond_information.bid_date || "",
      bid_estimate: parseFloat(bond_information.bid_estimate),
      bid_amount: parseFloat(bond_information.bid_bond_amount),
      bid_bond_percent: bond_information.bid_bond_percent || ""
    };
  }

  if (bond_information.type_of_bond === "P") {
    return {
      contract_amount: parseFloat(bond_information.contract_amount),
      contract_date: bond_information.contract_date || "",
      ...(bond_information?.second_lowest_bidder && {
        second_lowest_bidder: parseFloat(bond_information.second_lowest_bidder)
      })
    };
  }
};

const appendBondInfo = bond_information => {
  if (bond_information) {
    // Allow Obligee PO in address line. Set following fields to pass WSUAR.
    if (
      poboxPattern.test(bond_information?.obligee_unit_type) ||
      poboxPattern.test(bond_information.obligee_address_line)
    ) {
      bond_information.obligee_unit_type = null;
      bond_information.obligee_unit_number = "";
    }

    return {
      type_of_bond: bond_information.type_of_bond,
      effective_date: bond_information.effective_date,
      bond_number: "",
      bond_form: bond_information.bond_form,
      form_number: bond_information.form_number,
      edition_date: bond_information.edition_date,
      bond_city: bond_information.city,
      bond_state: bond_information.state,
      bond_zip: bond_information.zip.replace("-", ""),
      obligee: {
        account_number: bond_information.obligee_account_number,
        name: bond_information.obligee_name.substring(0, AccountNameMaxLength),
        type: bond_information.obligee_type,
        address_line: bond_information.obligee_address_line,
        building_number: bond_information.obligee_building_number,
        street_name: bond_information?.obligee_street_name?.substring(0, 17),
        unit_type: bond_information.obligee_unit_type || null,
        unit_number: bond_information.obligee_unit_number,
        pobox: bond_information.obligee_pobox,
        city: bond_information.obligee_city,
        state: bond_information.obligee_state,
        zip: bond_information.obligee_zip.replace("-", ""),
        confidence: bond_information.obligee_confidence,
        verify_date: bond_information.obligee_verify_date
      },
      project_description: bond_information.project_description,
      project_completion_date: bond_information.project_completion_date,
      maintenance_period: bond_information.maintenance_period,
      liquidated_damages: parseFloat(bond_information.liquidated_damages),
      ...appendProjectInfo(bond_information)
    };
  }

  //no bond
  return {
    obligee: {
      account_number: "",
      name: " ",
      type: "",
      address_line: "",
      building_number: "",
      street_name: "",
      unit_type: null,
      unit_number: "",
      pobox: "",
      city: "",
      state: "",
      zip: "",
      confidence: 0
    }
  };
};

export const submissionTransform = suretyQuote => {
  const { bond_information } = suretyQuote;

  const { eligibility_questions, ...eligibility } = suretyQuote.eligibility;

  const submissionData = {
    ...suretyQuote,
    agency_code: suretyQuote.agency_code,
    sub_producer: suretyQuote.sub_producer,
    account_name: suretyQuote.account_name.substring(0, AccountNameMaxLength),
    street_name: suretyQuote.street_name?.substring(0, 17),
    app_type: appTypes.suretyBond,
    pobox: suretyQuote.pobox || "",
    zip: suretyQuote.zip.replace("-", ""),
    owners: suretyQuote.ownerData.map(owner => ({
      ...owner,
      street_name: owner.street_name?.substring(0, 17),
      pobox: owner.pobox || "",
      zip: owner.zip.replace("-", ""),
      business_type: "Individual"
    })),
    bond_information: appendBondInfo(bond_information),
    eligibility: {
      ...eligibility
    }
  };

  delete submissionData.ownerData;

  // keep logging for testing purposes
  console.log({ submissionData });
  return submissionData;
};

export const createAccountPayload = suretyQuote => ({
  account_name: suretyQuote.account_name,
  account_number: suretyQuote.account_number,
  agency_code: suretyQuote.agency_code,
  app_type: appTypes.suretyBond,
  building_number: suretyQuote.building_number,
  business_type: suretyQuote.business_type,
  city: suretyQuote.city,
  company_number: suretyQuote.company_number,
  fein: suretyQuote.fein,
  legal_business_name: suretyQuote.legal_business_name.trim(),
  pobox: suretyQuote.pobox,
  state: suretyQuote.state,
  street_name: suretyQuote.street_name,
  sub_producer: suretyQuote.sub_producer,
  unit_number: suretyQuote.unit_number,
  unit_type: suretyQuote.unit_type,
  verify_date: suretyQuote.verify_date,
  zip: suretyQuote.zip
});

export const createObligeePayload = suretyQuote => {
  const { obligee } = suretyQuote.bond_information;

  return {
    account_name: obligee.name,
    address_line: obligee.address_line,
    app_type: appTypes.suretyBond,
    building_number: obligee.building_number,
    city: obligee.city,
    company_number: suretyQuote.company_number,
    confidence: parseInt(obligee.confidence),
    hasBond: suretyQuote.hasBond,
    pobox: obligee.pobox,
    state: obligee.state,
    street_name: obligee.street_name,
    type: obligee.type,
    unit_number: obligee.unit_number,
    unit_type: obligee.unit_type,
    verify_date: obligee.verify_date,
    zip: obligee.zip
  };
};

export const createBondPayload = suretyQuote => {
  const { bond_information } = suretyQuote;

  const payload = {
    ...bond_information,
    agency_code: suretyQuote.agency_code,
    app_type: appTypes.suretyBond,
    bid_amount: bond_information?.bid_amount || 0,
    company_number: suretyQuote.company_number,
    construction_other: suretyQuote.construction_other,
    construction_specialty: suretyQuote.construction_specialty,
    obligee_number: bond_information.obligee.account_number,
    process_indicator: bond_information.type_of_bond === "P" ? "I" : "Q",
    sub_producer: suretyQuote.sub_producer
  };

  delete payload.obligee;

  return payload;
};

export const createOwnersPayload = suretyQuote => {
  const { owners } = suretyQuote;

  return {
    agency_code: suretyQuote.agency_code,
    app_type: appTypes.suretyBond,
    company_number: suretyQuote.company_number,
    account_name: suretyQuote.account_name,
    verify_date: suretyQuote.verify_date,
    owners
  };
};
