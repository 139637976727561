import * as types from "../constants/actionTypes";
import * as homeClaimsService from "../services/homeClaimsService";
import { logger } from "../loggers";
import { Translations } from "./../components/translations";

const REMOVE_CLAIM_OPENED_FAIL_MESSAGE =
  Translations.claims_tab.remove_claim_opened_fail_message;
const CLAIM_OPENED_FAIL_ERROR_MESSAGE =
  Translations.claims_tab.claim_opened_fail_error_message;

const REMOVE_CLAIM_PAYMENT_FAIL_MESSAGE =
  Translations.claims_tab.remove_claim_payment_fail_message;
const CLAIM_PAYMENTS_FAIL_ERROR_MESSAGE =
  Translations.claims_tab.claim_payment_fail_error_message;

const REMOVE_CLAIMS_FAIL_MESSAGE =
  Translations.claims_tab.remove_claim_status_fail_message;
const CLAIM_STATUS_FAIL_ERROR_MESSAGE =
  Translations.claims_tab.claim_status_fail_error_message;

const fetchClaimsOpenedStarted = () => ({
  type: types.FETCH_CLAIMS_OPENED_STARTED
});

const fetchClaimsOpenedSucceeded = (data, agency_code) => ({
  type: types.FETCH_CLAIMS_OPENED_SUCCEEDED,
  payload: data,
  agencyCode: agency_code
});

const fetchClaimsOpenedFailed = error => ({
  type: types.FETCH_CLAIMS_OPENED_FAILED,
  payload: error,
  error: true
});

const removeClaimOpenedFailed = error => ({
  type: types.REMOVE_CLAIM_OPENED_FAILED,
  payload: error,
  error: true
});

const fetchClaimPaymentsStarted = () => ({
  type: types.FETCH_CLAIM_PAYMENTS_STARTED
});

const fetchClaimPaymentsSucceeded = (data, agencyCode) => ({
  type: types.FETCH_CLAIM_PAYMENTS_SUCCEEDED,
  payload: data,
  agencyCode
});

const fetchClaimPaymentsFailed = error => ({
  type: types.FETCH_CLAIM_PAYMENTS_FAILED,
  payload: error,
  error: true
});

const removeClaimPaymentFailed = error => ({
  type: types.REMOVE_CLAIM_PAYMENT_FAILED,
  payload: error,
  error: true
});

const fetchClaimStatusesStarted = () => ({
  type: types.FETCH_CLAIMS_STATUSES_STARTED
});

const fetchClaimStatusesSucceeded = (data, agencyCode) => ({
  type: types.FETCH_CLAIMS_STATUSES_SUCCEEDED,
  payload: data,
  agencyCode
});

const fetchClaimStatusesFailed = error => ({
  type: types.FETCH_CLAIMS_STATUSES_FAILED,
  payload: error,
  error: true
});

const removeClaimStatusFailed = error => ({
  type: types.REMOVE_CLAIMS_STATUS_FAILED,
  payload: error,
  error: true
});

const fetchClaimsSavedLossesListStarted = () => ({
  type: types.FETCH_CLAIMS_SAVED_LOSSES_LIST_STARTED
});

const fetchClaimsSavedLossesListSucceeded = (data, agencyCode) => ({
  type: types.FETCH_CLAIMS_SAVED_LOSSES_LIST_SUCCEEDED,
  payload: data,
  agencyCode
});

const fetchClaimsSavedLossesListFailed = error => ({
  type: types.FETCH_CLAIMS_SAVED_LOSSES_LIST_FAILED,
  payload: error,
  error: true
});

const removeClaimsSavedLossesFailed = error => ({
  type: types.DELETE_CLAIMS_SAVED_LOSSES_LIST_FAILED,
  payload: error,
  error: true
});

const fetchClaimsSubmittedLossesListStarted = () => ({
  type: types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_STARTED
});

const fetchClaimsSubmittedLossesListSucceeded = (data, agency_code) => ({
  type: types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_SUCCEEDED,
  payload: data,
  agencyCode: agency_code
});

const fetchClaimsSubmittedLossesListFailed = error => ({
  type: types.FETCH_CLAIMS_SUBMITTED_LOSSES_LIST_FAILED,
  payload: error,
  error: true
});

export const fetchClaimsOpened = (
  agency_code,
  start_date,
  employeeUserName,
) => dispatch => {
  dispatch(fetchClaimsOpenedStarted());
  return homeClaimsService
    .fetchClaimsOpened(
      agency_code,
      start_date,
      employeeUserName,
    )
    .then(
      response => {
        if (response.data)
          dispatch(fetchClaimsOpenedSucceeded(response.data, agency_code));
        else {
          dispatch(fetchClaimsOpenedFailed(CLAIM_OPENED_FAIL_ERROR_MESSAGE));
        }
      },
      error => {
        dispatch(fetchClaimsOpenedFailed(CLAIM_OPENED_FAIL_ERROR_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
export const removeClaimOpened = (
  agency_code,
  start_date,
  employeeUserName,
  claim_number,
) => dispatch => {
  return homeClaimsService
    .removeClaimOpened(claim_number)
    .then(
      () => {
        dispatch(
          fetchClaimsOpened(
            agency_code,
            start_date,
            employeeUserName
          )
        );
      },
      error => {
        dispatch(removeClaimOpenedFailed(REMOVE_CLAIM_OPENED_FAIL_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClaimPayments = (
  agency_code,
  start_date
) => dispatch => {
  dispatch(fetchClaimPaymentsStarted());
  return homeClaimsService
    .fetchClaimPayments(agency_code, start_date)
    .then(
      response => {
        if (response.data)
          dispatch(fetchClaimPaymentsSucceeded(response.data, agency_code));
        else {
          dispatch(fetchClaimPaymentsFailed(CLAIM_PAYMENTS_FAIL_ERROR_MESSAGE));
        }
      },
      error => {
        dispatch(fetchClaimPaymentsFailed(CLAIM_PAYMENTS_FAIL_ERROR_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
export const removeClaimPayment = (
  agency_code,
  start_date,
  payment_number
) => dispatch => {
  return homeClaimsService
    .removeClaimPayment(payment_number)
    .then(
      () => {
        dispatch(
          fetchClaimPayments(agency_code, start_date)
        );
      },
      error => {
        dispatch(removeClaimPaymentFailed(REMOVE_CLAIM_PAYMENT_FAIL_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClaimStatuses = (
  agency_code
) => dispatch => {
  dispatch(fetchClaimStatusesStarted());
  return homeClaimsService
    .fetchClaimStatuses(agency_code)
    .then(
      response => {
        if (response.data)
          dispatch(fetchClaimStatusesSucceeded(response.data, agency_code));
        else {
          dispatch(fetchClaimStatusesFailed(CLAIM_STATUS_FAIL_ERROR_MESSAGE));
        }
      },
      error => {
        dispatch(fetchClaimStatusesFailed(CLAIM_STATUS_FAIL_ERROR_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
export const removeClaimStatus = (
  agency_code,
  document_din
) => dispatch => {
  return homeClaimsService
    .removeClaimStatus(agency_code, document_din)
    .then(
      () => {
        dispatch(fetchClaimStatuses(agency_code));
      },
      error => {
        dispatch(removeClaimStatusFailed(REMOVE_CLAIMS_FAIL_MESSAGE));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClaimSavedLosses = (
  agency_code
) => dispatch => {
  dispatch(fetchClaimsSavedLossesListStarted());
  return homeClaimsService
    .fetchSavedLossesList(agency_code)
    .then(
      response => {
        dispatch(
          fetchClaimsSavedLossesListSucceeded(response.data, agency_code)
        );
      },
      error => {
        dispatch(fetchClaimsSavedLossesListFailed(error.response.data));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const removeClaimsSavedLosses = (
  savedLossesData
) => dispatch => {
  return homeClaimsService
    .deleteSavedLosses(
      savedLossesData.agency_code,
      savedLossesData.control_number
    )
    .then(
      () => {
        dispatch(fetchClaimSavedLosses(savedLossesData.agency_code));
      },
      error => {
        dispatch(removeClaimsSavedLossesFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};

export const fetchClaimSubmittedLosses = (
  agency_code
) => dispatch => {
  dispatch(fetchClaimsSubmittedLossesListStarted());
  return homeClaimsService
    .fetchSubmittedLossesList(agency_code)
    .then(
      response => {
        dispatch(
          fetchClaimsSubmittedLossesListSucceeded(response.data, agency_code)
        );
      },
      error => {
        dispatch(fetchClaimsSubmittedLossesListFailed(error.response.data));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
