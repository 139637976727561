import { connect } from "react-redux";
import Umail from "./umail";
import { dismissUmail, postUmailMessage } from "./umailAction";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getisSpecialtyPortalView } from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  ...state.umailData,
  name: sessionSelector.getFirstAndLastName(state),
  email: sessionSelector.getEmail(state),
  agency: sessionSelector.getActiveAgencyName(state),
  producer_number: sessionSelector.getAgentProducerCode(state),
  agentCode: sessionSelector.getActiveAgencyCode(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

const mapDispatchToProps = {
  handleClose: () => dismissUmail,
  postUmailMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Umail);
