import * as routes from "../../../constants/routes";
import {
  useRootRouteMatch,
  useStateRouteMatch,
  useStateProductRouteMatch,
  useStateProductSubheaderRouteMatch,
  useStateProductSubheaderSubheader2RouteMatch
} from "../hooks";

export const useRouteEventHandler = ({
  setSelectedState,
  setSelectedProduct,
  setSelectedSubheader,
  setSelectedSubheader2,
  history,
  defaultSelectedSubheader,
  selectedState,
  selectedProduct
}) => {

  const rootRouteMatch = useRootRouteMatch();
  const stateRouteMatch = useStateRouteMatch();
  const stateProductRouteMatch = useStateProductRouteMatch();
  const stateProductSubheaderRouteMatch = useStateProductSubheaderRouteMatch();
  const stateProductSubheaderSubheader2RouteMatch = useStateProductSubheaderSubheader2RouteMatch();

  const onAgentManualsHomeClick = (replaceHistory) => {
    setSelectedState(null);
    setSelectedProduct(null);
    setSelectedSubheader(null);
    setSelectedSubheader2(null);
    if (replaceHistory) {
      history.replace(`${routes.AGENT_MANUALS}`);
    } else {
      history.push(`${routes.AGENT_MANUALS}`);
    }
  };

  const onStatesListClick = option => {
    setSelectedState(option);
    setSelectedProduct(null);
    setSelectedSubheader(null);
    setSelectedSubheader2(null);
    const isTheRootRoute = rootRouteMatch && rootRouteMatch.isExact;
    const isTheSameStateSelectionRoute = stateRouteMatch && stateRouteMatch.isExact && stateRouteMatch.params.stateId === option.value;
    if (option) {
      if (isTheRootRoute) {
        history.replace(`${routes.AGENT_MANUALS}/state/${option.value}`);
      } else if (isTheSameStateSelectionRoute) {
        history.replace(`${routes.AGENT_MANUALS}/state/${option.value}`);
      } else {
        history.push(`${routes.AGENT_MANUALS}/state/${option.value}`);
      }
    }
  };

  const onProductListClick = product => {
    setSelectedProduct(product);
    setSelectedSubheader(null);
    setSelectedSubheader2(null);
    const isTheSameDefaultProductSubheaderSelectionRoute = stateProductSubheaderRouteMatch &&
      stateProductSubheaderRouteMatch.isExact &&
      stateProductSubheaderRouteMatch.params.subheaderId === defaultSelectedSubheader?.id;
    if (selectedState && product) {
      if (isTheSameDefaultProductSubheaderSelectionRoute) {
        history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${product.id}`);
      } else {
        history.push(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${product.id}`);
      }
    }
  };

  const onHeaderClick = (header) => {
    if (!header.navigation_link) {
      setSelectedSubheader2(null);
      setSelectedSubheader(header);
      const isProductSelectionRoute = stateProductRouteMatch && stateProductRouteMatch.isExact;
      const isSameProductHeaderSelectionRoute = stateProductSubheaderRouteMatch &&
        stateProductSubheaderRouteMatch.isExact &&
        stateProductSubheaderRouteMatch.params.subheaderId === header?.id;
      if (selectedState && selectedProduct && header) {
        if (isProductSelectionRoute) {
          history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${header.id}`);
        } else if (isSameProductHeaderSelectionRoute) {
          history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${header.id}`);
        } else {
          history.push(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${header.id}`);
        }
      }
    } else {
      const target = header.open_new_window ? "_blank" : "_self";
      window.open(`${header.navigation_link}`, target);
    }
  };

  const onSubHeaderClick = (subHeader) => {
    if (!subHeader.navigation_link) {
      setSelectedSubheader2(null);
      setSelectedSubheader(subHeader);
      const isProductSelectionRoute = stateProductRouteMatch && stateProductRouteMatch.isExact;
      const isSameProductSubheaderSelectionRoute = stateProductSubheaderRouteMatch &&
        stateProductSubheaderRouteMatch.isExact &&
        stateProductSubheaderRouteMatch.params.subheaderId === subHeader?.id;
      if (selectedState && selectedProduct && subHeader) {
        if (isProductSelectionRoute) {
          history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${subHeader.id}`);
        } else if (isSameProductSubheaderSelectionRoute) {
          history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${subHeader.id}`);
        } else {
          history.push(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${subHeader.id}`);
        }
      }

    } else {
      const target = subHeader.open_new_window ? "_blank" : "_self";
      window.open(`${subHeader.navigation_link}`, target);
    }
  };

  const onSubHeader2Click = (subHeader, subHeader2) => {
    if (!subHeader2?.navigation_link) {
      setSelectedSubheader(subHeader);
      setSelectedSubheader2(subHeader2);
      const isSameProductSubheaderSubheader2SelectionRoute = stateProductSubheaderSubheader2RouteMatch &&
        stateProductSubheaderSubheader2RouteMatch.isExact &&
        stateProductSubheaderSubheader2RouteMatch.params.subheader2Id === subHeader2?.id;
      if (selectedState && selectedProduct && subHeader && subHeader2) {
        if (isSameProductSubheaderSubheader2SelectionRoute) {
          history.replace(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${subHeader.id}/subheader-2/${subHeader2.id}`);
        } else {
          history.push(`${routes.AGENT_MANUALS}/state/${selectedState.value}/product/${selectedProduct.id}/subheader/${subHeader.id}/subheader-2/${subHeader2.id}`);
        }
      }
    } else {
      const target = subHeader2?.open_new_window ? "_blank" : "_self";
      window.open(`${subHeader2?.navigation_link}`, target);
    }
  };

  return {
    onAgentManualsHomeClick,
    onStatesListClick,
    onProductListClick,
    onHeaderClick,
    onSubHeaderClick,
    onSubHeader2Click
  };
};
