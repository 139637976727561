import { useEffect, useRef, useState } from "react";
import { logger } from "../../../loggers";
import { getActiveAgencyContacts } from "../../services";

export default function useGetContacts(agencyCode, formatCallback) {
  const [data, setData] = useState();
  const [isContactsLoading, setIsContactsLoading] = useState(false);
  const cache = useRef({});
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (!agencyCode || isContactsLoading || error.current) return;

    if (cache?.current?.[agencyCode]) {
      setData(cache.current[agencyCode]);
      return;
    }

    setIsContactsLoading(true);

    getActiveAgencyContacts(agencyCode)
      .then(res => {
        if (res.status === 200 && res.data) {
          const d = !!formatCallback ? formatCallback(res.data) : res.data;
          cache.current[agencyCode] = d;
          setData(d);
        } else {
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsContactsLoading(false));
  }, [agencyCode, isContactsLoading, formatCallback]);

  return [data, isContactsLoading];
}
