import React, { useState } from "react";
import { connect } from "react-redux";
import { Dropdown, FormGroup } from "@ufginsurance/ui-kit";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import useGetContacts from "../../surety/consent-of-surety/hooks/useGetContactList";
import NewProducerModal from "../../commercial/builders-risk/components/NewProducerModal";

function ProducerDropdown({
  agencyCode,
  form,
  isRequired,
  label,
  size,
  allowOther
}) {
  const [showNewProducerModal, setShowNewProducerModal] = useState(false);
  const [producers, isProducersLoading] = useGetContacts(agencyCode, data => {
    if (!data) return [];
    const options = data
      .map(({ name, number, first_name, last_name, email, phone_number }) => ({
        label: name,
        value: String(number), // Must be string for Dropdown.
        first_name,
        last_name,
        email,
        phone_number
      }))
      .toSorted((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

    if (allowOther)
      options.push({
        label: "Other",
        value: "other"
      });

    return options;
  });

  const {
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    values,
    updateForm,
    errors
  } = form;

  const handleProducerCodeOnChange = ({ value, event, field }) => {
    if (value === "other") {
      setShowNewProducerModal(true);
      return;
    }

    const selectedProducer = producers?.find(
      producer => parseInt(producer.value, 10) === parseInt(value, 10)
    );

    if (selectedProducer) {
      updateForm({
        values: {
          ...values,
          producer_code: value,
          producer: {
            first_name: selectedProducer.first_name,
            last_name: selectedProducer.last_name,
            email: selectedProducer.email,
            phone: selectedProducer.phone_number
          }
        },
        errors: {
          ...errors,
          producer: [],
          producer_code: []
        }
      });
    } else {
      handleOnChange({ value, event, field });
    }
  };

  return (
    <>
      <FormGroup>
        <Dropdown
          id="producer_code"
          name="producer_code"
          label={label}
          options={producers || []}
          value={values?.producer_code}
          onChange={handleProducerCodeOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          required={isRequired}
          size={size}
          isLoading={isProducersLoading}
        />
      </FormGroup>

      <NewProducerModal
        form={form}
        showNewProducerModal={showNewProducerModal}
        setShowNewProducerModal={setShowNewProducerModal}
      />
    </>
  );
}

ProducerDropdown.defaultProps = {
  isRequired: true,
  id: "contact_person",
  label: "Contact Person",
  size: "lg"
};

const mapState = state => ({
  agencyCode: getSelectedAgencyCode(state)
});

export default connect(mapState)(ProducerDropdown);
