import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Translations } from "../../components/translations";
import { DATE_FORMAT } from "../services/claimsUtil.js";
import { Currency, Table } from "@ufginsurance/ui-kit";

const ClaimsClaimants = ({
  claimNumber,
  selectedAgencyCode,
  claimants,
  fetchClaimsClaimants,
  employeeUserName
}) => {
 

  useEffect(() => {
    fetchClaimsClaimants(
      claimNumber,
      selectedAgencyCode,
      employeeUserName
    );
  }, [
    fetchClaimsClaimants,
    claimNumber,
    selectedAgencyCode,
    employeeUserName
  ]);

  const columns = [
    {
      key: "claimant_name",
      label: Translations.Claims.claims_detail.claimant_name,
      classes: " claimants-claimant-name",
      element: row => row.claimant_name
    },
    {
      key: "cover",
      label: Translations.Claims.claims_detail.coverage,
      element: row => row.cover
    },
    {
      key: "last_payment_amount",
      label: Translations.Claims.claims_detail.last_payment,
      element: row => <Currency amount={row.last_payment_amount} />,
      align: "right"
    },

    {
      key: "last_payment_issue_date",
      label: Translations.Claims.claims_detail.issue_date,
      className: "claimants-issue-date",
      align: "center",
      element: row =>
        row.last_payment_issue_date &&
        moment(row.last_payment_issue_date).format(DATE_FORMAT)
    },
    {
      key: "total_paid",
      label: Translations.Claims.claims_detail.total_paid,
      element: row => <Currency amount={row.total_paid} />,
      align: "right"
    },
    {
      key: "outstanding_reserves",
      label: Translations.Claims.claims_detail.outstanding_reserves,
      className: "claimants-outstanding-reserves",
      element: row => <Currency amount={row.outstanding_reserves} />
    },
    {
      key: "status",
      label: Translations.Claims.claims_detail.status,
      element: row => row.status
    }
  ];

  return (
    <div>
      {selectedAgencyCode === "109015" ? (
        Translations.Claims.claims_detail.claimants_internal_user
      ) : claimants && claimants.length > 0 ? (
        <div className="claimants">
          <Table
            data={claimants}
            columns={columns}
            compact
            showPagination
            wrapperClasses="claims-claimants-table"
            rowKey="id"
          />
        </div>
      ) : (
        <div>{Translations.Claims.claims_detail.no_claimants}</div>
      )}
    </div>
  );
};

ClaimsClaimants.propTypes = {
  claimNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedAgencyCode: PropTypes.string,
  claimants: PropTypes.array,
  fetchClaimsClaimants: PropTypes.func,
  employeeUserName: PropTypes.string
};

export default ClaimsClaimants;
