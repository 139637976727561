import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";
import PropTypes from "prop-types";
import { Anchor, Icon } from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";

const Breadcrumbs = ({
  onAgentManualsHomeClick,
  selectedState,
  selectedProduct,
  onStatesListClick,
  selectedSubheader,
  selectedSubheader2,
  onProductListClick,
  isSpecialtyPortalView,
  onSubHeaderClick
}) => {
  const current = " current";
  const defaultHeaderText = isSpecialtyPortalView
    ? Translations.commercial.broker_manuals
    : Translations.commercial.agent_manuals;

  return (
    <nav className="breadcrumb">
      <Switch>
        <Route exact path={routes.AGENT_MANUALS_STATE}>
          <Anchor
            className="breadcrumb__item"
            onClick={onAgentManualsHomeClick}
          >
            {defaultHeaderText}
            {selectedState && <Icon size="lg" icon="farAngleRight" />}
          </Anchor>
          <Anchor className={`breadcrumb__item${current}`} disabled>
            <>{selectedState && selectedState.label}</>
          </Anchor>
        </Route>
        <Route exact path={routes.AGENT_MANUALS_STATE_PRODUCT}>
          <Anchor
            className="breadcrumb__item"
            onClick={onAgentManualsHomeClick}
          >
            {defaultHeaderText}
            {selectedState && <Icon size="lg" icon="farAngleRight" />}
          </Anchor>

          <Anchor
            className="breadcrumb__item"
            onClick={() => onStatesListClick(selectedState)}
          >
            <>
              {selectedState && selectedState.label}
              {selectedProduct && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>

          <Anchor className={`breadcrumb__item${current}`} disabled>
            <>{selectedProduct && selectedProduct.product}</>
          </Anchor>
        </Route>
        <Route exact path={routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER}>
          <Anchor
            className="breadcrumb__item"
            onClick={onAgentManualsHomeClick}
          >
            {defaultHeaderText}
            {selectedState && <Icon size="lg" icon="farAngleRight" />}
          </Anchor>

          <Anchor
            className="breadcrumb__item"
            onClick={() => onStatesListClick(selectedState)}
          >
            <>
              {selectedState && selectedState.label}
              {selectedProduct && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>

          <Anchor
            className="breadcrumb__item"
            onClick={() => onProductListClick(selectedProduct)}
          >
            <>
              {selectedProduct && selectedProduct.product}
              {selectedSubheader && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>
          <Anchor className={`breadcrumb__item${current}`} disabled>
            <>{selectedSubheader && selectedSubheader.description}</>
          </Anchor>
        </Route>
        <Route
          exact
          path={routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER_SUBHEADER2}
        >
          <Anchor
            className="breadcrumb__item"
            onClick={onAgentManualsHomeClick}
          >
            {defaultHeaderText}
            {selectedState && <Icon size="lg" icon="farAngleRight" />}
          </Anchor>

          <Anchor
            className="breadcrumb__item"
            onClick={() => onStatesListClick(selectedState)}
          >
            <>
              {selectedState && selectedState.label}
              {selectedProduct && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>

          <Anchor
            className="breadcrumb__item"
            onClick={() => onProductListClick(selectedProduct)}
          >
            <>
              {selectedProduct && selectedProduct.product}
              {selectedSubheader && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>
          <Anchor
            className="breadcrumb__item"
            onClick={() => onSubHeaderClick(selectedSubheader)}
          >
            <>
              {selectedSubheader && selectedSubheader.description}
              {selectedSubheader2 && <Icon size="lg" icon="farAngleRight" />}
            </>
          </Anchor>
          <Anchor className={`breadcrumb__item${current}`} disabled>
            <>{selectedSubheader2 && selectedSubheader2.description}</>
          </Anchor>
        </Route>
        <Route>
          <Anchor className={`breadcrumb__item${current}`} disabled>
            {defaultHeaderText}
          </Anchor>
        </Route>
      </Switch>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  onAgentManualsHomeClick: PropTypes.func.isRequired,
  selectedState: PropTypes.object,
  selectedProduct: PropTypes.object,
  onStatesListClick: PropTypes.func.isRequired,
  selectedSubheader: PropTypes.object,
  selectedSubheader2: PropTypes.object,
  onProductListClick: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool,
  onSubHeaderClick: PropTypes.func
};

export default withRouter(Breadcrumbs);
