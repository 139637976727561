import API from "../api";

export const postUmailMessage = (form, attachments) => {
  const formData = new FormData();
  formData.append("json_body", JSON.stringify(form));
  attachments.forEach((file, i) => {
    const fileNumber = i === 0 ? "" : i + 1;
    // https://ufginsurance.atlassian.net/browse/OOA-2475
    // certain uploaded file content-types provided e.g. for .xlsx when processed by services are corrupted,
    // rewriting the content-type to a generic streamed file solves this issue.
    const genericFileContentType = "application/octet-stream";
    const fileBlob = file.slice(0, file.size, genericFileContentType);
    const newFile = new File([fileBlob], file.name, {
      type: genericFileContentType
    });
    formData.append(`file${fileNumber}`, newFile);
  });
  return API.agent().post(`/umail`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
};
