import React, { useState, useContext } from "react";
import cn from "classnames";
import { Panel, FlexRow, Button } from "@ufginsurance/ui-kit";
import { updateCoverageValue } from "../shared/coveragePanels";
import CoverageSearch from "./CoverageSearch";
import FormSetup from "../shared/FormSetup";
import HiredAutoToggles from "../step4/HiredAutoToggles";
import OnlineQuotingContext from "../OnlineQuotingContext";
import OqModal from "../shared/OqModal";
import { CyberLineRemoveLinkStep4 } from "../shared/CyberLine";
import "../shared/CoverageTitle.scss";

const ProductCoverages = ({
  panels,
  allCoverages,
  selectedCoverageTitle,
  saveCoverages,
  saveNonCoverage,
  updateFormStatus,
  formId,
  hideAddCoverageButton,
  hideOutterPanel = false,
  showHiredNonAutoSwitches = false,
  coveragePaths,
  coverageExclusions,
  coverageUpdating,
  setCoverageUpdating
}) => {
  const { toastErrr } = useContext(OnlineQuotingContext);

  const [showCoverageSearch, setShowCoverageSearch] = useState(false);

  // this state is used to manage the hiding of the add coverage modal when we edit
  // ... a scheduled item
  const [scheduleItemOpenInCoverable, setScheduleItemOpenInCoverable] =
    useState();

  const localUpdateCoverageValue = (fieldName, value) => {
    updateCoverageValue({
      fieldName,
      value,
      allCoverages,
      saveCoverages,
      toastErrr,
      saveNonCoverage
    });
  };

  //hide Auto Coverages add button
  const _hideAddAutoCoverageButton =
    showHiredNonAutoSwitches && selectedCoverageTitle === "Auto";

  return (
    <div className="oq__product-coverages">
      <>
        {showCoverageSearch && (
          <OqModal
            className={cn(
              "oq__modal__coverable",
              "oq_modal__coverages__policy-info",
              {
                scheduleItemOpenInCoverable,
                [`oq__modal__add-coverages__${selectedCoverageTitle.replace(
                  /[^a-zA-Z0-9-]/g,
                  "_"
                )}`]: selectedCoverageTitle
              }
            )}
            title="Additional Coverages"
            overlayHidden={scheduleItemOpenInCoverable}
            show={!!showCoverageSearch}
            closeIcon={false}
            onHide={() => {
              setShowCoverageSearch(null);
            }}
            body={
              <CoverageSearch
                key={coveragePaths}
                unselectedCoverages={allCoverages.filter(
                  c => c.selected === false
                )}
                formTitle={`${selectedCoverageTitle} Additional Coverage`}
                onHide={() => {
                  setShowCoverageSearch(null);
                }}
                saveCoverages={saveCoverages}
                allCoverages={allCoverages}
                updateFormStatus={updateFormStatus}
                formId={formId + "-coverageSearch"}
                coveragePaths={coveragePaths}
                setScheduleItemOpenInCoverable={setScheduleItemOpenInCoverable}
                coverageExclusions={coverageExclusions}
              />
            }
          />
        )}
        {panels && panels.length > 0 && (
          <Panel
            id={formId}
            title={
              selectedCoverageTitle && (
                <div className="oq__coverage__title-container">
                  <div className="oq__coverage__title-row">
                    <div className="oq__coverage__title">
                      {selectedCoverageTitle} Coverages
                    </div>
                    {selectedCoverageTitle === "Cyber" && (
                      <CyberLineRemoveLinkStep4 />
                    )}
                  </div>
                </div>
              )
            }
            titlebar
            rounded
            bgcolor={hideOutterPanel ? undefined : "grey"}
            noBorder={hideOutterPanel}
            className={`oq__coverage-panel__${(
              selectedCoverageTitle || ""
            ).replace(/[^a-zA-Z0-9-]/g, "_")}`}
          >
            <FormSetup
              sectionHeader=""
              panels={panels}
              handleFormSubmit={() => {}}
              submitBtnLabel=""
              saveCurrentValues={localUpdateCoverageValue}
              hideSubmitBtn
              useCoveragePanel
              updateFormStatus={updateFormStatus}
              formId={formId}
              coverageUpdating={coverageUpdating}
            />
            {showHiredNonAutoSwitches &&
              selectedCoverageTitle === "BOP-Pro" && (
                <HiredAutoToggles setCoverageUpdating={setCoverageUpdating} />
              )}

            {!_hideAddAutoCoverageButton && !hideAddCoverageButton && (
              <FlexRow>
                <Button
                  icon="farPlus"
                  variant="primary"
                  className="oq__add-coverage-button"
                  onClick={() => {
                    setShowCoverageSearch(selectedCoverageTitle);
                  }}
                >
                  Add Additional Coverages
                </Button>
              </FlexRow>
            )}
          </Panel>
        )}
      </>
    </div>
  );
  //TODO put the search coverages table here instead of CoverageDataSection
  // combine coverages not selected and additionalCoverages
};

export default ProductCoverages;
