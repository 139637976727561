import { useEffect } from "react";
import { useRootRouteMatch, useCurrentRouteMatch } from "../hooks";

export const useStatesRouteFetch = ({
  areStatesLoading,
  stateListData,
  activeSpecialtyCompanyBranch,
  fetchStatesListDetails,
  previousFetchedCompanyBranch
}) => {
  const rootMatch = useRootRouteMatch();
  const currentMatch = useCurrentRouteMatch();
  const routeMatchesRootExactly = rootMatch?.isExact;
  const currentRouteStateId = currentMatch?.params?.stateId;

  useEffect(() => {
    if (
      (routeMatchesRootExactly || currentRouteStateId) &&
      !areStatesLoading &&
      activeSpecialtyCompanyBranch !== previousFetchedCompanyBranch
    ) {
      fetchStatesListDetails(activeSpecialtyCompanyBranch);
    }
  }, [
    fetchStatesListDetails,
    routeMatchesRootExactly,
    currentRouteStateId,
    activeSpecialtyCompanyBranch,
    areStatesLoading,
    stateListData,
    previousFetchedCompanyBranch
  ]);

  return null;
};
