import React, { useLayoutEffect } from "react";

import { useResizeObserver } from "@asyarb/use-resize-observer";
import { Button, FlexRow } from "@ufginsurance/ui-kit";

import "./BottomNavigation.scss";

const BottomNavigation = ({ left, middle, right }) => {
  // It'd be a lot easier if we could use CSS: position: sticky
  // but it is not currently allowed because it doesn't support IE 11
  // until then, we'll position the bar using javascript

  const updateBottomNavigationbar = () => {
    const currentScrollPos = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const body = document.body;

    const navbar = document.getElementById("oq__bottom-nav");
    const footer = document.getElementById("footer_bottom");

    if (!navbar || !footer) return false;

    const footerHeight = footer.offsetHeight;
    const footerOffssetTop = footer.offsetTop;

    if (footerOffssetTop + footerHeight < windowHeight) {
      // if the window height is so small that the page doesn't have a scrollbar
      const offset = windowHeight - footerOffssetTop;
      navbar.style.bottom = `${offset}px`;

      // adding this class makes the navbar look like it's part of the page
      navbar.classList.add("oq__bottom-nav__transparent");
    } else if (
      // if we've scrolled all of the way to the bottom of the page
      windowHeight + currentScrollPos >=
      body.scrollHeight - footerHeight
    ) {
      const offset =
        windowHeight + currentScrollPos - body.scrollHeight + footerHeight;
      navbar.style.bottom = `${offset}px`;

      // removing this class makes the navbar have a background
      navbar.classList.add("oq__bottom-nav__transparent");
    } else {
      // if the navbar is hovering over page content
      document.getElementById("oq__bottom-nav").style.bottom = "0";

      // removing this class makes the navbar have a background
      navbar.classList.remove("oq__bottom-nav__transparent");
    }
  };

  // this watches the body tag and if its size changes at all, it runs the updater
  useResizeObserver({
    element: document.getElementById("body"),
    callback: () => {
      updateBottomNavigationbar();
    }
  });

  // these events also trigger the update to the navbar position
  useLayoutEffect(() => {
    window.onscroll = updateBottomNavigationbar;
    window.onresize = updateBottomNavigationbar;
    window.popstate = updateBottomNavigationbar;
  });

  return (
    <div id="oq__bottom-nav">
      <div className="oq__bottom-nav__inner">
        <FlexRow className="oq__bottom-nav__row">
          {left && !left.hidden && (
            <>
              {left.jsx ? (
                left.jsx
              ) : (
                <Button
                  className="oq__nav__previous"
                  disabled={left.disabled}
                  onClick={left.onClick}
                  spinner={left.spinner}
                >
                  {left.text}
                </Button>
              )}
            </>
          )}

          {middle && !middle.hidden && middle.jsx && <>{middle.jsx}</>}

          {right && !right.hidden && (
            <>
              {right.jsx ? (
                right.jsx
              ) : (
                <Button
                  wrapperClassName="align-right"
                  className="oq__nav__continue"
                  variant="primary"
                  disabled={right.disabled}
                  onClick={right.onClick}
                  spinner={right.spinner}
                >
                  {right.text}
                </Button>
              )}
            </>
          )}
        </FlexRow>
      </div>
    </div>
  );
};

export default BottomNavigation;
