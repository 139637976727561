import React from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";

const propTypes = {
  addressdetails: PropTypes.any
};

const ViewOnMaps = ({ addressdetails }) => {
  const { address1, address2, city, state, zip, geolocation } = addressdetails;
  const defaultCenter = {
    lat: !isNaN(geolocation.latitude) ? parseFloat(geolocation.latitude) : 0,
    lng: !isNaN(geolocation.latitude) ? parseFloat(geolocation.longitude) : 0
  };
  const defaultZoom = 11;
  const defaultOptions = {
    mapTypeControl: true
  };

  const renderMarkers = mapObj => {
    const map = mapObj.map;
    const maps = mapObj.maps;
    const marker = new maps.Marker({
      position: defaultCenter,
      map,
      animation: maps.Animation.Drop
    });
    const mapInfoWindow = new maps.InfoWindow({
      content:
        "Address: <br>" +
        (!!address1 ? address1 + "<br> " : "") +
        (!!address2 ? address2 + "<br> " : "") +
        ((!!city ? city : "") + (!!city && !!state ? `, ${state}` : state)) +
        " " +
        (!!zip ? zip : ""),
      maxWidth: 350
    });
    mapInfoWindow.open(map, marker);
    maps.event.addListener(marker, "click", () => {
      mapInfoWindow.open(map, marker);
    });
  };

  return [
    <div key="googleMap" style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        key="map"
        bootstrapURLKeys={{
          key: "AIzaSyDKDvEi4eRJ8He5XmGDGA7aSZbbIn_cZ7c",
          language: "en"
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={renderMarkers}
        defaultZoom={defaultZoom}
        center={defaultCenter}
        options={defaultOptions}
      />
    </div>
  ];
};

ViewOnMaps.propTypes = propTypes;

export default ViewOnMaps;
