import { CancelToken } from "axios";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";

import { toTitleCase } from "../../../../components/Factory";
import { logger } from "../../../../loggers";
import { sortByProperty } from "../../../../online-quoting/shared/util";
import { getAccountSearch } from "../../../../services/onlineQuotingService";

export default function useFetchAccounts(value) {
  const [debouncedValue, setDebouncedValue] = useState();
  const [accounts, setAccounts] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const cache = useRef({});
  const error = useRef(false);
  const cancelSearch = useRef(null);
  const MIN_SEARCH_LENGTH = 3;

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  // Debounce the value.
  useEffect(() => {
    if (value && !/^[a-zA-Z0-9_].*/.test(value)) return;

    // If the field is reset, empty the options.
    if (!value?.length && cache?.current?.[debouncedValue]) {
      cache.current = {};
      setIsLoading(false);
      setData([]);
      setAccounts([]);
    }
    // Debounce.
    const handleDebounceValue = setTimeout(() => setDebouncedValue(value), 500);
    return () => clearTimeout(handleDebounceValue);
  }, [debouncedValue, value]);

  // Fetch the accounts with the debounced value.
  useEffect(() => {
    if (
      !debouncedValue ||
      debouncedValue?.length < MIN_SEARCH_LENGTH ||
      isLoading ||
      error.current
    ) {
      return;
    }

    if (cache?.current?.[debouncedValue]) {
      setData(cache.current[debouncedValue]);
      return;
    }

    setIsLoading(true);

    getAccountSearch(
      { searchText: encodeURIComponent(debouncedValue) },
      {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelSearch.current = c;
        })
      }
    )
      .then(res => {
        if (res.status === 200 && res.data) {
          cache.current[debouncedValue] = res.data;
          setData(res.data);
        } else {
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsLoading(false));
  }, [debouncedValue, isLoading]);

  // Create the account dropdown options.
  useEffect(() => {
    if (isLoading || !cache.current[debouncedValue]) return;
    const additionalOptions = [
      {
        value: debouncedValue + "|business",
        label: "+ Add New Business: " + debouncedValue,
        account_name: "+ Add New Business: " + debouncedValue,
        custom: true,
        type: "business"
      },
      {
        value: debouncedValue + "|individual",
        label: "+ Add New Individual: " + debouncedValue,
        account_name: "+ Add New Individual: " + debouncedValue,
        custom: true,
        type: "individual"
      }
    ];

    if (!data?.length && debouncedValue) {
      setAccounts(additionalOptions);
    }

    if (data?.length) {
      const formattedAccounts = data
        .map(a => {
          const name = !!a?.accountHolder?.contactName
            ? a?.accountHolder?.contactName
            : `${a?.accountHolder?.lastName} ${a?.accountHolder?.firstName}`;
          return {
            value: v4(),
            label: `${name} (${toTitleCase(
              a?.accountHolder?.primaryAddress?.city
            )}, ${a?.accountHolder?.primaryAddress?.state})`,
            account_number: a?.accountNumber,
            account_name: a?.accountHolder?.contactName,
            type: "api",
            account: a
          };
        })
        .sort(sortByProperty("label"));

      setAccounts([...formattedAccounts, ...additionalOptions]);
    }
  }, [data, debouncedValue, isLoading]);

  return [accounts, isLoading, error];
}
