import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import { ContentHeader, Tabs, Tab } from "@ufginsurance/ui-kit";

import * as routes from "../../constants/routes";
import Search from "./Search";
import { getTypes, getCategories } from "../services/documentsService";
import { getActiveSpecialtyCompanyBranch } from "../../portalview/changePortalViewStateSelectors";

const DocumentLibrary = ({ history, activeSpecialtyCompanyBranch }) => {
  const [documentTabs, setDocumentTabs] = useState([]);
  const [categoryTabs, setCategoryTabs] = useState([]);
  const [categoryIndex, setCategoryIndex] = useState(0);

  const [currentDocumentType, setCurrentDocumentType] = useState();
  const [currentDocumentCategory, setCurrentDocumentCategory] = useState();

  const COVERAGE_FORMS = "CF";

  useEffect(() => {
    history.push(routes.DOCUMENT_LIBRARY_COVERAGE);
  }, [history]);

  useEffect(() => {
    // This matches the properties that come back from the api
    const defaultDocumentsTab = [
      {
        type_id: "CF",
        name: "Coverage Forms"
      }
    ];

    getTypes().then(res => {
      setDocumentTabs(defaultDocumentsTab.concat(res.data));
    });

    setCurrentDocumentType(defaultDocumentsTab[0].type_id);
  }, []);

  useEffect(() => {
    if (currentDocumentType === COVERAGE_FORMS) {
      setCategoryTabs([]);
    } else {
      getCategories(currentDocumentType).then(res => {
        setCategoryTabs(res.data && res.data.length > 0 ? res.data : []);
        setCurrentDocumentCategory(
          res.data && res.data.length > 0 ? res.data[0].category_id : ""
        );
        setCategoryIndex(0);
      });
    }
  }, [currentDocumentType]);

  const onDocumentTypeTabClick = index => {
    setCurrentDocumentType(documentTabs[index].type_id);

    // Reset category to first on type switch
    setCurrentDocumentCategory(categoryTabs[0]?.category_id);
  };

  const onDocumentCategoryTabClick = index => {
    setCurrentDocumentCategory(categoryTabs[index].category_id);
    setCategoryIndex(index);
  };

  return (
    <div className="document-library">
      <ContentHeader>Documents and Forms</ContentHeader>
      {/* // Having a default tab and then a map does not work, so we need to
        concatenate the static tab with the other tabs in one map. */}
      <>
        <Tabs>
          {documentTabs.map(t => (
            <Tab
              key={t?.type_id}
              title={t?.name}
              onClick={onDocumentTypeTabClick}
            />
          ))}
        </Tabs>

        {categoryTabs && categoryTabs.length > 0 && (
          <Tabs activeTab={categoryIndex}>
            {categoryTabs.map(t => (
              <Tab
                key={t?.category_id}
                title={t?.description}
                onClick={onDocumentCategoryTabClick}
              />
            ))}
          </Tabs>
        )}

        <Search companyBranch={activeSpecialtyCompanyBranch} type={currentDocumentType} category={currentDocumentCategory} />
      </>
    </div>
  );
};

DocumentLibrary.propTypes = {
  history: PropTypes.object,
  activeSpecialtyCompanyBranch: PropTypes.string
};

const mapStateToProps = state => ({
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
});

export default compose(withRouter, connect(mapStateToProps))(DocumentLibrary);
