import { useEffect, useState, useRef } from "react";
import { getAgencyActivity } from "../../services/agencyActivityService";
import { logger } from "../../../loggers";
import { v4 } from "uuid";

export default function useGetAgencyActivity(agencyCode) {
  const [activity, setActivity] = useState([]);
  const [activityIsLoading, setActivityIsLoading] = useState(false);
  const activityCache = useRef({});
  const activityError = useRef(null);

  useEffect(() => {
    if (!agencyCode
      || activityIsLoading
      || activityCache.current[agencyCode]
      || activityError.current
    )
      return;

    setActivity([]);
    setActivityIsLoading(true);
    activityError.current = null;

    getAgencyActivity(agencyCode)
      .then(_ => {
        if (_.status === 200) {
          const activity = (_.data ?? []).map(_ => ({
            ..._,
            id: v4()
          }));
          activityCache.current[agencyCode] = activity;
          setActivity(activity);
        } else {
          activityError.current = _;
        }
      })
      .catch(_ => {
        activityError.current = _;
        console.error(_);
        logger.error({
          description: _.toString(),
          fatal: false
        });
      })
      .finally(() => setActivityIsLoading(false));
  }, [agencyCode, activityIsLoading]);

  return [activity, activityIsLoading, activityError.current];
}
