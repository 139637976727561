import React from "react";

const AccountNumber = ({ quoteData, step }) => {
  return (
    <>
      {quoteData && step > 1 && (
        <div className="qq__sidebar__summary__item">
          <b>Account:</b>
          <div>{quoteData?.account_number}</div>
        </div>
      )}
    </>
  );
};

export default AccountNumber;
