import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, LoadingIndicator, Table } from "@ufginsurance/ui-kit";
import moment from "moment";
import { DATE_FORMAT } from "../services/claimsUtil";
import { Translations } from "../../components/translations";

const ClaimsNotes = ({
  fetchClaimsNotes,
  fetchClaimNoteDocument,
  notes,
  loading,
  claimNumber
}) => {
  const [documentsLoading, setDocumentsLoading] = useState([]);
  const hasNotes = notes && notes.length > 0;

  useEffect(() => {
    fetchClaimsNotes(claimNumber);
  }, [fetchClaimsNotes, claimNumber]);

  const handleClaimNoteClick = (id, documentId, isAdjusterNote) => () => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(id)
      ? [...documentsLoading, id]
      : documentsLoading;
    setDocumentsLoading(documentsLoadingWithCurrentSelection);
    fetchClaimNoteDocument(claimNumber, documentId, isAdjusterNote).finally(
      () => {
        setDocumentsLoading(documentsLoading.filter(x => x !== id));
      }
    );
  };

  const columns = [
    {
      key: "file_date",
      label: "",
      className: "notes-file-date",
      element: row => <span>{moment(row.file_date).format(DATE_FORMAT)}</span>
    },
    {
      key: "file_type",
      label: "",
      className: "notes-file-type",
      element: row => {
        const requestInProgress = documentsLoading.includes(row.id);
        return (
          <Button
            className="display-flex"
            onClick={handleClaimNoteClick(
              row.id,
              row.document_id,
              row.is_adjuster_note
            )}
            disabled={requestInProgress}
          >
            {row.file_type}
            {requestInProgress && (
              <LoadingIndicator message="" type="spinner" />
            )}
          </Button>
        );
      }
    }
  ];

  return (
    <div>
      {loading ? (
        <LoadingIndicator />
      ) : hasNotes ? (
        <div className="notes">
          <Table
            data={notes}
            columns={columns}
            compact
            showPagination
            className="claims-notes-table"
            rowKey="id"
          />
        </div>
      ) : (
        <div>{Translations.Claims.claims_detail.no_notes}</div>
      )}
    </div>
  );
};

ClaimsNotes.propTypes = {
  fetchClaimsNotes: PropTypes.func,
  fetchClaimNoteDocument: PropTypes.func,
  notes: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  claimNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

export default ClaimsNotes;
