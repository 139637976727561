import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  LoadingIndicator,
  ButtonBar,
  Button,
  Alert
} from "@ufginsurance/ui-kit";
import QuoteProposalTable from "./QuoteProposalTable";
import { Translations } from "../../translations";

const QuoteProposals = ({
  agencyCode,
  daysFilterTabs,
  quoteProposals,
  sortableColumns,
  updateQuoteProposal,
  pastQuoteProposalsFilter,
  pastFetchedQuoteProposalsCode,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch,
  areQuoteProposalsLoading,
  activeSpecialtyCompanyBranch,
  loadingProposalDocumentIds
}) => {
  const [filter, setFilter] = useState(0);

  // Needs done prior to useEffect because of daysFilterTabs being a new
  // array every render.
  const numberOfDays = daysFilterTabs[filter];

  const agencyChanged = agencyCode !== pastFetchedQuoteProposalsCode;
  const companyBranchChanged =
    activeSpecialtyCompanyBranch !==
    pastFetchedQuoteProposalsSpecialtyCompanyBranch;

  const filterChanged = pastQuoteProposalsFilter !== numberOfDays;

  const shouldFetchProposals =
    agencyChanged ||
    companyBranchChanged ||
    (filterChanged && !(quoteProposals.items?.[numberOfDays]?.length > 0));

  useEffect(() => {
    if (
      agencyCode !== "109015" &&
      !areQuoteProposalsLoading &&
      shouldFetchProposals
    ) {
      updateQuoteProposal(
        agencyCode,
        numberOfDays,
        activeSpecialtyCompanyBranch
      );
    }
  }, [
    activeSpecialtyCompanyBranch,
    agencyCode,
    areQuoteProposalsLoading,
    numberOfDays,
    shouldFetchProposals,
    updateQuoteProposal
  ]);

  const quoteProposalListData = quoteProposals.items?.[numberOfDays] || [];

  return (
    <div className="quote-proposals">
      <ButtonBar size="sm">
        {daysFilterTabs.map((daysCount, i) => {
          const isActive = areQuoteProposalsLoading
            ? pastQuoteProposalsFilter === daysCount
            : filter === i;
          return (
            <Button
              key={daysCount}
              variant={isActive ? "primary" : "plain"}
              disabled={areQuoteProposalsLoading}
              onClick={() => {
                setFilter(i);
              }}
            >
              {`Last ${daysCount} Days`}
            </Button>
          );
        })}
      </ButtonBar>
      <div>
        {agencyCode === "109015" ? (
          <Alert type="plain" dismissible={false}>
            {Translations.commercial.home_agent_table_error}
          </Alert>
        ) : areQuoteProposalsLoading ? (
          <LoadingIndicator />
        ) : (
          <QuoteProposalTable
            quoteProposalList={quoteProposalListData}
            sortableColumns={sortableColumns}
            loadingProposalDocumentIds={loadingProposalDocumentIds}
          />
        )}
      </div>
    </div>
  );
};

QuoteProposals.propTypes = {
  agencyCode: PropTypes.string.isRequired,
  daysFilterTabs: PropTypes.array.isRequired,
  quoteProposals: PropTypes.object.isRequired,
  sortableColumns: PropTypes.array,
  updateQuoteProposal: PropTypes.func.isRequired,
  pastQuoteProposalsFilter: PropTypes.number,
  pastFetchedQuoteProposalsCode: PropTypes.string,
  pastFetchedQuoteProposalsSpecialtyCompanyBranch: PropTypes.string,
  areQuoteProposalsLoading: PropTypes.bool.isRequired,
  activeSpecialtyCompanyBranch: PropTypes.string,
  loadingProposalDocumentIds: PropTypes.array
};

export default QuoteProposals;
