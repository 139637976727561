import React, { useContext } from "react";

import {
  Button,
  FlexRow,
  useForm,
  Form,
  Switch,
  FormGroup,
  TextArea
} from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import {
  productKeys,
  modifierPath,
  statesWorkCompModifierTypes,
  dividendPlanTypes
} from "../../shared/constants";
import OqModal from "../../shared/OqModal";
import _get from "lodash/get";
import CreditDebitFields from "./CreditDebitFields";
import DividendFields from "./DividendFields";

const CreditsDebitsModal = ({
  setShowModal,
  selectedProducts,
  editMode,
  primaryLocationState
}) => {
  const { quoteData, toastErrr, patchQuotePromise, quoteIsUpdating } =
    useContext(OnlineQuotingContext);

  const workCompStateType = statesWorkCompModifierTypes[primaryLocationState];

  let workCompIsDividends = false;

  const initialValues = {};

  selectedProducts.forEach(lob => {
    const isWorkComp = lob === "wcmWorkersComp";
    const modifier = _get(quoteData, modifierPath(lob), {});

    const amount = modifier?.totalModifier || 0;
    initialValues[lob + "Switch"] = amount !== 0;
    if (
      !isWorkComp ||
      (isWorkComp && ["creditsDebits", "both"].includes(workCompStateType))
    ) {
      initialValues[lob + "Action"] = amount > 0 ? "debit" : "credit";
      initialValues[lob + "Amount"] = String(Math.abs(amount));
    }
    // Wisconsin only does dividends... and not credits/debits
    else if (isWorkComp && ["dividends"].includes(workCompStateType)) {
      const dividend = _get(quoteData, modifierPath(lob + "-Dividend"), {});
      if (dividend?.planApplies === "Yes") {
        initialValues[lob + "Switch"] = true;

        if (dividend?.planType === "Variable") {
          initialValues[lob + "DividendPlanType"] = "0";
        } else {
          initialValues[lob + "DividendPlanType"] = dividendPlanTypes.find(
            x =>
              x.type === dividend?.planType &&
              Number(x.value) === Number(dividend?.percentage)
          )?.value;
        }
      } else {
        initialValues[lob + "DividendPlanType"] = "";
      }
      workCompIsDividends = true;
    }

    initialValues[lob + "Comment"] = modifier?.justification || "";
  });

  const CallApi = ({ newData }) => {
    patchQuotePromise({
      newData,
      quoteId: quoteData.quoteID,
      updateMessage: "Updating credits and debits"
    })
      .then(() => {
        setShowModal(false);
      })
      .catch(({ error }) => {
        toastErrr({
          action: "creditDebits: patchQuotePromise",
          description: "unable to save credit or debits",
          error,
          payload: newData
        });

        setShowModal(false);
      });
  };

  const handleFormSubmit = ({ values }) => {
    const nonWCpayload = [];
    // set the default work comp payload to include 'dividends = no', but...
    // ... only if workComp product is on the quote and it's a dividend state
    // ... this helps remove it when the switch it off
    let wcPayload =
      selectedProducts.includes("wcmWorkersComp") &&
      ["dividends"].includes(workCompStateType)
        ? { dividends: { planApplies: "No" } }
        : {};

    let newData = {};
    selectedProducts.forEach(lob => {
      if (lob !== "wcmWorkersComp" || !workCompIsDividends) {
        nonWCpayload.push({
          lob,
          totalModifier:
            values[lob + "Action"] === "credit"
              ? 0 - Number(values[lob + "Amount"])
              : Number(values[lob + "Amount"]),
          justification: values[lob + "Comment"]
        });
      }
      if (
        ["0", "5", "10"].includes(values[lob + "DividendPlanType"]) &&
        !!values[lob + "Switch"]
      ) {
        const dividentObj = dividendPlanTypes.find(
          x => Number(x.value) === Number(values[lob + "DividendPlanType"])
        );
        wcPayload = {
          dividends: {
            planApplies: !!values[lob + "Switch"] ? "Yes" : "No",
            planType: dividentObj?.type
          }
        };

        if (dividentObj?.value !== "0") {
          wcPayload.dividends.percentage = Number(dividentObj?.value);
        }
      }
    });

    if (nonWCpayload.length > 0) {
      newData = { premiumModifier: nonWCpayload };
    }
    if (wcPayload) {
      newData = { ...newData, ...wcPayload };
    }
    if (Object.keys(newData).length !== 0) {
      CallApi({ newData });
    }
  };

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  const handleLobSwitchChange = ({ lob, field, value }) => {
    const newValues = { [field]: value };

    //  When toggling the LOB switch, reset the field values to the default/null state
    if (values[lob + "Amount"]) newValues[[lob + "Amount"]] = "";
    if (values[lob + "Comment"]) newValues[[lob + "Comment"]] = "";
    if (values[lob + "DividendPlanType"])
      newValues[[lob + "DividendPlanType"]] = "";

    updateForm({
      values: newValues
    });
  };

  return (
    <>
      <OqModal
        size="lg"
        className="oq__modal oq__modal__credits-debits"
        title="Credits and Debits"
        body={
          <>
            <FlexRow>
              <p>
                Select a product to add credit or debits. Total credit or debit
                cannot exceed 25%.
              </p>
            </FlexRow>
            <Form className="oq__form__credits-debits oq-forms" context={form}>
              {selectedProducts.length &&
                selectedProducts.map(lob => {
                  const isWorkComp = lob === "wcmWorkersComp";
                  // if work comp and not an allowed state, don't show options
                  if (isWorkComp && !workCompStateType) return null;

                  return (
                    <div className={`oq__creditdebits__${lob}`} key={lob}>
                      <FormGroup hideErrors>
                        <Switch
                          size="full"
                          id={lob + "Switch"}
                          name={lob + "Switch"}
                          label={productKeys[lob].label}
                          onChange={({ field, value }) => {
                            handleLobSwitchChange({ field, value, lob });
                          }}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[lob + "Switch"] || false}
                          noLabel
                          className="oq__form__semibold-label"
                        />
                      </FormGroup>
                      {
                        // if LOB SWITCH IS ON, SHOW LOB-APPRORIATE FIELDS
                        values[lob + "Switch"] === true && (
                          <>
                            {isWorkComp && workCompIsDividends && (
                              <DividendFields
                                primaryLocationState={primaryLocationState}
                                lob={lob}
                                form={form}
                                editMode={editMode}
                              />
                            )}
                            {(!isWorkComp ||
                              (isWorkComp && !workCompIsDividends)) && (
                              <>
                                <CreditDebitFields
                                  lob={lob}
                                  form={form}
                                  editMode={editMode}
                                />
                                <FormGroup>
                                  <TextArea
                                    id={lob + "Comment"}
                                    name={lob + "Comment"}
                                    label="Please Explain"
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                    onValidate={handleOnValidate}
                                    value={values[lob + "Comment"]}
                                    maxLength={255}
                                    size="md"
                                    required={
                                      Number(values[lob + "Amount"]) !== 0
                                    }
                                  />
                                </FormGroup>
                              </>
                            )}
                          </>
                        )
                      }
                    </div>
                  );
                })}
              <FormGroup align="right">
                <Button
                  className="dark-link"
                  onClick={() => {
                    updateForm({
                      values: initialValues
                    });
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    Object.keys(initialValues).every(
                      x => initialValues[x] === values[x]
                    ) ||
                    quoteIsUpdating ||
                    !!invalidFields.length
                  }
                  type="submit"
                >
                  Save
                </Button>
              </FormGroup>
            </Form>
          </>
        }
        altCloseMethod={false}
        show
        onHide={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default CreditsDebitsModal;
