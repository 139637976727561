import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const policiesReducer = (state = initialState.policiesData, action) => {
  switch (action.type) {
    case types.FETCH_MY_POLICY_SEARCH_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        search_result: [],
        searchKeyword: action.payload,
        policyTermsHistoryError: false,
        hasPolicyTermsHistory: false,
        policyTermsHistory: null
      };
    case types.FETCH_MY_POLICY_SEARCH_SUCCEEDED:
      return {
        ...state,
        search_result: action.payload.policies,
        search_type: action.payload.search_type,
        isLoading: false,
        error: null
      };
    case types.FETCH_MY_POLICY_SEARCH_FAILED:
      return {
        ...state,
        search_result: [],
        isLoading: false,
        error: action.error,
        search_type: action.payload
      };
    case types.SEARCH_KEYWORD_UPDATED:
      return {
        ...state,
        search_result: [],
        searchKeyword: action.payload,
        isReportClaim: false,
        show: true,
        error: null
      };
    case types.SEARCH_KEYWORD_UPDATED_REPORT_CLAIMS:
      return {
        ...state,
        searchKeyword: action.payload,
        isReportClaim: true,
        show: true,
        error: null
      };
    case types.FETCH_MY_POLICY_SEARCH_RETURNED_NODATA:
      return {
        ...state,
        search_result: [],
        search_type: action.payload,
        error: null,
        isLoading: false
      };
    case types.DISMISS_MODAL_WINDOW:
      return {
        ...state,
        show: false,
        search_result: []
      };
    case types.RESET_POLICY_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: "",
        search_result: []
      };
    case types.SET_POLICY_SEARCH_TYPE:
      return {
        ...state,
        search_type: action.payload,
        isLoading: false
      };
    case types.FETCH_POLICY_TERMS_HISTORY_STARTED:
      return {
        ...state,
        isLoadingPolicyTermsHistory: true,
        policyTermsHistoryError: false,
        hasPolicyTermsHistory: false,
        policyTermsHistory: null
      };
    case types.FETCH_POLICY_TERMS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingPolicyTermsHistory: false,
        policyTermsHistoryError: false,
        hasPolicyTermsHistory: true,
        policyTermsHistory: action.payload
      };
    case types.FETCH_POLICY_TERMS_HISTORY_FAILED:
      return {
        ...state,
        isLoadingPolicyTermsHistory: false,
        policyTermsHistoryError: true,
        hasPolicyTermsHistory: false,
        policyTermsHistory: null
      };
    default:
      return state;
  }
};

export default policiesReducer;
