import React from "react";
import cn from "classnames";
import "./CheckboxButton.scss";

const CheckboxButton = ({ selected, invalid, children, ...rest }) => {
  return (
    <button
      type="button"
      className={cn("oq__checkbox-button", {
        selected,
        "oq__checkbox-button__invalid": invalid
      })}
      {...rest}
    >
      {!invalid && (
        <div className="oq__checkbox-button__checkbox">
          <span
            className={cn("oq__checkbox-button__checkbox-icon", {
              "oq__checkbox-button__checkbox-icon-checked": selected === true
            })}
          />
        </div>
      )}
      <div className="oq__checkbox-button__text">{children}</div>
    </button>
  );
};

export default CheckboxButton;
