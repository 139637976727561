export const CMS = {
  DEFAULT_CONTENT_ID: 11779,
  TOP_NAVIGATION_ID: 11310,
  COMMERCIAL_NAVIGATION_ID: 11758,
  CONTRACT_NAVIGATION_ID: 11732
};

export const IGNORES = [
  "and",
  "FAQ's",
  "FAQs",
  "Janitorial/Business",
  "UFG"
];
