import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import AppQuoteStatusForm from "./AppQuoteStatusForm";
import AppQuoteStatusList from "./AppQuoteStatusList";
import { Translations } from "../../../../components/translations";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";

const AppQuoteStatus = ({
  selectedAgencyCode,
  fetchAppQuoteStatusList,
  fetchAppQuoteStatusReport,
  activeUserProducerName,
  activeAgency,
  appQuoteStatusList,
  isLoading,
  error,
  hasAgencyProductionReportsAccess
}) => {
  const [filteredAppQuoteStatusList, setFilteredAppQuoteStatusList] = useState(
    []
  );
  const [agencyCode, setAgencyCode] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [status, setStatus] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (
      fetchAppQuoteStatusList &&
      selectedAgencyCode &&
      hasAgencyProductionReportsAccess
    ) {
      setAgencyCode(selectedAgencyCode);
      setNumberOfDays("360");
      setStatus("");
      setType("");
      fetchAppQuoteStatusList(selectedAgencyCode, "360", "", "");
    }
  }, [
    selectedAgencyCode,
    fetchAppQuoteStatusList,
    hasAgencyProductionReportsAccess
  ]);

  useEffect(() => {
    if (appQuoteStatusList) {
      setFilteredAppQuoteStatusList(appQuoteStatusList);
    }
  }, [appQuoteStatusList]);

  const handleFilter = values => {
    const translations = Translations.reports.tabUnderwriting.appQuoteStatus;
    const newStatus =
      values.status !== "" ? translations.status.options[values.status] : "";
    const date = moment()
      .subtract(values.numberOfDays, "days")
      .format("YYYY-MM-DD");
    const filteredList = appQuoteStatusList.filter(
      i =>
        i.received_date >= date && (newStatus === "" || i.status === newStatus)
    );
    setNumberOfDays(values.numberOfDays);
    setStatus(values.status);
    setFilteredAppQuoteStatusList(filteredList);
  };

  const handleGetPdf = () => {
    const translations = Translations.reports.tabUnderwriting.appQuoteStatus;
    const statusValue = status ? translations.status.options[status] : "Any";
    const request = {
      // We want active agency and not selected agency here because it shows
      // who requested the report.
      active_user_agency_code: activeAgency.agencyCode,
      active_user_agency_name: activeAgency.name,
      active_user_producer_name: activeUserProducerName,
      queried_agency_code: agencyCode,
      number_of_days: parseInt(numberOfDays, 10),
      status: statusValue,
      submission_type: "Any",
      submissions: filteredAppQuoteStatusList.map(_ => {
        return {
          customer: _.customer === undefined ? "" : _.customer,
          quote_number: _.quote_number,
          received_date: _.received_date,
          status: _.status,
          status_date: _.status_date,
          policy_number: _.policy_number
        };
      })
    };
    fetchAppQuoteStatusReport(request);
  };

  return (
    <div>
      <div className="reports-above-form-message">
        <HelpDeskMessage display="questionsOrAssistance" />
      </div>
      {selectedAgencyCode && selectedAgencyCode === "109015" ? (
        <div className="underwriting-error-message">
          {Translations.reports.internal_user}
        </div>
      ) : (
        <>
          {!hasAgencyProductionReportsAccess ? (
            <div className="agency-error-message">
              {Translations.reports.error_message}
            </div>
          ) : (
            <div className="reports-form__container">
              <AppQuoteStatusForm
                getPdf={handleGetPdf}
                filter={handleFilter}
                values={{
                  filteredAppQuoteStatusList,
                  agencyCode,
                  numberOfDays,
                  status,
                  type
                }}
                error={error}
                filteredAppQuoteStatusList={filteredAppQuoteStatusList}
              />
              {isLoading ? (
                <LoadingIndicator />
              ) : error ? (
                Translations.reports.tabUnderwriting.appQuoteStatus.error
              ) : filteredAppQuoteStatusList != null &&
                filteredAppQuoteStatusList.length > 0 ? (
                <AppQuoteStatusList submissions={filteredAppQuoteStatusList} />
              ) : (
                <div />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

AppQuoteStatus.propTypes = {
  selectedAgencyCode: PropTypes.string,
  fetchAppQuoteStatusList: PropTypes.func,
  fetchAppQuoteStatusReport: PropTypes.func,
  activeUserProducerName: PropTypes.string,
  activeAgency: PropTypes.object,
  appQuoteStatusList: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  hasAgencyProductionReportsAccess: PropTypes.bool
};

export default AppQuoteStatus;
