import { useRouteMatch } from "react-router-dom";
import * as routes from "../../constants/routes";

export const useRootRouteMatch = () => {
  return useRouteMatch(routes.AGENT_MANUALS);
};

export const useStateRouteMatch = () => {
  return useRouteMatch(routes.AGENT_MANUALS_STATE);
};

export const useStateProductRouteMatch = () => {
  return useRouteMatch(routes.AGENT_MANUALS_STATE_PRODUCT);
};

export const useStateProductSubheaderRouteMatch = () => {
  return useRouteMatch(routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER);
};

export const useStateProductSubheaderSubheader2RouteMatch = () => {
  return useRouteMatch(routes.AGENT_MANUALS_STATE_PRODUCT_SUBHEADER_SUBHEADER2);
};

export const useCurrentRouteMatch = () => {
  const rootRouteMatch = useRootRouteMatch();
  const stateRouteMatch = useStateRouteMatch();
  const stateProductRouteMatch = useStateProductRouteMatch();
  const stateProductSubheaderRouteMatch = useStateProductSubheaderRouteMatch();
  const stateProductSubheaderSubheader2RouteMatch = useStateProductSubheaderSubheader2RouteMatch();
  const currentMatch = (stateProductSubheaderSubheader2RouteMatch?.isExact && stateProductSubheaderSubheader2RouteMatch) ||
    (stateProductSubheaderRouteMatch?.isExact && stateProductSubheaderRouteMatch) ||
    (stateProductRouteMatch?.isExact && stateProductRouteMatch) ||
    (stateRouteMatch?.isExact && stateRouteMatch) ||
    (rootRouteMatch?.isExact && rootRouteMatch);
  return currentMatch;
};
