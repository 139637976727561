import React, { useContext } from "react";
import QuotingUfgHelperContext from "../QuotingUfgHelperContext";
import { sortByProperty } from "../../../online-quoting/shared/util";
import QuoteCard from "./QuoteCard";

import "./StoreQuotesLocally.scss";

const StoreQuotesLocallyModal = () => {
  const { recentQuotes } = useContext(QuotingUfgHelperContext);

  return (
    <div className="oq__dev__load">
      {recentQuotes.sort(sortByProperty("timestamp", false)).map(q => (
        <QuoteCard key={q?.quoteId} quote={q} type="recent" />
      ))}
    </div>
  );
};

export default StoreQuotesLocallyModal;
