import React, { useContext, useState } from "react";
import { Button, Panel } from "@ufginsurance/ui-kit";
import _remove from "lodash/remove";
import { sortByProperty } from "../../shared/util";
import DriverCardContents from "./DriverCardContents";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import ConfirmationModal from "../../shared/ConfirmationModal";
import DriverFormStep from "./DriverFormStep";
import MissingItemPanel from "../../shared/MissingItemPanel";
import "../../Step5RiskInfo.scss";

const DriverCards = ({ drivers }) => {
  const { updateCoverablesPromise, toastErrr } =
    useContext(OnlineQuotingContext);

  const [showItemForm, setShowItemForm] = useState(false);
  const [activeDriver, setActiveDriver] = useState();
  const [itemToDelete, setItemToDelete] = useState();

  const onDeleteDriver = () => {
    const id = itemToDelete.id;
    const newDriversArray = [...drivers];
    _remove(newDriversArray, d => d.id === id);

    updateCoverablesPromise({
      coverableType: "driver",
      coverables: { drivers: newDriversArray },
      action: "Deleting"
    })
      .catch(({ error }) =>
        toastErrr({
          action: "onDeleteDriver",
          misc: { driverId: id },
          description: "Failed to delete driver",
          error,
          displayMessage: "Failed to remove driver."
        })
      )
      .finally(() => setItemToDelete(null));
  };

  const deleteActionCancelled = () => setItemToDelete(null);

  const openItemEdit = driver => {
    setActiveDriver(driver);
    setShowItemForm(true);
  };

  const onHideModal = () => {
    setShowItemForm(false);
    setActiveDriver(null);
  };

  return (
    <div>
      {itemToDelete && (
        <ConfirmationModal
          title="Delete confirmation"
          className="oq__coverable__delete-driver"
          warningMessage={
            <>
              <div>
                Are you sure you want to remove driver
                <br />
                {`${itemToDelete.firstName} ${itemToDelete.lastName}`}?
              </div>
            </>
          }
          onClickYes={onDeleteDriver}
          onClickNo={deleteActionCancelled}
          show
        />
      )}
      {showItemForm && (
        <DriverFormStep
          drivers={drivers}
          itemData={activeDriver}
          show={showItemForm}
          onHide={onHideModal}
        />
      )}
      <Panel
        id="oq__coverable__container__driver"
        rounded
        titlebar
        bgcolor="grey"
        className="oq__coverable__container oq__coverable__container__driver"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Drivers ({drivers.length})
            </span>
          </div>
        }
      >
        <div className="oq__coverable__card__driver__wrapper">
          {drivers.sort(sortByProperty("id")).map(driver => (
            <Panel
              key={`container__driver__${driver?.fixedId}`}
              id={`container__driver__${driver?.fixedId}`}
              isOpen
              rounded
              collapsible={false}
              className="oq__coverable__card oq__coverable__card__driver"
              title={`${driver.firstName} ${driver.lastName} `}
            >
              <DriverCardContents key={drivers.id} driver={driver} />
              <div className="oq__coverable__card__actions">
                <Button
                  wrapperClassName="oq__coverable__card__edit"
                  className="oq__coverable__card__edit-button"
                  onClick={() => {
                    openItemEdit(driver);
                  }}
                  inline
                  isLink
                >
                  Edit Driver
                </Button>
                <Button
                  wrapperClassName="oq__coverable__card__delete"
                  isLink
                  inline
                  variant="tertiary"
                  onClick={() => setItemToDelete(driver)}
                >
                  Remove Driver
                </Button>
              </div>
            </Panel>
          ))}
        </div>
        {drivers.length === 0 && (
          <MissingItemPanel
            onClickHandler={() => openItemEdit(null)}
            content="A driver is required for Commercial Auto"
            buttonText="Add Driver"
          />
        )}

        {drivers.length > 0 && (
          <Button
            block
            variant="outline"
            wrapperClassName="oq__coverable__add-button-wrapper"
            className="oq__coverable__card__add__button"
            icon="farPlus"
            onClick={() => {
              openItemEdit(null);
            }}
          >
            Add Driver
          </Button>
        )}
      </Panel>
    </div>
  );
};

export default DriverCards;
