import { createSlice } from "@reduxjs/toolkit";
import * as agencyBillEpayService from "../services/agencyBillEpayService";

export const agencyStatementOptionsSlice = createSlice({
  name: "agencyStatementOptions",
  initialState: {
    getGuidewireAgencyStatementOptionsLoading: false,
    validStatementOptions: null,
    getGuidewireAgencyStatementOptionsError: false,
    getGuidewireAgencyStatementOptionsException: false
  },
  reducers: {
    started: state => {
      state.getGuidewireAgencyStatementOptionsLoading = true;
      state.validStatementOptions = null;
      state.getGuidewireAgencyStatementOptionsError = false;
      state.getGuidewireAgencyStatementOptionsException = false;
    },
    succeeded: (state, action) => {
      state.getGuidewireAgencyStatementOptionsLoading = false;
      state.validStatementOptions = action.payload;
      state.getGuidewireAgencyStatementOptionsError = false;
      state.getGuidewireAgencyStatementOptionsException = false;
    },
    failed: state => {
      state.getGuidewireAgencyStatementOptionsLoading = false;
      state.validStatementOptions = null;
      state.getGuidewireAgencyStatementOptionsError = true;
      state.getGuidewireAgencyStatementOptionsException = false;
    },
    exception: state => {
      state.getGuidewireAgencyStatementOptionsLoading = false;
      state.validStatementOptions = null;
      state.getGuidewireAgencyStatementOptionsError = true;
      state.getGuidewireAgencyStatementOptionsException = true;
    }
  }
});

const { started, succeeded, failed, exception } =
  agencyStatementOptionsSlice.actions;

export const getGuidewireAgencyStatementOptions =
  (agency_code, statementOptions) => dispatch => {
    dispatch(started());
    return agencyBillEpayService
      .getGuidewireAgencyStatementOptions(agency_code, statementOptions)
      .then(
        data => {
          dispatch(succeeded(data));
        },
        isException => {
          if (isException) {
            dispatch(exception());
          } else {
            dispatch(failed());
          }
        }
      );
  };

export default agencyStatementOptionsSlice.reducer;
