import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Anchor } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import PaymentsPastDueTable from "./PaymentsPastDueTable";
import PaymentsDueSoonTable from "./PaymentsDueSoonTable";
import { Translations } from "../../../components/translations";
import * as Routes from "../../../constants/routes";
import useGetAgencyInfo from "../../../shared/hooks/useGetAgencyInfo";

const BILLING_TYPES = {
  directBill: "direct_bill_only",
  agencyBill: "agency_bill_only",
  both: "both_agency_direct_bill"
};

const BillingTab = ({
  selectedAgencyCode,
  fetchBillingPastDue,
  billingPastDueList,
  pastDueLoading,
  dueSoonLoading,
  pastDueError,
  fetchBillingDueSoon,
  billingDueSoonList,
  dueSoonError,
  pastDueFetchedAgencyCode,
  dueSoonFetchedAgencyCode
}) => {
  const [isPastDueOpen, setIsPastDueOpen] = useState(true);
  const [isDueSoonOpen, setIsDueSoonOpen] = useState(false);
  const [billingType, setBillingType] = useState("");

  useGetAgencyInfo(selectedAgencyCode, async agencyData => {
    const billingType = agencyData?.billing_type;
    if (billingType) {
      setBillingType(billingType);
    }
  });

  useEffect(() => {
    if (selectedAgencyCode !== "109015") {
      if (pastDueFetchedAgencyCode !== selectedAgencyCode && isPastDueOpen) {
        fetchBillingPastDue(selectedAgencyCode);
      }
      if (dueSoonFetchedAgencyCode !== selectedAgencyCode && isDueSoonOpen) {
        fetchBillingDueSoon(selectedAgencyCode);
      }
    }
  }, [
    fetchBillingDueSoon,
    fetchBillingPastDue,
    selectedAgencyCode,
    pastDueFetchedAgencyCode,
    dueSoonFetchedAgencyCode,
    isPastDueOpen,
    isDueSoonOpen
  ]);

  const handlePastDueClick = e => {
    if (pastDueError && e.open) {
      fetchBillingPastDue(selectedAgencyCode);
    }
    setIsPastDueOpen(e.open);
  };

  const handleDueSoonClick = e => {
    if (dueSoonError && e.open) {
      fetchBillingDueSoon(selectedAgencyCode);
    }
    setIsDueSoonOpen(e.open);
  };

  return (
    <div id="home-billing-container">
      <Accordion
        isOpen
        onClick={handlePastDueClick}
        textMain={Translations.home_billing_tab.textMainPaymentsPastDue}
        textExtra={Translations.home_billing_tab.textExtra}
      >
        {[BILLING_TYPES.directBill, BILLING_TYPES.both].includes(
          billingType
        ) && (
          <section id="direct-bill">
            <div className="direct-bill-heading">Direct Bill</div>
            <PaymentsPastDueTable
              billingPastDueList={billingPastDueList}
              selectedAgencyCode={selectedAgencyCode}
              isLoading={pastDueLoading}
              error={pastDueError}
            />
          </section>
        )}

        {[BILLING_TYPES.agencyBill, BILLING_TYPES.both].includes(
          billingType
        ) && (
          <section id="agency-bill">
            <div className="agency-bill-heading">Agency Bill</div>
            <Anchor
              as={Link}
              to={`/reports/accounting/${Routes.REPORTS_AGENCY_BILL_PAST_DUE}`}
            >
              View Agency Bill Past Due report
            </Anchor>
          </section>
        )}
      </Accordion>
      <Accordion
        onClick={handleDueSoonClick}
        className="payment-due-soon-accordion"
        textMain={Translations.home_billing_tab.textMainPaymentsDueSoon}
        textExtra={Translations.home_billing_tab.textExtraifExpired}
      >
        <PaymentsDueSoonTable
          billingDueSoonList={billingDueSoonList}
          selectedAgencyCode={selectedAgencyCode}
          isLoading={dueSoonLoading}
          error={dueSoonError}
        />
      </Accordion>
    </div>
  );
};

BillingTab.propTypes = {
  fetchBillingPastDue: PropTypes.func.isRequired,
  billingPastDueList: PropTypes.array,
  billingDueSoonList: PropTypes.array,
  pastDueLoading: PropTypes.bool,
  dueSoonLoading: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  fetchBillingDueSoon: PropTypes.func.isRequired,
  pastDueError: PropTypes.any,
  dueSoonError: PropTypes.any,
  pastDueFetchedAgencyCode: PropTypes.string,
  dueSoonFetchedAgencyCode: PropTypes.string
};

export default BillingTab;
