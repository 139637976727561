import { OTHER_CHANGES_MODAL_ACTIVATED, OTHER_CHANGES_MODAL_DISMISS } from "./otherChangesModalActions";
import initialState from "../../reducers/initialState";

const otherChangesModalReducer = (state = initialState.otherChangesModalData, action) => {
  switch (action.type) {
    case OTHER_CHANGES_MODAL_ACTIVATED:
      return {
        ...state,
        isActivated: true
      };
    case OTHER_CHANGES_MODAL_DISMISS:
      return {
        ...state,
        isActivated: false
      };
    default:
      return state;
  }
};

export default otherChangesModalReducer;
