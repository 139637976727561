import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import CMSLayout from "../../../cms-content/CMSLayout";
import AgencyInterfaceChecklist from "./AgencyInterfaceChecklist";
import { useCMSContext } from "../../../cms-content/context";
import { addChecklistTrigger } from "../util";
import * as routes from "../../../constants/routes";
import * as agencyInterfaceServices from "../services/AgencyInterfaceServices";

const AgencyInterface = ({
  firstAndLastName,
  agentEmail,
  activeAgencyName,
  agencyCode,
  hasServiceCenterAccess
}) => {
  const {
    cmsData,
    updateAgencyInterfaceChecklistShow,
    fetchAgencyInterfaceContent,
    updateCMSHeaderRoute,
    headerRoute,
    fetchTableOfContents
  } = useCMSContext();

  const openChecklistModal = useCallback(() => {
    updateAgencyInterfaceChecklistShow(true);
  }, [updateAgencyInterfaceChecklistShow]);

  useEffect(() => {
    if (cmsData?.tableOfContents?.data && cmsData?.content?.data)
      addChecklistTrigger(openChecklistModal);
  }, [cmsData, openChecklistModal]);

  useEffect(() => {
    if (headerRoute !== routes.AGENCY_INTERFACE_HEADER) {
      updateCMSHeaderRoute(routes.AGENCY_INTERFACE_HEADER);
    }
  }, [updateCMSHeaderRoute, headerRoute]);

  return (
    <>
      <CMSLayout
        baseRoute={routes.AGENCY_INTERFACE}
        fetchContent={fetchAgencyInterfaceContent}
        defaultBreadcrumbText="Agency Interface"
        service={agencyInterfaceServices}
        fetchTableOfContents={fetchTableOfContents}
      >
        <AgencyInterfaceChecklist
          firstAndLastName={firstAndLastName}
          agentEmail={agentEmail}
          activeAgencyName={activeAgencyName}
          agencyCode={agencyCode}
          hasServiceCenterAccess={hasServiceCenterAccess}
        />
      </CMSLayout>
    </>
  );
};

const mapStateToProps = state => ({
  firstAndLastName: sessionSelector.getFirstAndLastName(state),
  agentEmail: sessionSelector.getEmail(state),
  activeAgencyName: sessionSelector.getActiveAgencyName(state),
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  hasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state)
});

export default compose(connect(mapStateToProps))(AgencyInterface);
