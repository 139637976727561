import React from "react";
import { headerLogo } from "../../src/constants/images";
import { Link } from "react-router-dom";
import { Icon } from "@ufginsurance/ui-kit";

const UWHeaderTop = () => {
  return (
    <div className="headerTop__navbar-container">
      <button
        type="button"
        className="headerTop__navbar-button hidden-lg"
        data-toggle="headerTop__collapse"
        data-target="#navbar"
        aria-expanded="false"
        aria-controls="navbar"
        data-icon="bars"
      >
        <Icon icon="fasBars" className="headerTop__fa-bars" />
      </button>
      <div className="row">
        <div className="col-sm-6 headerTop__col-no-left-padding">
          <Link to="/" className="headerTop__logo">
            <img src={headerLogo} alt="UFG Insurance Logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UWHeaderTop;
