import { connect } from "react-redux";
import WeeklyPayment from "../components/claims/weekly-payments/WeeklyPayment";
import {
  fetchWeeklyPaymentListDetails,
  fetchWeeklyPaymentViewReport
} from "../actions/weeklyPaymentAction";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  isLoading: state.weeklyPaymentListReportData.isLoading,
  weeklyPaymentList: state.weeklyPaymentListReportData.weeklyPaymentList,
  error: state.weeklyPaymentListReportData.error
});

const mapDispatchToProps = {
  fetchWeeklyPaymentListDetails,
  fetchWeeklyPaymentViewReport
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyPayment);
