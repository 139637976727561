import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DATE_FORMAT } from "../services/claimsUtil.js";
import moment from "moment";
import { Translations } from "../../components/translations";
import { toTitleCase } from "../../components/Factory";
import {
  Currency,
  Button,
  FlexRow,
  Modal,
  Alert,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import {
  createM4PrintPaymentDetailsRequest
} from "../../selectors/claimsSelectors";

const PaymentDetail = ({
  fetchClaimsPaymentDetails,
  claimNumber,
  paymentNumber,
  selectedAgencyCode,
  paymentDetails,
  policyNumber,
  insuredName,
  printClaimsPaymentDetails,
  claimPaymentDetailsLoading,
  printPaymentDetails,
  show,
  handleClose
}) => {

  useEffect(() => {
    fetchClaimsPaymentDetails(
      claimNumber,
      paymentNumber,
      selectedAgencyCode
    );
  }, [
    fetchClaimsPaymentDetails,
    claimNumber,
    paymentNumber,
    selectedAgencyCode
  ]);

  const handlePrint = () => {
    const printClaimsPaymentDetailsRequest = createM4PrintPaymentDetailsRequest(
          paymentDetails,
          paymentNumber,
          claimNumber,
          policyNumber,
          insuredName
        )
    printClaimsPaymentDetails(
      printClaimsPaymentDetailsRequest,
      claimNumber,
      paymentNumber
    );
  };

  return (
    <div className="payment-details-container">
      <Modal
        title="Payment Information"
        show={show}
        onHide={handleClose}
        body={
          <div>
            {claimPaymentDetailsLoading && (
              <div className="row payment-details-section">
                <LoadingIndicator />
              </div>
            )}
            {paymentDetails && (
              <div key={paymentNumber}>
                <div className="payment-details-section">
                  <div className="row payment-detail-header-row">
                    <div className="col-md-6">
                      {Translations.Claims.claims_detail.insured}
                    </div>
                    <div className="col-md-3">
                      {Translations.Claims.claims_detail.policy_number}
                    </div>
                    <div className="col-md-3">
                      {Translations.Claims.claims_detail.claim_number}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">{insuredName}</div>
                    <div className="col-md-3">{policyNumber}</div>
                    <div className="col-md-3">{claimNumber}</div>
                  </div>
                </div>
                <div className="payment-details-section">
                  <div className="row payment-detail-header-row">
                    <div className="col-md-12">
                      {Translations.Claims.claims_detail.payment_detail}:{" "}
                      {paymentNumber}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {paymentDetails.payment_detail.map(detail => (
                        <span key={detail}>
                          {toTitleCase(detail)} <br />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="payment-details-section">
                  <div className="row payment-detail-header-row">
                    <div className="col-md-6">
                      {Translations.Claims.claims_detail.claimant_name}
                    </div>
                    <div className="col-md-3">
                      {Translations.Claims.claims_detail.cover}
                    </div>
                    <div className="col-md-3">
                      {Translations.Claims.claims_detail.amount}
                    </div>
                  </div>

                  {paymentDetails.claimants &&
                    paymentDetails.claimants.length > 0 && (
                      <div className="payment-details-section">
                        {paymentDetails.claimants.map(claimant => (
                          <div className="row" key={claimant.id}>
                            <div className="col-md-6">
                              {toTitleCase(claimant.claimant_name)}
                            </div>
                            <div className="col-md-3">{claimant.cover}</div>
                            <div className="col-md-3">
                              <Currency amount={claimant.claimant_amount} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                <div className="payment-details-section">
                  <div className="row payment-detail-header-row">
                    <div className="col-md-6">
                      {Translations.Claims.claims_detail.sent_to}
                    </div>
                    <div className="col-md-2">
                      {Translations.Claims.claims_detail.issued_on}
                    </div>
                    <div className="col-md-2">
                      {Translations.Claims.claims_detail.amount}
                    </div>
                    <div className="col-md-2">
                      {Translations.Claims.claims_detail.status}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {toTitleCase(paymentDetails.name_payment_sent_to)}
                      <br />
                      {paymentDetails.payment_address_1_sent_to && (
                        <span>
                          {toTitleCase(
                            paymentDetails.payment_address_1_sent_to
                          )}
                          <br />
                        </span>
                      )}
                      {paymentDetails.payment_address_2_sent_to && (
                        <span>
                          {toTitleCase(
                            paymentDetails.payment_address_2_sent_to
                          )}{" "}
                          <br />
                        </span>
                      )}
                      {toTitleCase(
                        paymentDetails.payment_city_state_zip_sent_to
                      )}
                    </div>
                    <div className="col-md-2">
                      {moment(paymentDetails.date_check_issued_on).format(
                        DATE_FORMAT
                      )}
                    </div>
                    <div className="col-md-2">
                      <Currency amount={paymentDetails.total_amount_paid} />
                    </div>
                    <div className="col-md-2">
                      {paymentDetails.check_status &&
                        toTitleCase(paymentDetails.check_status)}
                      {paymentDetails.date_status && (
                        <span>
                          <br />
                          {moment(paymentDetails.date_status).format(
                            DATE_FORMAT
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {printPaymentDetails.error && (
                  <div>
                    <Alert type="error">
                      Payment Information print request failed.
                    </Alert>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button variant="secondary" onClick={handleClose}>
              {Translations.Claims.claims_detail.close}
            </Button>
            <Button
              onClick={handlePrint}
              variant="primary"
              disabled={
                printPaymentDetails.isLoading || claimPaymentDetailsLoading
              }
              spinner={printPaymentDetails.isLoading}
              spinnerPos="right"
            >
              {Translations.Claims.claims_detail.print}
            </Button>
          </FlexRow>
        }
      />
    </div>
  );
};

PaymentDetail.propTypes = {
  fetchClaimsPaymentDetails: PropTypes.func.isRequired,
  paymentDetails: PropTypes.object,
  claimNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  paymentNumber: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  insuredName: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  selectedAgencyCode: PropTypes.string.isRequired,
  printClaimsPaymentDetails: PropTypes.func.isRequired,
  printPaymentDetails: PropTypes.object.isRequired,
  claimPaymentDetailsLoading: PropTypes.bool.isRequired
};

export default PaymentDetail;
