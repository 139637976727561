import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, ContentHeader, Panel } from "@ufginsurance/ui-kit";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SuretyHomeProvider } from "./SuretyHomeProvider";
import Tiles from "./components/Tiles";
import AccountBondSearch from "./components/AccountBondSearch";
import LegacyAccountBondSearch from "./components/LegacyAccountBondSearch";
import AgencyActivity from "./components/AgencyActivity";
import DocumentUploadModals from "../components/document-upload/DocumentUploadModals";
import "./SuretyHome.scss";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { useSuretyContext } from "../surety-context/SuretyContext";

function SuretyHome(props) {
  const { updateBondOverview } = useSuretyContext();
  const [showModal, setShowModal] = useState(false);
  const { history, match } = props;
  const {
    suretyDocumentUploadEnabled,
    suretyNewLandingAccountBondSearchEnabled,
    suretyAgencyActivityEnabled
  } = useFlags();

  // If searching for bond via url, set the bond number to open BO modal.
  useEffect(() => {
    if (match?.params?.bond_number) {
      updateBondOverview({
        bond_number: match.params.bond_number,
        showModal: true
      });
    }
  }, [match, updateBondOverview]);

  return (
    <SuretyHomeProvider {...props}>
      <ContentHeader>Surety</ContentHeader>

      <Tiles />

      <div className="search-row">
        {suretyNewLandingAccountBondSearchEnabled ? (
          <AccountBondSearch history={history} />
        ) : (
          <LegacyAccountBondSearch />
        )}

        {suretyDocumentUploadEnabled && (
          <div className="document-upload--wrapper">
            <Panel rounded bgcolor="grey" title="Document Upload" titlebar>
              <p className="panel-text">
                Send documents directly to your Surety underwriting team.
              </p>
              <Button
                variant="primary"
                block
                onClick={() => setShowModal(true)}
              >
                Upload Documents
              </Button>
            </Panel>
            <DocumentUploadModals
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
        )}
      </div>
      {suretyAgencyActivityEnabled && <AgencyActivity />}
    </SuretyHomeProvider>
  );
}

const mapStateToProps = state => ({
  agencyCode: getSelectedAgencyCode(state)
});

export default connect(mapStateToProps)(SuretyHome);
