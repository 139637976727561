import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  FlexRow,
  FormGroup,
  LoadingIndicator,
  RadioButtonContainer,
  RadioButton,
  Form,
  useForm,
  Panel,
  Modal
} from "@ufginsurance/ui-kit";

import * as addressService from "../../../services/addressService";

import "./VerifyAddresses.scss";

/**
 * 
 * PROPS:
 * 
    addressesToVerify,
    onContinue,
    onCancel,
    autoContinueOnExactMatches


    ------------------
    addressesToVerify
    ------------------

    An object of dates, based on the top-level key for each address.
    We chose to use an object here (instead of an array) because it's typically expected to be 
    used with forms which would have very specific keys and values.

    {
    "physical": {   // refered to using [type] key
        "address1": "888 2nd",
        "city": "Eagan",
        "state": "MN",
        "zip": "55123",
        "county": "Dakota",
        "label": "Physical Business"
    },
    "mailing_ufg": {
        "address1": "555 2nd",
        "city": "Eagan",
        "state": "MN",
        "zip": "55123",
        "county": "Dakota",
        "label": "Mailing"
    }
  }


    ------------------
    onContinue 
    ------------------

    A callback function that is triggered when the user hit's Continue button.

    The response includes quite a bit of data.  The primary shape looks like this:

    1. If the user selects a suggested address, the response looks like this:

    {
      [type] :{
        label: <JSX> used in the form
        value: "string" // typically: "888 2ND ST NW,SAINT PAUL,MN,55112-7343"
        addressFields:  // the common data from the api that aligns with the address selected
          {
            "address1": "888 2ND ST NW",
            "city": "SAINT PAUL",
            "state": "MN",
            "zip": "55112-7343",
            "county": "Ramsey County",
            "county_code": "123"
          }
        rawVerificationData:
          {
            // a very large object of all of the data associated to the selected address
            // that comes from the Address API call
            // this is useful in case the parent component needs more than the 
            // common data in the above object
          }
        ogfields: // data that was originally sent to verify
          {
            "address1": "888 2nd",
            "city": "Eagan",
            "state": "MN",
            "zip": "55123",
            "county": "Dakota",
            "label": "Physical Business"
          }
        isOriginal: boolean // is true if user selected original address (not a suggested one)
      }
    }

    2. If the user selects the original address, the response includes like this:


    ------------------
    onCancel 
    ------------------

    A callback function that is triggered when the user hit's Cancel button.


    ------------------------------
    autoContinueOnExactMatches
    ------------------------------

    If all addresses have an exact match, then automatically trigger the onContinue method so the user doesn't have to

    default value is true

 */

const VerifyAddressesModal = ({
  addressesToVerify,
  onContinue,
  onCancel,
  autoContinueOnExactMatches = true
}) => {
  const [processedAddresses, setProcessedAddresses] = useState(-1); // -1 state for loading
  const [allAddressesExact, setAllAddressesExact] = useState(null);
  const [originals, setOriginals] = useState({});

  // initial values for the fields for the useForm hook
  const initialValues = Object.keys(addressesToVerify).reduce((acc, curr) => {
    acc[curr] = "";
    return acc;
  }, {});

  // initialize the hook
  const addressForm = useForm({ values: initialValues, onSubmi: () => {} });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = addressForm;

  // on component load, check the addresses using the address service and set up the data for the UI

  useEffect(() => {
    if (processedAddresses === -1) {
      setProcessedAddresses(null); // sets state to remove loading (-1) value

      const addressesToCheck = [];

      // run the address validation for all addresses in the quote data
      const types = Object.keys(addressesToVerify);

      const _originals = {};
      /**
       * PREPARE ALL ADDRESSES FOR SENDING TO API
       */
      types.forEach(type => {
        const og = { ogfields: addressesToVerify[type] };
        const { address1, city, state, zip } = addressesToVerify[type];

        if (!!address1) {
          addressesToCheck.push(
            addressService
              .verifyAddressV2(address1, city, state, zip)
              .then(response => ({ type, data: response?.data, og }))
              .catch(error => {
                console.log(error);
                return { type, error, og, data: {} };
              })
          );
        }
      });

      /**
       * PROMISE ALL ADDRESS CHECKS
       */
      Promise.all(addressesToCheck).then(results => {
        const defaultValues = {};
        const processed = {};

        let exactMatch = true;

        results.forEach(({ type, data, og }, i) => {
          const defaultItem = data?.[0];

          processed[type] = {};
          _originals[type] = og;

          /**
           *
           * set up original data in case user selects the original address
           *
           */

          // only do for the first record in the response
          if (i === 0) {
            const {
              address_line: address1,
              city,
              state,
              zip,
              county_name: county,
              fips_county_number: county_code
            } = defaultItem?.address_output || {};

            // could not validate the address...
            _originals[type].rawVerificationData = defaultItem;
            _originals[type].addressFields = {
              address1,
              city,
              state,
              zip,
              county,
              county_code
            };
            _originals[type].isOriginal = true;
            _originals[type].confidence = 0;
          }

          /**
           * CAN'T BE VERIFIED? Just stop and show the original.
           *
           * if the address cannot be validated...
           * just stop and show the user the bad news
           */
          if (
            !defaultItem ||
            defaultItem?.address_output?.match_code_description.includes(
              "cannot be validated"
            )
          ) {
            defaultValues[type] = "original";
            exactMatch = false;
            return;
          }

          /**
           * CONFIDENCE : EXACT MATCH?
           *
           * if first result is high-confidence, then we have exact match
           */
          const confidence = !!defaultItem?.address_output?.confidence
            ? Number(defaultItem?.address_output.confidence)
            : 0;

          //set if the address is exact match - used to see if all addresses are exact match
          if (confidence < 100) exactMatch = false;

          /**
           * ORGANIZE ADDRESS API RESPONSES TO SHOW TO USER
           *
           * Start aligning address response data to options to use for the UI
           */
          processed[type].options = data.reduce((acc, a) => {
            if (!a.address_output) return acc;

            const {
              address_line: address1,
              city,
              state,
              zip,
              county_name: county,
              fips_county_number: county_code,
              confidence
            } = a.address_output;

            return [
              ...acc,
              {
                label: (
                  <div className="oq__verify-address__drop-down__option">
                    <div className="oq__verify-address__drop-down__option__address-line">
                      {address1}
                    </div>
                    <div className="oq__verify-address__drop-down__option__city-line">
                      {city}, {state} {zip}
                    </div>
                  </div>
                ),
                value: `${address1},${city},${state},${zip}`,
                rawVerificationData: a,
                confidence: Number(confidence) || 0,
                addressFields: {
                  address1,
                  city,
                  state,
                  zip,
                  county,
                  county_code
                },
                ogfields: addressesToVerify[type]
              }
            ];
          }, []);

          defaultValues[type] = processed[type]?.options?.[0]?.value || "";
        });

        // update values with the first selection from service (or use original if no matches found)
        updateForm({ values: { ...defaultValues } });

        setProcessedAddresses(processed);
        setAllAddressesExact(exactMatch);
        setOriginals(_originals);
      });
    }
  }, [addressesToVerify, processedAddresses, updateForm]);

  const handleContinue = useCallback(() => {
    const selectedAddresses = Object.keys(values).reduce((acc, type) => {
      if (values[type] === "original") acc[type] = originals[type];
      else
        acc[type] = {
          ...(processedAddresses[type]?.options?.find(
            a => a.value === values[type]
          ) || {})
        };
      return acc;
    }, {});

    onContinue(selectedAddresses);
  }, [onContinue, originals, processedAddresses, values]);

  // Automatically continue if addresses are all valid...
  // check the allAddressesExact state in a useEffect because the handleContinue funciton is reading
  // data from the processedAddresses state which is null until after the Promise in the loading useEffect
  useEffect(() => {
    //if all address is exact match, move to next step- Bop prefill
    if (autoContinueOnExactMatches && allAddressesExact) {
      setTimeout(() => {
        handleContinue();
      }, 1500);
    }
    // only run when allAddressesExact is updated... including handleContinue causes this to retrigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAddressesExact]);

  return (
    <Modal
      title="Verify Addresses"
      className="shared__address-validate__modal"
      closeIcon={false}
      size="lg"
      show
      altCloseMethod={false} // prevents Escape key or clicking outside modal to close
      body={
        <div className="shared__address-validate__container">
          {(allAddressesExact === null || allAddressesExact === true) && (
            <LoadingIndicator message="Verifying addresses..." />
          )}
          {!!processedAddresses && !allAddressesExact && (
            <>
              <Form
                context={addressForm}
                className="shared__address-validate__address-form"
              >
                {Object.keys(processedAddresses).map(type => {
                  return (
                    <Panel
                      title={`${addressesToVerify[type].label} Address`}
                      key={type}
                    >
                      <FormGroup>
                        <RadioButtonContainer
                          id={type}
                          name={type}
                          onChange={handleOnChange}
                          onBlur={handleOnBlur}
                          onValidate={handleOnValidate}
                          value={values[type]}
                          required
                        >
                          <div className="shared__address-validate__address-wrapper">
                            {originals?.[type]?.ogfields && (
                              <div className="shared__address-validate__address-original">
                                <b>You Entered:</b>
                                <RadioButton
                                  id="original"
                                  label={
                                    <>
                                      <div>
                                        {originals[type]?.ogfields?.address1}
                                      </div>
                                      <div>
                                        {originals[type]?.ogfields?.city},{" "}
                                        {originals[type]?.ogfields?.state}{" "}
                                        {originals[type]?.ogfields?.zip}
                                      </div>
                                    </>
                                  }
                                  optionValue="original"
                                />
                              </div>
                            )}
                            <div className="shared__address-validate__address-selection">
                              <b>Suggested Address:</b>
                              {!processedAddresses[type]?.options ||
                              processedAddresses[type]?.options.length === 0 ? (
                                <div>
                                  <em>Unable to verify address</em>
                                </div>
                              ) : (
                                processedAddresses[type]?.options.map(o => (
                                  <div key={o.value}>
                                    <RadioButton
                                      id={`type_${o.value}`}
                                      label={o.label}
                                      optionValue={o.value || ""}
                                    />
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </RadioButtonContainer>
                      </FormGroup>
                    </Panel>
                  );
                })}
              </Form>
              <FlexRow align="right">
                <Button onClick={onCancel}>Cancel</Button>

                <Button
                  variant="primary"
                  disabled={invalidFields.length > 0}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </FlexRow>
            </>
          )}
        </div>
      }
    />
  );
};

export default VerifyAddressesModal;
