import * as types from "../quickQuotingActionTypes";
import * as quickQuotingServices from "../quickQuotingServices";
import { logger } from "../../../../src/loggers";

/*--------SURETY BONDFORM SECTION---------- */
const fetchSurtyBondFormServiceStarted = () => ({
  type: types.FETCH_SURETY_BONDFORM_STARTED
});

const fetchSurtyBondFormServiceSucceeded = data => ({
  type: types.FETCH_SURETY_BONDFORM_SUCCEEDED,
  payload: data
});

const fetchSurtyBondFormServiceFailed = error => ({
  type: types.FETCH_SURETY_BONDFORM_FAILED,
  payload: error
});

export const fetchSuretyBondForm =
  (bond_type, state, date_effective) => dispatch => {
    dispatch(fetchSurtyBondFormServiceStarted());
    return quickQuotingServices
      .fetchSuretyBondForm(bond_type, state, date_effective)
      .then(
        response => {
          dispatch(
            fetchSurtyBondFormServiceSucceeded(suretyBondOptions(response.data))
          );
          return response;
        },
        error => {
          dispatch(fetchSurtyBondFormServiceFailed(error));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

const suretyBondOptions = data =>
  data.map(data => ({
    ...data,
    vaule: data.form_description,
    label: data.form_description
  }));
