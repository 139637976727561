import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";

const ClassAppetiteGuideFilter = props => {
  const { filterText, handleFilterChange } = props;

  const handleFilterTextChange = event => {
    event.preventDefault();
    const keyword = event.target.value;
    handleFilterChange(keyword);
  };

  return (
    <div className="class-appetite-filter ">
      {/*
        <p>{Translations.commercial.FilterClasses}</p>
        */}
      <div>
        <div className="filter-box">
          <input
            value={filterText}
            type="text"
            className="form-control"
            maxLength="120"
            onChange={handleFilterTextChange}
            onBlur={handleFilterChange}
            onKeyDown={handleFilterChange}
            placeholder={
              filterText.trim() === ""
                ? Translations.general.search_text
                : filterText
            }
          />
        </div>
      </div>
    </div>
  );
};

ClassAppetiteGuideFilter.propTypes = {
  handleFilterChange: PropTypes.func,
  filterText: PropTypes.string
};

export default ClassAppetiteGuideFilter;
