import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import * as V from "./AgencyLossFormValidation";
import {
  FormGroup,
  Form,
  useForm,
  Dropdown,
  DateRange,
  Popover,
  Input,
  Button,
  Icon
} from "@ufginsurance/ui-kit";

const AgencyLossRunForm = props => {
  const renderTrigger = (
    <span className="agency-loss-runs-popover__trigger">
      <Icon icon="fasQuestion" />
    </span>
  );

  const renderOverlay = (
    <div>
      <p>
        {Translations.reports.tabClaims.agencyLossRun.popoverTextOne}{" "}
        {props.administratorName}.
      </p>
      <p>{Translations.reports.tabClaims.agencyLossRun.popoverTextTwo}</p>
    </div>
  );

  const { emailList, is109015Agent, handleSubmit } = props;
  const { dateSearch, LineofBusiness, claimStatus, sortList, reportFormat } =
    Translations.reports.tabClaims.agencyLossRun;

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 365 * 6);

  const initialValues = {
    is109015Agent,
    emailTo: "",
    dateSearchDropdown: "A",
    lineOfBusinessList: "0",
    claimStatusTypes: "0",
    sortListTypes: "1",
    reportFormatTypes: "P",
    incurredAmount: "",
    name: "",
    agencyLossRunsFormRange: { from: "", to: "" }
  };

  const handleSubmission = ({ values }) => {
    const formData = {
      ...values,
      beginDate: values.agencyLossRunsFormRange.from,
      endDate: values.agencyLossRunsFormRange.to
    };
    handleSubmit(formData, () => updateForm({ values: initialValues }));
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  return (
    <div>
      <div className="reports-form__container">
        <div className="reports-form__note">
          {Translations.reports.tabClaims.agencyLossRun.receiveClaimsList}
        </div>

        <Form context={form} className="agency-loss-runs">
          <FormGroup>
            <DateRange
              id="agencyLossRunsFormRange"
              name="agencyLossRunsFormRange"
              labelFrom={
                Translations.reports.tabClaims.agencyLossRun.label.beginDate
              }
              labelTo={
                Translations.reports.tabClaims.agencyLossRun.label.endDate
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.agencyLossRunsFormRange}
              format="MM/DD/YYYY"
              required
              minDate={minDate}
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              id="dateSearchDropdown"
              name="dateSearchDropdown"
              label={
                Translations.reports.tabClaims.agencyLossRun.label.dateSearch
              }
              options={dateSearch}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.dateSearchDropdown}
              size="lg"
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              id="lineOfBusinessList"
              name="lineOfBusinessList"
              label={Translations.reports.tabClaims.agencyLossRun.label.lob}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={field => {
                return V.lineOfBusinessList(field, handleOnValidate, values);
              }}
              value={values.lineOfBusinessList}
              size="lg"
              options={Object.keys(LineofBusiness).map(key => ({
                value: key,
                label: LineofBusiness[key]
              }))}
            />
          </FormGroup>

          <FormGroup>
            <Dropdown
              id="claimStatusTypes"
              name="claimStatusTypes"
              label={
                Translations.reports.tabClaims.agencyLossRun.label.claimStatus
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={field => {
                return V.claimStatusTypes(field, handleOnValidate, values);
              }}
              value={values.claimStatusTypes}
              size="lg"
              options={Object.keys(claimStatus).map(key => ({
                value: key,
                label: claimStatus[key]
              }))}
            />
          </FormGroup>

          <FormGroup>
            <div className="align-with-input">$</div>
            <Input
              id="incurredAmount"
              name="incurredAmount"
              label={
                Translations.reports.tabClaims.agencyLossRun.label.incurred
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={field => {
                return V.incurredAmount(field, handleOnValidate, values);
              }}
              value={values.incurredAmount}
            />
          </FormGroup>

          <FormGroup>
            <Dropdown
              id="sortListTypes"
              name="sortListTypes"
              label={
                Translations.reports.tabClaims.agencyLossRun.label.sortList
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.sortListTypes}
              size="md"
              options={Object.keys(sortList).map(key => ({
                value: key,
                label: sortList[key]
              }))}
              isClearable={false}
            />
          </FormGroup>

          {!is109015Agent ? (
            <FormGroup>
              <Dropdown
                id="emailTo"
                name="emailTo"
                label={
                  Translations.reports.tabClaims.agencyLossRun.label.emailTo
                }
                labelElement={
                  <Popover
                    id="email-to-popover"
                    placement="top"
                    triggerContent={renderTrigger}
                    popoverContent={renderOverlay}
                    trigger="click"
                    maxWidth={400}
                  />
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.emailTo}
                size="lg"
                options={emailList.map(q => ({
                  value: q.email_address,
                  label: q.name + " " + q.email_address
                }))}
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Input
                id="emailTo"
                name="emailTo"
                label={
                  Translations.reports.tabClaims.agencyLossRun.label.emailTo
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={({ field, value, validation }) => {
                  const errors = handleOnValidate({ field, value, validation });
                  // eslint-disable-next-line
                  const emailRegex = /@/;
                  if (!!is109015Agent && emailRegex.test(value)) {
                    errors.push("Do not enter an email address.");
                  }
                  return errors;
                }}
                value={values.emailTo}
                maxLength={30}
                required
              />
              <div className="align-with-input">@unitedfiregroup.com</div>
            </FormGroup>
          )}
          <FormGroup>
            <Dropdown
              id="reportFormatTypes"
              name="reportFormatTypes"
              label={
                Translations.reports.tabClaims.agencyLossRun.label.reportFormat
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.reportFormatTypes}
              size="md"
              options={Object.keys(reportFormat).map(key => ({
                value: key,
                label: reportFormat[key]
              }))}
              isClearable={false}
            />
          </FormGroup>
          {is109015Agent && (
            <FormGroup>
              <Input
                id="name"
                name="name"
                label={Translations.reports.tabClaims.agencyLossRun.label.name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.name}
                maxLength={22}
                required
              />
            </FormGroup>
          )}

          <FormGroup className="pad-top">
            <Button type="submit" variant="primary">
              {Translations.reports.tabClaims.agencyLossRun.label.getReport}
            </Button>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

AgencyLossRunForm.propTypes = {
  emailList: PropTypes.array,
  is109015Agent: PropTypes.bool,
  handleSubmit: PropTypes.func,
  administratorName: PropTypes.string
};

export default AgencyLossRunForm;
