import React from "react";
import { Popover, Icon } from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";

const Trigger = (
  <span>
    <Icon icon="fasInfoCircle" />
  </span>
);

const CustomerIdPopover = ({ content }) => {
  const Overlay = (
    <div>
      <p>{content}</p>
    </div>
  );
  return (
    <span className="helpText">
      <Popover
        id="customer-info-popover"
        placement="right"
        popoverClass="customer-info-popover__overlay"
        triggerContent={Trigger}
        popoverContent={Overlay}
        trigger="click"
      />
    </span>
  );
};

export default CustomerIdPopover;

CustomerIdPopover.defaultProps = {
  content: Translations.start_new_quote.customer_modal.customer_i
};
