import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "./../components/translations";
import { ActionCardContainer, ActionCard } from "@ufginsurance/ui-kit";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as routes from "../constants/routes";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { appetiteGuideLink } from "../online-quoting/shared/constants";
import { clearLocalQuoteManually } from "../online-quoting/shared/util";
import { toggleProductSelectionModal } from "../commercial/shared/ProductSelection/ProductSelectionModal";
import PreQuoteModal from "./../online-quoting/step1/PreQuoteModal";

const TopActionCard = ({
  history,
  activateMakeAPayment,
  startReportAClaim,
  isSpecialtyPortalView,
  is109015Agent,
  acceptsCommercialLine,
  toggleProductSelectionModal
}) => {
  const { proQuoteQuoteFromAppetiteGuide, buildersRiskEnabled } = useFlags();
  const [showPreQuoteModal, setShowPreQuoteModal] = useState(false);

  const openStartAQuote = () => {
    if (buildersRiskEnabled) {
      toggleProductSelectionModal();
    } else {
      clearLocalQuoteManually();
      history.push(routes.ONLINE_QUOTING_STEP1);
    }
  };

  return (
    !isSpecialtyPortalView && (
      <ActionCardContainer className="ActionCardWrapper">
        <ActionCard
          className="card-green"
          title={Translations.home.make_a_payment}
          onClick={activateMakeAPayment}
          icon="farCreditCard"
          color="green"
        />
        {showPreQuoteModal && (
          <PreQuoteModal
            history={history}
            onContinue={openStartAQuote}
            showPreQuoteModal={showPreQuoteModal}
            setShowPreQuoteModal={setShowPreQuoteModal}
          />
        )}
        {acceptsCommercialLine && (
          <ActionCard
            className="card-light-blue"
            title="Start a Quote"
            icon="farPlusCircle"
            color="lt-blue"
            onClick={() => {
              setShowPreQuoteModal(true);
            }}
          />
        )}
        {acceptsCommercialLine && proQuoteQuoteFromAppetiteGuide && (
          <ActionCard
            className="card-purple"
            title="Small Business Appetite"
            onClick={() => {
              if (is109015Agent) {
                window.open(appetiteGuideLink, "_blank");
              } else {
                history.push(routes.ONLINE_QUOTING_APPETITE);
              }
            }}
            icon="farFileAlt"
            color="purple"
          />
        )}

        <ActionCard
          className="card-dark-blue"
          title={Translations.home.report_a_claim}
          onClick={startReportAClaim}
          icon="farPaperPlane"
          color="dk-blue"
        />
      </ActionCardContainer>
    )
  );
};

TopActionCard.propTypes = {
  activateMakeAPayment: PropTypes.func.isRequired,
  startReportAClaim: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  history: PropTypes.object,
  is109015Agent: PropTypes.bool,
  acceptsCommercialLine: PropTypes.bool,
  toggleProductSelectionModal: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  acceptsCommercialLine:
    sessionSelector.agencyAcceptsCommercialLinesNewBusiness(state)
});

export default connect(mapStateToProps, { toggleProductSelectionModal })(
  TopActionCard
);
