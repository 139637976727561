import React, { useContext, useState } from "react";

import { Button, Form, FormGroup, TextArea, FlexRow } from "@ufginsurance/ui-kit";
import Step6Context from "./Step6Context";
import OnlineQuotingContext from "../OnlineQuotingContext";
import * as routes from "../../constants/routes";
import OqModal from "../shared/OqModal";

const UnderwriterReviewForm = ({ onCancel, showModal }) => {
  const { sendUwForm, sendUWIssue } = useContext(Step6Context);
  const {
    quoteIsUpdating,
    clearLocalQuote,
    stpException,
    hasBlockingUwIssues
  } = useContext(OnlineQuotingContext);
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = sendUwForm;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const modalBody = showConfirmation ? (
    <>
      <p>
        <b>
          Thank you for your business. Your submission has been sent to your
          underwriting team for review.
        </b>
      </p>
      <p>
        <b> Underwriting team next steps:</b>
        <br />
        You can look forward to hearing from us <b>within 1-2 business days.</b>
      </p>
      <FlexRow align="right">
        <Button
          variant="primary"
          onClick={() => {
            clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
          }}
          disabled={quoteIsUpdating}
        >
          Exit
        </Button>
      </FlexRow>
    </>
  ) : (
    <>
      <Form context={sendUwForm}>
        <FormGroup>
          <TextArea
            id="comments"
            name="comments"
            label="Comments / Remarks"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.comments}
            maxLength={65000}
          />
        </FormGroup>
        <FormGroup align="right">
          {onCancel && (
            <Button
              variant="plain"
              onClick={onCancel}
              disabled={quoteIsUpdating}
            >
              Cancel
            </Button>
          )}

          <Button
            onClick={() => {
              sendUWIssue(status => {
                if (status === true) {
                  setShowConfirmation(true);
                }
              });
            }}
            variant="primary"
            disabled={quoteIsUpdating}
          >
            Send
          </Button>
        </FormGroup>
      </Form>
    </>
  );
  return (
    <OqModal
      size={showConfirmation ? "md" : "lg"}
      closeIcon={!showConfirmation}
      className="oq__modal oq__modal__send-uw-review"
      title={
        showConfirmation
          ? "Submission Confirmation"
          : stpException || !hasBlockingUwIssues
          ? "Consult with Underwriter"
          : "Send for Underwriter Review"
      }
      body={modalBody}
      show={showModal}
      onHide={onCancel}
    />
  );
};

export default UnderwriterReviewForm;
