import React, { useState, useContext, useEffect, useCallback } from "react";
import { Panel, FlexRow, Button } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { processStateData } from "../../step5/state/processStateData";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import CoveragesInCards, {
  displayedCoverages
} from "../../step5/CoveragesInCards";
import { applyExclusions } from "../../shared/coveragePanels";

const StateSpecific = ({ lobKey, coverageExclusions }) => {
  const { get, quoteData, supportingData } = useContext(OnlineQuotingContext);
  const history = useHistory();

  const [statesData, setStatesData] = useState([]);

  useEffect(() => {
    if (!statesData.length) {
      const newStatesData = processStateData({
        get,
        quoteData,
        supportingData
      });

      setStatesData(newStatesData);
    }
  }, [statesData, get, quoteData, supportingData]);

  /** Filter stateData based on productLine */
  const getStateData = useCallback(
    lobKey => {
      return statesData
        .filter(
          x =>
            x.coverageCount > 0 &&
            (x?.data || []).some(d => d?.productLine === lobKey)
        )
        .map(s => {
          const _coverages = (s?.data || []).reduce((allCov, g) => {
            return [
              ...allCov,
              ...(g?.clausesGroups?.coverages || []).filter(
                c => !!c.selected && c?.productLine === lobKey
              )
            ];
          }, []);
          //apply of ".*" state to this
          const stateCov = {
            formData: {
              coverageControl: [
                ...(coverageExclusions?.formData?.stateCoverageControl?.[
                  ".*" //apply of ".*" state to this
                ] || []),
                ...(coverageExclusions?.formData?.stateCoverageControl?.[
                  s.coverableName //apply those related to this specific coverage state
                ] || [])
              ]
            }
          };
          // console.log("stateCov", stateCov);
          const coverages = applyExclusions(_coverages, stateCov);
          return { ...s, coverages };
        });
    },
    [statesData, coverageExclusions]
  );

  return (
    <Panel
      noBorder
      className={`oq__quote-summary__column__section oq__coverage-panel__StateSpecific`}
      title={
        <FlexRow>
          <span>
            State Specific Coverages (
            {getStateData(lobKey).reduce(
              (count, s) => count + s.coverages.length,
              0
            )}
            )
          </span>
          <Button
            variant="outline"
            wrapperClassName="align-right"
            isLink
            inline
            className="link-not-bolded"
            onClick={() => {
              history.push({
                pathname: routes.ONLINE_QUOTING_STEP5,
                hash: "StateSpecific"
              });
            }}
          >
            Back to State Specific Coverages
          </Button>
        </FlexRow>
      }
    >
      {(getStateData(lobKey) || []).map(s => {
        const coverages = displayedCoverages({
          coverages: s?.coverages || [],
          coverageExclusions
        });
        return (
          <Panel
            title={s?.coverableName}
            key={s?.coverableName}
            className={"oq__state_specific_state__panel"}
          >
            {(s?.coverages || []).length > 0 ? (
              <div className="oq__coverable__card__content__coverages">
                <CoveragesInCards
                  quoteData={quoteData}
                  coverageFields={coverages}
                  coverageExclusions={coverageExclusions}
                  id={`coverages__statespecific__${lobKey}`}
                  alwaysDisplay
                  editScheduledItems={false}
                />
              </div>
            ) : (
              <span>No State Specific Coverages for {s?.coverableName}</span>
            )}
          </Panel>
        );
      })}
    </Panel>
  );
};
export default StateSpecific;
