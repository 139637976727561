/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { useFlags } from "launchdarkly-react-client-sdk";

const Chat = ({ name, email, agencyCode, producerCode, agentCode, agency }) => {
  const { proQuoteChatEnabled } = useFlags();

  const [chatInitialized, setChatInitialized] = useState();
  const [agentInContext, setAgentInContext] = useState();

  useEffect(() => {
    // method to setup chat context with user details
    const initializeAgency = () => {
      if (window?.chatReady) {
        // console.log("initialize chat context");
        Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(
          function contextProvider() {
            return {
              agencyCode: { value: agencyCode, isDisplayable: true },
              producerCode: { value: producerCode, isDisplayable: true },
              agentCode: { value: agentCode, isDisplayable: true },
              name: { value: name, isDisplayable: true },
              email: { value: email, isDisplayable: true },
              agencyName: { value: agency?.name || "", isDisplayable: true },
              agencyCity: { value: agency?.city || "", isDisplayable: true },
              agencyState: { value: agency?.state || "", isDisplayable: true },
              agencyEmail: { value: agency?.email || "", isDisplayable: true }
            };
          }
        );
        setAgentInContext(agentCode);
      }
    };

    // if chat not intiially initialized, then dynamically add the script to get it going
    // and follow it up with adding the agnecy data into the chat context
    if (!chatInitialized && agency && proQuoteChatEnabled) {
      const chatAppId = window.env?.REACT_APP_CHAT_APP_ID;
      const chatOrgId = window.env?.REACT_APP_CHAT_ORG_ID;
      const chatOrgUrl = window.env?.REACT_APP_CHAT_ORG_URL;

      if (chatAppId && chatOrgId && chatOrgUrl) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://oc-cdn-ocprod.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js";
        script.setAttribute("id", "Microsoft_Omnichannel_LCWidget");
        //script.setAttribute("data-hide-chat-button", "true");
        script.setAttribute("data-lcw-version", "prod");
        script.setAttribute("data-color-override", "#002E5D");
        script.setAttribute("data-app-id", chatAppId);
        script.setAttribute("data-org-id", chatOrgId);
        script.setAttribute("data-org-url", chatOrgUrl);
        head.appendChild(script);
        setChatInitialized(true);

        window.addEventListener("lcw:ready", () => {
          window.chatReady = true;
          initializeAgency();
        });
        return () => {
          window.removeEventListener("lcw:ready", () => {
            initializeAgency();
          });
        };
      }
    }

    // if chat is already initialized, let's just update the chat context with the agency data
    if (window.chatReady && agentCode !== agentInContext) {
      initializeAgency();
    }
  }, [
    agency,
    agencyCode,
    agentCode,
    agentInContext,
    chatInitialized,
    email,
    name,
    proQuoteChatEnabled,
    producerCode
  ]);

  return null;
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    name: sessionSelector.getFirstAndLastName(state),
    email: sessionSelector.getEmail(state),
    agencyCode: sessionSelector.getActiveAgencyCode(state),
    producerCode: sessionSelector.getAgentProducerCode(state),
    agentCode: sessionSelector.getAgentCode(state),
    agency: sessionSelector.getActiveAgency(state)
  };
};

export default connect(mapStateToProps, {})(Chat);
