import { useForm } from "@ufginsurance/ui-kit";
import React, { createContext, useContext, useReducer } from "react";

const SuretyContext = createContext();

export const useSuretyContext = () => useContext(SuretyContext);

export function SuretyContextProvider({ agency, producer, ...props }) {
  const [bondOverview, updateBondOverview] = useReducer(
    (b, u) => ({ ...b, ...u }),
    {
      bond_number: "",
      showModal: false
    }
  );

  const cosDevToolsForm = useForm({
    values: {
      submissionOverride: false
    },
    onSubmit: () => {}
  });

  return (
    <SuretyContext.Provider
      value={{
        agency,
        bondOverview,
        producer,
        updateBondOverview,
        cosDevToolsForm
      }}
      {...props}
    >
      {props.children}
    </SuretyContext.Provider>
  );
}
