import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Form, FormGroup, InputSearch, useForm, Button } from "@ufginsurance/ui-kit";
import "./PolicySearchInput.scss";

const PolicySearchInput = ({
  searchPlaceholder,
  iconEnable,
  handleformSubmission,
  buttonText
}) => {
  const handleSubmit = f => {
    if (f.values.keyword && f.values.keyword.length > 0) {
      handleformSubmission(f.values.keyword.trim());
      handleOnChange({ event: null, field: "keyword", value: "" });
    }
  };

  const form = useForm({
    values: {
      keyword: ""
    },
    onSubmit: handleSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const classes = cn("", { searchButton: iconEnable });
  return (
    <div id="policy-search-input" className={classes}>
      <Form context={form}>
        <FormGroup>
          <InputSearch
            id="keyword"
            name="keyword"
            onChange={handleOnChange}
            placeholder={searchPlaceholder}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.keyword}
            displayIcon={iconEnable}
            size="lg"
            noLabel
          />
          {!iconEnable && (
            <Button
              disabled={values.keyword.length === 0}
              type="submit"
              variant="primary"
            >
              {buttonText}
            </Button>
          )}
        </FormGroup>
      </Form>
    </div>
  );
};

PolicySearchInput.propTypes = {
  searchPlaceholder: PropTypes.string,
  iconEnable: PropTypes.bool,
  handleformSubmission: PropTypes.func.isRequired,
  buttonText: PropTypes.string
};

PolicySearchInput.defaultProps = { iconEnable: true };

export default PolicySearchInput;
