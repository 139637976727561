import React, { useEffect, useState, useRef } from "react";
import { v4 } from "uuid";
import { Button, Modal, FlexRow } from "@ufginsurance/ui-kit";
import JobSiteInfoForm from "./JobSiteInfoForm";
import JobSitePanel from "./JobSitePanel";
import {
  formatJobSitesWithNewDeductible,
  getEmptyJobSite,
  jobSiteConstructionTypeRate
} from "./lib/utils";
import ConfirmationModal from "./../../components/modal/ConfirmationModal";

export default function JobSiteInfo({
  form,
  is109015Agent,
  licensedStates,
  saveOrUpdate
}) {
  const { updateForm, values } = form;
  const [showJobSiteModal, setShowJobSiteModal] = useState(false);
  const [jobsiteIndex, setJobsiteIndex] = useState(null);

  const [jobSites, setJobSites] = useState(values.job_sites);
  const [jobSiteToRemove, setJobSiteToRemove] = useState(null);
  const isInitialMount = useRef(true);

  const saveJobSite = jobSite => {
    let updatedJobSites = [...(jobSites || [])];
    const jobSiteIndex = updatedJobSites.findIndex(js => js.id === jobSite.id);

    let updatedJobSite = jobSite;
    if (jobSite.construction_type && jobSite.state) {
      const baseRate = jobSiteConstructionTypeRate(
        jobSite.construction_type,
        jobSite.state
      );
      updatedJobSite = { ...jobSite, base_rate: baseRate };
    }

    if (jobSiteIndex > -1) {
      updatedJobSites.splice(jobSiteIndex, 1, updatedJobSite);
      if (updatedJobSites.length > 1 && jobSiteIndex === 0) {
        updatedJobSites = formatJobSitesWithNewDeductible(
          updatedJobSites,
          updatedJobSite.deductible
        );
      }
    } else {
      updatedJobSites.push(updatedJobSite);
    }

    setJobSites(updatedJobSites);
    updateForm({
      values: { ...values, job_sites: updatedJobSites }
    });
  };

  useEffect(() => {
    if (!isInitialMount.current) saveOrUpdate();
    isInitialMount.current = false;
    // eslint-disable-next-line
  }, [values.job_sites]);

  const removeJobSite = jobSite => {
    setJobSiteToRemove(jobSite);
  };

  const removeJobSiteClose = () => {
    setJobSiteToRemove(null);
  };

  const removeJobSiteConfirm = () => {
    const tempJobSites = Array.from(jobSites || []);
    const indexToRemove = tempJobSites.findIndex(
      _ => _.id === jobSiteToRemove.id
    );
    if (indexToRemove > -1) {
      tempJobSites.splice(indexToRemove, 1);
      setJobSites(tempJobSites);
      updateForm({
        values: { ...values, job_sites: tempJobSites }
      });
    }
    setJobSiteToRemove(null);
  };

  useEffect(() => {
    if (jobSites.length === 0) {
      setShowJobSiteModal(true);
    }
  }, [jobSites]);

  const getJobSite = () => {
    if (jobsiteIndex !== null) {
      return jobSites[jobsiteIndex];
    }
    return getEmptyJobSite();
  };

  return (
    <div>
      {(values?.job_sites || []).map(jobSite => (
        <JobSitePanel
          key={jobSite.id}
          jobSite={jobSite}
          footer={
            <FlexRow className="leftSide topLine" align="justify">
              <Button
                className="leftSide noOutline"
                isLink
                onClick={() => {
                  const indexToUpdate = jobSites.findIndex(
                    _ => _.id === jobSite.id
                  );
                  setJobsiteIndex(indexToUpdate);
                  setShowJobSiteModal(true);
                }}
              >
                Edit Job Site
              </Button>
              <Button
                isLink
                className="rightSide noOutline"
                variant="tertiary"
                onClick={() => {
                  removeJobSite(jobSite);
                }}
              >
                Remove Job Site
              </Button>
            </FlexRow>
          }
          is109015Agent={is109015Agent}
        />
      ))}
      <Button
        block
        variant="outline"
        onClick={() => {
          setJobsiteIndex(null);
          setShowJobSiteModal(true);
        }}
      >
        &#43; Add Job Site
      </Button>
      {!!showJobSiteModal && (
        <Modal
          title="Job Site Information"
          className="job_site_info_modal"
          show={showJobSiteModal}
          body={
            <JobSiteInfoForm
              jobSite={getJobSite()}
              jobSites={jobSites}
              jobsiteIndex={jobsiteIndex}
              saveJobSite={saveJobSite}
              setShowJobSiteModal={setShowJobSiteModal}
              licensedStates={licensedStates}
            />
          }
          size="lg"
          onHide={() => setShowJobSiteModal(false)}
          altCloseMethod={false}
        />
      )}
      {!!jobSiteToRemove && (
        <ConfirmationModal
          show={!!jobSiteToRemove}
          id={v4()}
          title="Remove Job Site"
          message="Are you sure you want to remove this job site?"
          handleClose={removeJobSiteClose}
          handleConfirm={removeJobSiteConfirm}
          confirmText="Confirm"
          closeIcon
        />
      )}
    </div>
  );
}
