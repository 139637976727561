import * as types from "../uwReviewActionTypes";
import * as UWReviewServices from "../uwReviewServices";
import { logger } from "../../loggers";

/*--------UWREVIEW BOND INFORMATION SECTION---------- */
const fetchUWReviewBondInfoServiceStarted = () => ({
  type: types.FETCH_UW_REVIEW_BOND_STARTED
});

const fetchUWReviewBondServiceSucceeded = data => ({
  type: types.FETCH_UW_REVIEW_BOND_SUCCEEDED,
  payload: data
});

const fetchUWReviewBondServiceFailed = error => ({
  type: types.FETCH_UW_REVIEW_BOND_FAILED,
  payload: error
});
export const fetchUWReviewBondInfo = bond_number => dispatch => {
  dispatch(fetchUWReviewBondInfoServiceStarted());
  return UWReviewServices.getUWReviewBondInfo(bond_number).then(
    response => {
      dispatch(fetchUWReviewBondServiceSucceeded(response.data));
    },
    error => {
      dispatch(fetchUWReviewBondServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
