import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import {
  FormGroup,
  Form,
  useForm,
  Dropdown,
  DateRange,
  Popover,
  Input,
  Button,
  Icon
} from "@ufginsurance/ui-kit";

const AgencyAuditForm = props => {
  const renderTrigger = (
    <span className="agency-audit-popover__trigger">
      <Icon icon="fasQuestion" />
    </span>
  );

  const renderOverlay = (
    <div>
      {Translations.reports.tabUnderwriting.agencyAudit.popOverText}
      {props.administratorName}.
    </div>
  );

  const { emailList, is109015Agent, submit } = props;
  const { reportFormat } = Translations.reports.tabUnderwriting.agencyAuditForm;

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 365 * 6);

  const initialValues = {
    is109015Agent,
    reportFormatTypes: "P",
    name: "",
    emailTo: "",
    beginDate: "",
    endDate: "",
    agencyAuditFormRange: { from: "", to: "" }
  };

  const handleSubmission = ({ values }) => {
    const formData = {
      ...values,
      beginDate: values.agencyAuditFormRange.from,
      endDate: values.agencyAuditFormRange.to
    };
    submit(formData, () => updateForm({ values: initialValues }));
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  const rangeValidation = ({ field, value, event }) => {
    const errors = handleOnValidate({ field, value, event });

    // date range should not exceed 30 days
    if (!!value.from && !!value.to) {
      const f = new Date(value.from);
      const t = new Date(value.to);
      if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) > 365) {
        errors.push("The date range cannot exceed one year.");
      }
    }
    return errors;
  };

  return (
    <div className="reports-form__container">
      <div className="reports-form__note">
        {Translations.reports.tabUnderwriting.agencyAudit.note}
      </div>

      <Form context={form} className="agency-audit-form">
        <FormGroup>
          <DateRange
            id="agencyAuditFormRange"
            name="agencyAuditFormRange"
            labelFrom={
              Translations.reports.tabUnderwriting.agencyAudit.label.beginDate
            }
            labelTo={
              Translations.reports.tabUnderwriting.agencyAudit.label.endDate
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={rangeValidation}
            value={values.agencyAuditFormRange}
            format="MM/DD/YYYY"
            required
            minDate={minDate}
          />
        </FormGroup>

        {!is109015Agent ? (
          <FormGroup>
            <Dropdown
              id="emailTo"
              name="emailTo"
              label={
                Translations.reports.tabUnderwriting.agencyAudit.label.emailTo
              }
              labelElement={
                <Popover
                  id="email-to-popover"
                  placement="top"
                  triggerContent={renderTrigger}
                  popoverContent={renderOverlay}
                  trigger="click"
                  maxWidth={300}
                  theme="white"
                />
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.emailTo}
              size="lg"
              options={emailList.map(q => ({
                value: q.email_address,
                label: q.email_address
              }))}
            />
          </FormGroup>
        ) : (
          <FormGroup>
            <Input
              id="emailTo"
              name="emailTo"
              label={
                Translations.reports.tabUnderwriting.agencyAudit.label.emailTo
              }
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={({ field, value, validation }) => {
                const errors = handleOnValidate({ field, value, validation });
                // eslint-disable-next-line
                const emailRegex = /@/;
                if (!!is109015Agent && emailRegex.test(value)) {
                  errors.push("Do not enter an email address.");
                }
                return errors;
              }}
              value={values.emailTo}
              maxLength={30}
              required
            />
            <div className="align-with-input">@unitedfiregroup.com</div>
          </FormGroup>
        )}
        <FormGroup>
          <Dropdown
            id="reportFormatTypes"
            name="reportFormatTypes"
            label={
              Translations.reports.tabUnderwriting.agencyAudit.label
                .reportFormat
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.reportFormatTypes}
            size="md"
            required
            options={Object.keys(reportFormat).map(key => ({
              value: key,
              label: reportFormat[key]
            }))}
            isClearable={false}
          />
        </FormGroup>

        {is109015Agent && (
          <FormGroup>
            <Input
              id="name"
              name="name"
              label={Translations.reports.tabClaims.agencyLossRun.label.name}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.name}
              maxLength={22}
              required
            />
          </FormGroup>
        )}
        <FormGroup className="pad-top">
          <Button type="submit" variant="primary">
            {Translations.reports.tabClaims.agencyLossRun.label.getReport}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

AgencyAuditForm.propTypes = {
  emailList: PropTypes.array,
  is109015Agent: PropTypes.bool,
  submit: PropTypes.func,
  administratorName: PropTypes.string
};

export default AgencyAuditForm;
