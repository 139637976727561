import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const ScrollToTop = props => {
  const { location, children } = props;
  const [prevPathName, setPrevPathName] = useState("");

  useEffect(() => {
    if (location.pathname !== prevPathName) {
      window.scrollTo(0, 0);
      setPrevPathName(location.pathname);
    }
  }, [location.pathname, prevPathName]);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.array.isRequired,
  location: PropTypes.object
};

export default withRouter(ScrollToTop);
