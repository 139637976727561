import React, { useEffect } from "react";
import { Translations } from "../components/translations";
import PropTypes from "prop-types";
import PolicySearchListContainer from "./PolicySearchListContainer";
import {
  FormGroup,
  Form,
  useForm,
  Input,
  Dropdown,
  Button
} from "@ufginsurance/ui-kit";

export const PolicySearchWrapper = ({
  activeAgencyCode,
  searchKeyword,
  fetchPoliciesSearchList,
  isSpecialtyPortalView
}) => {
  const handleSubmit = ({ values }) => {
    fetchPoliciesSearchList(
      activeAgencyCode,
      values.searchKeyword,
      values.policyType,
      true
    );
  };
  // run the search once at component first render
  useEffect(() => {
    fetchPoliciesSearchList(activeAgencyCode, searchKeyword, "A", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    policyType: "A",
    searchKeyword: searchKeyword || ""
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleSubmit
  });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <div>
      <Form context={form}>
        <FormGroup>
          <Input
            id="searchKeyword"
            name="searchKeyword"
            label="Keyword"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.searchKeyword}
            maxLength={30}
            hideLabel
          />
          {!isSpecialtyPortalView && (
            <Dropdown
              id="policyType"
              name="policyType"
              label="Policy Type"
              hideLabel
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.policyType}
              isClearable={false}
              options={[
                { value: "A", label: "All" },
                { value: "C", label: "Commercial" },
                { value: "X", label: "Commercial/Personal" },
                { value: "P", label: "Personal" },
                { value: "B", label: "Surety" }
              ]}
              size="md"
            />
          )}
          <Button
            type="submit"
            labelSpace
            // disabled={() => values.length === 0}
            variant="primary"
          >
            {Translations.Name_Search.Button_Text}
          </Button>
        </FormGroup>
      </Form>
      <PolicySearchListContainer searchKeyword={values.searchKeyword} />
    </div>
  );
};

PolicySearchWrapper.propTypes = {
  searchKeyword: PropTypes.string,
  activeAgencyCode: PropTypes.string.isRequired,
  fetchPoliciesSearchList: PropTypes.func.isRequired,
  isSpecialtyPortalView: PropTypes.bool
};
