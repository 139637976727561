import React from "react";
import { suretyLegalEntities } from "../constants/suretyDictionary";

const LegalEntity = ({ quoteData, step }) => {
  const legalEnt = suretyLegalEntities
    .filter(a => a.value === quoteData?.business_type)
    .map(s => s.label);

  const lines = step > 1 ? legalEnt : "";

  return (
    <>
      <div className="qq__sidebar__summary__item">
        <b>Legal Entity:</b>
        <div className="qq__sidebar__summary__item-lines">{lines}</div>
      </div>
    </>
  );
};

export default LegalEntity;
