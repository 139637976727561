import React, { useState } from "react";
import PropTypes from "prop-types";
import * as routes from "../../constants/routes";
import TableOfContents from "./TableOfContents";
import Breadcrumbs from "./Breadcrumbs";
import StatesDropDown from "./StatesDropDown";
import Content from "./Content";
import ProductsList from "./ProductsList";
import { Route, withRouter, useRouteMatch } from "react-router-dom";
import { Anchor } from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";
import { useRouteEventHandler } from "../hooks/route-event/useRouteEventHandler";
import { useStatesRouteFetch } from "../hooks/route-fetch/useStatesRouteFetch";
import { useProductsRouteFetch } from "../hooks/route-fetch/useProductsRouteFetch";
import { useTableOfContentsRouteFetch } from "../hooks/route-fetch/useTableOfContentsRouteFetch";
import { useContentRouteFetch } from "../hooks/route-fetch/useContentRouteFetch";
import { useDefaultStateRouteValidation } from "../hooks/route-validation/useDefaultStateRouteValidation";
import { useStateRouteValidation } from "../hooks/route-validation/useStateRouteValidation";
import { useProductRouteValidation } from "../hooks/route-validation/useProductRouteValidation";
import { useDefaultSubheaderRouteValidation } from "../hooks/route-validation/useDefaultSubheaderRouteValidation";
import { useSubheaderRouteValidation } from "../hooks/route-validation/useSubheaderRouteValidation";
import { useSubheader2RouteValidation } from "../hooks/route-validation/useSubheader2RouteValidation";

const AgentManuals = ({
  fetchStatesListDetails,
  stateListData,
  areStatesLoading,
  statesError,
  fetchStateProducts,
  stateProducts,
  areProductsLoading,
  productsError,
  tableOfContents,
  areTableOfContentsLoading,
  tableOfContentsError,
  content,
  isContentLoading,
  contentError,
  isSpecialtyPortalView,
  activeSpecialtyCompanyBranch,
  selectedState,
  defaultAgentState,
  history,
  fetchTableOfContents,
  setSelectedState,
  setSelectedProduct,
  selectedProduct,
  fetchContent,
  isMercerSpecialtyPortalView,
  previousTableOfContentsProductId,
  previousFetchedContentId,
  previousFetchedStateId,
  previousFetchedCompanyBranch,
  defaultSelectedSubheader
}) => {
  const [selectedSubheader, setSelectedSubheader] = useState(null);
  const [selectedSubheader2, setSelectedSubheader2] = useState(null);
  const stateRouteMatch = useRouteMatch(routes.AGENT_MANUALS_STATE);
  const invalidStateRouteMatch = useRouteMatch(
    routes.INVALID_AGENT_MANUALS_STATE
  );
  const isExactInvalidStateRoute =
    invalidStateRouteMatch && !stateRouteMatch?.params?.stateId;
  const isCurrentPortalViewRouteAvailable =
    stateRouteMatch && !areStatesLoading
      ? stateListData &&
        stateListData.find(state => {
          return state.value === stateRouteMatch.params.stateId;
        })
      : true;
  const currentPortalView = isSpecialtyPortalView
    ? isMercerSpecialtyPortalView
      ? Translations.header.mercer_insurance
      : Translations.header.ufg_specialty_insurance
    : Translations.header.ufg_agent;
  const defaultHeaderText = isSpecialtyPortalView
    ? Translations.commercial.broker_manuals
    : Translations.commercial.agent_manuals;

  const {
    onAgentManualsHomeClick,
    onStatesListClick,
    onProductListClick,
    onHeaderClick,
    onSubHeaderClick,
    onSubHeader2Click
  } = useRouteEventHandler({
    setSelectedState,
    setSelectedProduct,
    setSelectedSubheader,
    setSelectedSubheader2,
    history,
    defaultSelectedSubheader,
    selectedState,
    selectedProduct
  });
  useStatesRouteFetch({
    areStatesLoading,
    stateListData,
    activeSpecialtyCompanyBranch,
    fetchStatesListDetails,
    previousFetchedCompanyBranch
  });
  useProductsRouteFetch(
    previousFetchedStateId,
    areProductsLoading,
    fetchStateProducts
  );
  useTableOfContentsRouteFetch(
    previousTableOfContentsProductId,
    areTableOfContentsLoading,
    fetchTableOfContents
  );
  useContentRouteFetch(
    isContentLoading,
    fetchContent,
    previousFetchedContentId
  );
  useDefaultStateRouteValidation(
    selectedState,
    onStatesListClick,
    defaultAgentState,
    stateListData
  );
  useStateRouteValidation(selectedState, stateListData, setSelectedState);
  useProductRouteValidation(stateProducts, selectedProduct, setSelectedProduct);
  useDefaultSubheaderRouteValidation({
    selectedProduct,
    defaultSelectedSubheader,
    previousTableOfContentsProductId,
    tableOfContents,
    onHeaderClick,
    onSubHeaderClick,
    setSelectedProduct
  });
  useSubheaderRouteValidation(
    tableOfContents,
    setSelectedSubheader,
    selectedSubheader
  );
  useSubheader2RouteValidation(
    tableOfContents,
    setSelectedSubheader2,
    selectedSubheader2
  );

  const contentElement = (
    <Content
      content={content}
      contentError={contentError}
      tableOfContentsError={tableOfContentsError}
      isContentLoading={isContentLoading}
    />
  );

  return (
    <div id="agent-manuals">
      {!isCurrentPortalViewRouteAvailable || isExactInvalidStateRoute ? (
        <nav className="breadcrumb">
          {Translations.formatString(
            Translations.commercial.agent_manuals_not_accessible_begin,
            currentPortalView
          )}
          <Anchor
            className="breadcrumb__item"
            onClick={() => {
              onAgentManualsHomeClick(true);
            }}
          >
            {defaultHeaderText}
          </Anchor>
          {Translations.commercial.agent_manuals_not_accessible_end}
        </nav>
      ) : (
        <>
          <Breadcrumbs
            onAgentManualsHomeClick={onAgentManualsHomeClick}
            selectedState={selectedState}
            selectedProduct={selectedProduct}
            onStatesListClick={onStatesListClick}
            selectedSubheader={selectedSubheader}
            selectedSubheader2={selectedSubheader2}
            onProductListClick={onProductListClick}
            isSpecialtyPortalView={isSpecialtyPortalView}
            onSubHeaderClick={onSubHeaderClick}
          />
          <Route
            exact
            path={routes.AGENT_MANUALS}
            render={() => (
              <div className="row">
                <StatesDropDown
                  onStatesListClick={onStatesListClick}
                  stateListData={stateListData}
                  selectedState={selectedState}
                  areStatesLoading={areStatesLoading}
                  statesError={statesError}
                />
              </div>
            )}
          ></Route>
          <Route exact path={routes.AGENT_MANUALS_STATE}>
            <div className="row">
              <StatesDropDown
                onStatesListClick={onStatesListClick}
                stateListData={stateListData}
                selectedState={selectedState}
                activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
                defaultAgentState={defaultAgentState}
                areStatesLoading={areStatesLoading}
                statesError={statesError}
              />
            </div>
            <div className="row">
              <ProductsList
                activeSpecialtyCompanyBranch={activeSpecialtyCompanyBranch}
                onProductListClick={onProductListClick}
                stateProducts={stateProducts}
                selectedProduct={selectedProduct}
                areProductsLoading={areProductsLoading}
                productsError={productsError}
              />
            </div>
          </Route>
          <Route path={routes.AGENT_MANUALS_STATE_PRODUCT}>
            <a className="uikit-sr-only" href="#sr-content">
              Skip to Content
            </a>
            <div aria-hidden="true" className="row">
              {contentElement}
            </div>
            <TableOfContents
              tableOfContents={tableOfContents}
              onHeaderClick={onHeaderClick}
              onSubHeaderClick={onSubHeaderClick}
              onSubHeader2Click={onSubHeader2Click}
              areTableOfContentsLoading={areTableOfContentsLoading}
              tableOfContentsError={tableOfContentsError}
              statesError={statesError}
              productsError={productsError}
              isContentLoading={isContentLoading}
              contentElement={<div id="sr-content">{contentElement}</div>}
            />
          </Route>
        </>
      )}
    </div>
  );
};

AgentManuals.propTypes = {
  fetchStatesListDetails: PropTypes.func.isRequired,
  stateListData: PropTypes.array,
  areStatesLoading: PropTypes.bool.isRequired,
  statesError: PropTypes.object,
  fetchStateProducts: PropTypes.func.isRequired,
  stateProducts: PropTypes.array,
  areProductsLoading: PropTypes.bool.isRequired,
  productsError: PropTypes.object,
  fetchTableOfContents: PropTypes.func.isRequired,
  tableOfContents: PropTypes.array,
  areTableOfContentsLoading: PropTypes.bool.isRequired,
  tableOfContentsError: PropTypes.object,
  fetchContent: PropTypes.func.isRequired,
  content: PropTypes.object,
  isContentLoading: PropTypes.bool.isRequired,
  contentError: PropTypes.object,
  isSpecialtyPortalView: PropTypes.bool,
  activeSpecialtyCompanyBranch: PropTypes.string,
  defaultAgentState: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.object,
  selectedState: PropTypes.object,
  setSelectedState: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  setSelectedProduct: PropTypes.func.isRequired,
  isMercerSpecialtyPortalView: PropTypes.bool.isRequired,
  previousTableOfContentsProductId: PropTypes.string,
  previousFetchedContentId: PropTypes.string,
  previousFetchedStateId: PropTypes.string,
  previousFetchedCompanyBranch: PropTypes.string,
  defaultSelectedSubheader: PropTypes.object
};

export default withRouter(AgentManuals);
