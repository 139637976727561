import * as types from "../constants/actionTypes";

export const initialState = {
  accounts: {},
  accountServiceLinks: {
    actions: {},
    isLoading: false,
    error: null
  },
  isLoading: false,
  policyNumber: null,
  showBillingStatement: false,
  billingStatements: [],
  policyType: null,
  idCards: [],
  error: null,
  isLoadingAutoIdCards: false,
  errorAutoIdCardsFailed: null,
  driversListReport: {
    isLoading: false,
    error: null
  },
  vehiclesListReport: {
    isLoading: false,
    error: null
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEFT_ACCOUNT_OVERVIEW: {
      return { ...initialState };
    }
    case types.FETCH_ACCOUNT_INFO_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_ACCOUNT_INFO_SUCCEEDED:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false
      };
    case types.FETCH_ACCOUNT_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_ACCOUNT_SERVICE_LINKS_STARTED:
      return {
        ...state,
        accountServiceLinks: {
          ...state.accountServiceLinks,
          isLoading: true,
          error: null,
          requestedPolicyNumber: null
        }
      };
    case types.FETCH_ACCOUNT_SERVICE_LINKS_SUCCEEDED:
      return {
        ...state,
        accountServiceLinks: {
          ...state.accountServiceLinks,
          actions: action.payload,
          isLoading: false,
          error: null,
          requestedPolicyNumber: action.policyNumber
        }
      };
    case types.FETCH_ACCOUNT_SERVICE_LINKS_FAILED:
      return {
        ...state,
        accountServiceLinks: {
          ...state.accountServiceLinks,
          isLoading: false,
          error: action.payload,
          requestedPolicyNumber: action.policyNumber
        }
      };
    case types.FETCH_AUTO_ID_CARDS_STARTED:
      return {
        ...state,
        errorAutoIdCardsFailed: false,
        idCards: [],
        isLoadingAutoIdCards: true
      };
    case types.FETCH_AUTO_ID_CARDS_SUCCEEDED:
      return {
        ...state,
        idCards: action.payload,
        isLoadingAutoIdCards: false,
        errorAutoIdCardsFailed: false
      };
    case types.FETCH_AUTO_ID_CARDS_FAILED:
      return {
        ...state,
        isLoadingAutoIdCards: false,
        errorAutoIdCardsFailed: action.payload,
        idCards: []
      };
    case types.FETCH_DRIVERS_LIST_REPORT_STARTED:
      return {
        ...state,
        driversListReport: {
          isLoading: true,
          error: false
        }
      };
    case types.FETCH_DRIVERS_LIST_REPORT_SUCCEEDED:
      return {
        ...state,
        driversListReport: {
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_DRIVERS_LIST_REPORT_FAILED:
      return {
        ...state,
        driversListReport: {
          isLoading: false,
          error: action.error
        }
      };
    case types.FETCH_VEHICLES_LIST_REPORT_STARTED:
      return {
        ...state,
        vehiclesListReport: {
          isLoading: true,
          error: false
        }
      };
    case types.FETCH_VEHICLES_LIST_REPORT_SUCCEEDED:
      return {
        ...state,
        vehiclesListReport: {
          isLoading: false,
          error: false
        }
      };
    case types.FETCH_VEHICLES_LIST_REPORT_FAILED:
      return {
        ...state,
        vehiclesListReport: {
          isLoading: false,
          error: action.error
        }
      };
    case types.BILLED_BY_STATEMENT:
      return {
        ...state,
        policyNumber: action.payload.policyNumber,
        showBillingStatement: action.payload.showBillingStatement,
        billingStatements: action.payload.billingStatements,
        policyType: action.payload.policyType,
        isLoading: false
      };
    default:
      return state;
  }
};
