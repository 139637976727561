import { useEffect } from "react";
import { useRootRouteMatch, useDepartmentRouteMatch } from "../hooks";
import * as routes from "../../../constants/routes";

export const useDefaultDepartmentRouteValidation = (
  departmentData,
  history
) => {
  const yourUfgTeamRootRoute = useRootRouteMatch();
  const yourUfgTeamDepartmentRouteMatch = useDepartmentRouteMatch();

  // This defaults /your_ufg_team to the first department
  useEffect(() => {
    const hasDepartments = departmentData && departmentData.length > 0;
    if (hasDepartments && yourUfgTeamRootRoute?.isExact) {
      history.replace(`${routes.YOUR_UFG_TEAM_ROOT}/${departmentData[0].id}`);
    }
  }, [yourUfgTeamRootRoute, departmentData, history]);

  // This sets the department to the first department when the department
  // does not change such as when the company_branch changes.
  useEffect(() => {
    const hasDepartments = departmentData && departmentData.length > 0;
    const newDepartmentSelection = departmentData?.find(
      dep => dep.id === yourUfgTeamDepartmentRouteMatch?.params?.departmentId
    );
    if (
      hasDepartments &&
      yourUfgTeamDepartmentRouteMatch?.isExact &&
      yourUfgTeamDepartmentRouteMatch?.params?.departmentId
    ) {
      if (!newDepartmentSelection) {
        history.replace(`${routes.YOUR_UFG_TEAM_ROOT}/${departmentData[0].id}`);
      }
    }
  }, [yourUfgTeamDepartmentRouteMatch, departmentData, history]);

  return null;
};
