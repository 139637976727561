import { useState, useEffect } from "react";
import { logger } from "../../../loggers";
import { getValidAgencyStates } from "../service";

export const useGetValidAgencyStates = (agencyCode, category) => {
  const [validAgencyStates, setValidAgencyStates] = useState();
  const [isLoading, setIsLoading] =
    useState(false);

  useEffect(() => {
    if (category.includes("bonds") && !validAgencyStates) {
      setIsLoading(true);

      const bondsCategory = category.split("bonds")[1]; // ("Contract" || "Commercial").
      getValidAgencyStates(agencyCode, bondsCategory)
        .then(res => {
          setValidAgencyStates(res.data);
        })
        .catch(err => {
          console.error({ err });
          logger.error({
            description: err.toString(),
            fatal: false
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [agencyCode, category, validAgencyStates]);

  return [validAgencyStates, isLoading];
};
