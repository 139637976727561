export function getPastFetchedRenewalAgencyCode(state) {
  return state.homeCommercial.pastFetchedRenewalAgencyCode;
}

export function getPolicyDecsCommercialDocumentFailed(state) {
  return state.homeCommercial.policyDecsCommercialDocumentFailed;
}

export function getPastFetchedQuoteProposalsCode(state) {
  return state.commercialLines.quoteProposals.pastFetchedQuoteProposalsCode;
}

export function getPastQuoteProposalsFilter(state) {
  return state.commercialLines.quoteProposals.pastQuoteProposalsFilter;
}

export function getPastFetchedQuoteProposalsSpecialtyCompanyBranch(state) {
  return state.commercialLines.quoteProposals.pastFetchedQuoteProposalsSpecialtyCompanyBranch;
}

export function areQuoteProposalsLoading(state) {
  return state.commercialLines.quoteProposals.isLoading;
}
