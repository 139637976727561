import * as policyChangesService from "./policyChangesService";
import * as types from "../../constants/actionTypes";
import { logger } from "../../loggers";
import { DateFormat } from "../../components/Factory";
import { DocumentService } from "../../services/documentService";

const fetchChangeRequestElegibilityInfoStarted = () => ({
  type: types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_STARTED
});

const fetchChangeRequestElegibilityInfoSucceeded = data => ({
  type: types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_SUCCEEDED,
  payload: data
});

const fetchChangeRequestElegibilityInfoFailed = error => ({
  type: types.FETCH_CHANGE_REQUEST_ELEGIBILTY_INFO_FAILED,
  payload: error
});

export const processChange = (policyInfo, handleSuccess, handleError) => () => {
  switch (policyInfo.changeType) {
    // commercial vehicle change
    case "vehicle-changes":
      postHandleSubmit(policyInfo, handleSuccess, handleError);
      break;
    // personal auto change
    case "personal-auto":
      postPersonalAutoHandleSubmit(policyInfo, handleSuccess, handleError);
      break;
    // homeowners change
    case "homeowners":
      postHomeOwnersHandleSubmit(policyInfo, handleSuccess, handleError);
      break;
    // other change
    case "other":
      postOtherHandleSubmit(policyInfo, handleSuccess, handleError);
      break;
    // personal additional interest change
    case "additional-interest":
      postAdditionalInterestHandleSubmit(
        policyInfo,
        handleSuccess,
        handleError
      );
      break;
    // commercial additional interest change
    case "additionalinterest-losspayee-mortgagee":
      postCommercialAIhandleSubmit(policyInfo, handleSuccess, handleError);
      break;
    default:
      throw new Error(`Unkown change type: ${policyInfo.changeType}`);
  }
};

const postHandleSubmit = (policyInfo, handleSucess, handleError) => {
  const data = {
    agent_number: policyInfo.agentCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD"),
    commercial_underwriter: policyInfo.commercial_underwriter
  };

  return policyChangesService
    .getChangeRequestEligibilityInfo(
      policyInfo.policyNumber,
      policyInfo.agencyNumber
    )
    .then(
      responseData => {
        const error_code_vehicle_change_in_progress = "999";
        if (responseData.data.has_saved_commercial_auto_change === true) {
          const error = {
            response: {
              data: { error_code: error_code_vehicle_change_in_progress }
            }
          };
          handleError(error);
          return;
        }
        policyChangesService
          .postHandleSubmit(policyInfo.policyNumber, data)
          .then(
            res => {
              handleSucess(res.data);
            },
            err => {
              handleError(err);
            }
          );
      },
      error => {
        handleError(error);
      }
    );
};

const postPersonalAutoHandleSubmit = (
  policyInfo,
  handleSucess,
  handleError
) => {
  const data = {
    agency_number: policyInfo.activeAgencyCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD")
  };

  return policyChangesService
    .postPersonalAutoHandleSubmit(policyInfo.policyNumber, data)
    .then(
      res => {
        handleSucess(res.data);
      },
      error => {
        handleError(error);
      }
    );
};

const postHomeOwnersHandleSubmit = (policyInfo, handleSucess, handleError) => {
  const data = {
    agency_number: policyInfo.activeAgencyCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD")
  };

  return policyChangesService
    .postHomeOwnersHandleSubmit(policyInfo.policyNumber, data)
    .then(
      res => {
        handleSucess(res.data);
      },
      error => {
        handleError(error);
      }
    );
};

const postOtherHandleSubmit = (policyInfo, handleSucess, handleError) => {
  const data = {
    agency_number: policyInfo.activeAgencyCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD")
  };

  return policyChangesService
    .postOtherHandleSubmit(policyInfo.policyNumber, data)
    .then(
      res => {
        handleSucess(res.data);
      },
      error => {
        handleError(error);
      }
    );
};

const postAdditionalInterestHandleSubmit = (
  policyInfo,
  handleSucess,
  handleError
) => {
  const data = {
    agency_number: policyInfo.activeAgencyCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD")
  };

  return policyChangesService
    .postAdditionalInterestHandleSubmit(policyInfo.policyNumber, data)
    .then(
      res => {
        handleSucess(res.data);
      },
      error => {
        handleError(error);
      }
    );
};

const postCommercialAIhandleSubmit = (
  policyInfo,
  handleSucess,
  handleError
) => {
  const data = {
    agency_number: policyInfo.activeAgencyCode,
    change_date: DateFormat(policyInfo.effectiveDate, "YYYY-MM-DD")
  };

  return policyChangesService
    .postCommercialAIhandleSubmit(policyInfo.policyNumber, data)
    .then(
      res => {
        handleSucess(res.data);
      },
      error => {
        handleError(error);
      }
    );
};

export const getChangeRequestEligibilityInfo =
  (policy_number, agent_code) => dispatch => {
    dispatch(fetchChangeRequestElegibilityInfoStarted());
    policyChangesService
      .getChangeRequestEligibilityInfo(policy_number, agent_code)
      .then(
        res => {
          dispatch(fetchChangeRequestElegibilityInfoSucceeded(res.data));
        },
        error => {
          dispatch(fetchChangeRequestElegibilityInfoFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

export const fetchPolicyChangesViewDocument =
  (policy_number, change_id) => () => {
    const documentService = new DocumentService();
    return policyChangesService
      .getPolicyChangesDocument(policy_number, change_id)
      .then(
        response => {
          documentService.initializeRenderer("/loading-document");
          documentService.saveOrOpenDocument(
            response.data,
            "policy_change_request.pdf"
          );
        },
        () => {
          documentService.clearRenderer();
        }
      );
  };

const createPolicyDraftStarted = () => ({
  type: types.CREATE_POLICY_DRAFT_STARTED
});

const createPolicyDraftSucceeded = data => ({
  type: types.CREATE_POLICY_DRAFT_SUCCEEDED,
  payload: data
});

const createPolicyDraftFailed = error => ({
  type: types.CREATE_POLICY_DRAFT_FAILED,
  payload: error
});

export const createPolicyDraft =
  (policy_number, type, effective_date) => dispatch => {
    dispatch(createPolicyDraftStarted());
    return policyChangesService
      .createPolicyDraft(policy_number, type, effective_date)
      .then(
        res => {
          dispatch(createPolicyDraftSucceeded(res.data));
          return Promise.resolve(res.data);
        },
        error => {
          dispatch(createPolicyDraftFailed(error.response));
          logger.error({
            description: error.toString(),
            fatal: true
          });
        }
      );
  };

const fetchPolicyDraftStarted = () => ({
  type: types.FETCH_POLICY_DRAFT_STARTED
});

export const fetchPolicyDraftSucceeded = data => ({
  type: types.FETCH_POLICY_DRAFT_SUCCEEDED,
  payload: data
});

const fetchPolicyDraftFailed = error => ({
  type: types.FETCH_POLICY_DRAFT_FAILED,
  payload: error
});

export const fetchPolicyDraft = (policy_number, type) => dispatch => {
  dispatch(fetchPolicyDraftStarted());
  return policyChangesService.fetchPolicyDraft(policy_number, type).then(
    res => {
      dispatch(fetchPolicyDraftSucceeded(res.data));
      return Promise.resolve(res.data);
    },
    error => {
      dispatch(fetchPolicyDraftFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

const putPolicyChangeStarted = () => ({
  type: types.PUT_POLICY_CHANGE_STARTED
});

const putPolicyChangeSucceeded = data => ({
  type: types.PUT_POLICY_CHANGE_SUCCEEDED,
  payload: data
});

export const putPolicyChange =
  (policy_number, type, effective_date) => dispatch => {
    dispatch(putPolicyChangeStarted());
    return policyChangesService
      .putPolicyChange(policy_number, type, effective_date)
      .then(
        res => {
          dispatch(putPolicyChangeSucceeded(res.data));
          return Promise.resolve(res.data);
        },
        error => {
          logger.error({
            description: error.toString(),
            fatal: true
          });
          return Promise.resolve(error.response.data);
        }
      );
  };

const fetchDriverLicenseValidationListStarted = () => ({
  type: types.FETCH_DRIVERS_LICENSE_VALIDATION_LIST_STARTED
});

const fetchDriverLicenseValidationListSucceeded = data => ({
  type: types.FETCH_DRIVERS_LICENSE_VALIDATION_LIST_SUCCEEDED,
  payload: data
});

const fetchDriverLicenseValidationListFailed = error => ({
  type: types.FETCH_DRIVERS_LICENSE_VALIDATION_LIST_FAILED,
  payload: error
});

export const fetchDriverLicenseValidationList = () => dispatch => {
  dispatch(fetchDriverLicenseValidationListStarted());
  return policyChangesService.getDriversLicenseValidation().then(
    res => {
      dispatch(
        fetchDriverLicenseValidationListSucceeded(res.data?.jurisdiction)
      );
      return Promise.resolve(res.data?.jurisdiction);
    },
    error => {
      dispatch(fetchDriverLicenseValidationListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
