import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ContentHeader, Alert } from "@ufginsurance/ui-kit";
import CompletePaymentLegacyForm from "./CompletePaymentLegacyForm";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";
import * as completePaymentSelector from "./completePaymentSelector";

const CompletePaymentLegacy = ({
  match,
  history,
  copyAgencySweepPaymentRequestDetails,
  accountBilledRows,
  policyBilledRows,
  hasAccountAndBillingInformation,
  agencySweepAccountNumber,
  hasDirectBillPayNowEnrollment
}) => {
  const {
    Agency_Sweep
  } = Translations.Billing_Details.Account_Billing_Activity;
  const [accountBilledTableData, setAccountBilledTableData] = useState(null);
  const [policyBilledTableData, setPolicyBilledTableData] = useState(null);
  const [showInvalidPayment] = useState("");

  useEffect(() => {
    if (accountBilledRows) {
      setAccountBilledTableData(accountBilledRows);
    }
    if (policyBilledRows) {
      setPolicyBilledTableData(policyBilledRows);
    }
  }, [accountBilledRows, policyBilledRows]);

  useEffect(() => {
    const storedAccountNumberMatchesURI = completePaymentSelector.validateAccountNumberMatch(
      match.params.account_number,
      agencySweepAccountNumber
    );
    if (
      !hasAccountAndBillingInformation ||
      !storedAccountNumberMatchesURI ||
      !hasDirectBillPayNowEnrollment
    ) {
      history.push(
        `${routes.ACCOUNT_HOME}/${match.params.account_number}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
      );
    }
  }, [
    match,
    agencySweepAccountNumber,
    hasAccountAndBillingInformation,
    hasDirectBillPayNowEnrollment,
    history
  ]);

  const hasAccountBilledPolicies =
    accountBilledTableData &&
    accountBilledTableData.length > 0 &&
    accountBilledTableData.filter(row => row.rowType === "policy").length > 0;
  const hasPolicyBilledPolicies =
    policyBilledTableData && policyBilledTableData.length > 0;

  return (
    <div className="account-sweep">
      {showInvalidPayment && (
        <div>
          <Alert type="error">{showInvalidPayment}</Alert>
        </div>
      )}
      <div className="row">
        <ContentHeader>{Agency_Sweep.Agency_Sweep_Header}</ContentHeader>
        <h3>{Agency_Sweep.Agency_Sweep_Header_Title}</h3>
        <div className="row">
          {Agency_Sweep.Eligible_Accounts_Policies_Prompt}
        </div>
        <div className="row">{Agency_Sweep.Eligible_Rules_Prompt} </div>
        <div className="row">&nbsp;</div>
        <ul>
          <li>{Agency_Sweep.Eligible_Rules_Balance}</li>
          <li>{Agency_Sweep.Eligible_Rules_Cancel}</li>
          <li>{Agency_Sweep.Eligible_Rules_Mortgagee}</li>
          <li>{Agency_Sweep.Eligible_Rules_AutoPay}</li>
        </ul>
      </div>
      <div className="row">{Agency_Sweep.Billed_By_Type_Rules_Prompt}</div>
      {hasAccountBilledPolicies && hasPolicyBilledPolicies && (
        <div className="row">
          <div className="row">&nbsp;</div>
          <Alert type="note">{Agency_Sweep.Separate_Payments_Prompt}</Alert>
        </div>
      )}
      <div className="row">&nbsp;</div>
      {(accountBilledTableData || policyBilledTableData) && (
        <CompletePaymentLegacyForm
          accountNumber={agencySweepAccountNumber}
          accountBilledTableData={accountBilledTableData}
          policyBilledTableData={policyBilledTableData}
          copyAgencySweepPaymentRequestDetails={
            copyAgencySweepPaymentRequestDetails
          }
        />
      )}
      <div className="row">{Agency_Sweep.Disclaimer}</div>
    </div>
  );
};

CompletePaymentLegacy.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  copyAgencySweepPaymentRequestDetails: PropTypes.func.isRequired,
  accountBilledRows: PropTypes.array,
  policyBilledRows: PropTypes.array,
  hasAccountAndBillingInformation: PropTypes.bool.isRequired,
  agencySweepAccountNumber: PropTypes.string,
  hasDirectBillPayNowEnrollment: PropTypes.bool.isRequired
};

export default withRouter(CompletePaymentLegacy);
