import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Alert, ContentHeader, FlexRow } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import * as routes from "../../constants/routes";
import { PolicyChangesProvider } from "./context";
import SidebarStepper from "./SidebarStepper";
import SidebarChangeInformation from "./SidebarChangeInformation";
import UnderwritingTeamInformation from "./UnderwritingTeamInformation";
import PolicyChangesHeader from "./PolicyChangesHeader";
import Step1DriverList from "./driver-steps/Step1DriverList";
import Step1DriverFooter from "./driver-steps/Step1DriverFooter";
import Step2DriverSummary from "./driver-steps/Step2DriverSummary";
import Step2DriverFooter from "./driver-steps/Step2DriverFooter";
import {
  fetchPolicyDraft,
  getChangeRequestEligibilityInfo,
  putPolicyChange,
  fetchPolicyDraftSucceeded,
  fetchDriverLicenseValidationList
} from "../redux/policyChangesAction";
import {
  POLICY_WIZARD_STEPS,
  POLICY_TRANSACTION_STATUS_TYPES
} from "../constants_helpers";
import { getRequestChange } from "../accountChangeRequestSelector";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const { QUOTED, ISSUED, WITHDRAWN } = POLICY_TRANSACTION_STATUS_TYPES;

const POLICY_HEADERS = {
  STEP_1: "Add, edit or remove drivers",
  STEP_2: "Your change is ready to be issued",
  BIND_BLOCKED: "Almost done: Submit your change for underwriter review"
};

const PolicyChanges = ({
  history,
  policyDraft,
  fetchPolicyDraft,
  getChangeRequestEligibilityInfo,
  requestChange,
  selectedAgencyCode,
  putPolicyChange,
  fetchPolicyDraftSucceeded,
  fetchDriverLicenseValidationList,
  driverLicenseValidationList
}) => {
  const showErrorMessage = !!policyDraft?.error?.data?.error_code;
  const [showStep2Loader, setShowStep2Loader] = useState(false);

  const policyChangesStepRouteMatch = useRouteMatch(routes.POLICY_CHANGES_STEP);

  const { ufgagentPolicyChanges, driverChangesPhase2 } = useFlags();

  const { policyNumber, jobId, step } = policyChangesStepRouteMatch.params;

  const currentStep = step;

  const isBindBlocked =
    policyDraft?.data?.bind_blocked && currentStep === POLICY_WIZARD_STEPS.TWO;

  const STATUSES_FOR_REDIRECT = useMemo(() => {
    const statuses = [ISSUED, WITHDRAWN];

    if (!driverChangesPhase2) {
      statuses.push(QUOTED);
    }

    return statuses;
  }, [driverChangesPhase2]);

  useEffect(() => {
    if (STATUSES_FOR_REDIRECT.includes(policyDraft.data?.status)) {
      history.push(
        `${routes.ACCOUNT_HOME}/${policyNumber}/${routes.ACCOUNT_OVERVIEW_CHANGE_REQUESTS_ACTIVE_TAB}`
      );
    } else if (!policyDraft.data && policyChangesStepRouteMatch.isExact) {
      fetchPolicyDraft(policyNumber, jobId);
    }
  }, [
    policyDraft.data,
    policyNumber,
    jobId,
    policyChangesStepRouteMatch.isExact,
    fetchPolicyDraft,
    history,
    STATUSES_FOR_REDIRECT
  ]);

  useEffect(() => {
    if (!!policyNumber && !!selectedAgencyCode && !requestChange) {
      getChangeRequestEligibilityInfo(policyNumber, selectedAgencyCode);
    }
  }, [
    policyNumber,
    selectedAgencyCode,
    requestChange,
    getChangeRequestEligibilityInfo
  ]);

  useEffect(() => {
    if (!driverLicenseValidationList.data && !!policyDraft.data) {
      fetchDriverLicenseValidationList();
    }
  }, [
    fetchDriverLicenseValidationList,
    driverLicenseValidationList.data,
    policyDraft.data
  ]);

  return (
    <PolicyChangesProvider {...{ policy: policyDraft.data }}>
      <PolicyChangesHeader showStep2Loader={showStep2Loader} />

      <section id="content">
        <div id="body" className="container">
          {showErrorMessage ? (
            <FlexRow>
              <div className="no-results-info">
                <HelpDeskMessage display="anErrorOccurred" />
              </div>
            </FlexRow>
          ) : (
            <div className="pol__layout__left-sidebar__container">
              <div className="pol__layout__left-sidebar">
                <SidebarStepper />
                <SidebarChangeInformation
                  policyInfo={policyDraft.data}
                  requestChange={requestChange}
                  putPolicyChange={putPolicyChange}
                />
                <UnderwritingTeamInformation policyInfo={policyDraft.data} />
              </div>

              <div className="pol__content-body">
                {!showStep2Loader && (
                  <ContentHeader className={"policy-changes__step-heading"}>
                    {isBindBlocked
                      ? POLICY_HEADERS.BIND_BLOCKED
                      : POLICY_HEADERS[`STEP_${currentStep}`]}
                  </ContentHeader>
                )}

                {isBindBlocked && (
                  <Alert
                    className="driver-changes-alert"
                    type="warning"
                    dismissible={false}
                  >
                    <div>
                      <b>Underwriter Review Needed</b>
                    </div>
                    <div>
                      Your change request and/or policy has items needing review
                      by your underwriter before issuing.
                    </div>
                  </Alert>
                )}

                {ufgagentPolicyChanges &&
                  currentStep === POLICY_WIZARD_STEPS.ONE && (
                    <>
                      <Step1DriverList />
                      <Step1DriverFooter />
                    </>
                  )}

                {ufgagentPolicyChanges &&
                  currentStep === POLICY_WIZARD_STEPS.TWO && (
                    <>
                      <Step2DriverSummary
                        showStep2Loader={showStep2Loader}
                        setShowStep2Loader={setShowStep2Loader}
                        setPolicyDraft={fetchPolicyDraftSucceeded}
                      />
                      <Step2DriverFooter showStep2Loader={showStep2Loader} />
                    </>
                  )}
              </div>
            </div>
          )}
        </div>
      </section>
    </PolicyChangesProvider>
  );
};

const mapStateToProps = state => ({
  policyDraft: state.policyChange.policyDraft,
  requestChange: getRequestChange(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  driverLicenseValidationList: state.policyChange.driverLicenseValidationList
});

const mapDispatchToProps = {
  fetchPolicyDraft,
  getChangeRequestEligibilityInfo,
  putPolicyChange,
  fetchPolicyDraftSucceeded,
  fetchDriverLicenseValidationList
};

PolicyChanges.propTypes = {
  history: PropTypes.object.isRequired,
  policyDraft: PropTypes.object.isRequired,
  fetchPolicyDraft: PropTypes.func.isRequired,
  getChangeRequestEligibilityInfo: PropTypes.func.isRequired,
  selectedAgencyCode: PropTypes.any.isRequired,
  requestChange: PropTypes.object,
  putPolicyChange: PropTypes.func.isRequired,
  fetchPolicyDraftSucceeded: PropTypes.func.isRequired,
  fetchDriverLicenseValidationList: PropTypes.func.isRequired,
  driverLicenseValidationList: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PolicyChanges);
