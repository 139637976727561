import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Accordion } from "@ufginsurance/ui-kit";
import ClaimsActivity from "./ClaimsActivity";
import SavedLosses from "./SavedLosses";
import moment from "moment";
import SubmittedLosses from "./SubmittedLosses";
import { Translations } from "../../translations";

const ClaimsTab = ({
  fetchClaimStatuses,
  agencyCode,
  fetchClaimsOpened,
  employeeUserName,
  fetchClaimPayments,
  fetchClaimSavedLosses,
  SavedLossesList,
  SavedLossesIsLoading,
  SavedLossesError,
  removeClaimsSavedLosses,
  is109015Agent,
  activateClaimsInquiryCard,
  fetchClaimSubmittedLosses,
  SubmittedLossesListError,
  SubmittedLossesList,
  SubmittedLossesListIsLoading,
  claimPaymentsList,
  removeClaimPayment,
  claimPaymentsError,
  claimPaymentDeleteError,
  claimsStatusesError,
  claimsStatusDeleteError,
  claimsStatusesLoading,
  claimsOpenedList,
  removeClaimOpened,
  claimsOpenedError,
  claimOpenedDeleteError,
  claimsStatusesList,
  removeClaimStatus,
  pastFetchClaimsStatusAgencyCode,
  pastFetchedSavedLossesAgencyCode,
  pastFetchedSubmittedAgencyCode,
  pastFetchedClaimsOpenedCode,
  pastFectchedClaimsPaymentCode,
  claimsOpenedLoading,
  claimPaymentsLoading
}) => {
  const [isClaimsActivityOpen, setIsClaimsActivityOpen] = useState(false);
  const [isSavedAndSubmittedOpen, setIsSavedAndSubmittedOpen] = useState(true);

  useEffect(() => {
    if (
      isClaimsActivityOpen &&
      pastFetchClaimsStatusAgencyCode !== agencyCode
    ) {
      fetchClaimStatuses(agencyCode);
    }
  }, [
    agencyCode,
    fetchClaimStatuses,
    isClaimsActivityOpen,
    pastFetchClaimsStatusAgencyCode
  ]);

  useEffect(() => {
    if (
      isClaimsActivityOpen &&
      !!employeeUserName &&
      pastFetchedClaimsOpenedCode !== agencyCode
    ) {
      fetchClaimsOpened(
        agencyCode,
        moment().format("YYYY-MM-DD"),
        employeeUserName
      );
    }
  }, [
    agencyCode,
    fetchClaimsOpened,
    employeeUserName,
    isClaimsActivityOpen,
    pastFetchedClaimsOpenedCode
  ]);

  useEffect(() => {
    if (isClaimsActivityOpen && pastFectchedClaimsPaymentCode !== agencyCode) {
      fetchClaimPayments(agencyCode, moment().format("YYYY-MM-DD"));
    }
  }, [
    agencyCode,
    fetchClaimPayments,
    isClaimsActivityOpen,
    pastFectchedClaimsPaymentCode
  ]);

  const toggleClaimsActivityAcoordian = () => {
    setIsClaimsActivityOpen(!isClaimsActivityOpen);
  };

  const toggleSavedAndSubmittedAccordian = event => {
    if (isSavedAndSubmittedOpen !== event.open) {
      setIsSavedAndSubmittedOpen(!isSavedAndSubmittedOpen);
    }
  };
  return (
    <div id="home-claims-container">
      <Accordion
        isOpen
        onClick={toggleSavedAndSubmittedAccordian}
        textMain="Loss Notices"
        textExtra="View saved and submitted loss notices from the past seven days"
      >
        {isSavedAndSubmittedOpen && (
          <div className="loss-notices-container">
            <div className="saved-losses-container">
              <div className="saved-losses-heading">
                {Translations.home_claims_Tab.saved_losses}
              </div>
              <SavedLosses
                selectedAgencyCode={agencyCode}
                fetchClaimSavedLosses={fetchClaimSavedLosses}
                SavedLossesList={SavedLossesList}
                isLoading={SavedLossesIsLoading}
                error={SavedLossesError}
                removeClaimsSavedLosses={removeClaimsSavedLosses}
                is109015Agent={is109015Agent}
                activateClaimsInquiryCard={activateClaimsInquiryCard}
                pastFetchedSavedLossesAgencyCode={
                  pastFetchedSavedLossesAgencyCode
                }
              />
            </div>
            <div className="submitted-losses-container">
              <div className="submitted-losses-heading">
                {Translations.home_claims_Tab.submitted_losses}
              </div>
              <SubmittedLosses
                fetchClaimSubmittedLosses={fetchClaimSubmittedLosses}
                error={SubmittedLossesListError}
                SubmittedLossesList={SubmittedLossesList}
                isLoading={SubmittedLossesListIsLoading}
                selectedAgencyCode={agencyCode}
                activateClaimsInquiryCard={activateClaimsInquiryCard}
                pastFetchedSubmittedAgencyCode={pastFetchedSubmittedAgencyCode}
              />
            </div>
          </div>
        )}
      </Accordion>
      <Accordion
        onClick={toggleClaimsActivityAcoordian}
        textMain="Claims Activity"
        textExtra="View opened claims and payments from the past seven days and claims statuses, closings and reopenings from the past 14 days"
      >
        {isClaimsActivityOpen && (
          <ClaimsActivity
            claimsStatusesList={claimsStatusesList}
            removeClaimStatus={removeClaimStatus}
            agencyCode={agencyCode}
            employeeUserName={employeeUserName}
            claimsStatusesError={claimsStatusesError}
            claimsStatusDeleteError={claimsStatusDeleteError}
            claimsStatusesLoading={claimsStatusesLoading}
            claimsOpenedList={claimsOpenedList}
            removeClaimOpened={removeClaimOpened}
            claimsOpenedError={claimsOpenedError}
            claimOpenedDeleteError={claimOpenedDeleteError}
            is109015Agent={is109015Agent}
            claimPaymentsList={claimPaymentsList}
            removeClaimPayment={removeClaimPayment}
            claimPaymentsError={claimPaymentsError}
            claimPaymentDeleteError={claimPaymentDeleteError}
            claimsOpenedLoading={claimsOpenedLoading}
            claimPaymentsLoading={claimPaymentsLoading}
          />
        )}
      </Accordion>
    </div>
  );
};

ClaimsTab.propTypes = {
  fetchClaimStatuses: PropTypes.func.isRequired,
  fetchClaimsOpened: PropTypes.func.isRequired,
  fetchClaimPayments: PropTypes.func.isRequired,
  employeeUserName: PropTypes.string,
  fetchClaimSavedLosses: PropTypes.func.isRequired,
  SavedLossesList: PropTypes.array,
  SavedLossesIsLoading: PropTypes.any,
  SavedLossesError: PropTypes.any,
  removeClaimsSavedLosses: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool,
  fetchClaimSubmittedLosses: PropTypes.func.isRequired,
  SubmittedLossesListError: PropTypes.any,
  SubmittedLossesList: PropTypes.array,
  SubmittedLossesListIsLoading: PropTypes.any,
  activateClaimsInquiryCard: PropTypes.func.isRequired,
  agencyCode: PropTypes.string.isRequired,
  claimPaymentsList: PropTypes.array,
  removeClaimPayment: PropTypes.func,
  claimPaymentsError: PropTypes.any,
  claimPaymentDeleteError: PropTypes.any,
  claimsStatusesError: PropTypes.any,
  claimsStatusDeleteError: PropTypes.any,
  claimsStatusesLoading: PropTypes.bool,
  claimsOpenedList: PropTypes.array,
  removeClaimOpened: PropTypes.any,
  claimsOpenedError: PropTypes.any,
  claimOpenedDeleteError: PropTypes.any,
  claimsStatusesList: PropTypes.array,
  removeClaimStatus: PropTypes.any,
  pastFetchClaimsStatusAgencyCode: PropTypes.string,
  pastFetchedSavedLossesAgencyCode: PropTypes.string,
  pastFetchedSubmittedAgencyCode: PropTypes.string,
  pastFectchedClaimsPaymentCode: PropTypes.string,
  pastFetchedClaimsOpenedCode: PropTypes.string,
  claimsOpenedLoading: PropTypes.bool.isRequired,
  claimPaymentsLoading: PropTypes.bool.isRequired
};

export default ClaimsTab;
