import React from "react";
import PropTypes from "prop-types";
import { Accordion, ExternalLink } from "@ufginsurance/ui-kit";
import { Translations } from "../../translations";
import CertificateHistoryForm from "./CertificateHistoryForm";

const ServiceCenterTab = ({
  agencyCode,
  fetchCertificateHistory,
  certificateHistoryList,
  error,
  isLoading
}) => {
  const addPoliciesExternalLink = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/codes/gotoWEBCE.asp?BTN_NEXT=WBSC1&AGENCY_CD=${agencyCode}&page=WBSC1`;

  const removePoliciesExternalLink = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/codes/gotoWEBCE.asp?BTN_NEXT=WBSC7&AGENCY_CD=${agencyCode}&page=WBSC3`;

  return (
    <div id="home-service-center-container" className="service-center">
      <Accordion
        textMain={Translations.home_service_center_tab.certificate_history}
        textExtra={Translations.home_service_center_tab.view_certificate}
        isOpen
        overflowWhenOpen="visible"
      >
        <CertificateHistoryForm
          agencyCode={agencyCode}
          fetchCertificateHistory={fetchCertificateHistory}
          certificateHistoryList={certificateHistoryList}
          certificateHistoryIsLoading={isLoading}
          certificateHistoryError={error}
        />
      </Accordion>

      <Accordion
        overflowWhenOpen="visible"
        textMain={Translations.home_service_center_tab.account_management}
      >
        <div className="service-center-account-management">
          <div className="add-policies">
            <ExternalLink
              to={addPoliciesExternalLink}
              rel="noopener noreferrer"
              target="_self"
            >
              {Translations.home_service_center_tab.add_policies}
            </ExternalLink>
          </div>
          <div>
            <ExternalLink
              to={removePoliciesExternalLink}
              rel="noopener noreferrer"
              target="_self"
            >
              {Translations.home_service_center_tab.remove_policies}
            </ExternalLink>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default ServiceCenterTab;

ServiceCenterTab.propTypes = {
  agencyCode: PropTypes.string,
  fetchCertificateHistory: PropTypes.func,
  certificateHistoryList: PropTypes.array,
  error: PropTypes.object,
  isLoading: PropTypes.bool
};
