import _get from "lodash/get";
import { quotePath, productKeys } from "../../shared/constants";

export const locationCoverages = (quoteData, coverableFixedId, productLine) => {
  const getAllCoveragesFromQuote = coverableFixedId => {
    const path = quotePath.bp7LocationCoverages.replace(
      "bp7BusinessOwners",
      productLine
    );
    const locationCoverageGroup = _get(quoteData, path, []).filter(
      c => String(c.coverableFixedId) === String(coverableFixedId)
    )[0];

    if (!locationCoverageGroup || !locationCoverageGroup.clausesGroups)
      return [];

    const clausesGroups = locationCoverageGroup.clausesGroups;

    return [
      ...(clausesGroups?.coverages || []),
      ...(clausesGroups?.defaults || []),
      ...(clausesGroups?.addlCoverages || []),
      ...(clausesGroups?.addlInsdCoverages || [])
    ].map(f => {
      f.coverableFixedId = coverableFixedId;
      f.coverableType = "location";
      f.coveragesPath = path;
      return f;
    });
  };

  return getAllCoveragesFromQuote(coverableFixedId);
};

// this function runs when you navigate away from step 1...
// to update the coverages for the selected lines of business
export const getLobCoverages = (quoteData, itemData) => {
  const lobCoverages = [
    {
      productLine: "bp7BusinessOwners",
      label: productKeys.bp7BusinessOwners.label,
      locationCoverages: itemData?.bp7LocationFixedID
        ? locationCoverages(
            quoteData,
            itemData?.bp7LocationFixedID,
            "bp7BusinessOwners"
          ) || []
        : []
    },
    {
      productLine: "wcmWorkersComp",
      label: productKeys.wcmWorkersComp.label,
      locationCoverages: itemData?.wcmLocationFixedID
        ? locationCoverages(
            quoteData,
            itemData?.wcmLocationFixedID,
            "wcmWorkersComp"
          ) || []
        : []
    }
    //Filter out lines of business that are not selected on step1 or don't locationcoverages
  ].filter(
    p =>
      (itemData?.linesOfBusiness || []).includes(p.productLine) &&
      p.locationCoverages.length > 0
  );

  return lobCoverages;
};
