import * as types from "./actionTypes";
import * as reportService from "../services/claimsReportsService";
import { logger } from "../../loggers";

const fetchEmailListStarted = () => ({
  type: types.FETCH_EMAIL_LIST_STARTED
});

const fetchEmailListSucceeded = data => ({
  type: types.FETCH_EMAIL_LIST_SUCCEEDED,
  payload: data
});

const fetchEmailListFailed = error => ({
  type: types.FETCH_EMAIL_LIST_FAILED,
  payload: error
});

export const fetchEmailListDetails = agency_code => dispatch => {
  dispatch(fetchEmailListStarted());

  return reportService.getEmailList(agency_code).then(
    res => dispatch(fetchEmailListSucceeded(res.data)),
    error => {
      dispatch(fetchEmailListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
