import "./UpdateOverlayTransparent.scss";

import cn from "classnames";
import React from "react";

const UpdateOverlay = ({ className }) => {
  return <div className={cn("oq__update-overlay__main", className)} />;
};

export default UpdateOverlay;
