import { Translations } from "../../components/translations";
import {
  CLASS_APPETITE_GUIDE,
  AGENT_MANUALS,
  DOCUMENT_LIBRARY,
  THREE_SIXTY_VALUE,
  RISK_CONTROL,
  NEW_BUSINESS,
  VIEW_UNDERWRITING_TEAM_UFG_ONLINE
} from "../../constants/routes";

export const commercialLinks = [
  {
    to: "/commercial/commercial_lines",
    activeClassName: "nav-menu--active",
    text: Translations.commercial.new_submissions
  },
  {
    to: "/commercial/quote_proposal",
    activeClassName: "nav-menu--active",
    text: "Quote Proposals"
  },
  {
    to: AGENT_MANUALS,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.agent_manuals
  },
  {
    to: DOCUMENT_LIBRARY,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.document_library
  },
  {
    to: THREE_SIXTY_VALUE,
    activeClassName: "nav-menu--active",
    text: Translations.commercial._360_value
  },
  {
    to: RISK_CONTROL,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.risk_control
  },
  {
    to: NEW_BUSINESS,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.new_business
  },
  {
    to: CLASS_APPETITE_GUIDE,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.class_appetite_guide
  },
  {
    to: VIEW_UNDERWRITING_TEAM_UFG_ONLINE,
    activeClassName: "nav-menu--active",
    text: Translations.commercial.view_underwriting_team
  }
];
