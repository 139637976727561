import _get from "lodash/get";
import { quotePath } from "../../shared/constants";
import { sortByProperty } from "../../shared/util";

const vehicleCoverages = (quoteData, coverableFixedId, vehicleType) => {
  const getAllCoveragesFromQuote = coverableFixedId => {
    const path = `${quotePath.ca7VehicleCoverages}.${vehicleType.coverageGroupName}`;
    const vehicleCoverageGroup = _get(quoteData, path, []).filter(
      c => c.coverableFixedId === coverableFixedId
    )[0];

    if (!vehicleCoverageGroup || !vehicleCoverageGroup.clausesGroups) return [];

    const clausesGroups = vehicleCoverageGroup.clausesGroups;

    return [
      ...(clausesGroups?.coverages || []),
      ...(clausesGroups?.defaults || []),
      ...(clausesGroups?.addlCoverages || []),
      ...(clausesGroups?.addlInsdCoverages || [])
    ]
      .map(f => {
        f.coverableFixedId = coverableFixedId;
        f.vehicleType = vehicleType;
        f.coverableType = "vehicle";
        f.coveragesPath = path;
        return f;
      })
      .sort(sortByProperty("name"));
  };

  return getAllCoveragesFromQuote(coverableFixedId);
};
export default vehicleCoverages;
