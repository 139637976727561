import qs from "query-string";
import API from "../api";

export const fetchRenewalUpdates = agency_code => {
  return API.agent().get(`/agencies/${agency_code}/renewal-updates`);
};

export const deleteRenewalUpdate = ({
  agency_code,
  process_date,
  stat_type,
  effective_date,
  cbp_indicator,
  policy_number
}) => {
  const queryString = qs.stringify({
    policy_number,
    process_date,
    delete_type: "renewal-update",
    effective_date,
    cbp_indicator,
    status: stat_type,
    origin: "ufg_core" //hardcode until we handle the oden_notice jen story
  });
  return API.agent().delete(
    `/agencies/${agency_code}/${cbp_indicator}/decs-and-audit-bills?${queryString}`
  );
};
