import React from "react";
import {
  Panel,
  Button,
  Input,
  useForm,
  Form,
  FormGroup,
  Table
} from "@ufginsurance/ui-kit";
import AdditionalCoveragesName from "../shared/AdditionalCoveragesName";

import "./CoverageSearch.scss";

const CoverageSearch = ({
  unselectedCoverages,
  formTitle,
  onAddCoverage,
  rowKey
}) => {
  const initialValues = {
    search: ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });
  const { handleOnBlur, handleOnValidate, values, handleOnChange } = form;

  const addCoverage = row => {
    onAddCoverage(row);
  };

  const getAddButton = row => {
    return (
      <Button
        onClick={() => addCoverage(row)}
        className="addCoverageButton"
        wrapperClassName="oq__add-button__small"
        variant="info"
        size="sm"
        dataAttrib={[
          {
            name: "fullstory_oq_add_coverage_from_search",
            value: row.name
          }
        ]}
      >
        Add
      </Button>
    );
  };

  const tableColumns = [
    {
      key: "name",
      label: "Coverage",
      sortable: true,
      element: row => (
        <AdditionalCoveragesName
          coverage={row}
          extraLabel={
            row?.classificationName && (
              <div className="oq__coverage__classname">
                <small>{row.classificationName}</small>
              </div>
            )
          }
        />
      )
    },
    {
      key: "coverageCategoryDisplayName",
      label: "Category",
      sortable: true,
      element: row => (
        <>{row.coverageCategoryDisplayName.replace(/Line\s/g, "")}</>
      ),
      className: "oq__coverage-search-table__category-col__building"
    },
    {
      key: "",
      label: "",
      element: row => getAddButton(row),
      className: "oq__coverage-search-table__add-col__building",
      align: "center"
    }
  ];

  const handleSearchOnChange = ({ field, value }) => {
    handleOnChange({ field, value });
  };

  return (
    <Panel title={`Search for ${formTitle}`} titlebar rounded bgcolor="grey">
      <Form context={form}>
        <FormGroup noOuterPadding className="searchInputRow addPadUnder">
          <Input
            id="search"
            name="search"
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            label="Search Form or Description"
            onChange={handleSearchOnChange}
            value={values.search}
            size="lg"
            maxLength={200}
          />
        </FormGroup>
        {
          <Table
            rowKey={rowKey}
            initialSort="coverageCategoryDisplayName"
            initialDirection="desc"
            columns={tableColumns}
            data={unselectedCoverages.filter(c => {
              if (!values.search) {
                return true;
              }
              return (
                c.name &&
                c.name.toLowerCase().includes(values.search.toLowerCase())
              );
            })}
            showPagination
            itemsPerPage={5}
            noResultsMessage="No results found"
          />
        }
      </Form>
    </Panel>
  );
};

export default CoverageSearch;
