import * as types from "../uwReviewActionTypes";
import * as uwReviewServices from "../uwReviewServices";
import { logger } from "../../loggers";

/*--------UWREVIEW WARNING MESSAGES INFORMATION SECTION---------- */
const fetchUWReviewWarningMessagesServiceStarted = () => ({
  type: types.UW_REVIEW_WARNING_MESSAGES_STARTED
});

const fetchUWReviewWarningMessagesServiceSucceeded = data => ({
  type: types.UW_REVIEW_WARNING_MESSAGES_SUCCEEDED,
  payload: data
});

const fetchUWReviewWarningMessagesServiceFailed = error => ({
  type: types.UW_REVIEW_WARNING_MESSAGES_FAILED,
  payload: error
});
export const fetchUWReviewWarningMessagesInfo = (
  agency_code,
  account_number,
  bond_number,
  is_new_account
) => dispatch => {
  dispatch(fetchUWReviewWarningMessagesServiceStarted());
  return uwReviewServices
    .getUWReviewWarningMessages(agency_code, account_number, bond_number, is_new_account)
    .then(
      response => {
        dispatch(fetchUWReviewWarningMessagesServiceSucceeded(response.data));
      },
      error => {
        dispatch(fetchUWReviewWarningMessagesServiceFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
