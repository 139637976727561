import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FlexRow, Modal } from "@ufginsurance/ui-kit";
import { Translations } from "../../../components/translations";
import TabSelection from "../tabSelection";
import { LOB } from "../constants";
import { Alert } from "react-bootstrap";

const LineOfBusiness = ({
  initialFormValues,
  onClose,
  onContinue,
  isCancelledAgent
}) => {
  const [lineOfBusiness, setLineOfBusiness] = useState(
    initialFormValues?.lineOfBusiness || ""
  );

  const handleLineOfBusinessSelected = selectedLine => {
    setLineOfBusiness(selectedLine);
  };

  const handleContinue = e => {
    e.preventDefault();
    const form = {
      lineOfBusiness
    };
    onContinue({
      form
    });
  };

  return (
    <Modal
      show
      className="quote__select-line-modal"
      onHide={onClose}
      title={
        !isCancelledAgent
          ? Translations.newQuote.lineOfBusiness.header
          : Translations.newQuote.lineOfBusiness.header_sorry
      }
      body={
        isCancelledAgent ? (
          <Alert bsStyle="danger">
            {Translations.error_messages.canceled_or_sold}
          </Alert>
        ) : (
          <form onSubmit={handleContinue}>
            <div className="new-quote-modal__body new-quote-modal--line-of-business">
              <div className="new-quote-modal__lob-selection">
                <p>{Translations.newQuote.lineOfBusiness.label}</p>
                <TabSelection
                  activeTab={lineOfBusiness}
                  onSelectTab={handleLineOfBusinessSelected}
                  tabs={LOB}
                />
              </div>
              <FlexRow className="new-quote-modal__buttons" align="right">
                <Button type="reset" variant="secondary" onClick={onClose}>
                  {Translations.newQuote.lineOfBusiness.buttons.cancel}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!lineOfBusiness}
                >
                  {Translations.newQuote.lineOfBusiness.buttons.continue}
                </Button>
              </FlexRow>
            </div>
          </form>
        )
      }
    />
  );
};

LineOfBusiness.propTypes = {
  initialFormValues: PropTypes.shape({
    lineOfBusiness: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  isCancelledAgent: PropTypes.bool
};

export default LineOfBusiness;
