import qs from "query-string";
import API from "../../api";
import { DateFormat } from "../../components/Factory";

export const getPolicyLossRunsUrl = accountOrPolicyNumber => {
  const queryString = qs.stringify({
    claims: "acct",
    polacctno: accountOrPolicyNumber
  });
  return `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgAgent/Claims/reportcriteriao.aspx?${queryString}`;
};

export const getEmailList = agency_code => {
  return API.agent().get(
    `/agencies/${agency_code}/producers/feature-access/claims-reports`
  );
};

export const postAgencyLossReport = (data, agency_code) => {
  const request = {
    requestor_name: data.name,
    email: data.is109015Agent
      ? `${data.emailTo}@${window.env.REACT_APP_UFG_EMAIL_DOMAIN}`
      : data.emailTo,
    document_format: data.reportFormatTypes,
    order: parseInt(data.sortListTypes || 0, 10),
    begin_date: DateFormat(data.beginDate, "YYYY-MM-DD"),
    end_date: DateFormat(data.endDate, "YYYY-MM-DD"),
    date_type: data.dateSearchDropdown,
    claim_amount: parseInt(
      data.incurredAmount === "" ? 0 : data.incurredAmount
    ),
    claim_status: parseInt(data.claimStatusTypes || 0, 10),
    line_of_business: data.lineOfBusinessList
  };
  return API.agent()
    .post(`/reports/agencies/${agency_code}/claims/loss-run`, request)
    .then(response => response.data);
};

export const getYearToDateLossReportList = agency_code => {
  return API.agent().get(
    `/reports/agencies/${agency_code}/summaries/loss-year-date/list`
  );
};

export const getYearToDateLossViewReport = (agency_code, date) => {
  const queryString = qs.stringify({ date });
  return API.agent().get(
    `/reports/agencies/${agency_code}/summaries/loss-year-date?${queryString}`,
    {
      responseType: "blob"
    }
  );
};

export const getWeeklyPaymentSummaryList = agency_code => {
  return API.agent().get(
    `/reports/agencies/${agency_code}/summaries/billing-weekly-payment/list`
  );
};

export const getWeeklyPaymentSummary = (agency_code, date) => {
  const queryString = qs.stringify({ date });
  return API.agent().get(
    `/reports/agencies/${agency_code}/summaries/billing-weekly-payment?${queryString}`,
    { responseType: "blob" }
  );
};
