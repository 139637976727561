import React, { useEffect } from "react";
import { useBillingResourcesContext } from "../context";
import ServiceCharges from "./ServiceCharges";
import CMSContent from "../../../cms-content/CMSContent";

const Content = () => {
  const { tableOfContentId, fetchContent, billingData } =
    useBillingResourcesContext();

  const contentData = billingData.tableOfContents.data.find(
    c => c.id === tableOfContentId
  );
  const showServiceCharges =
    contentData && contentData?.description === "Service Charges";

  useEffect(() => {
    if (tableOfContentId) {
      fetchContent(tableOfContentId);
    }
  }, [tableOfContentId, fetchContent]);

  return (
    <>
      <CMSContent
        id="cms-content-resources-billing"
        classNames="cms-content cms-content--billing"
        content={billingData?.content?.data?.display_text}
      />

      {showServiceCharges && <ServiceCharges />}
    </>
  );
};

export default Content;
