import React from "react";
import { Button, ButtonBar } from "@ufginsurance/ui-kit";
import { useSuretyDocsFormsContext } from "../context";

export default function ButtonNavigation() {
  const {
    currentSection,
    setCurrentSection,
    updateFormValues,
    resetFormValues
  } = useSuretyDocsFormsContext();

  return (
    <>
      <ButtonBar fullWidth size="lg" id="sdf-nav">
        <Button
          onClick={() => setCurrentSection("bondsContract")}
          variant={currentSection.includes("bonds") ? "primary" : "plain"}
        >
          Bond Form
        </Button>
        <Button
          onClick={() => setCurrentSection("applications")}
          variant={currentSection === "applications" ? "primary" : "plain"}
        >
          Applications
        </Button>
        <Button
          onClick={() => setCurrentSection("riders")}
          variant={currentSection === "riders" ? "primary" : "plain"}
        >
          Riders
        </Button>
        <Button
          onClick={() => setCurrentSection("other")}
          variant={currentSection === "other" ? "primary" : "plain"}
        >
          Other Forms
        </Button>
        <Button
          onClick={() => setCurrentSection("consent")}
          variant={currentSection === "consent" ? "primary" : "plain"}
        >
          Consent of Surety
        </Button>
      </ButtonBar>

      {currentSection.includes("bonds") && (
        <ButtonBar fullWidth size="lg">
          <Button
            onClick={() => {
              setCurrentSection("bondsContract");
              resetFormValues();
            }}
            variant={currentSection === "bondsContract" ? "primary" : "plain"}
          >
            Contract
          </Button>
          <Button
            onClick={() => {
              setCurrentSection("bondsCommercial");
              updateFormValues({
                bondType: "LicenseAndPermit",
                obligeeType: "All",
                keyword: ""
              });
            }}
            variant={currentSection === "bondsCommercial" ? "primary" : "plain"}
          >
            Commercial
          </Button>
        </ButtonBar>
      )}
    </>
  );
}
