import { iconStatus } from "../../constants/iconConstants";

export const iconPolicyStatusFactory = policyStatus => {
  const policyStatusIcon = iconStatus.find(icon => {
    return policyStatus && icon && icon.status
      ? icon.status.toUpperCase() === policyStatus.toUpperCase()
      : "";
  });
  return {
    iconPolicyStatus:
      policyStatusIcon && policyStatusIcon.iconName
        ? policyStatusIcon.iconName
        : "",
    iconPolicyStatusColor:
      policyStatusIcon && policyStatusIcon.color ? policyStatusIcon.color : ""
  };
};
