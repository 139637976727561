import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import Eligibility from "./Eligibility";
import { fetchEligibility } from "../actions/EligibilityAction";
import { updateSuretyQuoteData } from "../actions/suretyQuoteActions";
import { getCacheId } from "../../selectors/cacheId";

const mapStateToProps = state => {
  return {
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    eligibilityListData: state.quickEligibilityList,
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state)
  };
};

const mapDispatchToProps = {
  fetchEligibility,
  updateSuretyQuoteData
};

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility);
