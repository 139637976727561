import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, FlexRow, LoadingIndicator, Panel } from "@ufginsurance/ui-kit";
import cn from "classnames";
import { _set, massageMetadata } from "../../shared/util";

import {
  quotePath,
  productKeys,
  getCoverageGroupPath
} from "../../shared/constants";
import coveragePanels, {
  updateCoverageValue,
  getCoverageDetailsForLogging,
  coveragesMissingRequireTerms,
  applyExclusions
} from "../../shared/coveragePanels";

import {
  saveJurisdictionField,
  saveNonOwnedAutoFields
} from "./lobAdditionalFields";
import { updateWorkersCompLineFields } from "../../step4/workmansCompDetails";

import CoveragesInCards, { displayedCoverages } from "../CoveragesInCards";
import FormSetup from "../../shared/FormSetup";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import OqModal from "../../shared/OqModal.js";
import Step5Context from "../Step5Context";
import {
  processStateData,
  coverageExclusionsForState
} from "./processStateData";
import "./stateDataSection.scss";

const StateDataSection = ({ step5Exclusions }) => {
  const {
    quoteData,
    updateCoveragesPromise,
    updateCoverablesPromise,
    validateCoverablesPromise,
    refreshQuoteFromPC,
    supportingData,
    updateLineDetailsPromise,
    loggingData,
    get,
    quoteIsUpdating,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [statesData, setStatesData] = useState([]);
  const [currentState, setCurrentState] = useState();
  const [panels, setPanels] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [invalidFields, setInvalidFields] = useState({});
  const [invalidFieldsCheck, setInvalidFieldCheck] = useState(false);
  const [continueWasClicked, setContinueWasClicked] = useState(false);
  const [selectedCoveragesChanged, setSelectedCoveragesChanged] =
    useState(false);

  const [scheduleItemOpenInCoverable, setScheduleItemOpenInCoverable] =
    useState();

  const { setStateSpeficIsInvalid } = useContext(Step5Context);

  const validateAndClose = useCallback(() => {
    setContinueWasClicked(false);

    // look at all of the validations and show a toast if there's any for the selected state
    const allCoverableIdsFromSelectedRowState =
      (
        statesData.find(s => s?.coverableName === selectedRow?.coverableName)
          ?.data || []
      ).map(d => d.coverableFixedId) || [];

    if (selectedCoveragesChanged) {
      validateCoverablesPromise({
        coverableIds: allCoverableIdsFromSelectedRowState,
        classIds: [],
        coverableName: "jurisdiction",
        loggingData,
        stateSpecific: selectedRow?.coverableName
      }).then(({ success }) => {
        setSelectedCoveragesChanged(false);
        if (success) {
          // after we get the validation cleared, we have to update
          // the entire quote because it's possible that the changes made to the
          // state specific coverages will affect other coverables -- and we don't get
          // the coverable data in the coverage updates (because that data would be a lot and often)
          // this refresh quote loads the whole quote object which can be pretty big, so we only do it
          // when we close the modal
          refreshQuoteFromPC().then(() => setSelectedRow(null));
        }
      });
    } else {
      setSelectedRow(null);
    }
  }, [
    loggingData,
    refreshQuoteFromPC,
    selectedRow,
    statesData,
    validateCoverablesPromise,
    selectedCoveragesChanged
  ]);

  const handleContinueClickedAfterValueUpdate = useCallback(() => {
    if (continueWasClicked) {
      validateAndClose();
    }
  }, [continueWasClicked, validateAndClose]);

  const saveCoverages = useCallback(
    (coverage, termToUpdate, action = "added") => {
      // object shape of the coverage to be updated:
      //lobData.bp7BusinessOwners.coverages.jurisdictionClausesGroups(filter by coverableFixedId)[0].clausesGroups.
      coverage.updated = true;
      const clauseGroupToSave = {
        coverableFixedId: coverage.coverableFixedId,
        entityName: `entity.${coverage.coverageCategoryCode
          .replace("StdGrp", "")
          .replace("AddlGrp", "")
          .replace("AddlInsdGrp", "")}`,
        coverableName: coverage.coverableName
      };

      _set(
        clauseGroupToSave,
        getCoverageGroupPath(coverage.coverageCategoryCode),
        [coverage]
      );

      // coveragePath eg: "lobData.ca7CommAuto.coverages.hiredAutoClausesGroups"
      // this gets the clause group name from the path (the last item)
      const clauseGroupKey = coverage.coveragesPath.split(".").pop();

      const coveragesToSave = {
        [coverage.productLine]: {
          [clauseGroupKey]: [
            {
              ...clauseGroupToSave,
              clausesGroups: { [coverage.clausesGroupName]: [coverage] }
            }
          ]
        }
      };

      updateCoveragesPromise({
        coveragesToSave,
        productName: coverage.productLine,
        coverageDetails: getCoverageDetailsForLogging({
          coverage,
          termToUpdate,
          action
        })
      }).then(() => {
        setSelectedCoveragesChanged(true);

        handleContinueClickedAfterValueUpdate();
      });
    },
    [handleContinueClickedAfterValueUpdate, updateCoveragesPromise]
  );

  useEffect(() => {
    // pull values from the temporary collection
    const newStatesData = processStateData({ get, quoteData, supportingData });

    // save to the local state
    setStatesData(newStatesData);
  }, [get, quoteData, supportingData]);

  const removeCoverageAndSave = useCallback(
    coverageToRemove => {
      setContinueWasClicked(false);
      coverageToRemove.selected = !coverageToRemove.selected;
      coverageToRemove.terms = [];
      saveCoverages(
        coverageToRemove,
        null,
        coverageToRemove.selected ? "added" : "removed"
      );
    },
    [saveCoverages]
  );

  /**
   * create and store the panels used in the modal for updating coverages
   * - it doesn't return anything, it just stores data in local state
   *
   *  used in the useEffect below after coverages are organized
   */
  useEffect(() => {
    const _panels = [];
    statesData.forEach(s => {
      s.data.forEach(d => {
        const coverages = d?.clausesGroups?.coverages || [];
        if (coverages.length) {
          const coverageExclusions = coverageExclusionsForState(
            s.coverableName,
            d.productLine,
            step5Exclusions,
            supportingData,
            quoteData
          );
          _panels.push({
            panels: massageMetadata(
              coveragePanels({
                fields: coverages,
                coverageExclusions,
                removeCoverageAndSave,
                setCoverageUpdating: quoteIsUpdating,
                setScheduleItemOpenInCoverable,
                quoteData,
                supportingData
              }),
              quoteData
            ),
            allCoverages: applyExclusions(coverages, coverageExclusions),
            productLine: d.productLine,
            coverableName: s.coverableName,
            key: d.entityName
          });
        }
      });
    });

    setPanels(_panels);
    // should only update when statesData is updated - prevents issue during save and close
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesData, step5Exclusions]);

  /**
   * Check the coverages to see if any terms are missing values (or scheduled items)
   *
   *  update flag/state in context with results
   */
  useEffect(() => {
    let missingRequiredTerms = false;
    statesData.forEach(s => {
      s.data.forEach(d => {
        const coverages = d?.clausesGroups?.coverages || [];
        if (coverages.length && coveragesMissingRequireTerms({ coverages }))
          missingRequiredTerms = true;
      });

      setStateSpeficIsInvalid(missingRequiredTerms);
    });
  }, [setStateSpeficIsInvalid, statesData]);

  const openItemEdit = useCallback(
    row => {
      if (!statesData || !statesData.length) return;

      setSelectedRow(row);
      setCurrentState(row.coverableName);
    },
    [statesData]
  );

  const localUpdateCoverageValue = (fieldName, value, allCoverages) => {
    if (quoteIsUpdating) return;

    //if updating coverage:`hired Non Auto `
    if (
      fieldName.startsWith("hiredAuto") ||
      fieldName.startsWith("ca7FFLESurchargeExempt")
    ) {
      saveJurisdictionField({
        allCoverages,
        fieldName,
        value,
        quoteData,
        path: quotePath.ca7JurisdictionCoverable,
        coverableType: "ca7Jurisdiction",
        updateCoverablesPromise,
        callback: ({ error, success }) => {
          setSelectedCoveragesChanged(true);
          handleContinueClickedAfterValueUpdate();
          if (!success)
            toastErrr({
              action: "saveJurisdictionField",
              description: "failed to update special jurisdiction field",
              error,
              misc: {
                fieldName,
                value
              },
              displayMessage: "An error occurred. Coverage(s) were not updated."
            });
        }
      });
      return;
    }

    if (fieldName.startsWith("flexibleRatingIndicator")) {
      const hasWCMContractingClass =
        quoteData?.lobData?.wcmWorkersComp?.coverages?.jurisdictionClausesGroups.some(
          g =>
            g?.clausesGroups?.coverages?.some(
              c => c.codeIdentifier === "WCMContractingClass" && c.selected
            )
        );
      /**
       * OOQ-14811
       * this story includes a scenario where:
       * is Nebraska quote with Contractor class
       * When the user add the endorsement: "Contracting Classification Premium Adjustment Endorsement",
       * then a coverage is added "Contacting Class" and there's a value updated in the
       * jurisdictional coverable for "NE"
       * `contractingClassIndicator=Yes`
       *
       * When coverages are updated, we don't get the coverable data back, so the local dto may
       * have `contractingClassIndicator` as "No"
       *
       * This current hack checks to see if the coverage is on the quote, and if it is, then we
       * include `contractingClassIndicator=Yes` so that coverage doesn't get removed.
       *
       * A future alternative is to have Edge return {lobData.[lob].coverables.jurisdiction} in the response
       * when updating coverages so that we can rely on PC data instead of this work-around.
       */
      const overrides = hasWCMContractingClass
        ? { contractingClassIndicator: "Yes" }
        : {};

      saveJurisdictionField({
        allCoverages,
        fieldName,
        value,
        quoteData,
        path: quotePath.wcm7JurisdictionCoverable,
        coverableType: "wcmJurisdiction",
        overrides,
        updateCoverablesPromise,
        callback: ({ error, success }) => {
          setSelectedCoveragesChanged(true);
          handleContinueClickedAfterValueUpdate();
          if (!success)
            toastErrr({
              action: "saveJurisdictionField",
              description: "failed to update special jurisdiction field",
              error,
              misc: {
                fieldName,
                value
              },
              displayMessage: "An error occurred. Coverage(s) were not updated."
            });
        }
      });
      return;
    }

    if (fieldName.startsWith("nonOwnedAuto")) {
      saveNonOwnedAutoFields({
        allCoverages,
        get,
        fieldName,
        value,
        quoteData,
        path: quotePath.ca7JurisdictionCoverable,
        coverableType: "ca7Jurisdiction",
        updateCoverablesPromise,
        callback: ({ error, success }) => {
          setSelectedCoveragesChanged(true);
          handleContinueClickedAfterValueUpdate();
          if (!success)
            toastErrr({
              action: "saveJurisdictionField",
              description: "failed to update special jurisdiction field",
              error,
              misc: {
                fieldName,
                value
              },
              displayMessage: "An error occurred. Coverage(s) were not updated."
            });
        }
      });
      return;
    }

    //if updating coverage:`unEmploymentId ,bureauId ,ncciIntrastate,stateTaxID,taxIDNumber`
    if (
      fieldName.startsWith("unEmploymentId") ||
      fieldName.match(/bureauId/g) ||
      fieldName.match(/stateTaxID/g) ||
      fieldName.match(/taxIDNumber/g) ||
      fieldName.match(/ncciintrastate/g)
    ) {
      saveJurisdictionField({
        allCoverages,
        fieldName,
        value,
        quoteData,
        updateCoverablesPromise,
        path: quotePath.wcm7JurisdictionCoverable,
        coverableType: "wcmJurisdiction",
        callback: ({ success, error }) => {
          setSelectedCoveragesChanged(true);
          handleContinueClickedAfterValueUpdate();
          if (!success) {
            toastErrr({
              action: "saveJurisdictionField",
              description: "failed to update special jurisdiction field",
              error,
              misc: {
                fieldName,
                value
              },
              displayMessage: "An error occurred. Coverage(s) were not updated."
            });
          }
        }
      });

      return;
    }

    // if updating coverage ncciInterstate
    if (fieldName.match(/ncciid/g)) {
      const fdNames = (fieldName || "").split(".");
      if (fdNames.length > 2) {
        updateWorkersCompLineFields({
          field: fdNames[2],
          value,
          quoteData,
          loggingData,
          updateLineDetailsPromise,
          callback: () => {
            setSelectedCoveragesChanged(true);
            handleContinueClickedAfterValueUpdate();
          }
        });
      }
      return;
    }

    updateCoverageValue({
      fieldName,
      value,
      allCoverages,
      toastErrr,
      saveCoverages
    });
  };

  const updateFormStatus = (formId, fieldErrors) => {
    // save each state's form status in the object
    if (invalidFields[formId] !== !!fieldErrors.length) {
      const newInvalids = {
        ...invalidFields,
        [formId]: !!fieldErrors.length
      };
      setInvalidFields(newInvalids);

      // check all of the states to see if any have errors...
      // then update the invalidFieldsCheck that disables the continue button
      const checkForInvalids = Object.keys(newInvalids).some(f => {
        return newInvalids[f] === true;
      });
      setInvalidFieldCheck(checkForInvalids);
    }
  };

  // temporary loop cache to help determine if should show header for each clasuses group in the MODAL
  let currentProductInPanel = "";

  return (
    <div>
      {!!selectedRow && (
        <OqModal
          className={cn(
            "oq__modal__coverable oq__modal__coverages__state_specific oq__product-coverages",
            {
              scheduleItemOpenInCoverable
            }
          )}
          title={`${currentState} State Specific Coverages`}
          closeIcon={false}
          show={!!selectedRow}
          size="lg"
          onHide={() => {
            setContinueWasClicked(false);
            setSelectedRow(null);
          }}
          overlayHidden={scheduleItemOpenInCoverable}
          body={
            <div className="oq__jurisdictional__coverages">
              {panels &&
                panels.length > 0 &&
                panels
                  .filter(p => p.coverableName === selectedRow.coverableName)
                  .map(p => {
                    const showHeader = p.productLine !== currentProductInPanel;
                    currentProductInPanel = p.productLine;

                    return (
                      <div key={p.key + p.productLine + p.allCoverages.length}>
                        {p.panels.length > 0 && (
                          <FormSetup
                            className={`oq__state-specific__${p.productLine}`}
                            sectionHeader={
                              showHeader ? (
                                <div className="oq_state_specific_lob_title">
                                  {productKeys[p.productLine].label +
                                    " Coverages"}
                                </div>
                              ) : undefined
                            }
                            updateFormStatus={updateFormStatus}
                            formId={`${p.coverableName.replace(/\s/g, "")}_${
                              p.productLine
                            }_${p.key}`}
                            panels={p.panels}
                            handleFormSubmit={() => {}}
                            submitBtnLabel=""
                            saveCurrentValues={(fieldName, value) =>
                              localUpdateCoverageValue(
                                fieldName,
                                value,
                                p.allCoverages
                              )
                            }
                            hideSubmitBtn
                            useCoveragePanel
                          />
                        )}
                      </div>
                    );
                  })}
              <FlexRow align="right">
                <Button
                  className="continue"
                  onMouseDown={() => setContinueWasClicked(true)}
                  onClick={validateAndClose}
                  disabled={invalidFieldsCheck || quoteIsUpdating}
                  variant="primary"
                >
                  {!selectedCoveragesChanged ? "Close" : "Continue"}
                </Button>
              </FlexRow>
            </div>
          }
        />
      )}

      {statesData && statesData.length > 0 && (
        <Panel rounded titlebar bgcolor="grey" title="State Specific Coverages">
          <div className="oq__stateSpecific__container">
            {statesData.map(s => {
              // temporary loop cache to help determine if should show header for each clasuses group
              let currentProduct = "";
              return (
                <Panel
                  key={s.coverableName.replace(/\s/g, "")}
                  rounded
                  highlight="none"
                  collapsible={s.coverageCount > 0}
                  title={
                    <span className="oq__coverable__card__initial-content">
                      <span className="oq__coverable__card__initial-content__title">
                        {s.coverableName} Specific Coverages
                      </span>
                      {s.coverageCount === 0 && "No coverages available"}
                    </span>
                  }
                  isOpen={s.coverageCount > 0}
                  className={cn(
                    "oq__coverable__card oq__coverable oq__coverable__card oq__coverable__state-specific",
                    `oq__coverable__state-specific__${s.coverableName.replace(
                      /\s/g,
                      ""
                    )}`
                  )}
                >
                  {!step5Exclusions && (
                    <LoadingIndicator
                      className="oq_loading_state_coverages oq__view__coverage__loading-metadata"
                      message="Loading coverages"
                    />
                  )}
                  {step5Exclusions && (
                    <>
                      {(s.data || []).map(c => {
                        const coverageFields =
                          c?.clausesGroups?.coverages || [];

                        if (!coverageFields.length) return null;

                        const showHeader =
                          c.productLine !== currentProduct &&
                          coverageFields.filter(c => c.selected).length > 0;

                        // update loop cache to determine if we show the header in the next group
                        currentProduct = c.productLine;

                        const coverageExclusions = coverageExclusionsForState(
                          s.coverableName,
                          c.productLine,
                          step5Exclusions,
                          supportingData,
                          quoteData
                        );

                        const coverages = displayedCoverages({
                          coverages: coverageFields,
                          coverageExclusions
                        });

                        return (
                          <div
                            className="oq__coverable__state-specific__container oq__coverable__card__content__coverages"
                            key={`${c.coverableName.replace(/\s/g, "")}${
                              c.coverableFixedId
                            }${c?.entityName}`}
                          >
                            {showHeader && (
                              <b className="oq__coverable__card__content__coverages__lob-header">
                                {productKeys[c.productLine].label}
                              </b>
                            )}
                            <CoveragesInCards
                              quoteData={quoteData}
                              coverageFields={coverages}
                              coverageExclusions={coverageExclusions}
                              alwaysDisplay
                              coverable={s}
                              editCoverable={openItemEdit}
                            />
                          </div>
                        );
                      })}

                      {s.coverageCount > 0 && (
                        <Button
                          className={`oq__state-specific__open__${s.coverableName.replace(
                            /\s/g,
                            ""
                          )}`}
                          isLink
                          inline
                          onClick={() => openItemEdit(s)}
                        >
                          Edit Coverages
                        </Button>
                      )}
                    </>
                  )}
                </Panel>
              );
            })}
          </div>
        </Panel>
      )}
    </div>
  );
};

export default StateDataSection;
