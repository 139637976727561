import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import CurrentPolicy from "./currentPolicy";
import {
  fetchCurrentSummary,
  fetchOasisDocumentSummary,
  fetchUFGDocumentSummary,
  fetchCurrentPolicyDocument,
  fetchGWCurrentPolicyDocument
} from "./currentPolicyActions";
import {
  getCurrentSummarySelector,
  getCurrentSummaryErrorSelector,
  getOasisDocumentSummarySelector,
  getOasisDocumentSummaryErrorSelector,
  getCurrentSummaryIsLoadingSelector,
  getOasisDocumentSummaryIsLoadingSelector
} from "./currentPolicySelector";
import {
  CURRENT_POLICY_TERM,
  RENEWAL_POLICY_TERM
} from "./policyTermSummary";

const mapStateToProps = state => ({
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  currentSummary: getCurrentSummarySelector(state),
  currentSummaryIsloading: getCurrentSummaryIsLoadingSelector(state),
  currentSummaryError: getCurrentSummaryErrorSelector(state),
  oasisDocumentSummaryCurrent: getOasisDocumentSummarySelector(state, CURRENT_POLICY_TERM),
  oasisDocumentSummaryIsLoadingCurrent: getOasisDocumentSummaryIsLoadingSelector(state, CURRENT_POLICY_TERM),
  oasisDocumentSummaryErrorCurrent: getOasisDocumentSummaryErrorSelector(state, CURRENT_POLICY_TERM),
  oasisDocumentSummaryRenewal: getOasisDocumentSummarySelector(state, RENEWAL_POLICY_TERM),
  oasisDocumentSummaryIsLoadingRenewal: getOasisDocumentSummaryIsLoadingSelector(state, RENEWAL_POLICY_TERM),
  oasisDocumentSummaryErrorRenewal: getOasisDocumentSummaryErrorSelector(state, RENEWAL_POLICY_TERM),
  currentPolicyData: state.currentPolicyData
});

const mapDispatchToProps = {
  fetchCurrentSummary,
  fetchOasisDocumentSummary,
  fetchUFGDocumentSummary,
  fetchCurrentPolicyDocument,
  fetchGWCurrentPolicyDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPolicy);
