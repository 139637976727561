import * as types from "../constants/actionTypes";

const initialState = {
  agencyNumber: undefined
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AGENCY_CODE_CHANGED:
      return {
        ...state,
        agencyNumber: action.payload
      };
    default:
      return state;
  }
};
