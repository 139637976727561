import React, { useContext } from "react";
import { Button } from "@ufginsurance/ui-kit";
import { sortByProperty } from "../../../online-quoting/shared/util";
import QuotingUfgHelperContext from "../QuotingUfgHelperContext";
import QuoteCard from "./QuoteCard";
import "./StoreQuotesLocally.scss";

const StoreQuotesLocallyModal = () => {
  const { savedQuotes, clearAllSaved } = useContext(QuotingUfgHelperContext);

  return (
    <>
      <div className="oq__dev__load">
        {savedQuotes.sort(sortByProperty("timestamp", false)).map(q => (
          <QuoteCard key={q?.quoteId} quote={q} type="saved" />
        ))}
      </div>
      {savedQuotes.length > 0 && (
        <Button onClick={clearAllSaved} variant="tertiary">
          Clear All Saved Quotes
        </Button>
      )}
    </>
  );
};

export default StoreQuotesLocallyModal;
