import { useEffect } from "react";
import { findProductById } from "../../selectors";
import { useCurrentRouteMatch } from "../hooks";

export const useProductRouteValidation = (
  stateProducts,
  selectedProduct,
  setSelectedProduct
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteProductId = currentMatch?.params?.productId;

  useEffect(() => {
    const hasProducts = stateProducts && stateProducts.length > 0;
    const isProductSelectionRouteWithoutSelection =
      hasProducts && !selectedProduct && currentRouteProductId;
    if (isProductSelectionRouteWithoutSelection) {
      const routeProduct = findProductById(
        stateProducts,
        currentRouteProductId
      );
      if (routeProduct) {
        setSelectedProduct(routeProduct);
      }
    }
  }, [
    stateProducts,
    selectedProduct,
    currentRouteProductId,
    setSelectedProduct
  ]);

  useEffect(() => {
    const newProductSelectionChange =
      selectedProduct?.id &&
      currentRouteProductId &&
      selectedProduct.id !== currentRouteProductId;
    if (newProductSelectionChange) {
      const routeProduct = findProductById(
        stateProducts,
        currentRouteProductId
      );
      if (routeProduct) {
        setSelectedProduct(routeProduct);
      }
    }
  }, [
    selectedProduct,
    currentRouteProductId,
    stateProducts,
    setSelectedProduct
  ]);

  return null;
};
