import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";

const propTypes = {};

const CommercialValueContainer = () => {
  return (
    <div>
      <ContentHeader>Commercial 360 Value</ContentHeader>
    </div>
  );
};

CommercialValueContainer.propTypes = propTypes;

export default CommercialValueContainer;
