import React, { useContext } from "react";
import { hasHNO } from "../../shared/util";
import { Panel } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../../OnlineQuotingContext";
const HiredAutoView = () => {
  const { supportingData } = useContext(OnlineQuotingContext);

  const HNOValues = [
    {
      name: "Hired Auto",
      value: !!supportingData?.packages?.hiredNonOwnedAuto?.hiredAuto
    },
    {
      name: "Non-owned Auto",
      value: !!supportingData?.packages?.hiredNonOwnedAuto?.nonOwnedAuto
    }
  ];

  return (
    <>
      {hasHNO(supportingData) && (
        <Panel
          key={"hnoPanel"}
          id={"hnoPanel"}
          bgcolor="white"
          className="oq__view__coverage-panel"
          highlight="blue"
        >
          <div className="oq__view__coverage__title">
            <span className="oq__view__coverage__title-text">
              Hired and Non-owned Auto
            </span>
          </div>
          {HNOValues.map(k => (
            <div key={k} className="oq__view__coverage__term">
              <span className="oq__view__coverage__term-name">{k.name}:</span>
              <span className="oq__view__coverage__term-value">
                {!!k.value ? "Yes" : "No"}
              </span>
            </div>
          ))}
        </Panel>
      )}
    </>
  );
};
export default HiredAutoView;
