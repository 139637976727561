import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import Confirmation from "./Confirmation";
import { clearSuretyQuote } from "../actions/suretyQuoteActions";
import { getCacheId } from "../../selectors/cacheId";
import { postQuickDocuments } from "./actions/documentUpload";
import { fetchDocumentsPacket } from "./actions/documentPacket";
import {
  validateAccountHasBond,
  checkSubmissionStatusPended,
  checkIsItNewAccount,
  checkBondHasBidBond,
  checkBondHasPerformanceAndPayment
} from "../../selectors/confirmationData";

const mapStateToProps = state => {
  return {
    quickSubmissionData: state.quickSubmission.submissionData,
    prevSubmissionData: state.quickSubmission.prevSubmissionData,
    suretyQuickUserAttachments: state.suretyQuickUserAttachments,
    suretyQuote: state.suretyQuote.data,
    cacheId: getCacheId(state),
    isNewAccount: checkIsItNewAccount(state),
    hasBond: validateAccountHasBond(state),
    isSubmissionError: !!state.quickSubmission?.error ?? false,
    isSubmissionStatusPended: checkSubmissionStatusPended(state),
    isBondHasBidBond: checkBondHasBidBond(state),
    isBondHasPerformanceAndPayment: checkBondHasPerformanceAndPayment(state),
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    suretyQuickDocumentsPacket: state.suretyQuickDocumentsPacket
  };
};

// ideally we would map the delete action but that doesn't exist yet
const mapDispatchToProps = {
  clearSuretyQuote,
  postQuickDocuments,
  fetchDocumentsPacket
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
