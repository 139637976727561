import React from "react";
import { Button, Popover } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";

export default function AccountServiceButton({
  buttonDisabled,
  onClickFunction,
  buttonText,
  popoverId,
  hoverText = "To update account status, please contact your underwriter."
}) {
  return (
    <Popover
      id={popoverId}
      placement="top"
      theme="white"
      trigger="hover"
      popoverContent={<span>{hoverText}</span>}
      disabled={!buttonDisabled}
      triggerContent={
        <span>
          <Button size="lg" onClick={onClickFunction} disabled={buttonDisabled}>
            {buttonText}
          </Button>
        </span>
      }
    />
  );
}

AccountServiceButton.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  onClickFunction: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  popoverId: PropTypes.string.isRequired,
  hoverText: PropTypes.string
};
