import { connect } from "react-redux";
import MoratoriumAlert from "./MoratoriumAlert";
import { fetchMoratoriumListings } from "./../../actions/moratoriumActions";
import { cancelQuote } from "../../top-action-cards/new-quote/actions/newQuoteActions";

const mapStateToProps = state => {
  return {
    ...state.moratorium,
    quoteStarted: state.newQuote.quoteStarted
  };
};

const mapDispatchToProps = {
  fetchMoratoriumListings,
  cancelQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(MoratoriumAlert);
