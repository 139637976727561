import _set from "lodash/set";
import React from "react";
import { logger } from "../../loggers";
import {
  bopQuestionsreversFilters,
  questionCodesToFormat
} from "../shared/constants";
import QuestionToolTip from "./QuestionToolTip";

const INELIGIBLE_WHEN =
  "This applicant is not eligible to be quoted online. Please review our online eligibility guidelines.";

const getQuestions = (details, productLine) => {
  const panels = [];
  const panel = {};
  const _details = details[productLine];

  _set(panel, "title", "");
  const rows = [];

  if (!_details || !_details.length) return [];

  //OOQ-4182 formating the label to include bullet points.
  const questionLabel = item => {
    const { displayKey, code, tooltip } = item;

    //OOQ-4182 formating the label to include bullet points.
    const subQuestions = displayKey.split("-");

    const label =
      questionCodesToFormat.includes(code) && !!subQuestions.length ? (
        <>
          {subQuestions[0]}
          <ul>
            {subQuestions.map((s, i) => {
              if (i > 0) {
                return <li key={s}>{s}</li>;
              }
              return null;
            })}
          </ul>
        </>
      ) : (
        displayKey
      );

    return (
      <>
        {label} {tooltip && <QuestionToolTip tooltip={tooltip} code={code} />}
      </>
    );
  };

  //content
  _details.forEach(x => {
    const fielditem = {
      label: "",
      labelElement: questionLabel(x),
      path: x.code,
      component:
        x.questionType.toLowerCase() === "boolean"
          ? "gw-pl-yes-no-group"
          : x.questionType,
      required: x.required || x.filters.length > 0, //OOQ-4181 make all the fields with filters required
      requiredError: `${x.displayKey} is required.`,
      display: x.filters.length === 0, //x.display, //TODO: mule droped the display flag used before.
      options: x.choices.map(o => {
        return {
          code: o.choiceCode,
          name: o.displayKey,
          optionValue: o.displayKey
        };
      }),
      order: x.order,
      defaultValue:
        x.answer === "true"
          ? true
          : x.answer === "false"
          ? false
          : !x.answer
          ? null
          : x.answer,
      className: "OneColumn",
      onChange: ({ field, values, updateForm }) => {
        // track when the user answers "yes" to any question
        if (field.value === true) {
          logger.event({
            category: "OQ_eligibility",
            action: "answered",
            label: x.code,
            value: 1
          });
        }

        // this is custom functionality specific to the Eligibility Questions on step3
        // it's used to hack an issue where switch values are defaulted to "true" when first displayed
        if (bopQuestionsreversFilters[field.field]) {
          const _values = {};
          bopQuestionsreversFilters[field.field].forEach(y => {
            _values[y.id] = y.default;
          });

          updateForm({ values: { ..._values, [field.field]: field.value } });
        }

        // if the user selects "yes", and this causes a quote-stopping condition
        //... track if the user changes the answer back to "no"
        if (
          (x.ineligibleWhen === "true" && field.value === false) ||
          (x.ineligibleWhen === "false" && field.value === true)
        ) {
          logger.event({
            category: "OQ_eligibility",
            action: "changed",
            label: x.code,
            value: 0
          });
        }

        // this validation trigger is in the 'onChange' so that the error appears immediately
        // if it only existed in the 'validation' section below, errors would only appear on blur
        if (
          (x.ineligibleWhen === "true" && values[field.field]) ||
          (x.ineligibleWhen === "false" && !values[field.field])
        ) {
          updateForm({
            errors: {
              [field.field]: [INELIGIBLE_WHEN]
            }
          });
        } else {
          updateForm({
            errors: {
              [field.field]: []
            }
          });
        }

        return true;
      },
      // validation func to ensure continue is disabled correclty using 'invalidFields'
      validation:
        x.ineligibleWhen === "true"
          ? {
              validatorfunc: value => {
                if (value) return false;
                return true;
              },
              errorMessage: INELIGIBLE_WHEN
            }
          : x.ineligibleWhen === "false"
          ? {
              validatorfunc: value => {
                if (!value) return false;
                return true;
              },
              errorMessage: INELIGIBLE_WHEN
            }
          : null,
      filters: Array.isArray(x.filters)
        ? x.filters.map(f => {
            return {
              id: f.questionCode,
              value: f.answer.toLowerCase(),
              display: f.display,
              required: f.required,
              pattern: f.answer
            };
          })
        : null
    };

    if (fielditem.component.toLowerCase() === "string") {
      fielditem.dataType = "textArea";
    }
    const fields = [];
    fields.push(fielditem);
    rows.push({ fields });
  });

  _set(
    panel,
    "rows",
    rows.sort((a, b) => {
      //sort by order:
      const A = a.fields[0].order;
      const B = b.fields[0].order;
      return A < B ? -1 : A > B ? 1 : 0;
    })
  );
  panels.push(panel);
  return panels;
};

export default getQuestions;
