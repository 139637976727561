import API from "../../api";

// Non-bonds.
export const getDocuments = (agencyCode, formType) => {
  return API.surety().get(
    `/documents-and-forms/${agencyCode}/downloadable-forms?form_type=${formType}`
  );
};

export const getValidAgencyStates = (agencyCode, category) =>
  API.surety().get(
    `/documents-and-forms/${agencyCode}/valid-states?category=${category}`
  );

export const getBondDocuments = (
  agencyCode,
  category,
  state,
  bondType,
  obligeeType
) => {
  return API.surety().get(
    `/documents-and-forms/${agencyCode}/bond-forms?category=${category}&state_name=${state}&bond_form_type=${bondType}&obligee_type=${obligeeType}`
  );
};

export const getForm = (formNumber, editionDate) =>{
  if (!editionDate){
    return API.agent().get(
      `/form?form_number=${formNumber}`,
      { responseType: "blob" }
    );  
  }
  return API.surety().get(
    `/documents/forms?form_number=${formNumber}&edition_date=${editionDate}`,
    { responseType: "blob" }
  );
};
