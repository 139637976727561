import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import ClaimsInquiryFormContainer from "../containers/ClaimsInquiryFormContainer";
import { Modal } from "@ufginsurance/ui-kit";

const ClaimsInquiry = ({ handleClose, isActivated }) => {
  return (
    <div className="claim-inquiry-container">
      <Modal
        title={Translations.Claims_Inquiry.claims_inquiry}
        show={isActivated}
        onHide={handleClose}
        body={<ClaimsInquiryFormContainer />}
        subTitle={Translations.Claims_Inquiry.claims_inquiry_text}
      />
    </div>
  );
};

ClaimsInquiry.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isActivated: PropTypes.bool
};

export default ClaimsInquiry;
