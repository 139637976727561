import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";

const propTypes = {};

const RiskControlContainer = () => {
  return (
    <div>
      <ContentHeader>Risk Control</ContentHeader>
    </div>
  );
};

RiskControlContainer.propTypes = propTypes;

export default RiskControlContainer;
