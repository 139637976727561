import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback
} from "react";
import { useHistory } from "react-router-dom";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getisSpecialtyPortalView,
  getActiveSpecialtyCompanyBranch,
  getIsMercerSpecialtyBrokerPortalView
} from "../../portalview/changePortalViewStateSelectors";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getStoreState } from "../../store";
import * as routes from "../../constants/routes";
import * as billingServices from "./services/billingResourceServices";

const BillingResourcesContext = createContext({
  tableOfContentId: null,
  serviceChargeStates: {
    previousCompanyBranch: "invalidCompanyBranch",
    stateListData: [],
    isLoading: false,
    error: null
  },
  tableOfContents: {
    previousFetchedProductId: null,
    data: [],
    isLoading: false,
    error: null
  },
  currentHeader: {
    sequence: null,
    type: "",
    description: "",
    id: ""
  },
  content: {
    data: null,
    isLoading: false,
    error: null
  }
});

const FETCH_BILLING_TABLE_OF_CONTENTS = "FETCH_BILLING_TABLE_OF_CONTENTS";
const UPDATE_TABLE_OF_CONTENT_ID = "UPDATE_TABLE_OF_CONTENT_ID";
const FETCH_BILLING_CONTENT = "FETCH_BILLING_CONTENT";

export const useBillingResourcesContext = () =>
  useContext(BillingResourcesContext);

const billingReducer = (state, action) => {
  switch (action.type) {
    // set the currentHeader based on what is in the url
    case UPDATE_TABLE_OF_CONTENT_ID:
      return {
        ...state,
        tableOfContentId: action.payload
      };
    case FETCH_BILLING_TABLE_OF_CONTENTS:
      // return all billing menu data
      return {
        ...state,
        tableOfContents: {
          ...state.tableOfContents,
          data: action.payload
        }
      };
    case FETCH_BILLING_CONTENT: // returns cms data
      return {
        ...state,
        content: {
          ...state.tableOfContents,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export function BillingResourcesProvider({ ssoSession, ...props }) {
  const { serviceChargeStates, tableOfContents, content, tableOfContentId } =
    useBillingResourcesContext();

  const [billingData, updateBillingData] = useReducer(billingReducer, {
    serviceChargeStates,
    tableOfContents,
    content,
    tableOfContentId
  });

  const history = useHistory();
  const storeState = getStoreState();

  const isSpecialtyPortalView = getisSpecialtyPortalView(storeState);
  const activeSpecialtyCompanyBranch =
    getActiveSpecialtyCompanyBranch(storeState);
  const defaultAgentState = sessionSelector.getActiveAgencyState(storeState);
  const isMercerSpecialtyPortalView =
    getIsMercerSpecialtyBrokerPortalView(storeState);

  const agencyCode = getSelectedAgencyCode(storeState);

  const fetchTableOfContents = activeAgencyBranch => {
    billingServices
      .getTableOfContents(activeAgencyBranch)
      .then(
        res => {
          if (res && res.data && res.data.length > 0) {
            updateBillingData({
              type: FETCH_BILLING_TABLE_OF_CONTENTS,
              payload: res.data
            });
          }
        },
        () => {}
      )
      .finally(() => {});
  };

  const fetchContent = useCallback(contentId => {
    billingServices
      .getContent(contentId)
      .then(
        res => {
          if (res && res.data) {
            updateBillingData({
              type: FETCH_BILLING_CONTENT,
              payload: res.data
            });
          }
        },
        () => {}
      )
      .finally(() => {});
  }, []);

  const updateTableOfContentId = contentId => {
    updateBillingData({
      type: UPDATE_TABLE_OF_CONTENT_ID,
      payload: contentId
    });
  };

  useEffect(() => {
    if (
      !tableOfContents.isLoading &&
      tableOfContents.data &&
      tableOfContents.data.length > 0
    ) {
      history.push(
        `${routes.BILLING_RESOURCES_HEADER}/${tableOfContents.data[0].id}`
      );
    }
  }, [history, tableOfContents.data, tableOfContents.isLoading]);
  return (
    <BillingResourcesContext.Provider
      value={{
        agencyCode,
        isSpecialtyPortalView,
        activeSpecialtyCompanyBranch,
        defaultAgentState,
        isMercerSpecialtyPortalView,
        billingData,
        fetchTableOfContents,
        updateTableOfContentId,
        tableOfContentId: billingData.tableOfContentId,
        fetchContent
      }}
      {...props}
    >
      <div id="billingResources">{props.children}</div>
    </BillingResourcesContext.Provider>
  );
}
