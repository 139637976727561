import { connect } from "react-redux";
import AgencyProfile from "./AgencyProfile";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";

const mapStateToProps = state => ({
  agentCode: sessionSelector.getAgentCode(state),
  token: state?.oidc?.user?.access_token,
  agencyName: sessionSelector.getActiveAgencyName(state),
  agencyCode: sessionSelector.getActiveAgencyCode(state),
  isAgencyAdministrator: sessionSelector.isAgencyAdministrator(state),
  selectedAgencyCode: getSelectedAgencyCode(state)
});

export default connect(mapStateToProps, null)(AgencyProfile);
