import {
  buildAddressLine,
  formatZip,
  getIsNewAccountOrObligee,
  getAllowExistingObligeePoBoxOnly
} from "./../shared/util";

let cancelSearch;

const minSearchCharacters = 3;

const formValues = {
  obligee_name: "",
  obligee_account_number: "",
  obligee_address_line: "",
  obligee_building_number: "",
  obligee_street_name: "",
  obligee_unit_type: "",
  obligee_unit_number: "",
  obligee_pobox: "",
  obligee_zip: "",
  obligee_city: "",
  obligee_state: "",
  obligee_type: "",
  obligee_address_is_pobox: false,
  confidence: ""
};

const formErrors = {
  obligee_name: [],
  obligee_account_number: [],
  obligee_address_line: [],
  obligee_zip: [],
  obligee_city: [],
  obligee_state: [],
  obligee_type: []
};

// ------------
//   SEARCH
// ------------

export const search = ({
  value,
  setObligeeOptions,
  fetchBondObligeeList,
  quickBondObligeeList
}) => {
  // if search is empty, then empty the saved option data

  if (
    !value ||
    value.length < minSearchCharacters ||
    !!quickBondObligeeList.data.error_code
  ) {
    if (cancelSearch) cancelSearch();
    setObligeeOptions([]);
    return false;
  }

  fetchBondObligeeList(value);
};

// ------------
//   SELECT
// ------------
export const select = ({
  value,
  form,
  obligeeOptions,
  setDisableFields,
  setObligeeOptions,
  today
}) => {
  // if Obligee Name has an error, clear it out
  if (form.errors.contactName) {
    form.updateForm({ errors: { contactName: [] } });
  }

  const selectedObligee = obligeeOptions?.find(a => a.obligee_number === value);

  //account deselected
  if (!value && !selectedObligee) {
    //reset form values
    form.updateForm({
      values: formValues,
      errors: formErrors
    });
    setObligeeOptions([]);
    setDisableFields(false);
    return;
  }

  if (!!value && selectedObligee) {
    const isCreatingNewObligee = getIsNewAccountOrObligee(
      selectedObligee.obligee_number
    );
    // Disable address fields when user selects existing obligee.
    if (!isCreatingNewObligee) setDisableFields(true);

    let obligee_address_line = "";

    // existing obligees could have only pobox value
    if (
      !!selectedObligee.obligee_street_name ||
      !!selectedObligee.obligee_pobox
    ) {
      obligee_address_line = buildAddressLine({
        building_number: selectedObligee.obligee_building_number,
        street_name: selectedObligee.obligee_street_name,
        unit_type: selectedObligee.obligee_unit_type,
        unit_number: selectedObligee.obligee_unit_number,
        pobox: selectedObligee.obligee_pobox
      });
    }

    form.updateForm({
      values: {
        ...formValues,
        obligee_name: selectedObligee.obligee_account_name,
        obligee_account_number: selectedObligee.obligee_number,
        obligee_address_line,
        obligee_building_number: selectedObligee.obligee_building_number,
        obligee_street_name: selectedObligee.obligee_street_name,
        obligee_unit_type: selectedObligee.obligee_unit_type,
        obligee_unit_number: selectedObligee.obligee_unit_number,
        obligee_pobox: selectedObligee.obligee_pobox,
        obligee_zip: formatZip(selectedObligee.obligee_zip),
        obligee_city: selectedObligee.obligee_city,
        obligee_state: selectedObligee.obligee_state,
        obligee_type: selectedObligee.obligee_type,
        obligee_address_is_pobox:
          getAllowExistingObligeePoBoxOnly(selectedObligee),
        confidence: 100,
        obligee_verify_date: isCreatingNewObligee ? null : today
      },
      errors: formErrors
    });
  } else {
    setDisableFields(false);
    form.updateForm({
      values: formValues,
      errors: formErrors
    });
  }
};
