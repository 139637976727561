import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";
import { v4 } from "uuid";

const serviceCenterReducer = (
  state = initialState.serviceCenterSummary,
  action
) => {
  switch (action.type) {
    case types.FETCH_COMMERCIAL_LIST_DATES_STARTED:
      return {
        ...state,
        commercialDateIsLoading: true,
        commercialDateList: [],
        commercialDateError: false
      };
    case types.FETCH_COMMERCIAL_LIST_DATES_SUCCEEDED:
      return {
        ...state,
        commercialDateIsLoading: false,
        commercialDateList: action.payload.map(n => ({
          id: v4(),
          ...n
        })),
        commercialDateError: false
      };
    case types.FETCH_COMMERCIAL_LIST_DATES_FAILED:
      return {
        ...state,
        commercialDateIsLoading: false,
        commercialDateList: [],
        commercialDateError: action.payload
      };
    case types.FETCH_PERSONAL_LIST_DATES_STARTED:
      return {
        ...state,
        isLoading: true,
        personalDateList: [],
        error: false
      };
    case types.FETCH_PERSONAL_LIST_DATES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        personalDateList: action.payload.map(n => ({
          id: v4(),
          ...n
        })),
        error: false
      };
    case types.FETCH_PERSONAL_LIST_DATES_FAILED:
      return {
        ...state,
        isLoading: false,
        personalDateList: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default serviceCenterReducer;
