import { v4 } from "uuid";
import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const homePersonalReducer = (state = initialState.homeCommercial, action) => {
  switch (action.type) {
    case types.FETCH_PERSONAL_POLICY_DECS_STARTED:
      return {
        ...state,
        policyDecsLoading: true,
        policyDecsError: null
      };
    case types.FETCH_PERSONAL_POLICY_DECS_SUCCEEDED:
      return {
        ...state,
        policyDecsList: action.payload.flatMap(result => {
          return result.accounts.flatMap(account => {
            return account.policies.map(policy => {
              return {
                id: v4(),
                insured_name: account.insured_name,
                account_number: account.account_number,
                processed_date: result.processed_date,
                source_system: account.source_system,
                ...policy
              };
            });
          });
        }),
        policyDecsLoading: false,
        policyDecsError: null
      };
    case types.FETCH_PERSONAL_POLICY_DECS_FAILED:
      return {
        ...state,
        policyDecsLoading: false,
        policyDecsList: [],
        policyDecsError: action.payload
      };

    case types.FETCH_PERSONAL_CLIENT_NOTICES_STARTED:
      return {
        ...state,
        clientNoticesLoading: true,
        clientNoticesError: null,
        deleteClientNoticeError: null
      };
    case types.FETCH_PERSONAL_CLIENT_NOTICES_SUCCEEDED:
      return {
        ...state,
        clientNoticesList: (action.payload || []).map(r => {
          return {
            id: v4(),
            ...r
          };
        }),
        clientNoticesLoading: false,
        clientNoticesError: null
      };
    case types.FETCH_PERSONAL_CLIENT_NOTICES_FAILED:
      return {
        ...state,
        clientNoticesLoading: false,
        clientNoticesError: action.payload,
        clientNoticesList: []
      };
    case types.DELETE_PERSONAL_CLIENT_NOTICES_SUCCEEDED:
      return {
        ...state,
        deleteClientNoticeError: null
      };
    case types.DELETE_PERSONAL_CLIENT_NOTICES_FAILED:
      return {
        ...state,
        deleteClientNoticeError: action.payload
      };
    case types.RESET_PERSONAL_CLIENT_NOTICES:
      return {
        ...state,
        clientNoticesLoading: false,
        clientNoticesError: null,
        deleteClientNoticeError: null,
        clientNoticesList: []
      };

    default:
      return state;
  }
};

export default homePersonalReducer;
