import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { LoadingIndicator, Button, FlexRow } from "@ufginsurance/ui-kit";
import InstallmentDetails from "./InstallmentDetails";
import { Translations } from "../../../components/translations";
import {
  fetchInstallmentSchedule,
  fetchInstallmentDocument
} from "../../../actions/installment";
import {
  CORE_IN_FORCE_STATUSES,
  GUIDEWIRE_IN_FORCE_STATUSES
} from "../../../constants/dictionary";

const InstallmentScheduleContainer = ({
  accountNumber,
  activeAgencyCode,
  fetchInstallmentDocument,
  fetchInstallmentSchedule,
  installmentDetails = {},
  policyDetails
}) => {
  useEffect(() => {
    if (accountNumber && activeAgencyCode) {
      fetchInstallmentSchedule(accountNumber, activeAgencyCode);
    }
  }, [accountNumber, activeAgencyCode, fetchInstallmentSchedule]);

  const handlePrint = () => {
    fetchInstallmentDocument(installmentDetails.print_installment_data);
  };

  const { isLoading } = installmentDetails;
  const hasInstallments =
    installmentDetails &&
    installmentDetails.installments &&
    installmentDetails.installments.length > 0;

  const anyPolicyInforce = policyDetails.some(pd =>
    [
      ...Object.values(CORE_IN_FORCE_STATUSES),
      ...GUIDEWIRE_IN_FORCE_STATUSES
    ].includes(pd && pd.status_key && pd.status_key.toUpperCase())
  );

  return !anyPolicyInforce ? (
    <div className="installment-error-message">
      {Translations.account_overview.installmentSchedule.error_message}
      <a href="mailto:directbill@unitedfiregroup.com">
        directbill@unitedfiregroup.com
      </a>
    </div>
  ) : (
    <div className="installment-satement">
      {hasInstallments && (
        <FlexRow>
          <div className="flex-row installment-note">
            {Translations.account_overview.installmentSchedule.lines}
          </div>
          <div className="align-right print-button">
            <Button onClick={handlePrint} variant="primary">
              {Translations.general.print_report}
            </Button>
          </div>
        </FlexRow>
      )}
      <div>
        {isLoading ? (
          <LoadingIndicator />
        ) : hasInstallments ? (
          <InstallmentDetails installments={installmentDetails.installments} />
        ) : (
          <p>{Translations.validators.installments_table_error_account}</p>
        )}
      </div>
    </div>
  );
};

InstallmentScheduleContainer.propTypes = {
  fetchInstallmentSchedule: PropTypes.func.isRequired,
  activeAgencyCode: PropTypes.string.isRequired,
  installmentDetails: PropTypes.any.isRequired,
  accountNumber: PropTypes.string.isRequired,
  fetchInstallmentDocument: PropTypes.func.isRequired,
  policyDetails: PropTypes.array
};

const mapStateToProps = state => ({
  installmentDetails: state.installmentDetails,
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  fetchInstallmentSchedule,
  fetchInstallmentDocument
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentScheduleContainer);
