import React, { useState, useEffect } from "react";
import { printPdf } from "../shared/documents/helpers";
import { Modal, LoadingIndicator } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../help-desk/helpDeskMessage";

const CMSContent = ({ id, classNames, content }) => {
  const [showPleaseWait, setShowPleaseWait] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onStart = () => setShowPleaseWait(true);

  const onError = () => setShowErrorMessage(true);

  const onSuccess = () => {
    setShowPleaseWait(false);
    setShowErrorMessage(false);
  };

  const onComplete = () => onSuccess();

  const isPrintContentEvent = target => {
    if (target?.tagName?.toLowerCase() !== "a") return false;
    const path = new URL(
      target?.getAttribute("href"),
      window.location.origin
    )?.pathname?.toLowerCase();
    if (!path?.includes("document_library/print")) return false;
    return true;
  };

  const isInContentScope = target => {
    return (
      !target?.getAttribute("class")?.includes("cms-content") &&
      !!target?.tagName
    );
  };

  const handleClickEvent = event => {
    let target = event?.target;
    let found = false;
    while (isInContentScope(target)) {
      if (isPrintContentEvent(target)) {
        found = true;
        break;
      }
      target = target?.parentElement;
    }
    if (!found) return;
    event?.preventDefault();
    const path = new URL(target?.getAttribute("href"), window.location.origin)
      ?.pathname;
    const parts = path.split("/").reverse();
    printPdf({
      form_type: parts[1],
      form_number: parts[0],
      onStart,
      onSuccess,
      onError
    });
  };

  const interceptCMSDocumentClickEvent = () => {
    if (!id) return;
    const element = document.querySelector("#" + id);
    if (!element) return;
    element.addEventListener("click", handleClickEvent, false);
  };

  useEffect(() => {
    interceptCMSDocumentClickEvent();
    // eslint-disable-next-line
  }, []);

  // TODO: figure out what the a11y for this div would be
  return (
    <>
      <div
        className={classNames + " cms-content"}
        id={id}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      ></div>
      <Modal
        show={showPleaseWait}
        onHide={onComplete}
        body={
          showErrorMessage ? (
            <HelpDeskMessage
              display="questionsOrAssistance"
              before="There was an error loading your document."
            />
          ) : (
            <LoadingIndicator />
          )
        }
      />
    </>
  );
};

export default CMSContent;
