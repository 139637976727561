// Inland Marine - Builders Risk, Constants.

export const DEDUCTIBLES = [
  { amount: 1000, factor: 1 },
  { amount: 2500, factor: 0.9 },
  { amount: 5000, factor: 0.85 },
  { amount: 10000, factor: 0.75 }
];

export const PROTECTION_CLASSES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10"
];

export const PROJECT_LIMITS = {
  Frame: 1500000,
  Noncombustible: 3500000,
  Masonry: 3500000,
  "Fire Resistive": 3500000
};

export const CONSTRUCTION_TYPES = [
  "Frame",
  "Masonry",
  "Noncombustible",
  "Fire Resistive"
];

export const RATE_RULES = {
  blockedStates: ["FL"],
  blockedRegion: {
    AR: {
      zips: ["72002", "72103", "72201"],
      counties: [],
      cities: ["Little Rock"]
    },
    CA: {
      zips: [],
      counties: [],
      cities: ["Los Angeles", "San Francisco"]
    },
    IL: {
      zips: [],
      counties: [],
      cities: ["Chicago"]
    },
    LA: {
      zips: [],
      counties: [
        "ASCENSION",
        "ASSUMPTION",
        "CAMERON",
        "IBERIA",
        "JEFFERSON",
        "LAFOURCHE",
        "ORLEANS",
        "PLAQUEMINES",
        "ST. BERNARD",
        "ST. CHARLES",
        "ST. JAMES",
        "ST. JOHN THE BAPTIST",
        "ST. MARTIN",
        "ST. MARY",
        "ST. TAMMANY",
        "TANGIPAHOA",
        "TERREBONNE",
        "VERMILION",
        "WASHINGTON"
      ],
      cities: []
    },
    PA: {
      zips: ["19019", "19050", "19082", "19092", "19093", "19099"],
      counties: [],
      cities: ["Philadelphia", "Phila"]
    },
    NJ: {
      zips: ["07101", "07102", "07103", "07104", "07105", "07106"],
      counties: [],
      cities: ["Newark", "Camden"]
    },
    NV: {
      zips: [],
      counties: [],
      cities: ["Las Vegas"]
    },
    TX: {
      zips: [],
      counties: [
        "ARANSAS",
        "BRAZORIA",
        "CALHOUN",
        "CAMERON",
        "CHAMBERS",
        "GALVESTON",
        "JEFFERSON",
        "KENEDY",
        "KLEBERG",
        "MATAGORDA",
        "NUECES",
        "REFUGIO",
        "SAN PATRICIO",
        "WILLACY"
      ],
      cities: []
    }
  },
  // ignore territory breakdown
  // territory doesn't impact implementation
  // rates of states in multiple territories will be the identical
  constructionType: {
    Frame: {
      //territory 1
      CA: 0.25,
      NV: 0.25,
      OR: 0.25,
      AZ: 0.23,
      CO: 0.26,
      WY: 0.35,
      MT: 0.3,
      ID: 0.23,
      //territory 2
      IL: 0.34,
      IN: 0.34,
      IA: 0.3,
      MN: 0.3,
      NE: 0.32,
      ND: 0.34,
      SD: 0.34,
      WI: 0.3,
      //territory 3
      OH: 0.29,
      KY: 0.3,
      AR: 0.3,
      KS: 0.3,
      MO: 0.3,
      MS: 0.31,
      PA: 0.29,
      NJ: 0.31,
      //territory 4
      LA: 0.3,
      AL: 0.3,
      TX: 0.27 /*FL: EXCLUDED*/,
      //territory 5
      NM: 0.28,
      OK: 0.31 /*TX: 0.27*/,
      //territory 6
      UT: 0.25
    },
    Masonry: {
      //territory 1
      CA: 0.15,
      NV: 0.15,
      OR: 0.15,
      AZ: 0.12,
      CO: 0.14,
      WY: 0.2,
      MT: 0.17,
      ID: 0.12,
      //territory 2
      IL: 0.2,
      IN: 0.18,
      IA: 0.15,
      MN: 0.15,
      NE: 0.2,
      ND: 0.2,
      SD: 0.2,
      WI: 0.2,
      //territory 3
      OH: 0.17,
      KY: 0.17,
      AR: 0.16,
      KS: 0.15,
      MO: 0.15,
      MS: 0.15,
      PA: 0.15,
      NJ: 0.17,
      //territory 4
      LA: 0.18,
      AL: 0.17,
      FL: 0.17,
      TX: 0.15,
      //territory 5
      NM: 0.15,
      OK: 0.17 /*TX: 0.15*/,
      //territory 6
      UT: 0.15
    },
    Noncombustible: {
      //territory 1
      CA: 0.15,
      NV: 0.15,
      OR: 0.15,
      AZ: 0.12,
      CO: 0.14,
      WY: 0.2,
      MT: 0.17,
      ID: 0.12,
      //territory 2
      IL: 0.2,
      IN: 0.18,
      IA: 0.15,
      MN: 0.15,
      NE: 0.2,
      ND: 0.2,
      SD: 0.2,
      WI: 0.2,
      //territory 3
      OH: 0.17,
      KY: 0.17,
      AR: 0.16,
      KS: 0.15,
      MO: 0.15,
      MS: 0.15,
      PA: 0.15,
      NJ: 0.17,
      //territory 4
      LA: 0.18,
      AL: 0.17,
      FL: 0.17,
      TX: 0.15,
      //territory 5
      NM: 0.15,
      OK: 0.17 /*TX: 0.15*/,
      //territory 6
      UT: 0.15
    },
    "Fire Resistive": {
      //territory 1
      CA: 0.1,
      NV: 0.1,
      OR: 0.1,
      AZ: 0.08,
      CO: 0.08,
      WY: 0.15,
      MT: 0.12,
      ID: 0.09,
      //territory 2
      IL: 0.1,
      IN: 0.1,
      IA: 0.1,
      MN: 0.1,
      NE: 0.1,
      ND: 0.1,
      SD: 0.1,
      WI: 0.1,
      //territory 3
      OH: 0.1,
      KY: 0.1,
      AR: 0.1,
      KS: 0.1,
      MO: 0.1,
      MS: 0.1,
      PA: 0.1,
      NJ: 0.1,
      //territory 4
      LA: 0.1,
      AL: 0.1,
      FL: 0.1,
      TX: 0.1,
      //territory 5
      NM: 0.1,
      OK: 0.12 /*TX: 0.10*/,
      //territory 6
      UT: 0.1
    }
  },
  windhailDeductible: {
    OH: "0.01",
    KY: "0.01",
    AR: "0.01",
    KS: "0.01",
    MO: "0.01",
    MS: "0.01",
    PA: "0.01",
    NJ: "0.01",
    AL: {
      0.02: {
        counties: ["Mobile", "Baldwin"]
      }
    },
    CO: {
      0.01: {
        counties: [
          "Larimer",
          "Boulder",
          "Jefferson",
          "El Paso",
          "Pueblo",
          "Weld",
          "Adams",
          "Arapahoe"
        ]
      },
      0.02: {
        counties: [
          "Logan",
          "Sedgwick",
          "Phillips",
          "Yuma",
          "Washington",
          "Morgan",
          "Elbert",
          "Lincoln",
          "Kit Carson",
          "Cheyenne",
          "Kiowa",
          "Crowley",
          "Otero",
          "Bent",
          "Prowers",
          "Las Animas",
          "Baca"
        ]
      }
    },
    LA: {
      0.02: {
        counties: [
          "Calcasieu",
          "Jefferson Davis",
          "Acadia",
          "Lafayette",
          //"St. Martin",
          "Iberville",
          //"Ascension",
          //"Assumption",
          //"St. James",
          "Livingston",
          //"St. John the Baptist",
          //"St. Charles",
          //"Tangipahoa",
          //"Washington",
          "St. Tammany",
          "Orleans"
        ]
      }
    },
    MT: {
      0.02: {
        counties: [
          "Cascade",
          "Gallatin",
          "Big Horn",
          "Carter",
          "Custer",
          "Fallon",
          "Powder River",
          "Rosebud",
          "Treasure",
          "Yellowstone"
        ]
      }
    },
    NM: {
      0.02: {
        counties: [
          "Colfax",
          "Union",
          "Mora",
          "Harding",
          "San Miguel",
          "Guadalupe",
          "Quay",
          "De Baca",
          "Curry",
          "Roosevelt",
          "Chaves",
          "Lea",
          "Eddy"
        ]
      }
    },
    TX: {
      0.02: {
        counties: [
          "Hidalgo",
          "Brooks",
          "Jim Wells",
          "Live Oak",
          "Bee",
          "Goliad",
          "Victoria",
          "Jackson",
          "Liberty",
          "Hardin",
          "Orange",
          "Wharton",
          "Fort Bend",
          "Harris"
        ]
      }
    },
    WY: {
      0.02: {
        counties: [
          "Johnson",
          "Natrona",
          "Sheridan",
          "Albany",
          "Campbell",
          "Converse",
          "Crook",
          "Goshen",
          "Laramie",
          "Niobrara",
          "Platte",
          "Weston"
        ]
      }
    }
  }
};

export const STATUS = {
  InProgress: "InProgress",
  UnderReview: "UnderReview",
  Delete: "Delete"
};

export const ADDITIONAL_INSURED_TYPES = [
  { value: "FIRSTLOSSPAYEE_UFG", label: "1st Loss Payee" },
  { value: "FIRSTMORTGAGEE_UFG", label: "1st Mortgagee" },
  { value: "SECONDLOSSPAYEE_UFG", label: "2nd Loss Payee" },
  { value: "SECONDMORTGAGEE_UFG", label: "2nd Mortgagee" },
  { value: "THIRDLOSSPAYEE_UFG", label: "3rd Loss Payee" },
  { value: "THIRDMORTGAGEE_UFG", label: "3rd Mortgagee" },
  {
    value: "ADDNINSUREDORINTEREST_UFG",
    label: "Additional Insured or Interest"
  },
  { value: "CONSALE", label: "Contract of Sale" },
  {
    value: "EXTENSIONTOREALPROPERTYLESSOR_UFG",
    label: "Extension of Insured Status to Real Property Lessor"
  },
  { value: "LENDLOSS", label: "Lenders Loss Payable" },
  { value: "LESSOR", label: "Lessor" },
  { value: "LESSORLEASEDEQUIP_UFG", label: "Lessor of Leased Equipment" },
  { value: "LIEN", label: "Lienholder" },
  { value: "LOSSPAY", label: "Loss Payable" },
  { value: "OTHERCONTRACTINGPARTY_UFG", label: "Other Contracting Party" }
];

export const buildersRiskErrors = {
  address_line: [],
  account_name: [],
  account_number: [],
  account_search_input: [],
  account_search: [],
  account_type: [],
  city: [],
  comments: [],
  contractor_name: [],
  deductible: [],
  deductible_modification_factor: [],
  documents: [],
  effective_date: [],
  first_name: [],
  last_name: [],
  premium: [],
  producer_code: [],
  producer: [],
  state: [],
  underwriter: [],
  zip: []
};
