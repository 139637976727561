import React, { useState, useEffect, useCallback } from "react";
import OqModal from "../shared/OqModal";
import { LoadingIndicator } from "@ufginsurance/ui-kit";

const RatingQuoteModal = () => {
  const [message, setMessage] = useState(0);
  const messages = [
    {
      title: "Preparing quote",
      message:
        "Please wait while we review your submission and prepare the quote."
    },
    {
      title: "Preparing quote",
      message: "This should only take a minute or two."
    }
  ];

  const intervalTimer = useCallback(() => setMessage(message + 1), [message]);

  useEffect(() => {
    if (message === messages.length - 1) {
      return;
    }
    const id = setInterval(intervalTimer, 30000);
    return () => clearInterval(id);
  }, [message, messages.length, intervalTimer]);

  return (
    <OqModal
      title={messages[message]?.title}
      show
      closeIcon={false}
      onHide={() => {}}
      body={
        <div className="oq__dummy-modal-message">
          <LoadingIndicator message={messages[message]?.message} />
        </div>
      }
    />
  );
};

export default RatingQuoteModal;
