import React, { useContext } from "react";
import * as routes from "../../constants/routes";
import { Button, LoadingIndicator } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const QuoteErrorReturnToStep1 = () => {
  const { clearLocalQuote, quoteRecovery } = useContext(OnlineQuotingContext);

  const startOver = () => {
    clearLocalQuote({ goto: routes.COMMERCIAL_LINES });
  };

  return (
    <div className="oq__quote-failure">
      <h2>
        <br />
        <br />
        We have encountered an issue while processing your quote.
      </h2>
      {quoteRecovery > 0 && (
        <div>
          <br />
          <LoadingIndicator message="Please wait a moment while we attempt to resolve the issue." />
          <br />
          <div>or</div>
        </div>
      )}
      {quoteRecovery === -1 && (
        <div>
          <br /> <h3>We were unable to recover from the issue.</h3>
          <br />
          <HelpDeskMessage display="questionsOrAssistance" />
          <br />
          <br />
          <p>or</p>
        </div>
      )}
      <div>
        <Button isLink onClick={startOver}>
          Click here to start a new quote
        </Button>
      </div>
    </div>
  );
};

export default QuoteErrorReturnToStep1;
