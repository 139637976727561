import React from "react";

const LocationCardContents = ({ location }) => {
  const coverableDetailItems = [
    { label: "Protection Class", value: location.fireProtection }
  ];

  return (
    <div className="oq__coverable__card__content oq__coverable__card__content__locations">
      <div className="oq__coverable__card__content__column-container">
        <div className="oq__coverable__card__content__column">
          <ul className="">
            {coverableDetailItems.map(c => {
              if (c.visible !== false)
                return (
                  <li
                    key={c.label}
                    className="oq__coverable__card__content__item"
                  >
                    <b className="oq__coverable__card__content__item-label">
                      {c.label}:
                    </b>
                    <span className="oq__coverable__card__content__item-value">
                      {c.value}
                    </span>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LocationCardContents;
