import { useState, useEffect } from "react";
import * as FullStory from "@fullstory/browser";

const isDisabledForEmployee = employeeUsername => {
  const disabledEmployeeUsernames =
    window.env.REACT_APP_FULLSTORY_DISABLE_EMPLOYEE_WHITELIST.split(" ") || [];
  const match =
    disabledEmployeeUsernames?.find(
      username =>
        employeeUsername &&
        username.toLowerCase() === employeeUsername.toLowerCase()
    ) || false;
  return !!match;
};

export const useFullStory = (
  fullStoryUserId,
  agentFirstAndLastName,
  agentEmail,
  employeeUsername
) => {
  const [fullStoryInitialized, setFullStoryInitialized] = useState(false);
  useEffect(() => {
    if (
      !fullStoryInitialized &&
      fullStoryUserId &&
      agentFirstAndLastName &&
      agentEmail
    ) {
      const disableFullStory =
        window.env.REACT_APP_FULLSTORY_DISABLE === "true";
      const isDisabled =
        disableFullStory || isDisabledForEmployee(employeeUsername);
      setFullStoryInitialized(true);
      if (!isDisabled) {
        const organizationId = window.env.REACT_APP_FULLSTORY_ORGANIZATION_ID;
        const enableDebug =
          window.env.REACT_APP_FULLSTORY_ENABLE_DEBUG === "true";
        const enableDevMode =
          window.env.REACT_APP_FULLSTORY_ENABLE_DEVMODE === "true";
        FullStory.init({
          orgId: organizationId,
          debug: enableDebug,
          devMode: enableDevMode
        });
        FullStory.identify(fullStoryUserId, {
          displayName: agentFirstAndLastName,
          email: agentEmail
        });
      }
    }
  }, [
    fullStoryInitialized,
    fullStoryUserId,
    agentFirstAndLastName,
    agentEmail,
    employeeUsername
  ]);
  return null;
};
