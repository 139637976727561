export const sortClaims = (claims, claimsField) =>
  [...claims].sort(function(a, b) {
    return a[claimsField] > b[claimsField]
      ? 1
      : a[claimsField] < b[claimsField]
        ? -1
        : 0;
  });

export const DATE_FORMAT = "MM/DD/YYYY";

export const CLOSED_STATUS = "Closed";
