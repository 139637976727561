import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import CMSContent from "../../cms-content/CMSContent";

const Content = ({
  content,
  isContentLoading,
  contentError,
  tableOfContentsError
}) => {
  const sanitizedHtml = sanitizeHtml(content?.display_text || "", {
    allowedTags: [
      "address",
      "article",
      "aside",
      "footer",
      "header",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "hgroup",
      "main",
      "nav",
      "section",
      "blockquote",
      "dd",
      "div",
      "dl",
      "dt",
      "figcaption",
      "figure",
      "hr",
      "li",
      "main",
      "ol",
      "p",
      "pre",
      "ul",
      "a",
      "abbr",
      "b",
      "bdi",
      "bdo",
      "br",
      "cite",
      "code",
      "data",
      "dfn",
      "em",
      "i",
      "kbd",
      "mark",
      "q",
      "rb",
      "rp",
      "rt",
      "rtc",
      "ruby",
      "s",
      "samp",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "time",
      "u",
      "var",
      "wbr",
      "caption",
      "col",
      "colgroup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
      "font",
      "nl",
      "strike",
      "iframe",
      "img"
    ],
    allowedClasses: {
      "*": ["*"]
    },
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
      "*": [
        "style",
        "size",
        "colspan",
        "cellpadding",
        "cellspacing",
        "width",
        "height"
      ]
    }
  });

  return (
    <>
      {!contentError && content && (
        <CMSContent
          id="cms-content-agent-manuals"
          classNames="col-sm-12"
          content={sanitizedHtml}
        />
      )}
      {isContentLoading && (
        <div className="col-sm-12">
          <LoadingIndicator />
        </div>
      )}
      {(contentError || tableOfContentsError) && !isContentLoading && (
        <div className="col-sm-12 friendly-error-messaging">
          <HelpDeskMessage display="anErrorOccurred" />
        </div>
      )}
    </>
  );
};

Content.propTypes = {
  content: PropTypes.object,
  contentError: PropTypes.object,
  tableOfContentsError: PropTypes.object,
  isContentLoading: PropTypes.bool
};

export default withRouter(Content);
