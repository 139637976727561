import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translations } from "../../components/translations";
import { DateFormat } from "../../components/Factory";
import { LoadingIndicator, Currency, Table, Icon } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";

const PolicyDecsTable = ({
  policyDecsList,
  removePolicyDec,
  openPolicyDecsPdf,
  is109015Agent
}) => {
  const columns = [
    {
      key: "insured_name",
      label: Translations.commercial_tab.policyholder,
      group: true,
      element: row => (
        <>
          {row && row.is_service_center && <span className="asterik">*</span>}
          {row.insured_name}
        </>
      )
    },
    {
      key: "account_number",
      label: Translations.commercial_tab.account_number,
      group: true
    },
    {
      key: "policy_number",
      label: Translations.commercial_tab.policy_number,
      element: row => (
        <Link to={`${routes.ACCOUNT_HOME}/${row.policy_number}`}>
          {row.policy_number}
        </Link>
      )
    },
    {
      key: "policy_and_job_number",
      label: "",
      group: true,
      element: row => (
        <span>
          <span className="file_icon">
            <Icon
              icon="farFileAlt"
              onClick={openPolicyDecsPdf(
                row.policy_number,
                row.processed_date,
                row.job_number,
                row.account_number
              )}
            />
          </span>
          <div
            className={
              row.policy_number.toString() +
              row.job_number?.toString() +
              " LoadingIndicator"
            }
            style={{ display: "none" }}
          >
            <LoadingIndicator />
          </div>
        </span>
      )
    },
    {
      key: "effective_date",
      label: Translations.commercial_tab.effective_date,
      element: row => DateFormat(row.effective_date, "MM/DD/YYYY")
    },
    { key: "status_description", label: Translations.commercial_tab.type },
    { key: "dec_code", label: Translations.commercial_tab.dec },
    {
      key: "premium",
      label: Translations.commercial_tab.premium,
      element: row =>
        !isNaN(row?.premium ?? 0) && <Currency amount={row.premium} />
    },
    {
      key: "source_system",
      label: Translations.commercial_tab.remove,
      align: "center",
      element: row =>
        !is109015Agent &&
        row.source_system !== "oasis" && (
          <div
            className="remove-icon"
            role="presentation"
            onClick={() => removePolicyDec(row, row.processed_date)}
          >
            <Icon icon="fasTimesCircle" />
          </div>
        )
    }
  ];
  return (
    <Table
      rowKey="id"
      groupHeaderKey="processed_date"
      groupHeaderContents={row => (
        <>Process Date: {DateFormat(row.processed_date, "MM/DD/YYYY")}</>
      )}
      columns={columns}
      data={policyDecsList}
      showPagination
      itemsPerPage={20}
    />
  );
};

PolicyDecsTable.propTypes = {
  policyDecsList: PropTypes.array,
  removePolicyDec: PropTypes.func,
  openPolicyDecsPdf: PropTypes.func,
  is109015Agent: PropTypes.bool
};
export default PolicyDecsTable;
