import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";
import { Button, DropdownNonForm, FlexRow } from "@ufginsurance/ui-kit";

const AppQuoteStatusForm = ({ getPdf, filter, values, error, filteredAppQuoteStatusList }) => {
  const translations = Translations.reports.tabUnderwriting.appQuoteStatus;

  const handleStatusChange = value => {
    if (value.value === values.status) return;
    const newValues = {
      ...values,
      status: value.value
    };
    filter(newValues);
  };

  const handleNumberOfDaysChange = value => {
    if (value.value === values.numberOfDays) return;
    const newValues = {
      ...values,
      numberOfDays: value.value
    };
    filter(newValues);
  };

  return (
    <div className="app-quote-status-container">
      <FlexRow>
        <div className="app-quote-status-dropdown">
          <DropdownNonForm
            label={translations.numberOfDays.label}
            id="numberOfDays"
            name="numberOfDays"
            value={values.numberOfDays}
            onChange={handleNumberOfDaysChange}
            options={Object.keys(translations.numberOfDays.options).map(
              key => ({
                value: key,
                label: translations.numberOfDays.options[key]
              })
            )}
            isClearable={false}
            compactIndicator
            size="md"
          />
        </div>
        <div className="app-quote-status-dropdown">
          <DropdownNonForm
            label={translations.status.label}
            id="status"
            name="status"
            value={values.status}
            onChange={handleStatusChange}
            options={Object.keys(translations.status.options).map(key => ({
              value: key,
              label: translations.status.options[key]
            }))}
            isClearable={false}
            compactIndicator
            size="md"
          />
        </div>
        <div className="align-right app-quote-status-report-buttons">
          <Button labelSpace onClick={getPdf} variant="primary" disabled={error || (filteredAppQuoteStatusList?.length === 0 ?? true)}>
            {translations.getPdf}
          </Button>
        </div>
      </FlexRow>
    </div>
  );
};

AppQuoteStatusForm.propTypes = {
  getPdf: PropTypes.func,
  filter: PropTypes.func,
  values: PropTypes.object,
  error: PropTypes.any,
  filteredAppQuoteStatusList: PropTypes.array
};

export default AppQuoteStatusForm;
