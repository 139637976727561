import React, { useReducer, useState, useEffect } from "react";
import {
  Alert,
  Button,
  Icon,
  Modal,
  Table,
  Anchor,
  FlexRow
} from "@ufginsurance/ui-kit";
import { convertBytesToMB, exceedsMaxSize } from "../../surety/shared/utils";
import DragAndDropAttachments from "../../components/commercial/commercial-lines/DragAndDropAttachments";
import { ALLOWED_FILE_TYPES } from "../../quick-program/quick-quoting/constants/suretyDictionary";
import {
  postRiskIndicationDocument,
  deleteRiskIndicationDocument
} from "../shared/services/index";
import { v4 } from "uuid";

/**
 * Files are held in state until cancel or submit is clicked, then the form
 * values (BuildersRisk.js) are updated.
 */
function DocumentUploadModal({ form, allowedFileTypes = ALLOWED_FILE_TYPES }) {
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { values, updateForm } = form;

  const [modal, updateModal] = useReducer((m, u) => ({ ...m, ...u }), {
    isOpen: false,
    isSuccess: false,
    showAlert: false,
    documents: values.documents || []
  });

  useEffect(() => {
    updateModal({ documents: values.documents });
  }, [values.documents]);

  const handleRemoveAttachment = doc => {
    const filteredDocs = modal.documents.filter(o => o.name !== doc.name);
    if (doc.uploaded) {
      doc.toDelete = true;
    } else {
      updateModal({ documents: filteredDocs });
    }

    if (!exceedsMaxSize(filteredDocs)) {
      updateModal({
        showAlert: false
      });
      setAlertMessage("");
    } else {
      updateModal({ showAlert: true });
      setAlertMessage("The combined filesize is too large.");
    }
  };

  const handleOnSelectFiles = documents => {
    const allDocs = modal.documents.length
      ? [...modal.documents, ...documents]
      : documents;
    const uniqueAttachments = Array.from(
      new Map(allDocs.map(att => [att.name, att])).values()
    );

    // Blocks file names that have a '+' in them
    if (documents.some(doc => doc.name.includes("+"))) {
      updateModal({ showAlert: true });
      setAlertMessage("File names cannot contain the '+' character.");
    } else if (!exceedsMaxSize(uniqueAttachments)) {
      updateModal({
        documents: uniqueAttachments,
        showAlert: false
      });
      setAlertMessage("");
    } else {
      updateModal({ showAlert: true });
      setAlertMessage("The combined filesize is too large.");
    }
  };

  const handleOnCancel = () => {
    values.documents.forEach(_ => {
      if (_.toDelete) {
        _.toDelete = false;
      }
      if (_.actionFailed) {
        _.actionFailed = false;
      }
    });
    updateModal({
      documents: values.documents || [],
      isOpen: false,
      showAlert: false
    });
  };

  const uploadDocuments = () => {
    setIsLoading(true);
    const filesToUpload = (modal?.documents ?? []).filter(_ => !_.uploaded);
    const filesToDelete = (modal?.documents ?? []).filter(_ => _.toDelete);
    Promise.allSettled(
      filesToUpload
        .map(doc =>
          postRiskIndicationDocument({
            riskIndicationId: values.risk_indication_id,
            agencyId: values.agency_code,
            file: doc
          })
        )
        .concat(
          filesToDelete.map(doc =>
            deleteRiskIndicationDocument(
              values.risk_indication_id,
              doc.name,
              values.agency_code
            )
          )
        )
    )
      .then(data => {
        data.forEach((datum, index) => {
          if (datum.status !== "fulfilled") {
            if (index < filesToUpload.length) {
              filesToUpload[index].actionFailed = true;
            } else {
              filesToDelete[index - filesToUpload.length].actionFailed = true;
            }
          } else if (
            datum.status === "fulfilled" &&
            index < filesToUpload.length
          ) {
            filesToUpload[index].uploaded = true;
            filesToUpload[index].actionFailed = false;
          } else if (datum.status === "fulfilled") {
            filesToDelete[index - filesToUpload.length].uploaded = false;
            filesToDelete[index - filesToUpload.length].actionFailed = false;
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
        updateForm({
          values: {
            ...values,
            documents: modal.documents.filter(
              _ => _.uploaded || (!_.toDelete && !_.actionFailed)
            )
          }
        });
        if (modal.documents.filter(_ => _.actionFailed === true).length === 0)
          updateModal({ isOpen: false, showAlert: false });
      });
  };

  const failedUploads = modal.documents.filter(
    _ => !_.uploaded && _.actionFailed
  );
  const failedDeletes = modal.documents.filter(
    _ => _.toDelete && _.actionFailed
  );

  return (
    <div>
      <Button
        variant="primary"
        icon="fasArrowCircleUp"
        onClick={() => updateModal({ isOpen: true })}
        className="mt-1"
      >
        Upload Documents
      </Button>

      <Modal
        title="Document Upload"
        show={modal.isOpen}
        onHide={handleOnCancel}
        body={
          <>
            {(failedDeletes?.length > 0 || failedUploads?.length > 0) && (
              <>
                <Alert type="error" dismissible={false}>
                  <FlexRow className="builders-risk-failed-uploads">
                    <span>
                      {failedUploads.length > 0 && (
                        <>
                          <div>The following files failed to upload:</div>
                          <ul>
                            {failedUploads.map(_ => (
                              <li key={v4()}>
                                <strong>{_.name}</strong>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {failedDeletes.length > 0 && (
                        <>
                          <div>The following files failed to delete:</div>
                          <ul>
                            {failedDeletes.map(_ => (
                              <li key={v4()}>
                                <strong>{_.name}</strong>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      <div>
                        Please try again or email them directly to your
                        underwriter. You can also email your underwriter to
                        disregard files that should be deleted.
                      </div>
                    </span>
                    <Anchor
                      onClick={uploadDocuments}
                      variant="red"
                      className="pull-right"
                    >
                      Try again
                    </Anchor>
                  </FlexRow>
                </Alert>
                <br />
              </>
            )}
            <DragAndDropAttachments
              id="view-attachments-file-input"
              allowMultiple
              allowedFileTypes={allowedFileTypes}
              onSelectFiles={handleOnSelectFiles}
            />

            <div className="upload-filesize mt-1">
              <i>
                We only accept the following file types{" "}
                {allowedFileTypes.join(", ")}
              </i>
              <br />
              <i>
                <b>Note* </b>
                <span>Total size of all attachments cannot exceed 10mb.</span>
              </i>
            </div>

            <hr />

            <p className="lead">Your Attachments</p>

            {modal.showAlert && (
              <Alert
                className="alert-message mb-1"
                type="error"
                onDismiss={() => {
                  updateModal({ showAlert: false });
                  setAlertMessage("");
                }}
              >
                {alertMessage}
              </Alert>
            )}

            <Table
              data={modal.documents.filter(_ => !_.toDelete) || []}
              rowKey="name"
              columns={[
                {
                  key: "name",
                  label: "Name",
                  sortable: true
                },
                {
                  label: "Size",
                  className: "size",
                  key: "fileSize",
                  align: "center",
                  element: row => (
                    <span role="presentation">
                      {convertBytesToMB(row.size)} mb
                    </span>
                  )
                },
                {
                  label: "Remove",
                  className: "remove",
                  key: "removeFile",
                  align: "center",
                  element: row => {
                    return (
                      <Icon
                        className="remove-icon"
                        icon="fasTimesCircle"
                        onClick={() => handleRemoveAttachment(row)}
                      />
                    );
                  }
                }
              ]}
            />

            <div className="modal-form-buttons">
              <Button onClick={handleOnCancel}>Cancel</Button>
              <Button
                variant="primary"
                disabled={
                  (!modal.documents?.length && !values.documents?.length) ||
                  isLoading
                }
                onClick={uploadDocuments}
                spinner={isLoading}
              >
                Submit
              </Button>
            </div>
          </>
        }
      />
    </div>
  );
}

export default DocumentUploadModal;
