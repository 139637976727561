import React, { useState, useEffect, useContext } from "react";
import OnlineQuotingContext from "../OnlineQuotingContext";
import * as api from "../../services/billingService";
import { Select, FormGroup, Form, useForm } from "@ufginsurance/ui-kit";

//constant for payment methods
const OQBillingPaymentMethodList = [
  { label: "One Time EFT, Credit, or Debit Card", value: "policyHolderSweep" }
];

const BillingPaymentMethod = ({
  activeAgencyCode,
  formIsValid,
  setFormIsValid
}) => {
  //use OnlineQuoting Context
  const { updateSupportingDataPromise, quoteIsUpdating, supportingData } =
    useContext(OnlineQuotingContext);

  // initial values for the fields for the useForm hook
  const initialValues = {
    billingPaymentMethod:
      supportingData?.billingData?.billingPaymentMethod || ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  //use form context
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = form;

  useEffect(() => {
    const isValid = !invalidFields.length;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [formIsValid, setFormIsValid, invalidFields]);

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState();

  //call the agency weep api to determin if the agent enrolled for agency sweep
  //when entring the form first time
  useEffect(() => {
    if (!availablePaymentMethods && activeAgencyCode) {
      api
        .getDirectBillPayNowEnrollment(activeAgencyCode)
        .then(results => {
          let paymentMethod = [];
          paymentMethod = OQBillingPaymentMethodList;

          /*
          // OOQ-6772 : temporary removal of agency sweep 
          // fixes a billing problem

          paymentMethod.push({
            label: "Sweep Agent Account",
            value: "agencySweep"
          });

          */
          setAvailablePaymentMethods(paymentMethod);

          //save in supporting data
          const billingData = {
            ...supportingData.billingData,
            paymentMethodToken: results.data?.token
          };

          //if nothing is selected for payment method and if the agent enrolled
          //for agency sweep, default the form value to agency sweep
          if (!values.billingPaymentMethod) {
            billingData.billingPaymentMethod = "policyHolderSweep";
            updateForm({
              values: {
                billingPaymentMethod: "policyHolderSweep"
              },
              errors: { billingPaymentMethod: [] }
            });
          }
          updateSupportingDataPromise({
            dataToMergeAndSave: {
              billingData
            }
          });
        })
        .catch(() => {
          setAvailablePaymentMethods(OQBillingPaymentMethodList);
          //if nothing is selected for payment method and when agent not enrolled
          //for agency sweep, default the form value to policy holder sweep
          if (!values.billingPaymentMethod) {
            updateForm({
              values: {
                billingPaymentMethod: "policyHolderSweep"
              },
              errors: { billingPaymentMethod: [] }
            });

            //save in supporting data
            const billingData = {
              ...supportingData.billingData,
              billingPaymentMethod: "policyHolderSweep"
            };
            updateSupportingDataPromise({
              dataToMergeAndSave: {
                billingData
              }
            });
          }
        });
    }
  }, [
    activeAgencyCode,
    availablePaymentMethods,
    supportingData.billingData,
    updateForm,
    updateSupportingDataPromise,
    values.billingPaymentMethod
  ]);

  //handle payment method change and save down payment enetered
  const handleOnChangePaymentMethod = field => {
    handleOnChange(field);

    //save in supporting data
    const billingData = {
      ...supportingData.billingData
    };
    billingData.billingPaymentMethod = field.value;
    updateSupportingDataPromise({
      dataToMergeAndSave: {
        billingData
      }
    });
  };

  return (
    <Form context={form}>
      <FormGroup>
        <Select
          label="Choose Method for Down Payment"
          placeholder="Select Payment Method"
          id="billingPaymentMethod"
          name="billingPaymentMethod"
          onChange={handleOnChangePaymentMethod}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.billingPaymentMethod}
          options={availablePaymentMethods || []}
          className="payment__method"
          isClearable={false}
          disabled={quoteIsUpdating}
          required
        />
      </FormGroup>
    </Form>
  );
};

export default BillingPaymentMethod;
