import React from "react";
import { SidebarNav } from "../menus/SidebarNav";
import { Translations } from "../../components/translations";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import PropTypes from "prop-types";
import { commercialLinks } from "../header/CommercialLinks";
import {
  CLASS_APPETITE_GUIDE,
  RISK_CONTROL,
  AGENT_MANUALS,
  VIEW_UNDERWRITING_TEAM,
  VIEW_UNDERWRITING_TEAM_UFG_ONLINE,
  COMMERCIAL_LINES
} from "../../constants/routes";

const CommercialNav = props => {
  const {
    isSpecialtyPortalView,
    location,
    hasCommercialContract,
    isCancelledAgent
  } = props;

  const commercialSubMenuItems = () => {
    if (isSpecialtyPortalView) {
      let commercialLinksFiltered = commercialLinks.filter(item => {
        return (
          item.to !== RISK_CONTROL &&
          item.to !== CLASS_APPETITE_GUIDE &&
          item.to !== COMMERCIAL_LINES
        );
      });
      const brokerManual = {
        to: AGENT_MANUALS,
        activeClassName: "nav-menu--active",
        text: Translations.commercial.broker_manuals
      };

      const underWritingTeam = {
        to: VIEW_UNDERWRITING_TEAM,
        activeClassName: "nav-menu--active",
        text: Translations.commercial.view_underwriting_team
      };

      commercialLinksFiltered = commercialLinksFiltered.map(item => {
        return item.to === AGENT_MANUALS
          ? brokerManual
          : item.to === VIEW_UNDERWRITING_TEAM_UFG_ONLINE
          ? underWritingTeam
          : item;
      });
      return commercialLinksFiltered;
    }
    return commercialLinks;
  };

  const showComercial =
    (location.pathname !== "/commercial/commercial_lines" ||
      hasCommercialContract) &&
    !isCancelledAgent;
  return (
    showComercial && (
      <SidebarNav
        links={commercialSubMenuItems()}
        header={Translations.commercial.header}
      />
    )
  );
};

const mapStateToProps = state => ({
  hasCommercialContract:
    sessionSelector.agencyHasCommercialLinesContract(state),
  isCancelledAgent: sessionSelector.isCancelledAgent(state),
  location: PropTypes.object.isRequired
});

export default connect(mapStateToProps)(CommercialNav);
