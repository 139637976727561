import React, { useState, useCallback, useContext } from "react";
import OqModal from "../../shared/OqModal";
import * as api from "../../../services/onlineQuotingService";
import * as routes from "../../../constants/routes";
import { Button } from "@ufginsurance/ui-kit";
import {
  hasHNO,
  massageMetadata,
  transformAnswersToBoolForForm
} from "../../shared/util";
import { toast } from "react-toastify";
import { CyberLineStep6 } from "../../shared/CyberLine";
import getQuestions from "../../step3/getQuestions";
import FormSetup from "../../shared/FormSetup";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { useHistory } from "react-router-dom";

const LobAdd = ({ lob, product }) => {
  const {
    quoteData,
    supportingData,
    changeLineOfBusiness,
    showUpdatingToast,
    closeUpdatingToast,
    quoteIsUpdating,
    updateSupportingDataPromise,
    navigateToStep,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const history = useHistory();

  const [panels, setPanels] = useState([]);
  const [showLobModal, setShowLobModal] = useState(false);

  const gotoStep4 = useCallback(() => {
    const message = "Returning to step 4...";
    showUpdatingToast({ message });
    navigateToStep({ step: 4 }, ({ success }) => {
      if (success)
        history.push({
          pathname: routes.ONLINE_QUOTING_STEP4,
          hash: lob
        });
      closeUpdatingToast({ message });
    });
  }, [showUpdatingToast, navigateToStep, history, lob, closeUpdatingToast]);

  const getLobQuestions = () => {
    if (!panels.length) {
      const message = "Looking for eligibility questions...";
      showUpdatingToast({ message });
      api
        .getEligibilityQuestions(quoteData?.quoteID)
        .then(results => {
          const questionsData = getQuestions(results.data, lob);
          if ((questionsData || []).length > 0) {
            setPanels(questionsData);
            setShowLobModal(true);
          }
        })
        .finally(() => closeUpdatingToast({ message }));
    }
  };

  const handleQuestionsFormSubmit = ({ values }) => {
    toast.dismiss();
    const formValues = { ...values };

    //use null for items not filled in.
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === true) formValues[key] = "true";
      if (formValues[key] === false) formValues[key] = "false";
    });

    // save checkbox timestamp and the answers in supporting data
    const dataToMergeAndSave = {
      [product]: {
        dateTermsCheckCaptured: new Date().toLocaleDateString()
      },
      answered_questions: {
        values: { ...supportingData?.answered_questions?.values, ...formValues }
      }
    };

    const payload = {
      sessionUUID: quoteData.sessionUUID,
      answers: { values: formValues }
    };

    const message = "Updating question answers...";
    showUpdatingToast({ message });
    api
      .updateEligibilityQuestions(quoteData.quoteID, payload)
      .then(results => {
        const newSupportingData = {
          ...results.data.supportingData,
          ...dataToMergeAndSave
        };

        // update the checkbox confirmation value
        updateSupportingDataPromise({
          dataToMergeAndSave: newSupportingData
        }).then(() => {
          closeUpdatingToast({ message });
          setShowLobModal(false);
          gotoStep4();
        });
      })
      .catch(error => {
        toastErrr({
          displayMessage:
            "An error occurred.  Unable to save answered questions on step 6",
          action: "updateEligibilityQuestions",
          description: "unable to save questions on step 6",
          error,
          payload
        });
        closeUpdatingToast({ message });
      });
  };

  const addLobLine = () => {
    if (lob === "ca7CommAuto" && hasHNO(supportingData)) {
      toastErrr({
        type: "other",
        action: "addLobLine",
        description: "not an error - dissallowed user action - hno-issue",
        misc: { hasHNO: hasHNO(supportingData), product: "ca7CommAuto" },
        displayMessage:
          "This coverage is included in the BOP-Pro and cannot be added separately. Please contact your underwriter with any additional questions."
      });
      return;
    }

    changeLineOfBusiness({ action: "Adding", product }).then(() =>
      getLobQuestions()
    );
  };

  // Cyber has a special "add" that has it's own modal
  if (lob === "internetSecurity") return <CyberLineStep6 />;

  return (
    <>
      {
        <Button onClick={addLobLine} isLink inline>
          Add {product.shortDescription || product.label} policy
        </Button>
      }
      {showLobModal && (
        <OqModal
          className="oq_modal__coverages__policy-info"
          show={showLobModal}
          size="lg"
          closeIcon={false}
          onHide={() => {
            setShowLobModal(false);
          }}
          body={
            <>
              {panels && panels.length > 0 && (
                <FormSetup
                  key={product.label}
                  sectionHeader=""
                  panels={massageMetadata(
                    panels,
                    transformAnswersToBoolForForm(
                      supportingData?.answered_questions?.values
                    )
                  )}
                  submitBtnLabel="Continue to Coverages"
                  handleFormSubmit={handleQuestionsFormSubmit}
                  continueBtnSpinner={quoteIsUpdating}
                  // setFormIsInvalid={setFormIsInvalid}
                  cancelButton={
                    <Button
                      isLink
                      onClick={() => {
                        changeLineOfBusiness({
                          action: "Removing",
                          product,
                          rateAfter: true
                        }).then(() => {
                          setShowLobModal(false);
                          setPanels([]);
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  }
                  showRequireTerms
                  eligibilityChecked={
                    !!supportingData[lob]?.dateTermsCheckCaptured
                  }
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};
export default LobAdd;
