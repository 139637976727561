import React, { useContext, useState } from "react";
import { sortByProperty, locationDisplayName } from "../../shared/util";
import { Button, Panel } from "@ufginsurance/ui-kit";

import MissingItemPanel from "../../shared/MissingItemPanel";
import EmployeeModalWizard from "./EmployeeModalWizard";
import EmployeeCardContents from "./EmployeeCardContents";
import OnlineQuotingContext from "../../OnlineQuotingContext";

import "../../Step5RiskInfo.scss";

const EmployeeCards = ({ locations, coverageExclusions }) => {
  const { get } = useContext(OnlineQuotingContext);

  const [activeWorkCompLocation, setActiveWorkCompLocation] = useState();
  const [showItemForm, setShowItemForm] = useState(false);

  const openItemEdit = () => {
    setShowItemForm(true);
  };

  const onHideModal = () => {
    setShowItemForm(false);
    if (activeWorkCompLocation) setActiveWorkCompLocation(null);
  };

  const locationsWorkComp = (locations || [])
    .filter(loc => !!loc?.wcmLocationFixedID)
    .sort(sortByProperty("id"));

  return (
    <div>
      {showItemForm && activeWorkCompLocation && (
        <EmployeeModalWizard
          location={activeWorkCompLocation}
          show={showItemForm}
          name="Class Code"
          currentEmployee={null}
          onHide={onHideModal}
          coverageExclusions={coverageExclusions}
          mode="ADD"
          copyItemForEdit={() => {}}
        />
      )}
      <Panel
        id="oq__coverable__container__employee"
        rounded
        titlebar
        bgcolor="grey"
        className="oq__coverable__container oq__coverable__container__employee"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Workers&apos; Comp Classes
            </span>
          </div>
        }
      >
        {locationsWorkComp.map(loc => {
          const employees = get.employees({
            locationId: loc.wcmLocationFixedID
          });

          if (employees.length > 0)
            return (
              <Panel
                id={`oq__coverable__container__employee__${loc.id}`}
                key={`oq__coverable__container__employee__${loc.id}`}
                highlight="none"
                className="oq__coverable__container oq__coverable__employee"
                rounded
                isOpen
                collapsible
                title={
                  <span className="oq__coverable__card__employee__location-adress">
                    {locationDisplayName(loc)}
                  </span>
                }
              >
                <div className="oq__coverable__card__employee__wrapper">
                  {employees
                    .filter(e => loc.wcmLocationFixedID === e.locationId)
                    .sort(sortByProperty("id"))
                    .map(item => (
                      <EmployeeCardContents
                        key={item.id}
                        employee={item}
                        coverageExclusions={coverageExclusions}
                      />
                    ))}
                </div>

                <div className="oq__coverable__card__actions">
                  <Button
                    wrapperClassName="oq__coverable__card__edit"
                    className="oq__coverable__card__add__button"
                    onClick={() => {
                      setActiveWorkCompLocation(loc);
                      openItemEdit(null);
                    }}
                    inline
                    isLink
                  >
                    Edit Workers&apos; Comp Classes
                  </Button>
                </div>
              </Panel>
            );

          if (employees.length === 0)
            return (
              <MissingItemPanel
                className="oq__coverable__employee__missing"
                key={`oq__coverable__container__employee__${loc.id}`}
                onClickHandler={() => {
                  setActiveWorkCompLocation(loc);
                  openItemEdit(null);
                }}
                content={
                  <span className="oq__coverable__card__initial-content">
                    <span className="oq__coverable__card__initial-content__secondary">
                      A class is required for the location:
                    </span>
                    <span className="oq__coverable__card__initial-content__extended oq__coverable__card__employee__location-adress">
                      {locationDisplayName(loc)}
                    </span>
                  </span>
                }
                buttonText="Add Class"
              />
            );

          return null;
        })}
      </Panel>
    </div>
  );
};

export default EmployeeCards;
