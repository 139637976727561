import React from "react";
import { ContentHeader, Button } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import { withRouter } from "react-router-dom";
import * as routes from "../../constants/routes";

const ErrorDeclineDetail = ({ history, match }) => {
  const accountNumber = match.params.account_number;
  const { Error_Decline, AgencySweep } = Translations;

  const onBackToAccountOverview = () => {
    history.push(
      `${routes.ACCOUNT_HOME}/${accountNumber}/${routes.ACCOUNT_OVERVIEW_BILLING_ACTIVITY_ACTIVE_TAB}`
    );
  };

  return (
    <div className="error-details">
      <ContentHeader>{Error_Decline.heading}</ContentHeader>
      <div className="row inner-error-details-row">
        <p>{Error_Decline.payment}</p>
        <br />
        <p>{Error_Decline.description}</p>
        <br />
        <b>{Error_Decline.questions} </b>
        <p>{Error_Decline.Billing_Customer_Service}</p>
        <p>{Error_Decline.Phone_number}</p>
        <p>{Error_Decline.Hours}</p>
      </div>
      <br />
      <Button variant="secondary" onClick={onBackToAccountOverview}>
        {AgencySweep.back_to_account_overview}
      </Button>
    </div>
  );
};

ErrorDeclineDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(ErrorDeclineDetail);
