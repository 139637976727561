/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import { Translations } from "../../../components/translations";
import {
  FormGroup,
  Form,
  useForm,
  Dropdown,
  Button
} from "@ufginsurance/ui-kit";
import { getGuidewireAgencyStatementOptions } from "../../slices/guidewireAgencyStatementOptions";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const { sortByOptions, actionOptions } = Translations.agency_bill_epay;

const getStatementOptions = () => {
  const lastMonth = moment().subtract(1, "months");
  const twoMonthsAgo = moment().subtract(2, "months");

  return [
    {
      label: lastMonth.format("MMMM") + " and prior",
      value: lastMonth.format("MMYYYY"),
      month: lastMonth.format("MM"),
      year: lastMonth.format("YYYY")
    },
    {
      label: twoMonthsAgo.format("MMMM") + " and prior",
      value: twoMonthsAgo.format("MMYYYY"),
      month: twoMonthsAgo.format("MM"),
      year: twoMonthsAgo.format("YYYY")
    }
  ];
};

const AgencyBillEpayForm = ({
  handleSubmit,
  hideForm,
  legacyPaymentError,
  paymentLinks,
  guidewirePaymentError,
  agencyCode
}) => {
  const statementOptions = getStatementOptions();
  const getGuidewireAgencyStatementOptionsLoading = useSelector(state => {
    return state.agencyStatementOptionsReducer
      .getGuidewireAgencyStatementOptionsLoading;
  });
  const validStatementOptions = useSelector(state => {
    return state.agencyStatementOptionsReducer.validStatementOptions;
  });
  const getGuidewireAgencyStatementOptionsError = useSelector(state => {
    return state.agencyStatementOptionsReducer
      .getGuidewireAgencyStatementOptionsError;
  });
  const getGuidewireAgencyStatementOptionsException = useSelector(state => {
    return state.agencyStatementOptionsReducer
      .getGuidewireAgencyStatementOptionsException;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      agencyCode &&
      !validStatementOptions &&
      !getGuidewireAgencyStatementOptionsLoading &&
      !getGuidewireAgencyStatementOptionsError &&
      !getGuidewireAgencyStatementOptionsException
    ) {
      dispatch(
        getGuidewireAgencyStatementOptions(agencyCode, statementOptions)
      );
    }
  }, [
    agencyCode,
    statementOptions,
    validStatementOptions,
    getGuidewireAgencyStatementOptionsLoading,
    getGuidewireAgencyStatementOptionsError,
    getGuidewireAgencyStatementOptionsException,
    dispatch
  ]);

  useEffect(() => {
    if (getGuidewireAgencyStatementOptionsException) {
      toast(<HelpDeskMessage display="anErrorOccurred" noLinks />, {
        position: "top-center",
        className: "error",
        autoClose: false
      });
    }
  }, [getGuidewireAgencyStatementOptionsException]);

  const initialValues = {
    statementLegacy: "",
    statementGuidewire: "",
    sort_by: "",
    action: ""
  };

  const handleSubmission = ({ values }) => {
    handleSubmit(values);
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;
  const legacyOnChange = ({ field, value, event }) => {
    // change this field
    handleOnChange({ field, value, event });
    // reset the other one
    handleOnChange({ field: "statementGuidewire", value: [], event: "clear" });
  };
  const guidewireOnChange = ({ field, value, event }) => {
    // change this field
    handleOnChange({ field, value, event });
    // reset the other one
    handleOnChange({
      field: "statementLegacy",
      value: null,
      event: "clear"
    });
  };

  const showGuidewireStatementOptions =
    !getGuidewireAgencyStatementOptionsError &&
    (validStatementOptions?.length ?? 0) > 0;
  const showLegacyStatementOptions =
    !legacyPaymentError && !paymentLinks.legacy_payment;

  return (
    <Form context={form}>
      <FormGroup wrap={false}>
        {legacyPaymentError ? (
          <div className="in-progress-payment-message">
            <p>{Translations.agency_bill_epay.legacy_statement_label}</p>
            <div className="error-message">{legacyPaymentError}</div>
          </div>
        ) : paymentLinks.legacy_payment ? (
          <div className="in-progress-payment-message">
            <p>{Translations.agency_bill_epay.legacy_statement_label}</p>
            <div>{paymentLinks.legacy_payment}</div>
          </div>
        ) : hideForm ? (
          <div className="in-progress-payment-message">
            <p>{Translations.agency_bill_epay.legacy_statement_label}</p>
            <div>
              You have a saved, pending, confirmed, or rejected statement
              payment in progress.
            </div>
          </div>
        ) : (
          <Dropdown
            id="statementLegacy"
            name="statementLegacy"
            label={Translations.agency_bill_epay.legacy_statement_label}
            options={statementOptions}
            onChange={legacyOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.statementLegacy}
            size="lg"
            isClearable
          />
        )}
        {showGuidewireStatementOptions && (
          <>
            <div
              className={classnames({
                "orSpacer-error": guidewirePaymentError,
                "in-progress-payment-message": true
              })}
            >
              or
            </div>
            {guidewirePaymentError ? (
              <div className="in-progress-payment-message">
                <p>{Translations.agency_bill_epay.guidewire_statement_label}</p>
                <div className="error-message">{guidewirePaymentError}</div>
              </div>
            ) : paymentLinks.guidewire_payment ? (
              <div className="in-progress-payment-message">
                <p>{Translations.agency_bill_epay.guidewire_statement_label}</p>
                <div>{paymentLinks.guidewire_payment}</div>
              </div>
            ) : hideForm ? (
              <div className="in-progress-payment-message">
                <p>{Translations.agency_bill_epay.guidewire_statement_label}</p>
                <div>
                  You have a saved, pending, confirmed, or rejected statement
                  payment in progress.
                </div>
              </div>
            ) : (
              <Dropdown
                id="statementGuidewire"
                name="statementGuidewire"
                label={Translations.agency_bill_epay.guidewire_statement_label}
                options={validStatementOptions}
                onChange={guidewireOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.statementGuidewire}
                size="lg"
                isClearable
              />
            )}
          </>
        )}
      </FormGroup>
      {hideForm ||
      (!showGuidewireStatementOptions && !showLegacyStatementOptions) ? (
        ""
      ) : (
        <>
          <FormGroup>
            <Dropdown
              id="sort_by"
              name="sort_by"
              label={Translations.agency_bill_epay.sort_by}
              options={sortByOptions}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.sort_by}
              size="lg"
              required
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              id="action"
              name="action"
              label={Translations.agency_bill_epay.action}
              options={actionOptions}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.action}
              size="lg"
              required
            />
          </FormGroup>
          <FormGroup>
            <Button type="submit" variant="primary">
              {Translations.general.submit_text}
            </Button>
          </FormGroup>
        </>
      )}
    </Form>
  );
};

export default AgencyBillEpayForm;

AgencyBillEpayForm.propTypes = {
  hideForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  legacyPaymentError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  guidewirePaymentError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  paymentLinks: PropTypes.object,
  agencyCode: PropTypes.string.isRequired
};
