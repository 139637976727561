import React, { useEffect } from "react";
import { v4 } from "uuid";
import {
  Button,
  Dropdown,
  FlexRow,
  Form,
  FormGroup,
  Input,
  Modal,
  useForm
} from "@ufginsurance/ui-kit";
import Address from "../../../quick-program/quick-quoting/shared/Address";
import { ADDITIONAL_INSURED_TYPES } from "../lib/constants";

export default function AdditionalInsuredModal({
  agencyCode,
  buildersRiskForm,
  editAddInID,
  setEditAddInID,
  setShowAdditionalInsuredModal,
  showAdditionalInsuredModal
}) {
  const initialValues = {
    address_line: "",
    city: "",
    company_name: "",
    first_name: "",
    last_name: "",
    relationship: "",
    state: "",
    type: "",
    zip: ""
  };

  const form = useForm({
    values: initialValues,
    onSubmit: () => {}
  });

  const {
    values,
    errors,
    invalidFields,
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    updateForm
  } = form;

  const resetForm = () => {
    updateForm({
      values: initialValues,
      errors: {
        address_line: [],
        city: [],
        company_name: [],
        first_name: [],
        last_name: [],
        relationship: [],
        state: [],
        type: [],
        zip: []
      }
    });
  };

  const handleAdditionalInsuredTypeChange = event => {
    updateForm({
      values: {
        ...values,
        company_name: "",
        first_name: "",
        last_name: "",
        relationship: "",
        address_line: "",
        city: "",
        state: "",
        zip: ""
      },
      errors: {
        ...errors,
        address_line: [],
        city: [],
        company_name: [],
        first_name: [],
        last_name: [],
        relationship: [],
        state: [],
        type: [],
        zip: []
      }
    });
    handleOnChange(event);
  };

  useEffect(() => {
    if (editAddInID) {
      const addIns = buildersRiskForm.values.additional_insured.find(
        ({ id }) => id === editAddInID
      );
      updateForm({ values: addIns });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAddInID, buildersRiskForm.values.additional_insured]);

  const handleClose = (isAddingNew = false) => {
    setEditAddInID(null);
    if (isAddingNew) {
      updateForm({
        values: {
          ...initialValues,
          type: values?.type
        }
      });
    } else {
      resetForm();
      setShowAdditionalInsuredModal(false);
    }
  };

  // Update the aws record.
  const handleSubmit = addNew => {
    if (editAddInID) {
      const addIns = buildersRiskForm.values.additional_insured.map(a =>
        a.id !== values.id ? a : values
      );

      buildersRiskForm.updateForm({
        values: {
          ...buildersRiskForm.values,
          additional_insured: addIns
        }
      });
    } else {
      if (values.type === "individual") {
        delete values.company_name;
      } else {
        delete values.first_name;
        delete values.last_name;
      }

      values.id = v4();

      buildersRiskForm.updateForm({
        values: {
          ...buildersRiskForm.values,
          additional_insured: [
            ...buildersRiskForm.values?.additional_insured,
            values
          ]
        }
      });
    }

    handleClose(addNew);
  };

  return (
    <Modal
      show={showAdditionalInsuredModal}
      onHide={() => handleClose(false)}
      title="Add Additional Interest/Insured"
      size="lg"
      body={
        <Form context={form}>
          <FormGroup>
            <Dropdown
              id="type"
              name="type"
              label="Add a Company/Individual"
              options={[
                { value: "company", label: "Company" },
                { value: "individual", label: "Individual" }
              ]}
              onChange={handleAdditionalInsuredTypeChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.type}
              size="lg"
              required
            />
          </FormGroup>

          {values?.type && (
            <FormGroup>
              <Dropdown
                id="relationship"
                name="relationship"
                label="Additional Insured Type"
                options={ADDITIONAL_INSURED_TYPES}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.relationship}
                size="fill"
                required
              />
            </FormGroup>
          )}

          {values?.type && values?.type === "company" && (
            <FormGroup>
              <Input
                id="company_name"
                name="company_name"
                label="Company Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.company_name}
                size="lg"
                required
              />
            </FormGroup>
          )}
          {values?.type && values?.type === "individual" && (
            <FormGroup>
              <Input
                id="first_name"
                name="first_name"
                label="First Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.first_name}
                size="50p"
                required
              />
              <Input
                id="last_name"
                name="last_name"
                label="Last Name"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.last_name}
                size="50p"
                required
              />
            </FormGroup>
          )}

          {values?.type && (
            <div>
              <Address
                form={form}
                activeAgencyCode={agencyCode}
                showDefaultAddressField
                disableProjectCity
                disableProjectState
                zipCodeLabel="Zip"
              />

              <FlexRow align="right">
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button
                  variant="outline"
                  onClick={() => handleSubmit(true)}
                  disabled={
                    !!Object.keys(errors)?.length || !!invalidFields?.length
                  }
                >
                  Save and Add New
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleSubmit(false)}
                  disabled={
                    !!Object.keys(errors)?.length || !!invalidFields?.length
                  }
                >
                  {editAddInID ? "Update" : "Add"}
                </Button>
              </FlexRow>
            </div>
          )}
        </Form>
      }
    />
  );
}
