import React, { useState, useEffect, useContext, useCallback } from "react";
import OqModal from "../shared/OqModal";
import * as api from "../../services/onlineQuotingService";
import { LoadingIndicator, FlexRow, Button } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import * as routes from "../../constants/routes";

const CUSTOMER_VALIDATION_ERRORS = {
  "error.quoting.unavailableState":
    "To complete the quote, you will be redirected to our standard quoting system, as the product and/or state you selected is not yet available on our new Pro-Quote system.",
  "Unable to create legacy quote":
    " We are unable to process your quote at this time, please contact our helpdesk.",
  "error.quoting.existingCustomer":
    "We’re sorry but this account is already insured by UFG Insurance and cannot be quoted. If you have any further questions, please contact your UFG underwriter or marketing representative.",
  "error.quoting.requiredProduct":
    "To complete the quote, you will be redirected to our standard quoting system, as the product and/or state you selected is not yet available on our new Pro-Quote system."
};

const ValidateCustomer = ({
  show,
  setValidateModal,
  setPrefillModal,
  history
}) => {
  const { supportingData, clearLocalQuote } = useContext(OnlineQuotingContext);

  const [stopCustomer, setStopCustomer] = useState();
  const [apiStatus, setApiStatus] = useState();
  const [apiResponseData, setApiResponseData] = useState();
  const [redirrecting, setRedirrecting] = useState(false);

  const continueOnward = useCallback(() => {
    //if the address is unverified , do not call the bop prefill
    if (
      supportingData?.customerInformation?.primaryAddress?.verified ===
      "unverified"
    )
      history.push(routes.ONLINE_QUOTING_STEP2);
    // otherwise, call bop prefill modal
    else setPrefillModal(true);
  }, [history, setPrefillModal, supportingData]);

  useEffect(() => {
    if (!stopCustomer && !apiStatus) {
      setApiStatus("running");
      api
        .validateCustomer(supportingData)
        .then(response => {
          setApiStatus("complete");
          if (response?.data?.valid === true) {
            setTimeout(() => {
              setValidateModal(false);
              continueOnward();
            }, 1500);
          } else {
            setStopCustomer(true);

            //process the api response for error messages to be displayed in the modal
            let errorMessages = [];
            let legacyQuoteFailure = false;
            let existingCustomer = false;
            let errorMessage = "";
            errorMessages = response?.data?.validationErrors;
            for (const e of errorMessages) {
              if (e.error === "Unable to create legacy quote") {
                legacyQuoteFailure = true; //if the error has legacy quote failure , set this to true
                errorMessage = e.error;
                break; //and ignore other error messages
              } else if (e.error === "error.quoting.existingCustomer") {
                existingCustomer = true; //set this to true if an existing customer i.e name and address match
                errorMessage = e.error;
                break; //and ignore other error messages
              } else {
                errorMessage = e.error;
              }
            }
            const apiResponse = {
              errorMessage,
              legacyQuoteId: response?.data?.legacyQuoteId,
              legacyQuoteFailure,
              existingCustomer
            };

            setApiResponseData(apiResponse);
          }
        })
        .catch(error => {
          setApiStatus("complete");
          console.log(error);
          setTimeout(() => {
            setValidateModal(false);
            continueOnward();
          }, 1500);
        });
    }
  }, [
    supportingData,
    apiStatus,
    continueOnward,
    stopCustomer,
    setValidateModal
  ]);

  const redirectToLegacy = () => {
    setRedirrecting(true);
    // do not redirect if its a legacy quote failure or existing customer
    if (
      !apiResponseData.legacyQuoteFailure &&
      !apiResponseData.existingCustomer
    ) {
      window.location = `${window.env.REACT_APP_NEW_BUSINESS_SUBMISSION_EDIT_BASE_URL}/${apiResponseData.legacyQuoteId}`;
    } else {
      setValidateModal(false);
    }
  };

  return (
    <OqModal
      title="Validate Customer"
      show={show}
      closeIcon={false}
      onHide={() => {}}
      body={
        <>
          {!stopCustomer ? (
            <LoadingIndicator message="Checking Customer Information..." />
          ) : (
            <>
              <p>{CUSTOMER_VALIDATION_ERRORS[apiResponseData?.errorMessage]}</p>
              <FlexRow align="right">
                <Button
                  variant="primary"
                  onClick={() => {
                    clearLocalQuote({ goto: null });
                    redirectToLegacy();
                  }}
                  spinner={redirrecting}
                  disabled={redirrecting}
                >
                  Close
                </Button>
              </FlexRow>
            </>
          )}
        </>
      }
    />
  );
};

export default ValidateCustomer;
