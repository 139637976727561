import { useEffect, useState } from "react";

const formatProducerOptions = (producers = []) => {
  const options = producers.map(({ name, number }) => ({
    label: name,
    value: String(number)
  }));

  return [
    ...options,
    {
      label: "Other",
      value: "other"
    }
  ];
};

export default function useGetFormattedProducerOptions(producers, values) {
  const [formattedOptions, setFormattedOptions] = useState([]);

  useEffect(() => {
    const options = formatProducerOptions(producers);

    if (values?.new_producer || values?.new_producer?.hasOwnProperty("email")) {
      const { first_name, last_name } = values.new_producer;
      options.unshift({
        label: `${first_name} ${last_name}`,
        value: "-1"
      });
    }

    setFormattedOptions(options);
  }, [producers, values.new_producer]);

  return [formattedOptions];
}
