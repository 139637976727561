//source: https://reactjs.org/docs/error-boundaries.html
import React from "react";
import * as routes from "../../constants/routes";
import * as exceptionReportingService from "../../services/exceptionReportingService";
import { connect } from "react-redux";

const EXCEPTION_REPORTING_ENABLED =
  window.env.REACT_APP_EXCEPTION_REPORTING_ENABLED === "true";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  componentDidCatch(error) {
    const { loggedInUser, employeeUsername } = this.props;

    console.error(error);
    if (EXCEPTION_REPORTING_ENABLED) {
      let stacktrace =
        error.stack +
        `\n\nLocation: ${window.location.href}}\n\nDownload the associated *.js.map ` +
        `file using the stack trace links referenced to the scripts ` +
        `folder and run 'findminifiedsourceposition' script passing in the ` +
        `location where you downloaded the source map file, line, and character number ` +
        `from the stack trace, e.g. yarn findminifiedsourceposition ./scripts/main.chunk.js.map ` +
        `2 594597 \n\n You may also want to refer to FullStory (https://app.fullstory.com) ` +
        `dashboard recording for the user (find the agent's email address from their agent code) ` +
        `or by ${routes.ERROR} exception route visited ` +
        `at the timestap this alert was generated. \n\n`;
      if (loggedInUser) stacktrace += `Logged In User: ${loggedInUser}\n`;
      if (employeeUsername) stacktrace += `Employee: ${employeeUsername}\n\n`;

      exceptionReportingService
        .sendExceptionReport({
          message: error.message,
          stacktrace
        })
        .finally(() => {
          window.location = routes.ERROR;
        });
    } else {
      window.location = routes.ERROR;
    }
  }

  render() {
    if (this.state.hasError) {
      return <div>Does not show - see componentDidCatch redirect</div>;
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  loggedInUser: state?.oidc?.user?.profile?.username,
  employeeUsername: state?.oidc?.user?.profile?.employeeUsername
});

export default connect(mapStateToProps, {})(ErrorBoundary);
