
export const findStateById = (stateList, stateId) => {
  return stateList && stateList.find(state => {
    return state.value && state.value === stateId;
  });
};

export const findProductById = (productList, productId) => {
  return productList && productList.find(product => {
    return product?.id && product.id === productId;
  });
};

export const findTableOfContentsItemById = (tableOfContentsList, tableOfContentItemId) => {
  return tableOfContentsList && tableOfContentsList.find(tocItem => {
    return tocItem?.id && tocItem.id === tableOfContentItemId;
  });
};

export const findDefaultTableOfContentsItem = state => {
  const tableOfContentsList = state?.agentManuals?.tableOfContents?.data;
  return tableOfContentsList && tableOfContentsList.find(tocItem => {
    return tocItem?.contains_text;
  });
};
