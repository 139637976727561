import React from "react";
import { Modal } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";

const ModalHOC = ({ children, show, title, onHide }) => {
  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      onHide={onHide}
      body={children}
      altCloseMethod={false}
    />
  );
};

ModalHOC.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default ModalHOC;
