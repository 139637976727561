import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  LoadingIndicator,
  Table,
  Anchor,
  Button,
  FlexRow
} from "@ufginsurance/ui-kit";
import { Translations } from "../../translations";
import { toTitleCase } from "../../Factory";
import { Link } from "react-router-dom";
import PolicyLossRunNameSearch from "../../../reports/components/claims/policy-loss-runs/PolicyLossRunNameSearch";
import { getPolicyLossRunsUrl } from "../../../reports/services/claimsReportsService";
import { CLARION_DOOR_POLICY_PREFIX } from "../../../constants/dictionary";
import * as routes from "../../../constants/routes";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import qs from "query-string";

const PolicySearchList = props => {
  const {
    policies,
    isLoading,
    search_type,
    policySelected,
    isReportClaim,
    isSpecialtyPortalView,
    isMercerSpecialtyPortalView,
    history,
    searchKeyword,
    isLoadingPolicyTermsHistory,
    policyTermsHistoryError,
    hasPolicyTermsHistory,
    policyTermsHistory
  } = props;

  useEffect(() => {
    if (!isLoading && policies && policies.length > 0) {
      const policy = policies[0];
      if (search_type !== "name") {
        const isBondsPolicy = getIsBondsPolicy(policy);
        const isBondNumberSearch =
          isBondsPolicy && searchKeyword === policy?.policy_number;

        if (isBondsPolicy && isBondNumberSearch) {
          policySelected();
          history.push(`/surety/bond/${policy.policy_number}`);
        } else if (isBondsPolicy && !isBondNumberSearch) {
          policySelected();
          history.push(`/surety/account/${policy?.account_number}`);
        } else {
          const isClarionDoorPolicyNumber =
            (policy?.policy_number?.substring(0, 3) ?? "") ===
            CLARION_DOOR_POLICY_PREFIX;
          if (!isClarionDoorPolicyNumber) {
            policySelected();
            history.push({
              pathname: "/account/" + policy.policy_number,
              state: {
                agency_code: policy.agency_code
              }
            });
          }
        }
      } else if (policies.length === 1) {
        policySelected();

        if (getIsBondsPolicy(policy)) {
          history.push({
            pathname: "/surety/account/" + policy.account_number,
            state: {
              agency_code: policy.agency_code
            }
          });
        } else if (isReportClaim) {
          const url = getPolicyLossRunsUrl(policy.policy_number);
          window.open(url);
        } else {
          history.push({
            pathname: "/account/" + policy.policy_number,
            state: {
              agency_code: policy.agency_code
            }
          });
        }
      }
    }
  }, [
    policies,
    search_type,
    isLoading,
    policySelected,
    history,
    isReportClaim,
    searchKeyword
  ]);

  const getIsBondsPolicy = policies => {
    return policies.policy_type === "B";
  };

  const handlePolicyChange = () => {
    policySelected();
  };

  const policyErrorFeedback = (
    isLoadingPolicyTermsHistory,
    policyTermsHistoryError,
    hasPolicyTermsHistory,
    helpDeskMessage,
    hasPolicyTermsHistoryHelpDeskMessage
  ) => {
    return (
      <>
        {!isLoadingPolicyTermsHistory &&
          !(!policyTermsHistoryError && hasPolicyTermsHistory) && (
            <HelpDeskMessage
              display="questionsOrAssistance"
              before={
                <>
                  {helpDeskMessage}
                  <br />
                  <br />
                </>
              }
            />
          )}
        {isLoadingPolicyTermsHistory && <LoadingIndicator />}
        {!policyTermsHistoryError && hasPolicyTermsHistory && (
          <>
            <FlexRow>
              <span>
                {hasPolicyTermsHistoryHelpDeskMessage}
                <br />
              </span>
            </FlexRow>
            {policyTermsHistory && (policyTermsHistory?.length ?? 0) !== 0 && (
              <>
                <FlexRow align="right">
                  <Button
                    className="card-button policy-terms-button"
                    id="policy-not-found-loss-run-card-button"
                    onClick={() => {
                      policySelected();
                      const queryString = qs.stringify({
                        claims: "acct",
                        polacctno: searchKeyword
                      });
                      const lossRunsRedirectUrl = `${window.env.REACT_APP_LEGACY_SITE_URL}/AgentsOnly/ufgAgent/Claims/reportcriteriao.aspx?${queryString}`;
                      window.open(lossRunsRedirectUrl, "_blank");
                    }}
                  >
                    <span className="card-button__title">
                      <b>Looking for loss runs?</b>
                    </span>{" "}
                    <span className="card-button__description">
                      Loss runs are available for 5 years.
                    </span>
                    <br />
                    <span className="card-button__description loss-runs-card-button-description">
                      <Anchor onClick={e => e.preventDefault()}>
                        Search in Loss Runs
                      </Anchor>
                    </span>
                  </Button>
                  <div className="align-right">
                    <Button
                      className="card-button policy-terms-button"
                      id="policy-not-found-claim-inquiry-card-button"
                      onClick={() => {
                        policySelected();
                        const queryString = qs.stringify({
                          policy_number: searchKeyword
                        });
                        const claimsInquiryRedirectUrl = `/claim_information?${queryString}`;
                        history.push(claimsInquiryRedirectUrl);
                      }}
                    >
                      <span className="card-button__title">
                        <b>Looking for claim information?</b>
                      </span>{" "}
                      <span className="card-button__description">
                        Claim information is available 1 year after the claim
                        has been closed.
                      </span>
                      <br />
                      <span className="card-button__description">
                        <Anchor onClick={e => e.preventDefault()}>
                          Search in Claims
                        </Anchor>
                      </span>
                    </Button>
                  </div>
                </FlexRow>
              </>
            )}
            <FlexRow>
              <HelpDeskMessage display="questionsOrAssistance" />
            </FlexRow>
          </>
        )}
      </>
    );
  };

  const renderErrorMessage = () => {
    switch (search_type) {
      case "name":
        return (
          <HelpDeskMessage
            display="questionsOrAssistance"
            before={
              <>
                {
                  "Unfortunately, no match was found. Please try again. If the policy has been expired for more than 1 year it is not available to view online."
                }
                <br />
                <br />
              </>
            }
          />
        );
      case "policy":
        return (isMercerSpecialtyPortalView || isSpecialtyPortalView) &&
          searchKeyword.startsWith(CLARION_DOOR_POLICY_PREFIX)
          ? policyErrorFeedback(
              isLoadingPolicyTermsHistory,
              policyTermsHistoryError,
              hasPolicyTermsHistory,
              "The policy number you’re searching for is not currently available in ufgSpecialty. Please review your entry or contact your underwriter for more information.",
              "The policy number you’re searching for is not currently available in ufgSpecialty. Please review your entry or contact your underwriter for more information."
            )
          : policyErrorFeedback(
              isLoadingPolicyTermsHistory,
              policyTermsHistoryError,
              hasPolicyTermsHistory,
              "Unfortunately, the policy number you entered could not be found. Please ensure the number was entered correctly.",
              "Unfortunately, no match was found. If the policy has been expired for more than 1 year it is not available to view online."
            );
      case "account":
        return (
          <HelpDeskMessage
            display="questionsOrAssistance"
            before="Unfortunately, the account number you entered could not be found. Please ensure the number was entered correctly."
          />
        );
      case "invalid_number":
        return Translations.Name_Search.invalid_account;
      default:
        return Translations.Name_Search.default_error;
    }
  };

  const insured_name_Formatter = row => {
    return (
      <div className="insured_name">
        {isReportClaim ? (
          <PolicyLossRunNameSearch row={row} />
        ) : getIsBondsPolicy(row) ? (
          <Anchor
            className="text-capitalize"
            onClick={() => {
              policySelected();
              history.push(`/surety/account/${row.account_number}`);
            }}
          >
            {row.insured_name.toLowerCase()}{" "}
          </Anchor>
        ) : (isMercerSpecialtyPortalView || isSpecialtyPortalView) &&
          row.policy_number.startsWith(CLARION_DOOR_POLICY_PREFIX) ? (
          <span className="text-capitalize">
            {"*" + row.insured_name.toLowerCase()}
          </span>
        ) : (
          <Link
            onClick={handlePolicyChange}
            to={{
              pathname: "/account/" + row.policy_number,
              state: { agency_code: row.agency_code, policySearch: true }
            }}
            className="text-capitalize"
          >
            {row.insured_name.toLowerCase()}
          </Link>
        )}
      </div>
    );
  };

  const insured_city_formatter = row => {
    return (
      <span>
        {toTitleCase(row.insured_city)}, {row.insured_state}
      </span>
    );
  };

  const columns = [
    {
      key: "insured_name",
      label: "Insured Name",
      element: insured_name_Formatter,
      sortable: true
    },
    {
      key: "insured_city",
      label: "City, State",
      element: insured_city_formatter
    },
    {
      key: "policy_number",
      label: "Acct/Pol #",
      element: ({ policy_number, policy_type, account_number }) => {
        const isPolicyTypeBond =
          !!policy_type && policy_type.toUpperCase() === "B";
        const isPolicyGW =
          policy_number?.length === 11 &&
          (policy_number?.startsWith("100") ||
            policy_number?.startsWith("101"));
        if (isPolicyTypeBond) {
          return (
            <Anchor
              onClick={() => {
                policySelected(); // close policy search modal.
                history.push(`/surety/bond/${policy_number}`)
              }}
            >
              {policy_number}
            </Anchor>
          );
        }
        return isPolicyGW ? account_number : policy_number;
      }
    },
    {
      key: "policy_description",
      label: "Dec"
    },
    {
      key: "agency_code",
      label: isSpecialtyPortalView ? "Broker #" : "Agent #"
    }
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (policies.length === 0) {
    return <div> {renderErrorMessage()} </div>;
  }

  const onPolicyLossRunsHomeClick = () => {
    history.push(
      `${routes.REPORTS}/${routes.REPORTS_CLAIMS_ACTIVE_TAB}/${routes.REPORTS_POLICY_LOSS}`
    );
    policySelected();
  };

  return (
    <div className="policy-search">
      <Table
        data={policies}
        columns={columns}
        compact
        initialSort="insured_name"
        initialDirection="asc"
        showPagination
        rowKey="policy_number"
      />
      <div>
        {(isMercerSpecialtyPortalView || isSpecialtyPortalView) &&
        policies.some(policy =>
          policy.policy_number.startsWith(CLARION_DOOR_POLICY_PREFIX)
        )
          ? Translations.formatString(
              Translations.Name_Search.GWunavailability,
              <Anchor
                className="lossrunlink"
                onClick={onPolicyLossRunsHomeClick}
              >
                {Translations.Name_Search.GWunavailability_lossrunlink}
              </Anchor>
            )
          : ""}
      </div>
    </div>
  );
};

PolicySearchList.propTypes = {
  policies: PropTypes.array,
  search_type: PropTypes.string,
  isLoading: PropTypes.bool,
  policySelected: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isReportClaim: PropTypes.bool.isRequired,
  isSpecialtyPortalView: PropTypes.bool,
  searchKeyword: PropTypes.string,
  isMercerSpecialtyPortalView: PropTypes.bool,
  isLoadingPolicyTermsHistory: PropTypes.bool,
  policyTermsHistoryError: PropTypes.bool,
  hasPolicyTermsHistory: PropTypes.bool,
  policyTermsHistory: PropTypes.array
};

export default PolicySearchList;
