import { connect } from "react-redux";
import AgencyStatement from "../components/accounting/agency-statement/AgencyStatement";
import {
  fetchAgencyStatementListDetails,
  fetchAccountingStatementViewPDF,
  fetchPurchasedAgencyCodes,
  fetchSponsoredAgencyCodes
} from "../actions/agencyStatementAction";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import {
  getAgencyStatementList,
  getAgencyStatementListError,
  getAgencyStatementListIsLoading,
  getPurchasedAgencyCodes,
  getIsLoadingPurchasedAgencyCodes,
  getPurchasedAgencyCodesError,
  getSponsoredAgencyCodes,
  getIsLoadingSponsoredAgencyCodes,
  getSponsoredAgencyCodesError
} from "../selectors/agencyStatementListSelector";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  statementList: getAgencyStatementList(state),
  error: getAgencyStatementListError(state),
  isLoading: getAgencyStatementListIsLoading(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  purchasedAgencyCodes: getPurchasedAgencyCodes(state),
  isLoadingPurchasedAgencyCodes: getIsLoadingPurchasedAgencyCodes(state),
  purchasedAgencyCodesError: getPurchasedAgencyCodesError(state),
  sponsoredAgencyCodes: getSponsoredAgencyCodes(state),
  isLoadingSponsoredAgencyCodes: getIsLoadingSponsoredAgencyCodes(state),
  sponsoredAgencyCodesError: getSponsoredAgencyCodesError(state)
});

const mapDispatchToProps = {
  fetchAgencyStatementListDetails,
  fetchAccountingStatementViewPDF,
  fetchPurchasedAgencyCodes,
  fetchSponsoredAgencyCodes
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyStatement);
