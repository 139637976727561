export const getMinMax = ({
  effectiveDateOverride,
  proquoteMaxEffectiveDateDays,
  originalEffectiveDate,
  step
}) => {
  // if launchdarkly override is on, then min/max is null
  // ... which allows for any date to be used
  if (effectiveDateOverride) return { minDate: null, maxDate: null };

  const minDate = new Date();
  const maxDate = new Date();

  /**
   * DEFAULT RULES THAT ALWAYS APPLY
   */

  // MIN
  const numberOfMinDays = step < 3 ? 30 : 5;

  minDate.setDate(minDate.getDate() - numberOfMinDays);

  // MAX
  maxDate.setDate(
    maxDate.getDate() +
      (proquoteMaxEffectiveDateDays !== undefined
        ? Number(proquoteMaxEffectiveDateDays)
        : 90)
  );

  return { minDate, maxDate, originalEffectiveDate };
};
