import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { LoadingIndicator, Table, Icon } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;

const RenewalUpdates = props => {
  const {
    renewalUpdatesList,
    deleteError,
    isLoading,
    fetchRenewalUpdates,
    error,
    deleteRenewalUpdate
  } = props;

  const [prevDeleteError, setPrevDeleteError] = useState();
  const [showDeleteError, setShowDeleteError] = useState(true);

  const showDeleteErrorMessage = deleteError && showDeleteError;

  const deleteUpdate = row => () => {
    deleteRenewalUpdate(row);
  };

  const createColumns = () => [
    {
      key: "account_number",
      label: "Account Number"
    },
    {
      key: "policy_number",
      label: "Policy Number",
      element: row => (
        <Link to={`${routes.ACCOUNT_HOME}/${row.policy_number}`}>
          {row.policy_number}
        </Link>
      )
    },
    {
      key: "insured_name",
      label: "Policyholder Name"
    },
    {
      key: "stat_type_name",
      label: "Type",
      sortable: true,
      element: row => {
        const { policy_number, company_or_branch_code, agency_code } = row;
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseLink}/AgentsOnly/ufgAgent/PolicyCenter/codes/linktoDriver.asp?polidn=${policy_number}&cobr=${company_or_branch_code}&polagt=${agency_code}`}
          >
            {row.stat_type_name}
          </a>
        );
      }
    },
    {
      key: "remove",
      label: "Remove",
      className: "renewal-updates-table__remove",
      element: row => (
        <div
          className="remove-icon"
          role="presentation"
          onClick={deleteUpdate(row)}
        >
          <Icon icon="fasTimesCircle" />
        </div>
      )
    }
  ];

  const handleDismissDeleteError = () => {
    setShowDeleteError(false);
  };

  useEffect(() => {
    fetchRenewalUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deleteError !== prevDeleteError) {
      setPrevDeleteError(deleteError);
      setShowDeleteError(true);
    }
  }, [deleteError, prevDeleteError]);

  return (
    <div className="renewal-updates-table">
      {showDeleteErrorMessage && (
        <Alert bsStyle="danger" onDismiss={handleDismissDeleteError}>
          {deleteError}
        </Alert>
      )}
      {isLoading && <LoadingIndicator />}
      {renewalUpdatesList && renewalUpdatesList.length ? (
        <Table
          rowKey="policy_number"
          data={renewalUpdatesList}
          columns={createColumns()}
          itemsPerPage={20}
          showPagination
        />
      ) : error ? (
        <div>{error}</div>
      ) : null}
    </div>
  );
};

RenewalUpdates.propTypes = {
  fetchRenewalUpdates: PropTypes.func.isRequired,
  renewalUpdatesList: PropTypes.array.isRequired,
  deleteRenewalUpdate: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  deleteError: PropTypes.string
};

export default RenewalUpdates;
