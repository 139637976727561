import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  useForm,
  FormGroup,
  Form,
  DatePicker,
  Dropdown,
  Alert,
  Button,
  LoadingIndicator,
  formatDate
} from "@ufginsurance/ui-kit";
import { Translations } from "./../components/translations";
import {
  getDriverChangeUrl,
  handleRedirect,
  OTHER_POLICY_CHANGE_TYPE
} from "./formRedirectHelper";
import OtherChanges from "./other-changes/OtherChanges";

const DRIVER_CHANGE_REQUEST_TYPE = "driver-change";
const currentDate = formatDate(new Date(), "YYYY-MM-DD");

const LegacyPolicyChangesForm = ({
  policyInfo,
  requestChangeData,
  commercial_underwriter,
  agentCode,
  activeAgencyCode,
  processChange,
  activateOtherChangesModal,
  maxDate,
  minDate,
  pristine,
  submitting,
  invalid
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { changeRequests } = Translations.account_overview;

  const changeTypes =
    requestChangeData && requestChangeData.allowed_change_types;
  const dropDnChanTypes = (changeTypes || []).map(item => item);
  const dropDnArr = (dropDnChanTypes || []).map(key =>
    changeRequests.change_types.find(ct => ct.value === key)
  );

  const handleSubmission = ({ values }) => {
    const processPolicyInfo = {
      ...policyInfo,
      effectiveDate: values.effectiveDate,
      changeType: values.policyChangeInformation,
      commercial_underwriter,
      agentCode,
      activeAgencyCode
    };

    if (processPolicyInfo.changeType === DRIVER_CHANGE_REQUEST_TYPE) {
      getDriverChangeUrl(processPolicyInfo);
    } else {
      processChange(
        processPolicyInfo,
        response => {
          if (!response.is_successful) {
            setError(
              Translations.account_overview.changeRequests.other_message
            );
            setIsLoading(false);
            return;
          }
          setIsLoading(false);
          if (
            processPolicyInfo &&
            processPolicyInfo.changeType === OTHER_POLICY_CHANGE_TYPE
          ) {
            activateOtherChangesModal();
          } else {
            handleRedirect({
              ...processPolicyInfo,
              transid: response.transaction_number,
              underwriter_number: response.underwriter_number,
              change_indicator: response.change_indicator,
              change_id: response.change_id,
              activeAgencyCode,
              image_type: response.image_type,
              image_policy_date_range: response.image_policy_date_range
            });
          }
        },
        error => {
          setIsLoading(false);
          const responseData =
            (error && error.response && error.response.data) || {};
          if (
            Translations.account_overview.changeRequests.errors[
              responseData.error_code
            ]
          ) {
            setError(
              Translations.account_overview.changeRequests.errors[
                responseData.error_code
              ]
            );
          } else {
            const submitAsOtherMessage =
              "ERROR, INVALID IMAGE TYPE FOR INQUIRY";
            const shouldSubmitAsOther =
              responseData.message === submitAsOtherMessage ||
              !!responseData.show_change_as_other_message;
            setError(
              shouldSubmitAsOther
                ? Translations.account_overview.changeRequests.other_message
                : Translations.account_overview.changeRequests.general_error
            );
          }
        }
      );
      setIsLoading(true);
      setError(null);
    }
  };

  const initialValues = {
    effectiveDate: "",
    policyChangeInformation: ""
  };
  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const handleEffectiveDateOnChange = ({ field, value }) => {
    updateForm({
      values: {
        ...values,
        [field]: value
      },
      errors: {
        ...errors,
        effectiveDate: []
      }
    });
  };

  const handleChangeTypeOnChange = ({ event, field, value }) => {
    if (value === DRIVER_CHANGE_REQUEST_TYPE) {
      form.values.effectiveDate = currentDate;
    }

    handleOnChange({ event, field, value });
  };

  return (
    <>
      <div className="policy-change">
        <Form className="policy-change__form" context={form}>
          <FormGroup>
            <Dropdown
              id="policyChangeInformation"
              name="policyChangeInformation"
              label={changeRequests.change_type}
              placeholder="-- Select One --"
              options={dropDnArr}
              onChange={handleChangeTypeOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.policyChangeInformation}
              size="lg"
              required
            />
            <DatePicker
              id="effectiveDate"
              name="effectiveDate"
              label={changeRequests.effective_date}
              onChange={handleEffectiveDateOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.effectiveDate}
              format="DATE"
              required
              className="effective-date"
              minDate={minDate}
              maxDate={maxDate}
              disabled={
                values.policyChangeInformation === DRIVER_CHANGE_REQUEST_TYPE
              }
            />
            <Button
              id="policy-changes-submit-button"
              type="submit"
              variant="primary"
              labelSpace
              disabled={pristine || submitting || invalid}
            >
              Next
            </Button>
          </FormGroup>
        </Form>

        {values.policyChangeInformation === "other" && (
          <OtherChanges
            updateParentEffectDate={handleEffectiveDateOnChange}
            policyInfo={policyInfo}
            effectiveDate={values.effectiveDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
        {isLoading && (
          <div>
            <LoadingIndicator />
          </div>
        )}
        {!!error && (
          <div>
            <Alert type="error">{error}</Alert>
          </div>
        )}
      </div>
    </>
  );
};

LegacyPolicyChangesForm.propTypes = {
  policyInfo: PropTypes.object,
  requestChangeData: PropTypes.object,
  commercial_underwriter: PropTypes.string,
  agentCode: PropTypes.string,
  activeAgencyCode: PropTypes.string,
  processChange: PropTypes.func,
  activateOtherChangesModal: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  pristine: PropTypes.string,
  submitting: PropTypes.string,
  invalid: PropTypes.string
};

export default LegacyPolicyChangesForm;
