import React from "react";
import { formatDate } from "@ufginsurance/ui-kit";
import { genderOptions, stateOptions } from "../../shared/constants";

//constant for gender description
const DriverCardContents = ({ driver }) => {
  const coverableDetailItems = [
    {
      label: "Date of Birth",
      value: `${formatDate(driver.dateOfBirth, "MM/DD/YYYY")}`
    },
    { label: "Gender", value: `${genderOptions[driver.gender_UFG]}` },
    { label: "State", value: `${stateOptions[driver.licenseState]}` },
    { label: "License", value: `${driver.licenseNumber}` }
  ];

  return (
    <div className="oq__coverable__card__content">
      <div className="oq__coverable__card__content__column-container">
        <div className="oq__coverable__card__content__column__driver">
          <ul className="">
            {coverableDetailItems.map(c => (
              <li key={c.label} className="oq__coverable__card__content__item">
                <b className="oq__coverable__card__content__item-label">
                  {c.label}:
                </b>
                <span className="oq__coverable__card__content__item-value">
                  {c.value}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DriverCardContents;
