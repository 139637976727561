import { BP7EndorsementType_UFG } from "./BP7EndorsementType_UFG";
import { WCMFactor14 } from "./WCMFactor14";
import { CA7LimitText } from "./CA7VehicleLiab";
import { CA7CombinedSingleLimitText1 } from "./CA7CombinedSingleLimitText1";
import { BP7BusinessIncome } from "./BP7BusinessIncome";
import { BP7WindstormOrHailExcl } from "./BP7WindstormOrHailExcl";

export const coverageTermOverrides = {
  BP7EndorsementType_UFG,
  WCMFactor14,
  CA7LimitText,
  CA7CombinedSingleLimitText1
};

/**
 * imports an object based on the panel "key"
 * object example:
  {
    jsxBefore: (<>Some React Element</>) // optional
    jsxAfter: (<>Some React Element</>) // optional
    jsxAfterTitle: (<>Some React Element</>) // optional
  }
 */
export const coveragePanelJsx = {
  BP7BusinessIncome,
  BP7WindstormOrHailExcl
};
