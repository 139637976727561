import { connect } from "react-redux";
import CommercialTab from "./CommercialTab";
import { updateQuoteProposal } from "../../../actions/commercialLinesAction";
import {
  fetchRenewalUpdates,
  deleteRenewalUpdate,
  fetchPolicyDecs,
  removePolicyDec,
  fetchClientNotices,
  removeClientNotice,
  resetClientNotices,
  fetchClientNoticeDocument,
  getPolicyDecsCommercialDocument,
  DismissErrorModal
} from "../../../actions/homeCommercialActions";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getPastFetchedRenewalAgencyCode,
  getPolicyDecsCommercialDocumentFailed,
  getPastFetchedQuoteProposalsCode,
  getPastQuoteProposalsFilter,
  getPastFetchedQuoteProposalsSpecialtyCompanyBranch,
  areQuoteProposalsLoading
} from "./CommercialTabSelectors";
import {
  getisSpecialtyPortalView,
  getActiveSpecialtyCompanyBranch,
  getIsMercerSpecialtyBrokerPortalView
} from "../../../portalview/changePortalViewStateSelectors";

// TODO: Do not spread state like this. Several issues with it but things like
// error and loading states will be overwritten by 2nd spread.
const mapStateToProps = state => {
  return {
    ...state.commercialLines,
    ...state.homeCommercial,
    renewalUpdatesList: state.homeCommercial.renewalUpdatesList,
    agencyCode: getSelectedAgencyCode(state),
    is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
    policyDecsCommercialDocumentFailed: getPolicyDecsCommercialDocumentFailed(
      state
    ),
    pastFetchedRenewalAgencyCode: getPastFetchedRenewalAgencyCode(state),
    pastFetchedQuoteProposalsCode: getPastFetchedQuoteProposalsCode(state),
    pastQuoteProposalsFilter: getPastQuoteProposalsFilter(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    pastFetchedQuoteProposalsSpecialtyCompanyBranch: getPastFetchedQuoteProposalsSpecialtyCompanyBranch(state),
    isMercerSpecialtyBrokerPortalView: getIsMercerSpecialtyBrokerPortalView(state),
    areQuoteProposalsLoading: areQuoteProposalsLoading(state),
    activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
  };
};

const mapDispatchToProps = {
  updateQuoteProposal,
  fetchRenewalUpdates,
  deleteRenewalUpdate,
  fetchPolicyDecs,
  removePolicyDec,
  fetchClientNotices,
  removeClientNotice,
  resetClientNotices,
  fetchClientNoticeDocument,
  getPolicyDecsCommercialDocument,
  DismissErrorModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CommercialTab);
