import { connect } from "react-redux";
import {
  cancelQuote,
  dismissError,
  goToPreviousStep,
  savePilotAddressValidationStep
} from "../actions/newQuoteActions";

import AddressValidation from "./AddressValidation";

// TODO: selectors
const mapStateToProps = state => {
  const step = state.newQuote.steps.pilotAddressValidation;
  return {
    enteredAddress: step.enteredAddress,
    suggestedAddress: step.suggestedAddress,
    error: step.error
  };
};

const mapDispatchToProps = {
  onClose: cancelQuote,
  onBackClick: goToPreviousStep,
  onDismissError: dismissError,
  onSubmit: savePilotAddressValidationStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressValidation);
