import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo
} from "react";
import _isEqual from "lodash/isEqual";
import {
  Button,
  Input,
  Select,
  Form,
  FormGroup,
  useForm,
  Popover,
  Icon
} from "@ufginsurance/ui-kit";
import {
  vehicleTypes,
  vehicleRadiusClassOptions,
  vehicleSizeClassOptions
} from "../../shared/constants";
import {
  getVehicleClassData,
  getSizeClassByWeight
} from "./getVehicleClassData";
import { guid, locationDisplayName } from "../../shared/util";
import { getAllVehicles, addUpdateVehicles } from "./getAllVehicles.js";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import VinInputSelector from "./VinInputSelector";

import "./VehicleFormStep1.scss";

const VehicleFormStep1 = ({
  currentVehicle,
  onNextStep,
  locations,
  onCancel,
  mode,
  setVehicleClasses
}) => {
  const {
    quoteData,
    toastErrr,
    updateSupportingDataPromise,
    supportingData,
    updateCoverablesPromise,
    quoteIsUpdating
  } = useContext(OnlineQuotingContext);

  const [disableVinFields, setDisableVinFields] = useState({});
  const [selectedVin, setSelectedVin] = useState();
  const [getingClassData, setGettingClassData] = useState();

  const convertVehicleJsonDTOToForm = useCallback(
    v => {
      const vehicle = {
        ...v,
        locationId: String(v?.location?.fixedID),
        make: v.make || "",
        vin: v.vin || "",
        model: v.model,
        originalCostNew: v?.originalCostNew ? String(v.originalCostNew) : "",
        year: v?.year ? String(v.year) : "",
        grossVehicleWeight:
          v?.grossVehicleWeight || v?.grossVehicleWeight === 0
            ? String(v.grossVehicleWeight)
            : "",
        grossCombinationWeight:
          v?.grossCombinationWeight || v?.grossCombinationWeight === 0
            ? String(v.grossCombinationWeight)
            : "",
        entityName: v?.entityName || `entity.${v?.vehicleType}`,
        vehicleType:
          v?.fixedId &&
          (supportingData?.trailerIDs || []).find(x => x === v.fixedId)
            ? "CA7Trailer"
            : v?.vehicleType || v?.entityName?.replace("entity.", ""),
        sizeClass: v?.sizeClass,
        radiusClass: v?.radiusClass
      };
      if (!vehicle?.fixedId) delete vehicle.fixedId;

      return vehicle;
    },
    [supportingData?.trailerIDs]
  );

  const convertVehicleToJsonDTO = v => {
    let vehicle = {
      ...currentVehicle,
      ...v,
      id: currentVehicle?.id || guid(),
      year: Number(v.year),
      originalCostNew: Math.trunc(Number(v.originalCostNew)),
      locationId: Number(v.locationId),
      grossVehicleWeight: v.grossVehicleWeight
        ? Math.trunc(Number(v.grossVehicleWeight))
        : null,
      grossCombinationWeight: v.grossCombinationWeight
        ? Math.trunc(Number(v.grossCombinationWeight))
        : null,
      entityName:
        v?.vehicleType === "CA7Trailer"
          ? "entity.CA7Truck"
          : v.entityName || `entity.${v.vehicleType}`,
      vehicleType: v.vehicleType || currentVehicle.vehicleType || "",
      statedAmount: 0 // OOQ-13657 - statedAmount necessary for states that have taking the new OCP rating
    };

    // forcing this again here in case the user made any changes that would affect this
    // ... like selecting a VIN after selecting Trailer type
    if (vehicle?.vehicleType === "CA7Trailer") {
      vehicle.grossVehicleWeight = "5000"; // TRAILER is hard-coded to 5000
    }

    //OOQ-4520  actual defaults values for the truck type.
    if (
      vehicle?.vehicleType === "CA7Truck" ||
      vehicle?.vehicleType === "CA7Trailer"
    ) {
      vehicle = {
        ...vehicle,
        autoRentedToOtherMotorCarriers:
          v.autoRentedToOtherMotorCarriers || "No",
        trailerHowUsed: v.trailerHowUsed || "Not Applicable",
        usedToTransportProperty: v.usedToTransportProperty || "No",
        vehicleType: "CA7Truck"
      };
    }

    // Private pasenger vehicles require this value in order to get a rating
    // https://ufginsurance.atlassian.net/browse/OOQ-13445
    if (vehicle?.vehicleType === "CA7PrivatePassenger")
      vehicle = {
        ...vehicle,
        accidentPreventionDiscount: "No"
      };

    //get sibling vehicles?
    vehicle.location = locations.find(
      l => Number(l.fixedID) === Number(vehicle.locationId)
    );
    vehicle.territoryCode = vehicle?.location?.territoryCode;

    if (!vehicle?.fixedId) delete vehicle.fixedId;

    return vehicle;
  };

  //try to figure out what the new fixedId is...
  const getNewVehicle = (data, oldIds) => {
    let foundVehicle = null;
    const newVehicles = getAllVehicles({ quoteData: data });
    newVehicles.forEach(v => {
      if (!oldIds.includes(v.fixedId)) {
        foundVehicle = v;
      }
    });
    return foundVehicle;
  };

  const handleFormSubmit = () => {
    let vehicle = convertVehicleToJsonDTO(values);

    const oldIds = getAllVehicles({ quoteData }).map(x => x.fixedId);

    const isNew = !vehicle?.fixedId;

    setGettingClassData(true);
    getVehicleClassData({ vehicle, supportingData })
      .then(({ classData, foundClass }) => {
        setVehicleClasses(classData);

        // if we are editing a vehicle and no values have not changed...
        // just go to step 3 without submitting an update
        if (!!vehicle?.fixedId && _isEqual(initialValues, values)) {
          onNextStep(vehicle);
          return false;
        }

        if (!vehicle?.classCode && foundClass) {
          vehicle = { ...vehicle, ...foundClass };
        }

        const vehicles = addUpdateVehicles({
          quoteData,
          vehicle
        });

        setGettingClassData(false);

        updateCoverablesPromise({
          coverableType: "vehicle",
          coverables: { vehicles },
          action: isNew ? "Adding" : "Updating"
        })
          .then(({ data, error }) => {
            if (data && !error) {
              let newVehicle = null;
              //if new vehicle get that vehicle
              if (!vehicle?.fixedId) {
                newVehicle = {
                  ...vehicle,
                  ...getNewVehicle(data, oldIds),
                  isVehicleNew: true
                };

                //save the actual fixedID of trailer type to supportingData.
                if (newVehicle.actualVehicleType === "CA7Trailer") {
                  const previousTrailers = supportingData?.trailerIDs || [];
                  const trailerIDs = [
                    ...new Set([...previousTrailers, newVehicle.fixedId])
                  ];
                  updateSupportingDataPromise({
                    dataToMergeAndSave: { trailerIDs }
                  }).then(() =>
                    // after supporting data is saved, goto next step
                    onNextStep(newVehicle)
                  );
                } else {
                  onNextStep(newVehicle);
                }
              } else {
                newVehicle = { ...vehicle, isVehicleNew: false };
                onNextStep(newVehicle);
              }
            }
          })
          .catch(({ error }) => {
            toastErrr({
              action: "updateCoverablesPromise",
              misc: {
                coverables: { vehicles: [vehicle] }
              },
              description: "vehicle add/update",
              error,
              displayMessage: `Failed to ${isNew ? "add" : "update"} vehicle`
            });
          });
      })
      .catch(({ error }) => {
        toastErrr({
          action: "getVehicleClassData",
          coverables: { vehicles: [vehicle] },
          description: "failed to get vehicle class data",
          error,
          displayMessage: `Unable to load vehicle class data`
        });
      });
  };

  // primary location for default location when creating new Vehicle
  const primaryLocation = (locations || []).find(l => l.isPrimary);

  const initialValues = useMemo(
    () =>
      currentVehicle
        ? convertVehicleJsonDTOToForm(currentVehicle)
        : {
            vehicleType: "",
            locationId: String(primaryLocation?.fixedID) || "",
            vin: "",
            year: "",
            make: "",
            model: "",
            grossVehicleWeight: "",
            grossCombinationWeight: "",
            originalCostNew: "",
            sizeClass: "",
            radiusClass: "",
            actualVehicleType: ""
          },
    [convertVehicleJsonDTOToForm, currentVehicle, primaryLocation?.fixedID]
  );

  useEffect(() => {
    // when loading the component, disableVinFields is an empty obect...
    // if we are returning, then intialvalues have values... and update the
    // disabled fields if they have values
    const updateDisabledVinFields = {
      vin: !!initialValues.vin,
      year: mode === "EDIT",
      make: mode === "EDIT",
      model: mode === "EDIT",
      originalCostNew: mode === "EDIT",
      vehicleType: mode === "EDIT"
    };
    // only update if the new values are different (prevents looping)
    if (!_isEqual(updateDisabledVinFields, disableVinFields)) {
      setDisableVinFields(updateDisabledVinFields);
    }
  }, [disableVinFields, initialValues, mode, selectedVin]);

  //setup location dropdowns.
  const locationOptions = (locations || []).map(loc => {
    return {
      value: String(loc.fixedID),
      label: locationDisplayName(loc)
    };
  });

  //if there is only one location select it by default.
  if (locationOptions.length === 1) {
    initialValues.locationId = locationOptions[0].value.toString();
  }

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    updateForm
  } = form;

  /**
   * reset the radiusClass when it doesn't allow the current value
   */
  useEffect(() => {
    if (
      values.vehicleType === "CA7Truck" &&
      values.radiusClass === "Long Distance" &&
      ["Medium Truck", "Heavy Truck", "Extra-Heavy Truck"].includes(
        values?.sizeClass
      )
    )
      updateForm({ values: { radiusClass: "" }, errors: { radiusClass: [] } });
  }, [updateForm, values.radiusClass, values.sizeClass, values.vehicleType]);

  const handleYearValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    if (!!value) {
      const y = !isNaN(value) ? Number(value) : null;
      if (!y || y < 1900)
        fieldErrors.push("Vehicle year cannot be less than the year 1900.");
      if (y && y > 2050) {
        fieldErrors.push("Vehicle year cannot be greater than the year 2050.");
      }
    }
    return fieldErrors;
  };

  const handleChangeVehicleType = ({ field, value }) => {
    if (value === "CA7Trailer") {
      updateForm({
        values: {
          [field]: value,
          actualVehicleType: value,
          sizeClass: "Trailer",
          grossVehicleWeight: "5000" // TRAILER is hard-coded to 5000
        },
        errors: {
          grossVehicleWeight: [],
          grossCombinationWeight: [],
          vehicleType: []
        }
      });
    } else if (value === "CA7PrivatePassenger") {
      updateForm({
        values: {
          [field]: value,
          actualVehicleType: value,
          sizeClass: "",
          grossVehicleWeight: ""
        },
        errors: {
          grossVehicleWeight: [],
          sizeClass: [],
          vehicleType: []
        }
      });
    } else {
      // is Commercial
      updateForm({
        values: {
          [field]: value,
          actualVehicleType: value,
          sizeClass: getSizeClassByWeight(selectedVin?.gross_vehicle_weight),
          grossVehicleWeight: selectedVin?.gross_vehicle_weight || ""
        },
        errors: {
          grossVehicleWeight: [],
          sizeClass: [],
          vehicleType: []
        }
      });
    }
  };

  const handleSizeChange = ({ field, value }) => {
    updateForm({
      values: { [field]: value },
      errors: { [field]: [], sizeClass: [] }
    });
  };

  return (
    <div>
      <Form className="oq__form__vehicle__step1 oq__form" context={form}>
        <FormGroup wrap={false}>
          <VinInputSelector
            form={form}
            setSelectedVin={setSelectedVin}
            disableVinFields={disableVinFields}
            setDisableVinFields={setDisableVinFields}
          />
          <Select
            id="locationId"
            name="locationId"
            label="Location"
            labelElement={
              <Popover
                id="appetiteTooltip"
                tooltip
                trigger="hover"
                triggerContent={<Icon icon="fasInfoCircle" size="1x" />}
                popoverContent="If the garaging location isn't currently listed, please add the new location to our list."
              />
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.locationId}
            options={locationOptions || []}
            disabled={mode === "EDIT"}
            isClearable={false}
            required
            size="fill"
          />
          <Select
            id="vehicleType"
            name="vehicleType"
            label="Vehicle Type"
            onChange={handleChangeVehicleType}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.vehicleType}
            disabled={disableVinFields.vehicleType || mode === "EDIT"}
            options={
              vehicleTypes?.map(v => ({
                label: v.label,
                value: v.key
              })) || []
            }
            isClearable={false}
            required
            size="auto"
          />
        </FormGroup>

        <FormGroup groupErrors className="oq__vehicle-orm__row2">
          <Input
            id="year"
            name="year"
            label="Year"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleYearValidate}
            value={values.year}
            required
            size="sm"
            disabled={disableVinFields.year}
            className="oq__vehicle__year"
            numbersOnly
            maxLength={4}
          />
          <Input
            id="make"
            name="make"
            label="Make"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.make}
            required
            disabled={disableVinFields.make}
          />
          <Input
            id="model"
            name="model"
            label="Model"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.model}
            required
            disabled={disableVinFields.model}
          />
          <Input
            id="originalCostNew"
            name="originalCostNew"
            label="Original Cost New"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.originalCostNew}
            required
            disabled={disableVinFields.originalCostNew}
            mask="currency"
            stripMaskFromValue
          />
          {!!values?.grossVehicleWeight &&
            values?.vehicleType !== "CA7Trailer" && (
              <Input
                id="grossVehicleWeight"
                name="grossVehicleWeight"
                label="Vehicle Weight"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.grossVehicleWeight}
                required
                disabled
              />
            )}
        </FormGroup>

        {(values.vehicleType === "CA7Truck" ||
          values.vehicleType === "CA7Trailer") && (
          <>
            <FormGroup groupErrors>
              <Select
                id="sizeClass"
                name="sizeClass"
                label="Size Class"
                onChange={handleSizeChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.sizeClass || ""}
                disabled={
                  (!!values.sizeClass && mode === "EDIT") ||
                  !!selectedVin?.gross_vehicle_weight ||
                  !!selectedVin?.gross_combined_weight ||
                  values.vehicleType === "CA7Trailer"
                }
                options={
                  values.vehicleType === "CA7Trailer"
                    ? [{ label: "Trailer", value: "Trailer" }]
                    : vehicleSizeClassOptions || []
                }
                isClearable={false}
                required
                size="lg"
              />
            </FormGroup>

            <FormGroup>
              <Select
                id="radiusClass"
                name="radiusClass"
                label="Truck Usage Details"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.radiusClass}
                disabled={!!values.radiusClass && mode === "EDIT"}
                options={
                  // remove Long Distance option if Size Class is not a Light Truck type
                  vehicleRadiusClassOptions?.filter(o => {
                    if (o.value === "Long Distance")
                      return ![
                        "Medium Truck",
                        "Heavy Truck",
                        "Extra-Heavy Truck"
                      ].includes(values?.sizeClass);
                    return true;
                  }) || []
                }
                showSearch={false}
                isClearable={false}
                required
                size="lg"
              />
            </FormGroup>
          </>
        )}
        <FormGroup align="right">
          <Button
            variant="plain"
            className="cancel"
            onClick={() => onCancel()}
            disabled={quoteIsUpdating || getingClassData}
          >
            {mode === "EDIT" ? "Close" : "Cancel"}
          </Button>

          <Button
            variant="primary"
            className="continue"
            disabled={
              invalidFields.length > 0 || quoteIsUpdating || getingClassData
            }
            onClick={() => handleFormSubmit()}
          >
            Continue
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default VehicleFormStep1;
