import React from "react";
import { Currency } from "@ufginsurance/ui-kit";

const EmployeeCardContents = ({ employee }) => {
  const coverableDetailItems = [
    { label: "Class Code", value: employee.classCodeForClassDescription },
    { label: "If any", value: employee.ifAny },
    { label: "Annual Payroll", value: Number(employee.payroll), currency: true }
  ];

  return (
    <div className="oq__coverable__card__employee">
      <div className="oq__coverable__card__employee__header">
        {employee.classDescriptionByClassCode}
      </div>
      <div className="oq__coverable__card__content">
        <div className="oq__coverable__card__content__column-container">
          <div className="oq__coverable__card__content__column__employee">
            <ul className="">
              {coverableDetailItems.map(c => {
                if (c.visible !== false)
                  return (
                    <li
                      key={c.label}
                      className="oq__coverable__card__content__item"
                    >
                      <b className="oq__coverable__card__content__item-label">
                        {c.label}:
                      </b>
                      <span className="oq__coverable__card__content__item-value">
                        {!!c.currency ? (
                          <Currency amount={c.value} includeDecimals={false} />
                        ) : (
                          c.value
                        )}
                      </span>
                    </li>
                  );
                return null;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCardContents;
