import React, { useEffect } from "react";
import {
  getActiveSpecialtyCompanyBranch,
  getisSpecialtyPortalView
} from "../../../portalview/changePortalViewStateSelectors";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CMSLayout from "../../../cms-content/CMSLayout";
import * as routes from "../../../constants/routes";
import { useCMSContext } from "../../../cms-content/context";
import * as claimsResourcesServices from "../services/ClaimsResourcesServices";
import { addReportAClaimTrigger } from "../../agency-interface/util";
import { startReportAClaim } from "../../../top-action-cards/report-claim/actions/reportClaimActions";

const ClaimsResources = ({
  startReportAClaim,
  activeSpecialtyCompanyBranch
}) => {
  const {
    cmsData,
    fetchClaimsResourcesContent,
    fetchTableOfContents,
    updateCMSHeaderRoute,
    headerRoute
  } = useCMSContext();

  useEffect(() => {
    if (cmsData?.tableOfContents?.data && cmsData?.content?.data) {
      addReportAClaimTrigger(() => {
        startReportAClaim();
      });
    }
  }, [cmsData, startReportAClaim]);

  useEffect(() => {
    if (headerRoute !== routes.CLAIMS_RESOURCES_HEADER) {
      updateCMSHeaderRoute(routes.CLAIMS_RESOURCES_HEADER);
    }
  }, [headerRoute, updateCMSHeaderRoute]);

  return (
    <>
      <CMSLayout
        baseRoute={routes.CLAIMS_RESOURCES}
        fetchContent={fetchClaimsResourcesContent}
        fetchTableOfContents={fetchTableOfContents}
        companyBranch={activeSpecialtyCompanyBranch}
        defaultBreadcrumbText="Claims"
        service={claimsResourcesServices}
      ></CMSLayout>
    </>
  );
};

ClaimsResources.propTypes = {
  activeSpecialtyCompanyBranch: PropTypes.string,
  startReportAClaim: PropTypes.func
};

const mapDispatchToProps = {
  startReportAClaim
};

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsResources);
