import React, { useState, useEffect, useCallback, useContext } from "react";
import * as routes from "../constants/routes";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { logger } from "../loggers";
import { ContentHeader, LoadingIndicator } from "@ufginsurance/ui-kit";
import BottomNavigation from "./shared/BottomNavigation";
import CreateQuoteModal from "./step2/CreatingQuoteModal";
import CreateQuoteErrorModal from "./step2/CreateQuoteErrorModal";
import OnlineQuotingContext from "./OnlineQuotingContext";
import ClassCodes from "./step2/ClassCodes";
import "./Step2Classify.scss";

const Step2Classify = ({ history, activeAgencyCode, userInfo }) => {
  const {
    supportingData,
    step,
    setStep,
    createQuote,
    navigateToStep,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const continueDisabled = !supportingData?.classCode?.code;

  const [showCreateQuoteModal, setShowCreateQuoteModal] = useState(false);
  const [quoteErrorForModal, setQuoteErrorForModal] = useState(null);

  useEffect(() => {
    if (step !== 2) setStep(2);
  }, [setStep, step]);

  const onContinue = useCallback(() => {
    setShowCreateQuoteModal(true);
    const dataToSave = supportingData;

    // add referenceId so the database can link this data to the PC data
    //  dataToSave.referenceId = supportingData.referenceId;
    dataToSave.currentPage = "3";

    // CREATE QUOTE
    createQuote({ dataToSave })
      .then(({ success, error, data }) => {
        if (success) {
          if (data?.dto) {
            logger.event({
              category: "OQ_quote",
              action: "pc_created"
            });
            history.push(routes.ONLINE_QUOTING_STEP3);
          } else {
            setShowCreateQuoteModal(false);
            toastErrr({
              action: "createQuote",
              description: "unable to create quote",
              payload: dataToSave,
              error
            });
          }
        }
      })
      .catch(error => {
        setShowCreateQuoteModal(false);

        if (error?.error?.message === "AOR error") {
          setQuoteErrorForModal("AOR error");
        } else {
          setShowCreateQuoteModal(false);
          toastErrr({
            action: "createQuote",
            description: "unable to create quote",
            payload: dataToSave,
            error
          });
        }
      });
  }, [createQuote, history, supportingData, toastErrr]);

  const returnToStep1 = useCallback(() => {
    navigateToStep({ step: 1 }, ({ success, error }) => {
      if (success) history.push(routes.ONLINE_QUOTING_STEP1);
      else {
        toastErrr({
          action: "navigateToStep",
          description: "unable to return to step 1",
          error
        });
      }
    });
  }, [history, navigateToStep, toastErrr]);

  // if we don't have data at the beginning... show the loading indicator
  if (!supportingData) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <div>
        <ContentHeader>What type of business is this?</ContentHeader>
        <ClassCodes activeAgencyCode={activeAgencyCode} userInfo={userInfo} />
        {showCreateQuoteModal && <CreateQuoteModal />}
        {!!quoteErrorForModal && (
          <CreateQuoteErrorModal
            error={quoteErrorForModal}
            onHide={() => setQuoteErrorForModal(null)}
          />
        )}
      </div>
      <BottomNavigation
        left={{
          text: "Back to Business Information",
          onClick: () => returnToStep1(true)
        }}
        right={{
          text: "Continue to Check Eligibility",
          onClick: () => onContinue(true),
          disabled: continueDisabled
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Step2Classify);
