import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { Translations } from "../../../components/translations";
import { getAgencyBillPastDue } from "../../services/accountingReportService";
import { DocumentService } from "../../../services/documentService";
import { Button, LoadingIndicator } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const INTERNAL_AGENCY_CODE = "109015";
const STATUSES = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  NO_RESULTS: "NO_RESULTS",
  ERROR: "ERROR",
  INTERNAL_USER: "INTERNAL_USER",
  NO_ACCESS: "NO_ACCESS"
};

const AgencyBillPastDue = ({
  selectedAgencyCode,
  hasAgencyAccountingReportsAccess
}) => {
  const [status, setStatus] = useState(STATUSES.LOADING);
  const [documentData, setDocumentData] = useState();

  const openDocument = data => {
    if (!!data) {
      const documentService = new DocumentService();

      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(data, "");
    }
  };

  useEffect(() => {
    if (selectedAgencyCode === INTERNAL_AGENCY_CODE)
      setStatus(STATUSES.INTERNAL_USER);
    else if (!hasAgencyAccountingReportsAccess) setStatus(STATUSES.NO_ACCESS);
    else if (hasAgencyAccountingReportsAccess) {
      setStatus(STATUSES.LOADING);

      getAgencyBillPastDue(selectedAgencyCode)
        .then(res => {
          if (res.data !== null) {
            setDocumentData(res?.data);
            openDocument(res.data);
          }
          setStatus(STATUSES.SUCCESS);
        })
        .catch(err => {
          if (err?.response?.status === 404) {
            setStatus(STATUSES.NO_RESULTS);
          } else {
            setStatus(STATUSES.ERROR);
            toast(Translations.general.error, {
              position: "top-center",
              className: "error",
              autoClose: false
            });
          }
        });
    }
  }, [hasAgencyAccountingReportsAccess, selectedAgencyCode]);

  return (
    <section id="agency-bill-past-due">
      {status === STATUSES.LOADING && <LoadingIndicator />}

      {status === STATUSES.INTERNAL_USER && (
        <p>{Translations.reports.internal_user}</p>
      )}

      {status === STATUSES.SUCCESS && (
        <>
          <p>
            View agency bill accounts that are both due soon (0-30 days) and
            past due (31-60 days, 61-91 days, and over 90 days).
          </p>
          <p>
            <Button
              isLink
              onClick={() => {
                openDocument(documentData);
              }}
            >
              View Agency Bill Past Due report.
            </Button>
          </p>
        </>
      )}

      {status === STATUSES.NO_RESULTS && (
        <p>
          There is no Agency Bill Past Due report for your agency this month.
        </p>
      )}

      {status === STATUSES.NO_ACCESS && (
        <>
          <p>
            Reports are only available to employees who have access. If you have
            questions, please contact your agency administrator.
          </p>
          <p>
            <HelpDeskMessage display="questionsOrAssistance" />
          </p>
        </>
      )}

      {status === STATUSES.ERROR && <p>{Translations.general.error}</p>}
    </section>
  );
};

const mapStateToProps = state => ({
  selectedAgencyCode: getSelectedAgencyCode(state),
  hasAgencyAccountingReportsAccess:
    sessionSelector.hasAgencyAccountingReportsAccess(state)
});

AgencyBillPastDue.propTypes = {
  selectedAgencyCode: PropTypes.string.isRequired,
  hasAgencyAccountingReportsAccess: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(AgencyBillPastDue);
