export function getRequestChange(store) {
  return store.policyChange.policyChangeInformation.data;
}

export function getErrorPageload(store) {
  return store.policyChange.policyChangeInformation.error;
}

export function getIsLoading(store) {
  return store.policyChange.policyChangeInformation.isLoading;
}
