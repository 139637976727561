const getSelectedClassColumns = ({ vehicleType }) => {
  let _tableColumns = [];
  if (vehicleType === "CA7Truck") {
    _tableColumns = [
      {
        key: "sizeClass",
        label: "Size Class"
      },
      {
        key: "businessUse",
        label: "Business Use",
        sortable: true
      },
      {
        key: "radiusClass",
        label: "Radius Class",
        sortable: true
      },
      {
        key: "secondaryClass",
        label: "Class Description",
        sortable: true
      }
    ];
  } else if (vehicleType === "CA7PrivatePassenger") {
    _tableColumns = [
      {
        key: "type",
        label: "Type",
        sortable: true
      },
      {
        key: "operatorExperience",
        label: "Operator Experience",
        sortable: true
      },
      {
        key: "use",
        label: "Business Use",
        sortable: true
      }
    ];
  }

  // both vehicle types get the class code column
  _tableColumns.push({
    key: "classCode",
    label: "Class Code",
    className: "oq__classes__class__class-code",
    sortable: true
  });

  return _tableColumns;
};

export default getSelectedClassColumns;
