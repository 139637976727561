import { v4 } from "uuid";
import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";

const yearToDateLossReducer = (
  state = initialState.yearToDateLossListReport,
  action
) => {
  switch (action.type) {
    case types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_STARTED:
      return {
        ...state,
        isLoading: true,
        yearToDateLossList: [],
        error: false
      };
    case types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        yearToDateLossList: action.payload.map(list => ({
          id: v4(),
          ...list
        })),
        error: false
      };
    case types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        yearToDateLossList: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default yearToDateLossReducer;
