import { connect } from "react-redux";
import Home from "./Home";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  has_personal_lines_contract: sessionSelector.agencyHasPersonalLinesContract(
    state
  ),
  has_commercial_lines_contract: sessionSelector.agencyHasCommercialLinesContract(
    state
  ),
  hasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state)
});

export default connect(mapStateToProps)(Home);
