import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory } from "react-router-dom";
import * as agencyInterfaceServices from "../resources/agency-interface/services/AgencyInterfaceServices";
import * as claimsResourcesServices from "../resources/claims/services/ClaimsResourcesServices";

const initialState = {
  agencyInterfaceChecklistShow: false,
  headerRoute: "",
  tableOfContentId: null,
  tableOfContents: {
    previousFetchedProductId: null,
    data: [],
    isLoading: false,
    error: null
  },
  currentHeader: {
    sequence: null,
    type: "",
    description: "",
    id: ""
  },
  content: {
    data: null,
    isLoading: false,
    error: null
  }
};
const CMSContext = createContext(initialState);

const FETCH_CMS_TABLE_OF_CONTENTS = "FETCH_CMS_TABLE_OF_CONTENTS";
const UPDATE_CMS_TABLE_OF_CONTENT_ID = "UPDATE_CMS_TABLE_OF_CONTENT_ID";
const FETCH_CMS_CONTENT_STARTED = "FETCH_CMS_CONTENT_STARTED";
const FETCH_CMS_CONTENT_SUCCEEDED = "FETCH_CMS_CONTENT_SUCCEEDED";
const UPDATE_CMS_HEADER_ROUTE = "UPDATE_CMS_HEADER_ROUTE";
const UPDATE_AGENCY_INTERFACE_CHECKLIST_MODAL_SHOW =
  "UPDATE_AGENCY_INTERFACE_CHECKLIST_MODAL_SHOW";
  const UPDATE_CURRENT_HEADER = "UPDATE_CURRENT_HEADER";

export const useCMSContext = () => useContext(CMSContext);

const cmsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CMS_TABLE_OF_CONTENT_ID:
      return {
        ...state,
        tableOfContentId: action.payload
      };
    case FETCH_CMS_TABLE_OF_CONTENTS:
      return {
        ...state,
        tableOfContents: {
          ...state.tableOfContents,
          data: action.payload
        }
      };
    case FETCH_CMS_CONTENT_STARTED:
      return {
        ...state,
        content: {
          ...state.tableOfContents,
          data: null,
          isLoading: true
        }
      };
    case FETCH_CMS_CONTENT_SUCCEEDED:
      return {
        ...state,
        tableOfContentId: action.payload.contentId,
        content: {
          ...state.tableOfContents,
          data: action.payload.content,
          isLoading: false
        }
      };
    case UPDATE_CMS_HEADER_ROUTE:
      return {
        ...state,
        headerRoute: action.payload,
        tableOfContentId: null,
        tableOfContents: {
          previousFetchedProductId: null,
          data: [],
          isLoading: false,
          error: null
        }
      };
    case UPDATE_AGENCY_INTERFACE_CHECKLIST_MODAL_SHOW:
      return {
        ...state,
        agencyInterfaceChecklistShow: action.payload
      };
      case UPDATE_CURRENT_HEADER:
        return {
          ...state,
          currentHeader: action.payload
        };
    default:
      return state;
  }
};

export const CMSProvider = props => {
  const {
    tableOfContents,
    content,
    tableOfContentId,
    agencyInterfaceChecklistShow,
    headerRoute
  } = useCMSContext();

  const history = useHistory();

  const initialState = {
    tableOfContents,
    content,
    tableOfContentId,
    agencyInterfaceChecklistShow,
    headerRoute
  };

  const [cmsData, updateCMSData] = useReducer(cmsReducer, initialState);

  const fetchTableOfContents = useCallback((service, companyBranch) => {
    service
      .getTableOfContents(companyBranch)
      .then(
        res => {
          if (res && res.data && res.data.length > 0) {
            const tableOfContents = [];
            let currentHeader = "";

            res.data.forEach(content => {
              if (content.type === "Header" && content.id !== currentHeader) {
                currentHeader = content.id;
              }

              const header = tableOfContents.find(h => {
                return h.id === currentHeader && h.type === "Header";
              });

              if (content.type === "Header") {
                if (!header) {
                  const tempHeader = {
                    ...content,
                    subheaders: []
                  };
                  tableOfContents.push(tempHeader);
                }
              } else {
                header.subheaders.push(content);
              }
            });
            updateCMSData({
              type: FETCH_CMS_TABLE_OF_CONTENTS,
              payload: tableOfContents
            });
          }
        },
        () => {} // TODO: error case?
      )
      .finally(() => {}); // TODO: ??
  }, []);

  const fetchAgencyInterfaceContent = useCallback(contentId => {
    updateCMSData({
      type: FETCH_CMS_CONTENT_STARTED
    });

    agencyInterfaceServices
      .getContent(contentId)
      .then(
        res => {
          if (res && res.data) {
            updateCMSData({
              type: FETCH_CMS_CONTENT_SUCCEEDED,
              payload: {
                content: res.data,
                contentId
              }
            });
          }
        },
        () => {}
      )
      .finally(() => {});
  }, []);

  const fetchClaimsResourcesContent = useCallback(
    (contentId, companyBranch) => {
      updateCMSData({
        type: FETCH_CMS_CONTENT_STARTED
      });

      claimsResourcesServices
        .getContent(contentId, companyBranch)
        .then(
          res => {
            if (res && res.data) {
              updateCMSData({
                type: FETCH_CMS_CONTENT_SUCCEEDED,
                payload: {
                  content: res.data,
                  contentId
                }
              });
            }
          },
          () => {}
        )
        .finally(() => {});
    },
    []
  );

  const updateTableOfContentId = contentId => {
    updateCMSData({
      type: UPDATE_CMS_TABLE_OF_CONTENT_ID,
      payload: contentId
    });
  };

  const updateCMSHeaderRoute = useCallback(route => {
    updateCMSData({
      type: UPDATE_CMS_HEADER_ROUTE,
      payload: route
    });
  }, []);

  const updateAgencyInterfaceChecklistShow = useCallback(shouldShow => {
    updateCMSData({
      type: UPDATE_AGENCY_INTERFACE_CHECKLIST_MODAL_SHOW,
      payload: shouldShow
    });
  }, []);

  const updateCurrentHeader = useCallback((header) => {
    updateCMSData({
      type: UPDATE_CURRENT_HEADER,
      payload: header
    });
  }, []);

  // route to default header
  useEffect(() => {
    if (
      headerRoute &&
      !tableOfContents.isLoading &&
      tableOfContents.data &&
      tableOfContents.data.length > 0
    ) {
      history.push(`${headerRoute}/${tableOfContents.data[0].id}`);
    }
  }, [headerRoute, history, tableOfContents.data, tableOfContents.isLoading]);

  const memoizedData = useMemo(() => {
    return cmsData;
  }, [cmsData]);

  return (
    <CMSContext.Provider
      value={{
        cmsData: memoizedData,
        tableOfContents: cmsData.tableOfContents,
        fetchTableOfContents,
        updateTableOfContentId,
        tableOfContentId: cmsData.tableOfContentId,
        fetchAgencyInterfaceContent,
        fetchClaimsResourcesContent,
        updateAgencyInterfaceChecklistShow,
        agencyInterfaceChecklistShow: cmsData.agencyInterfaceChecklistShow,
        updateCMSHeaderRoute,
        updateCurrentHeader,
        headerRoute: cmsData.headerRoute
      }}
      {...props}
    >
      {props.children}
    </CMSContext.Provider>
  );
};
