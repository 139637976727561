import { connect } from "react-redux";
import AgencyLossRun from "../components/claims/agency-loss-runs/AgencyLossRun";
import { fetchEmailListDetails } from "../actions/agencyLossRunAction";
import { fetchAdministrator } from "../actions/reportAdministratorAction";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getEmailListIsLoading,
  getEmailList,
  getEmailListError
} from "../selectors/agencyLossRunSelector";
import {
  getAdministratorIsLoading,
  getAdministrator,
  getAdministratorError
} from "../selectors/administratorSelector";

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  isLoading: getEmailListIsLoading(state),
  emailList: getEmailList(state),
  error: getEmailListError(state),
  administratorIsLoading: getAdministratorIsLoading(state),
  administrator: getAdministrator(state),
  administratorError: getAdministratorError(state),
  hasAgencyClaimReportsAccess: sessionSelector.hasAgencyClaimReportsAccess(
    state
  )
});

const mapDispatchToProps = {
  fetchEmailListDetails,
  fetchAdministrator
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgencyLossRun);
