import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Dropdown, Icon, Popover } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import { getProducts } from "../../services/documentsService";

const ProductsDropdown = ({ form }) => {
  const [productsList, setProductsList] = useState([]);
  const [areProductsLoading, setAreProductsLoading] = useState(true);

  const { values, handleOnChange, handleOnBlur, handleOnValidate, updateForm } =
    form;

  useEffect(() => {
    if (values.selectedState.value) {
      getProducts(values.selectedState.value).then(
        res => {
          const data = res.data
            .map(d => ({
              label: d.product,
              value: d.id,
              product_id: d.product_id
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1));

          setProductsList(data);
        },
        () => {
          toast(
            <HelpDeskMessage
              display="anErrorOccurred"
              after="(Error loading products)"
            />,
            {
              position: "top-center",
              className: "error",
              autoClose: false,
              toastId: "productsError"
            }
          );
        }
      );

      setAreProductsLoading(false);
    }
  }, [values.selectedState]);

  const handleProductChangeValue = ({ event, field, value }) => {
    handleOnChange({ event, field, value });
    updateForm({
      values: {
        selectedProduct: event.newValue ?? "",
        keyword: ""
      },
      ...values
    });
  };

  const lineOfBusinessLabel = (
    <Popover
      id="lineOfBusiness"
      placement="top"
      trigger="hover"
      triggerContent={<Icon icon="fasInfoCircle"></Icon>}
      popoverContent={
        <span>
          To view coverage forms for ArtisanPro® or PremierPro®, please select
          Businessowners as the Line of Business.
        </span>
      }
      maxWidth={200}
      theme="white"
    />
  );

  return (
    <Dropdown
      label="Select Line of Business"
      labelElement={lineOfBusinessLabel}
      id="selectedProduct"
      name="selectedProduct"
      placeholder={"Select Line of Business"}
      onChange={handleProductChangeValue}
      onBlur={handleOnBlur}
      onValidate={handleOnValidate}
      options={productsList}
      value={values.selectedProduct}
      isSearchable
      isLoading={areProductsLoading}
      className="line-of-business-dropdown"
      size="33p"
    />
  );
};

ProductsDropdown.propTypes = {
  form: PropTypes.object.isRequired
};

export default ProductsDropdown;
