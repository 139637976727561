export const appTypes = {
  suretyBond: "10"
};

export const accountStatus = {
  QuickAccepted: "Q"
};

export const AccountNameMaxLength = 55;

export const allowedStatusCodes = ["Q", "X", "W", "B", "D", "L"];

export const prohibitedStatusCodes = ["A", "I", "N", "P", "S"];

export const suretyLegalEntities = [
  { value: "Corporation", label: "Corporation", display: true },
  { value: "LimitedLiabilityCorporation", label: "LLC", display: true },
  { value: "Partnership", label: "Partnership", display: true },
  { value: "Proprietorship", label: "Proprietorship", display: true },
  {
    value: "LimitedLiabilityPartnership",
    label: "LLP",
    display: false,
    disabled: true
  }
];

export const legalEntityTitleDisplay = [
  { value: "Corporation", label: "PRESIDENT" },
  { value: "LimitedLiabilityCorporation", label: "MEMBER" },
  { value: "Partnership", label: "PARTNER" },
  { value: "Proprietorship", label: "OWNER" }
];

export const suretyBusinessTitles = [
  {
    type: "Corporation",
    title: "President",
    alert: "Corporation requires a President"
  },
  {
    type: "LimitedLiabilityCorporation",
    title: "Member",
    alert: "LLC requires a Member"
  },
  {
    type: "Partnership",
    title: "Partner",
    alert: "Partnership requires a Partner"
  },
  {
    type: "Proprietorship",
    title: "Owner",
    alert: "Proprietorship requires an Owner."
  },
  {
    type: "LimitedLiabilityPartnership",
    title: "Partner",
    alert: "LLP requires a Partner."
  }
];

export const statesList = [
  { value: "AK", label: "AK" },
  { value: "AL", label: "AL" },
  { value: "AR", label: "AR" },
  { value: "AS", label: "AS" },
  { value: "AZ", label: "AZ" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DC", label: "DC" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "GU", label: "GU" },
  { value: "HI", label: "HI" },
  { value: "IA", label: "IA" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "MA", label: "MA" },
  { value: "MD", label: "MD" },
  { value: "ME", label: "ME" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MO", label: "MO" },
  { value: "MP", label: "MP" },
  { value: "MS", label: "MS" },
  { value: "MT", label: "MT" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "NE", label: "NE" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NV", label: "NV" },
  { value: "NY", label: "NY" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "PR", label: "PR" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UM", label: "UM" },
  { value: "UT", label: "UT" },
  { value: "VA", label: "VA" },
  { value: "VI", label: "VI" },
  { value: "VT", label: "VT" },
  { value: "WA", label: "WA" },
  { value: "WI", label: "WI" },
  { value: "WV", label: "WV" },
  { value: "WY", label: "WY" }
];

export const ConstructionSpecialtyTypeOtherNotListed = "100020066";

export const ConstructionSpecialtyTypesRequiringDescription = [
  "100030018",
  "100050003",
  "100040058"
];

export const SuretyConstructionSpecialty = [
  { value: "100020001", label: "Air Conditioning Heating and Ventilation" },
  { value: "100030003", label: "Bridges" },
  { value: "100020007", label: "Building Rehabilitation" },
  { value: "100020009", label: "Carpentry / Framing and Finish" },
  { value: "100020011", label: "Concrete" },
  { value: "100020019", label: "Drywall / Plastering" },
  { value: "100020021", label: "Electrical" },
  { value: "100020023", label: "Excavations / Grading" },
  { value: "100030018_1", label: "Fencing" },
  { value: "100040015", label: "Fire Alarm Systems / Fire Escapes" },
  { value: "100050003", label: "Floor Coverings - Carpet" },
  { value: "100040017", label: "Floor Coverings - Wood/Vinyl" },
  { value: "100020026", label: "Foundations" },
  { value: "100030006", label: "Glazing / Windows / Curtainwall" },
  { value: "100040022", label: "Insulation Contractors" },
  { value: "100040023", label: "Iron or Steel Contracts" },
  { value: "100020031", label: "Irrigation" },
  { value: "100020033", label: "Landscaping" },
  { value: "100020024", label: "Low Voltage / Fiber Optics" },
  { value: "100020035", label: "Mason Stone" },
  { value: "100020040", label: "Painting" },
  { value: "100020043", label: "Pipelines for Gas" },
  { value: "100020044", label: "Pipelines for Water" },
  { value: "100030012", label: "Playgrounds and Parks" },
  { value: "100020045", label: "Plumbing" },
  { value: "100020046", label: "Power and Telephone Lines" },
  { value: "100030015", label: "Roofing" },
  { value: "100020055", label: "Sewage & Water Treatment Plant" },
  {
    value: "100020056",
    label: "Sewers (Sanitary or Storm)/Septic Tanks"
  },
  { value: "100040041", label: "Sidewalks" },
  { value: "100030016", label: "Siding" },
  { value: "100040058", label: "Signs" },
  { value: "100040045", label: "Street & Subway Lighting/Traffic Control" },
  { value: "100040020", label: "Streets / Surfacing and Repairs" },
  { value: "100020035", label: "Tile & Terrazzo" },
  { value: "100030018_2", label: "Water Proofing" },
  { value: "100020064", label: "Water Wells" },
  { value: "100040054", label: "Weather Stripping" },
  { value: "100020066", label: "Other / Not Listed Above" }
];

export const SuretyTitleOptions = [
  { value: "MEMBER", label: "Member" },
  { value: "OWNER", label: "Owner" },
  { value: "PARTNER", label: "Partner" },
  { value: "PRESIDENT", label: "President" },
  { value: "SECRETARY", label: "Secretary" },
  { value: "TREASURER", label: "Treasurer" },
  { value: "VICE-PRESIDENT", label: "Vice - President" },
  { value: "OTHER", label: "Other" },
  { value: "", label: "" }
];
export const typeOfBond = [
  { value: "B", label: "Bid Bond", enum: "Bid" },
  {
    value: "P",
    label: "Performance & Payment Bond",
    enum: "PerformanceAndPayment"
  }
];

export const BidBond = [
  { value: "Percent", label: "Percent" },
  { value: "Amount", label: "Amount" }
];

export const ObligeeType = [
  { value: "GovernmentCounty", label: "Government - County" },
  { value: "GovernmentMunicipality", label: "Government - Municipality" },
  { value: "GovernmentFederal", label: "Government - Federal" },
  { value: "GovernmentState", label: "Government - State" },
  { value: "Miscellaneous", label: "Miscellaneous" },
  { value: "OtherPublic", label: "Other Public" },
  { value: "PrivateBusiness", label: "Private Business" }
];

export const BidBondPercent = [
  { value: "5", label: "5%" },
  { value: "10", label: "10%" },
  { value: "15", label: "15%" },
  { value: "20", label: "20%" },
  { value: "25", label: "25%" }
];

// ADDR_BLDNO = 9, ADDR_STR = 17, ADDR_TYPUN = 4, ADDR_UNIT = 5 + 3 spaces.
export const ADDRESS_MAX_LENGTH = 38;
export const POBOX_MAX_LENGTH = 8;
export const NON_SURETY_STATES = ["NY"];
export const VALID_BONDS_UNIT_TYPE = [
  "APT",
  "BLDG",
  "DEPT",
  "FL",
  "LOT",
  "STOP",
  "RM",
  "STE",
  "UNIT",
  "#",
  ""
];

/**
 * Regex
 */
export const streetPattern =
  /^[A-Za-z0-9.,-/]{1,9}[ ]{1}[A-Za-z0-9.,/]+([A-Za-z0-9.,/ ]|[ ]{1}#[0-9])*$/;
export const zipPattern = /^\d{5}$|^\d{5}-\d{4}$/;
export const poboxPattern =
  /((^P\.?O\.?\b)|(P\.?O\.? box\b)|(Post Office Box))/i;

/**
 * Documents & Forms
 */
// Commercial
export const sdfCommercialFormTypes = [
  { label: "License & Permit", value: "LicenseAndPermit" },
  { label: "Court", value: "Court" },
  { label: "Public Official", value: "PublicOfficial" },
  { label: "Lost Instrument", value: "LostInstrument" },
  { label: "Miscellaneous", value: "Miscellaneous" },
  { label: "Janitorial/Business", value: "JanitorialOrBusiness" }
];

// Contract.
export const sdfContractFormTypes = [
  { label: "All Bond Types", value: "All" },
  { label: "Bid Bond", value: "Bid" },
  { label: "Performance and Payment", value: "PerformanceAndPayment" },
  { label: "Performance Only", value: "Performance" },
  { label: "Payment Only", value: "Payment" },
  { label: "SubContract", value: "SubContract" },
  { label: "Maintenance", value: "Maintenance" },
  { label: "Supply Bond", value: "Supply" }
];

// Obligee.
export const sdfObligeeTypeOptions = [
  { label: "All", value: "All" },
  { label: "Any Obligee", value: "Any" },
  { label: "Federal", value: "Federal" },
  { label: "State", value: "State" },
  { label: "County", value: "County" },
  { label: "Municipality", value: "Municipality" },
  { label: "Other", value: "Other" }
];

// Document upload
export const MAX_ATTACHMENTS_SIZE = 10485760; // 10mb.

export const ALLOWED_FILE_TYPES = [
  "txt",
  "rtf",
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "png",
  "jpg"
];
