import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateQuoteProposal,
  fetchSubmissionList
} from "../../../actions/commercialLinesAction";
import { ContentHeader } from "@ufginsurance/ui-kit";
import { Alert } from "react-bootstrap";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import NewSubmissionsContainer from "./NewSubmissionsContainer";
import { Translations } from "../../translations";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import {
  getisSpecialtyPortalView,
  getActiveSpecialtyCompanyBranch,
} from "../../../portalview/changePortalViewStateSelectors";

const CommercialLinesContainer = ({
  submissionList,
  fetchSubmissionList,
  isLoading,
  hasCommercialContract,
  isCancelledAgent,
  agencyCode
}) => {

  if (!hasCommercialContract || isCancelledAgent) {
    return (
      <Alert bsStyle="danger">
        {isCancelledAgent
          ? Translations.error_messages.canceled_or_sold
          : Translations.commercial.commercial_contract_error}
      </Alert>
    );
  }

  return (
    <div className="commercial">
     <ContentHeader>New Submissions</ContentHeader>
      <div><NewSubmissionsContainer
              submissionList={submissionList}
              fetchSubmissionList={fetchSubmissionList}
              agencyCode={agencyCode}
              isLoading={isLoading}
            /></div>
    </div>
  );
};

CommercialLinesContainer.propTypes = {
  fetchSubmissionList: PropTypes.func,
  submissionList: PropTypes.array,
  agencyCode: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  hasCommercialContract: PropTypes.bool,
  isCancelledAgent: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    ...state.commercialLines,
    agencyCode: getSelectedAgencyCode(state),
    hasCommercialContract:
      sessionSelector.agencyHasCommercialLinesContract(state),
    isCancelledAgent: sessionSelector.isCancelledAgent(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
  };
};

const mapDispatchToProps = {
  updateQuoteProposal,
  fetchSubmissionList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommercialLinesContainer);
