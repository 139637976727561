import React from "react";
import { connect } from "react-redux";
import { Translations } from "../../../components/translations";
import { Tabs, Tab } from "@ufginsurance/ui-kit";
import InForceListContainer from "../../containers/InForceListContainer";
import YearHitRatioContainer from "../../containers/YearHitRatioContainer";
import AgencyAuditContainer from "../../containers/AgencyAuditContainer";
import AppQuoteStatusContainer from "../../containers/AppQuoteStatusContainer";
import PropTypes from "prop-types";
import { getActiveTabIndex, onTabClick } from "./../../../constants/routes";
import { getisSpecialtyPortalView } from "../../../portalview/changePortalViewStateSelectors";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const Underwriting = ({
  hasAgencyProductionReportsAccess,
  match,
  history,
  isSpecialtyPortalView
}) => {
  const onUnderwritingTabClick = tabIndex => {
    onTabClick(tabIndex, match, history);
  };

  return (
    <div className="underwriting-container">
      <div>
        <Tabs activeTab={getActiveTabIndex(match)}>
          <Tab
            title={Translations.reports.tabUnderwriting.inForceList}
            onClick={onUnderwritingTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <InForceListContainer
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
            />
          </Tab>
          {!isSpecialtyPortalView && (
            <Tab
              title={Translations.reports.tabUnderwriting.app_QuoteStatus}
              onClick={onUnderwritingTabClick}
            >
              <AppQuoteStatusContainer
                hasAgencyProductionReportsAccess={
                  hasAgencyProductionReportsAccess
                }
              />
            </Tab>
          )}
          {!isSpecialtyPortalView && (
            <Tab
              title={Translations.reports.tabUnderwriting.hitRatio}
              onClick={onUnderwritingTabClick}
            >
              <div className="reports-above-form-message">
                <HelpDeskMessage display="questionsOrAssistance" />
              </div>
              <YearHitRatioContainer
                hasAgencyProductionReportsAccess={
                  hasAgencyProductionReportsAccess
                }
              />
            </Tab>
          )}
          <Tab
            title={Translations.reports.tabUnderwriting.agencyAuditReport}
            onClick={onUnderwritingTabClick}
          >
            <div className="reports-above-form-message">
              <HelpDeskMessage display="questionsOrAssistance" />
            </div>
            <AgencyAuditContainer
              hasAgencyProductionReportsAccess={
                hasAgencyProductionReportsAccess
              }
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

Underwriting.propTypes = {
  hasAgencyProductionReportsAccess: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  isSpecialtyPortalView: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

export default connect(mapStateToProps)(Underwriting);
