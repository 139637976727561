import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

export const getCacheId = state => {
  if (sessionSelector.getEmployeeUserName(state)) {
    return [
      sessionSelector.getAgentCode(state),
      sessionSelector.getEmployeeUserName(state)
    ].join("-");
  }

  return sessionSelector.getAgentCode(state);
};
