import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../../Factory";
import { Translations } from "../../translations";
import {
  LoadingIndicator,
  Button,
  Table,
  Modal,
  FlexRow,
  Anchor
} from "@ufginsurance/ui-kit";

const PrintAutoIdCards = props => {
  const {
    fetchAutoIdCards,
    policyNumber,
    idCards,
    searchNumber,
    viewAutoIdCard,
    state,
    isLoading,
    show,
    onClose,
    policyType
  } = props;

  const [prevPolicyNumber, setPrevPolicyNumber] = useState("");

  const onViewAutoIdCard = id_number => () => {
    viewAutoIdCard(id_number, searchNumber, policyType);
  };

  const createColumns = () => [
    {
      key: "process_date",
      label: "Process Date",
      sortable: true,
      element: row => <span>{DateFormat(row.process_date, "MM/DD/YYYY")}</span>
    },
    {
      key: "document_description",
      label: "Document Type",
      element: row => {
        return (
          <Anchor onClick={onViewAutoIdCard(row.id_number)}>
            {Translations.account_overview.accountServices.auto_id_display}
          </Anchor>
        );
      }
    },
    {
      key: "effective_date",
      label: "Effective Date",
      element: row => (
        <span>{DateFormat(row.effective_date, "MM/DD/YYYY")}</span>
      )
    }
  ];

  useEffect(() => {
    if (!!policyNumber && policyNumber !== prevPolicyNumber) {
      fetchAutoIdCards(policyNumber);
      setPrevPolicyNumber(policyNumber);
    }
  }, [fetchAutoIdCards, policyNumber, prevPolicyNumber]);

  return (
    <div>
      <Modal
        body={
          <div className="print-id-cards__table">
            {state === "NY" || state === "NJ" ? (
              Translations.account_overview.accountServices
                .auto_id_error_message
            ) : isLoading ? (
              <LoadingIndicator />
            ) : idCards.length === 0 ? (
              Translations.account_overview.accountServices.auto_id_no_data
            ) : (
              <Table
                rowKey="id_number"
                data={idCards}
                columns={createColumns()}
                itemsPerPage={20}
                initialSort="process_date"
                initialDirection="desc"
              />
            )}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button type="button" variant="secondary" onClick={onClose}>
              {Translations.general.close}
            </Button>
          </FlexRow>
        }
        title={
          <>
            {Translations.account_overview.accountServices.header}
            {searchNumber}
          </>
        }
        show={show}
        onHide={onClose}
      />
    </div>
  );
};

PrintAutoIdCards.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  viewAutoIdCard: PropTypes.func.isRequired,
  searchNumber: PropTypes.string,
  idCards: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  policyType: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  fetchAutoIdCards: PropTypes.func.isRequired,
  policyNumber: PropTypes.string.isRequired
};

export default PrintAutoIdCards;
