import * as types from "../constants/actionTypes";
import initialState from "./initialState";
import { v4 } from "uuid";

const homeCommercialReducer = (state = initialState.homeBilling, action) => {
  switch (action.type) {
    case types.FETCH_BILLING_PAST_DUE_STARTED:
      return {
        ...state,
        pastDueLoading: true,
        pastDueError: null,
        pastDueFetched: false,
        pastDueFetchedAgencyCode: action.agency_code
      };
    case types.FETCH_BILLING_PAST_DUE_SUCCEEDED:
      return {
        ...state,
        billingPastDueList: action.payload.map(x => ({
          id: v4(),
          ...x
        })),
        pastDueLoading: false,
        pastDueError: null,
        pastDueFetched: true
      };
    case types.FETCH_BILLING_PAST_DUE_FAILED:
      return {
        ...state,
        pastDueLoading: false,
        pastDueError: action.payload,
        pastDueFetched: true
      };
    case types.FETCH_BILLING_DUE_SOON_STARTED:
      return {
        ...state,
        dueSoonLoading: true,
        dueSoonError: null,
        dueSoonFetched: true,
        dueSoonFetchedAgencyCode: action.agency_code
      };
    case types.FETCH_BILLING_DUE_SOON_SUCCEEDED:
      return {
        ...state,
        billingDueSoonList: action.payload.map(x => ({
          id: v4(),
          ...x
        })),
        dueSoonLoading: false,
        dueSoonError: null,
        dueSoonFetched: true
      };
    case types.FETCH_BILLING_DUE_SOON_FAILED:
      return {
        ...state,
        dueSoonLoading: false,
        dueSoonError: action.payload
      };
    default:
      return state;
  }
};

export default homeCommercialReducer;
