import { useEffect } from "react";
import { findTableOfContentsItemById } from "../../selectors";
import { useCurrentRouteMatch } from "../hooks";

export const useSubheaderRouteValidation = (
  tableOfContents,
  setSelectedSubheader,
  selectedSubheader
) => {
  const currentMatch = useCurrentRouteMatch();
  const currentRouteSubheaderId = currentMatch?.params?.subheaderId;
  const currentRouteSubheader2Id = currentMatch?.params?.subheader2Id;

  useEffect(() => {
    const hasTableOfContents = tableOfContents && tableOfContents.length > 0;
    const isNewSubheaderSelectionRoute =
      hasTableOfContents &&
      currentRouteSubheaderId &&
      selectedSubheader?.id &&
      selectedSubheader?.id !== currentRouteSubheaderId;
    if (isNewSubheaderSelectionRoute) {
      const routeSubheaderTableOfContentItem = findTableOfContentsItemById(
        tableOfContents,
        currentRouteSubheaderId
      );
      if (routeSubheaderTableOfContentItem) {
        setSelectedSubheader(routeSubheaderTableOfContentItem);
      }
    }
    //currentRouteSubheader2Id only used to trigger useEffect, not used inside the function so ignore the linter warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableOfContents,
    currentRouteSubheaderId,
    currentRouteSubheader2Id,
    selectedSubheader,
    setSelectedSubheader
  ]);

  useEffect(() => {
    const hasTableOfContents = tableOfContents && tableOfContents.length > 0;
    const isSubheaderRouteWithoutSelection =
      hasTableOfContents && currentRouteSubheaderId && !selectedSubheader;
    if (isSubheaderRouteWithoutSelection) {
      const routeSubheaderTableOfContentItem = findTableOfContentsItemById(
        tableOfContents,
        currentRouteSubheaderId
      );
      if (routeSubheaderTableOfContentItem) {
        setSelectedSubheader(routeSubheaderTableOfContentItem);
      }
    }
    //currentRouteSubheader2Id only used to trigger useEffect, not used inside the function so ignore the linter warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableOfContents,
    currentRouteSubheaderId,
    currentRouteSubheader2Id,
    selectedSubheader,
    setSelectedSubheader
  ]);

  return null;
};
