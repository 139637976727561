import React from "react";
import PropTypes from "prop-types";
import DirectUfgCore from "./DirectUfgCore";
import AgencyUfgCore from "./AgencyUfgCore";
import { Translations } from "../../../../components/translations";
import orderBy from "lodash/orderBy";

const UfgCore = ({
  directList,
  agencyList,
  fetchAccountingStatementViewPDF,
  report_group,
  agency_code,
  source
}) => {
  const getTitle = isDirectBill => {
    const {
      direct_bill,
      agency_bill,
      direct_bill_sponsor_agency,
      agency_bill_sponsor_agency
    } = Translations.reports.tabAccounting.agencyStatement;
    let title;
    if (report_group === "sponsored") {
      title =
        (isDirectBill
          ? direct_bill_sponsor_agency
          : agency_bill_sponsor_agency) +
        " " +
        agency_code;
    } else {
      title = isDirectBill ? direct_bill : agency_bill;
    }
    return title;
  };

  return (
    <div>
      {directList.map(item => {
        return (
          <div key={item.billing_type}>
            <DirectUfgCore
              documents={orderBy(item.documents, "date", "desc")}
              title={getTitle(true)}
              fetchAccountingStatementViewPDF={fetchAccountingStatementViewPDF}
              report_group={report_group}
              agency_code={agency_code}
              source={source}
              billing_type={item.billing_type}
            />
          </div>
        );
      })}

      {agencyList.map(item => {
        return (
          <div key={item.billing_type}>
            <AgencyUfgCore
              documents={orderBy(item.documents, "date", "desc")}
              title={getTitle(false)}
              fetchAccountingStatementViewPDF={fetchAccountingStatementViewPDF}
              report_group={report_group}
              agency_code={agency_code}
              source={source}
              billing_type={item.billing_type}
            />
          </div>
        );
      })}
    </div>
  );
};

UfgCore.propTypes = {
  directList: PropTypes.array.isRequired,
  agencyList: PropTypes.array.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  report_group: PropTypes.string,
  agency_code: PropTypes.string,
  source: PropTypes.string
};

export default UfgCore;
