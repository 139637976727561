import { getAgencyBillPayNowEnrollment } from "../actions/agencyBillEpayActions";
import { connect } from "react-redux";
import AgencyBillEpay from "../components/accounting/AgencyBillEpay";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  agencyCode: getSelectedAgencyCode(state),
  ...state.agencyBillPay
});

const mapDispatchToProps = {
  getAgencyBillPayNowEnrollment
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyBillEpay);
