import React, { useState, useContext } from "react";
import classnames from "classnames";
import {
  Input,
  FormGroup,
  LoadingIndicator,
  Select
} from "@ufginsurance/ui-kit";
import {
  zipCodeValidate,
  addressLineValidate,
  validatePoBox
} from "../shared/util";
import * as zipSearch from "./zipSearch";
import OnlineQuotingContext from "../OnlineQuotingContext";
import OQstrings from "../shared/strings";
import PhysicalAddressMessage from "./PhysicalAddressMessage";
import "./Address.scss";

const Address = ({
  form,
  type,
  agencyStates,
  selectedState,
  setSelectedState,
  addresses,
  setAddresses,
  accountIsInforce,
  disabled
}) => {
  const { toastErrr } = useContext(OnlineQuotingContext);

  const [zipSearching, setZipSearching] = useState();

  if (!addresses) return null;

  const { handleOnChange, handleOnBlur, handleOnValidate, values } = form;

  // if this address is set to not be displayed, then don't
  if (addresses[type]?.display === false) return null;

  const handleZipChange = ({ field, value }) => {
    handleOnChange({ field, value });

    setZipSearching(true);
    zipSearch.search({
      field,
      value,
      form,
      selectedState,
      setSelectedState,
      agencyStates,
      type,
      addresses,
      setAddresses,
      toastErrr,
      callbackAfterSearch: () => {
        setZipSearching(false);
      }
    });
  };

  // this will only get triggered if the zipcode lookup fails and
  // the user inputs their city/state manually
  const handleStateChange = ({ field, value }) => {
    handleOnChange({ field, value });
    setSelectedState(value);
  };

  const handleZipValidate = field => {
    const fieldErrors = handleOnValidate(field);

    const zipErrorCheck = zipCodeValidate(field.value);
    if (zipErrorCheck) fieldErrors.push(zipErrorCheck);

    //OOQ-8172 skip state validation for mailing addess
    // validation to keep the "agent in state validation" even after other fields get updated
    if (
      type !== "mailing_ufg" &&
      values[type + "_state"] &&
      !agencyStates.includes(values[type + "_state"])
    )
      fieldErrors.push(OQstrings.error.zip_code_not_in_agent_state);

    return fieldErrors;
  };

  const handleStreetValidate = (field, type) => {
    const fieldErrors = handleOnValidate(field);
    const addressLineCheck = addressLineValidate(field.value);
    if (addressLineCheck) fieldErrors.push(addressLineCheck);
    const poBoxCheck = validatePoBox(field.value);
    if (type === "physical" && poBoxCheck) {
      fieldErrors.push(poBoxCheck);
    }
    return fieldErrors;
  };

  return (
    <>
      <PhysicalAddressMessage form={form} type={type} />
      <FormGroup
        className={classnames("oq__address", `oq__address__${type}`)}
        groupErrors
      >
        <Input
          id={type + "_address1"}
          name={type + "_address1"}
          label={addresses[type]?.label + " Address"}
          placeholder="123 Main Street"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={field => handleStreetValidate(field, type)}
          value={values[type + "_address1"]}
          required
          className="grow address"
          disabled={
            disabled ||
            !!addresses[type]?.disabled?.address1 ||
            accountIsInforce
          }
          maxLength={60}
        />
        <Input
          id={type + "_zip"}
          name={type + "_zip"}
          label="Zip"
          labelElement={
            zipSearching ? (
              <LoadingIndicator
                className="oq__label-spinner"
                type="spinner"
                message="Searching"
              />
            ) : null
          }
          placeholder="12345-1234"
          onChange={handleZipChange}
          onBlur={handleOnBlur}
          onValidate={handleZipValidate}
          value={values[type + "_zip"]}
          required
          className="zip"
          disabled={
            disabled || !!addresses[type]?.disabled?.zip || accountIsInforce
          }
        />
        {!addresses[type]?.zipcodeFail && (
          <Select
            id={type + "_city"}
            name={type + "_city"}
            label="City"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values[type + "_city"]}
            required
            options={addresses[type]?.options?.city}
            placeholder=""
            isClearable={false}
            className="city"
            disabled={
              disabled || !!addresses[type]?.disabled?.city || accountIsInforce
            }
          />
        )}
        {addresses[type]?.zipcodeFail && (
          <Input
            id={type + "_city"}
            name={type + "_city"}
            label="City"
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values[type + "_city"]}
            required
            className="cityInput"
            disabled={
              disabled || !!addresses[type]?.disabled?.city || accountIsInforce
            }
          />
        )}
        <Select
          id={type + "_state"}
          name={type + "_state"}
          label="State"
          onChange={handleStateChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values[type + "_state"]}
          required
          options={addresses[type]?.options?.state}
          placeholder=""
          isClearable={false}
          className="state"
          disabled={
            disabled || !!addresses[type]?.disabled?.state || accountIsInforce
          }
        />
      </FormGroup>
    </>
  );
};

export default Address;
