import React from "react";
import { toast } from "react-toastify";
import { logger } from "../../loggers";
import DefaultErrorResponse from "./DefaultErrorResponse";

/**
 * Opens a toast error notification with the DefaultErrorResponse ("We're sorry, but an error occurred...").
 * @param {Error} error The error.
 * @param {Boolean} isFatal Make logger.error "fatal."
 * @param {Boolean} showMessage Show the toast message.
 * @param {Object} options Default settings: {
    position: "top-center",
    className: "error",
    autoClose: false,
  }
 */
export default function defaultToastError(
  error,
  isFatal = false,
  showMessage = false,
  options = {}
) {
  logger.error({
    description: error.toString(),
    fatal: isFatal
  });

  const _options = {
    position: "top-center",
    className: "error",
    autoClose: false,
    ...options
  };

  toast(
    showMessage ? error?.message ?? "" : <DefaultErrorResponse />,
    _options
  );
}
