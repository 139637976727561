import React from "react";
import { DateFormat, toTitleCase } from "../components/Factory";
import { formatAddress } from "./shared/util";
import { formatZip } from "../../src/quick-program/quick-quoting/shared/util";
import { Currency, Form, Dropdown } from "@ufginsurance/ui-kit";

const UWBondInformation = ({
  uwReviewBondInfoData,
  bondNumber,
  form,
  uwDecisionAlreadyMade
}) => {
  const has_bid_percent = !!Number(
    uwReviewBondInfoData.uwBondInfoData.bid_percent || ""
  );

  return (
    <>
      <div className="uw_items">Bond Information</div>
      {!!bondNumber ? (
        <div>
          <div className="uw_items__item-list">
            <b>Bond Effective Date:</b>
            <p>
              {DateFormat(
                uwReviewBondInfoData.uwBondInfoData.effective_date,
                "MM/DD/YYYY"
              )}
            </p>
          </div>
          <div>
            <b>Bond Type:</b>
            <p className="uwBondType">
              {uwReviewBondInfoData.uwBondInfoData.bond_form_type}
            </p>
          </div>
          <div>
            <b>Bond Form:</b>
            <p className="uwBondFormNum">
              {uwReviewBondInfoData.uwBondInfoData.form_number}
            </p>
          </div>
          <div>
            <b>Project Location:</b>
            <p>
              {toTitleCase(uwReviewBondInfoData.uwBondInfoData.bond_city)},{" "}
              {uwReviewBondInfoData.uwBondInfoData.bond_state}{" "}
              {formatZip(uwReviewBondInfoData.uwBondInfoData.bond_zip)}
            </p>
          </div>
          <div>
            <b>Project Description:</b>
            <p>
              {toTitleCase(
                uwReviewBondInfoData.uwBondInfoData.bond_description
              )}
            </p>
          </div>
          <div>
            <b>Obligee:</b>
            <p>
              {toTitleCase(uwReviewBondInfoData.uwBondInfoData.obligee_name)}
              <br />
              {toTitleCase(
                uwReviewBondInfoData.uwBondInfoData.obligee_street_address
              )}
              {toTitleCase(
                uwReviewBondInfoData.uwBondInfoData.obligee_street_address2
              )}{" "}
              <br />
              {formatAddress(
                uwReviewBondInfoData.uwBondInfoData.obligee_city_state_zip
              )}
              <br />
            </p>
          </div>
          <div>
            <b>Completion Date:</b>
            <p>
              {DateFormat(
                uwReviewBondInfoData.uwBondInfoData.completion_date,
                "MM/DD/YYYY"
              )}
            </p>
          </div>
          <div>
            <b>Maintenance Period:</b>
            <p>
              {uwReviewBondInfoData.uwBondInfoData.maintenance_period}{" Months"}
            </p>
          </div>
          <div>
            <b>Liquidated Damages:</b>
            <p>
              <Currency
                amount={uwReviewBondInfoData.uwBondInfoData.liquidated_damages}
              />
            </p>
          </div>
          {uwReviewBondInfoData.uwBondInfoData.type_of_bond === "Bid" ? (
            <div>
              <div>
                <b>Bid Date:</b>
                <p>
                  {DateFormat(
                    uwReviewBondInfoData.uwBondInfoData.bid_date,
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div>
                <b>Bid Estimate:</b>
                <p className="uwBidEstimate">
                  <Currency
                    amount={uwReviewBondInfoData.uwBondInfoData.bid_estimate}
                  />
                </p>
              </div>
              {has_bid_percent && (
                <div>
                  <b>Bid Bond Percent:</b>
                  <p>{uwReviewBondInfoData.uwBondInfoData.bid_percent}%</p>
                </div>
              )}
              {!has_bid_percent && (
                <div>
                  <b>Bid Bond Amount:</b>
                  <p>
                    <Currency
                      amount={uwReviewBondInfoData.uwBondInfoData.bid_amount}
                    />
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div>
                <b>Contract Date:</b>
                <p>
                  {DateFormat(
                    uwReviewBondInfoData.uwBondInfoData.contract_date,
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div>
                <b>Contract Amount:</b>
                <p className="uwPPContractAmount">
                  <Currency
                    amount={uwReviewBondInfoData.uwBondInfoData.contract_amount}
                  />
                </p>
              </div>
              <div>
                <b>Second Lowest Bidder:</b>
                <p>
                  <Currency
                    amount={
                      uwReviewBondInfoData.uwBondInfoData.second_lowest_bidder
                    }
                  />
                </p>
              </div>
              {uwReviewBondInfoData.uwBondInfoData.bond_state === "KY" &&
                !uwDecisionAlreadyMade && (
                  <div>
                    <Form context={form} className="kyb-toggle">
                      <Dropdown
                        id="kybtoggle"
                        name="kybtoggle"
                        label="Kentucky-LGPT Tax:"
                        options={[
                          { value: "true", label: "Yes" },
                          { value: "false", label: "No" }
                        ]}
                        onChange={form.handleOnChange}
                        onBlur={form.handleOnBlur}
                        onValidate={form.handleOnValidate}
                        value={form.values.kybtoggle}
                        size="md"
                        required
                      />
                    </Form>
                  </div>
                )}
            </div>
          )}
        </div>
      ) : (
        <div className="uw_items__item-list">
          No Bond Information was submitted.
        </div>
      )}
    </>
  );
};

export default UWBondInformation;
