import API from "../../../api";
import qs from "query-string";
import axios from "axios";

export const rateRiskIndication = (riskIndicationId, values) => {
  const postRequestMinusDocuments = { ...values };
  delete postRequestMinusDocuments.documents;
  return API.aws("risk-indication-submission").post(
    `${riskIndicationId}/rate`,
    postRequestMinusDocuments
  );
};

export const getRiskIndicationRecord = riskIndicationId => {
  return API.aws("risk-indication-submission").get(`/${riskIndicationId}`);
};

export const postRiskIndicationRecord = riskIndicationRecord => {
  return API.aws("risk-indication-submission").post("", riskIndicationRecord);
};

export const putRiskIndicationRecord = (riskIndicationId, values) => {
  // Removing the documents from the put request, because the functionality will otherwise be different depending on if the user has uploaded
  // files this session or if the documents were received as part of loading a previously saved request.
  const putRequestMinusDocuments = { ...values };
  delete putRequestMinusDocuments.documents;
  return API.aws("risk-indication-submission").put(
    String(riskIndicationId),
    putRequestMinusDocuments
  );
};

export const deleteRiskIndicationRecord = riskIndicationId => {
  return API.aws("risk-indication-submission").delete(String(riskIndicationId));
};

export const notifyUnderwriting = (
  riskIndicationId,
  agencyCode,
  underwriter
) => {
  return API.aws("risk-indication-submission").post(
    `${riskIndicationId}/notify`,
    {
      agency_code: agencyCode,
      recipients: [underwriter]
    }
  );
};

const getPresignedUrl = ({ riskIndicationId, agencyId, file }) => {
  return API.aws("risk-indication-submission").get(
    `/${riskIndicationId}/files/presigned-url`,
    {
      params: {
        agencyCode: agencyId,
        filename: file.name
      }
    }
  );
};

export const postRiskIndicationDocument = async ({
  riskIndicationId,
  agencyId,
  file
}) => {
  try {
    const { data } = await getPresignedUrl({
      riskIndicationId,
      agencyId,
      file
    });

    const { url, fields } = data.presignedPostData;

    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("Content-Type", file.type);
    formData.append("file", file);

    await axios.post(url, formData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDocumentList = riskIndicationId => {
  return API.aws("risk-indication-submission").get(`${riskIndicationId}/files`);
};

export const getLocationRatingData = address => {
  const queryString = qs.stringify({
    zip_code: address.zip,
    state: address.state,
    address_line: address.street_address,
    city: address.city
  });
  return API.base("", "/eapi/rating-data/v2").get(`/ppc-bceg?${queryString}`);
};

export const deleteRiskIndicationDocument = (
  riskIndicationId,
  fileName,
  agencyId
) => {
  return API.aws("risk-indication-submission").delete(
    `/${riskIndicationId}/files?agencyCode=${agencyId}`,
    { data: { filenames: [fileName] } }
  );
};
