import { useEffect, useRef, useState } from "react";
import {
  getRiskIndicationRecord,
  getDocumentList
} from "../../shared/services";

export default function useGetRiskIndication(id) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  useEffect(() => {
    if (!id || data || isLoading || error.current) return;

    setIsLoading(true);

    const results = Promise.allSettled([
      getRiskIndicationRecord(id),
      getDocumentList(id)
    ]);
    results
      .then(responses => {
        if (
          responses[0].status !== "fulfilled" ||
          responses[0].value.status !== 200 ||
          !responses[0].value.data
        ) {
          handleError();
        } else {
          const data = responses[0].value.data;
          if (
            responses[1].status === "fulfilled" &&
            responses[0].value.status === 200 &&
            responses[1].value.data
          ) {
            const fileData = responses[1].value?.data?.filesData?.map(file => {
              return { name: file.fileName, size: file.size, uploaded: true };
            });
            data.documents = fileData;
          }
          setData(data);
        }
      })
      .finally(() => setIsLoading(false));
  }, [data, isLoading, id]);
  return [data, isLoading];
}
