import * as types from "../constants/actionTypes";
import * as billingService from "../services/billingService";
import { logger } from "../loggers";

const fetchBillingPastDueStarted = agency_code => ({
  type: types.FETCH_BILLING_PAST_DUE_STARTED,
  agency_code
});

const fetchBillingPastDueSucceeded = data => ({
  type: types.FETCH_BILLING_PAST_DUE_SUCCEEDED,
  payload: data
});

const fetchBillingPastDueFailed = error => ({
  type: types.FETCH_BILLING_PAST_DUE_FAILED,
  payload: error
});

const fetchBillingDueSoonStarted = agency_code => ({
  type: types.FETCH_BILLING_DUE_SOON_STARTED,
  agency_code
});

const fetchBillingDueSoonSucceeded = data => ({
  type: types.FETCH_BILLING_DUE_SOON_SUCCEEDED,
  payload: data
});

const fetchBillingDueSoonFailed = error => ({
  type: types.FETCH_BILLING_DUE_SOON_FAILED,
  payload: error,
  error: true
});

export const fetchBillingPastDue = agency_code => dispatch => {
  dispatch(fetchBillingPastDueStarted(agency_code));
  return billingService.fetchBillingPastDue(agency_code).then(
    response => {
      dispatch(fetchBillingPastDueSucceeded(response.data));
    },
    error => {
      dispatch(fetchBillingPastDueFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

export const fetchBillingDueSoon = agency_code => dispatch => {
  dispatch(fetchBillingDueSoonStarted(agency_code));
  return billingService.fetchBillingDueSoon(agency_code).then(
    response => {
      dispatch(fetchBillingDueSoonSucceeded(response.data, agency_code));
    },
    error => {
      dispatch(fetchBillingDueSoonFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
