import { connect } from "react-redux";
import NewsArchives from "./newsArchives";
import { fetchNewsArchives } from "./newsArchivesActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getNewsArchive } from "./newsSelector";
import {
  getActiveSpecialtyCompanyBranch
} from "./../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  agentState: sessionSelector.getActiveAgencyState(state),
  newsArchive: getNewsArchive(state),
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
});

const mapDispatchToProps = {
  fetchNewsArchives
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsArchives);
