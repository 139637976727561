export const uwTransform = (data, uwReviewAccountInfoData, values) => ({
  ...data,
  agent_email: {
    agent_email: uwReviewAccountInfoData.uwAccountInfoData.requestor_email,
    account_name: uwReviewAccountInfoData.uwAccountInfoData.account_name,
    agent_name:
      uwReviewAccountInfoData.uwAccountInfoData.requestor_firstname +
      " " +
      uwReviewAccountInfoData.uwAccountInfoData.requestor_lastname,
    agent_number: uwReviewAccountInfoData.uwAccountInfoData.agency_code,
    message: values.message_to_agent
  }
});
