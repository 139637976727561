export const formatPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    const strippedPhoneNumber = phoneNumber.replace(/[^0-9.]/g, "");

    return (
      strippedPhoneNumber.slice(0, 3) +
      "-" +
      strippedPhoneNumber.slice(3, 6) +
      "-" +
      strippedPhoneNumber.slice(6, 10)
    );
  }

  return phoneNumber;
};
