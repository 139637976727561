import React, { createContext, useContext } from "react";
import useFetchCommercialQuotes from "./hooks/useFetchCommercialQuotes";
import useAccountBondSearch from "./hooks/useAccountBondSearch";

const SuretyHomeContext = createContext();

export const useSuretyHomeContext = () => useContext(SuretyHomeContext);

export function SuretyHomeProvider({
  agencyCode,
  ...props
}) {
  const [commercialQuotes, commercialQuotesIsLoading] =
    useFetchCommercialQuotes(agencyCode);

  const [accounts, accountsIsLoading] = useAccountBondSearch(agencyCode);


  return (
    <SuretyHomeContext.Provider
      value={{
        agencyCode,
        commercialQuotes,
        commercialQuotesIsLoading,
        accounts,
        accountsIsLoading
      }}
      {...props}
    >
      <div id="surety-home">{props.children}</div>
    </SuretyHomeContext.Provider>
  );
}
