import React from "react";
import PropTypes from "prop-types";
import {
  useForm,
  FormGroup,
  Form,
  TextArea,
  Input,
  FlexRow,
  Button
} from "@ufginsurance/ui-kit";
import { Translations } from "../../../translations";

const NewConversation = ({
  messages,
  result,
  onStartConversation,
  isLoading,
  onHandleClose,
  isStartConversationLoading
}) => {
  const onSubmit = formData => {
    const values = {
      ...formData.values
    };
    onStartConversation(values);
  };

  const form = useForm({
    values: {
      subject: "",
      message: ""
    },
    onSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <div>
      <div className="message-body">
        <div className="quote-count">
          Quote: {result && result.quote_number}
        </div>
        <p>You are starting a conversation with</p>
        <div className="conversation-container">
          <div
            className="photo-space"
            style={{
              backgroundImage: `url(${messages.underwriter_photo_url})`
            }}
          />
          <div className="message-details">
            <h4>{messages.underwriter_name}</h4>
          </div>
        </div>
        <div className="message-form">
          <Form context={form} id="new-conversation-form">
            <FormGroup groupErrors wrap={false}>
              <Input
                id="subject"
                name="subject"
                label="Subject"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.subject || ""}
                required
              />
            </FormGroup>
            <FormGroup>
              <TextArea
                id="message"
                name="message"
                label="Message"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.message || ""}
                required
              />
            </FormGroup>
            <FlexRow align="right">
              <Button
                className="messaging-close"
                variant="secondary"
                onClick={onHandleClose}
              >
                {Translations.commercial.new_submissions_tab.messages.close}
              </Button>

              {messages && messages.can_start_conversation && (
                <Button
                  type="submit"
                  variant="primary"
                  className="messaging-start"
                  disabled={
                    !values.subject ||
                    !values.message ||
                    isStartConversationLoading ||
                    isLoading
                  }
                  spinner={isStartConversationLoading || isLoading}
                  spinnerPos="right"
                >
                  {
                    Translations.commercial.new_submissions_tab.messages
                      .startConversation
                  }
                </Button>
              )}
            </FlexRow>
          </Form>
        </div>
      </div>
    </div>
  );
};

NewConversation.propTypes = {
  messages: PropTypes.object,
  onStartConversation: PropTypes.func,
  result: PropTypes.object,
  isLoading: PropTypes.bool,
  onHandleClose: PropTypes.func,
  isStartConversationLoading: PropTypes.bool
};

export default NewConversation;
