import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const agencySweepPaymentRequestDetailsReducer = (
  state = initialState.agencySweepPaymentRequestDetails,
  action
) => {
  switch (action.type) {
    case types.COPY_AGENCY_SWEEP_PAYMENT_REQUEST_DETAILS_SUCCEEDED:
      return {
        ...state,
        agencySweepPaymentInfo: action.payload
      };
    default:
      return state;
  }
};

export default agencySweepPaymentRequestDetailsReducer;
