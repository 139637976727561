import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Translations } from "../components/translations";
import { LoadingIndicator, Button, Modal, FlexRow } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "./helpDeskMessage";

const HelpDesk = props => {
  const {
    isLoading,
    helpDeskError,
    fetchHelpDeskService,
    dismissHelpDesk,
    isActivated
  } = props;

  useEffect(() => {
    fetchHelpDeskService();
  }, [fetchHelpDeskService]);

  return (
    <div>
      <Modal
        onHide={dismissHelpDesk}
        show={isActivated}
        title={Translations.HelpDesk.heading}
        body={
          <div className="helpdesk">
            {isLoading ? (
              <LoadingIndicator />
            ) : helpDeskError ? (
              <div className="friendly-error-messaging">
                {Translations.HelpDesk.error}
              </div>
            ) : (
              <HelpDeskMessage display="availability" />
            )}
          </div>
        }
        footer={
          <FlexRow align="right">
            <Button type="button" variant="secondary" onClick={dismissHelpDesk}>
              {Translations.general.close}
            </Button>
          </FlexRow>
        }
      />
    </div>
  );
};

HelpDesk.propTypes = {
  dismissHelpDesk: PropTypes.func.isRequired,
  isActivated: PropTypes.bool.isRequired,
  fetchHelpDeskService: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  helpDeskError: PropTypes.any
};

export default HelpDesk;
