import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as dictionary from "../../constants/dictionary";
import * as routes from "../../constants/routes";
import { Table, LoadingIndicator, Modal, Anchor } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { Translations } from "../../components/translations";
import {
  getFormsByFormCategory
} from "../services/documentsService";
import { getActiveSpecialtyCompanyBranch } from "../../portalview/changePortalViewStateSelectors";
import { printPdf } from "../../shared/documents/helpers";
import { v4 } from "uuid";
import useDebounce from "../../online-quoting/shared/useDebounce";

let cancelSearch;

const DocumentsFormsResultsGrid = ({
  form,
  type,
  category,
  activeSpecialtyCompanyBranch
}) => {
  const [formsList, setFormsList] = useState([]);
  const [isAxiosLoading, setIsAxiosLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState([]);
  const [resultsGridLabel, setResultsGridLabel] = useState(
    Translations.documents_forms.results_grid_initial_label
  );
  const [showModal, setShowModal] = useState(false);
  const [previousValues, setPreviousValues] = useState({});
  const [previousCategory, setPreviousCategory] = useState("");
  const [previousCompanyBranch, setPreviousCompanyBranch] = useState(null);
  const [previousType, setPreviousType] = useState("");

  const { values } = form;
  const debouncedValues = useDebounce(values, 500);

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPrintStart = (form_number) => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      form_number
    )
      ? [...documentsLoading, form_number]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = (form_number) => {
    setDocumentsLoading(documentsLoading.filter(x => x !== form_number));
  }

  const onPrintError = () => {
    setShowModal(true);
  }

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const columns = [
    {
      key: "number",
      label: "Form Number",
      align: "left",
      searchable: true,
      sortable: true,
      noWrap: false
    },
    {
      key: "name",
      label: "Form Name",
      align: "left",
      searchable: true,
      sortable: true,
      className: "document-library-form-name",
      element: row => {
        const requestInProgress =
          row.number && documentsLoading.includes(row.number);

        const form_number = `${row.number ?? ""}`;

        return (
          <div className="document-library-form-name__wrapper">
            {requestInProgress && (
              <span className="document-library-spinner">
                <LoadingIndicator message="" type="spinner" />
              </span>
            )}

            <Anchor
              href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${dictionary.DOCUMENT_FORM_TYPES.DOCUMENT}/${row.number ?? ""}`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();

                printPdf({
                  form_number,
                  form_type: dictionary.DOCUMENT_FORM_TYPES.DOCUMENT,
                  onStart: () => {
                    onPrintStart(form_number);
                  },
                  onComplete: () => {
                    onPrintComplete(form_number);
                  },
                  onError: onPrintError
                });
              }}
            >
              {row.name}
            </Anchor>
          </div>
        );
      }
    }
  ];

  const searchUsingDebounce = useCallback(
    state => {
      if (cancelSearch) {
        cancelSearch();
      }

      setIsAxiosLoading(true);
      setFormsList([]); // Wipe out forms list to trigger loading indicator

      getFormsByFormCategory(
        type,
        state.state_abbreviation,
        category,
        activeSpecialtyCompanyBranch
      ).then(
        res => {
          const formsWithId = res.data.map(form => {
            return {
              ...form,
              id: v4()
            };
          });

          setFormsList(formsWithId);
          setIsAxiosLoading(false);
        },
        () => {
          if (!cancelSearch) {
            toast(<HelpDeskMessage display="anErrorOccurred" />, {
              position: "top-center",
              className: "error",
              autoClose: false,
              toastId: "documentLibraryFormsError"
            });
            setFormsList([]);
            setIsAxiosLoading(false);
          }
        }
      );
    },
    [type, category, activeSpecialtyCompanyBranch]
  );

  const shouldGetForms = useCallback(
    (previousValues, selectedState, category, type, companyBranch) => {
      const stateChanged =
        previousValues?.selectedState?.state_abbreviation !==
        selectedState.state_abbreviation;

      const categoryChanged = previousCategory !== category;
      const branchChanged = previousCompanyBranch !== companyBranch;
      const typeChanged = previousType !== type;

      return (
        (selectedState && stateChanged) ||
        categoryChanged ||
        branchChanged ||
        typeChanged
      );
    },
    [previousCategory, previousCompanyBranch, previousType]
  );

  // Fetch forms and populate table
  useEffect(() => {
    const { selectedState } = debouncedValues;

    if (
      shouldGetForms(
        previousValues,
        selectedState,
        category,
        type,
        activeSpecialtyCompanyBranch
      )
    ) {
      searchUsingDebounce(selectedState);
      setPreviousValues(debouncedValues);
      setPreviousCategory(category);
      setPreviousCompanyBranch(activeSpecialtyCompanyBranch);
      setPreviousType(type);
    }
  }, [
    shouldGetForms,
    debouncedValues,
    searchUsingDebounce,
    previousValues,
    previousCategory,
    category,
    type,
    activeSpecialtyCompanyBranch
  ]);

  useEffect(() => {
    if (
      isAxiosLoading &&
      resultsGridLabel !== Translations.documents_forms.no_results
    ) {
      setResultsGridLabel(Translations.documents_forms.no_results);
    }
  }, [isAxiosLoading, resultsGridLabel]);

  return (
    <>
      {isAxiosLoading ? (
        <LoadingIndicator />
      ) : (
        <Table
          searchable
          searchQuery={debouncedValues.keyword}
          className="document-coverage-forms"
          data={formsList}
          columns={columns}
          showPagination
          rowKey="id"
          initialSort="number"
          initialDirection="asc"
          noResultsMessage={resultsGridLabel}
        />
      )}

      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </>
  );
};

DocumentsFormsResultsGrid.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.string,
  category: PropTypes.string,
  activeSpecialtyCompanyBranch: PropTypes.string
};

const mapStateToProps = state => ({
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state)
});

export default connect(mapStateToProps)(DocumentsFormsResultsGrid);
