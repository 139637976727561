import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Translations } from "../../translations";
import * as dictionary from "../../../constants/dictionary";
import { DATE_FORMAT } from "../../../claims/services/claimsUtil";
import {
  Table,
  Icon,
  Alert,
  LoadingIndicator,
  Anchor,
  Modal
} from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { printPdf } from "../../../shared/documents/helpers";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const ClaimsOpenedTable = ({
  removeClaimOpened,
  agencyCode,
  employeeUserName,
  claimsOpenedList,
  claimsOpenedError,
  claimOpenedDeleteError,
  is109015Agent,
  claimsOpenedLoading
}) => {
  const history = useHistory();

  const [showAlert, setShowAlert] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState([]);

  const handleRemoveClaim = claimNumber => () => {
    setShowAlert(true);
    removeClaimOpened(
      agencyCode,
      moment().format("YYYY-MM-DD"),
      employeeUserName,
      claimNumber
    );
  };

  const handleDismissAlert = () => {
    setShowAlert(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onPrintStart = claim_number => {
    const documentsLoadingWithCurrentSelection = !documentsLoading.includes(
      claim_number
    )
      ? [...documentsLoading, claim_number]
      : documentsLoading;

    setDocumentsLoading(documentsLoadingWithCurrentSelection);
  };

  const onPrintComplete = claim_number => {
    setDocumentsLoading(documentsLoading.filter(x => x !== claim_number));
  };

  const onPrintError = () => {
    setShowModal(true);
  };

  const ErrorModalBody = () => {
    return (
      <div className="">
        <HelpDeskMessage
          display="questionsOrAssistance"
          before="We're sorry, we could not locate the requested document."
        />
      </div>
    );
  };

  const columns = [
    { key: "insurance_name", label: Translations.claims_tab.policy_holder },
    {
      key: "claim_number",
      align: "center",
      label: Translations.claims_tab.claim_number,
      element: row => {
        return (
          <Anchor
            onClick={() => {
              history.push(
                `${routes.CLAIM_INFORMATION}?claim_number=${row.claim_number}`
              );
            }}
          >
            {row.claim_number}
          </Anchor>
        );
      }
    },
    {
      key: "policyNumber",
      label: Translations.claims_tab.policy_number,
      element: row => {
        const policyNumber = (row.policy_prefix || "") + row.policy_number;
        return (
          <Anchor
            onClick={() => {
              history.push(`${routes.ACCOUNT_HOME}/${policyNumber}`);
            }}
          >
            {policyNumber}
          </Anchor>
        );
      }
    },
    { key: "claim_status", label: Translations.claims_tab.status },
    {
      key: "",
      label: Translations.claims_tab.claim_notice,
      align: "center",
      element: row => {
        const requestInProgress =
          row.claim_number && documentsLoading.includes(row.claim_number);

        const claim_number = `${row.claim_number ?? ""}`;

        return (
          <div className="claims-table-form-name__wrapper">
            {requestInProgress && (
              <span className="claims-table-spinner">
                <LoadingIndicator message="" type="spinner" />
              </span>
            )}

            <Anchor
              href={`${routes.DOCUMENT_LIBRARY_PRINT_FORM_ROOT}/${dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC}/${claim_number}`}
              target="_blank"
              rel="noopener noreferrer"
              className="claims-notice-link"
              onClick={e => {
                e.preventDefault();

                printPdf({
                  form_number: claim_number,
                  form_type: dictionary.DOCUMENT_FORM_TYPES.CLAIMS.AGAC,
                  onStart: () => {
                    onPrintStart(claim_number);
                  },
                  onComplete: () => {
                    onPrintComplete(claim_number);
                  },
                  onError: onPrintError
                });
              }}
            >
              <Icon icon="farFile" size="lg" />
            </Anchor>
          </div>
        );
      }
    },
    {
      key: "",
      label: Translations.claims_tab.remove,
      align: "center",
      element: row => (
        <>
          {!is109015Agent && (
            <div
              className="icon"
              role="presentation"
              onClick={handleRemoveClaim(row.claim_number)}
            >
              <Icon icon="fasTimesCircle" />
            </div>
          )}
        </>
      )
    }
  ];

  const hasClaims = claimsOpenedList && claimsOpenedList.length > 0;
  return (
    <div>
      <div className="claim-status-heading">
        <b>{Translations.claims_tab.header_opened}</b>
      </div>
      {!claimsOpenedLoading && (!hasClaims || claimsOpenedError) && (
        <div className="friendly-error-messaging">
          {Translations.claims_tab.claim_opened_fail_error_message}
        </div>
      )}
      {claimOpenedDeleteError && showAlert && (
        <div className="row">
          <Alert type="error" onDismiss={handleDismissAlert}>
            {claimOpenedDeleteError}
          </Alert>
          &nbsp;
        </div>
      )}
      {claimsOpenedLoading ? (
        <LoadingIndicator />
      ) : (
        hasClaims && (
          <Table
            rowKey="claim_number"
            groupHeaderKey="opened_date"
            groupHeaderContents={row => (
              <>
                {Translations.claims_tab.opened_date}{" "}
                {moment(row.opened_date).format(DATE_FORMAT)}
              </>
            )}
            columns={columns}
            data={claimsOpenedList}
            showPagination
            itemsPerPage={20}
            className="claims-status-table"
          />
        )
      )}

      <Modal
        title="Document not found"
        show={showModal}
        onHide={onCloseModal}
        body={<ErrorModalBody />}
      />
    </div>
  );
};

ClaimsOpenedTable.propTypes = {
  agencyCode: PropTypes.string,
  employeeUserName: PropTypes.string,
  claimsOpenedList: PropTypes.array,
  removeClaimOpened: PropTypes.func.isRequired,
  claimsOpenedError: PropTypes.string,
  claimOpenedDeleteError: PropTypes.string,
  is109015Agent: PropTypes.bool,
  claimsOpenedLoading: PropTypes.bool.isRequired
};

export default ClaimsOpenedTable;
