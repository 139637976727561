import React from "react";

import { Panel, FlexRow, Anchor } from "@ufginsurance/ui-kit";
import { CheckboxOptions } from "../../shared/coverageOverrides/BP7EndorsementType_UFG";

const BP7EnhancementView = ({ coverage }) => {
  return (
    <>
      {!!coverage && (
        <Panel
          key={"hnoPanel"}
          id={"hnoPanel"}
          bgcolor="white"
          className="oq__view__coverage-panel"
          highlight="blue"
        >
          <div className="oq__view__coverage__title">
            <span className="oq__view__coverage__title-text">
              {coverage?.name}
            </span>
          </div>
          {(coverage?.terms || []).map(t => (
            <FlexRow key={t} className="oq__view__coverage__term">
              <span className="oq__view__coverage__term-name">{t?.name}:</span>
              <Anchor
                className="oq__endorsement-link pull-right oq__view__coverage__term-value"
                onKeyDown={() => {}}
                tabIndex={-1}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    CheckboxOptions[t?.chosenTermValue]?.compare?.link,
                    "_blank"
                  );
                  return false;
                }}
              >
                {t?.chosenTermValue}
              </Anchor>
            </FlexRow>
          ))}
        </Panel>
      )}
    </>
  );
};
export default BP7EnhancementView;
