import * as routes from "../constants/routes";

export const POLICY_TRANSACTION_STATUS_TYPES = {
  QUOTED: "Quoted",
  WITHDRAWN: "Withdrawn",
  ISSUED: "Issued",
  QUOTING: "Quoting"
};

export const POLICY_WIZARD_STEPS = {
  ONE: "1",
  TWO: "2"
};

export const mapPolicyChangeUrl = (policyNumber, jobId, step) => {
  return routes.POLICY_CHANGES_STEP.replace(":policyNumber", policyNumber)
    .replace(":jobId", jobId)
    .replace(":step", step);
};
