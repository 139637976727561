import { connect } from "react-redux";
import YourUfgTeam from "../components/YourUfgTeam";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { getActiveSpecialtyCompanyBranch } from "../../portalview/changePortalViewStateSelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  fetchUfgTeamContactDetails,
  fetchUfgTeamDepartmentData,
  setPreviousDepartment
} from "../actions/yourUfgTeamActions";

const mapStateToProps = state => ({
  agencyCode: getSelectedAgencyCode(state),
  has_surety_contract: sessionSelector.agencyHasSuretyContract(state),
  has_personal_lines_contract:
    sessionSelector.agencyHasPersonalLinesContract(state),
  has_commercial_lines_contract:
    sessionSelector.agencyHasCommercialLinesContract(state),
  hasServiceCenterAccess: sessionSelector.hasServiceCenterAccess(state),
  departmentData: state.yourUfgTeamReducer.departmentData,
  contactDetails: state.yourUfgTeamReducer.contactDetails,
  areContactsLoading: state.yourUfgTeamReducer.contactLoading,
  contactError: state.yourUfgTeamReducer.contactError,
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state),
  previousCompanyBranch: state.yourUfgTeamReducer.previousCompanyBranch,
  previousAgencyCode: state.yourUfgTeamReducer.previousAgencyCode,
  previousDepartment: state.yourUfgTeamReducer.previousDepartment,
  areDepartmentsLoading: state.yourUfgTeamReducer.departmentLoading
});

const mapDispatchToprops = {
  fetchUfgTeamContactDetails,
  fetchUfgTeamDepartmentData,
  setPreviousDepartment
};

export default connect(mapStateToProps, mapDispatchToprops)(YourUfgTeam);
