import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SuretyAgentManualsStore } from "../context";
import Navigation from "./Navigation";
import {
  ContentHeader,
  Alert,
  LoadingIndicator,
  Icon,
  Button
} from "@ufginsurance/ui-kit";
import { getContent } from "../actions";
import * as constants from "../constants";
import * as selectors from "../selectors";
import { toTitleCase } from "../../../components/Factory";
import CMSContent from "../../../cms-content/CMSContent";

const Content = ({ onNavigationChange }) => {
  const { state, dispatch } = useContext(SuretyAgentManualsStore);
  const { content, navigation } = state;
  const { contentId } = useParams();

  useEffect(() => {
    getContent(contentId, dispatch);
  }, [contentId, dispatch]);

  const getPageHeader = () => {
    if (selectors.isLandingPage(contentId)) return "Surety Agent Manuals";
    const section = selectors.getSectionByHierarchyKey(
      navigation?.data,
      contentId
    );
    const prefix = selectors.getSubsectionTitle(section, navigation?.data);
    return `${prefix} Surety ${toTitleCase(
      section?.web_description,
      constants.IGNORES
    )}`;
  };

  /***
   * Included for specific pages where the content in the CMS is NOT
   * formatted properly such that we can hide the header without
   * hiding the associated content.
   */
  const shouldIncludeHeader = [11740].includes(Number(contentId));

  return content?.loading || navigation?.loading ? (
    <LoadingIndicator />
  ) : content?.error || navigation?.error ? (
    <>
      {content?.error && (
        <Alert dismissible={false} type="error" label="Error" block>
          {content?.error?.message ||
            "An unexpected error occurred loading the content"}
          .
        </Alert>
      )}
      {navigation?.error && (
        <Alert dismissible={false} type="error" label="Error" block>
          {navigation?.error?.message ||
            "An unexpected error occured loading the navigation"}
          .
        </Alert>
      )}
    </>
  ) : (
    <>
      {!selectors.isLandingPage(contentId) && (
        <div className="sam-back-link">
          <Button
            isLink
            inline
            onClick={() => onNavigationChange(constants.CMS.DEFAULT_CONTENT_ID)}
          >
            <Icon size="lg" icon="farAngleLeft" />
            Back to Surety Agent Manuals
          </Button>
        </div>
      )}
      <ContentHeader>{getPageHeader()}</ContentHeader>
      <CMSContent
        id={`sam-content`}
        classNames={`sam-content ${
          shouldIncludeHeader && "sam-content-include-header"
        }`}
        content={content?.data?.display_text || ""}
      />
      <Navigation onNavigationChange={onNavigationChange} />
    </>
  );
};

export default Content;
