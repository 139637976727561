import qs from "query-string";
import API from "../../../api";

export const getTableOfContents = activeSpecialtyCompanyBranch => {
  const params = {
    company_branch: activeSpecialtyCompanyBranch
  };

  const queryString = qs.stringify(params);
  if (activeSpecialtyCompanyBranch) {
    return API.agent().get(
      `/resources/billing/table-of-contents?${queryString}`
    );
  }

  return API.agent().get(`/resources/billing/table-of-contents`);
};

export const getContent = contentId => {
  return API.agent().get(`/resources/billing/table-of-contents/${contentId}`);
};

export const getLicensedStates = agencyCode => {
  return API.agent().get(`/agencies/${agencyCode}/licensed-states`);
};

export const getServiceChargeFees = state => {
  const params = {
    state
  };

  const queryString = qs.stringify(params);

  return API.agent().get(`/fees?${queryString}`);
};
