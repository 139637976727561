import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";

import { GlobalContext } from "../../../shared/globalContext";
import { useResizeObserver } from "@asyarb/use-resize-observer";
import "./BottomNav.scss";

const BottomNav = ({ children, leftNav }) => {
  // It'd be a lot easier if we could use CSS: position: sticky
  // but it is not currently allowed because it doesn't support IE 11
  // until then, we'll position the bar using javascript

  const { footer, content } = useContext(GlobalContext);
  const navbar = useRef(null);

  useEffect(() => {
    return () => {
      window.onscroll = null;
      window.onresize = null;
      window.popstate = null;
    };
  }, []);

  const updateBottomNavbar = () => {
    const currentScrollPos = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const body = document.body;

    if (!navbar?.current || !footer?.current) return false;

    const footerHeight = footer.current.offsetHeight;
    const footerOffssetTop = footer.current.offsetTop;

    if (footerOffssetTop + footerHeight < windowHeight) {
      // if the window height is so small that the page doesn't have a scrollbar
      const offset = windowHeight - footerOffssetTop;
      navbar.current.style.bottom = `${offset}px`;

      // adding this class makes the navbar look like it's part of the page
      navbar.current.className = "bottom-nav__transparent";
    } else if (
      // if we've scrolled all of the way to the bottom of the page
      windowHeight + currentScrollPos >=
      body.scrollHeight - footerHeight
    ) {
      const offset =
        windowHeight + currentScrollPos - body.scrollHeight + footerHeight;
      navbar.current.style.bottom = `${offset}px`;

      // removing this class makes the navbar have a background
      navbar.current.className = "bottom-nav__transparent";
    } else {
      // if the navbar is hovering over page content
      navbar.current.style.bottom = "0";

      // removing this class makes the navbar have a background
      navbar.current.className = "";
    }

    content.current.style.marginBottom = navbar.current.clientHeight + "px";
  };

  // this watches the body tag and if its size changes at all, it runs the updater
  useResizeObserver({
    element: document.body,
    callback: () => {
      updateBottomNavbar();
    }
  });

  // these events also trigger the update to the navbar position
  useLayoutEffect(() => {
    // TODO: make updateBottomNavbar a dep of this useeffect
    window.onscroll = updateBottomNavbar;
    window.onresize = updateBottomNavbar;
    window.popstate = updateBottomNavbar;
    updateBottomNavbar();
  });

  return (
    <div ref={navbar} id="surety-quick__bottom-nav">
      <div className="container">
        {leftNav && <div className="spacer"></div>}
        <div className="bottom-nav__inner">{children}</div>
      </div>
    </div>
  );
};

BottomNav.propTypes = {
  children: PropTypes.node.isRequired,
  leftNav: PropTypes.bool
};

export default BottomNav;
