import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { useForm, Form, FormGroup } from "@ufginsurance/ui-kit";

import StatesDropdown from "./search-fields/StatesDropdown";
import ProductsDropdown from "./search-fields/ProductsDropdown";
import CoverageFormsResultsGrid from "./CoverageFormsResultsGrid";
import DocumentsFormsResultsGrid from "./DocumentsFormsResultsGrid";
import SearchInput from "./search-fields/SearchInput";

const Search = ({ type, category, companyBranch }) => {
  const COVERAGE_FORMS = "CF";
  const initialValues = {
    selectedState: "",
    selectedProduct: "",
    keyword: ""
  };

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  return (
    <Form context={form}>
      <FormGroup>
        <StatesDropdown form={form} />
        {type === COVERAGE_FORMS && <ProductsDropdown form={form} />}
        <SearchInput form={form} />
      </FormGroup>
      <FormGroup>
        {type && type === COVERAGE_FORMS && (
          <CoverageFormsResultsGrid form={form} companyBranch={companyBranch} />
        )}
        {type && type !== COVERAGE_FORMS && (
          <DocumentsFormsResultsGrid
            form={form}
            type={type}
            category={category}
          />
        )}
      </FormGroup>
    </Form>
  );
};

Search.propTypes = {
  type: PropTypes.string,
  category: PropTypes.string,
  companyBranch: PropTypes.string,
};

export default withRouter(Search);
