import React, { useEffect, useState, useContext } from "react";
import * as routes from "../constants/routes";
import { Link } from "react-router-dom";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "./OnlineQuotingContext";
import "./OnlineQuotingLayout.scss";

// http://localhost:8080/online_quoting/ivans/0001849730

const VendorQuoteLoader = ({ history, location }) => {
  const [status, setStatus] = useState();

  const { getVendorQuote } = useContext(OnlineQuotingContext);

  useEffect(() => {
    // !status and setStatus makes this effect only run one time
    if (!status) {
      setStatus("loading");
      const path = location.pathname;

      const regEx = `(${routes.VENDOR_QUOTING_LOADER}/)(.+)`;
      const qstringId = path.match(regEx) || [];

      const id = qstringId?.[2] || null;
      getVendorQuote(id)
        .then(({ quoted }) => {
          history.replace(routes.ONLINE_QUOTING + "/step" + (quoted ? 6 : 4));
        })
        .catch(() => {
          setStatus("failure");
        });
    }
  }, [getVendorQuote, history, location, location.pathname, status]);

  if (status === "loading")
    return (
      <LoadingIndicator
        message="Loading Quote"
        className="oq__quote-loader-wrapper"
      />
    );

  if (status === "failure")
    return (
      <div className="oq__quote-not-found">
        <h2>Unable to load quote.</h2>
        <b>We are sorry... unfortunately, we are unable load the quote.</b>
        <div>
          <Link to={routes.ONLINE_QUOTING_STEP1}>
            Click here to start a new quote.
          </Link>
        </div>
      </div>
    );

  return null;
};

export default VendorQuoteLoader;
