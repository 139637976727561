import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { SuretyAgentManualsStore } from "../context";
import { PanelGrid, Panel, Button } from "@ufginsurance/ui-kit";
import { getNavigation } from "../actions";
import * as constants from "../constants";
import * as selectors from "../selectors";
import { toTitleCase } from "../../../components/Factory";

const Navigation = ({ onNavigationChange }) => {
  const { state, dispatch } = useContext(SuretyAgentManualsStore);
  const { loading, error, data } = state.navigation;
  const { contentId } = useParams();

  useEffect(() => {
    if (!loading && !error && data?.length === 0)
      getNavigation(constants.CMS.TOP_NAVIGATION_ID, dispatch);
  }, [loading, error, data, dispatch]);

  const displayTopSectionNav = () => {
    const commercialSection = selectors.getCommercialSection(data);
    const contractSection = selectors.getContractSection(data);
    return (
      <>
        <Panel
          key={commercialSection?.hierarchy_key}
          title="Commercial Surety Manual"
        >
          {displaySectionLinks(commercialSection?.children || [])}
        </Panel>
        <Panel
          key={contractSection?.hierarchy_key}
          title="Contract Surety Manual"
        >
          {displaySectionLinks(contractSection?.children || [])}
        </Panel>
      </>
    );
  };

  const displaySubSectionNav = () => {
    const activeSection = selectors.getSectionByHierarchyKey(data, contentId);
    const parentSection = selectors.getParentSectionFor(activeSection, data);
    return (parentSection?.children || []).map(_ => {
      return (
        selectors.isPanel(_) && (
          <Panel key={_.hierarchy_key} title={_.web_description}>
            {displaySectionLinks(parentSection?.children || [], _) || []}
          </Panel>
        )
      );
    });
  };

  const displaySectionLinks = (items, section) => {
    return selectors.getSectionLinksFor(items, section, contentId).map(_ => {
      const isHeader = selectors.isHeader(_);
      const isLink = selectors.isLink(_);
      return (
        <div
          key={_.hierarchy_key}
          className={`${isHeader && "sam-nav-group-header"} ${
            isLink && "sam-nav-link"
          }`}
        >
          {_.text_flag ? (
            <Button
              inline
              isLink
              onClick={() => onNavigationChange(_?.hierarchy_key)}
              disabled={!_.enabled}
            >
              {toTitleCase(_.web_description, constants.IGNORES)}
            </Button>
          ) : (
            <b>{toTitleCase(_.web_description, constants.IGNORES)}</b>
          )}
        </div>
      );
    });
  };

  if (loading || error || !data) return null;

  const section = selectors.getSectionByHierarchyKey(data, contentId);
  const isLandingPage = selectors.isLandingPage(contentId);

  const sizes = isLandingPage
    ? { sm: 1, md: 2, lg: 2, xl: 2 }
    : { sm: 1, md: 2, lg: 3, xl: 4 };
  return (
    <>
      {!isLandingPage && (
        <div className="sam-related-topics">
          More topics related to {selectors.getSubsectionTitle(section, data)}{" "}
          Surety
        </div>
      )}
      <PanelGrid sizes={sizes}>
        {(isLandingPage ? displayTopSectionNav() : displaySubSectionNav()) ||
          []}
      </PanelGrid>
    </>
  );
};

export default Navigation;
