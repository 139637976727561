import { formatDate } from "@ufginsurance/ui-kit";
import { toTitleCase } from "../../../components/Factory";
import {
  SuretyConstructionSpecialty,
  ConstructionSpecialtyTypeOtherNotListed,
  ConstructionSpecialtyTypesRequiringDescription,
  allowedStatusCodes
} from "../constants/suretyDictionary";
import { getIsLegalEntityType } from "../shared/util";

const initialFormErrors = {
  account_name: [],
  account_number: [],
  address_line: [],
  zip: [],
  city: [],
  state: [],
  business_type: [],
  legal_business_name: [],
  legal_name_verified: [],
  ownerData: [],
  fein: [],
  construction_specialty: [],
  construction_specialty_dd: [],
  status: [],
  company_number: [],
  construction_other: []
};

export const getConstructionSpecialty = (classCode, other) => {
  return SuretyConstructionSpecialty.find(
    o =>
      o.value.substring(0, 9) === classCode &&
      (o.label.toLowerCase() === (other || "").toLowerCase() ||
        ConstructionSpecialtyTypeOtherNotListed === classCode ||
        !ConstructionSpecialtyTypesRequiringDescription.includes(classCode))
  );
};

export const getConstructionOther = (classCode, other) => {
  return classCode !== ConstructionSpecialtyTypeOtherNotListed
    ? getConstructionSpecialty(classCode, other)?.label || ""
    : other || "";
};

// ------------
//   SEARCH
// ------------
export const search = ({
  value,
  setBusinessNameOptions,
  agencyAccountsListData,
  setErrorMsg
}) => {
  // if search is empty, then empty the saved option data
  if (value.length === 0) {
    setBusinessNameOptions([]);
    return false;
  }

  // only continue if the search is at least X chars
  if (value && value.length < 0) {
    return false;
  }

  //Regex
  value = value.replace(/[~_^=<>`{\][}\\]/g, "");

  setErrorMsg(false);

  //TODO: these could be a reduce?
  const tempData =
    agencyAccountsListData?.data?.filter(
      a =>
        a.account_name.toLowerCase().indexOf(value.toString().toLowerCase()) >=
          0 || a.account_number.toString().indexOf(value) >= 0
    ) || [];

  const opts = tempData?.map(a => {
    return {
      value: a.account_number,
      label: `${a.account_name} (${toTitleCase(a.city)}, ${a.state})`,
      account_number: a.account_number,
      account_name: a.account_name
    };
  });

  setBusinessNameOptions(opts);

  // delete the previous typed "Add" option
  for (let i = 0; i < opts.length; i++) {
    if (opts[i].custom) {
      opts.splice(i, 1);
    }
  }
  // if there's no current value that matches the input, add the "Add Customer" option to the end
  if (!!value && !opts.some(o => o.value === value))
    opts.push({
      value,
      label: "Add Customer: " + value,
      custom: true
    });
  setBusinessNameOptions(opts);
};

// ------------
//   SELECT
// ------------
export const select = ({
  value,
  form,
  businessNameOptions,
  setBusinessNameOptions,
  errorMessageRenewal,
  agencyAccountsListData,
  setErrorMsg,
  setAlertType,
  setDisableFields,
  initialValues
}) => {
  // Account Deselected.
  if (!value) {
    setBusinessNameOptions(
      (agencyAccountsListData.data ?? []).map(a => {
        //reset the options dropdown
        return {
          value: a.account_number,
          label: `${a.account_name} (${toTitleCase(a.city)}, ${a.state})`,
          account_number: a.account_number,
          account_name: a.account_name
        };
      })
    );

    //reset form values
    form.updateForm({
      values: {
        ...initialValues,
        verifyModalData: null,
        legal_name_verified: false
      },
      errors: {
        ...initialFormErrors,
        verifyModalData: null
      }
    });
    setDisableFields(false);
    return;
  }

  let selectedAccount = agencyAccountsListData?.data?.find(
    a => a.account_number === value
  );

  //  -- new account --
  if (!selectedAccount) {
    setErrorMsg(false);
    setDisableFields(false);
    selectedAccount = {
      ...initialValues,
      account_name: value,
      legal_business_name: value,
      status: "W",
      company_number: "01",
      isNew: true,
      verify_date: formatDate(new Date(), "YYYY-MM-DD")
    };
    //  -- existing account --
  } else {
    setDisableFields(true);
    setAlertType("note");
    errorMessageRenewal =
      "To change other Account information, please contact your Underwriter.";
    setErrorMsg(errorMessageRenewal);
  }

  setBusinessNameOptions([selectedAccount]);

  const isStatusAllowed = allowedStatusCodes.includes(
    selectedAccount?.status?.toUpperCase()
  );

  const isValidLegalEntityType = getIsLegalEntityType(selectedAccount);

  const tempAcctNum = !isNaN(value)
    ? value
    : selectedAccount.account_name !== ""
    ? "0000000000"
    : null;

  //company number is not one
  const isCompanyUfg01 = selectedAccount.company_number === "01";

  const getLegalBusinessName = ({ bondname1, bondname2, bondname3 }) => {
    let string = bondname1;
    if (bondname2) {
      string += bondname2;
      if (bondname3) string += bondname3;
    }
    return string;
  };
  const legalBusinessName = getLegalBusinessName(selectedAccount);

  if (!isStatusAllowed || !isCompanyUfg01 || !isValidLegalEntityType) {
    setAlertType("warning");
    errorMessageRenewal =
      "The account selected is not eligible for the Quick Program. Please contact your underwriter or select a different account.";
    setErrorMsg(errorMessageRenewal);

    form.updateForm({
      values: {
        ...initialValues,
        account_number: tempAcctNum,
        account_name:
          selectedAccount.account_number !== ""
            ? businessNameOptions.find(o => o.account_number === value)
                .account_name
            : selectedAccount.account_name
      },
      errors: { ...initialFormErrors }
    });
  } else if (!!value && selectedAccount) {
    const constructionSpecialty = getConstructionSpecialty(
      selectedAccount?.construction_specialty,
      selectedAccount?.construction_other
    );
    const constructionOther = getConstructionOther(
      selectedAccount?.construction_specialty,
      selectedAccount?.construction_other
    );
    form.updateForm({
      values: {
        ...initialValues,
        account_number: tempAcctNum,
        account_name:
          selectedAccount.account_number !== ""
            ? businessNameOptions.find(o => o.account_number === value)
                .account_name
            : selectedAccount.account_name,
        address_line: selectedAccount.address_line,
        building_number: selectedAccount.building_number,
        street_name: selectedAccount.street_name,
        pobox: selectedAccount.pobox,
        city: selectedAccount.city,
        state: selectedAccount.state,
        zip: selectedAccount.zip,
        unit_number: selectedAccount.unit_number,
        unit_type: selectedAccount.unit_type,
        confidence: selectedAccount.confidence,
        business_type: selectedAccount.legal_entity_type,
        legal_business_name:
          parseInt(tempAcctNum) !== 0
            ? legalBusinessName
            : selectedAccount.account_name,
        construction_specialty: !!constructionSpecialty
          ? selectedAccount.construction_specialty
          : "",
        construction_specialty_dd: !!constructionSpecialty
          ? constructionSpecialty.value
          : "",
        status: selectedAccount.status,
        fein: selectedAccount.fein || "",
        legal_name_verified: parseInt(tempAcctNum) !== 0,
        ownerData: [],
        company_number: selectedAccount.company_number,
        construction_other: constructionOther,
        verify_date: selectedAccount.amend_date || selectedAccount.verify_date
      },
      errors: { ...initialFormErrors }
    });
  } else {
    form.updateForm({
      values: { ...initialValues },
      errors: { ...initialFormErrors }
    });
  }
};
