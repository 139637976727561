import React, { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  Button,
  Panel,
  Modal,
  LoadingIndicator,
  Anchor,
  ButtonDropNav,
  formatDate,
  Popover,
  FlexRow
} from "@ufginsurance/ui-kit";
import useGetBond from "./hooks/useGetBond";
import useGetRenewalFormStatus from "./hooks/useGetRenewalFormStatus";
import { toTitleCase } from "../../components/Factory";
import { formatCurrencyUSD, formatPercent } from "../shared/utils";
import { fetchBondDocuments, fetchBondDocumentById } from "../shared/documents";
import { useSuretyContext } from "../surety-context/SuretyContext";
import useGetAccount from "../account-overview/hooks/useGetAccount";
import DefaultErrorResponse from "../../shared/error/DefaultErrorResponse";
import BondOverviewBillingHistory from "./BondOverviewBillingHistory";
import BondOverviewBondRating from "./BondOverviewBondRating";
import CancelBond from "./CancelBond";
import ChangeBondAmount from "./ChangeBondAmount";
import OtherChange from "./OtherChange";
import ChangeProjectDescription from "./ChangeProjectDescription";
import "./SuretyBondOverview.scss";
import { SURETY_CONSENT_OF_SURETY } from "../../constants/routes";

function BondOverview({
  bond,
  bondIsLoading,
  bondError,
  underwriter,
  setModalBody,
  renewalFormExists
}) {
  const { suretyBondRatingTablesEnabled } = useFlags();
  const { agency, updateBondOverview } = useSuretyContext();
  const [isLoadingStatusInquiry, setIsLoadingStatusInquiry] = useState(false);

  if (bondIsLoading)
    return <LoadingIndicator message="Gathering Bond Details" />;
  if (!bond || bondError) return <DefaultErrorResponse />;

  const isContract = bond?.bond_type?.toLowerCase() === "contract";
  const isClosed = bond?.status?.toLowerCase() === "closed";
  const handleChangeRequestClick = ({ to }) => setModalBody(to);

  const handleRequestConsentOfSuretyClick = () => {
    const url = `${SURETY_CONSENT_OF_SURETY}/?bond_number=${bond?.bond_number}`;
    window.open(url, "_blank");
  };

  const openStatusInquiry = async (bondNumber, documentId) => {
    setIsLoadingStatusInquiry(true);
    await fetchBondDocumentById(bondNumber, documentId);
    setIsLoadingStatusInquiry(false);
  };

  const isBondEligibleForConsentOfSurety = () => {
    if (bond?.bond_type?.toLowerCase() !== "contract") return false;
    /*"lapsed but open" not a real status, inferred when:
     *  - bond amount > $5m
     *  - not canceled or closed
     *  - expired (unless maintenance bond)
     */
    const allowedStatuses = !bond?.maintenance_bond
      ? ["open", "lapsed", "closed"]
      : ["open", "lapsed"];
    if (allowedStatuses.includes(bond?.status?.toLowerCase())) return true;
    return false;
  };

  const getMaintenanceBondLengthMonthsOrYears = () => {
    return new Date("11/01/2022") >
      new Date(formatDate(bond?.effective_date, "MM/DD/YYYY"))
      ? "years"
      : "months";
  };

  return (
    <>
      {isClosed && (
        <Panel bgcolor="grey" noBorder>
          <p>
            This bond was <strong>Closed</strong> on{" "}
            <strong>{formatDate(bond?.closed_date, "MM/DD/YYYY")}</strong>
          </p>
          <div>
            <strong>Close/Cancel Reason:</strong>
            <br />
            {bond?.cancel_reason}
          </div>
        </Panel>
      )}
      <div className="summary">
        <Panel>
          <div>
            <strong>Bond Type: </strong>
            <span>{toTitleCase(bond?.bond_type) ?? ""}</span>
          </div>
          <div>
            <strong>Account: </strong>
            <span>
              <p>{toTitleCase(bond?.account_name, ["LLC"]) ?? ""}</p>
              <p>{toTitleCase(bond?.account_number) ?? ""}</p>
              <p>{toTitleCase(bond?.account_address?.street) ?? ""}</p>
              <p>{toTitleCase(bond?.account_address?.csz) ?? ""}</p>
            </span>
          </div>
          <div>
            <strong>Obligee: </strong>
            <span>
              {toTitleCase(bond?.obligee_name) ?? ""}
              <br />
              {toTitleCase(bond?.obligee_address?.street) ?? ""}
              <br />
              {toTitleCase(bond?.obligee_address?.csz) ?? ""}
            </span>
          </div>
          {isContract && (
            <div>
              <strong>Project Description: </strong>
              <span>{bond?.bond_description}</span>
            </div>
          )}
          {isContract && bond?.maintenance_bond_length > 1 && (
            <div>
              <strong>Maintenance Bond: </strong>
              <span>
                <div>
                  <strong>
                    Length ({getMaintenanceBondLengthMonthsOrYears()}):{" "}
                  </strong>
                  <span>
                    {`${bond?.maintenance_bond_length}` ?? ""}{" "}
                    {getMaintenanceBondLengthMonthsOrYears()}
                  </span>
                </div>
                <div>
                  <strong>Maintenance Bond Amount: </strong>
                  <span>
                    {formatCurrencyUSD(bond?.maintenance_bond_amount, 2) ?? ""}
                  </span>
                </div>
                <div>
                  <strong>Bond Class: </strong>
                  <span>{bond?.maintenance_bond_class ?? ""}</span>
                </div>
              </span>
            </div>
          )}
        </Panel>
        <Panel>
          <p>
            <strong>Bond No:</strong> <span>{bond?.bond_number ?? ""}</span>
          </p>
          <p>
            <strong>Status: </strong>
            <span>{toTitleCase(bond?.status) ?? "-"}</span>
          </p>
          <p>
            <strong>Underwriter: </strong>
            <span>{`${underwriter?.first_name} ${underwriter?.last_name}`}</span>
          </p>
          <p>
            <strong>Effective: </strong>
            <span>{formatDate(bond?.effective_date, "MM/DD/YYYY") ?? ""}</span>
          </p>
          <p>
            <strong>Expiration: </strong>
            <span>{formatDate(bond?.expiration_date, "MM/DD/YYYY") ?? ""}</span>
          </p>
          <p>
            <strong>Renewal Type: </strong>
            <span>{toTitleCase(bond?.renewal_type) ?? ""}</span>
          </p>
          <p>
            <strong>Bond Amount: </strong>
            <span>{formatCurrencyUSD(bond?.current_bond_amount, 2)}</span>
          </p>
          {isContract && (
            <p>
              <strong>Contract Amount: </strong>
              <span>{formatCurrencyUSD(bond?.contract_amount, 2)}</span>
            </p>
          )}
          <p>
            <strong>Premium: </strong>
            <span>
              {!bond?.bond_premium
                ? "-"
                : formatCurrencyUSD(bond?.bond_premium, 2)}
            </span>
          </p>
          <p>
            <strong>Bill Type: </strong>
            <span>{toTitleCase(bond?.bill_type) ?? ""}</span>
          </p>
          <p>
            <strong>Agent Comm: </strong>
            <span>{formatPercent(bond?.agency_commission, true) ?? "-"}</span>
          </p>

          <div className="bond-overview-links">
            <Anchor
              className="mb-0"
              onClick={() => fetchBondDocuments("original", bond?.bond_number)}
            >
              View/Print Bond Documents
            </Anchor>

            {renewalFormExists ? (
              <Anchor
                id="viewPrintRenewalForm"
                className="nowrap"
                onClick={() => fetchBondDocuments("latest", bond?.bond_number)}
              >
                View/Print Renewal Form
              </Anchor>
            ) : (
              <Popover
                id="viewPrintRenewalFormDisabled"
                className="mb-0"
                trigger="hover"
                placement="left"
                triggerContent={
                  <p className="inactive-link">View/Print Renewal Form</p>
                }
                popoverContent={
                  <p>This bond does not have any associated Renewal Forms</p>
                }
                theme="white"
              />
            )}

            {bond?.latest_status_inquiry?.id ? (
              <span>
                <Button
                  id="viewStatusInquiry"
                  inline
                  isLink
                  spinner={isLoadingStatusInquiry}
                  disabled={isLoadingStatusInquiry}
                  onClick={() =>
                    openStatusInquiry(
                      bond?.bond_number,
                      bond?.latest_status_inquiry?.id
                    )
                  }
                >
                  View Status Inquiry
                </Button>
              </span>
            ) : (
              <Popover
                id="viewStatusInquiryDisabled"
                className="mb-0"
                trigger="hover"
                placement="left"
                triggerContent={
                  <p className="inactive-link">View Status Inquiry</p>
                }
                popoverContent={
                  <p>There are no Status Inquiries for this bond</p>
                }
                theme="white"
              />
            )}

            {isBondEligibleForConsentOfSurety() ? (
              <Anchor
                id="request-consent-of-surety"
                target="_blank"
                className="nowrap"
                onClick={handleRequestConsentOfSuretyClick}
              >
                Request Consent of Surety
              </Anchor>
            ) : (
              <Popover
                id="request-consent-of-surety-disabled"
                className="mb-0"
                trigger="hover"
                placement="left"
                triggerContent={
                  <p className="inactive-link">Request Consent of Surety</p>
                }
                popoverContent={
                  <p>This bond is not eligible for Consent of Surety</p>
                }
                theme="white"
              />
            )}

            <ButtonDropNav
              id="request-change"
              variant="link"
              buttonText="Request Change"
              onClick={handleChangeRequestClick}
              links={[
                { text: "Bond Amount", to: "changeAmount" },
                { text: "Project Description", to: "projectDescription" },
                { text: "Cancel Bond", to: "cancelBond" },
                { text: "Other", to: "otherChange" }
              ]}
            />
          </div>
        </Panel>
      </div>
      <BondOverviewBillingHistory bond={bond} agencyCode={agency?.agencyCode} />

      {suretyBondRatingTablesEnabled && <BondOverviewBondRating bond={bond} />}

      <FlexRow align="right">
        <Button onClick={() => updateBondOverview({ showModal: false })}>
          Close
        </Button>
      </FlexRow>
    </>
  );
}

export default function BondOverviewModal() {
  const { agency, bondOverview, updateBondOverview } = useSuretyContext();
  const [modalBody, setModalBody] = useState("bondOverview");
  const [bond, bondIsLoading, bondError] = useGetBond(
    bondOverview?.bond_number
  );
  const [account, accountIsLoading] = useGetAccount(
    agency?.agencyCode,
    bond?.account_number
  );
  const { renewalFormExists } = useGetRenewalFormStatus(bond?.bond_number);

  const renderModalBody = modalBodySelection => {
    switch (modalBodySelection) {
      case "cancelBond":
        return <CancelBond bond={bond} setModalBody={setModalBody} />;
      case "otherChange":
        return <OtherChange bond={bond} setModalBody={setModalBody} />;
      case "changeAmount":
        return <ChangeBondAmount bond={bond} setModalBody={setModalBody} />;
      case "projectDescription":
        return (
          <ChangeProjectDescription bond={bond} setModalBody={setModalBody} />
        );
      case "bondOverview":
      default:
        return (
          <BondOverview
            bond={bond}
            bondIsLoading={bondIsLoading}
            bondError={bondError}
            agencyCode={agency?.agencyCode}
            underwriter={account?.surety_underwriter}
            accountIsLoading={accountIsLoading}
            setModalBody={setModalBody}
            renewalFormExists={renewalFormExists}
          />
        );
    }
  };

  const getBondOverviewTitle = () => {
    if (bond && !bondIsLoading) {
      return `${modalBody !== "bondOverview" ? "Edit " : ""} ${
        bond?.bond_type?.toLowerCase() === "contract"
          ? "Contract"
          : "Commercial"
      } Bond Overview`;
    }
    if (bondError) return "Bond Overview - Unable to Find Bond";
    return "";
  };

  return (
    <Modal
      title={getBondOverviewTitle()}
      show={bondOverview?.showModal}
      onHide={() => {
        setModalBody("bondOverview");
        updateBondOverview({ showModal: false });
      }}
      body={renderModalBody(modalBody)}
      containerClassName="bond-overview"
      size="xl"
      altCloseMethod={false}
    />
  );
}
