import React, { useState, useEffect, useCallback, useContext } from "react";
import * as api from "../services/onlineQuotingService";
import * as routes from "../constants/routes";
import { ContentHeader, Alert } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import { quotePath } from "./shared/constants";
import {
  sortByProperty,
  hasAutoWithoutHNO,
  scrollToViewItem
} from "./shared/util";
import { getLobCoverages } from "./step5/location/locationCoverages.js";
import { usePriorLosses } from "./step5/priorLosses/usePriorLosses";
import getAllLocations from "./step5/location/getAllLocations";

import Step5Context, { useStep5 } from "./step5/Step5Context";
import BottomNavigation from "./shared/BottomNavigation";
import BuildingCards from "./step5/building/BuildingCards";
import DriverCards from "./step5/driver/DriverCards";
import EmployeeCards from "./step5/employees/EmployeeCards";
import LocationCards from "./step5/location/LocationCards";
import OnlineQuotingContext from "./OnlineQuotingContext";
import PriorLossesPanel from "./step5/priorLosses/PriorLossesPanel";
import StateDataSection from "./step5/state/StateDataSection";
import RatingQuoteModal from "./step5/RatingQuoteModal";
import VehicleCards from "./step5/vehicle/VehicleCards";
import { useLocation } from "react-router-dom";
import { mergeExclusions } from "./shared/helpers/mergeExclusions";

import "./step5/Cards.scss";
import "./Step5RiskInfo.scss";

const Step5RiskInfo = ({ history }) => {
  const {
    quoteData,
    getQuotePart,
    navigateToStep,
    rateQuote,
    supportingData,
    updateSupportingDataPromise,
    supportingDataIsUpdating,
    step,
    loggingData,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const { hash } = useLocation();

  useEffect(() => {
    if (step !== 5 && !supportingDataIsUpdating) {
      updateSupportingDataPromise({
        dataToMergeAndSave: { currentPage: "5" }
      });
    }
  }, [step, updateSupportingDataPromise, supportingDataIsUpdating]);

  const [locations, setlocations] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [agencyStates, setAgencyStates] = useState([]);
  const [autoLine, setAutoLineVisibility] = useState(false);
  const [missingCoverables, setMissingCoverables] = useState(false);
  const [formDataRiskInfo, setFormDataRiskInfo] = useState();
  const [vehicleCoverageExclusion, setVehicleCoverageExclusion] = useState();
  const [buildingCoverageExclusion, setBuildingCoverageExclusion] = useState();
  const [locationCoverageExclusion, setLocationCoverageExclusion] = useState();
  const [showRatingQuoteModal, setShowRatingQuoteModal] = useState(false);
  const [navNextSpinner, setNavNextSpinner] = useState(false);
  const [loadingMetadata, setLoadingMetadata] = useState(false); // it should only attempt to load metadata once (prevents loops)

  const bopLine = !!quoteData.lobData?.bp7BusinessOwners;
  const wcmLine = !!quoteData.lobData?.wcmWorkersComp;

  const step5ContextValue = useStep5();

  const { stateSpecificIsInvalid } = step5ContextValue;

  const workCompLosses = usePriorLosses({
    lob: "WCMWorkersCompLine",
    supportingData,
    quoteData
  });
  const autoLosses = usePriorLosses({
    lob: "CA7CommAutoLine",
    supportingData,
    quoteData
  });

  const isMissingLossClaims =
    (wcmLine &&
      !workCompLosses?.quoteHasLosses &&
      !!workCompLosses?.requireLosses) ||
    (autoLine && !autoLosses?.quoteHasLosses && !!autoLosses?.requireLosses);

  useEffect(() => {
    if (hash && loadingMetadata === "done") {
      scrollToViewItem({ itemId: hash, hash, history });
    }
  }, [wcmLine, history, locations, drivers, vehicles, hash, loadingMetadata]);

  useEffect(() => {
    const _hasAuto = hasAutoWithoutHNO(quoteData, supportingData);
    setAutoLineVisibility(_hasAuto);
  }, [quoteData, supportingData]);

  useEffect(() => {
    if (!formDataRiskInfo && !loadingMetadata) {
      setLoadingMetadata(true);

      const apisToRun = [
        api.getRiskInfoPageMetadata(quoteData.quoteID).catch(error =>
          toastErrr({
            error,
            action: "getRiskInfoPageMetadata",
            description: "api failure getRiskInfoPageMetadata"
          })
        ),
        api.getCustomerInfoMetaData().catch(error =>
          toastErrr({
            error,
            action: "getCustomerInfoMetaData",
            description: "api failure getCustomerInfoMetaData"
          })
        ),
        api.getVehicleFormMetadata().catch(error =>
          toastErrr({
            error,
            action: "getVehicleFormMetadata",
            description: "api failure getVehicleFormMetadata"
          })
        ),
        api
          .getCoverageControlData({ dto: quoteData, supportingData })
          .catch(error =>
            toastErrr({
              error,
              action: "getCoverageControlData",
              description: "api failure getCoverageControlData"
            })
          ),
        api.getBuildingFormMetadata(quoteData.quoteID).catch(error => {
          toastErrr({
            error,
            action: "getBuildingFormMetadata",
            description: "api failure getBuildingFormMetadata"
          });
        }),
        api.getBuildingCoveragesMetadata().catch(error => {
          toastErrr({
            error,
            action: "getBuildingCoveragesMetadata",
            description: "api failure getBuildingCoveragesMetadata"
          });
        })
      ];

      Promise.all(apisToRun).then(results => {
        const [
          riskInfo,
          customerInfo,
          vehicle,
          awsExclusions,
          buildingMeta,
          buildingExcl
        ] = results;

        setAgencyStates(customerInfo?.data?.formData.licensed_states);

        const allAwsExclusions = {
          formData: {
            stateCoverageControl:
              awsExclusions?.data?.controls?.coverable?.stateSpecific
                ?.stateCoverageControl || {},
            coverageControl: [
              ...(awsExclusions?.data?.controls?.coverable?.location
                ?.coverageControl || []),
              ...(awsExclusions?.data?.controls?.coverable?.building
                ?.coverageControl || []),
              ...(awsExclusions?.data?.controls?.coverable?.vehicle
                ?.coverageControl || []),
              ...(awsExclusions?.data?.controls?.coverable?.workcomp
                ?.coverageControl || []),
              ...(awsExclusions?.data?.recommended || [])
            ]
          }
        };

        setFormDataRiskInfo(
          mergeExclusions([allAwsExclusions, riskInfo?.data])
        );

        setVehicleCoverageExclusion(
          mergeExclusions([allAwsExclusions, vehicle?.data])
        );

        setBuildingCoverageExclusion(
          mergeExclusions([
            buildingMeta?.data,
            buildingExcl?.data,
            allAwsExclusions
          ])
        );

        setLocationCoverageExclusion(allAwsExclusions);

        setLoadingMetadata("done");
      });
    }
  }, [
    formDataRiskInfo,
    loadingMetadata,
    loggingData,
    quoteData,
    supportingData,
    toastErrr
  ]);

  const getCoveragebleFromStore = useCallback(
    path => {
      const obj = getQuotePart(path, true);
      return obj.map(l => ({
        ...l,
        id: l?.id || l?.fixedId || l?.fixedID
      }));
    },
    [getQuotePart]
  );

  useEffect(() => {
    if (quoteData) {
      const inlocations = getAllLocations({ quoteData, supportingData }).map(
        l => {
          const hasErrors =
            quoteData?.errorsAndWarnings?.validationIssues?.issues?.some(i => {
              return (
                i?.relatedEntity?.fixedId === l.bp7LocationFixedID ||
                l?.classifications
                  ?.map(c => c.fixedId)
                  .includes(i?.relatedEntity?.fixedId)
              );
            });
          let coverages = [];
          getLobCoverages(quoteData, l).forEach(g => {
            coverages = [...coverages, ...g.locationCoverages];
          });

          const hasEmptySI = coverages.some(
            f =>
              f.hasOwnProperty("clauseScheduleItems") &&
              f.clauseScheduleItems.scheduleItems.length === 0
          );

          return {
            ...l,
            className:
              hasErrors || hasEmptySI ? "oq__coverable__tableRowWithError" : ""
          };
        }
      );
      //OOQ-4257 select auto by default  and OOQ-5404 able to turn off auto.
      const primaryLocation = inlocations.find(l => l.isPrimary);
      if (
        !supportingData.hasOwnProperty("autoLocationFixedIDs") &&
        primaryLocation
      ) {
        // saving ca7CommAuto locations to supporting data if not saved for the first time
        // Note that this will save the primary location in the "supportingData.autoLocationFixedIDs" array by default....
        // even if auto is not on the quote.  This will be ignored until auto is added to the quote.
        updateSupportingDataPromise({
          dataToMergeAndSave: {
            autoLocationFixedIDs: [primaryLocation.fixedID]
          }
        });
      }

      setlocations(inlocations);

      const inVehicles =
        getCoveragebleFromStore(quotePath.vehicles).map(v => ({
          ...v,
          locationId: v.location
            ? v.location.id || v.location.fixedId || v.location.fixedID
            : null,
          className:
            quoteData?.errorsAndWarnings?.validationIssues?.issues?.some(
              i =>
                i?.relatedEntity?.fixedId === v.fixedId ||
                v?.classifications
                  ?.map(c => c.fixedId)
                  .includes(i?.relatedEntity?.fixedId)
            )
              ? "oq__coverable__tableRowWithError"
              : ""
        })) || [];

      setVehicles(inVehicles.sort(sortByProperty("fixedId")));

      const inDrivers = getCoveragebleFromStore(quotePath.drivers) || [];
      setDrivers(inDrivers.sort(sortByProperty("fixedId")));
    }
  }, [
    getCoveragebleFromStore,
    quoteData,
    supportingData,
    updateSupportingDataPromise
  ]);

  useEffect(() => {
    // gather up counts to update the state: setMissingCoverables
    const {
      incompleteBuilding,
      locationMissingBuilding,
      locationMissingEmployee
    } = locations.reduce(
      (acc, l) => {
        if (
          wcmLine &&
          !!l?.wcmLocationFixedID &&
          (l?.coveredEmployees || []).length === 0
        )
          acc.locationMissingEmployee = true;

        // check if location has any buildings... all bp7 locations should have at least one building buildings
        if (!!l?.bp7LocationFixedID && (l?.buildings || []).length === 0)
          acc.locationMissingBuilding = true;

        // if a quote has building(s) from bop-prefill, but they haven't
        // been edited, then disable the continue buttin until edited OOQ-7298
        if (
          !!l?.bp7LocationFixedID &&
          (l?.buildings || []).some(
            b =>
              !b?.description ||
              !b?.yearBuilt ||
              !b?.numberOfStories ||
              !b?.constructionType ||
              !b?.roofType
          )
        )
          acc.incompleteBuilding = true;

        return acc;
      },
      {
        incompleteBuilding: false,
        locationMissingBuilding: false,
        locationMissingEmployee: false
      }
    );

    // if missing coverables, this state helps disable the continue button
    const _missingCoverables =
      (vehicles.length === 0 && autoLine) ||
      (drivers.length === 0 && autoLine) ||
      locationMissingEmployee ||
      locationMissingBuilding ||
      incompleteBuilding;

    setMissingCoverables(_missingCoverables);
  }, [autoLine, drivers, locations, vehicles, wcmLine]);

  // -----------------------
  // SUBMIT QUOTE FOR RATING
  // -----------------------
  const continueAction = useCallback(() => {
    toast.dismiss();
    setNavNextSpinner(true);
    setShowRatingQuoteModal(true);
    rateQuote()
      .then(({ success, error, issues, quoted }) => {
        if (success) {
          if (quoted) {
            //  rating was successful, continue to step6
            navigateToStep({ step: 6 }, ({ success }) => {
              if (success) history.push(routes.ONLINE_QUOTING_STEP6);
            });
          } else if (issues?.length) {
            // rating call may be "successful" but if it has validationIssues
            // ... then show the issues in a toast msg
            setShowRatingQuoteModal(false);
            setNavNextSpinner(false);
            toastErrr({
              type: "validation",
              action: "rateQuote",
              description: "Validation issues stops user from rating",
              error,
              misc: { issues },
              displayMessage: (
                <div className="oq__flow-error__msg">
                  <div className="oq__flow-error__msg-text">
                    Unable to continue. Please fix these issues in order to
                    continue:
                  </div>
                  <div className="oq__flow-error__msg-list">
                    <ul>
                      {issues?.map(e => (
                        <li key={e.reason}>{e.reason}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            });
          }
          // something else went wrong - like the quote DTO returned in Draft status
          else {
            setShowRatingQuoteModal(false);
            setNavNextSpinner(false);

            toastErrr({
              action: "rateQuote",
              description:
                "unable to rate when proceeding to step 6 with 200 response",
              error
            });
          }
        }
      })
      .catch(({ error }) => {
        // if the rating call failed, display an error
        setShowRatingQuoteModal(false);
        setNavNextSpinner(false);
        toastErrr({
          action: "rateQuote",
          description:
            "unable to rate when proceeding to step 6 with hard error",
          error
        });
      });
  }, [history, navigateToStep, rateQuote, toastErrr]);

  const returnToStep4 = useCallback(() => {
    toast.dismiss();
    history.push(routes.ONLINE_QUOTING_STEP4);
  }, [history]);

  if (!bopLine && !autoLine) {
    return (
      <Alert type="warning" dismissible={false} className="oq__alert">
        You have not selected BOP(Business Owner Policy) or Commercial Auto
        Products
      </Alert>
    );
  }

  return (
    <Step5Context.Provider value={step5ContextValue}>
      <div id="oq-step5" className="oq__step-container">
        {showRatingQuoteModal && <RatingQuoteModal />}

        <ContentHeader>Now we&rsquo;re down to the details</ContentHeader>
        <div id={"Locations"}>
          <LocationCards
            locations={[...locations]}
            agencyStates={agencyStates}
            coverageExclusions={locationCoverageExclusion}
          />
        </div>
        {bopLine && (
          <div id={"Buildings"}>
            <BuildingCards
              isOpen={false}
              locations={locations}
              coverageExclusions={buildingCoverageExclusion}
            />
          </div>
        )}
        {autoLine && (
          <>
            <div id={"Vehicles"}>
              <VehicleCards
                isOpen={false}
                locations={locations}
                vehicles={vehicles}
                agencyStates={agencyStates}
                coverageExclusions={vehicleCoverageExclusion}
              />
            </div>
            <div id={"Drivers"}>
              <DriverCards isOpen={false} drivers={drivers} />
            </div>
            <PriorLossesPanel lob="CA7CommAutoLine" />
          </>
        )}
        {wcmLine && (
          <div id={"Employees"}>
            <EmployeeCards
              isOpen={false}
              locations={locations}
              coverageExclusions={formDataRiskInfo}
            />
            <PriorLossesPanel lob="WCMWorkersCompLine" />
          </div>
        )}
        <div id="StateSpecific">
          <StateDataSection step5Exclusions={formDataRiskInfo} />
        </div>
        {loadingMetadata !== "done" && (
          <span className="oq__pageIsLoading"></span>
        )}
        <BottomNavigation
          left={{
            text: "Back to Coverages",
            onClick: () => returnToStep4(),
            disabled: loadingMetadata !== "done"
          }}
          right={{
            text: "Continue to Review Quote",
            onClick: () => continueAction(),
            spinner: navNextSpinner,
            disabled:
              missingCoverables ||
              isMissingLossClaims ||
              navNextSpinner ||
              stateSpecificIsInvalid ||
              loadingMetadata !== "done"
          }}
        />
      </div>
    </Step5Context.Provider>
  );
};

export default Step5RiskInfo;
