import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "@ufginsurance/ui-kit";
import { DateFormat } from "../../../../components/Factory";
import { Translations } from "../../../../components/translations";

const DirectUfgCore = ({
  documents,
  title,
  fetchAccountingStatementViewPDF,
  report_group,
  agency_code,
  source,
  billing_type
}) => {
  const isSponsored = () => report_group === "sponsored";

  const [documentsLoading, setDocumentsLoading] = useState([]);

  const handleDocumentClick = (date, report_type, document_id, pdfId) => () => {
    setDocumentsLoading(items => [...items, pdfId]);
    fetchAccountingStatementViewPDF({
      agency_code,
      source,
      billing_type,
      report_group,
      date,
      report_type,
      document_id
    }).finally(() => {
      setDocumentsLoading(items => items.filter(_ => _ !== pdfId));
    });
  };

  const displayButton = (item, type, label) => {
    if (!label) return null;
    const pdfId = item.id + type;
    return (
      <div>
        <Button
          isLink
          inline
          className="nowrap"
          onClick={handleDocumentClick(item.date, type, 0, pdfId)}
          spinner={documentsLoading.includes(pdfId)}
          disabled={documentsLoading.includes(pdfId)}
        >
          {label}
        </Button>
      </div>
    );
  };

  const getColumns = () => [
    {
      label: "",
      key: "date",
      element: row => <div>{DateFormat(row.date, "MM/DD/YYYY")}</div>
    },
    {
      label: "",
      key: "statement",
      isDummyField: true,
      element: row => {
        const label = row.report_information
          .map(item => item.report_type)
          .includes("statement")
          ? isSponsored()
            ? Translations.reports.tabAccounting.agencyStatement
                .document_header_sponsored
            : Translations.reports.tabAccounting.agencyStatement.statement
          : "";
        return displayButton(row, "statement", label);
      }
    }
  ];

  const getAllColumns = () => {
    const columns = getColumns();
    if (!isSponsored()) {
      columns.push({
        key: "remittance",
        label: "",
        isDummyField: true,
        element: row => {
          const label = row.report_information
            .map(item => item.report_type)
            .includes("remittance")
            ? Translations.reports.tabAccounting.agencyStatement.remittance
            : "";
          return displayButton(row, "remittance", label);
        }
      });
    }
    return columns;
  };

  return (
    <div>
      <div className="accounting-title-ufg">{title}</div>
      <Table
        className="noHeaders"
        rowKey="id"
        data={documents}
        columns={getAllColumns()}
        itemsPerPage={15}
        showPagination
      />
    </div>
  );
};

DirectUfgCore.propTypes = {
  documents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  fetchAccountingStatementViewPDF: PropTypes.func,
  report_group: PropTypes.string,
  agency_code: PropTypes.string,
  source: PropTypes.string,
  billing_type: PropTypes.string
};

export default DirectUfgCore;
