import * as types from "../../constants/actionTypes";
import initialState from "../../reducers/initialState";

const agencySweepAccountBillingActivityReducer = (
  state = initialState.agencySweepAccountBillingActivity,
  action
) => {
  switch (action.type) {
    case types.COPY_ACCOUNT_BILLING_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        accountNumber: action.accountNumber,
        accountData: action.accountData,
        billingActivity: action.billingActivity
      };
    default:
      return state;
  }
};

export default agencySweepAccountBillingActivityReducer;
