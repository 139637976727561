import React from "react";
import PropTypes from "prop-types";
import { DateFormat } from "../components/Factory";
import sanitizeHtml from "sanitize-html";
import CMSContent from "../cms-content/CMSContent";

const BASE_LINK = window.env.REACT_APP_LEGACY_SITE_URL;
const FILE_DISPLAY_INLINE = "FI";
const FILE_ATTACHMENT = "FA";
const LINK_SAME_WINDOW = "LS";
const LINK_NEW_WINDOW = "LN";
const CUSTOM_MARKUP = "CU";

const sanitize = text =>
  sanitizeHtml(text, {
    allowedTags: ["b", "i", "em", "strong", "a"],
    allowedAttributes: {
      a: ["href", "target"]
    }
  });

const NewsMessage = props => {
  const { news } = props;

  const getFilePageUrl = entry_id_number =>
    `${BASE_LINK}/ufghandlers/WNFileView.ashx?WNEntryID=${entry_id_number}`;

  const getLinkPageUrl = (page_url, is_full_url) =>
    is_full_url ? page_url : `${BASE_LINK}${page_url}`;

  const getUrl = () => {
    switch (news.message_type_id) {
      case FILE_ATTACHMENT:
      case FILE_DISPLAY_INLINE:
        return getFilePageUrl(news.entry_id_number);
      case LINK_NEW_WINDOW:
      case LINK_SAME_WINDOW:
        return getLinkPageUrl(news.page_url, news.is_full_url);
      default:
        break;
    }
  };

  const target =
    news.message_type_id === FILE_DISPLAY_INLINE ||
    news.message_type_id === LINK_NEW_WINDOW
      ? "_blank"
      : "_self";

  return news.message_type_id === CUSTOM_MARKUP ? (
    <CMSContent
      id={`cms-content-news--${news?.entry_id_number}`}
      classNames=""
      content={sanitize(news?.entry_text)}
    />
  ) : (
    <a href={getUrl()} target={target}>
      {`${news.entry_text} -- (${DateFormat(news.publish_date, "MM/YY")})`}
    </a>
  );
};

NewsMessage.propTypes = {
  news: PropTypes.object.isRequired
};

export default NewsMessage;
