import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ufginsurance/ui-kit";
import * as routes from "../../../constants/routes";
import { toTitleCase } from "../../../components/Factory";
import DefaultErrorResponse from "../../../shared/error/DefaultErrorResponse";

export default function SubmissionError({
  error,
  history,
  updateQuickSubmissionError
}) {
  const errSource = error.response.data?.error_source?.toUpperCase();

  const STEPS = {
    ACCOUNT: 1,
    BOND: 4,
    ELIGIBILITY: 3,
    OBLIGEE: 4,
    OWNERS: 2
    // SUBMIT: 5, // generic error since user will already be on last step?
    // UNKNOWN: -1 // generic
  };

  const handleJumpToStep = () => {
    const step = STEPS[errSource];
    updateQuickSubmissionError(null);
    history.push(routes.setStepUrl(step));
  };

  if (errSource && errSource !== "SUBMIT" && errSource !== "UNKNOWN") {
    return (
      <span>
        Whoops! An error occurred. Please verify that{" "}
        <Button
          onClick={handleJumpToStep}
          isLink
          inline
          style={{
            fontWeight: "bold"
          }}
        >
          {toTitleCase(errSource)}
        </Button>{" "}
        screen data is correct.
      </span>
    );
  }

  return <DefaultErrorResponse />;
}

SubmissionError.propTypes = {
  error: PropTypes.object,
  history: PropTypes.any,
  updateQuickSubmissionError: PropTypes.func.isRequired
};
