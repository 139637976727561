import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { Panel, Currency, LoadingIndicator, Table } from "@ufginsurance/ui-kit";
import { usePolicyChangesContext } from "../context";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";
import * as routes from "../../../constants/routes";
import QuoteUnderwriterReviewErrorModal from "./QuoteUnderwriterReviewErrorModal";

const Step2DriverSummary = ({
  history,
  showStep2Loader,
  setShowStep2Loader,
  setPolicyDraft
}) => {
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const noResultsMessage = "No changes";
  const {
    policyData,
    quotePolicyChangeDriver,
    setPolicyInfo,
    QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE
  } = usePolicyChangesContext();
  const [showUnderwriterReviewErrorModal, setShowUnderwriterReviewErrorModal] =
    useState(false);

  const flattenPolicyDiff = useCallback(data => {
    const driverData = data.filter(d => d.change_label === "Drivers")[0];
    const flatData = [];
    driverData?.children.forEach(d => {
      flatData.push({
        id: v4(),
        name: d.change_label.replace("Driver: ", ""),
        type: d.operation.length > 0 ? d.operation : d.children[0].operation,
        fields:
          d.children.length > 0
            ? d.children.map(child => child.change_label)
            : [],
        values:
          d.children.length > 0 ? d.children.map(child => child.changed_to) : []
      });
    });
    return flatData;
  }, []);

  const columns = [
    { key: "name", label: "Driver Updates" },
    { key: "type" },
    {
      key: "fields",
      element: row =>
        row.fields.length > 0
          ? row.fields.map(field => {
              return <div key={field}>{field}</div>;
            })
          : ""
    },
    {
      key: "values",
      element: row =>
        row.values.length > 0
          ? row.values.map(value => {
              return <div key={value}>{value}</div>;
            })
          : ""
    }
  ];

  useEffect(() => {
    if (
      showStep2Loader ||
      policyData?.policyInfo?.policy_diff ||
      showUnderwriterReviewErrorModal
    ) {
      return;
    }

    const needToFetchQuote = !policyData?.policyInfo?.policy_diff;
    const canFetchQuote =
      policyData?.policyInfo?.policy_number &&
      policyData?.policyInfo?.job_id &&
      policyData?.policyInfo?.effective_date;

    if (!showStep2Loader && !error && needToFetchQuote && canFetchQuote) {
      const { policy_number, job_id, effective_date } = policyData?.policyInfo;
      setShowStep2Loader(true);
      quotePolicyChangeDriver(policy_number, job_id, effective_date)
        .then(res => {
          setPolicyInfo(res.data);
          setPolicyDraft({
            ...policyData.policyInfo,
            ...res.data
          });
          setError(false);
        })
        .catch(quoteError => {
          setError(true);
          if (
            quoteError?.response?.status ===
            QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE
          ) {
            setShowUnderwriterReviewErrorModal(true);
          } else {
            toast(<HelpDeskMessage display="anErrorOccurred" />, {
              className: "error",
              autoClose: false
            });
            // route to step one if error quoting
            history.push(
              `${routes.POLICY_CHANGES}/${policy_number}/jobId/${job_id}/step/1`
            );
          }
        })
        .finally(() => {
          setShowStep2Loader(false);
        });
    }
  }, [
    error,
    history,
    showStep2Loader,
    setShowStep2Loader,
    policyData?.policyInfo,
    quotePolicyChangeDriver,
    setPolicyInfo,
    showUnderwriterReviewErrorModal,
    QUOTE_UNDERWRITER_ERROR_RESPONSE_CODE,
    setPolicyDraft
  ]);

  useEffect(() => {
    if (policyData?.policyInfo?.policy_diff) {
      setTableData(flattenPolicyDiff(policyData?.policyInfo?.policy_diff));
    }
  }, [flattenPolicyDiff, policyData?.policyInfo?.policy_diff]);

  return (
    <>
      {showStep2Loader || !policyData?.policyInfo?.policy_diff ? (
        <LoadingIndicator message="Please wait while we quote your policy change." />
      ) : (
        <>
          <div id="policy-change-summary">
            <div id="summary-total">
              <span>
                <b>New Annual Premium</b>
              </span>
              <span id="newAnnualPremium" className="large-currency">
                <Currency
                  amount={policyData?.policyInfo?.adjusted_premium?.amount}
                />
              </span>
            </div>

            <Panel className="premium-summary" rounded title="Premium Summary">
              <ul className="line-items">
                <li className="line-item">
                  <span>Current Annual Premium</span>
                  <span>
                    <Currency
                      amount={policyData?.policyInfo?.current_premium?.amount}
                    />
                  </span>
                </li>
                <li className="line-item">
                  <span>Endorsed Annual Premium</span>
                  <span>
                    <Currency
                      amount={policyData?.policyInfo?.adjusted_premium?.amount}
                    />
                  </span>
                </li>
                <li className="line-item">
                  <span>
                    <b>Change in Annual Premium</b>
                  </span>
                  <span>
                    <b>
                      <Currency
                        amount={policyData?.policyInfo?.premium_change?.amount}
                      />
                    </b>
                  </span>
                </li>
              </ul>
            </Panel>
          </div>
          <div>
            <Panel title="Review Change" rounded>
              <Table
                id="policy-changes-summary"
                rowKey="id"
                columns={columns}
                data={tableData || []}
                initialSort="type"
                noResultsMessage={noResultsMessage}
              />
            </Panel>
          </div>
        </>
      )}
      <QuoteUnderwriterReviewErrorModal
        showUnderwriterReviewErrorModal={showUnderwriterReviewErrorModal}
        setShowUnderwriterReviewErrorModal={setShowUnderwriterReviewErrorModal}
        policyNumber={policyData?.policyInfo?.policy_number}
      />
    </>
  );
};

Step2DriverSummary.propTypes = {
  history: PropTypes.object,
  showStep2Loader: PropTypes.bool.isRequired,
  setShowStep2Loader: PropTypes.func.isRequired,
  setPolicyDraft: PropTypes.func.isRequired
};

export default withRouter(Step2DriverSummary);
