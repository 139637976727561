import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../../components/translations";

const hitRatioPageRedirect = selectedAgencyCode => {
  const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
  window.open(
    `${baseLink}/AgentsOnly/ufgAgent/Reports/AppQuoteStatus/wait.aspx?strPage=threeYear&agentCode=${selectedAgencyCode}`
  );
};

const HitRatio = ({ hasAgencyProductionReportsAccess, selectedAgencyCode }) => {
  return (
    <div>
      {selectedAgencyCode && selectedAgencyCode === "109015" ? (
        <div className="underwriting-error-message">
          {Translations.reports.internal_user}
        </div>
      ) : hasAgencyProductionReportsAccess ? (
        <div>{hitRatioPageRedirect(selectedAgencyCode)}</div>
      ) : (
        <div className="underwriting-error-message">
          {Translations.reports.error_message}
        </div>
      )}
    </div>
  );
};

HitRatio.propTypes = {
  selectedAgencyCode: PropTypes.string,
  hasAgencyProductionReportsAccess: PropTypes.bool
};

export default HitRatio;
