import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../translations";
import { toTitleCase, DateFormat, toYesNoCase } from "../../Factory";
import {
  Popover,
  DropdownNonForm,
  Icon,
  ExternalLink,
  Button
} from "@ufginsurance/ui-kit";
import AccountUwModal from "./AcountUwModal";
import { getServiceCenterUW } from "../../../services/agentService";

const PolicyCard = ({
  handlePolicyChange,
  selectedPolicyDetail,
  iconPolicyStatus,
  iconPolicyStatusColor,
  eDelivery,
  policyDetails,
  isSpecialtyPortalView,
  dba_name,
  isServiceCenterAccount,
  isUfgCore
}) => {
  const policyOptions = policyDetails.map(p => ({
    value: p.policy_number,
    label: p.policy_type
      ? `${p.policy_number} - ${toTitleCase(p.policy_type)}`
      : p.policy_number
  }));
  const [showUwModal, setShowUwModal] = useState(false);
  const [selectedUw, setSelectedUw] = useState(
    selectedPolicyDetail.underwriter
  );
  const [receivedSCUw, setReceivedSCUw] = useState(false);

  useEffect(() => {
    if (isServiceCenterAccount && isUfgCore) {
      getServiceCenterUW(selectedPolicyDetail?.agent_number).then(res => {
        if (res && res.data && res.data.length > 0) {
          setSelectedUw(res.data.find(uw => uw.title === "Account Specialist"));
          setReceivedSCUw(true);
        }
      });
    }
  }, [isServiceCenterAccount, isUfgCore, selectedPolicyDetail]);

  const useServiceCenterUW = isServiceCenterAccount && isUfgCore;

  const openUwModal = () => {
    setShowUwModal(true);
  };
  return (
    <>
      <div className="policy-card">
        <div className="address-margin">
          <div className="address-icon">
            <Icon icon="farFileAlt" />
          </div>
          <div className="row">&nbsp;</div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-xs-5 policy-info-label text-right">
                  {Translations.account_overview.policy.Policy_number}
                </div>
                <span className="policy-card-insured">
                  {policyOptions && policyOptions.length > 1 ? (
                    <div className="col-xs-7">
                      <DropdownNonForm
                        id="policy-card-insured-dropdown"
                        name="policy-card-insured-dropdown"
                        options={policyOptions}
                        value={selectedPolicyDetail.policy_number}
                        onChange={handlePolicyChange}
                        isClearable={false}
                        size="auto"
                        noLabel
                        compactIndicator
                      />
                    </div>
                  ) : (
                    <div className="col-xs-7 policy-info">
                      {selectedPolicyDetail.policy_type
                        ? `${
                            selectedPolicyDetail.policy_number
                          } - ${toTitleCase(selectedPolicyDetail.policy_type)}`
                        : `${selectedPolicyDetail.policy_number}`}
                    </div>
                  )}
                </span>
              </div>
              <div className="row">
                <div className="col-xs-5 policy-info-label text-right">
                  {Translations.account_overview.policy.insured}
                </div>
                <div className="col-xs-7 policy-info">
                  <div className="policy-card-insured policy-card-insured-name">
                    {selectedPolicyDetail.insured_name &&
                      selectedPolicyDetail.insured_name.toUpperCase()}
                  </div>
                </div>
              </div>
              {dba_name && (
                <div className="row">
                  <div className="col-xs-5 policy-info-label text-right">
                    {"DBA:"}
                  </div>
                  <div className="col-xs-7 policy-info">
                    <div className="policy-card-insured policy-card-insured-dba">
                      {!!dba_name && dba_name}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-xs-5 policy-info-label text-right">
                  {Translations.account_overview.policy.policy_term}
                </div>
                <div className="col-xs-7 policy-info">
                  {selectedPolicyDetail.effective_date &&
                    selectedPolicyDetail.effective_date &&
                    `${DateFormat(selectedPolicyDetail.effective_date)} to
                  ${DateFormat(selectedPolicyDetail.expiration_date)}`}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-5 policy-info-label text-right">
                  {Translations.account_overview.policy.status}
                </div>
                <div id="policy-info-status" className="col-xs-7 policy-info">
                  {!!iconPolicyStatus && (
                    <Icon
                      icon={iconPolicyStatus}
                      style={{
                        fontSize: "14px",
                        color: iconPolicyStatusColor,
                        marginRight: "5px"
                      }}
                    />
                  )}
                  {selectedPolicyDetail.status}
                </div>
              </div>

              {!isSpecialtyPortalView && (
                <div className="row">
                  <div className="col-xs-5 policy-info-label text-right">
                    {Translations.account_overview.policy.payment_plan}
                  </div>
                  <div className="col-xs-7 policy-info">
                    {selectedPolicyDetail.payment_plan}
                  </div>
                </div>
              )}

              {!isSpecialtyPortalView && (
                <AutomaticPayments
                  selectedPolicyDetail={selectedPolicyDetail}
                />
              )}

              {!isSpecialtyPortalView && (
                <div className="row">
                  <div className="col-xs-5 policy-info-label text-right">
                    {Translations.account_overview.policy.edelivery}
                  </div>
                  <div className="col-xs-7 policy-info">
                    {toYesNoCase(eDelivery)}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-xs-5 policy-info-label text-right">
                  Underwriter:
                </div>
                <div className="col-xs-7 policy-info">
                  {!!selectedUw?.email ? (
                    <Button
                      isLink
                      inline
                      onClick={openUwModal}
                      spinner={useServiceCenterUW && !receivedSCUw}
                    >
                      {useServiceCenterUW && !receivedSCUw ? (
                        <>Loading</>
                      ) : (
                        <>
                          {selectedUw?.first_name} {selectedUw?.last_name}
                        </>
                      )}
                    </Button>
                  ) : (
                    <span>Unavailable</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AccountUwModal
        showUwModal={showUwModal}
        setShowUwModal={setShowUwModal}
        underwriter={selectedUw}
      />
    </>
  );
};

PolicyCard.propTypes = {
  handlePolicyChange: PropTypes.func,
  selectedPolicyDetail: PropTypes.object.isRequired,
  policyDetails: PropTypes.array.isRequired,
  iconPolicyStatus: PropTypes.string.isRequired,
  iconPolicyStatusColor: PropTypes.string.isRequired,
  eDelivery: PropTypes.bool,
  isSpecialtyPortalView: PropTypes.bool,
  isServiceCenterAccount: PropTypes.bool,
  isUfgCore: PropTypes.bool
};

const isDirectBill = paymentPlan =>
  paymentPlan && paymentPlan === "Direct Bill";

// eslint-disable-next-line react/prop-types
const AutomaticPayments = ({ selectedPolicyDetail }) => {
  const url = window.env.REACT_APP_UFG_POLICYHOLDER_BASEURL;

  if (
    !selectedPolicyDetail ||
    !selectedPolicyDetail.payment_options ||
    !isDirectBill(selectedPolicyDetail.payment_plan)
  ) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-xs-5 policy-info-label text-right">
        {Translations.account_overview.policy.automatic_payments}
      </div>

      <div className="col-xs-7 policy-info">
        {selectedPolicyDetail.payment_options === "Enrolled" ? (
          selectedPolicyDetail.payment_options
        ) : (
          <Popover
            id="automatic-payments-popover"
            trigger="hover"
            theme="white"
            maxWidth={204}
            popoverClass="account-overview-popover-overlay"
            popoverContent={
              <span className="account-overview-popover">
                {Translations.formatString(
                  Translations.account_overview.account.popOverText,
                  <ExternalLink
                    to={window.env.REACT_APP_UFG_POLICYHOLDER_BASEURL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ufgpolicyholder.com
                  </ExternalLink>
                )}
              </span>
            }
            triggerContent={
              <a href={url} target="_blank" rel="noopener noreferrer">
                Not Enrolled
              </a>
            }
          />
        )}
      </div>
    </div>
  );
};

PolicyCard.propTypes = {
  selectedPolicyDetail: PropTypes.object.isRequired,
  dba_name: PropTypes.string
};

export default PolicyCard;
