import { connect } from "react-redux";
import ModalHOC from "../components/modal/ModalHOC";
import { dismissPolicyModal } from "../actions/policies";

const mapStateToProps = state => ({
  searchKeyword: state.policiesData.searchKeyword,
  show: state.policiesData.show,
  title: 'Search Results For "' + state.policiesData.searchKeyword + '"'
});

const mapDispatchToProps = dispatch => ({
  onHide: () => {
    dispatch(dismissPolicyModal);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHOC);
