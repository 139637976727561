import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";
import ErrorLevel from "../constants/errorLevel";
import ErrorCodes from "../constants/errorCodes";

const getError = (statusCode, section) => {
  if (section === "installment_schedule") {
    const errorMessage =
      Translations.installment_schedule.error_messages[statusCode];
    return errorMessage ? errorMessage : "";
  }

  switch (statusCode) {
    case ErrorCodes.BadRequest:
      return Translations.error_messages.error_400;
    case ErrorCodes.NotFound:
      return Translations.error_messages.error_404;
    case ErrorCodes.NotImplemented:
      return Translations.error_messages.error_501;
    case ErrorCodes.ServerError:
      return Translations.error_messages.error_500;
    case ErrorCodes.ServiceUnavailable:
      return Translations.error_messages.error_503;
    default:
      return Translations.error_messages.error_520;
  }
};

const getcssClass = level => {
  switch (level) {
    case ErrorLevel.verbose:
      return "alert-success";
    case ErrorLevel.info:
      return "alert-info";
    case ErrorLevel.warning:
      return "alert-warning";
    case ErrorLevel.danger:
      return "alert-danger";
    default:
      return "";
  }
};
const ErrorView = ({ level, statusCode, section }) => {
  const classLevel = getcssClass(level);
  return (
    <div>
      <p className={`alert ${classLevel} alertNoMargin`}>
        {getError(statusCode, section)}
      </p>
    </div>
  );
};

ErrorView.propTypes = {
  statusCode: PropTypes.number.isRequired,
  level: PropTypes.string.isRequired,
  section: PropTypes.string
};

export default ErrorView;
