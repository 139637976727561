import qs from "query-string";
import API from "../../api";
import { DateFormat } from "../../components/Factory";

export const getAppQuoteStatusList = (
  agencyCode,
  numberOfDays,
  status,
  type
) => {
  const queryString = qs.stringify({
    number_of_days: numberOfDays,
    status: !!status ? status : undefined,
    type: !!type ? type : undefined
  });
  return API.agent().get(
    `/reports/agencies/${agencyCode}/underwriting/quote-and-app-status?${queryString}`
  );
};

export const getAppQuoteStatusReport = request => {
  return API.agent().post(
    `/reports/agencies/${request.active_user_agency_code}/underwriting/quote-and-app-status/report`,
    request,
    { responseType: "blob" }
  );
};

export const getUnderwritingEmailList = agency_code => {
  return API.agent().get(
    `/agencies/${agency_code}/producers/feature-access/underwriting-reports`
  );
};

export const postInForceList = (data, agency_code) => {
  const formatted_data = {
    requestor_name: data.name,
    email: data.is109015Agent
      ? `${data.emailTo}@${window.env.REACT_APP_UFG_EMAIL_DOMAIN}`
      : data.emailTo,
    document_format: data.reportFormatTypes,
    begin_date: DateFormat(data.inForceDate, "YYYY-MM-DD"),
    order: Number(data.sortByList),
    business_type: Number(data.businessType),
    bill_type: Number(data.billType)
  };

  return API.agent()
    .post(
      `/reports/agencies/${agency_code}/underwriting/inforce`,
      formatted_data
    )
    .then(response => response.data);
};

export const postAgencyAudit = (data, agency_code) => {
  return API.agent()
    .post(`/reports/agencies/${agency_code}/underwriting/audit`, {
      requestor_name: data.name,
      email: data.is109015Agent
        ? `${data.emailTo}@${window.env.REACT_APP_UFG_EMAIL_DOMAIN}`
        : data.emailTo,
      document_format: data.reportFormatTypes,
      begin_date: DateFormat(data.beginDate, "YYYY-MM-DD"),
      end_date: DateFormat(data.endDate, "YYYY-MM-DD")
    })
    .then(response => response.data);
};
