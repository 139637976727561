import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export const ClaimsActivityColumnHeader = ({
  children,
  value,
  onClick,
  selectedSortField
}) => {
  const showSortCaret = value === selectedSortField;
  return (
    <button value={value} onClick={onClick} className="sort-button">
      {children}{" "}
      {showSortCaret && (
        <FontAwesomeIcon className="claims-sort-caret" icon={faCaretDown} />
      )}
    </button>
  );
};

ClaimsActivityColumnHeader.propTypes = {
  children: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedSortField: PropTypes.string.isRequired
};
