import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import HeaderTop from "../components/header/HeaderTop";
import HeaderMenu from "../components/header/HeaderMenu";
import HeaderUserMenuContainer from "./HeaderUserMenuContainer";
import { agencyNumberReducerUpdated } from "../actions/globalactivity";
import * as routes from "../constants/routes";
import { NAV_EVENT_TYPES } from "../constants/dictionary";
import QuotingHeader from "../online-quoting/QuotingHeader";
import QuotingHeaderContainer from "../quick-program/quick-quoting/QuotingHeaderContainer";
import ConsentOfSuretyHeader from "../surety/consent-of-surety/ConsentOfSuretyHeader";
import { withRouter } from "react-router-dom";
import { updateSuretyQuoteData } from "../quick-program/quick-quoting/actions/suretyQuoteActions";
import { getCacheId } from "../quick-program/selectors/cacheId";
import {
  getIsMercerSpecialtyBrokerPortalView,
  getisSpecialtyPortalView
} from "../portalview/changePortalViewStateSelectors";
import {
  changeToMercerSpecialtyPortalViewState,
  changeToUfgSpecialtyPortalViewState
} from "../portalview/changePortalViewStateAction";
import CommercialHeader from "../commercial/shared/CommercialHeader";

const HeaderContainer = props => {
  const { suretyConsentOfSuretyEnabled } = useFlags();
  const [navExpanded, setNavExpanded] = useState(false);
  const [userNavExpanded, setUserNavExpanded] = useState(false);

  const navEventRef = useRef({
    [NAV_EVENT_TYPES.MOUSE_ENTER]: "",
    [NAV_EVENT_TYPES.TAB]: ""
  });

  const {
    activeAgencyCode,
    handleAgentNumberUpdate,
    hasMarketingSolutionsAccess,
    is109015Agent,
    location,
    firstAndLastName,
    agentCode
  } = props;

  useEffect(() => {
    if (activeAgencyCode !== "109015") {
      handleAgentNumberUpdate(activeAgencyCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAgencyCode]);

  // used to toggle navigation on smaller screens
  const handleToggleNav = () => {
    setNavExpanded(!navExpanded);
  };

  // HeaderTop component used to make logo not clickable when

  return (
    <header className="header">
      <HeaderTop
        {...props}
        handleToggleNav={handleToggleNav}
        is109015Agent={is109015Agent}
        minimal={location.pathname.includes("/online_quoting")}
      />
      <Switch>
        <Route
          path={routes.QUICK_QUOTING}
          render={props => <QuotingHeaderContainer {...props} />}
        />
        {suretyConsentOfSuretyEnabled && (
          <Route
            path={routes.SURETY_CONSENT_OF_SURETY}
            render={props => <ConsentOfSuretyHeader {...props} />}
          />
        )}
        <Route
          path={routes.ONLINE_QUOTING}
          render={props => <QuotingHeader {...props} />}
        />
        <Route
          path={routes.BUILDERS_RISK}
          render={props => <CommercialHeader {...props} />}
        />
        <Route>
          <HeaderMenu
            navExpanded={navExpanded}
            setUserNavExpanded={setUserNavExpanded}
            userNavExpanded={userNavExpanded}
            handleToggleNav={handleToggleNav}
            hasMarketingSolutionsAccess={hasMarketingSolutionsAccess}
            navEventRef={navEventRef}
          >
            <HeaderUserMenuContainer
              setUserNavExpanded={setUserNavExpanded}
              userNavExpanded={userNavExpanded}
              navEventRef={navEventRef}
            />
          </HeaderMenu>
        </Route>
      </Switch>
      <span className="dynatrace__agency-and-username hidden">
        {`${agentCode} ${firstAndLastName}`}
      </span>
    </header>
  );
};

HeaderContainer.propTypes = {
  hasMarketingSolutionsAccess: PropTypes.bool,
  is109015Agent: PropTypes.bool,
  activeAgencyCode: PropTypes.string.isRequired,
  handleAgentNumberUpdate: PropTypes.func,
  quoteData: PropTypes.object,
  clearLocalQuote: PropTypes.func,
  step: PropTypes.number,
  location: PropTypes.object.isRequired,
  isSpecialtyPortalView: PropTypes.bool,
  firstAndLastName: PropTypes.string,
  agentCode: PropTypes.any
};

const mapStateToProps = state => {
  return {
    hasMarketingSolutionsAccess:
      sessionSelector.getHasMarketingSolutionsAccess(state),
    is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
    activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
    cacheId: getCacheId(state),
    isSpecialtyPortalView: getisSpecialtyPortalView(state),
    isMercerSpecialtyPortalView: getIsMercerSpecialtyBrokerPortalView(state),
    firstAndLastName: sessionSelector.getFirstAndLastName(state),
    agentCode: sessionSelector.getAgentCode(state)
  };
};

const mapDispatchToProps = {
  changeToMercer: changeToMercerSpecialtyPortalViewState,
  changeToUfgSpecialty: changeToUfgSpecialtyPortalViewState,
  handleAgentNumberUpdate: agencyNumberReducerUpdated,
  updateSuretyQuoteData
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
);
