import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";
import ProductSelectionModalOld from "./ProductSelectionModalOld";
import ProductSelectionModalNew from "./ProductSelectionModalNew";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";

// REDUX.
export const TOGGLE_PRODUCT_SELECTION_MODAL = "TOGGLE_PRODUCT_SELECTION_MODAL";
export const CLOSE_PRODUCT_SELECTION_MODAL = "CLOSE_PRODUCT_SELECTION_MODAL";

export const toggleProductSelectionModal = () => dispatch => {
  dispatch({
    type: TOGGLE_PRODUCT_SELECTION_MODAL
  });
};

export const closeProductSelectionModal = () => dispatch => {
  dispatch({
    type: CLOSE_PRODUCT_SELECTION_MODAL
  });
};

export const quotingProductSelection = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case TOGGLE_PRODUCT_SELECTION_MODAL:
      return {
        ...state,
        isOpen: !state?.isOpen
      };
    case CLOSE_PRODUCT_SELECTION_MODAL:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};

const mapState = state => ({
  agencyCode: getSelectedAgencyCode(state),
  isOpen: state?.quotingProductSelection?.isOpen
});

const ProductSelectionModal = props => {
  const { newQuotingProductSelectionFlowEnabled } = useFlags();

  if (newQuotingProductSelectionFlowEnabled) {
    return <ProductSelectionModalNew {...props} />;
  }
  return <ProductSelectionModalOld {...props} />;
};

export default connect(mapState, { toggleProductSelectionModal })(
  ProductSelectionModal
);
