import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Translations } from "../../components/translations";
import { LoadingIndicator, Button, Icon } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";

const MoratoriumAlert = ({
  fetchMoratoriumListings,
  isLoading,
  listings,
  history,
  quoteStarted,
  cancelQuote
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!Object.keys(listings).length) fetchMoratoriumListings();
  }, [fetchMoratoriumListings, listings]);

  const onClose = () => {
    setShow(false);
  };

  const goToMoratorium = () => {
    if (quoteStarted) cancelQuote();
    history.push(routes.MORATORIUM);
  };

  return (
    <>
      {listings && listings.news_message && listings.news_message.length > 0 && (
        <div className="moratorium_wrapper">
          {show && (
            <div className="moratorium-alert">
              <div className="moratorium-alert__icon">
                <Icon icon="fasExclamationCircle" />
              </div>
              <div className="moratorium-alert__message-group">
                {isLoading && <LoadingIndicator />}
                {listings.news_message &&
                  listings.news_message.map(message => (
                    <div key={v4()} className="notification">
                      <p className="moratorium-alert__title">
                        {Translations.alert.message}
                      </p>
                      <p>{message}</p>
                      <Button isLink onClick={goToMoratorium}>
                        {Translations.alert.more_information}
                      </Button>
                    </div>
                  ))}
                {listings.show_more_message && (
                  <div className="notification moreInfo">
                    {Translations.alert.more_moratoriums_message}{" "}
                    <Button isLink onClick={goToMoratorium}>
                      {Translations.alert.more_information}
                    </Button>
                  </div>
                )}
              </div>
              <button
                className="moratorium-alert__close"
                onClick={onClose}
                onKeyPress={onClose}
                aria-label="close moratorium alert"
              >
                {Translations.general.close_x}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MoratoriumAlert;

MoratoriumAlert.propTypes = {
  fetchMoratoriumListings: PropTypes.func.isRequired,
  listings: PropTypes.object,
  isLoading: PropTypes.bool,
  quoteStarted: PropTypes.bool,
  cancelQuote: PropTypes.func,
  history: PropTypes.any
};
