import API from "../../api";
import moment from "moment";
import { logger } from "../../loggers";

export const getAgencyBillPayNowEnrollment = agency_code => {
  return API.agent().get(`/agencies/${agency_code}/payment-method-token/AB`);
};

export const getAgencyBillPayNow = agency_code => {
  return API.agent().get(`/billing/agency-bill/${agency_code}/staged-payment`);
};

export const getGuidewireAgencyStatementOptions = (
  agency_code,
  statementOptions
) => {
  return new Promise((resolve, reject) => {
    API.agent()
      .get(`/billing/agency-bill/${agency_code}/statement`)
      .then(res => {
        const totalAmountDue =
          res.data?.billing_statement?.reduce(
            (accumulator, item) =>
              accumulator + (item?.amount_due?.amount ?? 0),
            0
          ) ?? 0;
        if (totalAmountDue === 0) {
          reject(false);
        } else {
          const lastMonth = moment().subtract(1, "months");
          const mostRecentOption = statementOptions.find(
            option => option.month === lastMonth.format("MM")
          );
          resolve([mostRecentOption]);
        }
      })
      .catch(_err => {
        logger.error(_err.toString());
        reject(true);
      });
  });
};
