import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ClientNoticesTable from "./ClientNoticesTable";
import { Translations } from "../../components/translations";
import {
  LoadingIndicator,
  FormGroup,
  Form,
  useForm,
  DateRange,
  Alert,
  formatDate
} from "@ufginsurance/ui-kit";

const ClientNotices = React.memo(
  ({
    agencyCode,
    fetchClientNotices,
    clientNoticesList,
    clientNoticesError,
    clientNoticesLoading,
    removeClientNotice,
    deleteClientNoticeError,
    resetClientNotices,
    fetchClientNoticeDocument,
    is109015Agent,
    activeSpecialtyCompanyBranch
  }) => {
    // TODO: I'm not really sure why this is needed
    useEffect(() => {
      resetClientNotices();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 60);
    const maxDate = new Date();

    const initialFromDate = new Date();
    initialFromDate.setDate(initialFromDate.getDate() - 8);

    const initialValues = {
      clientNoticesRange: { from: initialFromDate, to: maxDate }
    };

    const form = useForm({ values: initialValues });
    const { errors, values, handleOnBlur, handleOnValidate, updateForm } = form;

    const handleSubmit = useCallback(() => {
      const formattedBeginDate = formatDate(
        values.clientNoticesRange.from,
        "YYYY-MM-DD"
      );
      const formattedEndDate = formatDate(
        values.clientNoticesRange.to,
        "YYYY-MM-DD"
      );
      fetchClientNotices(
        agencyCode,
        formattedBeginDate,
        formattedEndDate,
        activeSpecialtyCompanyBranch
      );
    }, [
      values.clientNoticesRange.from,
      values.clientNoticesRange.to,
      agencyCode,
      fetchClientNotices,
      activeSpecialtyCompanyBranch
    ]);

    useEffect(() => {
      const errorKeys = Object.keys(errors || {});
      const hasErrors = errorKeys && errorKeys.length > 0;
      if (
        !hasErrors &&
        agencyCode !== "109015" &&
        values.clientNoticesRange.from &&
        values.clientNoticesRange.to
      ) {
        handleSubmit();
      }
    }, [
      errors,
      agencyCode,
      values.clientNoticesRange.from,
      values.clientNoticesRange.to,
      handleSubmit
    ]);

    const removeClientNoticeItem = clientStatusItem => () => {
      const formattedBeginDate = formatDate(
        values.clientNoticesRange.from,
        "YYYY-MM-DD"
      );
      const formattedEndDate = formatDate(
        values.clientNoticesRange.to,
        "YYYY-MM-DD"
      );

      removeClientNotice(
        agencyCode,
        clientStatusItem,
        formattedBeginDate,
        formattedEndDate
      );
    };

    const handleRangeChange = field => {
      const validatinErrors = rangeValidation(field, field.value);
      const errorKeys = Object.keys(validatinErrors || {});
      const hasErrors = errorKeys && errorKeys.length > 0;
      updateForm({
        values: {
          ...values,
          clientNoticesRange: field.value
        },
        errors: {
          clientNoticesRange: hasErrors
            ? [Translations.commercial_tab.date_range_exceeds_error]
            : []
        }
      });
    };

    const rangeValidation = ({ field, value, event }) => {
      const errors = handleOnValidate({ field, value, event });

      // date range should not exceed 30 days
      if (!!value.from && !!value.to) {
        const f = new Date(value.from);
        const t = new Date(value.to);
        if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) > 30) {
          errors.push(Translations.commercial_tab.date_range_exceeds_error);
        }
      }
      return errors;
    };

    return (
      <div className="clientNoticesWrapper">
        <div className="clientNotices__form">
          <Form context={form}>
            <FormGroup>
              <DateRange
                id="clientNoticesRange"
                name="clientNoticesRange"
                className="client-notices-range"
                labelFrom={
                  Translations.reports.tabAgency.experienceDetailTab.label
                    .beginDate
                }
                labelTo={
                  Translations.reports.tabAgency.experienceDetailTab.label
                    .endDate
                }
                onChange={handleRangeChange}
                onBlur={handleOnBlur}
                onValidate={rangeValidation}
                value={values.clientNoticesRange}
                format="MM/DD/YYYY"
                required
                minDate={minDate}
                maxDate={maxDate}
              />
            </FormGroup>
          </Form>
          {
            // show text that 109015 users must enter code to search
            agencyCode === "109015" && (
              <Alert type="plain" dismissible={false}>
                {
                  Translations.commercial_tab
                    .home_agent_client_notices_fail_error_message
                }
              </Alert>
            )
          }
          {
            // if props includes an error, display it
            clientNoticesError && (
              <Alert type="warning">{clientNoticesError}</Alert>
            )
          }
        </div>
        {clientNoticesLoading && <LoadingIndicator />}
        {deleteClientNoticeError && (
          <Alert type="error">{deleteClientNoticeError}</Alert>
        )}
        {clientNoticesList.length ? (
          <ClientNoticesTable
            clientNoticesList={clientNoticesList}
            removeClientNotice={removeClientNoticeItem}
            fetchClientNoticeDocument={fetchClientNoticeDocument}
            is109015Agent={is109015Agent}
          />
        ) : null}
      </div>
    );
  }
);

export default ClientNotices;

ClientNotices.propTypes = {
  agencyCode: PropTypes.string,
  fetchClientNotices: PropTypes.func.isRequired,
  clientNoticesList: PropTypes.array,
  clientNoticesError: PropTypes.string,
  clientNoticesLoading: PropTypes.bool,
  removeClientNotice: PropTypes.func,
  deleteClientNoticeError: PropTypes.string,
  resetClientNotices: PropTypes.func,
  fetchClientNoticeDocument: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool,
  activeSpecialtyCompanyBranch: PropTypes.string
};
