import { floridaSelectedLocationWindHailIsNotApplicable } from "../../step5/building/floridaDetails";
/**
 * APPLIES TO A SINGLE LOCATION
 *
 * this custom coverage control logic applies to a single location and should be loaded
 * in a way that the location property is defined
 *
 */

export const locationCoverageControls = ({
  location,
  quoteData,
  supportingData
}) => {
  const coverageControl = [];

  const locationCoverages = (
    quoteData?.lobData?.bp7BusinessOwners?.coverages?.locationClausesGroups ||
    []
  ).find(c => c.coverableFixedId === location.bp7LocationFixedID);

  // start applying custom rules

  // on location coverage : "Property Deductibles"
  // the term "Windstorm or Hail Percentage Deductible"
  // ...should not display the options with the value of "Not Applicable" or "1%"
  // when:
  // 1: location is in a matching county
  // AND
  // 2: either: location has a building that has the building coverage
  //        or: location has a building with BPP coverage

  const currentStateWindHaleRules =
    BP7WindHailDedRules?.[location?.address?.state] || [];

  // PC doesn't always include the word "County" at the end of the county name, so match both
  const locationHasCountyRules =
    currentStateWindHaleRules.includes(location?.address?.county) ||
    currentStateWindHaleRules.includes(`${location?.address?.county} County`);

  // hasLouisianaCoastalRules https://ufginsurance.atlassian.net/browse/OOQ-14001
  const hasLouisianaCoastalRules = louisianaCoastalRules({ location });

  if (
    locationHasCountyRules &&
    (locationHasBuildingWithBuildingCoverage({ location, quoteData }) ||
      locationHasBuildingWithBPPCoverage({ location, quoteData }))
  ) {
    // find the coverage and term that we're wanting to change
    const coverage = (locationCoverages?.clausesGroups?.defaults || []).find(
      c => c.codeIdentifier === "BP7LocationPropertyDeds"
    );
    const term = (coverage.terms || []).find(
      t => t.publicID === "BP7WindHailDed"
    );

    // only proceed if the coverage and term exist
    if (term) {
      const optionControls = [
        {
          name: "Not Applicable",
          code: "zubjsf6tqqao22j54d851tsef48", // "Not Applicable"
          control: "hidden"
        },
        {
          name: "1%",
          code: "zl1jmja88chnv4hugk7rkdvimfb", // "1%"
          control: "hidden"
        }
      ];

      if (hasLouisianaCoastalRules)
        optionControls.push({
          name: "5%",
          code: "z12ji7silh8so4fgjc8nb1r3g4a", // "5%"
          control: "hidden"
        });

      coverageControl.push({
        codeIdentifier: "BP7LocationPropertyDeds", // "Property Deductibles"
        control: "visible",
        termControl: [
          {
            patternCode: "BP7WindHailDed", // "Windstorm or Hail Percentage Deductible"
            control: "visible",
            options: optionControls.filter(o => o.code !== term.chosenTerm)
          }
        ]
      });
    }
  }

  // Hide windstorm or hail percentage deductibles https://ufginsurance.atlassian.net/browse/OOQ-9764
  coverageControl.push({
    codeIdentifier: "BP7WindHailDeds",
    control: "hidden"
  });

  const BP7LocationPropertyDeds = {
    codeIdentifier: "BP7LocationPropertyDeds",
    control: "visible",
    termControl: [
      {
        name: "Optional Deductible",
        patternCode: "BP7OptionalDed",
        control: "visible",
        lessThanOptions: [
          {
            name: "1,000",
            control: "hidden"
          }
        ]
      }
    ]
  };

  /**
   * FLORIDA WINDHAIL - disable WindStorm term:
   * if value is "Not Applicable" and...
   * the SupportingData for location[locationId].floridaWindHail has a value (user sets this when creating a building)
   * https://ufginsurance.atlassian.net/browse/OOQ-14835
   *
   */

  const BP7LocationPropertyDeds_BP7WindHailDed_readonly =
    location?.address?.state === "FL" &&
    floridaSelectedLocationWindHailIsNotApplicable({
      quoteData,
      location
    }) &&
    supportingData?.locations?.[location?.fixedID]?.floridaWindHail !==
      undefined;

  if (BP7LocationPropertyDeds_BP7WindHailDed_readonly) {
    BP7LocationPropertyDeds.termControl.push({
      name: "Windstorm or Hail Percentage Deducible",
      patternCode: "BP7WindHailDed",
      control: "readonly"
    });
  }

  coverageControl.push(BP7LocationPropertyDeds);

  return { formData: { coverageControl } };
};

/**
 *
 * HELPER FUNCTIONS FOR CONTROL DELIVERY
 *
 */

const locationHasBuildingWithBuildingCoverage = ({ location, quoteData }) => {
  const buildings = location?.buildings;

  // if there are no buildings, then return false
  if (!buildings) return false;

  const buildingIds = buildings.map(b => b.fixedId);

  const buildingCoverages =
    quoteData?.lobData?.bp7BusinessOwners?.coverages?.buildingClausesGroups ||
    [];

  return buildingCoverages.some(
    c =>
      // coverage on building in this location
      buildingIds.includes(c.coverableFixedId) &&
      // "Building" coverage is selected
      (c?.clausesGroups?.coverages || []).find(
        coverage =>
          coverage.publicID === "BP7StructureBuilding" && coverage.selected
      )
  );
};

const locationHasBuildingWithBPPCoverage = ({ location, quoteData }) => {
  const buildings = location?.buildings;

  // if there are no buildings, then return false
  if (!buildings) return false;

  const classificationIds = buildings.reduce((acc, curr) => {
    const classifications = curr.classifications || [];
    acc.push(...classifications.map(c => c.fixedId));
    return acc;
  }, []);

  const bp7clausesGroups =
    quoteData?.lobData?.bp7BusinessOwners?.coverages
      ?.classificationClausesGroups || [];

  const classCoveragesOnBuilding = bp7clausesGroups.filter(c =>
    classificationIds.includes(c.coverableFixedId)
  );

  return classCoveragesOnBuilding.some(c =>
    c.clausesGroups.coverages.some(i => i.name === "Business Personal Property")
  );
};

const louisianaCoastalRules = ({ location }) => {
  // is Louisana and location has buildings
  return location?.address?.state === "LA" && location?.buildings?.length;
};

const BP7WindHailDedRules = {
  TX: [
    "Andrews County",
    "Armstrong County",
    "Bailey County",
    "Bee County",
    "Borden County",
    "Briscoe County",
    "Brooks County",
    "Carson County",
    "Castro County",
    "Childress County",
    "Cochran County",
    "Collin County",
    "Collingsworth County",
    "Concho County",
    "Crane County",
    "Crosby County",
    "Dallam County",
    "Dallas County",
    "Dawson County",
    "Dear Smith County",
    "Denton County",
    "Dickens County",
    "Donley County",
    "Ector County",
    "Ellis County",
    "Floyd County",
    "Fort Bend County",
    "Gaines County",
    "Garza County",
    "Goliad County",
    "Gray County",
    "Hale County",
    "Hall County",
    "Hansford County",
    "Hardin County",
    "Harris County",
    "Hartley County",
    "Hemphill County",
    "Hidalgo County",
    "Hockley County",
    "Hood County",
    "Howard County",
    "Hunt County",
    "Hutchinson County",
    "Jackson County",
    "Jim Wells County",
    "Johnson County",
    "Kaufman County",
    "Kent County",
    "Lamb County",
    "Liberty County",
    "Lipscomb County",
    "Live Oak County",
    "Lubbock County",
    "Lynn County",
    "Martin County",
    "Midland County",
    "Moore County",
    "Motley County",
    "Ochiltree County",
    "Oldham County",
    "Orange County",
    "Parker County",
    "Parmer County",
    "Pecos County",
    "Potter County",
    "Randall County",
    "Refugio County",
    "Roberts County",
    "Rockwall County",
    "Runnels County",
    "Scurry County",
    "Sherman County",
    "Swisher County",
    "Tarrant County",
    "Terry County",
    "Tom Green County",
    "Victoria County",
    "Wharton County",
    "Wheeler County",
    "Wise County",
    "Yoakum County"
  ],
  CO: [
    "Adams County",
    "Arapahoe County",
    "Baca County",
    "Bent County",
    "Boulder County",
    "Cheyenne County",
    "Crowley County",
    "Denver County",
    "Douglas County",
    "El Paso County",
    "Elbert County",
    "Jefferson County",
    "Kiowa County",
    "Kit Carson County",
    "Larimer County",
    "Las Animas County",
    "Lincoln County",
    "Logan County",
    "Morgan County",
    "Otero County",
    "Phillips County",
    "Prowers County",
    "Pueblo County",
    "Sedgwick County",
    "Teller County",
    "Washington County",
    "Weld County",
    "Yuma County"
  ],
  LA: ["East Baton Rouge Parish", "West Baton Rouge Parish"]
};
