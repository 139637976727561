import React from "react";
import { toast } from "react-toastify";
import { logger } from "../../../loggers";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

export default function ToastError(
  error,
  isFatal = false,
  showMessage = false
) {
  logger.error({
    description: error.toString(),
    fatal: isFatal
  });

  const options = {
    position: "top-center",
    className: "error",
    autoClose: false
  };
  toast(
    showMessage ? (
      error?.message ?? ""
    ) : (
      <HelpDeskMessage display="anErrorOccurred" />
    ),
    options
  );
}
