import * as types from "../../quickQuotingActionTypes";
import * as quickQuotingServices from "../../quickQuotingServices";
import { logger } from "../../../../../src/loggers";

/*--------UPLOAD SURETY QUICK DOCUMENTS---------- */
const postQuickDocumentsStarted = () => ({
  type: types.POST_QUICK_DOCS_STARTED
});

const postQuickDocumentsSucceeded = data => ({
  type: types.POST_QUICK_DOCS_SUCCEEDED,
  payload: data
});

const postQuickDocumentsFailed = error => ({
  type: types.POST_QUICK_DOCS_FAILED,
  payload: error
});

export const postQuickDocuments = data => dispatch => {
  dispatch(postQuickDocumentsStarted());

  return quickQuotingServices.postSuretyQuoteDocuments(data).then(
    response => {
      dispatch(postQuickDocumentsSucceeded(response.data));
    },
    error => {
      dispatch(postQuickDocumentsFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
