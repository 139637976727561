import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

export const getisSpecialtyPortalView = state => {
  const onlySpecialtyAccess =
    sessionSelector.getHasBrokerAccess(state) &&
    !sessionSelector.getHasUfgAgentAccess(state);

  if (onlySpecialtyAccess) {
    return true;
  }

  const onlyUfgAccess =
    !sessionSelector.getHasBrokerAccess(state) &&
    sessionSelector.getHasUfgAgentAccess(state);

  if (onlyUfgAccess) {
    return false;
  }

  return (
    state &&
    state.portalViewState &&
    state.portalViewState.isSpecialtyPortalView
  );
};

export const getIsUfgSpecialtyBrokerPortalView = state => {
  return (
    getisSpecialtyPortalView(state) &&
    state.portalViewState.isUfgSpecialtyBrokerPortalView
  );
};

export const getIsMercerSpecialtyBrokerPortalView = state =>
  getisSpecialtyPortalView(state) &&
  !state.portalViewState.isUfgSpecialtyBrokerPortalView;

export const hasAccessToUfgAgentAndSpecialty = state => {
  return (
    sessionSelector.getHasBrokerAccess(state) &&
    sessionSelector.getHasUfgAgentAccess(state)
  );
};

export const getActiveSpecialtyCompanyBranch = state => {
  const isSpecialtyPortalView = getisSpecialtyPortalView(state);
  const isMercerSpecialtyBrokerPortalView =
    getIsMercerSpecialtyBrokerPortalView(state);
  return isMercerSpecialtyBrokerPortalView
    ? window.env.REACT_APP_MERCER_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH
    : !isMercerSpecialtyBrokerPortalView && isSpecialtyPortalView
    ? window.env.REACT_APP_SPECIALTY_PORTAL_VIEW_COMPANY_BRANCH
    : null;
};

export const getIsSuretyOnlyAgency = state => {
  return (
    !sessionSelector.agencyHasCommercialLinesContract(state)
    && !sessionSelector.agencyHasPersonalLinesContract(state)
    && sessionSelector.agencyHasSuretyContract(state)
  );
};