import "./FeedbackForm.scss";

import React, { useContext } from "react";
import { Button, Form, FormGroup, TextArea, useForm } from "@ufginsurance/ui-kit";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { toast } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

import * as api from "../../services/onlineQuotingService";
import * as ufgagentWebApi from "../../../src/your-ufg-team/services/yourUfgTeamService.js";
import OnlineQuotingContext from "../OnlineQuotingContext";
import OqModal from "./OqModal";

const FeedbackForm = ({
  show,
  onHide,
  agentEmail,
  agencyNumber,
  agentName,
  agency_name,
  employeeUsername,
  primaryState
}) => {
  const { proQuoteFeedbackEmailList } = useFlags();
  const {
    quoteData,
    step,
    showUpdatingToast,
    closeUpdatingToast,
    quoteIsUpdating
  } = useContext(OnlineQuotingContext);

  const initialValues = { emailBody: "" };

  const handleFormSubmit = async () => {
    /**
     * if we have an active quote, then get the UW from the quoteData
     */
    let underwriter = "";
    if (quoteData?.baseData) {
      const { displayName: uwName, email: uwEmail } =
        quoteData?.baseData?.underwriter_UFG;
      underwriter = `${uwName} (${uwEmail})`;
    } else {
      // call the UW contact api and get the UfgOnline UW
      const uwContact = await ufgagentWebApi
        .getUfgTeamContactsData(agencyNumber, "UfgOnline")
        .then(response => response?.data || [])
        .catch(e => {
          console.error(e);
          return [];
        });
      const uw = uwContact.find(
        c => c.sub_heading.includes("UFG Online") && c.title === "Underwriter"
      );
      underwriter = uwContact
        ? `${uw.first_name} ${uw.last_name} (${uw.email})`
        : "";
    }

    const payload = {
      emailAddresses: proQuoteFeedbackEmailList?.emailAddresses,
      ccAddresses: proQuoteFeedbackEmailList?.ccAddresses,
      bccAddresses: proQuoteFeedbackEmailList?.bccAddresses,
      subject: "ProQuote Feedback",
      message:
        `<br/>Agent Email: ${agentEmail}` +
        `<br/>Agency Number: ${agencyNumber}` +
        `<br/>Quote Step Number: ${step}` +
        `<br/>Quote Number: ${quoteData?.quoteID || ""}` +
        `<br/>Agent Name: ${agentName}` +
        `<br/>Agency Name: ${agency_name}` +
        `<br/>Employee Username: ${employeeUsername}` +
        `<br/>Primary State: ${primaryState}` +
        `<br/>Online Underwriter: ${underwriter}` +
        `<br/>Message: ${values.emailBody}`
    };

    const message = "Sending feedback...";
    showUpdatingToast({ message });
    api.getSendEmail({ payload }).then(() => {
      closeUpdatingToast({ message });
      toast(
        "We genuinely appreciate you taking the time to send us feedback. \n" +
          "Your feedback allows us to make our products better every day.",
        {
          position: "top-center",
          className: "success"
        }
      );
      onHide(false);
    });
  };

  const form = useForm({ values: initialValues, onSubmit: handleFormSubmit });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields
  } = form;

  return (
    <div>
      {show && (
        <OqModal
          className="oq__modal__coverable__driver"
          title={<h3>Provide feedback to the UFG product team</h3>}
          show={show}
          size="md"
          onHide={onHide}
          body={
            <Form className="oq__form__feedback__step1 oq__form" context={form}>
              <FormGroup groupErrors>
                <TextArea
                  id="emailBody"
                  name="emailBody"
                  label="Feedback Message"
                  noLabel
                  requiredError="A message is required in order to submit."
                  className={"oq__formgroup__feedback__emailBody"}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  placeholder="Your feedback will be sent directly to our product team to improve your experience. What can we do better?"
                  value={values.emailBody}
                  required
                />
              </FormGroup>

              <FormGroup align="right">
                <Button
                  variant="plain"
                  onClick={() => {
                    onHide();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  disabled={invalidFields.length > 0 || quoteIsUpdating}
                  onClick={() => handleFormSubmit()}
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  agentEmail: sessionSelector.getEmail(state),
  agencyNumber: sessionSelector.getActiveAgencyCode(state),
  agentName: sessionSelector.getFirstAndLastName(state),
  agency_name: sessionSelector.getActiveAgency(state).name,
  employeeUsername: state?.oidc?.user?.profile?.employeeUsername,
  primaryState: sessionSelector.getActiveAgencyState(state)
});

export default connect(mapStateToProps, {})(FeedbackForm);
