import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Panel,
  LoadingIndicator,
  Table,
  FormatDate,
  Button,
  Anchor
} from "@ufginsurance/ui-kit";
import { billingCopiesTransactionTypes } from "../../../constants/dictionary.js";
import { Eportal } from "../../shared/eportal";
import { openPopupWindow } from "../../shared/utils";
import { useSuretyContext } from "../../surety-context/SuretyContext.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchBondExecution } from "../../shared/documents";

export default function AccountOverviewBillingActivityPanel({
  isLoading,
  error,
  list,
  account,
  agencyCode
}) {
  const [bondFormsLoading, setBondFormsLoading] = useState({});
  const { updateBondOverview } = useSuretyContext();
  const { suretyBondOverviewEnabled } = useFlags();

  const getBondExecutionForm = (bond_number, transaction_number) => {
    setBondFormsLoading({ ...bondFormsLoading, [transaction_number]: true });
    fetchBondExecution(bond_number, agencyCode, null, transaction_number).then(
      () => {
        const tempBondFormsLoading = { ...bondFormsLoading };
        delete tempBondFormsLoading[transaction_number];
        setBondFormsLoading(tempBondFormsLoading);
      }
    );
  };
  const columnsBillingActivity = [
    {
      key: "entry_date",
      label: "Date",
      sortable: true,
      element: _ => {
        return <FormatDate date={_.entry_date} format="MM/DD/YYYY" />;
      }
    },
    {
      key: "bond_number",
      label: "Bond No",
      sortable: true,
      className: "bond_number_column",
      element: ({ bond_number }) => (
        <Anchor
          target="_blank"
          className="nowrap"
          variant="black"
          onClick={() => {
            if (suretyBondOverviewEnabled) {
              updateBondOverview({ bond_number, showModal: true });
            } else {
              openPopupWindow(Eportal.getBondInfoLink(bond_number));
            }
          }}
        >
          {bond_number}
        </Anchor>
      )
    },
    {
      key: "transaction_code",
      label: "Activity Type",
      sortable: true,
      element: row => {
        return (
          <span>{billingCopiesTransactionTypes[row.transaction_code]}</span>
        );
      }
    },
    {
      key: "",
      label: "",
      sortable: false,
      align: "right",
      element: _ => {
        return (
          <Button
            isLink
            inline
            spinner={bondFormsLoading.hasOwnProperty(_.transaction_number)}
            size="sm"
            onClick={() =>
              getBondExecutionForm(_.bond_number, _.transaction_number)
            }
          >
            Transaction Report
          </Button>
        );
      }
    }
  ];

  return (
    <Panel
      className="account-overview__billing-activity"
      bgcolor="grey"
      titlebar
      title="Billing Activity"
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Table
            rowKey="transaction_number"
            columns={columnsBillingActivity}
            data={
              error || !list
                ? []
                : list.filter(a => a.account_number === account?.account_number)
            }
            showPagination
            itemsPerPage={10}
            noResultsMessage="No billing activity found"
            initialSort="entry_date"
            initialDirection="desc"
          />
        </>
      )}
    </Panel>
  );
}

AccountOverviewBillingActivityPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  list: PropTypes.array,
  account: PropTypes.object.isRequired,
  agencyCode: PropTypes.string.isRequired
};
