import { connect } from "react-redux";
import MyProfile from "./MyProfile";
import {
  sessionSelector,
  ssoActions
} from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  agentCode: sessionSelector.getAgentCode(state),
  token: state?.oidc?.user?.access_token
});

const mapDispatchToProps = {
  rehydrateSession: ssoActions.rehydrateSession
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
