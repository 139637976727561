import { useEffect, useRef, useState } from "react";
import { logger } from "../../../loggers";
import { getLocationRatingData } from "../../shared/services";

export default function useGetLocationRatingData(address) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const error = useRef(false);

  const handleError = () => {
    error.current = true;
    setTimeout(() => (error.current = false), 200);
  };

  const chain = item => {
    return Array.isArray(item) && item.length ? item[0] : null;
  };

  const canCheckAddress = (a, d) => {
    const isComplete =
      (a?.street_address || "").length > 0 &&
      (a?.city || "").length > 0 &&
      (a?.zip || "").length === 5 &&
      (a?.state || "").length === 2;
    if (!isComplete) return false;
    const standardize = _ => (_ || "").toUpperCase().trim();
    const matchesPrior =
      standardize(a?.street_address) ===
        standardize(d?.address?.street_address) &&
      standardize(a?.city) === standardize(d?.address?.city) &&
      standardize(a?.state) === standardize(d?.address?.state) &&
      standardize(a?.zip) === standardize(d?.address?.zip);
    return !matchesPrior;
  };

  useEffect(() => {
    if (!canCheckAddress(address, data)) return;
    if (isLoading || error.current) return;
    setIsLoading(true);
    getLocationRatingData(address)
      .then(res => {
        if (res.status === 200 && res.data) {
          const loc = chain(res.data?.reports)?.report?.location;
          const detail = chain(
            (loc?.details ?? []).sort((a, b) =>
              a.percent >= b.percent ? -1 : 1
            )
          );
          const suggestedProtectionClasses = detail?.value ?? [];
          const suggestedProtectionClass =
            suggestedProtectionClasses.length > 0
              ? suggestedProtectionClasses[0]
              : "";
          setData({
            address: {
              street_address: `${loc?.street_number?.trim() ?? ""} ${
                loc?.street_name?.trim() ?? ""
              } ${loc?.street_type?.trim() ?? ""}`,
              city: loc?.city ?? "",
              state: loc?.state ?? "",
              zip: loc?.zip ?? ""
            },
            suggested_protection_class: suggestedProtectionClass
          });
        } else {
          setData(null);
          handleError();
        }
      })
      .catch(err => {
        handleError();
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [address]);

  return [data, isLoading];
}
