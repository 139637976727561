import React, { useContext, useState } from "react";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import { Button, FlexRow, Modal, Tabs, Tab } from "@ufginsurance/ui-kit";
import StoreQuotesLocallySavedList from "./StoreQuotesLocallySavedList";
import StoreQuotesLocallyRecentList from "./StoreQuotesLocallyRecentList";
import QuotingUfgHelperContext from "../QuotingUfgHelperContext";
import "./StoreQuotesLocally.scss";
import { initDB } from "react-indexed-db-hook";

initDB({
  name: "ProQuoteDevData",
  version: 4,
  objectStoresMeta: [
    {
      store: "quotes",
      storeSchema: []
    },
    {
      store: "recent",
      storeSchema: []
    }
  ]
});

const StoreQuotesLocally = () => {
  const { supportingData } = useContext(OnlineQuotingContext);

  const { savedQuotes, recentQuotes, currentQuoteInStorage, saveQuote } =
    useContext(QuotingUfgHelperContext);

  const [showLoadModal, setShowLoadModal] = useState();

  return (
    <div>
      <FlexRow>
        <Button
          block
          size="sm"
          onClick={saveQuote}
          disabled={!supportingData?.quoteNumber}
        >
          Save
        </Button>
        <Button
          block
          size="sm"
          onClick={() => setShowLoadModal(true)}
          disabled={savedQuotes?.length === 0 && recentQuotes?.length === 0}
        >
          Load
        </Button>
      </FlexRow>
      {currentQuoteInStorage && (
        <div>
          Last Saved:{" "}
          {new Date(currentQuoteInStorage?.timestamp).toLocaleString()}
        </div>
      )}
      {showLoadModal && (
        <Modal
          title="Quotes in Local Storage"
          show
          size="lg"
          onHide={() => setShowLoadModal(false)}
          body={
            <Tabs id="savedquoteslists" activeTab={0}>
              <Tab title="Saved Quotes">
                <StoreQuotesLocallySavedList />
              </Tab>

              <Tab title="Recent Quotes">
                <StoreQuotesLocallyRecentList />
              </Tab>
            </Tabs>
          }
          footer={
            <div>
              <Button onClick={() => setShowLoadModal(false)}>Close</Button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default StoreQuotesLocally;
