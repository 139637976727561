import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Translations } from "./../components/translations";
import PolicyChangesFormContainer from "./policyChangesFormContainer";
import RequestedPolicyChanges from "./requestedPolicyChanges";
import PolicyChangeCommercialVehicleContainer from "./policyChangeCommercialVehicleContainer";
import PolicyChangesMoratorium from "./policyChangesMoratorium";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import PolicyTransactionsTable from "./policyTransactionsTable";
import {
  CORE_IN_FORCE_STATUSES,
  GUIDEWIRE_IN_FORCE_STATUSES
} from "../constants/dictionary";

const EXPIRED_POLICY_STATUS = "Expired";

const { changeRequests } = Translations.account_overview;

const AccountChangeRequest = props => {
  const {
    getChangeRequestEligibilityInfo,
    requestChange,
    policyInfo,
    isLoading,
    selectedAgencyCode,
    fetchPolicyChangesViewDocument,
    is109015Agent,
    fetchPolicyDraft
  } = props;
  const [prevPolicyNumber, setPrevPolicyNumber] = useState("");

  const canRequestChange =
    requestChange !== undefined &&
    requestChange !== null &&
    requestChange?.allowed_change_types?.length > 0;

  const { origin = "" } = policyInfo;
  const isGuidewirePolicy = origin === "oasis";

  const legacyCancelledOrExpiredPolicy =
    !isGuidewirePolicy &&
    (policyInfo?.isCancelled || policyInfo?.status === EXPIRED_POLICY_STATUS);

  const isCurrentPolicyInForce = useMemo(() => {
    const isInForce = [
      ...GUIDEWIRE_IN_FORCE_STATUSES,
      ...Object.values(CORE_IN_FORCE_STATUSES)
    ].includes(policyInfo && policyInfo.status.replace(" ", "").toUpperCase());

    return isInForce;
  }, [policyInfo]);

  const hasPendingCommercialChanges = commercialChangeValue => {
    return commercialChangeValue && commercialChangeValue != null;
  };

  const hasMoratorium = moratorium => {
    return !moratorium && moratorium !== null;
  };

  const hasRecentChanges = personal_changes => {
    return (
      personal_changes &&
      personal_changes !== "null" &&
      personal_changes.length > 0
    );
  };

  // Fetch eligibility for policy changes only if policy is in-force.
  // Do not fetch if you are a home agent for a guidewire policy
  useEffect(() => {
    if (is109015Agent && selectedAgencyCode !== "109015" && isGuidewirePolicy) {
      toast(
        `Warning: Current agency is 109015. You will need to impersonate in order to create or edit a policy change.`,
        {
          toastId: 109015,
          position: "top-center",
          className: "warning",
          autoClose: false
        }
      );
    } else if (
      isCurrentPolicyInForce &&
      prevPolicyNumber !== policyInfo.policyNumber
    ) {
      if ((isGuidewirePolicy && !is109015Agent) || !isGuidewirePolicy) {
        setPrevPolicyNumber(policyInfo.policyNumber);
        
        getChangeRequestEligibilityInfo(
          policyInfo.policyNumber,
          selectedAgencyCode
        );
      }
    }
  }, [
    getChangeRequestEligibilityInfo,
    is109015Agent,
    isCurrentPolicyInForce,
    isGuidewirePolicy,
    policyInfo.policyNumber,
    prevPolicyNumber,
    selectedAgencyCode
  ]);

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {isGuidewirePolicy && (
            <>
              {canRequestChange && (
                <>
                  <div>{changeRequests.submit_note}</div>
                  <div>
                    <strong>{changeRequests.note_heading}</strong>
                    <span>{changeRequests.note_description}</span>
                  </div>
                </>
              )}
              {isGuidewirePolicy && (
                <PolicyTransactionsTable
                  is109015Agent={is109015Agent}
                  policyNumber={policyInfo?.policyNumber}
                  fetchRequestEligibility={getChangeRequestEligibilityInfo}
                  agencyCode={selectedAgencyCode}
                  fetchPolicyDraft={fetchPolicyDraft}
                  disableActionButtons={
                    is109015Agent || !isCurrentPolicyInForce
                  }
                />
              )}
              {is109015Agent && (
                <p className="friendly-error-messaging">
                  Current agency is 109015. You will need to impersonate in
                  order to create or edit a policy change.
                </p>
              )}

              {canRequestChange && (
                <>
                  <PolicyChangesFormContainer
                    requestChangeData={requestChange}
                    policyInfo={policyInfo}
                    isCurrentPolicyInForce={isCurrentPolicyInForce}
                  />
                </>
              )}

              {!canRequestChange && !is109015Agent && (
                <p className="friendly-error-messaging">
                  You cannot submit a change request at this time. Please
                  contact your underwriter for assistance.
                </p>
              )}
            </>
          )}

          {!isGuidewirePolicy && (
            <>
              {canRequestChange ? (
                <>
                  <div>{changeRequests.submit_note}</div>
                  <div>
                    <strong>{changeRequests.note_heading}</strong>
                    <span>{changeRequests.note_description}</span>
                  </div>

                  <PolicyChangesFormContainer
                    requestChangeData={requestChange}
                    policyInfo={policyInfo}
                    isCurrentPolicyInForce={isCurrentPolicyInForce}
                  />
                </>
              ) : (
                <>
                  {legacyCancelledOrExpiredPolicy ? (
                    <div className="cancelled-policy-error-message">
                      {
                        Translations.account_overview.changeRequests
                          .cancelled_legacy_policy_message
                      }
                    </div>
                  ) : (
                    <p className="friendly-error-messaging">
                      You cannot submit a change request at this time. Please
                      contact your underwriter for assistance.
                    </p>
                  )}
                </>
              )}

              <>
                {hasPendingCommercialChanges(
                  requestChange?.has_saved_commercial_auto_change
                ) && (
                  <PolicyChangeCommercialVehicleContainer
                    policyInfo={policyInfo}
                    amendDate={requestChange?.commercial_auto_change_amend_date}
                  />
                )}

                {hasMoratorium &&
                  requestChange?.state_name &&
                  requestChange?.moratorium && (
                    <PolicyChangesMoratorium
                      statename={requestChange?.state_name}
                      moratorium={requestChange?.moratorium}
                    />
                  )}

                {hasRecentChanges(requestChange?.personal_changes) && (
                  <RequestedPolicyChanges
                    requestedChanges={requestChange?.personal_changes}
                    fetchPolicyChangesViewDocument={
                      fetchPolicyChangesViewDocument
                    }
                    policy_number={policyInfo?.policyNumber}
                  />
                )}
              </>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AccountChangeRequest;

AccountChangeRequest.propTypes = {
  getChangeRequestEligibilityInfo: PropTypes.func.isRequired,
  requestChange: PropTypes.object,
  policyInfo: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  selectedAgencyCode: PropTypes.string,
  fetchPolicyChangesViewDocument: PropTypes.func.isRequired,
  is109015Agent: PropTypes.bool.isRequired,
  fetchPolicyDraft: PropTypes.func.isRequired
};
