import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  Button,
  DatePicker,
  Form,
  formatDate,
  LoadingIndicator,
  Modal,
  TextArea,
  useForm,
  FlexRow
} from "@ufginsurance/ui-kit";
import Address from "../../../quick-program/quick-quoting/shared/Address";
import { putSuretyAccountAddressChange } from "../../services";
import { createSuretyAccountAddressChangePayload } from "../../shared/utils";
import ApiResponseModalBody from "../../shared/ApiResponseModalBody/ApiResponseModalBody";

function RequestAddressChangeModal({
  account,
  address,
  agency,
  agent,
  setShowModal,
  show
}) {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = ({ values }) => {
    setIsLoading(true);
    const payload = createSuretyAccountAddressChangePayload({
      account,
      agency,
      agent,
      values
    });

    putSuretyAccountAddressChange(account.account_number, payload)
      .then(res => {
        if (res.status === 200) {
          setSubmitSuccess(true);
        }
      })
      .catch(err => {
        console.error(err);
        setSubmitError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const initialValues = {
    address_line: "",
    city: "",
    comments: "",
    effective_date: "",
    state: "",
    zip: ""
  };

  const form = useForm({
    values: initialValues,
    onSubmit: handleSubmit
  });

  const {
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    values,
    errors,
    invalidFields,
    updateForm
  } = form;

  // Reset the form on modal close.
  const handleCloseModal = () => {
    updateForm({
      values: initialValues,
      errors: {
        address_line: [],
        city: [],
        comments: [],
        effective_date: [],
        state: [],
        zip: []
      }
    });
    setShowModal(false);
    setSubmitError(false);
    setSubmitSuccess(false);
  };

  return (
    <Modal
      title="Request Address Change"
      show={show}
      onHide={handleCloseModal}
      size="lg"
      className="request-address-change"
      body={
        <>
          {isLoading ? (
            <LoadingIndicator color="blue" />
          ) : submitSuccess || submitError ? (
            <ApiResponseModalBody
              isSuccess={submitSuccess}
              isError={submitError}
              message="Account Address Change Request has been submitted and a confirmation email has been sent."
            />
          ) : (
            <Form context={form}>
              <div className="request-address-change__current-account">
                <strong>Current Account Name and Address</strong>
                <p className="account-name">{account?.account_name}</p>
                <p className="address-line">{address?.addressLine}</p>
                <p className="address-zone">{address?.addressZone}</p>
              </div>

              <DatePicker
                className="request-address-change__date-of-change"
                id="effective_date"
                name="effective_date"
                label="Effective Date of Change"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.effective_date}
                format="YYYY-MM-DD"
                size="md"
                minDate={formatDate(new Date(), "MM/DD/YYYY")}
                required
              />

              <div className="request-address-change__new-address">
                <strong>New Account Address:</strong>
                <Address
                  form={form}
                  showDefaultAddressField
                  disableProjectCity
                  disableProjectState
                />
                <TextArea
                  id="comments"
                  name="comments"
                  label="Comments/Remarks"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.comments}
                />
              </div>

              <FlexRow align="right" className="edit-modal-buttons">
                <Button variant="plain" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  spinner={isLoading}
                  disabled={
                    !!Object.keys(errors).length ||
                    !!invalidFields.length ||
                    !Object.values(values).reduce((a, b) => a + b, "").length ||
                    isLoading
                  }
                >
                  Submit Change
                </Button>
              </FlexRow>
            </Form>
          )}
        </>
      }
      footer={
        <>
          {(submitSuccess || submitError) && (
            <Button onClick={handleCloseModal}>Close</Button>
          )}
        </>
      }
    />
  );
}

RequestAddressChangeModal.propTypes = {
  account: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
  agent: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  agency: sessionSelector.getActiveAgency(state),
  agent: {
    name: sessionSelector.getFirstAndLastName(state),
    code: sessionSelector.getAgentCode(state),
    email: sessionSelector.getEmail(state)
  }
});

export default connect(mapStateToProps)(RequestAddressChangeModal);
