import { Translations } from "../../../../components/translations";

/*
  VALIDATION RULES
  */
export const STATUS = "1";
export const LINE_OF_BUSINESS = "2";

const ALL_CLAIMS = "0";
export const OPEN_CLAIMS = "1";
export const CLOSED_CLAIMS = "2";

const { form_errors } = Translations.reports.tabClaims.agencyLossRun;

export const lineOfBusinessList = (
  { field, value, validation },
  handleOnValidate,
  values
) => {
  const errors = handleOnValidate({ field, value, validation });
  if (value !== ALL_CLAIMS) {
    const sortListTypes = values && values.sortListTypes;
    if (sortListTypes && sortListTypes === STATUS) {
      errors.push(form_errors.sort_status_with_lob);
    }
    if (sortListTypes && sortListTypes === LINE_OF_BUSINESS) {
      errors.push(form_errors.sort_lob_with_lob);
    }
  }

  return errors;
};

export const claimStatusTypes = (
  { field, value, validation },
  handleOnValidate,
  values
) => {
  const errors = handleOnValidate({ field, value, validation });
  if (value === OPEN_CLAIMS) {
    const sortListTypes = values && values.sortListTypes;
    if (sortListTypes && sortListTypes === STATUS) {
      errors.push(form_errors.sort_status_open_claims);
    }
    if (sortListTypes && sortListTypes === LINE_OF_BUSINESS) {
      errors.push(form_errors.sort_lob_open_claims);
    }
  }
  if (value === CLOSED_CLAIMS) {
    const sortListTypes = values && values.sortListTypes;
    if (sortListTypes && sortListTypes === STATUS) {
      errors.push(form_errors.sort_status_closed_claims);
    }
    if (sortListTypes && sortListTypes === LINE_OF_BUSINESS) {
      errors.push(form_errors.sort_lob_closed_claims);
    }
  }
  return errors;
};

export const incurredAmount = (
  { field, value, validation },
  handleOnValidate,
  values
) => {
  const errors = handleOnValidate({ field, value, validation });
  const sortListTypes = values && values.sortListTypes;
  if (value && parseInt(value, 10) > 0) {
    if (sortListTypes && sortListTypes === STATUS) {
      errors.push(form_errors.sort_status_amount_zero);
    }
    if (sortListTypes && sortListTypes === LINE_OF_BUSINESS) {
      errors.push(form_errors.sort_lob_amount_zero);
    }
  }
  return errors;
};
