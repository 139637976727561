/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactAdapterConsumer from "../../shared/components/React/ReactAdapterConsumer";
import { LoadingIndicator, FlexRow } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { fetchAgencyProfileManifest } from "../AgencyProfileSlice";

const MyProfile = ({ token, agentCode, rehydrateSession }) => {
  const dispatch = useDispatch();

  const baseUrl = window.env.REACT_APP_M4_AGENT_BASEURL;
  const clientId = window.env.REACT_APP_CLIENT_ID;
  const clientSecret = window.env.REACT_APP_CLIENT_SECRET_ID;

  const agencyProfileData = useSelector(state => state.agencyProfile);

  const manifestVersion = agencyProfileData?.manifest?.version;

  const showErrorMessage = agencyProfileData?.fetchManifestError;

  useEffect(() => {
    if (!agencyProfileData?.manifest?.version) {
      dispatch(fetchAgencyProfileManifest());
    }
  }, [agencyProfileData?.manifest?.version, dispatch]);

  return (
    <>
      {showErrorMessage && (
        <FlexRow>
          <div className="no-results-info">
            <HelpDeskMessage display="anErrorOccurred" />
          </div>
        </FlexRow>
      )}

      {!manifestVersion && !showErrorMessage && <LoadingIndicator />}

      {manifestVersion && !showErrorMessage && (
        <ReactAdapterConsumer
          {...{
            token,
            agentCode,
            clientId,
            baseUrl,
            clientSecret,
            rehydrateSession,
            scope: "agencyProfile",
            module: "MyProfile",
            url: `${window.env.REACT_APP_UFGDIGITAL_MFE_URL}/agency-profile/${manifestVersion}/agencyProfile_${manifestVersion}.js`
          }}
        />
      )}
    </>
  );
};

export default MyProfile;
