import { useState, useEffect } from "react";
import { getRenewalFormExistsStatus } from "../../services/index";
import { logger } from "../../../loggers";

export default function useGetRenewalFormStatus(bondNumber) {
  const [renewalFormExists, setRenewalFormExists] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fetchedBond, setFetchedBond] = useState(null);

  useEffect(() => {
    if (!bondNumber || fetchedBond === bondNumber) return;

    setIsLoading(true);

    getRenewalFormExistsStatus(bondNumber)
      .then(res => {
        if (res.status === 200 && res.data) {
          setRenewalFormExists(res.data.status === "success");
          setFetchedBond(bondNumber);
        } else {
          setError(
            `Failed to fetch renewal form status. Status Code: ${res.status}`
          );
        }
      })
      .catch(err => {
        const errorMessage = err.toString();
        setError(errorMessage);
        logger.error({
          description: errorMessage,
          fatal: false
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [bondNumber, fetchedBond]);

  return { renewalFormExists, isLoading, error };
}
