import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const moratoriumReducer = (state = initialState.moratorium, action) => {
  switch (action.type) {
    case types.FETCH_MORATORIUM_LISTINGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_MORATORIUM_LISTINGS_SUCCEEDED:
      return {
        ...state,
        listings: action.payload,
        isLoading: false
      };
    case types.FETCH_MORATORIUM_LISTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_MORATORIUM_DETAILS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_MORATORIUM_DETAILS_SUCCEEDED:
      return {
        ...state,
        details: action.payload,
        isLoading: false
      };
    case types.FETCH_MORATORIUM_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default moratoriumReducer;
