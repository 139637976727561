import { mergeExclusions } from "../shared/helpers/mergeExclusions";
import * as api from "../../services/onlineQuotingService";

import { getDynamicLineLevelCoverageControls } from "../shared/dynamicCoverageControls/coverageControls";
const getExclusionStep4 = ({
  toastErrr,
  quoteData,
  supportingData,
  callback
}) => {
  // get metadata from Mule and Node

  /*
   * We're calling multiple APIs here and merging them together
   * Promise.all will call all apis in the array and return an array of responses in alignment of the calls
   */
  const metadataServices = [
    api
      .getPolicyLineMetaData(quoteData.quoteID)
      .then(r => r?.data || {})
      .catch(error => {
        toastErrr({
          action: "getPolicyLineMetaData",
          description: "api failure getPolicyLineMetaData",
          error
        });
      })
  ];

  metadataServices.push(
    api
      .getCoverageControlData({ dto: quoteData, supportingData })
      .then(r => r.data || {})
      .catch(error => {
        toastErrr({
          action: "getCoverageControlData",
          description: "api failure getCoverageControlData",
          error
        });
      })
  );

  /**
   * add the dynamic coverage controls handled in the UI
   *  - because these controls are dependent on values in the quote
   *    ... we handle them directly in the UI since they need to be dyanmic
   */
  const dynamicCoverageControls = [
    getDynamicLineLevelCoverageControls({
      quoteData,
      supportingData
    })
  ];

  Promise.all(metadataServices).then(responses => {
    // Note: `responses` includes this data if all LD flags are "ON":
    //  [muleMetadata, recommendedCoverages + coverageControl]
    // mergeExclusions (latter object has priority over prior objects)

    const exclusions = mergeExclusions([
      ...(responses || []).map(r => {
        return {
          formData: {
            ...r?.formData,
            ...{
              coverageControl: [
                ...(r?.controls?.lineLevel.cyber?.coverageControl || []),
                ...(r?.controls?.lineLevel.bop?.coverageControl || []),
                ...(r?.controls?.lineLevel.auto?.coverageControl || []),
                ...(r?.recommended || [])
              ]
            }
          }
        };
      }),
      // add dynamic coverage controls here to override the static ones we get anywhere else
      // these controls have dependencies that can be changed by the user after we recieve the static controls
      ...dynamicCoverageControls
    ]);

    // A note about "hiredNonOwnedAllowed":
    // formData?.hiredNonOwnedAllowed will be false if HNO is selected because mule is only sets this to be true if CA7CommAuto isn't in the quote
    // ... for this reason, we have to look at both "hiredNonOwnedAllowed" and "hiredNonOwnedSelected" to determin if we display the HNO toggles
    const hiredNonOwnedAllowed =
      !!exclusions?.formData?.hiredNonOwnedAllowed ||
      !!exclusions?.formData?.hiredNonOwnedSelected;

    //  if the hiredNonOwnedAllowed is false then remove coverageControls of CA7
    if (!hiredNonOwnedAllowed && exclusions.formData) {
      exclusions.formData.coverageControl =
        exclusions?.formData?.coverageControl || [];
    }

    callback(exclusions, hiredNonOwnedAllowed);

    // // if workman comp lob exists, then setup the options for the wcLineDetails
    // if (quoteData?.lobData?.wcmWorkersComp) {
    //   const wcOptions = workCompFieldOptions
    //   setWcLineOptions(wcOptions);
    // }
  });
};
export default getExclusionStep4;
