export const getAgencyStatementListIsLoading = store => {
  return store.agencyStatementList.isLoading;
};

export const getAgencyStatementList = store => {
  return store.agencyStatementList.statementList;
};

export const getAgencyStatementListError = store => {
  return store.agencyStatementList.error;
};

export const getIsLoadingPurchasedAgencyCodes = store => {
  return store.agencyStatementList.isLoadingPurchasedAgencyCodes;
};

export const getPurchasedAgencyCodes = store => {
  return store.agencyStatementList.purchasedAgencyCodes;
};

export const getPurchasedAgencyCodesError = store => {
  return store.agencyStatementList.purchasedAgencyCodesError;
};

export const getIsLoadingSponsoredAgencyCodes = store => {
  return store.agencyStatementList.isLoadingSponsoredAgencyCodes;
};

export const getSponsoredAgencyCodes = store => {
  return store.agencyStatementList.sponsoredAgencyCodes;
};

export const getSponsoredAgencyCodesError = store => {
  return store.agencyStatementList.sponsoredAgencyCodesError;
};
