import React, { useState } from "react";
import { Button } from "@ufginsurance/ui-kit";
import ClassCodeWizard from "./ClassCodeWizard";

const AddAdditionalClassCodes = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {
        <Button
          isLink
          inline
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add Class Code
        </Button>
      }
      {showModal && (
        <ClassCodeWizard onHide={() => setShowModal(false)} show={showModal} />
      )}
    </>
  );
};
export default AddAdditionalClassCodes;
