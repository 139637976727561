import { connect } from "react-redux";
import PolicyLossRunForm from "../components/claims/policy-loss-runs/PolicyLossRunForm";
import { searchKeywordUpdatedReportClaims } from "../../actions/policies";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state)
});

const mapDispatchToProps = {
  searchKeywordUpdatedReportClaims
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyLossRunForm);
