import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";

export default (state = initialState.claimsInquiry, action) => {
  switch (action.type) {
    case types.CLAIMS_INQUIRY_ACTIVATED:
      return {
        ...state,
        isActivated: true,
        claimNumber: action.payload
      };

    case types.CANCEL_CLAIM_INQUIRY: {
      return {
        ...initialState.claimsInquiry
      };
    }

    default:
      return state;
  }
};
