import * as types from "./helpDeskActionTypes";
import initialState from "../reducers/initialState";

const helpDeskReducer = (state = initialState.helpDesk, action) => {
  switch (action.type) {
    case types.HELPDESK_ACTIVATED:
      return {
        ...state,
        isActivated: true
      };
    case types.DISMISS_HELPDESK:
      return {
        ...state,
        isActivated: false
      };
    case types.HELPDESK_SERVICE_STARTED:
      return {
        ...state,
        isLoading: true,
        helpDeskError: null
      };
    case types.HELPDESK_SERVICE_SUCCEEDED:
      return {
        ...state,
        helpDeskDetails: action.payload,
        isLoading: false,
        helpDeskError: null
      };
    case types.HELPDESK_SERVICE_FAILED:
      return {
        ...state,
        isLoading: false,
        helpDeskError: action.payload
      };
    default:
      return state;
  }
};

export default helpDeskReducer;
