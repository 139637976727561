import React, { useState, useEffect, useCallback } from "react";
import {
  FormGroup,
  Panel,
  Dropdown,
  Input,
  Button,
  formatDate,
  Checkbox
} from "@ufginsurance/ui-kit";
import {
  statesList,
  ObligeeType,
  AccountNameMaxLength
} from "../constants/suretyDictionary";
import "./BondInfo.scss";

import * as obligeeSearch from "./obligeeSearch";
import Address from "../shared/Address";
import {
  buildStreetName,
  isObligeeAddressPOBox,
  getIsNewAccountOrObligee
} from "../shared/util";
import { debounceApi } from "../../../util/debounce";
const Obligee = ({
  form,
  formInitialized,
  activeAgencyCode,
  agencyStates,
  quickBondObligeeList,
  fetchBondObligeeList,
  bondInfo,
  setObligeeSearchIsLoading,
  handleAddressIsPOBoxChange
}) => {
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  //Form metadata values
  const [obligeeNumberInputValue, setObligeeNumberInputValue] = useState();
  const [obligeeOptions, setObligeeOptions] = useState([]);
  const [disabledFields, setDisableFields] = useState(false);

  /*
  =================
  FORM INPUT EVENTS
  =================
  */

  const search = value => {
    obligeeSearch.search({
      value,
      form,
      obligeeOptions,
      setObligeeOptions,
      fetchBondObligeeList,
      quickBondObligeeList
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedSearch = useCallback(
    debounceApi(v => search(v), 500),
    []
  );

  const handleObligeeNameSearch = (value, { action }) => {
    if (!value) {
      setObligeeOptions([]);
      setObligeeSearchIsLoading(false);
    }
    if (action === "input-change") {
      value =
        value.length <= AccountNameMaxLength
          ? value
          : value.substr(0, AccountNameMaxLength);
      setObligeeNumberInputValue(value);
      setObligeeSearchIsLoading(true);
      delayedSearch(value);
    }
  };

  const handleObligeeNameChange = ({ value }) => {
    setObligeeNumberInputValue("");

    obligeeSearch.select({
      value: (value || "").trim(),
      form,
      obligeeOptions,
      setDisableFields,
      setObligeeOptions,
      today: formatDate(new Date(), "YYYY-MM-DD")
    });
  };

  useEffect(() => {
    const obligeeSearchItem = {
      value: "0000000000",
      label: "",
      obligee_account_name: "",
      obligee_number: "0000000000",
      obligee_address_line: "",
      obligee_pobox: "",
      obligee_zip: "",
      obligee_city: "",
      obligee_state: "",
      obligee_type: "",
      obligee_address_is_pobox: false,
      custom: false
    };

    //bondInfo.obligee_account_number corresponds to obligee_number
    //rather than obligee_account_number when mapping to the items in
    //the obligee search dropdown (quickBondObligeeList) -- OOS-215

    //refactor bondInfo datastructure to rename obligee_account_number
    //to obligee_number for consistency. -- OOS-335
    if (!formInitialized && !!bondInfo?.obligee_account_number) {
      let opts = [];

      // existing obligee
      if (
        parseInt(bondInfo.obligee_account_number) !== 0 // skip if parsed as 0
      ) {
        opts = [
          {
            ...obligeeSearchItem,
            value: bondInfo.obligee_account_number,
            label: `${bondInfo.obligee_name}`,
            obligee_number: bondInfo.obligee_account_number,
            obligee_account_name: bondInfo.obligee_name
          }
        ];

        setDisableFields(true);

        // new obligee
      } else {
        opts = [
          {
            ...obligeeSearchItem,
            label: `Add Obligee: ${bondInfo.obligee_name}`,
            obligee_account_name: bondInfo.obligee_name,
            custom: true
          }
        ];
      }

      // update the options for the business name
      setObligeeOptions(opts);
    }

    //user is doing a search
    if (obligeeNumberInputValue?.length > 2) {
      const opts = (quickBondObligeeList?.data ?? [])
        .filter(
          obligee =>
            !obligee?.custom &&
            (obligee?.obligee_account_name ?? "")
              .toString()
              .toLowerCase()
              .indexOf(obligeeNumberInputValue.toString().toLowerCase()) >= 0
        )
        .map(obligee => {
          const addr = [obligee.obligee_city, obligee.obligee_state];
          const street = buildStreetName({
            prefix: obligee.obligee_building_number,
            street: obligee.obligee_street_name
          });

          if (street.length) addr.unshift(street);

          return {
            ...obligee,
            value: obligee.obligee_number,
            label: `${obligee.obligee_account_name} (${addr.join(", ")})`
          };
        });

      // if there's no current value that matches the input, add the "Add Customer" option to the end
      if (
        !opts.some(o => o.value === obligeeNumberInputValue) &&
        !quickBondObligeeList.isLoading
      ) {
        opts.push({
          ...obligeeSearchItem,
          label: "Add Obligee: " + obligeeNumberInputValue,
          obligee_account_name: obligeeNumberInputValue,
          custom: true
        });
      }

      setObligeeOptions(opts);
    }
  }, [
    quickBondObligeeList,
    obligeeNumberInputValue,
    bondInfo,
    formInitialized
  ]);

  return (
    <Panel
      rounded
      bgcolor="grey"
      title="Enter project owner / obligee"
      titlebar
    >
      <FormGroup className="Obligee">
        {!values.obligee_name ? (
          <Dropdown
            id="obligee_name"
            name="obligee_name"
            label="Search Obligee Name"
            placeholder="Enter Obligee name..."
            onChange={handleObligeeNameChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            onInputChange={handleObligeeNameSearch}
            value={values.obligee_account_number}
            required
            options={obligeeOptions}
            isSearchable
            hideIndicatorUntilSearch
            loadingMessage={() =>
              obligeeNumberInputValue?.length > 2 ? "Searching..." : null
            }
            inputValue={obligeeNumberInputValue}
            className="grow"
            noOptionsMessage={() => null}
            isLoading={
              obligeeNumberInputValue?.length > 2 &&
              quickBondObligeeList.isLoading
            }
          />
        ) : (
          <>
            <Input
              id="obligee_name"
              name="obligee_name"
              label="Search Obligee Name"
              onChange={handleObligeeNameChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.obligee_name}
              required
              className="grow"
              size="auto"
              maxLength={AccountNameMaxLength}
            />
            <Button
              labelSpace
              wrapperClassName="oq__account-search__reset"
              isLink
              onClick={handleObligeeNameChange}
            >
              Reset
            </Button>
          </>
        )}
      </FormGroup>
      <FormGroup className="obligee-address">
        {getIsNewAccountOrObligee(values?.obligee_account_number) && (
          <Checkbox
            id="obligee_address_is_pobox"
            name="obligee_address_is_pobox"
            label="Is address a P.O. Box?"
            onChange={handleAddressIsPOBoxChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.obligee_address_is_pobox}
          />
        )}
        <Address
          form={form}
          prefix="obligee"
          showDefaultAddressField
          agencyStates={agencyStates}
          activeAgencyCode={activeAgencyCode}
          stateOptions={statesList}
          disableFields={disabledFields}
          replaceAddressWithPoBox={isObligeeAddressPOBox(values)}
          addressLineFieldLabel={
            isObligeeAddressPOBox(values)
              ? "P.O. Box Number"
              : "Physical Address"
          }
        />
      </FormGroup>
      <FormGroup className="Obligee">
        <Dropdown
          id="obligee_type"
          name="obligee_type"
          label="Obligee Type"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.obligee_type}
          options={ObligeeType}
          required
          className="type"
          size="lg"
          disabled={disabledFields}
        />
      </FormGroup>
    </Panel>
  );
};
export default Obligee;
