import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchPoliciesSearchList } from "../../../actions/policies";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { cancelReportAClaim } from "../actions/reportClaimActions";
import { Translations } from "../../../components/translations";
import ReportClaim from "../components/ReportClaim";
import { Modal, Alert } from "@ufginsurance/ui-kit";
import { getisSpecialtyPortalView } from "../../../portalview/changePortalViewStateSelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const ReportClaimContainer = ({
  show,
  fetchPoliciesSearchList,
  agencyNumber,
  reportInformation,
  policyListError,
  policyNumber,
  isSpecialtyPortalView,
  cancelReportAClaim,
  is109015Agent
}) => {
  const { report_a_claim } = Translations;

  const onHide = () => {
    cancelReportAClaim();
  };
  const reportaclaimsubHeader = () => {
    return (
      <div>
        <p className="report-a-claim-sub-header-text">
          {report_a_claim.sub_header}
        </p>
        <Alert type="note" dismissible={false}>
          {Translations.formatString(
            report_a_claim.workers_compensation_claims,
            <a href="tel:+18448422674">844-842-2674</a>
          )}
        </Alert>
      </div>
    );
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      title={report_a_claim.header}
      subTitle={reportaclaimsubHeader()}
      size="lg"
      body={
        <ReportClaim
          onHide={onHide}
          fetchPoliciesSearchList={fetchPoliciesSearchList}
          agencyNumber={agencyNumber}
          reportInformation={reportInformation}
          policyListError={policyListError}
          policyNumber={policyNumber}
          isSpecialtyPortalView={isSpecialtyPortalView}
          is109015Agent={is109015Agent}
        />
      }
    />
  );
};

ReportClaimContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  cancelReportAClaim: PropTypes.func.isRequired,
  fetchPoliciesSearchList: PropTypes.func.isRequired,
  agencyNumber: PropTypes.string.isRequired,
  reportInformation: PropTypes.object,
  policyListError: PropTypes.bool,
  policyNumber: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool.isRequired,
  is109015Agent: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  show: state.reportClaimReducer.startReportAClaim,
  policyNumber: state.reportClaimReducer.policyNumber,
  agencyNumber: getSelectedAgencyCode(state),
  policyListError: state.policiesData.error,
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state)
});

const mapDispatchToProps = {
  cancelReportAClaim,
  fetchPoliciesSearchList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportClaimContainer);
