import moment from "moment";
import { Translations } from "./translations";

export const toTitleCase = (str, ignores = []) => {
  return !!str
    ? str
        .split(" ")
        .map(function(word) {
          return !ignores.includes(word)
            ? word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
            : word;
        })
        .join(" ")
    : "";
};

export const joinName = str => {
  return !!str
    ? str
        .toUpperCase()
        .split(" ")
        .join("+")
    : "";
};

export const toCamelCase = str => {
  return !!str
    ? str
        .split(" ")
        .map(function(word) {
          return word.charAt(0).toLowerCase() + word.slice(1);
        })
        .join(" ")
    : "";
};

export const DateFormat = (date, formatStr = Translations.date_format.date) => {
  if (date) {
    return moment(date).format(formatStr);
  }
  return ""; //required for other components.
};

export const toYesNoCase = isCheck => {
  return isCheck ? Translations.general.yes : Translations.general.no;
};

export const convertStatesAndAbbreviations = (input, to) => {
  // USAGE:
  // abbrState('ny', 'name');
  // --> 'New York'
  // abbrState('New York', 'abbr');
  // --> 'NY'

  const states = [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"]
  ];

  if (to === "abbr") {
    input = input.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    for (let i = 0; i < states.length; i++) {
      if (states[i][0] === input) {
        return states[i][1];
      }
    }
  } else if (to === "name") {
    input = input.toUpperCase();
    for (let i = 0; i < states.length; i++) {
      if (states[i][1] === input) {
        return states[i][0];
      }
    }
  } else return "";
};
