import React, { useState } from "react";
import PropTypes from "prop-types";
import ViewOnMaps from "./ViewOnMaps";
import { Translations } from "../../translations";
import AddressToSingleLine from "../../utils/Address";
import {
  Modal,
  FlexRow,
  Button,
  Icon,
  DropdownNonForm
} from "@ufginsurance/ui-kit";
import { ACTIVE_IN_SERVICE_CENTER } from "../../../constants/dictionary";

const propTypes = {
  accountDetails: PropTypes.object.isRequired,
  handleLinkedAccountChange: PropTypes.func.isRequired
};

export const showOnMap = accountDetails => {
  return (
    accountDetails.address &&
    accountDetails.address.geolocation &&
    accountDetails.address.address1 &&
    !accountDetails.address.address1.includes("P.O") &&
    !accountDetails.address.address1.toLowerCase().includes("po box")
  );
};

const AccountCard = ({ accountDetails, handleLinkedAccountChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const address = AddressToSingleLine(accountDetails.address);
  const accountOptions = [
    {
      value: accountDetails.account_number,
      label: accountDetails.account_number
    }
  ];
  accountDetails?.linked_accounts?.forEach(accountNumber =>
    accountOptions.push({
      value: accountNumber,
      label: accountNumber
    })
  );
  const showLinkedAccounts = (accountOptions?.length || 0) > 1;
  return (
    <div className="account-card">
      <div className="account-contact">
        <div className="contact-icon">
          <Icon icon="farAddressCard" />
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-3 col-xs-3">&nbsp;</div>
          <div className="col-md-9 col-sm-6 col-xs-6 account-address">
            <div className="account-overview-title row no-left-margin">
              {accountDetails &&
                accountDetails.primary_account_name &&
                accountDetails.primary_account_name.toUpperCase()}
              <br />
              {accountDetails &&
                accountDetails.secondary_account_name &&
                accountDetails.secondary_account_name.toUpperCase()}
            </div>
            <div className="row">&nbsp;</div>
            <div className="row">
              {showLinkedAccounts ? (
                <FlexRow align="center">
                  <span className="account-info-label text-nowrap">
                    {" "}
                    {Translations.account_overview.account.account}{" "}
                  </span>
                  <DropdownNonForm
                    id="account-card-linked-accounts-dropdown"
                    name="account-card-linked-accounts-dropdown"
                    options={accountOptions}
                    value={accountOptions[0]}
                    onChange={handleLinkedAccountChange}
                    isClearable={false}
                    size="md"
                    noLabel
                    compactIndicator
                    className="account-card-linked-accounts-dropdown"
                  />
                </FlexRow>
              ) : (
                <>
                  <span className="account-info-label text-nowrap">
                    {" "}
                    {Translations.account_overview.account.account}{" "}
                  </span>
                  <span className="account-card-account-number-label">
                    {accountDetails.account_number}
                  </span>
                </>
              )}
            </div>
            {accountDetails &&
              accountDetails.is_service_center &&
              accountDetails.service_center_status &&
              accountDetails.service_center_status ===
                ACTIVE_IN_SERVICE_CENTER && (
                <div className="row no-left-margin">
                  <span className="service-center-account text-nowrap">
                    {Translations.account_overview.account.service_account}
                  </span>
                </div>
              )}
            <div className="account-overview-address row no-left-margin">
              <span className="policy-info-label">
                {" "}
                {Translations.account_overview.account.address}{" "}
              </span>
              <span className="address-detail">
                <br />
                {accountDetails.address && accountDetails.address.address1}
                <br />
              </span>
              {accountDetails.address && accountDetails.address.address2 && (
                <span className="address-detail">
                  {accountDetails.address.address2}
                  <br />
                </span>
              )}
              <span className="address-detail">
                {accountDetails.address && accountDetails.address.city}
                {accountDetails.address &&
                  accountDetails.address.city &&
                  ", " + accountDetails.address.state}
                {accountDetails.address && " " + accountDetails.address.zip}
              </span>
              <div className="address-detail">
                {showOnMap(accountDetails) && (
                  <>
                    <button
                      className="account-overview-map primary btn btn-link"
                      onClick={() => setIsModalOpen(true)}
                      key="viewOnMapButton"
                    >
                      <u>View on Map</u>
                    </button>
                    {isModalOpen && (
                      <Modal
                        size="lg"
                        key="map"
                        body={
                          <>
                            <ViewOnMaps
                              addressdetails={accountDetails.address}
                            />
                          </>
                        }
                        title={address}
                        footer={
                          <FlexRow align="right">
                            <Button
                              type="button"
                              variant="secondary"
                              className="closeView"
                              onKeyPress={() => setIsModalOpen(false)}
                              onClick={() => setIsModalOpen(false)}
                            >
                              Close
                            </Button>
                          </FlexRow>
                        }
                        show={isModalOpen}
                        onHide={() => setIsModalOpen(false)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-xs-3">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

AccountCard.propTypes = propTypes;

export default AccountCard;
