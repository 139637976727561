/**
 * WCMFactor14 - this coverage is located on WorkComp State Specific for Nebraska (only when Apply Flexibly Adjustment = Yes)
 * https://ufginsurance.atlassian.net/browse/OOQ-14281
 */
export const WCMFactor14 = field => {
  /**
   * function to return an array of values
   * implementation below would result in an array like:
   * [-0.4, -0.39, -0.38,...]
   */
  const generatePercentageArray = ({ minDecimal, maxDecimal, step }) => {
    const numValues = Math.floor((maxDecimal - minDecimal) / step) + 1;
    const decimalArray = [];
    for (let i = 0; i < numValues; i++) {
      const inc = step * i;
      decimalArray.push(parseFloat((minDecimal + inc).toFixed(2)));
    }
    return decimalArray;
  };

  const options = generatePercentageArray({
    minDecimal: -0.1,
    maxDecimal: 0.1,
    step: 0.01
  }) // convert to option array
    .map((o, i) => ({
      value: o.toString(),
      label: `${parseInt(o * 100)}%`,
      order: i
    }));

  return {
    ...field,
    options,
    className: "oq__coverage__input-to-dropdown",
    dataType: "inputToDropdown",
    chosenTermValue: field.directValue,
    sortOptions: false
  };
};
