export function getAdministratorIsLoading(store) {
  return store.administrator.isLoading;
}

export function getAdministrator(store) {
  return store.administrator.details;
}

export function getAdministratorError(store) {
  return store.administrator.error;
}
