import { connect } from "react-redux";
import AgentManuals from "./AgentManuals";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  fetchStatesListDetails,
  setSelectedState,
  fetchStateProducts,
  setSelectedProduct,
  fetchTableOfContents,
  fetchContent
} from "../actions/agentManualActions";
import {
  getisSpecialtyPortalView,
  getActiveSpecialtyCompanyBranch,
  getIsMercerSpecialtyBrokerPortalView
} from "../../portalview/changePortalViewStateSelectors";
import { findDefaultTableOfContentsItem } from "../selectors";

const mapStateToProps = state => ({
  stateListData: state.agentManuals.states.stateListData,
  areStatesLoading: state.agentManuals.states.isLoading,
  statesError: state.agentManuals.states.error,
  stateProducts: state.agentManuals.products.stateProducts,
  areProductsLoading: state.agentManuals.products.isLoading,
  productsError: state.agentManuals.products.error,
  tableOfContents: state.agentManuals.tableOfContents.data,
  areTableOfContentsLoading: state.agentManuals.tableOfContents.isLoading,
  tableOfContentsError: state.agentManuals.tableOfContents.error,
  content: state.agentManuals.content.data,
  isContentLoading: state.agentManuals.content.isLoading,
  contentError: state.agentManuals.content.error,
  isSpecialtyPortalView: getisSpecialtyPortalView(state),
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state),
  defaultAgentState: sessionSelector.getActiveAgencyState(state),
  selectedState: state.agentManuals.selectedState,
  selectedProduct: state.agentManuals.selectedProduct,
  isMercerSpecialtyPortalView: getIsMercerSpecialtyBrokerPortalView(state),
  previousFetchedStateId: state.agentManuals.products.previousFetchedStateId,
  previousTableOfContentsProductId: state.agentManuals.tableOfContents.previousFetchedProductId,
  previousFetchedContentId: state.agentManuals.content.previousFetchedContentId,
  previousFetchedCompanyBranch: state.agentManuals.states.previousCompanyBranch,
  defaultSelectedSubheader: findDefaultTableOfContentsItem(state)
});

const mapDispatchToProps = {
  fetchStatesListDetails,
  setSelectedState,
  fetchStateProducts,
  setSelectedProduct,
  fetchTableOfContents,
  fetchContent
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentManuals);
