import * as types from "./actionTypes";
import * as reportService from "../services/claimsReportsService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

const fetchWeeklyPaymentListStarted = () => ({
  type: types.FETCH_WEEKLY_PAYMENT_REPORT_LIST_STARTED
});

const fetchWeeklyPaymentListSucceeded = data => ({
  type: types.FETCH_WEEKLY_PAYMENT_REPORT_LIST_SUCCEEDED,
  payload: data
});

const fetchWeeklyPaymentListFailed = error => ({
  type: types.FETCH_WEEKLY_PAYMENT_REPORT_LIST_FAILED,
  payload: error
});

export const fetchWeeklyPaymentListDetails = agency_code => dispatch => {
  dispatch(fetchWeeklyPaymentListStarted());

  return reportService.getWeeklyPaymentSummaryList(agency_code).then(
    res => dispatch(fetchWeeklyPaymentListSucceeded(res.data)),
    error => {
      dispatch(fetchWeeklyPaymentListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

// TODO: This isn't dispatching any actions
export const fetchWeeklyPaymentViewReport = (agency_code, date) => () => {
  const documentService = new DocumentService();
  documentService.initializeRenderer("/loading-document");
  return reportService
    .getWeeklyPaymentSummary(agency_code, date)
    .then(response => {
      documentService.saveOrOpenDocument(
        response.data,
        "weekly-payment-report.pdf"
      );
    })
    .catch(() => {
      documentService.clearRenderer();
    });
};
