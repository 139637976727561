import { toTitleCase } from "../../../src/components/Factory";

export const formatAddress = phrase => {
  const isState = phrase?.split(" ").slice(-2, -1);
  return toTitleCase(phrase)
    .split(" ")
    .map(word => {
      if (word.toUpperCase().includes(isState)) {
        return word.toUpperCase();
      }
      return word;
    })
    .join(" ");
};
