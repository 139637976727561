import qs from "query-string";
import { DateFormat } from "../components/Factory";

const baseLink = window.env.REACT_APP_LEGACY_SITE_URL;
export const OTHER_POLICY_CHANGE_TYPE = "other";
export const ADDITIONAL_INTEREST_POLICY_CHANGE_TYPE = "additional-interest";
export const VEHICLE_CHANGES_POLICY_CHANGE_TYPE = "vehicle-changes";
export const PERSONAL_AUTO_POLICY_CHANGE_TYPE = "personal-auto";
export const HOMEOWNERS_POLICY_CHANGE_TYPE = "homeowners";
export const ADDITIONAL_INTEREST_MORTGAGEE_POLICY_CHANGE_TYPE =
  "additionalinterest-losspayee-mortgagee";

export const getDriverChangeUrl = policy_info => {
  const queryString = qs.stringify({
    polidn: policy_info.policyNumber,
    cobr: policy_info.company_branch,
    polagt: policy_info.agencyNumber
  });
  window.open(
    `${baseLink}/AgentsOnly/ufgAgent/PolicyCenter/codes/linktoDriver.asp?${queryString}`
  );
};

const getPersonalAutoChangeUrl = personalAuto => {
  const queryString = qs.stringify({
    pol: personalAuto.policyNumber,
    cdeagt: personalAuto.activeAgencyCode,
    chgDate: DateFormat(personalAuto.effectiveDate, "MMDDYYYY"),
    typimg: personalAuto.image_type,
    INSNAME: personalAuto.primary_account_name,
    EFF_DT: DateFormat(
      personalAuto.image_policy_date_range.start_date,
      "YYYYMMDD"
    ),
    EXP_DT: DateFormat(
      personalAuto.image_policy_date_range.end_date,
      "YYYYMMDD"
    )
  });
  return `${baseLink}/agentsonly/ufgagent/perslines/policychanges/auto/PPOverview.asp?${queryString}`;
};

const hasCommercialVehicleChangeInProgress = transactionNumber =>
  transactionNumber === "00";

const getCommercialVehicleChangeUrl = commercialVehicle => {
  const changeDate = DateFormat(commercialVehicle.effectiveDate, "YYYYMMDD");

  if (hasCommercialVehicleChangeInProgress(commercialVehicle.transid)) {
    const queryString = qs.stringify({
      pol: commercialVehicle.policyNumber,
      cob: commercialVehicle.company_branch,
      dt: changeDate,
      saved: "Y"
    });
    return `${baseLink}/AgentsOnly/ufgAgent/Changes/CommChanges/checkCommChanges.aspx?${queryString}`;
  }

  const queryString = qs.stringify({
    agencyCode: commercialVehicle.agencyNumber,
    rateState: commercialVehicle.rate_state,
    policyNumber: commercialVehicle.policyNumber,
    companyOrBranchCode: commercialVehicle.company_branch,
    effectiveChangeDate: changeDate,
    transactionID: commercialVehicle.transid,
    effectiveDate: DateFormat(commercialVehicle.effective_date, "YYYYMMDD"),
    expirationDate: DateFormat(commercialVehicle.expiration_date, "YYYYMMDD"),
    insuredName: commercialVehicle.primary_account_name,
    insuredAddress: commercialVehicle.address,
    insuredCityStateZip: commercialVehicle.citystatezip,
    underwriterEmployeeNumber: commercialVehicle.underwriter_number,
    changeID: commercialVehicle.change_id
  });
  return `${baseLink}/AgentsOnly/ufgAgent/Changes/CommChanges/CA/VehChange.aspx?${queryString}`;
};

const getHomeownersChangeUrl = homeowners => {
  const queryString = qs.stringify({
    policy: homeowners.policyNumber,
    changeDate: DateFormat(homeowners.effectiveDate, "MM/DD/YYYY")
  });
  return `${baseLink}/AgentsOnly/ufgAgent/Personal/Changes/HOChanges/default_portal.aspx?${queryString}`;
};

const getPersonalOtherChangeUrl = otherChange => {
  const queryString = qs.stringify({
    tranid: otherChange.transid,
    typofchg: "OT"
  });

  const line =
    otherChange.insurance_type === "commercial" ? "commlines" : "perslines";
  return `${baseLink}/agentsonly/ufgagent/${line}/policychanges/general.asp?${queryString}`;
};

const getAdditionalInterestChangeUrl = additionalInterest => {
  const queryString = qs.stringify({
    tranid: additionalInterest.transid,
    typofchg: "AI"
  });
  return `${baseLink}/agentsonly/ufgagent/perslines/policychanges/additionalInterest.asp?${queryString}`;
};

const getCommercialAdditionalInterestChangeUrl = additionalInterest => {
  const queryString = qs.stringify({
    COB: additionalInterest.company_branch,
    POLNO: additionalInterest.policyNumber,
    EFF_DATE: DateFormat(additionalInterest.effectiveDate, "YYYYMMDD"),
    IND1_1A: additionalInterest.change_indicator,
    page: "AGT",
    txtAgentCode: additionalInterest.agencyNumber
  });
  return `${baseLink}/agentsonly/ufgagent/commlines/includes/gotoACLCE.asp?${queryString}`;
};

export const handleRedirect = processPolicyInfo => {
  switch (processPolicyInfo.changeType) {
    case VEHICLE_CHANGES_POLICY_CHANGE_TYPE:
      window.open(
        getCommercialVehicleChangeUrl({
          ...processPolicyInfo,
          citystatezip:
            processPolicyInfo.city +
            processPolicyInfo.state +
            processPolicyInfo.zip
        })
      );
      break;
    case PERSONAL_AUTO_POLICY_CHANGE_TYPE:
      window.open(
        getPersonalAutoChangeUrl({
          ...processPolicyInfo
        })
      );
      break;
    case HOMEOWNERS_POLICY_CHANGE_TYPE:
      window.open(
        getHomeownersChangeUrl({
          policyNumber: processPolicyInfo.policyNumber,
          effectiveDate: processPolicyInfo.effectiveDate
        })
      );
      break;
    case OTHER_POLICY_CHANGE_TYPE:
      window.open(
        getPersonalOtherChangeUrl({
          transid: processPolicyInfo.transid,
          insuranceType: processPolicyInfo.insurance_type
        })
      );
      break;
    case ADDITIONAL_INTEREST_POLICY_CHANGE_TYPE:
      window.open(
        getAdditionalInterestChangeUrl({
          transid: processPolicyInfo.transid
        })
      );
      break;
    case ADDITIONAL_INTEREST_MORTGAGEE_POLICY_CHANGE_TYPE:
      window.open(
        getCommercialAdditionalInterestChangeUrl({
          ...processPolicyInfo
        })
      );
      break;
    default:
      break;
  }
};
