import { connect } from "react-redux";
import InForceList from "../components/underwriting/in-force-list/InForceList";
import { fetchUnderwritingEmailListDetails } from "../actions/inforceListAction";
import { fetchAdministrator } from "../actions/reportAdministratorAction";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getUnderwritingEmailListIsLoading,
  getUnderwritingEmailList,
  getUnderwritingEmailListError,
  getServiceCenterAccess,
  getIsServiceCenterAccessLoading,
  getErrorServiceCenterAccess
} from "../selectors/underwritingSelector";
import {
  getAdministratorIsLoading,
  getAdministrator,
  getAdministratorError
} from "../selectors/administratorSelector";
import { getisSpecialtyPortalView } from "../../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  isLoading: getUnderwritingEmailListIsLoading(state),
  emailList: getUnderwritingEmailList(state),
  error: getUnderwritingEmailListError(state),
  hasAgencyProductionReportsAccess: sessionSelector.hasAgencyProductionReportsAccess(
    state
  ),
  administratorIsLoading: getAdministratorIsLoading(state),
  administrator: getAdministrator(state),
  administratorError: getAdministratorError(state),
  hasServiceCenterAccess: getServiceCenterAccess(state),
  isServiceCenterAccessLoading: getIsServiceCenterAccessLoading(state),
  errorServiceCenterAccess: getErrorServiceCenterAccess(state),
  isSpecialtyPortalView: getisSpecialtyPortalView(state)
});

const mapDispatchToProps = {
  fetchUnderwritingEmailListDetails,
  fetchAdministrator
};

export default connect(mapStateToProps, mapDispatchToProps)(InForceList);
