import React from "react";
import PropTypes from "prop-types";
import "./CompanyWarning.scss";
import { Icon, Button } from "@ufginsurance/ui-kit";

const CompanyWarning = ({ message, action, onClose, onActionClick }) => {
  return (
    <div className="company-warning">
      <div className="company-warning__icon">
        <Icon icon="fasBullhorn" />
      </div>
      <div className="company-warning__message-group">
        {message}
        <div>
          <Button onClick={onActionClick} isLink inline>
            {action}
          </Button>
        </div>
      </div>
      <div>
        <button
          className="company-warning__close"
          onClick={onClose}
          onKeyPress={onClose}
          aria-label="close news alert"
        >
          X
        </button>
      </div>
    </div>
  );
};

CompanyWarning.propTypes = {
  message: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired
};

export default CompanyWarning;
