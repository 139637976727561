import React from "react";
import cn from "classnames";
import { Button, Panel, Icon } from "@ufginsurance/ui-kit";
import "./MissingItemPanel.scss";

const MissingItemPanel = ({
  className,
  onClickHandler,
  content,
  buttonText,
  vertical = false
}) => (
  <Panel
    className={cn(className, "oq__missing-item__panel")}
    bgcolor="white"
    rounded
    titlebar={false}
  >
    <div
      className={cn("oq__missing-item__container", {
        "oq__missing-item__container-vertical": vertical
      })}
    >
      <Icon
        className="oq__missing-item__warning-icon"
        icon="fasExclamationTriangle"
      />
      <span className="oq__missing-item__content">{content}</span>
      <Button
        variant="info"
        className="oq__missing-item__button"
        icon="farPlus"
        wrapperClassName="oq__missing-item__button-wrapper"
        onClick={onClickHandler}
        size="sm"
      >
        {buttonText}
      </Button>
    </div>
  </Panel>
);

export default MissingItemPanel;
