import * as accountholderService from "../services/accountholderService";
import * as types from "./topActionCardActionTypes";

const fetchPaymentEligibilityStarted = () => ({
  type: types.FETCH_PAYMENT_ELIGIBILITY_STARTED
});

const fetchPaymentEligibilitySucceeded = data => ({
  type: types.FETCH_PAYMENT_ELIGIBILITY_SUCCEEDED,
  payload: data
});

const fetchPaymentEligibilityFailed = error => ({
  type: types.FETCH_PAYMENT_ELIGIBILITY_FAILED,
  payload: error
});

export const activateMakeAPayment = () => ({
  type: types.MAKE_A_PAYMENT_ACTIVATED
});

export const dismissMakeAPayment = dispatch => {
  dispatch({
    type: types.DISMISS_MAKE_A_PAYMENT
  });
};

export const checkPaymentEligibility = (
  search_number,
  agency_number,
  zip_code
) => dispatch => {
  dispatch(fetchPaymentEligibilityStarted());
  accountholderService
    .checkPaymentEligibility(search_number, agency_number, zip_code)
    .then(
      res => {
        dispatch(fetchPaymentEligibilitySucceeded(res.data));
      },
      error => dispatch(fetchPaymentEligibilityFailed(error.response))
    );
};
