import React from "react";
import { withRouter, Redirect, Route, Switch } from "react-router-dom";
import * as routes from "../../constants/routes";
import { SuretyAgentManualsProvider } from "./context";
import Content from "./components/Content";
import * as constants from "./constants";
import "./styles.scss";

const SuretyAgentManuals = ({history}) => {
  const onNavigationChange = (contentId) =>
    history.push(`${routes.SURETY_AGENT_MANUALS}/${contentId}`);

  return (
    <div className={`surety-agent-manuals`}>
      <SuretyAgentManualsProvider>
        <Switch>
          <Redirect exact
            from={routes.SURETY_AGENT_MANUALS}
            to={routes.SURETY_AGENT_MANUALS + "/" + constants.CMS.DEFAULT_CONTENT_ID} />
          <Route path={routes.SURETY_AGENT_MANUALS_CONTENT_PAGE}>
            <Content onNavigationChange={onNavigationChange}/>
          </Route>
        </Switch>
      </SuretyAgentManualsProvider>
    </div>
  );
};

export default withRouter(SuretyAgentManuals);
