import React from "react";
import PropTypes from "prop-types";
import StarRatings from "./StarRatings";

const propTypes = {
  grade: PropTypes.string,
  productCode: PropTypes.string
};

const COMMERCIAL_PRODUCT_CODE = "CP2";
const WORKERS_COMPENSATION_PRODUCT_CODE = "WC2";

const getAppetiteDesirabilityText = grade => {
  switch (grade) {
    case "1":
      return "Not Desirable";
    case "2":
      return "Less Acceptable";
    case "3":
      return "Acceptable";
    case "4":
      return "Desirable";
    case "5":
      return "Very Desirable";
    default:
      return "";
  }
};
const getAppetiteDesirabilityDescription = (grade, productCode) => {
  if (
    productCode === COMMERCIAL_PRODUCT_CODE ||
    productCode === WORKERS_COMPENSATION_PRODUCT_CODE
  ) {
    return "Please contact your underwriter to determine our current risk appetite.";
  }

  switch (grade) {
    case "1":
      return "These risks may need supplemental apps and will require further underwriting information.";
    case "2":
      return "These risks will require further underwriting information and may require supplemental apps before binding coverage.";
    case "3":
      return "These risks have characteristics that may require further underwriting information.";
    case "4":
      return "These risks can generally be bound by agents but may need further underwriting information.";
    case "5":
      return "These risks are generally written and bound by agents without further underwriting necessary.";
    default:
      return "No details are available.";
  }
};

const StarsAndAppetite = ({ grade, productCode }) => {
  const showStarRatings =
    grade &&
    grade > 0 &&
    productCode !== COMMERCIAL_PRODUCT_CODE &&
    productCode !== WORKERS_COMPENSATION_PRODUCT_CODE;

  return (
    <div className="appetite-rating">
      {showStarRatings ? (
        <span>
          <StarRatings grade={grade} />
          <h3 className="desirabilityText">
            {getAppetiteDesirabilityText(grade)}
          </h3>
        </span>
      ) : (
        ""
      )}

      {getAppetiteDesirabilityDescription(grade, productCode)}
    </div>
  );
};

StarsAndAppetite.propTypes = propTypes;
export default StarsAndAppetite;
