import * as types from "../../constants/actionTypes";

export const copyAccountAndBillingActivitySucceeded = (
  accountNumber,
  accountData,
  billingActivity
) => ({
  type: types.COPY_ACCOUNT_BILLING_ACTIVITY_SUCCEEDED,
  accountNumber,
  accountData,
  billingActivity
});

export const copyAccountAndBillingActivity = (
  accountNumber,
  accountData,
  billingActivity
) => dispatch => {
  dispatch(
    copyAccountAndBillingActivitySucceeded(
      accountNumber,
      accountData,
      billingActivity
    )
  );
};
