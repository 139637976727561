/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useContext } from "react";
import _remove from "lodash/remove";
import { Panel, Button, LoadingIndicator } from "@ufginsurance/ui-kit";
import { locationDisplayName, sortByProperty, hasHNO } from "../../shared/util";

import { locationCoverageControls } from "../../shared/dynamicCoverageControls/location";
import { locationCoverages } from "./locationCoverages.js";
import { mergeExclusions } from "../../shared/helpers/mergeExclusions";
import { productKeys } from "../../shared/constants";
import CoveragesInCards, {
  displayedCoverages,
  isMissingRequiredTerms
} from "../CoveragesInCards";
import ConfirmationModal from "../../shared/ConfirmationModal";
import LocationModalWizard from "./LocationModalWizard";
import LocationCardContents from "./LocationCardContents";
import OnlineQuotingContext from "../../OnlineQuotingContext";
import "../../Step5RiskInfo.scss";
import { toast } from "react-toastify";
import * as routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";

const LocationCards = ({ locations, isQuoteSummary, coverageExclusions }) => {
  const {
    quoteData,
    supportingData,
    updateCoverablesPromise,
    toastErrr,
    updateSupportingDataPromise
  } = useContext(OnlineQuotingContext);
  const history = useHistory();

  const [showItemForm, setShowItemForm] = useState(false);
  const [activeLocation, setActiveLocation] = useState();
  const [itemToDelete, setItemToDelete] = useState();

  const onDeleteLocation = () => {
    const id = itemToDelete.id;
    const newItemsArray = [...locations];
    _remove(newItemsArray, b => b.id === id);

    updateCoverablesPromise({
      coverableType: "location",
      coverables: { locations: newItemsArray },
      action: "Deleting"
    })
      .catch(({ error }) => {
        toastErrr({
          action: "onDeleteLocation",
          description: "failed to delete location",
          error,
          misc: { itemToDelete, locations: newItemsArray },
          displayMessage: "Failed to remove location."
        });
      })
      .finally(() => setItemToDelete(null));
  };

  const deleteActionCancelled = () => {
    setItemToDelete(null);
  };

  const openItemEdit = location => {
    setActiveLocation(location);
    setShowItemForm(true);
  };

  const onHideModal = () => {
    setShowItemForm(false);
    setActiveLocation(null);
  };

  const getCoverages = location => {
    //OOQ-11821 show toast message when windowStorm or Hail Deductible is set to 2% or5%
    const locCovers = (
      locationCoverages(
        quoteData,
        location?.bp7LocationFixedID,
        "bp7BusinessOwners"
      ) || []
    ).filter(c => c.selected === true);

    const hasWindHailDed25 = locCovers.find(c =>
      (c?.terms || []).some(
        t =>
          t.publicID === "BP7WindHailDed" &&
          [
            "zl1jmja88chnv4hugk7rkdvimfb", //1%
            "zmsgearh45cla18upe3cnofki18", //2%
            "z12ji7silh8so4fgjc8nb1r3g4a" //5%
          ].includes(t.chosenTerm)
      )
    );
    const _showtoastLocations = supportingData?.showOnceToast?.locations || [];
    const toastDedLoc = _showtoastLocations.find(
      l => l.fixedID === location?.bp7LocationFixedID && !!l.windHailDed25
    );

    if (
      !toast.isActive("hasWindHailDed25Toast") &&
      !!hasWindHailDed25 &&
      !toastDedLoc
    ) {
      //Show the toast message only once to the user per location.

      _showtoastLocations.push({
        fixedID: location?.bp7LocationFixedID,
        windHailDed25: true
      });

      const payload = {
        dataToMergeAndSave: {
          showOnceToast: {
            locations: _showtoastLocations
          }
        }
      };
      updateSupportingDataPromise(payload);

      toast(
        `Windstorm or Hail Percentage Deductible has been updated on location(s) ${locationDisplayName(
          location
        )}.`,
        {
          toastId: "hasWindHailDed25Toast",
          position: "top-center",
          className: "warning",
          autoClose: true
        }
      );
    }

    return locCovers
      .concat(
        locationCoverages(
          quoteData,
          location.wcmLocationFixedID,
          "wcmWorkersComp"
        ) || []
      )
      .filter(c => c.selected === true);
  };

  const LocationLinesOfBusiness = ({ location }) => (
    <>
      {(location?.linesOfBusiness || [])
        //OOQ-4428 hide autoLine indicator if any of HNO Auto are selected
        .filter(l => {
          // if quote has HNO, then filter out Auto
          if (hasHNO(supportingData)) return l !== "ca7CommAuto";
          // otherwise, return the product
          return true;
        })
        .map(p => productKeys[p].label)
        .join(", ")}
    </>
  );

  return (
    <div>
      {itemToDelete && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <>
              <div>
                Are you sure you want to remove location
                <br />
                {locationDisplayName(itemToDelete)}?
              </div>
            </>
          }
          onClickYes={onDeleteLocation}
          onClickNo={deleteActionCancelled}
          show
        />
      )}
      {showItemForm && (
        <LocationModalWizard
          show={showItemForm}
          locations={locations}
          name="Location"
          itemData={activeLocation}
          onHide={onHideModal}
          coverageExclusions={coverageExclusions}
        />
      )}
      <Panel
        id="oq__coverable__container__location"
        rounded={!isQuoteSummary}
        noBorder={isQuoteSummary}
        titlebar
        isOpen
        bgcolor={isQuoteSummary ? undefined : "grey"}
        className="oq__coverable__container oq__coverable__container__location"
        title={
          <div className="flexRow flexRow__full-width flexRow__centered-vertically">
            <span className="oq__data-section__title">
              Locations ({locations.length})
            </span>
            {isQuoteSummary && (
              <Button
                variant="outline"
                wrapperClassName="align-right"
                isLink
                inline
                className="link-not-bolded"
                onClick={() => {
                  history.push({
                    pathname: routes.ONLINE_QUOTING_STEP5,
                    hash: "Locations"
                  });
                }}
              >
                Back to Locations
              </Button>
            )}
          </div>
        }
      >
        {locations.sort(sortByProperty("id")).map(location => {
          const mergedExclusions = mergeExclusions([
            coverageExclusions,
            locationCoverageControls({
              location,
              quoteData,
              supportingData
            })
          ]);

          const coverages = displayedCoverages({
            coverages: getCoverages(location),
            coverageExclusions: mergedExclusions
          });

          return (
            <Panel
              id={`container__locatio__${location?.id}`}
              key={`container__locatio__${location?.id}`}
              collapsible={!isMissingRequiredTerms({ coverages })}
              isOpen
              rounded
              className="oq__coverable__card oq__coverable__card__location"
              title={
                <div>
                  <div className="oq__coverable__card__initial-content__container">
                    <span className="oq__coverable__card__initial-content">
                      <span className="oq__coverable__card__initial-content__title">
                        {locationDisplayName(location)}
                      </span>
                      <span className="oq__coverable__card__initial-content__secondary">
                        <LocationLinesOfBusiness location={location} />
                      </span>
                    </span>
                  </div>
                </div>
              }
            >
              <div className="oq__coverable__location-wrapper">
                <LocationCardContents
                  location={location}
                  setActiveLocation={setActiveLocation}
                  coverageExclusions={mergedExclusions}
                />
                {!coverageExclusions ? (
                  <LoadingIndicator
                    message="Loading Coverages"
                    type="spinner"
                    className="oq__view__coverage__loading-metadata"
                  />
                ) : (
                  getCoverages(location) && (
                    <div className="oq__coverable__card__content__coverages">
                      <CoveragesInCards
                        quoteData={quoteData}
                        coverageFields={coverages}
                        coverageExclusions={mergedExclusions}
                        id={`coverages__location__${location.id}`}
                        alwaysDisplay
                        editScheduledItems={!isQuoteSummary}
                        handleEditItem={() => openItemEdit(location)}
                      />
                    </div>
                  )
                )}
                {!isQuoteSummary && (
                  <div className="oq__coverable__card__actions">
                    <Button
                      wrapperClassName="oq__coverable__card__edit"
                      className="oq__coverable__card__edit-button"
                      inline
                      isLink
                      onClick={() => {
                        openItemEdit(location);
                      }}
                    >
                      Edit Location and Coverages
                    </Button>
                    {!location?.isPrimary && (
                      <Button
                        wrapperClassName="oq__coverable__card__delete"
                        isLink
                        inline
                        variant="tertiary"
                        onClick={() => setItemToDelete(location)}
                      >
                        Remove Location
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Panel>
          );
        })}
        {!isQuoteSummary && (
          <div>
            <Button
              block
              variant="outline"
              className="oq__coverable__card__add__button"
              icon="farPlus"
              onClick={() => {
                openItemEdit(null);
              }}
            >
              Add Location
            </Button>
          </div>
        )}
      </Panel>
    </div>
  );
};

export default LocationCards;
