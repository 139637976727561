import * as types from "./helpDeskActionTypes";
import * as helpdeskService from "./helpDeskService";
import { logger } from "../loggers";

export const activateHelpDeskCard = () => ({
  type: types.HELPDESK_ACTIVATED
});

export const dismissHelpDesk = () => ({
  type: types.DISMISS_HELPDESK
});
const fetchHelpDeskServiceStarted = () => ({
  type: types.HELPDESK_SERVICE_STARTED
});

const fetchHelpDeskServiceSucceeded = data => ({
  type: types.HELPDESK_SERVICE_SUCCEEDED,
  payload: data
});

const fetchHelpDeskServiceFailed = error => ({
  type: types.HELPDESK_SERVICE_FAILED,
  payload: error
});
export function getIsActivatedSelector(store) {
  return store.helpDesk.isActivated;
}

export function getHelpDeskErrorSelector(store) {
  return store.helpDesk.helpDeskError;
}

export function getisLoadingSelector(store) {
  return store.helpDesk.isLoading;
}

export function getHelpDeskDetailsSelector(store) {
  return store.helpDesk.helpDeskDetails;
}

export const fetchHelpDeskService = () => dispatch => {
  dispatch(fetchHelpDeskServiceStarted());
  return helpdeskService.getHelpDeskDetails().then(
    response => {
      dispatch(fetchHelpDeskServiceSucceeded(response.data));
    },
    error => {
      dispatch(fetchHelpDeskServiceFailed(error));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};
