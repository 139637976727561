import React, { useContext } from "react";
import { Button, formatDate } from "@ufginsurance/ui-kit";
import QuotingUfgHelperContext from "../QuotingUfgHelperContext";
import { productKeys } from "../../shared/constants";
import "./StoreQuotesLocally.scss";

const QuoteCard = ({ quote: q, type }) => {
  const { loadQuote, deleteQuote } = useContext(QuotingUfgHelperContext);

  return (
    <div className="oq__dev__load__item" key={q?.quoteId}>
      <Button
        block
        onClick={() =>
          loadQuote({
            agent: q?.agent,
            quoteId: q?.quoteId
          })
        }
      >
        <div className="flx">
          <div>
            <b>{q?.account}</b>
          </div>
          <div>
            <em>Effective: </em>
            {formatDate(q?.effectiveDate, "M/D/YYYY")}
          </div>
        </div>
        <div className="flx">
          <div>{q?.address}</div>
          <div>
            <em>Agent: </em>
            {q?.agent}
          </div>
        </div>
        <div className="flx top">
          <div>
            <em>
              Class:{q?.classCode} - {q?.classDescription} ({q?.bizType})
            </em>
          </div>
          <span className="nowrap">
            <em>Quote: </em>
            {q?.quoteId}
          </span>
        </div>
        <div className="flx">
          <div className="oq__dev__load__products">
            {q?.policyLines.map(l => (
              <span className={l} key={l}>
                {productKeys[l]?.label}
              </span>
            ))}
          </div>
          {q?.timestamp && (
            <div>
              <em>Viewed: </em>
              <>{new Date(q?.timestamp).toLocaleString()}</>
            </div>
          )}
        </div>
        {window.location.hostname.includes("localhost") && q.env && (
          <div className="flx">
            <div className=""></div>
            <div>localhost: {q.env}</div>
          </div>
        )}
      </Button>

      <Button
        icon="farTimes"
        onClick={() => deleteQuote({ id: q.quoteId, type })}
        isLink
      ></Button>
    </div>
  );
};

export default QuoteCard;
