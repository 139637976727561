import React, { useState, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Panel,
  FormatDate,
  Button,
  Modal,
  Form,
  FormGroup,
  useForm,
  DatePicker,
  FlexRow
} from "@ufginsurance/ui-kit";
import { toTitleCase, DateFormat } from "../../components/Factory";
import { Translations } from "../../components/translations";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as routes from "../../constants/routes";
import { POLICY_WIZARD_STEPS } from "../constants_helpers";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const EFFECTIVE_DATE_UPDATE_SUCCESS_MESSAGE =
  "The effective date of this change has been successfully updated.";

const SidebarChangeInformation = props => {
  const { policyInfo, requestChange, putPolicyChange } = props;

  const policyChangesStepRouteMatch = useRouteMatch(routes.POLICY_CHANGES_STEP);
  const { step } = policyChangesStepRouteMatch.params;

  const end_date = requestChange?.effective_date_range?.end_date;
  const start_date = requestChange?.effective_date_range?.start_date;

  const maxDate = useMemo(() => {
    return !end_date ? new Date() : new Date(moment(end_date).format());
  }, [end_date]);

  const minDate = useMemo(() => {
    return !start_date ? new Date() : new Date(moment(start_date).format());
  }, [start_date]);

  const [showEffectiveDateModal, setShowEffectiveDateModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const initialValues = useMemo(() => {
    const effectiveDate = !policyInfo?.effective_date
      ? new Date()
      : new Date(moment(policyInfo.effective_date).format());

    return {
      effectiveDate
    };
  }, [policyInfo?.effective_date]);

  const displayToast = (success = false) => {
    toast.dismiss();

    const message = success ? (
      EFFECTIVE_DATE_UPDATE_SUCCESS_MESSAGE
    ) : (
      <HelpDeskMessage display="anErrorOccurred" />
    );

    const options = {
      position: "top-center",
      className: success ? "success" : "error",
      autoClose: success
    };

    toast(message, options);
  };

  const handleSubmission = values => {
    setIsSubmitLoading(true);
    const formattedDate = DateFormat(values.effectiveDate, "YYYY-MM-DD");

    putPolicyChange(
      policyInfo.policy_number,
      policyInfo.job_id,
      formattedDate
    ).then(
      res => {
        setIsSubmitLoading(false);

        if (res && !res.error_code) {
          displayToast(true);
          onModalClose();
        } else {
          displayToast();
        }
      },
      _err => {
        setIsSubmitLoading(false);
        displayToast();
      }
    );
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const {
    values,
    errors,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    handleOnChange
  } = form;

  const submitDisabled = useMemo(() => {
    const errorsExist = Object.keys(errors).length >= 1;

    const sameEffectiveDate =
      !!policyInfo?.effective_date &&
      !!values.effectiveDate &&
      DateFormat(values.effectiveDate, "YYYY-MM-DD") ===
        policyInfo.effective_date;
    const isDateOutOfBounds =
      values.effectiveDate < minDate || values.effectiveDate > maxDate;

    const shouldDisable =
      values.effectiveDate === "" ||
      sameEffectiveDate ||
      isDateOutOfBounds ||
      errorsExist;

    return shouldDisable;
  }, [values.effectiveDate, errors, policyInfo, minDate, maxDate]);

  const resetForm = () => {
    setIsSubmitLoading(false);
    updateForm({
      values: initialValues
    });
  };

  const onModalClose = () => {
    setShowEffectiveDateModal(false);
    resetForm();
  };

  return (
    <>
      <Panel
        className="pol__sidebar__summary"
        highlight="blue"
        title="Change Information"
      >
        <div className="pol__effective-date__wrapper">
          {
            <div
              className="pol__sidebar__summary__item "
              id="effectiveDateOfChange"
            >
              <b>Effective Date of Change:</b>
              <FormatDate
                className="pol__sidebar__summary__effective-date"
                date={policyInfo?.effective_date ?? ""}
                format="MM/DD/YYYY"
              />
              {POLICY_WIZARD_STEPS.ONE === step && (
                <Button
                  isLink
                  inline
                  className={`policy-change-edit-icon`}
                  onClick={() => {
                    resetForm();
                    setShowEffectiveDateModal(true);
                  }}
                >
                  Edit Effective Date
                </Button>
              )}
            </div>
          }
        </div>
        <div className="pol__sidebar__summary__item business-information">
          <b>Business Information:</b>
          <p id="businessAccountName">{policyInfo?.account_name ?? ""}</p>
          <p id="businessAddress">
            {toTitleCase(policyInfo?.policy_address?.address1 ?? ``)}
          </p>
          <div className="flexRow wrap">
            <span id="businessCity" className="pol__sidebar__summary__city">
              {toTitleCase(policyInfo?.policy_address?.city ?? ``)}
            </span>
            ,
            <span id="businessState" className="pol__sidebar__summary__state">
              {(policyInfo?.policy_address?.state ?? ``).toUpperCase()}
            </span>
            <span
              id="businessZip"
              className="pol__sidebar__summary__postalCode"
            >
              {policyInfo?.policy_address?.zip ?? ``}
            </span>
          </div>
        </div>
        <div className="pol__sidebar__summary__item">
          <b>Policy Number:</b>
          <p id="policyNumber">{policyInfo?.policy_number} </p>
        </div>

        <div className="pol__sidebar__summary__item">
          <b>Policy Term:</b>
          <p id="policyTerm">
            {`${DateFormat(
              policyInfo?.policy_effective_date ?? ""
            )} to ${DateFormat(policyInfo?.policy_expiration_date ?? "")}`}{" "}
          </p>
        </div>

        <div className="pol__sidebar__summary__item">
          <b>Product(s):</b>
          <p id="policyProducts">{(policyInfo?.products ?? []).join(", ")} </p>
        </div>
      </Panel>

      <Modal
        onHide={onModalClose}
        show={showEffectiveDateModal}
        title="Edit Effective Date"
        size="sm"
        body={
          <Form className="" context={form}>
            <FormGroup>
              <DatePicker
                id="effectiveDate"
                name="effectiveDate"
                label={
                  Translations.account_overview.changeRequests.effective_date
                }
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.effectiveDate}
                required
                className="effective-date"
                minDate={minDate}
                maxDate={maxDate}
              />
            </FormGroup>

            <FlexRow align="right" className="effective-date-modal-buttons">
              <Button
                id="effective-date-modal-cancel-button"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                id="effective-date-modal-submit-button"
                type="submit"
                variant="primary"
                spinnerPos="right"
                spinner={isSubmitLoading}
                disabled={submitDisabled || isSubmitLoading}
                onClick={() => {
                  handleSubmission(values);
                }}
                data-component="effective-date-modal-submit"
              >
                Update
              </Button>
            </FlexRow>
          </Form>
        }
      />
    </>
  );
};

SidebarChangeInformation.propTypes = {
  policyInfo: PropTypes.object,
  requestChange: PropTypes.object,
  putPolicyChange: PropTypes.func.isRequired
};

export default SidebarChangeInformation;
