import { mergeExclusions } from "../../helpers/mergeExclusions";

// OOQ-4429 filter stateExclusion by state/coverableName just for ca7CommAuto
export const generalCoverageControls = ({ supportingData }) => {
  // more hard-coded logic
  // OOQ-10422 Hide Funeral Director Medical Payments Coverage for all
  // classes other than 71865 Funeral Homes or Chapels
  const exclusionArray = [];

  if (supportingData?.classCode?.code !== "71865")
    exclusionArray.push({
      formData: {
        coverageControl: [
          {
            codeIdentifier: "CA7FuneralDirectorMedPay",
            control: "hidden"
          }
        ]
      }
    });

  return mergeExclusions(exclusionArray);
};
