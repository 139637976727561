import React, { useState, useContext } from "react";
import * as api from "../../services/onlineQuotingService";
import { Panel, Switch, useForm, Form } from "@ufginsurance/ui-kit";
import OnlineQuotingContext from "../OnlineQuotingContext";
import "../shared/CoverageTitle.scss";

const HiredAutoToggles = () => {
  const {
    quoteData,
    updateQuote,
    supportingData,
    toastErrr,
    closeUpdatingToast,
    showUpdatingToast
  } = useContext(OnlineQuotingContext);

  const initialValues = {
    both_hno:
      !!supportingData?.packages?.hiredNonOwnedAuto?.hiredAuto &&
      !!supportingData?.packages?.hiredNonOwnedAuto?.nonOwnedAuto,
    hiredAuto: !!supportingData?.packages?.hiredNonOwnedAuto?.hiredAuto,
    nonOwnedAuto: !!supportingData?.packages?.hiredNonOwnedAuto?.nonOwnedAuto
  };

  const [previousValues, setPreviousValues] = useState(initialValues);

  const form = useForm({ values: initialValues });
  const { values, handleOnBlur, handleOnValidate, updateForm } = form;

  // on change event for HNO toggles
  const hnoTogglesOnChange = ({ field, value }) => {
    const newValues =
      field === "both_hno"
        ? { ...values, hiredAuto: value, nonOwnedAuto: value }
        : { ...values, [field]: value };

    if (!value) newValues.both_hno = false;
    if (newValues.hiredAuto && newValues.nonOwnedAuto)
      newValues.both_hno = true;

    const toastId = Math.random();
    const message = "Updating Hired and Non-owned Auto";
    showUpdatingToast({ message, toastId });

    updateForm({ values: newValues });

    api
      .saveHiredNonOwnedAuto(quoteData.quoteID, newValues)
      .then(result => {
        updateQuote({
          newData: result?.data?.dto,
          newSupportingData: result?.data?.supportingData,
          mergeData: false,
          sendToPC: false
        });
        setPreviousValues(newValues);
        closeUpdatingToast({ toastId });
      })
      .catch(error => {
        closeUpdatingToast({ toastId });
        toastErrr({
          action: "saveHiredNonOwnedAuto",
          description: "failure saving HNO",
          error,
          payload: { quoteId: quoteData.quoteID, values }
        });
        // if there was a problem with the api call, reset the form to what it was set before the call
        updateForm({ values: previousValues });
      });
  };

  return (
    <Form context={form}>
      <Panel className="oq__hno-panel">
        {
          <>
            <Switch
              id="both_hno"
              name="both_hno"
              label="Hired and Non-owned Auto (Add Both)"
              noLabel
              className="oq__hno-both-switch"
              onChange={hnoTogglesOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.both_hno}
              size="auto"
            />
            <Switch
              id="hiredAuto"
              name="hiredAuto"
              label="Hired Auto"
              onChange={hnoTogglesOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.hiredAuto}
              size="auto"
            />
            <Switch
              id="nonOwnedAuto"
              name="nonOwnedAuto"
              label="Non-owned Auto"
              onChange={hnoTogglesOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.nonOwnedAuto}
              size="auto"
            />
          </>
        }
      </Panel>
    </Form>
  );
};

export default HiredAutoToggles;
