import React, { useState } from "react";
import { Icon, Button, Modal, FlexRow } from "@ufginsurance/ui-kit";
import "./ConsentSuretySteps.scss";

const ConsentOfSuretyHeader = () => {
  const [showMessageModal, setShowMessageModal] = useState(false);

  const saveAndExit = () => {
    window.close();
  };

  return (
    <div>
      <Modal
        title={"Exit Confirmation"}
        className="cos__ufg-modal"
        body={
          "Data entered will NOT be saved if you exit at this time. Are you sure you want to Exit?"
        }
        footer={
          <FlexRow align="right">
            <Button
              wrapperClassName="cos_cancel"
              isLink
              inline
              onClick={() => {
                setShowMessageModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              wrapperClassName="cos_exit"
              variant="primary"
              onClick={() => {
                saveAndExit();
                setShowMessageModal(false);
              }}
            >
              Exit
            </Button>
          </FlexRow>
        }
        show={showMessageModal}
        onHide={() => setShowMessageModal(false)}
        altCloseMethod={false}
        size="sm"
      />

      <div id="CoS__header">
        <div id="CoS_menu" className="cos__main-nav">
          <div className="headerMenu__navbar-container">
            <ul className="main-nav__nav-list">
              <li role="presentation" className="route-item">
                <button className="cos-exit" onClick={() => setShowMessageModal(true)}>
                  <span>
                    <Icon icon="farAngleLeft" /> EXIT
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentOfSuretyHeader;
