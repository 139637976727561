import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Anchor, Icon } from "@ufginsurance/ui-kit";
import { useCMSContext } from "./context";

const Breadcrumbs = ({ links, onBaseRouteClick, defaultBreadcrumbText }) => {
  const history = useHistory();
  const { tableOfContentId } = useCMSContext();

  const isCurrent = id => {
    return id === tableOfContentId;
  };

  return (
    <div className="cms-layout__breadcrumbs">
      <Anchor className="breadcrumb__link" onClick={onBaseRouteClick}>
        {defaultBreadcrumbText}
        {<Icon size="lg" icon="farAngleRight" />}
      </Anchor>

      {links.map((link, index) => {
        const linkId = `breadcrumb_link_${link?.id}`.replace('undefined', index).replace('null', index)
        return isCurrent(link?.id) ? (
          <Anchor
            key={linkId}
            className={`breadcrumb__link breadcrumb__link--disabled`}
            onClick={() => {}}
          >
            {link?.description}
          </Anchor>
        ) : (
          <Anchor
          key={linkId}
            className={`breadcrumb__link`}
            onClick={() => {
              history.replace(link.link);
            }}
          >
            {link?.description}
            {<Icon key={`icon-${link?.id}`} size="lg" icon="farAngleRight" />}
          </Anchor>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  onBaseRouteClick: PropTypes.func.isRequired,
  defaultBreadcrumbText: PropTypes.string.isRequired
};

export default Breadcrumbs;
