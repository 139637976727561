//injected in Containers via mapStateToProps

export const getInvoiceDetailsLoading = state => {
  return !!(
    state &&
    state.agencySweepOasisInvoiceDetail &&
    state.agencySweepOasisInvoiceDetail.isLoading
  );
};

export const getInvoiceDetailsError = state => {
  return (
    state &&
    state.agencySweepOasisInvoiceDetail &&
    state.agencySweepOasisInvoiceDetail.error
  );
};

export const hasBillingInformation = state => {
  return !!(
    state &&
    state.agencySweepOasisInvoiceDetail &&
    state.agencySweepOasisInvoiceDetail.billingInformation &&
    state.agencySweepOasisInvoiceDetail.billingInformation.invoice_information
  );
};

export const getBillingInformationAccountNumber = state => {
  return (
    state &&
    state.agencySweepOasisInvoiceDetail &&
    state.agencySweepOasisInvoiceDetail.billingInformation &&
    state.agencySweepOasisInvoiceDetail.billingInformation
      .billing_account_number
  );
};

export const getInvoiceDetails = state => {
  if (!hasBillingInformation(state)) return [];
  const { billingInformation } = state.agencySweepOasisInvoiceDetail;
  const { active_invoices = [], future_invoices = [], next_invoice = null } =
    (billingInformation && billingInformation.invoice_information) || {};
  const invoiceDetails = next_invoice ?
    [next_invoice, ...active_invoices, ...future_invoices] :
    [...active_invoices, ...future_invoices];
  let allInvoices = [];
  const completeInvoiceData = {
    accountPolicy: billingInformation.billing_account_number,
    insuredKey: `${billingInformation.billing_account_number}-${billingInformation.payor}`,
    insured: billingInformation.payor,
    minimum: active_invoices
      .map(o => o.amount_due && o.amount_due.amount)
      .reduce((a, b) => Number(a) + Number(b), 0),
    balance: invoiceDetails
      .map(o => o.amount_due && o.amount_due.amount)
      .reduce((a, b) => Number(a) + Number(b), 0),
    enterPaymentAmount: "",
    input: true
  };
  invoiceDetails.forEach(item => {
    allInvoices = allInvoices.concat(item.invoice_items);
  });
  let activeInvoiceItems = [];
  active_invoices.forEach(item => {
    activeInvoiceItems = activeInvoiceItems.concat(item.invoice_items);
  });
  const policyDetails = [];
  allInvoices.forEach(item => {
    const hasExist = policyDetails.some(
      o => o.insured === item.charge_name && o.policy_id === item.policy_id
    );
    if (!hasExist) {
      const ownInvoices = allInvoices.filter(
        o => o.charge_name === item.charge_name
      );
      policyDetails.push({
        accountPolicy: item.policy_id,
        insuredKey: `${item.policy_id}-${item.charge_name}`,
        insured: item.charge_name,
        minimum: activeInvoiceItems
          .filter(
            o =>
              o.charge_name === item.charge_name &&
              o.policy_id === item.policy_id
          )
          .map(o => o.unsettled_gross_amount && o.unsettled_gross_amount.amount)
          .filter(o => o)
          .reduce((a, b) => Number(a) + Number(b), 0),
        balance: ownInvoices
          .map(o => o.unsettled_gross_amount && o.unsettled_gross_amount.amount)
          .filter(o => o)
          .reduce((a, b) => Number(a) + Number(b), 0),
        enterPaymentAmount: "",
        policy_id: item.policy_id
      });
    }
  });
  return [completeInvoiceData, ...policyDetails];
};

export const getBillingSummary = state => {
  if (!hasBillingInformation(state)) return [];
  const { billingInformation = {} } = state.agencySweepOasisInvoiceDetail;
  const { policy_billing_summary } = billingInformation;
  return policy_billing_summary || [];
};
//utility functions

export const createVerifyPaymentDetails = (totalPayment, invoiceDetails) => {
  const accountLineItem = invoiceDetails && invoiceDetails[0];
  const paymentLineItems = [
    {
      ...accountLineItem,
      billed_by_type: "account",
      payment_amount: totalPayment
    }
  ];
  return {
    lineItems: paymentLineItems,
    totalPaymentAmount: totalPayment
  };
};
