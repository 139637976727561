export function getUnderwritingEmailListIsLoading(store) {
  return store.underwritingProducerEmailList.isLoading;
}

export function getUnderwritingEmailList(store) {
  return store.underwritingProducerEmailList.emailList;
}

export function getUnderwritingEmailListError(store) {
  return store.underwritingProducerEmailList.error;
}

export function getIsServiceCenterAccessLoading(store) {
  return store.underwritingProducerEmailList.isServiceCenterAccessLoading;
}
export const getServiceCenterAccess = store => {
  return (
    store.underwritingProducerEmailList.serviceCenterAccess &&
    !!store.underwritingProducerEmailList.serviceCenterAccess.is_service_center
  );
};

export function getErrorServiceCenterAccess(store) {
  return store.underwritingProducerEmailList.errorServiceCenterAccess;
}
