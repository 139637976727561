import { useRouteMatch } from "react-router-dom";
import * as routes from "../../constants/routes";

export const useRootRouteMatch = () => {
  return useRouteMatch(routes.YOUR_UFG_TEAM_ROOT);
};

export const useDepartmentRouteMatch = () => {
  return useRouteMatch(routes.YOUR_UFG_TEAM_DEPARTMENT_ID);
};
