import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";
import { Translations } from "../../components/translations";
import PolicySearchBarContainer from "../../containers/PolicySearchBarContainer";
import PropTypes from "prop-types";

const AccountHome = ({ isSpecialtyPortalView }) => {
  const accountHeaderText = isSpecialtyPortalView
    ? Translations.Name_Search.specialty_broker_hint
    : Translations.Name_Search.hint;

  return (
    <div className="account-static">
      <ContentHeader>{Translations.navigation.account}</ContentHeader>
      <div className="row">
        <div className="col-sm-12 m-t">{accountHeaderText}</div>
      </div>
      <div className="row">
        <div className="hdr-search-search m-t">
          <label htmlFor="AccountPolicySearchBarContainer">
            {Translations.Name_Search.Search_header}
          </label>
          <PolicySearchBarContainer
            id="AccountPolicySearchBarContainer"
            iconEnable={false}
            buttonText={Translations.Name_Search.Button_Text}
            searchPlaceholder={Translations.Name_Search.Search_placeholder}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountHome;

AccountHome.propTypes = {
  isSpecialtyPortalView: PropTypes.bool
};
