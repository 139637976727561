import React, { useContext } from "react";
import { Button, Form, useForm, FormGroup, Input } from "@ufginsurance/ui-kit";

import QuotingUfgHelperContext from "./QuotingUfgHelperContext";

const LoadQuoteByNumber = () => {
  const { loadQuote, userAgentCode } = useContext(QuotingUfgHelperContext);

  // initialize the hook
  const form = useForm({
    values: { quoteNum: "", userAgentCode },
    onSubmit: () => {
      loadQuote({
        agent: values.userAgentCode,
        quoteId: values.quoteNum
      });
    }
  });

  // get access to the methods available in the hook
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <Form context={form}>
      <FormGroup>
        <Input
          id="userAgentCode"
          name="userAgentCode"
          label="Agent"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.userAgentCode}
          size="50p"
          required
        />
        <Input
          id="quoteNum"
          name="quoteNum"
          label="Submission #"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.quoteNum}
          size="50p"
        />
      </FormGroup>
      <Button
        inline
        wrapperClassName="oq__helper__load-quote"
        labelSpace
        disabled={
          values.quoteNum === "" && values.userAgentCode === userAgentCode
        }
        type="submit"
      >
        {values.quoteNum === "" &&
          values.userAgentCode !== userAgentCode &&
          "Change Agent"}
        {values.quoteNum !== "" && "Load Quote"}
      </Button>
    </Form>
  );
};

export default LoadQuoteByNumber;
