import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatDate, Button } from "@ufginsurance/ui-kit";
import { toast } from "react-toastify";
import OtherChangesModal from "./OtherChangesModal";
import { otherChangePolicyChangeReport } from "../redux/policyChangesService";
import { DocumentService } from "../../services/documentService";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const OtherChanges = ({
  policyInfo,
  effectiveDate,
  minDate,
  maxDate,
  updateParentEffectDate
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [Pdfdata, setpdfdata] = useState(null);
  const [documentsLoading, setDocumentsLoading] = useState(false);

  const printSummaryPdf = data => {
    const documentService = new DocumentService();
    setDocumentsLoading(true);
    otherChangePolicyChangeReport(data).then(
      res => {
        if (res && res.data) {
          documentService.initializeRenderer("/loading-document");
          documentService.saveOrOpenDocument(
            res.data,
            "other_changes_summary_report.pdf"
          );
          setDocumentsLoading(false);
        }
      },
      () => {
        documentService.clearRenderer();
        setDocumentsLoading(false);
        displayToastError();
      }
    );
  };

  const displayToastError = () => {
    const message = (
      <span>
        We&apos;re sorry, we could not locate the requested document.{" "}
        <HelpDeskMessage display="questionsOrAssistance" />
      </span>
    );
    const options = {
      position: "top-center",
      className: "error",
      autoClose: false
    };

    toast(message, options);
  };

  const temppdfData = data => {
    setpdfdata(data);
  };

  return (
    <>
      <OtherChangesModal
        policyInfo={policyInfo}
        updateParentEffectDate={updateParentEffectDate}
        effectiveDate={formatDate(effectiveDate, "MM/DD/YYYY")}
        setShowSuccessModal={setShowSuccessModal}
        printPDFdata={temppdfData}
        minDate={minDate}
        maxDate={maxDate}
      />
      <ConfirmationModal
        id="other-changes__success-modal"
        className="other-changes-success-modal"
        show={showSuccessModal}
        altCloseMethod
        title="Change Request Confirmation"
        message={
          <>
            <p>
              Thank you for submitting your policy change request at{" "}
              <a href="https://ufgagent.com">ufgagent.com</a>. Your request has
              been sent directly to our underwriting team. Once it is processed,
              we will send you a revised copy of the policy. If you do not
              receive a revised copy within 30 days, please contact your UFG
              underwriter.
            </p>
            <p></p>
            <Button
              id="other-changes-modal-summary-report"
              type="submit"
              isLink
              spinnerPos="right"
              spinner={documentsLoading}
              onClick={() => {
                printSummaryPdf(Pdfdata);
              }}
              className="summary-link"
            >
              Print Change Summary
            </Button>
            <p></p>
            <p>
              All policy change requests are subject to review by a UFG
              underwriter. UFG reserves the right to reject a change request if
              it fails to comply with our underwriting guidelines. Any questions
              about policy coverages or coverage effective dates should be
              directed to your UFG underwriter.
            </p>
          </>
        }
        footer={
          <>
            <Button
              id="other-changes-modal-close-button"
              onClick={() => setShowSuccessModal(false)}
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
};

OtherChanges.propTypes = {
  policyInfo: PropTypes.object,
  effectiveDate: PropTypes.any,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  updateParentEffectDate: PropTypes.func
};

const mapStateToProps = state => ({
  isActivated: state.otherChangesModalData.isActivated
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OtherChanges);
