import React, { useContext, useEffect } from "react";

import { FormGroup, Select, Form, useForm } from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../OnlineQuotingContext";

const ProducerField = ({
  formMetadata,
  formIsValid,
  setFormIsValid,
  triggerFormValidation
}) => {
  const { quoteData, patchQuotePromise, toastErrr } =
    useContext(OnlineQuotingContext);

  const producer_code =
    quoteData?.baseData?.producerCodeOfRecord_UFG?.code || "";

  const form = useForm({ values: { producer_code }, onSubmit: () => {} });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    invalidFields,
    validateForm
  } = form;

  useEffect(() => {
    const isValid = !invalidFields.length;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [formIsValid, setFormIsValid, invalidFields]);

  useEffect(() => {
    if (triggerFormValidation) validateForm();
  }, [triggerFormValidation, validateForm]);

  // On changing the producer code update to PC
  const handleProducerUpdate = ({ field, value }) => {
    patchQuotePromise({
      quoteId: quoteData.quoteID,
      newData: {
        producerCode: value
      },
      updateMessage: "Updating producer..."
    })
      .then(() => {
        handleOnChange({ field, value });
      })
      .catch(({ error }) =>
        toastErrr({
          displayMessage: "An error occurred.  Unable to update Producer.",
          action: "handleEmailUpdate",
          description: "update binding data failed",
          error,
          payload: { producerCode: value }
        })
      );
  };

  return (
    <Form context={form}>
      <h4>Agency Contact Information</h4>
      <FormGroup>
        <Select
          id="producer_code"
          name="producer_code"
          label="Producer"
          onChange={handleProducerUpdate}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.producer_code}
          options={formMetadata?.producerOpts || []}
          className="oq__producer"
          required
          isClearable={false}
          size="full"
          isLoading={(formMetadata?.producerOpts || []).length === 0}
        />
      </FormGroup>
    </Form>
  );
};
export default ProducerField;
