import { connect } from "react-redux";
import ClaimsTab from "./ClaimsTab";
import {
  fetchClaimStatuses,
  removeClaimStatus,
  fetchClaimsOpened,
  removeClaimOpened,
  fetchClaimPayments,
  removeClaimPayment,
  fetchClaimSavedLosses,
  removeClaimsSavedLosses,
  fetchClaimSubmittedLosses
} from "../../../actions/homeClaimsActions";
import { getSelectedAgencyCode } from "../../../selectors/agencySelectors";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  getSavedLossesList,
  getSavedLossesListError,
  getSavedLossesListIsLoading,
  getSubmittedLossesListError,
  getSubmittedLossesList,
  getSubmittedLossesListIsLoading,
  getClaimsOpenedLoading,
  getClaimPaymentsLoading
} from "./HomeClaimsSelectors";
import { activateClaimsInquiryCard } from "../../../claims/actions/claimsInquiryActions";

const mapStateToProps = state => {
  return {
    ...state.homeClaims,
    agencyCode: getSelectedAgencyCode(state),
    employeeUserName: sessionSelector.getEmployeeUserName(state),
    SavedLossesList: getSavedLossesList(state),
    SavedLossesError: getSavedLossesListError(state),
    SavedLossesIsLoading: getSavedLossesListIsLoading(state),
    is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
    SubmittedLossesListError: getSubmittedLossesListError(state),
    SubmittedLossesList: getSubmittedLossesList(state),
    SubmittedLossesListIsLoading: getSubmittedLossesListIsLoading(state),
    claimsOpenedLoading: getClaimsOpenedLoading(state),
    claimPaymentsLoading: getClaimPaymentsLoading(state)
  };
};

const mapDispatchToProps = {
  fetchClaimStatuses,
  removeClaimStatus,
  fetchClaimsOpened,
  removeClaimOpened,
  fetchClaimPayments,
  removeClaimPayment,
  fetchClaimSavedLosses,
  removeClaimsSavedLosses,
  fetchClaimSubmittedLosses,
  activateClaimsInquiryCard
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsTab);
