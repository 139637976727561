import React, { useEffect, useState, useCallback } from "react";

import {
  Button,
  FlexRow,
  Form,
  Panel,
  FormGroup,
  RadioButton,
  RadioButtonContainer,
  LoadingIndicator,
  useForm
} from "@ufginsurance/ui-kit";

import * as addressService from "../../../services/addressService";

import "./VerifyAddresses.scss";

// // helper function to create object for managing the extra data from the address service
// export const mapExtraDataFromAddressService = a => {
//   if (!a) return {};

//   const floodDistance = a.flood_risk.distance_100_year_flood;
//   const fireBehavior = a.wild_fire.predicted_fire_behavior;
//   const valueCalculation = a.property_attributes.value_calculation;
//   const landValue = a.property_attributes.land_value;
//   const improvementValue = a.property_attributes.improvement_value;

//   return {
//     buildingQuality_UFG: a.property_attributes.quality,
//     improveValueCalculation_UFG:
//       improvementValue && !isNaN(improvementValue)
//         ? parseFloat(improvementValue)
//         : null, // int
//     landValueCalculation_UFG:
//       landValue && !isNaN(landValue) ? parseFloat(landValue) : null, // int
//     totalValueCalculation_UFG:
//       valueCalculation && !isNaN(valueCalculation)
//         ? parseFloat(valueCalculation)
//         : null, // int
//     hailRiskLevel_UFG: a.weather_risk.hail_risk_level,
//     windRiskLevel_UFG: a.weather_risk.wind_risk_level,
//     dist100YearFlood_UFG:
//       floodDistance && !isNaN(floodDistance) ? parseFloat(floodDistance) : null, // int
//     riskDescription_UFG: a.wild_fire.risk_description,
//     predFireBehavior_UFG:
//       fireBehavior && !isNaN(fireBehavior) ? parseFloat(fireBehavior) : null, // int
//     urbanicity_UFG: a?.ext_attributes?.urbanicity
//   };
// };

const VerifyAddress = ({
  addressToVerify,
  onContinue,
  onCancel,
  autoContinueOnExactMatches = true,
  disableContinueButton
}) => {
  const [processedAddress, setProcessedAddress] = useState();
  const [addressExact, setAddressExact] = useState(null);
  const [original, setOriginal] = useState({});
  const [loading, setLoading] = useState();

  const addressForm = useForm({ values: { Address: "" }, onSubmi: () => {} });

  // get access to the methods available in the hook
  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields
  } = addressForm;

  // on component load, check the addresses using the address service and set up the data for the UI
  useEffect(() => {
    // only run if the api service hasn't been run yet
    if (!loading && !processedAddress) {
      // set a value so if the component re-renders before the api is finished,
      // we don't try to run it multiple times
      setLoading(true);

      const og = { ogfields: addressToVerify };
      const defaultValues = { Address: "" };
      let exactMatch = true;
      const processed = {};

      const { addressLine1, city, state, zip } = addressToVerify;

      addressService
        .verifyAddressV2(addressLine1, city, state, zip)
        .then(response => {
          const data = response?.data;
          const defaultItem = data?.[0];

          const {
            address_line: address1,
            city,
            state,
            zip,
            county_name: county,
            fips_county_number: county_code
          } = defaultItem.address_output;

          // could not validate the address...
          og.rawVerificationData = defaultItem;
          og.addressFields = {
            address1,
            city,
            state,
            zip,
            county,
            county_code
          };
          og.confidence = 0;
          og.isOriginal = true;

          /**
           * CAN'T BE VERIFIED? Just stop and show the original.
           *
           * if the address cannot be validated...
           * just stop and show the user the bad news
           */
          if (
            !defaultItem ||
            defaultItem?.address_output?.match_code_description.includes(
              "cannot be validated"
            )
          ) {
            defaultValues.Address = "original";
            exactMatch = false;
            return;
          }

          /**
           * CONFIDENCE : EXACT MATCH?
           *
           * if first result is high-confidence, then we have exact match
           */
          const confidence = !!defaultItem?.address_output?.confidence
            ? Number(defaultItem?.address_output.confidence)
            : 0;

          //set if the address is exact match - used to see if all addresses are exact match
          if (confidence < 100) exactMatch = false;

          /**
           * ORGANIZE ADDRESS API RESPONSES TO SHOW TO USER
           *
           * Start aligning address response data to options to use for the UI
           */
          processed.options = data.reduce((acc, a) => {
            if (!a.address_output) return acc;

            const {
              address_line: address1,
              city,
              state,
              zip,
              county_name: county,
              fips_county_number: county_code,
              confidence
            } = a.address_output;

            return [
              ...acc,
              {
                label: (
                  <div className="oq__verify-address__drop-down__option">
                    <div className="oq__verify-address__drop-down__option__address-line">
                      {address1}
                    </div>
                    <div className="oq__verify-address__drop-down__option__city-line">
                      {city}, {state} {zip}
                    </div>
                  </div>
                ),
                value: `${address1},${city},${state},${zip}`,
                rawVerificationData: a,
                confidence: Number(confidence) || 0,
                addressFields: {
                  address1,
                  city,
                  state,
                  zip,
                  county,
                  county_code
                },
                ogfields: addressToVerify
              }
            ];
          }, []);

          // update values with the first selection from service (or use original if no matches found)
          updateForm({
            values: { Address: processed?.options?.[0]?.value || "" }
          });

          setProcessedAddress(processed);

          setAddressExact(exactMatch);
          setOriginal(og);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          processed.isNotVerified = true;
        });
    }
  }, [addressToVerify, loading, processedAddress, updateForm]);

  const handleContinue = useCallback(() => {
    const selectedAddress =
      values.Address === "original"
        ? original
        : {
            ...(processedAddress?.options?.find(
              a => a.value === values.Address
            ) || {})
          };

    onContinue(selectedAddress);
  }, [onContinue, original, processedAddress?.options, values.Address]);

  // catch when an address is processed and is an exact match
  // ... using a useEffect here so the 'updateAddressFormFields'
  // ... completes before handleContinue fires
  useEffect(() => {
    if (autoContinueOnExactMatches && addressExact) handleContinue();

    // ignore the handleContinue so this only runs once on a save
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressExact]);

  return (
    <>
      <div className="shared__address-validate__container">
        {loading && <LoadingIndicator message="Verifying address..." />}
        {!!processedAddress && !loading && (
          <div className="shared__address-validate__verify-address">
            <Form
              context={addressForm}
              className="shared__address-validate__address-form"
            >
              <Panel>
                <p>Please verify your address:</p>

                <FormGroup>
                  <RadioButtonContainer
                    id="Address"
                    name="Address"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onValidate={handleOnValidate}
                    value={values?.Address}
                    required
                  >
                    <div className="shared__address-validate__address-wrapper">
                      <div className="shared__address-validate__address-original">
                        <b>You Entered:</b>
                        <RadioButton
                          id="original"
                          label={
                            <>
                              <div>{original?.ogfields?.addressLine1}</div>
                              <div>
                                {original?.ogfields?.city},{" "}
                                {original?.ogfields?.state}{" "}
                                {original?.ogfields?.zip}
                              </div>
                            </>
                          }
                          optionValue="original"
                        />
                      </div>
                      <div className="shared__address-validate__address-selection">
                        <b>Suggested Address:</b>
                        {!processedAddress?.options ||
                        processedAddress?.options.length === 0 ? (
                          <div>
                            <em>Unable to verify address</em>
                          </div>
                        ) : (
                          processedAddress?.options.map((o, i) => (
                            <div key={o.value}>
                              <RadioButton
                                id={`AddressOption${i}`}
                                label={o.label}
                                optionValue={o.value || ""}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </RadioButtonContainer>
                </FormGroup>
              </Panel>
            </Form>
            <FlexRow align="right">
              <Button onClick={onCancel}>Cancel</Button>

              <Button
                variant="primary"
                disabled={invalidFields.length > 0 || disableContinueButton}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </FlexRow>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyAddress;
