import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./../../components/translations";
import {
  Button,
  FlexRow,
  Modal,
  Alert,
  useForm,
  FormGroup,
  Form,
  Input
} from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const AgencyLinking = ({
  openModal,
  agency_name,
  agency_number,
  agencyLinkingError,
  email,
  postAgencyLinkingDetails,
  updateWebsiteLogoState,
  is109015Agent,
  agencyLinking,
  agencyLinkingLoading
}) => {
  const renderAgencyLinkingTerms = () => (
    <ol>
      <li>
        {Translations.website_logo.agency_linking_terms_one}{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={"http://www.ufginsurance.com"}
        >
          {Translations.website_logo.website_link}
        </a>
      </li>
      <li>{Translations.website_logo.agency_linking_terms_two}</li>
      <li>{Translations.website_logo.agency_linking_terms_three}</li>
      <li>{Translations.website_logo.agency_linking_terms_four}</li>
      <li>{Translations.website_logo.agency_linking_terms_five}</li>
      <li>{Translations.website_logo.agency_linking_terms_six}</li>
      <li>{Translations.website_logo.agency_linking_terms_seven}</li>
    </ol>
  );

  const renderThankyouMessage = () => (
    <div>
      <p>
        <b>{Translations.website_logo.thankyou_message}</b>
      </p>
      <p>{Translations.website_logo.thankyou_message_ack}</p>
      <p>
        <HelpDeskMessage before="If you have any questions about this agreement," />
      </p>
    </div>
  );

  const handleDismissAlert = () => {
    updateWebsiteLogoState({ agencyLinkingError: false });
  };

  const onSubmit = formData => {
    const agencyLinkingInfo = {
      agency_name: formData.values.agencyName,
      agency_number: formData.values.agencyNumber,
      submitted_by: formData.values.contactName,
      website_url: formData.values.webAddress,
      agency_email_address: formData.values.contactEmail
    };
    postAgencyLinkingDetails(agencyLinkingInfo);
  };

  const form = useForm({
    values: {
      agencyName: agency_name,
      agencyNumber: agency_number,
      contactEmail: is109015Agent ? "webhelp@unitedfiregroup.com" : email,
      contactName: "",
      webAddress: ""
    },
    onSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const validateWebAddress = value => {
    let error;
    const reg =
      /^(http[s]?:\/\/){0,1}(www.){0,1}[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}[.]{0,1}/;
    if (!reg.test(value)) {
      error = "Please enter valid web address";
    }
    return error;
  };

  const handleWebAddressOnValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });
    const webAddressError = validateWebAddress(value);
    if ((fieldErrors?.length ?? 0) === 0 && webAddressError) {
      fieldErrors.push(webAddressError);
    }
    return fieldErrors;
  };

  return (
    <div className="agency-linking-container">
      <Modal
        size="lg"
        title={Translations.website_logo.header.agency_agreement_header}
        show
        onHide={openModal(null)}
        body={
          agencyLinking ? (
            renderThankyouMessage()
          ) : (
            <div className="agency-linking-modal__body">
              <p>{Translations.website_logo.agency_linking_data}</p>
              {renderAgencyLinkingTerms()}
              <p>
                {Translations.website_logo.agency_linking_by_filling}
                {Translations.website_logo.agency_linking_out_all}
                {Translations.website_logo.agency_linking_declaration}
              </p>
              <div>
                <>
                  <Form context={form} id="agency-linking-form">
                    <FormGroup wrap={false}>
                      <Input
                        id="agencyName"
                        name="agencyName"
                        label={Translations.website_logo.header.agency_name}
                        placeholder="Agency Name"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleOnValidate}
                        value={values.agencyName || ""}
                        disabled
                        size="lg"
                        maxLength={30}
                      />
                    </FormGroup>
                    <FormGroup wrap={false}>
                      <Input
                        id="agencyNumber"
                        name="agencyNumber"
                        label={Translations.website_logo.header.agency_number}
                        placeholder="Agency Number"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleOnValidate}
                        value={values.agencyNumber || ""}
                        disabled
                        size="lg"
                        maxLength={6}
                      />
                    </FormGroup>
                    <FormGroup wrap={false}>
                      <Input
                        id="contactEmail"
                        name="contactEmail"
                        label={Translations.website_logo.header.contact_email}
                        placeholder="Contact Email"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleOnValidate}
                        value={values.contactEmail || ""}
                        disabled
                        size="lg"
                      />
                    </FormGroup>
                    <FormGroup wrap={false}>
                      <Input
                        id="contactName"
                        name="contactName"
                        label={Translations.website_logo.header.contact_name}
                        placeholder="Contact Name"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleOnValidate}
                        value={values.contactName || ""}
                        required
                        size="lg"
                        maxLength={100}
                      />
                    </FormGroup>
                    <FormGroup wrap={false}>
                      <Input
                        id="webAddress"
                        name="webAddress"
                        label={Translations.website_logo.header.web_address}
                        placeholder="Enter Web Address"
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onValidate={handleWebAddressOnValidate}
                        value={values.webAddress || ""}
                        required
                        size="lg"
                        maxLength={100}
                      />
                    </FormGroup>
                    <span>
                      <i className="row">
                        {Translations.website_logo.header.web_address_format}
                      </i>
                    </span>
                    {agencyLinkingError && (
                      <>
                        <br />
                        <Alert type="error" onDismiss={handleDismissAlert}>
                          <HelpDeskMessage display="anErrorOccurred" />
                        </Alert>
                        <br />
                      </>
                    )}
                    <FlexRow align="right">
                      <Button
                        type="reset"
                        variant="secondary"
                        onClick={openModal(null)}
                      >
                        {Translations.general.cancel}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={agencyLinkingLoading}
                        spinner={agencyLinkingLoading}
                        spinnerPos="right"
                      >
                        {Translations.general.submit_text}
                      </Button>
                    </FlexRow>
                  </Form>
                </>
              </div>
            </div>
          )
        }
        footer={
          agencyLinking && (
            <FlexRow align="right">
              <Button
                type="reset"
                variant={"secondary"}
                onClick={openModal(null)}
              >
                Close
              </Button>
            </FlexRow>
          )
        }
        altCloseMethod={false}
        className="agency-linking-modal"
      />
    </div>
  );
};

export default AgencyLinking;

AgencyLinking.propTypes = {
  openModal: PropTypes.func.isRequired,
  agency_name: PropTypes.string,
  agency_number: PropTypes.string,
  agencyLinkingError: PropTypes.bool,
  email: PropTypes.string,
  postAgencyLinkingDetails: PropTypes.func,
  updateWebsiteLogoState: PropTypes.func,
  is109015Agent: PropTypes.bool.isRequired,
  agencyLinking: PropTypes.object,
  agencyLinkingLoading: PropTypes.bool.isRequired
};
