import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../../components/translations";
import {
  FormGroup,
  Form,
  useForm,
  Dropdown,
  Button,
  DateRange,
  Input,
  formatDate
} from "@ufginsurance/ui-kit";
import { getElectronicPaymentsReport } from "../../services/accountingReportService";
import { toast } from "react-toastify";
import { DocumentService } from "../../../services/documentService";
import HelpDeskMessage from "../../../help-desk/helpDeskMessage";

const minDate = new Date();
minDate.setDate(minDate.getDate() - 365 * 1);
const maxDate = new Date();

const ElectronicPaymentsForm = ({
  isHomeAgent,
  agencyCode,
  isSpecialtyPortalView
}) => {
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [noPaymentsFound, setNoPaymentsFound] = useState(false);

  const handleSubmit = ({ values }) => {
    const paymentTypeMap = {
      0: "DBALL",
      1: "ABEPay"
    };
    const documentService = new DocumentService();
    setIsLoadingDocument(true);
    setNoPaymentsFound(false);
    getElectronicPaymentsReport({
      agency_code: values.agency_code,
      payment_type: paymentTypeMap[values.payment_type],
      start_date: formatDate(
        values.electronicPaymentsFormRange.from,
        "YYYY-MM-DD"
      ),
      end_date: formatDate(values.electronicPaymentsFormRange.to, "YYYY-MM-DD"),
      is_specialty_agency: isSpecialtyPortalView
    }).then(
      response => {
        setIsLoadingDocument(false);
        documentService.initializeRenderer("/loading-document");
        documentService.saveOrOpenDocument(
          response.data,
          "reconciled_payments_report.pdf"
        );
      },
      error => {
        const isNoPaymentsFoundError = (error?.response?.status ?? 500) === 404;
        setIsLoadingDocument(false);
        documentService.clearRenderer();
        if (isNoPaymentsFoundError) {
          setNoPaymentsFound(true);
        } else {
          toast(<HelpDeskMessage display="anErrorOccurred" />, {
            position: "top-center",
            className: "error",
            autoClose: false
          });
        }
      }
    );
  };

  const initialValues = {
    payment_type: isSpecialtyPortalView ? "1" : "",
    agency_code: !isHomeAgent ? agencyCode : "",
    electronicPaymentsFormRange: { from: "", to: "" }
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  useEffect(() => {
    if (isSpecialtyPortalView) {
      setPaymentTypes([{ label: "Agency Bill", value: "1" }]);
    } else {
      setPaymentTypes([
        { label: "Direct Bill", value: "0" },
        { label: "Agency Bill", value: "1" }
      ]);
    }
  }, [isSpecialtyPortalView]);

  return (
    <div className="reports-form__container">
      <Form context={form} className="agency-electronic-payments-form">
        <FormGroup>
          <Dropdown
            id="payment_type"
            name="payment_type"
            label={
              Translations.reports.tabReconcileElectronicPayments.payment_types
            }
            isClearable={!isSpecialtyPortalView}
            disabled={isSpecialtyPortalView}
            options={paymentTypes}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.payment_type}
            size="lg"
            required
          />
        </FormGroup>
        <FormGroup>
          <DateRange
            id="electronicPaymentsFormRange"
            name="electronicPaymentsFormRange"
            labelFrom={
              Translations.reports.tabReconcileElectronicPayments.begin_date
            }
            labelTo={
              Translations.reports.tabReconcileElectronicPayments.end_date
            }
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.electronicPaymentsFormRange}
            format="MM/DD/YYYY"
            required
            minDate={minDate}
            maxDate={maxDate}
          />
        </FormGroup>

        {isHomeAgent && (
          <FormGroup>
            <Input
              id="agency_code"
              name="agency_code"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.agency_code}
              required
              label={
                isSpecialtyPortalView
                  ? Translations.reports.tabReconcileElectronicPayments
                      .broker_code
                  : Translations.reports.tabReconcileElectronicPayments
                      .agent_code
              }
            />
          </FormGroup>
        )}

        <FormGroup>
          <Button
            type="submit"
            variant="primary"
            disabled={isLoadingDocument}
            spinner={isLoadingDocument}
            spinnerPos="right"
          >
            {Translations.reports.tabReconcileElectronicPayments.show_list}
          </Button>
        </FormGroup>
        {noPaymentsFound && (
          <FormGroup>
            <p className="friendly-error-messaging">
              There were no payments made for the options selected.
            </p>
          </FormGroup>
        )}
      </Form>
    </div>
  );
};

ElectronicPaymentsForm.propTypes = {
  isHomeAgent: PropTypes.bool,
  agencyCode: PropTypes.string,
  isSpecialtyPortalView: PropTypes.bool
};

export default ElectronicPaymentsForm;
