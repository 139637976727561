import { useEffect, useRef, useState } from "react";
import { logger } from "../../../loggers";
import { getAccounts } from "../../../quick-program/quick-quoting/quickQuotingServices";

export default function useAccountBondSearch(agencyCode) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const cache = useRef({});
  const error = useRef(null);

  useEffect(() => {
    if (!agencyCode || isLoading || cache.current[agencyCode] || error?.current)
      return;

    setIsLoading(true);

    getAccounts(agencyCode)
      .then(res => {
        if (res.status === 200) {
          cache.current[agencyCode] = res.data;
          setData(res.data);
        }
      })
      .catch(err => {
        error.current = err;
        console.error(err);
        logger.error({
          description: err.toString(),
          fatal: false
        });
      })
      .finally(() => setIsLoading(false));
  }, [agencyCode, isLoading]);

  return [data, isLoading, error];
}
