import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PolicyActivityTable from "./policyActivityTable";
import CancellationTable from "./cancellationTable";
import PolicyActivityFormContainer from "./policyActivityFormContainer";
import { LoadingIndicator, Accordion } from "@ufginsurance/ui-kit";
import { Translations } from "../components/translations";

const PolicyActivity = props => {
  const {
    fetchActivityDocument,
    activityList,
    policy_number,
    policy_type,
    isActivityLoading,
    errorActivityList,
    isCancellationLoading,
    errorCancellationList,
    fetchCancellationDocument,
    cancellationList,
    fetchPolicyActivityCancellationList
  } = props;

  const [is404, setIs404] = useState(false);
  const [prevPolicyNumber, setPrevPolicyNumber] = useState();
  const [prevErrorCancellationList, setPrevErrorCancellationList] = useState();

  const showCancellationNotices =
    cancellationList && cancellationList.length > 0 && !is404;

  const hasActivity = activityList.length > 0;

  const hasActivityError = !!errorActivityList;
  const hasNoActivityErrorStatus =
    errorActivityList &&
    errorActivityList.response &&
    errorActivityList.response.status &&
    errorActivityList.response.status === 404;

  useEffect(() => {
    if (
      !!errorCancellationList &&
      errorCancellationList !== prevErrorCancellationList
    ) {
      setIs404(
        errorCancellationList &&
          errorCancellationList.response &&
          errorCancellationList.response.status === 404
      );

      setPrevErrorCancellationList(errorCancellationList);
    }
  }, [errorCancellationList, prevErrorCancellationList]);

  useEffect(() => {
    if (!!policy_number && policy_number !== prevPolicyNumber) {
      fetchPolicyActivityCancellationList(policy_number);
      setPrevPolicyNumber(policy_number);
    }
  }, [fetchPolicyActivityCancellationList, policy_number, prevPolicyNumber]);

  return (
    <div>
      {showCancellationNotices && (
        <Accordion
          isOpen
          textMain={
            Translations.account_overview.policyActivityTab.cancellation_notices
          }
        >
          <div className="activity-notice">
            {
              Translations.account_overview.policyActivityTab
                .view_cancellation_notices
            }
          </div>

          <CancellationTable
            isCancellationLoading={isCancellationLoading}
            errorCancellationList={errorCancellationList}
            cancellationList={cancellationList}
            policy_number={policy_number}
            fetchCancellationDocument={fetchCancellationDocument}
          />
        </Accordion>
      )}
      <Accordion
        isOpen
        textMain={Translations.account_overview.policyActivityTab.activity}
      >
        <div className="activity-notice">
          {Translations.account_overview.policyActivityTab.activity_notice}
        </div>

        <PolicyActivityFormContainer
          policy_number={policy_number}
          policy_type={policy_type}
        />
        {isActivityLoading ? (
          <LoadingIndicator />
        ) : hasActivityError || !hasActivity ? (
          <span>
            {hasNoActivityErrorStatus || !hasActivity
              ? Translations.account_overview.policyActivityTab.no_activity
              : Translations.general.error}
          </span>
        ) : (
          <PolicyActivityTable
            activityList={activityList}
            fetchActivityDocument={fetchActivityDocument}
            policy_type={policy_type}
          />
        )}
      </Accordion>
    </div>
  );
};

PolicyActivity.propTypes = {
  activityList: PropTypes.array.isRequired,
  policy_number: PropTypes.string.isRequired,
  policy_type: PropTypes.string.isRequired,
  isActivityLoading: PropTypes.bool,
  errorActivityList: PropTypes.any,
  isCancellationLoading: PropTypes.bool,
  fetchActivityDocument: PropTypes.func,
  errorCancellationList: PropTypes.any,
  cancellationList: PropTypes.array.isRequired,
  fetchPolicyActivityCancellationList: PropTypes.func,
  fetchCancellationDocument: PropTypes.func
};

export default PolicyActivity;
