import React, { useContext, useEffect } from "react";

import { FormGroup, InputEmail, Form, useForm } from "@ufginsurance/ui-kit";

import OnlineQuotingContext from "../OnlineQuotingContext";

const PolicyHolderEmail = ({
  formIsValid,
  setFormIsValid,
  triggerFormValidation
}) => {
  const { quoteData, updateAccountholderEmail, quoteIsUpdating } =
    useContext(OnlineQuotingContext);

  const policyholder_email =
    quoteData?.baseData?.accountHolder?.emailAddress1 || "";

  const form = useForm({ values: { policyholder_email }, onSubmit: () => {} });

  const {
    values,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    invalidFields,
    validateForm
  } = form;

  useEffect(() => {
    /**
     * update form is valid state on step 6
     */
    const isValid = !invalidFields.length;
    if (formIsValid !== isValid) setFormIsValid(isValid);
  }, [formIsValid, setFormIsValid, invalidFields]);

  useEffect(() => {
    /**
     * update form field if quoteData email address updates
     */
    const quoteDataEmail = quoteData?.baseData?.accountHolder?.emailAddress1;

    if (quoteDataEmail && quoteDataEmail !== values.policyholder_email)
      updateForm({
        values: {
          policyholder_email: quoteDataEmail
        }
      });
    // only check this when quoteData changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData?.baseData?.accountHolder?.emailAddress1]);

  useEffect(() => {
    if (triggerFormValidation) validateForm();
  }, [triggerFormValidation, validateForm]);

  const handleEmailUpdate = ({ field, value }) => {
    handleOnBlur({
      field,
      value,
      // callback to be run after the blur event checks the field is valid
      callback: ({ error }) => {
        if (error.length === 0 && policyholder_email !== value) {
          updateAccountholderEmail({ emailAddress1: value }).catch(() => {
            // if there's an error updating, then revert the form field back
            updateForm({
              values: { policyholder_email },
              errors: { policyholder_email: [] }
            });
          });
        }
      }
    });

    return false;
  };

  return (
    <Form context={form}>
      <FormGroup>
        <InputEmail
          id="policyholder_email"
          name="policyholder_email"
          label="Policyholder Email Address"
          placeholder=""
          onChange={handleOnChange}
          onBlur={handleEmailUpdate}
          onValidate={handleOnValidate}
          value={values.policyholder_email}
          disabled={quoteIsUpdating}
          required
          className="email"
          size="lg"
        />
      </FormGroup>
    </Form>
  );
};
export default PolicyHolderEmail;
