import React from "react";
import PropTypes from "prop-types";
import { getPolicyLossRunsUrl } from "../../../services/claimsReportsService";

const PolicyLossRunNameSearch = ({ row }) => {
  return (
    <div>
      <a
        href={getPolicyLossRunsUrl(row.policy_number)}
        className="capitalize"
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.insured_name.toLowerCase()}
      </a>
    </div>
  );
};

PolicyLossRunNameSearch.propTypes = {
  row: PropTypes.object.isRequired
};

export default PolicyLossRunNameSearch;
