import { DateFormat } from "../../components/Factory";

//injected in Containers via mapStateToProps

export const getPaymentDetailsLoading = state => {
  return !!(
    state &&
    state.agencySweepPaymentConfirmation &&
    state.agencySweepPaymentConfirmation.paymentDetailsLoading
  );
};

export const getPaymentInformation = state => {
  return (
    state &&
    state.agencySweepPaymentConfirmation &&
    state.agencySweepPaymentConfirmation.agencySweepPaymentInfo
  );
};

export const getPaymentDetailsResponseStatusCode = state => {
  return (
    (state &&
      state.agencySweepPaymentConfirmation &&
      state.agencySweepPaymentConfirmation.responseStatusCode) ||
    0
  );
};

export const isOasisPaymentConfirmationLoading = state => {
  return !!(
    state &&
    state.agencySweepPaymentConfirmation &&
    state.agencySweepPaymentConfirmation.oasisPaymentConfirmationLoading
  );
};

export const getOriginalOasisInvoiceDetails = state => {
  return (
    state &&
    state.agencySweepOasisInvoiceDetail &&
    state.agencySweepOasisInvoiceDetail.billingInformation &&
    state.agencySweepOasisInvoiceDetail.billingInformation
  );
};

export const getPaymentRequestInsuredName = state => {
  return (
    state &&
    state.agencySweepPaymentRequestDetails &&
    state.agencySweepPaymentRequestDetails.agencySweepPaymentInfo &&
    state.agencySweepPaymentRequestDetails.agencySweepPaymentInfo.lineItems &&
    state.agencySweepPaymentRequestDetails.agencySweepPaymentInfo.lineItems
      .length &&
    state.agencySweepPaymentRequestDetails.agencySweepPaymentInfo.lineItems[0]
      .insured
  );
};

//utility functions

export const createPaymentPdfRequest = (
  agencySweepPaymentInfo,
  paymentDetails
) => {
  const paymentPdfData = {
    insured_name:
      agencySweepPaymentInfo &&
      agencySweepPaymentInfo.lineItems &&
      agencySweepPaymentInfo.lineItems.length &&
      agencySweepPaymentInfo.lineItems[0].insured,
    confirmation_id: paymentDetails.confirmation_number,
    payment_id: paymentDetails.payment_id,
    payment_date: paymentDetails.payment_date,
    payment_cutoff_time: "7:30 p.m. CT",
    payments: paymentDetails.paid_amounts.map(item => ({
      account_policy_number: item.policy_account_number,
      payment_amount: item.payment_amount
    })),
    payment_total: paymentDetails.total_amount
  };
  return paymentPdfData;
};

export const createAllOasisPaymentConfirmations = (
  activeAgencyCode,
  accountNumber,
  paymentRequestConfirmation,
  originalOasisInvoiceDetails
) => {
  // flatten active_invoices, next_invoice, future_invoices
  // set remaining payment amount = total payment amount
  // for each invoice
  //    if remaining payment amount === 0
  //        stop creating payment request confirmations
  //    if remaining payment amount > invoice balance
  //        create and append payment request confirmation against invoice balance
  //        set remaining payment amount to remaining payment amount - invoice balance
  //    if remaining payment amount less or equal to invoice balance
  //        create and append payment request confirmation against remaining payment amount
  //        set remaining payment amount to 0
  const { invoice_information } = originalOasisInvoiceDetails;
  const {
    active_invoices,
    next_invoice,
    future_invoices
  } = invoice_information;
  const allInvoices = [...active_invoices, next_invoice, ...future_invoices];
  let remainingPaymentAmount = Number(
    paymentRequestConfirmation.total_amount || 0
  );
  const paymentConfirmations = [];
  const numberOfInvoices = (allInvoices && allInvoices.length) || 0;
  for (let i = 0; i < numberOfInvoices; i++) {
    const invoice = allInvoices[i];
    if (remainingPaymentAmount <= 0) {
      break;
    } else if (
      remainingPaymentAmount >
      Number((invoice.amount_due && invoice.amount_due.amount) || 0)
    ) {
      paymentConfirmations.push(
        createOasisPaymentConfirmationRequest(
          paymentRequestConfirmation,
          activeAgencyCode,
          accountNumber,
          (invoice.amount_due && invoice.amount_due.amount) || 0,
          invoice.invoice_items
        )
      );
      remainingPaymentAmount = parseFloat(
        Number(
          Number(
            Math.round(remainingPaymentAmount * 100) -
              Math.round(
                Number(
                  (invoice.amount_due && invoice.amount_due.amount) * 100
                ) || 0
              )
          ) / 100
        ).toFixed(2)
      );
    } else {
      paymentConfirmations.push(
        createOasisPaymentConfirmationRequest(
          paymentRequestConfirmation,
          activeAgencyCode,
          accountNumber,
          remainingPaymentAmount,
          invoice.invoice_items
        )
      );
      remainingPaymentAmount = 0;
    }
  }
  return paymentConfirmations;
};

export const createOasisPaymentConfirmationRequest = (
  paymentRequestConfirmation,
  agencyCode,
  accountNumber,
  invoicePaymentAmount,
  invoiceItems
) => {
  const request = {
    payment_id: paymentRequestConfirmation.payment_id,
    agent_number: agencyCode,
    account_number: accountNumber,
    payment_date: DateFormat(
      paymentRequestConfirmation.payment_date,
      "YYYY-MM-DD"
    ),
    payment_amount: {
      amount:
        Number(invoicePaymentAmount) &&
        parseFloat(Number(invoicePaymentAmount).toFixed(2)),
      currency: "usd"
    },
    invoice_items:
      invoiceItems &&
      invoiceItems.map(item => {
        return createOasisPaymentInvoiceItem(
          item.paid_amount.amount,
          item.unsettled_gross_amount.amount,
          item.policy_id,
          item.public_id,
          item.amount.amount
        );
      })
  };
  return request;
};

export const createOasisPaymentInvoiceItem = (
  paidAmount,
  unsettledGrossAmount,
  policyNumber,
  publicId,
  amount
) => {
  const invoiceItem = {
    paid_amount: {
      amount: Number(paidAmount) && parseFloat(Number(paidAmount).toFixed(2)),
      currency: "usd"
    },
    unsettled_gross_amount: {
      amount:
        Number(unsettledGrossAmount) &&
        parseFloat(Number(unsettledGrossAmount).toFixed(2)),
      currency: "usd"
    },
    policy_id: policyNumber,
    public_id: publicId,
    gross_amount: {
      amount: Number(amount) && parseFloat(Number(amount).toFixed(2)),
      currency: "usd"
    }
  };
  return invoiceItem;
};
