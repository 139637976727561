import { useEffect } from "react";
import { StateLongNames } from "../../../constants/dictionary";
import { findStateByName } from "../../../util/selectors";
import { useCurrentRouteMatch } from "../hooks";

export const useDefaultStateRouteValidation = (
  selectedState,
  onStatesListClick,
  defaultAgentState,
  stateListData
) => {
  const currentMatch = useCurrentRouteMatch();
  const defaultAgentStateLongName = defaultAgentState
    ? StateLongNames[defaultAgentState]
    : null;
  const defaultStateSelection =
    findStateByName(stateListData, defaultAgentStateLongName) ||
    stateListData[0];
  const currentRouteStateId = currentMatch?.params?.stateId;

  useEffect(() => {
    if (selectedState && !currentRouteStateId) {
      onStatesListClick(selectedState);
    }
  }, [selectedState, currentRouteStateId, onStatesListClick]);

  useEffect(() => {
    const hasStatesList = stateListData && stateListData.length > 0;
    const notStateSelectionRouteWithDefaultSelection =
      hasStatesList &&
      !selectedState &&
      defaultStateSelection &&
      !currentRouteStateId;
    if (notStateSelectionRouteWithDefaultSelection) {
      onStatesListClick(defaultStateSelection);
    }
  }, [
    stateListData,
    selectedState,
    defaultStateSelection,
    currentRouteStateId,
    onStatesListClick
  ]);

  return null;
};
