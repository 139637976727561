import React, { useMemo, useState } from "react";

export default React.createContext(null);

const useStep5 = () => {
  // stateSpecificIsInvalid helps disable step5 continue button if there are any
  // state-specific coverages are are missing values on terms or schedule items
  const [stateSpecificIsInvalid, setStateSpeficIsInvalid] = useState();

  const [showBadBuildingModal, setShowBadBuildingModal] = useState();

  return useMemo(
    () => ({
      stateSpecificIsInvalid,
      setStateSpeficIsInvalid,
      showBadBuildingModal,
      setShowBadBuildingModal
    }),
    [
      stateSpecificIsInvalid,
      setStateSpeficIsInvalid,
      showBadBuildingModal,
      setShowBadBuildingModal
    ]
  );
};

export { useStep5 };
