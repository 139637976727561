import React from "react";
import { Button, formatDate } from "@ufginsurance/ui-kit";
import "./Summary.scss";
import { typeOfBond } from "../constants/suretyDictionary";
import * as routes from "../../../constants/routes";
import { isObligeeAddressPOBox } from "../shared/util";

const BondInfoSummary = ({ suretyQuote, history, quickSubmission }) => {
  const bondInfo =
    !!suretyQuote && !!suretyQuote.bond_information
      ? suretyQuote.bond_information
      : {};

  const EditBondInformation = () => {
    suretyQuote.bond_information.bondInfoPreventReload = true;
    history.push(routes.setStepUrl(4), suretyQuote);
  };

  return (
    <div>
      <div className="qq__step6__summary__item">
        <b>Effective Date:</b>
        <div className="qq__step6__summary__item-lines">
          {formatDate(bondInfo.effective_date, "MM/DD/YYYY")}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Bond Type:</b>
        <div className="qq__step6__summary__item-lines">
          {!!bondInfo && !!bondInfo.type_of_bond
            ? typeOfBond.find(x => x.value === bondInfo.type_of_bond).label
            : ""}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Bond Form:</b>
        <div className="qq__step6__summary__item-lines">
          {bondInfo.bond_form}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Project Location:</b>
        <div className="qq__step6__summary__item-lines">
          {`${bondInfo.city}, ${bondInfo.state} ${bondInfo.zip}`}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Project Description:</b>
        <div className="qq__step6__summary__item-lines">
          {bondInfo.project_description}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Obligee:</b>
        <div className="qq__step6__summary__item-lines">
          {bondInfo.obligee_name}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Obligee Address:</b>
        <div className="qq__step6__summary__item-lines">
          {isObligeeAddressPOBox(bondInfo)
            ? "P.O. Box " + bondInfo.obligee_pobox
            : bondInfo.obligee_address_line}
          <br />
          {`${bondInfo.obligee_city}, ${bondInfo.obligee_state} ${bondInfo.obligee_zip}`}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Completion Date:</b>
        <div className="qq__step6__summary__item-lines">
          {formatDate(bondInfo.project_completion_date, "MM/DD/YYYY")}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Maintenance Period:</b>
        <div className="qq__step6__summary__item-lines">
          {`${bondInfo.maintenance_period} Months`}
        </div>
      </div>

      <div className="qq__step6__summary__item">
        <b>Liquidated Damages:</b>
        <div className="qq__step6__summary__item-lines">
          {`$${bondInfo.liquidated_damages}`}
        </div>
      </div>

      {bondInfo.type_of_bond === "B" ? (
        <div>
          <div className="qq__step6__summary__item">
            <b>Bid Date:</b>
            <div className="qq__step6__summary__item-lines">
              {formatDate(bondInfo.bid_date, "MM/DD/YYYY")}
            </div>
          </div>

          <div className="qq__step6__summary__item">
            <b>Bid Estimate:</b>
            <div className="qq__step6__summary__item-lines">
              {`$${bondInfo.bid_estimate}`}
            </div>
          </div>
          {bondInfo.bid_bond === "Amount" ? (
            <div className="qq__step6__summary__item">
              <b>Bid Bond Amount:</b>
              <div className="qq__step6__summary__item-lines">
                {`$${bondInfo.bid_bond_amount}`}
              </div>
            </div>
          ) : (
            <div className="qq__step6__summary__item">
              <b>Bid Bond Percent:</b>
              <div className="qq__step6__summary__item-lines">
                {`${bondInfo.bid_bond_percent}`}%
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="qq__step6__summary__item">
            <b>Contract Date:</b>
            <div className="qq__step6__summary__item-lines">
              {formatDate(bondInfo.contract_date, "MM/DD/YYYY")}
            </div>
          </div>

          <div className="qq__step6__summary__item">
            <b>Contract Amount:</b>
            <div className="qq__step6__summary__item-lines">
              {`$${bondInfo.contract_amount}`}
            </div>
          </div>

          <div className="qq__step6__summary__item">
            <b>Second Lowest Bidder:</b>
            <div className="qq__step6__summary__item-lines">
              {`$${bondInfo.second_lowest_bidder}`}
            </div>
          </div>
        </div>
      )}
      <div className="qq__step6__summary__item">
        <Button
          isLink
          disabled={quickSubmission.isLoading}
          onClick={EditBondInformation}
          inline
        >
          Edit Bond Information
        </Button>
      </div>
    </div>
  );
};

export default BondInfoSummary;
