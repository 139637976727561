import { connect } from "react-redux";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import AccountBillingActivity from "../components/account/billing-details/AccountBillingActivity";
import {
  fetchBillingActivityStatement,
  getLegacyViewStatementPDF,
  getGWViewStatementPDF
} from "../actions/billingActivityAction";
import { fetchDirectBillPayNowEnrollment } from "../actions/directBillPayNowEnrollmentActions";
import { copyAccountAndBillingActivity } from "../agency-sweep/complete-payment/agencySweepAccountBillingActivityAction";
import * as completePaymentSelector from "../agency-sweep/complete-payment/completePaymentSelector";

const isOasisAccount = state => {
  return !!(
    state &&
    state.accountData &&
    state.accountData.accounts &&
    state.accountData.accounts.origin === "oasis"
  );
};

const mapStateToProps = state => ({
  accountData: state.accountData,
  billingActivity: state.billingActivity,
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  activeAgencyCode: sessionSelector.getActiveAgencyCode(state),
  isAgencyAdministrator: sessionSelector.isAgencyAdministrator(state),
  hasDirectBillPayNowEnrollment:
    completePaymentSelector.hasDirectBillPayNowEnrollment(state),
  isOasisAccount: isOasisAccount(state)
});

const mapDispatchToProps = {
  fetchBillingActivityStatement,
  fetchDirectBillPayNowEnrollment,
  getLegacyViewStatementPDF,
  getGWViewStatementPDF,
  copyAccountAndBillingActivity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountBillingActivity);
