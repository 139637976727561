import { connect } from "react-redux";
import ClaimsInquiry from "../components/ClaimsInquiry";
import { cancelClaimInquiry } from "../actions/claimsInquiryActions";

const mapStateToProps = state => ({
  isActivated: state.claimsInquiry.isActivated,
  error: state.claimsInquiry.error
});

const mapDispatchToProps = { handleClose: cancelClaimInquiry };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsInquiry);
