import React, { useEffect, useState, useCallback, useContext } from "react";
import cn from "classnames";
import * as api from "../../../services/onlineQuotingService";
import { LoadingIndicator, Button, formatDate } from "@ufginsurance/ui-kit";
import { FormGroup } from "react-bootstrap";
import { sortByProperty } from "../../shared/util";
import * as SiUtils from "./scheduleItemUtils";
import FormSetup from "../FormSetup";
import OqModal from "../OqModal";
import ScheduleItemContact from "./ScheduleItemContact";
import OnlineQuotingContext from "../../OnlineQuotingContext";

const fieldsWithChangeTypes = ["select", "scheduleItemContact", "switch"];

const ERROR_UPDATING_SCHEDITEM_VALUE =
  "Sorry, an error occurred.  We were unable to save the changes to the field you updated.";

const ScheduleItemModal = ({
  setShowModal,
  apiPayload,
  selectedItem,
  setSelectedItem,
  cancelTheAddItem
}) => {
  const { quoteData, updateScheduledItem, toastErrr, closeUpdatingToast } =
    useContext(OnlineQuotingContext);

  // formData is constructed in the useEffect on component load
  // contains the form definition to send to FormSetup
  const [formData, setFormData] = useState(null);

  // state to handle selection the selection and management of a contact
  const [selectedContact, setSelectedContact] = useState();

  // contact options are updated from the /contacts api at load...
  // and when a contact is added/updated
  const [contactOptions, setContactOptions] = useState([]);

  const [loadingContacts, setLoadingContacts] = useState();

  // formStatus: a state to manage the api-running and ready_to_submit states
  // the ready_to_submit state is used for the scenario where the user udpates a field value
  // and immediately clicks on the form submit button (Continue)
  // this state prevents the form from submitting if there's an error/api failure
  const [formStatus, setFormStatus] = useState({
    api_running: false,
    ready_to_submit: true
  });

  // keep track of invalidFields to disable continue button
  const [invalidFields, setInvalidFields] = useState([]);

  const updateFormStatus = (formId, invalidFields) => {
    setInvalidFields(invalidFields);
  };

  const hasContactField = selectedItem.data.fieldsMetadata.some(
    f => f.key === "AdditionalInsured"
  );

  // ---------------------
  // FUNCTION: UPDATERS
  // ---------------------

  const scheduleItemUpdater = useCallback(
    ({ newFieldsMetadata }) => {
      updateScheduledItem({ apiPayload, selectedItem, newFieldsMetadata })
        .then(({ data }) => {
          setSelectedItem({ ...selectedItem, data });

          setFormStatus({
            api_running: false,
            ready_to_submit: true
          });
        })
        .catch(error => {
          console.error({ error });
          // reset to original values if error occured
          setSelectedItem({ ...selectedItem });
          setFormStatus({
            api_running: false,
            ready_to_submit: false
          });
          if (error?.message !== "No Contact defined")
            toastErrr({
              action: "scheduleItemUpdater > updateQuote",
              payload: apiPayload,
              misc: { selectedItem, newFieldsMetadata },
              error,
              description: "unable to save SchedItem field - PC failure",
              displayMessage: ERROR_UPDATING_SCHEDITEM_VALUE
            });
        });
    },
    [apiPayload, selectedItem, setSelectedItem, toastErrr, updateScheduledItem]
  );

  // when updating the schedule item from the regular form
  const updateScheduleItemFromForm = useCallback(
    ({ field, values, invalidFields }) => {
      // if the form has invalid fields, do not try to save anything
      if (invalidFields.length > 0) {
        return;
      }

      // set formupdate states
      setFormStatus({
        api_running: true,
        ready_to_submit: false
      });

      // compare the new value with the previous value... only run if the value changed
      // prepare the comparing data
      const { field: fieldName, value } = field;
      const updatedField = selectedItem.data.fieldsMetadata.find(
        f => f.key === fieldName
      );
      const { value: previousValue, type } =
        SiUtils.fieldDefinition(updatedField);
      // when comparing date, we need to format it to match first
      const newValue =
        type === "date" ? formatDate(value, "MM/DD/YYYY") : value;
      const oldValue =
        type === "date"
          ? formatDate(previousValue, "MM/DD/YYYY")
          : previousValue;

      // if the value has not changed or if there's a field error, don't update anything
      if (
        newValue === oldValue ||
        (!fieldsWithChangeTypes.includes(type) &&
          invalidFields.some(f => f.name === fieldName))
      ) {
        setFormStatus({
          api_running: false,
          ready_to_submit: true
        });
        return;
      }

      // yay, no errors and we have a new value... let's do the update

      // using a copy of the fieldsMetadata object,
      // update the values in the obect from the form's values
      const newFieldsMetadata =
        selectedItem.data.fieldsMetadata.map(f => {
          const { valueKey, value } = SiUtils.fieldDefinition(f);

          const newValue =
            valueKey === "integerValue"
              ? Number(values[f.key] || value) //Note:  || value will return the original value if it's not in the form (important for "ScheduleNumber")
              : valueKey === "dateValue" && values[f.key] === "" // PC doesn't like date to be an empty string, pass null instead if it's empty
              ? null
              : values[f.key];

          return {
            ...f,
            [valueKey]: newValue
          };
        }) || [];

      scheduleItemUpdater({ newFieldsMetadata });
    },
    [selectedItem.data.fieldsMetadata, scheduleItemUpdater]
  );

  // when updating the schedule item from the contact form...
  // used when a contact is added or edited
  const updateScheduleItemFromContactEditor = async ({ contact }) => {
    // wait for getContacts to finish so the payload includes the contact data
    await getContacts();

    // update the value of the AdditionalInsured from the contact form
    const newFieldsMetadata =
      [...selectedItem.data.fieldsMetadata].map(f => {
        if (f.key !== "AdditionalInsured") return { ...f };
        return { ...f, stringValue: contact.publicID };
      }) || [];

    const maxRetries = 5;
    const retries = [];
    const toastMessage = "Updating Schedule Item Contact";
    do {
      if (retries.length < maxRetries) {
        await updateScheduledItem({
          apiPayload,
          selectedItem,
          newFieldsMetadata,
          letCallerCloseToast: true,
          toastMessage
        })
          .then(({ data }) => {
            /**
             * break the loop if the save is successful
             */
            retries.push(1, 2, 3, 4, 5);
            setSelectedItem({ ...selectedItem, data });
            closeUpdatingToast({ toastId: toastMessage });
            setFormStatus({
              api_running: false,
              ready_to_submit: true
            });
          })
          .catch(({ error }) => {
            /**
             * if the error is something besides "No Contact defined", we'll stop.
             */
            if (error?.message !== "No Contact defined") {
              // reset to original values if error occured
              setSelectedItem({ ...selectedItem });

              toastErrr({
                action: "scheduleItemUpdater > updateQuote",
                payload: apiPayload,
                misc: { selectedItem },
                error,
                description: "unable to save SchedItem field - PC failure",
                displayMessage: ERROR_UPDATING_SCHEDITEM_VALUE
              });
              // break the loop beacuse we got an unexpected error
              retries.push(1, 2, 3, 4, 5);
              closeUpdatingToast({ toastId: toastMessage });
              setFormStatus({
                api_running: false,
                ready_to_submit: false
              });
            }

            // push into retry array - if we get this error too many times, we should still stop looping
            retries.push("X");
            if (retries.length >= maxRetries) {
              closeUpdatingToast({ toastId: toastMessage });
              setFormStatus({
                api_running: false,
                ready_to_submit: false
              });
            }
          })
          .finally(async () => {
            if (retries.length < maxRetries)
              await new Promise(resolve => setTimeout(resolve, 2000));
          });
      }
    } while (retries.length < maxRetries);
  };

  // getContacts set the options for the AdditionalInsured field
  const getContacts = useCallback(() => {
    // clear existing contacts (triggers the loading indicator on the dropdown)
    setContactOptions([]);
    // get the contacts and update state
    setLoadingContacts(true);
    return api
      .getScheduleItemContacts({ accountId: quoteData.baseData.accountNumber })
      .then(results => {
        const newContactOptions =
          (results?.data || []).map(c => ({
            value: c.publicID,
            label: c.displayName,
            publicId: c.publicID
          })) || [];
        setContactOptions(newContactOptions.sort(sortByProperty("label")));
        return results;
      })
      .finally(() => setLoadingContacts(false));
  }, [quoteData.baseData.accountNumber]);

  // ---------------------
  // ON LOAD: SETUP FORM
  // ---------------------

  // on the first load of the form, load contacts if there's an AdditionalInsured field
  useEffect(() => {
    if (!formData) {
      if (hasContactField) getContacts();
    }
  }, [
    formData,
    getContacts,
    hasContactField,
    selectedItem.data.fieldsMetadata
  ]);

  // on load, pull the fieldsMetadata from the schedule item ...
  //  and create the form definition for FormSetup
  useEffect(() => {
    if (!!quoteData && !!selectedItem) {
      const fields =
        selectedItem.data.fieldsMetadata
          .filter(f => f.key !== "ScheduleNumber" && !!f.visible)
          .map(f => {
            const { value, valueKey, type } = SiUtils.fieldDefinition(f);

            const isContact = f.key === "AdditionalInsured";

            // the AdditionalInsured field is not disabled when we create a new Schedule Item
            // the options for the AdditionalInsureds are populated by mule only during the first
            // call to create the schedule item... otherwise, the
            const isDisabled = isContact
              ? false
              : f?.editable === false || false;

            const term = {
              key: f?.key,
              label: f?.label,
              id: f?.key,
              path: f?.key,
              type: type || "text",
              required: f?.required || false,
              options: (isContact
                ? contactOptions
                : f?.valueRange && !!f?.valueRange.length
                ? f?.valueRange.map(o => ({
                    value: o.publicId || o.value,
                    label: o.value
                  }))
                : []
              ).sort(sortByProperty("label")),
              onBlur: updateScheduleItemFromForm,
              /**
               * no `onChange` method... because...
               * All changes to occur after onBlur
               * because that's when the `invalidFields` response is accurate
               */
              onClickAddScheduleItemContact: isContact // custom events specifically for the contact buttons
                ? () => setSelectedContact({ field: f.key })
                : undefined,
              onClickEditScheduleItemContact: isContact // custom events specifically for the contact buttons
                ? ({ contactId }) => {
                    setSelectedContact({
                      field: f.key,
                      id: contactId
                    });
                  }
                : undefined,
              defaultValue: value || "",
              disabled: isDisabled,
              isLoading: isContact ? !contactOptions : undefined,
              mask: f.type === "Integer" ? "numberCommas" : null,
              validation:
                valueKey === "integerValue"
                  ? {
                      validatorfunc: value => {
                        if (isNaN(value)) return false;
                        return true; //ignore validation
                      },
                      errorMessage: `${f?.label} must be a number.`
                    }
                  : undefined
            };

            if (term.type === "text") {
              term.maxLength = 255;
            }
            return term;
          }) || [];

      const formPanels = [
        {
          title: "",
          key: selectedItem.data.scheduleItemFixedId,
          rows: [
            {
              key: selectedItem.data.scheduleItemFixedId + "row",
              fields
            }
          ]
        }
      ];
      setFormData(formPanels);
    }
  }, [
    quoteData,
    selectedItem,
    scheduleItemUpdater,
    contactOptions,
    updateScheduleItemFromForm
  ]);

  const handleOnCancel = useCallback(() => {
    // if this is an item that was just added...
    // then delete it if cancel is clicked
    if (selectedItem?.isNew) cancelTheAddItem(selectedItem.data);
    // then close the modal
    setSelectedItem(null);
    setShowModal(false);
    setRunAfterApi(null);
  }, [
    cancelTheAddItem,
    selectedItem.data,
    selectedItem.isNew,
    setSelectedItem,
    setShowModal
  ]);

  // hitting continue just closes the modal because the values are already saved
  const handleOnContinue = useCallback(() => {
    if (formStatus.ready_to_submit) {
      setSelectedItem(null);
      setShowModal(false);
      setRunAfterApi(null);
    }
  }, [formStatus.ready_to_submit, setSelectedItem, setShowModal]);

  const [runAfterApi, setRunAfterApi] = useState();

  useEffect(() => {
    if (!formStatus.api_running && !!runAfterApi) {
      if (runAfterApi === "continue" && invalidFields?.length === 0) {
        handleOnContinue();
      }
      if (runAfterApi === "cancel") {
        handleOnCancel();
      }
    }
  }, [
    formStatus,
    handleOnCancel,
    handleOnContinue,
    invalidFields?.length,
    runAfterApi
  ]);

  // dynamic modal title changes between "schedule items" and "contacts"
  const modalTitle = !!selectedContact
    ? !!selectedContact?.id
      ? "Edit Contact"
      : "Add Contact"
    : "Edit Scheduled Item";

  const handleContinueButton = useCallback(() => {
    // when clicking the continue button, we check to see if an api call is running
    // if it is, we update the runAfterApi state with the function to run after the call is complete

    // we also update the classname so that it sits ontop of any coverage overlays
    // so the button can be clicked
    if (formStatus.api_running) setRunAfterApi("continue");
    // no api calls happening, just do it
    else handleOnContinue();
  }, [formStatus, handleOnContinue]);

  const handleCancelButton = useCallback(() => {
    // when clicking the continue button, we check to see if an api call is running
    // if it is, we update the runAfterApi state with the function to run after the call is complete

    // we also update the classname so that it sits ontop of any coverage overlays
    // so the button can be clicked
    if (formStatus.api_running) setRunAfterApi("cancel");
    // no api calls happening, just do it
    else handleOnCancel();
  }, [formStatus.api_running, handleOnCancel]);

  return (
    <OqModal
      size="md"
      className="oq__schedule-item-modal"
      title={modalTitle}
      subTitle={!selectedContact && apiPayload.coverableName}
      show
      closeIcon={false}
      body={
        !formData ? (
          <LoadingIndicator />
        ) : (
          <div className="oq__schedule-item-form__container">
            <div className="oq__schedule-item-form">
              {hasContactField && loadingContacts ? (
                <LoadingIndicator message="Loading Contacts..." />
              ) : !selectedContact ? (
                <>
                  <FormSetup
                    panels={formData}
                    submitBtnLabel="Continue"
                    useCoveragePanel
                    hideSubmitBtn
                    updateFormStatus={updateFormStatus}
                    className="oq__schedule-item-form"
                  />
                  <FormGroup align="right">
                    {selectedItem?.isNew && (
                      <Button
                        isLink
                        className={cn({
                          // this class brings button up over the overlay so the click will register
                          "oq__on-top-of-overlay":
                            formStatus.api_running && !runAfterApi,
                          "oq__button-appears-disabled": formStatus.api_running
                        })}
                        disabled={!!runAfterApi}
                        onMouseDown={() =>
                          // using setTimeout here to allow the onBlur event to trigger first
                          // ... so it can update the formStatus.api_running before the useEffect triggers "continue"
                          setTimeout(() => setRunAfterApi("cancel"))
                        }
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      className={cn({
                        // this class brings button up over the overlay so the click will register
                        "oq__on-top-of-overlay":
                          formStatus.api_running && !runAfterApi,
                        "oq__button-appears-disabled": formStatus.api_running
                      })}
                      disabled={
                        invalidFields.length > 0 ||
                        !!runAfterApi ||
                        (hasContactField && contactOptions?.length === 0)
                      }
                      spinner={formStatus.api_running}
                      onMouseDown={() =>
                        // using setTimeout here to allow the onBlur event to trigger first
                        // ... so it can update the formStatus.api_running before the useEffect triggers "continue"
                        setTimeout(() => setRunAfterApi("continue"))
                      }
                      onClick={handleContinueButton}
                    >
                      Continue
                    </Button>
                  </FormGroup>
                </>
              ) : (
                <ScheduleItemContact
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  getContacts={getContacts}
                  updateScheduleItemFromContactEditor={
                    updateScheduleItemFromContactEditor
                  }
                />
              )}
            </div>
          </div>
        )
      }
    />
  );
};

export default ScheduleItemModal;
