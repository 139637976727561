import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, Button } from "@ufginsurance/ui-kit";
import "./QuotingSteps.scss";
import ExitQuoteModal from "./shared/ExitQuoteModal";
import FeedbackForm from "./shared/FeedbackForm";
import { useFlags } from "launchdarkly-react-client-sdk";
import { closeProductSelectionModal } from "../commercial/shared/ProductSelection/ProductSelectionModal";

const QuotingHeader = ({ history, closeProductSelectionModal }) => {
  const [showExitQuoteModal, setShowExitQuoteModal] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const { proQuoteFeedbackEmailList, buildersRiskEnabled } = useFlags();

  const handleExitClick = () => {
    if (buildersRiskEnabled) closeProductSelectionModal();
    setShowExitQuoteModal(true);
  };

  const handleFeedbackClick = () => {
    setShowFeedbackForm(true);
  };

  return (
    <div>
      {showExitQuoteModal && (
        <ExitQuoteModal
          history={history}
          setShowExitQuoteModal={setShowExitQuoteModal}
        />
      )}
      {showFeedbackForm && (
        <FeedbackForm
          show={showFeedbackForm}
          onHide={setShowFeedbackForm}
        />
      )}
      <div id="OQ__header">
        <div id="OQ_menu" className="oq__main-nav">
          <div className="headerMenu__navbar-container">
            <ul className="main-nav__nav-list">
              <li
                role="presentation"
                className="route-item
              oq__header-menu__feedback_exit_item"
              >
                <button onClick={handleExitClick}>
                  <span className="oq__header-menu__exit-quote">
                    <Icon icon="farAngleLeft" />
                    EXIT QUOTE
                  </span>
                </button>
              </li>
              {(proQuoteFeedbackEmailList?.emailAddresses || []).length > 0 && (
                <li
                  role="presentation"
                  className="route-item oq__header-menu__feedback_list_item"
                >
                  <Button
                    onClick={handleFeedbackClick}
                    variant="info"
                    className="oq__header-menu__feedback-button-quote"
                  >
                    <span className="oq__header-menu__feedback-quote">
                      <Icon icon="farEnvelope" />
                      Feedback
                    </span>
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { closeProductSelectionModal })(QuotingHeader);
