import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";
import { v4 } from "uuid";

const agencyLossRunReducer = (
  state = initialState.producerEmailList,
  action
) => {
  switch (action.type) {
    case types.FETCH_EMAIL_LIST_STARTED:
      return {
        ...state,
        isLoading: true,
        emailList: [],
        error: false
      };
    case types.FETCH_EMAIL_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        emailList: action.payload.map(n => ({
          id: v4(),
          ...n
        })),
        error: false
      };
    case types.FETCH_EMAIL_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        emailList: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default agencyLossRunReducer;
