//https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
const ErrorCodes = {
  NotFound: 404,
  ServerError: 500,
  NotImplemented: 501,
  Unauthorized: 401,
  ServiceUnavailable: 503,
  UnknownError: 520,
  BadRequest: 400
};
export default ErrorCodes;
