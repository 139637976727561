import { v4 } from "uuid";
import * as types from "../constants/actionTypes";
import initialState from "../reducers/initialState";

const reducer = (state = initialState.currentPolicyData, action) => {
  switch (action.type) {
    case types.FETCH_CURRENT_SUMMARY_STARTED:
      return {
        ...state,
        currentSummaryIsloading: true,
        currentSummaryError: false,
        currentSummary: []
      };
    case types.FETCH_CURRENT_SUMMARY_SUCCEEDED:
      return {
        ...state,
        currentSummaryIsloading: false,
        currentSummary: action.payload,
        currentSummaryError: false,
        ufgDocuments: {}
      };
    case types.FETCH_CURRENT_SUMMARY_FAILED:
      return {
        ...state,
        currentSummaryIsloading: false,
        currentSummaryError: action.payload,
        currentSummary: [],
        ufgDocuments: {}
      };
    case types.FETCH_OASIS_DOCUMENT_SUMMARY_STARTED:
      return {
        ...state,
        ...(action.isCurrentTerm ? {
          oasisDocumentSummaryErrorCurrent: false,
          oasisDocumentSummaryIsLoadingCurrent: true,
          oasisDocumentSummaryCurrent: []
        } :
        {
          oasisDocumentSummaryErrorRenewal: false,
          oasisDocumentSummaryIsLoadingRenewal: true,
          oasisDocumentSummaryRenewal: []
        })
      };
    case types.FETCH_OASIS_DOCUMENT_SUMMARY_SUCCEEDED:
      return {
        ...state,
        ...(action.isCurrentTerm ? {
          oasisDocumentSummaryCurrent: action.payload.map(d => ({
            id: v4(),
            ...d
          })),
          oasisDocumentSummaryIsLoadingCurrent: false,
          oasisDocumentSummaryErrorCurrent: false
        } :
        {
          oasisDocumentSummaryRenewal: action.payload.map(d => ({
            id: v4(),
            ...d
          })),
          oasisDocumentSummaryIsLoadingRenewal: false,
          oasisDocumentSummaryErrorRenewal: false
        })
      };
    case types.FETCH_OASIS_DOCUMENT_SUMMARY_FAILED:
      return {
        ...state,
        ...(action.isCurrentTerm ? {
          oasisDocumentSummaryErrorCurrent: action.payload,
          oasisDocumentSummaryIsLoadingCurrent: false,
          oasisDocumentSummaryCurrent: []
        } :
        {
          oasisDocumentSummaryErrorRenewal: action.payload,
          oasisDocumentSummaryIsLoadingRenewal: false,
          oasisDocumentSummaryRenewal: []
        })
      };
    case types.FETCH_UFG_DOCUMENT_SUMMARY_STARTED:
      return {
        ...state,
        ufgDocuments: {
          ...state.ufgDocuments,
          [action.declarationCode + action.policyTerm]: {
            documents: [],
            isLoading: true,
            error: undefined
          }
        }
      };
    case types.FETCH_UFG_DOCUMENT_SUMMARY_SUCCEEDED: {
      const documents = action.payload.map(d => ({
        id: v4(),
        ...d
      }));

      return {
        ...state,
        policyTerm: action.policyTerm,
        ufgDocuments: {
          ...state.ufgDocuments,
          [action.declarationCode + action.policyTerm]: {
            documents,
            isLoading: false,
            error: undefined
          }
        }
      };
    }
    case types.FETCH_UFG_DOCUMENT_SUMMARY_FAILED:
      return {
        ...state,
        ufgDocuments: {
          ...state.ufgDocuments,
          [action.declarationCode + action.policyTerm]: {
            documents: [],
            isLoading: false,
            error: action.error
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
