import * as types from "../actions/actionTypes";
import initialState from "../../reducers/initialState";
import { v4 } from "uuid";

// eslint-disable-next-line complexity
const claimsReducer = (state = initialState.claims, action) => {
  switch (action.type) {
    case types.CLEAR_CLAIMS: {
      return {
        ...initialState.claims
      };
    }
    case types.FETCH_CLAIMS_STARTED: {
      return {
        ...state,
        claimsLoading: true,
        claimsError: null
      };
    }
    case types.FETCH_CLAIMS_SUCCEEDED: {
      return {
        ...state,
        claims: action.payload,
        claimsLoading: false,
        claimsError: null
      };
    }
    case types.FETCH_CLAIMS_FAILED:
      return {
        ...state,
        claims: [],
        claimsLoading: false,
        claimsError: action.error
      };
    case types.FETCH_CLAIM_SUCCEEDED:
      return {
        ...state,
        claims: [action.payload],
        isLoading: false,
        error: null,
        claimsError: null
      };
    case types.FETCH_CLAIM_FAILED:
      return {
        ...state,
        claims: [],
        claimsLoading: false,
        claimsError: action.error
      };
    case types.FETCH_CLAIMS_PAYMENTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        claimsError: null
      };
    case types.FETCH_CLAIMS_PAYMENTS_SUCCEEDED:
      return {
        ...state,
        payments: {
          ...state.payments,
          [action.payload.claimNumber]: action.payload.data.map(payment => ({
            ...payment,
            id: v4()
          }))
        },
        isLoading: false,
        error: null
      };
    case types.FETCH_CLAIMS_PAYMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_CLAIMS_PAYMENT_DETAILS_STARTED:
      return {
        ...state,
        paymentDetails: null,
        printPaymentDetails: {
          isLoading: false,
          error: false
        },
        isLoading: true,
        error: null
      };
    case types.FETCH_CLAIMS_PAYMENT_DETAILS_SUCCEEDED:
      return {
        ...state,
        paymentDetails: {
          ...action.payload,
          claimants: action.payload.claimants.map(claimant => ({
            ...claimant,
            id: v4()
          }))
        },
        isLoading: false,
        error: null
      };
    case types.FETCH_CLAIMS_PAYMENT_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_CLAIMS_CLAIMANTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_CLAIMS_CLAIMANTS_SUCCEEDED:
      return {
        ...state,
        claimants: {
          ...state.claimants,
          [action.payload.claimNumber]: action.payload.data.map(c => ({
            id: v4(),
            ...c
          }))
        },
        isLoading: false,
        error: null
      };
    case types.FETCH_CLAIMS_CLAIMANTS_FAILED:
      return {
        ...state,
        claimants: {
          ...state.claimants,
          [action.payload.claimNumber]: []
        },
        isLoading: false,
        error: action.payload.error
      };

    case types.PRINT_CLAIMS_PAYMENT_DETAILS_STARTED:
      return {
        ...state,
        printPaymentDetails: {
          isLoading: true,
          error: false
        }
      };
    case types.PRINT_CLAIMS_PAYMENT_DETAILS_SUCCEEDED:
      return {
        ...state,
        printPaymentDetails: {
          isLoading: false,
          error: false
        }
      };
    case types.PRINT_CLAIMS_PAYMENT_DETAILS_FAILED:
      return {
        ...state,
        printPaymentDetails: {
          isLoading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

export default claimsReducer;
