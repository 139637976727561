import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { LoadingIndicator } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";
import { printPdf } from "../../shared/documents/helpers";

const DISPLAY_STATE_TYPES = {
    NOT_LOADING: 1,
    LOADING: 2,
    ERROR: 3,
    COMPLETE: 4
}

const PrintForms = () => {
    const history = useHistory();

    const [displayState, setDisplayState] = useState(DISPLAY_STATE_TYPES.NOT_LOADING);

    const match = useRouteMatch(routes.DOCUMENT_LIBRARY_PRINT_FORM_NUMBER);

    const onPrintStart = () => {
        setDisplayState(DISPLAY_STATE_TYPES.LOADING);
    };

    const onPrintSuccess = () => {
        setDisplayState(DISPLAY_STATE_TYPES.COMPLETE);
    };

    const onPrintError = (data) => {
        if (data && data?.response?.status !== 200) {
            setDisplayState(DISPLAY_STATE_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (match.isExact && displayState === DISPLAY_STATE_TYPES.NOT_LOADING) {
            printPdf({
                form_number: match.params.form_number,
                form_type: match.params.form_type,
                onStart: onPrintStart,
                onSuccess: onPrintSuccess,
                onError: onPrintError
            });
        }
        else if (displayState === DISPLAY_STATE_TYPES.COMPLETE) {
            history.push(routes.DOCUMENT_LIBRARY_COVERAGE);
        }
    }, [match, displayState, history])

    return (
        <div className="document-library">
            {displayState === DISPLAY_STATE_TYPES.LOADING &&
                <LoadingIndicator />
            }

            {displayState === DISPLAY_STATE_TYPES.ERROR &&
                <div className="friendly-error-messaging">
          <HelpDeskMessage display="anErrorOccurred" />
                </div>
            }
        </div>
    )
}

export default PrintForms;