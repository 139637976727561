import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Translations } from "../../components/translations";
import AuditBreakdownList from "./AuditBreakdownList";
import { LoadingIndicator, Button, Modal, FlexRow } from "@ufginsurance/ui-kit";

const AuditBreakdownModal = props => {
  const { fetchAuditBreakdown, show, onHide, searchNumber, audits, isLoading } =
    props;

  useEffect(() => {
    fetchAuditBreakdown(searchNumber);
  }, [fetchAuditBreakdown, searchNumber]);

  const hasAudits = audits && audits.length > 0;

  return (
    <Modal
      body={
        isLoading ? (
          <LoadingIndicator />
        ) : hasAudits ? (
          <AuditBreakdownList audits={audits} searchNumber={searchNumber} />
        ) : (
          <div>{Translations.review_audit.no_audits_message}</div>
        )
      }
      footer={
        <FlexRow align="right">
          <Button type="button" variant="secondary" onClick={onHide}>
            {Translations.review_audit.close}
          </Button>
        </FlexRow>
      }
      title={
        <>
          {Translations.review_audit.title}
          {searchNumber}
        </>
      }
      show={show}
      onHide={onHide}
    />
  );
};

AuditBreakdownModal.propTypes = {
  audits: PropTypes.array,
  isLoading: PropTypes.bool,
  searchNumber: PropTypes.string,
  show: PropTypes.bool,
  fetchAuditBreakdown: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default AuditBreakdownModal;
