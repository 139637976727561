import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import {
  Button,
  ContentHeader,
  FlexRow,
  Modal,
  Panel,
  StepIndicator,
  useForm,
  LoadingIndicator,
  formatDate
} from "@ufginsurance/ui-kit";
import { HOME } from "../../constants/routes";
import { getSelectedAgencyCode } from "../../selectors/agencySelectors";
import HeaderContainer from "../../containers/HeaderContainer";
import FooterContainer from "../../containers/FooterContainer";
import { logoGetStarted } from "../../constants/images";
import QuoteSummary from "./QuoteSummary";
import InsuredInfoForm from "./InsuredInfoForm";
import JobSiteInfo from "./JobSiteInfo";
import useGetContactDetailsMeta from "./hooks/useGetContactDetailsMeta";
import useGetRiskIndication from "./hooks/useGetRiskIndication";
import UnderwritingTeam from "./UnderwritingTeam";
import VerifyAddress from "../../quick-program/quick-quoting/shared/AddressVerification/VerifyAddress";
import Summary from "./Summary";
import "./BuildersRisk.scss";
import {
  postRiskIndicationRecord,
  putRiskIndicationRecord,
  notifyUnderwriting,
  rateRiskIndication
} from "../shared/services/index";
import {
  createRiskIndicationPayload,
  getEmptyBuildersRisk,
  getPrefilledBuildersRisk
} from "./lib/utils";
import { STATUS, buildersRiskErrors } from "./lib/constants";
import ToastError from "./components/ToastError";
import useGetAgencyInfo from "../../shared/hooks/useGetAgencyInfo";

function BuildersRisk({ agencyCode, is109015Agent, history }) {
  const location = useLocation();
  const [readyToContinue, setReadyToContinue] = useState(false);
  const [addressToVerify, setAddressToVerify] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showSubmitSuccessModal, setShowSubmitSuccessModal] = useState(false);
  const [meta] = useGetContactDetailsMeta(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [isRerendering, setIsRerendering] = useState(false);
  const lastVerifiedAddress = useRef(null);
  const lastAccountAndEffectiveDate = useRef(null);
  const isInitializing = useRef(true);
  const commenting = useRef("");
  const [isValidEffectiveDate, setIsValidEffectiveDate] = useState(true);

  const [riskData, isFetchingRiskData] = useGetRiskIndication(
    new URLSearchParams(location.search).get("id")
  );

  useEffect(() => {
    document.title = `${document.title} | Builders' Risk`;
  }, []);

  // Passing customer information from the Product Selection Modal when available.
  const initialValues = getEmptyBuildersRisk(agencyCode);

  const form = useForm({
    values: initialValues,
    onSubmit: () => {}
  });

  const { updateForm, values, errors, invalidFields, validateForm } = form;

  const [inlandMarineUderwriter, setInlandMarineUderwriter] = useState(null);

  useGetAgencyInfo(agencyCode, async agencyData => {
    const underwriter = agencyData?.ufgteam?.inland_marine_underwriter;
    if (
      !underwriter?.email ||
      inlandMarineUderwriter?.email === underwriter?.email
    )
      return;
    setInlandMarineUderwriter(underwriter);
  });

  useEffect(() => {
    if (!inlandMarineUderwriter?.email) return;
    if (inlandMarineUderwriter?.email === values?.underwriter?.email) return;
    const { first_name, last_name, email, phone } = inlandMarineUderwriter;
    updateForm({
      values: {
        ...values,
        underwriter: { first_name, last_name, email, phone }
      }
    });
  }, [inlandMarineUderwriter, updateForm, values]);

  const hasAccount = () =>
    values.account_name || (values.first_name && values.last_name);

  const resetForm = () => {
    updateForm({
      values: initialValues,
      errors: buildersRiskErrors
    });
  };

  const saveOrUpdate = () => {
    if (
      isInitializing.current ||
      isLoading ||
      isFetchingRiskData ||
      !hasAccount()
    )
      return;
    setIsLoading(true);

    const request = createRiskIndicationPayload(values);

    const create = () => {
      let error;
      postRiskIndicationRecord(request)
        .then(_ => {
          if (_.status !== 201) {
            error = _;
          } else {
            updateForm({
              values: {
                ...values,
                risk_indication_id: _?.data?.risk_indication_id
              }
            });
            setTimeout(() => {
              setIsLoading(false);
              setIsRerendering(false);
            }, 500);
          }
        })
        .catch(_ => (error = _))
        .finally(() => {
          if (!!error) {
            setIsLoading(false);
            setIsRerendering(false);
            ToastError(error);
          }
        });
    };

    const update = () => {
      let error;
      putRiskIndicationRecord(values?.risk_indication_id, request)
        .then(_ => {
          if (_.status !== 201) error = _;
        })
        .catch(_ => (error = _))
        .finally(() => {
          if (!error) {
            rate(request);
          } else {
            ToastError(error);
            setIsLoading(false);
            setIsRerendering(false);
          }
        });
    };

    const rate = () => {
      if (values?.status !== STATUS.InProgress) {
        setIsLoading(false);
        setIsRerendering(false);
      } else {
        let error;
        rateRiskIndication(values?.risk_indication_id, request)
          .then(_ => {
            if (_.status !== 201) {
              error = _;
            } else {
              updateForm({
                values: {
                  ...getPrefilledBuildersRisk(
                    {
                      ...values,
                      ...(_?.data || {})
                    },
                    agencyCode
                  )
                }
              });
              setTimeout(() => {
                setIsLoading(false);
                setIsRerendering(false);
              }, 500);
            }
          })
          .catch(_ => (error = _))
          .finally(() => {
            if (!!error) {
              ToastError(error);
              setIsLoading(false);
              setIsRerendering(false);
            }
          });
      }
    };

    if (!values?.risk_indication_id) {
      create();
    } else {
      update();
    }
  };

  useEffect(() => {
    if (!(values?.premium > 0)) return;
    saveOrUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.premium]);

  useEffect(() => {
    saveOrUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.additional_insured]);

  useEffect(() => {
    //Don't attempt to save or update on page 1
    if (values.step === 1) return;
    saveOrUpdate();
    if (
      formatDate(values.effective_date, "YYYY-MM-DD") <
      formatDate(Date.now(), "YYYY-MM-DD")
    ) {
      setIsValidEffectiveDate(false);
    } else {
      setIsValidEffectiveDate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.effective_date]);

  useEffect(() => {
    if (!values.producer_code) return;
    saveOrUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.producer_code]);

  useEffect(() => {
    if (commenting.current) clearTimeout(commenting.current);
    commenting.current = setTimeout(() => saveOrUpdate(), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.comments]);

  useEffect(() => {
    if (!values.risk_indication_id || !isInitializing.current) return;
    isInitializing.current = false;
    const t = setTimeout(validateForm, 3000);

    return () => {
      clearTimeout(t);
    };
    // Exclude: validateForm.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.risk_indication_id]);

  useEffect(() => {
    setIsRerendering(true);
    setTimeout(() => {
      const id = new URLSearchParams(location.search).get("id");
      if (!id) {
        setTimeout(() => {
          isInitializing.current = false;
          setIsRerendering(false);
        }, 1000);
      } else {
        if (!riskData || isFetchingRiskData) return;
        history.replace({ search: "" });
        isInitializing.current = true;
        lastVerifiedAddress.current = {
          address_line: riskData?.address_line,
          city: riskData?.city,
          state: riskData?.state,
          zip: riskData?.zip
        };
        updateForm({ values: getPrefilledBuildersRisk(riskData, agencyCode) });
      }
      if (
        riskData &&
        formatDate(riskData?.effective_date, "YYYY-MM-DD") <
          formatDate(new Date(), "YYYY-MM-DD")
      ) {
        setIsValidEffectiveDate(false);
      }
      setIsRerendering(false);
    }, 1000);
    // eslint-disable-next-line
  }, [riskData, isFetchingRiskData]);

  // Determines whether the Continue button is enabled.
  useEffect(() => {
    const hasErrors = !!Object.keys(errors)?.length || !!invalidFields?.length;

    if (hasErrors) {
      setReadyToContinue(false);
    } else if (
      (values.step === 1 && !values?.account_name && !values?.last_name) ||
      (values.step === 2 && !values?.job_sites?.length)
    ) {
      setReadyToContinue(false);
    } else {
      setReadyToContinue(true);
    }
  }, [errors, invalidFields, values]);

  const closeSuccessModal = () => {
    history.replace(HOME);
  };

  const updateStep = nextStep => {
    nextStep = nextStep < 1 ? 1 : nextStep > 3 ? 3 : nextStep;
    if (nextStep === values.step) return;
    updateForm({
      values: {
        ...values,
        step: nextStep,
        priorStep: values.step
      }
    });
  };

  const onPreviousClicked = () => {
    if (values.step === 3) {
      // Reset summary fields errors when navigating back to job sites.
      updateForm({
        errors: {
          ...errors,
          premium: [],
          producer_code: [],
          producer: []
        }
      });
    }
    updateStep(values.step - 1);
  };

  useEffect(() => {
    if (values.step !== values.priorStep) setIsRerendering(true);
    saveOrUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.step]);

  const onContinueClicked = async () => {
    const {
      account_name,
      address_line,
      city,
      effective_date,
      state,
      zip,
      risk_indication_id: riskIndicationId
    } = values;
    if (
      values.step === 1 &&
      !isEqual({ address_line, city, state, zip }, lastVerifiedAddress?.current)
    ) {
      lastAccountAndEffectiveDate.current = { account_name, effective_date };
      setAddressToVerify({ address_line, city, state, zip });
      setShowVerifyModal(true);
    } else {
      // Address verification handles the step 1 to step 2 nav.
      updateStep(values.step + 1);
      if (
        !isEqual(
          { account_name, effective_date },
          lastAccountAndEffectiveDate?.current
        )
      ) {
        lastAccountAndEffectiveDate.current = { account_name, effective_date };
      }
    }

    // Final submission, only called when user clicks Notify
    if (values.step === 3) {
      setIsLoading(true);
      setShowSubmitSuccessModal(true);
      try {
        const submission = await putRiskIndicationRecord(
          riskIndicationId,
          createRiskIndicationPayload({ ...values, status: STATUS.UnderReview })
        );

        if (submission.status === 201) {
          try {
            const notify = await notifyUnderwriting(
              riskIndicationId,
              agencyCode,
              values?.underwriter?.email
            );
            if (notify.status === 201) setIsLoading(false);
          } catch (notifyError) {
            setShowSubmitSuccessModal(false);
            ToastError("Failed to complete submission", true);
          }
        }
      } catch (error) {
        ToastError(error, true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const verifiedAddressContinue = () => {
    updateStep(values.step + 1);
    setShowVerifyModal(false);
    setAddressToVerify(null);
  };

  const updateLastVerifiedAddress = address => {
    if (!address) return;
    lastVerifiedAddress.current = {
      address_line: address?.address_line,
      city: address?.city,
      state: address?.state,
      zip: address?.zip
    };
  };

  const updateAddressValues = ({ address, isCancel }) => {
    if (!isCancel) {
      const { address_line, city, state, zip } = address;
      if (
        isEqual(
          { address_line, city, state, zip },
          lastVerifiedAddress?.current
        )
      )
        return;
      updateLastVerifiedAddress(address);
      updateForm({
        values: {
          ...values,
          ...address
        }
      });
    }
  };

  return (
    <div className="builders-risk">
      <HeaderContainer />

      <main>
        <div className="sidebar">
          <StepIndicator totalSteps={3} currentStep={values.step} />
          {values.step === 1 ? (
            <img src={logoGetStarted} alt="Lets get started" />
          ) : (
            <>
              <QuoteSummary
                form={form}
                isValidEffectiveDate={isValidEffectiveDate}
              />
              <UnderwritingTeam underwriter={values?.underwriter || null} />
            </>
          )}
        </div>

        <div className="forms">
          {isFetchingRiskData || isRerendering ? (
            <LoadingIndicator />
          ) : (
            <>
              {values.step === 1 && (
                <InsuredInfoForm
                  form={form}
                  agencyCode={agencyCode}
                  licensedStates={meta?.licensed_states || []}
                  resetForm={resetForm}
                  updateLastVerifiedAddress={updateLastVerifiedAddress}
                />
              )}
              {values.step === 2 && (
                <>
                  <ContentHeader>Tell us about the project</ContentHeader>
                  <Panel rounded bgcolor="grey" title="Job Site(s)" titlebar>
                    <JobSiteInfo
                      form={form}
                      is109015Agent={is109015Agent}
                      licensedStates={meta?.licensed_states || []}
                      saveOrUpdate={saveOrUpdate}
                    />
                  </Panel>
                </>
              )}
              {values.step === 3 && (
                <Summary
                  form={form}
                  producers={meta?.producers || []}
                  agencyCode={agencyCode}
                  isValidEffectiveDate={isValidEffectiveDate}
                />
              )}

              <FlexRow align={values.step === 1 ? "right" : "justify"}>
                {values.step > 1 && (
                  <Button
                    isLink
                    variant="primary"
                    disabled={isLoading}
                    onClick={onPreviousClicked}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={onContinueClicked}
                  disabled={
                    !readyToContinue ||
                    isLoading ||
                    showSubmitSuccessModal ||
                    (values.step === 3 && !isValidEffectiveDate)
                  }
                  spinner={isLoading}
                >
                  {values.step === 1 && "Continue to Job Site Information"}
                  {values.step === 2 && "Continue to Review Quote"}
                  {values.step === 3 && "Submit for Underwriter Review"}
                </Button>
              </FlexRow>
            </>
          )}
        </div>
      </main>

      <Modal
        show={showVerifyModal}
        altCloseMethod={false}
        title="Verify Addresses"
        className="qq__verify-address__modal"
        size="lg"
        closeIcon={false}
        body={
          <VerifyAddress
            updateAddressFormFields={updateAddressValues}
            addressToVerify={addressToVerify}
            setAddressToVerify={setAddressToVerify}
            handleContinue={verifiedAddressContinue}
            setShowVerifyModal={setShowVerifyModal}
          />
        }
      />

      <Modal
        show={showSubmitSuccessModal}
        altCloseMethod={false}
        title="Confirmation"
        size="md"
        closeIcon={false}
        body={
          <>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <>
                <strong>
                  Thank you for your business. Your submission has been sent to
                  your underwriting team for review.
                  <br />
                  <br />
                  Underwriting team next steps:
                  <br />
                </strong>
                <p>
                  Your underwriting team will review this submission and reach
                  out to you. This usually takes about{" "}
                  <strong>1 business day.</strong>
                </p>
              </>
            )}
          </>
        }
        footer={
          <FlexRow align="right">
            <Button variant="primary" onClick={closeSuccessModal}>
              Exit
            </Button>
          </FlexRow>
        }
      />

      <FooterContainer />
    </div>
  );
}

const mapState = state => ({
  agencyCode: getSelectedAgencyCode(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state)
});

export default connect(mapState)(BuildersRisk);
