import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../components/translations";

import {
  FormGroup,
  Form,
  useForm,
  DateRange,
  Button
} from "@ufginsurance/ui-kit";

// set date values for initial values and datepicker range limits
const minDate = new Date();
minDate.setDate(minDate.getDate() - 365 * 7 - 1);
minDate.setHours(0, 0, 0, 0);

const maxDate = new Date();
maxDate.setHours(0, 0, 0, 0);

const initialFromDate = new Date();
initialFromDate.setDate(initialFromDate.getDate() - 9);
maxDate.setHours(0, 0, 0, 0);

// The Form
const PolicyActivityForm = ({
  fetchPolicyActivityList,
  policy_number,
  policy_type
}) => {
  const [runOnceChecker, setRunOnceChecker] = useState(0);
  const [runOnceData, setRunOnceData] = useState({
    policy_number,
    policy_type
  });
  const handleSubmission = ({ values }) => {
    if (policy_number && policy_type)
      fetchPolicyActivityList({
        policy_number,
        policy_type,
        begin_date: values.policyActivityFormRange.from,
        end_date: values.policyActivityFormRange.to
      });
  };

  const initialValues = {
    policyActivityFormRange: { from: initialFromDate, to: maxDate }
  };

  // using runOnce state to make sure this only runs one time at load or when policy_number/policy_type changes
  useEffect(() => {
    if (
      runOnceData.policy_number !== policy_number ||
      runOnceData.policy_type !== policy_type
    ) {
      setRunOnceData({ policy_number, policy_type });
      setRunOnceChecker(0);
    }
    if (policy_number && policy_type && !!!runOnceChecker) {
      fetchPolicyActivityList({
        policy_number,
        policy_type,
        begin_date: initialValues.policyActivityFormRange.from,
        end_date: initialValues.policyActivityFormRange.to
      });
      setRunOnceChecker(1);
    }
  }, [
    fetchPolicyActivityList,
    initialValues.policyActivityFormRange.from,
    initialValues.policyActivityFormRange.to,
    policy_number,
    policy_type,
    runOnceChecker,
    runOnceData.policy_number,
    runOnceData.policy_type
  ]);

  const form = useForm({ values: initialValues, onSubmit: handleSubmission });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <Form context={form}>
      <FormGroup>
        <DateRange
          id="policyActivityFormRange"
          name="policyActivityFormRange"
          labelFrom={Translations.account_overview.policyActivityTab.begin_date}
          labelTo={Translations.account_overview.policyActivityTab.end_date}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onValidate={handleOnValidate}
          value={values.policyActivityFormRange}
          format="MM/DD/YYYY"
          required
          minDate={minDate}
          maxDate={maxDate}
        />
        <Button labelSpace type="submit" variant="primary">
          {Translations.account_overview.policyActivityTab.submit}
        </Button>
      </FormGroup>
    </Form>
  );
};

PolicyActivityForm.propTypes = {
  fetchPolicyActivityList: PropTypes.func.isRequired,
  policy_number: PropTypes.string.isRequired,
  policy_type: PropTypes.string.isRequired
};

export default PolicyActivityForm;
