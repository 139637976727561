import API from "../../api";

export const fetchOasisInvoiceDetail = accountNumber => {
  return API.agent().get(
    `/billing/billing-information/${accountNumber}?search_type=account`
  );
};

export const processAgencySweepPayment = data => {
  return API.agent().post(`/billing/payment-request`, data);
};

export const sendOasisPaymentConfirmation = paymentRequests => {
  return API.agent().post(`/billing/payment-confirmation`, paymentRequests);
};

export const paymentConfirmationReport = data => {
  return API.agent().post(`billing/payment-confirmation-report`, data, {
    responseType: "blob"
  });
};
