import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgencyInfo } from "../slices/agencyInfoSlice";

function useGetAgencyInfo(agencyCode, responseCallback = null) {
  const dispatch = useDispatch();
  const agencyInfoState = useSelector(state => state.agencyInfoData);

  useEffect(() => {
    (async () => {
      if (!agencyCode) return;
      if (!agencyInfoState) return;
      if (agencyInfoState?.isLoading || agencyInfoState?.error) return;
      if (
        !agencyInfoState?.data?.code ||
        agencyInfoState?.data?.code !== agencyCode
      ) {
        dispatch(fetchAgencyInfo(agencyCode));
      } else {
        responseCallback(agencyInfoState?.data);
      }
    })();
  }, [agencyInfoState, dispatch, agencyCode, responseCallback]);

  return [
    agencyInfoState?.isLoading,
    agencyInfoState?.data,
    agencyInfoState?.error
  ];
}

export default useGetAgencyInfo;
