import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import PolicyDecsTable from "./PolicyDecsTable";
import { Translations } from "../../components/translations";
import {
  LoadingIndicator,
  FormGroup,
  Form,
  useForm,
  DateRange,
  Button,
  Alert,
  formatDate,
  Modal,
  FlexRow
} from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const PolicyDecs = React.memo(
  ({
    agencyCode,
    fetchPolicyDecs,
    policyDecsLoading,
    policyDecsList,
    policyDecsError,
    removePolicyDec,
    fetchPolicyDecsDocument,
    is109015Agent,
    DismissErrorModal,
    policyDecsCommercialDocumentFailed,
    activeSpecialtyCompanyBranch
  }) => {
    const openPolicyDecsPdf =
      (policyNumber, processedDate, job_number, accountnumber) => () => {
        const requestedDecs = policyDecsList.filter(
          item =>
            item.account_number === accountnumber &&
            item.processed_date === processedDate &&
            item.policy_number === policyNumber &&
            (job_number ? item.job_number === job_number : true)
        );

        const body = {
          documents: requestedDecs.map(item => ({
            amendment_date: moment(item.amendment_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            ),
            dec_code: item.dec_code,
            processed_date: moment(item.processed_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            ),
            policy_number: item.policy_number,
            status: item.status,
            job_number
          })),
          source_system: requestedDecs[0].source_system
        };
        if (
          document.getElementsByClassName(
            policyNumber.toString() + job_number?.toString()
          ).length > 0
        ) {
          document.getElementsByClassName(
            policyNumber.toString() + job_number?.toString()
          )[0].style.display = "inherit";
        }
        fetchPolicyDecsDocument(body).then(() => {
          if (
            document.getElementsByClassName(
              policyNumber.toString() + job_number?.toString()
            ).length > 0
          ) {
            document.getElementsByClassName(
              policyNumber.toString() + job_number?.toString()
            )[0].style.display = "none";
          }
        });
      };

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 60);
    const maxDate = new Date();

    const initialFromDate = new Date();
    initialFromDate.setDate(initialFromDate.getDate() - 8);

    const initialValues = {
      policyDecsRange: { from: initialFromDate, to: maxDate }
    };

    const form = useForm({ values: initialValues });
    const { errors, values, handleOnBlur, handleOnValidate, updateForm } = form;

    const handleSubmit = useCallback(() => {
      const formattedBeginDate = formatDate(
        values.policyDecsRange.from,
        "YYYY-MM-DD"
      );
      const formattedEndDate = formatDate(
        values.policyDecsRange.to,
        "YYYY-MM-DD"
      );
      fetchPolicyDecs(
        agencyCode,
        formattedBeginDate,
        formattedEndDate,
        activeSpecialtyCompanyBranch
      );
    }, [
      values.policyDecsRange.from,
      values.policyDecsRange.to,
      activeSpecialtyCompanyBranch,
      agencyCode,
      fetchPolicyDecs
    ]);

    useEffect(() => {
      const errorKeys = Object.keys(errors || {});
      const hasErrors = errorKeys && errorKeys.length > 0;
      if (
        !hasErrors &&
        agencyCode !== "109015" &&
        values.policyDecsRange.from &&
        values.policyDecsRange.to
      ) {
        handleSubmit();
      }
    }, [
      errors,
      agencyCode,
      values.policyDecsRange.from,
      values.policyDecsRange.to,
      handleSubmit
    ]);

    const removePolicyDeclaration = (policy, process_date) => {
      const formattedBeginDate = formatDate(
        values.policyDecsRange.from,
        "YYYY-MM-DD"
      );
      const formattedEndDate = formatDate(
        values.policyDecsRange.to,
        "YYYY-MM-DD"
      );
      removePolicyDec(
        agencyCode,
        policy,
        process_date,
        formattedBeginDate,
        formattedEndDate
      );
    };

    const handleRangeChange = field => {
      const validatinErrors = rangeValidation(field, field.value);
      const errorKeys = Object.keys(validatinErrors || {});
      const hasErrors = errorKeys && errorKeys.length > 0;
      updateForm({
        values: {
          ...values,
          policyDecsRange: field.value
        },
        errors: {
          policyDecsRange: hasErrors
            ? [Translations.commercial_tab.date_range_exceeds_error]
            : []
        }
      });
    };

    const rangeValidation = ({ field, value, event }) => {
      const errors = handleOnValidate({ field, value, event });

      // date range should not exceed 30 days
      if (!!value.from && !!value.to) {
        const f = new Date(value.from);
        const t = new Date(value.to);
        if ((t.getTime() - f.getTime()) / (1000 * 3600 * 24) > 30) {
          errors.push(Translations.commercial_tab.date_range_exceeds_error);
        }
      }
      return errors;
    };

    return (
      <div className="policyDecsWrapper">
        <div>
          <Modal
            show={!!policyDecsCommercialDocumentFailed}
            onHide={DismissErrorModal}
            body={<HelpDeskMessage display="anErrorOccurred" />}
            footer={
              <FlexRow align="right">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={DismissErrorModal}
                >
                  {Translations.account_overview.billingDetailsTab.close}
                </Button>
              </FlexRow>
            }
          />
        </div>

        <Form context={form}>
          <FormGroup>
            <DateRange
              id="policyDecsRange"
              name="policyDecsRange"
              className="policy-decs-range"
              labelFrom={
                Translations.reports.tabAgency.experienceDetailTab.label
                  .beginDate
              }
              labelTo={
                Translations.reports.tabAgency.experienceDetailTab.label.endDate
              }
              onChange={handleRangeChange}
              onBlur={handleOnBlur}
              onValidate={rangeValidation}
              value={values.policyDecsRange}
              format="MM/DD/YYYY"
              required
              minDate={minDate}
              maxDate={maxDate}
            />
          </FormGroup>
        </Form>
        {
          // show text that 109015 users must enter code to search
          agencyCode === "109015" && (
            <Alert type="plain" dismissible={false}>
              {
                Translations.commercial_tab
                  .home_agent_policy_decs_fail_error_message
              }
            </Alert>
          )
        }
        {
          // if props includes an error, display it
          policyDecsError && <Alert type="warning">{policyDecsError}</Alert>
        }
        {policyDecsLoading ? (
          <LoadingIndicator />
        ) : policyDecsList.length ? (
          <PolicyDecsTable
            policyDecsList={policyDecsList}
            removePolicyDec={removePolicyDeclaration}
            beginDate={values.policyDecsRange.from}
            endDate={values.policyDecsRange.to}
            openPolicyDecsPdf={openPolicyDecsPdf}
            is109015Agent={is109015Agent}
          />
        ) : null}
      </div>
    );
  }
);

export default PolicyDecs;

PolicyDecs.propTypes = {
  agencyCode: PropTypes.string,
  fetchPolicyDecs: PropTypes.func.isRequired,
  policyDecsLoading: PropTypes.bool,
  policyDecsList: PropTypes.array,
  policyDecsError: PropTypes.string,
  removePolicyDec: PropTypes.func,
  fetchPolicyDecsDocument: PropTypes.func,
  is109015Agent: PropTypes.bool,
  DismissErrorModal: PropTypes.func,
  policyDecsCommercialDocumentFailed: PropTypes.bool,
  activeSpecialtyCompanyBranch: PropTypes.string
};
