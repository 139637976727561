import * as types from "../uwReviewActionTypes";
import * as uwReviewServices from "../uwReviewServices";
import { logger } from "../../loggers";

/*--------UWREVIEW ACCOUNT INFORMATION SECTION---------- */
const fetchUWReviewAccountInfoServiceStarted = () => ({
  type: types.FETCH_UW_REVIEW_ACCOUNT_STARTED
});

const fetchUWReviewAccountInfoServiceSucceeded = data => ({
  type: types.FETCH_UW_REVIEW_ACCOUNT_SUCCEEDED,
  payload: data
});

const fetchUWReviewAccountInfoServiceFailed = error => ({
  type: types.FETCH_UW_REVIEW_ACCOUNT_FAILED,
  payload: error
});
export const fetchUWReviewAccountInfo = (
  agency_code,
  account_number,
  sub_producer
) => dispatch => {
  dispatch(fetchUWReviewAccountInfoServiceStarted());
  return uwReviewServices
    .getUWReviewAccountInfo(agency_code, account_number, sub_producer)
    .then(
      response => {
        dispatch(fetchUWReviewAccountInfoServiceSucceeded(response.data));
      },
      error => {
        dispatch(fetchUWReviewAccountInfoServiceFailed(error));
        logger.error({
          description: error.toString(),
          fatal: true
        });
      }
    );
};
