import React from "react";
import cn from "classnames";
import "./ConsentSuretySteps.scss";
import { Icon } from "@ufginsurance/ui-kit";

const ConsentSuretySteps = ({ step }) => {
  const steps = [
    { num: 1, name: "Consent Information" },
    { num: 2, name: "Review Request" },
    { num: 3, name: "Confirmation" }
  ];

  return (
    <div className="cos__steps-menu">
      <ul className="cos__steps-step">
        {steps.map(s => {
          return (
            <li
              key={s.name}
              className={cn("cos__steps-step__item", {
                active: s.num === step
              })}
            >
              <div className="cos__steps-step__wrapper">
                {s.num < 3 && (
                  <div className="cos__steps-step__progress-bar"></div>
                )}
                <div className="cos__steps-step__num">
                  <span className="cos__steps__step-number">
                    <span className="cos__steps-step__num-label">{s.num}</span>
                    <span className="cos__steps-step__check">
                      <Icon icon="fasCheck" />
                    </span>
                  </span>
                </div>
                <div className="cos__steps-step__label">{s.name}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ConsentSuretySteps;
