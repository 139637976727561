import * as types from "../quickQuotingActionTypes";

const initState = {
  isLoading: false,
  error: null,
  isSuccess: false
};

const suretyQuickUserAttachments = (state = initState, action) => {
  switch (action.type) {
    case types.POST_QUICK_DOCS_STARTED:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        error: null
      };
    case types.POST_QUICK_DOCS_SUCCEEDED:
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        error: null
      };
    case types.POST_QUICK_DOCS_FAILED:
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default suretyQuickUserAttachments;
