import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ExperienceDetailForm from "./ExperienceDetailForm";
import { postExperienceDetail } from "../../../services/agencyReportService";
import { Translations } from "../../../../components/translations";
import { Button, Modal, FlexRow, Alert } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../../../../help-desk/helpDeskMessage";

const ExperienceDetail = ({
  selectedAgencyCode,
  emailList,
  fetchUnderwritingEmailListDetails,
  is109015Agent,
  hasAgencyProductionReportsAccess,
  email
}) => {
  const [showModal, setShowModal] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [emailTo, setEmailTo] = useState("");
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  useEffect(() => {
    if (
      fetchUnderwritingEmailListDetails &&
      selectedAgencyCode &&
      hasAgencyProductionReportsAccess
    ) {
      fetchUnderwritingEmailListDetails(selectedAgencyCode);
    }
  }, [
    fetchUnderwritingEmailListDetails,
    selectedAgencyCode,
    hasAgencyProductionReportsAccess
  ]);

  const handleSubmit = (values, formReset) => {
    if (is109015Agent) {
      values.emailTo = `${values.emailTo}@${window.env.REACT_APP_UFG_EMAIL_DOMAIN}`;
    }
    setIsLoadingReport(true);
    postExperienceDetail(values, selectedAgencyCode).then(
      () => {
        setShowModal(true);
        setSubmitFailed(false);
        setEmailTo(values.emailTo);
        formReset();
        setIsLoadingReport(false);
      },
      () => {
        setShowModal(false);
        setSubmitFailed(true);
        setEmailTo("");
        setIsLoadingReport(false);
      }
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setSubmitFailed(false);
    setEmailTo("");
  };

  return (
    <div>
      {selectedAgencyCode && selectedAgencyCode === "109015" ? (
        <div className="agency-error-message">
          {Translations.reports.internal_user}
        </div>
      ) : (
        <>
          <div className="reports-above-form-message">
            <HelpDeskMessage display="questionsOrAssistance" />
          </div>
          {!hasAgencyProductionReportsAccess ? (
            <div className="agency-error-message">
              {Translations.reports.tabAgency.access_error_message}
            </div>
          ) : (
            <>
              {submitFailed && (
                <div className="reports-above-form-message">
                  <Alert type="error">
                    <HelpDeskMessage display="anErrorOccurred" />
                  </Alert>
                </div>
              )}
              <ExperienceDetailForm
                emailList={emailList}
                selectedAgencyCode={selectedAgencyCode}
                is109015Agent={is109015Agent}
                submit={handleSubmit}
                hasAgencyProductionReportsAccess={
                  hasAgencyProductionReportsAccess
                }
                email={email}
                isLoadingReport={isLoadingReport}
              />
            </>
          )}
          <Modal
            title={Translations.reports.request_Submitted}
            body={
              <div className="agency-audit">
                {Translations.reports.emailTo}
                {emailTo}
              </div>
            }
            footer={
              <FlexRow align="right">
                <Button variant="secondary" onClick={handleClose}>
                  {Translations.general.close}
                </Button>
              </FlexRow>
            }
            show={showModal}
            onHide={handleClose}
          />
        </>
      )}
    </div>
  );
};

ExperienceDetail.propTypes = {
  selectedAgencyCode: PropTypes.string,
  emailList: PropTypes.array,
  fetchUnderwritingEmailListDetails: PropTypes.func,
  is109015Agent: PropTypes.bool,
  hasAgencyProductionReportsAccess: PropTypes.bool,
  email: PropTypes.string
};

export default ExperienceDetail;
