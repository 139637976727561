import { connect } from "react-redux";
import UWReview from "./UWReview";
import { fetchUWReviewBondInfo } from "./actions/uwReviewBondInfoAction";
import { fetchUWReviewAccountInfo } from "./actions/uwReviewAccountInfoAction";
import { uwReviewDecision } from "./actions/uwReviewDecisionAction";
import { fetchUWReviewWarningMessagesInfo } from "./actions/uwReviewWarningMessagesAction";

const mapStateToProps = state => {
  return {
    uwReviewBondInfoData: state.quickUnderWriterReviewBond,
    uwReviewAccountInfoData: state.quickUnderWriterReviewAccountInfo,
    uwReviewDecisionData: state.quickUnderWriterDecision,
    uwReviewWarningMessagesData: state.quickUnderWriterWarningMessages
  };
};
const mapDispatchToProps = {
  fetchUWReviewBondInfo,
  fetchUWReviewAccountInfo,
  uwReviewDecision,
  fetchUWReviewWarningMessagesInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(UWReview);
