import { connect } from "react-redux";
import NewsCurrent from "./newsCurrent";
import { fetchNewsCurrent } from "./newsCurrentActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { getNewsCurrent } from "./newsSelector";
import {
  getActiveSpecialtyCompanyBranch
} from "../portalview/changePortalViewStateSelectors";

const mapStateToProps = state => ({
  agentState: sessionSelector.getActiveAgencyState(state),
  newsCurrent: getNewsCurrent(state),
  activeSpecialtyCompanyBranch: getActiveSpecialtyCompanyBranch(state),
});

const mapDispatchToProps = {
  fetchNewsCurrent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsCurrent);
