import React, { useState, useEffect, useCallback, useContext } from "react";
import * as api from "../../services/onlineQuotingService";
import _set from "lodash/set";
import _equal from "lodash/isEqual";
import { Button, Panel, FlexRow, LoadingIndicator } from "@ufginsurance/ui-kit";
import { massageMetadata } from "./util";
import { quotePath, productKeys } from "./constants";
import coveragePanels, { getCoverageDetailsForLogging } from "./coveragePanels";

import OqModal from "./OqModal";
import ConfirmationModal from "./ConfirmationModal";
import OnlineQuotingContext from "../OnlineQuotingContext";
import ProductCoverages from "../step4/ProductCoverages.js";

//cyberExclusions moved to AWS controls/lineLevel/cyber
//check if all cyber coverages have been removed.
// then remove cyber from policy.
const checkRemainingCyberCoverages = ({
  changeLineOfBusiness,
  quoteData,
  getQuotePart
}) => {
  if (quoteData) {
    const cyberCoverages = getQuotePart(quotePath.cyberCoverages, true);
    const shouldRemoveCyberline =
      cyberCoverages.length > 0 &&
      cyberCoverages.filter(c => c.selected === true).length === 0;

    if (shouldRemoveCyberline) {
      changeLineOfBusiness({
        action: "Removing",
        product: productKeys.ISPLine
      });
    }
  }
};

// -----------------------------------------------
//   STEP 4 CYBER COMPONENTS
// -----------------------------------------------

export const CyberLineRemoveLinkStep4 = () => {
  const { quoteData, changeLineOfBusiness, getQuotePart } =
    useContext(OnlineQuotingContext);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const hasCyber = quoteData?.lobData?.hasOwnProperty("internetSecurity");
  useEffect(() => {
    checkRemainingCyberCoverages({
      changeLineOfBusiness,
      quoteData,
      getQuotePart
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuotePart, quoteData]);

  return (
    <>
      <Button
        onClick={() => {
          setShowDeleteConfirmation(true);
        }}
        isLink
        variant="tertiary"
        className="oq__remove-cyber-coverages"
      >
        <span>Remove Cyber Coverages</span>
      </Button>
      {hasCyber && showDeleteConfirmation && (
        <ConfirmationModal
          title="Delete confirmation"
          warningMessage={
            <>
              <div>Are you sure you want to remove Cyber Policy?</div>
            </>
          }
          onClickYes={() => {
            changeLineOfBusiness({
              action: "Removing",
              product: productKeys.ISPLine
            });
          }}
          show={showDeleteConfirmation}
          onHide={() => {
            setShowDeleteConfirmation(!showDeleteConfirmation);
          }}
        />
      )}
    </>
  );
};

export const AddCyberLinePanelStep4 = () => {
  const { quoteData, changeLineOfBusiness } = useContext(OnlineQuotingContext);

  const hasCyber = quoteData?.lobData?.hasOwnProperty("internetSecurity");
  const cyberElibigle = !!quoteData?.baseData?.cyberEligible_UFG;

  if (hasCyber || !cyberElibigle) return null;

  return (
    <div className="oq__product-coverages">
      <Panel
        isOpen
        titlebar
        rounded
        title={productKeys.internetSecurity.label + " Coverages"}
        bgcolor="grey"
        className="oq__coverage-panel__Cyber"
      >
        <Button
          wrapperClassName="push-right"
          variant="primary"
          onClick={() =>
            changeLineOfBusiness({
              action: "Adding",
              product: productKeys.ISPLine
            })
          }
        >
          Add Cyber Policy
        </Button>
      </Panel>
    </div>
  );
};

// -----------------------------------------------
//   STEP 6 CYBER COMPONENT
// -----------------------------------------------

export const CyberLineStep6 = () => {
  const {
    quoteData,
    supportingData,
    updateQuote,
    getQuotePart,
    changeLineOfBusiness,
    updateCoveragesPromise,
    loggingData,
    step,
    rateQuote,
    showUpdatingToast,
    closeUpdatingToast,
    toastErrr
  } = useContext(OnlineQuotingContext);

  const [hasCyber, setHasCyber] = useState(false);
  const [showCyberModal, setShowCyberModal] = useState(false);
  const [panels, setPanels] = useState([]);
  const [isEligibleForCyber, setCyberEligiblity] = useState(false);

  const [cyberExclusionData, setCyberExclusionData] = useState();

  useEffect(() => {
    setHasCyber(quoteData?.lobData?.hasOwnProperty("internetSecurity"));
    setCyberEligiblity(!!quoteData?.baseData?.cyberEligible_UFG);
    checkRemainingCyberCoverages({
      showUpdatingToast,
      closeUpdatingToast,
      quoteData,
      getQuotePart,
      updateQuote,
      loggingData,
      step
    });
  }, [
    showUpdatingToast,
    closeUpdatingToast,
    getQuotePart,
    loggingData,
    quoteData,
    step,
    updateQuote
  ]);

  const saveCoverages = useCallback(
    (coverage, termToUpdate, action = "added") => {
      const productName = "internetSecurity";
      coverage.updated = true;
      coverage.selected = action === "added";
      const coveragePath = `${productName}.${quotePath.groupsCoverages}`;
      const coveragesToSave = {};
      _set(coveragesToSave, coveragePath, [coverage]);

      updateCoveragesPromise({
        coveragesToSave,
        productName,
        coverageDetails: getCoverageDetailsForLogging({
          coverage,
          termToUpdate,
          action
        })
      }).catch(({ error }) => console.error({ error }));
    },
    [updateCoveragesPromise]
  );

  // OOQ-3049 remove behaves like a toggle add/remove
  const removeCoverageAndSave = useCallback(
    coverageToRemove => {
      coverageToRemove.selected = !coverageToRemove.selected;
      coverageToRemove.terms = [];
      saveCoverages(
        coverageToRemove,
        null,
        coverageToRemove.selected ? "added" : "removed"
      );
    },
    [saveCoverages]
  );

  /**
   * when Cyber is added, we need the coverage exclusions

   * if not, then use the local ones
   *
   * 
   */
  useEffect(() => {
    if (showCyberModal && !cyberExclusionData) {
      api
        .getCoverageControlData({ dto: quoteData, supportingData })
        .then(r => {
          const data = {
            formData: {
              coverageControl: [
                ...(r?.controls?.lineLevel.cyber?.coverageControl || [])
              ]
            }
          };
          setCyberExclusionData(data);
        })
        .catch(error => {
          toastErrr({
            error,
            action: "getCoverageControlData",
            description: "api failure getCoverageControlData"
          });
        });
    }
  }, [
    cyberExclusionData,
    quoteData,
    showCyberModal,
    loggingData,
    supportingData,
    toastErrr
  ]);

  //read just cyber coverages.
  useEffect(() => {
    if (cyberExclusionData && hasCyber) {
      const cyberCoverages = getQuotePart(quotePath.cyberCoverages, true).map(
        f => {
          f.coveragesPath = quotePath.cyberCoverages;
          return f;
        }
      );

      const _panels = [];
      _panels.push({
        panels: massageMetadata(
          coveragePanels({
            fields: cyberCoverages,
            coverageExclusions: cyberExclusionData,
            removeCoverageAndSave,
            quoteData,
            supportingData
          }),
          quoteData
        ),
        allCoverages: cyberCoverages,
        panelTitle: productKeys.internetSecurity.label,
        coveragePaths: quotePath.cyberCoverages
      });

      // update the panels if:
      // we haven't created panels yet...
      // or if the coverages change (add/remove coverage)
      if (
        panels.length === 0 ||
        !_equal(panels?.[0].panels, _panels?.[0].panels)
      )
        setPanels(_panels);
    }
  }, [
    cyberExclusionData,
    getQuotePart,
    hasCyber,
    panels,
    quoteData,
    removeCoverageAndSave,
    supportingData
  ]);

  return (
    <>
      {hasCyber && showCyberModal && (
        <OqModal
          className="oq_modal__coverages__policy-info"
          title={productKeys.internetSecurity.label + " Coverages"}
          show
          size="lg"
          closeIcon={false}
          body={
            <>
              {!cyberExclusionData && (
                <LoadingIndicator message="Loading Coverage Data" />
              )}
              {cyberExclusionData &&
                panels &&
                panels.length > 0 &&
                panels.map(p => (
                  <ProductCoverages
                    key={`${p.coveragePaths.productName}${
                      p.allCoverages[0]?.title || ""
                    }`}
                    panels={p.panels}
                    quoteData={quoteData}
                    allCoverages={p.allCoverages}
                    saveCoverages={saveCoverages}
                    isOpen
                    hideAddCoverageButton
                    hideOutterPanel
                  />
                ))}
              <FlexRow align="right">
                <Button
                  isLink
                  variant="tertiary"
                  onClick={() => {
                    changeLineOfBusiness({
                      action: "Removing",
                      product: productKeys.ISPLine,
                      rateAfter: true
                    }).then(() => setShowCyberModal(false));
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    rateQuote().then(() => setShowCyberModal(false));
                  }}
                  variant="primary"
                >
                  Continue
                </Button>
              </FlexRow>
            </>
          }
        />
      )}

      {!hasCyber && isEligibleForCyber && (
        <Button
          onClick={() => {
            changeLineOfBusiness({
              action: "Adding",
              product: productKeys.ISPLine
            }).then(() => setShowCyberModal(true));
          }}
          isLink
          inline
        >
          Add Cyber Policy
        </Button>
      )}
    </>
  );
};
