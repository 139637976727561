import * as types from "../constants/actionTypes";
import initialState from "./initialState";

/* eslint-disable complexity */
const commercialLinesReducer = (
  state = initialState.commercialLines,
  action
) => {
  switch (action.type) {
    case types.FETCH_QUOTE_PROPOSAL_START: {
      const branchChanged =
        state.quoteProposals.pastFetchedQuoteProposalsSpecialtyCompanyBranch !==
        action.specialtyCompanyBranch;

      const agencyChanged =
        state.quoteProposals.pastFetchedQuoteProposalsCode !==
        action.agencyCode;

      const newItems =
        branchChanged || agencyChanged
          ? {}
          : {
              ...state.quoteProposals.items,
              [action.currentFilter]: []
            };

      return {
        ...state,
        quoteProposals: {
          ...state.quoteProposals,
          items: newItems,
          isLoading: true,
          error: null,
          pastQuoteProposalsFilter: action.currentFilter
        }
      };
    }

    case types.FETCH_QUOTE_PROPOSAL_SUCCEEDED:
      return {
        ...state,
        quoteProposals: {
          ...state.quoteProposals,
          items: {
            ...state.quoteProposals.items,
            [action.currentFilter]: action.payload
          },
          error: {
            ...state.quoteProposals.error,
            [action.currentFilter]: null
          },
          isLoading: false,
          pastFetchedQuoteProposalsCode: action.agencyCode,
          pastQuoteProposalsFilter: action.currentFilter,
          pastFetchedQuoteProposalsSpecialtyCompanyBranch:
            action.specialtyCompanyBranch
        }
      };
    case types.FETCH_QUOTE_PROPOSAL_FAILED: {
      const finalState = {
        ...state,
        quoteProposals: {
          ...state.quoteProposals,
          isLoading: false,
          items: {
            ...state.quoteProposals.items,
            [action.currentFilter]: null
          },
          error: {
            ...state.quoteProposals.error,
            [action.currentFilter]: action.payload
          },
          pastFetchedQuoteProposalsCode: action.agencyCode,
          pastQuoteProposalsFilter: action.currentFilter,
          pastFetchedQuoteProposalsSpecialtyCompanyBranch:
            action.specialtyCompanyBranch
        }
      };
      return finalState;
    }
    case types.UPDATE_QUOTE_PROPOSAL_FILTER:
      return {
        ...state,
        quoteProposals: {
          ...state.quoteProposals,
          currentFilter: action.payload
        }
      };
    case types.CREATE_OR_UPDATE_CUSTOMER_SUCCEEDED: {
      const customerIndex = state.submissionList.findIndex(
        s =>
          s.Customer.id.toLowerCase() === action.payload.data.id.toLowerCase()
      );

      const existingCustomer = customerIndex !== -1;
      if (!existingCustomer) {
        return state;
      }

      const updatedSubmissions = [...state.submissionList];
      updatedSubmissions[customerIndex].Customer = action.payload.data;

      return { ...state, submissionList: updatedSubmissions };
    }
    case types.FETCH_SUBMISSION_LIST_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        submissionList: []
      };
    case types.DELETE_QUOTE_SUCCEEDED:
      return {
        ...state,
        submissionList: action.payload
      };
    case types.FETCH_SUBMISSION_LIST_SUCCEEDED:
      return {
        ...state,
        submissionList: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_SUBMISSION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        submissionList: []
      };
    case types.UPDATE_SUBMISSION_LIST_FILTER:
      return {
        ...state,
        currentFilter: action.payload
      };

    case types.FETCH_MESSAGES_STARTED:
      return {
        ...state,
        isMessagesLoading: true,
        error: null
      };
    case types.FETCH_MESSAGES_SUCCEEDED:
      return {
        ...state,
        messages: action.payload,
        isMessagesLoading: false
      };

    case types.RESET_MESSAGES:
      return {
        ...state,
        messages: initialState.commercialLines.messages
      };
    case types.MARK_CONVERSATION_READ: {
      const messages = state.messages;
      messages.conversations = messages.conversations.map(conversation => {
        if (conversation.id === action.payload)
          conversation.unread_message_count = 0;
        return conversation;
      });
      return {
        ...state,
        messages,
        isMessagesLoading: false
      };
    }
    case types.FETCH_MESSAGES_FAILED:
      return {
        ...state,
        isMessagesLoading: false,
        error: action.payload
      };
    case types.START_CONVERSATIONS_STARTED:
      return {
        ...state,
        isMessagesLoading: false,
        isStartConversationLoading: true
      };
    case types.START_CONVERSATIONS_SUCCEEDED:
      return {
        ...state,
        isMessagesLoading: false,
        isStartConversationLoading: false
      };
    case types.CONVERSATIONS_FAILED:
      return {
        ...state,
        isMessagesLoading: false,
        isStartConversationLoading: false,
        error: action.payload
      };
    case types.REPLY_MESSAGE_STARTED:
      return {
        ...state,
        isMessagesLoading: false,
        isReplyMessageLoading: true
      };
    case types.REPLY_MESSAGE_SUCCEEDED:
      return {
        ...state,
        isMessagesLoading: false,
        isReplyMessageLoading: false
      };
    case types.REPLY_FAILED:
      return {
        ...state,
        isMessagesLoading: false,
        isReplyMessageLoading: false,
        error: action.payload
      };

    case types.FETCH_ATTACHMENT_LIST:
      return {
        ...state,
        attachmentData: action.payload
      };
    case types.RESET_ATTACHMENTS:
      return {
        ...state,
        attachmentData: initialState.commercialLines.attachmentData
      };
    case types.UPLOAD_ATTACHMENT_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload
      };
    case types.FETCH_PROPOSAL_DOCUMENT_STARTED:
      return {
        ...state,
        loadingProposalDocumentIds: [
          ...state.loadingProposalDocumentIds,
          action.id
        ]
      };
    case types.FETCH_PROPOSAL_DOCUMENT_SUCCESS:
      return {
        ...state,
        loadingProposalDocumentIds:
          state.loadingProposalDocumentIds?.filter(id => id !== action.id) ?? []
      };
    case types.FETCH_PROPOSAL_DOCUMENT_FAIL:
      return {
        ...state,
        loadingProposalDocumentIds:
          state.loadingProposalDocumentIds?.filter(id => id !== action.id) ?? []
      };

    default:
      return state;
  }
};

export default commercialLinesReducer;
