import React from "react";
import PropTypes from "prop-types";
import OrganizationContacts from "./OrganizationContacts";
import IndividualContacts from "./IndividualContacts";
import { ContentHeader, LoadingIndicator } from "@ufginsurance/ui-kit";
import { useDepartmentsFetch } from "../hooks/fetch/useDepartmentsFetch";
import { useDepartmentContactsFetch } from "../hooks/fetch/useDepartmentContactsFetch";
import { useDepartmentRouteValidation } from "../hooks/validation/useDepartmentRouteValidation";
import { useDefaultDepartmentRouteValidation } from "../hooks/validation/useDefaultDepartmentRouteValidation";
import { contactCategories } from "../constants";
import HelpDeskMessage from "../../help-desk/helpDeskMessage";

const YourUfgTeam = ({
  departmentData,
  agencyCode,
  fetchUfgTeamDepartmentData,
  fetchUfgTeamContactDetails,
  contactDetails,
  areContactsLoading,
  contactError,
  activeSpecialtyCompanyBranch,
  previousCompanyBranch,
  previousAgencyCode,
  setPreviousDepartment,
  previousDepartment,
  areDepartmentsLoading,
  history
}) => {
  useDepartmentsFetch({
    activeSpecialtyCompanyBranch,
    previousCompanyBranch,
    agencyCode,
    previousAgencyCode,
    fetchUfgTeamDepartmentData,
    areDepartmentsLoading
  });

  useDepartmentContactsFetch({
    agencyCode,
    fetchUfgTeamContactDetails,
    setPreviousDepartment,
    previousDepartment,
    previousAgencyCode,
    areContactsLoading,
    departmentData,
    activeSpecialtyCompanyBranch,
    previousCompanyBranch
  });

  useDefaultDepartmentRouteValidation(departmentData, history);

  const { department } = useDepartmentRouteValidation(departmentData);

  const organizationContacts =
    contactDetails?.filter(
      item =>
        item.category &&
        item.category === contactCategories.ORGANIZATION_CATEGORY
    ) || [];

  const individualContacts =
    contactDetails?.filter(
      item =>
        !item?.category ||
        item.category === contactCategories.INDIVIDUAL_CATEGORY
    ) || [];

  return (
    <>
      {department && <ContentHeader>{department?.name ?? ""}</ContentHeader>}
      {areContactsLoading ? (
        <LoadingIndicator />
      ) : contactError ? (
        <div className="friendly-error-messaging">
          <HelpDeskMessage display="anErrorOccurred" />
        </div>
      ) : (
        <div>
          {department && organizationContacts.length > 0 && (
            <OrganizationContacts
              contacts={organizationContacts}
              department={department}
            />
          )}
          {individualContacts.length > 0 && (
            <IndividualContacts
              contacts={individualContacts}
              department={department}
            />
          )}
        </div>
      )}
    </>
  );
};

YourUfgTeam.propTypes = {
  departmentData: PropTypes.array,
  contactDetails: PropTypes.array,
  fetchUfgTeamDepartmentData: PropTypes.func.isRequired,
  fetchUfgTeamContactDetails: PropTypes.func.isRequired,
  areContactsLoading: PropTypes.bool,
  contactError: PropTypes.object,
  agencyCode: PropTypes.string.isRequired,
  previousCompanyBranch: PropTypes.string,
  previousAgencyCode: PropTypes.string,
  previousDepartment: PropTypes.string,
  setPreviousDepartment: PropTypes.func,
  activeSpecialtyCompanyBranch: PropTypes.string,
  areDepartmentsLoading: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default YourUfgTeam;
