import { connect } from "react-redux";
import AccountChangeRequest from "./accountChangeRequest";
import {
  getChangeRequestEligibilityInfo,
  fetchPolicyChangesViewDocument,
  fetchPolicyDraft
} from "./redux/policyChangesAction";
import { getSelectedAgencyCode } from "../selectors/agencySelectors";
import {
  getRequestChange,
  getErrorPageload,
  getIsLoading
} from "./accountChangeRequestSelector";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = (state, ownProps) => ({
  requestChange: getRequestChange(state),
  policyInfo: ownProps.policyInfo,
  error: getErrorPageload(state),
  isLoading: getIsLoading(state),
  selectedAgencyCode: getSelectedAgencyCode(state),
  is109015Agent: sessionSelector.isAgentIn109015AgencyCode(state),
  accountData: state.accountData
});

const mapDispatchToProps = {
  getChangeRequestEligibilityInfo,
  fetchPolicyChangesViewDocument,
  fetchPolicyDraft
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountChangeRequest);
