import React from "react";
import { Form, FormGroup, Input, ContentHeader, Panel } from "@ufginsurance/ui-kit";
import Address from "../../quick-program/quick-quoting/shared/Address";
import AccountOrNameSelector from "../shared/AccountOrNameSelector/AccountOrNameSelector";
import EffectiveExpirationDate from "./components/EffectiveExpirationDate";
import { RATE_RULES } from "./lib/constants";
import { isImbrState } from "./lib/utils";

export default function InsuredInfoForm({
  agencyCode,
  licensedStates,
  form,
  resetForm,
  updateLastVerifiedAddress
}) {
  const {
    handleOnBlur,
    handleOnChange,
    handleOnValidate,
    values,
    errors,
    updateForm
  } = form;

  const isDisabled =
    (!!values?.account_name && values?.account_type === "api") ||
    (!!values?.last_name && !values?.first_name);

  const handleZipChange = (field, localeData) => {
    const locale = Array.isArray(localeData) ? localeData[0] : localeData;
    const zipErrors = [];

    if (!!locale?.state) {
      if (
        !isImbrState(locale.state) ||
        RATE_RULES.blockedStates.includes(locale.state)
      ) {
        zipErrors.push(
          "The state you have selected is not yet available for the Builders' Risk portal. Please contact your underwriter with questions."
        );
      } else if (!licensedStates.some(_ => _ === locale.state)) {
        zipErrors.push(
          "You are attempting to quote business in a state in which we do not have a license on file for. Please contact your UFG marketing representative for licensing procedures."
        );
      }
    }

    updateForm({
      values: {
        ...values,
        zip: field.value,
        city: locale?.city,
        state: locale?.state
      },
      errors: {
        ...errors,
        zip: zipErrors
      }
    });
  };

  return (
    <div>
      <ContentHeader>
        Let&apos;s get started with your Builders&apos; Risk quote
      </ContentHeader>

      <Panel rounded bgcolor="grey" title="Insured Information" titlebar>
        <Form context={form}>
          <AccountOrNameSelector
            form={form}
            resetForm={resetForm}
            licensedStates={licensedStates}
            updateLastVerifiedAddress={updateLastVerifiedAddress}
          />

          <Address
            form={form}
            activeAgencyCode={agencyCode}
            customZipChangeHandler={handleZipChange}
            showDefaultAddressField
            disableProjectCity
            disableProjectState
            addressLineFieldLabel="Insured Address"
            disableFields={isDisabled}
            zipCodeLabel="Zip"
          />
          <EffectiveExpirationDate form={form} />
          <FormGroup>
            <Input
              placeholder="Contractor Name"
              id="contractor_name"
              name="contractor_name"
              label="If insured is not the contractor, enter the contractor name"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.contractor_name}
              maxLength={55}
              size="fill"
              isClearable
            />
          </FormGroup>
        </Form>
      </Panel>
    </div>
  );
}
