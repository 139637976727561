import * as types from "../../constants/actionTypes";
import * as agencySweepService from "../services/agencySweepService";

export const fetchOasisInvoiceDetailStarted = () => ({
  type: types.FETCH_OASIS_INVOICE_DETAIL_STARTED
});

export const fetchOasisInvoiceDetailSucceeded = data => ({
  type: types.FETCH_OASIS_INVOICE_DETAIL_SUCCEEDED,
  payload: data
});

export const fetchOasisInvoiceDetailFailed = error => ({
  type: types.FETCH_OASIS_INVOICE_DETAIL_FAILED,
  payload: error,
  error: true
});

export const fetchOasisInvoiceDetail = accountNumber => dispatch => {
  dispatch(fetchOasisInvoiceDetailStarted());
  return agencySweepService.fetchOasisInvoiceDetail(accountNumber).then(
    response => {
      if (response) {
        dispatch(fetchOasisInvoiceDetailSucceeded(response.data));
      }
    },
    err => {
      dispatch(fetchOasisInvoiceDetailFailed(err));
    }
  );
};
