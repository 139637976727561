import React, { useState, useCallback } from "react";
import {
  Alert,
  Anchor,
  Button,
  LoadingIndicator,
  Modal,
  Panel,
  Table,
  Form,
  FormGroup,
  Input,
  useForm
} from "@ufginsurance/ui-kit";
import {
  ACCOUNT_NUM_MAX_LENGTH,
  BOND_NUM_LENGTH
} from "../../shared/constants";
import { openPopupWindow, formatAccountsDropdownOptions } from "../../shared/utils";
import { useSuretyHomeContext } from "../SuretyHomeProvider";
import { Eportal } from "../../shared/eportal";

export default function LegacyAccountBondSearch() {
  const { accounts, accountsIsLoading } = useSuretyHomeContext();
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = useCallback(({ values }) => {
    if (values.accountBondSearch.length === BOND_NUM_LENGTH) {
      openPopupWindow(Eportal.getBondInfoLink(values.accountBondSearch));
    } else {
      openPopupWindow(Eportal.getAccountInfoLink(values.accountBondSearch));
    }
  }, []);

  const form = useForm({
    values: { accountBondSearch: "" },
    onSubmit: handleSubmit
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  const handleValidation = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    if (
      value &&
      (!(
        value.length <= ACCOUNT_NUM_MAX_LENGTH &&
        value.length >= BOND_NUM_LENGTH
      ) ||
        value === "0000000000" ||
        value === "00000000")
    ) {
      fieldErrors.push("Not a valid account or bond number.");
    }

    return fieldErrors;
  };

  return (
    <div className="account-bond-search--wrapper">
      <Panel rounded bgcolor="grey" title="Account/Bond Inquiry" titlebar>
        <div>Inquire on a specific bond number or account number</div>
        <Form context={form}>
          <FormGroup>
            <Input
              placeholder="123..."
              id="accountBondSearch"
              name="accountBondSearch"
              label="Account/Bond Number"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleValidation}
              value={values.accountBondSearch}
              minLength={BOND_NUM_LENGTH}
              maxLength={ACCOUNT_NUM_MAX_LENGTH}
              required
            />
            <div className="uikit__input-wrapper uikit__input-size__auto">
              <label htmlFor="accountBondInquiry">&nbsp;</label>
              <Button
                id="accountBondInquiry"
                type="submit"
                variant="primary"
                disabled={
                  !(
                    values.accountBondSearch.length <= ACCOUNT_NUM_MAX_LENGTH &&
                    values.accountBondSearch.length >= BOND_NUM_LENGTH
                  )
                }
              >
                Search
              </Button>
            </div>
          </FormGroup>
        </Form>

        <Anchor onClick={() => setShowModal(true)}>
          View Full Surety Account List
        </Anchor>
      </Panel>

      <Modal
        title="Account List"
        id="surety-account-list"
        body={
          <>
            {!accounts?.length && !accountsIsLoading ? (
              <Alert label="No accounts" type="warning">
                &nbsp;
              </Alert>
            ) : (
              <Table
                data={formatAccountsDropdownOptions(accounts || [])}
                rowKey="accounts-"
                initialSort="account_name"
                columns={[
                  {
                    key: "account_name",
                    label: "Account Name",
                    sortable: true,
                    element: _ => (
                      <span className="break-word">{_.account_name}</span>
                    )
                  },
                  {
                    key: "account_number",
                    label: "Account",
                    sortable: true
                  },
                  {
                    key: "state",
                    label: "State",
                    element: _ => <span className="nowrap">{_.state}</span>,
                    sortable: true
                  },
                  {
                    key: "viewAccount",
                    label: "",
                    element: _ => (
                      <Anchor
                        target="_blank"
                        className="nowrap"
                        onClick={() =>
                          openPopupWindow(Eportal.getAccountInfoLink(_.value))
                        }
                      >
                        View Account
                      </Anchor>
                    )
                  }
                ]}
                showPagination
                itemsPerPage={10}
              />
            )}
            {accountsIsLoading && (
              <LoadingIndicator message="Fetching accounts..." color="blue" />
            )}
          </>
        }
        footer={<Button onClick={() => setShowModal(false)}>Close</Button>}
        onHide={() => setShowModal(false)}
        show={showModal}
        size="lg"
      />
    </div>
  );
}
