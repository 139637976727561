export const longCodeNames = {
  NC: "Not Chosen",
  CA: "Commercial Auto",
  CG: "General Liability",
  CP: "Commercial Property",
  CX: "Commercial Umbrella",
  IM: "Inland Marine",
  CR: "Crime",
  FR: "Family Resort",
  EO: "Errors and Omissions",
  OP: "Commercial Output",
  BP: "Premier Pro",
  PC: "PCUS",
  TP: "Trade Pro",
  GP: "Garage Pro",
  WC: "Workers Compensation",
  PP: "Personal Auto",
  HO: "Homeowners",
  DP: "Dwelling Fire",
  RV: "Rec Vehicles",
  WA: "Watercraft",
  PX: "Umbrella",
  HV: "Signature Premier",
  PW: "Personal Watercraft"
};

export const billingCopiesSortingOptions = {
  N: "AccountName",
  D: "EffectiveDate",
  B: "BondNumber"
};

export const billingCopiesTransactionTypes = {
  10: "New Business",
  11: "Renewal",
  21: "Correction",
  22: "Increase",
  23: "Decrease",
  31: "Closing",
  41: "Flat Cancel",
  51: "Reinstate",
  61: "Over/Underrun"
};

export const StateLongNames = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};

export const ACTIVE_IN_SERVICE_CENTER = "Current";

export const ONLINE_ONLY = "11";

export const CLARION_DOOR_POLICY_PREFIX = "200";

export const DOCUMENT_FORM_TYPES = {
  COVERAGE: "coverage",
  DOCUMENT: "document",
  CLAIMS: {
    AGAC: "AGAC"
  },
  CERTIFICATE: "certificate"
};

export const CHANGE_REQUEST_TYPES = {
  "driver-change": {
    title: "Driver Change",
    description: "Add / Edit / Remove Drivers",
    key: "driver-change"
  },
  other: {
    title: "Other Change",
    description: "Submit Other Change to Underwriter",
    key: "other"
  }
};

export const POLICY_TRANSACTION_TYPES = {
  POLICY_CHANGE: "policy-change"
};

export const CORE_IN_FORCE_STATUSES = {
  InForce: "II",
  FutureDirectBill: "FD",
  FutureAgencyBill: "FA",
  PendingPayment: "PP"
};

export const GUIDEWIRE_IN_FORCE_STATUSES = ["INFORCE", "SCHEDULED"];

export const HEADER_NAV_ITEMS = {
  HOME: "HOME",
  ACCOUNTS: "ACCOUNTS",
  COMMERCIAL: "COMMERCIAL",
  SURETY: "SURETY",
  REPORTS: "REPORTS",
  RESOURCES: "RESOURCES",
  MARKETING: "MARKETING",
  USER_MENU: "USER_MENU"
};

export const NAV_EVENT_TYPES = {
  MOUSE_ENTER: "mouse_enter",
  TAB: "tab",
  BLUR: "blur"
};
