import * as types from "./actionTypes";
import * as reportService from "../services/claimsReportsService";
import { logger } from "../../loggers";
import { DocumentService } from "../../services/documentService";

const fetchYearToDateLossReportListStarted = () => ({
  type: types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_STARTED
});

const fetchYearToDateLossReportListSucceeded = data => ({
  type: types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_SUCCEEDED,
  payload: data
});

const fetchYearToDateLossReportListFailed = error => ({
  type: types.FETCH_YEAR_TO_DATE_LOSS_REPORT_LIST_FAILED,
  payload: error
});

export const fetchYearToDateLossReportListDetails = agency_code => dispatch => {
  dispatch(fetchYearToDateLossReportListStarted());

  return reportService.getYearToDateLossReportList(agency_code).then(
    res => dispatch(fetchYearToDateLossReportListSucceeded(res.data)),
    error => {
      dispatch(fetchYearToDateLossReportListFailed(error.response));
      logger.error({
        description: error.toString(),
        fatal: true
      });
    }
  );
};

// TODO: This isn't dispatching any actions.
export const fetchYearToDateLossSummaryViewReport = (
  agency_code,
  date
) => () => {
  const documentService = new DocumentService();
  return reportService
    .getYearToDateLossViewReport(agency_code, date)
    .then(response => {
      documentService.initializeRenderer("/loading-document");
      documentService.saveOrOpenDocument(
        response.data,
        "year_to_date_loss_report.pdf"
      );
    })
    .catch(error => {
      documentService.clearRenderer();
      logger.error({
        description: error.toString(),
        fatal: false
      });
    });
};
